// Repurposed from: https://github.com/lmgonzalves/jelly

// ===== Interfaces =====

import {
    IJellyMouseMoveEvent,
    IJellyHoverEvent,
    IJellyProperties,
    IJellyClickEvent,
    ICoord,
    IJellyPathPoint,
}  from './interfaces';

// Repurposed from: http://github.com/greggman/twgl.js
function resizeCanvasToDisplaySize(canvas: HTMLCanvasElement): boolean {
    const width: number = canvas.clientWidth ?? 0;
    const height: number = canvas.clientHeight ?? 0;
    if (canvas.width !== width || canvas.height !== height) {
        // eslint-disable-next-line no-param-reassign
        canvas.width = width;
        // eslint-disable-next-line no-param-reassign
        canvas.height = height;
        return true;
    }
    return false;
}

// ===== Jelly =====

export default class Jelly {
    canvas: HTMLCanvasElement;

    ctx: CanvasRenderingContext2D | null;

    properties: IJellyProperties = {
        pathsContainer: document,
        borderWidth: 4,
        color: '#666',
        debug: false,
        pointsNumber: 10,
        mouseIncidence: 40,
        maxIncidence: 40,
        maxDistance: 70,
        intensity: 0.95,
        fastness: 1 / 40,
        ent: 0.25,
        x: 0,
        y: 0,
    };

    shakeLimit = 5; // This will be the max distance for shaking

    x = 0;

    y = 0;

    startX = 0;

    startY = 0;

    endX: number;

    endY: number;

    lastX = 0;

    lastY = 0;

    down = false;

    moved = false;

    hover = false;

    mouseX = 0;

    mouseY = 0;

    speed = 0;

    disableMouseEvents = false;

    constructor(
        canvas: HTMLCanvasElement,
        container: HTMLDivElement,
        properties: IJellyProperties,
    ) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        const canvasBoundingRect: DOMRect = container.getBoundingClientRect();
        this.endX = canvasBoundingRect.left;
        this.endY = canvasBoundingRect.top;
        this.init(container, properties);
        window.requestAnimationFrame(() => this.checkHover());
    }

    init(container: HTMLDivElement, properties: IJellyProperties): void {
        resizeCanvasToDisplaySize(this.canvas);
        this.initializeEvents(container);
        this.initializeProperties(properties);
    }

    initializeEvents(container: HTMLDivElement): void {
        this.canvas.addEventListener('mousemove', (e: MouseEvent) => {
            const pos: DOMRect = this.canvas.getBoundingClientRect();
            const x: number = e.clientX - pos.left;
            const y: number = e.clientY - pos.top;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const dx: number = x - this.mouseX!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const dy: number = y - this.mouseY!;
            let dist: number = Math.sqrt(dx * dx * 3 + dy * dy * 3);
            if (dist < 2) dist = 0;
            if (dist > 100) dist = 100;
            const scaleX: number = pos.width / this.canvas.offsetWidth;
            const scaleY: number = pos.height / this.canvas.offsetHeight;
            this.mouseX = x * (1 / scaleX);
            this.mouseY = y * (1 / scaleY);
            this.speed = dist ? dist / 10 : 0;
        });

        this.canvas.addEventListener('mouseout', () => {
            this.mouseX = 0;
            this.mouseY = 0;
            this.speed = 0;
        });

        container.addEventListener('click', () => {
            if (this.disableMouseEvents) return;

            const clickEvent: IJellyClickEvent = {
                detail: {
                    jelly: this,
                },
            };

            if (!this.moved) {
                window.dispatchEvent(
                    new CustomEvent(
                        'jelly-click',
                        clickEvent,
                    ),
                );
            }
        });

        const mouseDown = (e: MouseEvent | TouchEvent): void => {
            if (e.type === 'mousedown') {
                this.startX = (e as MouseEvent).clientX;
                this.startY = (e as MouseEvent).clientY;
            } else {
                const touch = (e as TouchEvent).touches[0];
                this.startX = touch.clientX;
                this.startY = touch.clientY;
            }

            const canvasBoundingRect: DOMRect = container.getBoundingClientRect();
            this.endX = canvasBoundingRect.left;
            this.endY = canvasBoundingRect.top;
            this.down = true;
            this.moved = false;
            window.dispatchEvent(
                new CustomEvent('jelly-mousedown'),
            );
        };

        container.addEventListener('mousedown', (e: MouseEvent) => {
            if (this.disableMouseEvents) return;

            mouseDown(e);
        });

        container.addEventListener('touchstart', (e: TouchEvent) => {
            if (this.disableMouseEvents) return;

            mouseDown(e);
        });

        const mouseMove = (e: MouseEvent | TouchEvent): void => {
            let clientX: number;
            let clientY: number;
            if (e.type === 'mousemove') {
                clientX = (e as MouseEvent).clientX;
                clientY = (e as MouseEvent).clientY;
            } else {
                const touch = (e as TouchEvent).touches[0];
                clientX = touch.clientX;
                clientY = touch.clientY;
            }

            let dx = 0;
            let dy = 0;

            if (this.down) {
                const VIEWPORT_WIDTH: number = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                const VIEWPORT_HEIGHT: number = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
                this.x = clientX - this.startX;
                this.y = clientY - this.startY;
                const left: number = this.endX + this.x + this.canvas.width > VIEWPORT_WIDTH + this.properties.x
                    ? VIEWPORT_WIDTH - this.canvas.width + this.properties.x
                    : Math.max(-this.properties.x, this.endX + this.x); // The makes sure we don't let it moved past left edge of viewport

                const top: number = this.endY + this.y + this.canvas.height > VIEWPORT_HEIGHT + this.properties.y
                    ? VIEWPORT_HEIGHT - this.canvas.height + this.properties.y
                    : Math.max(-this.properties.y, this.endY + this.y); // The makes sure we don't let it moved past top edge of viewport
                // eslint-disable-next-line no-param-reassign
                container.style.left = `${left}px`;
                // eslint-disable-next-line no-param-reassign
                container.style.top = `${top}px`;

                dx = this.x - this.lastX;
                dy = this.y - this.lastY;
                let truncatedDx = dx;
                let truncatedDy = dy;
                if (dx > this.shakeLimit || dx < -this.shakeLimit) truncatedDx = dx < 0 ? -this.shakeLimit : this.shakeLimit;
                if (dy > this.shakeLimit || dy < -this.shakeLimit) truncatedDy = dy < 0 ? -this.shakeLimit : this.shakeLimit;

                const mousemoveEvent: IJellyMouseMoveEvent = {
                    detail: {
                        velocity: Math.sqrt(Math.abs(dx) ** 2 + Math.abs(dy) ** 2),
                    },
                };
                window.dispatchEvent(
                    new CustomEvent(
                        'jelly-mousemove',
                        mousemoveEvent,
                    ),
                );

                // The `shake` function will "move" the half of the points (alternately) the distance defined
                const coord: ICoord = {
                    x: -truncatedDx,
                    y: -truncatedDy,
                };
                this.shake(coord);

                this.lastX = this.x;
                this.lastY = this.y;
            }

            this.moved = true;
        };

        document.addEventListener('mousemove', (e) => {
            if (this.disableMouseEvents) return;

            mouseMove(e);
        });

        document.addEventListener('touchmove', (e) => {
            if (this.disableMouseEvents) return;

            mouseMove(e);
        });

        const mouseUp = (): void => {
            if (this.down) {
                const VIEWPORT_WIDTH: number = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                const VIEWPORT_HEIGHT: number = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
                const canvasBoundingRect: DOMRect = this.canvas.getBoundingClientRect();
                this.down = false;
                this.endX = canvasBoundingRect.left + this.endX + this.x > -this.properties.x
                && canvasBoundingRect.left + this.endX + this.x - this.properties.x < VIEWPORT_WIDTH
                    ? this.endX + this.x
                    : canvasBoundingRect.left;
                this.endY = canvasBoundingRect.top + this.endY + this.y > -this.properties.y
                && canvasBoundingRect.bottom + this.endY + this.y - this.properties.y < VIEWPORT_HEIGHT
                    ? this.endY + this.y
                    : canvasBoundingRect.top;
                window.dispatchEvent(
                    new CustomEvent('jelly-mouseup'),
                );
            }
        };

        document.addEventListener('mouseup', mouseUp);

        document.addEventListener('mouseout', (e: MouseEvent) => {
            if (e.target && (e.target as Node).nodeName === 'HTML') {
                mouseUp();
            }
        });

        document.addEventListener('touchend', mouseUp);

        document.addEventListener('touchcancel', mouseUp);
    }

    initializeProperties(properties: IJellyProperties): void {
        const calcPoints = (): void => {
            const pointsData: IJellyPathPoint[][] = this.getPathPointsData();
            this.properties.pointsData = pointsData;
            const centroidPoint = this.getCentroid();
            this.properties.centroidPoint = centroidPoint;
        };

        const keys = Object.keys(properties);
        for (let i = 0; i < keys.length; i += 1) {
            const key: string = keys[i];
            this.properties[key] = properties[key];
        }

        calcPoints();
        this.renderJelly();
    }

    checkHover(): void {
        const hoverEvent: IJellyHoverEvent = {
            detail: {
                hover: this.hover,
            },
        };
        window.dispatchEvent(
            new CustomEvent(
                'jelly-hover',
                hoverEvent,
            ),
        );

        window.requestAnimationFrame(() => this.checkHover());
    }

    shake(offset: ICoord): void {
        const animateShake = (point: ICoord): void => {
            // eslint-disable-next-line no-param-reassign
            point.x += offset.x;
            // eslint-disable-next-line no-param-reassign
            point.y += offset.y;
        };

        if (this.properties.pointsData) {
            let point;
            for (let i = 0; i < this.properties.pointsData.length; i += 1) {
                point = this.properties.pointsData[i];
                for (let j = 0; j < point.length; j += 2) {
                    animateShake(point[j]);
                }
            }
        }
    }

    getPathPointsData(): IJellyPathPoint[][] {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (!this.properties.pathsContainer) return [];

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const paths: SVGGeometryElement[] = Array.from(this.properties.pathsContainer.querySelectorAll(this.properties.paths!));
        const pathPointsData: IJellyPathPoint[][] = [];
        for (let i = 0; i < paths.length; i += 1) {
            pathPointsData.push(this.getPathPoints(paths[i]));
        }
        return pathPointsData;
    }

    getPathPoints(path: SVGGeometryElement): IJellyPathPoint[] {
        const pathLength = path.getTotalLength();
        let { pointsNumber } = this.properties;
        const margin = (pathLength / pointsNumber);
        let currentPosition = 0;

        const points: IJellyPathPoint[] = [];
        while (pointsNumber > 0) {
            pointsNumber -= 1;
            const p = path.getPointAtLength(currentPosition);
            const point: IJellyPathPoint = {
                xs: 0,
                ys: 0,
                x: p.x + this.properties.x,
                y: p.y + this.properties.y,
                ox: p.x + this.properties.x,
                oy: p.y + this.properties.y,
            };
            points.push(point);
            currentPosition += margin;
        }

        return points;
    }

    renderJelly(): void {
        if (this && this.ctx && this.properties.pointsData && this.properties.centroidPoint) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            let path;
            for (let i = 0; i < this.properties.pointsData.length; i += 1) {
                path = this.properties.pointsData[i];
                this.calcLoop(path);
            }

            this.properties.centroidPoint = this.getCentroid();
            if (this.properties.centroid && this.properties.centroidPoint) {
                this.properties.centroid.style.transform = `translate(${this.properties.centroidPoint.x}px, ${this.properties.centroidPoint.y}px)`;
            }

            for (let i = 0; i < this.properties.pointsData.length; i += 1) {
                this.ctx.save();
                path = this.properties.pointsData[i];
                this.drawPath(path);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.hover = this.ctx.isPointInPath(this.mouseX!, this.mouseY!);
                this.ctx.clip();

                this.ctx.fillStyle = this.properties.color;
                this.ctx.fill();

                if (this.properties.border && this.properties.borderWidth) {
                    this.ctx.strokeStyle = this.properties.border;
                    this.ctx.lineWidth = this.properties.borderWidth;
                    this.ctx.stroke();
                }

                this.ctx.restore();
                if (this.properties.debug) this.drawPoints(path);
            }
            window.requestAnimationFrame(() => this.renderJelly());
        }
    }

    calcLoop(path: IJellyPathPoint[]): void {
        if (
            !this.properties.intensity
            || !this.properties.fastness
            || !this.properties.mouseIncidence
            || !this.properties.maxIncidence
            || !this.properties.ent
        ) {
            return;
        }

        for (let i = 0; i < path.length; i += 1) {
            // eslint-disable-next-line no-param-reassign
            path[i].xs *= this.properties.intensity;
            // eslint-disable-next-line no-param-reassign
            path[i].ys *= this.properties.intensity;

            if (path[i].xs > 11 || path[i].xs < -11) {
                // eslint-disable-next-line no-param-reassign
                path[i].xs = 11 * (path[i].xs < 0 ? -1 : 1);
            }

            // eslint-disable-next-line no-param-reassign
            path[i].xs -= (path[i].x - path[i].ox) * this.properties.fastness;
            // eslint-disable-next-line no-param-reassign
            path[i].ys -= (path[i].y - path[i].oy) * this.properties.fastness;

            // eslint-disable-next-line no-param-reassign
            path[i].x += path[i].xs;

            // eslint-disable-next-line no-param-reassign
            path[i].y += path[i].ys;

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const diffX = path[i].x - this.mouseX!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const diffY = path[i].y - this.mouseY!;
            const dist = Math.sqrt(diffX * diffX + diffY * diffY);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const incidence = Math.min(this.properties.mouseIncidence * this.speed!, this.properties.maxIncidence);
            if (dist < incidence) {
                // eslint-disable-next-line no-param-reassign
                path[i].xs = diffX * (incidence / 100);
                // eslint-disable-next-line no-param-reassign
                path[i].ys = diffY * (incidence / 100);
            }
        }

        let n2 = path.length - 1;

        for (let i = 0; i < path.length; i += 1) {
            const xd = path[n2].x - path[i].x;
            const yd = path[n2].y - path[i].y;
            const d = Math.sqrt(xd * xd + yd * yd);
            if (d > this.properties.maxDistance) {
                // eslint-disable-next-line no-param-reassign
                path[i].xs += this.properties.ent * (xd / d);
                // eslint-disable-next-line no-param-reassign
                path[i].ys += this.properties.ent * (yd / d);
                // eslint-disable-next-line no-param-reassign
                path[n2].xs -= this.properties.ent * (xd / d);
                // eslint-disable-next-line no-param-reassign
                path[n2].ys -= this.properties.ent * (yd / d);
            }
            n2 = i;
        }
    }

    drawPath(path: IJellyPathPoint[]): void {
        if (!this.ctx) return;

        this.ctx.beginPath();
        this.ctx.moveTo(path[0].x, path[0].y);

        let p0;
        let p1;
        for (let i = 0; i <= path.length; i += 1) {
            p0 = path[i >= path.length ? i - path.length : i];
            p1 = path[i + 1 >= path.length ? i + 1 - path.length : i + 1];
            this.ctx.quadraticCurveTo(p0.x, p0.y, (p0.x + p1.x) * 0.5, (p0.y + p1.y) * 0.5);
        }
        this.ctx.closePath();
    }

    drawPoints(path: IJellyPathPoint[]): void {
        for (let i = 0; i < path.length; i += 1) {
            this.drawPoint(path[i]);
        }
        if (this.properties.centroidPoint) this.drawPoint(this.properties.centroidPoint);
    }

    drawPoint(point: ICoord): void {
        if (!this.ctx) return;

        this.ctx.beginPath();
        this.ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI, false);
        this.ctx.closePath();
        this.ctx.fillStyle = 'red';
        this.ctx.fill();
    }

    getCentroid(): ICoord | undefined {
        let len = 0;
        let sumX = 0;
        let sumY = 0;
        let i;
        let j;
        let p;

        if (this.properties.pointsData) {
            for (i = 0; i < this.properties.pointsData.length; i += 1) {
                p = this.properties.pointsData[i];
                len += p.length;
                for (j = 0; j < p.length; j += 1) {
                    sumX += p[j].x;
                    sumY += p[j].y;
                }
            }

            return { x: sumX / len, y: sumY / len };
        }

        return undefined;
    }

    setDisableMouseEvents(disable: boolean): void {
        this.disableMouseEvents = disable;
    }
}
