// ===== Packages =====

import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px 0;
    box-shadow: ${({ theme }) => theme.color.boxShadow500};

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    & iframe {
        border-radius: 5px;
    }
`;
