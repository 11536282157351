// ===== Interfaces =====

import { ITwitterData } from '../interfaces';

// ===== Constants =====

const TWITTER_URL_REGEX = /(http|https):\/\/twitter.com\/([^/]*)(\/status\/([0-9]*))?/;

const getTwitterId = (url = ''): ITwitterData | boolean => {
    const match = url ? url.match(TWITTER_URL_REGEX) : null;
    let username: string | null = null;
    let id: string | null = null;

    if (match && match[2]) [, , username] = match;
    if (match && match[4] && match[4].length === 19) [, , , , id] = match;

    if (id || username) {
        return {
            username,
            id,
        };
    }

    return false;
};

export default getTwitterId;
