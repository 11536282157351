/* eslint-disable @typescript-eslint/no-empty-function */

// ===== Packages =====

import isHotkey, { KeyboardEventLike }  from 'is-hotkey';
import { isFunction }                   from 'lodash';

// ===== Interfaces ====
import { HotKeyDownOptions }            from '../interfaces';

/**
 A function that maps hotkey events with callbacks. Only pass callbacks
 for the hotkeys that one wants to listen and respond to
 */

const HandleOnKeyDown = (callbacks: HotKeyDownOptions = {}, options = { byKey: false }): (event: KeyboardEventLike) => void => {
    const { byKey } = options;

    let {
        tabCallback,
        shiftTabCallback,
        modBCallback,
        modICallback,
        modUCallback,
        modShiftSCallback,
        modKCallback,
        modACallback,
    } = callbacks;

    // NOTE: we aim to put as much of the computation as possible
    //  here so that the returned function has the least amount of
    //  computation to perform as possible

    // each callback has a default of a no-op function
    //  if the passed callback is not a function (or missing)
    tabCallback = isFunction(tabCallback) ? tabCallback : () => {};
    shiftTabCallback = isFunction(shiftTabCallback) ? shiftTabCallback : () => {};
    modBCallback = isFunction(modBCallback) ? modBCallback : () => {};
    modICallback = isFunction(modICallback) ? modICallback : () => {};
    modUCallback = isFunction(modUCallback) ? modUCallback : () => {};
    modShiftSCallback = isFunction(modShiftSCallback) ? modShiftSCallback : () => {};
    modKCallback = isFunction(modKCallback) ? modKCallback : () => {};
    modACallback = isFunction(modACallback) ? modACallback : () => {};

    const isTabHotKey = isHotkey('tab', { byKey });
    const isShiftTabHotKey = isHotkey('shift+tab', { byKey });
    const isModBHotKey = isHotkey('mod+b', { byKey });
    const isModIHotKey = isHotkey('mod+i', { byKey });
    const isModUHotKey = isHotkey('mod+u', { byKey });
    const isModShiftSHotKey = isHotkey('mod+shift+s', { byKey });
    const isModKHotKey = isHotkey('mod+k', { byKey });
    const isModAHotKey = isHotkey('mod+a', { byKey });

    return (event: KeyboardEventLike) => {
        if (isTabHotKey(event) && tabCallback) {
            return tabCallback(event);
        }
        if (isShiftTabHotKey(event) && shiftTabCallback) {
            return shiftTabCallback(event);
        }
        if (isModBHotKey(event) && modBCallback) {
            return modBCallback(event);
        }
        if (isModIHotKey(event) && modICallback) {
            return modICallback(event);
        }
        if (isModUHotKey(event) && modUCallback) {
            return modUCallback(event);
        }
        if (isModShiftSHotKey(event) && modShiftSCallback) {
            return modShiftSCallback(event);
        }
        if (isModKHotKey(event) && modKCallback) {
            return modKCallback(event);
        }
        if (isModAHotKey(event) && modACallback) {
            return modACallback(event);
        }
    };
};

export default HandleOnKeyDown;
