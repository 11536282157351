// ===== Interfaces =====

import {
    ICartItem,
}               from '../interfaces';

const isSameCart = (a: ICartItem[], b: ICartItem[]): boolean => {
    let sameCart = true;
    for (let i = 0; i < a.length; i += 1) {
        const aItem = a[i];
        const findIndex = b.findIndex((bItem) => (
            bItem.priceId === aItem.priceId
            && bItem.quantity === aItem.quantity
        ));
        if (findIndex === -1) {
            sameCart = false;
            break;
        }
    }

    return sameCart;
};

export default isSameCart;
