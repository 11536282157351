// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
}                                       from 'react';
import {
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';

// ===== Enums =====

import {
    CHECKOUT_CHECKPOINT,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    STORE_ITEM_TYPE,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    detectTouchDevice,
    updateUserInDB,
    playAudio,
    stripeGetStoreItems,
    roundToNDecimals,
    checkBookPurchases,
}                                       from '../../services';

// ===== Interfaces =====

import {
    ICartItem,
    IUserItem,
    IStoreItem,
    ISnackbarItem,
}                                       from '../../interfaces';

// ===== Images =====

import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';
import PixelCartIcon                    from '../../images/8-bit_cart.svg';
import PixelCrossIcon                   from '../../images/8-bit_cross.svg';
import PixelPlusIcon                    from '../../images/8-bit_plus.svg';
import PixelMinusIcon                   from '../../images/8-bit_minus.svg';
import BoxIcon                          from '../../images/box.svg';
import Spinner                          from '../../images/editor/spinner.svg';

// ===== Sounds =====

import Success                          from '../../sounds/message_success.mp3';
import Delete                           from '../../sounds/delete.mp3';
import ButtonClick                      from '../../sounds/button_click.mp3';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    DEFAULT_AUDIO_VOLUME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    CENTS_IN_A_DOLLAR,
    WEB_BOOK_STRIPE_PRICE_API_ID,
    WEB_BOOK_STRIPE_PRICE_TEST_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    ContentContainer,
    PopulatedCart,
    EmptyCart,
    EmptyCartIcon,
    EmptyCartTitle,
    CartItemsContainer,
    OrderSummaryContainer,
    Header,
    HeaderText,
    CartItemsCountText,
    EmptyCartButtonContainer,
    EmptyCartButton,
    CartItems,
    CartItemContainer,
    CartItemImageContainer,
    CartItemDetails,
    CartItemName,
    CartItemRemoveButton,
    CartItemQuantityContainer,
    CartItemQuantityText,
    CartItemQuantityButton,
    CartItemQuantityCount,
    CartItemQuantityPriceContainer,
    OrderSummary,
    OrderSummaryEntries,
    OrderSummaryEntry,
    OrderSummaryText,
    OrderSummaryPrice,
    CheckoutButton,
    BuyBookButton,
    CartItemPrice,
    CartItemImage,
    DiscountedCartItemPrice,
    CartItemPriceAfterDiscount,
    SpinnerContainer,
}                                       from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
    PlaceholderBox,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    hasSound: boolean,
    currentSessionId: string | null,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function CartView({
    user,
    hasSound,
    currentSessionId,
    setSnackbarData,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====

    // Snackbar Message when encountering error removing cart item
    const SNACKBAR_MESSAGE_REMOVE_CART_ITEM_ERROR = 'Encountered an error removing cart item. Please try again.';
    // Snackbar Message when encountering error incrementing cart item
    const SNACKBAR_MESSAGE_INCREMENT_CART_ITEM_ERROR = 'Encountered an error incrementing cart item quantity. Please try again.';
    // Snackbar Message when encountering error decrementing cart item
    const SNACKBAR_MESSAGE_DECREMENT_CART_ITEM_ERROR = 'Encountered an error decrementing cart item quantity. Please try again.';
    const CART_ITEM_NAME_PLACEHOLDER_WIDTH = 300;
    const CART_ITEM_NAME_PLACEHOLDER_HEIGHT = 20;
    const CART_ITEM_PRICE_PLACEHOLDER_WIDTH = 70;
    const CART_ITEM_PRICE_PLACEHOLDER_HEIGHT = 20;
    const ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH = 80;
    const ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT = 20;

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== Refs =====

    // ----- Sound Clips
    const successClip = useRef<HTMLAudioElement>(new Audio());
    const deleteClip = useRef<HTMLAudioElement>(new Audio());
    const buttonClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);
    // Stores a list of cart items
    const [storeItems, setStoreItems] = useState<Map<string, IStoreItem>>(new Map());
    // Indicates whether store items have been fetched
    const [fetchedStoreItems, setFetchedStoreItems] = useState<boolean>(false);
    // Stores store item information of web book
    const [webBookStoreItem, setWebBookStoreItem] = useState<IStoreItem | undefined>(undefined);
    // Stores store item information of digital book
    const [digitalBookStoreItem, setDigitalBookStoreItem] = useState<IStoreItem | undefined>(undefined);
    // Indicates whether user has bought a physical book
    const [purchasedPhysicalBookSessionIds, setPurchasedPhysicalBookSessionIds] = useState<string[]>([]);
    // Indicates whether user has bought a digital book
    const [purchasedDigitalBookSessionIds, setPurchasedDigitalBookSessionIds] = useState<string[]>([]);
    // Indicates whether checked for book purchases
    const [checkedBookPurchases, setCheckedBookPurchases] = useState<boolean>(false);

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.cart,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    /**
     * Records user action of viewing cart page
     */
    const recordCartPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewCartPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    /**
     * Fetch stripe product prices
     */
    const fetchStoreItems = async (): Promise<void> => {
        const items = await stripeGetStoreItems({
            test: process.env.NODE_ENV === 'development',
        });
        if (items) {
            setStoreItems(items);
            setWebBookStoreItem(items.get(WEB_BOOK_PRICE_ID));
            setDigitalBookStoreItem(items.get(DIGITAL_BOOK_PRICE_ID));
            setFetchedStoreItems(true);
        }
    };

    const determineBookPurchases = async (): Promise<void> => {
        if (!checkedBookPurchases) setCheckedBookPurchases(true);
        if (user) {
            const purchases = await checkBookPurchases(user);
            if (purchases) {
                setPurchasedPhysicalBookSessionIds(purchases.physical);
                setPurchasedDigitalBookSessionIds(purchases.digital);
            }
        }
    };

    const onBuyBookButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.cartBuyBookButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onBuyBookButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onBuyBookButtonClick = (e: React.MouseEvent): void => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewReadPage,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.cart,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.book}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const onClearCartButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.clearCartButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onClearCartButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onEmptyCartButtonClick = (): void => {
        if (user) {
            updateUserInDB({
                userId: user.id,
                cart: [],
            });
            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.emptyCart,
                    userId: user.id,
                    sessionId: currentSessionId,
                });
            }
            // Play Sound
            if (hasSound && deleteClip.current) {
                deleteClip.current.pause();
                deleteClip.current.currentTime = 0;
                playAudio(deleteClip.current);
            }
        }
    };

    const onCartItemQuantityButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.cartItemQuantityButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCartItemQuantityButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onCartItemRemoveButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.cartItemRemoveButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCartItemRemoveButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onCartItemIncrementQuantityButtonClick = (priceId: string): void => {
        if (
            user
            && user.cart
        ) {
            const newCart: ICartItem[] = [];
            user.cart.forEach((item: ICartItem) => {
                if (item.priceId === priceId) {
                    newCart.push({
                        ...item,
                        quantity: item.quantity + 1,
                        updated: Date.now(),
                    });
                } else {
                    newCart.push(item);
                }
            });
            updateUserInDB({
                userId: user.id,
                cart: newCart,
            });
            if (user && currentSessionId) {
                let type: STORE_ITEM_TYPE | undefined;
                switch (priceId) {
                case WEB_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.webBook;
                    break;
                case DIGITAL_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.digitalBook;
                    break;
                case PHYSICAL_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.physicalBook;
                    break;
                default:
                    // Shouldn't reach here
                }
                // Record user action
                if (type) {
                    recordUserAction({
                        type: USER_ACTION_TYPE.incrementItemQuantity,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            type,
                        },
                    });
                } else {
                    recordUserAction({
                        type: USER_ACTION_TYPE.incrementItemQuantity,
                        userId: user.id,
                        sessionId: currentSessionId,
                    });
                }
            }
            // Play Sound
            if (hasSound && successClip.current) {
                successClip.current.pause();
                successClip.current.currentTime = 0;
                playAudio(successClip.current);
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_INCREMENT_CART_ITEM_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });
        }
    };

    const onCartItemDecrementQuantityButtonClick = (priceId: string): void => {
        if (
            user
            && user.cart
        ) {
            const newCart: ICartItem[] = [];
            let removeItem = false;
            user.cart.forEach((item: ICartItem) => {
                if (
                    item.priceId === priceId
                    && item.quantity > 1
                ) {
                    newCart.push({
                        ...item,
                        quantity: item.quantity - 1,
                        updated: Date.now(),
                    });
                } else if (
                    item.priceId === priceId
                    && item.quantity === 1
                ) {
                    // Do noting to remove from cart
                    removeItem = true;
                } else {
                    newCart.push(item);
                }
            });
            updateUserInDB({
                userId: user.id,
                cart: newCart,
            });
            if (user && currentSessionId) {
                let type: STORE_ITEM_TYPE | undefined;
                switch (priceId) {
                case WEB_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.webBook;
                    break;
                case DIGITAL_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.digitalBook;
                    break;
                case PHYSICAL_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.physicalBook;
                    break;
                default:
                    // Shouldn't reach here
                }
                // Record user action
                if (type) {
                    recordUserAction({
                        type: removeItem
                            ? USER_ACTION_TYPE.removeItemFromCart
                            : USER_ACTION_TYPE.decrementItemQuantity,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            type,
                        },
                    });
                } else {
                    recordUserAction({
                        type: removeItem
                            ? USER_ACTION_TYPE.removeItemFromCart
                            : USER_ACTION_TYPE.decrementItemQuantity,
                        userId: user.id,
                        sessionId: currentSessionId,
                    });
                }
            }
            // Play Sound
            if (hasSound && deleteClip.current) {
                deleteClip.current.pause();
                deleteClip.current.currentTime = 0;
                playAudio(deleteClip.current);
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_DECREMENT_CART_ITEM_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });
        }
    };

    const onCartItemRemoveButtonClick = (priceId: string, e: React.MouseEvent | React.TouchEvent): void => {
        if (
            user
            && user.cart
        ) {
            const cartDB = user.cart.filter((item: ICartItem) => item.priceId !== priceId);
            updateUserInDB({
                userId: user.id,
                cart: cartDB,
            });
            if (user && currentSessionId) {
                let type: STORE_ITEM_TYPE | undefined;
                switch (priceId) {
                case WEB_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.webBook;
                    break;
                case DIGITAL_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.digitalBook;
                    break;
                case PHYSICAL_BOOK_PRICE_ID:
                    type = STORE_ITEM_TYPE.physicalBook;
                    break;
                default:
                    // Shouldn't reach here
                }
                // Record user action
                if (type) {
                    recordUserAction({
                        type: USER_ACTION_TYPE.removeItemFromCart,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            type,
                        },
                    });
                } else {
                    recordUserAction({
                        type: USER_ACTION_TYPE.removeItemFromCart,
                        userId: user.id,
                        sessionId: currentSessionId,
                    });
                }
            }
            // Play Sound
            if (hasSound && deleteClip.current) {
                deleteClip.current.pause();
                deleteClip.current.currentTime = 0;
                playAudio(deleteClip.current);
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_REMOVE_CART_ITEM_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });
        }
        onCursorLeave(e);
    };

    const onCheckoutButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.checkoutActionButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCheckoutButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onCheckoutButtonClick = (e: React.MouseEvent): void => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewCheckoutPage,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.cart,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.info}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'Cart',
        );
    }, []);

    /**
     * Records Cart Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordCartPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            successClip.current
            && deleteClip.current
            && buttonClickClip.current
        ) {
            // Success
            successClip.current.volume = DEFAULT_AUDIO_VOLUME;
            successClip.current.src = Success;

            // Delete
            deleteClip.current.volume = DEFAULT_AUDIO_VOLUME;
            deleteClip.current.src = Delete;

            // Button Click
            buttonClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            buttonClickClip.current.src = ButtonClick;
        }

        return function cleanup() {
            if (successClip.current) successClip.current.remove();
            if (deleteClip.current) deleteClip.current.remove();
            if (buttonClickClip.current) buttonClickClip.current.remove();
        };
    }, []);

    /**
     * Fetches and saves strore items
     */
    useEffect(() => {
        fetchStoreItems();
    }, []);

    /**
     * Manages checking whether user has bought a book in the past
     */
    useEffect(() => {
        if (
            user
            && !checkedBookPurchases
        ) {
            determineBookPurchases();
        }
    }, [user]);

    // ===== Memoization =====

    const PHYSICAL_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID
            : PHYSICAL_BOOK_STRIPE_PRICE_API_ID
    ), []);
    const DIGITAL_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID
            : DIGITAL_BOOK_STRIPE_PRICE_API_ID
    ), []);
    const WEB_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? WEB_BOOK_STRIPE_PRICE_TEST_API_ID
            : WEB_BOOK_STRIPE_PRICE_API_ID
    ), []);

    const numCartItems = useMemo(() => {
        let count = 0;
        if (
            user
            && user.cart
            && user.cart.length > 0
        ) {
            user.cart.forEach((item: ICartItem) => {
                count += item.quantity;
            });
            if (
                user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                && purchasedPhysicalBookSessionIds.length === 0
                && purchasedDigitalBookSessionIds.length === 0
            ) {
                // Add one for complimentary digital book
                count += 1;
            }
            if (
                user.cart.find((item: ICartItem) => (
                    item.priceId === PHYSICAL_BOOK_PRICE_ID
                    || item.priceId === DIGITAL_BOOK_PRICE_ID
                ))
                && (
                    (
                        !user.bookAccess
                        || !user.bookAccess.granted
                    )
                )
            ) {
                // Add one for complimentary web book
                count += 1;
            }
        }
        return count;
    }, [
        user?.cart,
        purchasedPhysicalBookSessionIds,
        purchasedDigitalBookSessionIds,
    ]);

    const subtotal = useMemo(() => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && fetchedStoreItems
        ) {
            return user.cart.reduce((
                total: number,
                item: ICartItem,
            ) => {
                const storeItem = storeItems.get(item.priceId);
                if (
                    storeItem
                    && storeItem.amount
                ) {
                    return total + (item.quantity * storeItem.amount);
                }

                return total;
            }, 0);
        }
    }, [
        user?.cart,
        fetchedStoreItems,
    ]);

    return (
        <Container>
            <PageLogo
                dark
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
                color={theme.verascopeColor.purple200}
            >
                CART
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={theme.verascopeColor.purple400}
            >
                Your haul.
            </PageSubtitle>
            <ContentContainer>
                {user?.cart && user.cart.length > 0
                    ? (
                        <PopulatedCart>
                            <CartItemsContainer>
                                <Header>
                                    <HeaderText>
                                        {fetchedStoreItems ? (
                                            <>
                                                {`Item${numCartItems !== 1 ? 's' : ''}`}
                                                {user && user.cart && (
                                                    <CartItemsCountText>
                                                        {numCartItems}
                                                    </CartItemsCountText>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                Items
                                            </>
                                        )}
                                    </HeaderText>
                                    {fetchedStoreItems && (
                                        <EmptyCartButtonContainer>
                                            <EmptyCartButton
                                                className={HOVER_TARGET_CLASSNAME}
                                                detectTouchDevice={detectTouchDevice(document)}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchStart: (e) => onClearCartButtonEnter(e),
                                                } : {
                                                    onMouseEnter: (e) => onClearCartButtonEnter(e),
                                                })}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchEnd: (e) => onClearCartButtonLeave(e),
                                                } : {
                                                    onMouseLeave: (e) => onClearCartButtonLeave(e),
                                                })}
                                                onMouseDown={onEmptyCartButtonClick}
                                            >
                                                Empty
                                            </EmptyCartButton>
                                        </EmptyCartButtonContainer>
                                    )}
                                </Header>
                                <CartItems>
                                    {fetchedStoreItems && user && user.cart
                                        ? (
                                            user.cart.map((cartItem: ICartItem) => {
                                                const storeItem = storeItems.get(cartItem.priceId)!;
                                                return (
                                                    <CartItemContainer>
                                                        <CartItemImageContainer
                                                            hasImage={storeItem.imageURLs.length > 0}
                                                        >
                                                            {storeItem.imageURLs.length > 0
                                                                ? (
                                                                    <CartItemImage
                                                                        url={storeItem.imageURLs[0]}
                                                                    />
                                                                ) : (
                                                                    <ReactSVG
                                                                        src={BoxIcon}
                                                                    />
                                                                )}
                                                        </CartItemImageContainer>
                                                        <CartItemDetails>
                                                            <CartItemName>
                                                                {storeItem.name}
                                                            </CartItemName>
                                                            <CartItemPrice>
                                                                {`$${storeItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                            </CartItemPrice>
                                                            <CartItemQuantityContainer>
                                                                <CartItemQuantityText>
                                                                    Quantity
                                                                </CartItemQuantityText>
                                                                {storeItem.physical && (
                                                                    <CartItemQuantityButton
                                                                        isDisabled={false}
                                                                        className={HOVER_TARGET_CLASSNAME}
                                                                        detectTouchDevice={detectTouchDevice(document)}
                                                                        {...(detectTouchDevice(document) ? {
                                                                            onTouchStart: (e) => onCartItemQuantityButtonEnter(e),
                                                                        } : {
                                                                            onMouseEnter: (e) => onCartItemQuantityButtonEnter(e),
                                                                        })}
                                                                        {...(detectTouchDevice(document) ? {
                                                                            onTouchEnd: (e) => onCartItemQuantityButtonLeave(e),
                                                                        } : {
                                                                            onMouseLeave: (e) => onCartItemQuantityButtonLeave(e),
                                                                        })}
                                                                        onMouseDown={() => onCartItemDecrementQuantityButtonClick(cartItem.priceId)}
                                                                    >
                                                                        <ReactSVG
                                                                            src={PixelMinusIcon}
                                                                        />
                                                                    </CartItemQuantityButton>
                                                                )}
                                                                <CartItemQuantityCount
                                                                    nonInteractable={!storeItem.physical}
                                                                >
                                                                    {cartItem.quantity}
                                                                </CartItemQuantityCount>
                                                                {storeItem.physical && (
                                                                    <CartItemQuantityButton
                                                                        isDisabled={false}
                                                                        className={HOVER_TARGET_CLASSNAME}
                                                                        detectTouchDevice={detectTouchDevice(document)}
                                                                        {...(detectTouchDevice(document) ? {
                                                                            onTouchStart: (e) => onCartItemQuantityButtonEnter(e),
                                                                        } : {
                                                                            onMouseEnter: (e) => onCartItemQuantityButtonEnter(e),
                                                                        })}
                                                                        {...(detectTouchDevice(document) ? {
                                                                            onTouchEnd: (e) => onCartItemQuantityButtonLeave(e),
                                                                        } : {
                                                                            onMouseLeave: (e) => onCartItemQuantityButtonLeave(e),
                                                                        })}
                                                                        onMouseDown={() => onCartItemIncrementQuantityButtonClick(cartItem.priceId)}
                                                                    >
                                                                        <ReactSVG
                                                                            src={PixelPlusIcon}
                                                                        />
                                                                    </CartItemQuantityButton>
                                                                )}
                                                                <CartItemQuantityPriceContainer>
                                                                    {`$${roundToNDecimals(cartItem.quantity * (storeItem.amount! / CENTS_IN_A_DOLLAR), 2)}`}
                                                                </CartItemQuantityPriceContainer>
                                                            </CartItemQuantityContainer>
                                                            <CartItemRemoveButton
                                                                className={HOVER_TARGET_CLASSNAME}
                                                                detectTouchDevice={detectTouchDevice(document)}
                                                                {...(detectTouchDevice(document) ? {
                                                                    onTouchStart: (e) => onCartItemRemoveButtonEnter(e),
                                                                } : {
                                                                    onMouseEnter: (e) => onCartItemRemoveButtonEnter(e),
                                                                })}
                                                                {...(detectTouchDevice(document) ? {
                                                                    onTouchEnd: (e) => onCartItemRemoveButtonLeave(e),
                                                                } : {
                                                                    onMouseLeave: (e) => onCartItemRemoveButtonLeave(e),
                                                                })}
                                                                onMouseDown={(e) => onCartItemRemoveButtonClick(cartItem.priceId, e)}
                                                            >
                                                                <ReactSVG
                                                                    src={PixelCrossIcon}
                                                                />
                                                            </CartItemRemoveButton>
                                                        </CartItemDetails>
                                                    </CartItemContainer>
                                                );
                                            })
                                        )
                                        : (
                                            <>
                                                <CartItemContainer>
                                                    <CartItemImageContainer
                                                        hasImage={false}
                                                    >
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    </CartItemImageContainer>
                                                    <CartItemDetails>
                                                        <CartItemName>
                                                            <PlaceholderBox
                                                                width={CART_ITEM_NAME_PLACEHOLDER_WIDTH}
                                                                height={CART_ITEM_NAME_PLACEHOLDER_HEIGHT}
                                                            />
                                                        </CartItemName>
                                                        <CartItemPrice>
                                                            <PlaceholderBox
                                                                width={CART_ITEM_PRICE_PLACEHOLDER_WIDTH}
                                                                height={CART_ITEM_PRICE_PLACEHOLDER_HEIGHT}
                                                            />
                                                        </CartItemPrice>
                                                    </CartItemDetails>
                                                </CartItemContainer>
                                                <CartItemContainer>
                                                    <CartItemImageContainer
                                                        hasImage={false}
                                                    >
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    </CartItemImageContainer>
                                                    <CartItemDetails>
                                                        <CartItemName>
                                                            <PlaceholderBox
                                                                width={CART_ITEM_NAME_PLACEHOLDER_WIDTH}
                                                                height={CART_ITEM_NAME_PLACEHOLDER_HEIGHT}
                                                            />
                                                        </CartItemName>
                                                        <CartItemPrice>
                                                            <PlaceholderBox
                                                                width={CART_ITEM_PRICE_PLACEHOLDER_WIDTH}
                                                                height={CART_ITEM_PRICE_PLACEHOLDER_HEIGHT}
                                                            />
                                                        </CartItemPrice>
                                                    </CartItemDetails>
                                                </CartItemContainer>
                                                <CartItemContainer>
                                                    <CartItemImageContainer
                                                        hasImage={false}
                                                    >
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    </CartItemImageContainer>
                                                    <CartItemDetails>
                                                        <CartItemName>
                                                            <PlaceholderBox
                                                                width={CART_ITEM_NAME_PLACEHOLDER_WIDTH}
                                                                height={CART_ITEM_NAME_PLACEHOLDER_HEIGHT}
                                                            />
                                                        </CartItemName>
                                                        <CartItemPrice>
                                                            <PlaceholderBox
                                                                width={CART_ITEM_PRICE_PLACEHOLDER_WIDTH}
                                                                height={CART_ITEM_PRICE_PLACEHOLDER_HEIGHT}
                                                            />
                                                        </CartItemPrice>
                                                    </CartItemDetails>
                                                </CartItemContainer>
                                            </>
                                        )}
                                    {!!user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                                    && digitalBookStoreItem
                                    && purchasedPhysicalBookSessionIds.length === 0
                                    && purchasedDigitalBookSessionIds.length === 0
                                    && (
                                        <CartItemContainer>
                                            <CartItemImageContainer
                                                hasImage={digitalBookStoreItem.imageURLs.length > 0}
                                            >
                                                {digitalBookStoreItem.imageURLs.length > 0
                                                    ? (
                                                        <CartItemImage
                                                            url={digitalBookStoreItem.imageURLs[0]}
                                                        />
                                                    ) : (
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    )}
                                            </CartItemImageContainer>
                                            <CartItemDetails>
                                                <CartItemName>
                                                    {digitalBookStoreItem.name}
                                                </CartItemName>
                                                <CartItemPrice>
                                                    <DiscountedCartItemPrice>
                                                        {`$${digitalBookStoreItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                    </DiscountedCartItemPrice>
                                                    <CartItemPriceAfterDiscount>
                                                        {' $0.00'}
                                                    </CartItemPriceAfterDiscount>
                                                </CartItemPrice>
                                                <CartItemQuantityContainer>
                                                    <CartItemQuantityText>
                                                        Quantity
                                                    </CartItemQuantityText>
                                                    <CartItemQuantityCount
                                                        nonInteractable
                                                    >
                                                        1
                                                    </CartItemQuantityCount>
                                                    <CartItemQuantityPriceContainer>
                                                        {' $0.00'}
                                                    </CartItemQuantityPriceContainer>
                                                </CartItemQuantityContainer>
                                            </CartItemDetails>
                                        </CartItemContainer>
                                    )}
                                    {!!user.cart.find(
                                        (item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID
                                            || item.priceId === DIGITAL_BOOK_PRICE_ID,
                                    )
                                    && webBookStoreItem
                                    && (
                                        !user.bookAccess
                                        || !user.bookAccess.granted
                                    ) && (
                                        <CartItemContainer>
                                            <CartItemImageContainer
                                                hasImage={webBookStoreItem.imageURLs.length > 0}
                                            >
                                                {webBookStoreItem.imageURLs.length > 0
                                                    ? (
                                                        <CartItemImage
                                                            url={webBookStoreItem.imageURLs[0]}
                                                        />
                                                    ) : (
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    )}
                                            </CartItemImageContainer>
                                            <CartItemDetails>
                                                <CartItemName>
                                                    {webBookStoreItem.name}
                                                </CartItemName>
                                                <CartItemPrice>
                                                    <DiscountedCartItemPrice>
                                                        {`$${webBookStoreItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                    </DiscountedCartItemPrice>
                                                    <CartItemPriceAfterDiscount>
                                                        {' $0.00'}
                                                    </CartItemPriceAfterDiscount>
                                                </CartItemPrice>
                                                <CartItemQuantityContainer>
                                                    <CartItemQuantityText>
                                                        Quantity
                                                    </CartItemQuantityText>
                                                    <CartItemQuantityCount
                                                        nonInteractable
                                                    >
                                                        1
                                                    </CartItemQuantityCount>
                                                    <CartItemQuantityPriceContainer>
                                                        {' $0.00'}
                                                    </CartItemQuantityPriceContainer>
                                                </CartItemQuantityContainer>
                                            </CartItemDetails>
                                        </CartItemContainer>
                                    )}
                                </CartItems>
                            </CartItemsContainer>
                            <OrderSummaryContainer>
                                <Header>
                                    <HeaderText>
                                        Order Summary
                                    </HeaderText>
                                </Header>
                                <OrderSummary>
                                    <OrderSummaryEntries>
                                        <OrderSummaryEntry>
                                            <OrderSummaryText>
                                                Cost
                                            </OrderSummaryText>
                                            <OrderSummaryPrice>
                                                {fetchedStoreItems
                                                && subtotal
                                                    ? `$${subtotal / CENTS_IN_A_DOLLAR}`
                                                    : (
                                                        <PlaceholderBox
                                                            width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                                            height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                                        />
                                                    )}
                                            </OrderSummaryPrice>
                                        </OrderSummaryEntry>
                                        <OrderSummaryEntry>
                                            <OrderSummaryText>
                                                Shipping
                                            </OrderSummaryText>
                                            <OrderSummaryPrice>
                                                {fetchedStoreItems
                                                    ? 'TBD'
                                                    : (
                                                        <PlaceholderBox
                                                            width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                                            height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                                        />
                                                    )}
                                            </OrderSummaryPrice>
                                        </OrderSummaryEntry>
                                        <OrderSummaryEntry
                                            divider
                                        >
                                            <OrderSummaryText>
                                                Subtotal
                                            </OrderSummaryText>
                                            <OrderSummaryPrice>
                                                {fetchedStoreItems
                                                && subtotal
                                                    ? `$${subtotal / CENTS_IN_A_DOLLAR}`
                                                    : (
                                                        <PlaceholderBox
                                                            width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                                            height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                                        />
                                                    )}
                                            </OrderSummaryPrice>
                                        </OrderSummaryEntry>
                                        <CheckoutButton
                                            className={HOVER_TARGET_CLASSNAME}
                                            detectTouchDevice={detectTouchDevice(document)}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: (e) => onCheckoutButtonEnter(e),
                                            } : {
                                                onMouseEnter: (e) => onCheckoutButtonEnter(e),
                                            })}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchEnd: (e) => onCheckoutButtonLeave(e),
                                            } : {
                                                onMouseLeave: (e) => onCheckoutButtonLeave(e),
                                            })}
                                            {...(fetchedStoreItems ? {
                                                onMouseDown: onCheckoutButtonClick,
                                            } : {})}
                                        >
                                            {!fetchedStoreItems
                                                ? (
                                                    <SpinnerContainer
                                                        src={Spinner}
                                                        alt="spinner"
                                                    />
                                                )
                                                : 'Checkout'}
                                        </CheckoutButton>
                                    </OrderSummaryEntries>
                                </OrderSummary>
                            </OrderSummaryContainer>
                        </PopulatedCart>
                    ) : (
                        <EmptyCart>
                            <EmptyCartIcon>
                                <ReactSVG
                                    src={PixelCartIcon}
                                />
                            </EmptyCartIcon>
                            <EmptyCartTitle>
                                Your cart is empty.
                            </EmptyCartTitle>
                            {user?.admin && (
                                <BuyBookButton
                                    className={HOVER_TARGET_CLASSNAME}
                                    detectTouchDevice={detectTouchDevice(document)}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => onBuyBookButtonEnter(e),
                                    } : {
                                        onMouseEnter: (e) => onBuyBookButtonEnter(e),
                                    })}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchEnd: (e) => onBuyBookButtonLeave(e),
                                    } : {
                                        onMouseLeave: (e) => onBuyBookButtonLeave(e),
                                    })}
                                    onMouseDown={onBuyBookButtonClick}
                                >
                                    Buy Book
                                </BuyBookButton>
                            )}
                        </EmptyCart>
                    )}
            </ContentContainer>
        </Container>
    );
}

export default CartView;
