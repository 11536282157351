// ===== Interfaces =====

import { IRgb } from '../interfaces';

const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (rgb: IRgb): string => `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;

export default rgbToHex;
