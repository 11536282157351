// Full list of errors: // https://firebase.google.com/docs/auth/admin/errors

export enum AUTHENTICATION_ERROR_CODE {
    // The claims payload provided exceeds the maximum allowed size of 1000 bytes.
    claimsTooLarge = 'auth/claims-too-large',
    // The provided email is already in use by an existing user.
    emailAlreadyExists = 'auth/email-already-exists',
    // The provided email is already in use by an existing user.
    emailAlreadyInUse = 'auth/email-already-in-use',
    // The provided session token is expired.
    tokenExpired = 'auth/id-token-expired',
    // The session token has been revoked.
    tokenRevoked = 'auth/id-token-revoked',
    // The credential has insufficient permission to access the requested Authentication resource.
    insufficientPermission = 'auth/insufficient-permission',
    // The Authentication server encountered an unexpected error while trying to process the request.
    serverError = 'auth/internal-error',
    // An invalid argument was provided to an Authentication method.
    invalidArgument = 'auth/invalid-argument',
    // The custom claim attributes provided are invalid.
    invlaidClaims = 'auth/invalid-claims',
    // The continue URL must be a valid URL string.
    invalidContinueURL = 'auth/invalid-continue-uri',
    // The creation time must be a valid date.
    invalidCreationTime = 'auth/invalid-creation-time',
    // The credential used to authenticate cannot be used to perform the desired action.
    invalidCredential = 'auth/invalid-credential',
    // The provided value for the disabled user property is invalid.
    invalidDisabledField = 'auth/invalid-disabled-field',
    // The provided value for the displayName user property is invalid.
    invalidDisplayName = 'auth/invalid-display-name',
    // The provided dynamic link domain is not configured or authorized.
    invlaidDynamicLinkDomain = 'auth/invalid-dynamic-link-domain',
    // The provided value for the email user property is invalid.
    invalidEmail = 'auth/invalid-email',
    // The provided value for the emailVerified user property is invalid.
    invalidEmailVerified = 'auth/invalid-email-verified',
    // The hash algorithm must match one of the strings in the list of supported algorithms.
    invalidHashAlgorithm = 'auth/invalid-hash-algorithm',
    // The hash block size must be a valid number.
    invalidHashBlockSize = 'auth/invalid-hash-block-size',
    // The hash derived key length must be a valid number.
    invalidHashDerivedKeyLength = 'auth/invalid-hash-derived-key-length',
    // The hash key must a valid byte buffer.
    invalidHashKey = 'auth/invalid-hash-key',
    // The hash memory cost must be a valid number.
    ivalidHashMemoryCost = 'auth/invalid-hash-memory-cost',
    // The hash parallelization must be a valid number.
    invalidHashParallelization = 'auth/invalid-hash-parallelization',
    // The hash rounds must be a valid number.
    invalidHashRounds = 'auth/invalid-hash-rounds',
    // The hashing algorithm salt separator field must be a valid byte buffer.
    invalidHashSaltSeparator = 'auth/invalid-hash-salt-separator',
    // The provided token is not valid.
    invalidIdToken = 'auth/invalid-id-token',
    // The last sign-in time must be a valid date.
    invalidLastSignInTime = 'auth/invalid-last-sign-in-time',
    // The provided next page token is invalid.
    invalidPageToken = 'auth/invalid-page-token',
    // The provided value for the password is invalid. It must be a string with at least six characters.
    invalidPassword = 'auth/invalid-password',
    // The provided value for the password is incorrect.
    wrongPassword = 'auth/wrong-password',
    // The password hash must be a valid byte buffer.
    invalidPasswordHash = 'auth/invalid-password-hash',
    // The password salt must be a valid byte buffer
    invalidPasswordSalt = 'auth/invalid-password-salt',
    // The provided value for the phoneNumber is invalid.
    invalidPhoneNumber = 'auth/invalid-phone-number',
    // The provided value for the photoURL user property is invalid.
    invalidPhotoURL = 'auth/invalid-photo-url',
    // The providerData must be a valid array of UserInfo objects.
    invalidProviderData = 'auth/invalid-provider-data',
    // The providerId must be a valid supported provider identifier string.
    invalidProviderId = 'auth/invalid-provider-id',
    // Only exactly one OAuth responseType should be true.
    invalidOauthResponseType = 'auth/invalid-oauth-responsetype',
    // The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.
    invalidSessionCookieDuration = 'auth/invalid-session-cookie-duration',
    // The provided uid must be a non-empty string with at most 128 characters.
    invalidUID = 'auth/invalid-uid',
    // The user record to import is invalid.
    invaliduserImport = 'auth/invalid-user-import',
    // The maximum allowed number of users to import has been exceeded.
    maxUserCountExceeded = 'auth/maximum-user-count-exceeded',
    // An Android Package Name must be provided if the Android App is required to be installed.
    missingAndroidPackageName = 'auth/missing-android-pkg-name',
    // A valid continue URL must be provided in the request.
    missingContinueURL = 'auth/missing-continue-uri',
    // Importing users with password hashes requires that the hashing algorithm and its parameters be provided.
    missinghasAlgorithm = 'auth/missing-hash-algorithm',
    // The request is missing a Bundle ID.
    missingiOSBundleId = 'auth/missing-ios-bundle-id',
    // A uid identifier is required for the current operation.
    missingUID = 'auth/missing-uid',
    // The OAuth configuration client secret is required to enable OIDC code flow.
    missingOauthClientSecret = 'auth/missing-oauth-client-secret',
    // The provided sign-in provider is disabled for your Firebase project.
    operationotAllowed = 'auth/operation-not-allowed',
    // The provided phone number is already in use by an existing user.
    numberAlreadyExists = 'auth/phone-number-already-exists',
    // No project was found for the credential used to initialize the development kit.
    projectNotFound = 'auth/project-not-found',
    // One or more custom user claims provided are reserved.
    reservedClaims = 'auth/reserved-claims',
    // The provided session cookie is expired.
    sessionCookieExpired = 'auth/session-cookie-expired',
    // The session cookie has been revoked.
    sessionCookieRevoked = 'auth/session-cookie-revoked',
    // The provided uid is already in use by an existing user.
    uidAlreadyExists = 'auth/uid-already-exists',
    // The domain of the continue URL is not whitelisted. Whitelist the domain.
    unauthorizedContinueURL = 'auth/unauthorized-continue-uri',
    // There is no existing user record corresponding to the provided identifier.
    userNotFound = 'auth/user-not-found',
    // Authentication request was denied
    authenticationRequestDenied = 'auth/popup-closed-by-user',
    // Website domain not authorized to handle authentication
    unauthorizedDomain = 'auth/unauthorized-domain',
    // Authentication popup blocked by browser
    popupBlocked = 'auth/popup-blocked',
}
