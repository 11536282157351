// ===== Services =====

import { binarySearchIndex } from '.';

function binarySearch<T>(
    arr: T[],
    isLower: (val: T) => boolean,
    isHigher: (val: T) => boolean,
): T | null {
    const index = binarySearchIndex(arr, isLower, isHigher);
    if (index) {
        return arr[index];
    }
    return null;
}

export default binarySearch;
