const ID_TO_TYPE = new Map<string, string>([
    ['005fd78b-3593-466f-804e-85a4589c0f65', 'video-documentary'],
    ['78a0530f-04e4-4ec5-88d2-759fbdd8171d', 'text-interview'],
    ['819edb29-918d-468a-ab5a-c0281dc85db5', 'advertisement'],
    ['8a2e6f7a-c234-48a6-be50-f479aa34064d', 'audio-interview'],
    ['8a97512c-9c27-4348-9124-9ff5206aa08d', 'academic-paper'],
    ['8b5abbb7-bf1d-494f-959b-a42cd69e897f', 'book'],
    ['ab578543-f09d-40fd-8925-b949549ba6cc', 'film'],
    ['b705f5e4-edae-4005-a8fc-c0905b902c68', 'video-game'],
    ['c51289c3-911f-4f91-b0d1-67b8634e6e9a', 'essay'],
    ['cdae540d-5782-45a9-901c-2b194d7d3396', 'video-interview'],
    ['e18eb67c-e7a7-444b-97f1-1d4001b0b371', 'video-talk'],
]);

export default ID_TO_TYPE;
