// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
}                                               from 'react';
import styled                                   from 'styled-components';
import { useDropzone }                          from 'react-dropzone';
import { Transition }                           from 'react-transition-group';
import { useSelected }                          from 'slate-react';
import {
    StorageError,
    getDownloadURL,
    UploadTaskSnapshot,
    StorageReference,
}                                               from 'firebase/storage';
import ShortUniqueId                            from 'short-unique-id';
import { v4 as uuidv4 }                         from 'uuid';
import mime                                     from 'mime-types';

// ===== Components =====

import { DropzoneInput }                        from '../../../styles';
import { Button }                               from '../helpers';

// ===== Hooks =====

import { useTimeout }                           from '../../../hooks';

// ===== Services =====

import {
    uploadToCloudStorage,
    setMediaInDB,
    getMediaStorageBucket,
    getStorageErrorMessage,
}                                               from '../../../services';

// ===== Interfaces =====

import {
    IMediaItem,
    IUserItem,
}                                               from '../../../interfaces';

// ===== Enums =====
import {
    BUTTON_TYPE,
    TOOLTIP_TYPE,
    EDITOR_FIGURE_FOCUS_EVENT_TYPE,
    EDITOR_DROPZONE_SIDE_TYPE,
    MEDIA_TYPE,
    STORAGE_ENTITY,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    EDITOR_CONTEXT_TYPE,
    STORAGE_ERROR_CODE,
}                                               from '../../../enums';
import { FIGURE_ORIENTATION }                   from '../elements/figureElement/enums';

// ===== Images =====

import ImageAddIcon                             from '../../../images/editor/image-add.svg';

// ===== Constants =====

import {
    EDITOR_FIGURE_MARGIN,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    EDITOR_FIGURE_IMAGE_CONTAINER_CLASSNAME,
    EDITOR_CAPTION_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
    UPLOADING_MEDIA_DESTINATION_ID,
}                                               from '../../../constants/generalConstants';
import CURSOR_SIGN                              from '../../../constants/cursorSigns';

// ===== Styles =====

import { theme as themeObj }                    from '../../../themes/theme-context';

Figure.defaultProps = {
    enableGallery: false,
    parentRef: null,
    editorRef: null,
    readOnly: false,
};
interface Props {
    url: string,
    user: IUserItem | null,
    filePath: string,
    readOnly?: boolean,
    layoutType: FIGURE_ORIENTATION,
    editorType: EDITOR_CONTEXT_TYPE,
    isGalleryChild: boolean,
    rerender: boolean,
    setRerender: React.Dispatch<React.SetStateAction<boolean>>,
    createGallery: (id: string, url?: string, filePath?: string) => void,
    enableGallery?: boolean,
    parentRef?: HTMLElement | null,
    editorRef?: HTMLElement | null,
    uploadingMedia: Map<string, IMediaItem>,
    setUploadingMedia: (mediaItem: IMediaItem) => void,
    updateUploadingMedia: (mediaItem: IMediaItem) => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    children: React.ReactElement,
    attributes: any,
}
function Figure({
    url,
    user,
    filePath,
    readOnly,
    layoutType,
    editorType,
    children,
    attributes,
    isGalleryChild,
    createGallery,
    rerender = false,
    setRerender,
    enableGallery = false,
    parentRef,
    editorRef,
    uploadingMedia,
    setUploadingMedia,
    updateUploadingMedia,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====

    const GALLERY_MARGIN = 2.5;
    const RERENDER_DELAY_DURATION = 1500; // Mainly for changing to fullScreen

    // ===== Refs =====

    // We use this ref to access Figure ref because
    // there is an issue assigning ref directly using figure
    //
    // This likely has to do with Slate attributes
    const addImageContainerRef = useRef<HTMLDivElement>(null);

    // ===== State =====

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(0);
    const [lastFocusEvent, setLastFocusEvent] = useState<EDITOR_FIGURE_FOCUS_EVENT_TYPE | null>(null);
    const [editorMarginLeft, setEditorMarginLeft] = useState<number>(0);
    const selected = useSelected();
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);

    // Add Image Left
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            handleAddImage(acceptedFiles[0]);
        }
    }, [user]);

    const {
        open: openAddLeft,
        getRootProps: getRootPropsAddLeft,
        getInputProps: getInputPropsAddLeft,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: 'image/*',
        onDropAccepted,
    });

    const {
        open: openAddRight,
        getRootProps: getRootPropsAddRight,
        getInputProps: getInputPropsAddRight,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: 'image/*',
        onDropAccepted,
    });

    // ===== Side Effects =====

    // Update Height
    useEffect(() => {
        if (
            addImageContainerRef.current
        ) {
            // Determine Figure Height
            let figureHeight = 0;
            const figureChildNodes = addImageContainerRef.current.parentElement?.childNodes;
            figureChildNodes?.forEach((node: ChildNode) => {
                const child = node as HTMLElement;
                if (child.classList.contains(EDITOR_FIGURE_IMAGE_CONTAINER_CLASSNAME)) {
                    figureHeight += child.clientHeight;
                } else if (child.tagName === 'FIGCAPTION') {
                    figureHeight += child.clientHeight;
                    figureHeight += parseInt(window.getComputedStyle(child).marginTop.replace('px', ''), 10);
                }
            });

            setHeight(figureHeight);

            if (rerender && setRerender) {
                setRerender(false);
            }
        }
    }, [
        layoutType,
        addImageContainerRef.current,
        rerender,
    ]);

    // Timeout for rerendering
    const {
        start: setRerenderTimeout,
        clear: clearRerenderTimeout,
    } = useTimeout(() => {
        setRerender(true);
    }, RERENDER_DELAY_DURATION);

    // Determine Editor Left Margin
    // Determine Fullscreen Width
    useEffect(() => {
        if (
            parentRef
            && layoutType === FIGURE_ORIENTATION.fullscreen
        ) {
            const parentRect = parentRef.getBoundingClientRect();
            const marginLeft = parentRect.left;
            setEditorMarginLeft(marginLeft);

            // Rerender
            clearRerenderTimeout();
            setRerenderTimeout();
        }
    }, (parentRef
        ? [
            rerender,
            layoutType,
            parentRef.clientWidth,
        ] : [
            rerender,
            layoutType,
        ]
    ));

    // Make sure permanent figure associated with
    // a transient progress item remains hidden until completed
    // transition
    //
    // Once the progress item has transitioned
    // it is popped off files.get('uploadProgress') at
    // which point this side effect runs again revealing the image
    useEffect(() => {
        if (uploadingMedia.size > 0) {
            const match = Array.from(uploadingMedia.values()).filter((uploadingItem) => {
                if (
                    uploadingItem.url === url
                    && uploadingItem.filePath === filePath
                ) {
                    return true;
                }
                return false;
            }).length > 0;
            if (!match) {
                setIsVisible(true);
            }
        } else {
            setIsVisible(true);
        }
    }, [uploadingMedia]);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
            // setUploadingStateChangeEvent(null);
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    // ===== Methods =====

    const handleFocus = (event: EDITOR_FIGURE_FOCUS_EVENT_TYPE): void => {
        if (
            event === EDITOR_FIGURE_FOCUS_EVENT_TYPE.click
            && lastFocusEvent === EDITOR_FIGURE_FOCUS_EVENT_TYPE.click
            && selected
        ) {
            // Case # 1
            // Last event was click and it focused figure
            // We wish to make unlocked
            setLastFocusEvent(null);
        } else if (
            event === EDITOR_FIGURE_FOCUS_EVENT_TYPE.click
            && !lastFocusEvent
        ) {
            // Case # 2
            // Last event was null, which only occurs
            // if we hit Case #1 after hitting Case #3
            // We wish to close it
            // setIsFocused(true);
            setLastFocusEvent(EDITOR_FIGURE_FOCUS_EVENT_TYPE.click);
        } else if (
            event === EDITOR_FIGURE_FOCUS_EVENT_TYPE.click
            && lastFocusEvent === EDITOR_FIGURE_FOCUS_EVENT_TYPE.mouseEnter
            && selected
        ) {
            // Case # 3
            // Last event was mouse enter and it focused figure
            // We wish to lock it focused
            // setIsFocused(true);
            setLastFocusEvent(EDITOR_FIGURE_FOCUS_EVENT_TYPE.click);
        } else if (
            event === EDITOR_FIGURE_FOCUS_EVENT_TYPE.mouseEnter
            && lastFocusEvent !== EDITOR_FIGURE_FOCUS_EVENT_TYPE.click
        ) {
            // Case # 4
            // Last event was mouse leave (Case #5)
            // We wish to temporarily make focused
            // setIsFocused(true);
            setLastFocusEvent(EDITOR_FIGURE_FOCUS_EVENT_TYPE.mouseEnter);
        } else if (
            event === EDITOR_FIGURE_FOCUS_EVENT_TYPE.mouseLeave
            && lastFocusEvent !== EDITOR_FIGURE_FOCUS_EVENT_TYPE.click
        ) {
            // Case # 5
            // Last event was a mouse enter
            // We wish to remove temporary focus
            // setIsFocused(false);
            setLastFocusEvent(EDITOR_FIGURE_FOCUS_EVENT_TYPE.mouseLeave);
        }
    };

    const handleAddImage = (file: File): void => {
        if (!user) throw Error('There was a problem adding image. User data not found.');

        const mediaId = uuidv4();
        const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
        const mediaBucket = getMediaStorageBucket(MEDIA_TYPE.image);
        const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
        const fileExtension = mime.extension(file.type);
        let storageEntity: STORAGE_ENTITY;
        if (editorType === EDITOR_CONTEXT_TYPE.post) {
            storageEntity = process.env.NODE_ENV === 'production'
                ? STORAGE_ENTITY.posts
                : STORAGE_ENTITY.stagingPosts;
        } else {
            storageEntity = process.env.NODE_ENV === 'development'
                ? STORAGE_ENTITY.stagingAnnotations
                : STORAGE_ENTITY.annotations;
        }
        const path = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
        const mediaItem: IMediaItem = {
            id: mediaId,
            userId: user.id,
            file,
            filePath: path,
            type: MEDIA_TYPE.image,
            uploadProgress: 0,
            caption: '',
        };
        setUploadingMedia(mediaItem);

        // make db entry of media
        // execute before file upload so upload cloud function has a place to write to
        setMediaInDB({
            mediaItem,
            filePath: path,
        });

        // upload file to cloud storage
        // url will be set by cloud function
        const uploadTask = uploadToCloudStorage(
            file,
            path,
        );

        uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadingStateChangeEvent({
                    mediaItem,
                    snapshot,
                    progress,
                });
            },
            (error: StorageError) => {
                throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
            },
            () => {
                setUploadingCompleteEvent({
                    mediaItem,
                    storageRef: uploadTask.snapshot.ref,
                });
            },
        );
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorElementButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        imgURL: string,
    ): void => {
        // Update Uploading Media
        updateUploadingMedia({
            ...uploadingMedia.get(mediaItem.id)!,
            url: imgURL,
        });

        // No need to update DB because filePath remains the same

        // Create Gallery
        createGallery(mediaItem.id, undefined, mediaItem.filePath);
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            setUploadingMedia(mediaItem);
        }
    };

    return (
        <Container
            {...attributes}
            id={UPLOADING_MEDIA_DESTINATION_ID(filePath)}
            isVisible={isVisible}
            layoutType={layoutType}
            isGalleryChild={isGalleryChild}
            editorMarginLeft={editorMarginLeft}
            galleryMargin={GALLERY_MARGIN}
            onClick={() => handleFocus(EDITOR_FIGURE_FOCUS_EVENT_TYPE.click)}
        >
            {(
                layoutType === FIGURE_ORIENTATION.left
                || layoutType === FIGURE_ORIENTATION.right
            ) && !readOnly
            && (
                <FloatPaddingContainer
                    height={height}
                />
            )}
            {children}
            <Transition
                in={
                    enableGallery
                    && !readOnly
                    && selected
                    && (
                        layoutType === FIGURE_ORIENTATION.right
                        || layoutType === FIGURE_ORIENTATION.center
                    )
                    && !isGalleryChild
                }
                timeout={{
                    enter: EDITOR_CAPTION_TRANSITION_DURATION,
                    exit: EDITOR_CAPTION_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(transitionState) => (
                    <AddImageContainer
                        {...getRootPropsAddLeft({
                            ref: addImageContainerRef,
                            className: HOVER_TARGET_CLASSNAME,
                            onMouseEnter: onButtonMouseEnter,
                            onMouseLeave: onButtonMouseLeave,
                            onClick: (e) => {
                                e.stopPropagation();
                                openAddLeft();
                            },
                            side: EDITOR_DROPZONE_SIDE_TYPE.left,
                            style: {
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: 'up',
                                    offset: 10,
                                    duration: EDITOR_CAPTION_TRANSITION_DURATION,
                                    easing: themeObj.motion.eagerEasing,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: 'up',
                                    offset: 10,
                                })[transitionState],
                            },
                        })}
                    >
                        <DropzoneInput {...getInputPropsAddLeft()} />
                        <Button
                            type={BUTTON_TYPE.secret}
                            height={35}
                            icon={ImageAddIcon}
                            tooltip={{
                                active: true,
                                text: 'Add Image',
                                side: TOOLTIP_TYPE.top,
                            }}
                        />
                    </AddImageContainer>
                )}
            </Transition>
            <Transition
                in={
                    enableGallery
                    && !readOnly
                    && selected
                    && (
                        layoutType === FIGURE_ORIENTATION.left
                        || layoutType === FIGURE_ORIENTATION.center
                    )
                    && !isGalleryChild
                }
                timeout={{
                    enter: EDITOR_CAPTION_TRANSITION_DURATION,
                    exit: EDITOR_CAPTION_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(transitionState) => (
                    <AddImageContainer
                        {...getRootPropsAddRight({
                            className: HOVER_TARGET_CLASSNAME,
                            onMouseEnter: onButtonMouseEnter,
                            onMouseLeave: onButtonMouseLeave,
                            onClick: (e) => {
                                e.stopPropagation();
                                openAddRight();
                            },
                            side: EDITOR_DROPZONE_SIDE_TYPE.right,
                            style: {
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: 'up',
                                    offset: 10,
                                    duration: EDITOR_CAPTION_TRANSITION_DURATION,
                                    easing: themeObj.motion.eagerEasing,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: 'up',
                                    offset: 10,
                                })[transitionState],
                            },
                        })}
                    >
                        <DropzoneInput {...getInputPropsAddRight()} />
                        <Button
                            type={BUTTON_TYPE.secret}
                            height={35}
                            icon={ImageAddIcon}
                            tooltip={{
                                active: true,
                                text: 'Add Image',
                                side: TOOLTIP_TYPE.top,
                            }}
                        />
                    </AddImageContainer>
                )}
            </Transition>
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    isVisible: boolean,
    layoutType: FIGURE_ORIENTATION,
    editorMarginLeft: number,
    isGalleryChild: boolean,
    galleryMargin: number,
}
const Container = styled.figure<ContainerProps>`
    position: relative;
    // Rather than be none, we want a transparent the background color
    // so that the background transitions in response to hover events
    background: transparent;
    left: ${({
        layoutType,
        editorMarginLeft,
    }) => (layoutType === FIGURE_ORIENTATION.fullscreen
        ? `-${editorMarginLeft}px`
        : 'auto'
    )};
    width: ${({
        layoutType,
        isGalleryChild,
        galleryMargin,
    }) => {
        if (layoutType === FIGURE_ORIENTATION.fullscreen) {
            return '100vw';
        }

        if (
            (
                layoutType === FIGURE_ORIENTATION.left
                || layoutType === FIGURE_ORIENTATION.right
            )
            || isGalleryChild
        ) {
            return `calc(50% - ${galleryMargin}px)`;
        }
        return '90%';
    }};
    float: ${({ layoutType }) => {
        if (layoutType === FIGURE_ORIENTATION.left) {
            return 'left';
        }

        if (layoutType === FIGURE_ORIENTATION.right) {
            return 'right';
        }
        return 'none';
    }};
    margin: ${({ layoutType }) => (layoutType === FIGURE_ORIENTATION.center
        ? `${EDITOR_FIGURE_MARGIN}px auto`
        : `${EDITOR_FIGURE_MARGIN}px 0px`
    )};
    margin-right: ${({ layoutType, isGalleryChild, galleryMargin }) => {
        if (
            layoutType === FIGURE_ORIENTATION.left
            && !isGalleryChild
        ) {
            return '30px';
        }

        if (
            layoutType === FIGURE_ORIENTATION.left
            && isGalleryChild
        ) {
            return `${galleryMargin}px`;
        }
        return 'auto';
    }};
    margin-left: ${({ layoutType, isGalleryChild }) => {
        if (layoutType === FIGURE_ORIENTATION.right && !isGalleryChild) {
            return '30px';
        }

        if (layoutType === FIGURE_ORIENTATION.right && isGalleryChild) {
            return '2.5px';
        }
        return 'auto';
    }};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    transition: ${({ theme }) => `
        opacity 300ms ${theme.motion.standardEasing}
    `};
`;

interface AddImageContainerProps {
    side: EDITOR_DROPZONE_SIDE_TYPE,
}
const AddImageContainer = styled.div<AddImageContainerProps>`
    position: absolute;
    top: 8px;
    left: ${({ side }) => (side === EDITOR_DROPZONE_SIDE_TYPE.left
        ? '-45px'
        : 'auto'
    )};
    right: ${({ side }) => (side === EDITOR_DROPZONE_SIDE_TYPE.right
        ? '-45px'
        : 'auto'
    )};
`;

interface FloatPaddingContainerProps {
    height: number,
}
const FloatPaddingContainer = styled.div<FloatPaddingContainerProps>`
    padding-bottom: ${({ height }) => `${height}px`};
`;

export default Figure;
