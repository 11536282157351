// ===== Packages =====

import React                                    from 'react';
import styled                                   from 'styled-components';
import { useSelected }                          from 'slate-react';

// ===== Services =====

import { setColorLightness }                    from '../../../services';

// ===== Constants =====

import {
    POST_EDITOR_MAX_WIDTH,
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
}                                               from '../../../constants/generalConstants';

Divider.defaultProps = {
    readOnly: false,
};
interface Props {
    color: string,
    readOnly?: boolean,
    children: React.ReactElement,
    attributes: any,
}
function Divider({
    color,
    children,
    readOnly,
    attributes,
}: Props): JSX.Element {
    // ===== State =====
    const selected = useSelected();

    return (
        <Container
            {...attributes}
            color={color}
            selected={selected}
            readOnly={readOnly}
        >
            <Line />
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    selected: boolean,
    color: string,
    readOnly: boolean,
}
const Container = styled.div<ContainerProps>`
    border: ${({ selected, color, theme }) => (selected
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        }`
        : 'none'
    )};
    border-radius: 5px;
    height: 41px;
    width: 90%;
    max-width: ${`calc(0.9 * ${POST_EDITOR_MAX_WIDTH}px)`};
    padding: 20px 0px;
    margin: 10px auto;
    clear: both;
    cursor: ${({ readOnly }) => (readOnly
        ? 'default'
        : 'cursor'
    )};
`;

const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.neutral300};
`;

export default Divider;
