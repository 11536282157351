// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import {
    useFocused,
    useSelected,
}                                               from 'slate-react';

// ===== Services =====

import {
    getSpotifyId,
    setColorLightness,
    getEmbeddingDimensions,
}                                               from '../../../services';

// ===== Interfaces =====

import {
    ISpotifyData,
}                                               from '../../../interfaces';
import { instanceOfSpotifyData }                from '../../../interfaces/ISpotifyData';

// ===== Hooks =====

import { useEventListener }                     from '../../../hooks';

// ===== Constants =====

import MEDIA_QUERY_SIZE                         from '../../../constants/mediaQuerySizes';
import EMBEDDING_DIMENSION                      from '../../../constants/embeddingDimensions';
import {
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
}                                               from '../../../constants/generalConstants';

const DIMENSIONS_RATIO = 30 / 38;

// Example Song Link: https://open.spotify.com/track/0ZPPbwJD2WVU8HdMsVtChk?si=X60GqMekSoC316GTHBD2PA
// Example Song Embed Link: https://open.spotify.com/embed/track/0ZPPbwJD2WVU8HdMsVtChk

// Example Album Link: https://open.spotify.com/album/20r762YmB5HeofjMCiPMLv?si=oncf6Ih1SpmLpAGDKsd2EQ
// Example Album Embed Link: https://open.spotify.com/embed/album/20r762YmB5HeofjMCiPMLv

// Example Podcast Episode: https://open.spotify.com/episode/732wSQDBZnIqZ0J63nYFsk?si=JEOnNWXVRy-XuIpPKUVqoA
// Example Podcast Embed Link: https://open.spotify.com/embed-podcast/episode/732wSQDBZnIqZ0J63nYFsk

// Example Podcast Show: https://open.spotify.com/show/3qv8BS1HzrgKpDnXSlYWWL?si=GDIRGMuJSb2FyVn_L2fJMQ
// Example Podcast Embed Link: https://open.spotify.com/embed-podcast/show/3qv8BS1HzrgKpDnXSlYWWL

SpotifyEmbed.defaultProps = {
    onLoad: undefined,
};
interface Props {
    url: string,
    color: string,
    children: React.ReactElement,
    attributes: any,
    onLoad?: () => void,
}
function SpotifyEmbed({
    url,
    color,
    children,
    attributes,
    onLoad,
}: Props): JSX.Element {
    // ===== Refs =====

    const embedRef = useRef<HTMLDivElement | null>(null);

    // ===== State =====

    const [id, setId] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [dimensions, setDimensions] = useState(EMBEDDING_DIMENSION[480]);
    const focused = useFocused();
    const selected = useSelected();

    // ===== Side Effects =====

    // Get ID
    useEffect(() => {
        const value = getSpotifyId(url);
        if (instanceOfSpotifyData(value)) {
            const data = value as ISpotifyData;
            setId(data.id);
            setType(data.type);
        }
    }, [url]);

    // On Mount, determine dimensions
    useEffect(() => {
        determineDimensions();
    }, []);

    // On resize, determine dimensions
    useEventListener(
        'resize',
        () => {
            determineDimensions();
        },
    );

    // ===== Methods =====

    const determineDimensions = (): void => {
        if (embedRef.current) {
            const parentWidth = embedRef.current.parentElement!.clientWidth;
            const currentDimension = getEmbeddingDimensions(parentWidth);
            setDimensions(EMBEDDING_DIMENSION[currentDimension]);
        }
    };

    let width;
    if (
        embedRef.current
        && embedRef.current.parentElement
        && MEDIA_QUERY_SIZE.extraSmall.max > window.innerWidth
    ) {
        width = embedRef.current.parentElement.clientWidth;
    } else {
        width = DIMENSIONS_RATIO * dimensions.height;
    }

    return (
        <Container
            {...attributes}
            ref={embedRef}
        >
            {id.length > 0
            && (
                <EmbedContainer
                    // Necessary to avoid: Cannot resolve a Slate point from DOM point
                    // Reference: https://github.com/ianstormtaylor/slate/issues/3930
                    contentEditable={false}
                >
                    <Frame
                        title={`spotify-embed=${url}`}
                        src={`https://open.spotify.com/embed${(type === 'show' || type === 'episode') ? '-podcast' : ''}/${type}/${id}`}
                        width={width}
                        height={(type === 'show' || type === 'episode') ? 240 : dimensions.height}
                        frameBorder="0"
                        allowTransparency
                        allow="encrypted-media"
                        color={color}
                        focused={focused}
                        selected={selected}
                        onLoad={onLoad}
                    />
                </EmbedContainer>
            )}
            {children}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
`;

interface FrameProps {
    selected: boolean,
    focused: boolean,
}
const Frame = styled.iframe<FrameProps>`
    border: ${({
        selected,
        focused,
        color,
        theme,
    }) => (selected && focused
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        }`
        : 'none'
    )};
    border-radius: 5px;
`;

const EmbedContainer = styled.div``;

export default SpotifyEmbed;
