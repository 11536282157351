// ===== Packages =====

import React                from 'react';
import { Link }             from 'react-router-dom';

// ===== Services =====

import {
    detectTouchDevice,
}                           from '../../services';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
}                           from '../../constants/generalConstants';

// ===== Styles =====

import {
    Container,
}                           from './styles';

MenuButton.defaultProps = {
    route: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMouseEnter: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMouseLeave: undefined,
};
interface Props {
    route?: string,
    width: number,
    height: number,
    isVisible: boolean,
    disabled: boolean,
    margin: number,
    withTransition: boolean,
    withDelay: boolean,
    translateDuration: number,
    translateDelay: number,
    offsetDelay: number,
    onClick?: (event: React.MouseEvent) => void;
    onMouseEnter?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
    children: React.ReactNode
}
function MenuButton({
    route,
    width,
    height,
    isVisible,
    disabled,
    margin,
    withTransition,
    withDelay,
    translateDuration,
    translateDelay,
    offsetDelay,
    onClick,
    onMouseEnter,
    onMouseLeave,
    children,
}: Props): JSX.Element {
    const button = (
        <Container
            {...(onMouseEnter || onMouseLeave ? { className: HOVER_TARGET_CLASSNAME } : {})}
            width={width}
            height={height}
            isVisible={isVisible}
            detectTouchDevice={detectTouchDevice(document)}
            disabled={disabled}
            withTransition={withTransition}
            withDelay={withDelay}
            offsetDelay={offsetDelay}
            translateDuration={translateDuration}
            translateDelay={translateDelay}
            margin={margin}
            {...(onClick ? { onClick } : {})}
            {...(onMouseEnter ? { onMouseEnter } : {})}
            {...(onMouseLeave ? { onMouseLeave } : {})}
        >
            {children}
        </Container>
    );

    if (route) {
        return (
            <Link to={`/${route}`}>
                {button}
            </Link>
        );
    }

    return button;
}

export default MenuButton;
