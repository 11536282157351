// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripePromotionIsValid = async ({
    test,
    promotionCode,
}: {
    test: boolean,
    promotionCode: string,
}): Promise<Stripe.Coupon | undefined> => {
    const functions = getFunctions();
    const isValid = httpsCallable<
        {
            test: boolean,
            promotionCode: string,
        },
        Stripe.Coupon | undefined
    >(
        functions,
        'stripePromotionIsValid',
    );

    return isValid({
        test,
        promotionCode,
    }).then((result) => {
        const promotionId = result.data as Stripe.Coupon | undefined;
        return promotionId;
    }).catch((err) => err);
};

export default stripePromotionIsValid;
