// ===== Interfaces =====

import { IEmbeddingDimension } from '../interfaces';

const EMBEDDING_DIMENSION: IEmbeddingDimension = {
    240: {
        width: 426,
        height: 240,
    },
    360: {
        width: 640,
        height: 360,
    },
    480: {
        width: 854,
        height: 480,
    },
    720: {
        width: 1280,
        height: 720,
    },
    1080: {
        width: 1920,
        height: 1080,
    },
    1440: {
        width: 2560,
        height: 1440,
    },
    2160: {
        width: 3840,
        height: 2160,
    },
};

export default EMBEDDING_DIMENSION;
