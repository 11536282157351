// ===== Packages =====

import {
    ref,
    getStorage,
    uploadBytesResumable,
    UploadTask,
    StorageReference,
}                                       from 'firebase/storage';

const uploadToCloudStorage = (
    file: File,
    filePath: string,
): UploadTask => {
    // Create a root reference
    const storage = getStorage();
    const storageRef: StorageReference = ref(storage, filePath);
    const uploadTask: UploadTask = uploadBytesResumable(storageRef, file);
    // Register three observers on Upload Task:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    return uploadTask;
};

export default uploadToCloudStorage;
