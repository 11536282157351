// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';

interface ContainerProps {
    width: number,
    height: number,
    isVisible: boolean,
    detectTouchDevice: boolean,
    disabled: boolean,
    margin: number,
    withTransition: boolean,
    withDelay: boolean,
    translateDuration: number,
    translateDelay: number,
    offsetDelay: number
}
export const Container = styled.button<ContainerProps>`
    position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute')};
    left: 50%;
    transform: ${({ isVisible }) => (isVisible ? 'translate(-50%, 0px)' : 'translate(-50%, 20px)')};
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    margin: ${({ margin }) => `${margin}px`};
    font-size: 1em;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 400;
    color: ${({ disabled, theme }) => (disabled ? theme.verascopeColor.purple200 : theme.verascopeColor.white)};
    background: transparent;
    outline: none;
    border: none;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible, disabled }) => (isVisible && !disabled ? 'auto' : 'none')};
    cursor: none;
    user-select: none;
    transition: ${({
        isVisible,
        withTransition,
        withDelay,
        translateDuration,
        translateDelay,
        offsetDelay,
        theme,
    }) => (withTransition
        // eslint-disable-next-line max-len
        ? `opacity ${isVisible ? translateDuration : 0}ms ${isVisible && withDelay ? `${translateDelay + offsetDelay}ms` : ''} ${theme.motion.bounceEasing}, transform ${isVisible ? translateDuration : 0}ms ${isVisible && withDelay ? `${translateDelay + offsetDelay}ms` : ''} ${theme.motion.bounceEasing}`
        : 'all 0s ease 0s')
};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            background: ${theme.verascopeColor.white};
            color: ${theme.verascopeColor.purple300};
        `)}
    }
`;
