// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
}                                   from '../../constants/generalConstants';

export const CustomRadioButton = styled.span`
    position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 15px;
    border: ${({ theme }) => `3px solid ${theme.verascopeColor.purple100}`};
    pointer-events: none;
`;

export const Label = styled.div``;

export const Title = styled.label`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;
    line-height: 2em;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
        line-height: 1.8em;
    }
`;

export const Container = styled.div`
    position: relative;
    width: fit-content;

    &:hover ${CustomRadioButton} {
        border: ${({ theme }) => `3px solid ${theme.verascopeColor.purple400}`};
    }

    &:hover ${Title} {
        color: ${({ theme }) => theme.verascopeColor.purple400};
    }

    &:hover input:checked ~ ${CustomRadioButton}:after {
        background: ${({ theme }) => theme.verascopeColor.purple400};
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    & input:checked ~ ${CustomRadioButton} {
        background: ${({ theme }) => theme.color.white};
    }

    & input:checked ~ ${CustomRadioButton}:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 7.5px;
        width: 7.5px;
        background: ${({ theme }) => theme.verascopeColor.purple100};
    }
`;

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    height: 100%;
    width: calc(100% - 10px);
    cursor: none;
    margin: 0;
`;
