// ===== Enums =====

import {
    FILTER_TYPE,
    FILTER_TYPE_PATH_KEY,
} from '../enums';

const getFilterTypePathKey = (type: FILTER_TYPE): FILTER_TYPE_PATH_KEY => {
    let pathKey: FILTER_TYPE_PATH_KEY;

    if (type === FILTER_TYPE.theme) {
        pathKey = FILTER_TYPE_PATH_KEY.theme;
    } else if (type === FILTER_TYPE.type) {
        pathKey = FILTER_TYPE_PATH_KEY.type;
    } else {
        pathKey = FILTER_TYPE_PATH_KEY.author;
    }

    return pathKey;
};

export default getFilterTypePathKey;
