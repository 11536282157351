// ===== Enums =====

import {
    STORAGE_ERROR_CODE,
}                       from '../enums';

const getStorageErrorMessage = (storageError: STORAGE_ERROR_CODE): string => {
    let message: string;
    switch (storageError) {
    case STORAGE_ERROR_CODE.unknown:
        message = 'An unknown error occurred.';
        break;
    case STORAGE_ERROR_CODE.objectNotFound:
        message = 'No object exists at the desired reference.';
        break;
    case STORAGE_ERROR_CODE.bucketNotFound:
        message = 'No bucket is configured for Cloud Storage';
        break;
    case STORAGE_ERROR_CODE.projectNotFound:
        message = 'No project is configured for Cloud Storage';
        break;
    case STORAGE_ERROR_CODE.quotaExceeded:
        message = 'Quota on your Cloud Storage bucket has been exceeded. If you\'re on the no-cost tier, upgrade to a paid plan. If you\'re on a paid plan, reach out to Firebase support.';
        break;
    case STORAGE_ERROR_CODE.unauthenticated:
        message = 'User is unauthenticated, please authenticate and try again.';
        break;
    case STORAGE_ERROR_CODE.unauthorized:
        message = 'User is not authorized to perform the desired action, check your security rules to ensure they are correct.';
        break;
    case STORAGE_ERROR_CODE.retryLimitExceeded:
        message = 'The maximum time limit on an operation (upload, download, delete, etc.) has been excceded. Try uploading again.';
        break;
    case STORAGE_ERROR_CODE.invalidChecksum:
        message = 'File on the client does not match the checksum of the file received by the server. Try uploading again.';
        break;
    case STORAGE_ERROR_CODE.canceled:
        message = 'User canceled the operation.';
        break;
    case STORAGE_ERROR_CODE.invalidEventName:
        message = 'Invalid event name provided. Must be one of [`running`, `progress`, `pause`]';
        break;
    case STORAGE_ERROR_CODE.invalidURL:
        message = 'Invalid URL provided to refFromURL(). Must be of the form: gs://bucket/object or https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>';
        break;
    case STORAGE_ERROR_CODE.invalidArgument:
        message = 'The argument passed to put() must be `File`, `Blob`, or `UInt8` Array. The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string.';
        break;
    case STORAGE_ERROR_CODE.noDefaultBucket:
        message = 'No bucket has been set in your config\'s storageBucket property.';
        break;
    case STORAGE_ERROR_CODE.cannotSliceBlob:
        message = 'Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn\'t changed.';
        break;
    case STORAGE_ERROR_CODE.serverFileWrongSize:
        message = 'File on the client does not match the size of the file recieved by the server. Try uploading again.';
        break;
    default:
        message = 'Unrecognized error occurred';
        break;
    }

    return message;
};

export default getStorageErrorMessage;
