// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
}                                       from 'react';
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
}                                       from 'react-router-dom';
import {
    doc,
    where,
    query,
    setDoc,
    getDoc,
    getDocs,
    collection,
    onSnapshot,
    getFirestore,
    runTransaction,
}                                       from 'firebase/firestore';
import {
    getAuth,
    Unsubscribe,
}                                       from 'firebase/auth';
import { ReactSVG }                     from 'react-svg';
import Stripe                           from 'stripe';
import {
    Elements,
}                                       from '@stripe/react-stripe-js';
import {
    loadStripe,
    Appearance,
    StripeElementsOptions,
}                                       from '@stripe/stripe-js';
import { v4 as uuidv4 }                 from 'uuid';

// ===== Components =====

import Breadcrumb                       from '../Breadcrumb';
import CheckoutCheckpoint               from '../CheckoutCheckpoint';

// ===== Hooks =====

import { useInterval }                  from '../../hooks';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    USER_ACTION_TYPE,
    CHECKOUT_CHECKPOINT,
    FIRESTORE_COLLECTION,
    CHECKOUT_SESSION_STATUS_TYPE,
    STRIPE_PAYMENT_INTENT_OPERATION_TYPE,
    STRIPE_PAYMENT_INTENT_STATUS,
    BILLING_ADDRESS_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    detectTouchDevice,
    playAudio,
    applyPromotionToCheckoutItem,
    stripeCreatePaymentIntent,
    stripeGetPaymentIntent,
    stripeUpdatePaymentIntent,
    stripeGetStoreItems,
    stripeApplyDigitalBookDiscount,
    stripeApplyWebBookDiscount,
    stripePromotionIsValid,
    isSameCart,
    stripeGetPromotions,
    stripeGetTaxCalculationLineItems,
    checkBookPurchases,
    stripeGetPaymentMethod,
    stripeGetTaxTransaction,
    stripeGetCustomerPaymentMethods,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    ISnackbarItem,
    IRadioItem,
    IDimension,
    ICartItem,
    IStoreItem,
    IStripePaymentIntentArgs,
    ICheckoutSessionItem,
    IShippoRateObject,
    IOrderCost,
    ISaleItem,
}                                       from '../../interfaces';

// ===== Images =====

import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';
import PixelCartIcon                    from '../../images/8-bit_cart.svg';
import PixelChevronIcon                 from '../../images/8-bit_chevron.svg';
import BoxIcon                          from '../../images/box.svg';
import Spinner                          from '../../images/editor/spinner.svg';
import PixelCrossIcon                   from '../../images/8-bit_cross.svg';

// ===== Sounds =====

import Success                          from '../../sounds/message_success.mp3';
import Delete                           from '../../sounds/delete.mp3';
import ButtonClick                      from '../../sounds/button_click.mp3';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    DEFAULT_AUDIO_VOLUME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    UNASSIGNED_ERROR_MESSAGE,
    CENTS_IN_A_DOLLAR,
    WEB_BOOK_STRIPE_PRICE_API_ID,
    WEB_BOOK_STRIPE_PRICE_TEST_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    SUPPORT_EMAIL_ADDRESS,
    DIGITAL_BOOK_STRIPE_COUPON_TEST_ID,
    DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID,
    WEB_BOOK_STRIPE_COUPON_TEST_ID,
    WEB_BOOK_STRIPE_COUPON_LIVE_ID,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import KEYCODE                          from '../../constants/keycodes';
import FONT_TYPE                        from '../../constants/fontType';
import MEDIA_QUERY_SIZE                 from '../../constants/mediaQuerySizes';

// ===== Styles =====

import {
    Container,
    ContentContainer,
    CheckpointContainer,
    OrderSummaryContainer,
    OrderSummary,
    Header,
    CartItemsContainer,
    BreadcrumbContainer,
    CartItemFinalQuantityValue,
    CartItemFinalQuantityContainer,
    OrderSummaryToggleButtonContainer,
    OrderSummaryToggleButton,
    PromotionCodeContainer,
    PromotionCodeHeader,
    PromotionCodeHeaderText,
    PromotionCodeToggleButtonContainer,
    PromotionCodeToggleButton,
    PromotionCodeBody,
    PromotionCodeInput,
    PromotionCodeAddButton,
    PromotionCodeBadgeContainer,
    PromotionCodeBadge,
    PromotionCodeBadgeRemoveButton,
    PlaceholderCheckpointContainer,
    PlaceholderCheckpointText,
}                                       from './styles';
import {
    HeaderText,
    CartItemsCountText,
    CartItems,
    CartItemContainer,
    CartItemImageContainer,
    CartItemImage,
    CartItemDetails,
    CartItemName,
    CartItemPrice,
    OrderSummaryEntries,
    OrderSummaryEntry,
    OrderSummaryText,
    OrderSummaryPrice,
    CartItemPriceAfterDiscount,
    DiscountedCartItemPrice,
    SpinnerContainer,
}                                       from '../CartView/styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
    PlaceholderBox,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

// ===== Initialize Stripe =====
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const STRIPE_PUBLISHABLE_KEY = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY!);

interface Props {
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    snackbarData: ISnackbarItem,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setCursorIsHidden: React.Dispatch<React.SetStateAction<boolean>>,
}
function CheckoutView({
    hasSound,
    user,
    currentSessionId,
    viewportDimensions,
    snackbarData,
    setSnackbarData,
    setInputFocused,
    onCursorEnter,
    onCursorLeave,
    setCursorIsHidden,
}: Props): JSX.Element {
    // ===== General Constants =====

    // Snackbar Message when stripe unavailable
    const SNACKBAR_MESSAGE_CALCULATE_CART_SUBTOTAL_ERROR = `Unable to calculate cart subtotal. Please contact the verascope team for support at: ${
        SUPPORT_EMAIL_ADDRESS
    }.`;
    // Snackbar Message when encounter error generating payment intent
    const SNACKBAR_MESSAGE_STRIPE_PAYMENT_INTENT_GENERAL_ERROR = (
        error: string,
    ): string => `Encountered an error relaying checkout request to server: ${error}.`;
    const SNACKBAR_MESSAGE_STRIPE_PAYMENT_INTENT_USER_ERROR = 'Unable to relay checkout request to server because user\'s data cannot be accessed.';
    // Snackbar Message when encounter error creating checkout session
    const SNACKBAR_MESSAGE_RESUME_CHECKOUT_SESSION_ERROR = 'Unable to resume previous checkout session because user\'s data cannot be accessed.';
    // Snackbar Message when encounter error creating checkout session
    const SNACKBAR_MESSAGE_CREATE_CHECKOUT_SESSION_ERROR = 'Unable to initiate new checkout session because checkout request has not yet been responded to.';
    // Snackbar Message when user submits an invalid promotion code
    const SNACKBAR_MESSAGE_INVALID_PROMOTION_CODE_ERROR = (code: string): string => `Promotion code '${code}' invalid.`;
    // Snackbar Message when user applies a valid promotion code
    const SNACKBAR_MESSAGE_APPLY_PROMOTION_CODE_SUCCESS = (code: string): string => `Successfully applied promotion code '${code}'!`;
    // Snackbar Message when user applies an invalid promotion code
    const SNACKBAR_MESSAGE_APPLY_PROMOTION_CODE_ERROR = (code: string): string => `Encountered error applying promotion code '${code}'.`;
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_SUCCESS = 'Payment succeeded!';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_PROCESSING = 'Your payment is processing and should complete momentarily. Please check your email inbox for further updates.';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_PAYMENT_METHOD = 'Your payment attempt failed. Please try again.';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_ACTION = 'Your payment requires authentication. Please consult the redirect page.';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_CONFIRMATION = 'Your payment was not successful. Unable to confirm your purchase. Please try again.';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_CAPTURE = 'Your payment was not successful. Unable to capture funds associated with your purchase. Please try again.';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_CANCELED = 'Your payment was not successful. Your checkout session has expired. Please refresh your page and try again.';
    // Snackbar Message after payment submission
    const SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_GENERIC = `Payment failed. Please try again or contact the verascope team for support at: ${SUPPORT_EMAIL_ADDRESS}.`;
    //
    const SNACKBAR_MESSAGE_FINAL_TAX_TRANSACTION_ERROR = `Encountered an error fetching order details. Please contact the verascope team for support at: ${
        SUPPORT_EMAIL_ADDRESS
    }.`;
    const CART_ITEM_NAME_PLACEHOLDER_WIDTH = 120;
    const CART_ITEM_NAME_PLACEHOLDER_HEIGHT = 15;
    const CART_ITEM_PRICE_PLACEHOLDER_WIDTH = 50;
    const CART_ITEM_PRICE_PLACEHOLDER_HEIGHT = 15;
    const ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH = 60;
    const ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT = 20;
    const CHECKPOINT_PLACEHOLDER_ELLIPSIS_INTERVAL_DURATION = 1000;

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    // ===== Refs =====

    const PROMOTION_CODE_INPUT_REF_NAME = 'promotionCodeInputRef';
    const promotionCodeInputRef = useRef<HTMLInputElement>(null);

    // ----- Sound Clips
    const successClip = useRef<HTMLAudioElement>(new Audio());
    const deleteClip = useRef<HTMLAudioElement>(new Audio());
    const buttonClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // ----- General
    const [stripeClientSecret, setStripeClientSecret] = useState<string | undefined>(undefined);
    const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string | undefined>(undefined);
    const [stripeTaxCalculationId, setStripeTaxCalculationId] = useState<string | undefined>(undefined);
    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);
    // Indicates whether user has bought a web book
    const [purchasedWebBookSessionIds, setPurchasedWebBookSessionIds] = useState<string[]>([]);
    // Indicates whether user has bought a digital book
    const [purchasedDigitalBookSessionIds, setPurchasedDigitalBookSessionIds] = useState<string[]>([]);
    // Indicates whether user has bought a digital book
    const [purchasedPhysicalBookSessionIds, setPurchasedPhysicalBookSessionIds] = useState<string[]>([]);
    // Indicates whether checked for book purchases
    const [checkedBookPurchases, setCheckedBookPurchases] = useState<boolean>(false);
    // Indicates whether checked for customer payment methods
    const [checkedPaymentMethods, setCheckedPaymentMethods] = useState<boolean>(false);
    // Animates placeholder text when loading checkpoint
    const [checkpointPlaceholderEllipsis, setCheckpointPlaceholderEllipsis] = useState<string>('...');
    // Indicated whether checkpoint placeholder text is running
    const [isRunningCheckpointPlaceholderInterval, setIsRunningCheckpointPlaceholderInterval] = useState<boolean>(true);

    // ----- Promotion Code
    // Indicates whether the promotion code input is visible
    const [promotionCodeInputIsVisible, setPromotionCodeInputIsVisible] = useState<boolean>(false);
    // Stores promotion code value
    const [promotionCode, setPromotionCode] = useState<string>('');
    // Indicates whether promotion code is being validated
    const [isValidatingPromotionCode, setIsValidatingPromotionCode] = useState<boolean>(false);
    // Indicates whether promotion code is invalid
    const [promotionCodeInvalid, setPromotionCodeInvalid] = useState<boolean>(false);
    // Stores applied promotion code ids
    const [appliedPromotions, setAppliedPromotions] = useState<Stripe.Coupon[]>([]);

    // ----- Store Items
    // Stores a map of store items
    const [storeItems, setStoreItems] = useState<Map<string, IStoreItem>>(new Map());
    // Indicates whether store items ahve been fetched
    const [fetchedStoreItems, setFetchedStoreItems] = useState<boolean>(false);
    // Indicates whether attempted to restore checkout session after payment
    const [attemptedRestoringCheckoutSessionAfterPayment, setAttemptedRestoringCheckoutSessionAfterPayment] = useState<boolean>(false);
    // Indicates whether attempted to resume previous checkout session
    const [attemptedResumingPreviousCheckoutSession, setAttemptedResumingPreviousCheckoutSession] = useState<boolean>(false);
    // Stores current checkout session
    const [currentCheckoutSessionId, setCurrentCheckoutSessionId] = useState<string | undefined>(undefined);
    // Indicates whether a physical item is in cart
    const [physicalItemsInCart, setPhysicalItemsInCart] = useState<string[]>([]);
    // Stores store item information of web book
    const [webBookStoreItem, setWebBookStoreItem] = useState<IStoreItem | undefined>(undefined);
    // Stores store item information of digital book
    const [digitalBookStoreItem, setDigitalBookStoreItem] = useState<IStoreItem | undefined>(undefined);

    // ----- Order Summary
    // Indicates whether order summary is visible on small screens
    const [orderSummaryIsVisible, setOrderSummaryIsVisible] = useState<boolean>(false);
    // Stores order cost details
    const [orderCost, setOrderCost] = useState<IOrderCost | undefined>(undefined);

    // ----- Info
    // Indicates whether address is valid
    const [addressIsValid, setAddressIsValid] = useState<boolean>(true);
    // Stores street address line one of customer
    const [customerStreetOne, setCustomerStreetOne] = useState<string>('');

    // ----- Shipping Method
    // Stores a list of shipping rates
    const [shippingRates, setShippingRates] = useState<Map<string, IShippoRateObject>>(new Map());
    // Stores selected shipping method id
    const [selectedShippingRateID, setSelectedShippingRateID] = useState<string | undefined>(undefined);

    // ----- Billing
    // Stores customer's saved payment methods
    const [savedStripePaymentMethods, setSavedStripePaymentMethods] = useState<Stripe.PaymentMethod[]>([]);
    // Stores selected billing address Type
    const [selectedBillingAddressType, setSelectedBillingAddressType] = useState<IRadioItem | undefined>(undefined);
    // Indicates whether payment details are completed
    const [stripePaymentDetailsCompleted, setStripePaymentDetailsCompleted] = useState<boolean>(false);
    // Indicates whether payment went through
    const [stripePaymentSucceeded, setStripePaymentSucceeded] = useState<boolean>(false);
    // Indicates whether billing address is valid
    const [billingAddressIsValid, setBillingAddressIsValid] = useState<boolean>(true);
    // Stores street address line one of billing customer
    const [customerBillingStreetOne, setCustomerBillingStreetOne] = useState<string>('');
    // Indicates whether customer has completed all details
    const [customerDetailsCompleted, setCustomerDetailsCompleted] = useState<boolean>(false);

    // ----- Confirmation
    // Stores succeeded stripe payment intent
    const [stripeSucceededPaymentIntent, setStripeSucceededPaymentIntent] = useState<Stripe.PaymentIntent | undefined>(undefined);
    // Stores payment method of succeeded stripe payment intnet
    const [stripePaymentMethod, setStripePaymentMethod] = useState<Stripe.PaymentMethod | undefined>(undefined);
    // Indicates whether we should be listening for order sale item
    const [listenForSaleItem, setListenForSaleItem] = useState<boolean>(false);
    // Stores order sale item
    const [orderSaleItem, setOrderSaleItem] = useState<ISaleItem | undefined>(undefined);
    // Indicates whether we should be waiting for order tax transaction
    const [waitForTaxTransaction, setWaitForTaxTransaction] = useState<boolean>(false);
    // Stores sentinel used to trigger check for tax transaction
    const [checkForTaxTransactionTrigger, setCheckForTaxTransactionTrigger] = useState<boolean>(false);

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.checkout,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    /**
     * Records user action of viewing checkout page
     */
    const recordCheckoutPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewCheckoutPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    const handlePlaceholderEllipsisText = (): void => {
        const currentEllipsis = checkpointPlaceholderEllipsis;
        const currentNumDots = currentEllipsis.split('.').length - 1;
        let updatedNumDots;
        if (currentNumDots === 3) {
            updatedNumDots = 0;
        } else {
            updatedNumDots = currentNumDots + 1;
        }

        setCheckpointPlaceholderEllipsis(`${'.'.repeat(updatedNumDots)}`);
    };

    const fetchStoreItems = async (): Promise<void> => {
        const items = await stripeGetStoreItems({
            test: process.env.NODE_ENV === 'development',
        });
        if (items) {
            setStoreItems(items);
            setWebBookStoreItem(items.get(WEB_BOOK_PRICE_ID));
            setDigitalBookStoreItem(items.get(DIGITAL_BOOK_PRICE_ID));
            setFetchedStoreItems(true);
        }
    };

    const determineBookPurchases = async (): Promise<void> => {
        if (!checkedBookPurchases) setCheckedBookPurchases(true);
        if (user) {
            const purchases = await checkBookPurchases(user);
            if (purchases) {
                setPurchasedWebBookSessionIds(purchases.web);
                setPurchasedDigitalBookSessionIds(purchases.digital);
                setPurchasedPhysicalBookSessionIds(purchases.physical);
            }
        }
    };

    const determinePaymentMethods = async (): Promise<void> => {
        if (!checkedPaymentMethods) setCheckedPaymentMethods(true);
        if (
            user
            && user.stripeCustomerId
        ) {
            const paymentMethods = await stripeGetCustomerPaymentMethods({
                test: process.env.NODE_ENV === 'development',
                customerId: user.stripeCustomerId,
            });
            setSavedStripePaymentMethods(paymentMethods);
        }
    };

    const calculateCartSubTotal = (): void => {
        if (
            user?.cart
            && user.cart.length > 0
            && fetchedStoreItems
            && storeItems.size > 0
            && !orderCost
        ) {
            const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
            const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
            const subtotal = user.cart.reduce((
                total: number,
                item: ICartItem,
            ) => {
                const storeItem = storeItems.get(item.priceId);
                if (storeItem && storeItem.amount) {
                    let appliedDigitalBookCutPromotion = false;
                    let appliedWebBookCutPromotion = false;
                    let cumulativeAmount = 0;
                    for (let i = 0; i < item.quantity; i += 1) {
                        let cumulativeDiscount = 0;
                        if (appliedPromotions.length > 0) {
                            for (let j = 0; j < appliedPromotions.length; j += 1) {
                                const promotion = appliedPromotions[j];
                                const discountedAmount = applyPromotionToCheckoutItem(
                                    storeItem,
                                    promotion,
                                );
                                if (
                                    !(
                                        promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                        && appliedWebBookCutPromotion
                                    ) && !(
                                        promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                        && appliedDigitalBookCutPromotion
                                    )
                                ) {
                                    cumulativeDiscount += storeItem.amount! - discountedAmount;
                                }
                                if (
                                    promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                    && !appliedWebBookCutPromotion
                                ) {
                                    appliedWebBookCutPromotion = true;
                                } else if (
                                    promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                    && !appliedDigitalBookCutPromotion
                                ) {
                                    appliedDigitalBookCutPromotion = true;
                                }
                            }
                        }
                        cumulativeAmount += storeItem.amount - cumulativeDiscount;
                    }
                    return total + cumulativeAmount;
                }
                return total;
            }, 0);
            setOrderCost({
                subtotal,
                // we ignore shipping because we want customer to pick a new rate
                // old rate may no longer be available
            });
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_CALCULATE_CART_SUBTOTAL_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });

            if (user && currentSessionId) {
                recordUserAction({
                    type: USER_ACTION_TYPE.error,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        error: SNACKBAR_MESSAGE_CALCULATE_CART_SUBTOTAL_ERROR,
                    },
                });
            }
        }
    };

    const changeCheckoutSessionStatus = async (status: CHECKOUT_SESSION_STATUS_TYPE): Promise<void> => {
        if (stripePaymentIntentId) {
            const db = getFirestore();
            const checkoutSessionsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.checkoutSessions
                : FIRESTORE_COLLECTION.stagingCheckoutSessions;
            runTransaction(db, async (transaction) => {
                const sessionRef = doc(
                    db,
                    checkoutSessionsCollection,
                    stripePaymentIntentId,
                );
                const sessionDoc = await transaction.get(sessionRef);
                if (!sessionDoc.exists()) {
                    throw Error(
                        'Encounter a problem while updating checkout session. Unable to locate checkout session in database.',
                    );
                }
                const session: ICheckoutSessionItem = sessionDoc.data() as ICheckoutSessionItem;
                if (session.status[session.status.length - 1].value !== status) {
                    transaction.update(sessionRef, {
                        updated: Date.now(),
                        status: [
                            ...session.status,
                            {
                                timestamp: Date.now(),
                                value: status,
                            },
                        ],
                    });
                }
            });
        } else {
            throw Error(
                'Unable to update checkout session status because it\'s unique identification number cannot be found.',
            );
        }
    };

    const restoreCheckoutSessionAfterPayment = async (
        paymentIntentId: string,
    ): Promise<void> => {
        if (
            searchParams.has('payment_intent')
            && searchParams.has('payment_intent_client_secret')
        ) {
            // Remove query parameters
            searchParams.delete('payment_intent');
            searchParams.delete('payment_intent_client_secret');
            setSearchParams(searchParams);

            // Get payment intent
            const paymentIntent = await stripeGetPaymentIntent({
                test: process.env.NODE_ENV === 'development',
                paymentIntentId,
            });
            switch (paymentIntent.status) {
            case STRIPE_PAYMENT_INTENT_STATUS.succeeded:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_SUCCESS,
                    icon: PixelCartIcon,
                    hasSuccess: true,
                });
                if (!stripePaymentSucceeded) {
                    setStripePaymentSucceeded(true);
                }
                break;
            case STRIPE_PAYMENT_INTENT_STATUS.processing:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_PROCESSING,
                    icon: PixelCartIcon,
                    dismissable: true,
                });
                if (!stripePaymentSucceeded) setStripePaymentSucceeded(true);
                break;
            case STRIPE_PAYMENT_INTENT_STATUS.requiresPaymentMethod:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_PAYMENT_METHOD,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.requiresPaymentMethod);
                if (stripePaymentSucceeded) setStripePaymentSucceeded(false);
                break;
            case STRIPE_PAYMENT_INTENT_STATUS.requiresAction:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_ACTION,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.requiresAction);
                if (stripePaymentSucceeded) setStripePaymentSucceeded(false);
                break;
            case STRIPE_PAYMENT_INTENT_STATUS.requiresConfirmation:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_CONFIRMATION,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.requiresConfirmation);
                if (stripePaymentSucceeded) setStripePaymentSucceeded(false);
                break;
            case STRIPE_PAYMENT_INTENT_STATUS.requiresCapture:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_REQUIRES_CAPTURE,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.requiresCapture);
                if (stripePaymentSucceeded) setStripePaymentSucceeded(false);
                break;
            case STRIPE_PAYMENT_INTENT_STATUS.canceled:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_CANCELED,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.abandoned);
                if (stripePaymentSucceeded) setStripePaymentSucceeded(false);
                break;
            default:
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_GENERIC,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (user && currentSessionId) {
                    recordUserAction({
                        type: USER_ACTION_TYPE.error,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            error: SNACKBAR_MESSAGE_SUBMIT_PAYMENT_ERROR_GENERIC,
                        },
                    });
                }
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.unknownIntentStatus);
                if (stripePaymentSucceeded) setStripePaymentSucceeded(false);
                break;
            }

            if (paymentIntent.status === STRIPE_PAYMENT_INTENT_STATUS.succeeded) {
                // Store succeeded payment intent
                setStripeSucceededPaymentIntent(paymentIntent);

                // Store succeeded payment method
                stripeGetPaymentMethod({
                    test: process.env.NODE_ENV === 'development',
                    paymentIntentId,
                }).then((paymentMethod) => {
                    if (paymentMethod) setStripePaymentMethod(paymentMethod);
                });

                // Retrieve sale item
                const db = getFirestore();
                const salesCollection = process.env.NODE_ENV === 'production'
                    ? FIRESTORE_COLLECTION.sales
                    : FIRESTORE_COLLECTION.stagingSales;
                const saleItemRef = doc(
                    db,
                    salesCollection,
                    paymentIntentId, // sales items share id with associated payment intents
                );
                getDoc(saleItemRef).then((saleItemSnap) => {
                    if (saleItemSnap.exists()) {
                        const saleItem = saleItemSnap.data() as ISaleItem;
                        setOrderSaleItem(saleItem);
                    } else {
                        setListenForSaleItem(true);
                    }
                });

                // Retrieve order cost
                if (paymentIntent.metadata.taxTransactionId) {
                    stripeGetTaxTransaction({
                        test: process.env.NODE_ENV === 'development',
                        taxTransactionId: paymentIntent.metadata.taxTransactionId,
                    }).then((taxTransaction) => {
                        if (taxTransaction.line_items) {
                            const subtotal = taxTransaction.line_items.data.reduce((
                                sum: number,
                                lineItem: Stripe.Tax.TransactionLineItem,
                            ) => (
                                sum + (lineItem.quantity * lineItem.amount)
                            ), 0);
                            let shipping: number | undefined;
                            if (taxTransaction.shipping_cost) {
                                shipping = taxTransaction.shipping_cost.amount;
                            }
                            let tax = taxTransaction.line_items.data.reduce((
                                sum: number,
                                lineItem: Stripe.Tax.TransactionLineItem,
                            ) => (
                                sum + (lineItem.quantity * lineItem.amount_tax)
                            ), 0);
                            if (taxTransaction.shipping_cost) {
                                tax += taxTransaction.shipping_cost.amount_tax;
                            }
                            const total = shipping !== undefined
                                ? subtotal + shipping + tax
                                : subtotal + tax;
                            setOrderCost({
                                subtotal,
                                shipping,
                                tax,
                                total,
                            });
                        } else {
                            setSnackbarData({
                                visible: true,
                                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                                text: SNACKBAR_MESSAGE_FINAL_TAX_TRANSACTION_ERROR,
                                icon: PixelCartIcon,
                                hasFailure: true,
                            });
                            if (user && currentSessionId) {
                                recordUserAction({
                                    type: USER_ACTION_TYPE.error,
                                    userId: user.id,
                                    sessionId: currentSessionId,
                                    payload: {
                                        error: SNACKBAR_MESSAGE_FINAL_TAX_TRANSACTION_ERROR,
                                    },
                                });
                            }
                        }
                    });
                } else {
                    setWaitForTaxTransaction(true);
                    setCheckForTaxTransactionTrigger(!checkForTaxTransactionTrigger);
                }

                // Restore relevant checkout session details
                const physicalIds = JSON.parse(paymentIntent.metadata.physicalIds)! as string[];
                setCurrentCheckoutSessionId(paymentIntentId);
                setPhysicalItemsInCart(physicalIds);
                if (physicalIds.length === 0) {
                    setSelectedBillingAddressType({
                        title: 'Use a different billing address',
                        value: BILLING_ADDRESS_TYPE.different,
                    });
                }
                stripeGetPromotions({
                    test: process.env.NODE_ENV === 'development',
                    paymentIntentId,
                }).then((promotionMap) => {
                    const promotions: Stripe.Coupon[] = Array.from(promotionMap.values());
                    const promotionIds: string[] = promotions.map((promotion) => promotion.id);
                    if (promotionIds.length > 0) {
                        // Apply promotions locally only if same cart
                        setAppliedPromotions(promotions);
                    }
                });
            }
        }

        if (!attemptedRestoringCheckoutSessionAfterPayment) {
            setAttemptedRestoringCheckoutSessionAfterPayment(true);
        }
    };

    const resumeCheckoutSession = async (): Promise<void> => {
        if (
            user
            && fetchedStoreItems
            && attemptedRestoringCheckoutSessionAfterPayment
            && !attemptedResumingPreviousCheckoutSession
            && !currentCheckoutSessionId
            && !stripePaymentIntentId
        ) {
            const db = getFirestore();
            const checkoutSessionsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.checkoutSessions
                : FIRESTORE_COLLECTION.stagingCheckoutSessions;
            const checkoutSessionQuery = query(
                collection(db, checkoutSessionsCollection),
                where('userId', '==', user.id),
            );
            const checkoutSessionSnap = await getDocs(checkoutSessionQuery);
            const checkoutSessionItems: ICheckoutSessionItem[] = [];
            checkoutSessionSnap.forEach((document) => {
                if (document.exists()) {
                    const item = document.data() as ICheckoutSessionItem;
                    if (
                        item.status[item.status.length - 1].value !== CHECKOUT_SESSION_STATUS_TYPE.completed
                        && item.status[item.status.length - 1].value !== CHECKOUT_SESSION_STATUS_TYPE.abandoned
                    ) {
                        checkoutSessionItems.push(item);
                    }
                }
            });
            if (checkoutSessionItems.length > 0) {
                // Sort from newest checkout item to oldest checkout
                checkoutSessionItems.sort((a, b) => b.timestamp - a.timestamp);
                // Determine if most recent checkout session is incomplete but valid
                const mostRecentCheckoutSession = {
                    ...checkoutSessionItems[0],
                };
                const lastHour = new Date();
                lastHour.setHours(new Date().getHours() - 1);
                if (mostRecentCheckoutSession.status[mostRecentCheckoutSession.status.length - 1].timestamp > lastHour.getTime()) {
                    // Resume checkout session
                    let sameCart = true;
                    let updatedCarts = [...mostRecentCheckoutSession.carts];
                    const paymentIntent = await stripeGetPaymentIntent({
                        test: process.env.NODE_ENV === 'development',
                        paymentIntentId: mostRecentCheckoutSession.paymentIntentId,
                    });
                    let physicalIds = JSON.parse(paymentIntent.metadata.physicalIds)! as string[];
                    if (user.cart) {
                        sameCart = isSameCart(
                            user.cart,
                            mostRecentCheckoutSession.carts[mostRecentCheckoutSession.carts.length - 1].items,
                        );
                    } else {
                        sameCart = false;
                    }

                    const sessionRef = doc(
                        db,
                        checkoutSessionsCollection,
                        mostRecentCheckoutSession.paymentIntentId,
                    );
                    if (!sameCart && user.cart) {
                        // Update cart history
                        updatedCarts = [
                            ...updatedCarts,
                            {
                                id: uuidv4(),
                                timestamp: Date.now(),
                                items: [...user.cart],
                            },
                        ];
                        // Update payment intent cart items
                        stripeUpdatePaymentIntent({
                            test: process.env.NODE_ENV === 'development',
                            paymentIntentId: mostRecentCheckoutSession.paymentIntentId,
                            cartItems: [...user.cart],
                            // clear promotions if not the same cart
                            promotionIds: [],
                        });
                        // Update physical ids
                        physicalIds = user.cart.filter((item) => {
                            const storeItem = storeItems.get(item.priceId);
                            if (storeItem) return storeItem.physical;
                            return false;
                        }).map((item) => item.priceId);

                        // Update checkout session cart history and status
                        runTransaction(db, async (transaction) => {
                            transaction.update(sessionRef, {
                                // change state to resumed
                                status: [
                                    ...mostRecentCheckoutSession.status,
                                    {
                                        timestamp: Date.now(),
                                        value: CHECKOUT_SESSION_STATUS_TYPE.resumed,
                                    },
                                ],
                                carts: updatedCarts,
                                updated: Date.now(),
                            });
                        });
                    } else {
                        runTransaction(db, async (transaction) => {
                            transaction.update(sessionRef, {
                                // only update checkout status
                                status: [
                                    ...mostRecentCheckoutSession.status,
                                    {
                                        timestamp: Date.now(),
                                        value: CHECKOUT_SESSION_STATUS_TYPE.resumed,
                                    },
                                ],
                                updated: Date.now(),
                            });
                        });
                    }

                    // Update promotions
                    stripeGetPromotions({
                        test: process.env.NODE_ENV === 'development',
                        paymentIntentId: mostRecentCheckoutSession.paymentIntentId,
                    }).then((promotionMap) => {
                        const promotions: Stripe.Coupon[] = Array.from(promotionMap.values());
                        const promotionIds: string[] = promotions.map((promotion) => promotion.id);
                        runTransaction(db, async (transaction) => {
                            transaction.update(sessionRef, {
                                // Clear promotions if not the same cart
                                promotionIds: sameCart && promotionIds.length > 0
                                    ? promotionIds
                                    : [],
                                updated: Date.now(),
                            });
                        });
                        // Apply promotions locally only if same cart
                        if (promotionIds.length > 0 && sameCart) {
                            setAppliedPromotions(promotions);
                            const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                                ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                                : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
                            const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                                ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                                : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
                            const subtotal = updatedCarts[updatedCarts.length - 1].items.reduce((
                                total: number,
                                item: ICartItem,
                            ) => {
                                const storeItem = storeItems.get(item.priceId);
                                if (storeItem && storeItem.amount) {
                                    let appliedDigitalBookCutPromotion = false;
                                    let appliedWebBookCutPromotion = false;
                                    let cumulativeAmount = 0;
                                    for (let i = 0; i < item.quantity; i += 1) {
                                        let cumulativeDiscount = 0;
                                        if (promotions.length > 0) {
                                            for (let j = 0; j < promotions.length; j += 1) {
                                                const promotion = promotions[j];
                                                const discountedAmount = applyPromotionToCheckoutItem(
                                                    storeItem,
                                                    promotion,
                                                );
                                                if (
                                                    !(
                                                        promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                                        && appliedWebBookCutPromotion
                                                    ) && !(
                                                        promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                                        && appliedDigitalBookCutPromotion
                                                    )
                                                ) {
                                                    cumulativeDiscount += storeItem.amount! - discountedAmount;
                                                }
                                                if (
                                                    promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                                    && !appliedWebBookCutPromotion
                                                ) {
                                                    appliedWebBookCutPromotion = true;
                                                } else if (
                                                    promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                                    && !appliedDigitalBookCutPromotion
                                                ) {
                                                    appliedDigitalBookCutPromotion = true;
                                                }
                                            }
                                        }
                                        cumulativeAmount += storeItem.amount - cumulativeDiscount;
                                    }
                                    return total + cumulativeAmount;
                                }
                                return total;
                            }, 0);
                            setOrderCost({
                                subtotal,
                                // we ignore shipping because we want customer to pick a new rate
                                // old rate may no longer be available
                            });
                        }
                    });
                    setCurrentCheckoutSessionId(mostRecentCheckoutSession.paymentIntentId);
                    setPhysicalItemsInCart(physicalIds);
                    if (physicalIds.length === 0) {
                        setSelectedBillingAddressType({
                            title: 'Use a different billing address',
                            value: BILLING_ADDRESS_TYPE.different,
                        });
                    }
                    setStripeClientSecret(paymentIntent.client_secret || undefined);
                    setStripePaymentIntentId(mostRecentCheckoutSession.paymentIntentId);
                } else {
                    // Checkout session is expired
                    // We keep in checkout sessions array to abandon it
                }
            } else {
                // No previous checkout session to resume
                // We will create a new checkout in another method
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_RESUME_CHECKOUT_SESSION_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });
            if (user && currentSessionId) {
                recordUserAction({
                    type: USER_ACTION_TYPE.error,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        error: SNACKBAR_MESSAGE_RESUME_CHECKOUT_SESSION_ERROR,
                    },
                });
            }
        }
        if (!attemptedResumingPreviousCheckoutSession) {
            setAttemptedResumingPreviousCheckoutSession(true);
        }
    };

    const createPaymentIntent = async (): Promise<void> => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && attemptedResumingPreviousCheckoutSession
            && attemptedRestoringCheckoutSessionAfterPayment
            && !currentCheckoutSessionId
            && !stripePaymentIntentId
        ) {
            try {
                const auth = getAuth();
                // Create PaymentIntent as soon as the page loads
                const paymentIntentArgs: IStripePaymentIntentArgs = {
                    test: process.env.NODE_ENV === 'development',
                    operation: STRIPE_PAYMENT_INTENT_OPERATION_TYPE.create,
                    cartItems: user!.cart!,
                    userId: user.id,
                    userEmail: user.email || auth.currentUser?.email || undefined,
                };
                if (user.stripeCustomerId) paymentIntentArgs.customerId = user.stripeCustomerId;
                const paymentIntent = await stripeCreatePaymentIntent(paymentIntentArgs);
                const physicalIds = JSON.parse(paymentIntent.metadata.physicalIds) as string[];
                setPhysicalItemsInCart(physicalIds);
                if (physicalIds.length === 0) {
                    setSelectedBillingAddressType({
                        title: 'Use a different billing address',
                        value: BILLING_ADDRESS_TYPE.different,
                    });
                }
                setStripeClientSecret(paymentIntent.client_secret || undefined);
                setStripePaymentIntentId(paymentIntent.id);
            } catch (e) {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_STRIPE_PAYMENT_INTENT_GENERAL_ERROR((e as any).toString()),
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (user && currentSessionId) {
                    recordUserAction({
                        type: USER_ACTION_TYPE.error,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            error: SNACKBAR_MESSAGE_STRIPE_PAYMENT_INTENT_GENERAL_ERROR((e as any).toString()),
                        },
                    });
                }
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_STRIPE_PAYMENT_INTENT_USER_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });
            if (user && currentSessionId) {
                recordUserAction({
                    type: USER_ACTION_TYPE.error,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        error: SNACKBAR_MESSAGE_STRIPE_PAYMENT_INTENT_USER_ERROR,
                    },
                });
            }
        }
    };

    const createCheckoutSession = async (): Promise<void> => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && !!stripePaymentIntentId
        ) {
            // Create new checkout session
            const db = getFirestore();
            const checkoutSessionsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.checkoutSessions
                : FIRESTORE_COLLECTION.stagingCheckoutSessions;
            const session: ICheckoutSessionItem = {
                paymentIntentId: stripePaymentIntentId,
                userId: user.id,
                timestamp: Date.now(),
                status: [{
                    timestamp: Date.now(),
                    value: CHECKOUT_SESSION_STATUS_TYPE.created,
                }],
                updated: Date.now(),
                carts: [{
                    id: uuidv4(),
                    timestamp: Date.now(),
                    items: user.cart,
                }],
            };
            // Add to firestore
            const sessionRef = doc(db, checkoutSessionsCollection, session.paymentIntentId);
            setDoc(sessionRef, session);
            // Save to state
            setCurrentCheckoutSessionId(session.paymentIntentId);
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_CREATE_CHECKOUT_SESSION_ERROR,
                icon: PixelCartIcon,
                hasFailure: true,
            });
            if (user && currentSessionId) {
                recordUserAction({
                    type: USER_ACTION_TYPE.error,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        error: SNACKBAR_MESSAGE_CREATE_CHECKOUT_SESSION_ERROR,
                    },
                });
            }
        }
    };

    const determineBookDiscountEligibility = async (): Promise<void> => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && stripePaymentIntentId
        ) {
            const test = process.env.NODE_ENV === 'development';
            let promotionApplied = false;
            let updatedPaymentIntent: Stripe.PaymentIntent | undefined;
            if (
                purchasedDigitalBookSessionIds.length > 0
                && purchasedPhysicalBookSessionIds.length === 0 // We only allow to reedem once
                && !!user.cart!.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
            ) {
                updatedPaymentIntent = await stripeApplyDigitalBookDiscount({
                    test,
                    salePaymentIntentId: purchasedDigitalBookSessionIds[0],
                    currPaymentIntentId: stripePaymentIntentId,
                });
                promotionApplied = true;
            } else if (
                purchasedWebBookSessionIds.length > 0
                && (
                    purchasedDigitalBookSessionIds.length === 0 // We only allow to reedem once
                    && !!user.cart!.find((item: ICartItem) => item.priceId === DIGITAL_BOOK_PRICE_ID)
                )
                && (
                    purchasedPhysicalBookSessionIds.length === 0 // We only allow to reedem once
                    && !!user.cart!.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                )
            ) {
                updatedPaymentIntent = await stripeApplyWebBookDiscount({
                    test,
                    salePaymentIntentId: purchasedWebBookSessionIds[0],
                    currPaymentIntentId: stripePaymentIntentId,
                });
                promotionApplied = true;
            } else {
                // Either no physical book or digital book in customer cart
                // or customer has not bought a previous book that makes them eligible for a discount
            }

            let updatedPromotions: Stripe.Coupon[] | undefined;
            if (promotionApplied) {
                const db = getFirestore();
                const promotionMap = await stripeGetPromotions({
                    test: process.env.NODE_ENV === 'development',
                    paymentIntentId: stripePaymentIntentId,
                });

                if (promotionMap.size > 0) {
                    // Change locally
                    updatedPromotions = Array.from(promotionMap.values());
                    const promotionIds: string[] = updatedPromotions.map((promotion) => promotion.id);
                    setAppliedPromotions(updatedPromotions);

                    // Change in firestore
                    const checkoutSessionsCollection = process.env.NODE_ENV === 'production'
                        ? FIRESTORE_COLLECTION.checkoutSessions
                        : FIRESTORE_COLLECTION.stagingCheckoutSessions;
                    runTransaction(db, async (transaction) => {
                        const sessionRef = doc(
                            db,
                            checkoutSessionsCollection,
                            stripePaymentIntentId,
                        );
                        transaction.update(sessionRef, {
                            updated: Date.now(),
                            promotionIds,
                        });
                    });
                }
            } else {
                // No promotions applied
            }

            // Even if tax was calculated before, we must calculate again
            // thus we dicard previous tax calculations
            const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
            const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
            const subtotal = user.cart.reduce((
                total: number,
                item: ICartItem,
            ) => {
                const storeItem = storeItems.get(item.priceId);
                if (storeItem && storeItem.amount) {
                    let appliedDigitalBookCutPromotion = false;
                    let appliedWebBookCutPromotion = false;
                    let cumulativeAmount = 0;
                    for (let i = 0; i < item.quantity; i += 1) {
                        let cumulativeDiscount = 0;
                        if (
                            updatedPromotions
                            && updatedPromotions.length > 0
                        ) {
                            for (let j = 0; j < updatedPromotions.length; j += 1) {
                                const promotion = updatedPromotions[j];
                                const discountedAmount = applyPromotionToCheckoutItem(
                                    storeItem,
                                    promotion,
                                );
                                if (
                                    !(
                                        promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                        && appliedWebBookCutPromotion
                                    ) && !(
                                        promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                        && appliedDigitalBookCutPromotion
                                    )
                                ) {
                                    cumulativeDiscount += storeItem.amount! - discountedAmount;
                                }
                                if (
                                    promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                    && !appliedWebBookCutPromotion
                                ) {
                                    appliedWebBookCutPromotion = true;
                                } else if (
                                    promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                    && !appliedDigitalBookCutPromotion
                                ) {
                                    appliedDigitalBookCutPromotion = true;
                                }
                            }
                        }
                        cumulativeAmount += storeItem.amount - cumulativeDiscount;
                    }
                    return total + cumulativeAmount;
                }
                return total;
            }, 0);
            setOrderCost({
                subtotal,
                // we ignore shipping because we want customer to pick a new rate
                // old rate may no longer be available
            });
        }
    };

    const onPromotionCodeToggleButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.promotionCodeToggleButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onPromotionCodeToggleButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onPromotionCodeToggleButtonClick = (): void => {
        setPromotionCodeInputIsVisible(!promotionCodeInputIsVisible);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.togglePromotionCode,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    expanded: !promotionCodeInputIsVisible,
                },
            });
        }
    };

    const onPromotionCodeInputEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.input,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onPromotionCodeInputLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onPromotionCodeInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && buttonClickClip.current) {
            buttonClickClip.current.pause();
            buttonClickClip.current.currentTime = 0;
            playAudio(buttonClickClip.current);
        }
    };

    const onPromotionCodeInputBlur = (): void => {
        setInputFocused(false);
    };

    const onPromotionCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (promotionCodeInvalid) setPromotionCodeInvalid(false);
        setPromotionCode(e.target.value);
    };

    /**
     * Manages tracking enter key presses during changes to promotion code input
     * @param e keyboard event
     */
    const checkForEnter = async (e: React.KeyboardEvent): Promise<void> => {
        if (e.key === KEYCODE.enter) {
            // Cancel the default action, if needed
            e.preventDefault();

            // Promotion code field is focused
            // Attempt to apply code
            if (document.activeElement === promotionCodeInputRef.current) {
                // Note that ref.current may be null. This is expected, because you may
                // conditionally render the ref-ed element, or you may forgot to assign it
                if (!promotionCodeInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(PROMOTION_CODE_INPUT_REF_NAME));
                const code = promotionCodeInputRef.current.value;
                onPromotionCodeApply(code);
            }
        } else if (e.key === KEYCODE.escape) {
            // Cancel the default action, if needed
            e.preventDefault();

            // Note that ref.current may be null. This is expected, because you may
            // conditionally render the ref-ed element, or you may forgot to assign it
            if (!promotionCodeInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(PROMOTION_CODE_INPUT_REF_NAME));

            // Blur Input
            promotionCodeInputRef.current.blur();
        }
    };

    const onPromotionCodeAddButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.promotionCodeAddButton,
            [promotionCodeInvalid ? CURSOR_SIGN.caution : CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onPromotionCodeAddButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onPromotionCodeAddButtonClick = async (): Promise<void> => {
        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!promotionCodeInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(PROMOTION_CODE_INPUT_REF_NAME));
        const code = promotionCodeInputRef.current.value;
        onPromotionCodeApply(code);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.applyPromotionCode,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    code,
                },
            });
        }
    };

    const validatePromotionCode = async (code: string): Promise<Stripe.Coupon | undefined> => {
        const validPromotion = await stripePromotionIsValid({
            test: process.env.NODE_ENV === 'development',
            promotionCode: code,
        });

        if (
            !!validPromotion
            && (
                appliedPromotions.length === 0
                || !appliedPromotions.map((promotion) => promotion.id).includes(validPromotion.id)
            )
        ) {
            return validPromotion;
        }

        return undefined;
    };

    const onPromotionCodeApply = async (code: string): Promise<void> => {
        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!promotionCodeInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(PROMOTION_CODE_INPUT_REF_NAME));
        promotionCodeInputRef.current.value = '';

        setIsValidatingPromotionCode(true);
        const validPromotion = await validatePromotionCode(code);
        if (validPromotion) {
            onSubmitPromotionCode(validPromotion);
            if (promotionCodeInvalid) setPromotionCodeInvalid(false);
        } else {
            setIsValidatingPromotionCode(false);
            if (!promotionCodeInvalid) setPromotionCodeInvalid(true);
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_INVALID_PROMOTION_CODE_ERROR(code),
                icon: PixelCartIcon,
                hasFailure: true,
            });
            if (user && currentSessionId) {
                recordUserAction({
                    type: USER_ACTION_TYPE.error,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        error: SNACKBAR_MESSAGE_INVALID_PROMOTION_CODE_ERROR(code),
                    },
                });
            }
        }
    };

    const onSubmitPromotionCode = async (code: Stripe.Coupon): Promise<void> => {
        // apply promotion code
        const cachedCode = promotionCode;
        const updatedPromotions = [...appliedPromotions, code];
        const promotionIds = updatedPromotions.map((promotion) => promotion.id);
        if (code && stripePaymentIntentId) {
            // Change locally
            setPromotionCode('');

            // Change in firestore
            const db = getFirestore();
            const checkoutSessionsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.checkoutSessions
                : FIRESTORE_COLLECTION.stagingCheckoutSessions;
            runTransaction(db, async (transaction) => {
                const sessionRef = doc(
                    db,
                    checkoutSessionsCollection,
                    stripePaymentIntentId,
                );
                transaction.update(sessionRef, {
                    updated: Date.now(),
                    promotionIds,
                });
            });

            // Change in stripe
            const updatedPaymentIntent = await stripeUpdatePaymentIntent({
                test: process.env.NODE_ENV === 'development',
                paymentIntentId: stripePaymentIntentId,
                promotionIds,
            });

            // Update order cost
            if (
                updatedPaymentIntent.metadata
                && updatedPaymentIntent.metadata.taxCalculationId
                && updatedPaymentIntent.metadata.taxCalculationId !== stripeTaxCalculationId
            ) {
                stripeGetTaxCalculationLineItems({
                    test: process.env.NODE_ENV === 'development',
                    taxCalculationId: updatedPaymentIntent.metadata.taxCalculationId,
                }).then((taxCalculationLineItems) => {
                    const subtotal = taxCalculationLineItems.reduce((
                        sum: number,
                        lineItem: Stripe.Tax.CalculationLineItem,
                    ) => (
                        sum + (lineItem.quantity * lineItem.amount)
                    ), 0);
                    let shipping: number | undefined;
                    if (updatedPaymentIntent.metadata.shippingRate) {
                        const rate = JSON.parse(updatedPaymentIntent.metadata.shippingRate) as IShippoRateObject;
                        shipping = parseFloat(rate.amount) * CENTS_IN_A_DOLLAR;
                    }
                    let tax = taxCalculationLineItems.reduce((
                        sum: number,
                        lineItem: Stripe.Tax.CalculationLineItem,
                    ) => (
                        sum + (lineItem.quantity * lineItem.amount_tax)
                    ), 0);
                    if (updatedPaymentIntent.metadata.shippingTax) {
                        tax += parseInt(updatedPaymentIntent.metadata.shippingTax, 10);
                    }
                    const total = !!subtotal
                        && (
                            shipping !== undefined
                            || physicalItemsInCart.length === 0
                        )
                        && tax !== undefined
                        ? subtotal + (shipping || 0) + tax
                        : undefined;
                    setOrderCost({
                        subtotal,
                        shipping,
                        tax,
                        total,
                    });
                    setIsValidatingPromotionCode(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_APPLY_PROMOTION_CODE_SUCCESS(cachedCode),
                        icon: BoxIcon,
                        hasSuccess: true,
                    });
                    // Play Sound
                    if (hasSound && successClip.current) {
                        successClip.current.pause();
                        successClip.current.currentTime = 0;
                        playAudio(successClip.current);
                    }
                    // We want order cost to update along with item price change triggered by this
                    setAppliedPromotions(updatedPromotions);
                    setStripeTaxCalculationId(updatedPaymentIntent.metadata.taxCalculationId);
                });
            } else if (user?.cart) {
                const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                    ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                    : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
                const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                    ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                    : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
                const subtotal = user.cart.reduce((
                    total: number,
                    item: ICartItem,
                ) => {
                    const storeItem = storeItems.get(item.priceId);
                    if (storeItem && storeItem.amount) {
                        let appliedDigitalBookCutPromotion = false;
                        let appliedWebBookCutPromotion = false;
                        let cumulativeAmount = 0;
                        for (let i = 0; i < item.quantity; i += 1) {
                            let cumulativeDiscount = 0;
                            if (updatedPromotions.length > 0) {
                                for (let j = 0; j < updatedPromotions.length; j += 1) {
                                    const promotion = updatedPromotions[j];
                                    const discountedAmount = applyPromotionToCheckoutItem(
                                        storeItem,
                                        promotion,
                                    );
                                    if (
                                        !(
                                            promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                            && appliedWebBookCutPromotion
                                        ) && !(
                                            promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                            && appliedDigitalBookCutPromotion
                                        )
                                    ) {
                                        cumulativeDiscount += storeItem.amount! - discountedAmount;
                                    }
                                    if (
                                        promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                        && !appliedWebBookCutPromotion
                                    ) {
                                        appliedWebBookCutPromotion = true;
                                    } else if (
                                        promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                        && !appliedDigitalBookCutPromotion
                                    ) {
                                        appliedDigitalBookCutPromotion = true;
                                    }
                                }
                            }
                            cumulativeAmount += storeItem.amount - cumulativeDiscount;
                        }
                        return total + cumulativeAmount;
                    }
                    return total;
                }, 0);
                let shipping: number | undefined;
                if (updatedPaymentIntent.metadata.shippingRate) {
                    const rate = JSON.parse(updatedPaymentIntent.metadata.shippingRate) as IShippoRateObject;
                    shipping = parseFloat(rate.amount) * CENTS_IN_A_DOLLAR;
                }
                setOrderCost({
                    subtotal,
                    shipping,
                });
                setIsValidatingPromotionCode(false);
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_APPLY_PROMOTION_CODE_SUCCESS(cachedCode),
                    icon: BoxIcon,
                    hasSuccess: true,
                });
                // Play Sound
                if (hasSound && successClip.current) {
                    successClip.current.pause();
                    successClip.current.currentTime = 0;
                    playAudio(successClip.current);
                }
                // We want order cost to update along with item price change triggered by this
                setAppliedPromotions(updatedPromotions);
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_APPLY_PROMOTION_CODE_ERROR(cachedCode),
                icon: PixelCartIcon,
                hasFailure: true,
            });
            if (user && currentSessionId) {
                recordUserAction({
                    type: USER_ACTION_TYPE.error,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        error: SNACKBAR_MESSAGE_APPLY_PROMOTION_CODE_ERROR(cachedCode),
                    },
                });
            }
        }
    };

    const onPromotionCodeBadgeRemoveButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.promotionCodeBadgeRemoveButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onPromotionCodeBadgeRemoveButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onPromotionCodeBadgeRemoveButtonClick = async (
        promotionId: string,
        e: React.MouseEvent | React.TouchEvent,
    ): Promise<void> => {
        onCursorLeave(e);

        // Change locally
        const updatedPromotions = appliedPromotions.filter((promotion) => promotion.id !== promotionId);
        setAppliedPromotions([...updatedPromotions]);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.removePromotionCodeBadge,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    promotionId,
                },
            });
        }

        // Play Sound
        if (hasSound && deleteClip.current) {
            deleteClip.current.pause();
            deleteClip.current.currentTime = 0;
            playAudio(deleteClip.current);
        }

        if (stripePaymentIntentId) {
            const promotionIds = [...updatedPromotions.map(((promotion) => promotion.id))];

            // Change in firestore
            const db = getFirestore();
            const checkoutSessionsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.checkoutSessions
                : FIRESTORE_COLLECTION.stagingCheckoutSessions;
            runTransaction(db, async (transaction) => {
                const sessionRef = doc(
                    db,
                    checkoutSessionsCollection,
                    stripePaymentIntentId,
                );
                transaction.update(sessionRef, {
                    updated: Date.now(),
                    promotionIds,
                });
            });

            // Change in stripe
            const updatedPaymentIntent = await stripeUpdatePaymentIntent({
                test: process.env.NODE_ENV === 'development',
                paymentIntentId: stripePaymentIntentId,
                promotionIds,
            });

            // Update order cost
            if (
                updatedPaymentIntent.metadata
                && updatedPaymentIntent.metadata.taxCalculationId
                && updatedPaymentIntent.metadata.taxCalculationId !== stripeTaxCalculationId
            ) {
                stripeGetTaxCalculationLineItems({
                    test: process.env.NODE_ENV === 'development',
                    taxCalculationId: updatedPaymentIntent.metadata.taxCalculationId,
                }).then((taxCalculationLineItems) => {
                    const subtotal = taxCalculationLineItems.reduce((
                        sum: number,
                        lineItem: Stripe.Tax.CalculationLineItem,
                    ) => (
                        sum + (lineItem.quantity * lineItem.amount)
                    ), 0);
                    let shipping: number | undefined;
                    if (updatedPaymentIntent.metadata.shippingRate) {
                        const rate = JSON.parse(updatedPaymentIntent.metadata.shippingRate) as IShippoRateObject;
                        shipping = parseFloat(rate.amount) * CENTS_IN_A_DOLLAR;
                    }
                    let tax = taxCalculationLineItems.reduce((
                        sum: number,
                        lineItem: Stripe.Tax.CalculationLineItem,
                    ) => (
                        sum + (lineItem.quantity * lineItem.amount_tax)
                    ), 0);
                    if (updatedPaymentIntent.metadata.shippingTax) {
                        tax += parseInt(updatedPaymentIntent.metadata.shippingTax, 10);
                    }
                    const total = !!subtotal
                        && (
                            shipping !== undefined
                            || physicalItemsInCart.length === 0
                        )
                        && tax !== undefined
                        ? subtotal + (shipping || 0) + tax
                        : undefined;
                    setOrderCost({
                        subtotal,
                        shipping,
                        tax,
                        total,
                    });
                    setStripeTaxCalculationId(updatedPaymentIntent.metadata.taxCalculationId);
                });
            } else if (user?.cart) {
                const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                    ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                    : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
                const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                    ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                    : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
                const subtotal = user.cart.reduce((
                    total: number,
                    item: ICartItem,
                ) => {
                    const storeItem = storeItems.get(item.priceId);
                    if (storeItem && storeItem.amount) {
                        let appliedDigitalBookCutPromotion = false;
                        let appliedWebBookCutPromotion = false;
                        let cumulativeAmount = 0;
                        for (let i = 0; i < item.quantity; i += 1) {
                            let cumulativeDiscount = 0;
                            if (updatedPromotions.length > 0) {
                                for (let j = 0; j < updatedPromotions.length; j += 1) {
                                    const promotion = updatedPromotions[j];
                                    const discountedAmount = applyPromotionToCheckoutItem(
                                        storeItem,
                                        promotion,
                                    );
                                    if (
                                        !(
                                            promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                            && appliedWebBookCutPromotion
                                        ) && !(
                                            promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                            && appliedDigitalBookCutPromotion
                                        )
                                    ) {
                                        cumulativeDiscount += storeItem.amount! - discountedAmount;
                                    }
                                    if (
                                        promotion.id === WEB_BOOK_STRIPE_COUPON_ID
                                        && !appliedWebBookCutPromotion
                                    ) {
                                        appliedWebBookCutPromotion = true;
                                    } else if (
                                        promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID
                                        && !appliedDigitalBookCutPromotion
                                    ) {
                                        appliedDigitalBookCutPromotion = true;
                                    }
                                }
                            }
                            cumulativeAmount += storeItem.amount - cumulativeDiscount;
                        }
                        return total + cumulativeAmount;
                    }
                    return total;
                }, 0);
                let shipping: number | undefined;
                if (updatedPaymentIntent.metadata.shippingRate) {
                    const rate = JSON.parse(updatedPaymentIntent.metadata.shippingRate) as IShippoRateObject;
                    shipping = parseFloat(rate.amount) * CENTS_IN_A_DOLLAR;
                }
                setOrderCost({
                    subtotal,
                    shipping,
                });
            }
        }
    };

    const onOrderSummaryToggleButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.orderSummaryToggleButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onOrderSummaryToggleButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onOrderSummaryToggleButtonClick = (): void => {
        setOrderSummaryIsVisible(!orderSummaryIsVisible);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.toggleOrderSummary,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    expanded: !orderSummaryIsVisible,
                },
            });
        }
    };

    const checkForTaxTransaction = async (): Promise<void> => {
        if (stripePaymentIntentId) {
            const paymentIntent = await stripeGetPaymentIntent({
                test: process.env.NODE_ENV === 'development',
                paymentIntentId: stripePaymentIntentId,
            });
            if (paymentIntent?.metadata.taxTransactionId) {
                stripeGetTaxTransaction({
                    test: process.env.NODE_ENV === 'development',
                    taxTransactionId: paymentIntent.metadata.taxTransactionId,
                }).then((taxTransaction) => {
                    setWaitForTaxTransaction(false);
                    if (taxTransaction.line_items) {
                        const subtotal = taxTransaction.line_items.data.reduce((
                            sum: number,
                            lineItem: Stripe.Tax.TransactionLineItem,
                        ) => (
                            sum + (lineItem.quantity * lineItem.amount)
                        ), 0);
                        let shipping: number | undefined;
                        if (taxTransaction.shipping_cost) {
                            shipping = taxTransaction.shipping_cost.amount;
                        }
                        let tax = taxTransaction.line_items.data.reduce((
                            sum: number,
                            lineItem: Stripe.Tax.TransactionLineItem,
                        ) => (
                            sum + (lineItem.quantity * lineItem.amount_tax)
                        ), 0);
                        if (taxTransaction.shipping_cost) {
                            tax += taxTransaction.shipping_cost.amount_tax;
                        }
                        const total = shipping !== undefined
                            ? subtotal + shipping + tax
                            : subtotal + tax;
                        setOrderCost({
                            subtotal,
                            shipping,
                            tax,
                            total,
                        });
                    }
                });
            } else {
                setCheckForTaxTransactionTrigger(!checkForTaxTransactionTrigger);
            }
        }
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'Checkout',
        );
    }, []);

    /**
     * Records View Checkout Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordCheckoutPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            successClip.current
            && deleteClip.current
            && buttonClickClip.current
        ) {
            // Success
            successClip.current.volume = DEFAULT_AUDIO_VOLUME;
            successClip.current.src = Success;

            // Delete
            deleteClip.current.volume = DEFAULT_AUDIO_VOLUME;
            deleteClip.current.src = Delete;

            // Button Click
            buttonClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            buttonClickClip.current.src = ButtonClick;
        }

        return function cleanup() {
            if (successClip.current) successClip.current.remove();
            if (deleteClip.current) deleteClip.current.remove();
            if (buttonClickClip.current) buttonClickClip.current.remove();
        };
    }, []);

    /**
     * Redirects user if they're on a page they shouldn't be on
     */
    useEffect(() => {
        if (
            user
            && !!stripePaymentIntentId // ensures checkout session and payment intent estabished
            && !stripePaymentSucceeded
        ) {
            if (
                // No checkpoint
                !params.checkpoint
                // Invalid checkpoint
                || (
                    params.checkpoint !== CHECKOUT_CHECKPOINT.info
                    && params.checkpoint !== CHECKOUT_CHECKPOINT.shipping
                    && params.checkpoint !== CHECKOUT_CHECKPOINT.billing
                    && params.checkpoint !== CHECKOUT_CHECKPOINT.review
                    && params.checkpoint !== CHECKOUT_CHECKPOINT.confirmation
                )
            ) {
                navigate(
                    `/${PAGE_ROUTE.cart}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );
            } else if (
                params.checkpoint === CHECKOUT_CHECKPOINT.shipping
                && physicalItemsInCart.length > 0
                && (
                    !addressIsValid // Address is valid when we load page
                    || customerStreetOne.length === 0
                )
            ) {
                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.info}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );
            } else if (
                params.checkpoint === CHECKOUT_CHECKPOINT.billing
                && physicalItemsInCart.length > 0
                && !selectedShippingRateID
            ) {
                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.shipping}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );
            } else if (
                (
                    params.checkpoint === CHECKOUT_CHECKPOINT.info
                    || params.checkpoint === CHECKOUT_CHECKPOINT.shipping
                )
                && physicalItemsInCart.length === 0
            ) {
                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );
            } else if (
                params.checkpoint === CHECKOUT_CHECKPOINT.review
                && (
                    !selectedBillingAddressType
                    || (
                        selectedBillingAddressType.value === BILLING_ADDRESS_TYPE.different
                        && (
                            !billingAddressIsValid
                            || customerBillingStreetOne.length === 0
                        )
                    ) || (
                        physicalItemsInCart.length > 0
                        && !selectedShippingRateID
                    ) || (
                        physicalItemsInCart.length > 0
                        && (
                            !addressIsValid
                            || customerStreetOne.length === 0
                        )
                    )
                )
            ) {
                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );
            } else if (params.checkpoint === CHECKOUT_CHECKPOINT.confirmation) {
                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.info}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );
            }
        } else if (
            // Empty cart
            user
            && (
                !user.cart
                || user.cart.length === 0
            ) && attemptedRestoringCheckoutSessionAfterPayment
            && !stripePaymentSucceeded
        ) {
            navigate(
                `/${PAGE_ROUTE.cart}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        }
    }, [
        user,
        params.checkpoint,
        physicalItemsInCart,
        stripePaymentIntentId,
        addressIsValid,
        selectedShippingRateID,
        selectedBillingAddressType,
        billingAddressIsValid,
        customerStreetOne,
        customerBillingStreetOne,
        attemptedRestoringCheckoutSessionAfterPayment,
    ]);

    /**
     * Manages Checkpoint Placeholder Ellipsis Changes
     */
    useInterval(() => {
        handlePlaceholderEllipsisText();
    }, isRunningCheckpointPlaceholderInterval ? CHECKPOINT_PLACEHOLDER_ELLIPSIS_INTERVAL_DURATION : null);

    /**
     * Fetches and saves strore items
     */
    useEffect(() => {
        fetchStoreItems();
    }, []);

    /**
     * Manages checking whether user has bought a book in the past
     */
    useEffect(() => {
        if (
            user
            && !checkedBookPurchases
        ) {
            determineBookPurchases();
        }
    }, [user]);

    /**
     * Manages checking whether user has bought a book in the past
     */
    useEffect(() => {
        if (
            user
            && !checkedPaymentMethods
        ) {
            determinePaymentMethods();
        }
    }, [user]);

    /**
     * Calculates cart subtotal
     */
    useEffect(() => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && fetchedStoreItems
            && storeItems.size > 0
            && !orderCost
            && attemptedRestoringCheckoutSessionAfterPayment
            && !stripePaymentSucceeded
        ) {
            // should always be true if we remain on checkout page
            // if it's empty we are rerouted to the cart page
            calculateCartSubTotal();
        }
    }, [
        user,
        fetchedStoreItems,
        attemptedRestoringCheckoutSessionAfterPayment,
    ]);

    /**
     * Attempts to restore checkout session after payment redirect
     */
    useEffect(() => {
        const clientSecret = searchParams.get('payment_intent_client_secret');
        const paymentIntentId = searchParams.get('payment_intent');
        if (
            user
            && clientSecret
            && paymentIntentId
            && fetchedStoreItems
        ) {
            // must be before we set stripe payment intent id
            // otherwise will redirect to another page
            setStripePaymentSucceeded(true);
            setStripeClientSecret(clientSecret);
            setStripePaymentIntentId(paymentIntentId);
            restoreCheckoutSessionAfterPayment(paymentIntentId);
        } else if (
            user
            && fetchedStoreItems
            && searchParams
            && !stripeClientSecret
            && !stripePaymentIntentId
            && !attemptedRestoringCheckoutSessionAfterPayment
        ) {
            setAttemptedRestoringCheckoutSessionAfterPayment(true);
        }
    }, [
        user,
        searchParams,
        fetchedStoreItems,
    ]);

    /**
     * Attempts to restore checkout session after leaving checkout session
     * Important Note: Succeeds restore checkout session after payment redirect side effect
     */
    useEffect(() => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && fetchedStoreItems
            && attemptedRestoringCheckoutSessionAfterPayment
            && !attemptedResumingPreviousCheckoutSession
            && !currentCheckoutSessionId
            && !stripePaymentIntentId
            && !stripePaymentSucceeded
        ) {
            resumeCheckoutSession();
        }
    }, [
        attemptedRestoringCheckoutSessionAfterPayment,
    ]);

    /**
     * Creates a payment intent if no restored checkout session
     * Important Note: Succeeds restore checkout session after leaving checkout session
     */
    useEffect(() => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && attemptedResumingPreviousCheckoutSession
            && attemptedRestoringCheckoutSessionAfterPayment
            && !currentCheckoutSessionId
            && !stripePaymentIntentId
        ) {
            createPaymentIntent();
        }
        // no need to put user because necessary for this to change
    }, [attemptedResumingPreviousCheckoutSession]);

    /**
     * Creates a new checkout session
     * Important Note: Succeeds creating a payment intent after failing to restore previous checkout sessions
     */
    useEffect(() => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && attemptedResumingPreviousCheckoutSession
            && attemptedRestoringCheckoutSessionAfterPayment
            && !currentCheckoutSessionId
            && !!stripePaymentIntentId
        ) {
            createCheckoutSession();
        }
        // no need to put user, attemptedResumingPreviousCheckoutSession because necessary for this to change
    }, [stripePaymentIntentId]);

    /**
     * Determine if eligible for web book or digital book discounts
     */
    useEffect(() => {
        if (
            user
            && user.cart
            && user.cart.length > 0
            && fetchedStoreItems
            && checkedBookPurchases
            && stripePaymentIntentId
            && !stripePaymentSucceeded
        ) {
            determineBookDiscountEligibility();
        }
    }, [
        user,
        fetchedStoreItems,
        stripePaymentIntentId,
        checkedBookPurchases,
    ]);

    /**
     * Manages turning off checkpoint placeholder text interval
     */
    useEffect(() => {
        if (
            currentCheckoutSessionId
            && orderCost
            && isRunningCheckpointPlaceholderInterval
        ) {
            setIsRunningCheckpointPlaceholderInterval(false);
        }
    }, [
        currentCheckoutSessionId,
        orderCost,
    ]);

    /**
     * Listens for changes to sale item in Firestore
     */
    useEffect(() => {
        let unsubscribe: Unsubscribe;
        if (
            stripePaymentIntentId
            && stripePaymentSucceeded
            && listenForSaleItem
        ) {
            const db = getFirestore();
            const salesCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.sales
                : FIRESTORE_COLLECTION.stagingSales;
            unsubscribe = onSnapshot(doc(
                db,
                salesCollection,
                stripePaymentIntentId, // sales items share id with associated payment intents
            ), (saleDoc) => {
                if (saleDoc.exists()) {
                    const saleItem = saleDoc.data() as ISaleItem;
                    if (saleItem) {
                        setOrderSaleItem(saleItem);
                        setListenForSaleItem(false);
                        unsubscribe();
                    }
                }
            });
        }

        return function cleanup() {
            if (unsubscribe) unsubscribe();
        };
    }, [listenForSaleItem]);

    /**
     * Manages checking for tax transaction when payment intent succeeded
     */
    useEffect(() => {
        if (waitForTaxTransaction) {
            checkForTaxTransaction();
        }
    }, [checkForTaxTransactionTrigger]);

    // ===== Memoization =====

    const pageSubtitle = useMemo(() => {
        switch (params.checkpoint) {
        case CHECKOUT_CHECKPOINT.info:
            return 'Where should we deliver your haul?';
        case CHECKOUT_CHECKPOINT.shipping:
            return 'How should we deliver your haul?';
        case CHECKOUT_CHECKPOINT.billing:
            return 'Who should we expense?';
        case CHECKOUT_CHECKPOINT.review:
            return 'How\'s everything look?';
        default:
            // CHECKOUT_CHECKPOINT.confirmation
            return 'Mission accomplished!';
        }
    }, [params.checkpoint]);

    const checkpointPlaceholderText = useMemo(() => {
        switch (params.checkpoint) {
        case CHECKOUT_CHECKPOINT.info:
            if (!fetchedStoreItems) {
                return 'Fetching cart details';
            }
            if (
                fetchedStoreItems
                && attemptedRestoringCheckoutSessionAfterPayment
                && !attemptedResumingPreviousCheckoutSession
                && !currentCheckoutSessionId
                && !stripePaymentIntentId
                && !stripePaymentSucceeded
            ) {
                return 'Searching for checkout session to restore';
            }
            if (
                attemptedResumingPreviousCheckoutSession
                && attemptedRestoringCheckoutSessionAfterPayment
                && !currentCheckoutSessionId
            ) {
                return 'Creating new checkout session';
            }
            return 'Preparing checkout session';
        case CHECKOUT_CHECKPOINT.shipping:
            return 'Fetching shipping rates';
        case CHECKOUT_CHECKPOINT.billing:
            return 'Loading payment service';
        case CHECKOUT_CHECKPOINT.review:
            return 'Aggregating checkout information';
        default:
            // CHECKOUT_CHECKPOINT.confirmation
            if (stripeSucceededPaymentIntent) {
                return 'Fetching order details';
            }
            if (
                stripeSucceededPaymentIntent
                && orderSaleItem
            ) {
                return 'Summarizing order details';
            }

            return 'Finalizing order purchase';
        }
    }, [
        params.checkpoint,
        fetchedStoreItems,
        stripeSucceededPaymentIntent,
        orderSaleItem,
        fetchedStoreItems,
        attemptedRestoringCheckoutSessionAfterPayment,
        attemptedResumingPreviousCheckoutSession,
        currentCheckoutSessionId,
        stripePaymentIntentId,
        stripePaymentSucceeded,
    ]);

    const PHYSICAL_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID
            : PHYSICAL_BOOK_STRIPE_PRICE_API_ID
    ), []);
    const DIGITAL_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID
            : DIGITAL_BOOK_STRIPE_PRICE_API_ID
    ), []);
    const WEB_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? WEB_BOOK_STRIPE_PRICE_TEST_API_ID
            : WEB_BOOK_STRIPE_PRICE_API_ID
    ), []);

    const numCheckoutItems = useMemo(() => {
        let count = 0;
        if (
            user
            && user.cart
            && user.cart.length > 0
        ) {
            user.cart.forEach((item: ICartItem) => {
                count += item.quantity;
            });
            if (
                user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                && (
                    (
                        purchasedPhysicalBookSessionIds.length === 0
                        && purchasedDigitalBookSessionIds.length === 0
                    ) || (
                        orderSaleItem
                        && purchasedPhysicalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                    )
                )
            ) {
                // Add one for complimentary digital book
                count += 1;
            }
            if (
                user.cart.find((item: ICartItem) => (
                    item.priceId === PHYSICAL_BOOK_PRICE_ID
                    || item.priceId === DIGITAL_BOOK_PRICE_ID
                ))
                && (
                    (
                        !user.bookAccess
                        || !user.bookAccess.granted
                    ) || (
                        orderSaleItem
                        && (
                            purchasedPhysicalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                            || purchasedDigitalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                        )
                    )
                )
            ) {
                // Add one for complimentary web book
                count += 1;
            }
        } else if (
            user
            && stripeSucceededPaymentIntent
            && stripeSucceededPaymentIntent
            && stripeSucceededPaymentIntent.metadata.cartItems
        ) {
            const cartItems = JSON.parse(stripeSucceededPaymentIntent.metadata.cartItems) as ICartItem[];
            cartItems.forEach((item: ICartItem) => {
                count += item.quantity;
            });
            if (
                cartItems.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                && (
                    (
                        purchasedPhysicalBookSessionIds.length === 0
                        && purchasedDigitalBookSessionIds.length === 0
                    ) || (
                        orderSaleItem
                        && purchasedPhysicalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                    )
                )
            ) {
                // Add one for complimentary digital book
                count += 1;
            }
            if (
                cartItems.find((item: ICartItem) => (
                    item.priceId === PHYSICAL_BOOK_PRICE_ID
                    || item.priceId === DIGITAL_BOOK_PRICE_ID
                ))
                && (
                    (
                        !user.bookAccess
                        || !user.bookAccess.granted
                    ) || (
                        orderSaleItem
                        && (
                            purchasedPhysicalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                            || purchasedDigitalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                        )
                    )
                )
            ) {
                // Add one for complimentary web book
                count += 1;
            }
        }
        return count;
    }, [
        user?.cart,
        stripePaymentSucceeded,
        stripeSucceededPaymentIntent,
        purchasedPhysicalBookSessionIds,
        purchasedDigitalBookSessionIds,
        orderSaleItem,
    ]);

    const breadcrumbItems = useMemo(() => {
        if (physicalItemsInCart.length > 0) {
            return [
                {
                    text: 'Cart',
                    route: PAGE_ROUTE.cart,
                },
                {
                    text: 'Info',
                    route: `${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.info}`,
                    interactable: addressIsValid,
                },
                {
                    text: 'Shipping',
                    abbrText: 'Ship',
                    route: `${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.shipping}`,
                    interactable: !!selectedShippingRateID,
                },
                {
                    text: 'Billing',
                    abbrText: 'Bill',
                    route: `${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`,
                    interactable: !!selectedBillingAddressType
                        && stripePaymentDetailsCompleted,
                },
                {
                    text: 'Review',
                    route: `${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.review}`,
                    interactable: addressIsValid
                        && !!selectedShippingRateID
                        && !!selectedBillingAddressType
                        && billingAddressIsValid
                        && stripePaymentDetailsCompleted
                        && customerDetailsCompleted,
                },
            ];
        }

        return [
            {
                text: 'Cart',
                route: PAGE_ROUTE.cart,
            },
            {
                text: 'Billing',
                abbrText: 'Bill',
                route: `${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`,
                interactable: !!selectedBillingAddressType
                    && stripePaymentDetailsCompleted,
            },
            {
                text: 'Review',
                route: `${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.review}`,
                interactable: (addressIsValid || physicalItemsInCart.length === 0)
                    && (!!selectedShippingRateID || physicalItemsInCart.length === 0)
                    && !!selectedBillingAddressType
                    && billingAddressIsValid
                    && stripePaymentDetailsCompleted
                    && customerDetailsCompleted,
            },
        ];
    }, [
        physicalItemsInCart,
        addressIsValid,
        selectedShippingRateID,
        selectedBillingAddressType,
        stripePaymentSucceeded,
        stripePaymentDetailsCompleted,
        billingAddressIsValid,
        customerDetailsCompleted,
    ]);

    const succeededPaymentCartItems = useMemo(() => {
        if (stripeSucceededPaymentIntent) {
            const cartItems = JSON.parse(stripeSucceededPaymentIntent.metadata.cartItems) as ICartItem[];
            return cartItems;
        }

        return undefined;
    }, [
        stripeSucceededPaymentIntent,
    ]);

    const promotionAddButtonInteractionProperties = useMemo(() => {
        const properties: Record<string, string | (
            (e: React.TouchEvent) => void) | ((e: React.MouseEvent) => void
        )> = !promotionCodeInvalid && !isValidatingPromotionCode
            ? {
                className: HOVER_TARGET_CLASSNAME,
            } : {};

        if (
            !promotionCodeInvalid
            && !isValidatingPromotionCode
            && detectTouchDevice(document)
        ) {
            properties.onTouchStart = (e: React.TouchEvent) => onPromotionCodeAddButtonEnter(e);
            properties.onTouchEnd = (e: React.TouchEvent) => onPromotionCodeAddButtonLeave(e);
        } else if (
            !promotionCodeInvalid
            && !isValidatingPromotionCode
            && !detectTouchDevice(document)
        ) {
            properties.onMouseEnter = (e: React.TouchEvent) => onPromotionCodeAddButtonEnter(e);
            properties.onMouseLeave = (e: React.TouchEvent) => onPromotionCodeAddButtonLeave(e);
        }

        return properties;
    }, [
        isValidatingPromotionCode,
        promotionCodeInvalid,
    ]);

    const appearance: Appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: theme.verascopeColor.purple400,
            colorBackground: theme.color.white,
            colorText: theme.verascopeColor.purple100,
            colorDanger: theme.verascopeColor.red200,
        },
    };
    const stripeOptions: StripeElementsOptions = {
        clientSecret: stripeClientSecret,
        appearance,
    };

    return (
        <Container>
            <PageLogo
                dark
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
                color={theme.verascopeColor.purple200}
            >
                Checkout
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={theme.verascopeColor.purple400}
            >
                {pageSubtitle}
            </PageSubtitle>
            <ContentContainer>
                <OrderSummaryContainer>
                    {viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                        ? (
                            <Header>
                                <HeaderText>
                                    {(
                                        (
                                            user?.cart
                                            && !stripePaymentSucceeded
                                        ) || (
                                            stripePaymentSucceeded
                                            && stripeSucceededPaymentIntent
                                        )
                                    ) ? (
                                            <>
                                                {`Item${numCheckoutItems !== 1 ? 's' : ''}`}
                                                {(
                                                    succeededPaymentCartItems
                                                    || (
                                                        fetchedStoreItems
                                                        && user
                                                        && user.cart
                                                    )
                                                ) && (
                                                    <CartItemsCountText>
                                                        {numCheckoutItems}
                                                    </CartItemsCountText>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                Items
                                            </>
                                        )}
                                </HeaderText>
                            </Header>
                        ) : (
                            <Header>
                                <HeaderText>
                                    Order Summary
                                </HeaderText>
                                <OrderSummaryToggleButtonContainer
                                    className={HOVER_TARGET_CLASSNAME}
                                    orderSummaryIsVisible={orderSummaryIsVisible}
                                    detectTouchDevice={detectTouchDevice(document)}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => onOrderSummaryToggleButtonEnter(e),
                                    } : {
                                        onMouseEnter: (e) => onOrderSummaryToggleButtonEnter(e),
                                    })}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchEnd: (e) => onOrderSummaryToggleButtonLeave(e),
                                    } : {
                                        onMouseLeave: (e) => onOrderSummaryToggleButtonLeave(e),
                                    })}
                                    onMouseDown={onOrderSummaryToggleButtonClick}
                                >
                                    <OrderSummaryToggleButton />
                                </OrderSummaryToggleButtonContainer>
                            </Header>
                        )}
                    <OrderSummary
                        isVisible={viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min || orderSummaryIsVisible}
                    >
                        <CartItemsContainer>
                            {stripePaymentSucceeded
                            && stripeSucceededPaymentIntent
                            && succeededPaymentCartItems
                            && (
                                <CartItems>
                                    {succeededPaymentCartItems.map((cartItem) => {
                                        const storeItem = storeItems.get(cartItem.priceId)!;
                                        let cumulativeDiscount = 0;
                                        let appliedWebBookCutPromotion = false;
                                        let appliedDigitalBookCutPromotion = false;
                                        if (appliedPromotions.length > 0) {
                                            const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                                                ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                                                : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
                                            const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                                                ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                                                : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
                                            for (let i = 0; i < appliedPromotions.length; i += 1) {
                                                const promotion = appliedPromotions[i];
                                                if (promotion.id === WEB_BOOK_STRIPE_COUPON_ID) {
                                                    appliedWebBookCutPromotion = true;
                                                }
                                                if (promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID) {
                                                    appliedDigitalBookCutPromotion = true;
                                                }
                                                const discountedAmount = applyPromotionToCheckoutItem(
                                                    storeItem,
                                                    promotion,
                                                );
                                                cumulativeDiscount += storeItem.amount! - discountedAmount;
                                            }
                                        }
                                        const discounted = appliedPromotions.length > 0
                                        && cumulativeDiscount > 0;

                                        return (
                                            <CartItemContainer
                                                small
                                            >
                                                <CartItemImageContainer
                                                    small
                                                    hasImage={storeItem.imageURLs.length > 0}
                                                >
                                                    {storeItem.imageURLs.length > 0
                                                        ? (
                                                            <CartItemImage
                                                                url={storeItem.imageURLs[0]}
                                                            />
                                                        ) : (
                                                            <ReactSVG
                                                                src={BoxIcon}
                                                            />
                                                        )}
                                                </CartItemImageContainer>
                                                <CartItemDetails>
                                                    <CartItemName
                                                        small
                                                    >
                                                        {storeItem.name}
                                                    </CartItemName>
                                                    <CartItemPrice
                                                        small
                                                        spaceBetween
                                                    >
                                                        {discounted
                                                            ? (
                                                                <DiscountedCartItemPrice>
                                                                    {`$${storeItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                                </DiscountedCartItemPrice>
                                                            ) : `$${storeItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                        {discounted && (
                                                            <CartItemPriceAfterDiscount>
                                                                {` $${(storeItem.amount! - cumulativeDiscount) / CENTS_IN_A_DOLLAR}${
                                                                    (appliedWebBookCutPromotion || appliedDigitalBookCutPromotion)
                                                                    && cartItem.quantity > 1
                                                                        ? ' (1)'
                                                                        : ''
                                                                }`}
                                                            </CartItemPriceAfterDiscount>
                                                        )}
                                                        <CartItemFinalQuantityContainer>
                                                            Qty
                                                            <CartItemFinalQuantityValue>
                                                                {cartItem.quantity}
                                                            </CartItemFinalQuantityValue>
                                                        </CartItemFinalQuantityContainer>
                                                    </CartItemPrice>
                                                </CartItemDetails>
                                            </CartItemContainer>
                                        );
                                    })}
                                    {succeededPaymentCartItems
                                    && !!succeededPaymentCartItems.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                                    && digitalBookStoreItem
                                    && (
                                        (
                                            purchasedPhysicalBookSessionIds.length === 0
                                            && purchasedDigitalBookSessionIds.length === 0
                                        ) || (
                                            orderSaleItem
                                            && purchasedPhysicalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                                        )
                                    )
                                    && (
                                        <CartItemContainer
                                            small
                                        >
                                            <CartItemImageContainer
                                                small
                                                hasImage={digitalBookStoreItem.imageURLs.length > 0}
                                            >
                                                {digitalBookStoreItem.imageURLs.length > 0
                                                    ? (
                                                        <CartItemImage
                                                            url={digitalBookStoreItem.imageURLs[0]}
                                                        />
                                                    ) : (
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    )}
                                            </CartItemImageContainer>
                                            <CartItemDetails>
                                                <CartItemName
                                                    small
                                                >
                                                    {digitalBookStoreItem.name}
                                                </CartItemName>
                                                <CartItemPrice
                                                    small
                                                    spaceBetween
                                                >
                                                    <DiscountedCartItemPrice>
                                                        {`$${digitalBookStoreItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                    </DiscountedCartItemPrice>
                                                    <CartItemPriceAfterDiscount>
                                                        {' $0.00'}
                                                    </CartItemPriceAfterDiscount>
                                                    <CartItemFinalQuantityContainer>
                                                        Qty
                                                        <CartItemFinalQuantityValue>
                                                            1
                                                        </CartItemFinalQuantityValue>
                                                    </CartItemFinalQuantityContainer>
                                                </CartItemPrice>
                                            </CartItemDetails>
                                        </CartItemContainer>
                                    )}
                                    {succeededPaymentCartItems
                                    && !!succeededPaymentCartItems.find((item: ICartItem) => (
                                        item.priceId === PHYSICAL_BOOK_PRICE_ID
                                        || item.priceId === DIGITAL_BOOK_PRICE_ID
                                    ))
                                    && webBookStoreItem
                                    && (
                                        (
                                            !user?.bookAccess
                                            || !user?.bookAccess.granted
                                        ) || (
                                            orderSaleItem
                                            && (
                                                purchasedPhysicalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                                                || purchasedDigitalBookSessionIds.find((id) => id === orderSaleItem.checkoutSessionId)
                                            )
                                        )
                                    )
                                    && (
                                        <CartItemContainer
                                            small
                                        >
                                            <CartItemImageContainer
                                                small
                                                hasImage={webBookStoreItem.imageURLs.length > 0}
                                            >
                                                {webBookStoreItem.imageURLs.length > 0
                                                    ? (
                                                        <CartItemImage
                                                            url={webBookStoreItem.imageURLs[0]}
                                                        />
                                                    ) : (
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    )}
                                            </CartItemImageContainer>
                                            <CartItemDetails>
                                                <CartItemName
                                                    small
                                                >
                                                    {webBookStoreItem.name}
                                                </CartItemName>
                                                <CartItemPrice
                                                    small
                                                    spaceBetween
                                                >
                                                    <DiscountedCartItemPrice>
                                                        {`$${webBookStoreItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                    </DiscountedCartItemPrice>
                                                    <CartItemPriceAfterDiscount>
                                                        {' $0.00'}
                                                    </CartItemPriceAfterDiscount>
                                                    <CartItemFinalQuantityContainer>
                                                        Qty
                                                        <CartItemFinalQuantityValue>
                                                            1
                                                        </CartItemFinalQuantityValue>
                                                    </CartItemFinalQuantityContainer>
                                                </CartItemPrice>
                                            </CartItemDetails>
                                        </CartItemContainer>
                                    )}
                                </CartItems>
                            )}
                            {!stripePaymentSucceeded
                            && !stripeSucceededPaymentIntent
                            && (
                                <CartItems>
                                    {fetchedStoreItems
                                    && user
                                    && user.cart
                                    && (
                                        user.cart.map((cartItem) => {
                                            const storeItem = storeItems.get(cartItem.priceId)!;
                                            let cumulativeDiscount = 0;
                                            let appliedWebBookCutPromotion = false;
                                            let appliedDigitalBookCutPromotion = false;
                                            if (appliedPromotions.length > 0) {
                                                const DIGITAL_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                                                    ? DIGITAL_BOOK_STRIPE_COUPON_TEST_ID
                                                    : DIGITAL_BOOK_STRIPE_COUPON_LIVE_ID;
                                                const WEB_BOOK_STRIPE_COUPON_ID = process.env.NODE_ENV === 'development'
                                                    ? WEB_BOOK_STRIPE_COUPON_TEST_ID
                                                    : WEB_BOOK_STRIPE_COUPON_LIVE_ID;
                                                for (let i = 0; i < appliedPromotions.length; i += 1) {
                                                    const promotion = appliedPromotions[i];
                                                    if (promotion.id === WEB_BOOK_STRIPE_COUPON_ID) {
                                                        appliedWebBookCutPromotion = true;
                                                    }
                                                    if (promotion.id === DIGITAL_BOOK_STRIPE_COUPON_ID) {
                                                        appliedDigitalBookCutPromotion = true;
                                                    }
                                                    const discountedAmount = applyPromotionToCheckoutItem(
                                                        storeItem,
                                                        promotion,
                                                    );
                                                    cumulativeDiscount += storeItem.amount! - discountedAmount;
                                                }
                                            }
                                            const discounted = appliedPromotions.length > 0
                                            && cumulativeDiscount > 0;

                                            return (
                                                <CartItemContainer
                                                    small
                                                >
                                                    <CartItemImageContainer
                                                        small
                                                        hasImage={storeItem.imageURLs.length > 0}
                                                    >
                                                        {storeItem.imageURLs.length > 0
                                                            ? (
                                                                <CartItemImage
                                                                    url={storeItem.imageURLs[0]}
                                                                />
                                                            ) : (
                                                                <ReactSVG
                                                                    src={BoxIcon}
                                                                />
                                                            )}
                                                    </CartItemImageContainer>
                                                    <CartItemDetails>
                                                        <CartItemName
                                                            small
                                                        >
                                                            {storeItem.name}
                                                        </CartItemName>
                                                        <CartItemPrice
                                                            small
                                                            spaceBetween
                                                        >
                                                            {discounted
                                                                ? (
                                                                    <DiscountedCartItemPrice>
                                                                        {`$${storeItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                                    </DiscountedCartItemPrice>
                                                                ) : `$${storeItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                            {discounted && (
                                                                <CartItemPriceAfterDiscount>
                                                                    {` $${(storeItem.amount! - cumulativeDiscount) / CENTS_IN_A_DOLLAR}${
                                                                        (appliedWebBookCutPromotion || appliedDigitalBookCutPromotion)
                                                                        && cartItem.quantity > 1
                                                                            ? ' (1)'
                                                                            : ''
                                                                    }`}
                                                                </CartItemPriceAfterDiscount>
                                                            )}
                                                            <CartItemFinalQuantityContainer>
                                                                Qty
                                                                <CartItemFinalQuantityValue>
                                                                    {cartItem.quantity}
                                                                </CartItemFinalQuantityValue>
                                                            </CartItemFinalQuantityContainer>
                                                        </CartItemPrice>
                                                    </CartItemDetails>
                                                </CartItemContainer>
                                            );
                                        })
                                    )}
                                    {user?.cart
                                    && !!user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                                    && digitalBookStoreItem
                                    && purchasedPhysicalBookSessionIds.length === 0
                                    && purchasedDigitalBookSessionIds.length === 0
                                    && (
                                        <CartItemContainer
                                            small
                                        >
                                            <CartItemImageContainer
                                                small
                                                hasImage={digitalBookStoreItem.imageURLs.length > 0}
                                            >
                                                {digitalBookStoreItem.imageURLs.length > 0
                                                    ? (
                                                        <CartItemImage
                                                            url={digitalBookStoreItem.imageURLs[0]}
                                                        />
                                                    ) : (
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    )}
                                            </CartItemImageContainer>
                                            <CartItemDetails>
                                                <CartItemName
                                                    small
                                                >
                                                    {digitalBookStoreItem.name}
                                                </CartItemName>
                                                <CartItemPrice
                                                    small
                                                    spaceBetween
                                                >
                                                    <DiscountedCartItemPrice>
                                                        {`$${digitalBookStoreItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                    </DiscountedCartItemPrice>
                                                    <CartItemPriceAfterDiscount>
                                                        {' $0.00'}
                                                    </CartItemPriceAfterDiscount>
                                                    <CartItemFinalQuantityContainer>
                                                        Qty
                                                        <CartItemFinalQuantityValue>
                                                            1
                                                        </CartItemFinalQuantityValue>
                                                    </CartItemFinalQuantityContainer>
                                                </CartItemPrice>
                                            </CartItemDetails>
                                        </CartItemContainer>
                                    )}
                                    {user?.cart
                                    && !!user.cart.find((item: ICartItem) => (
                                        item.priceId === PHYSICAL_BOOK_PRICE_ID
                                        || item.priceId === DIGITAL_BOOK_PRICE_ID
                                    ))
                                    && webBookStoreItem
                                    && (
                                        !user.bookAccess
                                        || !user.bookAccess.granted
                                    ) && (
                                        <CartItemContainer
                                            small
                                        >
                                            <CartItemImageContainer
                                                small
                                                hasImage={webBookStoreItem.imageURLs.length > 0}
                                            >
                                                {webBookStoreItem.imageURLs.length > 0
                                                    ? (
                                                        <CartItemImage
                                                            url={webBookStoreItem.imageURLs[0]}
                                                        />
                                                    ) : (
                                                        <ReactSVG
                                                            src={BoxIcon}
                                                        />
                                                    )}
                                            </CartItemImageContainer>
                                            <CartItemDetails>
                                                <CartItemName
                                                    small
                                                >
                                                    {webBookStoreItem.name}
                                                </CartItemName>
                                                <CartItemPrice
                                                    small
                                                    spaceBetween
                                                >
                                                    <DiscountedCartItemPrice>
                                                        {`$${webBookStoreItem.amount! / CENTS_IN_A_DOLLAR}`}
                                                    </DiscountedCartItemPrice>
                                                    <CartItemPriceAfterDiscount>
                                                        {' $0.00'}
                                                    </CartItemPriceAfterDiscount>
                                                    <CartItemFinalQuantityContainer>
                                                        Qty
                                                        <CartItemFinalQuantityValue>
                                                            1
                                                        </CartItemFinalQuantityValue>
                                                    </CartItemFinalQuantityContainer>
                                                </CartItemPrice>
                                            </CartItemDetails>
                                        </CartItemContainer>
                                    )}
                                </CartItems>
                            )}
                            {(
                                (
                                    !stripePaymentSucceeded
                                    && !stripeSucceededPaymentIntent
                                    && (
                                        !fetchedStoreItems
                                        || !user
                                        || !user.cart
                                        || user.cart.length === 0
                                    )
                                ) || (
                                    stripePaymentSucceeded
                                    && !succeededPaymentCartItems
                                )
                            )
                            && (
                                <>
                                    <CartItemContainer
                                        small
                                    >
                                        <CartItemImageContainer
                                            small
                                            hasImage={false}
                                        >
                                            <ReactSVG
                                                src={BoxIcon}
                                            />
                                        </CartItemImageContainer>
                                        <CartItemDetails>
                                            <CartItemName
                                                small
                                            >
                                                <PlaceholderBox
                                                    width={CART_ITEM_NAME_PLACEHOLDER_WIDTH}
                                                    height={CART_ITEM_NAME_PLACEHOLDER_HEIGHT}
                                                />
                                            </CartItemName>
                                            <CartItemPrice
                                                small
                                                spaceBetween
                                            >
                                                <PlaceholderBox
                                                    width={CART_ITEM_PRICE_PLACEHOLDER_WIDTH}
                                                    height={CART_ITEM_PRICE_PLACEHOLDER_HEIGHT}
                                                />
                                            </CartItemPrice>
                                        </CartItemDetails>
                                    </CartItemContainer>
                                    <CartItemContainer
                                        small
                                    >
                                        <CartItemImageContainer
                                            small
                                            hasImage={false}
                                        >
                                            <ReactSVG
                                                src={BoxIcon}
                                            />
                                        </CartItemImageContainer>
                                        <CartItemDetails>
                                            <CartItemName
                                                small
                                            >
                                                <PlaceholderBox
                                                    width={CART_ITEM_NAME_PLACEHOLDER_WIDTH}
                                                    height={CART_ITEM_NAME_PLACEHOLDER_HEIGHT}
                                                />
                                            </CartItemName>
                                            <CartItemPrice
                                                small
                                                spaceBetween
                                            >
                                                <PlaceholderBox
                                                    width={CART_ITEM_PRICE_PLACEHOLDER_WIDTH}
                                                    height={CART_ITEM_PRICE_PLACEHOLDER_HEIGHT}
                                                />
                                            </CartItemPrice>
                                        </CartItemDetails>
                                    </CartItemContainer>
                                    <CartItemContainer
                                        small
                                    >
                                        <CartItemImageContainer
                                            small
                                            hasImage={false}
                                        >
                                            <ReactSVG
                                                src={BoxIcon}
                                            />
                                        </CartItemImageContainer>
                                        <CartItemDetails>
                                            <CartItemName
                                                small
                                            >
                                                <PlaceholderBox
                                                    width={CART_ITEM_NAME_PLACEHOLDER_WIDTH}
                                                    height={CART_ITEM_NAME_PLACEHOLDER_HEIGHT}
                                                />
                                            </CartItemName>
                                            <CartItemPrice
                                                small
                                                spaceBetween
                                            >
                                                <PlaceholderBox
                                                    width={CART_ITEM_PRICE_PLACEHOLDER_WIDTH}
                                                    height={CART_ITEM_PRICE_PLACEHOLDER_HEIGHT}
                                                />
                                            </CartItemPrice>
                                        </CartItemDetails>
                                    </CartItemContainer>
                                </>
                            )}
                        </CartItemsContainer>
                        {currentCheckoutSessionId && (
                            <PromotionCodeContainer
                                noBorder={params.checkpoint === CHECKOUT_CHECKPOINT.confirmation}
                            >
                                {appliedPromotions.length > 0
                                && appliedPromotions.map((promotion) => (
                                    <PromotionCodeBadgeContainer>
                                        <PromotionCodeBadge>
                                            {promotion.name}
                                            {params.checkpoint !== CHECKOUT_CHECKPOINT.confirmation && (
                                                <PromotionCodeBadgeRemoveButton
                                                    className={HOVER_TARGET_CLASSNAME}
                                                    detectTouchDevice={detectTouchDevice(document)}
                                                    {...(detectTouchDevice(document) ? {
                                                        onTouchStart: (e) => onPromotionCodeBadgeRemoveButtonEnter(e),
                                                    } : {
                                                        onMouseEnter: (e) => onPromotionCodeBadgeRemoveButtonEnter(e),
                                                    })}
                                                    {...(detectTouchDevice(document) ? {
                                                        onTouchEnd: (e) => onPromotionCodeBadgeRemoveButtonLeave(e),
                                                    } : {
                                                        onMouseLeave: (e) => onPromotionCodeBadgeRemoveButtonLeave(e),
                                                    })}
                                                    onMouseDown={(e) => onPromotionCodeBadgeRemoveButtonClick(promotion.id, e)}
                                                >
                                                    <ReactSVG
                                                        src={PixelCrossIcon}
                                                    />
                                                </PromotionCodeBadgeRemoveButton>
                                            )}
                                        </PromotionCodeBadge>
                                    </PromotionCodeBadgeContainer>
                                ))}
                                {params.checkpoint !== CHECKOUT_CHECKPOINT.confirmation
                                && (
                                    <PromotionCodeHeader>
                                        <PromotionCodeHeaderText>
                                            Promotion Code
                                        </PromotionCodeHeaderText>
                                        <PromotionCodeToggleButtonContainer
                                            className={HOVER_TARGET_CLASSNAME}
                                            promotionCodeInputIsVisible={promotionCodeInputIsVisible}
                                            detectTouchDevice={detectTouchDevice(document)}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: (e) => onPromotionCodeToggleButtonEnter(e),
                                            } : {
                                                onMouseEnter: (e) => onPromotionCodeToggleButtonEnter(e),
                                            })}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchEnd: (e) => onPromotionCodeToggleButtonLeave(e),
                                            } : {
                                                onMouseLeave: (e) => onPromotionCodeToggleButtonLeave(e),
                                            })}
                                            onMouseDown={onPromotionCodeToggleButtonClick}
                                        >
                                            <PromotionCodeToggleButton />
                                        </PromotionCodeToggleButtonContainer>
                                    </PromotionCodeHeader>
                                )}
                                <PromotionCodeBody
                                    isVisible={promotionCodeInputIsVisible}
                                >
                                    <PromotionCodeInput
                                        type="text"
                                        ref={promotionCodeInputRef}
                                        className={HOVER_TARGET_CLASSNAME}
                                        placeholder="CODE"
                                        onChange={onPromotionCodeInputChange}
                                        onKeyUp={checkForEnter}
                                        isInvalid={promotionCodeInvalid}
                                        addButtonVisible={promotionCode.length > 0 || isValidatingPromotionCode}
                                        disabled={false}
                                        {...(detectTouchDevice(document) ? {
                                            onTouchStart: (e) => onPromotionCodeInputEnter(e),
                                        } : {
                                            onMouseEnter: (e) => onPromotionCodeInputEnter(e),
                                        })}
                                        {...(detectTouchDevice(document) ? {
                                            onTouchEnd: (e) => onPromotionCodeInputLeave(e),
                                        } : {
                                            onMouseLeave: (e) => onPromotionCodeInputLeave(e),
                                        })}
                                        onFocus={onPromotionCodeInputFocus}
                                        onBlur={onPromotionCodeInputBlur}
                                    />
                                    {(promotionCode.length > 0 || isValidatingPromotionCode) && (
                                        <PromotionCodeAddButton
                                            className={HOVER_TARGET_CLASSNAME}
                                            isInvalid={promotionCodeInvalid}
                                            isValidatingCode={isValidatingPromotionCode}
                                            detectTouchDevice={detectTouchDevice(document)}
                                            {...(promotionAddButtonInteractionProperties)}
                                            {...(!promotionCodeInvalid && !isValidatingPromotionCode
                                                ? {
                                                    onMouseDown: onPromotionCodeAddButtonClick,
                                                } : {})}
                                        >
                                            {!isValidatingPromotionCode
                                                ? 'Add'
                                                : (
                                                    <SpinnerContainer
                                                        src={Spinner}
                                                        alt="spinner"
                                                    />
                                                )}
                                        </PromotionCodeAddButton>
                                    )}
                                </PromotionCodeBody>
                            </PromotionCodeContainer>
                        )}
                        <OrderSummaryEntries>
                            <OrderSummaryEntry>
                                <OrderSummaryText>
                                    Subtotal
                                </OrderSummaryText>
                                <OrderSummaryPrice>
                                    {orderCost
                                        ? `$${orderCost.subtotal / CENTS_IN_A_DOLLAR}`
                                        : (
                                            <PlaceholderBox
                                                width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                                height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                            />
                                        )}
                                </OrderSummaryPrice>
                            </OrderSummaryEntry>
                            {physicalItemsInCart.length > 0 && (
                                <OrderSummaryEntry>
                                    <OrderSummaryText>
                                        Shipping
                                    </OrderSummaryText>
                                    <OrderSummaryPrice>
                                        {!!orderCost && (orderCost.shipping ? `$${orderCost.shipping / CENTS_IN_A_DOLLAR}` : 'TBD')}
                                        {!orderCost && (
                                            <PlaceholderBox
                                                width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                                height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                            />
                                        )}
                                    </OrderSummaryPrice>
                                </OrderSummaryEntry>
                            )}
                            <OrderSummaryEntry>
                                <OrderSummaryText>
                                    Tax
                                </OrderSummaryText>
                                <OrderSummaryPrice>
                                    {!!orderCost && (orderCost.tax !== undefined ? `$${orderCost.tax / CENTS_IN_A_DOLLAR}` : 'TBD')}
                                    {!orderCost && (
                                        <PlaceholderBox
                                            width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                            height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                        />
                                    )}
                                </OrderSummaryPrice>
                            </OrderSummaryEntry>
                            <OrderSummaryEntry
                                divider
                            >
                                <OrderSummaryText>
                                    Total
                                </OrderSummaryText>
                                <OrderSummaryPrice>
                                    {!!orderCost && (orderCost.total !== undefined ? `$${orderCost.total / CENTS_IN_A_DOLLAR}` : 'TBD')}
                                    {!orderCost && (
                                        <PlaceholderBox
                                            width={ORDER_SUMMARY_PRICE_PLACEHOLDER_WIDTH}
                                            height={ORDER_SUMMARY_PRICE_PLACEHOLDER_HEIGHT}
                                        />
                                    )}
                                </OrderSummaryPrice>
                            </OrderSummaryEntry>
                        </OrderSummaryEntries>
                    </OrderSummary>
                </OrderSummaryContainer>
                <CheckpointContainer>
                    {(
                        params.checkpoint !== CHECKOUT_CHECKPOINT.confirmation
                        && currentCheckoutSessionId
                    ) || (
                        params.checkpoint === CHECKOUT_CHECKPOINT.confirmation
                        && !!stripeSucceededPaymentIntent
                        && !!orderSaleItem
                        && !!orderCost
                    )
                        ? (
                            <>
                                {!stripePaymentSucceeded
                                && !stripeSucceededPaymentIntent
                                && (
                                    <BreadcrumbContainer>
                                        <Breadcrumb
                                            lightBackground
                                            fontFamily={FONT_TYPE.PRESS_START}
                                            items={breadcrumbItems}
                                            chevronSrc={PixelChevronIcon}
                                            activeColor={theme.verascopeColor.orange200}
                                            interactableColor={theme.verascopeColor.purple400}
                                            user={user}
                                            hasSound={hasSound}
                                            currentSessionId={currentSessionId}
                                            viewportDimensions={viewportDimensions}
                                            onCursorEnter={onCursorEnter}
                                            onCursorLeave={onCursorLeave}
                                        />
                                    </BreadcrumbContainer>
                                )}
                                <Elements
                                    options={stripeOptions}
                                    stripe={stripePromise}
                                >
                                    <CheckoutCheckpoint
                                        hasSound={hasSound}
                                        user={user}
                                        currentSessionId={currentSessionId}
                                        checkpoint={params.checkpoint}
                                        fetchedStoreItems={fetchedStoreItems}
                                        physicalItemsInCart={physicalItemsInCart}
                                        stripePaymentIntentId={stripePaymentIntentId}
                                        stripeClientSecret={stripeClientSecret}
                                        stripeTaxCalculationId={stripeTaxCalculationId}
                                        savedStripePaymentMethods={savedStripePaymentMethods}
                                        customerStreetOne={customerStreetOne}
                                        addressIsValid={addressIsValid}
                                        customerBillingStreetOne={customerBillingStreetOne}
                                        billingAddressIsValid={billingAddressIsValid}
                                        shippingRates={shippingRates}
                                        selectedShippingRateID={selectedShippingRateID}
                                        selectedBillingAddressType={selectedBillingAddressType}
                                        orderCost={orderCost}
                                        stripePaymentDetailsCompleted={stripePaymentDetailsCompleted}
                                        stripePaymentSucceeded={stripePaymentSucceeded}
                                        snackbarData={snackbarData}
                                        stripeSucceededPaymentIntent={stripeSucceededPaymentIntent}
                                        stripePaymentMethod={stripePaymentMethod}
                                        orderSaleItem={orderSaleItem}
                                        changeCheckoutSessionStatus={changeCheckoutSessionStatus}
                                        setOrderCost={setOrderCost}
                                        setStripeTaxCalculationId={setStripeTaxCalculationId}
                                        setCustomerStreetOne={setCustomerStreetOne}
                                        setAddressIsValid={setAddressIsValid}
                                        setCustomerBillingStreetOne={setCustomerBillingStreetOne}
                                        setBillingAddressIsValid={setBillingAddressIsValid}
                                        setShippingRates={setShippingRates}
                                        setSelectedShippingRateID={setSelectedShippingRateID}
                                        setSelectedBillingAddressType={setSelectedBillingAddressType}
                                        setStripePaymentDetailsCompleted={setStripePaymentDetailsCompleted}
                                        setCustomerDetailsCompleted={setCustomerDetailsCompleted}
                                        setSnackbarData={setSnackbarData}
                                        setInputFocused={setInputFocused}
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        setCursorIsHidden={setCursorIsHidden}
                                    />
                                </Elements>
                            </>
                        ) : (
                            <PlaceholderCheckpointContainer>
                                <ReactSVG
                                    src={Spinner}
                                    alt="spinner"
                                />
                                <PlaceholderCheckpointText>
                                    {`${checkpointPlaceholderText}${checkpointPlaceholderEllipsis}`}
                                </PlaceholderCheckpointText>
                            </PlaceholderCheckpointContainer>
                        )}
                </CheckpointContainer>
            </ContentContainer>
        </Container>
    );
}

export default CheckoutView;
