// ===== Packages =====
import { DefaultTheme }              from 'styled-components';

// ===== Constants =====

import MEDIA_QUERY_SIZE    from '../constants/mediaQuerySizes';

// ===== Interfaces =====

interface IMediaQuery {
    large: string,
    medium: string,
    small: string,
    extraSmall: string,
}

declare module 'styled-components' {
    export interface DefaultTheme {
        color: {
            // Neutrals
            neutral100: string,
            neutral200: string,
            neutral300: string,
            neutral400: string,
            neutral500: string,
            neutral600: string,
            neutral700: string,
            neutral800: string,
            neutral900: string,
            neutral1000: string,
            neutral1100: string,
            cream100: string,
            cream200: string,
            offWhite: string,
            white: string,
            error: string,
            // Box Shadow
            insetBoxShadow100: string,
            insetBoxShadow200: string,
            boxShadow000: string,
            boxShadow100: string,
            boxShadow300: string,
            boxShadow400: string,
            boxShadow500: string,
            [index: string]: string,
        },
        motion: {
            eagerEasing: string,
            delayEasing: string,
            standardEasing: string,
            bounceEasing: string,
            overshoot: string,
            [index: string]: string,
        },
        verascopeColor: {
            white: string,
            orange100: string,
            orange200: string,
            orange300: string,
            yellow100: string,
            yellow200: string,
            yellow300: string,
            red100: string,
            red200: string,
            red300: string,
            blue100: string,
            blue200: string,
            blue300: string,
            green100: string,
            green200: string,
            green300: string,
            purple100: string,
            purple200: string,
            purple300: string,
            purple400: string,
            purple500: string,
            gray100: string,
            gray200: string,
            gray300: string,
            gray400: string,
            gray500: string,
            gray600: string,
            [index: string]: string,
        },
        mediaQuery: IMediaQuery,
    }
}

const customMediaQuery = (minWidth: number | null = null, maxWidth: number | null = null): string => {
    if (minWidth && maxWidth) {
        return `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
    }

    if (minWidth) {
        return `@media (min-width: ${minWidth}px)`;
    }

    if (maxWidth) {
        return `@media (max-width: ${maxWidth}px)`;
    }

    return '';
};

export const theme: DefaultTheme = {
    color: {
        // Neutrals
        neutral100: '#F9FAFA',
        neutral200: '#F1F3F4',
        neutral300: '#E0E4E6',
        neutral400: '#D5DADD',
        neutral500: '#C6CED2',
        neutral600: '#B8C2C6',
        neutral700: '#9CA9B0',
        neutral800: '#5B6A71',
        neutral900: '#444F55',
        neutral1000: '#2D3539',
        neutral1100: '#171A1C',
        cream100: '#cccccb',
        cream200: '#e0e1e1',
        offWhite: '#f8f9fb',
        white: '#FAFAFA',
        error: '#EE2A51',
        // Box Shadow
        insetBoxShadow100:
        'inset 0 2px 8px rgba(0,0,0,.1)',
        insetBoxShadow200:
        'inset 0 2px 8px rgba(0,0,0,.3)',
        boxShadow000: '0 0px 0px rgba(0,0,0,.1)',
        boxShadow100: '0 2px 8px rgba(0,0,0,.1)',
        boxShadow300:
        'rgba(45,45,45,0.05) 0px 2px 2px, '
        + 'rgba(49,49,49,0.05) 0px 4px 4px, '
        + 'rgba(42,42,42,0.05) 0px 8px 8px, '
        + 'rgba(32,32,32,0.05) 0px 16px 16px, '
        + 'rgba(49,49,49,0.05) 0px 32px 32px, '
        + 'rgba(35,35,35,0.05) 0px 64px 64px, '
        + 'rgba(45,45,45,0.05) 0px -2px 2px',
        boxShadow400: '0 4px 20px rgb(0 0 0 / 50%)',
        boxShadow500: '0 4px 60px rgb(0 0 0 / 50%)',
    },
    motion: {
        eagerEasing: 'cubic-bezier(0,.75,.3,1)',
        delayEasing: 'cubic-bezier(0.5, 0, 0.5, 1)',
        standardEasing: 'cubic-bezier(0.73, 0.18, 0.28, 0.93)',
        bounceEasing: 'cubic-bezier(0, 6, 0, -0.35)',
        overshoot: 'cubic-bezier(0.39, 0.8, 0, 1.28)',
    },
    verascopeColor: {
        white: '#FAFAFA',
        orange100: '#CC3C27',
        orange200: '#F47421',
        orange300: '#EDB48E',
        yellow100: '#C9862C',
        yellow200: '#FCEE26',
        yellow300: '#F7F1AE',
        red100: '#991B2A',
        red200: '#EE2A51',
        red300: '#df80b3',
        blue100: '#207D8C',
        blue200: '#29BFEC',
        blue300: '#93D5D7',
        green100: '#24733A',
        green200: '#66BC46',
        green300: '#C0DD99',
        purple100: '#0E1526',
        purple200: '#0D1D38',
        purple300: '#1D3363',
        purple400: '#3953A4',
        purple500: '#6A98D0',
        gray100: '#0E1526',
        gray200: '#323341',
        gray300: '#565461',
        gray400: '#817E89',
        gray500: '#B6B4BA',
        gray600: '#E3E3E5',
    },
    // TypeScript friendly Styled Component Media Template
    // Source: https://blog.agney.dev/styled-components-&-typescript/
    mediaQuery: {
        large: customMediaQuery(MEDIA_QUERY_SIZE.large.min, MEDIA_QUERY_SIZE.large.max),
        medium: customMediaQuery(MEDIA_QUERY_SIZE.medium.min, MEDIA_QUERY_SIZE.medium.max),
        small: customMediaQuery(MEDIA_QUERY_SIZE.small.min, MEDIA_QUERY_SIZE.small.max),
        extraSmall: customMediaQuery(MEDIA_QUERY_SIZE.extraSmall.min, MEDIA_QUERY_SIZE.extraSmall.max),
    },
};
