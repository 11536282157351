// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import {
    useFocused,
    useSelected,
}                                               from 'slate-react';
import {
    TwitterTweetEmbed,
    TwitterTimelineEmbed,
}                                               from 'react-twitter-embed';

// ===== Services =====

import {
    getTwitterId,
    recordUserAction,
    setColorLightness,
}                                               from '../../../services';

// ===== Interfaces =====

import {
    ITwitterData,
    IUserItem,
}                                               from '../../../interfaces';
import { instanceOfTwitterData }                from '../../../interfaces/ITwitterData';

// ===== Enums =====

import {
    USER_ACTION_TYPE,
    EDITOR_CONTEXT_TYPE,
}                                               from '../../../enums';

// ===== Constants =====

import {
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
}                                               from '../../../constants/generalConstants';

// Example Tweet: https://twitter.com/afikanyati/status/1172996040192069633
// Example Timeline: https://twitter.com/EricRWeinstein

TwitterEmbed.defaultProps = {
    onLoad: undefined,
};
interface Props {
    id: string,
    url: string,
    color: string,
    user: IUserItem | null,
    currentSessionId: string | null,
    postId: string | undefined,
    editorType: EDITOR_CONTEXT_TYPE,
    readOnly: boolean,
    children: React.ReactElement,
    attributes: any,
    onLoad?: () => void,
}
function TwitterEmbed({
    id,
    url,
    color,
    user,
    currentSessionId,
    postId,
    editorType,
    readOnly,
    children,
    attributes,
    onLoad,
}: Props): JSX.Element {
    // ===== State =====

    const [tweetId, setTweetId] = useState<string>('');
    const [twitterUsername, setTwitterUsername] = useState<string>('');
    const focused = useFocused();
    const selected = useSelected();

    // ===== Side Effects =====

    useEffect(() => {
        const data = getTwitterId(url);

        if (instanceOfTwitterData(data)) {
            const { id: twtId, username } = data as ITwitterData;
            if (twtId) {
                setTweetId(twtId);
            }

            if (username) {
                setTwitterUsername(username);
            }
        }
    }, [url]);

    const clickTweet = async (): Promise<void> => {
        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickTweet,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    postId,
                    editorType,
                },
            });
        }
    };

    return (
        <Container
            {...attributes}
            color={color}
            focused={focused}
            selected={selected}
            isTimeline={tweetId.length === 0
            && twitterUsername.length > 0}
            onClick={clickTweet}
        >
            {tweetId.length > 0
            && (
                <EmbedContainer
                    // Necessary to avoid: Cannot resolve a Slate point from DOM point
                    // Reference: https://github.com/ianstormtaylor/slate/issues/3930
                    contentEditable={false}
                >
                    <TwitterTweetEmbed
                        tweetId={tweetId}
                        onLoad={onLoad}
                    />
                </EmbedContainer>
            )}
            {tweetId.length === 0
            && twitterUsername.length > 0
            && (
                <EmbedContainer
                    // Necessary to avoid: Cannot resolve a Slate point from DOM point
                    // Reference: https://github.com/ianstormtaylor/slate/issues/3930
                    contentEditable={false}
                >
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName={twitterUsername}
                        options={{
                            height: 0.7 * window.innerHeight,
                        }}
                        onLoad={onLoad}
                    />
                </EmbedContainer>
            )}
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    focused: boolean,
    selected: boolean,
    color: string,
    isTimeline: boolean,
}
const Container = styled.div<ContainerProps>`
    border-radius: 5px;
    margin: 20px 0;

    & > div > * {
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
        ${({ isTimeline }) => isTimeline && `
            display: block !important;
            max-width: 600px !important;
            margin-left: auto !important;
            margin-right: auto !important;
        `};
    border: ${({
        focused,
        selected,
        color,
        theme,
    }) => (focused && selected
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        } !important`
        : 'none'
    )};
    }
`;

const EmbedContainer = styled.div``;

export default TwitterEmbed;
