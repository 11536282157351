const playAudio = (audio: HTMLAudioElement): void => {
    const playPromise = audio.play();
    if (playPromise !== undefined) {
        playPromise
            .then(() => {
                // Automatic playback started!
            })
            .catch(() => {
                // Auto-play was prevented
            });
    }
};

export default playAudio;
