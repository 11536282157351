// ===== Packages =====

import {
    doc,
    setDoc,
    getFirestore,
}                                       from 'firebase/firestore';

// ===== Interfaces =====

import {
    IMediaItem,
    IEditorLinkEmbeddingLinkBundle,
    IEditorImageItem,
    IEditorAudioNoteItem,
    IEditorSketchItem,
    IEditorLinkItem,
    IEditorEmbeddingItem,
}                                       from '../interfaces';

// ===== Enums =====

import {
    MEDIA_TYPE,
    FIRESTORE_COLLECTION,
}                                       from '../enums';

interface SetMediaOptions {
    mediaItem?: IMediaItem,
    linkEmbeddingBundle?: IEditorLinkEmbeddingLinkBundle,
    filePath?: string,
}
const setMediaInDB = async ({
    mediaItem,
    linkEmbeddingBundle,
    filePath,
}: SetMediaOptions): Promise<void> => {
    const db = getFirestore();
    const collection = process.env.NODE_ENV === 'production'
        ? FIRESTORE_COLLECTION.media
        : FIRESTORE_COLLECTION.stagingMedia;
    if (mediaItem) {
        switch (mediaItem.type) {
        case MEDIA_TYPE.image: {
            if (filePath) {
                const media: IEditorImageItem = {
                    id: mediaItem.id,
                    userId: mediaItem.userId,
                    name: mediaItem.file.name.toLowerCase().split('.')[0],
                    type: mediaItem.type,
                    filePath,
                };
                await setDoc(doc(db, collection, mediaItem.id), media);
            } else {
                throw Error('Encountered underspecified Image during media data push to Firestore.');
            }
            break;
        }
        case MEDIA_TYPE.sketch: {
            if (filePath) {
                const media: IEditorSketchItem = {
                    id: mediaItem.id,
                    userId: mediaItem.userId,
                    name: mediaItem.file.name.toLowerCase().split('.')[0],
                    type: mediaItem.type,
                    filePath,
                };
                await setDoc(doc(db, collection, mediaItem.id), media);
            } else {
                throw Error('Encountered underspecified Sketch during media data push to Firestore.');
            }
            break;
        }
        case MEDIA_TYPE.audioNote: {
            if (mediaItem.title && filePath) {
                const media: IEditorAudioNoteItem = {
                    id: mediaItem.id,
                    userId: mediaItem.userId,
                    title: mediaItem.title,
                    type: mediaItem.type,
                    plays: [],
                    ...(mediaItem.description
                        ? { description: mediaItem.description }
                        : {}
                    ),
                    filePath,
                };
                await setDoc(doc(db, collection, mediaItem.id), media);
            } else {
                throw Error('Encountered underspecified Audio Note during media data push to Firestore.');
            }
            break;
        }
        default:
            throw Error('Encountered unrecognized media type during media data push to Firestore.');
        }
    } else if (linkEmbeddingBundle) {
        switch (linkEmbeddingBundle.type) {
        case MEDIA_TYPE.link: {
            if (linkEmbeddingBundle.url) {
                const media: IEditorLinkItem = {
                    id: linkEmbeddingBundle.id,
                    userId: linkEmbeddingBundle.userId,
                    type: linkEmbeddingBundle.type,
                    href: linkEmbeddingBundle.url,
                    clicks: [],
                };
                await setDoc(doc(db, collection, linkEmbeddingBundle.id), media);
            } else {
                throw Error('Encountered underspecified Link during media data push to Firestore.');
            }
            break;
        }
        case MEDIA_TYPE.embedding: {
            if (linkEmbeddingBundle.url && linkEmbeddingBundle.embeddingType) {
                const media: IEditorEmbeddingItem = {
                    id: linkEmbeddingBundle.id,
                    userId: linkEmbeddingBundle.userId,
                    type: linkEmbeddingBundle.type,
                    url: linkEmbeddingBundle.url,
                    embeddingType: linkEmbeddingBundle.embeddingType,
                };
                await setDoc(doc(db, collection, linkEmbeddingBundle.id), media);
            } else {
                throw Error('Encountered underspecified Embedding during media data push to Firestore.');
            }
            break;
        }
        default:
            throw Error('Encountered unrecognized media type during media data push to Firestore.');
        }
    }
};

export default setMediaInDB;
