// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeGetPaymentIntent = async ({
    test,
    paymentIntentId,
}: {
    test: boolean,
    paymentIntentId: string,
}): Promise<Stripe.PaymentIntent> => {
    const functions = getFunctions();
    const getPaymentIntent = httpsCallable<
        Record<
            string,
            boolean | string
        >,
        Stripe.PaymentIntent
    >(
        functions,
        'stripeGetPaymentIntent',
    );

    return getPaymentIntent({
        test,
        paymentIntentId,
    }).then((result) => {
        const paymentIntent = result.data as Stripe.PaymentIntent;
        return paymentIntent;
    }).catch((err) => err);
};

export default stripeGetPaymentIntent;
