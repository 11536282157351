// ===== Packages =====

import {
    doc,
    setDoc,
    getFirestore,
}                                       from 'firebase/firestore';

// ===== Interfaces =====

import {
    IAnnotationItem,
}                                       from '../interfaces';

// ===== Enums =====

import {
    FIRESTORE_COLLECTION,
}                                       from '../enums';

interface SetAnnotationOptions {
    annotation: IAnnotationItem,
}
const setAnnotationInDB = async ({ annotation }: SetAnnotationOptions): Promise<void> => {
    const db = getFirestore();
    const collection = process.env.NODE_ENV === 'production'
        ? FIRESTORE_COLLECTION.annotations
        : FIRESTORE_COLLECTION.stagingAnnotations;
    await setDoc(doc(db, collection, annotation.id), annotation);
};

export default setAnnotationInDB;
