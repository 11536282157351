// ===== Packages =====

import styled                       from 'styled-components';

// ===== Services =====

import {
    fontColorDiscriminator,
}                                   from '../../services';

// ===== Constants =====

import {
    CHARACTER_NAME_TEXT_MARGIN,
    CHARACTER_NAME_FONT_MULTIPLIER,
    CHARACTER_NAME_PARALLELOGRAM_HEIGHT,
    MAX_CHARACTER_ROLE_TEXT_WIDTH,
    MAX_CHARACTER_NAME_TEXT_WIDTH,
    CHARACTER_TILE_BORDER_WIDTH,
    CHARACTER_TILE_GRID_GUTTER_WIDTH,
    CAROUSEL_CONTAINER_HEIGHT_SMALL_VIEWPORT,
    CAROUSEL_CONTAINER_HEIGHT_EXTRA_SMALL_VIEWPORT,
    CAROUSEL_CONTAINER_MARGIN,
    CHARACTER_DETAIL_CONTAINER_MARGIN_SMALL_VIEWPORT,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

const CHARACTER_FULL_LENGTH_IMAGE_Z_INDEX = 1;
const CHARACTER_NAME_CONTAINER_MARGIN_TOP = 30;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.verascopeColor.purple100};

    ${({ theme }) => theme.mediaQuery.small} {
        flex-direction: column-reverse;
        background: ${({ theme }) => theme.verascopeColor.purple200};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column-reverse;
        background: ${({ theme }) => theme.verascopeColor.purple200};
    }
`;

export const CharacterTileContainer = styled.div`
    flex: 0 0 auto;
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    padding: 30px;
    
    ${({ theme }) => theme.mediaQuery.small} {
        position: relative;
        display: block;
        perspective: 1200px;
        height: ${CAROUSEL_CONTAINER_HEIGHT_SMALL_VIEWPORT}px;
        margin: 0px ${CHARACTER_DETAIL_CONTAINER_MARGIN_SMALL_VIEWPORT}px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        position: relative;
        display: block;
        perspective: 1200px;
        height: ${CAROUSEL_CONTAINER_HEIGHT_EXTRA_SMALL_VIEWPORT}px;
        margin: 0px ${CHARACTER_DETAIL_CONTAINER_MARGIN_SMALL_VIEWPORT}px;
    }
`;

export const CharacterTileGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: ${CHARACTER_TILE_GRID_GUTTER_WIDTH - 2 * CHARACTER_TILE_BORDER_WIDTH}px;
    row-gap: ${CHARACTER_TILE_GRID_GUTTER_WIDTH - 2 * CHARACTER_TILE_BORDER_WIDTH}px;
    
    ${({ theme }) => theme.mediaQuery.small} {
        display: block; 
        transform-style: preserve-3d;
        transform-origin: 50% 50% -400px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: block;
        transform-style: preserve-3d;
    }
`;

interface CharacterTileProps {
    index: number,
    rotateOffsetIndex: number,
    visible: boolean,
    isInitialRender: boolean,
    numTiles: number,
    length: number,
    selected: boolean,
    background: string,
    transitionDuration: number,
    enterDelayTransitionDuration: number,
    exitDelayTransitionDuration: number,
}
export const CharacterTile = styled.div<CharacterTileProps>`
    position: relative;
    left: ${({ visible }) => (visible ? '0px' : '-20px')};
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    justify-self: center;
    align-self: center;
    background: ${({ background }) => background};
    box-sizing: content-box;
    transform: scale(1);
    box-shadow: ${({ theme }) => theme.color.boxShadow400};
    border-radius: ${CHARACTER_TILE_BORDER_WIDTH}px;
    border: ${({ selected, theme }) => (selected
        ? `5px solid ${theme.color.white}`
        : 'none'
    )};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        visible,
        isInitialRender,
        transitionDuration,
        enterDelayTransitionDuration,
        exitDelayTransitionDuration,
        theme,
    }) => `
        background ${transitionDuration}ms ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${visible ? enterDelayTransitionDuration : exitDelayTransitionDuration}ms ${theme.motion.overshoot},
        box-shadow ${transitionDuration}ms ${theme.motion.overshoot},
        transform ${transitionDuration}ms ${isInitialRender
    ? `${visible ? enterDelayTransitionDuration : exitDelayTransitionDuration}`
    : 0
}ms ${isInitialRender
    ? theme.motion.delayEasing
    : theme.motion.overshoot
},
        opacity ${transitionDuration}ms ${isInitialRender
    ? `${visible ? enterDelayTransitionDuration : exitDelayTransitionDuration}`
    : 0
}ms ${isInitialRender
    ? theme.motion.delayEasing
    : theme.motion.standardEasing
}
    `};

    ${({ selected, theme }) => !selected && `
        &:hover {
            background: ${theme.verascopeColor.white};
            transform: scale(1.1);
            box-shadow: ${theme.color.boxShadow500};
            z-index: 1;
    }`}

    ${({ theme }) => theme.mediaQuery.small} {
        position: absolute;
        transform: ${({
        visible,
        index,
        numTiles,
        rotateOffsetIndex,
    }) => (visible
        ? `rotateY(${(-index + rotateOffsetIndex) * (360 / numTiles)}deg) scale(1)`
        : 'rotateY(0deg) scale(1)'
    )};
        display: flex;
        align-items: center;
        left: 47%;
        transform-origin: 50% 50% -200px;
        height: auto;
        width: 100px;

        &:hover {
            transform: ${({
        index,
        numTiles,
        rotateOffsetIndex,
    }) => `rotateY(${(-index + rotateOffsetIndex) * (360 / numTiles)}deg) scale(1.1)`};
        }
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: flex;
        align-items: center;
        position: absolute;
        transform: ${({
        visible,
        index,
        numTiles,
        rotateOffsetIndex,
    }) => (visible
        ? `rotateY(${(-index + rotateOffsetIndex) * (360 / numTiles)}deg) scale(1)`
        : 'rotateY(0deg) scale(1)'
    )};
        left: 40%;
        transform-origin: 50% 50% -130px;
        height: auto;
        width: 60px;

        &:hover {
            transform: ${({
        index,
        numTiles,
        rotateOffsetIndex,
    }) => `rotateY(${(-index + rotateOffsetIndex) * (360 / numTiles)}deg) scale(1.1)`};
        }
    }   
`;

export const CharacterTileImage = styled.img`
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

export const CharacterDetailContainer = styled.div`
    position: relative;
    flex-shrink: 2;
    flex-grow: 2;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    ${({ theme }) => theme.mediaQuery.small} {
        height: ${`calc(100% - ${CAROUSEL_CONTAINER_HEIGHT_SMALL_VIEWPORT + CAROUSEL_CONTAINER_MARGIN}px)`};
        background: ${({ theme }) => theme.verascopeColor.purple100};
        box-shadow: ${({ theme }) => theme.color.boxShadow500};
        margin: 50px;
        margin-top: 90px;
        border-radius: 10px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        height: ${`calc(100% - ${CAROUSEL_CONTAINER_HEIGHT_EXTRA_SMALL_VIEWPORT + CAROUSEL_CONTAINER_MARGIN}px)`};
        background: ${({ theme }) => theme.verascopeColor.purple100};
        box-shadow: ${({ theme }) => theme.color.boxShadow500};
        margin: ${CHARACTER_DETAIL_CONTAINER_MARGIN_SMALL_VIEWPORT}px;
        margin-top: 90px;
        border-radius: 10px;
    }
`;

export const CharacterDetail = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    width: 550px;

    ${({ theme }) => theme.mediaQuery.small} {
        padding-left: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 30px));
        width: 400px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-left: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 30px));
        width: 400px;
    }
`;

export const CharacterShadow = styled.div`
    position: absolute;
    bottom: ${`${CHARACTER_NAME_PARALLELOGRAM_HEIGHT + 20}px`};
    left: 50%;
    transform: translateX(-50%) !important;
    height: 50px;
    width: 300px;
    border-radius: 70%;
    background: ${({ theme }) => theme.verascopeColor.purple200};
`;

export const CharacterNameContainer = styled.div`
    position: relative;
    margin-top: ${`${CHARACTER_NAME_CONTAINER_MARGIN_TOP}px`};
    width: 100%;

    ${({ theme }) => theme.mediaQuery.small} {
        margin-top: 0px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-top: 0px;
    }
`;

interface CharacterNameParallelogramProps {
    visible: boolean,
    width: number | undefined,
    zeroWidth: boolean,
    longestWordChar: number | undefined,
    color: string,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterNameParallelogram = styled.div<CharacterNameParallelogramProps>`
    position: absolute;
    top: 50%;
    left: 0px;
    width: ${({
        width,
        zeroWidth,
        longestWordChar,
    }) => {
        if (zeroWidth) {
            return '0px';
        }

        if (
            width
            && longestWordChar
            && width > MAX_CHARACTER_NAME_TEXT_WIDTH
        ) {
            return `${MAX_CHARACTER_NAME_TEXT_WIDTH}px`;
        }

        if (width) {
            return `${width + 2 * CHARACTER_NAME_TEXT_MARGIN}px`;
        }

        return '0px';
    }};
    height: ${({ width }) => (width && width > MAX_CHARACTER_NAME_TEXT_WIDTH
        ? `${Math.ceil(width / MAX_CHARACTER_NAME_TEXT_WIDTH) * CHARACTER_NAME_PARALLELOGRAM_HEIGHT}px`
        : `${CHARACTER_NAME_PARALLELOGRAM_HEIGHT}px`
    )};
    transform: skew(-20deg) translateY(-50%);
    background: ${({ color }) => color};
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
    border-radius: 3px;
    white-space: ${({ width }) => (width && width > MAX_CHARACTER_NAME_TEXT_WIDTH
        ? 'normal'
        : 'nowrap'
    )};
    transition:${({
        visible,
        zeroWidth,
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => {
        if (visible && zeroWidth) {
            // when exiting, it waits for text to go before transitioning out
            return `
                width ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.overshoot},
                height ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.overshoot}
            `;
        }

        // we want no delay
        if (visible) {
            return `
                width ${transitionDuration}ms 0ms ${theme.motion.overshoot},
                height ${transitionDuration}ms 0ms ${theme.motion.overshoot}
            `;
        }

        return `
            width ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.overshoot},
            height ${transitionDuration}ms ${transitionDuration + transitionDelayDuration}ms ${theme.motion.overshoot}
        `;
    }};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        left: 50%;
        transform: skew(-20deg) translate(-53%, -50%);
    }
`;

export const CharacterNameTextContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
    height: ${CHARACTER_NAME_PARALLELOGRAM_HEIGHT}px;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column;
    }
`;

interface CharacterNameTextProps {
    width: number | undefined,
    longestWordChar: number | undefined,
    cachedLongestWordChar: number | null,
    color: string,
    visible: boolean,
    freezeLayout: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterNameText = styled.h3<CharacterNameTextProps>`
    display: inline-block;
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${CHARACTER_NAME_FONT_MULTIPLIER}em;
    color: ${({ color }) => fontColorDiscriminator(color)};
    line-height: 1.5em;
    text-transform: uppercase;
    margin: ${`0px ${CHARACTER_NAME_TEXT_MARGIN}px`};
    margin-left: ${CHARACTER_NAME_TEXT_MARGIN}px;
    margin-right: ${({ width, freezeLayout }) => ((width && width > MAX_CHARACTER_NAME_TEXT_WIDTH) || freezeLayout
        ? `${1.5 * CHARACTER_NAME_TEXT_MARGIN}px`
        : `${CHARACTER_NAME_TEXT_MARGIN}px`
    )};
    white-space: ${({ width, freezeLayout }) => ((width && width > MAX_CHARACTER_NAME_TEXT_WIDTH) || freezeLayout
        ? 'normal'
        : 'nowrap'
    )};
    text-align: ${({ width, freezeLayout }) => ((width && width > MAX_CHARACTER_NAME_TEXT_WIDTH) || freezeLayout
        ? 'center'
        : 'left'
    )};
    max-width: ${({
        width,
        cachedLongestWordChar,
        longestWordChar,
    }) => (width
    && (longestWordChar || cachedLongestWordChar)
    && width > MAX_CHARACTER_NAME_TEXT_WIDTH
        ? `${longestWordChar || cachedLongestWordChar}ch`
        : 'none'
    )};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        transitionDuration,
        transitionDelayDuration,
        visible,
        theme,
    }) => `opacity ${transitionDuration}ms ${visible ? transitionDelayDuration : 0}ms ${theme.motion.delayEasing}`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        position: relative;
        top: ${({ width, freezeLayout }) => ((width && width > MAX_CHARACTER_NAME_TEXT_WIDTH) || freezeLayout
        ? '-7.5px'
        : '0px'
    )};
        margin-right: ${CHARACTER_NAME_TEXT_MARGIN}px;
        margin-top: ${({ width, freezeLayout }) => ((width && width > MAX_CHARACTER_NAME_TEXT_WIDTH) || freezeLayout
        ? '0px'
        : '7.5px'
    )};
    }
`;

interface CharacterRoleTextProps {
    width: number | undefined,
    longestWordChar: number | undefined,
    cachedLongestWordChar: number | null,
    visible: boolean,
    freezeLayout: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterRoleText = styled.h4<CharacterRoleTextProps>`
    display: inline-block;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${CHARACTER_NAME_FONT_MULTIPLIER}em;
    font-style: italic;
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    line-height: 1.5em;
    text-transform: uppercase;
    margin: 0;
    margin-left: 20px;
    max-width: ${({ width, longestWordChar, cachedLongestWordChar }) => (width
    && (longestWordChar || cachedLongestWordChar)
    && width > MAX_CHARACTER_ROLE_TEXT_WIDTH
        ? `${longestWordChar || cachedLongestWordChar}ch`
        : 'none'
    )};
    white-space: ${({ width, freezeLayout }) => ((width && width > MAX_CHARACTER_ROLE_TEXT_WIDTH) || freezeLayout
        ? 'normal'
        : 'nowrap'
    )};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        transitionDuration,
        transitionDelayDuration,
        visible,
        theme,
    }) => `opacity ${transitionDuration}ms ${visible ? transitionDelayDuration : 0}ms ${theme.motion.delayEasing}`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${0.85 * CHARACTER_NAME_FONT_MULTIPLIER}em;
        margin-left: 0px;
        margin-top: 10px;
        max-width: none;
    }
`;

export const CharacterFullLengthImage = styled.img`
    position: relative;
    height: 400px;
    z-index: ${CHARACTER_FULL_LENGTH_IMAGE_Z_INDEX};
    pointer-events: none;

    ${({ theme }) => theme.mediaQuery.small} {
        top: ${`-${CHARACTER_NAME_CONTAINER_MARGIN_TOP}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${`-${CHARACTER_NAME_CONTAINER_MARGIN_TOP}px`};
    }
`;

export const CharacterInfo = styled.div`
    position: absolute;
    top: 50px;
    right: 50px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    transform: translateX(100%) perspective(1000px) rotateY(-25deg) rotateZ(0deg);

    ${({ theme }) => theme.mediaQuery.medium} {
        display: none;
    }

    ${({ theme }) => theme.mediaQuery.small} {
        display: none;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

interface CharacterInfoBorderProps {
    visible: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterInfoBorder = styled.div<CharacterInfoBorderProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: ${({ visible }) => (visible ? '100%' : '0%')};
    background: ${({ theme }) => theme.color.white};
    transition: ${({
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => `height ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing}`};

    ${({ theme }) => theme.mediaQuery.small} {
        display: none;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

interface CharacterInfoRowProps {
    margin?: boolean,
}
export const CharacterInfoRow = styled.div<CharacterInfoRowProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${({ margin }) => margin && '2.5px 0px'};
`;

interface CharacterInfoRowIconProps {
    visible: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterInfoRowIcon = styled.img<CharacterInfoRowIconProps>`
    position: relative;
    left: ${({ visible }) => `${visible ? 0 : -15}px`};
    width: 15px;
    height: 15px;
    margin: 5px;
    margin-right: 15px;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => `
        opacity ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing}
    `};
`;

interface CharacterInfoRowTextProps {
    visible: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterInfoRowText = styled.p<CharacterInfoRowTextProps>`
    position: relative;
    left: ${({ visible }) => `${visible ? 0 : -15}px`};
    font-size: 0.8em;
    font-weight: 300;
    font-style: normal;
    color: ${({ theme }) => theme.color.white};
    line-height: 1.5em;
    margin: 0;
    max-width: 30ch;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => `
        opacity ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing}
    `};
`;

export const CharacterInfoRowButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

interface CharacterInfoProfileRowButtonProps {
    visible: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CharacterInfoProfileRowButton = styled.button<CharacterInfoProfileRowButtonProps>`
    position: relative;
    left: ${({ visible }) => `${visible ? 0 : -15}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: none;
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transform: scale(1);
    border: none;
    cursor: none;
    margin: 0px 5px;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        transitionDuration,
        transitionDelayDuration,
        theme,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.overshoot},
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        background ${transitionDuration}ms ${theme.motion.overshoot},
        opacity ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing}
    `};

    &:hover {
        transform: scale(1.1);
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        background: ${({ theme }) => theme.verascopeColor.purple200};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        background: ${({ theme }) => theme.verascopeColor.purple200};

        &:hover {
            background: ${({ theme }) => theme.verascopeColor.purple300};
        }
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        background: ${({ theme }) => theme.verascopeColor.purple100};

        &:hover {
            background: ${({ theme }) => theme.verascopeColor.purple300};
        }
    }
`;

export const CharacterInfoProfileRowButtonIcon = styled.img`
    width: 15px;
    height: 15px;
`;

interface CarouselButtonProps {
    visible: boolean,
    leftSided?: boolean,
    transitionDuration: number,
    transitionDelayDuration: number,
}
export const CarouselButton = styled.button<CarouselButtonProps>`
    position: absolute;
    top: 50%;
    left: ${({ leftSided }) => (leftSided
        ? '0'
        : 'auto'
    )};
    right: ${({ leftSided }) => (leftSided
        ? 'auto'
        : '0'
    )};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: ${({ theme }) => theme.verascopeColor.purple300};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transform: ${({ leftSided }) => (leftSided
        ? 'translateY(-50%) scale(-1, 1)'
        : 'translateY(-50%) scale(1)'
    )};
    border: none;
    cursor: none;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({
        transitionDuration,
        theme,
        transitionDelayDuration,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.overshoot},
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        background ${transitionDuration}ms ${theme.motion.overshoot},
        opacity ${transitionDuration}ms ${transitionDelayDuration}ms ${theme.motion.delayEasing}
    `};

    &:hover {
        transform: ${({ leftSided }) => (leftSided
        ? 'translateY(-50%) scale(-1.1, 1.1)'
        : 'translateY(-50%) scale(1.1)'
    )};
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        background: ${({ theme }) => theme.verascopeColor.purple400};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
`;

export const CarouselButtonIcon = styled.img`
    width: 30px;
    height: 30px;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: 20px;
        height: 20px;
    }
`;

interface CharacterInfoButtonProps {
    transitionDuration: number,
}
export const CharacterInfoButton = styled.button<CharacterInfoButtonProps>`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: ${({ theme }) => theme.verascopeColor.purple200};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transform: scale(1);
    border: none;
    cursor: none;
    z-index: ${CHARACTER_FULL_LENGTH_IMAGE_Z_INDEX + 1}; // To place in front of CharacterFullLengthImage
    transition: ${({
        transitionDuration,
        theme,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.overshoot},
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        background ${transitionDuration}ms ${theme.motion.overshoot}
    `};

    &:hover {
        transform: scale(1.1);
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        background: ${({ theme }) => theme.verascopeColor.purple300};
    }
`;

export const CharacterInfoButtonIcon = styled.img`
    width: 15px;
    height: 15px;
`;

export const ModalContentsContainer = styled.div``;

interface ModalCloseButtonProps {
    transitionDuration: number,
}
export const ModalCloseButton = styled.button<ModalCloseButtonProps>`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: transparent;
    transform: scale(1);
    padding: 2.5px;
    border: none;
    cursor: none;
    transition: ${({
        transitionDuration,
        theme,
    }) => `
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        background ${transitionDuration}ms ${theme.motion.overshoot}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.white};
    }

    &:hover {
        transform: scale(1.1);
        background: ${({ theme }) => theme.verascopeColor.purple400};
    }
`;

export const ApplyButtonContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${CHARACTER_FULL_LENGTH_IMAGE_Z_INDEX + 1}; // To place in front of CharacterFullLengthImage
`;
