export enum CURSOR_TARGET {
    logomark = 'logomark',
    about = 'about',
    book = 'book',
    stayInformed = 'stay informed',
    continue = 'continue',
    characters = 'characters',
    treasureChest = 'treasure chest',
    exit = 'exit',
    input = 'input',
    radioInput = 'radio-input',
    email = 'email',
    twitter = 'twitter',
    abacus = 'abacus',
    molecule = 'molecule',
    gameController = 'game-controller',
    magnet = 'magnet',
    lightbulb = 'lightbulb',
    puzzleRed = 'puzzle-red',
    puzzleOrange = 'puzzle-orange',
    puzzleGreen = 'puzzle-green',
    puzzleBlue = 'puzzle-blue',
    cuneiformTablet='cuneiform-tablet',
    sound = 'sound',
    cart = 'cart',
    handlebar = 'handlebar',
    ejectButton = 'eject-button',
    cartridge = 'cartridge',
    linkButton = 'link-button',
    selectorExpander = 'selectorExpander',
    filterPivot = 'filter-pivot',
    tag = 'tag',
    resetButton = 'reset-button',
    characterTile = 'character-tile',
    characterInfoProfileButton = 'character-info-profile-button',
    characterCarouselButton = 'character-carousel-button',
    characterUnlockButton = 'character-unlock-button',
    characterInfoButton = 'character-info-button',
    modalCloseButton = 'modal-close-button',
    editorPivot = 'editor-pivot',
    editor = 'editor',
    editorButton = 'editor-button',
    editorToolbarToolGroup = 'editor-toolbar-tool-group',
    editorToolbarTool = 'editor-toolbar-tool',
    editorToolbarButton = 'editor-toolbar-button',
    emojiCategory = 'emoji-category',
    emoji = 'emoji',
    annotator = 'annotator',
    editorElement = 'editor-element',
    editorElementButton = 'editor-element-button',
    userAvatar = 'user-avatar',
    userProfileButton = 'user-profile-button',
    audioNoteTranscriptWord = 'audio-note-transcript-word',
    contentsTableButton = 'contents-table-button',
    resubscribeButton = 'resubscribe-button',
    dragIndicator = 'drag-indicator',
    addChapterButton = 'add-chapter-button',
    addSectionButton = 'add-section-button',
    socialEmergenceGraph = 'social-emergence-graph',
    breadcrumbButton = 'breadcrumb-button',
    cartBuyBookButton = 'cart-buy-book-button',
    clearCartButton = 'clear-cart-button',
    cartItemQuantityButton = 'cart-item-quantity-button',
    cartItemRemoveButton = 'cart-item-remove-button',
    promotionCodeToggleButton = 'promotion-code-toggle-button',
    promotionCodeAddButton = 'promotion-code-add-button',
    promotionCodeBadgeRemoveButton = 'promotion-code-badge-remove-button',
    checkoutActionButton = 'checkout-action-button',
    checkoutBackButton = 'checkout-back-button',
    dropdown = 'dropdown',
    dropdownUnselectButton = 'dropdown-unselect-button',
    orderSummaryToggleButton = 'order-summary-toggle-button',
}
