// ===== Packages =====

import {
    Node,
    Element,
}                           from 'slate';

// ===== Intefaces =====

import {
    CaptionOptions,
    CaptionNode,
    EditorNode,
}                           from '../../interfaces';

// ===== Enums =====

import {
    FIGURE_CONTENT_TYPE,
    FIGURE_ORIENTATION,
}                           from './enums';

const BLOCK_CAPTION_TYPE = 'caption';

const isCaptionElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_CAPTION_TYPE;

const newCaptionElement = (options: CaptionOptions): CaptionNode => {
    const {
        id,
        orientation,
        text,
        figureContentType,
        isGalleryChild,
    } = options;

    const captionElement = {
        id,
        type: BLOCK_CAPTION_TYPE,
        orientation: orientation || FIGURE_ORIENTATION.center,
        figureContentType: figureContentType || FIGURE_CONTENT_TYPE.image,
        children: [{ text: text || '' }],
        ...(isGalleryChild ? { isGalleryChild: true } : {}),
    };

    return captionElement;
};

export default {
    TYPE: BLOCK_CAPTION_TYPE,
    isCaptionElement,
    newCaptionElement,
};
