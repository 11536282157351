function binarySearchIndex<T>(
    arr: T[],
    isLower: (val: T) => boolean,
    isHigher: (val: T) => boolean,
): number | null {
    let left = 0;
    let right = arr.length - 1;

    while (left <= right) {
        const middle = Math.floor((left + right) / 2);

        if (isLower(arr[middle])) {
            left = middle + 1;
        } else if (isHigher(arr[middle])) {
            right = middle - 1;
        } else {
            return middle;
        }
    }
    return null;
}

export default binarySearchIndex;
