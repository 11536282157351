// ===== Packages =====

import {
    Node,
    Editor,
    Transforms,
    Range,
    Point,
    Path,
    NodeEntry,
    BaseRange,
}                           from 'slate';

// ===== Services =====

import {
    validateURL,
}                           from '../../../services';

// ===== Elements =====

import _WebLinkElement      from '../elements/webLinkElement';

// ===== Interfaces =====

import {
    WebLinkNode,
}                           from '../interfaces';

export const WebLinkElement = _WebLinkElement;

const getSelectionWebLinks = (editor: Editor): NodeEntry<Node>[] => Array.from(Editor.nodes(editor, { match: WebLinkElement.isWebLinkElement }));

const hasSelectedLink = (editor: Editor): boolean => {
    const [match] = getSelectionWebLinks(editor);
    return !!match;
};

const setWebLinkHref = (editor: Editor, id: string, newHref: string): void => {
    Array.from(Editor.nodes(editor, { match: WebLinkElement.isWebLinkElement })).forEach(([, webLinkPath]) => {
        Transforms.setNodes<WebLinkNode>(editor, { id, href: newHref }, { at: webLinkPath });
    });
};

const wrapSelectionInLinkCmd = (editor: Editor, id: string, href: string): void => {
    const { selection } = editor;
    if (!selection) { return; }

    if (Range.isCollapsed(selection)) {
        const above = Editor.above(editor, { match: (n) => Editor.isBlock(editor, n) });
        const [, parentBlockPath] = above as NodeEntry;
        const blockStartPoint = Editor.start(editor, parentBlockPath);
        const blockEndPoint = Editor.end(editor, parentBlockPath);
        const entireBlockRange = {
            anchor: blockStartPoint,
            focus: blockEndPoint,
        };
        return Transforms.wrapNodes(editor, WebLinkElement.newWebLinkElement(id, href), { at: entireBlockRange });
    }

    Transforms.wrapNodes(editor, WebLinkElement.newWebLinkElement(id, href), { split: true });
};

const removeWebLink = (editor: Editor): void => {
    const [webLinkEntry] = Array.from(Editor.nodes(editor, { match: WebLinkElement.isWebLinkElement }));
    if (webLinkEntry) {
        const [, webLinkPath] = webLinkEntry;
        return Transforms.unwrapNodes(editor, { at: webLinkPath });
    }
};

export const WebLinkHelpers = {
    getSelectionWebLinks,
    setWebLinkHref,
    wrapSelectionInLinkCmd,
    removeWebLink,
    hasSelectedLink,
};

const normalizeWebLink = (
    editor: Editor,
    node: WebLinkNode,
    path: Path,
): void => {
    const linkParent = Node.parent(editor, path);

    if (!Editor.isBlock(editor, linkParent)) { return Transforms.unwrapNodes(editor, { at: path }); }

    const hrefIsValid = validateURL(node.href);
    if (!hrefIsValid) { return Transforms.unwrapNodes(editor, { at: path }); }

    if (Node.string(node).length === 0) { return Transforms.unwrapNodes(editor, { at: path }); }
};

const handleCursorIsAtEndOfWeblinkWhenBreakInserted = (editor: Editor, insertBreak: () => void): boolean | undefined => {
    const { selection } = editor;
    if (Range.isExpanded(selection as Range)) { return; }

    const [webLinkMatch] = Array.from(Editor.nodes(editor, { match: WebLinkElement.isWebLinkElement }));
    if (!webLinkMatch) { return; }

    const [, webLinkPath] = webLinkMatch;
    const [endLinkLeaf, endLinkLeafPath] = Editor.leaf(editor, webLinkPath, { edge: 'end' });
    const endOfLinkPoint = {
        path: endLinkLeafPath,
        offset: Node.string(endLinkLeaf).length,
    };
    const cursorIsAtEndOfLink = Point.equals((selection as BaseRange).anchor, endOfLinkPoint);

    if (cursorIsAtEndOfLink) {
        insertBreak();
        Transforms.move(editor);
        return true;
    }

    return false;
};

export const withWebLinks = (editor: Editor): Editor => {
    const {
        normalizeNode, isInline, isVoid, insertBreak,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as WebLinkNode;

        if (WebLinkElement.isWebLinkElement(node)) { return normalizeWebLink(editor, node, path); }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (WebLinkElement.isWebLinkElement(element) ? true : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (WebLinkElement.isWebLinkElement(node) ? false : isVoid(node));

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        if (handleCursorIsAtEndOfWeblinkWhenBreakInserted(editor, insertBreak)) {
            return;
        }

        return insertBreak();
    };

    return editor;
};
