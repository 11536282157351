// ===== Packages =====

import React                            from 'react';
import { ReactSVG }                     from 'react-svg';
import {
    useDrag,
    useDrop,
}                                       from 'react-dnd';

// ===== Components =====

import Tooltip                          from '../Tooltip';

// ===== Enums =====

import {
    DRAGGABLE_COMPONENT_TYPE,
    USER_ACTION_TYPE,
    DRAG_DIRECTION,
    TOOLTIP_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    detectTouchDevice,
    recordUserAction,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IPostChapterItem,
    IPostChapterSectionItem,
    ISnackbarItem,
    IPostPathEvent,
}                                       from '../../interfaces';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    EDITOR_SECTION_INPUT_ID_PREFIX,
    EDITOR_SECTION_INPUT_CLASSNAME,
    OUTLINE_DRAG_INDICATOR_TRANSITION_DURATION,
    OUTLINE_DRAG_INDICATOR_X_OFFSET,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    GRAB_DRAG_INDICATOR_DELAY_DURATION,
    SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
}                                       from '../../constants/generalConstants';
import KEYCODE                          from '../../constants/keycodes';

// ===== Images =====

import CautionIcon                      from '../../images/caution.svg';
import PencilIcon                       from '../../images/editor/pencil.svg';
import HighlightIcon                    from '../../images/editor/highlight.svg';
import DragIndicatorIcon                from '../../images/editor/drag-indicator.svg';

// ===== Styles =====

import {
    Container,
    OutlineChapterSectionInput,
    OutlineSectionDragIndicator,
    SectionNonEmptyIndicator,
    SectionAnnotationsIndicator,
}                                       from './styles';
import {
    OutlineNumberText,
}                                       from '../ReaderOutlineChapter/styles';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    postId: string,
    chapterIndex: number,
    index: number,
    numSections: number,
    section: IPostChapterSectionItem,
    transitionDuration: number,
    chapters: IPostChapterItem[],
    sections: IPostChapterSectionItem[],
    postValueIsSaving: boolean,
    updateChapters: (chapters: IPostChapterItem[], value?: string) => Promise<void>,
    onDragIndicatorMouseEnter: (e: React.MouseEvent) => void,
    onDragIndicatorMouseLeave: (e: React.MouseEvent) => void,
    onDragIndicatorMouseDown: (e: React.MouseEvent | React.TouchEvent) => void,
    onDragIndicatorMouseUp: () => void,
    onDragIndicatorMouseMove: (type: USER_ACTION_TYPE) => void,
    onInputMouseEnter: (e: React.MouseEvent) => void,
    onInputMouseLeave: (e: React.MouseEvent) => void,
    onInputFocus: (e: React.FocusEvent) => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    selectedPostValuePath: number[],
    onChangeSelectedPostValuePath: (event: IPostPathEvent) => void,
}
function ReaderOutlineSection({
    user,
    currentSessionId,
    postId,
    chapterIndex,
    index,
    numSections,
    section,
    transitionDuration,
    chapters,
    sections,
    postValueIsSaving,
    updateChapters,
    onDragIndicatorMouseEnter,
    onDragIndicatorMouseLeave,
    onDragIndicatorMouseDown,
    onDragIndicatorMouseUp,
    onDragIndicatorMouseMove,
    onInputMouseEnter,
    onInputMouseLeave,
    onInputFocus,
    setSnackbarData,
    selectedPostValuePath,
    onChangeSelectedPostValuePath,
}: Props): JSX.Element {
    // ===== General Constants =====

    const SNACKBAR_MESSAGE_DELETE_SECTION_WITH_VALUE_ERROR = 'Section text must be cleared before it can be deleted.';
    const SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR = 'Section is saving. Please try again in a few seconds.';

    // ===== State =====

    // Set up drag source for chapter section
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: DRAGGABLE_COMPONENT_TYPE.editorOutlineSection,
            item: {
                id: section.id,
                index,
            },
            canDrag: () => !postValueIsSaving,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { index: sectionIndex } = item;
                const didDrop = monitor.didDrop();

                // If the chapter was dropped outside the list, move it back to its original position
                if (!didDrop) {
                    moveSection(index, sectionIndex);
                }
            },
        }),
        [
            index,
            chapters,
        ],
    );

    // Set up drop target for chapter section
    const [, drop] = useDrop(
        () => ({
            accept: DRAGGABLE_COMPONENT_TYPE.editorOutlineSection,
            hover({ id, index: draggedIndex  }: { id: string, index: number }) {
                // If the chapter is being dragged over itself, do nothing
                if (id !== section.id) {
                    moveSection(draggedIndex, index);
                }
            },
            drop({ id }: { id: string, index: number }) {
                // If the chapter is being dropped on itself, do nothing
                if (id === section.id) {
                    // Update selected post value path
                    const updatedPath = [chapterIndex, index];
                    onChangeSelectedPostValuePath({
                        path: updatedPath,
                    });
                }
            },
        }),
        [chapters],
    );

    // ===== Animation Constants =====

    // Duration (in milliseconds) for non-empty chapter indicator to appear
    const CHAPTER_INDICATOR_TRANSITION_DURATION = GRAB_DRAG_INDICATOR_DELAY_DURATION;

    // ===== Methods =====

    /**
     * Manages determining whether keystroke is enter or escape
     * If enter: moves focus to section input
     * If escape: blurs focused section input
     * If backspace/delete: removes focused section input
     * @param e keyboard event
     */
    const checkForEnter = (e: React.KeyboardEvent): void => {
        if (e.key === KEYCODE.enter) {
            // Move from outline chapter section to next chapter section if it exists
            if (document.activeElement?.classList.contains(EDITOR_SECTION_INPUT_CLASSNAME)) {
                // Prevents newline character from being placed in next chapter section
                e.preventDefault();

                const nextSectionChildren = document.activeElement?.parentElement?.nextSibling?.childNodes;
                if (nextSectionChildren) {
                    const nextChapterSection = Array
                        .from(nextSectionChildren)
                        .filter((node: ChildNode) => node.nodeName === 'INPUT')[0] as HTMLInputElement;
                    if (nextChapterSection) {
                        nextChapterSection.focus();
                    }
                }
            }
        } else if (e.key === KEYCODE.escape) {
            // Blur Focused Input
            (document.activeElement as HTMLInputElement).blur();
        } else if (
            e.key === KEYCODE.backspace
            || e.key === KEYCODE.delete
        ) {
            // Delete Outline Chapter Section
            // Backspaced on empty section
            if (
                document.activeElement?.classList.contains(EDITOR_SECTION_INPUT_CLASSNAME)
                && (document.activeElement as HTMLInputElement).value.length === 0
            ) {
                const sectionIdParts = document.activeElement.id.split('-');
                const deleteSectionIndex = parseInt(sectionIdParts[2], 10);
                deleteSection(deleteSectionIndex);
            }
        }
    };

    /**
     * Updates text of a chapter section in contents table
     * @param e input event
     */
    const onOutlineSectionInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const updatedChapters = [...chapters];
        updatedChapters[chapterIndex] = {
            ...updatedChapters[chapterIndex],
            sections: [
                ...sections,
            ],
            updated: [
                ...updatedChapters[chapterIndex].updated,
                Date.now(),
            ],
        };
        updatedChapters[chapterIndex].sections![index] = {
            ...sections[index],
            title: e.target.value,
            updated: [
                ...updatedChapters[chapterIndex].sections![index].updated,
                Date.now(),
            ],
        };
        updateChapters(updatedChapters);
    };

    /**
     * Moves a section due to drag and drop
     * @param draggedIndex index of the section to move
     * @param droppedIndex index to move the section to
     */
    const moveSection = (draggedIndex: number, droppedIndex: number): void => {
        const updatedChapters = [...chapters];
        const [removed] = updatedChapters[chapterIndex].sections!.splice(draggedIndex, 1);
        updatedChapters[chapterIndex].sections!.splice(droppedIndex, 0, removed);
        updatedChapters[chapterIndex].updated = [
            ...updatedChapters[chapterIndex].updated,
            Date.now(),
        ];
        updateChapters(updatedChapters);

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.movePostChapterSection,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId: chapters[chapterIndex].id,
                    sectionId: removed.id,
                    direction: droppedIndex > draggedIndex ? DRAG_DIRECTION.down : DRAG_DIRECTION.up,
                    units: Math.abs(droppedIndex - draggedIndex),
                },
            });
        }
    };

    /**
     * Removes a section from a chapter in the contents table
     * @param index index of the chapter to remove the section from
     * @param sectionIndex index of the section to remove
     */
    const deleteSection = (sectionIndex: number): void => {
        if (postValueIsSaving) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }
        if (
            sections.length === 1
            && sections[sectionIndex].value
            && sections[sectionIndex].value.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length
        ) {
            // There is a single section with a non-empty value
            // Move it to the chapter value
            const updatedChapters = [...chapters];
            updatedChapters[chapterIndex].sections = [
                ...sections,
            ];
            const [removed] = updatedChapters[chapterIndex].sections!.splice(sectionIndex, 1);
            updatedChapters[chapterIndex].value = removed.value;
            updatedChapters[chapterIndex].updated = [
                ...updatedChapters[chapterIndex].updated,
                Date.now(),
            ];
            updateChapters(updatedChapters);

            // Modify selected post value path
            // Select chapter
            onChangeSelectedPostValuePath({
                path: [chapterIndex],
            });

            // Record User Action
            if (
                user
                && currentSessionId
            ) {
                recordUserAction({
                    type: USER_ACTION_TYPE.deletePostChapterSection,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId,
                        chapterId: chapters[chapterIndex].id,
                        sectionId: removed.id,
                    },
                });
            }
            return;
        }

        if (
            chapters[chapterIndex].sections![sectionIndex].value
            && chapters[chapterIndex].sections![sectionIndex].value.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length
        ) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_DELETE_SECTION_WITH_VALUE_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }

        const updatedChapters = [...chapters];
        const [removed] = updatedChapters[chapterIndex].sections!.splice(sectionIndex, 1);
        updatedChapters[chapterIndex].updated = [
            ...updatedChapters[chapterIndex].updated,
            Date.now(),
        ];
        updateChapters(updatedChapters);

        // Modify selected post value path
        // Section index is greater than 0, so we can switch to the previous section
        const updatedPath = [chapterIndex, index - 1];
        onChangeSelectedPostValuePath({
            path: updatedPath,
        });

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.deletePostChapterSection,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId: chapters[chapterIndex].id,
                    sectionId: removed.id,
                },
            });
        }
    };

    /**
     * Manages modifying selected post value path to this section
     */
    const selectSection = (): void => {
        if (postValueIsSaving) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }
        if (selectedPostValuePath[1] !== index) {
            onChangeSelectedPostValuePath({
                path: [chapterIndex, index],
            });
        }
    };

    // ===== Render =====

    return (
        <Container
            // Ensures that drag preview and drop target is entire chapter
            ref={(node) => drop(preview(node))}
            isDragging={isDragging}
            selected={
                selectedPostValuePath.length === 2
                && selectedPostValuePath[0] === chapterIndex
                && selectedPostValuePath[1] === index
            }
            transitionDuration={transitionDuration}
            {...(detectTouchDevice(document) ? {
                onTouchStart: selectSection,
            } : {
                onMouseDown: selectSection,
            })}
        >
            <OutlineNumberText>
                {`${chapterIndex + 1}.${index + 1}`}
            </OutlineNumberText>
            <OutlineChapterSectionInput
                type="text"
                id={`${EDITOR_SECTION_INPUT_ID_PREFIX}-${chapterIndex}-${index}`}
                className={`${HOVER_TARGET_CLASSNAME} ${EDITOR_SECTION_INPUT_CLASSNAME}`}
                defaultValue={section.title}
                placeholder="Section Title"
                onKeyDown={checkForEnter}
                onMouseEnter={onInputMouseEnter}
                onMouseLeave={onInputMouseLeave}
                onFocus={onInputFocus}
                onChange={(e) => onOutlineSectionInputChange(e)}
            />
            {numSections > 1 && (
                <OutlineSectionDragIndicator
                    ref={drag} // Isolates drag interaction to this element
                    className={HOVER_TARGET_CLASSNAME}
                    horizontalOffset={OUTLINE_DRAG_INDICATOR_X_OFFSET}
                    transitionDuration={OUTLINE_DRAG_INDICATOR_TRANSITION_DURATION}
                    onMouseEnter={onDragIndicatorMouseEnter}
                    onMouseLeave={onDragIndicatorMouseLeave}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: onDragIndicatorMouseDown,
                    } : {
                        onMouseDown: onDragIndicatorMouseDown,
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchMove: () => onDragIndicatorMouseMove(USER_ACTION_TYPE.grabOutlineSection),
                    } : {
                        onMouseMove: () => onDragIndicatorMouseMove(USER_ACTION_TYPE.grabOutlineSection),
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchEnd: onDragIndicatorMouseUp,
                    } : {
                        onMouseUp: onDragIndicatorMouseUp,
                    })}
                >
                    <ReactSVG
                        src={DragIndicatorIcon}
                    />
                </OutlineSectionDragIndicator>
            )}
            <SectionNonEmptyIndicator
                isVisible={!!section.value && section.value.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length}
                transitionDuration={CHAPTER_INDICATOR_TRANSITION_DURATION}
            >
                <Tooltip
                    text="Section has content"
                    side={TOOLTIP_TYPE.left}
                />
                <ReactSVG
                    src={PencilIcon}
                />
            </SectionNonEmptyIndicator>
            <SectionAnnotationsIndicator
                isVisible={section.annotations.length > 0}
                transitionDuration={CHAPTER_INDICATOR_TRANSITION_DURATION}
            >
                <Tooltip
                    text="Section has annotations"
                    side={TOOLTIP_TYPE.left}
                />
                <ReactSVG
                    src={HighlightIcon}
                />
            </SectionAnnotationsIndicator>
        </Container>
    );
}

export default ReaderOutlineSection;
