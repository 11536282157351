// ===== Packages =====

import React                                    from 'react';
import styled                                   from 'styled-components';

// ===== Constants =====

import FONT_TYPE                                from '../../../constants/fontType';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';

interface Props {
    children: React.ReactElement,
    attributes: any,
}
function BlockQuote({ children, attributes }: Props): JSX.Element {
    return (
        <Container {...attributes}>
            {children}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.blockquote`
    ${basicEditorFontStyles}
    font-size: 1.2em;
    color: ${({ theme }) => theme.color.purple100};
    font-style: italic;
    padding: 15px 0px;
    padding-left: 30px;
    border-left: ${({ theme }) => `5px solid ${theme.color.neutral900}`};

    ${({ theme }) => theme.mediaQuery.small} {
        margin-left: 0px;
        margin-right: 0px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1em;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 15px;
    }
`;

export default BlockQuote;
