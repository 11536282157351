// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    SPINNER_HEIGHT_SMALL,
    SPINNER_HEIGHT_LARGE,
}                                   from '../../constants/generalConstants';

interface ContainerProps {
    large?: boolean,
    lightBackground?: boolean,
}
export const Container = styled.svg<ContainerProps>`
    width: ${({ large }) => (large
        ? `${SPINNER_HEIGHT_LARGE}px`
        : `${SPINNER_HEIGHT_SMALL}px`
    )};
    height: ${({ large }) => (large
        ? `${SPINNER_HEIGHT_LARGE}px`
        : `${SPINNER_HEIGHT_SMALL}px`
    )};
    animation: rotate 2s linear infinite;

    & path {
        fill: ${({ theme }) => theme.color.neutral700};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        fill: ${({ lightBackground, theme }) => (lightBackground
        ? theme.color.neutral700
        : theme.color.white
    )};
    }
`;
