// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                       from 'react';
import {
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';

// ===== Hooks =====

import {
    useTimeout,
}                                       from '../../hooks';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
}                                       from '../../interfaces';

// ===== Images =====

import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    PAGE_SUBTITLE_TRANSITION_DURATION,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    AboutText,
    AboutTextContainer,
    ColorText,
}                                       from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function AboutView({
    user,
    currentSessionId,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== State =====

    const [showText, setShowText] = useState<boolean>(false);
    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);

    // ===== Animation Constants =====

    const ABOUT_TEXT_DURATION = 400;
    const HEADER_DELAY_DURATION = PAGE_SUBTITLE_TRANSITION_DURATION - 150;

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.about,
                },
            });
        }
        onCursorLeave(e);
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    /**
     * Records user action of viewing about page
     */
    const recordAboutPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewAboutPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'About',
        );
    }, []);

    /**
     * Records View About Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordAboutPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    // Show text
    useEffect(() => {
        clearTimeoutShowText();
        timeoutShowText();
    }, []);

    const {
        start: timeoutShowText,
        clear: clearTimeoutShowText,
    } = useTimeout(() => {
        setShowText(true);
    }, HEADER_DELAY_DURATION);

    return (
        <Container>
            <PageLogo
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
            >
                ABOUT
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={theme.verascopeColor.orange200}
            >
                What is verascope?
            </PageSubtitle>
            <AboutTextContainer>
                <AboutText
                    isVisible={showText}
                    delay={0}
                    enterDuration={ABOUT_TEXT_DURATION}
                >
                    verascope is a company exploring the use of
                    <ColorText color={theme.verascopeColor.orange300}> video games </ColorText>
                    to improve how
                    <ColorText color={theme.verascopeColor.orange300}> humans learn </ColorText>
                    and how STEM subjects are taught.
                </AboutText>
                <AboutText
                    marginTop
                    isVisible={showText}
                    delay={ABOUT_TEXT_DURATION / 2}
                    enterDuration={ABOUT_TEXT_DURATION}
                >
                    We create
                    <ColorText color={theme.verascopeColor.orange300}> gamemaking software </ColorText>
                    for advanced-level STEM educators that enables them to
                    <ColorText color={theme.verascopeColor.orange300}> adapt complex, abstract subjects </ColorText>
                    (like linear algebra or quantum physics) into the form of fun, accessible video games that can carry out
                    <ColorText color={theme.verascopeColor.orange300}> teaching through engaging gameplay </ColorText>
                    as opposed to traditional lecture-based or textbook-based approaches.
                </AboutText>
                <AboutText
                    marginTop
                    isVisible={showText}
                    delay={ABOUT_TEXT_DURATION}
                    enterDuration={ABOUT_TEXT_DURATION}
                >
                    Our mission is to
                    <ColorText color={theme.verascopeColor.orange300}> cultivate individuals </ColorText>
                    with
                    <ColorText color={theme.verascopeColor.orange300}> high agency</ColorText>
                    , capable of effectively navigating a complex world.
                </AboutText>
            </AboutTextContainer>
        </Container>
    );
}

export default AboutView;
