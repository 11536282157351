// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                           from 'react';

// ===== Services =====

import {
    recordUserAction,
}                                           from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
}                                           from '../../interfaces';

// ===== Enums =====

import {
    USER_ACTION_TYPE,
}                                           from '../../enums';

// ===== Constants =====

import {
    DEFAULT_CSS_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
}                                           from '../../constants/generalConstants';

// ===== Images =====

import Exclamation                          from '../../images/exclamation.svg';

// ===== Styles =====

import {
    Container,
    ErrorTitle,
    ErrorMessage,
    ExclamationIcon,
    ImageContainer,
    ResetButtonContainer,
    ResetButton,
    VerascopeQuadrilateral,
}                                           from './styles';

interface Props {
    error: any,
    user: IUserItem | null,
    currentSessionId: string | null,
    resetErrorBoundary: () => void,
    onMouseEnter: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    onMouseLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function ErrorFallback({
    error,
    user,
    currentSessionId,
    resetErrorBoundary,
    onMouseEnter,
    onMouseLeave,
}: Props): JSX.Element {
    // ===== State =====

    const [recordedAction, setRecordedAction] = useState<boolean>(false);

    // ===== Methods =====

    const onResetButtonClick = async (): Promise<void> => {
        resetErrorBoundary();
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.errorResetPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    // ===== Side Effects =====

    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedAction
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.error,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    error,
                },
            });
            setRecordedAction(true);
        }
    }, [
        user,
        currentSessionId,
        recordedAction,
    ]);

    return (
        <Container>
            <ImageContainer>
                <VerascopeQuadrilateral
                    viewBox="0 0 88.7 60.4"
                >
                    <path d="M84.7,16.7L44.8,1.4c-5.1-2-13.3-1.8-18.3,0.2l-21,8.8C1,12.1-1.2,17.1,0.6,21.6C0.7,22,1,22.4,1.2,22.8l18.3,32.8c2.5,4.5,8.1,6.1,12.6,3.7c0.2-0.1,0.4-0.2,0.6-0.3l52.8-33.4C90.1,22.7,89.7,18.7,84.7,16.7z" />
                </VerascopeQuadrilateral>
                <ExclamationIcon
                    src={Exclamation}
                />
            </ImageContainer>
            <ErrorTitle>
                Oops, something went wrong.
            </ErrorTitle>
            <ErrorMessage>
                {error?.message || ''}
            </ErrorMessage>
            <ResetButtonContainer>
                <ResetButton
                    className={HOVER_TARGET_CLASSNAME}
                    transitionDuration={DEFAULT_CSS_TRANSITION_DURATION}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onResetButtonClick}
                >
                    Reset
                </ResetButton>
            </ResetButtonContainer>
        </Container>
    );
}

export default ErrorFallback;
