// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                       from 'react';
import {
    doc,
    getDoc,
    getFirestore,
}                                       from 'firebase/firestore';
import {
    useParams,
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';

// ===== Components =====

import Spinner                          from '../Spinner';

// ===== Hooks =====

import {
    useTimeout,
}                                       from '../../hooks';

// ===== Services =====

import {
    updateUserInDB,
    recordUserAction,
}                                       from '../../services';

// ===== Enums =====

import {
    PAGE_ROUTE,
    USER_ACTION_TYPE,
    FIRESTORE_COLLECTION,
    UNSUBSCRIBE_STATUS_TYPE,
}                                       from '../../enums';

// ===== Interfaces =====

import {
    IUserItem,
}                                       from '../../interfaces';

// ===== Styles =====

import {
    Container,
}                                       from './styles';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    setUnsubscribeId: React.Dispatch<React.SetStateAction<string | null>>,
    setUnsubscribeStatus: React.Dispatch<React.SetStateAction<UNSUBSCRIBE_STATUS_TYPE>>,
}
function UnsubscribeView({
    user,
    currentSessionId,
    setUnsubscribeId,
    setUnsubscribeStatus,
}: Props): JSX.Element {
    // ==== General Constants =====

    const AWAIT_USER_ID_TIMEOUT_DURATION = 1000;

    // ===== React Router =====

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    // ===== State =====

    // Stores user item data
    const [awaitingUserId, setAwaitingUserId] = useState<boolean>(false);

    // ===== Side Effects =====

    /**
     * Record user entering page
     */
    useEffect(() => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewUnsubscribeResultPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Unsubscribe from Mailing List
     */
    useEffect(() => {
        if (params.userId) {
            setUnsubscribeId(params.userId);
            clearAwaitUserId();
            unsubscribeUser();
        } else if (!awaitingUserId) {
            // Initiate timeout that routes to UnsubscribeResultView
            setAwaitingUserId(true);
            clearAwaitUserId();
            timeoutAwaitUserId();
        }
    }, [params]);

    /**
     * Manages delay of routing to UnsubscribeResultView
     * after waiting for User ID
     */
    const {
        start: timeoutAwaitUserId,
        clear: clearAwaitUserId,
    } = useTimeout(() => {
        // User not received during expected timeframe
        // Unsubscribe Unsuccessful
        setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.timeout);
        navigate(
            `/${PAGE_ROUTE.unsubscribeResult}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    }, AWAIT_USER_ID_TIMEOUT_DURATION);

    // ===== Methods ======

    const unsubscribeUser = async (): Promise<void> => {
        if (!params.userId) throw Error('Expected User ID while attempting to unsubscribe user, but ID was not found.');
        const db = getFirestore();
        const usersCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.users
            : FIRESTORE_COLLECTION.stagingUsers;
        const userRef = doc(db, usersCollection, params.userId);

        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            const userItem = userSnap.data() as IUserItem;
            // Change user mailingListSubscription value
            await updateUserInDB({
                userId: params.userId,
                mailingListSubscription: {
                    history: [
                        // We make assumption that it exists if they were able to reach
                        // unsubscribe page
                        ...userItem.mailingListSubscription!.history,
                        {
                            subscribed: false,
                            timestamp: Date.now(),
                        },
                    ],
                },
            });

            // verify that value has changed
            const userVerifySnap = await getDoc(userRef);
            if (userVerifySnap.exists()) {
                const userVerifyItem = userSnap.data() as IUserItem;
                if (userVerifyItem.mailingListSubscription!.history[userVerifyItem.mailingListSubscription!.history.length - 1].subscribed) {
                    // User still subscribed to mailing list
                    // Unsubscribe Unsuccessful
                    setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.stillSubscribed);
                } else if (!('mailingListSubscription' in userItem)) {
                    // User Item doesn't have a mailingListSubscription property when it should
                    // Unsubscribe Unsuccsessful
                    setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.missingProperty);
                } else if (
                    'mailingListSubscription' in userItem
                    && !userItem.mailingListSubscription!.history[userItem.mailingListSubscription!.history.length - 1].subscribed
                ) {
                    // User unsubscribed from mailing list
                    // Unsubscribe Successful
                    setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.success);
                } else {
                    // Uncaught Error
                    // Unsubscribe Unsuccessful
                    setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.uncaughtError);
                }
            } else {
                // User not found
                // Unsubscribe Unsucessful
                setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.userNotFound);
            }

            navigate(
                `/${PAGE_ROUTE.unsubscribeResult}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        } else {
            // User not found
            // Unsubscribe Unsucessful
            setUnsubscribeStatus(UNSUBSCRIBE_STATUS_TYPE.userNotFound);

            navigate(
                `/${PAGE_ROUTE.unsubscribeResult}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        }
    };

    return (
        <Container>
            <Spinner large lightBackground />
        </Container>
    );
}

export default UnsubscribeView;
