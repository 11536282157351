// ** IMPORTANT **: Keep in sync with functions/src/index.ts
const roundToNDecimals = (number: number, decimalPlaces: number): number => {
    // Check if the number is a float (has decimal places)
    if (Number.isInteger(number)) {
        return number; // Return the integer as is
    }

    // If it's a float, round to N decimal places
    return parseFloat(number.toFixed(decimalPlaces));
};

export default roundToNDecimals;
