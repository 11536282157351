// ===== Packages =====

import React, {
    useState,
}                       from 'react';

// ===== Hooks =====

import {
    useTimeout,
}                       from '../../hooks';

// ===== Enums =====

import {
    TOOLTIP_TYPE,
}                       from '../../enums';

// ===== Styles =====

import {
    TooltipParent,
    Caret,
    Text,
    BottomTooltip,
    TitleTooltip,
    TopTooltip,
    LeftTooltip,
    RightTooltip,
    RightBottomTooltip,
    LeftBottomTooltip,
}                       from './styles';

Tooltip.defaultProps = {
    large: false,
    tooltipStyle: undefined,
    permanent: false,
    background: undefined,
};
interface Props {
    side: TOOLTIP_TYPE,
    text: string,
    permanent?: boolean,
    large?: boolean,
    background?: string,
    tooltipStyle?: any | undefined,
}
function Tooltip({
    side,
    text,
    permanent,
    large = false,
    background,
    tooltipStyle = undefined,
}: Props): JSX.Element {
    // ===== General Constants =====

    const SET_VISIBLE_DELAY_DURATION = 300;

    // ===== State =====

    const [visible, setVisible] = useState<boolean>(false);

    // ===== Side Effects ======

    const {
        start: timeoutSetVisible,
        clear: clearTimeoutSetVisible,
    } = useTimeout(() => {
        setVisible(true);
    }, SET_VISIBLE_DELAY_DURATION);

    // ===== Methods ======

    const handleVisibility = (visibility: boolean): void => {
        clearTimeoutSetVisible();
        if (visibility) {
            timeoutSetVisible();
        } else {
            setVisible(false);
        }
    };

    let TooltipContainer;

    switch (side) {
    case TOOLTIP_TYPE.bottom:
        TooltipContainer = BottomTooltip;
        break;
    case TOOLTIP_TYPE.top:
        TooltipContainer = TopTooltip;
        break;
    case TOOLTIP_TYPE.left:
        TooltipContainer = LeftTooltip;
        break;
    case TOOLTIP_TYPE.rightBottom:
        TooltipContainer = RightBottomTooltip;
        break;
    case TOOLTIP_TYPE.leftBottom:
        TooltipContainer = LeftBottomTooltip;
        break;
    case TOOLTIP_TYPE.right:
        TooltipContainer = RightTooltip;
        break;
    case TOOLTIP_TYPE.title:
        TooltipContainer = TitleTooltip;
        break;
    default:
        TooltipContainer = BottomTooltip;
    }

    return (
        <TooltipParent
            onMouseEnter={() => {
                if (!permanent) handleVisibility(true);
            }}
            onMouseLeave={() => {
                if (!permanent) handleVisibility(false);
            }}
            onClick={() => handleVisibility(false)}
        >
            <TooltipContainer
                active={visible || permanent || false}
                large={large}
                background={background}
                style={tooltipStyle}
            >
                <Caret background={background} />
                <Text>{text}</Text>
            </TooltipContainer>
        </TooltipParent>
    );
}

export default Tooltip;
