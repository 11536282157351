// ===== Packages =====

import React                    from 'react';
import styled                   from 'styled-components';

// ===== Services =====

import {
    toUTF16,
    getIconObject,
}                               from '../../../services';

// ===== Constants =====

import { EMOJI_REGEX }          from '../../../constants/generalConstants';

interface Props {
    char: string,
}
function Emoji({
    char = '\uD83D\uDE00',
}: Props): JSX.Element {
    // ===== Methods =====

    /**
     * Converts emoji into surrogate pair hexadecimal representation to be
     * rendered appropriately
     * @param  {[string] || [number]} emoji emoji to be prepared
     * @return {[string]}       surrogate pair hexadecimal presentation of emoji
     */
    const prepareEmoji = (emoji: string): string => {
        let preparedEmoji;

        if (typeof emoji === 'string' && EMOJI_REGEX.test(emoji)) {
            // Already in surrogate pair hexadecimal representation
            preparedEmoji = emoji;
        } else if (typeof emoji === 'number') {
            // hexadecimal, but not surrogate pair representation
            preparedEmoji = toUTF16(emoji);
        } else if (typeof emoji === 'string' && emoji.length === 2) {
            // emoji representation
            const codePoint = emoji.codePointAt(0);
            if (codePoint) preparedEmoji = toUTF16(codePoint);
        } else if (
            typeof emoji === 'string'
            && EMOJI_REGEX.test(String.fromCodePoint(parseInt(emoji.replace('U+', ''), 16)))) {
            // U+XXXX Emoji Representation
            preparedEmoji = String.fromCodePoint(parseInt(emoji.replace('U+', ''), 16));
        }

        if (preparedEmoji && EMOJI_REGEX.test(preparedEmoji)) {
            return preparedEmoji.toString();
        }

        return toUTF16(0x1F600);
    };

    const emoji = getIconObject(char);
    return (
        <Container
            role="img"
            aria-label={emoji.name || ''}
            aria-hidden={!emoji.name}
        >
            {prepareEmoji(emoji.char)}
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.span``;

export default Emoji;
