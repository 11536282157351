// ===== Interfaces =====

import {
    IIPItem,
}               from '../interfaces';

// Reference: https://poopcode.com/how-to-get-clients-ip-address-using-javascript/
const fetchIP = async (): Promise<IIPItem> => {
    const url = 'https://ipinfo.io/json?token=655e1f4313d1f5';
    return fetch(url)
        .then((response) => response.json())
        .then((data) => data);
};

export default fetchIP;
