// ** IMPORTANT **: Keep in sync with functions/src/storageEntity

export enum STORAGE_ENTITY {
    posts = 'posts',
    stagingPosts = 'staging-posts',
    annotations = 'annotations',
    stagingAnnotations = 'staging-annotations',
    users = 'users',
    stagingUsers = 'staging-users',
    treasureChest = 'treasure-chest',
    mailAttachments = 'mail-attachments',
    stagingMailAttachments = 'staging-mail-attachments',
}
