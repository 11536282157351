// ===== Packages =====

import styled, {
    css,
    keyframes,
}                   from 'styled-components';

// ===== Components =====

import {
    IconButton,
}                   from '../../styles';

// ===== Interfaces =====

import { ICoord }   from '../../interfaces';

// ===== Constants =====

import FONT_TYPE    from '../../constants/fontType';

// ===== CSS Keyframes =====

export const typing = keyframes`
{
    to { width: 0 }
}`;

export const caret = keyframes`
{
    50% { color: transparent }
}
`;

export const pulseWhite = keyframes`
{
    0% {
        transform: scale(1);
    }
    
    70% {
        transform: scale(1.2);
    }
    
    100% {
        transform: scale(1);
    }
}
`;

// ===== Styled Components =====

interface VerascopeQuadrilateralProps {
    width: number,
    height: number
}
export const VerascopeQuadrilateral = styled.svg<VerascopeQuadrilateralProps>`
    position: absolute;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    opacity: 0;
    visibility: hidden;
`;

export const Quadrilateral = styled.polygon``;

interface JellyContainerProps {
    width: number,
    height: number,
    expanded: boolean,
    isTouchDevice: boolean,
    initPosY: number,
    initPosX: number,
    offset: ICoord,
    revealedExpandedTop: number,
    offsetExpandDuration: number,
    offsetContractDuration: number,
}
export const JellyContainer = styled.div<JellyContainerProps>`
    position: ${({ expanded, isTouchDevice }) => `${expanded && isTouchDevice
        ? 'absolute'
        : 'relative'}`};
    // Gets overridden by setting top property directly in LandingView
    top: ${({
        expanded,
        isTouchDevice,
        revealedExpandedTop,
        initPosY,
    }) => {
        const expandedTop = `${isTouchDevice ? revealedExpandedTop : 0}px`;
        return `${expanded ? expandedTop : `${initPosY}px`}`;
    }};
    // Gets overridden by setting left property directly in LandingView
    left: ${({
        expanded,
        isTouchDevice,
        initPosX,
        width,
    }) => {
        const expandedLeft: string = isTouchDevice ? `calc((100vw - ${width}px) / 2) !important` : '0px';
        return `${expanded ? expandedLeft : `${initPosX}px`}`;
    }};
    display: inline-block;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    transform: ${({ offset }) => `translate(${offset.x}px, ${offset.y}px)`};
    transition: ${({
        expanded,
        offsetExpandDuration,
        offsetContractDuration,
        theme,
    }) => `
        top ${offsetExpandDuration}ms ${theme.motion.eagerEasing},
        left ${offsetExpandDuration}ms ${theme.motion.eagerEasing},
        transform ${expanded
        ? `${offsetExpandDuration}ms`
        : `${offsetContractDuration}ms`
} ${expanded
    ? theme.motion.eagerEasing
    : theme.motion.delayEasing
}`};
`;

interface JellyCanvasProps {
    expanded: boolean,
    isTouchDevice: boolean,
    width: number,
    height: number
}
export const JellyCanvas = styled.canvas<JellyCanvasProps>`
    ${({ expanded, isTouchDevice }) => (expanded && isTouchDevice ? 'display: none;' : '')};
    position: relative;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
`;

interface CentroidContainerProps {
    expanded: boolean,
    isTouchDevice: boolean
}
/* It's important to position the `.centroid-container` in the top-left corner
     This way the `.centroid-text` will be positioned in the center (with JavaScript) */
export const CentroidContainer = styled.div<CentroidContainerProps>`
    position: absolute;
    left: 0;
    top: 0;
    transform: ${({ expanded, isTouchDevice }) => (expanded && isTouchDevice ? '' : 'translate(-50%, -50%)')};
    pointer-events: ${({ expanded }) => (expanded ? 'auto' : 'none')};
`;

interface CentroidBackgroundProps {
    expanded: boolean,
    isMobile: boolean,
    canvasWidth: number,
    canvasHeight: number,
    transitionDuration: number,
    contractionDelayDuration: number;
}
export const CentroidBackground = styled.div<CentroidBackgroundProps>`
    position: ${({ isMobile }) => (isMobile ? 'fixed' : 'absolute')};
    top: ${({ expanded, canvasHeight }) => (expanded ? '50%' : `${canvasHeight / 2}px`)};
    left: ${({ expanded, canvasWidth }) => (expanded ? '50%' : `${canvasWidth / 2}px`)};
    width: ${({ expanded, isMobile }) => {
        const mobileWidth = isMobile ? '200vw' : '250vw';
        return expanded ? mobileWidth : '0px';
    }};
    height: ${({ expanded, isMobile }) => {
        const mobileHeight: string = isMobile ? '200vh' : '250vh';
        return expanded ? mobileHeight : '0px';
    }};
    border-top-left-radius: ${({ expanded, isMobile }) => (expanded && !isMobile ? '50px' : '0px')};
    border-top-right-radius: ${({ expanded, isMobile }) => (expanded && !isMobile ? '50px' : '0px')};
    border-bottom-left-radius: ${({ expanded, isMobile }) => (expanded && !isMobile ? '50px' : '0px')};
    border-bottom-right-radius: ${({ expanded, isMobile }) => (expanded && !isMobile ? '50px' : '0px')};
    background: ${({ theme }) => theme.verascopeColor.purple300};
    transform: ${({ expanded }) => `translate(${expanded ? '-50%, -50%' : '0px, 0px'})`};
    transition: ${({
        expanded,
        transitionDuration,
        contractionDelayDuration,
        theme,
    }) => `width ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        height ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        top ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        transform ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        border-top-left-radius ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        border-top-right-radius ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        border-bottom-left-radius ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing},
        border-bottom-right-radius ${transitionDuration}ms ${expanded ? '0ms' : `${contractionDelayDuration}ms`} ${theme.motion.delayEasing}`};
`;

interface LogomarkGroupProps {
    expanded: boolean,
    isTouchDevice: boolean
}
export const LogomarkGroup = styled.div<LogomarkGroupProps>`
    ${({ expanded, isTouchDevice }) => (expanded && isTouchDevice ? 'transform: none !important;' : '')};
`;

interface VerascopeShapeGroupProps {
    expanded: boolean,
    isTouchDevice: boolean,
    topOffset: number,
    leftOffset: number,
    adjWidth: number,
    adjHeight: number
}
export const VerascopeShapeGroup = styled.svg<VerascopeShapeGroupProps>`
    position: relative;
    top: ${({ topOffset, expanded, isTouchDevice }) => (expanded && isTouchDevice
        ? '0px'
        : `${topOffset}px`)
};
    left: ${({ leftOffset, expanded, isTouchDevice }) => (expanded && isTouchDevice
        ? '0px'
        : `${leftOffset}px`)
};
    width: ${({ adjWidth }) => adjWidth}px;
    height: ${({ adjHeight }) => adjHeight}px;
    overflow: visible;

    ${({ theme }) => theme.mediaQuery.small} {
        top: ${({ topOffset, expanded }) => (expanded
        ? '0px'
        : `${topOffset}px`)
};
        left: ${({ leftOffset, expanded }) => (expanded
        ? '0px'
        : `${leftOffset}px`)
};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${({ topOffset, expanded }) => (expanded
        ? '0px'
        : `${topOffset}px`)
};
        left: ${({ leftOffset, expanded }) => (expanded
        ? '0px'
        : `${leftOffset}px`)
};
    }
`;

interface VerascopeShapeProps {
    fillDuration: number
}
export const VerascopeTriangle = styled.polygon<VerascopeShapeProps>`
    fill: ${({ theme }) => theme.verascopeColor.yellow200};
    transition: ${({ fillDuration, theme }) => `fill ${fillDuration}ms ${theme.motion.delayEasing}`};
`;

interface VerascopeCircleProps {
    expanded: boolean,
    isMobile: boolean,
    isSafariBrowser: boolean,
    fillDuration: number,
    translateDuration: number,
    translateExpandDelayDuration: number,
    translateContractDelayDuration: number
}
export const VerascopeCircle = styled.circle<VerascopeCircleProps>`
    fill: ${({ theme }) => theme.verascopeColor.orange200};
    transform: ${({ expanded, isSafariBrowser, isMobile }) => `translateX(${expanded ? `${!isMobile && isSafariBrowser ? 286 : 190}%` : '0px'})`};
    transition: ${({
        expanded,
        fillDuration,
        translateDuration,
        translateExpandDelayDuration,
        translateContractDelayDuration,
        theme,
    }) => `
        fill ${fillDuration}ms ${theme.motion.delayEasing},
        transform ${translateDuration}ms ${expanded
    ? `${translateExpandDelayDuration}ms`
    : `${translateContractDelayDuration}ms`
} ${theme.motion.overshoot}`};
`;

interface VerascopeLetterGroupProps {
    expanded: boolean,
    isTouchDevice: boolean,
    top: number,
    shapeTopOffset: number,
    left: number,
    shapeLeftOffset: number
}
export const VerascopeLetterGroup = styled.svg<VerascopeLetterGroupProps>`
    position: absolute;
    top: ${({
        top, shapeTopOffset, expanded, isTouchDevice,
    }) => (expanded && isTouchDevice
        ? `${top - shapeTopOffset}px`
        : `${top}px`)
};
        left: ${({
        left, shapeLeftOffset, expanded, isTouchDevice,
    }) => (expanded && isTouchDevice
        ? `${left - shapeLeftOffset}px`
        : `${left}px`)
};
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    overflow: visible;

    ${({ theme }) => theme.mediaQuery.small} {
        top: ${({ top, shapeTopOffset }) => `${top - shapeTopOffset}px`};
        left: ${({ left, shapeLeftOffset }) => `${left - shapeLeftOffset}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${({ top, shapeTopOffset }) => `${top - shapeTopOffset}px`};
        left: ${({ left, shapeLeftOffset }) => `${left - shapeLeftOffset}px`};
    }
`;

interface VerascopeLetterProps {
    expanded: boolean,
    letterOffset: number,
    delay: number,
    translateDuration: number,
    opacityDuration: number,
    reverseDelayDuration: number
}
const VerascopeLetter = styled.path<VerascopeLetterProps>`
    fill: ${({ theme }) => theme.verascopeColor.white};
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transform: ${({ expanded, letterOffset }) => (expanded ? 'translateX(0px)' : `translateX(${letterOffset}px)`)};
    transition: ${({
        opacityDuration,
        expanded,
        delay,
        reverseDelayDuration,
        theme,
        translateDuration,
    }) => `
        opacity ${opacityDuration}ms ${expanded
    ? `${delay}ms`
    : `${reverseDelayDuration - delay}ms`
} ${theme.motion.delayEasing},
        transform ${translateDuration}ms ${expanded
    ? `${delay}ms`
    : `${reverseDelayDuration - delay}ms`
} ${theme.motion.overshoot}`};
`;

export const VerascopeLetterE1 = styled(VerascopeLetter)``;

export const VerascopeLetterR = styled(VerascopeLetter)``;

export const VerascopeLetterA = styled(VerascopeLetter)``;

export const VerascopeLetterS = styled(VerascopeLetter)``;

export const VerascopeLetterC = styled(VerascopeLetter)``;

export const VerascopeLetterP = styled(VerascopeLetter)``;

export const VerascopeLetterE2 = styled(VerascopeLetter)``;

interface VerascopeSloganProps {
    isVisible: boolean,
    logomarkHeight: number,
    sloganMargin: number,
    leftOffset: number,
    sloganFontMultiplier: number,
    translateDuration: number,
    translateDelay: number,
    runTypingAnimation: boolean,
    typingDuration: number,
    sloganLength: number,
    blinkingDuration: number
}
export const VerascopeSlogan = styled.h2<VerascopeSloganProps>`
    position: absolute;
    top: ${({ logomarkHeight }) => `${logomarkHeight}px`};
    left: ${({ leftOffset }) => `${leftOffset}px`};
    margin: ${({ sloganMargin }) => `${sloganMargin}px 0`};
    padding: 0;
    transform: ${({ isVisible }) => {
        const contentTransformation = isVisible ? 'translate(-50%, 0px)' : 'translate(-50%, -20px)';
        return contentTransformation;
    }};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: ${({ sloganFontMultiplier }) => `${sloganFontMultiplier}em`};
    color: ${({ theme }) => theme.verascopeColor.white};
    white-space: nowrap;
    user-select: none;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({
        isVisible,
        translateDuration,
        translateDelay,
        theme,
    }) => `
        opacity ${isVisible ? translateDuration : 0}ms ${isVisible ? `${translateDelay}ms` : ''} ${theme.motion.bounceEasing},
        transform ${isVisible ? translateDuration : 0}ms ${isVisible ? `${translateDelay}ms` : ''} ${theme.motion.bounceEasing}`};

    ${({
        runTypingAnimation,
        theme,
        typingDuration,
        sloganLength,
        blinkingDuration,
    }) => (runTypingAnimation
        ? css`&::after {
                content: "|";
                position: absolute;
                right: 0;
                width: 100%;
                color: ${theme.verascopeColor.white};
                background: ${theme.verascopeColor.purple300};
                z-index: 0;
                animation: ${css`${typing} ${typingDuration}ms steps(${sloganLength}) forwards, ${caret} ${blinkingDuration}ms infinite`};
            }`
        : '')
}
`;

interface MenuContainerProps {
    leftOffset: number,
    logomarkHeight: number,
    sloganFontMultiplier: number,
    sloganMargin: number,
    margin: number
}
export const MenuContainer = styled.div<MenuContainerProps>`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: ${({
        logomarkHeight,
        sloganFontMultiplier,
        sloganMargin,
    }) => `calc(${logomarkHeight}px + ${sloganFontMultiplier}em + 2 * ${sloganMargin}px)`};
    left: ${({ leftOffset }) => `${leftOffset}px`};
    transform: translateX(-50%);
    width: 300px;
    margin: ${({ margin }) => `${margin}px 0px`};
`;

interface InputWrapperProps {
    width: number,
    height: number,
    isVisible: boolean,
    withTransition: boolean,
    margin: number,
    withDelay: boolean,
    offsetDelay: number,
    translateDuration: number,
    translateDelay: number
}
export const InputWrapper = styled.div<InputWrapperProps>`
    position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute')};
    transform: ${({ isVisible, withTransition }) => (!isVisible && withTransition ? 'translateY(20px)' : 'translateY(0px)')};
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    margin: ${({ margin }) => `${margin}px`};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({
        isVisible,
        withDelay,
        withTransition,
        offsetDelay,
        translateDuration,
        translateDelay,
        theme,
    }) => (withTransition
        ? `
            opacity ${isVisible ? translateDuration : 0}ms ${isVisible && withDelay
            ? `${translateDelay + offsetDelay}ms`
            : ''} ${theme.motion.bounceEasing},
            transform ${isVisible ? translateDuration : 0}ms ${isVisible && withDelay
            ? `${translateDelay + offsetDelay}ms`
            : ''} ${theme.motion.bounceEasing}`
        : 'all 0s ease 0s')
};
`;

interface InputProps {
    isInvalid: boolean
}
export const Input = styled.input<InputProps>`
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.white
    )};
    font-size: 1em;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 400;
    outline: none;
    border: ${({ theme, isInvalid }) => `4px solid ${isInvalid ? theme.verascopeColor.red200 : theme.verascopeColor.white}`}; 
    padding: 0px 10px;
    text-align: center;
    cursor: none;

    &:-ms-input-placeholder {
        color: ${({ theme }) => `${theme.color.white}`};
    }

    &::-webkit-input-placeholder {
        color: ${({ theme }) => `${theme.color.white}`};
    }

    &::placeholder {
        color: ${({ theme }) => `${theme.color.white}`};
    }

    &::-ms-input-placeholder {
        color: ${({ theme }) => `${theme.color.white}`};
    }

    &:focus {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.yellow200
    )};
        border: ${({ theme, isInvalid }) => `4px solid ${isInvalid ? theme.verascopeColor.red200 : theme.verascopeColor.yellow200}`};

        &:-ms-input-placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.yellow200}`};
        }

        &::-webkit-input-placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.yellow200}`};
        }

        &::placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.yellow200}`};
        }

        &::-ms-input-placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.yellow200}`};
        }
    }
`;

export const RightArrow = styled.svg`
    position: absolute;
    display: none;
    height: ${({ height }) => `${height}px`};

    & > polygon {
            fill: ${({ theme }) => theme.verascopeColor.white};
    }
`;

export const LeftArrow = styled.svg`
    position: absolute;
    display: none;
    height: ${({ height }) => `${height}px`};
    transform: scaleX(-1);

    & > polygon {
            fill: ${({ theme }) => theme.verascopeColor.white};
    }
`;

export const InteractableObjectContainer = styled.div``;

interface BasicInteractableObjectProps {
    width: number,
    top: number,
    left: number,
    rotate: number,
    delay: number,
    revealDuration: number,
    grabDuration: number,
    isVisible: boolean,
    isGrabbed: boolean,
    grabScale: number,
    cursorZIndex: number,
    dollyTowardObject?: boolean,
    dollyDuration?: number,
    dollyScale?: number,
}
export const basicInteractableObjectStyles = css<BasicInteractableObjectProps>`
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    width: ${({ isVisible, width }) => (isVisible ? `${width}px` : '0px')};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: ${({
        isGrabbed,
        grabScale,
        rotate,
        dollyTowardObject,
        dollyScale,
    }) => {
        if (dollyScale && dollyTowardObject) {
            return `scale(${dollyScale}) rotate(${rotate}deg)`;
        } if (isGrabbed) {
            return `scale(${grabScale}) rotate(${rotate}deg)`;
        }

        return `scale(1) rotate(${rotate}deg)`;
    }};
    z-index: ${({ isGrabbed, cursorZIndex }) => (isGrabbed ? cursorZIndex - 1 : 'auto')};
    overflow: visible;
    transition: ${({
        isVisible,
        delay,
        revealDuration,
        grabDuration,
        dollyTowardObject,
        dollyDuration,
        theme,
    }) => `
        opacity ${isVisible ? revealDuration : 0}ms ${isVisible ? `${delay}ms` : ''} ${theme.motion.bounceEasing},
        width ${isVisible ? revealDuration : 0}ms ${isVisible ? `${delay}ms` : ''} ${theme.motion.bounceEasing},
        height ${isVisible ? revealDuration : 0}ms ${isVisible ? `${delay}ms` : ''} ${theme.motion.bounceEasing},
        transform ${dollyTowardObject
        ? dollyDuration
        : grabDuration
}ms ${dollyTowardObject
    ? theme.motion.standardEasing
    : theme.motion.eagerEasing
}
    `};
`;

interface InteractableObjectProps {
    width: number,
    top: number,
    left: number,
    rotate: number,
    delay: number,
    revealDuration: number,
    grabDuration: number,
    isVisible: boolean,
    isGrabbed: boolean,
    grabScale: number,
    cursorZIndex: number,
    dollyTowardObject?: boolean,
    dollyDuration?: number,
    dollyScale?: number,
}
export const InteractableObject = styled.svg<InteractableObjectProps>`
    ${basicInteractableObjectStyles}
`;

interface InteractableObjectShadowProps {
    dataName: string,
    isGrabbed: boolean,
    shadowScale: number,
    grabDuration: number
}
export const InteractableObjectShadow = styled.path<InteractableObjectShadowProps>`
    transform: ${({ isGrabbed, shadowScale }) => (isGrabbed ? `scale(${shadowScale})` : 'scale(1)')};
    transition: ${({ grabDuration, theme }) => `transform ${grabDuration}ms ${theme.motion.eagerEasing}`};
`;

export const InteractableObjectShadowGroup = styled.g<InteractableObjectShadowProps>`
    transform: ${({ isGrabbed, shadowScale }) => (isGrabbed ? `scale(${shadowScale})` : 'scale(1)')};
    transition: ${({ grabDuration, theme }) => `transform ${grabDuration}ms ${theme.motion.eagerEasing}`};
`;

interface CopyrightClauseProps {
    isVisible: boolean,
    isTouchDevice: boolean,
    copyrightFontMultiplier: number,
    translateDuration: number,
    translateDelay: number
}
export const CopyrightClause = styled.div<CopyrightClauseProps>`
    position: absolute;
    bottom: 20px;
    right: ${({ isTouchDevice }) => (isTouchDevice
        ? 'auto'
        : '20px')
};
    left: ${({ isTouchDevice }) => (isTouchDevice
        ? '50%'
        : 'auto')
};
    transform: ${({ isTouchDevice }) => (isTouchDevice
        ? 'translateX(-50%)'
        : 'none')
};
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${({ copyrightFontMultiplier }) => `${copyrightFontMultiplier}em`};
    color: ${({ theme }) => theme.verascopeColor.white};
    white-space: nowrap;
    user-select: none;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({
        isVisible,
        translateDuration,
        translateDelay,
        theme,
    }) => `opacity ${translateDuration}ms ${isVisible ? `${translateDelay}ms` : ''} ${theme.motion.standardEasing}`};

    ${({ theme }) => theme.mediaQuery.small} {
        right: auto;
        left: 50%;
        font-size: ${({ copyrightFontMultiplier }) => `${0.8 * copyrightFontMultiplier}em`};
        transform: translateX(-50%);
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: auto;
        left: 50%;
        font-size: ${({ copyrightFontMultiplier }) => `${0.8 * copyrightFontMultiplier}em`};
        transform: translateX(-50%);
    }
`;

export const Envelope = styled(IconButton)``;

export const Anchor = styled.a`
        cursor: none;
`;

export const Twitter = styled(IconButton)`
    height: ${({ height }) => `${height}px`};
    margin-left: 10px;
    overflow: visible;

    & > path {
        fill: ${({ theme }) => theme.verascopeColor.white};
        transform: scale(1);
        transform-origin: 50% 50% 0;
        transition: ${({
        expandDuration,
        fillDuration,
        theme,
    }) => `transform ${expandDuration}ms ${theme.motion.bounceEasing}, fill ${fillDuration}ms ${theme.motion.bounceEasing}`};
    }
    
    &:hover > path {
        fill: ${({ theme }) => theme.verascopeColor.yellow200};
        transform: scale(1.5);
    }
`;

interface TooltipContainerProps {
    isVisible: boolean,
    delayDuration: number,
    transitionDuration: number
}
export const TooltipContainer = styled.div<TooltipContainerProps>`
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: ${({ isVisible }) => `${isVisible ? 30 : 0}px`};
    width: 100vw;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    z-index: 1;
    transition: ${({
        isVisible,
        transitionDuration,
        delayDuration,
        theme,
    }) => `
        opacity ${transitionDuration}ms ${isVisible ? delayDuration : 0}ms ${theme.motion.eagerEasing},
        bottom ${transitionDuration}ms ${isVisible ? delayDuration : 0}ms ${theme.motion.eagerEasing}
    `};
`;

interface TooltipIconProps {
    isVisible: boolean,
    pulseDuration: number,
    transitionDuration: number
}
export const TooltipIcon = styled.img<TooltipIconProps>`
    position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute')};
    top: -1px;
    width: 20px;
    pointer-events: none;
    transform: scale(1);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    animation: ${({ pulseDuration, theme }) => css`${pulseWhite} ${pulseDuration}ms ${theme.motion.eagerEasing} infinite`};
    transition: ${({
        isVisible,
        transitionDuration,
        theme,
    }) => `opacity ${isVisible ? transitionDuration : 0}ms ${theme.motion.bounceEasing}`};
`;

export const TooltipText = styled.p`
    margin: 0;
    padding-left: 10px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 1em;
    color: white;
    user-select: none;
`;

interface CuneiformTabletFlickerProps {
    isActive: boolean,
    flickerDuration: number,
}
export const CuneiformTabletFlicker = styled.path<CuneiformTabletFlickerProps>`
    animation: ${({ isActive, flickerDuration }) => (isActive
        ? `flicker ${flickerDuration}ms linear 0s infinite normal forwards`
        : 'none'
    )};
`;

interface CuneiformTabletContainerProps {
    width: number,
    top: number,
    left: number,
    rotate: number,
    delay: number,
    revealDuration: number,
    grabDuration: number,
    isVisible: boolean,
    isGrabbed: boolean,
    grabScale: number,
    cursorZIndex: number,
    dollyTowardObject: boolean,
    dollyDuration: number,
    dollyScale: number,
}
export const CuneiformTabletContainer = styled.div<CuneiformTabletContainerProps>`
    ${basicInteractableObjectStyles}
`;

export const CuneiformTablet = styled.svg`
    width: 100%;
`;

interface CuneiformTabletTextProps {
    delay: number,
    revealDuration: number,
    isVisible: boolean,
}
export const CuneiformTabletText = styled.span<CuneiformTabletTextProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 800;
    font-size: 1.5em;
    color: #f7f3b1;
    white-space: nowrap;
    user-select: none;
    padding: 5px 10px;
    border-radius: 5px;
    background: #463116;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({
        isVisible,
        delay,
        revealDuration,
        theme,
    }) => `
        opacity ${isVisible ? revealDuration : 0}ms ${isVisible ? `${delay}ms` : ''} ${theme.motion.standardEasing}`};
`;
