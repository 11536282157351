// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
}                                   from '../../constants/generalConstants';

interface ContainerProps {
    isInvalid: boolean,
    isFocused: boolean,
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    min-height: 40px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    font-weight: 500;
    color: ${({ theme }) => theme.verascopeColor.purple100};
    border: ${({
        isInvalid,
        isFocused,
        theme,
    }) => {
        if (isInvalid) {
            return `3px solid ${theme.verascopeColor.red200}`;
        }

        if (isFocused) {
            return `3px solid ${theme.verascopeColor.orange200}`;
        }

        return `3px solid ${theme.verascopeColor.purple100}`;
    }};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface InputHeadingProps {
    isInvalid: boolean,
}
export const InputHeading = styled.h3<InputHeadingProps>`
    margin: 0;
    padding: 0.8em 12px;
    padding-bottom: 0em;
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    font-weight: 500;
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.purple300
    )};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

interface InputContainerProps {
    noText: boolean,
}
export const InputContainer = styled.div<InputContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: ${({ noText }) => (noText ? '36px' : 'auto')};
    min-height: 20px;
    background: ${({ theme }) => theme.color.white};
`;

interface InputProps {
    isInvalid: boolean,
}
export const Input = styled.input<InputProps>`
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.1}em`};
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.purple100
    )};
    text-transform: uppercase;
    padding: 0px 12px;
    cursor: none;
    background-color: transparent;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }

    &:-ms-input-placeholder {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral500
    )};
    }

    &::-webkit-input-placeholder {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral500
    )};
    }

    &::placeholder {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral500
    )};
    }

    &::-ms-input-placeholder {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral500
    )};
    }

    &:focus {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};

        &:-ms-input-placeholder {
            color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
        }

        &::-webkit-input-placeholder {
            color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
        }

        &::placeholder {
            color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
        }

        &::-ms-input-placeholder {
            color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }
`;

interface RequiredIndicatorContainerProps {
    isInvalid: boolean,
}
export const RequiredIndicatorContainer = styled.div<RequiredIndicatorContainerProps>`
    width: 20px;
    height: 20px;
    padding-right: 5px;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 60%;
        height: 60%;
    }

    & path, & rect {
        fill: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
    }
`;
