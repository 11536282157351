// ===== Packages =====

import {
    Node,
    Element,
}                           from 'slate';

// ===== Services =====

import {
    normalizeURL,
    getYouTubeParams,
    getSpotifyId,
    getTwitterId,
    getVimeoId,
}                           from '../../../../services';

// ===== Intefaces =====

import {
    EditorNode,
    FigureContentOptions,
    FigureContentNode,
}                           from '../../interfaces';

// ===== Enums =====

import {
    FIGURE_CONTENT_TYPE,
    FIGURE_ORIENTATION,
}                           from './enums';

const BLOCK_CONTENT_TYPE = 'figure-content';

const isFigureContentElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_CONTENT_TYPE;

const newFigureContentElement = (options: FigureContentOptions): FigureContentNode => {
    const {
        id,
        url,
        filePath,
        orientation,
        isGalleryChild,
    } = options;
    return {
        type: BLOCK_CONTENT_TYPE,
        figureContentType: getTypeOfFigureContentUrl(url),
        orientation: orientation || FIGURE_ORIENTATION.center,
        id,
        url,
        filePath,
        children: [{ text: '' }],
        ...(isGalleryChild ? { isGalleryChild: true } : {}),
    };
};

const getTypeOfFigureContentUrl = (url?: string): FIGURE_CONTENT_TYPE => {
    if (url && getYouTubeParams(url)) {
        return FIGURE_CONTENT_TYPE.youtube;
    }
    if (url && getSpotifyId(url)) {
        return FIGURE_CONTENT_TYPE.spotify;
    }
    if (url && getTwitterId(url)) {
        return FIGURE_CONTENT_TYPE.twitter;
    }
    if (url && getVimeoId(url)) {
        return FIGURE_CONTENT_TYPE.vimeo;
    }

    // Has filePath
    return FIGURE_CONTENT_TYPE.image;
};

export default {
    TYPE: BLOCK_CONTENT_TYPE,
    isFigureContentElement,
    newFigureContentElement,
    normalizeURL,
    getTypeOfFigureContentUrl,
    FIGURE_CONTENT_TYPE,
};
