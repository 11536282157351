// ===== Packages =====

import React, {
    useMemo,
}                                       from 'react';
import { v4 as uuidv4 }                 from 'uuid';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
}                                       from '../../enums';

// ===== Services =====

import {
    detectTouchDevice,
}                                       from '../../services';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    CustomRadioButton,
    Input,
    InputContainer,
    Title,
    Label,
}                                       from './styles';

interface Props {
    checked: boolean,
    text: string,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    onChange: (checked: boolean) => void,
}
function PixelCheckbox({
    checked,
    text,
    onCursorEnter,
    onCursorLeave,
    onChange,
}: Props): JSX.Element {
    // ===== Methods =====

    const onInputEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.radioInput,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onInputLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(!checked);
    };

    // ===== Memoization =====

    const radioInputId = useMemo(() => (
        uuidv4()
    ), []);

    return (
        <Container
            className={HOVER_TARGET_CLASSNAME}
            {...(detectTouchDevice(document) ? {
                onTouchStart: (e) => onInputEnter(e),
            } : {
                onMouseEnter: (e) => onInputEnter(e),
            })}
            {...(detectTouchDevice(document) ? {
                onTouchEnd: (e) => onInputLeave(e),
            } : {
                onMouseLeave: (e) => onInputLeave(e),
            })}
        >
            <InputContainer>
                <Input
                    type="checkbox"
                    checked={checked}
                    name={`radio-input-${radioInputId}`}
                    onChange={(e) => onInputChange(e)}
                />
                <CustomRadioButton />
                <Label>
                    <Title
                        htmlFor={`radio-input-${radioInputId}`}
                    >
                        {text}
                    </Title>
                </Label>
            </InputContainer>
        </Container>
    );
}

export default PixelCheckbox;
