// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> prepareDailyDigest
export enum BROWSER_TYPE {
    chrome = 'chrome',
    safari = 'safari',
    opera = 'opera',
    edgeNonChromium = 'edge-non-chromium',
    edgeChromium = 'edge-chromium',
    firefox = 'firefox',
    explorer = 'explorer',
    other = 'other',
}
