// ===== Packages =====

import {
    doc,
    getFirestore,
    runTransaction,
    deleteField,
} from 'firebase/firestore';

// ===== Interfaces =====

import {
    IPostItem,
    IPostChapterItem,
}                           from '../interfaces';

// ===== Enums =====

import {
    FIRESTORE_COLLECTION,
}                           from '../enums';

// ===== Constants =====

import {
    DELETE_POST_VALUE_TOKEN,
    DELETE_POST_CHAPTERS_TOKEN,
}                           from '../constants/generalConstants';

interface Args {
    collection: string,
    id: string,
    title?: string,
    subtitle?: string,
    authors?: string[],
    imagePath?: string,
    chapters?: IPostChapterItem[] | string,
    value?: string,
    views?: string[],
    annotations?: string[],
}
const updatePostInDB = async ({
    collection,
    id,
    title,
    subtitle,
    authors,
    imagePath,
    chapters,
    value,
    views,
    annotations,
}: Args): Promise<void> => {
    const db = getFirestore();
    const ref = doc(db, collection, id);
    try {
        return await runTransaction(db, async (transaction) => {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            const postRef = doc(db, postsCollection, id);
            const postDoc = await transaction.get(postRef);
            if (!postDoc.exists()) {
                console.error('Encountered an error updating post. Post document does not exist.');
                return;
            }

            const postData = postDoc.data() as IPostItem;
            if (title) transaction.update(ref, { title });
            if (subtitle) transaction.update(ref, { subtitle });
            if (authors) transaction.update(ref, { authors });
            if (imagePath) transaction.update(ref, { imagePath });
            if (value === DELETE_POST_VALUE_TOKEN) {
                transaction.update(ref, { value: deleteField() });
            } else if (value) {
                transaction.update(ref, { value });
            }
            if (chapters === DELETE_POST_CHAPTERS_TOKEN) {
                transaction.update(ref, { chapters: deleteField() });
            } else if (chapters) {
                transaction.update(ref, { chapters });
            }
            if (views) transaction.update(ref, { views });
            if (annotations) transaction.update(ref, { annotations });

            // Indicate that post was updated
            transaction.update(ref, {
                updated: [
                    ...postData.updated,
                    Date.now(),
                ],
            });
        });
    } catch (e) {
        console.error('Encountered an error updating post.');
    }
};

export default updatePostInDB;
