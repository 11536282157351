// ===== Packages =====

import { v4 as uuidv4 }     from 'uuid';
import {
    doc,
    getDoc,
    setDoc,
    getFirestore,
    updateDoc,
}                           from 'firebase/firestore';

// ===== Interfaces =====

import {
    IUserActionItem,
    IUserSessionItem,
}                           from '../interfaces';

// ===== Enums =====

import {
    USER_ACTION_TYPE,
    FIRESTORE_COLLECTION,
}                           from '../enums';

interface Props {
    type: USER_ACTION_TYPE,
    userId: string,
    sessionId: string,
    payload?: any,
}
const recordUserAction = async ({
    type,
    userId,
    sessionId,
    payload,
}: Props): Promise<string> => {
    // Create action item
    const actionId = uuidv4();
    const action: IUserActionItem = {
        id: actionId,
        userId,
        sessionId,
        type,
        timestamp: Date.now(),
    };

    if (payload) {
        action.payload = payload;
    }

    // Initialize Firestore
    const db = getFirestore();

    // Set action item
    const actionsCollection = process.env.NODE_ENV === 'production'
        ? FIRESTORE_COLLECTION.userActions
        : FIRESTORE_COLLECTION.stagingUserActions;
    try {
        await setDoc(doc(db, actionsCollection, actionId), action);

        // Get existing session actions
        const sessionsCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.userSessions
            : FIRESTORE_COLLECTION.stagingUserSessions;
        const sessionRef = doc(db, sessionsCollection, sessionId);
        const sessionSnap = await getDoc(sessionRef);
        if (sessionSnap.exists()) {
            const session = sessionSnap.data() as IUserSessionItem;

            // Set updated session actions
            const updatedActions = [];
            if (session) {
                updatedActions.push(...session.actions);
                updatedActions.push(actionId);
                await updateDoc(sessionRef, {
                    actions: updatedActions,
                    stop: Date.now(), // we change this value everytime we add action to capture last discrete action
                });
            }
        }
    } catch (e) {
        throw Error(`There was an error processing user interaction: ${e}`);
    }

    return actionId;
};

export default recordUserAction;
