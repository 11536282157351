// ===== Packages =====

import {
    bellmanFordAlgorithm,
}                                   from '.';

// ===== Interfaces =====
import {
    IKShortesPathItem,
    IKShortestPathAlgorithmProps,
}                                   from '../interfaces';

// Based off of Yen's Algorithm: https://en.wikipedia.org/wiki/Yen%27s_algorithm
const yensAlgorithm = ({
    graph,
    sourceIndex,
    targetIndex,
    k,
}: IKShortestPathAlgorithmProps): IKShortesPathItem => {
    const n = graph.length;
    const A: number[][] = [];
    let B: number[][] = [];
    const result = bellmanFordAlgorithm({
        graph,
        sourceIndex,
    });

    // terminate if cycle detected
    if (result.cycle) {
        return {
            distances: [],
            paths: [],
            cycle: result.cycle,
        };
    }

    // Initial shortest path from start to end
    const initialShortestPath = getPath(bellmanFordAlgorithm({
        graph,
        sourceIndex,
    }).predecessor, targetIndex);
    // ensures that bellman ford finds a valid path
    if (initialShortestPath[0] !== sourceIndex) {
        return {
            distances: [],
            paths: [],
        };
    }
    A.push(initialShortestPath);

    for (let kIndex = 1; kIndex < k; kIndex += 1) {
        for (let i = 0; i < A[kIndex - 1].length - 1; i += 1) {
            const graphCopy = [...graph.map((arr: (number | null)[]) => [...arr])];
            const spurNode: number = A[kIndex - 1][i];
            const rootPath = A[kIndex - 1].slice(0, i + 1);

            // remove edge from spur node to next node in committed k-shortest paths
            A.forEach((path: number[]) => {
                if (compareArrays(path.slice(0, i + 1), rootPath)) {
                    graphCopy[path[i]][path[i + 1]] = null;
                }
            });

            // remove all edges to root path nodes from weight matrix
            for (let j = 0; j < rootPath.length - 1; j += 1) {
                graphCopy[j] = new Array(n).fill(null);
                for (let x = 0; x < n; x += 1) {
                    graphCopy[x][j] = null;
                }
            }

            const spurPath = getPath(bellmanFordAlgorithm({
                graph: graphCopy,
                sourceIndex: spurNode,
            }).predecessor, targetIndex);
            rootPath.splice(-1); // ensure spur node not duplicated
            const totalPath = rootPath.concat(spurPath);

            if (spurPath[0] === spurNode && !isSubset(B, totalPath)) {
                // first condition ensures that bellman ford finds a valid path
                B.push(totalPath);
            }
        }

        // handles the case of there being no spur paths, or no spur paths left
        // could happen if the spur paths have already been exhausted (added to A)
        // or there are no spur paths at all - such as when both the source and sink vertices
        // lie along a "dead end".
        if (B.length === 0) {
            break;
        }

        // Sort the potential k-shortest paths by distance.
        const pathDistances = new Array(B.length).fill(0);
        B.forEach((path: number[], pathIndex: number) => {
            const distance = getDistance(graph, path);
            pathDistances[pathIndex] = distance;
        });
        const combinedArray = B.map((value: number[], index: number) => (
            {
                value,
                order: pathDistances[index],
            }
        ));
        combinedArray.sort((a, b) => a.order - b.order);

        B = combinedArray.map((item: {
            value: number[],
            order: number,
        }) => item.value);

        // Add the lowest cost path becomes the k-shortest path.
        A.push(B[0]);
        B.splice(0, 1);
    }

    return {
        distances: A.map((path: number[]) => getDistance(graph, path)),
        paths: A,
    };
};

const getPath = (predecessor: number[], endIndex: number): number[] => {
    const path = [];
    let current = endIndex;

    while (current !== null) {
        path.unshift(current);
        current = predecessor[current];
    }

    return path;
};

const getDistance = (graph: (number | null)[][], path: number[]): number => {
    let distance = 0;
    for (let j = 0; j < path.length - 1; j += 1) {
        const weight = graph[path[j]][path[j + 1]];
        if (weight === null) throw Error(`Expected an edge between nodes ${path[j]} and ${path[j + 1]} while executing k-shortest path algorithm.`);
        else distance += weight;
    }

    return distance;
};

const compareArrays = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i += 1) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
};

const isSubset = (matrix: number[][], subset: number[]): boolean => {
    for (let i = 0; i < matrix.length; i += 1) {
        for (let j = 0; j < matrix[i].length; j += 1) {
            if (matrix[i][j] !== subset[j]) {
                break; // Break the inner loop if elements don't match
            }
            if (j === subset.length - 1) {
                return true; // Subset found in the 2D array
            }
        }
    }
    return false; // Subset not found in the 2D array
};

export default yensAlgorithm;
