// ===== Services =====

import hexToRgb         from './hexToRgb';
import rgbToHsl         from './rgbToHsl';
import hslToRgb         from './hslToRgb';
import rgbToHex         from './rgbToHex';

const min = 0;
const max = 100;

const setColorLightness = (hex: string, lightness: number): string => {
    const colorRgb = hexToRgb(hex);
    const colorHsl = rgbToHsl({
        r: colorRgb?.r ?? 0,
        g: colorRgb?.g ?? 0,
        b: colorRgb?.b ?? 0,
    });
    let normalizedLightness = lightness > max ? max : lightness;
    normalizedLightness = lightness < min ? min : normalizedLightness;
    const lightnessColorRgb = hslToRgb({
        h: colorHsl.h,
        s: colorHsl.s,
        l: normalizedLightness,
    });
    const lightnessColor = rgbToHex({
        r: lightnessColorRgb.r,
        g: lightnessColorRgb.g,
        b: lightnessColorRgb.b,
    });

    return lightnessColor;
};

export default setColorLightness;
