// ===== Packages =====

import styled                       from 'styled-components';

// ===== Services =====

import {
    setColorLightness,
}                                   from '../../services';

// ===== Constants =====

import {
    USER_PROFILE_DIALOG_Z_INDEX,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

interface ContainerProps {
    isExpanded: boolean,
    cartButtonVisible: boolean,
    lightBackground: boolean,
    transitionDuration: number,
}
export const Container = styled.div<ContainerProps>`
    position: fixed;
    top: 0px;
    right: ${({ cartButtonVisible }) => `${cartButtonVisible ? 42.5 : 0}px`};
    z-index: ${USER_PROFILE_DIALOG_Z_INDEX};
    background: ${({
        isExpanded,
        lightBackground,
        theme,
    }) => (isExpanded || lightBackground
        // The pages who you want to have a white custom cursor should route here
        // Dark background invokes white cursor
        ? theme.color.white
        : theme.verascopeColor.purple200
    )};
    transition: ${({ transitionDuration, theme }) => `
        right ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: ${({ cartButtonVisible, isExpanded }) => {
        if (cartButtonVisible && !isExpanded) {
            return '42.5px';
        }

        return '0px';
    }}}
`;

interface DialogBodyContainerProps {
    contractedTop: number,
    contractedRight: number,
    expandedTop: number,
    expandedRight: number,
    avatarLength: number,
    expandedWidth: number,
    expandedHeight: number,
    isExpanded: boolean,
    closeButtonLength: number,
    padding: number,
    transitionDuration: number,
}
export const DialogBodyContainer = styled.div<DialogBodyContainerProps>`
    position: absolute;
    top: ${({ isExpanded, contractedTop, expandedTop }) => `${isExpanded ? expandedTop : contractedTop}px`};
    right: ${({ isExpanded, contractedRight, expandedRight }) => `${isExpanded ? expandedRight : contractedRight}px`};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ isExpanded, theme }) => (isExpanded
        ? theme.color.boxShadow300
        : 'none'
    )};
    border-radius: ${({ closeButtonLength }) => `${closeButtonLength / 2}px`};
    border-top-right-radius: ${({ isExpanded, closeButtonLength }) => `${isExpanded ? 0 : closeButtonLength / 2}px`};
    width: ${({ isExpanded, avatarLength, expandedWidth }) => `${isExpanded ? expandedWidth : avatarLength}px`};
    height: ${({ isExpanded, avatarLength, expandedHeight }) => `${isExpanded ? expandedHeight : avatarLength}px`};
    padding: ${({ isExpanded, padding }) => `${isExpanded ? padding : 0}px`};
    pointer-events: ${({ isExpanded }) => (isExpanded ? 'auto' : 'none')};
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-right-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-left-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        width ${transitionDuration}ms ${theme.motion.eagerEasing},
        height ${transitionDuration}ms ${theme.motion.eagerEasing},
        padding ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${({ isExpanded, contractedTop }) => `${isExpanded ? 0 : contractedTop}px`};
        right: ${({ isExpanded, contractedRight }) => `${isExpanded ? 0 : contractedRight}px`};
        width: ${({ isExpanded, avatarLength }) => `${isExpanded ? '100vw' : `${avatarLength}px`}`};
        border-top-left-radius: ${({ isExpanded, closeButtonLength }) => `${isExpanded ? 0 : closeButtonLength / 2}px`};
    }   
`;

interface DialogBodyProps {
    isExpanded: boolean,
    opacityDuration: number,
    transitionDelayDuration: number,
}
export const DialogBody = styled.div<DialogBodyProps>`
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    transition: ${({
        isExpanded,
        opacityDuration,
        transitionDelayDuration,
        theme,
    }) => `
        opacity ${opacityDuration}ms ${isExpanded ? transitionDelayDuration : 0}ms ${theme.motion.standardEasing}
    `};
`;

interface CornerCurveProps {
    isExpanded: boolean,
    closeButtonLength: number,
    transitionDuration: number,
}
export const CornerCurve = styled.div<CornerCurveProps>`
    position: absolute;
    right: ${({ isExpanded, closeButtonLength }) => `${isExpanded ? closeButtonLength : 0}px`};
    top: 0px;
    width: ${({ isExpanded, closeButtonLength }) => `${isExpanded ? (closeButtonLength / 2) : 0}px`};
    height: ${({ isExpanded, closeButtonLength }) => `${isExpanded ? (closeButtonLength / 2) : 0}px`};
    transform: translateY(-100%) rotate(180deg);
    pointer-events: none;
    transition: ${({ transitionDuration, theme }) => `
        right ${transitionDuration}ms ${theme.motion.eagerEasing},
        width ${transitionDuration}ms ${theme.motion.eagerEasing},
        height ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
    }

    & path {
        fill: ${({ theme }) => theme.color.white};
    }
`;

interface CloseButtonContainerProps {
    contractedTop: number,
    contractedRight: number,
    expandedTop: number,
    expandedRight: number,
    avatarLength: number,
    closeButtonLength: number,
    isExpanded: boolean,
    transitionDuration: number,
}
export const CloseButtonContainer = styled.div<CloseButtonContainerProps>`
    position: absolute;
    top: ${({ isExpanded, contractedTop, expandedTop }) => `${isExpanded ? expandedTop : contractedTop}px`};
    right: ${({ isExpanded, contractedRight, expandedRight }) => `${isExpanded ? expandedRight : contractedRight}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.color.white};
    width: ${({ closeButtonLength, avatarLength, isExpanded }) => (isExpanded
        ? `${closeButtonLength}px`
        : `${avatarLength}px`
    )};
    height: ${({ closeButtonLength, avatarLength, isExpanded }) => (isExpanded
        ? `${closeButtonLength}px`
        : `${avatarLength}px`
    )};
    border-radius: ${({ closeButtonLength }) => `${(closeButtonLength) / 2}px`};
    border-bottom-right-radius: ${({ closeButtonLength, isExpanded }) => (isExpanded
        ? '0px'
        : `${closeButtonLength / 2}px`
    )};
    border-bottom-left-radius: ${({ closeButtonLength, isExpanded }) => (isExpanded
        ? '0px'
        : `${closeButtonLength / 2}px`
    )};
    transition: ${({ transitionDuration, theme }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-right-radius ${transitionDuration}ms ${theme.motion.eagerEasing},
        border-top-left-radius ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

interface AvatarOverlayProps {
    length: number,
    uploadingImage: boolean,
    transitionDuration: number,
}
export const AvatarOverlay = styled.div<AvatarOverlayProps>`
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background: ${({ theme }) => theme.verascopeColor.purple200};
    opacity: ${({ uploadingImage }) => (uploadingImage ? 1 : 0)};
    pointer-events: none;
    z-index: 1;
    transition: ${({ transitionDuration, theme }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & svg {
        width: 15px;
        height: 15px;
    }

    & fill {
        background: ${({ theme }) => theme.color.white};
    }
`;

interface AvatarContainerProps {
    lightBackground: boolean,
    contractedTop: number,
    contractedRight: number,
    expandedTop: number,
    expandedRight: number,
    length: number,
    isExpanded: boolean,
    anonIconId: string,
    avatarLength: number,
    dialogBodyPadding: number,
    translateDuration: number,
}
export const AvatarContainer = styled.div<AvatarContainerProps>`
    position: absolute;
    top: ${({ isExpanded, contractedTop, expandedTop }) => `${isExpanded ? expandedTop : contractedTop}px`};
    right: ${({ isExpanded, contractedRight, expandedRight }) => `${isExpanded ? expandedRight : contractedRight}px`};
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    background: ${({ isExpanded, lightBackground, theme }) => (lightBackground || isExpanded
        ? theme.color.white
        : theme.verascopeColor.orange200
    )};
    border-radius: ${({ length }) => `${length / 2}px`};
    z-index: 1;
    transition: ${({
        translateDuration,
        theme,
    }) => `
        top ${translateDuration}ms ${theme.motion.eagerEasing},
        right ${translateDuration}ms ${theme.motion.eagerEasing},
        width ${translateDuration}ms ${theme.motion.eagerEasing},
        height ${translateDuration}ms ${theme.motion.eagerEasing}
    `};

    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & ${({ anonIconId }) => `#${anonIconId}`} svg {
        width: ${({ length }) => `${length - 5}px`};
        height: ${({ length }) => `${length - 5}px`};
    }

    & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    & path {
        fill: ${({ theme }) => theme.color.white};
    }

    &:hover ${AvatarOverlay} {
        opacity: ${({ isExpanded }) => (isExpanded ? 0.6 : 0)};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: ${({
        isExpanded,
        contractedTop,
        dialogBodyPadding,
    }) => `${isExpanded ? dialogBodyPadding : contractedTop}px`};
        right: ${({
        isExpanded,
        contractedRight,
        avatarLength,
        dialogBodyPadding,
    }) => `${isExpanded ? `calc(100vw - ${avatarLength + dialogBodyPadding}px)` : `${contractedRight}px`}`};
    }
`;

interface AvatarUploadProgressBarContainerProps {
    length: number,
}
export const AvatarUploadProgressBarContainer = styled.div<AvatarUploadProgressBarContainerProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ length }) => `${length - 5}px`};
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    border: ${({ theme }) => `1px solid ${theme.verascopeColor.purple400}`};
`;

interface AvatarUploadProgressBarProps {
    progress: number,
}
export const AvatarUploadProgressBar = styled.div<AvatarUploadProgressBarProps>`
    width: ${({ progress }) => `${progress}%`};
    height: 100%;
    background: ${({ theme }) => theme.verascopeColor.blue200};
`;

export const NameContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const NameText = styled.h3`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 1em;
    color: ${({ theme }) => theme.color.neutral900};
    white-space: nowrap;
    margin: 0;
    margin-top: 1px;
    margin-left: 38px;
    margin-right: 5px;
`;

export const NameInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-right: 40px;
    }
`;

interface NameButtonContainerProps {
    width: number,
}
export const NameButtonContainer = styled.div<NameButtonContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${({ width }) => `${width}px`};
    background: ${({ theme }) => theme.color.white};
`;

interface AuthenticationMessageProps {
    isVisible?: boolean,
}
export const AuthenticationMessage = styled.p<AuthenticationMessageProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral700};
    max-width: 30ch;
    margin: 0;
    margin-top: 10px;
    margin-left: 35px;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: auto;
        max-width: 40ch;
    }
`;

interface AuthMethodsContainerProps {
    isExpanded: boolean,
}
export const AuthMethodsContainer = styled.div<AuthMethodsContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${({ isExpanded }) => `${isExpanded ? 10 : 0}px`};
    margin-left: ${({ isExpanded }) => `${isExpanded ? 35 : 0}px`};
    background: ${({ theme }) => theme.color.white};
`;

interface AuthMethodProps {
    transitionDuration: number,
}
export const AuthMethod = styled.div<AuthMethodProps>`
    display: inline-block;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 5px;
    margin-right: 5px;
    background: ${({ theme }) => theme.color.neutral300};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: ${({ theme, transitionDuration }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        background: ${({ theme }) => theme.color.neutral500};
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }


    & svg, & > div:nth-child(2) {
        width: 20px;
        height: 20px;
    }
`;

interface EmailAuthFieldsContainerProps {
    isVisible: boolean,
}
export const EmailAuthFieldsContainer = styled.div<EmailAuthFieldsContainerProps>`
    position: relative;
    padding-left: ${({ isVisible }) => `${isVisible ? 33 : 0}px`};
    max-width: 400px;
`;

export const EmailAuthInputContainer = styled.div`
    position: relative;
    display: inline-block;
    z-index: 1; // To put Tooltip in front of Back Button
`;

interface EmailAuthInputProps {
    isInvalid?: boolean,
    width: number,
}
export const EmailAuthInput = styled.input<EmailAuthInputProps>`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral800
    )};
    background-color: ${({
        isInvalid,
        theme,
    }) => {
        if (isInvalid) {
            return setColorLightness(
                theme.color.error,
                95,
            );
        }

        return theme.color.neutral200;
    }};
    border: ${({ theme, isInvalid }) => `2px solid ${isInvalid
        ? theme.verascopeColor.red200
        : theme.color.white}`}; 
    width: ${({ width }) => `${width}px`};
    margin: 0;
    margin-top: 5px;
    padding: 2.5px 15px;
    text-transform: none;
    border: none;
    text-decoration: none;
    border-radius: 20px;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral600};
    }
`;

interface NameInputProps {
    isInvalid?: boolean,
    width: number,
    marginLeft?: number,
}
export const NameInput = styled(EmailAuthInput)<NameInputProps>`
    margin-top: 0px;
    margin-left: ${({ marginLeft }) => `${marginLeft || 0}px`};
    padding: 2.5px 10px;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: calc((100% - 80px) / 2);
    }
`;

export const PasswordStrengthContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 7px;
    width: 100%;
`;

interface PasswordStrengthBarProps {
    active: boolean,
    hasErrors: boolean,
}
export const PasswordStrengthBar = styled.div<PasswordStrengthBarProps>`
    display: inline-block;
    width: 30px;
    height: 6px;
    border-radius: 3px;
    background: ${({ active, hasErrors, theme }) => {
        if (hasErrors && active) return theme.verascopeColor.red200;
        if (active) return theme.verascopeColor.blue200;

        return theme.color.neutral300;
    }};
`;

export const EmailAuthBackButtonContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background: ${({ theme }) => theme.color.white};
`;

interface EmailAuthTabsContainerProps {
    height: number,
}
export const EmailAuthTabsContainer = styled.div<EmailAuthTabsContainerProps>`
    position: relative;
    height: ${({ height }) => `${height}px`};
`;

interface EmailAuthTabButtonContainerProps {
    index: number,
    width: number,
    margin: number,
    background: string,
    movingButtonCurrentIndex: number,
}
export const EmailAuthTabButtonContainer = styled.div<EmailAuthTabButtonContainerProps>`
    position: absolute;
    top: 0px;
    left: ${({ index, width, margin }) => `${index * (width + margin)}px`};
    margin: ${({ margin }) => `0px ${margin / 2}px`};
    
    & h3 {
        color: ${({
        index,
        movingButtonCurrentIndex,
        theme,
    }) => (index === movingButtonCurrentIndex
        ? `${theme.color.white}`
        : 'auto'
    )};
    }

    &:hover button {
        background-color: ${({
        index,
        movingButtonCurrentIndex,
        background,
    }) => (index === movingButtonCurrentIndex
        ? background
        : 'auto'
    )};
    }
`;

interface ForgotPasswordTextProps {
    transitionDuration: number,
}
export const ForgotPasswordText = styled.p<ForgotPasswordTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.neutral800};
    white-space: nowrap;
    margin: 0;
    padding: 5px 0px;
    padding-left: 15px;
    transition: ${({ transitionDuration, theme }) => `
        color ${transitionDuration}ms ${theme.motion.standardEasing},
        font-weight ${transitionDuration}ms ${theme.motion.standardEasing}
    `};

    &:hover {
        color: ${({ theme }) => theme.verascopeColor.purple300};
        font-weight: 600;
    }
`;

interface SubmitEmailAuthButtonContainerProps {
    width: number,
}
export const SubmitEmailAuthButtonContainer = styled.div<SubmitEmailAuthButtonContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 5px;
    width: ${({ width }) => `${width}px`};
`;

interface AuthUserDetailsProps {
    isVisible: boolean,
    paddingDuration: number,
}
export const AuthUserDetails = styled.div<AuthUserDetailsProps>`
    display: flex;
    flex-direction: column;
    padding-left: ${({ isVisible }) => `${isVisible ? 35 : 0}px`};
    transition: ${({ paddingDuration, theme }) => `
        padding-left ${paddingDuration}ms ${theme.motion.eagerEasing}
    `};
`;

export const AuthUserContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
`;

export const AuthUserIcon = styled.div`
    position: relative;
    top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;

    & svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
    }

    & path {
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;

export const AuthUserText = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    color: ${({ theme }) => theme.color.neutral700};
    white-space: nowrap;
    margin: 0;
    margin-left: 5px;
`;

export const AuthUserSignOutButtonContainer = styled.div`
    margin-top: 10px;
`;
