export enum FIGURE_ORIENTATION {
    right = 'right',
    left = 'left',
    center = 'center',
    fullscreen = 'fullscreen',
}

export enum FIGURE_CONTENT_TYPE {
    image = 'image',
    youtube = 'youtube',
    spotify = 'spotify',
    twitter = 'twitter',
    vimeo = 'vimeo',
}
