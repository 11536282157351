/* eslint-disable no-unused-vars */

// ===== Packages =====

import {
    Node,
    Editor,
    NodeEntry,
    Transforms,
    Range,
}                           from 'slate';

// ===== Elements =====

import _BlockQuoteElement   from '../elements/blockQuoteElement';
import LineElement          from '../elements/lineElement';

// ===== Interfaces =====

import {
    EditorNode,
    BlockQuoteNode,
}                           from '../interfaces';

export const BlockQuoteElement = _BlockQuoteElement;

const setAsBlockQuoteElement = (editor: Editor): void => {
    Array.from(Editor.nodes(editor, { match: BlockQuoteElement.implementsLineInterface })).forEach(([, path]) => {
        Transforms.setNodes(editor, BlockQuoteElement.newBlockQuoteElement(), { at: path });
    });
};

const getSelectionBlockQuotes = (editor: Editor): NodeEntry<Node>[] => Array.from(
    Editor.nodes(editor, { match: BlockQuoteElement.isBlockQuoteElement }),
);

const hasSelectedBlockQuote = (editor: Editor): boolean => {
    const [match] = Array.from(Editor.nodes(editor, { match: BlockQuoteElement.isBlockQuoteElement }));
    return !!match;
};

const toggleBlockQuote = (editor: Editor): void => {
    if (hasSelectedBlockQuote(editor)) {
        Transforms.setNodes(editor, LineElement.newLineElement(), { match: BlockQuoteElement.isBlockQuoteElement });
    } else {
        setAsBlockQuoteElement(editor);
    }
};

export const BlockQuoteHelpers = {
    getSelectionBlockQuotes,
    setAsBlockQuoteElement,
    hasSelectedBlockQuote,
    toggleBlockQuote,
};

export const withBlockQuotes = (editor: Editor): Editor => {
    const {
        normalizeNode,
        isInline,
        isVoid,
        insertBreak,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as EditorNode;

        if (!BlockQuoteElement.isBlockQuoteElement(node)) { return normalizeNode(entry); }

        const blockQuote = node;

        if (Editor.hasBlocks(editor, blockQuote)) { return Transforms.unwrapNodes(editor, { at: path }); }

        const blockQuoteDeclaresIsLineProperty = node.isLine === true;
        if (!blockQuoteDeclaresIsLineProperty) { return Transforms.setNodes<BlockQuoteNode>(editor, { isLine: true }, { at: path }); }

        const blockQuoteDeclaresIsListableProperty = node.isListable === true;
        if (!blockQuoteDeclaresIsListableProperty) { return Transforms.setNodes<BlockQuoteNode>(editor, { isListable: true }, { at: path }); }
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (BlockQuoteElement.isBlockQuoteElement(element) ? false : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (BlockQuoteElement.isBlockQuoteElement(node) ? false : isVoid(node));

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        const [blockQuoteEntry] = Array.from(Editor.nodes(editor, { match: BlockQuoteElement.isBlockQuoteElement }));
        const { selection }: { selection: Range | null } = editor;
        if (blockQuoteEntry && selection) {
            const [start, end] = Range.edges(selection);
            const [startBlockQuoteEntry] = Array.from(Editor.nodes(editor, { at: start.path, match: BlockQuoteElement.isBlockQuoteElement }));
            const [endBlockQuoteEntry] = Array.from(Editor.nodes(editor, { at: end.path, match: BlockQuoteElement.isBlockQuoteElement }));
            if (startBlockQuoteEntry && endBlockQuoteEntry) {
                return Transforms.insertText(editor, '\n');
            }
            if (startBlockQuoteEntry) {
                const [[endNode]] = Array.from(
                    Editor.nodes(editor, { at: end.path, match: (n) => Editor.isBlock(editor, n), mode: 'lowest' }),
                );
                insertBreak();
                return Editor.withoutNormalizing(editor, () => {
                    Transforms.setNodes(editor, endNode as EditorNode);
                    Transforms.unsetNodes(editor, ['headerSize', 'isLine', 'isListable']);
                });
            }
            if (endBlockQuoteEntry) {
                insertBreak();
                return Transforms.setNodes(editor, BlockQuoteElement.newBlockQuoteElement());
            }
        }

        return insertBreak();
    };

    return editor;
};
