// ** IMPORTANT **: Keep in sync with functions/src/index.ts
export enum FIRESTORE_COLLECTION {
    treasureChestItems = 'treasure-chest-items',
    treasureChestThemes = 'treasure-chest-themes',
    treasureChestAuthors = 'treasure-chest-authors',
    treasureChestTypes = 'treasure-chest-types',
    // Production
    posts = 'posts',
    users = 'users',
    annotations = 'annotations',
    media = 'media',
    userActions = 'user-actions',
    notificationRequests = 'notification-requests',
    userSessions = 'user-sessions',
    mail = 'mail',
    sales = 'sales',
    checkoutSessions = 'checkout-sessions',
    dailyDigests = 'daily-digests',
    // Staging
    stagingPosts = 'staging-posts',
    stagingUsers = 'staging-users',
    stagingAnnotations = 'staging-annotations',
    stagingMedia = 'staging-media',
    stagingUserActions = 'staging-user-actions',
    stagingNotificationRequests = 'staging-notification-requests',
    stagingUserSessions = 'staging-user-sessions',
    stagingSales = 'staging-sales',
    stagingCheckoutSessions = 'staging-checkout-sessions',
}
