export enum HEADER_SIZE {
    one = 1,
    two = 2,
}

// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> prepareDailyDigest
export enum RESOLUTION_LEVEL {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5,
}

export enum RESOLUTION_LEVEL_TYPE {
    inline = 'inline',
    block = 'block',
}
