// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeApplyDigitalBookDiscount = async ({
    test,
    salePaymentIntentId,
    currPaymentIntentId,
}: {
    test: boolean,
    salePaymentIntentId: string,
    currPaymentIntentId: string,
}): Promise<Stripe.PaymentIntent | undefined> => {
    const functions = getFunctions();
    const applyDiscount = httpsCallable<
        {
            test: boolean,
            salePaymentIntentId: string,
            currPaymentIntentId: string,
        },
        Stripe.PaymentIntent | undefined
    >(
        functions,
        'stripeApplyDigitalBookDiscount',
    );

    return applyDiscount({
        test,
        salePaymentIntentId,
        currPaymentIntentId,
    }).then((result) => {
        const paymentIntent = result.data as Stripe.PaymentIntent | undefined;
        return paymentIntent;
    }).catch((err) => err);
};

export default stripeApplyDigitalBookDiscount;
