// ===== Packages =====

import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
}                                               from 'react';
import { ReactSVG }                             from 'react-svg';
import { Transition }                           from 'react-transition-group';
import {
    useDrop,
}                                               from 'react-dnd';
import { v4 as uuidv4 }                         from 'uuid';
import {
    getAuth,
}                                               from 'firebase/auth';

// ===== Components =====

import ReaderOutlineChapter                     from '../ReaderOutlineChapter';
import {
    Button,
}                                               from '../Editor/helpers';
import Tooltip                                  from '../Tooltip';

// ===== Enums =====

import {
    DRAGGABLE_COMPONENT_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    BUTTON_TYPE,
    TOOLTIP_TYPE,
    FIRESTORE_COLLECTION,
    USER_ACTION_TYPE,
}                                               from '../../enums';
import {
    RESOLUTION_LEVEL,
}                                               from '../Editor/elements/enums';

// ===== Services =====

import {
    playAudio,
    formatNumber,
    updatePostInDB,
    recordUserAction,
    detectTouchDevice,
}                                               from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IDimension,
    IPostItem,
    IPostChapterItem,
    IContentsChapterItem,
    IPostPathEvent,
    ISnackbarItem,
    IPostPathExpandChapterItem,
    IResolutionLevelItem,
}                                               from '../../interfaces';

// ===== Constants =====

import {
    DEFAULT_FONT_SIZE,
    DEFAULT_AUDIO_VOLUME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    HOVER_TARGET_CLASSNAME,
    POST_BANNER_LEFT_CONTAINER_WIDTH,
    POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT,
    POST_TITLE_ENTER_DURATION,
    POST_SUBTITLE_ENTER_DURATION,
    POST_THUMBNAIL_ENTER_DURATION,
    POST_METADATA_ENTER_DURATION,
    CONTENTS_TABLE_MARGIN,
    POST_BANNER_HEIGHT,
    CONTENTS_TABLE_TOP_EXPANDED,
    CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_Y_OFFSET_SMALL_VIEWPORT,
    DELETE_POST_VALUE_TOKEN,
    DELETE_POST_CHAPTERS_TOKEN,
    SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
}                                               from '../../constants/generalConstants';
import MEDIA_QUERY_SIZE                         from '../../constants/mediaQuerySizes';
import CURSOR_SIGN                              from '../../constants/cursorSigns';

// ===== Sounds =====

import SwooshIn                                 from '../../sounds/swoosh_in.mp3';
import SwooshOut                                from '../../sounds/swoosh_out.mp3';
import TooltipEnter                             from '../../sounds/create.mp3';

// ===== Images =====

import PlusIcon                                 from '../../images/editor/plus.svg';
import ChevronIcon                              from '../../images/editor/chevron.svg';
import HighlightIcon                            from '../../images/editor/highlight.svg';

// ===== Styles =====

import {
    ContentsTable,
    ContentsTableTitle,
    ContentsTableChaptersContainer,
    ContentsTableChapter,
    ContentsTableChapterHead,
    ContentsTableChapterTitle,
    ContentsTableChapterAnnotationContainer,
    ContentsTableChapterAnnotationIcon,
    ContentsTableChapterAnnotationText,
    ContentsTableChapterBody,
    ContentsTableChapterDescription,
    ContentsTableChapterSection,
    ContentsTableChapterSectionText,
    ContentsTableChapterSectionLine,
    ContentsTableChapterSectionAnnotationContainer,
    ContentsTableChapterSectionAnnotationIcon,
    ContentsTableChapterSectionAnnotationText,
    ContentsTableProgressBarContainer,
    ContentsTableProgressBar,
    ContentsTableChapterTitleContainer,
    ContentsTableAccordionIcon,
    ContentsTableToggleButtonContainer,
    ContentsTableOutlineContainer,
    AddChapterButton,
    AddChapterButtonIcon,
}                                               from './styles';
import { theme as themeObj }                    from '../../themes/theme-context';

ReaderContentsTableOutliner.defaultProps = {
    hide: false,
};
interface Props {
    hide?: boolean,
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    write: boolean,
    post: IPostItem | undefined,
    currentResolutionLevel: IResolutionLevelItem | null,
    contentsTable: IContentsChapterItem[] | null,
    selectedPostValuePath: number[],
    hasError: boolean,
    notifyUserToSignUpToNavigateBook: boolean,
    postValueIsSaving: boolean,
    onChangeSelectedPostValuePath: (event: IPostPathEvent) => void,
    onSetExpandContentsTableChapter: (
        expandItems: IPostPathExpandChapterItem[],
        e?: React.MouseEvent | React.TouchEvent,
    ) => void,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    contentsTableRef: React.MutableRefObject<HTMLDivElement | null>,
    contentsTableProgressBarContainerRef: React.MutableRefObject<HTMLDivElement | null>,
    contentsTableProgressBarRef: React.MutableRefObject<HTMLDivElement | null>,
    contentsTableToggleButtonContainerRef: React.MutableRefObject<HTMLDivElement | null>,
    setNotifyUserToSignUpToNavigateBook: React.Dispatch<React.SetStateAction<boolean>>,
}
function ReaderContentsTableOutliner({
    hide,
    hasSound,
    user,
    currentSessionId,
    viewportDimensions,
    write,
    post,
    currentResolutionLevel,
    contentsTable,
    selectedPostValuePath,
    hasError,
    notifyUserToSignUpToNavigateBook,
    postValueIsSaving,
    onChangeSelectedPostValuePath,
    onSetExpandContentsTableChapter,
    setCursorSigns,
    onCursorEnter,
    onCursorLeave,
    setSnackbarData,
    contentsTableRef,
    contentsTableProgressBarContainerRef,
    contentsTableProgressBarRef,
    contentsTableToggleButtonContainerRef,
    setNotifyUserToSignUpToNavigateBook,
}: Props): JSX.Element {
    // ===== General Constants =====

    const DEFAULT_LINE_HEIGHT = 1.2;
    const CHAPTER_SECTION_FONT_MULTIPLIER = 0.85;
    const CHAPTER_SECTION_PADDING = 5;
    const CHAPTER_SECTION_HEIGHT = DEFAULT_LINE_HEIGHT * CHAPTER_SECTION_FONT_MULTIPLIER * DEFAULT_FONT_SIZE
        + 2 * CHAPTER_SECTION_PADDING;
    const CONTENTS_TABLE_WIDTH_CONTRACTED = 10;
    const CONTENTS_TABLE_WIDTH_EXPANDED = POST_BANNER_LEFT_CONTAINER_WIDTH - POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT;
    const CONTENTS_TABLE_TOGGLE_BUTTON_LENGTH = 25;
    const CONTENTS_TABLE_TOGGLE_BUTTON_MARGIN = 5;
    const CONTENTS_TABLE_TOGGLE_SECTION_BUTTON_LENGTH = 20;
    const CONTENTS_TABLE_TITLE_FONT_MULTIPLIER = 0.75;
    const CONTENTS_TABLE_TITLE_PADDING_TOP = 10;
    // const CONTENTS_TABLE_TITLE_HEIGHT = DEFAULT_LINE_HEIGHT * CONTENTS_TABLE_TITLE_FONT_MULTIPLIER * DEFAULT_FONT_SIZE
    //     + CONTENTS_TABLE_TITLE_PADDING_TOP;
    const CHAPTER_HEAD_TITLE_FONT_MULTIPLIER = 0.9;
    const CHAPTER_HEAD_DESCRIPTION_FONT_MULTIPLIER = 0.8;
    const CHAPTER_HEAD_PADDING = 10;
    // const CHAPTER_HEAD_HEIGHT = DEFAULT_LINE_HEIGHT * CHAPTER_HEAD_TITLE_FONT_MULTIPLIER * DEFAULT_FONT_SIZE
    //     + DEFAULT_LINE_HEIGHT * CHAPTER_HEAD_DESCRIPTION_FONT_MULTIPLIER * DEFAULT_FONT_SIZE
    //     + 2 * CHAPTER_HEAD_PADDING;
    const CHAPTER_HEIGHT_BUFFER = 20;

    // ===== Refs =====

    // ----- Sound Clips
    const swooshInClip = useRef<HTMLAudioElement>(new Audio());
    const swooshOutClip = useRef<HTMLAudioElement>(new Audio());
    const tooltipEnterClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // Indicates whether contents table is visible
    const [contentsTableVisible, setContentsTableVisible] = useState<boolean>(true);
    // Indicates whether chapters are being saved
    const [chaptersSaving, setChaptersSaving] = useState<boolean>(false);
    // Indicates whether we need to execute a save operation
    // Occurs when we try to save while already perfoming save operation
    const [queueSave, setQueueSave] = useState<IPostChapterItem[] | null>(null);
    // Set up drop target for outline chapters
    const [, drop] = useDrop(() => ({ accept: DRAGGABLE_COMPONENT_TYPE.editorOutlineTitle }));

    // ===== Animation Constants =====

    const CONTENTS_TABLE_TRANSITION_DURATION = 200;
    const CONTENTS_TABLE_PROGRESS_BAR_TRANSITION_DURATION = 200;
    const CONTENTS_TABLE_CHAPTER_ENTER_DURATION = 200;
    const CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION = 200;
    const CONTENTS_TABLE_CHAPTER_SECTION_TRANSITION_DURATION = 200;
    const CONTENTS_TABLE_CHAPTER_SECTION_TEXT_TRANSITION_DURATION = 200;
    const CONTENTS_TABLE_ENTER_TRANSIITON_DURATION = 200;
    // Duration (in milliseconds) for add chapter button to respond to hover over
    const ADD_CHAPTER_BUTTON_TRANSITION_DURATION = 150;

    // ===== Methods =====

    /**
     * Updates chapters in post
     * @param chapters updated chapters
     * @param value non-empty chapter value inherited from a deleted chapter
     */
    const updateChapters = async (chapters: IPostChapterItem[], value?: string): Promise<void> => {
        if (!post) return;
        const postsCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.posts
            : FIRESTORE_COLLECTION.stagingPosts;
        if (!chaptersSaving && !postValueIsSaving) {
            setChaptersSaving(true);
            if (chapters.length === 0) {
                await updatePostInDB({
                    collection: postsCollection,
                    id: post.id,
                    value: value || SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
                    chapters: DELETE_POST_CHAPTERS_TOKEN,
                });
            } else {
                await updatePostInDB({
                    collection: postsCollection,
                    id: post.id,
                    chapters,
                });
            }
            setChaptersSaving(false);
        } else if (chaptersSaving || postValueIsSaving) {
            setQueueSave(chapters);
        }
    };

    /**
     * Manages response to contents table button hovering over
     * @param e mouse or touch event
     */
    const onContentsTableButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.contentsTableButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to contents table button no longer hovering over
     */
    const onContentsTableButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to scrolling contents table
     * @param e scroll event
     */
    const onContentsTableScroll = (e: React.UIEvent): void => {
        const target = e.target as HTMLElement;
        // eslint-disable-next-line no-param-reassign
        if (contentsTableProgressBarContainerRef.current) contentsTableProgressBarContainerRef.current.style.top = `${target.scrollTop}px`;
    };

    /**
     * Reveals or hides contents table
     * @param _e mouse or touch event
    */
    const onToggleContentsTable = (_e: React.MouseEvent | React.TouchEvent): void => {
        setContentsTableVisible(!contentsTableVisible);

        // Play Sound
        if (
            !contentsTableVisible
            && hasSound
            && swooshInClip.current
        ) {
            swooshInClip.current.pause();
            swooshInClip.current.currentTime = 0;
            playAudio(swooshInClip.current);
        } else if (
            contentsTableVisible
            && hasSound
            && swooshOutClip.current
        ) {
            swooshOutClip.current.pause();
            swooshOutClip.current.currentTime = 0;
            playAudio(swooshOutClip.current);
        }

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.toggleContentsTable,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    expanded: !contentsTableVisible,
                },
            });
        }
    };

    /**
     * Adds a new chapter to the contents table
     */
    const onAddChapterButtonMouseDown = (): void => {
        if (!post) return;
        const postsCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.posts
            : FIRESTORE_COLLECTION.stagingPosts;

        const chapterId = uuidv4();
        const newChapterIndex = post.chapters ? post.chapters.length : 0;
        updatePostInDB({
            collection: postsCollection,
            id: post.id,
            value: DELETE_POST_VALUE_TOKEN, // value moved to chapter
            // No need to move Annotations to chapter, because they
            // should be accounted for at the post level too
            chapters: [
                ...(post.chapters || []),
                {
                    id: chapterId,
                    title: '',
                    description: '',
                    published: Date.now(),
                    updated: [],
                    views: [],
                    // Copy annotations to chapter if value is being moved to chapter
                    annotations: post.annotations.length > 0
                        && post.value
                        ? [...post.annotations]
                        : [],
                    // Move existing post value to chapter if it exists
                    value: post.value
                        ? post.value
                        : SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
                },
            ],
        });

        // Move to new chapter
        onChangeSelectedPostValuePath({
            path: [newChapterIndex],
        });

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.addPostChapter,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId: post.id,
                    chapterId,
                },
            });
        }
    };

    /**
     * Manages response to chapter button hovering over
     * @param e mouse event
     */
    const onAddChapterButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.addChapterButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to chapter button no longer hovering over
     * @param e mouse event
     */
    const onAddChapterButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            swooshInClip.current
            && swooshOutClip.current
            && tooltipEnterClip.current
        ) {
            // Swoosh In
            swooshInClip.current.volume = DEFAULT_AUDIO_VOLUME;
            swooshInClip.current.src = SwooshIn;

            // Swoosh Out
            swooshOutClip.current.volume = DEFAULT_AUDIO_VOLUME;
            swooshOutClip.current.src = SwooshOut;

            // Tooltip Enter
            tooltipEnterClip.current.volume = DEFAULT_AUDIO_VOLUME;
            tooltipEnterClip.current.src = TooltipEnter;
        }

        return function cleanup() {
            if (swooshInClip.current) swooshInClip.current.remove();
            if (swooshOutClip.current) swooshOutClip.current.remove();
            if (tooltipEnterClip.current) tooltipEnterClip.current.remove();
        };
    }, []);

    /**
     * Execute queued save
     */
    useEffect(() => {
        if (
            !chaptersSaving
            && !postValueIsSaving
            && queueSave
        ) {
            updateChapters([...queueSave]);
            setQueueSave(null);
        }
    }, [
        chaptersSaving,
        postValueIsSaving,
    ]);

    /**
     * Manages hiding contents table on small viewports
     */
    useEffect(() => {
        if (
            viewportDimensions.width < MEDIA_QUERY_SIZE.large.min
            && contentsTableVisible
        ) setContentsTableVisible(false);
    }, [
        viewportDimensions.width,
    ]);

    /**
     * Manages hiding contents table when no chapters
     */
    useEffect(() => {
        if (
            !write
            && !!post
            && !post.chapters
            && contentsTableVisible
        ) setContentsTableVisible(false);
    }, [
        write,
        post,
        contentsTableVisible,
    ]);

    // ===== Renderers =====

    /**
     * Renderer for Contents Table Content
     */
    const Contents = useMemo(() => {
        if (
            !write
            && contentsTable
            && currentResolutionLevel
        ) {
            return (
                <ContentsTableChaptersContainer>
                    {currentResolutionLevel
                    && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
                    && contentsTable.map((chapter, chapterIndex) => {
                        const selectable = chapter.sections && chapter.sections.length > 0;
                        const chapterSelected = selectedPostValuePath.length > 0
                            && selectedPostValuePath[0] === chapterIndex;
                        const chapterPath = [chapterIndex];
                        const heightExpanded = chapter.sections ? chapter.sections.length * CHAPTER_SECTION_HEIGHT + CHAPTER_HEIGHT_BUFFER : 0;
                        return (
                            <Transition
                                key={chapter.id}
                                in={contentsTableVisible}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (chapterIndex * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + CONTENTS_TABLE_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((contentsTable.length - Math.max(chapterIndex - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(chapterState) => (
                                    <ContentsTableChapter
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'right',
                                                duration: CONTENTS_TABLE_CHAPTER_ENTER_DURATION,
                                                offset: 15,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'right',
                                                offset: 15,
                                                numItems: contentsTable.length,
                                                index: chapterIndex,
                                            })[chapterState],
                                        }}
                                    >
                                        <ContentsTableChapterHead
                                            {...(write
                                                || !chapter.sections
                                                || (
                                                    currentResolutionLevel
                                                    && currentResolutionLevel.level === RESOLUTION_LEVEL.four
                                                )
                                                ? {
                                                    className: HOVER_TARGET_CLASSNAME,
                                                    onMouseEnter: onContentsTableButtonMouseEnter,
                                                    onMouseLeave: onContentsTableButtonMouseLeave,
                                                    ...(detectTouchDevice(document) ? {
                                                        onTouchStart: (e) => {
                                                            const auth = getAuth();
                                                            if (!write && auth.currentUser?.isAnonymous) {
                                                                setNotifyUserToSignUpToNavigateBook(true);

                                                                // Play Sound
                                                                if (hasSound && tooltipEnterClip.current) {
                                                                    tooltipEnterClip.current.pause();
                                                                    tooltipEnterClip.current.currentTime = 0;
                                                                    playAudio(tooltipEnterClip.current);
                                                                }
                                                            } else {
                                                                onChangeSelectedPostValuePath({
                                                                    path: chapterPath,
                                                                    e,
                                                                    expandItems: contentsTable[chapterPath[0]].sections
                                                                        && contentsTable[chapterPath[0]].sections!.length > 0
                                                                        && currentResolutionLevel
                                                                        && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                                                                        && !contentsTable[chapterPath[0]].expanded
                                                                        ? [{
                                                                            chapterIndex,
                                                                            expand: true,
                                                                        }]
                                                                        : [],
                                                                });
                                                            }
                                                        },
                                                    } : {
                                                        onMouseDown: (e) => {
                                                            const auth = getAuth();
                                                            if (!write && auth.currentUser?.isAnonymous) {
                                                                setNotifyUserToSignUpToNavigateBook(true);

                                                                // Play Sound
                                                                if (hasSound && tooltipEnterClip.current) {
                                                                    tooltipEnterClip.current.pause();
                                                                    tooltipEnterClip.current.currentTime = 0;
                                                                    playAudio(tooltipEnterClip.current);
                                                                }
                                                            } else {
                                                                onChangeSelectedPostValuePath({
                                                                    path: chapterPath,
                                                                    e,
                                                                    expandItems: contentsTable[chapterPath[0]].sections
                                                                    && contentsTable[chapterPath[0]].sections!.length > 0
                                                                    && currentResolutionLevel
                                                                    && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                                                                    && !contentsTable[chapterPath[0]].expanded
                                                                        ? [{
                                                                            chapterIndex,
                                                                            expand: true,
                                                                        }]
                                                                        : [],
                                                                });
                                                            }
                                                        },
                                                    }),
                                                }
                                                : {})}
                                            hoverable={write
                                                || !chapter.sections
                                                || (currentResolutionLevel && currentResolutionLevel.level === RESOLUTION_LEVEL.four)}
                                            selected={chapterSelected}
                                            padding={CHAPTER_HEAD_PADDING}
                                            transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                        >
                                            <ContentsTableChapterTitleContainer>
                                                <ContentsTableChapterTitle
                                                    selected={chapterSelected}
                                                    fontMultiplier={CHAPTER_HEAD_TITLE_FONT_MULTIPLIER}
                                                    lineHeight={DEFAULT_LINE_HEIGHT}
                                                    transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                >
                                                    {`${chapterIndex + 1}. ${chapter.title}`}
                                                </ContentsTableChapterTitle>
                                                {selectable
                                                && currentResolutionLevel
                                                && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                                                && (
                                                    <ContentsTableAccordionIcon
                                                        selected={chapterSelected}
                                                        expanded={!!chapter.expanded}
                                                        transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                    >
                                                        <Button
                                                            className={HOVER_TARGET_CLASSNAME}
                                                            type={BUTTON_TYPE.secret}
                                                            background={themeObj.verascopeColor.purple400}
                                                            height={CONTENTS_TABLE_TOGGLE_SECTION_BUTTON_LENGTH}
                                                            width={CONTENTS_TABLE_TOGGLE_SECTION_BUTTON_LENGTH}
                                                            icon={ChevronIcon}
                                                            onMouseEnter={onContentsTableButtonMouseEnter}
                                                            onMouseLeave={onContentsTableButtonMouseLeave}
                                                            {...(detectTouchDevice(document) ? {
                                                                onTouchStart: (e) => onSetExpandContentsTableChapter(
                                                                    [{
                                                                        chapterIndex,
                                                                        expand: !contentsTable[chapterPath[0]].expanded,
                                                                    }],
                                                                    e,
                                                                ),
                                                            } : {
                                                                onMouseDown: (e) => onSetExpandContentsTableChapter(
                                                                    [{
                                                                        chapterIndex,
                                                                        expand: !contentsTable[chapterPath[0]].expanded,
                                                                    }],
                                                                    e,
                                                                ),
                                                            })}
                                                        />
                                                    </ContentsTableAccordionIcon>
                                                )}
                                            </ContentsTableChapterTitleContainer>
                                            <ContentsTableChapterDescription
                                                selected={chapterSelected}
                                                fontMultiplier={CHAPTER_HEAD_DESCRIPTION_FONT_MULTIPLIER}
                                                lineHeight={DEFAULT_LINE_HEIGHT}
                                                transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                            >
                                                {chapter.description}
                                            </ContentsTableChapterDescription>
                                            {chapter.annotationCount > 0
                                                && contentsTableVisible
                                                && (
                                                    <ContentsTableChapterAnnotationContainer>
                                                        <Tooltip
                                                            text="Annotations"
                                                            side={TOOLTIP_TYPE.left}
                                                        />
                                                        <ContentsTableChapterAnnotationIcon
                                                            selected={chapterSelected}
                                                            transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                        >
                                                            <ReactSVG
                                                                src={HighlightIcon}
                                                            />
                                                        </ContentsTableChapterAnnotationIcon>
                                                        <ContentsTableChapterAnnotationText
                                                            selected={chapterSelected}
                                                            transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                        >
                                                            {formatNumber(chapter.annotationCount)}
                                                        </ContentsTableChapterAnnotationText>
                                                    </ContentsTableChapterAnnotationContainer>
                                                )}
                                        </ContentsTableChapterHead>
                                        {currentResolutionLevel
                                        && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                                        && (
                                            <ContentsTableChapterBody
                                                visible={!!chapter.expanded}
                                                heightExpanded={heightExpanded}
                                                transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                transitionDelayDuration={(chapter.sections ? chapter.sections.length * FADE_IN_STAGGER_OFFSET_DURATION : 0)
                                                    + FADE_IN_STAGGER_TRANSITION_DURATION}
                                            >
                                                {chapter.sections && (
                                                    chapter.sections.map((section, sectionIndex) => {
                                                        const sectionSelected = selectedPostValuePath.length === 2
                                                            && selectedPostValuePath[0] === chapterIndex
                                                            && selectedPostValuePath[1] === sectionIndex;
                                                        const sectionPath = [chapterIndex, sectionIndex];
                                                        return (
                                                            <Transition
                                                                key={section.id}
                                                                in={chapter.expanded}
                                                                timeout={{
                                                                    enter: Math.min(
                                                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                                        (sectionIndex * FADE_IN_STAGGER_OFFSET_DURATION),
                                                                    ),
                                                                    exit: ((chapter.sections!.length - Math.max(sectionIndex - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION),
                                                                }}
                                                            >
                                                                {(sectionState) => (
                                                                    <ContentsTableChapterSection
                                                                        className={HOVER_TARGET_CLASSNAME}
                                                                        selected={sectionSelected}
                                                                        transitionDuration={CONTENTS_TABLE_CHAPTER_SECTION_TRANSITION_DURATION}
                                                                        onMouseEnter={onContentsTableButtonMouseEnter}
                                                                        onMouseLeave={onContentsTableButtonMouseLeave}
                                                                        {...(detectTouchDevice(document) ? {
                                                                            onTouchStart: (e) => {
                                                                                const auth = getAuth();
                                                                                if (!write && auth.currentUser?.isAnonymous) {
                                                                                    setNotifyUserToSignUpToNavigateBook(true);

                                                                                    // Play Sound
                                                                                    if (hasSound && tooltipEnterClip.current) {
                                                                                        tooltipEnterClip.current.pause();
                                                                                        tooltipEnterClip.current.currentTime = 0;
                                                                                        playAudio(tooltipEnterClip.current);
                                                                                    }
                                                                                } else {
                                                                                    onChangeSelectedPostValuePath({
                                                                                        path: sectionPath,
                                                                                        e,
                                                                                    });
                                                                                }
                                                                            },
                                                                        } : {
                                                                            onMouseDown: (e) => {
                                                                                const auth = getAuth();
                                                                                if (!write && auth.currentUser?.isAnonymous) {
                                                                                    setNotifyUserToSignUpToNavigateBook(true);

                                                                                    // Play Sound
                                                                                    if (hasSound && tooltipEnterClip.current) {
                                                                                        tooltipEnterClip.current.pause();
                                                                                        tooltipEnterClip.current.currentTime = 0;
                                                                                        playAudio(tooltipEnterClip.current);
                                                                                    }
                                                                                } else {
                                                                                    onChangeSelectedPostValuePath({
                                                                                        path: sectionPath,
                                                                                        e,
                                                                                    });
                                                                                }
                                                                            },
                                                                        })}
                                                                        style={{
                                                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                                                direction: 'right',
                                                                                duration: CONTENTS_TABLE_CHAPTER_SECTION_TRANSITION_DURATION,
                                                                                offset: 10,
                                                                            }),
                                                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                                                direction: 'right',
                                                                                offset: 10,
                                                                                numItems: chapter.sections!.length,
                                                                                index: sectionIndex,
                                                                            })[sectionState],
                                                                        }}
                                                                    >
                                                                        <ContentsTableChapterSectionText
                                                                            selected={sectionSelected}
                                                                            fontMultiplier={CHAPTER_SECTION_FONT_MULTIPLIER}
                                                                            lineHeight={DEFAULT_LINE_HEIGHT}
                                                                            padding={CHAPTER_SECTION_PADDING}
                                                                            transitionDuration={CONTENTS_TABLE_CHAPTER_SECTION_TEXT_TRANSITION_DURATION}
                                                                        >
                                                                            {section.title}
                                                                        </ContentsTableChapterSectionText>
                                                                        <ContentsTableChapterSectionLine
                                                                            selected={sectionSelected}
                                                                            visible={section.annotationCount > 0}
                                                                        />
                                                                        {section.annotationCount > 0
                                                                        && contentsTableVisible
                                                                        && (
                                                                            <ContentsTableChapterSectionAnnotationContainer>
                                                                                <Tooltip
                                                                                    text="Annotations"
                                                                                    side={TOOLTIP_TYPE.left}
                                                                                />
                                                                                <ContentsTableChapterSectionAnnotationIcon
                                                                                    selected={sectionSelected}
                                                                                    transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                                                >
                                                                                    <ReactSVG
                                                                                        src={HighlightIcon}
                                                                                    />
                                                                                </ContentsTableChapterSectionAnnotationIcon>
                                                                                <ContentsTableChapterSectionAnnotationText
                                                                                    selected={sectionSelected}
                                                                                    transitionDuration={CONTENTS_TABLE_CHAPTER_TRANSITION_DURATION}
                                                                                >
                                                                                    {formatNumber(section.annotationCount)}
                                                                                </ContentsTableChapterSectionAnnotationText>
                                                                            </ContentsTableChapterSectionAnnotationContainer>
                                                                        )}
                                                                    </ContentsTableChapterSection>
                                                                )}
                                                            </Transition>
                                                        );
                                                    })
                                                )}
                                            </ContentsTableChapterBody>
                                        )}
                                    </ContentsTableChapter>
                                )}
                            </Transition>
                        );
                    })}
                </ContentsTableChaptersContainer>
            );
        }

        return (
            <ContentsTableOutlineContainer
                ref={drop}
            >
                {currentResolutionLevel
                && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
                && post
                && post?.chapters
                && post.chapters.map((chapter, chapterIndex) => (
                    <ReaderOutlineChapter
                        key={chapter.id}
                        hasSound={hasSound}
                        user={user}
                        postId={post.id}
                        index={chapterIndex}
                        chapter={chapter}
                        currentSessionId={currentSessionId}
                        currentResolutionLevel={currentResolutionLevel}
                        chapters={post.chapters!}
                        postValueIsSaving={postValueIsSaving}
                        updateChapters={updateChapters}
                        setCursorSigns={setCursorSigns}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setSnackbarData={setSnackbarData}
                        selectedPostValuePath={selectedPostValuePath}
                        onChangeSelectedPostValuePath={onChangeSelectedPostValuePath}
                    />
                ))}
                {currentResolutionLevel
                && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
                && (
                    <AddChapterButton
                        className={HOVER_TARGET_CLASSNAME}
                        transitionDuration={ADD_CHAPTER_BUTTON_TRANSITION_DURATION}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: onAddChapterButtonMouseDown,
                        } : {
                            onMouseDown: onAddChapterButtonMouseDown,
                        })}
                        onMouseEnter={onAddChapterButtonEnter}
                        onMouseLeave={onAddChapterButtonLeave}
                    >
                        <Tooltip
                            text="Add Chapter"
                            side={TOOLTIP_TYPE.right}
                        />
                        <AddChapterButtonIcon>
                            <ReactSVG
                                src={PlusIcon}
                            />
                        </AddChapterButtonIcon>
                    </AddChapterButton>
                )}
            </ContentsTableOutlineContainer>
        );
    }, [
        write,
        post,
        contentsTable,
        postValueIsSaving,
        selectedPostValuePath,
        contentsTableVisible,
        currentResolutionLevel,
    ]);

    return (
        <>
            <Transition
                in={
                    !hide
                    && !!currentResolutionLevel
                    && currentResolutionLevel.level > RESOLUTION_LEVEL.three
                    && (
                        write
                        || (!write && !!contentsTable && contentsTable.length > 0)
                        || (!write && !!post && !post.chapters)
                    )
                }
                timeout={{
                    enter: POST_TITLE_ENTER_DURATION
                        + POST_SUBTITLE_ENTER_DURATION
                        + POST_THUMBNAIL_ENTER_DURATION
                        + POST_METADATA_ENTER_DURATION,
                    exit: 0,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(tableState) => (
                    <ContentsTable
                        ref={contentsTableRef}
                        contentsTableVisible={contentsTableVisible}
                        widthExpanded={CONTENTS_TABLE_WIDTH_EXPANDED}
                        widthContracted={CONTENTS_TABLE_WIDTH_CONTRACTED}
                        margin={CONTENTS_TABLE_MARGIN}
                        heightExpanded={viewportDimensions.height - POST_BANNER_HEIGHT}
                        topExpanded={CONTENTS_TABLE_TOP_EXPANDED}
                        transitionDuration={CONTENTS_TABLE_ENTER_TRANSIITON_DURATION}
                        showWarning={hasError}
                        notifyUserToSignUpToNavigateBook={notifyUserToSignUpToNavigateBook}
                        onScroll={onContentsTableScroll}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'right',
                                offset: POST_BANNER_LEFT_CONTAINER_WIDTH + POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT,
                                duration: CONTENTS_TABLE_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'right',
                                offset: POST_BANNER_LEFT_CONTAINER_WIDTH + POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT,
                            })[tableState],
                        }}
                    >
                        {!write && (
                            <ContentsTableProgressBarContainer
                                ref={contentsTableProgressBarContainerRef}
                            >
                                <ContentsTableProgressBar
                                    ref={contentsTableProgressBarRef}
                                    contentsTableVisible={contentsTableVisible}
                                    transitionDuration={CONTENTS_TABLE_PROGRESS_BAR_TRANSITION_DURATION}
                                />
                            </ContentsTableProgressBarContainer>
                        )}
                        {contentsTableVisible
                        && (
                            <ContentsTableTitle
                                fontMultiplier={CONTENTS_TABLE_TITLE_FONT_MULTIPLIER}
                                lineHeight={DEFAULT_LINE_HEIGHT}
                                paddingTop={CONTENTS_TABLE_TITLE_PADDING_TOP}
                            >
                                {write ? 'Outline' : 'Contents'}
                            </ContentsTableTitle>
                        )}
                        {Contents}
                    </ContentsTable>
                )}
            </Transition>
            <ContentsTableToggleButtonContainer
                ref={contentsTableToggleButtonContainerRef}
                isVisible={
                    !hide
                    && !!currentResolutionLevel
                    && currentResolutionLevel.level > RESOLUTION_LEVEL.three
                    && (write || (!write && !!contentsTable && contentsTable.length > 0)
                    )
                }
                topExpanded={viewportDimensions.width > MEDIA_QUERY_SIZE.small.min
                    ? POST_BANNER_HEIGHT + CONTENTS_TABLE_MARGIN
                    : POST_BANNER_HEIGHT + CONTENTS_TABLE_MARGIN + CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_Y_OFFSET_SMALL_VIEWPORT}
                leftExpanded={POST_BANNER_LEFT_CONTAINER_WIDTH
                    - POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT
                    + CONTENTS_TABLE_MARGIN
                    + CONTENTS_TABLE_TOGGLE_BUTTON_MARGIN}
                leftContracted={
                    CONTENTS_TABLE_MARGIN
                    + CONTENTS_TABLE_WIDTH_CONTRACTED
                    + CONTENTS_TABLE_TOGGLE_BUTTON_MARGIN
                }
                height={CONTENTS_TABLE_TOGGLE_BUTTON_LENGTH}
                tableVisible={contentsTableVisible}
                notifyUserToSignUpToNavigateBook={notifyUserToSignUpToNavigateBook}
                transitionDuration={CONTENTS_TABLE_ENTER_TRANSIITON_DURATION}
            >
                <Button
                    className={HOVER_TARGET_CLASSNAME}
                    type={BUTTON_TYPE.solid}
                    background={themeObj.verascopeColor.purple400}
                    height={CONTENTS_TABLE_TOGGLE_BUTTON_LENGTH}
                    width={CONTENTS_TABLE_TOGGLE_BUTTON_LENGTH}
                    icon={ChevronIcon}
                    onMouseEnter={onContentsTableButtonMouseEnter}
                    onMouseLeave={onContentsTableButtonMouseLeave}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: (e) => onToggleContentsTable(e),
                    } : {
                        onMouseDown: (e) => onToggleContentsTable(e),
                    })}
                />
            </ContentsTableToggleButtonContainer>
        </>
    );
}

export default ReaderContentsTableOutliner;
