// ===== Packages =====

import React, {
    useMemo,
}                                       from 'react';
import { v4 as uuidv4 }                 from 'uuid';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
}                                       from '../../enums';

// ===== Interfaces =====

import {
    IRadioItem,
}                                       from '../../interfaces';

// ===== Services =====

import {
    detectTouchDevice,
}                                       from '../../services';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    CustomRadioButton,
    Detail,
    Input,
    InputContainer,
    Title,
    Subtitle,
    Label,
    Annotation,
}                                       from './styles';

interface Props {
    items: IRadioItem[],
    selected: IRadioItem | undefined,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    onChange: (value: IRadioItem) => void,
}
function PixelRadioInput({
    selected,
    items,
    onCursorEnter,
    onCursorLeave,
    onChange,
}: Props): JSX.Element {
    // ===== Methods =====

    const onInputEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.radioInput,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onInputLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onInputChange = (item: IRadioItem, e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!selected || selected.value !== item.value) {
            onChange(item);
        }
    };

    // ===== Memoization =====

    const radioInputId = useMemo(() => (
        uuidv4()
    ), []);
    const subtitleItemCount = useMemo(() => (
        items.reduce((count: number, item: IRadioItem) => {
            if (item.subtitle) return count + 1;
            return count;
        }, 0)
    ), [items]);

    return (
        <Container
            subtitleItemCount={subtitleItemCount}
            itemCount={items.length}
        >
            {items.map((item: IRadioItem, index: number) => {
                const interactionProperties: Record<string, string | (
                    (e: React.TouchEvent) => void) | ((e: React.MouseEvent) => void
                )> = (!selected || selected.value !== item.value)
                    ? {
                        className: HOVER_TARGET_CLASSNAME,
                    } : {};

                if ((!selected || selected.value !== item.value) && detectTouchDevice(document)) {
                    interactionProperties.onTouchStart = (e: React.TouchEvent) => onInputEnter(e);
                    interactionProperties.onTouchEnd = (e: React.TouchEvent) => onInputLeave(e);
                } else if ((!selected || selected.value !== item.value) && !detectTouchDevice(document)) {
                    interactionProperties.onMouseEnter = (e: React.TouchEvent) => onInputEnter(e);
                    interactionProperties.onMouseLeave = (e: React.TouchEvent) => onInputLeave(e);
                }

                const subtitlePriorItemCount = items.reduce((count: number, itm: IRadioItem, idx: number) => {
                    if (idx < index && itm.subtitle) return count + 1;
                    return count;
                }, 0);
                return (
                    <InputContainer
                        key={item.value}
                        index={index}
                        withSubtitle={!!item.subtitle}
                        subtitlePriorItemCount={subtitlePriorItemCount}
                        selected={selected && selected.value === item.value}
                        {...(interactionProperties)}
                    >
                        <Input
                            type="radio"
                            id={item.title}
                            checked={selected && selected.value === item.value}
                            name={`radio-input-${radioInputId}`}
                            onChange={(e) => onInputChange(item, e)}
                        />
                        <CustomRadioButton />
                        <Label>
                            <Title
                                htmlFor={`radio-input-${radioInputId}`}
                            >
                                {item.title}
                                {item.annotation
                                && (
                                    <Annotation>
                                        {` ${item.annotation}`}
                                    </Annotation>
                                )}
                            </Title>
                            {item.subtitle
                            && (
                                <Subtitle>
                                    {item.subtitle}
                                </Subtitle>
                            )}
                        </Label>
                        {item.detail && (
                            <Detail>
                                {item.detail}
                            </Detail>
                        )}
                    </InputContainer>
                );
            })}
        </Container>
    );
}

export default PixelRadioInput;
