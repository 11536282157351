// ===== Constants =====

import { WEBPAGE_TITLE } from '../constants/generalConstants';

export const updatePageTitle = (
    page: string | null = null,
    section: string | null = null,
    notifCount = 0,
): void => {
    document.title = `${notifCount > 0
        ? `(${notifCount}) `
        : ''
    }${WEBPAGE_TITLE}${page
        ? `: ${page}`
        : ''
    }${section
        ? ` - ${section}`
        : ''
    }`;
};

export default updatePageTitle;
