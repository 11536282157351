// ===== Packages =====

import {
    Node,
    Element,
}                   from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
}                   from '.';

const implementsLineInterface = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'isLine' in node
    && !!node.isLine;

export default {
    implementsLineInterface,
};
