// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import {
    useFocused,
    useSelected,
}                                               from 'slate-react';
import YouTube, { Options }                     from 'react-youtube';

// ===== Services =====

import {
    getYouTubeParams,
    setColorLightness,
    getEmbeddingDimensions,
    recordUserAction,
}                                               from '../../../services';

// ===== Interfaces =====

import {
    IUserItem,
}                                               from '../../../interfaces';

// ===== Enums =====

import {
    EDITOR_CONTEXT_TYPE,
    USER_ACTION_TYPE,
}                                               from '../../../enums';

// ===== Hooks =====

import { useEventListener }                     from '../../../hooks';

// ===== Constants =====

import EMBEDDING_DIMENSION                      from '../../../constants/embeddingDimensions';
import {
    POST_EDITOR_MAX_WIDTH,
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
}                                               from '../../../constants/generalConstants';

// <YouTube
//   videoId={string}                  // defaults -> null
//   id={string}                       // defaults -> null
//   className={string}                // defaults -> null
//   containerClassName={string}       // defaults -> ''
//   opts={obj}                        // defaults -> {}
//   onReady={func}                    // defaults -> noop
//   onPlay={func}                     // defaults -> noop
//   onPause={func}                    // defaults -> noop
//   onEnd={func}                      // defaults -> noop
//   onError={func}                    // defaults -> noop
//   onStateChange={func}              // defaults -> noop
//   onPlaybackRateChange={func}       // defaults -> noop
//   onPlaybackQualityChange={func}    // defaults -> noop
// />

// Example: https://www.youtube.com/watch?v=nM9f0W2KD5s

YouTubeEmbed.defaultProps = {
    onLoad: undefined,
};
interface Props {
    id: string,
    url: string,
    color: string,
    user: IUserItem | null,
    currentSessionId: string | null,
    postId: string | undefined,
    editorType: EDITOR_CONTEXT_TYPE,
    readOnly: boolean,
    children: React.ReactElement,
    attributes: any,
    onLoad?: () => void,
}
function YouTubeEmbed({
    id,
    url,
    color,
    user,
    currentSessionId,
    postId,
    editorType,
    readOnly,
    children,
    attributes,
    onLoad,
}: Props): JSX.Element {
    // ===== Refs =====

    const embedRef = useRef<HTMLDivElement | null>(null);

    // ===== State =====

    const [youtubeId, setYoutubeId] = useState<string>('');
    const [youTubeStart, setYouTubeStart] = useState<number | null>(null);
    const [youTubeEnd, setYouTubeEnd] = useState<number | null>(null);
    const [options, setOptions] = useState<Options>({
        width: EMBEDDING_DIMENSION[480].width.toString(),
        height: EMBEDDING_DIMENSION[480].height.toString(),
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0, // 1 = autoplay 0 = no autoplay
        },
    });
    const focused = useFocused();
    const selected = useSelected();

    // ===== Side Effects =====

    // Get ID, Start Time, and End Time from URL
    useEffect(() => {
        const ytParams = getYouTubeParams(url);

        if (ytParams) {
            if (ytParams.id) setYoutubeId(ytParams.id);
            if (ytParams.start) setYouTubeStart(ytParams.start);
            if (ytParams.end) setYouTubeEnd(ytParams.end);
            determineOptions();
        }
    }, [url]);

    // On Mount, determine dimensions
    useEffect(() => {
        determineOptions();
    }, [
        embedRef.current,
        youTubeStart,
        youTubeEnd,
    ]);

    // On resize, determine dimensions
    useEventListener(
        'resize',
        () => {
            determineOptions();
        },
    );

    // ===== Methods =====

    const determineOptions = (): void => {
        if (embedRef.current) {
            const parentWidth = embedRef.current.parentElement!.clientWidth;
            const currentDimension = getEmbeddingDimensions(parentWidth);
            let width: string;
            let height: string;
            if (parentWidth > POST_EDITOR_MAX_WIDTH) {
                width = POST_EDITOR_MAX_WIDTH.toString();
                height = ((9 / 16) * POST_EDITOR_MAX_WIDTH).toString();
            } else if (EMBEDDING_DIMENSION[240].width > parentWidth) {
                width = (parentWidth - 40).toString();
                height = ((9 / 16) * parentWidth).toString();
            } else {
                width = EMBEDDING_DIMENSION[currentDimension].width.toString();
                height = EMBEDDING_DIMENSION[currentDimension].height.toString();
            }

            const updatedOptions: Options = {
                width,
                height,
                playerVars: {
                    autoplay: 0,
                },
            };
            if (youTubeStart) updatedOptions.playerVars!.start = youTubeStart;
            if (youTubeEnd) updatedOptions.playerVars!.end = youTubeEnd;
            setOptions(updatedOptions);
        }
    };

    const onPlay = async (): Promise<void> => {
        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.playYouTubeVideo,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    postId,
                    editorType,
                },
            });
        }
    };

    const onPause = async (): Promise<void> => {
        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.pauseYouTubeVideo,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    postId,
                    editorType,
                },
            });
        }
    };

    const onEnd = async (): Promise<void> => {
        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.endYoutubeVideo,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    postId,
                    editorType,
                },
            });
        }
    };

    return (
        <Container
            {...attributes}
            ref={embedRef}
            color={color}
            focused={focused}
            selected={selected}
        >
            {youtubeId.length > 0
            && (
                <EmbedContainer
                    // Necessary to avoid: Cannot resolve a Slate point from DOM point
                    // Reference: https://github.com/ianstormtaylor/slate/issues/3930
                    contentEditable={false}
                >
                    <YouTube
                        videoId={youtubeId}
                        opts={options}
                        onReady={onLoad}
                        onPlay={onPlay}
                        onPause={onPause}
                        onEnd={onEnd}
                    />
                </EmbedContainer>
            )}
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    selected: boolean,
    focused: boolean,
    color: string,
}
const Container = styled.div<ContainerProps>`
    margin: 20px 0;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    & iframe {
    border: ${({
        selected,
        focused,
        color,
        theme,
    }) => (selected && focused
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        }`
        : 'none'
    )};
        border-radius: 5px;
    }
`;

const EmbedContainer = styled.div``;

export default YouTubeEmbed;
