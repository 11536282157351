// ===== Styles =====

import { theme } from '../themes/theme-context';

const fontColorDiscriminator = (color: string): string => {
    const fontColor = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
    const r = parseInt(fontColor.substring(0, 2), 16); // hexToR
    const g = parseInt(fontColor.substring(2, 4), 16); // hexToG
    const b = parseInt(fontColor.substring(4, 6), 16); // hexToB

    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 160)
        ? theme.color.neutral900
        : theme.color.white;
};

export default fontColorDiscriminator;
