// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeGetCustomerPaymentMethods = async ({
    test,
    customerId,
}: {
    test: boolean,
    customerId: string,
}): Promise<Stripe.PaymentMethod[]> => {
    const functions = getFunctions();
    const getPaymentMethods = httpsCallable<
        Record<
            string,
            boolean | string
        >,
        Stripe.PaymentMethod[]
    >(
        functions,
        'stripeGetCustomerPaymentMethods',
    );

    return getPaymentMethods({
        test,
        customerId,
    }).then((result) => {
        const paymentMethods = result.data as Stripe.PaymentMethod[];
        return paymentMethods;
    }).catch((err) => err);
};

export default stripeGetCustomerPaymentMethods;
