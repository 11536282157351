// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                       from 'react';
import {
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
}                                       from '../../interfaces';

// ===== Images =====

import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
}                                       from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    currentSessionId: string | null,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function PhysicalBookView({
    user,
    currentSessionId,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== State =====

    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.physicalBook,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    /**
     * Records user action of viewing physical book page
     */
    const recordPhysicalBookPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewPhysicalBookPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'Physical Book',
        );
    }, []);

    /**
     * Records View Physical Book Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordPhysicalBookPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    return (
        <Container>
            <PageLogo
                dark
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
                color={theme.verascopeColor.purple200}
            >
                PHYSICAL BOOK
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={theme.verascopeColor.purple400}
            >
                Prefer your books in print?
            </PageSubtitle>
        </Container>
    );
}

export default PhysicalBookView;
