// ===== Packages =====

import {
    Node,
    Element,
}                                   from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    ResolutionLevelOptions,
    ResolutionLevelNode,
}                                   from '../interfaces';
import LineInterface                from '../interfaces/lineInterface';

// ===== Enums =====

import {
    RESOLUTION_LEVEL,
    RESOLUTION_LEVEL_TYPE,
}                                   from './enums';

const BLOCK_RESOLUTION_LEVEL_TYPE = 'resolution-level';

const isValidResolutionLevel = (level: RESOLUTION_LEVEL): boolean => Object.values(RESOLUTION_LEVEL).includes(level);

const isResolutionLevelElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_RESOLUTION_LEVEL_TYPE;

const isInlineResolutionLevel = (node: EditorNode | Node): boolean => isResolutionLevelElement(node)
    && 'level' in node
    && 'levelType' in node
    && (node as ResolutionLevelNode).levelType === RESOLUTION_LEVEL_TYPE.inline;

const isBlockResolutionLevel = (node: EditorNode | Node): boolean => isResolutionLevelElement(node)
    && 'level' in node
    && 'levelType' in node
    && (node as ResolutionLevelNode).levelType === RESOLUTION_LEVEL_TYPE.block;

const newResolutionLevelElement = (options: ResolutionLevelOptions): ResolutionLevelNode => ({
    type: BLOCK_RESOLUTION_LEVEL_TYPE,
    isLine: options.levelType === RESOLUTION_LEVEL_TYPE.block,
    level: options.level || RESOLUTION_LEVEL.five,
    levelType: options.levelType || RESOLUTION_LEVEL_TYPE.block,
    children: [{ text: '' }],
});

export default {
    RESOLUTION_LEVEL,
    BLOCK_RESOLUTION_LEVEL_TYPE,
    TYPE: BLOCK_RESOLUTION_LEVEL_TYPE,
    isResolutionLevelElement,
    ...LineInterface,
    newResolutionLevelElement,
    isValidResolutionLevel,
    isInlineResolutionLevel,
    isBlockResolutionLevel,
};
