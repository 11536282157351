// ===== Enums =====

import {
    FILTER_TYPE,
    FILTER_TYPE_PATH_KEY,
} from '../enums';

const getFilterType = (pathKey: FILTER_TYPE_PATH_KEY): FILTER_TYPE => {
    let type: FILTER_TYPE;

    if (pathKey === FILTER_TYPE_PATH_KEY.theme) {
        type = FILTER_TYPE.theme;
    } else if (pathKey === FILTER_TYPE_PATH_KEY.type) {
        type = FILTER_TYPE.type;
    } else {
        type = FILTER_TYPE.author;
    }

    return type;
};

export default getFilterType;
