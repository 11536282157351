// ===== Packages =====

import React, {
    useRef,
    useEffect,
    useMemo,
}                                       from 'react';
import {
    useParams,
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';

// ===== Enums =====

import {
    PAGE_ROUTE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    playAudio,
    recordUserAction,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IDimension,
    IBreadcrumbItem,
}                                       from '../../interfaces';

// ===== Sounds =====

import BreadcrumbClickClip              from '../../sounds/button_click.mp3';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    DEFAULT_AUDIO_VOLUME,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import MEDIA_QUERY_SIZE                 from '../../constants/mediaQuerySizes';

// ===== Styles =====

import {
    Container,
    BreadcrumbItemContainer,
    BreadcrumbItem,
    ChevronContainer,
}                                       from './styles';

interface Props {
    lightBackground: boolean,
    fontFamily: string,
    items: IBreadcrumbItem[],
    chevronSrc: string,
    activeColor: string,
    interactableColor: string,
    user: IUserItem | null,
    hasSound: boolean,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function Breadcrumb({
    lightBackground,
    fontFamily,
    items,
    chevronSrc,
    activeColor,
    interactableColor,
    user,
    hasSound,
    currentSessionId,
    viewportDimensions,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // ===== Refs =====

    // ----- Sound Clips

    const breadcrumbClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== Methods =====

    const onButtonEnter = (e: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.breadcrumbButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onButtonLeave = (e: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        onCursorLeave(e);
    };

    const onButtonClick = async (route: string, e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickBreadcrumbButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: location.pathname,
                },
            });
        }

        // Play Sound
        if (
            hasSound
            && breadcrumbClickClip.current
        ) {
            breadcrumbClickClip.current.pause();
            breadcrumbClickClip.current.currentTime = 0;
            playAudio(breadcrumbClickClip.current);
        }

        navigate(
            `/${route}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (breadcrumbClickClip.current) {
            // Breadcrumb Click
            breadcrumbClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            breadcrumbClickClip.current.src = BreadcrumbClickClip;
        }

        return function cleanup() {
            if (breadcrumbClickClip.current) breadcrumbClickClip.current.remove();
        };
    }, []);

    // ===== Memoization =====

    const activeItemIndex = useMemo(() => {
        for (let i = 0; i < items.length; i += 1) {
            const item = items[i];
            if (item.route === `${PAGE_ROUTE.checkout}/${params.checkpoint}`) {
                return i;
            }
        }

        return -1;
    }, [items, params.checkpoint]);

    return (
        <Container>
            {items.map((item: IBreadcrumbItem, index: number) => {
                const interactionProperties: Record<string, string | (
                    (e: React.TouchEvent) => void) | ((e: React.MouseEvent) => void
                )> = (index < activeItemIndex || !!item.interactable)
                    ? {
                        className: HOVER_TARGET_CLASSNAME,
                    } : {};

                if ((index < activeItemIndex || !!item.interactable) && detectTouchDevice(document)) {
                    interactionProperties.onTouchStart = (e: React.TouchEvent) => onButtonEnter(e);
                    interactionProperties.onTouchEnd = (e: React.TouchEvent) => onButtonLeave(e);
                } else if ((index < activeItemIndex || !!item.interactable) && !detectTouchDevice(document)) {
                    interactionProperties.onMouseEnter = (e: React.TouchEvent) => onButtonEnter(e);
                    interactionProperties.onMouseLeave = (e: React.TouchEvent) => onButtonLeave(e);
                }
                return (
                    <BreadcrumbItemContainer>
                        <BreadcrumbItem
                            key={item.text}
                            index={index}
                            isActive={activeItemIndex === index}
                            isInteractable={index < activeItemIndex || !!item.interactable}
                            fontFamily={fontFamily}
                            activeColor={activeColor}
                            interactableColor={interactableColor}
                            detectTouchDevice={detectTouchDevice(document)}
                            {...(interactionProperties)}
                            onMouseDown={(e) => onButtonClick(item.route, e)}
                        >
                            {viewportDimensions.width < MEDIA_QUERY_SIZE.small.min && item.abbrText
                                ? item.abbrText
                                : item.text}
                        </BreadcrumbItem>
                        {index !== items.length - 1
                        && (
                            <ChevronContainer
                                lightBackground={lightBackground}
                            >
                                <ReactSVG
                                    src={chevronSrc}
                                />
                            </ChevronContainer>
                        )}
                    </BreadcrumbItemContainer>
                );
            })}
        </Container>
    );
}

export default Breadcrumb;
