// ===== Packages =====

import {
    Node,
    Element,
}                       from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    BottomBufferNode,
}                       from '../interfaces';

const BLOCK_BOTTOM_BUFFER_TYPE = 'bottom-buffer';

const isBottomBufferElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_BOTTOM_BUFFER_TYPE;

const newBottomBufferElement = (): BottomBufferNode => ({
    type: BLOCK_BOTTOM_BUFFER_TYPE,
    children: [{ text: '' }],
});

export default {
    TYPE: BLOCK_BOTTOM_BUFFER_TYPE,
    isBottomBufferElement,
    newBottomBufferElement,
};
