// ===== Packages =====

import styled                       from 'styled-components';

// ===== Services =====

import {
    hexToRgb,
    setColorLightness,
}                                   from '../../services';

// ===== Constants =====

import {
    PROGRESS_BAR_LIGHTNESS_VALUE,
    PIVOT_BUTTON_HEIGHT,
    PIVOT_BUTTON_MARGIN,
    PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT,
    PIVOT_BUTTON_WIDTH,
    PIVOT_BUTTON_X_OFFSET,
    PIVOT_BUTTON_HORIZONTAL_PADDING,
    PIVOT_BUTTON_VERTICAL_PADDING,
    PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT,
    TAGS_CONTAINER_WIDTH,
    TAGS_CONTAINER_PADDING,
    AUTHOR_TAGS_CONTAINER_WIDTH,
    TAGS_CONTAINER_PADDING_SMALL_VIEWPORT,
    FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

interface ContainerProps {
    tagsVisible: boolean,
    transitionDuration: number,
}
export const Container = styled.div<ContainerProps>`
    position: fixed;
    left: 15px;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    border-radius: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px;
    z-index: 2;
    transition: ${({ transitionDuration, theme }) => `
        border-radius ${transitionDuration}ms ${theme.motion.overshoot},
        padding-bottom ${transitionDuration}ms ${theme.motion.overshoot}
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        left: auto;
        right: 15px;
        align-items: flex-end;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column-reverse;
        top: 0px;
        bottom: auto;
        left: 0px;
        background: ${({ theme }) => theme.verascopeColor.purple100};
        width: 100vw;
        border-radius: ${({ tagsVisible }) => `${(
        (2 * PIVOT_BUTTON_MARGIN)
        + (tagsVisible ? 0 : FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT)
        + PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT
    ) / 2}px`};
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        padding-bottom: ${({ tagsVisible }) => `${tagsVisible
        ? 0
        : FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT
    }px`};
    }
`;

interface PivotProps {
    hasInitialized: boolean,
    pivotExpanding: boolean,
    pivotContracting: boolean,
    pivotExpanded: boolean,
    tagsVisible: boolean,
    pivotButtonCount: number,
    expandDuration: number,
    transitionDuration: number,
    exitDelayDuration: number,
    tagsContainerExitDelayDuration: number,
}
export const Pivot = styled.div<PivotProps>`
    ${({ hasInitialized }) => (!hasInitialized
        ? `
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        `
        : `
            display: flex;
            flex-direction: row;
        `
    )};
    position: relative;
    width: ${({
        pivotExpanded,
        tagsVisible,
        pivotButtonCount,
    }) => `${pivotExpanded
        ? `${tagsVisible
            ? '100%'
            : `${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) + pivotButtonCount * (PIVOT_BUTTON_WIDTH + PIVOT_BUTTON_MARGIN)}px`
        }`
        : `${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT)}px`}`
};
    height: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT)}px;
    padding: ${PIVOT_BUTTON_MARGIN}px;
    background: ${({ theme }) => theme.color.white};
    border-radius: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px;
    transition: ${({
        theme,
        pivotExpanding,
        pivotContracting,
        pivotExpanded,
        tagsContainerExitDelayDuration,
        tagsVisible,
        expandDuration,
        transitionDuration,
        exitDelayDuration,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing},
        width ${expandDuration}ms ${
    (pivotExpanded && tagsVisible)
    || (!tagsVisible && (!pivotExpanded || (pivotExpanded && pivotExpanding)))
        ? `${pivotContracting ? exitDelayDuration : 0}`
        : `${!tagsVisible ? 0 : tagsContainerExitDelayDuration}`
}ms ${theme.motion.overshoot}
    `};
    overflow: hidden;

    ${({ theme }) => theme.mediaQuery.small} {
        ${({ hasInitialized }) => (!hasInitialized
        ? `
            flex-direction: row-reverse;
        `
        : `
            flex-direction: row-reverse;
        `
    )};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${({
        pivotButtonCount,
    }) => `${pivotButtonCount * (PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT + PIVOT_BUTTON_MARGIN)}px`};
        top: ${`${FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT / 2}px`};
        background: ${({ theme }) => theme.verascopeColor.purple100};
        box-shadow: none;
        height: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT)}px;
        margin: 0 auto;
    }
`;

interface SelectorExpanderProps {
    expanded: boolean,
    expandDuration: number,
    transitionDuration: number,
}
export const SelectorExpander = styled.button<SelectorExpanderProps>`
    position: relative;
    width: ${PIVOT_BUTTON_HEIGHT}px;
    height: ${PIVOT_BUTTON_HEIGHT}px;
    border-radius: ${PIVOT_BUTTON_HEIGHT / 2}px;
    background: ${({ expanded, theme }) => (expanded
        ? theme.verascopeColor.blue100
        : 'transparent'
    )};
    border: none;
    outline: none;
    margin-right: ${({ expanded }) => `${expanded ? 2.5 : 0}px`};
    z-index: 1;
    transition: ${({
        theme,
        transitionDuration,
        expandDuration,
    }) => `
        background ${transitionDuration}ms ${theme.motion.eagerEasing},
        margin-right ${expandDuration}ms ${theme.motion.eagerEasing}
    `};
    cursor: none;

    &:hover {
        background: ${({ theme, expanded }) => (expanded
        ? theme.verascopeColor.blue100
        : `rgba(
            ${hexToRgb(theme.color.neutral300)!.r},
            ${hexToRgb(theme.color.neutral300)!.g},
            ${hexToRgb(theme.color.neutral300)!.b},
            0.6
        )`
    )};

        & path {
            fill: ${({ theme }) => theme.color.white};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        margin-right: 0;
        margin-left: ${({ expanded }) => `${expanded ? 2.5 : 0}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        display: none;
    }
`;

interface SelectorExpanderIconProps {
    expanded: boolean,
    transitionDuration: number,
}
export const SelectorExpanderIcon = styled.svg<SelectorExpanderIconProps>`
    position: relative;
    top: 3px;
    height: 16px;
    width: 16px;
    fill: ${({ expanded, theme }) => (expanded
        ? theme.color.white
        : theme.color.neutral700
    )};
    transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};

    & path {
        transition: ${({
        theme,
        transitionDuration,
    }) => `fill ${transitionDuration}ms ${theme.motion.standardEasing}`};
    }
`;

interface PivotButtonProps {
    index: number,
    visible: boolean,
    selected: boolean,
    isMobile: boolean,
    buttonCount: number,
    expandDuration: number,
    hoverDuration: number,
    staggerOffsetDuration: number,
    enterDelayDuration: number,
    fadeInOffset: number
}
export const PivotButton = styled.button<PivotButtonProps>`
    position: absolute;
    top: ${PIVOT_BUTTON_MARGIN}px;
    left: ${({ index }) => `${PIVOT_BUTTON_X_OFFSET + index * (PIVOT_BUTTON_WIDTH + PIVOT_BUTTON_MARGIN)}px`};
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: ${PIVOT_BUTTON_WIDTH}px;
    height: ${PIVOT_BUTTON_HEIGHT}px;
    border-radius: ${PIVOT_BUTTON_HEIGHT / 2}px;
    padding: ${`${PIVOT_BUTTON_HORIZONTAL_PADDING}px ${PIVOT_BUTTON_VERTICAL_PADDING}px`};
    margin: ${`0px ${PIVOT_BUTTON_MARGIN / 2}px`}; /* Half so each neighboring buttons contribute to total */
    background: ${({ isMobile, theme }) => (isMobile
        ? `rgba(
            ${hexToRgb(theme.verascopeColor.blue100)!.r},
            ${hexToRgb(theme.verascopeColor.blue100)!.g},
            ${hexToRgb(theme.verascopeColor.blue100)!.b},
            0.1
        )`
        : 'transparent'
    )};
    transform: ${({
        visible,
        fadeInOffset,
    }) => (visible
        ? 'translateX(0px)'
        : `translateX(-${fadeInOffset}px)`
    )};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    border: none;
    outline: none;
    cursor: none;
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
    // To put in front of ProgressBarContainer
    z-index: 2;
    transition: ${({
        theme,
        index,
        visible,
        buttonCount,
        staggerOffsetDuration,
        enterDelayDuration,
        expandDuration,
        hoverDuration,
    }) => `
        background ${hoverDuration}ms ${theme.motion.standardEasing},
        transform ${expandDuration}ms ${visible
    ? enterDelayDuration + (index * staggerOffsetDuration)
    : (buttonCount - index - 1) * staggerOffsetDuration
}ms ${theme.motion.eagerEasing},
        opacity ${expandDuration}ms ${visible
    ? enterDelayDuration + (index * staggerOffsetDuration)
    : (buttonCount - index - 1) * staggerOffsetDuration
}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        background: ${({ theme, selected }) => (selected
        ? 'transparent'
        : `rgba(
            ${hexToRgb(theme.color.neutral300)!.r},
            ${hexToRgb(theme.color.neutral300)!.g},
            ${hexToRgb(theme.color.neutral300)!.b},
            0.6
        )`
    )};

        & h3 {
            color: ${({ theme }) => theme.color.neutral900};
        }

        & h4 {
            color: ${({ theme }) => theme.color.neutral700};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        left: auto;
        right: ${({ index }) => `${PIVOT_BUTTON_X_OFFSET + index * (PIVOT_BUTTON_WIDTH + PIVOT_BUTTON_MARGIN)}px`};
        transform: ${({
        visible,
        fadeInOffset,
    }) => (visible
        ? 'translateX(0px)'
        : `translateX(${fadeInOffset}px)`
    )};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        left: ${({
        index,
    }) => `${index * (PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT + PIVOT_BUTTON_MARGIN)}px`};
        width: ${PIVOT_BUTTON_WIDTH_SMALL_VIEWPORT}px;
        height: ${PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT}px;
        padding: ${`${PIVOT_BUTTON_HORIZONTAL_PADDING / 2}px ${PIVOT_BUTTON_VERTICAL_PADDING / 2}px`};

        &:hover {
            background: ${({ theme, selected }) => (selected
        ? 'transparent'
        : `rgba(
                ${hexToRgb(theme.verascopeColor.blue200)!.r},
                ${hexToRgb(theme.verascopeColor.blue200)!.g},
                ${hexToRgb(theme.verascopeColor.blue200)!.b},
                0.2
            )`
    )};

            & h3 {
                color: ${({ theme }) => theme.color.white};
            }

            & h4 {
                color: ${({ theme }) => theme.color.neutral300};
            }
        }
    }
`;

interface PivotButtonTextProps {
    selected: boolean,
    transitionDuration: number,
}
export const PivotButtonText = styled.h3<PivotButtonTextProps>`
    position: relative;
    top: 2px;
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: ${({ selected, theme }) => (selected
        ? `${theme.color.white} !important`
        : theme.color.neutral800
    )};
    transition: ${({
        theme,
        transitionDuration,
    }) => `background ${transitionDuration}ms ${theme.motion.standardEasing}`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        color: ${({ theme }) => theme.color.white};
    }
`;

interface ProgressBarContainerProps {
    tagsVisible: boolean,
    pivotExpanding: boolean,
    pivotExpanded: boolean,
    pivotContracting: boolean,
    pivotButtonCount: number,
    expandDuration: number,
    pivotExitDelayDuration: number,
    tagsContainerExitDelayDuration: number,
}
export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${({
        pivotExpanded,
        tagsVisible,
        pivotButtonCount,
    }) => `${pivotExpanded
        ? `${tagsVisible
            ? '100%'
            : `${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) + pivotButtonCount * (PIVOT_BUTTON_WIDTH + PIVOT_BUTTON_MARGIN)}px`
        }`
        : `${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT)}px`}`
};
    height: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT)}px;
    border-radius: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px;
    border-top-left-radius: ${({ tagsVisible }) => (tagsVisible
        ? '0px'
        : `${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px`
    )};
    border-top-right-radius: ${({ tagsVisible }) => (tagsVisible
        ? '0px'
        : `${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px`
    )};
    overflow: hidden;
    // To put in front of Pivot's white background
    // , which allows custom cursor to generate dark ring around buttons)
    z-index: 1;
    transition: ${({
        theme,
        tagsContainerExitDelayDuration,
        tagsVisible,
        expandDuration,
        pivotExpanded,
        pivotExpanding,
        pivotContracting,
        pivotExitDelayDuration,
    }) => `
        border-top-right-radius ${tagsVisible
        ? 0
        : expandDuration
}ms ${tagsVisible
    ? 0
    : tagsContainerExitDelayDuration
}ms ${theme.motion.standardEasing},
        border-top-left-radius ${tagsVisible
        ? 0
        : expandDuration
}ms ${tagsVisible
    ? 0
    : tagsContainerExitDelayDuration
}ms ${theme.motion.standardEasing},
        width ${tagsVisible
        ? 0
        : expandDuration
}ms ${tagsVisible
    || (!tagsVisible && (!pivotExpanded || (pivotExpanded && pivotExpanding)))
    ? `${pivotContracting ? pivotExitDelayDuration : 0}`
    : tagsContainerExitDelayDuration
}ms ${pivotExpanding || pivotContracting
    ? theme.motion.overshoot
    : theme.motion.standardEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        bottom: auto;
        top: 0px;
        width: 100vw;
        height: ${({ tagsVisible }) => `${
        PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT
        + (tagsVisible ? 0 : FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT)
        + (2 * PIVOT_BUTTON_MARGIN)
    }px`};
        border-radius: ${({ tagsVisible }) => `${(
        (2 * PIVOT_BUTTON_MARGIN)
        + (tagsVisible ? 0 : FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT)
        + PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT
    ) / 2}px`};
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: ${({ tagsVisible }) => (tagsVisible
        ? '0px'
        : `${((2 * PIVOT_BUTTON_MARGIN) + FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT + PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT) / 2}px`
    )};
    border-bottom-right-radius: ${({ tagsVisible }) => (tagsVisible
        ? '0px'
        : `${((2 * PIVOT_BUTTON_MARGIN) + FILTER_SELECTOR_PADDING_BOTTOM_SMALL_VIEWPORT + PIVOT_BUTTON_HEIGHT_SMALL_VIEWPORT) / 2}px`
    )};
    }
`;

interface ProgressBarProps {
    isFiltering: boolean,
    progress: number,
    transitionDuration: number,
}
export const ProgressBar = styled.div<ProgressBarProps>`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: ${({ isFiltering, progress }) => (isFiltering
        ? `${progress}%`
        : '100%'
    )};
    opacity: ${({ isFiltering }) => (isFiltering ? 1 : 0)};
    background-color: ${({ theme }) => setColorLightness(
        theme.verascopeColor.blue100,
        PROGRESS_BAR_LIGHTNESS_VALUE,
    )};
    transition: ${({ theme, transitionDuration }) => `
        width ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.small} {
        right: auto;
        left: 0;
    }       

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: auto;
        left: 0;
        height: 100%;
        background-color: ${({ theme }) => theme.verascopeColor.purple300};
    }
`;

interface ProgressBarTextContainerProps {
    visible: boolean,
    margin: number,
    opacityDuration: number,
}
export const ProgressBarTextContainer = styled.div<ProgressBarTextContainerProps>`
    position: absolute;
    bottom: 0px;
    right: 0;
    transform: ${({ margin }) => `translateX(calc(100% + ${margin}px))`};
    margin-left: ${({ margin }) => `${margin}px`};
    padding: 10px;
    background: ${({ theme }) => `rgba(${
        hexToRgb(theme.verascopeColor.purple200)!.r
    }, ${
        hexToRgb(theme.verascopeColor.purple200)!.g
    }, ${
        hexToRgb(theme.verascopeColor.purple200)!.b
    }, 0.95)`};
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: ${({ theme, opacityDuration }) => `
        opacity ${opacityDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.small} {
        right: auto;
        left: 0;
        transform: ${({ margin }) => `translateX(calc(-100% - ${margin}px))`};
        margin-left: 0;
        margin-right: ${({ margin }) => `${margin}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        position: fixed;
        bottom: 5px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 0px;
        text-align: center;
    }
`;

interface ProgressBarPercentageTextProps {
    progress: number,
    transitionDuration: number,
}
/* Animating Numbers: https://css-tricks.com/animating-number-counters/ */
export const ProgressBarPercentageText = styled.div<ProgressBarPercentageTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
    line-height: 1em;
    margin-bottom: 5px;
    --cartridgeFilterProgressPercentage: ${({ progress }) => progress};
    counter-reset: cartridgeFilterProgressPercentage var(--cartridgeFilterProgressPercentage);
    transition: ${({ transitionDuration }) => `--cartridgeFilterProgressPercentage ${transitionDuration}ms`};

    &::after {
        content: counter(cartridgeFilterProgressPercentage)"%";
    }
`;

export const ProgressBarCartridgeCount = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral300};
    line-height: 1em;
    white-space: nowrap;;
    margin: 0;
`;

interface TagsContainerProps {
    expanded: boolean,
    expandedHeight: number,
    authorTagsVisible: boolean,
    exitDelayDuration: number,
    transitionDuration: number,
}
export const TagsContainer = styled.div<TagsContainerProps>`
    width: ${({ expanded, authorTagsVisible }) => {
        if (expanded && authorTagsVisible) {
            return `${AUTHOR_TAGS_CONTAINER_WIDTH}px`;
        }

        if (expanded) {
            return `${TAGS_CONTAINER_WIDTH}px`;
        }

        return '0px';
    }};
    max-width: 100vw;
    height: ${({ expanded, expandedHeight }) => `${expanded ? expandedHeight : 0}px`};
    border-top-left-radius: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px;
    border-top-right-radius: ${((2 * PIVOT_BUTTON_MARGIN) + PIVOT_BUTTON_HEIGHT) / 2}px;
    padding: ${({ expanded }) => `${expanded ? TAGS_CONTAINER_PADDING : 0}px`};
    padding-bottom: 0;
    transition: ${({
        theme,
        expanded,
        exitDelayDuration,
        transitionDuration,
    }) => `
        height ${transitionDuration}ms ${!expanded ? exitDelayDuration : 0}ms ${theme.motion.overshoot},
        width ${expanded ? 0 : transitionDuration}ms ${expanded ? 0 : exitDelayDuration}ms ${theme.motion.standardEasing},
        padding ${expanded ? 0 : transitionDuration}ms ${expanded ? 0 : exitDelayDuration}ms ${theme.motion.standardEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: ${({ expanded }) => (expanded ? '100%' : '0px')};
        padding: ${({ expanded }) => `${expanded ? TAGS_CONTAINER_PADDING_SMALL_VIEWPORT : 0}px`};
        padding-bottom: 0;
    }
`;

export const TagsList = styled.div``;

interface FilterContainerProps {
    visible: boolean,
    topMargin: number,
    transitionDuration: number,
}
export const FilterContainer = styled.div<FilterContainerProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: ${({ topMargin }) => `translateY(calc(-100% - ${topMargin}px))`};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    padding-left: 5px;

    ${({ theme }) => theme.mediaQuery.small} {
        /* https://stackoverflow.com/questions/27382777/set-parent-width-equal-to-children-total-width-using-only-css */
        max-width: fit-content;
        width: auto;
        left: auto;
        right: 0;
        padding-left: 0px;
        padding-right: 5px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: auto;
        bottom: ${({ topMargin }) => `-${topMargin}px`};
        transform: translateY(100%);
    }
`;
