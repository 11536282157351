// Reference: https://stackoverflow.com/questions/5008609/vimeo-video-link-regex
const VIMEO_URL_REGEX = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

const getVimeoId = (url: string): string | null => {
    const match = url ? url.match(VIMEO_URL_REGEX) : false;
    const id = (match && match[4] && match[4].length === 9)
        ? match[4]
        : null;

    return id;
};

export default getVimeoId;
