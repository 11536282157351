// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
}                                   from '../../constants/generalConstants';

interface ContainerProps {
    lightBackground?: boolean,
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    background: ${({ theme }) => theme.color.white};

    ${({ theme }) => theme.mediaQuery.small} {
        height: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        height: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
        justify-content: center;
    }
`;

export const BreadcrumbItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface BreadcrumbItemProps {
    index: number,
    isActive: boolean,
    isInteractable: boolean,
    fontFamily: string,
    activeColor: string,
    interactableColor: string,
    detectTouchDevice: boolean,
}
export const BreadcrumbItem = styled.button<BreadcrumbItemProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    margin: 0 15px;
    ${({ index }) => index === 0
        && 'margin-left: 0px;'
};
    font-family: ${({ fontFamily }) => fontFamily};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({
        isInteractable,
        isActive,
        activeColor,
        interactableColor,
        theme,
    }) => {
        if (isActive) {
            return activeColor;
        }

        if (isInteractable) {
            return interactableColor;
        }

        return theme.verascopeColor.purple100;
    }};
    text-transform: uppercase;
    background: ${({ theme }) => theme.color.white};

    &:hover {
        ${({
        detectTouchDevice,
        isInteractable,
        theme,
    }) => (!detectTouchDevice && isInteractable && `
        color: ${theme.verascopeColor.yellow200};
        transform: scale(1.2);
    `)}
    }

    ${({ theme }) => theme.mediaQuery.small} {
        margin: 0 10px;
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin: 0 10px;
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface ChevronContainerProps {
    lightBackground?: boolean,
}
export const ChevronContainer = styled.div<ChevronContainerProps>`
    width: 20px;
    height: 20px;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 80%;
        height: 80%;
    }

    & path, & rect {
        fill: ${({ lightBackground, theme }) => (lightBackground
        ? theme.verascopeColor.purple100
        : theme.verascopeColor.white
    )};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        width: 10px;
        height: 10px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: 10px;
        height: 10px;
    }
`;
