// ===== Packages =====

import {
    Node,
    Element,
}                       from 'slate';

// ===== Services =====

import { normalizeURL } from '../../../services';

// ===== Interfaces =====

import {
    EditorNode,
    WebLinkNode,
}                       from '../interfaces';

const INLINE_WEB_LINK_TYPE = 'web-link';

const isWebLinkElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === INLINE_WEB_LINK_TYPE;

const newWebLinkElement = (id: string, href: string): WebLinkNode => {
    const data: WebLinkNode = {
        id,
        type: INLINE_WEB_LINK_TYPE,
        children: [{ text: '' }],
        href,
    };

    return data;
};

export default {
    TYPE: INLINE_WEB_LINK_TYPE,
    isWebLinkElement,
    newWebLinkElement,
    normalizeURL,
};
