// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    DETAIL_IMAGE_CONTAINER_WIDTH,
    DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH,
    DETAIL_INFO_BANNER_PADDING_BOTTOM,
    DETAIL_CONTENT_CONTAINER_PADDING_BOTTOM_SMALL_VIEWPORT,
    SPINNER_HEIGHT_LARGE,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

// ===== Services =====

import { fontColorDiscriminator }   from '../../services';

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
`;

interface CartridgeContainerProps {
    width: number,
    showWarning: boolean,
    isSteering: boolean,
    transitionDuration: number,
}
export const CartridgeContainer = styled.div<CartridgeContainerProps>`
    position: relative;
    width: ${({ width }) => `${width}px`};
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ showWarning, theme }) => (showWarning
        ? theme.verascopeColor.red200
        : theme.verascopeColor.blue200
    )};
    transition: ${({
        theme,
        isSteering,
        transitionDuration,
    }) => `background ${transitionDuration}ms ${theme.motion.eagerEasing}${isSteering
        ? ''
        : `, width ${transitionDuration}ms ${theme.motion.overshoot}`}`};
    
    ${({ theme }) => theme.mediaQuery.small} {
        width: 100vw;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: 100vw;
    }
`;

interface CartridgeInsertedProps {
    width: number,
    isVisible: boolean,
}
export const CartridgeInserted = styled.img<CartridgeInsertedProps>`
    position: absolute;
    top: 44px;
    left: -110px;
    height: ${({ width }) => `${width}px`};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};

    ${({ theme }) => theme.mediaQuery.small} {
        height: auto;
        width: ${({ width }) => `${width}px`};
        top: 0px;
        left: 50%;
        transform: translate(-50%, calc(-100% + 50px));
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        height: auto;
        width: ${({ width }) => `${width}px`};
        top: 0px;
        left: 50%;
        transform: translate(-50%, calc(-100% + 50px));
    }
`;

export const CartridgeDetailContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

interface DetailInfoBannerProps {
    leftMargin: number,
}
export const DetailInfoBanner = styled.div<DetailInfoBannerProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    background: linear-gradient(to top, rgba(32, 125, 140, 0) 0%, rgba(32, 125, 140, 0.2) 40%, rgba(32, 125, 140, 0.3) 60%, rgba(32, 125, 140, 1));
    margin-left: ${({ leftMargin }) => `${leftMargin}px`};
    padding-top: 50px;
    padding-bottom: ${DETAIL_INFO_BANNER_PADDING_BOTTOM}px;

    ${({ theme }) => theme.mediaQuery.small} {
        flex-direction: column;
        width: 100vw;
        margin-left: 0px;
        padding-top: 20px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column;
        width: 100vw;
        margin-left: 0px;
        padding-top: 20px;
    }
`;

interface DetailImageContainerProps {
    padding: number,
    width: number | null,
}
export const DetailImageContainer = styled.div<DetailImageContainerProps>`
    padding: ${({ padding }) => `0px ${padding}px`};
    max-width: ${`${DETAIL_IMAGE_CONTAINER_WIDTH}px`};
    width: ${({ width }) => (width
        ? `${width}px`
        : 'auto'
    )};

    ${({ theme }) => theme.mediaQuery.small} {
        margin: 0 auto;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin: 0 auto;
    }
`;

export const DetailTextContainer = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    ${({ theme }) => theme.mediaQuery.small} {
        align-items: center;
        width: 100%;
        margin-top: 40px;
        padding: 0px 20px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        align-items: center;
        width: 100%;
        margin-top: 40px;
        padding: 0px 20px;
    }
`;

interface DetailImageProps {
    maxWidth: number,
    maxHeight: number,
}
export const DetailImage = styled.img<DetailImageProps>`
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
    border-radius: 2.5px;

    ${({ theme }) => theme.mediaQuery.small} {
        box-shadow: ${({ theme }) => theme.color.boxShadow400};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        box-shadow: ${({ theme }) => theme.color.boxShadow400};
    }
`;

interface DetailTitleProps {
    fontMultiplier: number,
}
export const DetailTitle = styled.h1<DetailTitleProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 900;
    color: ${({ theme }) => theme.verascopeColor.white};
    margin: 0;
    margin-bottom: 10px;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${({ fontMultiplier }) => `${0.7 * fontMultiplier}em`};
        text-align: center;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${({ fontMultiplier }) => `${0.7 * fontMultiplier}em`};
        text-align: center;
    }
`;

interface DetailAuthorsProps {
    fontMultiplier: number,
}
export const DetailAuthors = styled.h3<DetailAuthorsProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 600;
    color: ${({ theme }) => theme.color.neutral600};
    margin: 0;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${({ fontMultiplier }) => `${0.8 * fontMultiplier}em`};
        text-align: center;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${({ fontMultiplier }) => `${0.8 * fontMultiplier}em`};
        text-align: center;
    }
`;

interface DetailTypeProps {
    fontMultiplier: number,
}
export const DetailType = styled.h4<DetailTypeProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 600;
    color: ${({ theme }) => theme.verascopeColor.white};
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 5px;
`;

interface ThemeLabelProps {
    fontMultiplier: number,
}
export const ThemeLabel = styled.p<ThemeLabelProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral700};
    margin: 0;
    margin-top: 30px;
    margin-bottom: 5px;
`;

interface DetailContentContainerProps {
    height: string,
}
export const DetailContentContainer = styled.div<DetailContentContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ height }) => height};
    min-height: ${`${SPINNER_HEIGHT_LARGE}px`};
    padding: 0px 25px;

    ${({ theme }) => theme.mediaQuery.small} {
        padding-bottom: ${`${DETAIL_CONTENT_CONTAINER_PADDING_BOTTOM_SMALL_VIEWPORT}px`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-bottom: ${`${DETAIL_CONTENT_CONTAINER_PADDING_BOTTOM_SMALL_VIEWPORT}px`};
    }
`;

export const EjectButtonContainer = styled.div`
    background: ${({ theme }) => theme.color.white};
`;

interface EjectButtonProps {
    transitionDuration: number,
}
export const EjectButton = styled.button<EjectButtonProps>`
    position: absolute;
    top: 225px;
    left: -49px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${({ theme }) => theme.verascopeColor.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    outline: none;
    border: none;
    cursor: none;
    user-select: none;
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        transform: scale(1.2) !important; /* We use important because of Transition which hijacks this property */
    }

    ${({ theme }) => theme.mediaQuery.small} {
        top: 0px;
        left: auto;
        right: 20px;
        z-index: 1;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        top: 0px;
        left: auto;
        right: 20px;
        z-index: 1;
    }
`;

export const EjectButtonIcon = styled.svg`
    width: 20px;
    height: 20px;

    & > path {
        fill: #C5CDD1;
    }
`;

export const LinkMetadataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 60px 20px;
    overflow-y: scroll;
`;

export const LinkMetadataImage = styled.img`
    max-height: 300px;
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
    border-radius: 7.5px;
    margin-bottom: 40px;
`;

export const LinkMetadataImagePlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    padding: calc((150px - 35px) / 2);
    background-color: ${({ theme }) => theme.color.neutral400};
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
    border-radius: 7.5px;
    margin-bottom: 40px;
`;

export const LinkMetadataImagePlaceholderIcon = styled.img`
    width: 35px;
    height: 35px;
`;

export const LinkMetadataTitle = styled.h2`
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 1.5em;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    margin-bottom: 25px;
    transition: color 0.3s;
`;

export const LinkMetadataDescription = styled.p`
    width: 100%;
    max-width: 55ch;
    margin: 0;
    margin-bottom: 25px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 1em;
    color: ${({ theme }) => theme.color.neutral500};
    line-height: 1.5em;
`;

export const LinkMetadataURL = styled.p`
    margin: 0;
    margin-top: 10px;
    padding-top: 5px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1em;
    word-break: break-all;
    color: ${({ theme }) => theme.color.neutral800};
`;

interface LinkButtonProps {
    color: string,
    transitionDuration: number,
}
export const LinkButton = styled.a<LinkButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 50px;
    border-radius: 30px;
    background: ${({ color }) => color};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    padding: 15px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 1.1em;
    color: ${({ color }) => fontColorDiscriminator(color)};
    text-decoration: none;
    outline: none;
    border: none;
    cursor: none;
    user-select: none;
    transition: ${({
        theme,
        transitionDuration,
    }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
        transform: scale(1.2) !important; /* We use important because of Transition which hijacks this property */
    }
`;

export const TagsContainer = styled.div`
    ${({ theme }) => theme.mediaQuery.small} {
        text-align: center;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        text-align: center;
    }
`;

interface GridCellProps {
    isVisible: boolean,
    isInserting: boolean,
    isEjecting: boolean,
    isForegrounded: boolean,
    focusedZIndex: number,
    enterDuration: number,
    insertEjectDuration: number,
}
export const GridCell = styled.div<GridCellProps>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: ${({ isVisible }) => `translateY(${isVisible ? 0 : -100}px)`};
    z-index: ${({
        isInserting,
        isEjecting,
        isForegrounded,
        focusedZIndex,
    }) => (isInserting || isEjecting || isForegrounded ? focusedZIndex : 'auto')};
    transition: ${({
        theme,
        enterDuration,
        isInserting,
        isEjecting,
        insertEjectDuration,
    }) => `
    ${isInserting || isEjecting
        ? `top ${insertEjectDuration}ms ${theme.motion.eagerEasing}, left ${insertEjectDuration}ms ${theme.motion.eagerEasing},`
        : ''
}
        opacity ${enterDuration}ms ${theme.motion.delayEasing},
        transform ${enterDuration}ms ${theme.motion.delayEasing}
    `};
`;

export const SpinnerContainer = styled.div`
    position: relative;
    top: ${-DETAIL_INFO_BANNER_PADDING_BOTTOM}px;
    left: ${DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH}px;
`;
