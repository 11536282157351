// ===== Packages =====

import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
}                                           from 'react';
import { Transition }                       from 'react-transition-group';
import {
    Link,
    useLocation,
    useSearchParams,
}                                           from 'react-router-dom';
import {
    ref,
    getStorage,
    getDownloadURL,
}                                           from 'firebase/storage';

// ===== Hooks =====

import {
    useTimeout,
    useInterval,
}                                           from '../../hooks';

// ===== Services =====

import {
    playAudio,
    getFilterPath,
    getFilterTypePathKey,
    tagIDToHumanID,
}                                           from '../../services';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    CARTRIDGE_WIDTH,
    DEFAULT_CSS_TRANSITION_DURATION,
    TAG_TRANSITION_DURATION,
    CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING,
    MORE_TAGS_TEXT_WIDTH,
    HOVER_TARGET_CLASSNAME,
}                                           from '../../constants/generalConstants';
import CARTRIDGE_TYPE                       from '../../constants/cartridgeType';
import CURSOR_SIGN                          from '../../constants/cursorSigns';
import ID_TO_ITEM                           from '../../constants/IDToItem';
import MEDIA_QUERY_SIZE                     from '../../constants/mediaQuerySizes';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    CARTRIDGE_STATE,
    FILTER_TYPE,
}                                           from '../../enums';

// ===== Interfaces =====

import {
    ICoord,
    IDimension,
    ICartridgeItem,
    ITag,
    IVirtualizedGridCell,
}                                           from '../../interfaces';

// ===== Styles =====

import { theme }                            from '../../themes/theme-context';
import {
    Container,
    CartridgeImage,
    CartridgeFace,
    CartridgeImageClippingMask,
    CartridgeDetailContainer,
    CartridgeType,
    CartridgeTitle,
    CartridgeAuthors,
    TagsContainer,
    CartridgeTitleContainer,
    TitleGradient,
    MoreTagsText,
}                                           from './styles';
import {
    PlaceholderBox,
    Tag,
    TagText,
}                                           from '../../styles';

interface Props {
    data: ICartridgeItem,
    cell: IVirtualizedGridCell,
    cartridgeContainerWidth: number,
    filters: Map<FILTER_TYPE, Map<string, ITag>>,
    insertCartridge: (data: ICartridgeItem, cell: IVirtualizedGridCell) => void,
    isInserting: boolean,
    isEjecting: boolean,
    isForegrounded: boolean,
    cartridgeInserted: boolean,
    hasSound: boolean,
    viewportDimensions: IDimension,
    cartridgeThudClip: React.MutableRefObject<HTMLAudioElement>,
    cartridgeSlideClip: React.MutableRefObject<HTMLAudioElement>,
    cartridgeInsertClip: React.MutableRefObject<HTMLAudioElement>,
    cartridgeEjectClip: React.MutableRefObject<HTMLAudioElement>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    onMouseEnter: (id: string) => void,
    onMouseLeave: (id: string) => void,
    triggerFilterAudio: () => void,
}
function Cartridge({
    data,
    cell,
    cartridgeContainerWidth,
    filters,
    isInserting,
    isEjecting,
    isForegrounded,
    cartridgeInserted,
    insertCartridge,
    hasSound,
    viewportDimensions,
    cartridgeThudClip,
    cartridgeSlideClip,
    cartridgeInsertClip,
    cartridgeEjectClip,
    onCursorEnter,
    onCursorLeave,
    onMouseEnter,
    onMouseLeave,
    triggerFilterAudio,
}: Props): JSX.Element {
    // ===== General Constants =====

    const CARTRIDGE_TYPE_FONT_MULTIPLIER = 0.7;
    const CARTRIDGE_TITLE_FONT_MULTIPLIER = 1.2;
    const CARTRIDGE_AUTHORS_FONT_MULTIPLIER = 0.875;
    const DETAIL_CONTAINER_MARGIN = 10;
    const TAG_HEIGHT = 24;
    const TAG_HEIGHT_SMALL_VIEWPORT = 20;
    const DEFAULT_TAG_COUNT = 3;
    const DETAIL_TITLE_SCROLL_BUFFER_LENGTH = 2 * CARTRIDGE_DETAIL_CONTAINER_SIDE_PADDING;
    const DETAIL_TYPE_PLACEHOLDER_HEIGHT = 40;
    const DETAIL_TITLE_PLACEHOLDER_HEIGHT = 120;
    const DETAIL_AUTHORS_PLACEHOLDER_HEIGHT = 60;

    // ===== Refs =====

    // ----- Cartridges

    const cartridgeRef = useRef<HTMLDivElement>(null);
    const detailContainerRef = useRef<HTMLDivElement>(null);
    const detailTitleRef = useRef<HTMLHeadingElement>(null);
    const tagsContainerRef = useRef<HTMLDivElement>(null);

    // ===== React Router =====

    const location = useLocation();
    const [searchParams] = useSearchParams();

    // ===== State =====

    // stores width of title
    const [titleWidth, setTitleWidth] = useState<number | null>(null);
    // stores the tags we should show on small viewport
    const [visibleTags, setVisibleTags] = useState<Map<string, number>>(new Map());
    // indicates whether title should be scrolled
    const [scrollTitle, setScrollTitle] = useState<boolean>(true);
    // stores whether image should be visible
    const [imageVisible, setImageVisible] = useState<boolean>(false);
    // stores path to cartridge image
    const [imagePath, setImagePath] = useState<string | null>(null);
    // stores url to cartridge image
    const [imageURL, setImageURL] = useState<string | null>(null);
    // stores whether we've rendered component before
    // used to prevent shaking when !isEjecting in initial render
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    // indicates whether the cursor is over the cartridge
    const [isHovered, setIsHovered] = useState<boolean>(false);
    // indicates whether catridge should be shaking
    const [isShaking, setIsShaking] = useState<boolean>(false);
    // stores detail container offset
    const [detailContainerOffset, setDetailContainerOffset] = useState<ICoord>({
        x: 0,
        y: 0,
    });

    // ===== Animation Constants =====

    const CARTRIDGE_SHAKE_DURATION = 400;
    const CARTRIDGE_TRANSFORM_DURATION = DEFAULT_CSS_TRANSITION_DURATION;
    const CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION = 200;
    const REVEAL_DETAIL_DELAY_DURATION = 300;
    const CARTRIDGE_IMAGE_TRANSITION_DURATION = 300;
    const IS_HOVERED_INTERVAL_DURATION = 1000;
    const HIDE_DETAIL_CONTAINER_DURATION = 500;

    // ===== Methods =====

    const onCartridgeCursorEnter = (e: React.MouseEvent): void => {
        const object = CURSOR_TARGET.cartridge;
        const actions = [CURSOR_SIGN.click];

        if (viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min) {
            // Cancel hide detail container timeout
            clearTimeoutHideDetailContainer();

            // Inform parent to bring it to foreground
            // i.e. increase z-index
            onMouseEnter(data.id);
        }

        onCursorEnter(
            object,
            actions,
            e.target as HTMLElement,
        );

        if (viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min) {
            // Delay before showing detail container
            clearTimeoutRevealDetail();
            timeoutRevealDetail();
        }
    };

    const onCartridgeCursorLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);

        if (viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min) {
            clearTimeoutHideDetailContainer();
            timeoutHideDetailContainer();
        }
    };

    const onCartridgeClick = (): void => {
        if (data && cartridgeRef.current) {
            insertCartridge(data, cell);

            // Play Sound
            if (hasSound && cartridgeSlideClip.current) {
                cartridgeSlideClip.current.pause();
                // eslint-disable-next-line no-param-reassign
                cartridgeSlideClip.current.currentTime = 0;
                playAudio(cartridgeSlideClip.current);
            }
        }
    };

    const onImageLoad = (): void => {
        setImageVisible(true);
    };

    const computeDetailContainerOffset = (): void => {
        if (
            cartridgeRef.current
            && detailContainerRef.current
            && isHovered
        ) {
            const detailContainerRect: DOMRect = detailContainerRef.current.getBoundingClientRect();
            const cartridgeRect: DOMRect = cartridgeRef.current.getBoundingClientRect();

            let xOffset = 0;
            if (detailContainerRect.left < DETAIL_CONTAINER_MARGIN) {
                xOffset = DETAIL_CONTAINER_MARGIN - detailContainerRect.left;
            } else if (Math.floor(detailContainerRect.right) > cartridgeContainerWidth - DETAIL_CONTAINER_MARGIN) {
                xOffset = (cartridgeContainerWidth - DETAIL_CONTAINER_MARGIN) - detailContainerRect.right;
            }

            let yOffset = 0;
            if (detailContainerRect.top < DETAIL_CONTAINER_MARGIN) {
                yOffset = DETAIL_CONTAINER_MARGIN - detailContainerRect.top;
            } else if (detailContainerRect.bottom > viewportDimensions.height - DETAIL_CONTAINER_MARGIN) {
                yOffset = cartridgeRect.top - detailContainerRect.bottom - DETAIL_CONTAINER_MARGIN;
            }

            if (xOffset !== 0 || yOffset !== 0) {
                setDetailContainerOffset({
                    x: xOffset,
                    y: yOffset,
                });
            }
        }
    };

    const computeDetailTitleWidth = (): void => {
        if (
            detailTitleRef.current
            && (
                isHovered
                || viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
            )
        ) {
            const detailTitleRect: DOMRect = detailTitleRef.current.getBoundingClientRect();
            if (detailTitleRect.width > CARTRIDGE_WIDTH - DETAIL_TITLE_SCROLL_BUFFER_LENGTH) {
                setTitleWidth(detailTitleRect.width);
            } else if (scrollTitle) {
                setScrollTitle(false);
            }
        }
    };

    const computeDetailVisibleTags = useCallback((): void => {
        if (
            tagsContainerRef.current
            && viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
        ) {
            const tags = tagsContainerRef.current.children;

            // sort tags by width so we show the shortest ones first
            const sortedTags: Element[] = Array.from(tags);
            sortedTags.sort((a, b): number => {
                const aRect = a.getBoundingClientRect();
                const bRect = b.getBoundingClientRect();
                if (aRect.width > bRect.width) { return 1; }
                if (aRect.width < bRect.width) { return -1; }
                return 0;
            });

            let cumulativeWidth = 0;
            const newVisibleTags: Map<string, number> = new Map();
            for (let i = 0; i < sortedTags.length; i += 1) {
                const tag = sortedTags[i];
                const rect = tag.getBoundingClientRect();
                cumulativeWidth += rect.width;
                const id = tag.firstElementChild?.id; // there is an anchor tag between tag container and tag component
                if (id && cumulativeWidth < CARTRIDGE_WIDTH - MORE_TAGS_TEXT_WIDTH) {
                    newVisibleTags.set(id, i);
                } else {
                    break;
                }
            }

            // We want to show at least one tag
            if (newVisibleTags.size === 0) {
                newVisibleTags.set(sortedTags[0].id, 0);
            }

            setVisibleTags(newVisibleTags);
        }
    }, [
        viewportDimensions,
        tagsContainerRef,
    ]);

    const hideDetailContainer = (): void => {
        // inform parent
        onMouseLeave(data.id);

        clearTimeoutRevealDetail();
        setIsHovered(false);
    };

    // ===== Side Effects =====

    useEffect(() => {
        if (imagePath !== data.imagePath.sm) {
            setImagePath(data.imagePath.sm);
            const storage = getStorage();
            getDownloadURL(ref(storage, data.imagePath.sm)).then((imgURL) => {
                setImageURL(imgURL);
            });
        }
    }, [data]);

    // sometimes we move too fast for the component to recognize we aren't hovering anymore
    // check for such cases and remove hover
    // Reference: https://stackoverflow.com/questions/14795099/pure-javascript-to-check-if-something-has-hover-without-setting-on-mouseover-ou
    useInterval(() => {
        if (!cartridgeRef.current) throw Error('[ERROR] Cartridge Reference not found.');
        const hovered = cartridgeRef.current.matches(':hover');
        if (isHovered && !hovered) {
            setIsHovered(false);
        }
    }, isHovered ? IS_HOVERED_INTERVAL_DURATION : null);

    useEffect(() => {
        if (!isEjecting) {
            if (isInitialRender) {
                setIsInitialRender(false);
            } else {
                // Play Shake Animation
                setIsShaking(true);
                clearTimeoutStopCartridgeShake();
                timeoutStopCartridgeShake();

                // Play Sound
                if (hasSound && cartridgeThudClip.current) {
                    cartridgeThudClip.current.pause();
                    // eslint-disable-next-line no-param-reassign
                    cartridgeThudClip.current.currentTime = 0;
                    playAudio(cartridgeThudClip.current);
                }
            }
        } else if (
            isEjecting
            && hasSound
            && cartridgeEjectClip.current
            && cartridgeSlideClip.current
        ) {
            cartridgeEjectClip.current.pause();
            // eslint-disable-next-line no-param-reassign
            cartridgeEjectClip.current.currentTime = 0;
            playAudio(cartridgeEjectClip.current);

            cartridgeSlideClip.current.pause();
            // eslint-disable-next-line no-param-reassign
            cartridgeSlideClip.current.currentTime = 0;
            playAudio(cartridgeSlideClip.current);
        }
    }, [isEjecting]);

    useEffect(() => {
        // Play Sound
        if (
            cartridgeInserted
            && hasSound
            && cartridgeInsertClip.current
        ) {
            cartridgeInsertClip.current.pause();
            // eslint-disable-next-line no-param-reassign
            cartridgeInsertClip.current.currentTime = 0;
            playAudio(cartridgeInsertClip.current);
        }
    }, [cartridgeInserted]);

    useEffect(() => {
        if (isHovered) {
            // Compute how far off screen the detail container is
            clearTimeoutComputeDetailContainerOffset();
            timeoutComputeDetailContainerOffset();

            if (!titleWidth) {
                // Compute the width of title element
                clearTimeoutComputeDetailTitleWidth();
                timeoutComputeDetailTitleWidth();
            }
        }
    }, [isHovered]);

    useEffect(() => {
        if (
            !isForegrounded
            && !isInitialRender
            && viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
        ) {
            hideDetailContainer();
        }
    }, [isForegrounded]);

    // Detail Title
    useEffect(() => {
        if (
            viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
            && !titleWidth
            && scrollTitle
        ) {
            computeDetailTitleWidth();
        }
    }, [
        viewportDimensions,
        titleWidth,
        scrollTitle,
    ]);

    // Detail Tags
    useEffect(() => {
        if (
            viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
            && visibleTags.size === 0
            && !isInitialRender
        ) {
            computeDetailVisibleTags();
        }
    }, [
        viewportDimensions,
        visibleTags,
        isInitialRender,
    ]);

    const {
        start: timeoutStopCartridgeShake,
        clear: clearTimeoutStopCartridgeShake,
    } = useTimeout(() => {
        setIsShaking(false);
    }, CARTRIDGE_SHAKE_DURATION);

    const {
        start: timeoutRevealDetail,
        clear: clearTimeoutRevealDetail,
    } = useTimeout(() => {
        setIsHovered(true);
    }, REVEAL_DETAIL_DELAY_DURATION);

    const {
        start: timeoutComputeDetailContainerOffset,
        clear: clearTimeoutComputeDetailContainerOffset,
    } = useTimeout(() => {
        computeDetailContainerOffset();
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (3 * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ) + Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (data.themeTags?.length || DEFAULT_TAG_COUNT) * FADE_IN_STAGGER_OFFSET_DURATION
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ));

    const {
        start: timeoutComputeDetailTitleWidth,
        clear: clearTimeoutComputeDetailTitleWidth,
    } = useTimeout(() => {
        if (scrollTitle) {
            computeDetailTitleWidth();
        }
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (3 * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ) + Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (data.themeTags?.length || DEFAULT_TAG_COUNT) * FADE_IN_STAGGER_OFFSET_DURATION
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ));

    const {
        start: timeoutHideDetailContainer,
        clear: clearTimeoutHideDetailContainer,
    } = useTimeout(() => {
        hideDetailContainer();
    }, HIDE_DETAIL_CONTAINER_DURATION);

    // ===== Memoization =====

    const tagHeight = useMemo(() => (
        viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            ? TAG_HEIGHT
            : TAG_HEIGHT_SMALL_VIEWPORT
    ), [viewportDimensions]);

    const tags = useMemo(() => {
        // present all tags
        if (
            data.themeTags
            && viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
        ) {
            return data.themeTags;
        }

        // present filtered and sorted tags
        if (data.themeTags && visibleTags.size > 0) {
            return data.themeTags
                .filter((tag) => visibleTags.has(tag.id))
                .sort((a, b): number => {
                    if (visibleTags.get(a.id)! > visibleTags.get(b.id)!) { return -1; }
                    if (visibleTags.get(a.id)! < visibleTags.get(b.id)!) { return 1; }
                    return 0;
                });
        }

        // We need to render tags once so that we can compute which should be presented
        if (data.themeTags && visibleTags.size === 0) {
            return data.themeTags;
        }

        return null;
    }, [
        data,
        visibleTags,
        viewportDimensions,
    ]);

    const detailContainerIsVisible = useMemo(() => (
        (
            isHovered
            || viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
        ) && !cartridgeInserted && !isInserting && !isEjecting
    ), [
        isHovered,
        viewportDimensions,
        cartridgeInserted,
        isInserting,
        isEjecting,
    ]);

    const detailTypeEnterTimeout = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (0 * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ), []);

    const detailTypeExitTimeout = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((3 - Math.max(0 - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const detailTitleEnterTimeout = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (1 * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ), []);

    const detailTitleExitTimeout = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((3 - Math.max(1 - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const detailAuthorsEnterTimeout = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (2 * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION
        + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
    ), []);

    const detailAuthorsExitTimeout = useMemo(() => Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((3 - Math.max(2 - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const showDetailType = useMemo(() => (
        isHovered && !!data.type
    ), [
        isHovered,
        data,
    ]);

    const showDetailTitle = useMemo(() => (
        (
            isHovered
            || viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
        ) && !!data.title
    ), [
        isHovered,
        data,
        viewportDimensions,
    ]);

    const showDetailAuthors = useMemo(() => (
        isHovered && !!data.authors
    ), [
        isHovered,
        data,
    ]);

    const showDetailTags = useMemo(() => (
        (
            isHovered
            || viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
        ) && !!data.themeTags
    ), [
        isHovered,
        viewportDimensions,
        data,
    ]);

    return (
        <Link
            to={`${ID_TO_ITEM.get(data.id)}${location.search}`}
            style={{ textDecoration: 'none' }}
        >
            <Container
                ref={cartridgeRef}
                id={data.id}
                className={HOVER_TARGET_CLASSNAME}
                isVisible={!cartridgeInserted}
                isShaking={isShaking}
                shakeDuration={CARTRIDGE_SHAKE_DURATION}
                transformDuration={CARTRIDGE_TRANSFORM_DURATION}
                onMouseEnter={onCartridgeCursorEnter}
                onMouseLeave={onCartridgeCursorLeave}
                onClick={onCartridgeClick}
            >
                <CartridgeImageClippingMask>
                    {imageURL && (
                        <CartridgeImage
                            src={imageURL}
                            color={CARTRIDGE_TYPE[data.color].color}
                            isVisible={imageVisible}
                            transitionDuration={CARTRIDGE_IMAGE_TRANSITION_DURATION}
                            onLoad={onImageLoad}
                        />
                    )}
                    {(!imageVisible || !imageURL) && (
                        <PlaceholderBox
                            width="100%"
                            height="100%"
                        />
                    )}
                </CartridgeImageClippingMask>
                <CartridgeFace
                    src={CARTRIDGE_TYPE[data.color][CARTRIDGE_STATE.face]}
                />
                <CartridgeDetailContainer
                    ref={detailContainerRef}
                    positionOffset={detailContainerOffset}
                    isVisible={detailContainerIsVisible}
                    transitionDuration={CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    {data.type
                        ? (
                            <Transition
                                in={showDetailType}
                                timeout={{
                                    enter: detailTypeEnterTimeout,
                                    exit: detailTypeExitTimeout,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <CartridgeType
                                        fontMultiplier={CARTRIDGE_TYPE_FONT_MULTIPLIER}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                offset: 5,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 5,
                                                numItems: 3,
                                                index: 0,
                                                enterDelay: CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
                                            })[state],
                                        }}
                                    >
                                        {data.type}
                                    </CartridgeType>
                                )}
                            </Transition>
                        )
                        : (
                            <PlaceholderBox
                                width={DETAIL_TYPE_PLACEHOLDER_HEIGHT}
                                height={CARTRIDGE_TYPE_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                margin="0px 0px 5px 0px"
                            />
                        )}
                    {data.title
                        ? (
                            <Transition
                                in={showDetailTitle}
                                timeout={{
                                    enter: detailTitleEnterTimeout,
                                    exit: detailTitleExitTimeout,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <CartridgeTitleContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                offset: 5,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 5,
                                                numItems: 3,
                                                index: 0,
                                                enterDelay: CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
                                            })[state],
                                        }}
                                    >
                                        <CartridgeTitle
                                            scroll={scrollTitle}
                                            width={titleWidth}
                                            ref={detailTitleRef}
                                            fontMultiplier={CARTRIDGE_TITLE_FONT_MULTIPLIER}
                                        >
                                            {data.title}
                                        </CartridgeTitle>
                                        {titleWidth && scrollTitle && (
                                            <TitleGradient />
                                        )}
                                    </CartridgeTitleContainer>
                                )}
                            </Transition>
                        )
                        : (
                            <PlaceholderBox
                                width={DETAIL_TITLE_PLACEHOLDER_HEIGHT}
                                height={CARTRIDGE_TITLE_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                margin="0px 0px 5px 0px"
                            />
                        )}
                    {data.authors
                        ? (
                            <Transition
                                in={showDetailAuthors}
                                timeout={{
                                    enter: detailAuthorsEnterTimeout,
                                    exit: detailAuthorsExitTimeout,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <CartridgeAuthors
                                        fontMultiplier={CARTRIDGE_AUTHORS_FONT_MULTIPLIER}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                offset: 5,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 5,
                                                numItems: 3,
                                                index: 0,
                                                enterDelay: CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
                                            })[state],
                                        }}
                                    >
                                        {data.authors.reduce((authors, author, i: number) => {
                                            if (i === 0) {
                                                return author;
                                            }

                                            return `${authors}, ${author}`;
                                        }, '')}
                                    </CartridgeAuthors>
                                )}
                            </Transition>
                        )
                        : (
                            <PlaceholderBox
                                width={DETAIL_AUTHORS_PLACEHOLDER_HEIGHT}
                                height={CARTRIDGE_AUTHORS_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                margin="0px 0px 10px 0px"
                            />
                        )}
                    <TagsContainer
                        ref={tagsContainerRef}
                    >
                        {tags
                        && tags.map((tagTheme: ITag, tagIndex: number, arr: string[] | ITag[]) => {
                            const isApplied = searchParams
                                .getAll(getFilterTypePathKey(tagTheme.type))
                                .includes(tagIDToHumanID(tagTheme.type, tagTheme.id));
                            const isInactive = searchParams.entries.length > 0
                                && !filters.get(tagTheme.type)!.get(tagTheme.id);

                            let color: string;
                            if (isInactive) {
                                // Grey out because inactive
                                color = theme.color.neutral200;
                            } else {
                                // Show as active
                                color = CARTRIDGE_TYPE[data.color].color;
                            }

                            const tagEnterTimeout = Math.min(
                                MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                (3 * FADE_IN_STAGGER_OFFSET_DURATION)
                                + FADE_IN_STAGGER_TRANSITION_DURATION
                                + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
                            ) + Math.min(
                                MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                (tagIndex * FADE_IN_STAGGER_OFFSET_DURATION)
                                + FADE_IN_STAGGER_TRANSITION_DURATION
                                + CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
                            );

                            const tagExitTimeout = Math.min(
                                MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                ((arr.length - Math.max(tagIndex - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                + FADE_IN_STAGGER_TRANSITION_DURATION,
                            );

                            return (
                                <Transition
                                    key={tagTheme.id}
                                    in={showDetailTags}
                                    timeout={{
                                        enter: tagEnterTimeout,
                                        exit: tagExitTimeout,
                                    }}
                                    appear
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    {(state) => {
                                        const tag = (
                                            <Tag
                                                id={tagTheme.id}
                                                className={HOVER_TARGET_CLASSNAME}
                                                center
                                                responsiveText
                                                solid={isApplied || isInactive}
                                                hover={
                                                    !isInactive
                                                    && !!filters.has(tagTheme.type)
                                                    && !!filters.get(tagTheme.type)!.get(tagTheme.id)
                                                }
                                                floating={isApplied}
                                                inactive={isInactive}
                                                color={color}
                                                index={tagIndex}
                                                height={tagHeight}
                                                transitionDuration={TAG_TRANSITION_DURATION}
                                                {...(isInactive
                                                    ? {} : {
                                                        onMouseEnter: (e) => onCursorEnter(
                                                            CURSOR_TARGET.tag,
                                                            [CURSOR_SIGN.click],
                                                            e.target as HTMLElement,
                                                        ),
                                                        onMouseLeave: (e) => onCursorLeave(e),
                                                        onClick: () => triggerFilterAudio(),
                                                    }
                                                )}
                                            >
                                                <TagText>
                                                    {tagTheme.text}
                                                </TagText>
                                            </Tag>
                                        );

                                        if (isInactive) {
                                            return tag;
                                        }

                                        return (
                                            <Link
                                                to={`?${getFilterPath(
                                                    searchParams,
                                                    tagTheme.type,
                                                    tagTheme.humanId,
                                                ).toString()}`}
                                                style={{
                                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                        direction: 'down',
                                                        offset: 10,
                                                    }),
                                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                        direction: 'down',
                                                        offset: 10,
                                                        numItems: arr.length,
                                                        index: tagIndex,
                                                        enterDelay: CARTRIDGE_DETAIL_CONTAINER_TRANSITION_DURATION,
                                                    })[state],
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {tag}
                                            </Link>
                                        );
                                    }}
                                </Transition>
                            );
                        })}
                        {viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                        && data.themeTags
                        && visibleTags.size < data.themeTags.length
                        && visibleTags.size !== 0
                        && (
                            <MoreTagsText>
                                {`+ ${data.themeTags.length - visibleTags.size} more`}
                            </MoreTagsText>
                        )}
                    </TagsContainer>
                </CartridgeDetailContainer>
            </Container>
        </Link>
    );
}

export default Cartridge;
