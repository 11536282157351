// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                       from 'react';
import { ReactSVG }                     from 'react-svg';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
}                                       from '../../enums';

// ===== Services =====

import {
    playAudio,
    detectTouchDevice,
}                                       from '../../services';

// ===== Images =====

import PixelAsteriskIcon                from '../../images/8-bit_asterisk.svg';

// ===== Sounds =====

import ButtonClick                      from '../../sounds/button_click.mp3';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    HOVER_TARGET_CLASSNAME,
    UNASSIGNED_ERROR_MESSAGE,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import KEYCODE                          from '../../constants/keycodes';

// ===== Styles =====

import {
    Container,
    InputHeading,
    Input,
    InputContainer,
    RequiredIndicatorContainer,
}                                       from './styles';

PixelInput.defaultProps = {
    isValid: true,
    isRequired: false,
    onEnter: undefined,
};
interface Props {
    isValid?: boolean,
    isRequired?: boolean,
    value: string,
    placeholderText: string,
    hasSound: boolean,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    onChange: (value: string) => void,
    onEnter?: () => void,
}
function PixelInput({
    isValid,
    isRequired,
    value,
    placeholderText,
    hasSound,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    onChange,
    onEnter,
}: Props): JSX.Element {
    // ===== Refs =====

    const inputRef = useRef<HTMLInputElement>(null);

    // ----- Sound Clips

    const buttonClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [isFocused, setIsFocused] = useState<boolean>(false);

    // ===== Methods =====

    const onInputEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.input,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onInputLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e.target.value);
    };

    /**
     * Manages tracking enter key presses during changes to input
     * @param e keyboard event
     */
    const checkForEnter = (e: React.KeyboardEvent): void => {
        if (e.key === KEYCODE.enter && onEnter) {
            // Cancel the default action, if needed
            e.preventDefault();

            // Respond to enter
            onEnter();
        } else if (e.key === KEYCODE.escape) {
            // Cancel the default action, if needed
            e.preventDefault();

            // Note that ref.current may be null. This is expected, because you may
            // conditionally render the ref-ed element, or you may forgot to assign it
            if (!inputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(`${placeholderText} Pixel Input Ref`));

            // Blur Input
            inputRef.current.blur();
        }
    };

    const focusInput = (): void => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const onInputFocus = (): void => {
        // Inform parent elements
        setInputFocused(true);
        // Change internal state
        setIsFocused(true);

        // Play Sound
        if (hasSound && buttonClickClip.current) {
            buttonClickClip.current.pause();
            buttonClickClip.current.currentTime = 0;
            playAudio(buttonClickClip.current);
        }
    };

    const onInputBlur = (): void => {
        // Inform parent elements
        setInputFocused(false);
        // Change internal state
        setIsFocused(false);
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            buttonClickClip.current
        ) {
            // Button Click
            buttonClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            buttonClickClip.current.src = ButtonClick;
        }

        return function cleanup() {
            if (buttonClickClip.current) buttonClickClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (
            value === ''
            && inputRef.current
            && inputRef.current.value.length > 0
        ) {
            inputRef.current.value = '';
        }
    }, [value]);

    return (
        <Container
            isInvalid={!isValid}
            isFocused={isFocused}
            className={HOVER_TARGET_CLASSNAME}
            {...(detectTouchDevice(document) ? {
                onTouchStart: (e) => onInputEnter(e),
            } : {
                onMouseEnter: (e) => onInputEnter(e),
            })}
            {...(detectTouchDevice(document) ? {
                onTouchEnd: (e) => onInputLeave(e),
            } : {
                onMouseLeave: (e) => onInputLeave(e),
            })}
            onClick={focusInput}
        >
            {value.length > 0 && (
                <InputHeading
                    isInvalid={!isValid}
                >
                    {placeholderText}
                </InputHeading>
            )}
            <InputContainer
                noText={value.length === 0}
            >
                <Input
                    type="text"
                    ref={inputRef}
                    defaultValue={value}
                    placeholder={placeholderText}
                    onChange={onInputChange}
                    onKeyUp={checkForEnter}
                    isInvalid={!isValid}
                    disabled={false}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                />
                {isRequired && (
                    <RequiredIndicatorContainer
                        isInvalid={!isValid}
                    >
                        <ReactSVG
                            src={PixelAsteriskIcon}
                        />
                    </RequiredIndicatorContainer>
                )}
            </InputContainer>
        </Container>
    );
}

export default PixelInput;
