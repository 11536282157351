/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */

// ===== Referenced Resources =====
// https://codepen.io/dremar_design/pen/vYmBzRw
// https://codepen.io/rachsmith/pen/ONVQWv

// ===== Packages =====

import React, {
    useMemo,
    useState,
    useEffect,
    useRef,
    useCallback,
}                                               from 'react';
import {
    Routes,
    Route,
    useMatch,
}                                               from 'react-router-dom';
import { hot }                                  from 'react-hot-loader';
import { ErrorBoundary }                        from 'react-error-boundary';
import {
    getAuth,
    signInAnonymously,
    onAuthStateChanged,
}                                               from 'firebase/auth';
import {
    doc,
    getDoc,
    setDoc,
    updateDoc,
    getFirestore,
    onSnapshot,
}                                               from 'firebase/firestore';
import { Transition }                           from 'react-transition-group';
import ShortUniqueId                            from 'short-unique-id';
import { v4 as uuidv4 }                         from 'uuid';
import { Buffer }                               from 'buffer';
import process                                  from 'process';
import fire                                     from './fire';

// ===== Components =====

import LandingView                              from './components/LandingView';
import AboutView                                from './components/AboutView';
import ReaderView                               from './components/ReaderView';
import PhysicalBookView                         from './components/PhysicalBookView';
import CharactersView                           from './components/CharactersView';
import TreasureChestView                        from './components/TreasureChestView';
import UnsubscribeView                          from './components/UnsubscribeView';
import UnsubscribeResultView                    from './components/UnsubscribeResultView';
import SocialEmergenceView                      from './components/SocialEmergenceView';
import CartView                                 from './components/CartView';
import CheckoutView                             from './components/CheckoutView';
import ErrorFallback                            from './components/ErrorFallback';
import UserProfileDialog                        from './components/UserProfileDialog';
import Snackbar                                 from './components/Snackbar';
import CartButton                               from './components/CartButton';

// ===== Hooks =====

import {
    useInterval,
    useTimeout,
    useEventListener,
}                                               from './hooks';

// ===== Services =====

import {
    detectBrowser,
    detectTouchDevice,
    fetchIP,
    findParentNodeWithClass,
    isMacOS,
    isMobile,
    recordUserAction,
    updateUserInDB,
}                                               from './services';

// ===== Constants =====

import {
    UNASSIGNED_ERROR_MESSAGE,
    DEFAULT_CSS_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
    HEADER_BUTTON_TRANSITION_DURATION,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    DEFAULT_USER_FIRST_NAME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
}                                               from './constants/generalConstants';
import CURSOR_SIGN                              from './constants/cursorSigns';

// ===== Enums =====

import {
    PAGE_ROUTE,
    CURSOR_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    FIRESTORE_COLLECTION,
    AUTHENTICATION_TYPE,
    USER_ACTION_TYPE,
    UNSUBSCRIBE_STATUS_TYPE,
}                                               from './enums';
import { RESOLUTION_LEVEL }                     from './components/Editor/elements/enums';

// ===== Interfaces =====

import {
    ICursorActionCornerPosition,
    ICursorLockedTargetProps,
    IDimension,
    IIPItem,
    IUserItem,
    IUserSessionItem,
    ISnackbarItem,
}                                               from './interfaces';

// ===== Images =====

import ChatSign                                 from './images/chat.svg';
import CautionIcon                              from './images/caution.svg';

// ===== Styles =====

import { theme }                                from './themes/theme-context';
import {
    Container,
    CustomCursor,
    CustomCursorSign,
    SoundButtonOuterContainer,
    SoundButtonInnerContainer,
    SoundButton,
    Path,
    SmallCursorActionContainer,
    CursorSight,
}                                               from './styles';

// ===== Firebase =====

// ----- Initialize Firebase
fire();

// ----- Initialize Buffer and process (for Shippo)
window.Buffer = window.Buffer || Buffer;
window.process = window.process || process;

function App(): JSX.Element {
    // ===== General Constants =====

    const SOUND_BUTTON_HEIGHT = 17.5;
    const CART_BUTTON_HEIGHT = 20;
    const SNACKBAR_MESSAGE_ANONYMOUS_SIGN_IN_ERROR = 'There was a problem signing in anonymously.';
    const DEFAULT_SMALL_CURSOR_CORNER: ICursorActionCornerPosition = {
        top: true,
        left: false,
    };
    const INITIAL_CURSOR_SIGN_INDEX = 0;

    // ===== Refs =====

    const CUSTOM_CURSOR_REF_NAME = 'customCursorRef';

    // ----- General

    const customCursorRef = useRef<HTMLDivElement>(null);
    const smallCursorSightRef = useRef<HTMLDivElement>(null);

    // ===== React Router =====

    const routeIsUnsubscribePage = useMatch(PAGE_ROUTE.unsubscribe);
    const routeIsLandingPage = useMatch(PAGE_ROUTE.landing);
    const routeIsReadPage = useMatch(PAGE_ROUTE.book);
    const routeIsReadPageAnnotationFocused = useMatch(`${PAGE_ROUTE.book}/:annotationId`);
    const routeIsCartPage = useMatch(PAGE_ROUTE.cart);
    const routeIsCheckoutPage = useMatch(`${PAGE_ROUTE.checkout}/:checkpoint`);

    // ===== State =====

    // Stores user item data
    const [user, setUser] = useState<IUserItem | null>(null);
    // Stores the current dimensions of the viewport
    const [viewportDimensions, setViewportDimensions] = useState<IDimension>({
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    });
    // Sound is enabled/disabled in the web app
    const [hasSound, setHasSound] = useState<boolean>(true);
    // Some input in the web app is focused
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    // Indicates what type of cursor is currently visible
    const [cursorType, setCursorType] = useState<CURSOR_TYPE>(CURSOR_TYPE.resting);
    // The type of a element being hovered over
    const [cursorTargetType, setCursorTargetType] = useState<CURSOR_TARGET | null>(null);
    // The set of cursor actions to present to the user
    const [cursorSigns, setCursorSigns] = useState<string[]>([]);
    // Cached cursor target
    const [cursorTarget, setCursorTarget] = useState<HTMLElement | undefined>(undefined);
    // Stores the corner at which action icon should be placed on small cursor
    const [smallCursorCorner, setSmallCursorCorner] = useState<ICursorActionCornerPosition>(DEFAULT_SMALL_CURSOR_CORNER);
    // Used to prevent cursor background from forming during transition to small cursor
    const [transitioningToSmallCursor, setTransitioningToSmallCursor] = useState<boolean>(false);
    // Stores properties of target element when cursor locked
    const [cursorLockedTargetProperties, setCursorLockedTargetProperties] = useState<ICursorLockedTargetProps | null>(null);
    // Indicates whether we should check whether to temporarily unlock cursor
    const [checkUnlockCursor, setCheckUnlockCursor] = useState<boolean>(false);
    // Indicates whether cursor can temporarily move, typicall when element grabbed
    const [temporarilyUnlockCursor, setTemporarilyUnlockCursor] = useState<boolean>(false);
    // Caching previous cursor state to be reverted to when we move between
    // two hover targets without a mouse leave
    const [previousCursorState, setPreviousCursorState] = useState<{
        targetType: CURSOR_TARGET,
        actions: string[],
        target?: HTMLElement,
    } | null>(null);
    // The index of the current cursor action if there is more than one
    const [cursorSignIndex, setCursorSignIndex] = useState<number>(INITIAL_CURSOR_SIGN_INDEX);
    // indicates whether cursor is hidden
    const [cursorIsHidden, setCursorIsHidden] = useState<boolean>(false);
    // indicates whether logomark has expanded to show menu
    const [landingPageContentRevealed, setLandingPageContentRevealed] = useState<boolean>(false);
    // indicates whether cuneiform book tablet is visible
    const [cuneiformTabletRevealed, setCuneiformTabletRevealed] = useState<boolean>(false);
    // indicates whether user profile dialog is expanded
    const [userProfileDialogExpanded, setUserProfileDialogExpanded] = useState<boolean>(false);
    // indicates whether we have initialized user item listener in firestore
    const [listeningForUserChanges, setListeningForUserChanges] = useState<boolean>(false);
    // indicates whether we're signing in or out
    const [signingInOut, setSigningInOut] = useState<boolean>(false);
    // stores id of current session
    const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
    // indicates whether to present sign up nudge message in user profile dialog
    // occurs when user tries to create an annotation while not signed in
    const [nudgeUserToSignUpAfterAnnotation, setNudgeUserToSignUpAfterAnnotation] = useState<boolean>(false);
    // indicates whether to present get web message in user profile dialog
    // occurs when user tries to get web book while not signed in
    const [notifyUserToSignUpForWebBook, setNotifyUserToSignUpForWebBook] = useState<boolean>(false);
    // indicates whether to present get digital message in user profile dialog
    // occurs when user tries to get digital book while not signed in
    const [notifyUserToSignUpForDigitalBook, setNotifyUserToSignUpForDigitalBook] = useState<boolean>(false);
    // indicates whether to present get physical message in user profile dialog
    // occurs when user tries to get physical book while not signed in
    const [notifyUserToSignUpForPhysicalBook, setNotifyUserToSignUpForPhysicalBook] = useState<boolean>(false);
    // indicates whether to present sign up message in user profile dialog
    // occurs when user tries to navigate to different chapter or section while not signed in
    const [notifyUserToSignUpToNavigateBook, setNotifyUserToSignUpToNavigateBook] = useState<boolean>(false);
    // indicates whether snackbar is visible
    const [snackbarData, setSnackbarData] = useState<ISnackbarItem>({
        visible: false,
        text: '',
    });
    // indicates whether signed in and the authentication method used
    const [signedIn, setSignedIn] = useState<AUTHENTICATION_TYPE | null>(null);
    // stores user id of unsubscriber
    const [unsubscribeId, setUnsubscribeId] = useState<string | null>(null);
    // indicates whether unsubscribe request was successful
    const [unsubscribeStatus, setUnsubscribeStatus] = useState<UNSUBSCRIBE_STATUS_TYPE>(UNSUBSCRIBE_STATUS_TYPE.uninitated);
    // stores cached value of user sound when tab or window not focused
    const [cachedHasSound, setCachedHasSound] = useState<boolean | null>(null);
    // indicates whether read page post banner is fixed to top of viewport
    const [stickyPostBannerActive, setStickyPostBannerActive] = useState<boolean>(false);

    // ===== Cursor Constants =====

    const CURSOR_EXPAND_LENGTH = 60;
    const CURSOR_STANDARD_LENGTH = 20;
    const CURSOR_STANDARD_BORDER_WIDTH = 3;
    const CURSOR_EXPAND_BORDER_WIDTH = 3;
    const CURSOR_EXPAND_BACKGROUND = theme.verascopeColor.purple200;
    const CURSOR_TRANSITION_DURATION = DEFAULT_CSS_TRANSITION_DURATION / 2;
    const SMALL_CURSOR_ACTION_DELAY_DURATION = CURSOR_TRANSITION_DURATION / 2;
    const CURSOR_BORDER_LENGTH_TRANSITION_DURATION = DEFAULT_CSS_TRANSITION_DURATION;
    const CURSOR_EXPAND_BACKGROUND_TRANSLUCENCY = 0.9;
    const CURSOR_SIGN_ALTERNATE_DURATION = 2000;
    const CURSOR_LOCKED_PADDING = 5;
    const CURSOR_LINE_WIDTH = 1.2;
    const SMALL_CURSOR_TRANSFORMATION_DELAY_DURATION = 100;
    const SMALL_CURSOR_ACTION_CONTAINER_LENGTH = 28;
    const SMALL_CURSOR_COMPUTER_CORNER_DELAY_DURATION = 50; // Lower than human visual perception

    // ===== Methods =====

    /**
     * Updates the value of the --vh custom property to the root of the document
     * Resolves issue where vh units are not accurate on mobile devices
     */
    const updateViewportHeightUnit = (): void => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    /**
     * Adjusts the position of the custom cursor to the position of the hidden cursor
     * when the hidden cursor moves
     * @param e mouse event
     */
    const modifyCursorPosition = (e: MouseEvent): void => {
        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));
        if (!cursorLockedTargetProperties) {
            // Large Cursor
            customCursorRef.current.style.transform = `translate(calc(${e.pageX}px - 50%), calc(${e.pageY}px - 50%))`;
        } else if (temporarilyUnlockCursor && cursorTarget) {
            // Small Cursor with Grab
            const targetRect = cursorTarget.getBoundingClientRect();
            const cursorX = targetRect.right - targetRect.width / 2;
            const cursorY = targetRect.bottom - targetRect.height / 2;
            customCursorRef.current.style.transform = `translate(calc(${cursorX}px - 50%), calc(${cursorY}px - 50%))`;
        } else if (cursorLockedTargetProperties) {
            // Small Cursor
            customCursorRef.current.style.transform = `translate(${cursorLockedTargetProperties.pos.x - CURSOR_LOCKED_PADDING}px, ${cursorLockedTargetProperties.pos.y - CURSOR_LOCKED_PADDING}px)`;
        }

        if (smallCursorSightRef.current) {
            smallCursorSightRef.current.style.top = `${e.pageY}px`;
            smallCursorSightRef.current.style.left = `${e.pageX}px`;
        }
    };

    /**
     * Manages toggling sound when sound button clicked
     */
    const onSoundButtonClick = async (): Promise<void> => {
        const hadSound = hasSound;
        setHasSound(!hasSound);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: hadSound
                    ? USER_ACTION_TYPE.turnOffSound
                    : USER_ACTION_TYPE.turnOnSound,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    /**
     * Alters the presentation of the cursor to a larger form
     * when in contact with larger user interface items
     * @param cursor HTML element of custom cursor
     */
    const transformToLargeCursor = (cursor: HTMLDivElement): void => {
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderColor = theme.color.neutral300;
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderRadius = '100%';
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderWidth = `${CURSOR_EXPAND_BORDER_WIDTH}px`;
        // eslint-disable-next-line no-param-reassign
        cursor.style.width = `${CURSOR_EXPAND_LENGTH}px`;
        // eslint-disable-next-line no-param-reassign
        cursor.style.height = `${CURSOR_EXPAND_LENGTH}px`;
    };

    /**
     * Alters the presentation of the cursor to a smaller form
     * when in contact with smaller user interface items
     * @param cursor HTML element of custom cursor
     * @param target HTML element of cursor target
     * @param rect DOM Rect of cursor target
     */
    const transformToSmallCursor = (
        cursor: HTMLDivElement,
        target: HTMLElement,
        rect: DOMRect,
    ): void => {
        /**
         * Determines whether cursor should be presented in dark mode
         * if above color threshold
         * @param target HTML element of cursor target
         * @returns boolean on whether to adopt dark mode
         */
        const chooseDarkCursor = (target: HTMLElement): boolean => {
            const element = (target as HTMLElement) || target;
            if (element instanceof HTMLElement) {
                const targetBackgroundColor = window
                    .getComputedStyle(element, null)
                    .getPropertyValue('background-color');
                const targetBackgroundParts = targetBackgroundColor.split('(');
                const targetRGB = targetBackgroundParts[1]?.split(',');
                const rgb = targetRGB?.map((str) => {
                    let modifiedStr = str;
                    modifiedStr = modifiedStr.replace(')', '');
                    modifiedStr.trim(); // remove leading and trailing spaces
                    return  parseInt(modifiedStr, 10);
                }).slice(0, 3); // we only want rgb and not a (alpha)
                const colorThreshold = 37.5; // Used to be 165. Changed to 37.5 to make dark for yellow200
                if (
                    rgb
                    && rgb[0] > colorThreshold
                    && rgb[1] > colorThreshold
                    && rgb[2] > colorThreshold
                ) {
                    return true;
                }
            }

            return false;
        };

        // Change cursor ring color if target background is white
        if (chooseDarkCursor(target.parentElement as HTMLElement)) {
            // eslint-disable-next-line no-param-reassign
            cursor.style.borderColor = theme.verascopeColor.purple200;
        } else {
            // eslint-disable-next-line no-param-reassign
            cursor.style.borderColor = theme.color.white;
        }

        // Change cursor sight color if target is white
        if (smallCursorSightRef.current) {
            if (chooseDarkCursor(target as HTMLElement)) {
                smallCursorSightRef.current.style.background = theme.verascopeColor.purple200;
            } else {
                smallCursorSightRef.current.style.background = theme.color.white;
            }
        }
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderWidth = `${CURSOR_EXPAND_BORDER_WIDTH}px`;
        // eslint-disable-next-line no-param-reassign
        cursor.style.width = `${rect.width + 2 * CURSOR_LOCKED_PADDING}px`;
        // eslint-disable-next-line no-param-reassign
        cursor.style.height = `${rect.height + 2 * CURSOR_LOCKED_PADDING}px`;

        // Initiate Cursor Inertia
        setCursorLockedTargetProperties({
            width: rect.width,
            height: rect.height,
            pos: {
                x: rect.x,
                y: rect.y,
            },
        });

        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));

        clearTimeoutComputeSmallCursorCorner();
        timeoutComputeSmallCursorCorner();
    };

    /**
     * Alters the presentation of the cursor to a line form
     * when in contact with text box elements
     * @param cursor HTML element of custom cursor
     * @param target HTML element of cursor target
     * @param _rect DOM Rect of cursor target
     */
    const transformToLineCursor = (
        cursor: HTMLDivElement,
        target: HTMLElement,
        _rect: DOMRect,
    ): void => {
        const targetFontSize = window
            .getComputedStyle(target, null)
            .getPropertyValue('line-height');
        const cursorFontSize = `${1.5 * parseInt(targetFontSize.replace('px', ''), 10)}px`;
        // Target is an input. Assume it has white background and darken cursor color
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderColor = theme.verascopeColor.purple200;
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderWidth = `${CURSOR_LINE_WIDTH}px`;
        // eslint-disable-next-line no-param-reassign
        cursor.style.borderRadius = '1px';
        // eslint-disable-next-line no-param-reassign
        cursor.style.width = '0px';
        // eslint-disable-next-line no-param-reassign
        cursor.style.height = cursorFontSize;
    };

    /**
     * Manages response to custom cursor hovering on a user interface element
     * @param targetType The kind of user interface element in focus
     * @param actions The list of (cursor) actions possible on user interface element
     * @param candidateTarget HTML element of cursor target
     */
    const onCursorEnter = useCallback((
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ): void => {
        // IMPORTANT: This method should never be triggered when an interactable object is grabbed
        // We avoid triggering this method when an object is grabbed in LandingView

        // We went straight from one element the next without hitting on cursor leave
        if (cursorType !== CURSOR_TYPE.resting) onCursorLeave();

        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));

        if (candidateTarget) {
            const target = findParentNodeWithClass(HOVER_TARGET_CLASSNAME, candidateTarget, 5);
            if (!target) return;
            const targetRect = target.getBoundingClientRect();
            const cursorTarget = targetType as CURSOR_TARGET;
            if (
                cursorTarget === CURSOR_TARGET.input
                || cursorTarget === CURSOR_TARGET.editor
                || cursorTarget === CURSOR_TARGET.annotator
            ) {
                transformToLineCursor(customCursorRef.current, target, targetRect);
                setCursorType(CURSOR_TYPE.line);
            } else if (
                targetRect.width < CURSOR_EXPAND_LENGTH
                && targetRect.height < CURSOR_EXPAND_LENGTH
            ) {
                setTransitioningToSmallCursor(true);
                clearTimeoutCursorSmallTransform();
                timeoutCursorSmallTransform({
                    cursor: customCursorRef.current!,
                    rect: targetRect,
                    target,
                });
            } else {
                transformToLargeCursor(customCursorRef.current);
                setCursorType(CURSOR_TYPE.engagedLarge);
            }
        } else {
            transformToLargeCursor(customCursorRef.current);
            setCursorType(CURSOR_TYPE.engagedLarge);
        }

        setCursorSigns([...actions]);
        setCursorTargetType(targetType as CURSOR_TARGET);
        setCursorTarget(candidateTarget);
    }, [
        cursorType,
    ]);

    /**
     * Manages response to custom cursor no longer hovering on a user interface element
     * @param e mouse event
     */
    const onCursorLeave = useCallback((e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        // IMPORTANT: This method should never be triggered when an interactable object is grabbed
        // We avoid triggering this method when an object is grabbed in LandingView

        clearTimeoutCursorSmallTransform();
        if (transitioningToSmallCursor) setTransitioningToSmallCursor(false);

        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));

        customCursorRef.current.style.borderColor = theme.color.neutral300;
        customCursorRef.current.style.borderWidth = `${CURSOR_STANDARD_BORDER_WIDTH}px`;
        customCursorRef.current.style.borderRadius = '100%';
        customCursorRef.current.style.width = `${CURSOR_STANDARD_LENGTH}px`;
        customCursorRef.current.style.height = `${CURSOR_STANDARD_LENGTH}px`;

        // Needs to be before initiating onCursorEnter
        // because that method prevents non-resting cursors from executing code
        setCursorType(CURSOR_TYPE.resting);

        let clearCursorState = true;
        if (e) {
            const event = e as React.MouseEvent;
            if (event.relatedTarget) {
                const focusGainedElement = event.relatedTarget as HTMLElement;
                if (
                    focusGainedElement
                    && focusGainedElement.classList
                    && focusGainedElement.classList.contains(HOVER_TARGET_CLASSNAME)
                    && previousCursorState
                ) {
                    clearCursorState = false;
                    onCursorEnter(
                        previousCursorState.targetType,
                        previousCursorState.actions,
                        previousCursorState.target,
                    );
                }
            }
        }

        if (cursorTargetType) {
            setPreviousCursorState({
                targetType: cursorTargetType,
                actions: [...cursorSigns],
                target: cursorTarget,
            });
        }

        if (clearCursorState) {
            if (cursorSigns.length > 0) setCursorSigns([]);
            // We don't check for cursorTargetType because we want to be sure its clear
            // There is an input bug in the ReaderView where the cursorTargetType is not cleared
            // which causes the cursor to be in the incorrect state
            setCursorTargetType(null);
            if (cursorTarget) setCursorTarget(undefined);
            if (!smallCursorCorner.top || smallCursorCorner.left) setSmallCursorCorner(DEFAULT_SMALL_CURSOR_CORNER);
            if (transitioningToSmallCursor) setTransitioningToSmallCursor(false);
        }
        setCursorLockedTargetProperties(null);
    }, [
        transitioningToSmallCursor,
        cursorTargetType,
        cursorSigns,
        cursorTarget,
        smallCursorCorner,
    ]);

    /**
     * Manages response to custom cursor selecting on a user interface element
     * @param e mouse event
     */
    const onCursorMouseDown = (): void => {
        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));

        setCheckUnlockCursor(true);
    };

    /**
     * Manages response to custom cursor de-selecting on a user interface element
     * @param e mouse event
     */
    const onCursorMouseUp = (): void => {
        setCheckUnlockCursor(true);
    };

    /**
     * Manages any modifications that should apply due to viewport changes
     */
    const handleResize = (): void => {
        // Update viewport dimensions
        setViewportDimensions({
            width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        });

        // Update viewport height unit
        updateViewportHeightUnit();
    };

    /**
     * Initiates a user session upon app startup
     * @param sessionId unique identifier of current session
     */
    const startSession = async (sessionId: string): Promise<void> => {
        // Creat Session
        const ip: IIPItem = await fetchIP();
        const session: IUserSessionItem = {
            id: sessionId,
            userId: user!.id,
            start: Date.now(),
            stop: Date.now(),
            actions: [],
            browser: detectBrowser(),
            isMobile: isMobile(),
            isMacOS: isMacOS(),
            ip,
        };

        const db = getFirestore();
        const sessionsCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.userSessions
            : FIRESTORE_COLLECTION.stagingUserSessions;
        await setDoc(doc(db, sessionsCollection, sessionId), session);
        const sessions = [
            ...user!.sessions,
            sessionId,
        ];
        updateUserInDB({
            userId: user!.id,
            sessions,
        });
    };

    /**
     * Ends current user session upon app termination
     */
    const stopSession = async (): Promise<void> => {
        if (currentSessionId) {
            const db = getFirestore();
            const sessionsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.userSessions
                : FIRESTORE_COLLECTION.stagingUserSessions;
            const sessionRef = doc(db, sessionsCollection, currentSessionId);
            await updateDoc(sessionRef, {
                stop: Date.now(),
            });
        }
    };

    // /**
    //  * Manages toggling sound on and off
    //  * based on whether tab is focused
    //  * IMPORTANT: Commented out because window.onfocus and window.onblur
    //  * work both for tab focus and window focus
    //  */
    // const handleVisibilityChange = (): void => {
    //     if (
    //         document.visibilityState === 'visible'
    //         && cachedHasSound !== null
    //     ) {
    //         setHasSound(cachedHasSound);
    //         setCachedHasSound(null);
    //     } else {
    //         setCachedHasSound(hasSound);
    //         setHasSound(false);
    //     }
    // };

    /**
     * Manages toggling sound on and off
     * based on whether tab or window is focused
     * @param focused whether tab or window is focused or blurred
     */
    const handleTabOrWindowChange = (focused: boolean): void => {
        if (
            focused
            && cachedHasSound !== null
        ) {
            setHasSound(cachedHasSound);
            setCachedHasSound(null);
        } else {
            setCachedHasSound(hasSound);
            setHasSound(false);
        }
    };

    // ===== Side Effects =====

    /**
     * Resolves viewport units issue on mobile devices
     * Reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     */
    useEffect(() => {
        updateViewportHeightUnit();
    }, []);

    /**
     * Manages event listener that tracks on focus and on blur events of window
     * Executes if change *tab* or *window* focus
     */
    useEffect(() => {
        if (
            !routeIsLandingPage
            && !routeIsReadPage
            && !routeIsReadPageAnnotationFocused
        ) {
            window.onfocus = () => handleTabOrWindowChange(true);
            window.onblur = () => handleTabOrWindowChange(false);
        }
    }, [
        cachedHasSound,
    ]);

    /**
     * Manages event listener that tracks on focus and on blur events of window
     * Prevent changes page if in another *tab*
     * IMPORTANT: Commented out because window.onfocus and window.onblur
     * work both for tab focus and window focus
     */
    // useEffect(() => {
    //     document.addEventListener('visibilitychange', handleVisibilityChange, false);

    //     return () => {
    //         document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    //     };
    // }, [
    //     cachedHasSound,
    // ]);

    /**
     * Manages user session initialization
     */
    useEffect(() => {
        if (user && !currentSessionId) {
            // Set session id first to avoid race conditions that create
            // extra sessions while currentSessionId is not set
            const sessionId = uuidv4();
            setCurrentSessionId(sessionId);
            startSession(sessionId);
        }

        return function cleanup() {
            if (user && currentSessionId) stopSession();
        };
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Susbscribes to and listens for Firebase user authentication updates
     */
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (signingInOut) return; // We don't want to overwrite user until completed sign in

            if (authUser && !authUser.isAnonymous && !signedIn) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                //
                const { uid } = authUser;
                const db = getFirestore();
                const usersCollection = process.env.NODE_ENV === 'production'
                    ? FIRESTORE_COLLECTION.users
                    : FIRESTORE_COLLECTION.stagingUsers;
                const userRef = doc(db, usersCollection, uid);

                // Check for existence before creating new anonymous user
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    const userItem = userSnap.data() as IUserItem;
                    setUser({
                        ...userItem,
                    });
                    setSignedIn(userItem.authenticationType);
                }
            } else if (authUser && authUser.isAnonymous) {
                const { uid } = authUser;
                // Create anonymous user document
                const db = getFirestore();
                const usersCollection = process.env.NODE_ENV === 'production'
                    ? FIRESTORE_COLLECTION.users
                    : FIRESTORE_COLLECTION.stagingUsers;
                const userRef = doc(db, usersCollection, uid);

                // Check for existence before creating new anonymous user
                const userSnap = await getDoc(userRef);

                // Anonymous user does not exist
                // Make a user item for them in firestore
                if (!userSnap.exists())  {
                    const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
                    const anonymousUserItem: IUserItem = user
                        // Email Authentication likely failed
                        // during which we deleted the previous anonymous user
                        // Create a new anonymouse user and populate it with existing user data
                        ? {
                            ...user,
                            id: uid,
                        } : {
                            id: uid,
                            authenticationType: AUTHENTICATION_TYPE.anonymous,
                            joined: Date.now(),
                            firstName: DEFAULT_USER_FIRST_NAME,
                            lastName: uniqueId,
                            sessions: [],
                            annotations: [],
                            // Anonymous Accounts shouldn't be subscribed to mailing list
                            mailingListSubscription: {
                                history: [
                                    {
                                        subscribed: false,
                                        timestamp: Date.now(),
                                    },
                                ],
                            },
                            bookAccess: {
                                granted: false,
                                pagesPreviewed: 0,
                            },
                            resolution: RESOLUTION_LEVEL.five,
                            cart: [],
                            addresses: [],
                        };
                    const providerUser = auth.currentUser?.providerData[0];

                    if (
                        providerUser
                        && providerUser.displayName
                    ) {
                        const displayNameParts = providerUser.displayName.split(' ');
                        const [firstName] = displayNameParts;

                        anonymousUserItem.firstName = firstName;
                    }

                    if (
                        providerUser
                        && providerUser.displayName
                    ) {
                        const displayNameParts = providerUser.displayName.split(' ');
                        const lastName = displayNameParts.length > 1
                            ? displayNameParts[1]
                            : null;

                        if (lastName) {
                            anonymousUserItem.lastName = lastName;
                        }
                    }

                    if (
                        providerUser
                        && providerUser.phoneNumber
                    ) {
                        const { phoneNumber } = providerUser;

                        anonymousUserItem.phoneNumber = phoneNumber;
                    }

                    if (
                        providerUser
                        && providerUser.photoURL
                    ) {
                        const { photoURL } = providerUser;

                        anonymousUserItem.photoURL = photoURL;
                    }
                    await setDoc(userRef, anonymousUserItem);
                    setUser({
                        ...anonymousUserItem,
                    });
                } else {
                    const userItem = userSnap.data() as IUserItem;
                    if (!('mailingListSubscription' in userItem)) {
                        // User doesn't have a mailing list attribute
                        // Give it to them
                        await updateUserInDB({
                            userId: userItem.id,
                            mailingListSubscription: {
                                history: [
                                    {
                                        subscribed: false,
                                        timestamp: Date.now(),
                                    },
                                ],
                            },
                        });
                        setUser({
                            ...userItem,
                            mailingListSubscription: {
                                history: [
                                    {
                                        subscribed: false,
                                        timestamp: Date.now(),
                                    },
                                ],
                            },
                        });
                    } else {
                        setUser({
                            ...userItem,
                        });
                    }
                }
            } else if (!signedIn) {
                // Conditional avoids bug where authentication listener creates an anonymous account
                // between signing up using email and rerendering.

                // User is not signed in
                // Create anonymous user
                signInAnonymously(auth)
                    .catch(() => {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_ANONYMOUS_SIGN_IN_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                    });
                // We set flag to false to make way for a new user
                if (listeningForUserChanges) setListeningForUserChanges(false);
                if (currentSessionId) {
                    // We likely signed out
                    // Indicate sign out timestamp
                    // Clear current session id to make way for new session
                    stopSession();
                    setCurrentSessionId(null);
                }
            }
        });

        return function cleanup() {
            if (unsubscribe) unsubscribe();
        };
    }, [
        signingInOut,
        signedIn,
    ]);

    /**
     * Listens for changes to user database object
     * and updates local object copy
     */
    useEffect(() => {
        if (signingInOut) return;
        if (!listeningForUserChanges && user) {
            // Initialize DB
            const db = getFirestore();

            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const userRef = doc(db, usersCollection, user.id);
            onSnapshot(userRef, (userSnap) => {
                if (signingInOut) return;

                if (userSnap.exists()) {
                    // Set User Item
                    const firestoreUser = userSnap.data() as IUserItem;
                    setUser({
                        ...firestoreUser,
                    });
                }
            });

            setListeningForUserChanges(true);
        }
    }, [
        user,
        signingInOut,
        listeningForUserChanges,
    ]);

    /**
     * Manages which cursor action icon should be present at any
     * given moment
     */
    useInterval(() => {
        const index = (cursorSignIndex + 1) % cursorSigns.length;
        setCursorSignIndex(index);
    }, cursorSigns.length > 1 ? CURSOR_SIGN_ALTERNATE_DURATION : null);

    /**
     * Manages event listener that tracks hidden cursor movement
     */
    useEffect(() => {
        // Modify cursor position
        document.body.addEventListener('mousemove', modifyCursorPosition);

        return () => {
            document.body.removeEventListener('mousemove', modifyCursorPosition);
        };
    }, [
        cursorLockedTargetProperties,
        cursorTarget,
        cursorType,
        temporarilyUnlockCursor,
    ]);

    /**
     * Manages unlocking cursor when small cursor is engaged
     * and a user interface object is grabbed.
     * Necessary for custom cursor to move freely along with grabbed object
     */
    useEffect(() => {
        if (
            checkUnlockCursor
            && cursorLockedTargetProperties
            && cursorTarget
            && cursorType === CURSOR_TYPE.engagedSmall
            && !temporarilyUnlockCursor
        ) {
            // Encountered when grabbing an interactable object with the small cursor

            // Note that ref.current may be null. This is expected, because you may
            // conditionally render the ref-ed element, or you may forgot to assign it
            if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));

            setTemporarilyUnlockCursor(true);

            customCursorRef.current.style.borderColor = theme.color.white;
            customCursorRef.current.style.borderWidth = `${CURSOR_STANDARD_BORDER_WIDTH}px`;
            customCursorRef.current.style.backgroundColor = theme.color.white;
            customCursorRef.current.style.width = `${10}px`;
            customCursorRef.current.style.height = `${10}px`;
            const target = findParentNodeWithClass(HOVER_TARGET_CLASSNAME, cursorTarget, 5);
            if (!target) return;
            const targetRect = target.getBoundingClientRect();
            const cursorX = targetRect.right - targetRect.width / 2;
            const cursorY = targetRect.bottom - targetRect.height / 2;
            customCursorRef.current.style.transform = `translate(calc(${cursorX}px - 50%), calc(${cursorY}px - 50%))`;
        } else if (
            checkUnlockCursor
            && cursorLockedTargetProperties
            && cursorTarget
            && temporarilyUnlockCursor
        ) {
            // Encountered when dropping a grabbed interactable object with the small cursor
            setTemporarilyUnlockCursor(false);
            // Note that ref.current may be null. This is expected, because you may
            // conditionally render the ref-ed element, or you may forgot to assign it
            if (!customCursorRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(CUSTOM_CURSOR_REF_NAME));

            customCursorRef.current.style.borderColor = theme.color.white;
            customCursorRef.current.style.borderWidth = `${CURSOR_EXPAND_BORDER_WIDTH}px`;
            customCursorRef.current.style.backgroundColor = '';
            customCursorRef.current.style.width = `${cursorLockedTargetProperties.width + 2 * CURSOR_LOCKED_PADDING}px`;
            customCursorRef.current.style.height = `${cursorLockedTargetProperties.height + 2 * CURSOR_LOCKED_PADDING}px`;

            // Update Cursor Lock Position
            const target = findParentNodeWithClass(HOVER_TARGET_CLASSNAME, cursorTarget, 5);
            if (!target) return;
            const targetRect = target.getBoundingClientRect();

            setCursorLockedTargetProperties({
                width: cursorLockedTargetProperties.width,
                height: cursorLockedTargetProperties.height,
                pos: {
                    x: targetRect.right - targetRect.width / 2 - cursorLockedTargetProperties.width / 2,
                    y: targetRect.bottom - targetRect.height / 2 - cursorLockedTargetProperties.height / 2,
                },
            });
        }

        setCheckUnlockCursor(false);
    }, [
        checkUnlockCursor,
    ]);

    /**
     * Prevents jarring cursor movement when traveling over element
     * without intent of hovering over it
     */
    const {
        start: timeoutCursorSmallTransform,
        clear: clearTimeoutCursorSmallTransform,
    } = useTimeout((props) => {
        const args = props as {
            cursor: HTMLDivElement,
            rect: DOMRect,
            target: HTMLElement,
        };
        transformToSmallCursor(args.cursor, args.target, args.rect);
        setCursorType(CURSOR_TYPE.engagedSmall);
        setTransitioningToSmallCursor(false);
    }, SMALL_CURSOR_TRANSFORMATION_DELAY_DURATION);

    /**
     * Determines which corner cursor actions should be placed
     * based on position of the custom cursor
     */
    const {
        start: timeoutComputeSmallCursorCorner,
        clear: clearTimeoutComputeSmallCursorCorner,
    } = useTimeout(() => {
        if (customCursorRef.current) {
            const cursorRect = customCursorRef.current.getBoundingClientRect();
            const cursorActionCornerPosition: ICursorActionCornerPosition = {
                top: true,
                left: false,
            };

            if (cursorRect.top - SMALL_CURSOR_ACTION_CONTAINER_LENGTH < 0) {
                cursorActionCornerPosition.top = false;
            }

            if (cursorRect.right + SMALL_CURSOR_ACTION_CONTAINER_LENGTH > viewportDimensions.width) {
                cursorActionCornerPosition.left = true;
            }

            setSmallCursorCorner(cursorActionCornerPosition);
        }
    }, SMALL_CURSOR_COMPUTER_CORNER_DELAY_DURATION);

    /**
     * Manages event listener that tracks viewport resizing
     */
    useEventListener('resize', handleResize);

    /**
     * Prepares renderer for when an uncaught error is encountered
     */
    const fallbackRender = useCallback(({
        error,
        resetErrorBoundary,
    }: {
        error: any,
        resetErrorBoundary: any,
    }) => (
        <ErrorFallback
            error={error}
            currentSessionId={currentSessionId}
            user={user}
            resetErrorBoundary={resetErrorBoundary}
            onMouseEnter={(e) => onCursorEnter(
                CURSOR_TARGET.resetButton,
                [CURSOR_SIGN.click],
                e?.target as HTMLElement,
            )}
            onMouseLeave={onCursorLeave}
        />
    ), [
        user,
        currentSessionId,
    ]);

    // ===== Memoization =====

    const cartButtonVisible = useMemo(() => (
        !!user && user.authenticationType !== AUTHENTICATION_TYPE.anonymous
    ), [user, user?.authenticationType]);

    /**
     * Memoizes a renderer for the custom cursor
     */
    const CustomCursorSigns = useMemo(() => (
        <>
            <CustomCursorSign
                isVisible={cursorSigns.length === 1 && cursorType !== CURSOR_TYPE.resting}
                small={
                    cursorTargetType === CURSOR_TARGET.input
                    || cursorType === CURSOR_TYPE.engagedSmall
                    || cursorType === CURSOR_TYPE.line
                }
                transitionDuration={CURSOR_TRANSITION_DURATION}
                transitionDelayDuration={SMALL_CURSOR_ACTION_DELAY_DURATION}
                src={inputFocused && cursorTargetType === CURSOR_TARGET.input
                    ? ChatSign
                    : cursorSigns[0]}
            />
            {cursorSigns.map((action: string, i: number) => (
                <CustomCursorSign
                    key={action}
                    isVisible={cursorSigns.length > 1 && cursorSignIndex === i && cursorType !== CURSOR_TYPE.resting}
                    small={
                        cursorTargetType === CURSOR_TARGET.input
                        || cursorType === CURSOR_TYPE.engagedSmall
                        || cursorType === CURSOR_TYPE.line
                    }
                    transitionDuration={CURSOR_TRANSITION_DURATION}
                    transitionDelayDuration={SMALL_CURSOR_ACTION_DELAY_DURATION}
                    src={action}
                />
            ))}
        </>
    ), [
        cursorSigns,
        inputFocused,
        cursorSignIndex,
        cursorTargetType,
        cursorType,
        temporarilyUnlockCursor,
    ]);

    // ===== Rendering =====

    const RouterRoutes = useMemo(() => (
        <Routes>
            <Route
                path="/"
                element={(
                    <LandingView
                        hasSound={hasSound}
                        viewportDimensions={viewportDimensions}
                        landingPageContentRevealed={landingPageContentRevealed}
                        cuneiformTabletRevealed={cuneiformTabletRevealed}
                        currentSessionId={currentSessionId}
                        user={user}
                        cachedHasSound={cachedHasSound}
                        setSnackbarData={setSnackbarData}
                        setLandingPageContentRevealed={setLandingPageContentRevealed}
                        setCuneiformTabletRevealed={setCuneiformTabletRevealed}
                        setInputFocused={setInputFocused}
                        setCursorSigns={setCursorSigns}
                        setHasSound={setHasSound}
                        setCachedHasSound={setCachedHasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        onCursorMouseDown={onCursorMouseDown}
                        onCursorMouseUp={onCursorMouseUp}
                    />
                )}
            />
            <Route
                path={PAGE_ROUTE.about}
                element={(
                    <AboutView
                        currentSessionId={currentSessionId}
                        user={user}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            />
            <Route
                path={PAGE_ROUTE.book}
                element={(
                    <ReaderView
                        write={process.env.NODE_ENV === 'development' && !!user?.admin}
                        user={user}
                        hasSound={hasSound}
                        currentSessionId={currentSessionId}
                        viewportDimensions={viewportDimensions}
                        notifyUserToSignUpToNavigateBook={notifyUserToSignUpToNavigateBook}
                        snackbarIsVisible={snackbarData.visible}
                        cachedHasSound={cachedHasSound}
                        stickyPostBannerActive={stickyPostBannerActive}
                        setStickyPostBannerActive={setStickyPostBannerActive}
                        setInputFocused={setInputFocused}
                        setCursorSigns={setCursorSigns}
                        setNudgeUserToSignUpAfterAnnotation={setNudgeUserToSignUpAfterAnnotation}
                        setNotifyUserToSignUpForWebBook={setNotifyUserToSignUpForWebBook}
                        setNotifyUserToSignUpForDigitalBook={setNotifyUserToSignUpForDigitalBook}
                        setNotifyUserToSignUpForPhysicalBook={setNotifyUserToSignUpForPhysicalBook}
                        setNotifyUserToSignUpToNavigateBook={setNotifyUserToSignUpToNavigateBook}
                        setSnackbarData={setSnackbarData}
                        setHasSound={setHasSound}
                        setCachedHasSound={setCachedHasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            >
                <Route
                    path=":annotationId"
                    element={(
                        <ReaderView
                            write={process.env.NODE_ENV === 'development' && !!user?.admin}
                            user={user}
                            hasSound={hasSound}
                            currentSessionId={currentSessionId}
                            viewportDimensions={viewportDimensions}
                            notifyUserToSignUpToNavigateBook={notifyUserToSignUpToNavigateBook}
                            snackbarIsVisible={snackbarData.visible}
                            cachedHasSound={cachedHasSound}
                            stickyPostBannerActive={stickyPostBannerActive}
                            setStickyPostBannerActive={setStickyPostBannerActive}
                            setInputFocused={setInputFocused}
                            setCursorSigns={setCursorSigns}
                            setNudgeUserToSignUpAfterAnnotation={setNudgeUserToSignUpAfterAnnotation}
                            setNotifyUserToSignUpForWebBook={setNotifyUserToSignUpForWebBook}
                            setNotifyUserToSignUpForDigitalBook={setNotifyUserToSignUpForDigitalBook}
                            setNotifyUserToSignUpForPhysicalBook={setNotifyUserToSignUpForPhysicalBook}
                            setNotifyUserToSignUpToNavigateBook={setNotifyUserToSignUpToNavigateBook}
                            setSnackbarData={setSnackbarData}
                            setHasSound={setHasSound}
                            setCachedHasSound={setCachedHasSound}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                        />
                    )}
                />
            </Route>
            <Route
                path={PAGE_ROUTE.physicalBook}
                element={(
                    <PhysicalBookView
                        currentSessionId={currentSessionId}
                        user={user}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            />
            <Route
                path={PAGE_ROUTE.characters}
                element={(
                    <CharactersView
                        hasSound={hasSound}
                        currentSessionId={currentSessionId}
                        user={user}
                        viewportDimensions={viewportDimensions}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            />
            <Route
                path={PAGE_ROUTE.treasureChest}
                element={(
                    <TreasureChestView
                        hasSound={hasSound}
                        currentSessionId={currentSessionId}
                        user={user}
                        viewportDimensions={viewportDimensions}
                        setCursorSigns={setCursorSigns}
                        setSnackbarData={setSnackbarData}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            >
                <Route
                    path=":cartridgeId"
                    element={(
                        <TreasureChestView
                            hasSound={hasSound}
                            currentSessionId={currentSessionId}
                            user={user}
                            viewportDimensions={viewportDimensions}
                            setCursorSigns={setCursorSigns}
                            setSnackbarData={setSnackbarData}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                        />
                    )}
                />
            </Route>
            <Route
                path={PAGE_ROUTE.socialEmergence}
                element={(
                    <SocialEmergenceView
                        hasSound={hasSound}
                        currentSessionId={currentSessionId}
                        user={user}
                        viewportDimensions={viewportDimensions}
                        cursorRef={customCursorRef}
                        setSnackbarData={setSnackbarData}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        setCursorIsHidden={setCursorIsHidden}
                    />
                )}
            />
            <Route
                path={PAGE_ROUTE.cart}
                element={(
                    <CartView
                        currentSessionId={currentSessionId}
                        user={user}
                        hasSound={hasSound}
                        setSnackbarData={setSnackbarData}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            />
            <Route
                path={`${PAGE_ROUTE.checkout}/:checkpoint`}
                element={(
                    <CheckoutView
                        currentSessionId={currentSessionId}
                        hasSound={hasSound}
                        user={user}
                        viewportDimensions={viewportDimensions}
                        snackbarData={snackbarData}
                        setInputFocused={setInputFocused}
                        setSnackbarData={setSnackbarData}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setCursorIsHidden={setCursorIsHidden}
                    />
                )}
            />
            <Route
                path={PAGE_ROUTE.unsubscribe}
                element={(
                    <UnsubscribeView
                        currentSessionId={currentSessionId}
                        user={user}
                        setUnsubscribeId={setUnsubscribeId}
                        setUnsubscribeStatus={setUnsubscribeStatus}
                    />
                )}
            >
                <Route
                    path=":userId"
                    element={(
                        <UnsubscribeView
                            currentSessionId={currentSessionId}
                            user={user}
                            setUnsubscribeId={setUnsubscribeId}
                            setUnsubscribeStatus={setUnsubscribeStatus}
                        />
                    )}
                />
            </Route>
            <Route
                path={PAGE_ROUTE.unsubscribeResult}
                element={(
                    <UnsubscribeResultView
                        currentSessionId={currentSessionId}
                        user={user}
                        unsubscribeId={unsubscribeId}
                        unsubscribeStatus={unsubscribeStatus}
                        setSnackbarData={setSnackbarData}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    />
                )}
            />
            <Route
                path="*"
                element={(
                    <LandingView
                        hasSound={hasSound}
                        viewportDimensions={viewportDimensions}
                        landingPageContentRevealed={landingPageContentRevealed}
                        cuneiformTabletRevealed={cuneiformTabletRevealed}
                        currentSessionId={currentSessionId}
                        user={user}
                        cachedHasSound={cachedHasSound}
                        setSnackbarData={setSnackbarData}
                        setLandingPageContentRevealed={setLandingPageContentRevealed}
                        setCuneiformTabletRevealed={setCuneiformTabletRevealed}
                        setInputFocused={setInputFocused}
                        setCursorSigns={setCursorSigns}
                        setHasSound={setHasSound}
                        setCachedHasSound={setCachedHasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        onCursorMouseDown={onCursorMouseDown}
                        onCursorMouseUp={onCursorMouseUp}
                    />
                )}
            />
        </Routes>
    ), [
        user,
        hasSound,
        cachedHasSound,
        currentSessionId,
        viewportDimensions,
        cuneiformTabletRevealed,
        landingPageContentRevealed,
        notifyUserToSignUpForWebBook,
        notifyUserToSignUpForDigitalBook,
        notifyUserToSignUpForPhysicalBook,
        notifyUserToSignUpToNavigateBook,
        nudgeUserToSignUpAfterAnnotation,
        snackbarData.visible,
        stickyPostBannerActive,
    ]);

    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
        >
            <Container>
                <CustomCursor
                    ref={customCursorRef}
                    isTouchDevice={detectTouchDevice(document)}
                    // Smooth expansion but swift motion while grasped
                    isLocked={(
                        cursorType === CURSOR_TYPE.engagedSmall
                        || cursorType === CURSOR_TYPE.line
                    ) && !temporarilyUnlockCursor}
                    isHidden={cursorIsHidden}
                    length={CURSOR_STANDARD_LENGTH}
                    borderWidth={CURSOR_STANDARD_BORDER_WIDTH}
                    transitionDuration={CURSOR_TRANSITION_DURATION}
                    lengthTransitionDuration={CURSOR_BORDER_LENGTH_TRANSITION_DURATION}
                    background={cursorSigns.length > 0
                        && cursorType === CURSOR_TYPE.engagedLarge
                        && !temporarilyUnlockCursor
                        ? CURSOR_EXPAND_BACKGROUND
                        : 'transparent'}
                    backgroundTranslucency={CURSOR_EXPAND_BACKGROUND_TRANSLUCENCY}
                >
                    <SmallCursorActionContainer
                        corner={smallCursorCorner}
                        isVisible={(
                            cursorTargetType === CURSOR_TARGET.input
                            || cursorType === CURSOR_TYPE.engagedSmall
                            || cursorType === CURSOR_TYPE.line
                        ) && cursorType !== CURSOR_TYPE.engagedLarge}
                        isLineCursor={cursorType === CURSOR_TYPE.line}
                        isGrabbing={temporarilyUnlockCursor}
                        length={SMALL_CURSOR_ACTION_CONTAINER_LENGTH}
                        background={CURSOR_EXPAND_BACKGROUND}
                        backgroundTranslucency={CURSOR_EXPAND_BACKGROUND_TRANSLUCENCY}
                        transitionDuration={CURSOR_TRANSITION_DURATION}
                    >
                        {CustomCursorSigns}
                    </SmallCursorActionContainer>
                    {(cursorType === CURSOR_TYPE.engagedLarge || (
                        cursorTargetType !== CURSOR_TARGET.input
                        && cursorType !== CURSOR_TYPE.engagedSmall
                        && cursorType !== CURSOR_TYPE.line
                    )) && (
                        CustomCursorSigns
                    )}
                </CustomCursor>
                <CursorSight
                    ref={smallCursorSightRef}
                    isVisible={
                        cursorType === CURSOR_TYPE.engagedSmall
                        && !temporarilyUnlockCursor
                    }
                    isTouchDevice={detectTouchDevice(document)}
                />
                {RouterRoutes}
                <SoundButtonOuterContainer
                    lightBackground={
                        !!routeIsUnsubscribePage
                        || (
                            stickyPostBannerActive
                            && (
                                !!routeIsReadPage
                                || !!routeIsReadPageAnnotationFocused
                            )
                        )
                        || !!routeIsCartPage
                        || !!routeIsCheckoutPage
                    }
                    userProfileDialogVisible={!!user}
                    cartButtonVisible={cartButtonVisible}
                    userProfileDialogExpanded={userProfileDialogExpanded}
                    transitionDuration={HEADER_BUTTON_TRANSITION_DURATION}
                >
                    <SoundButtonInnerContainer
                        lightBackground={
                            !!routeIsUnsubscribePage
                            || (
                                stickyPostBannerActive
                                && (
                                    !!routeIsReadPage
                                    || !!routeIsReadPageAnnotationFocused
                                )
                            )
                            || !!routeIsCartPage
                            || !!routeIsCheckoutPage
                        }
                        height={SOUND_BUTTON_HEIGHT}
                        className={HOVER_TARGET_CLASSNAME}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: (e) => onCursorEnter(
                                CURSOR_TARGET.sound,
                                [CURSOR_SIGN.click],
                                e.target as HTMLElement,
                            ),
                        } : {
                            onMouseEnter: (e) => onCursorEnter(
                                CURSOR_TARGET.sound,
                                [CURSOR_SIGN.click],
                                e.target as HTMLElement,
                            ),
                        })}
                        {...(detectTouchDevice(document) ? {
                            onTouchEnd: (e) => onCursorLeave(e),
                        } : {
                            onMouseLeave: (e) => onCursorLeave(e),
                        })}
                        onMouseDown={onSoundButtonClick}
                    >
                        <SoundButton
                            dark={
                                !!routeIsUnsubscribePage
                                || (
                                    stickyPostBannerActive
                                    && (
                                        !!routeIsReadPage
                                        || !!routeIsReadPageAnnotationFocused
                                    )
                                )
                                || !!routeIsCartPage
                                || !!routeIsCheckoutPage
                            }
                            viewBox={hasSound ? '0 0 12.91 12.91' : '0 0 11.37 9.7'}
                            height={SOUND_BUTTON_HEIGHT}
                            detectTouchDevice={detectTouchDevice(document)}
                        >
                            {hasSound
                                ? <Path d="M8.24,0V1.61H9.68V0ZM9.68,1.61V3.23H11.3V1.61ZM11.3,3.23V9.68h1.61V3.23Zm0,6.45H9.68V11.3H11.3V9.68ZM9.68,11.3H8.07v1.61H9.68V11.3ZM3.23,1.61V3.23H1.61V4.84H0V8.07H1.61V9.68H3.23V11.3H4.84V1.61ZM6.46,3.23V4.84H8.07V3.23ZM8.07,4.84V8.07H9.68V4.84Zm0,3.23H6.46V9.68H8.07V8.07Z" />
                                : <Path d="M3.25,0V1.62H1.62V3.23H0V6.47H1.62V8.08H3.25V9.7H4.87V0ZM6.5,3.23V4.85H8.12V3.23ZM8.12,4.85V6.47H9.75V4.85Zm1.63,0h1.62V3.23H9.75V4.85Zm0,1.62V8.08h1.62V6.47Zm-1.63,0H6.5V8.08H8.12V6.47Z" />}
                        </SoundButton>
                    </SoundButtonInnerContainer>
                </SoundButtonOuterContainer>
                <Transition
                    in={cartButtonVisible}
                    timeout={{
                        enter: HEADER_BUTTON_TRANSITION_DURATION,
                        exit: HEADER_BUTTON_TRANSITION_DURATION,
                    }}
                    appear
                    mountOnEnter
                    unmountOnExit
                >
                    {(state) => (
                        <CartButton
                            dark={
                                !!routeIsUnsubscribePage
                                || (
                                    stickyPostBannerActive
                                    && (
                                        !!routeIsReadPage
                                        || !!routeIsReadPageAnnotationFocused
                                    )
                                )
                                || !!routeIsCartPage
                                || !!routeIsCheckoutPage
                            }
                            height={CART_BUTTON_HEIGHT}
                            user={user}
                            currentSessionId={currentSessionId}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            style={{
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: 'left',
                                    offset: 10,
                                    duration: HEADER_BUTTON_TRANSITION_DURATION,
                                    easing: theme.motion.eagerEasing,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: 'left',
                                    offset: 10,
                                })[state],
                            }}
                        />
                    )}
                </Transition>
                <Transition
                    in={!!user}
                    timeout={{
                        enter: HEADER_BUTTON_TRANSITION_DURATION,
                        exit: HEADER_BUTTON_TRANSITION_DURATION,
                    }}
                    appear
                    mountOnEnter
                    unmountOnExit
                >
                    {(state) => (
                        <UserProfileDialog
                            user={user}
                            hasSound={hasSound}
                            lightBackground={
                                !!routeIsUnsubscribePage
                                || (
                                    stickyPostBannerActive
                                    && (
                                        !!routeIsReadPage
                                        || !!routeIsReadPageAnnotationFocused
                                    )
                                )
                                || !!routeIsCartPage
                                || !!routeIsCheckoutPage
                            }
                            currentSessionId={currentSessionId}
                            viewportDimensions={viewportDimensions}
                            isExpanded={userProfileDialogExpanded}
                            cartButtonVisible={cartButtonVisible}
                            nudgeUserToSignUpAfterAnnotation={nudgeUserToSignUpAfterAnnotation}
                            notifyUserToSignUpForWebBook={notifyUserToSignUpForWebBook}
                            notifyUserToSignUpForDigitalBook={notifyUserToSignUpForDigitalBook}
                            notifyUserToSignUpForPhysicalBook={notifyUserToSignUpForPhysicalBook}
                            notifyUserToSignUpToNavigateBook={notifyUserToSignUpToNavigateBook}
                            signedIn={signedIn}
                            setIsExpanded={setUserProfileDialogExpanded}
                            setSigningInOut={setSigningInOut}
                            setInputFocused={setInputFocused}
                            setUser={setUser}
                            setNudgeUserToSignUpAfterAnnotation={setNudgeUserToSignUpAfterAnnotation}
                            setNotifyUserToSignUpForWebBook={setNotifyUserToSignUpForWebBook}
                            setNotifyUserToSignUpForDigitalBook={setNotifyUserToSignUpForDigitalBook}
                            setNotifyUserToSignUpForPhysicalBook={setNotifyUserToSignUpForPhysicalBook}
                            setNotifyUserToSignUpToNavigateBook={setNotifyUserToSignUpToNavigateBook}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            setSnackbarData={setSnackbarData}
                            setSignedIn={setSignedIn}
                            style={{
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: 'left',
                                    offset: 10,
                                    duration: HEADER_BUTTON_TRANSITION_DURATION,
                                    easing: theme.motion.eagerEasing,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: 'left',
                                    offset: 10,
                                })[state],
                            }}
                        />
                    )}
                </Transition>
                <Snackbar
                    data={snackbarData}
                    hasSound={hasSound}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                />
            </Container>
        </ErrorBoundary>
    );
}

// Workaround for lack of hot module reloading
// https://dev.to/cronokirby/react-typescript-parcel-setting-up-hot-module-reloading-4f3f
declare const module: unknown;
export default hot(module)(App);
