// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';

// ===== Interfaces =====

import {
    IStoreItem,
}                               from '../interfaces';

const stripeGetStoreItems = async ({
    test,
}: {
    test: boolean,
}): Promise<Map<string, IStoreItem>> => {
    const functions = getFunctions();
    const getStoreItems = httpsCallable<
        Record<
            string,
            boolean | string
        >,
        {
            [k: string]: IStoreItem,
        }
    >(
        functions,
        'stripeGetStoreItems',
    );

    return getStoreItems({
        test,
    }).then((result) => {
        if (result.data) {
            const storeItems: Map<string, IStoreItem> = new Map(Object.entries(result.data));
            return storeItems;
        }
    }).catch((err) => err);
};

export default stripeGetStoreItems;
