// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

// ===== Interfaces =====

import {
    ICartItem,
    IStripePaymentIntentArgs,
}                               from '../interfaces';

const stripeCreatePaymentIntent = async ({
    test,
    cartItems,
    userId,
    userEmail,
    customerId,
}: IStripePaymentIntentArgs): Promise<Stripe.PaymentIntent> => {
    const functions = getFunctions();
    const createPaymentIntent = httpsCallable<
        Record<
            string,
            boolean | string | ICartItem[]
        >,
        Stripe.PaymentIntent
    >(
        functions,
        'stripeCreatePaymentIntent',
    );

    const args: Record<string, boolean | string | ICartItem[]> = {
        test,
        cartItems,
    };
    if (userId) args.userId = userId;
    if (userEmail) args.userEmail = userEmail;
    if (customerId) args.customerId = customerId;
    return createPaymentIntent(args).then((result) => {
        const paymentIntent = result.data as Stripe.PaymentIntent | Error;
        return paymentIntent;
    }).catch((err) => err);
};

export default stripeCreatePaymentIntent;
