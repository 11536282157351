/* eslint-disable @typescript-eslint/no-explicit-any */
// Repurposed from https://codesandbox.io/s/usetimeout-hook-z9v96
import { useEffect, useRef, useState } from 'react';
import { ITimeoutController } from '../interfaces';

const useTimeout = (callback: (arg?: any) => void,  delay: number | null): ITimeoutController => {
    const savedCallback = useRef<(arg?: any) => void>();

    // Remember the latest callback,
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const [isActive, setIsActive] = useState<boolean>(false);
    const [value, setValue] = useState<any | null>(null);

    // Set up timeout
    useEffect(() => {
        if (isActive && delay !== null) {
            const timeoutId = setTimeout(() => {
                if ((
                    value !== undefined
                    && value !== null
                ) && savedCallback.current) {
                    savedCallback.current(value);
                } else if (savedCallback.current) {
                    savedCallback.current();
                }
                setIsActive(false);
            }, delay);

            return () => clearTimeout(timeoutId);
        }
    }, [isActive, delay, value]);

    return {
        start: (val: any) => {
            if (
                val !== undefined
                && val !== null
            ) {
                setValue(val);
            }

            setIsActive(true);
        },
        clear: () => setIsActive(false),
    };
};

export default useTimeout;
