// ===== Packages =====

import {
    FirebaseError,
}                               from 'firebase/app';
import {
    User,
    getAuth,
    signInWithPopup,
    getRedirectResult,
    signInWithRedirect,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider,
    GithubAuthProvider,
    signInWithEmailAndPassword,
}                               from 'firebase/auth';

// ===== Enums =====

import {
    AUTHENTICATION_ERROR_CODE,
    AUTHENTICATION_TYPE,
}                               from '../enums';

interface SignInProps {
    type: AUTHENTICATION_TYPE,
    credentials?: {
        email: string,
        password: string,
    },
    onComplete: (user: User | null, accessToken?: string | undefined) => void,
    onError: (error: FirebaseError) => void,
}
// Popup Blocked Reference: // Reference: https://stackoverflow.com/questions/39898356/firebase-signinwithpopup-gives-auth-popup-blocked-when-used-via-built-in-browser
const signIn = ({
    type,
    credentials,
    onComplete,
    onError,
}: SignInProps): void => {
    const auth = getAuth();

    switch (type) {
    case AUTHENTICATION_TYPE.google: {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken || undefined;
                // The signed-in user info.
                onComplete(result.user, token);
            }).catch((error) => {
                if (error.code as AUTHENTICATION_ERROR_CODE === AUTHENTICATION_ERROR_CODE.popupBlocked) {
                    // On mobile, popups are blocked by browser so we much authenticate
                    // using a redirect
                    signInWithRedirect(auth, provider)
                        .then(() => {
                            getRedirectResult(auth).then((result) => {
                                // This gives you a Google Access Token. You can use it to access the Google API.
                                let token: string | undefined;
                                if (result) {
                                    const credential = GoogleAuthProvider.credentialFromResult(result);
                                    token = credential?.accessToken || undefined;
                                }
                                // The signed-in user info.
                                onComplete(result?.user || null, token);
                            });
                        }).catch((err) => {
                            onError(err);
                        });
                } else {
                    onError(error);
                }
            });
        break;
    }
    case AUTHENTICATION_TYPE.facebook: {
        const provider = new FacebookAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken || undefined;
                // The signed-in user info.
                onComplete(result.user, token);
            }).catch((error) => {
                if (error.code as AUTHENTICATION_ERROR_CODE === AUTHENTICATION_ERROR_CODE.popupBlocked) {
                    // On mobile, popups are blocked by browser so we much authenticate
                    // using a redirect
                    signInWithRedirect(auth, provider)
                        .then(() => {
                            getRedirectResult(auth).then((result) => {
                                // This gives you a Google Access Token. You can use it to access the Google API.
                                let token: string | undefined;
                                if (result) {
                                    const credential = FacebookAuthProvider.credentialFromResult(result);
                                    token = credential?.accessToken || undefined;
                                }
                                // The signed-in user info.
                                onComplete(result?.user || null, token);
                            });
                        }).catch((err) => {
                            onError(err);
                        });
                } else {
                    onError(error);
                }
            });
        break;
    }
    case AUTHENTICATION_TYPE.twitter: {
        const provider = new TwitterAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken || undefined;
                // The signed-in user info.
                onComplete(result.user, token);
            }).catch((error) => {
                if (error.code as AUTHENTICATION_ERROR_CODE === AUTHENTICATION_ERROR_CODE.popupBlocked) {
                    // On mobile, popups are blocked by browser so we much authenticate
                    // using a redirect
                    signInWithRedirect(auth, provider)
                        .then(() => {
                            getRedirectResult(auth).then((result) => {
                                // This gives you a Google Access Token. You can use it to access the Google API.
                                let token: string | undefined;
                                if (result) {
                                    const credential = TwitterAuthProvider.credentialFromResult(result);
                                    token = credential?.accessToken || undefined;
                                }
                                // The signed-in user info.
                                onComplete(result?.user || null, token);
                            });
                        }).catch((err) => {
                            onError(err);
                        });
                } else {
                    onError(error);
                }
            });
        break;
    }
    case AUTHENTICATION_TYPE.github: {
        const provider = new GithubAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GithubAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken || undefined;
                // The signed-in user info.
                onComplete(result.user, token);
            }).catch((error) => {
                if (error.code as AUTHENTICATION_ERROR_CODE === AUTHENTICATION_ERROR_CODE.popupBlocked) {
                    // On mobile, popups are blocked by browser so we much authenticate
                    // using a redirect
                    signInWithRedirect(auth, provider)
                        .then(() => {
                            getRedirectResult(auth).then((result) => {
                                // This gives you a Google Access Token. You can use it to access the Google API.
                                let token: string | undefined;
                                if (result) {
                                    const credential = GithubAuthProvider.credentialFromResult(result);
                                    token = credential?.accessToken || undefined;
                                }
                                // The signed-in user info.
                                onComplete(result?.user || null, token);
                            });
                        }).catch((err) => {
                            onError(err);
                        });
                } else {
                    onError(error);
                }
            });
        break;
    }
    default:
        if (credentials) {
            signInWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then((userCredential) => {
                    // Signed in
                    const { user } = userCredential;
                    onComplete(user);
                })
                .catch((error) => {
                    onError(error);
                });
        }
    }
};

export default signIn;
