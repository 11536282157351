const moveCursorToEnd = (input: HTMLInputElement): void => {
    const element = input;

    if (element.isContentEditable) {
        const textNode: Node = element.firstChild as Node;
        const cursorPosition = element.innerText.length;
        const range = document.createRange();
        range.setStart(textNode, cursorPosition);
        range.setEnd(textNode, cursorPosition);
        const sel = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);
    } else if (typeof input.selectionStart === 'number') {
        element.selectionStart = element.value.length;
        element.selectionEnd = element.value.length;
    }
};

export default moveCursorToEnd;
