// ** IMPORTANT **: Keep in sync with functions/src/index.ts
export enum CHECKOUT_SESSION_STATUS_TYPE {
    created = 'created',
    resumed = 'resumed',
    mailingInfoCollected = 'mailing-info-collected',
    shippingMethodSelected = 'shipping-method-selected',
    paymentInfoCollected = 'payment-info-collected',
    reviewed = 'reviewed',
    cardError = 'card-error',
    validationError = 'validation-error',
    apiError = 'api-error',
    idempotencyError = 'idempotency-error',
    invalidRequestError = 'invalid-request-error',
    unknownPaymentError = 'unknown-payment-error',
    requiresAction = 'requires-action',
    requiresPaymentMethod = 'requires-payment-method',
    requiresConfirmation = 'requires-confirmation',
    requiresCapture = 'requires-capture',
    unknownIntentStatus = 'unknown-intent-status',
    abandoned = 'abandoned',
    completed = 'completed',
}
