// ===== Packages =====

import normalizeUrl from 'normalize-url';

const DEFAULT_OPTIONS = {
    normalizeProtocol: true,
    normalizeHttps: true,
    stripFragment: true,
    removeQueryParameters: [/\w+/],
    removeTrailingSlash: true,
};

const normalizeURL = (url: string): string => {
    const noVars = url.split('&')[0];
    return normalizeUrl(noVars, DEFAULT_OPTIONS);
};

export default normalizeURL;
