import _CaptionElement              from './captionElement';
import _FigureContentElement        from './figureContentElement';
import _FigureElement, {
    DELETE_NODE_MARK,
}                                   from './figureElement';
import _GalleryElement              from './galleryElement';
import {
    FIGURE_ORIENTATION,
    FIGURE_CONTENT_TYPE,
}                                   from './enums';

export const Orientations = FIGURE_ORIENTATION;
export const FigureContentTypes = FIGURE_CONTENT_TYPE;
export const DELETE_FIGURE_NODE = DELETE_NODE_MARK;
export const CaptionElement = _CaptionElement;
export const FigureContentElement = _FigureContentElement;
export const FigureElement = _FigureElement;
export const GalleryElement = _GalleryElement;
