// ===== Referenced Resources =====

// https://github.com/react-grid-layout/react-resizable

// ===== Packages =====

import React,
{
    useRef,
    useState,
    useEffect,
    ReactElement,
    useCallback,
    useMemo,
}                                               from 'react';
import styled                                   from 'styled-components';
import {
    Resizable,
    ResizeCallbackData,
}                                               from 'react-resizable';
import {
    getDocs,
    getFirestore,
    collection,
    query,
    where,
    CollectionReference,
    DocumentData,
    Query,
    QuerySnapshot,
}                                               from 'firebase/firestore';
import {
    HttpsCallableResult,
}                                               from 'firebase/functions';
import {
    ref,
    getStorage,
    getDownloadURL,
}                                               from 'firebase/storage';
import { Transition }                           from 'react-transition-group';
import {
    useMatch,
    useParams,
    useNavigate,
    useSearchParams,
    useLocation,
}                                               from 'react-router-dom';
import {
    Grid,
    GridCellProps,
}                                               from 'react-virtualized';
// We've imported Framer Motion v4.1.17 because anything higher has an unresolved error
// Reference: https://stackoverflow.com/questions/69769360/error-importing-framer-motion-v5-in-react-with-create-react-app
import Sheet                                    from 'react-modal-sheet';
import { ReactSVG }                             from 'react-svg';

// ===== Components =====

import Cartridge                                from '../Cartridge';
import FilterSelector                           from '../FilterSelector';
import YouTubeEmbed                             from '../YouTubeEmbed';
import VimeoEmbed                               from '../VimeoEmbed';
import Spinner                                  from '../Spinner';

// ===== Hooks =====

import {
    useTimeout,
}                                               from '../../hooks';

// ===== Services =====

import {
    playAudio,
    getVimeoId,
    chunkArray,
    getYouTubeParams,
    applyCharacterLimit,
    findParentNodeWithClass,
    getFilterPath,
    getFilterTypePathKey,
    getFilterType,
    tagHumanIDToID,
    tagIDToHumanID,
    updatePageTitle,
    hexToRgb,
    fetchURLMetadata,
    recordUserAction,
    detectTouchDevice,
    deepEqual,
}                                               from '../../services';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    CARTRIDGE_STATE,
    CARTRIDGE,
    PAGE_ROUTE,
    FIRESTORE_COLLECTION,
    FILTER_TYPE,
    FILTER_TYPE_PATH_KEY,
    USER_ACTION_TYPE,
}                                               from '../../enums';

// ===== Interfaces =====

import {
    ITag,
    ICartridgeItem,
    IDimension,
    ILinkMetadata,
    ICachedInsertCartridgeArgs,
    RenderedSection,
    IVirtualizedGridCell,
    IUserItem,
    ISnackbarItem,
}                                               from '../../interfaces';

// ===== Images =====

import VerascopeLogo                            from '../../images/verascope-logo-silhouette.svg';
import LinkIcon                                 from '../../images/link.svg';
import CautionIcon                              from '../../images/caution.svg';

// ===== Sounds =====

import ObjectGrasp                              from '../../sounds/object_grasp.mp3';
import ObjectThump                              from '../../sounds/object_thump.mp3';
import CartridgeSlide                           from '../../sounds/cartridge_slide.mp3';
import CartridgeInsert                          from '../../sounds/cartridge_insert.mp3';
import CartridgeEject                           from '../../sounds/cartridge_eject.mp3';
import SelectTag                                from '../../sounds/select_tag.mp3';

// ===== Constants =====

import {
    BODY_FONT_SIZE,
    CURSOR_Z_INDEX,
    CARTRIDGE_INSERTING_Z_INDEX,
    CARTRIDGE_FOCUSED_Z_INDEX,
    DEFAULT_AUDIO_VOLUME,
    DEFAULT_CSS_TRANSITION_DURATION,
    EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION,
    EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    UNASSIGNED_ERROR_MESSAGE,
    TAG_TRANSITION_DURATION,
    CARTRIDGE_WIDTH,
    CARTRIDGE_HEIGHT,
    CARTRIDGE_GRID_MARGIN_TOP,
    CARTRIDGE_GRID_MARGIN_TOP_SMALL_VIEWPORT,
    CARTRIDGE_CLASSNAME,
    FIRESTORE_ARRAY_COMPARISON_LIMIT,
    DETAIL_IMAGE_CONTAINER_WIDTH,
    CARTRIDGE_GRID_SPACING,
    DETAIL_SHEET_CLASSNAME,
    DETAIL_VIEW_BACKGROUND,
    DETAIL_SHEET_HEIGHT_REDUCTION,
    DETAIL_SHEET_INSERTED_CARTRIDGE_TOP,
    CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT,
    DETAIL_VIEW_HANDLEBAR_WIDTH,
    DETAIL_VIEW_HANDLEBAR_HEIGHT,
    DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH,
    HOVER_TARGET_CLASSNAME,
    DETAIL_VIEW_Z_INDEX,
    HANDLEBAR_TRANSITION_DURATION,
    DETAIL_VIEW_TRANSITION_DURATION,
    DETAIL_SHEET_ENTER_TRANSITION_DURATION,
    DETAIL_SHEET_EXIT_TRANSITION_DURATION,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    MILLISECONDS_IN_A_SECOND,
}                                               from '../../constants/generalConstants';
import CURSOR_SIGN                              from '../../constants/cursorSigns';
import CARTRIDGE_TYPE                           from '../../constants/cartridgeType';
import ID_TO_THEME                              from '../../constants/IDToTheme';
import ID_TO_TYPE                               from '../../constants/IDToType';
import ID_TO_AUTHOR                             from '../../constants/IDToAuthor';
import ID_TO_ITEM                               from '../../constants/IDToItem';
import THEME_TO_ID                              from '../../constants/ThemeToID';
import TYPE_TO_ID                               from '../../constants/TypeToID';
import AUTHOR_TO_ID                             from '../../constants/AuthorToID';
import ITEM_TO_ID                               from '../../constants/ItemToID';
import MEDIA_QUERY_SIZE                         from '../../constants/mediaQuerySizes';

// ===== Styles =====

import { theme }                                from '../../themes/theme-context';
import {
    Container,
    DetailInfoBanner,
    DetailImageContainer,
    DetailTextContainer,
    DetailImage,
    DetailTitle,
    DetailAuthors,
    DetailType,
    ThemeLabel,
    CartridgeContainer,
    CartridgeInserted,
    EjectButton,
    EjectButtonIcon,
    DetailContentContainer,
    LinkMetadataContainer,
    LinkMetadataImage,
    LinkMetadataTitle,
    LinkMetadataDescription,
    LinkMetadataURL,
    LinkButton,
    LinkMetadataImagePlaceholder,
    LinkMetadataImagePlaceholderIcon,
    TagsContainer,
    GridCell,
    SpinnerContainer,
    EjectButtonContainer,
    CartridgeDetailContainer,
}                                               from './styles';
import {
    PageLogo,
    PageTitle,
    PlaceholderBox,
    Tag,
    TagText,
    DetailView,
    PageSubtitle,
    Handlebar,
    HandlebarContainer,
}                                               from '../../styles';

interface Props {
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function TreasureChestView({
    hasSound,
    user,
    currentSessionId,
    viewportDimensions,
    setCursorSigns,
    setSnackbarData,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====

    const MIN_DETAIL_SECTION_WIDTH = 500;
    const MIN_CARTRIDGE_SECTION_WIDTH = 400;
    const DETAIL_TITLE_FONT_MULTIPLIER = 2;
    const DETAIL_AUTHORS_FONT_MULTIPLIER = 1.3;
    const DETAIL_TYPE_FONT_MULTIPLIER = 0.8;
    const THEME_LABEL_FONT_MULTIPLIER = 1;
    const DETAIL_IMAGE_CONTAINER_PADDING = 20;
    const DETAIL_IMAGE_PLACEHOLDER_LENGTH = 200;
    const DETAIL_TYPE_PLACEHOLDER_WIDTH = 60;
    const THEME_LABEL_PLACEHOLDER_WIDTH = 80;
    const LINK_MAX_TITLE_COUNT = 30;
    const TAG_HEIGHT = 30;
    const CARTRIDGE_OVERSCAN_ROW_COUNT = 1;
    const DETAIL_IMAGE_MAX_WIDTH = 200;
    const DETAIL_IMAGE_MAX_HEIGHT = 150;
    const HANDLEBAR_CONTAINER_BACKGROUND_RESTING = '#143035';
    const HANDLEBAR_CONTAINER_BACKGROUND_STEERING = theme.color.white;
    const HANDLEBAR_CONTAINER_HOVER_BACKGROUND_RESTING = theme.color.blue100;
    const HANDLEBAR_CONTAINER_HOVER_BACKGROUND_STEERING = theme.color.white;
    const SNACKBAR_MESSAGE_FETCH_FILTERS_ERROR = 'There was a problem fetching cartridge filters. Please reload page.';
    const SNACKBAR_MESSAGE_INSERT_CARTRIDGE_FIND_ERROR = 'Could not find cartridge. Please select it through the Treasure Chest';
    const SNACKBAR_MESSAGE_FETCH_DETAIL_VIEW_IMAGE_ERROR = 'There was a problem fetching cartridge image.';

    // ===== Refs =====

    // ----- Sound Clips
    const handlebarGraspClip = useRef<HTMLAudioElement>(new Audio());
    const resizeConstraintThumpClip = useRef<HTMLAudioElement>(new Audio());
    const cartridgeThudClip = useRef<HTMLAudioElement>(new Audio());
    const cartridgeSlideClip = useRef<HTMLAudioElement>(new Audio());
    const cartridgeInsertClip = useRef<HTMLAudioElement>(new Audio());
    const cartridgeEjectClip = useRef<HTMLAudioElement>(new Audio());
    const selectTagClip = useRef<HTMLAudioElement>(new Audio());

    // ----- Views

    const DETAIL_VIEW_REF_NAME = 'detailViewRef';
    const DETAIL_INFO_BANNER_REF_NAME = 'detailInfoBannerRef';
    const detailViewRef = useRef<HTMLDivElement>(null);
    const detailInfoBannerRef = useRef<HTMLDivElement>(null);
    const detailContentContainerRef = useRef<HTMLDivElement>(null);
    const cartridgeGridRef = useRef<Grid>(null);

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const detailViewRevealed = useMatch(`${PAGE_ROUTE.treasureChest}/:cartridgeId`);
    const [searchParams] = useSearchParams();

    // ===== State =====

    // stores whether we've rendered component before
    // used to extract filters from url initially
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    // stores cartridge data
    const [cartridges, setCartridges] = useState<Map<string, ICartridgeItem>>(new Map());
    // stores section of cartridges rendered by react-virtualized
    const [renderedSection, setRenderedSection] = useState<RenderedSection | null>(null);
    // the width of the screen (%) occupied by resizable detail section
    const [detailViewWidth, setDetailViewWidth] = useState<number>(Math.max(
        MIN_DETAIL_SECTION_WIDTH + 100,
        Math.min(0.6 * viewportDimensions.width, viewportDimensions.width - MIN_CARTRIDGE_SECTION_WIDTH),
    ));
    // stores current selected cartridge data
    const [detailViewData, setDetailViewData] = useState< { data: ICartridgeItem, cell: IVirtualizedGridCell } | null>(null);
    // stores path to current selected cartridge data image
    const [detailViewImagePath, setDetailViewImagePath] = useState<string | null>(null);
    // stores url to current selected cartridge data image
    const [detailViewImageURL, setDetailViewImageURL] = useState<string | null>(null);
    // stores computed width of cartridge container
    const [cartridgeContainerWidth, setCartridgeContainerWidth] = useState<number>(detailViewRevealed && viewportDimensions.width - detailViewWidth
        ? viewportDimensions.width - detailViewWidth
        : viewportDimensions.width);
    // stores computed columns of cartridge react-virtualized grid
    const [cartridgeColumns, setCartridgeColumns] = useState<number>(0);
    // indicates whether detail view is being resized
    const [isSteering, setIsSteering] = useState<boolean>(false);
    // indicates whether the cursor is over the split view handlebar
    const [handlebarHovered, setHandlebarHovered] = useState<boolean>(false);
    // stores the minimum width of the detail view permissible
    const [hitDetailMinimum, setHitDetailMinimum] = useState<boolean>(false);
    // stores the minimum width of the cartridge view permissible
    const [hitCartridgeMinimum, setHitCartridgeMinimum] = useState<boolean>(false);
    // stores total amount of cartidges
    const [cartridgeCount, setCartridgeCount] = useState<number>(0);
    // indicates whether cartidges should be visible
    const [showCartridges, setShowCartridges] = useState<boolean>(false);
    // indicates which cartridge should be placed in foreground
    const [foregroundedCartridge, setForegroundedCartridge] = useState<string | null>(null);
    // indicates whether cartridge has been inserted
    const [cartridgeInserted, setCartridgeInserted] = useState<string | null>(null);
    // indicates whether cartridge is being inserted
    const [insertingCartridge, setInsertingCartridge] = useState<string | null>(null);
    // indicates whether cartridge is being ejected
    const [ejectingCartridge, setEjectingCartridge] = useState<string | null>(null);
    // caches insert cartridge arguments if we need to eject an cartridge before execution
    const [cachedInsertCartridgeArgs, setCachedInsertCartridgeArgs] = useState<ICachedInsertCartridgeArgs | null>(null);
    // stores height of detail view info banner
    const [detailViewInfoBannerHeight, setDetailViewInfoBannerHeight] = useState<number>(0);
    // indicates whether detail view embeddings should resize
    const [triggerEmbeddingResize, setTriggerEmbeddingResize] = useState<boolean>(false);
    // stores OG Metadata of cartridge with website
    const [linkMetadata, setLinkMetadata] = useState<ILinkMetadata | null>(null);
    // stores applied filters
    const [appliedFilters, setAppliedFilters] = useState<ITag[]>([]);
    // stores available filters
    const [filters, setFilters] = useState<Map<FILTER_TYPE, Map<string, ITag>>>(new Map());
    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);

    // ===== Animation Constants =====

    const CARTRIDGES_DELAY_DURATION = 800;
    const CARTRIDGE_SCROLL_DELAY_DURATION = 1000;
    const CARTRIDGE_INSERT_EJECT_DURATION = 500;
    const CARTRIDGE_EJECT_DELAY_DURATION = 150;
    const EJECT_BUTTON_ENTER_TRANSITION_DURATION = DEFAULT_CSS_TRANSITION_DURATION;
    const EJECT_BUTTON_EXIT_TRANSITION_DURATION = 200;
    const DETAIL_INFO_REVEAL_DELAY_DURATION = DETAIL_VIEW_TRANSITION_DURATION
        + CARTRIDGE_INSERT_EJECT_DURATION
        + EJECT_BUTTON_ENTER_TRANSITION_DURATION;
    const DETAIL_VIEW_ELEMENT_COUNT = 5;
    const DETAIL_VIEW_IMAGE_STAGGER_INDEX = 0;
    const DETAIL_VIEW_TYPE_STAGGER_INDEX = 1;
    const DETAIL_VIEW_TITLE_STAGGER_INDEX = 2;
    const DETAIL_VIEW_AUTHORS_STAGGER_INDEX = 3;
    const DETAIL_VIEW_THEME_LABEL_STAGGER_INDEX = 4;
    const DETAIL_VIEW_CONTENT_TRANSITION_DURATION = 200;
    const LINK_BUTTON_ENTER_TRANSITION_DURATION = DEFAULT_CSS_TRANSITION_DURATION;
    const SWITCH_CARTRIDGE_DELAY_DURATION = 500;
    const CARTRIDGE_ANIMATION_X_OFFSET = -40;
    const CARTRIDGE_ANIMATION_Y_OFFSET = 9;
    // Duration to delay filtering cartridges upon page load with filters
    const PAGE_LOAD_FILTER_DELAY_DURATION = MILLISECONDS_IN_A_SECOND;

    // ===== Methods =====

    /* Helper Function */
    const fetchPropertyData = useCallback(async (): Promise<{
        themes: Map<string, ITag>,
        type: Map<string, string>,
        authors: Map<string, string>
    }> => {
        // Initialize DB
        const db = getFirestore();

        // Get all themes
        const themeMap: Map<string, ITag> = new Map();
        const themeQuery = query(collection(db, FIRESTORE_COLLECTION.treasureChestThemes));
        try {
            const themeSnap = await getDocs(themeQuery);

            themeSnap.forEach((document) => {
                const data = document.data();
                const themeTag: ITag = {
                    id: document.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    humanId: ID_TO_THEME.get(document.id)!,
                    type: FILTER_TYPE.theme,
                    text: data.text,
                    items: data.items,
                };
                // Add theme to theme map
                themeMap.set(document.id, themeTag);
            });
        } catch (e) {
            throw Error(`Unable to fetch theme data: ${e}`);
        }

        // Get all types
        const typeMap: Map<string, string> = new Map();
        const typeQuery = query(collection(db, FIRESTORE_COLLECTION.treasureChestTypes));
        try {
            const typeSnap = await getDocs(typeQuery);

            typeSnap.forEach((document) => {
                const data = document.data();
                // Add type to type map
                typeMap.set(document.id, data.text);
            });
        } catch (e) {
            throw Error(`Unable to fetch type data: ${e}`);
        }

        // Get all authors
        const authorMap: Map<string, string> = new Map();
        const authorQuery = query(collection(db, FIRESTORE_COLLECTION.treasureChestAuthors));
        try {
            const authorSnap = await getDocs(authorQuery);

            authorSnap.forEach((document) => {
                const data = document.data();
                // Add author to author map
                authorMap.set(document.id, data.text);
            });
        } catch (e) {
            throw Error(`Unable to fetch author data: ${e}`);
        }

        return {
            themes: themeMap,
            type: typeMap,
            authors: authorMap,
        };
    }, []);

    /* Helper Function */
    const fetchFilteredCartridges = async (flters: ITag[]): Promise<Map<string, ICartridgeItem>> => {
        let set: Set<string> = new Set();
        const map: Map<string, ICartridgeItem> = new Map();

        // Initialize DB
        const db = getFirestore();

        // Fetch Theme, Type, and Author Data
        const {
            themes: themeMap,
            type: typeMap,
            authors: authorMap,
        } = await fetchPropertyData();

        if (flters.length > FIRESTORE_ARRAY_COMPARISON_LIMIT) {
            let chunkResults: string[][];

            // chunk type filters into groups of ten
            // firestore query system can only handle ten at a time
            // should never have more than two depth levels in stack because of limit
            const filterChunks: ITag[][] = chunkArray(flters, FIRESTORE_ARRAY_COMPARISON_LIMIT) as ITag[][];
            const filterPromises: Promise<Map<string, ICartridgeItem>>[] = [];
            for (let i = 0; i < filterChunks.length; i += 1) {
                filterPromises.push(fetchFilteredCartridges(filterChunks[i]));
            }

            try {
                const results = await Promise.all(filterPromises);
                chunkResults = results.map((result: Map<string, ICartridgeItem>) => Array.from(result.keys()));
            } catch (e) {
                throw Error(`Unable to fetch filtered treasure chest items: ${e}`);
            }

            // Get union of filter chunks
            let union: Set<string> = new Set(chunkResults[0]);
            for (let i = 1; i < chunkResults.length; i += 1) {
                union = new Set([
                    ...Array.from(union),
                    ...chunkResults[i],
                ]);
            }

            // Find intersection of all chunk arrays
            let intersection = new Set(union);
            for (let i = 0; i < chunkResults.length; i += 1) {
                const chunkSet = new Set(chunkResults[i]);
                intersection = new Set(Array.from(intersection).filter((filterId) => chunkSet.has(filterId)));
            }

            set = new Set(intersection);
        } else {
            const filterPromises: Promise<QuerySnapshot<DocumentData>>[] = [];
            for (let i = 0; i < flters.length; i += 1) {
                const filter = flters[i];
                let q: Query<DocumentData>;
                if (
                    filter.type === FILTER_TYPE.theme
                    || filter.type === FILTER_TYPE.author
                ) {
                    q = query(
                        collection(db, FIRESTORE_COLLECTION.treasureChestItems),
                        where(filter.type, 'array-contains', filter.id),
                    );
                } else {
                    q = query(
                        collection(db, FIRESTORE_COLLECTION.treasureChestItems),
                        where(filter.type, '==', filter.id),
                    );
                }
                filterPromises.push(getDocs(q));
            }

            // Form union of filter results
            const union: Set<string> = new Set();
            const filteredResults: Set<string>[] = [];
            try {
                const results = await Promise.all(filterPromises);
                results.forEach((result) => {
                    // Add results to filtered results set array
                    const resultSet: Set<string> = new Set();
                    result.forEach((document) => {
                        const data = document.data();

                        // Add item id to result set
                        resultSet.add(data.id);

                        // Add item id to union set
                        union.add(data.id);

                        // Determine color
                        // Inherit previous color or
                        // Generate a new one if no history
                        const color = cartridges.get(data.id)?.color
                            || generateCartridgeColor()
                            || CARTRIDGE.orangeRegular;

                        // Add media data to item map
                        map.set(document.id, {
                            id: data.id,
                            title: data.title,
                            link: data.link,
                            imagePath: data.imagePath,
                            authors: data.authors,
                            type: data.type,
                            themeIds: data.themes,
                            color,
                        });
                    });
                    filteredResults.push(resultSet);
                });
            } catch (e) {
                throw Error(`Unable to fetch filtered treasure chest items: ${e}`);
            }

            // Find intersection of all filtered treasure chest items
            let intersection = Array.from(union);
            for (let i = 0; i < filteredResults.length; i += 1) {
                const itemSet = filteredResults[i];
                intersection = intersection.filter((itemId) => itemSet.has(itemId));
            }

            set = new Set(intersection);
        }

        const filteredCartridges: Map<string, ICartridgeItem> = new Map();
        set.forEach((id) => {
            const data = map.get(id);
            if (data) {
                // Determine color
                // Inherit previous color or
                // Generate a new one if no history
                const color = cartridges.get(data.id)?.color
                    || generateCartridgeColor()
                    || CARTRIDGE.orangeRegular;

                const cartridge: ICartridgeItem = {
                    id: data.id,
                    title: data.title,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    authors: data.authors.map((userId: string): string => authorMap.get(userId)!),
                    imagePath: data.imagePath,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    type: typeMap.get(data.type)!,
                    link: data.link,
                    themeIds: data.themeIds,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    themeTags: data.themeIds.map((themeId: string): ITag => themeMap.get(themeId)!),
                    color,
                };
                filteredCartridges.set(cartridge.id, cartridge);
            }
        });

        return filteredCartridges;
    };

    const fetchCartridgeData = useCallback(async (): Promise<void> => {
        // Initialize DB
        const db = getFirestore();

        if (appliedFilters.length > 0) {
            fetchFilteredCartridges(appliedFilters).then((results) => {
                setCartridges(results);

                if (cartridgeCount === 0) {
                    // this should only happen once
                    // when we fetch cartridges for the first time
                    setCartridgeCount(results.size);
                }
            });
        } else {
            // No filters
            // Return all cartridges
            const {
                themes: themeMap,
                type: typeMap,
                authors: authorMap,
            } = await fetchPropertyData();
            const cartridgeMap: Map<string, ICartridgeItem> = new Map();
            const q = query(collection(db, FIRESTORE_COLLECTION.treasureChestItems));
            try {
                const snapshot = await getDocs(q);
                snapshot.forEach((document) => {
                    const data = document.data();
                    // Determine color
                    // Inherit previous color or
                    // Generate a new one if no history
                    const color = cartridges.get(data.id)?.color
                        || generateCartridgeColor()
                        || CARTRIDGE.orangeRegular;

                    // Add treasure chest item to cartridge array
                    cartridgeMap.set(
                        data.id,
                        {
                            id: data.id,
                            title: data.title,
                            link: data.link,
                            imagePath: data.imagePath,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            authors: (data.authors as string[]).map((userId: string): string => authorMap.get(userId)!),
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            type: typeMap.get(data.type)!,
                            themeIds: data.themes,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            themeTags: data.themes.map((themeId: string): ITag => themeMap.get(themeId)!),
                            color,
                        },
                    );
                });
                setCartridges(cartridgeMap);

                if (cartridgeCount === 0) {
                    // this should only happen once
                    // when we fetch cartridges for the first time
                    setCartridgeCount(cartridgeMap.size);
                }
            } catch (e) {
                throw Error(`Unable to fetch treasure chest items: ${e}`);
            }
        }
    }, [appliedFilters]);

    /* Helper Function */
    const fetchFiltersOfType = useCallback(async (
        type: FILTER_TYPE,
        typeRef: CollectionReference<DocumentData>,
    ): Promise<{ set: Set<string>, map: Map<string, ITag> }> => {
        const set: Set<string> = new Set();
        const map: Map<string, ITag> = new Map<string, ITag>();
        let humanReadableIDMap: Map<string, string>;
        if (type === FILTER_TYPE.theme) {
            humanReadableIDMap = ID_TO_THEME;
        } else if (type === FILTER_TYPE.type) {
            humanReadableIDMap = ID_TO_TYPE;
        } else {
            humanReadableIDMap = ID_TO_AUTHOR;
        }

        // Create set with cartridge IDs
        const cartridgeIDs = Array.from(cartridges.keys());
        const cartridgeIDSet = new Set(cartridgeIDs);

        const itemChunks: string[][] = chunkArray(cartridgeIDs, FIRESTORE_ARRAY_COMPARISON_LIMIT) as string[][];
        const filterPromises: Promise<QuerySnapshot<DocumentData>>[] = [];
        for (let i = 0; i < itemChunks.length; i += 1) {
            const q = query(
                typeRef,
                where('items', 'array-contains-any', itemChunks[i]),
            );
            filterPromises.push(getDocs(q));
        }

        try {
            const results = await Promise.all(filterPromises);
            results.forEach((result) => {
                result.forEach((document) => {
                    const data = document.data();

                    // Save to map
                    map.set(data.id, {
                        id: document.id,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        humanId: humanReadableIDMap.get(document.id)!,
                        type,
                        text: data.text,
                        items: data.items.filter((itemId: string) => cartridgeIDSet.has(itemId)),
                    });

                    // Add url to chunk array
                    set.add(data.id);
                });
            });
        } catch (e) {
            throw Error(`Unable to fetch filters: ${e}`);
        }

        return {
            set,
            map,
        };
    }, [cartridges]);

    // Referenced: https://2ality.com/2015/01/es6-set-operations.html
    // Referenced: https://stackoverflow.com/questions/20069828/how-to-convert-set-to-array
    // Referenced: https://stackoverflow.com/questions/31091772/javascript-es6-computational-time-complexity-of-collections
    const fetchAvailableFilters = useCallback(async (): Promise<void> => {
        // Create set with cartridge IDs
        const cartridgeIDs = Array.from(cartridges.keys());
        const cartridgeIDSet = new Set(cartridgeIDs);

        // Initialize DB
        const db = getFirestore();

        if (appliedFilters.length > 0) {
            // Create Queries + Fetch available filters
            const themeRef = collection(db, FIRESTORE_COLLECTION.treasureChestThemes);
            const typeRef = collection(db, FIRESTORE_COLLECTION.treasureChestTypes);
            const authorRef = collection(db, FIRESTORE_COLLECTION.treasureChestAuthors);
            Promise.all([
                fetchFiltersOfType(
                    FILTER_TYPE.theme,
                    themeRef,
                ),
                fetchFiltersOfType(
                    FILTER_TYPE.type,
                    typeRef,
                ),
                fetchFiltersOfType(
                    FILTER_TYPE.author,
                    authorRef,
                ),
            ]).then((results) => {
                const newFilters: Map<FILTER_TYPE, Map<string, ITag>> = new Map();
                const availableThemeFilters: Map<string, ITag> = new Map();
                results[0].set.forEach((tagId: string) => {
                    const tag = results[0].map.get(tagId);
                    if (tag) {
                        availableThemeFilters.set(tag.id, tag);
                    }
                });
                newFilters.set(FILTER_TYPE.theme, availableThemeFilters);

                const availableTypeFilters: Map<string, ITag> = new Map();
                results[1].set.forEach((tagId: string) => {
                    const tag = results[1].map.get(tagId);
                    if (tag) {
                        availableTypeFilters.set(tag.id, tag);
                    }
                });
                newFilters.set(FILTER_TYPE.type, availableTypeFilters);

                const availableAuthorFilters: Map<string, ITag> = new Map();
                results[2].set.forEach((tagId: string) => {
                    const tag = results[2].map.get(tagId);
                    if (tag) {
                        availableAuthorFilters.set(tag.id, tag);
                    }
                });
                newFilters.set(FILTER_TYPE.author, availableAuthorFilters);

                setFilters(newFilters);
            }).catch(() => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_FETCH_FILTERS_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            });
        } else {
            // Create Queries + Fetch available filters
            const themeQuery = query(collection(db, FIRESTORE_COLLECTION.treasureChestThemes));
            const typeQuery = query(collection(db, FIRESTORE_COLLECTION.treasureChestTypes));
            const authorQuery = query(collection(db, FIRESTORE_COLLECTION.treasureChestAuthors));
            Promise.all([
                getDocs(themeQuery),
                getDocs(typeQuery),
                getDocs(authorQuery),
            ]).then((results) => {
                const newFilters: Map<FILTER_TYPE, Map<string, ITag>> = new Map();
                const availableThemeFilters: Map<string, ITag> = new Map();
                results[0].forEach((document) => {
                    const data = document.data();
                    const items = data.items.filter((itemId: string) => cartridgeIDSet.has(itemId));
                    if (items.length > 0) {
                        availableThemeFilters.set(
                            document.id,
                            {
                                id: document.id,
                                humanId: ID_TO_THEME.get(document.id)!,
                                type: FILTER_TYPE.theme,
                                text: data.text,
                                items,
                            },
                        );
                    }
                });
                newFilters.set(FILTER_TYPE.theme, availableThemeFilters);

                const availableTypeFilters: Map<string, ITag> = new Map();
                results[1].forEach((document) => {
                    const data = document.data();
                    const items = data.items.filter((itemId: string) => cartridgeIDSet.has(itemId));
                    if (items.length > 0) {
                        availableTypeFilters.set(
                            document.id,
                            {
                                id: document.id,
                                humanId: ID_TO_TYPE.get(document.id)!,
                                type: FILTER_TYPE.type,
                                text: data.text,
                                items,
                            },
                        );
                    }
                });
                newFilters.set(FILTER_TYPE.type, availableTypeFilters);

                const availableAuthorFilters: Map<string, ITag> = new Map();
                results[2].forEach((document) => {
                    const data = document.data();
                    const items = data.items.filter((itemId: string) => cartridgeIDSet.has(itemId));
                    if (items.length > 0) {
                        availableAuthorFilters.set(
                            document.id,
                            {
                                id: document.id,
                                humanId: ID_TO_AUTHOR.get(document.id)!,
                                type: FILTER_TYPE.author,
                                text: data.text,
                                items,
                            },
                        );
                    }
                });
                newFilters.set(FILTER_TYPE.author, availableAuthorFilters);
                setFilters(newFilters);
            }).catch((e) => {
                throw Error(`Unable to fetch filters: ${e}`);
            });
        }
    }, [cartridges]);

    const generateCartridgeColor = (): CARTRIDGE => {
        const colorCount = Object.keys(CARTRIDGE).length;
        const randomIndex = Math.round(Math.random() * colorCount);
        const randomColor = Object.values(CARTRIDGE)[randomIndex];
        return randomColor;
    };

    const extractURLFilters = async (): Promise<void> => {
        const themeFilters: ITag[] = [];
        const typeFilters: ITag[] = [];
        const authorFilters: ITag[] = [];
        if (searchParams.has(FILTER_TYPE_PATH_KEY.theme) && filters.has(FILTER_TYPE.theme)) {
            const themeIds: string[] = searchParams.getAll(FILTER_TYPE_PATH_KEY.theme);
            themeIds.forEach((humanId: string) => {
                const tag = filters.get(FILTER_TYPE.theme)!.get(THEME_TO_ID.get(humanId)!);
                if (tag) {
                    themeFilters.push(tag);
                }
            });
        }

        if (searchParams.has(FILTER_TYPE_PATH_KEY.type) && filters.has(FILTER_TYPE.type)) {
            const typeIds: string[] = searchParams.getAll(FILTER_TYPE_PATH_KEY.type);
            typeIds.forEach((humanId: string) => {
                const tag = filters.get(FILTER_TYPE.type)!.get(TYPE_TO_ID.get(humanId)!);
                if (tag) {
                    typeFilters.push(tag);
                }
            });
        }

        if (searchParams.has(FILTER_TYPE_PATH_KEY.author) && filters.has(FILTER_TYPE.author)) {
            const userIds: string[] = searchParams.getAll(FILTER_TYPE_PATH_KEY.author);
            userIds.forEach((humanId: string) => {
                const tag = filters.get(FILTER_TYPE.author)!.get(AUTHOR_TO_ID.get(humanId)!);
                if (tag) {
                    authorFilters.push(tag);
                }
            });
        }

        const newAppliedFilters = [
            ...themeFilters,
            ...typeFilters,
            ...authorFilters,
        ];

        if (!deepEqual(appliedFilters, newAppliedFilters)) setAppliedFilters(newAppliedFilters);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.filterCartridges,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    filters: newAppliedFilters,
                },
            });
        }
    };

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.treasureChest,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const onHandlebarEnter = (e: React.MouseEvent): void => {
        setHandlebarHovered(true);
        if (!isSteering) {
            onCursorEnter(
                CURSOR_TARGET.handlebar,
                [CURSOR_SIGN.grab],
                e.target as HTMLElement,
            );
        }
    };

    const onHandlebarLeave = (e: React.MouseEvent): void => {
        setHandlebarHovered(false);
        if (!isSteering) {
            onCursorLeave(e);
        }
    };

    const onResizeStart = (e: React.SyntheticEvent): void => {
        if (detailViewRevealed) {
            setIsSteering(true);
            onCursorEnter(
                CURSOR_TARGET.handlebar,
                [CURSOR_SIGN.horizontalSteer],
                e.target as HTMLElement,
            );

            // Play Sound
            if (hasSound && handlebarGraspClip.current) {
                handlebarGraspClip.current.pause();
                handlebarGraspClip.current.currentTime = 0;
                playAudio(handlebarGraspClip.current);
            }
        }
    };

    const onResizeStop = async (e: React.SyntheticEvent): Promise<void> => {
        if (detailViewRevealed) {
            setIsSteering(false);
            setHitDetailMinimum(false);
            setHitCartridgeMinimum(false);
            if (!handlebarHovered) {
                onCursorLeave(e);
            } else {
                // Change Cursor
                setCursorSigns([CURSOR_SIGN.grab]);
            }

            if (detailViewData && (
                getYouTubeParams(detailViewData.data.link)
                || getVimeoId(detailViewData.data.link)
            )) {
                setTriggerEmbeddingResize(true);
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.adjustTreasureChestSplitView,
                    userId: user.id,
                    sessionId: currentSessionId,
                });
            }
        }
    };

    const onResize = (
        e: React.SyntheticEvent<Element, Event>,
        data: ResizeCallbackData,
    ): void => {
        setDetailViewWidth(data.size.width);
        if (data.size.width <= MIN_DETAIL_SECTION_WIDTH && !hitDetailMinimum) {
            setHitDetailMinimum(true);

            // Play Sound
            if (hasSound && resizeConstraintThumpClip.current) {
                resizeConstraintThumpClip.current.pause();
                resizeConstraintThumpClip.current.currentTime = 0;
                playAudio(resizeConstraintThumpClip.current);
            }

            // Change Cursor
            setCursorSigns([CURSOR_SIGN.caution]);
        } else if (hitDetailMinimum && data.size.width > MIN_DETAIL_SECTION_WIDTH) {
            setHitDetailMinimum(false);

            // Change Cursor
            if (isSteering) {
                setCursorSigns([CURSOR_SIGN.horizontalSteer]);
            } else if (handlebarHovered) {
                setCursorSigns([CURSOR_SIGN.grab]);
            }
        } else if ((viewportDimensions.width - data.size.width) <= MIN_CARTRIDGE_SECTION_WIDTH && !hitCartridgeMinimum) {
            setHitCartridgeMinimum(true);

            // Play Sound
            if (hasSound && resizeConstraintThumpClip.current) {
                resizeConstraintThumpClip.current.pause();
                resizeConstraintThumpClip.current.currentTime = 0;
                playAudio(resizeConstraintThumpClip.current);
            }

            // Change Cursor
            setCursorSigns([CURSOR_SIGN.caution]);
        } else if (hitCartridgeMinimum && (viewportDimensions.width - data.size.width) > MIN_CARTRIDGE_SECTION_WIDTH) {
            setHitCartridgeMinimum(false);

            // Change Cursor
            if (isSteering) {
                setCursorSigns([CURSOR_SIGN.horizontalSteer]);
            } else if (handlebarHovered) {
                setCursorSigns([CURSOR_SIGN.grab]);
            }
        }
    };

    const onCartridgeMouseEnter = (id: string): void => {
        setForegroundedCartridge(id);
    };

    const onCartridgeMouseLeave = (id: string): void => {
        if (foregroundedCartridge && foregroundedCartridge === id) {
            setForegroundedCartridge(null);
        }
    };

    const onInsertCartridge = async (
        data: ICartridgeItem,
        cell: IVirtualizedGridCell,
    ): Promise<void> => {
        // If we're switching between cartridges, first eject old cartridge before adding new one
        if (cartridgeInserted) {
            onEjectCartridge();
            setCachedInsertCartridgeArgs({
                data,
                cell,
            });
            clearTimeoutSwitchCartridges();
            timeoutSwitchCartridges();
        } else {
            const target: HTMLElement | null = document.getElementById(data.id);
            // Likely if the user has the url for a specific cartridge
            // that isn't loaded in the first cartridge batch
            if (!target) {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_INSERT_CARTRIDGE_FIND_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
                navigate(
                    `/${PAGE_ROUTE.treasureChest}`,
                    {
                        state: location.state,
                    },
                );
                return;
            }

            const parentNode = findParentNodeWithClass(CARTRIDGE_CLASSNAME, target, 5);

            if (parentNode) {
                const parentRect = parentNode.getBoundingClientRect();
                parentNode.style.position = 'fixed';
                parentNode.style.left = `${parentRect.left}px`;
                parentNode.style.top = `${parentRect.top}px`;
                parentNode.style.zIndex = `${CURSOR_Z_INDEX}`;

                setDetailViewData({
                    data,
                    cell,
                });

                // get detail view image url
                if (data.imagePath.md !== detailViewImagePath) {
                    setDetailViewImagePath(data.imagePath.md);
                    const storage = getStorage();
                    getDownloadURL(ref(storage, data.imagePath.md)).then((imageURL) => {
                        setDetailViewImageURL(imageURL);
                    }).catch(() => {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_FETCH_DETAIL_VIEW_IMAGE_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                    });
                }

                if (!getYouTubeParams(data.link) && !getVimeoId(data.link)) {
                    fetchURLMetadata(
                        data.link,
                        (result: HttpsCallableResult<unknown>) => {
                            const metadata: ILinkMetadata = result.data as ILinkMetadata;
                            setLinkMetadata(metadata);
                        },
                    );
                } else if (linkMetadata) {
                    // clear link metadata because we're inserting a video
                    setLinkMetadata(null);
                }
                // Begin insert cartridge animation
                clearTimeoutInsertCartridge();
                timeoutInsertCartridge(target);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_INSERT_CARTRIDGE_FIND_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
                navigate(
                    `/${PAGE_ROUTE.treasureChest}`,
                    {
                        state: location.state,
                    },
                );
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.insertCartridge,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        cartridge: data,
                    },
                });
            }
        }
    };

    const updateInsertedCartridgeCell = (cell: IVirtualizedGridCell): void => {
        if (detailViewData) {
            const target = document.getElementById(detailViewData.data.id) as HTMLElement;
            const parentNode = findParentNodeWithClass(CARTRIDGE_CLASSNAME, target, 5);

            if (parentNode) {
                setDetailViewData({
                    data: detailViewData.data,
                    cell,
                });

                // Get detail view image url
                if (detailViewData.data.imagePath.md !== detailViewImagePath) {
                    setDetailViewImagePath(detailViewData.data.imagePath.md);
                    const storage = getStorage();
                    getDownloadURL(ref(storage, detailViewData.data.imagePath.md)).then((imageURL) => {
                        setDetailViewImageURL(imageURL);
                    }).catch(() => {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_FETCH_DETAIL_VIEW_IMAGE_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                    });
                }
            }
        }
    };

    const onEjectButtonEnter = (e: React.MouseEvent): void => {
        // This causes rerendering which causes
        // the detail sheet to flicker
        if (
            viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            && detailViewRevealed
        ) {
            onCursorEnter(
                CURSOR_TARGET.ejectButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onEjectButtonLeave = (e: React.MouseEvent): void => {
        // This causes rerendering which causes
        // the detail sheet to flicker
        if (
            viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            && detailViewRevealed
        ) {
            onCursorLeave(e);
        }
    };

    const onLinkButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.linkButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLinkButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onLinkButtonClick = async (href: string): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.openCartridgeLink,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    href,
                    cartridge: detailViewData!.data,
                },
            });
        }
    };

    const onTagMouseEnter = (e: React.MouseEvent): void => {
        // This causes rerendering which causes
        // the detail sheet to flicker
        if (
            viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            && detailViewRevealed
        ) {
            onCursorEnter(
                CURSOR_TARGET.tag,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onTagMouseLeave = (e: React.MouseEvent): void => {
        // This causes rerendering which causes
        // the detail sheet to flicker
        if (
            viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            && detailViewRevealed
        ) {
            onCursorLeave(e);
        }
    };

    const onTagClick = async (search: string): Promise<void> => {
        if (
            viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
            && detailViewRevealed
        ) {
            onEjectCartridge(search);
            triggerFilterAudio();
        } else if (
            search
            && viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            && detailViewRevealed
        ) {
            triggerFilterAudio();
            navigate(
                // We want relative search here to not eject a cartridge if one is inserted
                `${search}`,
                {
                    state: location.state,
                },
            );
        }
    };

    const onEjectCartridge = async (search?: string): Promise<void> => {
        const target: HTMLElement | null = document.getElementById(detailViewData!.data.id);
        if (!target) {
            // Scroll to cell
            if (!cartridgeGridRef.current) throw Error('[ERROR] Cartridge Grid Reference not found.');
            const oldColumnCount = Math.floor(viewportDimensions.width / (CARTRIDGE_WIDTH + CARTRIDGE_GRID_SPACING));
            const cartridgeIndex = detailViewData!.cell.rowIndex * oldColumnCount + detailViewData!.cell.columnIndex;
            const cartridgeRowIndex = Math.floor(cartridgeIndex / cartridgeColumns);
            const cartridgeColumnIndex = cartridgeIndex - (cartridgeRowIndex * cartridgeColumns);
            const cell: IVirtualizedGridCell = {
                rowIndex: cartridgeRowIndex,
                columnIndex: cartridgeColumnIndex,
            };
            cartridgeGridRef.current.scrollToCell(cell);
            clearTimeoutScrollToCartridgeEject();
            timeoutScrollToCartridgeEject();
            return;
        }

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.ejectCartridge,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    cartridge: detailViewData!.data.id,
                },
            });
        }

        const parentNode = findParentNodeWithClass(CARTRIDGE_CLASSNAME, target, 5);

        if (parentNode) {
            // Initiate insert animation
            parentNode.style.position = 'fixed';
            if (viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min) {
                if (!detailViewRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(DETAIL_VIEW_REF_NAME));
                const detailViewRect = detailViewRef.current.getBoundingClientRect();
                parentNode.style.left = `${detailViewRect.left + CARTRIDGE_ANIMATION_X_OFFSET}px`;
                parentNode.style.top = `${detailViewRect.top + CARTRIDGE_ANIMATION_Y_OFFSET}px`;
                parentNode.style.transform = 'scale(1) rotate(-90deg)';
            } else {
                const parentRect = parentNode.getBoundingClientRect();
                parentNode.style.left = `${(viewportDimensions.width - parentRect.width) / 2}px`;
                parentNode.style.top = '5px';
                parentNode.style.zIndex = `${CURSOR_Z_INDEX}`;
            }

            setCartridgeInserted(null);
            if (search) {
                navigate(
                    `/${PAGE_ROUTE.treasureChest}${search}`,
                    {
                        state: location.state,
                    },
                );
            } else {
                navigate(
                    `/${PAGE_ROUTE.treasureChest}${location.search}`,
                    {
                        state: location.state,
                    },
                );
            }

            // Begin eject cartridge animation
            clearTimeoutEjectingCartridge();
            timeoutEjectingCartridge();

            // Set Page Title
            updatePageTitle(
                'Treasure Chest',
                getFilterByText(),
            );
        }
    };

    const onCartridgeSectionRendered = (props: RenderedSection): void => {
        setRenderedSection(props);
    };

    const triggerFilterAudio = (): void => {
        // Play Sound
        if (hasSound && selectTagClip.current) {
            selectTagClip.current.pause();
            // eslint-disable-next-line no-param-reassign
            selectTagClip.current.currentTime = 0;
            playAudio(selectTagClip.current);
        }
    };

    const getFilterByText = (): string => {
        // If we have a treasure chest item open, include it
        let text = detailViewData?.data ? detailViewData.data.title : '';
        if (appliedFilters.length > 0) {
            text = appliedFilters.reduce((str, tag, index) => {
                let newStr = str;
                if (index > 0) {
                    newStr += `, '${tag.text}'`;
                } else {
                    newStr += ` '${tag.text}'`;
                }
                return newStr;
            }, text.length > 0 ? `${text} | Filter by` : 'Filter by');
        }

        return text;
    };

    /**
     * Records user action of viewing treasure chestpage
     */
    const recordTreasureChestPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewTreasureChestPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'Treasure Chest',
            getFilterByText(),
        );
    }, [appliedFilters]);

    /**
     * Records View Treasure Chest Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordTreasureChestPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            handlebarGraspClip.current
            && resizeConstraintThumpClip.current
            && cartridgeThudClip.current
            && cartridgeSlideClip.current
            && cartridgeInsertClip.current
            && cartridgeEjectClip.current
            && selectTagClip.current
        ) {
            // Handlebar Grasp
            handlebarGraspClip.current.volume = DEFAULT_AUDIO_VOLUME;
            handlebarGraspClip.current.src = ObjectGrasp;

            // Resize Constraint Thump
            resizeConstraintThumpClip.current.volume = DEFAULT_AUDIO_VOLUME;
            resizeConstraintThumpClip.current.src = ObjectThump;

            // Cartridge Thud
            cartridgeThudClip.current.volume = DEFAULT_AUDIO_VOLUME;
            cartridgeThudClip.current.src = ObjectThump;

            // Cartridge  Slide
            cartridgeSlideClip.current.volume = 0.05; // VERY LOAD SOUND
            cartridgeSlideClip.current.src = CartridgeSlide;

            // Cartridge Insert
            cartridgeInsertClip.current.volume = DEFAULT_AUDIO_VOLUME;
            cartridgeInsertClip.current.src = CartridgeInsert;

            // Cartridge Eject
            cartridgeEjectClip.current.volume = DEFAULT_AUDIO_VOLUME;
            cartridgeEjectClip.current.src = CartridgeEject;

            // Select Tag
            selectTagClip.current.volume = DEFAULT_AUDIO_VOLUME;
            selectTagClip.current.src = SelectTag;
        }

        return function cleanup() {
            if (handlebarGraspClip.current) handlebarGraspClip.current.remove();
            if (resizeConstraintThumpClip.current) resizeConstraintThumpClip.current.remove();
            if (cartridgeThudClip.current) cartridgeThudClip.current.remove();
            if (cartridgeSlideClip.current) cartridgeSlideClip.current.remove();
            if (cartridgeInsertClip.current) cartridgeInsertClip.current.remove();
            if (cartridgeEjectClip.current) cartridgeEjectClip.current.remove();
            if (selectTagClip.current) selectTagClip.current.remove();
        };
    }, []);

    // Fetch Cartridges
    useEffect(() => {
        fetchCartridgeData();
    }, [appliedFilters]);

    // Fetch Filters
    useEffect(() => {
        if (cartridges.size > 0) {
            // Relies on cartridges to be filtered to get the correct filters
            fetchAvailableFilters();
        }
    }, [cartridges]);

    // Extract Applied Filters on Mount
    useEffect(() => {
        if (filters.size > 0 && isInitialRender) {
            const getURLFilters = Array
                .from(searchParams.entries())
                .map(([type, humanFilterId]) => {
                    if (
                        filters.get(getFilterType(type as FILTER_TYPE_PATH_KEY))
                        && filters
                            .get(getFilterType(type as FILTER_TYPE_PATH_KEY))
                            ?.has(tagHumanIDToID(getFilterType(type as FILTER_TYPE_PATH_KEY), humanFilterId))
                    ) {
                        return true;
                    }

                    return false;
                })
                .reduce((acc: boolean, tagPresent: boolean) => acc && tagPresent, true);
            const numAppliedFilters = Array
                .from(filters.values())
                .reduce((count: number, map: Map<string, ITag>) => count + map.size, 0);
            const numURLFilters = Array.from(searchParams.entries()).length;
            if (
                getURLFilters
                && isInitialRender
                && numAppliedFilters !== numURLFilters
                && (
                    !params.cartridgeId
                    || (
                        params.cartridgeId
                        && cartridgeInserted
                        && detailViewInfoBannerHeight > 0
                    )
                )
            ) {
                clearTimeoutFilterOnPageLoad();
                timeoutFilterOnPageLoad();
                setIsInitialRender(false);
            } else if (
                getURLFilters
                && isInitialRender
                && numAppliedFilters === numURLFilters
            ) {
                setIsInitialRender(false);
            }
        }
    }, [
        filters,
        cartridgeInserted,
        detailViewInfoBannerHeight,
    ]);

    // Extract Applied Filters
    // IMPORTANT: If page loads with both a cartridge to insert
    // and filters to apply, we first insert the cartridge before
    // applying the filters so that cartridge is not filtered out
    useEffect(() => {
        if (
            filters.size > 0
            && (
                !params.cartridgeId
                || (
                    params.cartridgeId
                    && cartridgeInserted
                    && detailViewInfoBannerHeight > 0
                )
            )
        ) {
            extractURLFilters();
        }
    }, [
        searchParams,
        cartridgeInserted,
        detailViewInfoBannerHeight,
    ]);

    // show cartridges
    useEffect(() => {
        if (!showCartridges) {
            clearTimeoutShowCartridges();
            timeoutShowCartridges();
        }
    }, [cartridges]);

    useEffect(() => {
        setCartridgeContainerWidth(detailViewRevealed && viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            ? viewportDimensions.width - detailViewWidth
            : viewportDimensions.width);
    }, [
        viewportDimensions,
        detailViewRevealed,
        detailViewWidth,
    ]);

    useEffect(() => {
        let updateColumns = false;
        const updatedCartridgeColumns = Math.floor(cartridgeContainerWidth / (CARTRIDGE_WIDTH + CARTRIDGE_GRID_SPACING));
        if (updatedCartridgeColumns !== cartridgeColumns) {
            updateColumns = true;
        }

        if (updateColumns) {
            setCartridgeColumns(updatedCartridgeColumns);

            if (detailViewData) {
                if (!cartridgeGridRef.current) throw Error('[ERROR] Cartridge Grid Reference not found.');
                // Make sure we're scrolled to correct position once detail view closed
                cartridgeGridRef.current.scrollToCell(detailViewData.cell);
            }
        }
    }, [cartridgeContainerWidth]);

    useEffect(() => {
        if (
            params.cartridgeId
            && !detailViewData
            && cartridges.has(ITEM_TO_ID.get(params.cartridgeId)!)
            && cartridgeGridRef.current
            // Upon page load, we only want to insert cartrigde once filters are retrieved
            && filters.size > 0
        ) {
            const cartridgeData = cartridges.get(ITEM_TO_ID.get(params.cartridgeId)!)!;
            const cartridgeIndex = Array.from(cartridges.keys()).findIndex((id: string) => id === ITEM_TO_ID.get(params.cartridgeId!));
            const cartridgeRowIndex = Math.floor(cartridgeIndex / cartridgeColumns);
            const cartridgeColumnIndex = cartridgeIndex - (cartridgeRowIndex * cartridgeColumns);

            const cell: IVirtualizedGridCell = {
                rowIndex: cartridgeRowIndex,
                columnIndex: cartridgeColumnIndex,
            };

            const targetCartridge: HTMLElement | null = document.getElementById(params.cartridgeId);
            if (targetCartridge) {
                onInsertCartridge(
                    cartridgeData,
                    cell,
                );

                // Set Page Title
                updatePageTitle(
                    'Treasure Chest',
                    cartridgeData.title,
                );
            } else {
                cartridgeGridRef.current.scrollToCell(cell);
                clearTimeoutScrollToCartridgeInsert();
                timeoutScrollToCartridgeInsert({
                    cell,
                    cartridgeData,
                });
            }
        }
    }, [
        params,
        filters,
        cartridges,
        cartridgeGridRef.current,
    ]);

    const {
        start: timeoutShowCartridges,
        clear: clearTimeoutShowCartridges,
    } = useTimeout(() => {
        setShowCartridges(true);
    }, CARTRIDGES_DELAY_DURATION);

    const {
        start: timeoutFilterOnPageLoad,
        clear: clearTimeoutFilterOnPageLoad,
    } = useTimeout(() => {
        extractURLFilters();
    }, PAGE_LOAD_FILTER_DELAY_DURATION);

    const {
        start: timeoutScrollToCartridgeInsert,
        clear: clearTimeoutScrollToCartridgeInsert,
    } = useTimeout((args) => {
        onInsertCartridge(
            args.cartridgeData,
            args.cell,
        );

        // Set Page Title
        updatePageTitle(
            'Treasure Chest',
            args.cartridgeData.title,
        );
    }, CARTRIDGE_SCROLL_DELAY_DURATION);

    const {
        start: timeoutScrollToCartridgeEject,
        clear: clearTimeoutScrollToCartridgeEject,
    } = useTimeout(() => {
        onEjectCartridge();
    }, CARTRIDGE_SCROLL_DELAY_DURATION);

    const {
        start: timeoutInsertCartridge,
        clear: clearTimeoutInsertCartridge,
    } = useTimeout((target) => {
        if (!cartridgeGridRef.current) throw Error('[ERROR] Cartridge Grid Reference not found.');
        // Make sure we're scrolled to correct position once detail view closed
        cartridgeGridRef.current.scrollToCell(detailViewData!.cell);

        // Likely if the user has the url for a specific cartridge
        // that isn't loaded in the first cartridge batch
        if (!target) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_INSERT_CARTRIDGE_FIND_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            navigate(
                `/${PAGE_ROUTE.treasureChest}`,
                {
                    state: location.state,
                },
            );
            return;
        }

        const parentNode = findParentNodeWithClass(CARTRIDGE_CLASSNAME, target, 5);

        if (parentNode) {
            setInsertingCartridge(detailViewData!.data.id);

            // Initiate insert animation
            parentNode.style.position = 'fixed';
            if (viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min) {
                if (!detailViewRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(DETAIL_VIEW_REF_NAME));
                const detailViewRect = detailViewRef.current.getBoundingClientRect();
                parentNode.style.left = `${detailViewRect.left + CARTRIDGE_ANIMATION_X_OFFSET}px`;
                parentNode.style.top = `${detailViewRect.top + CARTRIDGE_ANIMATION_Y_OFFSET}px`;
                parentNode.style.transform = 'scale(1) rotate(-90deg)';
            } else {
                const parentRect = parentNode.getBoundingClientRect();
                parentNode.style.left = `${(viewportDimensions.width - parentRect.width) / 2}px`;
                parentNode.style.top = `${DETAIL_SHEET_INSERTED_CARTRIDGE_TOP}px`;
                parentNode.style.zIndex = `${CURSOR_Z_INDEX}`;
            }

            clearTimeoutInsertedCartridge();
            timeoutInsertedCartridge();
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_INSERT_CARTRIDGE_FIND_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            navigate(
                `/${PAGE_ROUTE.treasureChest}`,
                {
                    state: location.state,
                },
            );
        }
    }, DETAIL_VIEW_TRANSITION_DURATION);

    const {
        start: timeoutInsertedCartridge,
        clear: clearTimeoutInsertedCartridge,
    } = useTimeout(() => {
        setInsertingCartridge(null);
        setCartridgeInserted(detailViewData!.data.id);

        // NOTE: cartridge will still be positioned (but hidden) beside the detail view
        // If the detail view is adjusted, it won't move. But it will move to correct position
        // before eject animation

        clearTimeoutMeasureInfoBannerHeight();
        timeoutMeasureInfoBannerHeight();
    }, CARTRIDGE_INSERT_EJECT_DURATION);

    const {
        start: timeoutMeasureInfoBannerHeight,
        clear: clearTimeoutMeasureInfoBannerHeight,
    } = useTimeout(() => {
        if (!detailInfoBannerRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(DETAIL_INFO_BANNER_REF_NAME));

        const infoBannerRect = detailInfoBannerRef.current.getBoundingClientRect();
        setDetailViewInfoBannerHeight(infoBannerRect.height);

        if (detailViewData && (
            getYouTubeParams(detailViewData.data.link)
            || getVimeoId(detailViewData.data.link)
        )) {
            setTriggerEmbeddingResize(true);
        }
    }, Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (4 * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
        + DETAIL_INFO_REVEAL_DELAY_DURATION,
    ));

    const {
        start: timeoutEjectingCartridge,
        clear: clearTimeoutEjectingCartridge,
    } = useTimeout(() => {
        setEjectingCartridge(detailViewData!.data.id);
        setDetailViewInfoBannerHeight(0);

        const target: HTMLElement | null = document.getElementById(detailViewData!.data.id);
        if (target) {
            const parentNode = findParentNodeWithClass(CARTRIDGE_CLASSNAME, target, 5);

            if (parentNode) {
                // Determine cartidge position
                // This could have changed if user filtered while we had cartridge inserted
                // So we compute new position while ejecting
                const columnWidth = CARTRIDGE_GRID_SPACING + CARTRIDGE_WIDTH;
                const rowHeight = viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                    ? CARTRIDGE_GRID_SPACING + CARTRIDGE_HEIGHT
                    : CARTRIDGE_GRID_SPACING + (CARTRIDGE_HEIGHT + CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT);
                const left = detailViewData!.cell.columnIndex * columnWidth;
                const right = detailViewData!.cell.rowIndex * rowHeight;

                // Set to position in grid
                parentNode.style.position = 'fixed';
                parentNode.style.left = `${left}px`;
                parentNode.style.top = `${right}px`;
                parentNode.style.transform = '';
                parentNode.style.zIndex = '';

                clearTimeoutEjectedCartridge();
                timeoutEjectedCartridge();
            }
        }
    }, CARTRIDGE_EJECT_DELAY_DURATION);

    const {
        start: timeoutEjectedCartridge,
        clear: clearTimeoutEjectedCartridge,
    } = useTimeout(() => {
        const target: HTMLElement | null = document.getElementById(ejectingCartridge!);
        if (target) {
            const parentNode = findParentNodeWithClass(CARTRIDGE_CLASSNAME, target, 5);

            if (parentNode) {
                // Set back to original position
                parentNode.style.position = 'absolute';
            }
        }

        setEjectingCartridge(null);
        setDetailViewData(null);
        setDetailViewImageURL(null);
        setLinkMetadata(null);
    }, CARTRIDGE_INSERT_EJECT_DURATION);

    const {
        start: timeoutSwitchCartridges,
        clear: clearTimeoutSwitchCartridges,
    } = useTimeout(() => {
        if (cachedInsertCartridgeArgs) {
            setDetailViewData({
                data: cachedInsertCartridgeArgs.data,
                cell: cachedInsertCartridgeArgs.cell,
            });

            // get detail view image url
            if (cachedInsertCartridgeArgs.data.imagePath.md !== detailViewImagePath) {
                setDetailViewImagePath(cachedInsertCartridgeArgs.data.imagePath.md);
                const storage = getStorage();
                getDownloadURL(ref(storage, cachedInsertCartridgeArgs.data.imagePath.md)).then((imageURL) => {
                    setDetailViewImageURL(imageURL);
                }).catch(() => {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_FETCH_DETAIL_VIEW_IMAGE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                });
            }

            if (!getYouTubeParams(cachedInsertCartridgeArgs.data.link)
                && !getVimeoId(cachedInsertCartridgeArgs.data.link)
            ) {
                fetchURLMetadata(
                    cachedInsertCartridgeArgs.data.link,
                    (result: HttpsCallableResult<unknown>) => {
                        const metadata: ILinkMetadata = result.data as ILinkMetadata;
                        setLinkMetadata(metadata);
                    },
                );
            } else if (linkMetadata) {
                // clear link metadata because we're inserting a video
                setLinkMetadata(null);
            }
            // Clear cached args
            const target = document.getElementById(cachedInsertCartridgeArgs.data.id);
            setCachedInsertCartridgeArgs(null);

            // Begin insert cartridge animation
            clearTimeoutInsertCartridge();
            timeoutInsertCartridge(target);

            navigate(
                `/${PAGE_ROUTE.treasureChest}/${ID_TO_ITEM.get(cachedInsertCartridgeArgs.data.id)}`,
                {
                    state: location.state,
                },
            );
        }
    }, CARTRIDGE_EJECT_DELAY_DURATION + CARTRIDGE_INSERT_EJECT_DURATION + SWITCH_CARTRIDGE_DELAY_DURATION);

    // ===== Renderers =====

    const handle = (
        <HandlebarContainer
            width={DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH}
            isSteering={isSteering}
            background={HANDLEBAR_CONTAINER_BACKGROUND_RESTING}
            steeringBackground={HANDLEBAR_CONTAINER_BACKGROUND_STEERING}
            hoverBackground={HANDLEBAR_CONTAINER_HOVER_BACKGROUND_RESTING}
            steeringHoverBackground={HANDLEBAR_CONTAINER_HOVER_BACKGROUND_STEERING}
            transitionDuration={HANDLEBAR_TRANSITION_DURATION}
        >
            <Handlebar
                className={HOVER_TARGET_CLASSNAME}
                width={DETAIL_VIEW_HANDLEBAR_WIDTH}
                height={DETAIL_VIEW_HANDLEBAR_HEIGHT}
                isSteering={isSteering}
                transitionDuration={HANDLEBAR_TRANSITION_DURATION}
                onMouseEnter={onHandlebarEnter}
                onMouseLeave={onHandlebarLeave}
            />
        </HandlebarContainer>
    );

    const cartridgeRenderer = useCallback((props: GridCellProps): ReactElement => {
        const index = props.rowIndex * cartridgeColumns + props.columnIndex;
        const cartridge = Array.from(cartridges.values())[index];
        const enterStaggerDuration = (props.rowIndex - (renderedSection?.rowStartIndex || 0)) * 150
        + (props.columnIndex * FADE_IN_STAGGER_OFFSET_DURATION);
        const cell: IVirtualizedGridCell = {
            rowIndex: props.rowIndex,
            columnIndex: props.columnIndex,
        };

        if (
            cartridge
            && detailViewData
            && cartridgeInserted === cartridge.id
            && (
                detailViewData.cell.rowIndex !== props.rowIndex
                || detailViewData.cell.columnIndex !== props.columnIndex
            )) {
            updateInsertedCartridgeCell(cell);
        }

        if (cartridge) {
            return (
                <GridCell
                    key={cartridge.id}
                    className={CARTRIDGE_CLASSNAME}
                    style={props.style}
                    isVisible={props.isVisible && showCartridges}
                    enterDuration={enterStaggerDuration}
                    insertEjectDuration={CARTRIDGE_INSERT_EJECT_DURATION}
                    isInserting={insertingCartridge === cartridge.id}
                    isEjecting={ejectingCartridge === cartridge.id}
                    isForegrounded={foregroundedCartridge === cartridge.id}
                    focusedZIndex={CARTRIDGE_FOCUSED_Z_INDEX}
                >
                    <Cartridge
                        data={cartridge}
                        cell={cell}
                        cartridgeContainerWidth={cartridgeContainerWidth}
                        filters={filters}
                        insertCartridge={onInsertCartridge}
                        isInserting={insertingCartridge === cartridge.id}
                        isEjecting={ejectingCartridge === cartridge.id}
                        isForegrounded={foregroundedCartridge === cartridge.id}
                        cartridgeInserted={cartridgeInserted === cartridge.id}
                        hasSound={hasSound}
                        viewportDimensions={viewportDimensions}
                        cartridgeThudClip={cartridgeThudClip}
                        cartridgeSlideClip={cartridgeSlideClip}
                        cartridgeInsertClip={cartridgeInsertClip}
                        cartridgeEjectClip={cartridgeEjectClip}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        onMouseEnter={onCartridgeMouseEnter}
                        onMouseLeave={onCartridgeMouseLeave}
                        triggerFilterAudio={triggerFilterAudio}
                    />
                </GridCell>
            );
        }

        return <div />;
    }, [
        cartridgeColumns,
        cartridges,
        detailViewData,
        renderedSection,
        showCartridges,
        insertingCartridge,
        ejectingCartridge,
        foregroundedCartridge,
        cartridgeContainerWidth,
        filters,
        cartridgeInserted,
        hasSound,
        viewportDimensions,
        cartridgeThudClip,
        cartridgeSlideClip,
        cartridgeInsertClip,
        cartridgeEjectClip,
    ]);

    // ===== Memoization =====

    const cartridgeGridHorizontalPadding = useMemo(() => (cartridgeContainerWidth - Math.max(
        cartridgeColumns * (CARTRIDGE_WIDTH + CARTRIDGE_GRID_SPACING) - CARTRIDGE_GRID_SPACING,
        CARTRIDGE_WIDTH,
    )) / 2, [
        cartridgeColumns,
        cartridgeContainerWidth,
    ]);

    const gridColumnWidth = useMemo(() => (
        CARTRIDGE_GRID_SPACING + CARTRIDGE_WIDTH
    ), []);

    const gridRowCount = useMemo(() => (
        Math.ceil(cartridges.size / cartridgeColumns)
    ), [
        cartridges,
        cartridgeColumns,
    ]);

    const gridRowHeight = useMemo(() => (
        viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            ? CARTRIDGE_GRID_SPACING + CARTRIDGE_HEIGHT
            : CARTRIDGE_GRID_SPACING + (CARTRIDGE_HEIGHT + CARTRIDGE_DETAIL_CONTAINER_HEIGHT_SMALL_VIEWPORT)
    ), [
        viewportDimensions,
    ]);

    const detailContentContainerHeight = useMemo(() => (
        viewportDimensions.height - detailViewInfoBannerHeight
    ), [
        viewportDimensions,
        detailViewInfoBannerHeight,
    ]);

    const resizeableMaxConstraintsWidth = useMemo(() => (
        viewportDimensions.width - MIN_CARTRIDGE_SECTION_WIDTH
    ), [
        viewportDimensions,
    ]);

    const customSheetSnapPoint = useMemo(() => (
        viewportDimensions.height - DETAIL_SHEET_HEIGHT_REDUCTION
    ), [
        viewportDimensions,
    ]);

    const containerWidth = useMemo(() => (
        detailViewRevealed && viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
            ? viewportDimensions.width - detailViewWidth
            : viewportDimensions.width
    ), [
        detailViewRevealed,
        detailViewWidth,
        viewportDimensions,
    ]);

    const gridPaddingTop = useMemo(() => (
        viewportDimensions.width < MEDIA_QUERY_SIZE.small.min
            ? CARTRIDGE_GRID_MARGIN_TOP_SMALL_VIEWPORT
            : CARTRIDGE_GRID_MARGIN_TOP
    ), [
        viewportDimensions,
    ]);

    const gridZIndex = useMemo(() => (
        viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
            && (ejectingCartridge || insertingCartridge)
            ? `${CARTRIDGE_INSERTING_Z_INDEX}`
            : 'auto'
    ), [
        viewportDimensions,
        ejectingCartridge,
        insertingCartridge,
    ]);

    const showDetailBanner = useMemo(() => (
        !!detailViewRevealed && !!detailViewData
    ), [
        detailViewRevealed,
        detailViewData,
    ]);

    const showDetailContent = useMemo(() => (
        !!detailViewRevealed
        && !!detailViewData
        && detailViewInfoBannerHeight > 0
    ), [
        detailViewRevealed,
        detailViewData,
        detailViewInfoBannerHeight,
    ]);

    const detailImageEnterTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (DETAIL_VIEW_IMAGE_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
        + DETAIL_INFO_REVEAL_DELAY_DURATION,
    ), []);

    const detailImageExitTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((DETAIL_VIEW_ELEMENT_COUNT - Math.max(DETAIL_VIEW_IMAGE_STAGGER_INDEX - 1, 0)) * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const detailTypeEnterTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (DETAIL_VIEW_TYPE_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
        + DETAIL_INFO_REVEAL_DELAY_DURATION,
    ), []);

    const detailTypeExitTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((DETAIL_VIEW_ELEMENT_COUNT - Math.max(DETAIL_VIEW_TYPE_STAGGER_INDEX - 1, 0)) * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const detailTitleEnterTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (DETAIL_VIEW_TITLE_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
        + DETAIL_INFO_REVEAL_DELAY_DURATION,
    ), []);

    const detailTitleExitTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((DETAIL_VIEW_ELEMENT_COUNT - Math.max(DETAIL_VIEW_TITLE_STAGGER_INDEX - 1, 0)) * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const detailAuthorsEnterTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (DETAIL_VIEW_AUTHORS_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
        + DETAIL_INFO_REVEAL_DELAY_DURATION,
    ), []);

    const detailAuthorsExitTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((DETAIL_VIEW_ELEMENT_COUNT - Math.max(DETAIL_VIEW_AUTHORS_STAGGER_INDEX - 1, 0)) * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const detailThemeEnterTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        (DETAIL_VIEW_THEME_LABEL_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
        + DETAIL_INFO_REVEAL_DELAY_DURATION,
    ), []);

    const detailThemeExitTimeout = useMemo(() => Math.min(
        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((DETAIL_VIEW_ELEMENT_COUNT - Math.max(DETAIL_VIEW_THEME_LABEL_STAGGER_INDEX - 1, 0)) * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION,
    ), []);

    const showSpinner = useMemo(() => (
        detailViewData
        && !getYouTubeParams(detailViewData.data.link)
        && !getVimeoId(detailViewData.data.link)
        && !linkMetadata
    ), [
        detailViewData,
        linkMetadata,
    ]);

    // ===== Rendering =====

    const CartridgeDetail = useMemo(() => (
        <CartridgeDetailContainer>
            <DetailInfoBanner
                ref={detailInfoBannerRef}
                leftMargin={DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH}
            >
                <DetailImageContainer
                    padding={DETAIL_IMAGE_CONTAINER_PADDING}
                    width={!detailViewData ? DETAIL_IMAGE_CONTAINER_WIDTH : null}
                >
                    {(
                        detailViewData === null
                        || !detailViewImageURL
                    ) && (
                        <PlaceholderBox
                            width={!detailViewData ? '100%' : DETAIL_IMAGE_MAX_WIDTH}
                            height={!detailViewData ? DETAIL_IMAGE_PLACEHOLDER_LENGTH : DETAIL_IMAGE_MAX_HEIGHT}
                        />
                    )}
                    <Transition
                        in={showDetailBanner && !!detailViewImageURL}
                        timeout={{
                            enter: detailImageEnterTimeout,
                            exit: detailImageExitTimeout,
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <DetailImage
                                maxWidth={DETAIL_IMAGE_MAX_WIDTH}
                                maxHeight={DETAIL_IMAGE_MAX_HEIGHT}
                                src={detailViewImageURL!}
                                style={{
                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                        direction: 'up',
                                        offset: 10,
                                    }),
                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                        direction: 'up',
                                        offset: 10,
                                        numItems: DETAIL_VIEW_ELEMENT_COUNT,
                                        index: DETAIL_VIEW_IMAGE_STAGGER_INDEX,
                                        enterDelay: DETAIL_INFO_REVEAL_DELAY_DURATION,
                                    })[state],
                                }}
                            />
                        )}
                    </Transition>
                </DetailImageContainer>
                <DetailTextContainer>
                    {detailViewData === null
                    && (
                        <PlaceholderBox
                            width={DETAIL_TYPE_PLACEHOLDER_WIDTH}
                            margin="0px 0px 5px 0px"
                            height={DETAIL_TYPE_FONT_MULTIPLIER * BODY_FONT_SIZE}
                        />
                    )}
                    <Transition
                        in={showDetailBanner}
                        timeout={{
                            enter: detailTypeEnterTimeout,
                            exit: detailTypeExitTimeout,
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <DetailType
                                fontMultiplier={DETAIL_TYPE_FONT_MULTIPLIER}
                                style={{
                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                        direction: 'right',
                                        offset: 10,
                                    }),
                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                        direction: 'right',
                                        offset: 10,
                                        numItems: DETAIL_VIEW_ELEMENT_COUNT,
                                        index: DETAIL_VIEW_TYPE_STAGGER_INDEX,
                                        enterDelay: DETAIL_INFO_REVEAL_DELAY_DURATION,
                                    })[state],
                                }}
                            >
                                {detailViewData?.data.type}
                            </DetailType>
                        )}
                    </Transition>
                    {detailViewData === null
                    && (
                        <PlaceholderBox
                            width={`calc(100% - ${DETAIL_IMAGE_CONTAINER_PADDING}px)`}
                            margin="0px 0px 10px 0px"
                            height={DETAIL_TITLE_FONT_MULTIPLIER * BODY_FONT_SIZE}
                        />
                    )}
                    <Transition
                        in={showDetailBanner}
                        timeout={{
                            enter: detailTitleEnterTimeout,
                            exit: detailTitleExitTimeout,
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <DetailTitle
                                fontMultiplier={DETAIL_TITLE_FONT_MULTIPLIER}
                                style={{
                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                        direction: 'right',
                                        offset: 10,
                                    }),
                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                        direction: 'right',
                                        offset: 10,
                                        numItems: DETAIL_VIEW_ELEMENT_COUNT,
                                        index: DETAIL_VIEW_TITLE_STAGGER_INDEX,
                                        enterDelay: DETAIL_INFO_REVEAL_DELAY_DURATION,
                                    })[state],
                                }}
                            >
                                {detailViewData?.data.title}
                            </DetailTitle>
                        )}
                    </Transition>
                    {detailViewData === null
                    && (
                        <PlaceholderBox
                            width={`calc(65% - ${DETAIL_IMAGE_CONTAINER_PADDING}px)`}
                            height={DETAIL_AUTHORS_FONT_MULTIPLIER * BODY_FONT_SIZE}
                        />
                    )}
                    <Transition
                        in={showDetailBanner}
                        timeout={{
                            enter: detailAuthorsEnterTimeout,
                            exit: detailAuthorsExitTimeout,
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <DetailAuthors
                                fontMultiplier={DETAIL_AUTHORS_FONT_MULTIPLIER}
                                style={{
                                    ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                        direction: 'right',
                                        offset: 10,
                                    }),
                                    ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                        direction: 'right',
                                        offset: 10,
                                        numItems: DETAIL_VIEW_ELEMENT_COUNT,
                                        index: DETAIL_VIEW_AUTHORS_STAGGER_INDEX,
                                        enterDelay: DETAIL_INFO_REVEAL_DELAY_DURATION,
                                    })[state],
                                }}
                            >
                                {detailViewData?.data.authors.reduce((authors, author, index: number) => {
                                    if (index === 0) {
                                        return author;
                                    }

                                    return `${authors}, ${author}`;
                                }, '')}
                            </DetailAuthors>
                        )}
                    </Transition>
                    {detailViewData === null
                    && (
                        <PlaceholderBox
                            width={THEME_LABEL_PLACEHOLDER_WIDTH}
                            margin="30px 0px 0px 0px"
                            height={THEME_LABEL_FONT_MULTIPLIER * BODY_FONT_SIZE}
                        />
                    )}
                    <Transition
                        in={showDetailBanner}
                        timeout={{
                            enter: detailThemeEnterTimeout,
                            exit: detailThemeExitTimeout,
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <>
                                <ThemeLabel
                                    fontMultiplier={THEME_LABEL_FONT_MULTIPLIER}
                                    style={{
                                        ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                            direction: 'right',
                                            offset: 10,
                                        }),
                                        ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                            direction: 'right',
                                            offset: 10,
                                            numItems: DETAIL_VIEW_ELEMENT_COUNT,
                                            index: DETAIL_VIEW_THEME_LABEL_STAGGER_INDEX,
                                            enterDelay: DETAIL_INFO_REVEAL_DELAY_DURATION,
                                        })[state],
                                    }}
                                >
                                    Themes
                                </ThemeLabel>
                                <TagsContainer>
                                    {detailViewData?.data.themeTags!.map((tagTheme: ITag, tagIndex: number, arr: ITag[]) => {
                                        const isApplied = searchParams
                                            .getAll(getFilterTypePathKey(tagTheme.type))
                                            .includes(tagIDToHumanID(tagTheme.type, tagTheme.id));
                                        const isInactive = searchParams.entries.length > 0
                                            && !filters.get(tagTheme.type)!.get(tagTheme.id);

                                        let color: string;
                                        if (isInactive) {
                                            // Grey out because inactive
                                            color = theme.color.neutral200;
                                        } else {
                                            // Show as active
                                            color = CARTRIDGE_TYPE[detailViewData.data.color].color;
                                        }

                                        return (
                                            <Transition
                                                key={tagTheme.id}
                                                in={showDetailBanner}
                                                timeout={{
                                                    enter: Math.min(
                                                        EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                        (DETAIL_VIEW_THEME_LABEL_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
                                                        + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
                                                        + DETAIL_INFO_REVEAL_DELAY_DURATION,
                                                    ) + Math.min(
                                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                        (tagIndex * FADE_IN_STAGGER_OFFSET_DURATION)
                                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                                    ),
                                                    exit: Math.min(
                                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                        ((arr.length - Math.max(tagIndex - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                                    ),
                                                }}
                                                appear
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                {(s) => (
                                                    <Tag
                                                        center
                                                        className={HOVER_TARGET_CLASSNAME}
                                                        solid={isApplied || isInactive}
                                                        hover={!isInactive && !!filters.get(tagTheme.type)!.get(tagTheme.id)}
                                                        floating={isApplied}
                                                        inactive={isInactive}
                                                        color={color}
                                                        index={tagIndex}
                                                        height={TAG_HEIGHT}
                                                        transitionDuration={TAG_TRANSITION_DURATION}
                                                        style={{
                                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                                direction: 'down',
                                                                offset: 10,
                                                            }),
                                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                                direction: 'down',
                                                                offset: 10,
                                                                numItems: arr.length,
                                                                index: tagIndex,
                                                                enterDelay: Math.min(
                                                                    EXTENDED_MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                                                    (DETAIL_VIEW_THEME_LABEL_STAGGER_INDEX * EXTENDED_FADE_IN_STAGGER_OFFSET_DURATION)
                                                                    + EXTENDED_FADE_IN_STAGGER_TRANSITION_DURATION
                                                                    + DETAIL_INFO_REVEAL_DELAY_DURATION,
                                                                ),
                                                            })[s],
                                                            textDecoration: 'none',
                                                        }}
                                                        {...(isInactive
                                                            ? {} : {
                                                                onMouseEnter: (e) => onTagMouseEnter(e),
                                                                onMouseLeave: (e) => onTagMouseLeave(e),
                                                                ...(detectTouchDevice(document) ? {
                                                                    onTouchEnd: () => onTagClick(`?${getFilterPath(
                                                                        searchParams,
                                                                        tagTheme.type,
                                                                        tagTheme.humanId,
                                                                    ).toString()}`),
                                                                } : {
                                                                    onClick: () => onTagClick(`?${getFilterPath(
                                                                        searchParams,
                                                                        tagTheme.type,
                                                                        tagTheme.humanId,
                                                                    ).toString()}`),
                                                                }),
                                                            }
                                                        )}
                                                    >
                                                        <TagText>
                                                            {tagTheme.text}
                                                        </TagText>
                                                    </Tag>
                                                )}
                                            </Transition>
                                        );
                                    })}
                                </TagsContainer>
                            </>
                        )}
                    </Transition>
                </DetailTextContainer>
            </DetailInfoBanner>
            <Transition
                in={showDetailContent}
                timeout={{
                    enter: DETAIL_VIEW_CONTENT_TRANSITION_DURATION,
                    exit: DETAIL_VIEW_CONTENT_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <DetailContentContainer
                        ref={detailContentContainerRef}
                        height={showSpinner ? `${detailContentContainerHeight}px` : 'auto'}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: DETAIL_VIEW_CONTENT_TRANSITION_DURATION,
                                easing: theme.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        {detailViewData
                        && getYouTubeParams(detailViewData!.data.link)
                        && (
                            <YouTubeEmbed
                                url={detailViewData!.data.link}
                                cartridge={detailViewData!.data}
                                user={user}
                                currentSessionId={currentSessionId}
                                parentRef={detailContentContainerRef!.current as HTMLElement}
                                triggerEmbeddingResize={triggerEmbeddingResize}
                                setTriggerEmbeddingResize={setTriggerEmbeddingResize}
                            />
                        )}
                        {detailViewData
                        && getVimeoId(detailViewData!.data.link)
                        && (
                            <VimeoEmbed
                                url={detailViewData!.data.link}
                                cartridge={detailViewData!.data}
                                user={user}
                                currentSessionId={currentSessionId}
                                parentRef={detailContentContainerRef!.current as HTMLElement}
                                triggerEmbeddingResize={triggerEmbeddingResize}
                                setTriggerEmbeddingResize={setTriggerEmbeddingResize}
                            />
                        )}
                        {detailViewData
                        && linkMetadata
                        && (
                            <LinkMetadataContainer>
                                {linkMetadata.image
                                    ? (
                                        <LinkMetadataImage
                                            src={linkMetadata.image}
                                        />
                                    ) : (
                                        <LinkMetadataImagePlaceholder>
                                            <LinkMetadataImagePlaceholderIcon
                                                src={LinkIcon}
                                            />
                                        </LinkMetadataImagePlaceholder>
                                    )}
                                <LinkMetadataTitle
                                    title={linkMetadata.title || detailViewData.data.title || ''}
                                >
                                    {linkMetadata.title
                                    || applyCharacterLimit(
                                        detailViewData.data.title || '',
                                        LINK_MAX_TITLE_COUNT,
                                    )}
                                </LinkMetadataTitle>
                                {linkMetadata.description
                                && (
                                    <LinkMetadataDescription>
                                        {linkMetadata.description}
                                    </LinkMetadataDescription>
                                )}
                                {(
                                    linkMetadata.url
                                    || detailViewData.data.link
                                ) && detailViewData.data.color
                                && (
                                    <LinkButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        href={linkMetadata.url || detailViewData.data.link}
                                        target="_blank"
                                        color={CARTRIDGE_TYPE[detailViewData.data.color].color}
                                        transitionDuration={LINK_BUTTON_ENTER_TRANSITION_DURATION}
                                        onMouseEnter={onLinkButtonEnter}
                                        onMouseLeave={onLinkButtonLeave}
                                        onClick={() => onLinkButtonClick(linkMetadata.url || detailViewData.data.link)}
                                    >
                                        VISIT
                                    </LinkButton>
                                )}
                                {(
                                    linkMetadata.url
                                    || detailViewData.data.link
                                ) && (
                                    <LinkMetadataURL>
                                        {linkMetadata.url}
                                    </LinkMetadataURL>
                                )}
                            </LinkMetadataContainer>
                        )}
                        {showSpinner
                        && (
                            <SpinnerContainer>
                                <Spinner large />
                            </SpinnerContainer>
                        )}
                    </DetailContentContainer>
                )}
            </Transition>
        </CartridgeDetailContainer>
    ), [
        detailInfoBannerRef,
        detailViewData,
        detailViewRevealed,
        searchParams,
        CARTRIDGE_TYPE,
        user,
        currentSessionId,
        showDetailContent,
        detailViewInfoBannerHeight,
        detailContentContainerRef,
        triggerEmbeddingResize,
        linkMetadata,
    ]);

    const CustomSheet = styled(Sheet)`
        .react-modal-sheet-backdrop {
            background-color: rgba(
                ${hexToRgb(theme.verascopeColor.purple100)!.r},
                ${hexToRgb(theme.verascopeColor.purple100)!.g},
                ${hexToRgb(theme.verascopeColor.purple100)!.b},
                0.6
            ) !important;
        }
        .react-modal-sheet-container {
            background: ${theme.verascopeColor.blue100} !important;
            box-shadow: ${theme.color.boxShadow300};
        }
        .react-modal-sheet-header {
            background: ${theme.verascopeColor.blue100};
        }
        .react-modal-sheet-content {
            background-color: ${DETAIL_VIEW_BACKGROUND};
            background: 'linear-gradient(to top, rgba(32, 125, 140, 0) 0%, rgba(32, 125, 140, 0.2) 40%, rgba(32, 125, 140, 0.3) 60%, rgba(32, 125, 140, 1))';
        }
    `;

    return (
        <Container>
            <PageLogo
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <CartridgeContainer
                width={containerWidth}
                showWarning={hitCartridgeMinimum}
                isSteering={isSteering}
                transitionDuration={DETAIL_VIEW_TRANSITION_DURATION}
            >
                <PageTitle
                    withEnterTransition
                >
                    TREASURE CHEST
                </PageTitle>
                <PageSubtitle
                    withEnterTransition
                    color={theme.verascopeColor.blue100}
                >
                    Open-sourcing the treasured gems that inspired verascope.
                </PageSubtitle>
                <FilterSelector
                    hasSound={hasSound}
                    viewportDimensions={viewportDimensions}
                    cartridges={cartridges}
                    cartridgeCount={cartridgeCount}
                    appliedFilters={appliedFilters}
                    filters={filters}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    triggerFilterAudio={triggerFilterAudio}
                />
                {cartridges.size > 0
                && cartridgeColumns > 0
                && (
                    <Grid
                        ref={cartridgeGridRef}
                        cellRenderer={cartridgeRenderer}
                        columnCount={cartridgeColumns}
                        columnWidth={gridColumnWidth}
                        height={viewportDimensions.height}
                        rowCount={gridRowCount}
                        rowHeight={gridRowHeight}
                        width={cartridgeContainerWidth}
                        overscanRowCount={CARTRIDGE_OVERSCAN_ROW_COUNT}
                        onSectionRendered={onCartridgeSectionRendered}
                        style={{
                            paddingTop: gridPaddingTop,
                            paddingLeft: cartridgeGridHorizontalPadding,
                            paddingRight: cartridgeGridHorizontalPadding,
                            zIndex: gridZIndex,
                        }}
                    />
                )}
            </CartridgeContainer>
            {viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                ? (
                    <DetailView
                        fixed
                        ref={detailViewRef}
                        isVisible={!!detailViewRevealed}
                        zIndex={DETAIL_VIEW_Z_INDEX}
                        width={detailViewWidth}
                        isSteering={isSteering}
                        showWarning={hitDetailMinimum}
                        background={DETAIL_VIEW_BACKGROUND}
                        transitionDuration={DETAIL_VIEW_TRANSITION_DURATION}
                    >
                        {detailViewData?.data.color
                        && (
                            <CartridgeInserted
                                isVisible={!!cartridgeInserted}
                                width={CARTRIDGE_WIDTH}
                                src={CARTRIDGE_TYPE[detailViewData.data.color][CARTRIDGE_STATE.insertedSide]}
                            />
                        )}
                        <Transition
                            in={!!cartridgeInserted}
                            timeout={{
                                enter: EJECT_BUTTON_ENTER_TRANSITION_DURATION,
                                exit: EJECT_BUTTON_EXIT_TRANSITION_DURATION,
                            }}
                        >
                            {(state) => (
                                <EjectButtonContainer>
                                    <EjectButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        transitionDuration={EJECT_BUTTON_ENTER_TRANSITION_DURATION}
                                        onMouseEnter={onEjectButtonEnter}
                                        onMouseLeave={onEjectButtonLeave}
                                        {...(detectTouchDevice(document) ? {
                                            onTouchEnd: () => onEjectCartridge(),
                                        } : {
                                            onMouseDown: () => onEjectCartridge(),
                                        })}
                                        style={{
                                            ...FADE_IN_DEFAULT_STYLE({
                                                direction: 'down',
                                                offset: 20,
                                                duration: EJECT_BUTTON_ENTER_TRANSITION_DURATION,
                                                easing: theme.motion.eagerEasing,
                                            }),
                                            ...FADE_IN_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 20,
                                            })[state],
                                        }}
                                    >
                                        <EjectButtonIcon
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M23.6,14.8L23.6,14.8L13.1,3.3c-0.3-0.3-0.6-0.4-1-0.4c0,0,0,0,0,0c-0.4,0-0.8,0.2-1,0.4L0.4,14.7l0,0
                                                C0.1,15,0,15.3,0,15.7c0,0.8,0.6,1.4,1.4,1.4h21.2c0.8,0,1.4-0.6,1.4-1.4C24,15.3,23.9,15,23.6,14.8z M12.1,6.3l7.4,8H4.6L12.1,6.3z
                                                "
                                            />
                                            <path
                                                d="M22.6,21.1H1.4c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h21.2c0.8,0,1.4,0.6,1.4,1.4S23.4,21.1,22.6,21.1z"
                                            />
                                        </EjectButtonIcon>
                                    </EjectButton>
                                </EjectButtonContainer>
                            )}
                        </Transition>
                        <Resizable
                            width={detailViewWidth}
                            height={viewportDimensions.height}
                            handle={handle}
                            axis="x"
                            onResizeStart={onResizeStart}
                            onResizeStop={onResizeStop}
                            onResize={onResize}
                            minConstraints={[MIN_DETAIL_SECTION_WIDTH, viewportDimensions.height]}
                            maxConstraints={[resizeableMaxConstraintsWidth, viewportDimensions.height]}
                            resizeHandles={['w']}
                        >
                            {CartridgeDetail}
                        </Resizable>
                    </DetailView>
                ) : (
                    <Transition
                        in={showDetailBanner}
                        timeout={{
                            enter: DETAIL_SHEET_ENTER_TRANSITION_DURATION,
                            exit: DETAIL_SHEET_EXIT_TRANSITION_DURATION,
                        }}
                        appear
                        mountOnEnter
                        unmountOnExit
                    >
                        {(state) => (
                            <CustomSheet
                                className={DETAIL_SHEET_CLASSNAME}
                                isOpen={!!detailViewRevealed}
                                onClose={onEjectCartridge}
                                snapPoints={[customSheetSnapPoint]}
                                initialSnap={0}
                                style={{
                                    ...FADE_IN_DEFAULT_STYLE({
                                        direction: 'up',
                                        offset: 20,
                                        duration: DETAIL_SHEET_ENTER_TRANSITION_DURATION,
                                        easing: theme.motion.eagerEasing,
                                    }),
                                    ...FADE_IN_TRANSITION_STYLES({
                                        direction: 'up',
                                        offset: 20,
                                    })[state],
                                }}
                            >
                                <CustomSheet.Container
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    onViewportBoxUpdate={() => {}}
                                >
                                    {detailViewData?.data.color
                                    && (
                                        <CartridgeInserted
                                            isVisible={!!cartridgeInserted}
                                            width={CARTRIDGE_WIDTH}
                                            src={CARTRIDGE_TYPE[detailViewData.data.color][CARTRIDGE_STATE.insertedTop]}
                                        />
                                    )}
                                    <CustomSheet.Header />
                                    <CustomSheet.Content
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        onViewportBoxUpdate={() => {}}
                                    >
                                        <EjectButton
                                            className={HOVER_TARGET_CLASSNAME}
                                            transitionDuration={EJECT_BUTTON_ENTER_TRANSITION_DURATION}
                                            onMouseEnter={onEjectButtonEnter}
                                            onMouseLeave={onEjectButtonLeave}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchEnd: () => onEjectCartridge(),
                                            } : {
                                                onMouseDown: () => onEjectCartridge(),
                                            })}
                                        >
                                            <EjectButtonIcon
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    d="M23.6,14.8L23.6,14.8L13.1,3.3c-0.3-0.3-0.6-0.4-1-0.4c0,0,0,0,0,0c-0.4,0-0.8,0.2-1,0.4L0.4,14.7l0,0
                                                    C0.1,15,0,15.3,0,15.7c0,0.8,0.6,1.4,1.4,1.4h21.2c0.8,0,1.4-0.6,1.4-1.4C24,15.3,23.9,15,23.6,14.8z M12.1,6.3l7.4,8H4.6L12.1,6.3z
                                                    "
                                                />
                                                <path
                                                    d="M22.6,21.1H1.4c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h21.2c0.8,0,1.4,0.6,1.4,1.4S23.4,21.1,22.6,21.1z"
                                                />
                                            </EjectButtonIcon>
                                        </EjectButton>
                                        {CartridgeDetail}
                                    </CustomSheet.Content>
                                </CustomSheet.Container>
                                <Sheet.Backdrop />
                            </CustomSheet>
                        )}
                    </Transition>
                )}
        </Container>
    );
}

export default TreasureChestView;
