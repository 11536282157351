// ===== Packages =====

import {
    Node,
    Editor,
    Transforms,
    Range,
    Point,
    Path,
    NodeEntry,
    BaseRange,
    Element,
    Text,
}                                   from 'slate';

// ===== Components =====

import {
    LineHelpers,
}                                   from './linesPlugin';

// ===== Elements =====

import _ResolutionLevelElement      from '../elements/resolutionLevelElement';

// ===== Interfaces =====

import {
    ResolutionLevelNode,
}                                   from '../interfaces';

// ===== Enums =====

import {
    RESOLUTION_LEVEL,
    RESOLUTION_LEVEL_TYPE,
}                                   from '../elements/enums';

export const ResolutionLevelElement = _ResolutionLevelElement;

const getSelectionResolutionLevels = (editor: Editor): NodeEntry<Node>[] => Array.from(Editor.nodes(editor, {
    match: ResolutionLevelElement.isResolutionLevelElement,
}));

const selectionHasHigherResolutionLevels = (editor: Editor, level: RESOLUTION_LEVEL): boolean => {
    const selectionResolutionLevelNodes = getSelectionResolutionLevels(editor);
    if (!level) return false;
    const hasHigherResolutionLevels = selectionResolutionLevelNodes.reduce((total, [, resolutionLevelPath]) => {
        const resolutionLevel = Node.get(editor, resolutionLevelPath) as ResolutionLevelNode;
        if (resolutionLevel.level > level) {
            return true && total;
        }

        return false;
    }, true);

    return hasHigherResolutionLevels;
};

const hasSelectionResolutionLevels = (editor: Editor): boolean => {
    const [match] = getSelectionResolutionLevels(editor);
    return !!match;
};

const addResolutionLevelToSelection = (editor: Editor, level: RESOLUTION_LEVEL): void => {
    const { selection } = editor;
    if (!selection) { return; }
    const lines = LineHelpers.getSelectionLines(editor);

    // Wrap nodes with new resolution level
    if (lines.length > 1) {
        const firstNode = Editor.first(editor, selection);
        const lastNode = Editor.last(editor, selection);
        const [, firstNodePath] = firstNode as NodeEntry;
        const [, lastNodePath] = lastNode as NodeEntry;
        const blockStartPoint = Editor.start(editor, firstNodePath);
        const blockEndPoint = Editor.end(editor, lastNodePath);
        const entireBlockRange = {
            anchor: blockStartPoint,
            focus: blockEndPoint,
        };
        return Transforms.wrapNodes(editor, ResolutionLevelElement.newResolutionLevelElement({
            level,
            levelType: RESOLUTION_LEVEL_TYPE.block,
        }), { at: entireBlockRange });
    }

    // Test to see if we've selected an entire paragraph
    const selectionLength = Math.abs(selection.focus.offset - selection.anchor.offset);
    const lineLength = (((lines[0] as NodeEntry)[0] as Element).children[0] as Text).text.length;
    const selectionTextLength = Editor.string(editor, selection).length;

    if (selectionLength === lineLength || selectionTextLength === lineLength) {
        const [line] = lines;
        const [, linePath] = line as NodeEntry;
        const blockStartPoint = Editor.start(editor, linePath);
        const blockEndPoint = Editor.end(editor, linePath);
        const entireBlockRange = {
            anchor: blockStartPoint,
            focus: blockEndPoint,
        };
        return Transforms.wrapNodes(editor, ResolutionLevelElement.newResolutionLevelElement({
            level,
            levelType: RESOLUTION_LEVEL_TYPE.block,
        }), { at: entireBlockRange });
    }

    // Transforms.wrapNodes will wrap the selection If no location is specified
    // Which is why we don't have to specify selection
    Transforms.wrapNodes(editor, ResolutionLevelElement.newResolutionLevelElement({
        level,
        levelType: RESOLUTION_LEVEL_TYPE.inline,
    }), { split: true });
};

const removeResolutionLevelFromSelection = (editor: Editor): void => {
    const [resolutionLevelEntry] = Array.from(Editor.nodes(editor, {
        match: ResolutionLevelElement.isResolutionLevelElement,
    }));
    if (resolutionLevelEntry) {
        const [, resolutionLevelPath] = resolutionLevelEntry;
        return Transforms.unwrapNodes(editor, { at: resolutionLevelPath });
    }
};

const toggleResolution = (editor: Editor, level: RESOLUTION_LEVEL): void => {
    if (hasSelectionResolutionLevels(editor) && !selectionHasHigherResolutionLevels(editor, level)) {
        const resolutionLevelEntries = Array.from(Editor.nodes(editor, {
            match: ResolutionLevelElement.isResolutionLevelElement,
        }));

        if (resolutionLevelEntries.length === 1) {
            const [[, resolutionLevelPath]] = resolutionLevelEntries;
            const resolutionLevel = Node.get(editor, resolutionLevelPath) as ResolutionLevelNode;
            if (resolutionLevel.level === level) {
                removeResolutionLevelFromSelection(editor);
            }
        } else {
            addResolutionLevelToSelection(editor, level);
        }
    } else {
        addResolutionLevelToSelection(editor, level);
    }
};

export const ResolutionLevelHelpers = {
    getSelectionResolutionLevels,
    addResolutionLevelToSelection,
    removeResolutionLevelFromSelection,
    hasSelectionResolutionLevels,
    toggleResolution,
    selectionHasHigherResolutionLevels,
};

const normalizeResolutionLevel = (
    editor: Editor,
    node: ResolutionLevelNode,
    path: Path,
): void => {
    if (ResolutionLevelElement.isBlockResolutionLevel(node) && !Editor.isBlock(editor, node)) {
        return Transforms.unwrapNodes(editor, { at: path });
    }

    if (ResolutionLevelElement.isInlineResolutionLevel(node) && Editor.isBlock(editor, node)) {
        return Transforms.unwrapNodes(editor, { at: path });
    }

    const resolutionIsValid = ResolutionLevelElement.isValidResolutionLevel(node.level);
    if (!resolutionIsValid) { return Transforms.unwrapNodes(editor, { at: path }); }

    if (Node.string(node).length === 0) { return Transforms.unwrapNodes(editor, { at: path }); }
};

const handleCursorIsAtEndOfResolutionLevelWhenBreakInserted = (
    editor: Editor,
    insertBreak: () => void,
): boolean | undefined => {
    const { selection } = editor;
    if (Range.isExpanded(selection as Range)) { return; }

    const [resolutionLevelMatch] = Array.from(Editor.nodes(editor, {
        match: ResolutionLevelElement.isResolutionLevelElement,
    }));
    if (!resolutionLevelMatch) { return; }

    const [, resolutionLevelPath] = resolutionLevelMatch;
    const [endResolutionLeaf, endResolutionLeafPath] = Editor.leaf(editor, resolutionLevelPath, { edge: 'end' });
    const endOfResolutionPoint = {
        path: endResolutionLeafPath,
        offset: Node.string(endResolutionLeaf).length,
    };
    const cursorIsAtEndOfResolution = Point.equals((selection as BaseRange).anchor, endOfResolutionPoint);

    if (cursorIsAtEndOfResolution) {
        insertBreak();
        Transforms.move(editor);
        return true;
    }

    return false;
};

export const withResolutionLevels = (editor: Editor): Editor => {
    const {
        normalizeNode, isInline, isVoid, insertBreak,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as ResolutionLevelNode;

        if (ResolutionLevelElement.isResolutionLevelElement(node)) {
            return normalizeResolutionLevel(editor, node, path);
        }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (ResolutionLevelElement.isResolutionLevelElement(element)
        ? ResolutionLevelElement.isInlineResolutionLevel(element)
        : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (ResolutionLevelElement.isResolutionLevelElement(node) ? false : isVoid(node));

    // eslint-disable-next-line no-param-reassign
    editor.insertBreak = () => {
        if (handleCursorIsAtEndOfResolutionLevelWhenBreakInserted(editor, insertBreak)) {
            return;
        }

        return insertBreak();
    };

    return editor;
};
