// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
    useMemo,
    useCallback,
}                                       from 'react';
import {
    css,
    Keyframes,
    FlattenSimpleInterpolation,
}                                       from 'styled-components';
import { ReactSVG }                     from 'react-svg';
import { useDropzone }                  from 'react-dropzone';
import {
    ref,
    listAll,
    getStorage,
    deleteObject,
    getDownloadURL,
    StorageError,
    StorageReference,
    UploadTaskSnapshot,
}                                       from 'firebase/storage';
import {
    doc,
    getDoc,
    setDoc,
    deleteDoc,
    getFirestore,
    DocumentData,
    DocumentSnapshot,
    query,
    collection,
    getDocs,
    where,
    runTransaction,
    writeBatch,
    addDoc,
}                                       from 'firebase/firestore';
import { v4 as uuidv4 }                 from 'uuid';
import mime                             from 'mime-types';
import ShortUniqueId                    from 'short-unique-id';
import { Transition }                   from 'react-transition-group';
import {
    User,
    getAuth,
    deleteUser,
    sendPasswordResetEmail,
    signOut,
}                                       from 'firebase/auth';
import {
    FirebaseError,
}                                       from 'firebase/app';
import TaiPasswordStrength              from 'tai-password-strength';
import PasswordValidator                from 'password-validator';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment                           from 'moment-timezone';

// ===== Components =====

import {
    Button,
}                                       from '../Editor/helpers';
import Tooltip                          from '../Tooltip';

// ===== Services =====

import {
    signIn,
    playAudio,
    getStorageErrorMessage,
    getMediaStorageBucket,
    uploadToCloudStorage,
    validateEmail,
    getMoveIndexLeftKeyframe,
    getMoveIndexRightKeyframe,
    signUpWithEmail,
    updateAnnotationInDB,
    updateAnnotationMediaInDB,
    recordUserAction,
    updateUserInDB,
    getAuthErrorMessage,
    setMediaInDB,
}                                       from '../../services';

// ===== Hooks =====

import { useTimeout }                   from '../../hooks';

// ===== Interfaces =====

import {
    IUserItem,
    IDimension,
    IMediaItem,
    IAuthMethod,
    IPasswordError,
    IAnnotationItem,
    ISnackbarItem,
    IPostItem,
    IEmail,
    IMailingListSubscription,
    ICartItem,
    IAddress,
}                                       from '../../interfaces';

// ===== Enums =====

import {
    MEDIA_TYPE,
    AUTHENTICATION_ERROR_CODE,
    AUTHENTICATION_TYPE,
    BUTTON_TYPE,
    CURSOR_TARGET,
    FIRESTORE_COLLECTION,
    INTERACTABLE_OBJECT,
    KEYFRAME_ANCHOR_TYPE,
    PAGE_ROUTE,
    PASSWORD_STRENGTH,
    STORAGE_ENTITY,
    STORAGE_ERROR_CODE,
    TOOLTIP_TYPE,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Sounds =====

import DialogExpand                     from '../../sounds/swoosh_in.mp3';
import DialogContract                   from '../../sounds/swoosh_out.mp3';
import InputClick                       from '../../sounds/button_click.mp3';

// ===== Images =====

import SmileyIcon                       from '../../images/editor/smiley.svg';
import ArrowIcon                        from '../../images/editor/arrow.svg';
import CheckmarkIcon                    from '../../images/editor/checkmark.svg';
import CrossIcon                        from '../../images/editor/cross.svg';
import PencilIcon                       from '../../images/editor/pencil.svg';
import EnvelopeIcon                     from '../../images/envelope.svg';
import CameraIcon                       from '../../images/camera.svg';
import GoogleIcon                       from '../../images/google.svg';
import GithubIcon                       from '../../images/github.svg';
import FacebookIcon                     from '../../images/facebook.svg';
import TwitterIcon                      from '../../images/twitter.svg';
import AtSignIcon                       from '../../images/at-sign.svg';
import CautionIcon                      from '../../images/caution.svg';
import HighlightIcon                    from '../../images/highlight.svg';
import HistoryIcon                      from '../../images/history.svg';
import UserIcon                         from '../../images/user.svg';
import DialogCornerCurve                from '../../images/editor/editor-toolbar-corner-curve.svg';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    HOVER_TARGET_CLASSNAME,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    DIALOG_BODY_CONTAINER_TRANSITION_DURATION,
    AVATAR_TRANSLATE_DURATION,
    DIALOG_BODY_OPACITY_DELAY_DURATION,
    AVATAR_CONTAINER_OFFSET_TOP_CONTRACTED,
    AVATAR_CONTAINER_OFFSET_RIGHT_CONTRACTED,
    USER_PROFILE_AVATAR_LENGTH,
    USER_PROFILE_CLOSE_BUTTON_LENGTH,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
    MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    EMAIL_SENDER_ADDRESS,
    EMAIL_REPLY_ADDRESS,
    EMAIL_TEMPLATE_NEW_ACCOUNT,
    HEADER_BUTTON_TRANSITION_DURATION,
}                                       from '../../constants/generalConstants';
import MEDIA_QUERY_SIZE                 from '../../constants/mediaQuerySizes';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import KEYCODE                          from '../../constants/keycodes';

// ===== Styles =====

import {
    Container,
    AuthMethod,
    DialogBody,
    CornerCurve,
    NameContainer,
    AvatarOverlay,
    EmailAuthInput,
    AvatarContainer,
    DialogBodyContainer,
    AuthMethodsContainer,
    CloseButtonContainer,
    AuthenticationMessage,
    EmailAuthInputContainer,
    AvatarUploadProgressBar,
    EmailAuthFieldsContainer,
    AvatarUploadProgressBarContainer,
    PasswordStrengthContainer,
    PasswordStrengthBar,
    EmailAuthTabsContainer,
    EmailAuthBackButtonContainer,
    EmailAuthTabButtonContainer,
    ForgotPasswordText,
    SubmitEmailAuthButtonContainer,
    NameInputContainer,
    NameInput,
    NameText,
    NameButtonContainer,
    AuthUserDetails,
    AuthUserContainer,
    AuthUserIcon,
    AuthUserText,
    AuthUserSignOutButtonContainer,
}                                       from './styles';
import {
    UserAvatar,
    DropzoneInput,
    MovingButtonBackground,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    user: IUserItem | null,
    style: any,
    hasSound: boolean,
    lightBackground: boolean,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    isExpanded: boolean,
    cartButtonVisible: boolean,
    nudgeUserToSignUpAfterAnnotation: boolean,
    notifyUserToSignUpForWebBook: boolean,
    notifyUserToSignUpForDigitalBook: boolean,
    notifyUserToSignUpForPhysicalBook: boolean,
    notifyUserToSignUpToNavigateBook: boolean,
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    setSigningInOut: React.Dispatch<React.SetStateAction<boolean>>,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setUser: React.Dispatch<React.SetStateAction<IUserItem | null>>,
    setNudgeUserToSignUpAfterAnnotation: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpForWebBook: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpForDigitalBook: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpForPhysicalBook: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpToNavigateBook: React.Dispatch<React.SetStateAction<boolean>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    setSignedIn: React.Dispatch<React.SetStateAction<AUTHENTICATION_TYPE | null>>,
    signedIn: AUTHENTICATION_TYPE | null,
}
function UserProfileDialog({
    user,
    style,
    hasSound,
    lightBackground,
    currentSessionId,
    viewportDimensions,
    isExpanded,
    cartButtonVisible,
    nudgeUserToSignUpAfterAnnotation,
    notifyUserToSignUpForWebBook,
    notifyUserToSignUpForDigitalBook,
    notifyUserToSignUpForPhysicalBook,
    notifyUserToSignUpToNavigateBook,
    setIsExpanded,
    setSigningInOut,
    setInputFocused,
    setUser,
    setNudgeUserToSignUpAfterAnnotation,
    setNotifyUserToSignUpForWebBook,
    setNotifyUserToSignUpForDigitalBook,
    setNotifyUserToSignUpForPhysicalBook,
    setNotifyUserToSignUpToNavigateBook,
    onCursorEnter,
    onCursorLeave,
    setSnackbarData,
    setSignedIn,
    signedIn,
}: Props): JSX.Element {
    // ===== General Constants =====

    const OVERLAY_TRANSITION_DURATION = 200;
    const DIALOG_OFFSET_TOP_CONTRACTED = 10;
    const DIALOG_OFFSET_RIGHT_CONTRACTED = 10;
    const DIALOG_OFFSET_TOP_EXPANDED = 40;
    const DIALOG_OFFSET_RIGHT_EXPANDED = 5;
    const DIALOG_BODY_PADDING = 10;
    const DIALOG_BODY_WIDTH_EXPANDED = 288;
    const DIALOG_BODY_HEIGHT_EXPANDED = 155;
    const DIALOG_BODY_HEIGHT_EXPANDED_SIGNED_IN = 130;
    const DIALOG_BODY_HEIGHT_EXPANDED_SIGNED_IN_WITH_ANNOTATIONS = 150;
    const DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_SIGN_UP = 225;
    const DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_SIGN_UP_WITH_PASSWORD_STRENGTH = 240;
    const DIALOG_BODY_HEIGHT_EXPANDED_FORGOT_PASSWORD = 205;
    const DIALOG_BODY_HEIGHT_EXPANDED_FORGOT_PASSWORD_AND_SUBMIT_BUTTON = 225;
    const DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_FORGOT_PASSWORD = 240;
    const DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_SUBMIT_BUTTON = 255;
    const DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_PASSWORD_STRENGTH_AND_SUBMIT_BUTTON = 275;
    const DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_FORGET_PASSWORD_AND_SUBMIT_BUTTON = 280;
    const CLOSE_BUTTON_CONTAINER_OFFSET_TOP_CONTRACTED = 10;
    const CLOSE_BUTTON_CONTAINER_OFFSET_RIGHT_CONTRACTED = 10;
    const CLOSE_BUTTON_CONTAINER_OFFSET_TOP_EXPANDED = 5;
    const CLOSE_BUTTON_CONTAINER_OFFSET_RIGHT_EXPANDED = 5;
    const AVATAR_CONTAINER_OFFSET_TOP_EXPANDED = 50;
    const AVATAR_CONTAINER_OFFSET_RIGHT_EXPANDED = DIALOG_BODY_WIDTH_EXPANDED - USER_PROFILE_AVATAR_LENGTH - 5;
    const ANONYMOUS_AVATAR_ICON_ID = 'anonymous-avatar-icon-id';
    const NUM_EMAIL_AUTH_TABS = 2;
    const EMAIL_AUTH_TAB_HEIGHT = 30;
    const EMAIL_AUTH_INPUT_WIDTH = 200;
    const EMAIL_AUTH_BACK_BUTTON_HEIGHT = EMAIL_AUTH_TAB_HEIGHT;
    const EMAIL_AUTH_SUBMIT_BUTTON_HEIGHT = EMAIL_AUTH_TAB_HEIGHT;
    const EMAIL_AUTH_TAB_WIDTH = 95;
    const MOVING_BUTTON_INITIAL_INDEX = 1;
    const EMAIL_AUTH_TAB_MARGIN = 5;
    const EMAIL_AUTH_TAB_BACKGROUND_COLOR = theme.color.neutral800;
    const SIGN_IN_INDEX = 1;
    const SIGN_UP_INDEX = 2;
    const FIRST_NAME_INPUT_WIDTH = 80;
    const LAST_NAME_INPUT_WIDTH = 80;
    const NAME_BUTTON_LENGTH = USER_PROFILE_AVATAR_LENGTH;
    const NAME_BUTTON_SPACING_WIDTH = 5;
    const SNACKBAR_MESSAGE_EXISTING_AVATAR_UPLOAD = 'Unable to upload new avatar while upload another';
    const SNACKBAR_MESSAGE_AVATAR_MISSING_USER = 'There was a problem uploading avatar. Please try again.';
    const SNACKBAR_MESSAGE_SIGN_IN_GOOGLE_ERROR = 'There was a problem signing in with Google.';
    const SNACKBAR_MESSAGE_SIGN_IN_GITHUB_ERROR = 'There was a problem signing in with Github.';
    const SNACKBAR_MESSAGE_SIGN_IN_FACEBOOK_ERROR = 'There was a problem signing in with Facebook.';
    const SNACKBAR_MESSAGE_SIGN_IN_TWITTER_ERROR = 'There was a problem signing in with Twitter.';
    const SNACKBAR_MESSAGE_RESET_EMAIL_SENT = (email: string): string => `An email has been sent to your email address, ${
        email
    }. Follow the directions in the email to reset your password.`;
    const SNACKBAR_MESSAGE_RESET_EMAIL_ERROR = 'There was a problem resetting your password. Please try again.';
    const SNACKBAR_MESSAGE_EMAIL_SIGN_UP_ERROR = 'There was a problem signing up.';
    const SNACKBAR_MESSAGE_EMAIL_SIGN_IN_ERROR = 'There was a problem signing in. Please try again.';
    const SNACKBAR_MESSAGE_SIGN_OUT_ERROR = 'There was a problem signing out. Please try again.';
    const SNACKBAR_MESSAGE_SIGN_OUT_SUCCESS = 'Sucessfully signed out!';
    const SNACKBAR_MESSAGE_AUTHENTICATION_ERROR = 'There was a problem authenticating. Please try again.';
    const USER_AUTH_SUBMIT_BUTTON_HEIGHT = EMAIL_AUTH_TAB_HEIGHT;
    // eslint-disable-next-line max-len
    const GENERIC_SIGN_IN_BOOK_ACCESS_MESSAGE = 'You are browsing anonymously. Sign in to gain unrestricted access to verascope\'s online book.';
    const SIGN_IN_ANNOTATION_EDIT_ACCESS_MESSAGE = 'You are browsing anonymously. Sign in to retain edit access of annotations into future sessions.';
    const SIGN_IN_GET_WEB_BOOK_ACCESS_MESSAGE = 'You are browsing anonymously. Please sign in to gain access to the web book.';
    const SIGN_IN_GET_DIGITAL_BOOK_ACCESS_MESSAGE = 'You are browsing anonymously. Please sign in to purchase the digital book.';
    const SIGN_IN_GET_PHYSICAL_BOOK_ACCESS_MESSAGE = 'You are browsing anonymously. Please sign in to purchase the physical book.';

    // ===== Refs =====

    const emailAuthInputRef = useRef<HTMLInputElement>(null);
    const passwordAuthInputRef = useRef<HTMLInputElement>(null);
    const firstNameInputRef = useRef<HTMLInputElement>(null);
    const lastNameInputRef = useRef<HTMLInputElement>(null);

    // ----- Sound Clips
    const dialogExpandClip = useRef<HTMLAudioElement>(new Audio());
    const dialogContractClip = useRef<HTMLAudioElement>(new Audio());
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [avatarURL, setAvatarURL] = useState<string | null>(null);
    const [avatarPath, setAvatarPath] = useState<string | undefined>(undefined);
    const [uploadingMedia, setUploadingMedia] = useState<IMediaItem | null>(null);
    // We track the progress of avatar upload here
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    // We track the completion of avatar here
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    const [showEmailAuthFields, setShowEmailAuthFields] = useState<boolean>(false);
    const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
    const [transitioningEmailAuthFields, setTransitioningEmailAuthFields] = useState<boolean>(false);
    // stores desired state of pivot button background
    const [movingButtonBackground, setMovingButtonBackground] = useState<{ active: boolean, desiredIndex: number}>({
        active: false,
        desiredIndex: 0,
    });
    // stores the current index of the pivot button background
    const [movingButtonCurrentIndex, setMovingButtonCurrentIndex] = useState(MOVING_BUTTON_INITIAL_INDEX);
    // stores the next index of the movingButtonCurrentIndex to be set following a timeout
    const [movingButtonNextIndex, setMovingButtonNextIndex] = useState(MOVING_BUTTON_INITIAL_INDEX + 1);
    const [emailAuth, setEmailAuth] = useState<string>('');
    const [passwordAuth, setPasswordAuth] = useState<string>('');
    // indicates whether email address of user is valid or not
    const [emailValid, setEmailValid] = useState<boolean>(true);
    // indicates whether password of user is valid or not
    const [passwordValid, setPasswordValid] = useState<boolean>(true);
    // stores schema used to evaluate password validity
    const [passwordSchema] = useState<PasswordValidator>(
        new PasswordValidator()
            .is()
            .min(8)                                         // Minimum length 8
            .is()
            .max(100)                                       // Maximum length 100
            .has()
            .uppercase()                                    // Must have uppercase letters
            .has()
            .lowercase()                                    // Must have lowercase letters
            .has()
            .digits(2)                                      // Must have at least 2 digits
            .has()
            .not()
            .spaces()                                       // Should not have spaces
            .is()
            .not()
            .oneOf(TaiPasswordStrength.commonPasswords),    // Blacklist these values
    );
    // stores any password errors
    const [passwordErrors, setPasswordErrors] = useState<IPasswordError[]>([]);
    // stores the password strength value
    const [passwordStrength, setPasswordStrength] = useState<PASSWORD_STRENGTH | null>(null);
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            handleAvatarReplace(acceptedFiles[0]);
        }
    }, [user]);
    const {
        open: openDropzone,
        getRootProps,
        getInputProps,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: 'image/*',
        onDropAccepted,
    });
    const [hasForgotPassword, setHasForgotPassword] = useState<boolean>(false);
    const [showEmailAuthSubmitButton, setShowEmailAuthSubmitButton] = useState<boolean>(false);
    // Indicates whether tooltip encouraging user to sign up is visible
    // Presents itself when user creates an annotation
    const [showSignUpTooltip, setShowSignUpTooltip] = useState<boolean>(false);
    // Indicates whether tooltip informing user to sign up is visible
    // Presents itself when anonymous user attempts to unlock web book
    const [showGetWebBookTooltip, setShowGetWebBookTooltip] = useState<boolean>(false);
    // Indicates whether tooltip informing user to sign up is visible
    // Presents itself when anonymous user attempts to download digital book
    const [showGetDigitalBookTooltip, setShowGetDigitalBookTooltip] = useState<boolean>(false);
    // Indicates whether tooltip informing user to sign up is visible
    // Presents itself when anonymous user attempts to purchase physical book
    const [showGetPhysicalBookTooltip, setShowGetPhysicalBookTooltip] = useState<boolean>(false);
    const [editingUserName, setEditingUserName] = useState<boolean>(false);

    // ===== Animation Constants =====

    const DIALOG_BODY_OPACITY_DURATION = 150;
    const NUM_EMAIL_AUTH_FIELDS = 4;
    const EMAIL_AUTH_TABS_INDEX = NUM_EMAIL_AUTH_FIELDS - (NUM_EMAIL_AUTH_FIELDS - 0);
    const EMAIL_AUTH_EMAIL_FIELD_INDEX = NUM_EMAIL_AUTH_FIELDS - (NUM_EMAIL_AUTH_FIELDS - 1);
    const EMAIL_AUTH_PASSWORD_FIELD_INDEX = NUM_EMAIL_AUTH_FIELDS - (NUM_EMAIL_AUTH_FIELDS - 2);
    const EMAIL_AUTH_FORGOT_PASSWORD_INDEX = NUM_EMAIL_AUTH_FIELDS - (NUM_EMAIL_AUTH_FIELDS - 3);
    const EMAIL_AUTH_BACK_BUTTON_INDEX = NUM_EMAIL_AUTH_FIELDS;
    const AUTH_METHOD_TRANSITION_DURATION = 150;
    const EMAIL_AUTH_FIELD_TRANSITION_DURATION = 150;
    const PASSWORD_STRENGTH_CONTAINER_TRANSITION_DURATION = 150;
    const FORGOT_PASSWORD_TRANSITION_DURATION = 150;
    const EMAIL_AUTH_SUBMIT_BUTTON_TRANSITION_DURATION = 150;
    const HIDE_SIGN_UP_TOOLTIP_DURATION = 8000;
    const HIDE_GET_WEB_TOOLTIP_DURATION = HIDE_SIGN_UP_TOOLTIP_DURATION;
    const HIDE_GET_DIGITAL_TOOLTIP_DURATION = HIDE_SIGN_UP_TOOLTIP_DURATION;
    const HIDE_GET_PHYSICAL_TOOLTIP_DURATION = HIDE_SIGN_UP_TOOLTIP_DURATION;
    const AUTH_USER_DETAILS_TRANSITION_DURATION = 150;
    const NUM_AUTH_USER_DETAILS = 4;
    const AUTH_USER_DETAILS_AUTH_TYPE_INDEX = NUM_AUTH_USER_DETAILS - (NUM_AUTH_USER_DETAILS - 0);
    const AUTH_USER_DETAILS_MEMBERSHIP_INDEX = NUM_AUTH_USER_DETAILS - (NUM_AUTH_USER_DETAILS - 1);
    const AUTH_USER_DETAILS_ANNOTATIONS_INDEX = NUM_AUTH_USER_DETAILS - (NUM_AUTH_USER_DETAILS - 2);
    const AUTH_USER_DETAILS_SIGN_OUT_BUTTON_INDEX = NUM_AUTH_USER_DETAILS - (NUM_AUTH_USER_DETAILS - 3);
    const AUTH_USER_DETAILS_ENTER_DURATION = 150;

    // ===== Methods =====

    const onAvatarMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.userAvatar,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onAvatarMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.userProfileButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onToggleExpand = async (): Promise<void> => {
        const wasExpanded = isExpanded;
        setIsExpanded(!isExpanded);

        // If sign in to navigate book flag active, deactivate it
        if (notifyUserToSignUpToNavigateBook) setNotifyUserToSignUpToNavigateBook(false);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: wasExpanded
                    ? USER_ACTION_TYPE.hideProfile
                    : USER_ACTION_TYPE.viewProfile,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }

        // Play Sound
        if (
            !isExpanded
            && hasSound
            && dialogExpandClip.current
        ) {
            dialogExpandClip.current.pause();
            dialogExpandClip.current.currentTime = 0;
            playAudio(dialogExpandClip.current);
        } else if (
            isExpanded
            && hasSound
            && dialogContractClip.current
        ) {
            dialogContractClip.current.pause();
            dialogContractClip.current.currentTime = 0;
            playAudio(dialogContractClip.current);
        }
    };

    const addUploadingMedia = (mediaItem: IMediaItem): void => {
        setUploadingMedia(mediaItem);
    };

    const updateUploadingMedia = (mediaItem: IMediaItem): void => {
        setUploadingMedia(mediaItem);
    };

    const removeUploadingMedia = (): void => {
        setUploadingMedia(null);
    };

    const selectImage = (e: React.MouseEvent): void => {
        if (!uploadingMedia) {
            e.stopPropagation();
            openDropzone();
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_EXISTING_AVATAR_UPLOAD,
                icon: CautionIcon,
                hasFailure: true,
            });
        }
    };

    const handleAvatarReplace = async (file: File): Promise<void> => {
        if (!user) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_AVATAR_MISSING_USER,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }
        const mediaId = uuidv4();
        const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
        const mediaBucket = getMediaStorageBucket(MEDIA_TYPE.image);
        const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
        const fileExtension = mime.extension(file.type);
        const storageEntity = process.env.NODE_ENV === 'production'
            ? STORAGE_ENTITY.users
            : STORAGE_ENTITY.stagingUsers;
        const filePath = `${storageEntity}/${user.id}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
        const mediaItem: IMediaItem = {
            id: mediaId,
            type: MEDIA_TYPE.image,
            userId: user.id,
            file,
            filePath,
            uploadProgress: 0,
        };
        setUploadingMedia(mediaItem);

        // make db entry of media
        // execute before file upload so upload cloud function has a place to write to
        setMediaInDB({
            mediaItem,
            filePath,
        });

        // upload file to cloud storage
        // url will be set by cloud function
        const uploadTask = uploadToCloudStorage(
            file,
            filePath,
        );

        uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadingStateChangeEvent({
                    mediaItem,
                    snapshot,
                    progress,
                });
            },
            (error: StorageError) => {
                throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
            },
            () => {
                setUploadingCompleteEvent({
                    mediaItem,
                    storageRef: uploadTask.snapshot.ref,
                });
            },
        );
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia) {
            updateUploadingMedia({
                ...uploadingMedia,
                uploadProgress: progress,
            });
        } else {
            addUploadingMedia(mediaItem);
        }
    };

    const handleUploadingMediaComplete = (): void => {
        // Add avatar file Path to user item
        if (user && uploadingMedia) {
            updateUserInDB({
                userId: user.id,
                ...user,
                avatarFilePath: uploadingMedia.filePath,
            });
        }

        // Clear uploading state change event data
        setUploadingStateChangeEvent(null);

        // Clear uploading complete even flag
        setUploadingCompleteEvent(null);

        // Remove from uploading queue
        removeUploadingMedia();
    };

    /* An email firebase account associated with a social authentication method will
     * be upgraded to the social login
    */
    const onAuthentication = async (type: AUTHENTICATION_TYPE): Promise<void> => {
        // Ensures we firebase and firestore listeners are paused
        setSigningInOut(true);

        const auth = getAuth();
        const isAnonymousUser = auth.currentUser?.isAnonymous;

        // Cache old user item to merge with authenticated user item
        let oldUser: IUserItem;
        if (user && isAnonymousUser) {
            oldUser = {
                ...user, // all basic data types
                sessions: [
                    ...user.sessions,
                ],
                annotations: [
                    ...user.annotations,
                ],
                cart: user.cart
                    ? [...user.cart]
                    : [],
                addresses: user.addresses
                    ? [...user.addresses]
                    : [],
            };
        }

        // Delete anonymous user
        // If authentication fails, authentication listener  will
        // create a new anonymous user and populate it with user data
        // we don't want to delete the anonymous user for email authentication here though
        // that is done earlier in its flow
        if (auth.currentUser && isAnonymousUser && type !== AUTHENTICATION_TYPE.email) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const { uid } = auth.currentUser;
            // delete anonymous user item in firestore
            const oldUserRef = doc(db, usersCollection, uid);
            await deleteDoc(oldUserRef);

            // delete any avatar files associated with anonymous user
            const storage = getStorage();
            // Create a reference under which you want to list
            const listRef = ref(storage, `${usersCollection}/${uid}/images`);
            listAll(listRef)
                .then((rootRes) => {
                    rootRes.prefixes.forEach((folderRef) => {
                        const mediaItemId = folderRef.name;
                        // delete all image files in folder
                        listAll(folderRef)
                            .then((folderRes) => {
                                folderRes.items.forEach((itemRef) => {
                                    // delete image file
                                    deleteObject(itemRef);
                                });
                            }).catch((error) => {
                                throw Error(`There was a problem deleting anonymous user: ${error}`);
                            });
                        // delete associated media item
                        const mediaCollection = process.env.NODE_ENV === 'production'
                            ? FIRESTORE_COLLECTION.media
                            : FIRESTORE_COLLECTION.stagingMedia;
                        const mediaItemRef = doc(db, mediaCollection, mediaItemId);
                        deleteDoc(mediaItemRef);
                    });
                }).catch((error) => {
                    throw Error(`There was a problem deleting anonymous user: ${error}`);
                });

            // delete anonymous user in firebase user directory
            // and signs out
            // signingInOut state prevents any overwriting due to authentication listener
            await deleteUser(auth.currentUser)
                .catch((error) => {
                    throw Error(`There was a problem deleting anonymous user: ${error}`);
                });
        }

        switch (type) {
        case AUTHENTICATION_TYPE.google:
            signIn({
                type: AUTHENTICATION_TYPE.google,
                onComplete: (currentUser: User | null, accessToken?: string | undefined) => onAuthenticationSuccess(
                    AUTHENTICATION_TYPE.google,
                    currentUser,
                    oldUser,
                    accessToken,
                ).then(() => {
                    // Unlocks authentication listener to accept new user objects
                    setSigningInOut(false);
                    // Reset authentication elements
                    resetAuth();
                }),
                onError: (error: FirebaseError) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_SIGN_IN_GOOGLE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                },
            });
            break;
        case AUTHENTICATION_TYPE.github:
            signIn({
                type: AUTHENTICATION_TYPE.github,
                onComplete: (currentUser: User | null, accessToken?: string | undefined) => onAuthenticationSuccess(
                    AUTHENTICATION_TYPE.github,
                    currentUser,
                    oldUser,
                    accessToken,
                ).then(() => {
                    // Unlocks authentication listener to accept new user objects
                    setSigningInOut(false);
                    // Reset authentication elements
                    resetAuth();
                }),
                onError: (error: FirebaseError) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_SIGN_IN_GITHUB_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                },
            });
            break;
        case AUTHENTICATION_TYPE.facebook:
            signIn({
                type: AUTHENTICATION_TYPE.facebook,
                onComplete: (currentUser: User | null, accessToken?: string | undefined) => onAuthenticationSuccess(
                    AUTHENTICATION_TYPE.facebook,
                    currentUser,
                    oldUser,
                    accessToken,
                ).then(() => {
                    // Unlocks authentication listener to accept new user objects
                    setSigningInOut(false);
                    // Reset authentication elements
                    resetAuth();
                }),
                onError: (error: FirebaseError) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_SIGN_IN_FACEBOOK_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                },
            });
            break;
        case AUTHENTICATION_TYPE.twitter:
            signIn({
                type: AUTHENTICATION_TYPE.twitter,
                onComplete: (currentUser: User | null, accessToken?: string | undefined) => onAuthenticationSuccess(
                    AUTHENTICATION_TYPE.twitter,
                    currentUser,
                    oldUser,
                    accessToken,
                ).then(() => {
                    // Unlocks authentication listener to accept new user objects
                    setSigningInOut(false);
                    // Reset authentication elements
                    resetAuth();
                }),
                onError: (error: FirebaseError) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_SIGN_IN_TWITTER_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                },
            });
            break;
        default:
            // Email Authentication Method
            setShowEmailAuthFields(true);
        }
    };

    const onSubmitEmailAuth = async (): Promise<void> => {
        const auth = getAuth();
        const isAnonymousUser = auth.currentUser?.isAnonymous;

        if (hasForgotPassword && emailValid) {
            sendPasswordResetEmail(auth, emailAuth)
                .then(() => {
                    // Inform user about email
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_RESET_EMAIL_SENT(emailAuth),
                        icon: EnvelopeIcon,
                        hasSuccess: true,
                    });
                    // Unlocks authentication listener to accept new user objects
                    setSigningInOut(false);
                    // Reset authentication elements
                    resetAuth();
                })
                .catch((error) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_RESET_EMAIL_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                });
            return;
        }

        // Ensures we firebase and firestore listeners are paused
        setSigningInOut(true);

        // Cache old user item to merge with authenticated user item
        let oldUser: IUserItem;
        if (user && isSigningUp) {
            const emailParts = emailAuth.split('@');
            oldUser = {
                ...user, // all basic data types
                firstName: emailParts[0],
                lastName: emailParts[1],
                email: emailAuth,
                sessions: [
                    ...user.sessions,
                ],
                annotations: [
                    ...user.annotations,
                ],
                cart: user.cart
                    ? [...user.cart]
                    : [],
                addresses: user.addresses
                    ? [...user.addresses]
                    : [],
            };
        } else if (user && isAnonymousUser) {
            oldUser = {
                ...user, // all basic data types
                sessions: [
                    ...user.sessions,
                ],
                annotations: [
                    ...user.annotations,
                ],
                cart: user.cart
                    ? [...user.cart]
                    : [],
                addresses: user.addresses
                    ? [...user.addresses]
                    : [],
            };
        }

        // Delete anonymous user
        // If authentication fails, authentication listener  will
        // create a new anonymouse user and populate it with user data
        if (auth.currentUser && isAnonymousUser) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const { uid } = auth.currentUser;
            // delete anonymous user item in firestore
            const oldUserRef = doc(db, usersCollection, uid);
            await deleteDoc(oldUserRef);

            // delete any avatar files associated with anonymous user
            const storage = getStorage();
            // Create a reference under which you want to list
            const listRef = ref(storage, `${usersCollection}/${uid}/images`);
            listAll(listRef)
                .then((rootRes) => {
                    rootRes.prefixes.forEach((folderRef) => {
                        const mediaItemId = folderRef.name;
                        // delete all image files in folder
                        listAll(folderRef)
                            .then((folderRes) => {
                                folderRes.items.forEach((itemRef) => {
                                    // delete image file
                                    deleteObject(itemRef);
                                });
                            }).catch((error) => {
                                throw Error(`There was a problem deleting anonymous user: ${error}`);
                            });
                        // delete associated media item
                        const mediaCollection = process.env.NODE_ENV === 'production'
                            ? FIRESTORE_COLLECTION.media
                            : FIRESTORE_COLLECTION.stagingMedia;
                        const mediaItemRef = doc(db, mediaCollection, mediaItemId);
                        deleteDoc(mediaItemRef);
                    });
                }).catch((error) => {
                    throw Error(`There was a problem deleting anonymous user: ${error}`);
                });

            // delete anonymous user in firebase user directory
            // and signs out
            // signingInOut state prevents any overwriting due to authentication listener
            await deleteUser(auth.currentUser)
                .catch((error) => {
                    throw Error(`There was a problem deleting anonymous user: ${error}`);
                });
        }

        if (
            isSigningUp
            && emailValid
            && passwordValid
        ) {
            signUpWithEmail({
                credentials: {
                    email: emailAuth,
                    password: passwordAuth,
                },
                onComplete: (currentUser) => {
                    onAuthenticationSuccess(
                        AUTHENTICATION_TYPE.email,
                        currentUser,
                        oldUser,
                    ).then(() => {
                        if (currentSessionId) {
                            // Record user action
                            recordUserAction({
                                type: USER_ACTION_TYPE.signUpProfile,
                                userId: currentUser.uid,
                                sessionId: currentSessionId,
                                payload: {
                                    authenticationType: AUTHENTICATION_TYPE.email,
                                },
                            });
                        }
                        // Unlocks authentication listener to accept new user objects
                        setSigningInOut(false);
                        // Reset authentication elements
                        resetAuth();
                    });
                },
                onError: (error: FirebaseError) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_EMAIL_SIGN_UP_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                },
            });
        } else if (
            !isSigningUp
            && emailValid
            && passwordAuth.length > 0
        ) {
            signIn({
                type: AUTHENTICATION_TYPE.email,
                credentials: {
                    email: emailAuth,
                    password: passwordAuth,
                },
                onComplete: (currentUser) => {
                    onAuthenticationSuccess(
                        AUTHENTICATION_TYPE.email,
                        currentUser,
                        oldUser,
                    ).then(() => {
                        if (currentSessionId && currentUser) {
                            // Record user action
                            recordUserAction({
                                type: USER_ACTION_TYPE.signInProfile,
                                userId: currentUser.uid,
                                sessionId: currentSessionId,
                            });
                        }
                        // Unlocks authentication listener to accept new user objects
                        setSigningInOut(false);
                        // Reset authentication elements
                        resetAuth();
                    });
                },
                onError: (error: FirebaseError) => {
                    setSigningInOut(false);
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: getAuthErrorMessage(error.code as AUTHENTICATION_ERROR_CODE) || SNACKBAR_MESSAGE_EMAIL_SIGN_IN_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                },
            });
        }
    };

    const onAuthenticationSuccess = async (
        authenticationType: AUTHENTICATION_TYPE,
        currentUser: User | null,
        oldUser?: IUserItem, // We can't rely on prop because it will be overwritten by user item listener
        _accessToken?: string | undefined,
    ): Promise<void> => {
        const auth = getAuth();
        let uid: string | null = null;
        if (currentUser) {
            uid = currentUser.uid;
        } else if (auth.currentUser) {
            uid = auth.currentUser.uid;
        }

        if (!uid) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_AUTHENTICATION_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }

        // If sign in to navigate book flag active, deactivate it
        if (notifyUserToSignUpToNavigateBook) setNotifyUserToSignUpToNavigateBook(false);

        const db = getFirestore();
        const usersCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.users
            : FIRESTORE_COLLECTION.stagingUsers;
        const userRef = doc(db, usersCollection, uid);

        // Check for existence before creating new user
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            const firestoreUser = userSnap.data() as IUserItem;

            if (oldUser) {
                const userUpdates: Record<string, number | string[] | boolean | IMailingListSubscription | ICartItem[] | IAddress[]> = {};
                // update database
                userUpdates.sessions = [
                    ...firestoreUser.sessions,
                    ...oldUser.sessions,
                ];
                userUpdates.annotations = [
                    ...firestoreUser.annotations,
                    ...oldUser.annotations,
                ];
                if (
                    'cart' in oldUser
                    && 'cart' in firestoreUser
                    && oldUser.cart
                    && firestoreUser.cart
                ) {
                    userUpdates.cart = [
                        ...firestoreUser.cart,
                        ...oldUser.cart,
                    ];
                } else if (
                    'cart' in oldUser
                    && oldUser.cart
                ) {
                    userUpdates.cart = [
                        ...oldUser.cart,
                    ];
                } else if (
                    'cart' in firestoreUser
                    && firestoreUser.cart
                ) {
                    userUpdates.cart = [
                        ...firestoreUser.cart,
                    ];
                }
                if (
                    'addresses' in oldUser
                    && 'addresses' in firestoreUser
                    && oldUser.addresses
                    && firestoreUser.addresses
                ) {
                    userUpdates.addresses = [
                        ...firestoreUser.addresses,
                        ...oldUser.addresses,
                    ];
                } else if (
                    'addresses' in oldUser
                    && oldUser.addresses
                ) {
                    userUpdates.addresses = [
                        ...oldUser.addresses,
                    ];
                } else if (
                    'cart' in firestoreUser
                    && firestoreUser.addresses
                ) {
                    userUpdates.addresses = [
                        ...firestoreUser.addresses,
                    ];
                }
                if (
                    'mailingListSubscription' in oldUser
                    && 'mailingListSubscription' in firestoreUser
                    && oldUser.mailingListSubscription
                    && !firestoreUser.mailingListSubscription
                ) {
                    // Both user items have a mailing list attribute
                    // But the old user has it activated, while the new one doesn't
                    // Transfer active value
                    const oldUserMailListHistory = oldUser.mailingListSubscription!.history;
                    oldUserMailListHistory.shift();
                    userUpdates.mailingListSubscription = {
                        history: [
                            ...firestoreUser.mailingListSubscription!.history,
                            ...oldUserMailListHistory,
                        ],
                    };
                } else if (
                    !('mailingListSubscription' in firestoreUser)
                    && 'mailingListSubscription' in oldUser
                    && oldUser.mailingListSubscription
                ) {
                    // Only the old user has a mailing list attribute
                    // And it is activated
                    // Transfer active value
                    userUpdates.mailingListSubscription = {
                        history: [
                            ...oldUser.mailingListSubscription!.history,
                        ],
                    };
                } else if (!('mailingListSubscription' in firestoreUser)) {
                    // User doesn't have a mailing list attribute
                    // Give it to them
                    userUpdates.mailingListSubscription = {
                        history: [
                            {
                                subscribed: false,
                                timestamp: Date.now(),
                            },
                            {
                                subscribed: true,
                                timestamp: Date.now(),
                            },
                        ],
                    };
                }

                await updateUserInDB({
                    userId: firestoreUser.id,
                    ...userUpdates,
                });

                // Make sure all annotations use new user id
                await modifyAnnotationsAuthor(
                    oldUser.id,
                    firestoreUser.id,
                );

                // Make sure all annotation media use new user id
                await modifyAnnotationMediaAuthor(
                    oldUser.id,
                    firestoreUser.id,
                );

                // Make sure all sessions use new user id
                await modifySessionsAuthor(
                    oldUser.id,
                    firestoreUser.id,
                );

                // Make sure all user actions use new user id
                await modifyUserActionsAuthor(
                    oldUser.id,
                    firestoreUser.id,
                );

                // Make sure all posts use new user id
                await modifyPostsAuthor(
                    oldUser.id,
                    firestoreUser.id,
                );
            }
        } else if (oldUser) {
            // Create new user item for authenticated user
            const authUserItem: IUserItem = {
                ...oldUser, // session and annotations and book purchases and cart propagated
                id: uid,
                authenticationType,
                joined: Date.now(),
            };
            const providerUser = auth.currentUser?.providerData[0];

            // We overwrite user first name even if they changed their anonymous name
            if (
                providerUser
                && providerUser.displayName
            ) {
                const displayNameParts = providerUser.displayName.split(' ');
                const [firstName] = displayNameParts;

                authUserItem.firstName = firstName;
            }

            // We overwrite user last name even if they changed their anonymous name
            if (
                providerUser
                && providerUser.displayName
            ) {
                const displayNameParts = providerUser.displayName.split(' ');
                const lastName = displayNameParts.length > 1
                    ? displayNameParts[1]
                    : null;

                if (lastName) {
                    authUserItem.lastName = lastName;
                }
            }

            if (
                providerUser
                && providerUser.email
            ) {
                const { email } = providerUser;

                authUserItem.email = email;
            }

            if (
                providerUser
                && providerUser.phoneNumber
            ) {
                const { phoneNumber } = providerUser;

                authUserItem.phoneNumber = phoneNumber;
            }

            if (
                providerUser
                && providerUser.photoURL
            ) {
                const { photoURL } = providerUser;

                authUserItem.photoURL = photoURL;
            }

            // Always subscribe new users to mailing list
            if (!('mailingListSubscription' in authUserItem)) {
                // User doesn't have a mailing list attribute
                // Give it to them
                authUserItem.mailingListSubscription = {
                    history: [
                        {
                            subscribed: false,
                            timestamp: Date.now(),
                        },
                        {
                            subscribed: true,
                            timestamp: Date.now(),
                        },
                    ],
                };
            } else {
                authUserItem.mailingListSubscription = {
                    history: [
                        ...authUserItem.mailingListSubscription!.history,
                        {
                            subscribed: true,
                            timestamp: Date.now(),
                        },
                    ],
                };
            }
            await setDoc(userRef, authUserItem);

            // Make sure all annotations are user new user id
            await modifyAnnotationsAuthor(
                oldUser.id,
                authUserItem.id,
            );

            // Make sure all annotation media use new user id
            await modifyAnnotationMediaAuthor(
                oldUser.id,
                authUserItem.id,
            );

            // Make sure all sessions use new user id
            await modifySessionsAuthor(
                oldUser.id,
                authUserItem.id,
            );

            // Make sure all user actions use new user id
            await modifyUserActionsAuthor(
                oldUser.id,
                authUserItem.id,
            );

            // Make sure all posts use new user id
            await modifyPostsAuthor(
                oldUser.id,
                authUserItem.id,
            );

            // Send confirmation email
            const emailCollection = FIRESTORE_COLLECTION.mail;
            if (authUserItem.email) {
                const emailRequest: IEmail = {
                    from: EMAIL_SENDER_ADDRESS,
                    to: authUserItem.email,
                    replyTo: EMAIL_REPLY_ADDRESS,
                    message: EMAIL_TEMPLATE_NEW_ACCOUNT({
                        email: authUserItem.email,
                        unsubscribeLink: `${window.location.origin}/${PAGE_ROUTE.unsubscribe}/${oldUser.id}`,
                    }),
                };
                await addDoc(collection(db, emailCollection), emailRequest);
            }

            if (
                currentSessionId
                && currentUser
                && authenticationType !== AUTHENTICATION_TYPE.anonymous
                && authenticationType !== AUTHENTICATION_TYPE.email
            ) {
                // Record user action
                // First time logging in with a social account
                recordUserAction({
                    type: USER_ACTION_TYPE.signUpProfile,
                    userId: currentUser.uid,
                    sessionId: currentSessionId,
                    payload: {
                        authenticationType,
                    },
                });
            }
        }

        // Check whether some other anonymous user exists with similar email
        // If so, transfer information to new user item
        // Delete old user items
        if (oldUser && oldUser.email) {
            const usersQuery = query(
                collection(db, usersCollection),
                where('email', '==', oldUser.email),
                where('id', '!=', uid),
            );
            const usersSnap = await getDocs(usersQuery);
            if (!usersSnap.empty) {
                // Get most up to date version of user
                const firestoreUserSnap = await getDoc(userRef);
                if (firestoreUserSnap.exists()) {
                    const firestoreUser = firestoreUserSnap.data() as IUserItem;
                    const userUpdates: Record<string, number | string[] | boolean | IMailingListSubscription> = {};

                    // Transfer duplicate information
                    usersSnap.forEach(async (userDoc) => {
                        if (userDoc.exists()) {
                            const duplicateUser = userDoc.data() as IUserItem;

                            // Use highest joined date
                            // Duplicate likely comes from signing up to mailing
                            // list and then registering account
                            if (duplicateUser.joined > firestoreUser.joined) {
                                userUpdates.joined = duplicateUser.joined;
                            } else {
                                // Keep current joined date
                            }

                            // Merge sessions
                            if (duplicateUser.sessions.length > 0) {
                                userUpdates.sessions = [
                                    ...firestoreUser.sessions,
                                    ...duplicateUser.sessions,
                                ];

                                // Make sure all sessions use new user id
                                await modifySessionsAuthor(
                                    duplicateUser.id,
                                    firestoreUser.id,
                                );
                            }

                            // Merge annotations
                            if (duplicateUser.annotations.length > 0) {
                                firestoreUser.annotations = [
                                    ...firestoreUser.annotations,
                                    ...duplicateUser.annotations,
                                ];

                                // Make sure all annotations use new user id
                                await modifyAnnotationsAuthor(
                                    duplicateUser.id,
                                    firestoreUser.id,
                                );

                                // Make sure all annotation media use new user id
                                await modifyAnnotationMediaAuthor(
                                    duplicateUser.id,
                                    firestoreUser.id,
                                );
                            }

                            // Make sure all user actions use new user id
                            await modifyUserActionsAuthor(
                                duplicateUser.id,
                                firestoreUser.id,
                            );

                            // Make sure all posts use new user id
                            await modifyPostsAuthor(
                                duplicateUser.id,
                                firestoreUser.id,
                            );

                            // Change mailing list value
                            if (
                                !firestoreUser.mailingListSubscription
                                && duplicateUser.mailingListSubscription
                            ) {
                                userUpdates.mailingListSubscription = duplicateUser.mailingListSubscription;
                            }

                            // Delete user item in firestore
                            const oldUserRef = doc(db, usersCollection, duplicateUser.id);
                            await deleteDoc(oldUserRef);
                        }
                    });

                    if (Object.keys(userUpdates).length > 0) {
                        updateUserInDB({
                            userId: uid,
                            ...userUpdates,
                        });
                    }
                }
            }
        }
    };

    const modifyAnnotationsAuthor = async (
        oldUserId: string,
        newUserId: string,
    ): Promise<void> => {
        const db = getFirestore();
        // We want to update all annotations, even deleted ones, so we get all of them from DB
        const annotationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        const annotationQuery = query(
            collection(db, annotationCollection),
            where('userId', '==', oldUserId),
        );
        const annotationsSnap = await getDocs(annotationQuery);

        annotationsSnap.forEach((annotationDoc) => {
            const annotation = annotationDoc.data();
            if (annotation.userId !== newUserId) {
                updateAnnotationInDB({
                    collection: annotationCollection,
                    id: annotation.id,
                    userId: newUserId,
                });
            }
        });
    };

    const modifyAnnotationMediaAuthor = async (
        oldUserId: string,
        newUserId: string,
    ): Promise<void> => {
        const db = getFirestore();
        // We want to update all annotations, even deleted ones, so we get all of them from DB
        const annotationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        const mediaCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.media
            : FIRESTORE_COLLECTION.stagingMedia;
        const annotationQuery = query(
            collection(db, annotationCollection),
            where('userId', '==', oldUserId),
        );
        const annotationsSnap = await getDocs(annotationQuery);

        // Get media ids
        const mediaIds: string[] = [];
        annotationsSnap.forEach((annotationSnap) => {
            if (annotationSnap.exists()) {
                const annotationItem = annotationSnap.data() as IAnnotationItem;
                mediaIds.push(...annotationItem.media);
            }
        });

        // Get media items
        const mediaPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
        for (let i = 0; i < mediaIds.length; i += 1) {
            const mediaId = mediaIds[i];
            mediaPromises.push(getDoc(doc(db, mediaCollection, mediaId)));
        }

        return Promise.all(mediaPromises)
            .then((mediaItems) => {
                mediaItems.forEach((mediaSnap) => {
                    if (mediaSnap.exists()) {
                        const mediaItem = mediaSnap.data() as IMediaItem;
                        if (mediaItem.userId !== newUserId) {
                            updateAnnotationMediaInDB({
                                collection: mediaCollection,
                                annotationMediaType: mediaItem.type,
                                id: mediaItem.id,
                                userId: newUserId,
                            });
                        }
                    }
                });
            });
    };

    const modifySessionsAuthor = async (
        oldUserId: string,
        newUserId: string,
    ): Promise<void> => {
        const db = getFirestore();
        const sessionCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.userSessions
            : FIRESTORE_COLLECTION.stagingUserSessions;
        const sessionQuery = query(
            collection(db, sessionCollection),
            where('userId', '==', oldUserId),
        );
        const sessionsSnap = await getDocs(sessionQuery);

        // Get session ids
        const sessionIds: string[] = [];
        sessionsSnap.forEach((sessionSnap) => {
            sessionIds.push(sessionSnap.id);
        });

        // Get and update session items

        // Get a new write batch
        const batch = writeBatch(db);
        for (let i = 0; i < sessionIds.length; i += 1) {
            const sessionId = sessionIds[i];
            const sessionRef = doc(db, sessionCollection, sessionId);
            batch.update(sessionRef, {
                userId: newUserId,
            });
        }

        // Commit the batch
        await batch.commit();
    };

    const modifyUserActionsAuthor = async (
        oldUserId: string,
        newUserId: string,
    ): Promise<void> => {
        const db = getFirestore();
        const actionCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.userActions
            : FIRESTORE_COLLECTION.stagingUserActions;
        const actionsQuery = query(
            collection(db, actionCollection),
            where('userId', '==', oldUserId),
        );
        const actionsSnap = await getDocs(actionsQuery);

        // Get user actions ids
        const actionIds: string[] = [];
        actionsSnap.forEach((actionSnap) => {
            actionIds.push(actionSnap.id);
        });

        // Get and update user actions items

        // Get a new write batch
        const batch = writeBatch(db);
        for (let i = 0; i < actionIds.length; i += 1) {
            const actionId = actionIds[i];
            const actionRef = doc(db, actionCollection, actionId);
            batch.update(actionRef, {
                userId: newUserId,
            });
        }

        // Commit the batch
        await batch.commit();
    };

    const modifyPostsAuthor = async (
        oldUserId: string,
        newUserId: string,
    ): Promise<void> => {
        const db = getFirestore();
        const postCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.posts
            : FIRESTORE_COLLECTION.stagingPosts;
        const postQuery = query(
            collection(db, postCollection),
            where('authors', 'array-contains', oldUserId),
        );
        const postsSnap = await getDocs(postQuery);

        // Get post
        const postIds: string[] = [];
        postsSnap.forEach((postSnap) => {
            postIds.push(postSnap.id);
        });

        const postPromises: Promise<void>[] = [];
        for (let i = 0; i < postIds.length; i += 1) {
            const postId = postIds[i];
            const postTransaction = runTransaction(db, async (transaction) => {
                const postRef = doc(db, postCollection, postId);
                const postSnapshot = await getDoc(postRef);
                if (postSnapshot.exists()) {
                    const postItem = postSnapshot.data() as IPostItem;
                    const { authors } = postItem;
                    let updatePost = false;
                    if (authors.includes(oldUserId)) {
                        const authorIndex = authors.findIndex((id: string) => id === oldUserId);
                        authors.splice(authorIndex, 1);
                        updatePost = true;
                    }
                    if (!authors.includes(newUserId)) {
                        authors.push(newUserId);
                        updatePost = true;
                    }
                    if (updatePost) {
                        transaction.update(postRef, { authors });
                    }
                }
            });
            postPromises.push(postTransaction);
        }

        await Promise.all(postPromises);
    };

    const handleEmailChange = (): void => {
        if (emailAuthInputRef.current) setEmailAuth(emailAuthInputRef.current.value);
    };

    const handlePasswordChange = (): void => {
        if (passwordAuthInputRef.current) setPasswordAuth(passwordAuthInputRef.current.value);
    };

    const onInputMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.input,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onInputMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onInputBlur = (): void => {
        setInputFocused(false);
    };

    const checkForEnter = (e: React.KeyboardEvent): void => {
        if (e.key === KEYCODE.enter) {
            // Cancel the default action, if needed
            e.preventDefault();
            const successButtonVisible = isExpanded
                && showEmailAuthFields
                && (
                    showEmailAuthSubmitButton
                    || (hasForgotPassword && emailAuth.length > 0)
                );

            if (
                emailAuthInputRef.current
                && emailAuthInputRef.current === document.activeElement
            ) {
                // Move from email field to password field
                passwordAuthInputRef.current?.focus();
            }  else if (
                firstNameInputRef.current
                && firstNameInputRef.current === document.activeElement
            ) {
                // Move from first name field to last name field
                lastNameInputRef.current?.focus();
            } else if (
                successButtonVisible
                && !isSigningUp
                && !hasForgotPassword
            ) {
                // Sign in
                onSubmitEmailAuth();
            } else if (
                successButtonVisible
                && !isSigningUp
                && (hasForgotPassword && emailAuth.length > 0)
            ) {
                // Forgot password
                onSubmitEmailAuth();
            }  else if (
                successButtonVisible
                && isSigningUp
            ) {
                // Sign up
                onSubmitEmailAuth();
            }
        } else if (e.key === KEYCODE.escape) {
            // Cancel the default action, if needed
            e.preventDefault();

            // Blur Focused Input
            (document.activeElement as HTMLInputElement).blur();
        }
    };

    const onEmailAuthBackButtonClick = (_e: React.MouseEvent): void => {
        if (showEmailAuthSubmitButton) setShowEmailAuthSubmitButton(false);
        if (hasForgotPassword) setHasForgotPassword(false);
        setShowEmailAuthFields(false);
        setTransitioningEmailAuthFields(true);
        setEmailAuth('');
        setPasswordAuth('');
        setPasswordStrength(null);
        setPasswordErrors([]);
        setEmailValid(true);
        setPasswordValid(true);
        setMovingButtonCurrentIndex(MOVING_BUTTON_INITIAL_INDEX);
        setIsSigningUp(false);
        clearTimeoutAnimatingEmailAuthFields();
        timeoutAnimatingEmailAuthFields();
    };

    const onEmailAuthTabButtonClick = async (
        index: number,
        e: React.MouseEvent,
    ): Promise<void> => {
        e.stopPropagation();
        // set next moving button index used
        // in the timeout
        setMovingButtonNextIndex(index);
        // clear any revious delay
        clearDelayChangeMovingButtonIndex();
        // start new delay
        delayChangeMovingButtonIndex();
        // start moving the button by setting desired index
        setMovingButtonBackground({
            active: true,
            desiredIndex: index,
        });

        if (index === 1 && isSigningUp) {
            setIsSigningUp(false);
        } else if (index === SIGN_UP_INDEX && !isSigningUp) {
            setIsSigningUp(true);
        }

        if (hasForgotPassword) {
            setHasForgotPassword(false);
        }
    };

    const onForgotPassword = (): void => {
        setHasForgotPassword(true);
    };

    const onEditName = (_e: React.MouseEvent): void => {
        setEditingUserName(true);
    };

    const onSaveEditName = (_e: React.MouseEvent): void => {
        const nameUpdates: Record<string, string> = {};
        const firstNameValue = firstNameInputRef.current?.value;
        const lastNameValue = lastNameInputRef.current?.value;
        if (
            user
            && firstNameInputRef.current
            && firstNameValue
            && firstNameValue !== user.firstName
        ) {
            if (firstNameValue.length === 0) {
                // error
            } else {
                nameUpdates.firstName = firstNameInputRef.current.value;
            }
        }
        if (
            user
            && lastNameInputRef.current
            && lastNameValue
            && lastNameValue !== user.lastName
        ) {
            if (lastNameValue.length === 0) {
                // error
            } else {
                nameUpdates.lastName = lastNameInputRef.current.value;
            }
        }

        if (user && Object.keys(nameUpdates).length > 0) {
            updateUserInDB({
                userId: user.id,
                ...nameUpdates,
            });
        }

        setEditingUserName(false);
    };

    const onCancelEditName = (_e: React.MouseEvent): void => {
        setEditingUserName(false);
    };

    const resetAuth = (): void => {
        if (emailAuthInputRef.current) emailAuthInputRef.current.value = '';
        if (passwordAuthInputRef.current) passwordAuthInputRef.current.value = '';
        if (showEmailAuthFields) setShowEmailAuthFields(false);
        if (showEmailAuthSubmitButton) setShowEmailAuthSubmitButton(false);
        if (!emailValid) setEmailValid(true);
        if (!passwordValid) setPasswordValid(true);
        if (passwordErrors.length > 0) setPasswordErrors([]);
        if (passwordStrength) setPasswordStrength(null);
        if (hasForgotPassword) setHasForgotPassword(false);
        if (showSignUpTooltip) setShowSignUpTooltip(false);
        if (showGetWebBookTooltip) setShowGetWebBookTooltip(false);
        if (showGetDigitalBookTooltip) setShowGetDigitalBookTooltip(false);
        if (showGetPhysicalBookTooltip) setShowGetPhysicalBookTooltip(false);
        if (isSigningUp) setIsSigningUp(false);
    };

    const onSignOut = (): void => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.signOutProfile,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }

        const auth = getAuth();
        // Ensures we firebase and firestore listeners are paused
        setSigningInOut(true);
        signOut(auth)
            .then(() => {
                // Communicates to parent that we are not authenticated
                setSignedIn(null);
                // Clear user so it is not used to repopulate new anonymous user item
                setUser(null);
                // Unlocks authentication listener to accept new user objects
                setSigningInOut(false);
                setAvatarURL(null);
                setAvatarPath(undefined);
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SIGN_OUT_SUCCESS,
                    icon: UserIcon,
                    hasSuccess: true,
                });
            }).catch(() => {
                setSigningInOut(false);
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SIGN_OUT_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            });
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            dialogExpandClip.current
            && dialogContractClip.current
            && inputClickClip.current
        ) {
            // Dialog Expand
            dialogExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dialogExpandClip.current.src = DialogExpand;

            // Dialog Contract
            dialogContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dialogContractClip.current.src = DialogContract;

            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;
        }

        return function cleanup() {
            if (dialogExpandClip.current) dialogExpandClip.current.remove();
            if (dialogContractClip.current) dialogContractClip.current.remove();
            if (inputClickClip.current) inputClickClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            handleUploadingMediaComplete();
        }
    }, [uploadingCompleteEvent]);

    useEffect(() => {
        if (user && user.avatarFilePath !== avatarPath) {
            setAvatarPath(user.avatarFilePath);
            const storage = getStorage();
            if (user && user.avatarFilePath) {
                const pathParts = user.avatarFilePath.split('.');
                const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                getDownloadURL(ref(storage, mediumPath)).then((imageURL) => {
                    setAvatarURL(imageURL);
                }).catch((error) => {
                    // We assume cloud function has not yet generated medium image yet
                    if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                        getDownloadURL(ref(storage, user.avatarFilePath)).then((imageURL) => {
                            setAvatarURL(imageURL);
                        }).catch((err) => {
                            throw Error(getStorageErrorMessage(err.code as STORAGE_ERROR_CODE));
                        });
                    } else {
                        throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                    }
                });
            } else {
                setAvatarURL(null);
                setAvatarPath(undefined);
            }
        }
    }, [user?.avatarFilePath]);

    useEffect(() => {
        if (emailAuth.length > 0 && !validateEmail(emailAuth)) {
            setEmailValid(false);
            if (showEmailAuthSubmitButton) setShowEmailAuthSubmitButton(false);
        } else {
            setEmailValid(true);
            if (
                (
                    (
                        passwordAuth
                        && emailAuth
                        && passwordValid
                        // email will be valid valid
                    ) || !isSigningUp
                )
                && passwordAuth.length > 0
                && emailAuth.length > 0
            ) setShowEmailAuthSubmitButton(true);
        }
    }, [
        emailAuth,
        isSigningUp,
    ]);

    useEffect(() => {
        if (passwordAuth.length > 0) {
            const valid = passwordSchema.validate(passwordAuth, { details: true });
            if (typeof valid === 'boolean') {
                setPasswordValid(valid);
                setPasswordErrors([]);
            } else {
                const errors = [...valid];
                const isValid = errors.length === 0;
                setPasswordValid(isValid);
                setPasswordErrors([
                    ...errors,
                ]);
            }

            if (
                (
                    (
                        (
                            (typeof valid === 'boolean' && valid)
                            || ((valid as any[]).length === 0)
                        )
                        && passwordAuth
                        && emailAuth
                        && emailValid
                    ) || !isSigningUp
                )
                && emailAuth.length > 0
                && passwordAuth.length > 0
            ) {
                setShowEmailAuthSubmitButton(true);
            } else if (showEmailAuthSubmitButton) {
                setShowEmailAuthSubmitButton(false);
            }

            if (!isSigningUp) return;

            const strengthTester = new TaiPasswordStrength.PasswordStrength();
            // Add in extra files for additional checks and better results
            strengthTester.addCommonPasswords(TaiPasswordStrength.commonPasswords);
            strengthTester.addTrigraphMap(TaiPasswordStrength.trigraphs);
            const strengthTestResult = strengthTester.check(passwordAuth);

            if (strengthTestResult.strengthCode.indexOf(PASSWORD_STRENGTH.veryWeak) >= 0) {
                setPasswordStrength(PASSWORD_STRENGTH.veryWeak);
            } else if (strengthTestResult.strengthCode.indexOf(PASSWORD_STRENGTH.weak) >= 0) {
                setPasswordStrength(PASSWORD_STRENGTH.weak);
            } else if (strengthTestResult.strengthCode.indexOf(PASSWORD_STRENGTH.reasonable) >= 0) {
                setPasswordStrength(PASSWORD_STRENGTH.reasonable);
            } else if (strengthTestResult.strengthCode.indexOf(PASSWORD_STRENGTH.strong) >= 0) {
                setPasswordStrength(PASSWORD_STRENGTH.strong);
            } else if (strengthTestResult.strengthCode.indexOf(PASSWORD_STRENGTH.veryStrong) >= 0) {
                setPasswordStrength(PASSWORD_STRENGTH.veryStrong);
            }
        } else {
            if (!passwordValid) setPasswordValid(true);
            if (passwordErrors.length > 0) setPasswordErrors([]);
            if (passwordStrength) setPasswordStrength(null);
            if (showEmailAuthSubmitButton) setShowEmailAuthSubmitButton(false);
        }
    }, [
        passwordAuth,
        isSigningUp,
    ]);
    useEffect(() => {
        if (
            nudgeUserToSignUpAfterAnnotation
            && !showSignUpTooltip
        ) {
            if (!isExpanded) setIsExpanded(true);
            setShowSignUpTooltip(true);
            clearTimeoutHideSignUpTooltip();
            timeoutHideSignUpTooltip();
            const auth = getAuth();
            const { currentUser } = auth;
            if (
                currentSessionId
                && currentUser
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.presentedCreateAccountAnnotationNudgeMessage,
                    userId: currentUser.uid,
                    sessionId: currentSessionId,
                });
            }
        }
    }, [nudgeUserToSignUpAfterAnnotation]);

    useEffect(() => {
        if (
            notifyUserToSignUpForWebBook
            && !showGetWebBookTooltip
        ) {
            if (!isExpanded) setIsExpanded(true);
            setShowGetWebBookTooltip(true);
            clearTimeoutHideGetWebBookTooltip();
            timeoutHideGetWebBookTooltip();

            const auth = getAuth();
            const { currentUser } = auth;
            if (
                currentSessionId
                && currentUser
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.presentedCreateAccountWebNudgeMessage,
                    userId: currentUser.uid,
                    sessionId: currentSessionId,
                });
            }
        }
    }, [notifyUserToSignUpForWebBook]);

    useEffect(() => {
        if (
            notifyUserToSignUpForDigitalBook
            && !showGetDigitalBookTooltip
        ) {
            if (!isExpanded) setIsExpanded(true);
            setShowGetDigitalBookTooltip(true);
            clearTimeoutHideGetDigitalBookTooltip();
            timeoutHideGetDigitalBookTooltip();

            const auth = getAuth();
            const { currentUser } = auth;
            if (
                currentSessionId
                && currentUser
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.presentedCreateAccountDigitalNudgeMessage,
                    userId: currentUser.uid,
                    sessionId: currentSessionId,
                });
            }
        }
    }, [notifyUserToSignUpForDigitalBook]);

    useEffect(() => {
        if (
            notifyUserToSignUpForPhysicalBook
            && !showGetPhysicalBookTooltip
        ) {
            if (!isExpanded) setIsExpanded(true);
            setShowGetPhysicalBookTooltip(true);
            clearTimeoutHideGetPhysicalBookTooltip();
            timeoutHideGetPhysicalBookTooltip();

            const auth = getAuth();
            const { currentUser } = auth;
            if (
                currentSessionId
                && currentUser
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.presentedCreateAccountPhysicalNudgeMessage,
                    userId: currentUser.uid,
                    sessionId: currentSessionId,
                });
            }
        }
    }, [notifyUserToSignUpForPhysicalBook]);

    useEffect(() => {
        if (
            notifyUserToSignUpToNavigateBook
            && !isExpanded
        ) {
            setIsExpanded(true);

            const auth = getAuth();
            const { currentUser } = auth;
            if (
                currentSessionId
                && currentUser
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.presentedCreateAccountNavigateNudgeMessage,
                    userId: currentUser.uid,
                    sessionId: currentSessionId,
                });
            }
        } else if (
            !notifyUserToSignUpToNavigateBook
            && isExpanded
        ) {
            setIsExpanded(false);
        }
    }, [notifyUserToSignUpToNavigateBook]);

    const {
        start: delayChangeMovingButtonIndex,
        clear: clearDelayChangeMovingButtonIndex,
    } = useTimeout(
        useCallback(() => {
            setMovingButtonBackground({
                active: true,
                desiredIndex: movingButtonNextIndex,
            });
            // Set the new index
            setMovingButtonCurrentIndex(movingButtonNextIndex);
        }, [
            movingButtonNextIndex,
        ]),
        Math.min(
            Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
            MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
        ),
    );

    const {
        start: timeoutAnimatingEmailAuthFields,
        clear: clearTimeoutAnimatingEmailAuthFields,
    } = useTimeout(() => {
        setTransitioningEmailAuthFields(false);
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((NUM_EMAIL_AUTH_FIELDS - Math.max(EMAIL_AUTH_TABS_INDEX - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ));

    const {
        start: timeoutHideSignUpTooltip,
        clear: clearTimeoutHideSignUpTooltip,
    } = useTimeout(() => {
        setShowSignUpTooltip(false);
        setNudgeUserToSignUpAfterAnnotation(false);
    }, HIDE_SIGN_UP_TOOLTIP_DURATION);

    const {
        start: timeoutHideGetWebBookTooltip,
        clear: clearTimeoutHideGetWebBookTooltip,
    } = useTimeout(() => {
        setShowGetWebBookTooltip(false);
        setNotifyUserToSignUpForWebBook(false);
    }, HIDE_GET_WEB_TOOLTIP_DURATION);

    const {
        start: timeoutHideGetDigitalBookTooltip,
        clear: clearTimeoutHideGetDigitalBookTooltip,
    } = useTimeout(() => {
        setShowGetDigitalBookTooltip(false);
        setNotifyUserToSignUpForDigitalBook(false);
    }, HIDE_GET_DIGITAL_TOOLTIP_DURATION);

    const {
        start: timeoutHideGetPhysicalBookTooltip,
        clear: clearTimeoutHideGetPhysicalBookTooltip,
    } = useTimeout(() => {
        setShowGetPhysicalBookTooltip(false);
        setNotifyUserToSignUpForPhysicalBook(false);
    }, HIDE_GET_PHYSICAL_TOOLTIP_DURATION);

    // ===== Memoization =====

    const dialogBodyContainerHeight = useMemo(() => {
        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && (hasForgotPassword && emailAuth.length > 0)
            && !isSigningUp
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_FORGOT_PASSWORD_AND_SUBMIT_BUTTON;
        }

        if (hasForgotPassword) {
            return DIALOG_BODY_HEIGHT_EXPANDED_FORGOT_PASSWORD;
        }

        if (
            signedIn
            && user
            && user.annotations.length === 0
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_SIGNED_IN;
        }

        if (
            signedIn
            && user
            && user.annotations.length > 0
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_SIGNED_IN_WITH_ANNOTATIONS;
        }

        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && showEmailAuthSubmitButton
            && !isSigningUp
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_FORGET_PASSWORD_AND_SUBMIT_BUTTON;
        }

        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && showEmailAuthSubmitButton
            && isSigningUp
            && passwordStrength
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_PASSWORD_STRENGTH_AND_SUBMIT_BUTTON;
        }

        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && showEmailAuthSubmitButton
            && isSigningUp
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_SUBMIT_BUTTON;
        }

        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && !isSigningUp
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_WITH_FORGOT_PASSWORD;
        }

        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && isSigningUp
            && passwordStrength
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_SIGN_UP_WITH_PASSWORD_STRENGTH;
        }

        if (
            (showEmailAuthFields || transitioningEmailAuthFields)
            && isSigningUp
        ) {
            return DIALOG_BODY_HEIGHT_EXPANDED_EMAIL_AUTH_SIGN_UP;
        }

        return DIALOG_BODY_HEIGHT_EXPANDED;
    }, [
        signedIn,
        isSigningUp,
        passwordStrength,
        hasForgotPassword,
        showEmailAuthFields,
        transitioningEmailAuthFields,
        showEmailAuthSubmitButton,
    ]);

    // ===== Rendering =====

    const authMethods: IAuthMethod[] = [
        {
            type: AUTHENTICATION_TYPE.google,
            tooltipText: 'Google',
            icon: GoogleIcon,
            onClick: () => onAuthentication(AUTHENTICATION_TYPE.google),
        },
        {
            type: AUTHENTICATION_TYPE.github,
            tooltipText: 'Github',
            icon: GithubIcon,
            onClick: () => onAuthentication(AUTHENTICATION_TYPE.github),
        },
        {
            type: AUTHENTICATION_TYPE.facebook,
            tooltipText: 'Facebook',
            icon: FacebookIcon,
            onClick: () => onAuthentication(AUTHENTICATION_TYPE.facebook),
        },
        {
            type: AUTHENTICATION_TYPE.twitter,
            tooltipText: 'Twitter',
            icon: TwitterIcon,
            onClick: () => onAuthentication(AUTHENTICATION_TYPE.twitter),
        },
        {
            type: AUTHENTICATION_TYPE.email,
            tooltipText: 'Email',
            icon: AtSignIcon,
            onClick: () => onAuthentication(AUTHENTICATION_TYPE.email),
        },
    ];

    const buttonAnimations = useMemo(() => {
        let animations: Map<string, Keyframes> = new Map();
        for (let i = 1; i < NUM_EMAIL_AUTH_TABS; i += 1) {
            const moveIndexLeft = getMoveIndexLeftKeyframe(
                EMAIL_AUTH_TAB_WIDTH,
                i,
                EMAIL_AUTH_TAB_MARGIN,
                KEYFRAME_ANCHOR_TYPE.left,
            );
            const moveIndexRight = getMoveIndexRightKeyframe(
                EMAIL_AUTH_TAB_WIDTH,
                i,
                EMAIL_AUTH_TAB_MARGIN,
                KEYFRAME_ANCHOR_TYPE.left,
            );
            animations = animations.set(
                `move${i}Right`,
                moveIndexRight,
            );
            animations = animations.set(
                `move${i}Left`,
                moveIndexLeft,
            );
        }

        return animations;
    }, [
        movingButtonCurrentIndex,
    ]);

    let animation: FlattenSimpleInterpolation | null = null;
    if (movingButtonBackground.active) {
        const keyframe = buttonAnimations.get(
            `move${
                Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex)
            }${
                movingButtonBackground.desiredIndex > movingButtonCurrentIndex
                    ? 'Right'
                    : 'Left'
            }`,
        );

        // Error: https://stackoverflow.com/questions/67601508/why-do-my-styled-component-keyframes-error-with-ts-styled-plugin9999-in-react
        // eslint-disable-next-line max-len
        animation = css`${keyframe} ${Math.min(Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION, MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION)}ms${` ${theme.motion.delayEasing}`}`;
    }

    const AuthTypeIcon = useMemo(() => {
        if (user && signedIn) {
            switch (user.authenticationType) {
            case AUTHENTICATION_TYPE.google:
                return GoogleIcon;
            case AUTHENTICATION_TYPE.github:
                return GithubIcon;
            case AUTHENTICATION_TYPE.facebook:
                return FacebookIcon;
            case AUTHENTICATION_TYPE.twitter:
                return TwitterIcon;
            case AUTHENTICATION_TYPE.email:
                return EnvelopeIcon;
            default:
                // We shouldn't hit here
                // If we do we have a problem
                // Anonymouse users shouldn't need this
            }
        }

        return null;
    }, [
        user?.authenticationType,
        signedIn,
    ]);

    const authTypeText = useMemo(() => {
        if (user && signedIn) {
            switch (user.authenticationType) {
            case AUTHENTICATION_TYPE.google:
                return 'Google Sign-in';
            case AUTHENTICATION_TYPE.github:
                return 'Github Sign-in';
            case AUTHENTICATION_TYPE.facebook:
                return 'Facebook Sign-in';
            case AUTHENTICATION_TYPE.twitter:
                return 'Twitter Sign-in';
            case AUTHENTICATION_TYPE.email:
                return 'Email Sign-in';
            default:
                // We shouldn't hit here
                // If we do we have a problem
                // Anonymouse users shouldn't need this
            }
        }

        return null;
    }, [
        user?.authenticationType,
        signedIn,
    ]);

    const numAuthUserDetails = useMemo(() => (user && user.annotations.length > 0
        ? NUM_AUTH_USER_DETAILS
        : NUM_AUTH_USER_DETAILS - 1
    ), [user?.annotations]);

    return (
        <Container
            isExpanded={isExpanded}
            cartButtonVisible={cartButtonVisible}
            lightBackground={lightBackground}
            transitionDuration={HEADER_BUTTON_TRANSITION_DURATION}
            style={style}
            {...(!isExpanded
                ? {
                    onClick: onToggleExpand,
                }
                : {}
            )}
        >
            <AvatarContainer
                {...getRootProps({
                    className: HOVER_TARGET_CLASSNAME,
                    onMouseEnter: onAvatarMouseEnter,
                    onMouseLeave: onAvatarMouseLeave,
                    ...(isExpanded ? { onClick: selectImage } : {}),
                })}
                length={USER_PROFILE_AVATAR_LENGTH}
                lightBackground={lightBackground}
                isExpanded={isExpanded}
                contractedTop={AVATAR_CONTAINER_OFFSET_TOP_CONTRACTED}
                contractedRight={AVATAR_CONTAINER_OFFSET_RIGHT_CONTRACTED}
                expandedTop={AVATAR_CONTAINER_OFFSET_TOP_EXPANDED}
                expandedRight={AVATAR_CONTAINER_OFFSET_RIGHT_EXPANDED}
                translateDuration={AVATAR_TRANSLATE_DURATION}
                anonIconId={ANONYMOUS_AVATAR_ICON_ID}
                avatarLength={USER_PROFILE_AVATAR_LENGTH}
                dialogBodyPadding={DIALOG_BODY_PADDING}
            >
                {!uploadingMedia
                && isExpanded
                && (<DropzoneInput {...getInputProps()} />)}
                {avatarURL || (user && !!user.photoURL)
                    ? (
                        <UserAvatar
                            url={avatarURL || user!.photoURL!} // Has to be present if avatarURL is false
                            length={USER_PROFILE_AVATAR_LENGTH}
                        />
                    ) : (
                        <ReactSVG
                            id={ANONYMOUS_AVATAR_ICON_ID}
                            src={SmileyIcon}
                        />
                    )}
                <AvatarOverlay
                    length={USER_PROFILE_AVATAR_LENGTH}
                    uploadingImage={!!uploadingMedia}
                    transitionDuration={OVERLAY_TRANSITION_DURATION}
                >
                    {uploadingMedia
                        ? (
                            <AvatarUploadProgressBarContainer
                                length={USER_PROFILE_AVATAR_LENGTH}
                            >
                                <AvatarUploadProgressBar
                                    progress={uploadingMedia.uploadProgress}
                                />
                            </AvatarUploadProgressBarContainer>
                        ) : (
                            <ReactSVG
                                src={CameraIcon}
                            />
                        )}
                </AvatarOverlay>
            </AvatarContainer>
            <DialogBodyContainer
                contractedTop={DIALOG_OFFSET_TOP_CONTRACTED}
                contractedRight={DIALOG_OFFSET_RIGHT_CONTRACTED}
                expandedTop={DIALOG_OFFSET_TOP_EXPANDED}
                expandedRight={DIALOG_OFFSET_RIGHT_EXPANDED}
                avatarLength={USER_PROFILE_AVATAR_LENGTH}
                closeButtonLength={USER_PROFILE_CLOSE_BUTTON_LENGTH}
                expandedWidth={DIALOG_BODY_WIDTH_EXPANDED}
                expandedHeight={dialogBodyContainerHeight}
                isExpanded={isExpanded}
                padding={DIALOG_BODY_PADDING}
                transitionDuration={DIALOG_BODY_CONTAINER_TRANSITION_DURATION}
            >
                {viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                && (
                    <CornerCurve
                        isExpanded={isExpanded}
                        closeButtonLength={USER_PROFILE_CLOSE_BUTTON_LENGTH}
                        transitionDuration={DIALOG_BODY_CONTAINER_TRANSITION_DURATION}
                    >
                        <ReactSVG
                            src={DialogCornerCurve}
                        />
                    </CornerCurve>
                )}
                {user && (
                    <DialogBody
                        isExpanded={isExpanded}
                        opacityDuration={DIALOG_BODY_OPACITY_DURATION}
                        transitionDelayDuration={DIALOG_BODY_OPACITY_DELAY_DURATION}
                    >
                        {editingUserName
                            ? (
                                <NameInputContainer>
                                    <NameInput
                                        type="text"
                                        ref={firstNameInputRef}
                                        className={HOVER_TARGET_CLASSNAME}
                                        defaultValue={user.firstName}
                                        placeholder="First"
                                        onKeyUp={checkForEnter}
                                        width={FIRST_NAME_INPUT_WIDTH}
                                        onMouseEnter={onInputMouseEnter}
                                        onMouseLeave={onInputMouseLeave}
                                        onFocus={onInputFocus}
                                        onBlur={onInputBlur}
                                    />
                                    <NameInput
                                        type="text"
                                        ref={lastNameInputRef}
                                        className={HOVER_TARGET_CLASSNAME}
                                        defaultValue={user.lastName}
                                        placeholder="Last"
                                        onKeyUp={checkForEnter}
                                        width={LAST_NAME_INPUT_WIDTH}
                                        onMouseEnter={onInputMouseEnter}
                                        onMouseLeave={onInputMouseLeave}
                                        onFocus={onInputFocus}
                                        onBlur={onInputBlur}
                                    />
                                    <NameButtonContainer
                                        width={2 * NAME_BUTTON_LENGTH + NAME_BUTTON_SPACING_WIDTH}
                                    >
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.solid}
                                            background={theme.verascopeColor.green300}
                                            height={NAME_BUTTON_LENGTH}
                                            icon={CheckmarkIcon}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            onClick={onSaveEditName}
                                        >
                                            <Tooltip
                                                text="Save"
                                                side={TOOLTIP_TYPE.bottom}
                                            />
                                        </Button>
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.solid}
                                            background={theme.verascopeColor.red300}
                                            height={NAME_BUTTON_LENGTH}
                                            icon={CrossIcon}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            onClick={onCancelEditName}
                                        >
                                            <Tooltip
                                                text="Cancel"
                                                side={TOOLTIP_TYPE.bottom}
                                            />
                                        </Button>
                                    </NameButtonContainer>
                                </NameInputContainer>
                            ) : (
                                <NameContainer>
                                    <NameText>
                                        {`${user.firstName} ${user.lastName}`}
                                    </NameText>
                                    <NameButtonContainer
                                        width={NAME_BUTTON_LENGTH}
                                    >
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.secret}
                                            background={theme.color.neutral600}
                                            height={NAME_BUTTON_LENGTH}
                                            icon={PencilIcon}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            onClick={onEditName}
                                        >
                                            <Tooltip
                                                text="Edit"
                                                side={TOOLTIP_TYPE.bottom}
                                            />
                                        </Button>
                                    </NameButtonContainer>
                                </NameContainer>
                            )}
                        {isExpanded
                        && user
                        && user.authenticationType === AUTHENTICATION_TYPE.anonymous
                        && !signedIn
                        && (
                            <AuthenticationMessage>
                                {GENERIC_SIGN_IN_BOOK_ACCESS_MESSAGE}
                            </AuthenticationMessage>
                        )}
                        <AuthMethodsContainer
                            isExpanded={isExpanded && !signedIn}
                        >
                            {authMethods.map((authMethod, index, arr) => (
                                <Transition
                                    key={authMethod.type}
                                    in={isExpanded
                                        && user.authenticationType === AUTHENTICATION_TYPE.anonymous
                                        && !showEmailAuthFields
                                        && !transitioningEmailAuthFields
                                        && !signedIn}
                                    timeout={{
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION
                                            + DIALOG_BODY_OPACITY_DELAY_DURATION,
                                        ),
                                        exit: 0,
                                    }}
                                    appear
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    {(state) => (
                                        <AuthMethod
                                            className={HOVER_TARGET_CLASSNAME}
                                            transitionDuration={AUTH_METHOD_TRANSITION_DURATION}
                                            style={{
                                                ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                    direction: 'left',
                                                    duration: AUTH_METHOD_TRANSITION_DURATION,
                                                    offset: 10,
                                                }),
                                                ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                    direction: 'left',
                                                    offset: 10,
                                                    numItems: arr.length,
                                                    index,
                                                })[state],
                                            }}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            onClick={authMethod.onClick}
                                        >
                                            <Tooltip
                                                text={authMethod.tooltipText}
                                                side={TOOLTIP_TYPE.bottom}
                                            />
                                            <ReactSVG
                                                src={authMethod.icon}
                                            />
                                        </AuthMethod>
                                    )}
                                </Transition>
                            ))}
                        </AuthMethodsContainer>
                        <EmailAuthFieldsContainer
                            isVisible={isExpanded && (showEmailAuthFields || transitioningEmailAuthFields)}
                        >
                            <Transition
                                in={isExpanded && showEmailAuthFields}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (EMAIL_AUTH_TABS_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((NUM_EMAIL_AUTH_FIELDS - Math.max(EMAIL_AUTH_TABS_INDEX - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <EmailAuthTabsContainer
                                        height={EMAIL_AUTH_TAB_HEIGHT}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: EMAIL_AUTH_FIELD_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: NUM_EMAIL_AUTH_FIELDS,
                                                index: EMAIL_AUTH_EMAIL_FIELD_INDEX,
                                            })[state],
                                        }}
                                    >
                                        <MovingButtonBackground
                                            solid
                                            visible
                                            color={EMAIL_AUTH_TAB_BACKGROUND_COLOR}
                                            top={0}
                                            buttonHeight={EMAIL_AUTH_TAB_HEIGHT}
                                            buttonWidth={EMAIL_AUTH_TAB_WIDTH}
                                            animation={animation}
                                            spacing={EMAIL_AUTH_TAB_MARGIN}
                                            xOffset={0}
                                            index={movingButtonCurrentIndex - 1}
                                            transitionDuration={MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION}
                                        />
                                        <EmailAuthTabButtonContainer
                                            index={SIGN_IN_INDEX - 1}
                                            movingButtonCurrentIndex={movingButtonCurrentIndex - 1}
                                            width={EMAIL_AUTH_TAB_WIDTH}
                                            margin={EMAIL_AUTH_TAB_MARGIN}
                                            background={EMAIL_AUTH_TAB_BACKGROUND_COLOR}
                                        >
                                            <Button
                                                center
                                                className={HOVER_TARGET_CLASSNAME}
                                                type={BUTTON_TYPE.secret}
                                                background={theme.color.neutral200}
                                                height={EMAIL_AUTH_TAB_HEIGHT}
                                                width={EMAIL_AUTH_TAB_WIDTH}
                                                text="Sign In"
                                                {...(isExpanded
                                                    ? {
                                                        onMouseEnter: onButtonMouseEnter,
                                                        onMouseLeave: onButtonMouseLeave,
                                                        onClick: (e) => onEmailAuthTabButtonClick(SIGN_IN_INDEX, e),
                                                    } : {}
                                                )}
                                            />
                                        </EmailAuthTabButtonContainer>
                                        <EmailAuthTabButtonContainer
                                            index={SIGN_UP_INDEX - 1}
                                            movingButtonCurrentIndex={movingButtonCurrentIndex - 1}
                                            width={EMAIL_AUTH_TAB_WIDTH}
                                            margin={EMAIL_AUTH_TAB_MARGIN}
                                            background={EMAIL_AUTH_TAB_BACKGROUND_COLOR}
                                        >
                                            <Button
                                                center
                                                className={HOVER_TARGET_CLASSNAME}
                                                type={BUTTON_TYPE.secret}
                                                background={theme.color.neutral200}
                                                height={EMAIL_AUTH_TAB_HEIGHT}
                                                width={EMAIL_AUTH_TAB_WIDTH}
                                                text="Sign Up"
                                                {...(isExpanded
                                                    ? {
                                                        onMouseEnter: onButtonMouseEnter,
                                                        onMouseLeave: onButtonMouseLeave,
                                                        onClick: (e) => onEmailAuthTabButtonClick(SIGN_UP_INDEX, e),
                                                    } : {}
                                                )}
                                            />
                                        </EmailAuthTabButtonContainer>
                                    </EmailAuthTabsContainer>
                                )}
                            </Transition>
                            <Transition
                                in={isExpanded && !!user && showEmailAuthFields}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (EMAIL_AUTH_EMAIL_FIELD_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((NUM_EMAIL_AUTH_FIELDS - Math.max(EMAIL_AUTH_EMAIL_FIELD_INDEX - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <EmailAuthInputContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: EMAIL_AUTH_FIELD_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: NUM_EMAIL_AUTH_FIELDS,
                                                index: EMAIL_AUTH_EMAIL_FIELD_INDEX,
                                            })[state],
                                        }}
                                    >
                                        {!emailValid
                                        && isSigningUp
                                        && (
                                            <Tooltip
                                                permanent
                                                text="Please enter a valid email address"
                                                side={TOOLTIP_TYPE.top}
                                                background={theme.verascopeColor.red100}
                                            />
                                        )}
                                        <EmailAuthInput
                                            type="text"
                                            ref={emailAuthInputRef}
                                            className={HOVER_TARGET_CLASSNAME}
                                            defaultValue={user!.email} // shouldn't be visible if user undefined
                                            placeholder="Email"
                                            onChange={handleEmailChange}
                                            onKeyUp={checkForEnter}
                                            isInvalid={!emailValid && isSigningUp}
                                            width={EMAIL_AUTH_INPUT_WIDTH}
                                            onMouseEnter={onInputMouseEnter}
                                            onMouseLeave={onInputMouseLeave}
                                            onFocus={onInputFocus}
                                            onBlur={onInputBlur}
                                        />
                                    </EmailAuthInputContainer>
                                )}
                            </Transition>
                            <Transition
                                in={isExpanded && showEmailAuthFields && !hasForgotPassword}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (EMAIL_AUTH_PASSWORD_FIELD_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((NUM_EMAIL_AUTH_FIELDS - Math.max(EMAIL_AUTH_PASSWORD_FIELD_INDEX - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <EmailAuthInputContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: EMAIL_AUTH_FIELD_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: NUM_EMAIL_AUTH_FIELDS,
                                                index: EMAIL_AUTH_PASSWORD_FIELD_INDEX,
                                            })[state],
                                        }}
                                    >
                                        {passwordErrors.length > 0
                                        && isSigningUp
                                        && (
                                            <Tooltip
                                                permanent
                                                text={passwordErrors[0].message}
                                                side={TOOLTIP_TYPE.top}
                                                background={theme.verascopeColor.red100}
                                            />
                                        )}
                                        <EmailAuthInput
                                            type="password"
                                            ref={passwordAuthInputRef}
                                            className={HOVER_TARGET_CLASSNAME}
                                            placeholder="Password"
                                            onChange={handlePasswordChange}
                                            onKeyUp={checkForEnter}
                                            isInvalid={!passwordValid && isSigningUp}
                                            width={EMAIL_AUTH_INPUT_WIDTH}
                                            onMouseEnter={onInputMouseEnter}
                                            onMouseLeave={onInputMouseLeave}
                                            onFocus={onInputFocus}
                                            onBlur={onInputBlur}
                                        />
                                        <Transition
                                            in={!!passwordStrength && isSigningUp}
                                            timeout={{
                                                enter: PASSWORD_STRENGTH_CONTAINER_TRANSITION_DURATION,
                                                exit: PASSWORD_STRENGTH_CONTAINER_TRANSITION_DURATION,
                                            }}
                                            appear
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            {(transitionState) => (
                                                <PasswordStrengthContainer
                                                    style={{
                                                        ...FADE_IN_DEFAULT_STYLE({
                                                            direction: 'down',
                                                            offset: 10,
                                                            duration: PASSWORD_STRENGTH_CONTAINER_TRANSITION_DURATION,
                                                            easing: theme.motion.eagerEasing,
                                                        }),
                                                        ...FADE_IN_TRANSITION_STYLES({
                                                            direction: 'down',
                                                            offset: 10,
                                                        })[transitionState],
                                                    }}
                                                >
                                                    <PasswordStrengthBar
                                                        hasErrors={passwordErrors.length > 0}
                                                        active={
                                                            passwordStrength === PASSWORD_STRENGTH.veryWeak
                                                            || passwordStrength === PASSWORD_STRENGTH.weak
                                                            || passwordStrength === PASSWORD_STRENGTH.reasonable
                                                            || passwordStrength === PASSWORD_STRENGTH.strong
                                                            || passwordStrength === PASSWORD_STRENGTH.veryStrong
                                                        }
                                                    />
                                                    <PasswordStrengthBar
                                                        hasErrors={passwordErrors.length > 0}
                                                        active={
                                                            passwordStrength === PASSWORD_STRENGTH.weak
                                                            || passwordStrength === PASSWORD_STRENGTH.reasonable
                                                            || passwordStrength === PASSWORD_STRENGTH.strong
                                                            || passwordStrength === PASSWORD_STRENGTH.veryStrong
                                                        }
                                                    />
                                                    <PasswordStrengthBar
                                                        hasErrors={passwordErrors.length > 0}
                                                        active={
                                                            passwordStrength === PASSWORD_STRENGTH.reasonable
                                                            || passwordStrength === PASSWORD_STRENGTH.strong
                                                            || passwordStrength === PASSWORD_STRENGTH.veryStrong
                                                        }
                                                    />
                                                    <PasswordStrengthBar
                                                        hasErrors={passwordErrors.length > 0}
                                                        active={
                                                            passwordStrength === PASSWORD_STRENGTH.strong
                                                            || passwordStrength === PASSWORD_STRENGTH.veryStrong
                                                        }
                                                    />
                                                    <PasswordStrengthBar
                                                        hasErrors={passwordErrors.length > 0}
                                                        active={passwordStrength === PASSWORD_STRENGTH.veryStrong}
                                                    />
                                                </PasswordStrengthContainer>
                                            )}
                                        </Transition>
                                    </EmailAuthInputContainer>
                                )}
                            </Transition>
                            <Transition
                                in={isExpanded && showEmailAuthFields}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (EMAIL_AUTH_BACK_BUTTON_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((NUM_EMAIL_AUTH_FIELDS - Math.max(EMAIL_AUTH_BACK_BUTTON_INDEX - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <EmailAuthBackButtonContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'left',
                                                duration: EMAIL_AUTH_FIELD_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'left',
                                                offset: 10,
                                                numItems: NUM_EMAIL_AUTH_FIELDS,
                                                index: EMAIL_AUTH_EMAIL_FIELD_INDEX,
                                            })[state],
                                        }}
                                    >
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.solid}
                                            background={theme.color.neutral200}
                                            height={EMAIL_AUTH_BACK_BUTTON_HEIGHT}
                                            icon={ArrowIcon}
                                            {...(isExpanded
                                                ? {
                                                    onMouseEnter: onButtonMouseEnter,
                                                    onMouseLeave: onButtonMouseLeave,
                                                    onClick: onEmailAuthBackButtonClick,
                                                } : {}
                                            )}
                                            style={{
                                                transform: 'rotate(180deg)',
                                            }}
                                        />
                                    </EmailAuthBackButtonContainer>
                                )}
                            </Transition>
                            <Transition
                                in={
                                    isExpanded
                                    && showEmailAuthFields
                                    && !hasForgotPassword
                                    && !isSigningUp
                                }
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (EMAIL_AUTH_FORGOT_PASSWORD_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((NUM_EMAIL_AUTH_FIELDS - Math.max(EMAIL_AUTH_FORGOT_PASSWORD_INDEX - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <ForgotPasswordText
                                        className={HOVER_TARGET_CLASSNAME}
                                        transitionDuration={FORGOT_PASSWORD_TRANSITION_DURATION}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: EMAIL_AUTH_FIELD_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: NUM_EMAIL_AUTH_FIELDS,
                                                index: EMAIL_AUTH_EMAIL_FIELD_INDEX,
                                            })[state],
                                        }}
                                        onMouseEnter={onButtonMouseEnter}
                                        onMouseLeave={onButtonMouseLeave}
                                        onClick={onForgotPassword}
                                    >
                                        Forgot Password
                                    </ForgotPasswordText>
                                )}
                            </Transition>
                            <Transition
                                in={isExpanded
                                    && showEmailAuthFields
                                    && (
                                        showEmailAuthSubmitButton
                                        || (hasForgotPassword && emailAuth.length > 0)
                                    )}
                                timeout={{
                                    enter: EMAIL_AUTH_SUBMIT_BUTTON_TRANSITION_DURATION,
                                    exit: EMAIL_AUTH_SUBMIT_BUTTON_TRANSITION_DURATION,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <SubmitEmailAuthButtonContainer
                                        width={EMAIL_AUTH_INPUT_WIDTH}
                                        style={{
                                            ...FADE_IN_DEFAULT_STYLE({
                                                direction: 'down',
                                                offset: 10,
                                                duration: EMAIL_AUTH_SUBMIT_BUTTON_TRANSITION_DURATION,
                                                easing: theme.motion.eagerEasing,
                                            }),
                                            ...FADE_IN_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                            })[state],
                                        }}
                                    >
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.solid}
                                            background={theme.verascopeColor.purple200}
                                            height={EMAIL_AUTH_SUBMIT_BUTTON_HEIGHT}
                                            text={hasForgotPassword
                                                ? 'Reset'
                                                : `${isSigningUp ? 'Sign Up' : 'Sign In'}`}
                                            {...(isExpanded
                                                ? {
                                                    onMouseEnter: onButtonMouseEnter,
                                                    onMouseLeave: onButtonMouseLeave,
                                                    onClick: onSubmitEmailAuth,
                                                } : {}
                                            )}
                                        />
                                    </SubmitEmailAuthButtonContainer>
                                )}
                            </Transition>
                        </EmailAuthFieldsContainer>
                        <AuthUserDetails
                            isVisible={!!signedIn}
                            paddingDuration={AUTH_USER_DETAILS_ENTER_DURATION}
                        >
                            <Transition
                                in={!!signedIn}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (AUTH_USER_DETAILS_AUTH_TYPE_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + DIALOG_BODY_OPACITY_DELAY_DURATION,
                                    ),
                                    exit: 0,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <AuthUserContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: AUTH_USER_DETAILS_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: numAuthUserDetails,
                                                index: AUTH_USER_DETAILS_AUTH_TYPE_INDEX,
                                            })[state],
                                        }}
                                    >
                                        <AuthUserIcon>
                                            <ReactSVG
                                                src={AuthTypeIcon}
                                            />
                                        </AuthUserIcon>
                                        <AuthUserText>
                                            {authTypeText}
                                        </AuthUserText>
                                    </AuthUserContainer>
                                )}
                            </Transition>
                            <Transition
                                in={!!signedIn && !!user}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (AUTH_USER_DETAILS_MEMBERSHIP_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + DIALOG_BODY_OPACITY_DELAY_DURATION,
                                    ),
                                    exit: 0,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <AuthUserContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: AUTH_USER_DETAILS_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: numAuthUserDetails,
                                                index: AUTH_USER_DETAILS_MEMBERSHIP_INDEX,
                                            })[state],
                                        }}
                                    >
                                        <AuthUserIcon>
                                            <ReactSVG
                                                src={HistoryIcon}
                                            />
                                        </AuthUserIcon>
                                        <AuthUserText>
                                            {/* shouldn't be visible if user undefined */}
                                            {`Since ${moment.utc(user.joined).format('MMMM YYYY')}`}
                                        </AuthUserText>
                                    </AuthUserContainer>
                                )}
                            </Transition>
                            <Transition
                                in={!!signedIn && !!user && user.annotations.length > 0}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (AUTH_USER_DETAILS_ANNOTATIONS_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + DIALOG_BODY_OPACITY_DELAY_DURATION,
                                    ),
                                    exit: 0,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <AuthUserContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: AUTH_USER_DETAILS_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: numAuthUserDetails,
                                                index: AUTH_USER_DETAILS_ANNOTATIONS_INDEX,
                                            })[state],
                                        }}
                                    >
                                        <AuthUserIcon>
                                            <ReactSVG
                                                src={HighlightIcon}
                                            />
                                        </AuthUserIcon>
                                        <AuthUserText>
                                            {/* shouldn't be visible if user undefined */}
                                            {`${user!.annotations.length} annotation${user!.annotations.length > 1 ? 's' : ''}`}
                                        </AuthUserText>
                                    </AuthUserContainer>
                                )}
                            </Transition>
                            <Transition
                                in={!!signedIn}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (AUTH_USER_DETAILS_SIGN_OUT_BUTTON_INDEX * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                    exit: 0,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <AuthUserSignOutButtonContainer
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'down',
                                                duration: AUTH_USER_DETAILS_TRANSITION_DURATION,
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                                numItems: numAuthUserDetails,
                                                index: AUTH_USER_DETAILS_SIGN_OUT_BUTTON_INDEX,
                                            })[state],
                                        }}
                                    >
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.solid}
                                            background={theme.verascopeColor.purple200}
                                            height={USER_AUTH_SUBMIT_BUTTON_HEIGHT}
                                            text="Sign Out"
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            onClick={onSignOut}
                                        />
                                    </AuthUserSignOutButtonContainer>
                                )}
                            </Transition>
                        </AuthUserDetails>
                        {showSignUpTooltip && (
                            <Tooltip
                                permanent={showSignUpTooltip}
                                text={SIGN_IN_ANNOTATION_EDIT_ACCESS_MESSAGE}
                                side={TOOLTIP_TYPE.bottom}
                            />
                        )}
                        {showGetWebBookTooltip && (
                            <Tooltip
                                permanent={showGetWebBookTooltip}
                                text={SIGN_IN_GET_WEB_BOOK_ACCESS_MESSAGE}
                                side={TOOLTIP_TYPE.bottom}
                            />
                        )}
                        {showGetDigitalBookTooltip && (
                            <Tooltip
                                permanent={showGetDigitalBookTooltip}
                                text={SIGN_IN_GET_DIGITAL_BOOK_ACCESS_MESSAGE}
                                side={TOOLTIP_TYPE.bottom}
                            />
                        )}
                        {showGetPhysicalBookTooltip && (
                            <Tooltip
                                permanent={showGetPhysicalBookTooltip}
                                text={SIGN_IN_GET_PHYSICAL_BOOK_ACCESS_MESSAGE}
                                side={TOOLTIP_TYPE.bottom}
                            />
                        )}
                    </DialogBody>
                )}
            </DialogBodyContainer>
            <CloseButtonContainer
                avatarLength={USER_PROFILE_AVATAR_LENGTH}
                closeButtonLength={USER_PROFILE_CLOSE_BUTTON_LENGTH}
                isExpanded={isExpanded}
                contractedTop={CLOSE_BUTTON_CONTAINER_OFFSET_TOP_CONTRACTED}
                contractedRight={CLOSE_BUTTON_CONTAINER_OFFSET_RIGHT_CONTRACTED}
                expandedTop={CLOSE_BUTTON_CONTAINER_OFFSET_TOP_EXPANDED}
                expandedRight={CLOSE_BUTTON_CONTAINER_OFFSET_RIGHT_EXPANDED}
                transitionDuration={DIALOG_BODY_CONTAINER_TRANSITION_DURATION}
            >
                <Button
                    className={HOVER_TARGET_CLASSNAME}
                    type={BUTTON_TYPE.secret}
                    background={theme.color.neutral600}
                    height={USER_PROFILE_AVATAR_LENGTH}
                    icon={CrossIcon}
                    {...(isExpanded
                        ? {
                            onMouseEnter: onButtonMouseEnter,
                            onMouseLeave: onButtonMouseLeave,
                            onClick: onToggleExpand,
                        } : {}
                    )}
                />
            </CloseButtonContainer>
        </Container>
    );
}

export default UserProfileDialog;
