// ===== Packages =====

import React                        from 'react';
import styled                       from 'styled-components';
import { ReactSVG }                 from 'react-svg';

// ===== Components =====

import Button                       from './Button';

// ===== Enums =====

import {
    BUTTON_TYPE,
}                                   from '../../../enums';

// ===== Interfaces =====

import {
    IModalMessage,
}                                   from '../../../interfaces';

// ===== Constants =====

import FONT_TYPE                    from '../../../constants/fontType';

GenericModal.defaultProps = {
    callback: undefined,
    handleClose: undefined,
};
interface Props {
    color: string,
    message: IModalMessage,
    callback?: (e: React.MouseEvent) => void,
    handleClose?: (e: React.MouseEvent) => void,
    hideCancel: boolean,
}
function GenericModal({
    color,
    message,
    callback,
    handleClose,
    hideCancel = false,
}: Props): JSX.Element {
    return (
        <Container>
            {((
                message.icon
            && message.icon.includes('.svg')
            ) || message.title)
        && (
            <ModalHeader>
                {message.icon
                && message.icon.includes('.svg')
                && (
                    <ModalIcon>
                        <ReactSVG
                            src={message.icon}
                        />
                    </ModalIcon>
                )}
                {message.title
                && (
                    <ModalTitle>
                        {message.title}
                    </ModalTitle>
                )}
            </ModalHeader>
        )}
            <ModalText>
                {message.message}
            </ModalText>
            <ButtonRow>
                {!hideCancel
            && (
                <ButtonContainer>
                    <Button
                        type={BUTTON_TYPE.line}
                        text="Cancel"
                        onClick={handleClose}
                    />
                </ButtonContainer>
            )}
                {callback
            && (
                <ButtonContainer>
                    <Button
                        type={BUTTON_TYPE.solid}
                        background={color}
                        text="Accept"
                        onClick={callback}
                    />
                </ButtonContainer>
            )}
            </ButtonRow>
        </Container>
    );
}

// ===== Styled Components =====

const Container = styled.div`
    margin: 50px 20px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 1em;
    text-align: center;
    color: ${({ theme }) => theme.color.neutral800};
    line-height: 1.8em;

    strong {
        display: block;
        margin: 10px;
    }
`;

const ButtonRow = styled.div`
    width: 100%;
`;

const ButtonContainer = styled.div`
    display: inline-block;
    margin: 40px 10px 0;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ModalTitle = styled.h2`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 2.2em;
    font-weight: 700;
    color: ${({ theme }) => theme.color.neutral900};
    margin: 0px;
    margin-left: 10px;
`;

const ModalIcon = styled.div`
    width: 45px;
    height: 45px;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral400};
    }
`;

const ModalText = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.1em;
    font-weight: 300;
    color: ${({ theme }) => theme.color.neutral700};
    line-height: 1.5em;
    max-width: 60ch;
`;

export default GenericModal;
