// ===== Enums =====

import {
    AUTHENTICATION_ERROR_CODE,
}                               from '../enums';

const getAuthErrorMessage = (authError: AUTHENTICATION_ERROR_CODE): string => {
    let message: string;
    switch (authError) {
    case AUTHENTICATION_ERROR_CODE.claimsTooLarge:
        message = 'The claims payload provided exceeds the maximum allowed size of 1000 bytes.';
        break;
    case AUTHENTICATION_ERROR_CODE.emailAlreadyExists:
        message = 'The provided email is already in use by an existing user.';
        break;
    case AUTHENTICATION_ERROR_CODE.emailAlreadyInUse:
        message = 'The provided email is already in use by an existing user.';
        break;
    case AUTHENTICATION_ERROR_CODE.tokenExpired:
        message = 'The provided session token is expired.';
        break;
    case AUTHENTICATION_ERROR_CODE.tokenRevoked:
        message = 'The session token has been revoked.';
        break;
    case AUTHENTICATION_ERROR_CODE.insufficientPermission:
        message = 'The credential has insufficient permission to access the requested Authentication resource.';
        break;
    case AUTHENTICATION_ERROR_CODE.serverError:
        message = 'The Authentication server encountered an unexpected error while trying to process the request.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidArgument:
        message = 'An invalid argument was provided to an Authentication method.';
        break;
    case AUTHENTICATION_ERROR_CODE.invlaidClaims:
        message = 'The custom claim attributes provided are invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidContinueURL:
        message = 'The continue URL must be a valid URL string.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidCreationTime:
        message = 'The creation time must be a valid date.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidCredential:
        message = 'The credential used to authenticate cannot be used to perform the desired action.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidDisabledField:
        message = 'The provided value for the disabled user property is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidDisplayName:
        message = 'The provided value for the displayName user property is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invlaidDynamicLinkDomain:
        message = 'The provided dynamic link domain is not configured or authorized.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidEmail:
        message = 'The provided value for the email user property is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidEmailVerified:
        message = 'The provided value for the emailVerified user property is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashAlgorithm:
        message = 'The hash algorithm must match one of the strings in the list of supported algorithms.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashBlockSize:
        message = 'The hash block size must be a valid number.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashDerivedKeyLength:
        message = 'The hash derived key length must be a valid number.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashKey:
        message = 'The hash key must a valid byte buffer.';
        break;
    case AUTHENTICATION_ERROR_CODE.ivalidHashMemoryCost:
        message = 'The hash memory cost must be a valid number.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashParallelization:
        message = 'The hash parallelization must be a valid number.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashRounds:
        message = 'The hash rounds must be a valid number.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidHashSaltSeparator:
        message = 'The hashing algorithm salt separator field must be a valid byte buffer.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidIdToken:
        message = 'The provided token is not valid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidLastSignInTime:
        message = 'The last sign-in time must be a valid date.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidPageToken:
        message = 'The provided next page token is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidPassword:
        message = 'The provided value for the password is invalid. It must be a string with at least six characters.';
        break;
    case AUTHENTICATION_ERROR_CODE.wrongPassword:
        message = 'The provided value for the password is incorrect.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidPasswordHash:
        message = 'The password hash must be a valid byte buffer.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidPasswordSalt:
        message = 'The password salt must be a valid byte buffer';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidPhoneNumber:
        message = 'The provided value for the phoneNumber is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidPhotoURL:
        message = 'The provided value for the photoURL user property is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidProviderData:
        message = 'The providerData must be a valid array of UserInfo objects.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidProviderId:
        message = 'The providerId must be a valid supported provider identifier string.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidOauthResponseType:
        message = 'Only exactly one OAuth responseType should be true.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidSessionCookieDuration:
        message = 'The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.';
        break;
    case AUTHENTICATION_ERROR_CODE.invalidUID:
        message = 'The provided uid must be a non-empty string with at most 128 characters.';
        break;
    case AUTHENTICATION_ERROR_CODE.invaliduserImport:
        message = 'The user record to import is invalid.';
        break;
    case AUTHENTICATION_ERROR_CODE.maxUserCountExceeded:
        message = 'The maximum allowed number of users to import has been exceeded.';
        break;
    case AUTHENTICATION_ERROR_CODE.missingAndroidPackageName:
        message = 'An Android Package Name must be provided if the Android App is required to be installed.';
        break;
    case AUTHENTICATION_ERROR_CODE.missingContinueURL:
        message = 'A valid continue URL must be provided in the request.';
        break;
    case AUTHENTICATION_ERROR_CODE.missinghasAlgorithm:
        message = 'Importing users with password hashes requires that the hashing algorithm and its parameters be provided.';
        break;
    case AUTHENTICATION_ERROR_CODE.missingiOSBundleId:
        message = 'The request is missing a Bundle ID.';
        break;
    case AUTHENTICATION_ERROR_CODE.missingUID:
        message = 'A uid identifier is required for the current operation.';
        break;
    case AUTHENTICATION_ERROR_CODE.missingOauthClientSecret:
        message = 'The OAuth configuration client secret is required to enable OIDC code flow.';
        break;
    case AUTHENTICATION_ERROR_CODE.operationotAllowed:
        message = 'The provided sign-in provider is disabled for your Firebase project.';
        break;
    case AUTHENTICATION_ERROR_CODE.numberAlreadyExists:
        message = 'The provided phone number is already in use by an existing user.';
        break;
    case AUTHENTICATION_ERROR_CODE.projectNotFound:
        message = 'No project was found for the credential used to initialize the development kit.';
        break;
    case AUTHENTICATION_ERROR_CODE.reservedClaims:
        message = 'One or more custom user claims provided are reserved.';
        break;
    case AUTHENTICATION_ERROR_CODE.sessionCookieExpired:
        message = 'The provided session cookie is expired.';
        break;
    case AUTHENTICATION_ERROR_CODE.sessionCookieRevoked:
        message = 'The session cookie has been revoked.';
        break;
    case AUTHENTICATION_ERROR_CODE.uidAlreadyExists:
        message = 'The provided uid is already in use by an existing user.';
        break;
    case AUTHENTICATION_ERROR_CODE.unauthorizedContinueURL:
        message = 'The domain of the continue URL is not whitelisted. Whitelist the domain.';
        break;
    case AUTHENTICATION_ERROR_CODE.userNotFound:
        message = 'There is no existing user record corresponding to the provided identifier.';
        break;
    case AUTHENTICATION_ERROR_CODE.authenticationRequestDenied:
        message = 'Authentication request was denied.';
        break;
    case AUTHENTICATION_ERROR_CODE.unauthorizedDomain:
        message = 'Website domain not authorized to handle authentication.';
        break;
    case AUTHENTICATION_ERROR_CODE.popupBlocked:
        message = 'Authentication popup blocked by browser.';
        break;
    default:
        message = 'Unrecognized error occurred.';
        break;
    }

    return message;
};

export default getAuthErrorMessage;
