// ===== Packages =====

import {
    Node,
    Element,
}                                       from 'slate';

// ===== Elements =====

import FigureContentElement             from './figureContentElement';
import CaptionElement                   from './captionElement';

// ===== Services =====

import {
    normalizeURL,
    validateURL,
}                                       from '../../../../services';

// ===== Interfaces =====

import {
    EditorNode,
    FigureOptions,
    FigureNode,
}                                       from '../../interfaces';
import ListableInterface                from '../../interfaces/listableInterface';

// ===== Enums =====

import {
    FIGURE_ORIENTATION,
}                                       from './enums';

const BLOCK_FIGURE_TYPE = 'figure';

const isFigureElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_FIGURE_TYPE;

const newFigureElement = (options: FigureOptions): FigureNode => {
    const {
        id,
        url,
        filePath,
        orientation,
        isGalleryChild,
        text,
    } = options;
    const figureContentElement = FigureContentElement.newFigureContentElement({
        id,
        url,
        filePath,
        orientation,
        isGalleryChild,
    });
    const { figureContentType } = figureContentElement;
    return {
        id,
        type: BLOCK_FIGURE_TYPE,
        isListable: true,
        orientation: orientation || FIGURE_ORIENTATION.center,
        figureContentType,
        children: [
            figureContentElement,
            CaptionElement.newCaptionElement({
                id,
                orientation,
                text,
                figureContentType,
                isGalleryChild,
            }),
        ],
        ...(isGalleryChild ? { isGalleryChild: true } : {}),
    };
};

const urlIsValid = (url: string): boolean => validateURL(url);

const orientationIsValid = (orientation: FIGURE_ORIENTATION): boolean => Object.values(FIGURE_ORIENTATION).includes(orientation);

export const DELETE_NODE_MARK = 'delete_node_mark';

export default {
    TYPE: BLOCK_FIGURE_TYPE,
    isFigureElement,
    newFigureElement,
    urlIsValid,
    ...ListableInterface,
    orientationIsValid,
    normalizeURL,
};
