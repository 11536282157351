// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
    useMemo,
}                                               from 'react';
import styled                                   from 'styled-components';
import { Transition }                           from 'react-transition-group';
import { ReactSVG }                             from 'react-svg';

// ===== Services =====

import {
    hexToRgb,
    recordUserAction,
    setColorLightness,
    detectTouchDevice,
}                                               from '../../../services';

// ===== Hooks =====

import { useTimeout }                           from '../../../hooks';

// ===== Interfaces =====

import {
    IResolutionLevelItem,
    IUserItem,
}                                               from '../../../interfaces';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    USER_ACTION_TYPE,
}                                               from '../../../enums';

// ===== Images =====

import MagnifyingGlass                          from '../../../images/editor/magnifying-glass.svg';

// ===== Constants =====

import CURSOR_SIGN                              from '../../../constants/cursorSigns';
import {
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    HOVER_TARGET_CLASSNAME,
    SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
    ERROR_BACKGROUND_LIGHTNESS_VALUE,
}                                               from '../../../constants/generalConstants';
import {
    HOVER_CARD_TRANSITION_DURATION,
}                                               from '../helpers/constants';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';
import { theme as themeObj }                    from '../../../themes/theme-context';

const TRANSITION_LINK_CARD_DELAY = 200;

ResolutionLevel.defaultProps = {
    readOnly: false,
    hasSuccess: false,
    hasError: false,
};
interface Props {
    level: IResolutionLevelItem,
    currentResolutionLevel: IResolutionLevelItem | null,
    isInline: boolean,
    color: string,
    readOnly?: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    parentRef: HTMLElement | null,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    hasSuccess?: boolean,
    hasError?: boolean,
    unusedResolutionLevelCount: number,
    children: React.ReactElement,
    attributes: any,
}
function ResolutionLevel({
    level,
    currentResolutionLevel,
    isInline,
    color,
    children,
    readOnly = false,
    user,
    currentSessionId,
    onCursorEnter,
    onCursorLeave,
    hasSuccess,
    hasError,
    unusedResolutionLevelCount,
    parentRef,
    attributes,
}: Props): JSX.Element {
    // ===== Refs =====

    // We use this ref to access ResolutionLevel ref because
    // there is an issue assigning ref directly in Figure
    // Figure requires a RefNode component for some reason
    // And if a Figure is inside a Resolution Level, we need to have this
    //
    // This likely has to do with Slate attributes
    const childRef = useRef<HTMLSpanElement | null>(null);
    const cardRef = useRef<HTMLDivElement | null>(null);

    // ===== State =====

    const [levelExpanded, setLevelExpanded] = useState<boolean>(false);
    const [showCard, setShowCard] = useState<boolean>(false);
    const [cardAbove, setCardAbove] = useState<boolean>(true);

    // ===== Methods =====

    const onClick = async (e: React.MouseEvent): Promise<void> => {
        e.stopPropagation();
        const expanded = levelExpanded;
        setLevelExpanded(!expanded);

        // Switch cursor icon
        onCursorEnter(
            CURSOR_TARGET.editorButton,
            [!expanded ? CURSOR_SIGN.contractResolution : CURSOR_SIGN.expandResolution],
            e.target as HTMLElement,
        );

        if (
            readOnly
            && user
            && currentSessionId
        ) {
            // Record user action
            await recordUserAction({
                type: USER_ACTION_TYPE.toggleResolutionLevelContent,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    expanded: !expanded,
                    resolution: level.level,
                    currentResolution: currentResolutionLevel,
                },
            });
        }
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.editorButton,
            [levelExpanded ? CURSOR_SIGN.contractResolution : CURSOR_SIGN.expandResolution],
            e.target as HTMLElement,
        );
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    // ===== Side Effects =====

    // Determine position of link card
    useEffect(() => {
        if (
            childRef.current
            && cardRef.current
            && parentRef
            && !detectTouchDevice(document)
        ) {
            const containerRect = childRef.current.parentElement!.getBoundingClientRect();
            const parentRect = parentRef.getBoundingClientRect();
            const cardRect = cardRef.current.getBoundingClientRect();

            // Determine vertical position
            // if link card should go above or below link text
            if (
                (containerRect.top - parentRect.top) > cardRect.height
                && !cardAbove
            ) {
                setCardAbove(true);
            } else if (
                (containerRect.top - parentRect.top) < cardRect.height
                && cardAbove
            ) {
                setCardAbove(false);
            }
        }
    }, [
        childRef.current,
        cardRef.current,
        parentRef,
    ]);

    const {
        start: delayHideLinkCard,
        clear: clearDelayHideLinkCard,
    } = useTimeout(() => {
        setShowCard(false);
    }, TRANSITION_LINK_CARD_DELAY);

    // ===== Rendering =====

    const ResolutionLevelContent = useMemo(() => (
        <>
            {currentResolutionLevel
            && level.level <= currentResolutionLevel.level
            && (children)}
            {currentResolutionLevel
            && level.level > currentResolutionLevel.level
            && readOnly // We only show level expanders in read-mode
            && isInline
            && (
                <InlineResolutionContainer
                    className={HOVER_TARGET_CLASSNAME}
                    onMouseEnter={onButtonMouseEnter}
                    onMouseLeave={onButtonMouseLeave}
                >
                    {'[ '}
                    <InlineResolutionIcon>
                        <ReactSVG
                            src={MagnifyingGlass}
                        />
                        <InlineResolutionExpandIndicatorText
                            levelExpanded={levelExpanded}
                        >
                            {levelExpanded ? '-' : '+'}
                        </InlineResolutionExpandIndicatorText>
                    </InlineResolutionIcon>
                    {!levelExpanded
                    && level.icon
                    && (
                        <InlineResolutionLevelIcon
                            src={level.icon}
                        />
                    )}
                    {!levelExpanded
                    && (
                        <InlineResolutionLevelText
                            withIcon={!!level.icon}
                        >
                            {level.level - unusedResolutionLevelCount}
                        </InlineResolutionLevelText>
                    )}
                    {level.level > currentResolutionLevel.level
                    && isInline
                    && levelExpanded
                    && readOnly
                    && (
                        <InlineResolutionChildrenContainer>
                            {children}
                        </InlineResolutionChildrenContainer>
                    )}
                    ]
                </InlineResolutionContainer>
            )}
            {currentResolutionLevel
            && level.level > currentResolutionLevel.level
            && !isInline
            && readOnly
            && (
                <BlockResolutionContainer
                    className={HOVER_TARGET_CLASSNAME}
                    onMouseEnter={onButtonMouseEnter}
                    onMouseLeave={onButtonMouseLeave}
                >
                    <BlockResolutionIndicator
                        className={HOVER_TARGET_CLASSNAME}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                    >
                        <BlockResolutionIcon>
                            <ReactSVG
                                src={MagnifyingGlass}
                            />
                            <BlockResolutionExpandIndicatorText
                                levelExpanded={levelExpanded}
                            >
                                {levelExpanded ? '-' : '+'}
                            </BlockResolutionExpandIndicatorText>
                        </BlockResolutionIcon>
                        {level.icon
                        && (
                            <BlockResolutionLevelIcon
                                src={level.icon}
                            />
                        )}
                        <BlockResolutionLevelText
                            withIcon={!!level.icon}
                        >
                            {level.level - unusedResolutionLevelCount}
                        </BlockResolutionLevelText>
                    </BlockResolutionIndicator>
                </BlockResolutionContainer>
            )}
            {currentResolutionLevel
            && level.level > currentResolutionLevel.level
            && !isInline
            && levelExpanded
            && readOnly
            && (
                <BlockResolutionChildrenContainer>
                    {children}
                </BlockResolutionChildrenContainer>
            )}
            <RefNode
                ref={childRef}
            />
            <Transition
                in={showCard
                    && !!currentResolutionLevel
                    && level.level > currentResolutionLevel.level
                    && !levelExpanded}
                timeout={{
                    enter: HOVER_CARD_TRANSITION_DURATION,
                    exit: HOVER_CARD_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                // This is important to prevent the card from being rendered in the DOM
                // But impacts transition smoothness
                unmountOnExit
            >
                {(state) => (
                    <HoverCard
                        ref={cardRef}
                        visible={showCard}
                        isAbove={cardAbove}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: cardAbove ? 'up' : 'down',
                                offset: 20,
                                duration: HOVER_CARD_TRANSITION_DURATION,
                                easing: themeObj.motion.standardEasing,
                                horizontalCenter: true,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: cardAbove ? 'up' : 'down',
                                offset: 20,
                                horizontalCenter: true,
                            })[state],
                        }}
                    >
                        <HoverCardResolutionIndicatorContainer>
                            <HoverCardResolutionIcon>
                                <ReactSVG
                                    src={MagnifyingGlass}
                                />
                            </HoverCardResolutionIcon>
                        </HoverCardResolutionIndicatorContainer>
                        <HoverCardResolutionChildrenContainer>
                            {children}
                        </HoverCardResolutionChildrenContainer>
                        <HoverCardGradient />
                    </HoverCard>
                )}
            </Transition>
        </>
    ), [
        level,
        currentResolutionLevel,
        children,
        isInline,
        levelExpanded,
        showCard,
        cardAbove,
        readOnly,
    ]);

    if (!isInline) {
        return (
            <BlockContainer
                color={color}
                highlighted={currentResolutionLevel
                && level.level === currentResolutionLevel.level
                && !readOnly}
                hasSuccess={hasSuccess}
                hasError={hasError}
                {...(currentResolutionLevel
                    && level.level > currentResolutionLevel.level
                    ? {
                        onMouseEnter: () => {
                            if (!detectTouchDevice(document)) {
                                clearDelayHideLinkCard();
                                setShowCard(true);
                            }
                        },
                        onMouseLeave: () => {
                            if (!detectTouchDevice(document)) {
                                clearDelayHideLinkCard();
                                delayHideLinkCard();
                            }
                        },
                        onClick,
                    } : {}
                )}
                {...attributes}
                // Necessary to avoid: Cannot resolve a Slate point from DOM point
                // Reference: https://github.com/ianstormtaylor/slate/issues/3930
                contentEditable={!readOnly}
            >
                {ResolutionLevelContent}
            </BlockContainer>
        );
    }

    return (
        <InlineContainer
            color={color}
            highlighted={currentResolutionLevel
            && level.level === currentResolutionLevel.level
            && !readOnly}
            hasSuccess={hasSuccess}
            hasError={hasError}
            {...(currentResolutionLevel
                && level.level > currentResolutionLevel.level
                ? {
                    onMouseEnter: () => {
                        if (!detectTouchDevice(document)) {
                            clearDelayHideLinkCard();
                            setShowCard(true);
                        }
                    },
                    onMouseLeave: () => {
                        if (!detectTouchDevice(document)) {
                            clearDelayHideLinkCard();
                            delayHideLinkCard();
                        }
                    },
                    onClick,
                } : {}
            )}
            {...attributes}
            // Necessary to avoid: Cannot resolve a Slate point from DOM point
            // Reference: https://github.com/ianstormtaylor/slate/issues/3930
            contentEditable={!readOnly}
        >
            {ResolutionLevelContent}
        </InlineContainer>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    highlighted: boolean,
    hasSuccess: boolean,
    hasError: boolean,
}
const BlockContainer = styled.div<ContainerProps>`
    position: relative;
    background: ${({
        highlighted,
        hasSuccess,
        hasError,
        theme,
    }) => {
        if (hasError) {
            return setColorLightness(
                theme.color.error,
                ERROR_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (hasSuccess) {
            return setColorLightness(
                theme.verascopeColor.blue200,
                SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (highlighted) {
            return `rgba(
                ${hexToRgb(theme.verascopeColor.orange200)!.r}, 
                ${hexToRgb(theme.verascopeColor.orange200)!.g}, 
                ${hexToRgb(theme.verascopeColor.orange200)!.b}, 
                0.6)`;
        }

        // Rather than be transparent, we want to inherit the background color
        // so that customer cursor adopts correct contrasting color
        return 'inherit';
    }};
    cursor: none;
`;

const InlineContainer = styled.span<ContainerProps>`
    position: relative;
    background: ${({
        highlighted,
        hasSuccess,
        hasError,
        theme,
    }) => {
        if (hasError) {
            return setColorLightness(
                theme.color.error,
                ERROR_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (hasSuccess) {
            return setColorLightness(
                theme.verascopeColor.blue200,
                SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (highlighted) {
            return `rgba(
                ${hexToRgb(theme.verascopeColor.orange200)!.r}, 
                ${hexToRgb(theme.verascopeColor.orange200)!.g}, 
                ${hexToRgb(theme.verascopeColor.orange200)!.b}, 
                0.6)`;
        }

        // Rather than be transparent, we want to inherit the background color
        // so that customer cursor adopts correct contrasting color
        return 'inherit';
    }};
    cursor: none;
`;

interface HoverCardProps {
    isAbove: boolean,
    visible: boolean,
    height?: number,
    width?: number,
}
const HoverCard = styled.div<HoverCardProps>`
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 15px;
    padding-left: 7.5px;
    width: max-content;
    max-width: 250px;
    max-height: 200px;
    background: ${({ theme }) => theme.color.offWhite};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    border-radius: 5px;
    pointer-events: ${({ visible }) => (visible
        ? 'auto'
        : 'none'
    )};
    z-index: 1;
    overflow-y: hidden;
`;

const HoverCardResolutionIndicatorContainer = styled.div`
    position: relative;
    top: 1px;
    display: flex;
    align-items: center;
    margin-right: 10px;
`;

const HoverCardResolutionChildrenContainer = styled.div`
    ${basicEditorFontStyles}
    font-size: 0.7em;
    background: inherit;
    margin-left: 15px;
    user-select: none;
`;

const HoverCardResolutionIcon = styled.span`
    position: absolute;
    top: 0.1em;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;

    & div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 19px;
    }

    & svg {
        width: 19px;
        height: 19px;
        fill: ${({ theme }) => theme.verascopeColor.purple300};
    }
`;

const HoverCardGradient = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 30%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 1));
    z-index: 1;
`;

const RefNode = styled.span``;

const InlineResolutionContainer = styled.span`
    ${basicEditorFontStyles}
    color: ${({ theme }) => theme.color.neutral600};
    font-size: 1em;
    font-weight: 600;
    background: inherit;
`;

const InlineResolutionIcon = styled.span`
    position: absolute;
    top: 0.3em;
    left: 0.45em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.9em;
    height: 0.9em;

    & div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 0.9em;
        height: 0.9em;
    }

    & svg {
        width: 0.9em;
        height: 0.9em;
        fill: ${({ theme }) => theme.verascopeColor.purple300};
    }
`;

interface InlineResolutionExpandIndicatorTextProps {
    levelExpanded: boolean,
}
const InlineResolutionExpandIndicatorText = styled.span<InlineResolutionExpandIndicatorTextProps>`
    ${basicEditorFontStyles}
    position: absolute;
    top: ${({ levelExpanded }) => `${levelExpanded ? 0.34 : 0.39}em`};
    left: ${({ levelExpanded }) => `${levelExpanded ? 0.58 : 0.34}em`};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.verascopeColor.purple300};
    font-weight: 600;
    font-size: 0.5em;
    line-height: 0.7em;
    user-select: none;
`;

const InlineResolutionLevelIcon = styled.img`
    display: inline-block;
    position: relative;
    top: 0.05em;
    left: 0.77em;
    width: 0.75em;
    height: 0.75em;
    pointer-events: none;
`;

interface InlineResolutionLevelTextProps {
    withIcon: boolean,
}
const InlineResolutionLevelText = styled.span<InlineResolutionLevelTextProps>`
    ${basicEditorFontStyles}
    color: ${({ theme }) => theme.verascopeColor.purple400};
    font-size: 0.8em;
    font-weight: 600;
    margin-left: ${({ withIcon }) => `${withIcon ? 1.1 : 1}em`};
    user-select: none;
`;

const InlineResolutionChildrenContainer = styled.span`
    ${basicEditorFontStyles}
    font-size: 1em;
    color: ${({ theme }) => theme.color.neutral800};
    margin-left: 20px;
    user-select: none;
`;

const BlockResolutionContainer = styled.div`
    position: relative;
    background: inherit;
    border-bottom: ${({ theme }) => `1.5px solid ${theme.verascopeColor.purple400}`};
    font-size: 1.4em;
`;

const BlockResolutionIndicator = styled.div`
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: inherit;
`;

const BlockResolutionIcon = styled.div`
    position: absolute;
    top: 0.25em;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.9em;
    height: 0.9em;

    & div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 0.9em;
        height: 0.9em;;
    }

    & svg {
        width: 0.9em;
        height: 0.9em;
        fill: ${({ theme }) => theme.verascopeColor.purple300};
    }
`;

interface BlockResolutionLevelTextProps {
    withIcon: boolean,
}
const BlockResolutionLevelText = styled.span<BlockResolutionLevelTextProps>`
    ${basicEditorFontStyles}
    color: ${({ theme }) => theme.verascopeColor.purple400};
    font-size: 0.8em;
    font-weight: 600;
    margin-left: ${({ withIcon }) => `${withIcon ? 1.5 : 1.4}em`};
    user-select: none;
`;

const BlockResolutionLevelIcon = styled.img`
    display: inline-block;
    position: relative;
    top: 0.05em;
    left: 1.1em;
    width: 0.75em;
    height: 0.75em;
    pointer-events: none;
`;

interface BlockResolutionExpandIndicatorTextProps {
    levelExpanded: boolean,
}
const BlockResolutionExpandIndicatorText = styled.span<BlockResolutionExpandIndicatorTextProps>`
    ${basicEditorFontStyles}
    position: absolute;
    top: ${({ levelExpanded }) => `${levelExpanded ? 0.34 : 0.39}em`};
    left: ${({ levelExpanded }) => `${levelExpanded ? 0.58 : 0.34}em`};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.verascopeColor.purple300};
    font-weight: 600;
    font-size: 0.5em;
    line-height: 0.7em;
    user-select: none;
`;

const BlockResolutionChildrenContainer = styled.div`
    ${basicEditorFontStyles}
    font-size: 1em;
    color: ${({ theme }) => theme.color.neutral800};
    padding: 10px 0px;
    background: inherit;
    border-bottom: ${({ theme }) => `1.5px solid ${theme.verascopeColor.purple400}`};
    user-select: none;
`;

export default ResolutionLevel;
