// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';

const HEADER_HEIGHT = 90;

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.verascopeColor.orange100};
`;

export const AboutTextContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    padding: 20px 30px;
    padding-top: ${`${HEADER_HEIGHT + 50}px`};
    padding-bottom: 60px;
    overflow-y: scroll;
`;

interface AboutTextProps {
    isVisible: boolean,
    delay: number,
    enterDuration: number,
    marginTop?: boolean,
}
export const AboutText = styled.p<AboutTextProps>`
    position: relative;
    top: ${({ isVisible }) => `${isVisible ? 0 : 40}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: 1.1em;
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    line-height: 1.5em;
    max-width: 40ch;
    margin: 0 auto;
    margin-top: ${({ marginTop }) => `${marginTop ? 40 : 0}px`};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: none;
    transition: ${({
        enterDuration,
        delay,
        theme,
    }) => `
        top ${enterDuration}ms ${delay}ms ${theme.motion.overshoot},
        opacity ${enterDuration}ms ${delay}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: 1em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 0.9em;
    }
`;

interface ColorTextProps {
    color: string,
}
export const ColorText = styled.span<ColorTextProps>`
    color: ${({ color }) => color};
`;
