// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import { ReactSVG }                             from 'react-svg';

// ===== Components =====

import Tooltip                                  from '../../Tooltip';

// ===== Hooks =====

import {
    useInterval,
}                                               from '../../../hooks';

// ===== Services =====

import {
    setColorLightness,
    getFileTypeIcon,
}                                               from '../../../services';

// ===== Enums =====

import {
    MEDIA_TYPE,
    TOOLTIP_TYPE,
}                                               from '../../../enums';

// ===== Interfaces =====

import {
    ICoord,
    IMediaItem,
}                                               from '../../../interfaces';

// ===== Constants =====

import {
    UPLOADING_MEDIA_SOURCE_ID,
    UPLOADING_MEDIA_DESTINATION_ID,
}                                               from '../../../constants/generalConstants';
import FONT_TYPE                                from '../../../constants/fontType';

const ICON_TRANSITION_DURATION = 700;
const MAX_SEARCH_CYCLES = 10;

UploadingMediaItem.defaultProps = {
    style: null,
    iconImageUrl: null,
};
interface Props {
    mediaItem: IMediaItem,
    style?: any,
    progress: number,
    color: string,
    compact: boolean,
    iconImageUrl?: string,
    onItemCompletion: (id: string) => void,
}
function UploadingMediaItem({
    mediaItem,
    style,
    progress,
    color,
    compact = false,
    iconImageUrl,
    onItemCompletion,
}: Props): JSX.Element {
    // ===== General Constants =====

    const IMAGE_LOAD_TIMEOUT_DURATION = 200;

    // ===== State =====

    // Used to determine how long it takes to travel from source to destination
    const [travelDuration] = useState<number>(ICON_TRANSITION_DURATION);
    const [iconOffset, setIconOffset] = useState<ICoord>({
        x: 0,
        y: 0,
    });
    const [isLocatingDestination, setIsLocatingDestination] = useState<boolean>(false);
    const [searchCycles, setSearchCycles] = useState<number>(0);
    const [isTravelingToDestination, setIsTravelingToDestination] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [imageWidth, setImageWidth] = useState<number | null>(null);
    const [isWaitingForImageToLoad, setIsWaitingForImageToLoad] = useState<boolean>(false);

    // ===== Refs =====
    const iconContainerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    // ===== Side Effects =====

    // Set Initial Icon Offset
    useEffect(() => {
        if (progress === 100) {
            if (
                iconImageUrl
                && !imageLoaded
            ) {
                setIsWaitingForImageToLoad(true);
            } else {
                setIsLocatingDestination(true);
            }
        }
    }, [progress]);

    useInterval(() => {
        // Removes upload if there are issues locating associated destination element
        if (searchCycles > MAX_SEARCH_CYCLES) {
            broadcastCompletion();
            setIsLocatingDestination(false);
        }

        // Find Sidebar Uploading Container
        let destinationElement;
        if (
            (
                mediaItem.type === MEDIA_TYPE.image
                || mediaItem.type === MEDIA_TYPE.sketch
            )
            && mediaItem.filePath
        ) {
            destinationElement = document.getElementById(UPLOADING_MEDIA_DESTINATION_ID(mediaItem.filePath));
        } else if (mediaItem.type === MEDIA_TYPE.audioNote) {
            // Broadcast item completion without upload animation
            broadcastCompletion();
        } else {
            throw Error(`Encountered an unrecognized file media type while handling UploadingMediaItem: ${mediaItem.type}`);
        }

        if (destinationElement && iconContainerRef.current) {
            // Found destination, so cancel destination locating
            setIsLocatingDestination(false);

            const destinationBoundingRect = destinationElement.getBoundingClientRect();

            // Get Button Icon Container
            const iconBoundingRect = iconContainerRef.current.getBoundingClientRect();

            // Set Icon Offset
            setIconOffset({
                x: (destinationBoundingRect.left - iconBoundingRect.left) + 10,
                y: (destinationBoundingRect.top - iconBoundingRect.top) - 10,
            });

            // Start Icon Animation
            setIsTravelingToDestination(true);
        } else {
            setSearchCycles((count) => count + 1);
        }
    }, isLocatingDestination ? 100 : null);

    // Set Interval
    // Runs twice
    // The first run sets the icon to zero
    // The second run ends the interval
    useInterval(() => {
        if (
            iconOffset
            && iconOffset.x !== 0
            && iconOffset.y !== 0
        ) {
            // Broadcast item completion
            broadcastCompletion();
        }
    }, isTravelingToDestination ? travelDuration : null);

    useInterval(() => {
        if (imageLoaded) {
            setIsWaitingForImageToLoad(false);
            setIsLocatingDestination(true);
        }
    }, isWaitingForImageToLoad ? IMAGE_LOAD_TIMEOUT_DURATION : null);

    // ===== Methods =====

    const broadcastCompletion = (): void => {
        onItemCompletion(mediaItem.id);
    };

    const onImageLoad = (): void => {
        if (
            !imageWidth
            && imageRef.current
        ) {
            const width = imageRef.current.clientWidth;
            setImageWidth(width);
        }
        if (!imageLoaded) setImageLoaded(true);
    };

    return (
        <Container
            {...(!progress && mediaItem.url
                ? {
                    id: UPLOADING_MEDIA_SOURCE_ID(mediaItem.url),
                } : {}
            )}
            key={mediaItem.id}
            progress={progress}
            compact={compact}
            isTravelingToDestination={isTravelingToDestination}
            style={style}
        >
            <ProgressBar
                color={color}
                progress={progress}
                isTravelingToDestination={isTravelingToDestination}
            >
                <IconContainer
                    ref={iconContainerRef}
                    isTravelingToDestination={isTravelingToDestination}
                    iconOffset={iconOffset}
                    compact={compact}
                    imageWidth={imageWidth}
                >
                    {iconImageUrl
                        ? (
                            <IconImageContainer>
                                <IconImage
                                    ref={imageRef}
                                    src={iconImageUrl}
                                    onLoad={onImageLoad}
                                />
                            </IconImageContainer>
                        ) : (
                            <ReactSVG
                                src={getFileTypeIcon(mediaItem)}
                            />
                        )}
                </IconContainer>
                <NameContainer
                    compact={compact}
                    isTravelingToDestination={isTravelingToDestination}
                >
                    <Name
                        compact={compact}
                    >
                        {mediaItem.file.name}
                    </Name>
                    <Tooltip
                        text={mediaItem.file.name}
                        side={TOOLTIP_TYPE.top}
                    />
                </NameContainer>
            </ProgressBar>
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    compact: boolean,
    progress: number | undefined,
    isTravelingToDestination: boolean,
}
const Container = styled.div<ContainerProps>`
    width: 100%;
    max-width: 325px;
    height: ${({ compact }) => `${compact ? 30 : 50}px`};
    box-shadow: ${({ theme, isTravelingToDestination }) => (isTravelingToDestination
        ? 'none'
        : theme.color.boxShadow100
    )};
    margin-bottom: ${({ compact }) => `${compact ? 5 : 10}px`};
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    background-color: ${({ theme, isTravelingToDestination }) => (isTravelingToDestination
        ? 'transparent'
        : theme.color.white
    )};
    cursor: ${({ progress }) => (progress
        ? 'default'
        : 'pointer'
    )};
    ${({ progress, theme }) => (progress
        ? `
            transition: background-color ${ICON_TRANSITION_DURATION}ms ${theme.motion.delayEasing},
            box-shadow ${ICON_TRANSITION_DURATION}ms ${theme.motion.delayEasing};
        `
        : `
            transition-duration: 300ms !important;
            transition-timing-function: linear !important;
            transition-property: box-shadow !important;
        `
    )};

    &:hover {
        box-shadow: ${({ theme, isTravelingToDestination }) => (isTravelingToDestination
        ? 'none'
        : theme.color.boxShadow300
    )};
    }
`;

interface ProgressBarProps {
    progress: number | undefined,
    isTravelingToDestination: boolean,
}
const ProgressBar = styled.div<ProgressBarProps>`
    height: 100%;
    width: ${({ progress, isTravelingToDestination }) => (progress && !isTravelingToDestination
        ? `${progress}%`
        : '0%'
    )};
    background-color: ${({ color, theme }) => (color
        ? setColorLightness(
            color,
            80,
        )
        : theme.color.neutral600
    )};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    ${({ progress }) => progress && progress > 95 && `
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    `};
    transition: width 50ms;
`;

interface IconContainerProps {
    compact: boolean,
    iconOffset: ICoord,
    imageWidth: number | null,
    isTravelingToDestination: boolean,
}
const IconContainer = styled.div<IconContainerProps>`
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    vertical-align: top;
    width: ${({
        compact,
        imageWidth,
    }) => {
        if (imageWidth) {
            return `${imageWidth}px`;
        }

        return `${compact ? 40 : 60}px`;
    }};
    height: 100%;
    ${({ isTravelingToDestination, iconOffset, theme }) => isTravelingToDestination && `
        top: ${iconOffset.y}px;
        left: ${iconOffset.x}px;
        box-shadow: ${theme.color.boxShadow100};
        background-color: ${theme.color.white};
        border-radius: 3px;
        transform: scale(1.6);
        z-index: 1000;
    `};
    transition: ${({ theme }) => `
        top ${ICON_TRANSITION_DURATION}ms ${theme.motion.delayEasing},
        left ${ICON_TRANSITION_DURATION}ms ${theme.motion.delayEasing},
        transform ${ICON_TRANSITION_DURATION}ms ${theme.motion.delayEasing};
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 25px;
        height: 25px;
        fill: ${({ theme }) => theme.color.neutral600};
    }
`;

interface NameContainerProps {
    compact: boolean,
    isTravelingToDestination: boolean,
}
const NameContainer = styled.div<NameContainerProps>`
    position: absolute;
    top: 50%;
    left: ${({ compact }) => `${compact ? 50 : 60}px`};
    transform: translateY(-50%);
    width: ${({ compact }) => (compact
        ? 'calc(100% - 50px)'
        : 'calc(100% - 100px)'
    )};
    display: ${({ isTravelingToDestination }) => (isTravelingToDestination ? 'none' : 'inline-block')};
    vertical-align: top;
`;

interface NameProps {
    compact: boolean,
}
const Name = styled.div<NameProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ compact }) => `${compact ? 0.75 : 1}em`};
    font-weight: 500;
    color: ${({ theme }) => theme.color.neutral800};
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IconImageContainerProps {
    small?: boolean,
    hasImage?: boolean,
}
const IconImageContainer = styled.div<IconImageContainerProps>`
    position: relative;
    width: 100%;
    height: 100%;
`;

const IconImage = styled.img`
    height: 100%;
    border-radius: 1px;
`;

export default UploadingMediaItem;
