// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeGetPromotions = async ({
    test,
    paymentIntentId,
}: {
    test: boolean,
    paymentIntentId: string,
}): Promise<Map<string, Stripe.Coupon>> => {
    const functions = getFunctions();
    const getPromotions = httpsCallable<
        Record<
            string,
            boolean | string
        >,
        {
            [k: string]: Stripe.Coupon,
        }
    >(
        functions,
        'stripeGetPromotions',
    );

    return getPromotions({
        test,
        paymentIntentId,
    }).then((result) => {
        if (result.data) {
            const promotions: Map<string, Stripe.Coupon> = new Map(Object.entries(result.data));
            return promotions;
        }
    }).catch((err) => err);
};

export default stripeGetPromotions;
