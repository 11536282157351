// ===== Interfaces =====

import {
    IRgb,
    IHsl,
} from '../interfaces';

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
const rgbToHsl = (rgb: IRgb): IHsl => {
    let newR = rgb.r;
    let newG = rgb.g;
    let newB = rgb.b;
    let h;
    let s;
    let l;
    let d;

    newR /= 255;
    newG /= 255;
    newB /= 255;
    const max = Math.max(newR, newG, newB);
    const min = Math.min(newR, newG, newB);
    l = (max + min) / 2;
    if (max === min) {
        h = 0;
        s = 0;
    } else {
        d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
        case newR:
            h = ((newG - newB) / d) + (newG < newB ? 6 : 0);
            break;
        case newG:
            h = ((newB - newR) / d) + 2;
            break;
        case newB:
            h = ((newR - newG) / d) + 4;
            break;
        default:
            // Should never reach here
            h = 1; // To make sure h always set
        }
        h /= 6;
    }
    h = Math.floor(h * 360);
    s = Math.floor(s * 100);
    l = Math.floor(l * 100);
    return {
        h,
        s,
        l,
    };
};

export default rgbToHsl;
