// ===== Packages =====

import { keyframes, Keyframes }             from 'styled-components';

// ===== Enums =====

import {
    KEYFRAME_ANCHOR_TYPE,
}                                           from '../enums';

const getMoveIndexRightKeyframe = (
    buttonWidth: number,
    index: number,
    spacing = 0,
    anchorLocation = KEYFRAME_ANCHOR_TYPE.left,
): Keyframes => keyframes`
    ${'0%'} {
        transform: translateX(0px);
        width: ${buttonWidth}px;
    }

    ${'50%'} {
        transform: ${anchorLocation === KEYFRAME_ANCHOR_TYPE.left
        ? 'translateX(0px)'
        : `translateX(calc((${index} * (${spacing}px + ${buttonWidth}px))))`};
        width: calc(${buttonWidth}px + ${index} * (${spacing}px + ${buttonWidth}px));
    }

    ${'100%'} {
        transform: translateX(calc(${index} * (${spacing}px + ${buttonWidth}px)));
        width: ${buttonWidth}px;
    }
`;

export default getMoveIndexRightKeyframe;
