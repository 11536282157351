// ===== Packages =====

import {
    Node,
    Element,
}                                       from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    ListNode,
}                                       from '../../interfaces';
import ListableInterface                from '../../interfaces/listableInterface';

// ===== Enums =====

import {
    LIST_TYPE,
}                                       from './enums';

const BLOCK_LIST_TYPE = 'list';

const isListElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_LIST_TYPE;

const isOL = (node: EditorNode): boolean => isListElement(node) && node.listType === LIST_TYPE.ordered;

const isUL = (node: EditorNode): boolean => isListElement(node) && node.listType === LIST_TYPE.unordered;

const newListElement = (listType: LIST_TYPE): ListNode => ({
    type: BLOCK_LIST_TYPE,
    listType,
    children: [],
});

const listTypeIsValid = (listType: LIST_TYPE): boolean => Object.values(LIST_TYPE).includes(listType);

export default {
    TYPE: BLOCK_LIST_TYPE,
    isListElement,
    newListElement,
    LIST_TYPE,
    ...ListableInterface,
    listTypeIsValid,
    isOL,
    isUL,
};
