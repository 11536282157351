// Repurposed from https://www.jayfreestone.com/writing/react-portals-with-hooks/
import { useRef, useEffect } from 'react';

/**
 * Creates DOM element to be used as React root.
 * @returns {HTMLElement}
 */
const createRootElement = (id: string): HTMLSpanElement => {
    const rootContainer = document.createElement('span');
    rootContainer.setAttribute('id', id);
    return rootContainer;
};

/**
 * Appends element as last child of body.
 * @param {HTMLElement} rootElem
 */
const addRootElement = (
    rootElem: HTMLElement,
    insertNode: Node,
    isFirstChild = false,
): void => {
    if (insertNode && isFirstChild) {
        insertNode.insertBefore(rootElem, insertNode.firstChild);
    } else if (insertNode) {
        insertNode.appendChild(rootElem);
    } else if (!insertNode && isFirstChild) {
        document.body.insertBefore(rootElem, document.body.firstChild);
    } else {
        document.body.appendChild(rootElem);
    }
};

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const usePortal = (
    id: string,
    insertNode = document.body,
    isFirstChild = false,
): HTMLSpanElement => {
    const rootElemRef = useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
    // Look for existing target dom element to append to
        const existingParent: HTMLElement | null = document.querySelector(`#${id}`);
        // Parent is either a new root or the existing dom element
        const parentElem = existingParent || createRootElement(id);

        // If there is no existing DOM element, add a new one.
        if (!existingParent) {
            addRootElement(parentElem, insertNode, isFirstChild);
        } else {
            // Remove existing DOM element
            rootElemRef.current?.remove();
            if (parentElem.childNodes.length === -1) {
                parentElem.remove();
            }
            addRootElement(parentElem, insertNode);
        }

        // Add the detached element to the parent
        if (rootElemRef.current) {
            parentElem.appendChild(rootElemRef.current);
        }

        return function removeElement() {
            rootElemRef.current?.remove();
            if (parentElem.childNodes.length === -1) {
                parentElem.remove();
            }
        };
    }, [insertNode]);

    /**
    * It's important we evaluate this lazily:
    * - We need first render to contain the DOM element, so it shouldn't happen
    *   in useEffect. We would normally put this in the constructor().
    * - We can't do 'const rootElemRef = useRef(document.createElement('span))',
    *   since this will run every single render (that's a lot).
    * - We want the ref to consistently point to the same DOM element and only
    *   ever run once.
    * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
    */
    const getRootElem = (): HTMLSpanElement => {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('span');
        }
        return rootElemRef.current;
    };

    return getRootElem();
};

export default usePortal;
