// ===== Referenced Resources =====
// https://andrebnassis.medium.com/setting-eslint-on-a-react-typescript-project-2021-1190a43ffba

// ===== Packages =====

import React                    from 'react';
import ReactDOM                 from 'react-dom';
import { ThemeContext }         from 'styled-components';
import { BrowserRouter }        from 'react-router-dom';
import { DndProvider }          from 'react-dnd';
import { HTML5Backend }         from 'react-dnd-html5-backend';
import { TouchBackend }         from 'react-dnd-touch-backend';

// ===== Components =====

import App                      from './App';

// ===== Services =====

import {
    detectTouchDevice,
}                               from './services';

// ===== Stylesheets =====

import { theme }                from './themes/theme-context';
import { GlobalStyle }          from './styles/global';

ReactDOM.render(
    <React.StrictMode>
        <ThemeContext.Provider value={theme}>
            <DndProvider backend={detectTouchDevice(document)
                ? TouchBackend
                : HTML5Backend}
            >
                <GlobalStyle />
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </DndProvider>
        </ThemeContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
