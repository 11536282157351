// Reference: https://codepedia.info/detect-browser-in-javascript
// ===== Enums =====

import {
    BROWSER_TYPE,
}                   from '../enums';

const detectBrowser = (): BROWSER_TYPE => {
    const agent = window.navigator.userAgent.toLowerCase();

    if (agent.indexOf('edge') > -1) return BROWSER_TYPE.edgeNonChromium;
    if (agent.indexOf('edg/') > -1) return BROWSER_TYPE.edgeChromium;
    if (agent.indexOf('opr') > -1) return BROWSER_TYPE.opera;
    if (agent.indexOf('chrome') > -1) return BROWSER_TYPE.chrome;
    if (agent.indexOf('trident') > -1) return BROWSER_TYPE.explorer;
    if (agent.indexOf('firefox') > -1) return BROWSER_TYPE.firefox;
    if (agent.indexOf('safari') > -1) return BROWSER_TYPE.safari;

    return BROWSER_TYPE.other;
};

export default detectBrowser;
