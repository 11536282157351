// ===== Packages =====

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
}                               from 'react';
import styled                   from 'styled-components';
import { Transition }           from 'react-transition-group';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment                   from 'moment-timezone';
import { ReactSVG }             from 'react-svg';
import {
    useParams,
    useMatch,
    useNavigate,
    useLocation,
    useSearchParams,
}                               from 'react-router-dom';
import {
    doc,
    DocumentData,
    DocumentSnapshot,
    getDoc,
    getFirestore,
}                               from 'firebase/firestore';
import {
    Editor,
}                               from 'slate';
import {
    ref,
    getStorage,
    getDownloadURL,
}                               from 'firebase/storage';
import { v4 as uuidv4 }         from 'uuid';

// ===== Components =====

import {
    AnnotationEditor,
}                               from '../editors';
import {
    Button,
    OptionsMenu,
}                               from '.';

// ===== Services =====

import {
    copyToClipboard,
    fontColorDiscriminator,
    generateTextSpeech,
    getStorageErrorMessage,
    playAudio,
    recordUserAction,
    updateAnnotationInDB,
    updatePostInDB,
    detectTouchDevice,
}                               from '../../../services';

// ===== Hooks =====

import {
    useEventListener,
    useInterval,
    useTimeout,
}                               from '../../../hooks';

// ===== Interfaces =====

import {
    IAnnotationItem,
    IAnnotationValue,
    IDimension,
    IPostItem,
    ISnackbarItem,
    IUserItem,
}                               from '../../../interfaces';

// ===== Enums =====

import {
    BUTTON_TYPE,
    CURSOR_TARGET,
    EDITOR_CONTEXT_TYPE,
    FIRESTORE_COLLECTION,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    STORAGE_ERROR_CODE,
    TOOLTIP_TYPE,
    USER_ACTION_TYPE,
}                               from '../../../enums';

// ===== Sounds =====

import AnnotationExpand         from '../../../sounds/swoosh_in.mp3';
import AnnotationContract       from '../../../sounds/swoosh_out.mp3';

// ===== Images =====

import SmileyIcon               from '../../../images/editor/smiley.svg';
import MoreIcon                 from '../../../images/editor/more.svg';
import CautionIcon              from '../../../images/caution.svg';
import LinkIcon                 from '../../../images/editor/link.svg';
import TrashIcon                from '../../../images/editor/trash.svg';
import PlayIcon                 from '../../../images/editor/play-fill.svg';
import PauseIcon                from '../../../images/editor/pause-fill.svg';

// ===== Constants =====

import {
    DEFAULT_CSS_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
    ANNOTATION_EDITOR_Z_INDEX,
    BODY_FONT_SIZE,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    USER_PROFILE_AVATAR_LENGTH,
    DEFAULT_AUDIO_VOLUME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    MILLISECONDS_IN_A_SECOND,
    ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT,
    ANNOTATION_EDITOR_FONT_MULTIPLIER_REGULAR,
}                               from '../../../constants/generalConstants';
import CURSOR_SIGN              from '../../../constants/cursorSigns';
import MEDIA_QUERY_SIZE         from '../../../constants/mediaQuerySizes';
import FONT_TYPE                from '../../../constants/fontType';

// ===== Styles =====

import { theme as themeObj }    from '../../../themes/theme-context';
import {
    UserAvatar,
    PlaceholderBox,
    AvatarContainer,
}                               from '../../../styles';

Annotation.defaultProps = {
    toggleAnnotationStack: undefined,
    setRemeasureBucketHeight: undefined,
};
interface Props {
    annotation: IAnnotationItem,
    user: IUserItem | null,
    post: IPostItem | undefined,
    hasSound: boolean,
    bucketWidth: number,
    bucketHeight: number | null,
    currentSessionId: string | null,
    index: number,
    zIndex: number,
    horizontalMargin: number,
    annotationStackActive: boolean,
    annotationStackExpanded: boolean,
    annotationCount: number,
    hide: boolean,
    viewportDimensions: IDimension,
    toggleAnnotationStack?: () => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setTargetAnnotationID: React.Dispatch<React.SetStateAction<string | null>>,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    showAnnotationDetail: (annotation: IAnnotationItem) => void,
    hideAnnotationDetail: () => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    setRemeasureBucketHeight?: React.Dispatch<React.SetStateAction<boolean>>,
}
function Annotation({
    annotation,
    user,
    post,
    hasSound,
    bucketWidth,
    bucketHeight,
    currentSessionId,
    index,
    zIndex,
    horizontalMargin,
    annotationStackActive,
    annotationStackExpanded,
    annotationCount,
    hide,
    viewportDimensions,
    toggleAnnotationStack,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    setTargetAnnotationID,
    setCursorSigns,
    showAnnotationDetail,
    hideAnnotationDetail,
    setSnackbarData,
    setRemeasureBucketHeight,
}: Props): JSX.Element {
    // ===== General Information =====
    //
    // 1. We specifically choose not to increment an annotation if a user loads the web app with an
    // annotation focused, and rather require that they click on the annotation to increment it.
    // This is to prevent the annotation from being incremented when the app rerenders when the
    // web app with an annotation focused.

    // ===== General Constants =====

    const AVATAR_LENGTH = 28;
    const ANNOTATION_ENTER_DURATION = 200;
    const ANNOTATION_TRANSITION_DURATION = DEFAULT_CSS_TRANSITION_DURATION;
    const ANNOTATION_STACK_OFFSET_INCREMENT_LENGTH = 7;
    const MAX_ANNOTATION_HEIGHT = 80;
    const MORE_BUTTON_LENGTH = 25;
    const OPTION_BUTTON_WIDTH = 80;
    const OPTION_BUTTON_HEIGHT = 30;
    const ANNOTATION_AUTHOR_PLACEHOLDER_WIDTH = 100;
    const ANNOTATION_AUTHOR_FONT_MULTIPLIER = 0.8;
    const QUOTE_CONTAINER_MARGIN_TOP = MORE_BUTTON_LENGTH + 15;
    const DICTATION_BUTTON_LENGTH = 28;
    const USER_SIGNATURE_PADDING = 8;
    const ANNOTATION_EDITOR_CONTAINER_PADDING_LEFT = 35;
    const SNACKBAR_MESSAGE_COPY_LINK_SUCCESS = 'Copied annotation link to clipboard!';
    const SNACKBAR_MESSAGE_DELETE_ANNOTATION_SUCCESS = 'Annotation successfully deleted.';
    const SNACKBAR_MESSAGE_COPY_LINK_ERROR = 'There was a problem copying annotation link to your clipboard';

    // ===== Refs =====

    const containerRef = useRef<HTMLDivElement | null>(null);
    const audioNodeRef = useRef<HTMLAudioElement>(null);

    // ----- Sound Clips
    const expandClip = useRef<HTMLAudioElement>(new Audio());
    const contractClip = useRef<HTMLAudioElement>(new Audio());

    // ===== React Router =====

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const annotationIsFocused = useMatch(`${PAGE_ROUTE.book}/:annotationId`);
    const [searchParams] = useSearchParams();

    // ===== State =====

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [annotationHeight, setAnnotationHeight] = useState<number | null>(null);
    const [annotationEditor, setAnnotationEditor] = useState<Editor | null>(null);
    const [remeasureAnnotationHeight, setRemeasureAnnotationHeight] = useState<boolean>(true);
    const [annotationAuthorAvatarURL, setAnnotationAuthorAvatarURL] = useState<string | null>(null);
    const [annotationAuthorAvatarPath, setAnnotationAuthorAvatarPath] = useState<string | undefined>(undefined);
    const [annotationAuthor, setAnnotationAuthor] = useState<IUserItem | null>(null);
    const [postAuthorAvatarURLs, setPostAuthorAvatarURLs] = useState<string[]>([]);
    const [postAuthors, setPostAuthors] = useState<IUserItem[]>([]);
    const [priorAnnotationsHeight, setPriorAnnotationsHeight] = useState<number>(0);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [audioURL, setAudioURL] = useState<string | null>(null);
    const [dictationPlaying, setDictationPlaying] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [savedTime, setSavedTime] = useState<number | null>(null);
    const [audioLength, setAudioLength] = useState<number>(0);
    const [timeElapsed, setTimeElapsed] = useState<number>(0);

    // ===== Animation Constants =====

    const FOCUSING_TRANSITION_DURATION = 200;

    // ===== Methods =====

    const onMouseEnter = (e: React.MouseEvent): void => {
        let cursorSign = CURSOR_SIGN.click;
        if (
            annotationStackActive
            && !annotationStackExpanded
            && annotationHeight
            && annotationHeight > MAX_ANNOTATION_HEIGHT
        ) {
            cursorSign = CURSOR_SIGN.more;
        } else if (annotationStackActive && !annotationStackExpanded) {
            cursorSign = CURSOR_SIGN.expandStack;
        }

        if (!isFocused) {
            onCursorEnter(
                CURSOR_TARGET.editorElement,
                [cursorSign],
                e.target as HTMLElement,
            );
        }
        setTargetAnnotationID(annotation.id);
    };

    const onMouseLeave = (e: React.MouseEvent): void => {
        setTargetAnnotationID(null);
        onCursorLeave(e);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.editorElement,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const focusAnnotation = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.openAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                views: [
                    ...annotation.views,
                    actionId,
                ],
            });
        }
        // Play Sound
        if (
            hasSound
            && expandClip.current
        ) {
            expandClip.current.pause();
            expandClip.current.currentTime = 0;
            playAudio(expandClip.current);
        } else if (
            hasSound
            && contractClip.current
        ) {
            contractClip.current.pause();
            contractClip.current.currentTime = 0;
            playAudio(contractClip.current);
        }

        navigate(
            `/${PAGE_ROUTE.book}/${annotation.id}?${searchParams.toString()}`,
            {
                state: location.state,
            },
        );
    };

    const unfocusAnnotation = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.closeAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.book}?${searchParams.toString()}`,
            {
                state: location.state,
            },
        );
    };

    const onClick = (): void => {
        if (
            toggleAnnotationStack
            && !annotationStackExpanded
            && !annotationIsFocused
        ) {
            toggleAnnotationStack();
            // We want the sign to change immediately
            setCursorSigns([CURSOR_SIGN.click]);
        } else if (!annotationIsFocused) {
            focusAnnotation();
        }
    };

    const onEditAnnotation = (): void => {
        setIsEditing(true);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.editInitiateAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    /* We intentionally do not remove associations of deleted annotations
    to users or posts */
    const onDeleteAnnotation = async (): Promise<void> => {
        // Update post annotation references
        // We place before deletion so rerender doesn't happen first
        if (post) {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            const updatedAnnotations = [...post.annotations];
            const annotationIndex = updatedAnnotations.findIndex((id) => id === annotation.id);
            if (annotationIndex !== -1) updatedAnnotations.splice(annotationIndex, 1);
            updatePostInDB({
                collection: postsCollection,
                id: post.id,
                annotations: [...updatedAnnotations],
            });

            // Remove from chapters and sections as well
            if (post.chapters) {
                const indicesOfChaptersToRemove: number[] = [];
                post.chapters.forEach((chapter, chapterIndex) => {
                    if (chapter.annotations.includes(annotation.id)) {
                        indicesOfChaptersToRemove.push(chapterIndex);
                    }
                });
                const updatedChapters = [...post.chapters];
                indicesOfChaptersToRemove.forEach((chapterIndex) => {
                    const chapterAnnotations = [...updatedChapters[chapterIndex].annotations];
                    const annotationChapterIndex = chapterAnnotations.findIndex((id) => id === annotation.id);
                    if (annotationChapterIndex !== -1) chapterAnnotations.splice(annotationChapterIndex, 1);
                    updatedChapters[chapterIndex].annotations = [...chapterAnnotations];
                    updatedChapters[chapterIndex].updated = [
                        ...updatedChapters[chapterIndex].updated,
                        Date.now(),
                    ];

                    if (updatedChapters[chapterIndex].sections) {
                        const indicesOfSectionsToRemove: number[] = [];
                        updatedChapters[chapterIndex].sections!.forEach((section, sectionIndex) => {
                            if (section.annotations.includes(annotation.id)) {
                                indicesOfSectionsToRemove.push(sectionIndex);
                            }
                        });
                        indicesOfSectionsToRemove.forEach((sectionIndex) => {
                            const sectionAnnotations = [...updatedChapters[chapterIndex].sections![sectionIndex].annotations];
                            const annotationSectionIndex = sectionAnnotations.findIndex((id) => id === annotation.id);
                            if (annotationSectionIndex !== -1) sectionAnnotations.splice(annotationSectionIndex, 1);
                            updatedChapters[chapterIndex].sections![sectionIndex].annotations = [...sectionAnnotations];
                            updatedChapters[chapterIndex].sections![sectionIndex].updated = [
                                ...updatedChapters[chapterIndex].sections![sectionIndex].updated,
                                Date.now(),
                            ];
                        });
                    }
                });
                updatePostInDB({
                    collection: postsCollection,
                    id: post.id,
                    chapters: updatedChapters,
                });
            }
        }

        // Delete annotation
        const collection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        updateAnnotationInDB({
            collection,
            id: annotation.id,
            deleted: {
                deleted: true,
                timestamp: Date.now(),
            },
        }).then(() => {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_DELETE_ANNOTATION_SUCCESS,
                icon: TrashIcon,
            });
        });

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.deleteAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    const onGetLink = async (): Promise<void> => {
        // Copy link to clipboard
        const link = `${window.location.origin}/${PAGE_ROUTE.book}/${annotation.id}`;
        copyToClipboard(
            link,
            () => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_COPY_LINK_SUCCESS,
                    icon: LinkIcon,
                });
            },
            () => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_COPY_LINK_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            },
        );

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.getLinkAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    const remeasureParentHeight = (): void => {
        setRemeasureAnnotationHeight(true);
        if (setRemeasureBucketHeight) setRemeasureBucketHeight(true);
    };

    const toggleDictation = async (e: React.MouseEvent): Promise<void> => {
        e.stopPropagation();
        // Updates timestamps on a regular interval
        if (!dictationPlaying && audioNodeRef.current) {
            // if (timeElapsed === 0) {
            //     audioNodeRef.current.currentTime = 0;
            // }
            audioNodeRef.current.play()
                .then(async () => {
                    setDictationPlaying(true);
                    // Cache timestamp started recording
                    setStartTime(Date.now());

                    if (
                        user
                        && currentSessionId
                    ) {
                        // Record user action
                        await recordUserAction({
                            type: USER_ACTION_TYPE.startAnnotationDictation,
                            userId: user.id,
                            sessionId: currentSessionId,
                            payload: {
                                id: annotation.id,
                            },
                        });
                    }
                })
                .catch(() => {
                    //
                });
        } else if (dictationPlaying && audioNodeRef.current) {
            audioNodeRef.current.pause();
            setDictationPlaying(false);
            if (
                user
                && currentSessionId
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.stopAnnotationDictation,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        id: annotation.id,
                    },
                });
            }
        }
    };

    const computeTimeElapsed = (): number => {
        // Collect and save elapsed time
        // We are likely recording audio, so need to time it the elapsed time
        let currentTime = Date.now();
        if (
            dictationPlaying
            && audioLength > 0
            && audioNodeRef.current
            && startTime
        ) {
            // We have track data, so compute normally
            currentTime = (audioNodeRef.current.currentTime * MILLISECONDS_IN_A_SECOND) + startTime;
        }

        let difference;
        if (
            !dictationPlaying
            && timeElapsed > 0
            && startTime
        ) {
            difference = currentTime - startTime;
        } else if (savedTime && startTime) {
            // If we've paused, factor in previous time
            difference = (currentTime - startTime) + savedTime;
        } else {
            // Ternary makes sure that we don't begin with a large
            // timestamp
            difference = startTime ? currentTime - startTime : 0;
        }
        return difference;
    };

    const recordFinishDictationAction = async (): Promise<void> => {
        if (
            user
            && currentSessionId
            && !dictationPlaying
        ) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.finishAnnotationDictation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id: annotation.id,
                },
            });
            const collection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            updateAnnotationInDB({
                collection,
                id: annotation.id,
                plays: [
                    ...annotation.plays,
                    actionId,
                ],
            });
        }
    };

    const handleEditAnnotation = async (
        value: string,
        text: string,
        media: string[],
    ): Promise<void> => {
        if (!annotationEditor) throw Error('There was a problem submitting annotation. Editor reference not found.');
        if (!user) throw Error('There was a problem submitting annotation. User data not found.');

        const timestamp = Date.now();
        const annotationValue: IAnnotationValue = {
            id: uuidv4(),
            timestamp,
            value,
            text,
        };
        // We capture text sychronously because it is cleared by the annotation editor
        // after this call.
        // Placing it in the updateAnnotationInDB callback will result in an empty string.
        const collection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        updateAnnotationInDB({
            collection,
            id: annotation.id,
            history: [
                annotationValue,
                ...annotation.history,
            ],
            media,
        }).then(() => {
            // Generate Text Dictation
            // This should in turn generate a transcript with timestamps
            // by triggering the speech-to-text cloud function
            generateTextSpeech(
                annotation.id,
                text,
                'masculine',
            );
            generateTextSpeech(
                annotation.id,
                text,
                'feminine',
                (result) => {
                    // We use feminine voice by default
                    const { filePath } = result.data;
                    const annotationCollection = process.env.NODE_ENV === 'production'
                        ? FIRESTORE_COLLECTION.annotations
                        : FIRESTORE_COLLECTION.stagingAnnotations;
                    updateAnnotationInDB({
                        collection: annotationCollection,
                        id: annotation.id,
                        dictationFilePaths: annotation.dictationFilePaths
                            ? [
                                filePath,
                                ...annotation.dictationFilePaths,
                            ] : [filePath],
                    });
                },
            );
        });

        if (user && currentSessionId) {
            // Record user action
            await recordUserAction({
                type: USER_ACTION_TYPE.editSaveAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                updated: [
                    ...annotation.updated,
                    Date.now(),
                ],
            });
        }
    };

    const handleCancelEditAnnotation = (): void => {
        setIsEditing(false);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.editCancelAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            expandClip.current
            && contractClip.current
        ) {
            // Annotation Expand
            expandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            expandClip.current.src = AnnotationExpand;

            // Annotation Contract
            contractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            contractClip.current.src = AnnotationContract;
        }

        return function cleanup() {
            if (expandClip.current) expandClip.current.remove();
            if (contractClip.current) contractClip.current.remove();
        };
    }, []);

    /**
     * Loads the annotation author's data
     */
    useEffect(() => {
        if (!annotationAuthor) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const userRef = doc(db, usersCollection, annotation.userId);
            getDoc(userRef).then((userSnap) => {
                if (userSnap.exists()) {
                    const userItem = userSnap.data() as IUserItem;
                    setAnnotationAuthor(userItem);
                }
            });
        }
    }, [annotation.userId]);

    /**
     * Loads the post authors' data
     */
    useEffect(() => {
        if (
            post
            && postAuthors.length === 0
        ) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const authorPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
            for (let i = 0; i < post.authors.length; i += 1) {
                const userId = post.authors[i];
                authorPromises.push(getDoc(doc(db, usersCollection, userId)));
            }
            Promise.all(authorPromises).then((authors) => {
                setPostAuthors([
                    ...authors
                        .map((authorSnap) => authorSnap.data() as IUserItem)
                        .filter((author) => !!author),
                ]);
            });
        }
    }, [post?.authors]);

    /**
     * Remeasures bucket height so annotation stacks
     * adjust
     */
    useEffect(() => {
        if (remeasureAnnotationHeight && containerRef.current) {
            const { height } = containerRef.current.getBoundingClientRect();
            setAnnotationHeight(height);
            setRemeasureAnnotationHeight(false);
        }
    }, [
        remeasureAnnotationHeight,
    ]);

    /**
     * Computes the height of all prior annotations when new annotations
     */
    useEffect(() => {
        clearTimeoutComputePriorAnnotationsHeight();
        timeoutComputePriorAnnotationsHeight();
    }, [
        bucketHeight,
        annotationCount,
        params.annotationId,
    ]);

    /**
     * Focuses the annotation when the annotation id is in the url
     */
    useEffect(() => {
        if (
            annotationIsFocused
            && params.annotationId
            && params.annotationId === annotation.id
            && !isFocused
        ) {
            setIsFocused(true);
            // useful for when annotation detail banner on smaller viewports
            showAnnotationDetail(annotation);
        } else if (
            !annotationIsFocused
            && isFocused
        ) {
            setIsFocused(false);
            hideAnnotationDetail();
        }
    }, [
        params,
        annotationIsFocused,
    ]);

    useEffect(() => {
        if (
            annotationAuthor
            && annotationAuthor.avatarFilePath !== annotationAuthorAvatarPath
            // multiple calls to getDownloadURL can be made
            // before the first one returns
            && !annotationAuthorAvatarURL
        ) {
            const storage = getStorage();
            if (annotationAuthor.avatarFilePath) {
                const pathParts = annotationAuthor.avatarFilePath.split('.');
                const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                getDownloadURL(ref(storage, mediumPath)).then((imageURL) => {
                    setAnnotationAuthorAvatarURL(imageURL);
                    setAnnotationAuthorAvatarPath(annotationAuthor.avatarFilePath);
                }).catch((error) => {
                    // We assume cloud function has not yet generated medium image yet
                    if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                        getDownloadURL(ref(storage, annotationAuthor.avatarFilePath)).then((imageURL) => {
                            setAnnotationAuthorAvatarURL(imageURL);
                        }).catch((err) => {
                            // This is not an error we need to present to the user
                            throw Error(getStorageErrorMessage(err.code as STORAGE_ERROR_CODE));
                        });
                    } else {
                        // This is not an error we need to present to the user
                        throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                    }
                });
            } else {
                setAnnotationAuthorAvatarURL(null);
                setAnnotationAuthorAvatarPath(undefined);
            }
        }
    }, [
        annotationAuthorAvatarURL,
        annotationAuthor?.avatarFilePath,
    ]);

    useEffect(() => {
        if (
            postAuthors.length > 0
            && postAuthorAvatarURLs.length === 0
        ) {
            const postAuthorsPromises = [];
            for (let i = 0; i < postAuthors.length; i += 1) {
                const postAuthor = postAuthors[i];
                if (postAuthor.avatarFilePath) {
                    const storage = getStorage();
                    const pathParts = postAuthor.avatarFilePath!.split('.');
                    const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                    postAuthorsPromises.push(getDownloadURL(ref(storage, mediumPath)));
                }
            }

            Promise.all(postAuthorsPromises).then((imageURLs) => {
                setPostAuthorAvatarURLs(imageURLs);
            });
        }
    }, [postAuthors]);

    // Get annotation dictation audio
    useEffect(() => {
        if (
            audioNodeRef.current
            && annotation.dictationFilePaths
            && annotation.dictationFilePaths.length > 0
            && !audioURL
        ) {
            audioNodeRef.current.load();
            const storage = getStorage();
            const pathParts = annotation.dictationFilePaths[0].split('.');
            const flacPath = `${pathParts[0]}.flac`;
            getDownloadURL(ref(storage, flacPath))
                .then((url) => {
                    setAudioURL(url);
                    if (audioNodeRef.current) {
                        audioNodeRef.current.volume = DEFAULT_AUDIO_VOLUME;
                        (audioNodeRef.current.childNodes[0] as HTMLSourceElement).src = url;
                    }
                })
                .catch((error) => {
                    if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                        getDownloadURL(ref(storage, annotation.dictationFilePaths![0]))
                            .then((url) => {
                                setAudioURL(url);
                                if (audioNodeRef.current) {
                                    audioNodeRef.current.volume = DEFAULT_AUDIO_VOLUME;
                                    (audioNodeRef.current.childNodes[0] as HTMLSourceElement).src = url;
                                }
                            });
                    }
                });
        }
    }, [
        audioURL,
        annotation.dictationFilePaths,
    ]);

    // Update elapsed time
    // Stop audio when reached the end
    useInterval(() => {
        const newElapsed = computeTimeElapsed();
        setTimeElapsed(newElapsed);

        const roundedTimeElapsed = Math.round(timeElapsed * 1000) / 1000;
        const roundedAudioLength = Math.round(audioLength * 1000) / 1000;
        // Stop Playback when reach end of recording
        if (
            audioNodeRef.current
            && dictationPlaying
            && !Number.isNaN(audioLength)
            && roundedTimeElapsed >= roundedAudioLength
        ) {
            // Update Recording timestamp
            setDictationPlaying(false);
            audioNodeRef.current.pause();
            audioNodeRef.current.currentTime = 0;
            setTimeElapsed(0);
            setStartTime(null);
            setSavedTime(null);
            recordFinishDictationAction();
        }
        // The alternative occurs when the metadata is not fetched.
        // This results in immediately ending the song.
        // This is an edge case that happens a handful of times.
    }, dictationPlaying ? MILLISECONDS_IN_A_SECOND / 10 : null);

    // Update audio lenth when metadata updated
    useEventListener(
        'loadedmetadata',
        useCallback((): void => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { duration } = audioNodeRef.current!;
            // Chromium bug condition
            if (audioNodeRef.current && duration === Infinity) {
                // ...set audioNodeRef.current.currentTime to a very large number
                // ...listen to "timeupdate" event
                // ...on "timeupdate", retrieve now-updated duration
                audioNodeRef.current.currentTime = 10000000 * Math.random();
            } else if (!Number.isNaN(duration)) {
                setAudioLength(duration * MILLISECONDS_IN_A_SECOND);
            }
        }, [audioNodeRef.current]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        audioNodeRef.current!,
    );

    const {
        start: timeoutComputePriorAnnotationsHeight,
        clear: clearTimeoutComputePriorAnnotationsHeight,
    } = useTimeout(() => {
        if (containerRef.current) {
            let priorAccumulatedHeights = 0;
            const siblings = containerRef.current.parentElement!.children;
            for (let i = 0; i < siblings.length; i += 1) {
                const sibling = siblings[i];
                const siblingId = sibling.id;
                if (siblingId === annotation.id) break;
                priorAccumulatedHeights += siblings[i].clientHeight;
                setPriorAnnotationsHeight(priorAccumulatedHeights);
            }
        }
    }, ANNOTATION_TRANSITION_DURATION);

    // ===== Memoization =====

    const isAnnotationAuthor = useMemo(() => !!user && annotation.userId === user.id, [
        user?.id,
        annotation.userId,
    ]);

    const isPostAuthor = useMemo(() => !!post?.authors && post.authors.includes(annotation.userId), [
        post?.authors,
        annotation.userId,
    ]);

    const hasAuthorAvatarImage = useMemo(() => (!!postAuthorAvatarURLs[0] || (
        postAuthors.length > 0
        && !!postAuthors[0] && !!postAuthors[0].photoURL
    )), [
        postAuthorAvatarURLs,
        postAuthors,
    ]);

    const hasUserAvatarImage = useMemo(() => (!!annotationAuthorAvatarURL || !!annotationAuthor?.photoURL), [
        annotationAuthorAvatarURL,
        annotationAuthor?.photoURL,
    ]);

    const DictationIcon = useMemo(() => (dictationPlaying ? PauseIcon : PlayIcon), [dictationPlaying]);

    // ===== Renderers =====

    /**
     * Renderer for the options menu buttons
     */
    const OptionsMenuButtons = useMemo(() => {
        const activeMenuButtons: {
            text: string;
            action: () => void;
        }[] = [];
        const menuButtons = [
            {
                text: isFocused ? 'Close' : 'Open',
                action: isFocused ? unfocusAnnotation : focusAnnotation,
            },
            {
                text: 'Edit',
                action: onEditAnnotation,
            },
            {
                text: 'Get Link',
                action: onGetLink,
            },
            {
                text: 'Delete',
                action: onDeleteAnnotation,
            },
        ];

        // Select Annotation
        activeMenuButtons.push(menuButtons[0]);

        // Edit Annotation
        if (isAnnotationAuthor) activeMenuButtons.push(menuButtons[1]);

        // Get Link of Annotation
        activeMenuButtons.push(menuButtons[2]);

        // Delete Annotation
        if (isAnnotationAuthor) activeMenuButtons.push(menuButtons[3]);

        return activeMenuButtons.map((button) => (
            <Button
                key={button.text}
                center
                className={HOVER_TARGET_CLASSNAME}
                type={BUTTON_TYPE.secret}
                height={OPTION_BUTTON_HEIGHT}
                width={OPTION_BUTTON_WIDTH}
                text={button.text}
                {...(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                    || !isFocused
                    ? {
                        onMouseEnter: onButtonMouseEnter,
                        onMouseLeave: onButtonMouseLeave,
                    } : {}
                )}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: button.action,
                } : {
                    onMouseDown: button.action,
                })}
            />
        ));
    }, [
        isFocused,
        viewportDimensions.width,
        isAnnotationAuthor,
    ]);

    const AnnotationComponent = useMemo(() => (
        <Container
            ref={containerRef}
            id={annotation.id}
            className={HOVER_TARGET_CLASSNAME}
            index={index}
            zIndex={zIndex}
            isFocused={isFocused}
            hide={hide}
            horizontalMargin={horizontalMargin}
            annotationStackActive={annotationStackActive}
            annotationStackExpanded={annotationStackExpanded}
            stackOffsetIncrementLength={ANNOTATION_STACK_OFFSET_INCREMENT_LENGTH}
            priorAnnotationsHeight={priorAnnotationsHeight}
            transitionDuration={ANNOTATION_TRANSITION_DURATION}
            enterDuration={ANNOTATION_ENTER_DURATION}
            {...(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                || !isFocused
                ? {
                    onMouseEnter,
                    onMouseLeave,
                } : {}
            )}
            {...(isEditing
                ? {}
                : {
                    onClick,
                }
            )}
        >
            {!isEditing
            && (viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min || isFocused)
            && (
                <OptionsMenuContainer>
                    <OptionsMenu
                        center
                        className={HOVER_TARGET_CLASSNAME}
                        revealAbove={false}
                        buttonHeight={MORE_BUTTON_LENGTH}
                        childItemHeight={OPTION_BUTTON_HEIGHT}
                        childItemWidth={OPTION_BUTTON_WIDTH}
                        parentRef={containerRef.current
                            ? containerRef.current.parentElement
                            : null}
                        tooltipText="More"
                        tooltipSideType={TOOLTIP_TYPE.left}
                        {...(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                            || !isFocused
                            ? {
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            } : {}
                        )}
                    >
                        {OptionsMenuButtons}
                    </OptionsMenu>
                </OptionsMenuContainer>
            )}
            <Transition
                in={
                    isFocused
                    && viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                }
                timeout={{
                    enter: FOCUSING_TRANSITION_DURATION,
                    exit: FOCUSING_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(transitionState) => (
                    <CloseButtonContainer
                        buttonHeight={ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 10,
                                duration: FOCUSING_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 10,
                            })[transitionState],
                        }}
                    >
                        <Button
                            boxShadow
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.solid}
                            height={ANNOTATION_FOCUSED_CLOSE_BUTTON_HEIGHT}
                            background={themeObj.verascopeColor.purple400}
                            text="Close"
                            {...(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                                || !isFocused
                                ? {
                                    onMouseEnter: onButtonMouseEnter,
                                    onMouseLeave: onButtonMouseLeave,
                                } : {}
                            )}
                            onClick={unfocusAnnotation}
                        />
                    </CloseButtonContainer>
                )}
            </Transition>
            <Transition
                in={isFocused}
                timeout={{
                    enter: FOCUSING_TRANSITION_DURATION,
                    exit: FOCUSING_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(transitionState) => (
                    <QuoteContainer
                        marginTop={QUOTE_CONTAINER_MARGIN_TOP}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'down',
                                offset: 10,
                                duration: FOCUSING_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'down',
                                offset: 10,
                            })[transitionState],
                        }}
                    >
                        <QuoteText>
                            {annotation.quoteHistory[0].texts.reduce((acc, text) => {
                                if (acc.length === 0) {
                                    return text;
                                }

                                return `${acc}, ${text}`;
                            }, '')}
                        </QuoteText>
                        <PostAuthorSignature>
                            <AvatarContainer
                                hasAvatarImage={hasAuthorAvatarImage}
                                length={AVATAR_LENGTH}
                            >
                                {hasAuthorAvatarImage
                                    ? (
                                        <UserAvatar
                                            url={
                                                postAuthorAvatarURLs[0]
                                                || postAuthors[0].photoURL!
                                            } // Has to be present if avatarURL is false
                                            length={USER_PROFILE_AVATAR_LENGTH}
                                        />
                                    ) : (
                                        <ReactSVG
                                            src={SmileyIcon}
                                        />
                                    )}
                            </AvatarContainer>
                            <PostAuthorDetails>
                                {postAuthors.length > 0
                                && (
                                    <PostAuthorName>
                                        {postAuthors.reduce((acc, author, authorIndex) => {
                                            if (authorIndex === 0) {
                                                return `${author.firstName} ${author.lastName}`;
                                            }

                                            return `${acc}, ${author.firstName} ${author.lastName}`;
                                        }, '')}
                                    </PostAuthorName>
                                )}
                                {post
                                && (
                                    <PostAuthorPostName>
                                        {post?.title}
                                    </PostAuthorPostName>
                                )}
                            </PostAuthorDetails>
                        </PostAuthorSignature>
                    </QuoteContainer>
                )}
            </Transition>
            <AnnotationBody
                isFocused={isFocused}
                isEditing={isEditing}
                maxHeight={MAX_ANNOTATION_HEIGHT}
                annotationStackActive={annotationStackActive}
                annotationStackExpanded={annotationStackExpanded}
            >
                <UserSignature
                    isFocused={isFocused}
                    padding={USER_SIGNATURE_PADDING}
                    transitionDuration={FOCUSING_TRANSITION_DURATION}
                >
                    <AvatarContainer
                        hasAvatarImage={hasUserAvatarImage}
                        length={AVATAR_LENGTH}
                        withRing={isPostAuthor
                            && viewportDimensions.width < MEDIA_QUERY_SIZE.small.min}
                    >
                        {hasUserAvatarImage
                            ? (
                                <UserAvatar
                                    url={annotationAuthorAvatarURL || annotationAuthor!.photoURL!} // Has to be present if avatarURL is false
                                    length={USER_PROFILE_AVATAR_LENGTH}
                                />
                            ) : (
                                <ReactSVG
                                    src={SmileyIcon}
                                />
                            )}
                    </AvatarContainer>
                    {(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min || isFocused)
                    && (
                        <AnnotationDetails>
                            {annotationAuthor
                                ? (
                                    <AnnotationAuthorName
                                        fontMultiplier={ANNOTATION_AUTHOR_FONT_MULTIPLIER}
                                    >
                                        {`${annotationAuthor?.firstName} ${annotationAuthor?.lastName}`}
                                        {isPostAuthor && (
                                            <AuthorTag>
                                                Author
                                            </AuthorTag>
                                        )}
                                    </AnnotationAuthorName>
                                ) : (
                                    <PlaceholderBox
                                        width={ANNOTATION_AUTHOR_PLACEHOLDER_WIDTH}
                                        height={ANNOTATION_AUTHOR_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                    />
                                )}
                            <Timestamp>
                                {`${moment.utc(annotation.published).fromNow()}${annotation.history.length > 1 ? ' (edited)' : ''}`}
                            </Timestamp>
                        </AnnotationDetails>
                    )}
                </UserSignature>
                {(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min || isFocused)
                && (
                    <AnnotationEditorContainer
                        isFocused={isFocused}
                        paddingLeft={ANNOTATION_EDITOR_CONTAINER_PADDING_LEFT}
                        transitionDuration={FOCUSING_TRANSITION_DURATION}
                    >
                        <AnnotationEditor
                            readOnly={!isEditing}
                            isEditing={isEditing}
                            width={bucketWidth - ANNOTATION_EDITOR_CONTAINER_PADDING_LEFT}
                            user={user}
                            currentSessionId={currentSessionId}
                            boxShadow={false}
                            isAuthor={isAnnotationAuthor}
                            hasSound={hasSound}
                            postId={annotation.postId}
                            color={themeObj.verascopeColor.purple200}
                            fontMultiplier={ANNOTATION_EDITOR_FONT_MULTIPLIER_REGULAR}
                            id={annotation.id}
                            type={EDITOR_CONTEXT_TYPE.annotation} // Used to know which storage bucket to save media
                            message={annotation.history[0]}
                            transcript={annotation.transcripts ? annotation.transcripts[0] : undefined}
                            dictationPlaying={dictationPlaying}
                            timeElapsed={timeElapsed}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            setInputFocused={setInputFocused}
                            setEditor={setAnnotationEditor}
                            setSnackbarData={setSnackbarData}
                            remeasureParentHeight={remeasureParentHeight}
                            editAnnotation={handleEditAnnotation}
                            cancelEditAnnotation={handleCancelEditAnnotation}
                        />
                        {annotation.dictationFilePaths
                        && annotation.transcripts
                        && !isEditing
                        && (
                            <DictationButtonContainer
                                left={USER_SIGNATURE_PADDING}
                            >
                                <Button
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={BUTTON_TYPE.secret}
                                    height={DICTATION_BUTTON_LENGTH}
                                    width={DICTATION_BUTTON_LENGTH}
                                    icon={DictationIcon}
                                    {...(viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                                        || !isFocused
                                        ? {
                                            onMouseEnter: onButtonMouseEnter,
                                            onMouseLeave: onButtonMouseLeave,
                                        } : {}
                                    )}
                                    onClick={toggleDictation}
                                />
                            </DictationButtonContainer>
                        )}
                    </AnnotationEditorContainer>
                )}
                {annotationHeight
                && annotationHeight > MAX_ANNOTATION_HEIGHT // Will never be possible on small viewport
                && annotationStackActive
                && (
                    <AnnotationGradient />
                )}
                {annotationHeight
                && annotationHeight > MAX_ANNOTATION_HEIGHT // Will never be possible on small viewport
                && annotationStackActive
                && (
                    <TruncatedAnnotationIndicator>
                        <ReactSVG
                            src={MoreIcon}
                        />
                    </TruncatedAnnotationIndicator>
                )}
                {annotation.dictationFilePaths
                && (
                    <AudioElement
                        ref={audioNodeRef}
                        preload="none"
                    >
                        {audioURL
                        && (
                            <AudioSource
                                src={audioURL}
                            />
                        )}
                        <AudioTrack kind="captions" />
                        Your browser does not support audio.
                    </AudioElement>
                )}
            </AnnotationBody>
        </Container>
    ), [
        // ===== Props =====
        annotation.history,
        annotation.dictationFilePaths,
        annotation.transcripts,
        annotation.quoteHistory,
        annotation.deleted,
        post?.title,
        hasSound,
        bucketWidth,
        zIndex,
        annotationStackActive,
        annotationStackExpanded,
        hide,
        viewportDimensions,
        // ===== State =====
        isEditing,
        annotationHeight,
        // annotationAuthor,
        annotationAuthorAvatarURL,
        // postAuthors,
        postAuthorAvatarURLs,
        // priorAnnotationsHeight, // causes rerender whenever new annotation rendered
        isFocused,
        // audioURL,
        dictationPlaying,
        timeElapsed,
        // ===== Memoizations =====
        // isAnnotationAuthor,
        // isPostAuthor,
        // hasAuthorAvatarImage,
        DictationIcon,
        // OptionsMenuButtons,
    ]);

    return AnnotationComponent;
}

// ===== Styled Components =====

interface ContainerProps {
    index: number,
    zIndex: number,
    isFocused: boolean,
    hide: boolean,
    priorAnnotationsHeight: number,
    annotationStackActive: boolean,
    annotationStackExpanded: boolean,
    stackOffsetIncrementLength: number,
    horizontalMargin: number,
    transitionDuration: number,
    enterDuration: number,
}
const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ isFocused }) => (isFocused
        ? 'center'
        : 'flex-start'
    )};
    position: ${({ isFocused }) => (isFocused
        ? 'absolute'
        : 'relative'
    )};
    top: ${({
        index,
        priorAnnotationsHeight,
        annotationStackActive,
        annotationStackExpanded,
        stackOffsetIncrementLength,
    }) => {
        if (annotationStackActive && !annotationStackExpanded) {
            return `${-priorAnnotationsHeight + index * stackOffsetIncrementLength}px`;
        }

        return '0px';
    }};
    left: 0px;
    width: ${({ horizontalMargin }) => `calc(100% - ${2 * horizontalMargin}px)`};
    height: ${({ isFocused }) => (isFocused
        ? '100%'
        : 'auto'
    )};
    max-width: ${({ isFocused }) => (isFocused
        ? 'none'
        : '500px'
    )};
    border-radius: 5px;
    margin: ${({ horizontalMargin }) => `0px ${horizontalMargin}px`};
    margin-top: ${({
        index,
        isFocused,
        annotationStackActive,
        annotationStackExpanded,
    }) => `${index > 0
        && !annotationStackActive
        && !annotationStackExpanded
        // When focused, the annotation is absolutely positioned to be top of annotation bucket
        && !isFocused
        ? 10
        : 0}px`};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    z-index: ${({ zIndex }) => zIndex};
    pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
    visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
    transition: ${({
        theme,
        enterDuration,
        transitionDuration,
    }) => `
        top ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${enterDuration}ms ${theme.motion.eagerEasing},
        opacity ${enterDuration}ms ${theme.motion.eagerEasing},
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing} !important
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        ${({ isFocused }) => isFocused && `
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        `}
    }
`;

interface AnnotationBodyProps {
    isFocused: boolean,
    isEditing: boolean,
    maxHeight: number,
    annotationStackActive: boolean,
    annotationStackExpanded: boolean,
}
const AnnotationBody = styled.div<AnnotationBodyProps>`
    display: flex;
    flex-direction: ${({ isFocused }) => (isFocused
        ? 'column-reverse'
        : 'column'
    )};
    border-radius: 5px;
    max-height: ${({
        maxHeight,
        annotationStackActive,
        annotationStackExpanded,
    }) => (annotationStackActive && !annotationStackExpanded ? `${maxHeight + 20}px` : 'none')};
    overflow-x: ${({ isEditing }) => {
        if (isEditing) return 'visible';
        return 'hidden';
    }};
    overflow-y: ${({ isFocused, isEditing }) => {
        if (isEditing) return 'visible';
        if (isFocused) return 'scroll';
        return 'hidden';
    }};
`;

interface UserSignatureProps {
    isFocused: boolean,
    padding: number,
    transitionDuration: number,
}
const UserSignature = styled.div<UserSignatureProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({ padding }) => `${padding}px`};
    padding-bottom: 1px;
    padding-top: ${({ isFocused }) => `${isFocused ? 0 : 8}px`};
    transition: ${({ transitionDuration, theme }) => `
        padding-top ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-bottom: ${({ padding }) => `${padding}px`};
    }
`;

const AnnotationDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
`;

interface AnnotationAuthorNameProps {
    fontMultiplier: number,
}
const AnnotationAuthorName = styled.h4<AnnotationAuthorNameProps>`
    position: relative;
    display: inline-block;
    opacity: 0.87;
    margin: 0;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    font-weight: 600;
    color: ${({ theme }) => theme.color.neutral1000};
    line-height: 1.2em;
    text-overflow: ellipsis;
`;

const Timestamp = styled.span`
    display: inline-block;
    font-size: 0.7em;
    line-height: 1.05em;
    color: ${({ theme }) => theme.color.neutral700};
    white-space: nowrap;
`;

const OptionsMenuContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 5px;
    background: ${({ theme }) => theme.color.white};
    z-index: ${ANNOTATION_EDITOR_Z_INDEX + 1};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: 50px;
    }
`;

const AuthorTag = styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    background: ${({ theme }) => theme.verascopeColor.purple400};
    border-radius: 7.5px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.8em;
    color: ${({ theme }) => fontColorDiscriminator(theme.verascopeColor.purple400)};
    line-height: 1em;
    padding: 2.5px 7.5px;
    margin-left: 7.5px;
`;

interface CloseButtonContainerProps {
    buttonHeight: number,
}
const CloseButtonContainer = styled.div<CloseButtonContainerProps>`
    position: absolute;
    top: ${({ buttonHeight }) => `-${buttonHeight + 5}px`};
    left: 0px;
    background: ${({ theme }) => theme.color.white};
`;

interface QuoteContainerProps {
    marginTop: number,
}
const QuoteContainer = styled.div<QuoteContainerProps>`
    margin: 15px;
    margin-top: ${({ marginTop }) => `${marginTop}px`};
    margin-bottom: 20px;
    margin-left: 40px;
    padding: 10px;
    border: ${({ theme }) => `2px solid ${theme.color.neutral300}`};
    border-radius: 5px;
`;

const QuoteText = styled.p`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 600;
    font-size: 0.9em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral1000};
    margin: 0;
    margin-bottom: 15px;
    padding-left: 5px;
    border-left: ${({ theme }) => `4px solid ${theme.color.neutral700}`};
`;

const PostAuthorSignature = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
`;

const PostAuthorDetails = styled.div`
    margin-right: 7.5px;
`;

const PostAuthorName = styled.p`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral1000};
    margin: 0;
    margin-bottom: 5px;
    text-align: right;
`;

const PostAuthorPostName = styled.p`
    position: relative;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.7em;
    line-height: 1em;
    color: ${({ theme }) => theme.color.neutral600};
    text-align: right;
    margin: 0;
`;

interface AnnotationEditorContainerProps {
    isFocused: boolean,
    transitionDuration: number,
    paddingLeft: number,
}
const AnnotationEditorContainer = styled.div<AnnotationEditorContainerProps>`
    position: relative;
    padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
    margin-bottom: ${({ isFocused }) => `${isFocused ? 5 : 0}px`};
    transition: ${({ transitionDuration, theme }) => `
        padding-top ${transitionDuration}ms ${theme.motion.eagerEasing},
        margin-bottom ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};
`;

const TruncatedAnnotationIndicator = styled.div`
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 3px;
    height: 20px;
    width: 20px;
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};

    & svg, & div {
        position: relative;
        height: 20px;
        width: 20px;
        margin: 0;
    }

    & path {
        fill: ${({ theme }) => theme.color.neutral800};
    }
`;

const AnnotationGradient = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 1));
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};
`;

interface DictationButtonContainerProps {
    left: number,
}
const DictationButtonContainer = styled.div<DictationButtonContainerProps>`
    position: absolute;
    top: 2px;
    left: ${({ left }) => `${left}px`};
    background: ${({ theme }) => theme.color.white};
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};

    & svg {
        fill: ${({ theme }) => theme.color.neutral700};
    }
`;

const AudioElement = styled.audio``;
const AudioSource = styled.source``;
const AudioTrack = styled.track``;

export default Annotation;
