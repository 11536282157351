// ===== Enums =====

import {
    UNSUBSCRIBE_STATUS_TYPE,
}                               from '../enums';

const getUnsubscribeMessage = (status: UNSUBSCRIBE_STATUS_TYPE): string => {
    let message: string;
    switch (status) {
    case UNSUBSCRIBE_STATUS_TYPE.uninitated:
        message = 'Unsubscribe request was not made. Please try unsubscribe again.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.stillSubscribed:
        message = 'Your account is still subscribed to the mailing list. Please try unsubscribe again.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.missingProperty:
        message = 'Your account is missing critical information required to unsubscribe you from the'
            + ' mailing list. Please contact verascope to resolve the issue.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.success:
        message = 'Your account has been successfully unsubscribed from the mailing list.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.userNotFound:
        message = 'No verascope account exists for the provided user. Please contact verascope if there are any unresolves matters.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.timeout:
        message = 'No verascope account was provided. Please try again.';
        break;
    case UNSUBSCRIBE_STATUS_TYPE.uncaughtError:
        message = 'Unrecognized error occurred. Please try again.';
        break;
    default:
        message = 'Unrecognized error occurred. Please try again.';
        break;
    }

    return message;
};

export default getUnsubscribeMessage;
