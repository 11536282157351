// ===== Packages =====

import React, {
    useRef,
    useState,
    useCallback,
    useEffect,
}                                   from 'react';
import ReactDOM                     from 'react-dom';
import { Transition }               from 'react-transition-group';

// ===== Services =====

import {
    playAudio,
}                                   from '../../services';

// ===== Hooks =====

import { useTimeout }               from '../../hooks';

// ===== Assets =====

import ModalEnter                   from '../../sounds/swoosh_in.mp3';
import ModalExit                    from '../../sounds/swoosh_out.mp3';

// ===== Constants =====

import {
    CURSOR_Z_INDEX,
    DEFAULT_AUDIO_VOLUME,
}                                   from '../../constants/generalConstants';

// ===== Styles =====

import {
    ModalContainer,
    ModallessContainer,
}                                   from './styles';
import {
    Overlay,
}                                   from '../../styles';
import { theme }                    from '../../themes/theme-context';

const root = window.document.getElementById('root');

interface Props {
    isOpen: boolean,
    color?: string,
    width?: number,
    hasSound: boolean,
    hasContainer: boolean,
    overrideClickOverlay?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement,
    closeModal?: () => void,
}
function Modal({
    isOpen,
    color,
    width,
    hasSound,
    children,
    overrideClickOverlay,
    closeModal,
    hasContainer,
}: Props): JSX.Element {
    // ===== Refs =====

    // ----- Sound Clips

    const modalEnterClip = useRef<HTMLAudioElement>(new Audio());
    const modalExitClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State ====

    const [isVisible, setIsVisible] = useState(false);

    // ===== Animation Constants =====

    // Duration is takes to enter and exit Modal
    const MODAL_CONTENTS_TRANSITION_DURATION = 300;

    // Duration it takes for modal to enter viewport
    const MODAL_ENTER_DURATION = 200;

    // Duration it takes for modal to exit viewport
    const MODAL_EXIT_DURATION = 500;

    // ===== Methods =====

    const handleClick = (): void => {
        if (overrideClickOverlay) return;
        setIsVisible(false);
        delayBroadcastModalExit();

        // Play Sound
        if (hasSound) {
            modalExitClip.current.pause();
            modalExitClip.current.currentTime = 0;
            playAudio(modalExitClip.current);
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        // Modal Enter
        modalEnterClip.current.volume = DEFAULT_AUDIO_VOLUME;
        modalEnterClip.current.src = ModalEnter;

        // Modal Exit
        modalExitClip.current.volume = DEFAULT_AUDIO_VOLUME;
        modalExitClip.current.src = ModalExit;

        return function cleanup() {
            modalEnterClip.current.remove();
            modalExitClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            delayModalEnter();
        } else if (isVisible) {
            setIsVisible(false);

            // Play Sound
            if (hasSound) {
                modalExitClip.current.pause();
                modalExitClip.current.currentTime = 0;
                playAudio(modalExitClip.current);
            }
        }
    }, [isOpen]);

    // Determine initial isVisible State
    const {
        start: delayModalEnter,
    } = useTimeout(() => {
        setIsVisible(true);

        // Play Sound
        if (hasSound) {
            modalEnterClip.current.pause();
            modalEnterClip.current.currentTime = 0;
            playAudio(modalEnterClip.current);
        }
    }, MODAL_ENTER_DURATION);

    const {
        start: delayBroadcastModalExit,
    } = useTimeout(() => {
        if (closeModal) closeModal();
    }, MODAL_EXIT_DURATION);

    // ===== Rendering =====

    const renderOverlay = useCallback(() => {
        const defaultStyle = {
            opacity: 0,
            zIndex: -1,
            transition: 'opacity 200ms ease-in',
        };

        interface OverlayTransitionStyle {
            entering: {
                opacity: number,
                zIndex: number,
            },
            entered: {
                opacity: number,
                zIndex: number,
            },
            exiting: {
                transitionDelay: string,
                zIndex: number,
            },
            [index: string]: {
                opacity: number,
                zIndex: number,
            } | {
                transitionDelay: string,
                zIndex: number,
            }
        }
        const transitionStyles: OverlayTransitionStyle = {
            entering: {
                opacity: 0,
                zIndex: -1,
            },
            entered: {
                opacity: 1,
                zIndex: CURSOR_Z_INDEX - 1,
            },
            exiting: {
                transitionDelay: '300ms',
                zIndex: CURSOR_Z_INDEX - 1,
            },
        };

        return (
            <Transition
                in={isOpen}
                timeout={{
                    enter: MODAL_ENTER_DURATION,
                    exit: MODAL_EXIT_DURATION,
                }}
                appear
                mountOnEnter
            >
                {(state) => (
                    <Overlay
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                        onClick={handleClick}
                    />
                )}
            </Transition>
        );
    }, [isOpen]);

    const renderModalContents = useCallback(() => {
        const defaultStyle = {
            opacity: 0,
            top: -1000,
            transform: 'translate(-50%, -50%)',
            transition: `top 300ms ${theme.motion.overshoot}, opacity 100ms ease-in`,
        };

        interface ModalContentsTransitionStyles {
            entering: {
                top: number,
                opacity: number,
                transform: string,
            },
            entered: {
                opacity: number,
                top: string,
                transform: string,
            },
            exiting: {
                opacity: number,
                top: string,
                transition: string,
            },
            exited: {
                top: number,
                opacity: number,
            },
            [index: string]: {
                top: number,
                opacity: number,
                transform: string,
            } | {
                top: number,
                opacity: number,
            } | {
                opacity: number,
                top: string,
                transition: string,
            } | {
                opacity: number,
                top: string,
                transform: string,
            }
        }
        const transitionStyles: ModalContentsTransitionStyles = {
            entering: {
                top: -1000,
                opacity: 0,
                transform: 'translate(-50%, -50%)',
            },
            entered: {
                opacity: 1,
                top: '50%',
                transform: 'translate(-50%, -50%)',
            },
            exiting: {
                opacity: 1,
                top: '50%',
                transition: 'top 300ms ease-out, opacity 300ms ease-out',
            },
            exited: {
                top: 0,
                opacity: 0,
            },
        };

        if (hasContainer) {
            return (
                <Transition
                    in={isVisible}
                    timeout={{
                        enter: MODAL_CONTENTS_TRANSITION_DURATION,
                        exit: MODAL_CONTENTS_TRANSITION_DURATION,
                    }}
                    appear
                    mountOnEnter
                >
                    {(state) => (
                        <ModalContainer
                            id="modal"
                            color={color}
                            width={width}
                            isVisible={isVisible}
                            style={{
                                ...defaultStyle,
                                ...transitionStyles[state],
                            }}
                        >
                            {React.cloneElement(children, { handleClose: handleClick })}
                        </ModalContainer>
                    )}
                </Transition>
            );
        }

        return (
            <Transition
                in={isVisible}
                timeout={{
                    enter: MODAL_CONTENTS_TRANSITION_DURATION,
                    exit: MODAL_CONTENTS_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
            >
                {(state) => (
                    <ModallessContainer
                        isVisible={isVisible}
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        {React.cloneElement(children, { handleClose: handleClick })}
                    </ModallessContainer>
                )}
            </Transition>
        );
    }, [
        hasContainer,
        isVisible,
        children,
    ]);

    return (
        ReactDOM.createPortal(
            <div>
                {renderOverlay()}
                {renderModalContents()}
            </div>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            root!,
        )
    );
}

export default Modal;
