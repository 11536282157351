// ===== Interface =====

import { ICoord } from '../interfaces';

// Reference: https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
const getElementCoords = (
    element: HTMLElement,
    parentElement?: HTMLElement,
    scrollElement?: HTMLElement,
): ICoord => { // crossbrowser version
    const box = element.getBoundingClientRect();
    const parentBox = parentElement?.getBoundingClientRect();

    const { body } = document;
    const docEl = document.documentElement;

    const scrollTop = scrollElement?.scrollTop || window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = scrollElement?.scrollLeft || window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = parentBox?.top || docEl.clientTop || body.clientTop || 0;
    const clientLeft = parentBox?.left || docEl.clientLeft || body.clientLeft || 0;

    const top  = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return {
        x: Math.round(left),
        y: Math.round(top),
    };
};

export default getElementCoords;
