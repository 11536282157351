// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                               from 'react';

// ===== Services =====

import {
    getVimeoId,
    getEmbeddingDimensions,
    recordUserAction,
}                                               from '../../services';

// ===== Hooks =====

import { useEventListener }                     from '../../hooks';

// ===== Interfaces =====

import {
    ICartridgeItem,
    IUserItem,
}                                               from '../../interfaces';

// ===== Enums =====

import {
    USER_ACTION_TYPE,
}                                               from '../../enums';

// ===== Constants =====

import EmbeddingDimensions                      from '../../constants/embeddingDimensions';

// ===== Styles =====

import {
    Container,
    Frame,
}                                               from './styles';

// Example Vimeo: https://vimeo.com/115154289

interface Props {
    url: string,
    parentRef: HTMLElement,
    cartridge: ICartridgeItem,
    user: IUserItem | null,
    currentSessionId: string | null,
    triggerEmbeddingResize: boolean,
    setTriggerEmbeddingResize: React.Dispatch<React.SetStateAction<boolean>>,
}
function VimeoEmbed({
    url,
    parentRef,
    cartridge,
    user,
    currentSessionId,
    triggerEmbeddingResize,
    setTriggerEmbeddingResize,
}: Props): JSX.Element {
    // ===== State =====

    const [vimeoId, setVimeoId] = useState('');
    const [dimensions, setDimensions] = useState(EmbeddingDimensions[480]);

    // ===== Side Effects =====

    // Get ID
    useEffect(() => {
        const vmoId = getVimeoId(url);
        if (vmoId) {
            setVimeoId(vmoId);
        }
    }, [url]);

    // On Mount, determine dimensions
    // On trigger, determine dimensions
    useEffect(() => {
        determineDimensions();
        if (triggerEmbeddingResize) {
            determineDimensions();
            setTriggerEmbeddingResize(false);
        }
    }, [triggerEmbeddingResize]);

    // On resize, determine dimensions
    useEventListener(
        'resize',
        () => {
            determineDimensions();
        },
    );

    // ===== Methods =====

    const determineDimensions = (): void => {
        if (parentRef) {
            const parentRect = parentRef.getBoundingClientRect();
            const parentPadding = 2 * parseFloat(
                window
                    .getComputedStyle(parentRef, null)
                    .getPropertyValue('padding')
                    .replace('px', ''),
            );
            const currentDimension = getEmbeddingDimensions(parentRect.width - parentPadding);
            setDimensions(EmbeddingDimensions[currentDimension]);
        }
    };

    const clickVimeo = async (): Promise<void> => {
        if (
            user
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickCartridgeVimeo,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    cartridge,
                    videoType: 'vimeo',
                    url,
                },
            });
        }
    };

    return (
        <Container
            onClick={clickVimeo}
        >
            {vimeoId && vimeoId.length > 0
            && (
                <Frame
                    title={`vimeo-embed=${url}`}
                    className="vimeo-embed"
                    src={`https://player.vimeo.com/video/${vimeoId}`}
                    frameBorder="0"
                    width={dimensions.width}
                    height={dimensions.height}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                        background: 'transparent',
                        border: '1px solid #ccc',
                    }}
                />
            )}
        </Container>
    );
}

// <iframe
//     src="https://player.vimeo.com/video/115154289?h=f7d38726fe&title=0&byline=0&portrait=0"
//     width="640"
//     height="360"
//     frameborder="0"
//     allow="autoplay; fullscreen; picture-in-picture"
//     allowfullscreen>
//  </iframe>
//
// <iframe
//     src="https://player.vimeo.com/video/97903574?h=6958c4c407&title=0&byline=0&portrait=0"
//     width="640"
//     height="360"
//     frameborder="0"
//     allow="autoplay; fullscreen; picture-in-picture"
//     allowfullscreen>
// </iframe>

// ===== Styled Components =====

export default VimeoEmbed;
