// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeGetPaymentMethod = async ({
    test,
    paymentIntentId,
}: {
    test: boolean,
    paymentIntentId: string,
}): Promise<Stripe.PaymentMethod | undefined> => {
    const functions = getFunctions();
    const getPaymentMethod = httpsCallable<
        Record<
            string,
            boolean | string
        >,
        Stripe.PaymentMethod | undefined
    >(
        functions,
        'stripeGetPaymentMethod',
    );
    return getPaymentMethod({
        test,
        paymentIntentId,
    }).then((result) => {
        const paymentMethod = result.data as Stripe.PaymentMethod | undefined;
        return paymentMethod;
    }).catch((err) => err);
};

export default stripeGetPaymentMethod;
