// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
    HttpsCallableResult,
}                           from 'firebase/functions';

// ===== Services =====

import { normalizeURL } from '.';

const fetchURLMetadata = (
    url: string,
    onComplete: (result: HttpsCallableResult<unknown>) => void,
): boolean => {
    const functions = getFunctions();
    const fetchMetadata = httpsCallable(functions, 'getURLMetadata');
    fetchMetadata({ url: normalizeURL(url) })
        .then((result) => {
            onComplete(result);
        });

    return true;
};

export default fetchURLMetadata;
