// ===== Enums =====

import {
    CARTRIDGE,
    CARTRIDGE_STATE,
}                                   from '../enums';

// ===== Interfaces =====

import { ICartridgeType }           from '../interfaces';

// ===== Images =====

import BlueDarkerInsertedSide       from '../images/cartridges/blue-darker-inserted-side.svg';
import BlueDarkerInsertedTop        from '../images/cartridges/blue-darker-inserted-top.svg';
import BlueDarkerFace               from '../images/cartridges/blue-darker-face.svg';
import BlueDarkerIsometric          from '../images/cartridges/blue-darker-isometric.svg';

import BlueDarkInsertedSide         from '../images/cartridges/blue-dark-inserted-side.svg';
import BlueDarkInsertedTop          from '../images/cartridges/blue-dark-inserted-top.svg';
import BlueDarkFace                 from '../images/cartridges/blue-dark-face.svg';
import BlueDarkIsometric            from '../images/cartridges/blue-dark-isometric.svg';

import BlueRegularInsertedSide       from '../images/cartridges/blue-regular-inserted-side.svg';
import BlueRegularInsertedTop       from '../images/cartridges/blue-regular-inserted-top.svg';
import BlueRegularFace              from '../images/cartridges/blue-regular-face.svg';
import BlueRegularIsometric         from '../images/cartridges/blue-regular-isometric.svg';

import BlueLightInsertedSide        from '../images/cartridges/blue-light-inserted-side.svg';
import BlueLightInsertedTop         from '../images/cartridges/blue-light-inserted-top.svg';
import BlueLightFace                from '../images/cartridges/blue-light-face.svg';
import BlueLightIsometric           from '../images/cartridges/blue-light-isometric.svg';

import GrayOneInsertedSide          from '../images/cartridges/gray-one-inserted-side.svg';
import GrayOneInsertedTop           from '../images/cartridges/gray-one-inserted-top.svg';
import GrayOneFace                  from '../images/cartridges/gray-one-face.svg';
import GrayOneIsometric             from '../images/cartridges/gray-one-isometric.svg';

import GrayTwoInsertedSide          from '../images/cartridges/gray-two-inserted-side.svg';
import GrayTwoInsertedTop           from '../images/cartridges/gray-two-inserted-top.svg';
import GrayTwoFace                  from '../images/cartridges/gray-two-face.svg';
import GrayTwoIsometric             from '../images/cartridges/gray-two-isometric.svg';

import GrayThreeInsertedSide        from '../images/cartridges/gray-three-inserted-side.svg';
import GrayThreeInsertedTop         from '../images/cartridges/gray-three-inserted-top.svg';
import GrayThreeFace                from '../images/cartridges/gray-three-face.svg';
import GrayThreeIsometric           from '../images/cartridges/gray-three-isometric.svg';

import GrayFourInsertedSide         from '../images/cartridges/gray-four-inserted-side.svg';
import GrayFourInsertedTop          from '../images/cartridges/gray-four-inserted-top.svg';
import GrayFourFace                 from '../images/cartridges/gray-four-face.svg';
import GrayFourIsometric            from '../images/cartridges/gray-four-isometric.svg';

import GrayFiveInsertedSide         from '../images/cartridges/gray-five-inserted-side.svg';
import GrayFiveInsertedTop          from '../images/cartridges/gray-five-inserted-top.svg';
import GrayFiveFace                 from '../images/cartridges/gray-five-face.svg';
import GrayFiveIsometric            from '../images/cartridges/gray-five-isometric.svg';

import GraySixInsertedSide          from '../images/cartridges/gray-six-inserted-side.svg';
import GraySixInsertedTop           from '../images/cartridges/gray-six-inserted-top.svg';
import GraySixFace                  from '../images/cartridges/gray-six-face.svg';
import GraySixIsometric             from '../images/cartridges/gray-six-isometric.svg';

import GraySevenInsertedSide        from '../images/cartridges/gray-seven-inserted-side.svg';
import GraySevenInsertedTop         from '../images/cartridges/gray-seven-inserted-top.svg';
import GraySevenFace                from '../images/cartridges/gray-seven-face.svg';
import GraySevenIsometric           from '../images/cartridges/gray-seven-isometric.svg';

import GreenDarkInsertedSide        from '../images/cartridges/green-dark-inserted-side.svg';
import GreenDarkInsertedTop         from '../images/cartridges/green-dark-inserted-top.svg';
import GreenDarkFace                from '../images/cartridges/green-dark-face.svg';
import GreenDarkIsometric           from '../images/cartridges/green-dark-isometric.svg';

import GreenRegularInsertedSide     from '../images/cartridges/green-regular-inserted-side.svg';
import GreenRegularInsertedTop      from '../images/cartridges/green-regular-inserted-top.svg';
import GreenRegularFace             from '../images/cartridges/green-regular-face.svg';
import GreenRegularIsometric        from '../images/cartridges/green-regular-isometric.svg';

import GreenLightInsertedSide       from '../images/cartridges/green-light-inserted-side.svg';
import GreenLightInsertedTop        from '../images/cartridges/green-light-inserted-top.svg';
import GreenLightFace               from '../images/cartridges/green-light-face.svg';
import GreenLightIsometric          from '../images/cartridges/green-light-isometric.svg';

import OrangeDarkInsertedSide       from '../images/cartridges/orange-dark-inserted-side.svg';
import OrangeDarkInsertedTop        from '../images/cartridges/orange-dark-inserted-top.svg';
import OrangeDarkFace               from '../images/cartridges/orange-dark-face.svg';
import OrangeDarkIsometric          from '../images/cartridges/orange-dark-isometric.svg';

import OrangeRegularInsertedSide    from '../images/cartridges/orange-regular-inserted-side.svg';
import OrangeRegularInsertedTop     from '../images/cartridges/orange-regular-inserted-top.svg';
import OrangeRegularFace            from '../images/cartridges/orange-regular-face.svg';
import OrangeRegularIsometric       from '../images/cartridges/orange-regular-isometric.svg';

import OrangeLightInsertedSide      from '../images/cartridges/orange-light-inserted-side.svg';
import OrangeLightInsertedTop       from '../images/cartridges/orange-light-inserted-top.svg';
import OrangeLightFace              from '../images/cartridges/orange-light-face.svg';
import OrangeLightIsometric         from '../images/cartridges/orange-light-isometric.svg';

import RedDarkInsertedSide          from '../images/cartridges/red-dark-inserted-side.svg';
import RedDarkInsertedTop           from '../images/cartridges/red-dark-inserted-top.svg';
import RedDarkFace                  from '../images/cartridges/red-dark-face.svg';
import RedDarkIsometric             from '../images/cartridges/red-dark-isometric.svg';

import RedRegularInsertedSide       from '../images/cartridges/red-regular-inserted-side.svg';
import RedRegularInsertedTop        from '../images/cartridges/red-regular-inserted-top.svg';
import RedRegularFace               from '../images/cartridges/red-regular-face.svg';
import RedRegularIsometric          from '../images/cartridges/red-regular-isometric.svg';

import RedLightInsertedSide         from '../images/cartridges/red-light-inserted-side.svg';
import RedLightInsertedTop          from '../images/cartridges/red-light-inserted-top.svg';
import RedLightFace                 from '../images/cartridges/red-light-face.svg';
import RedLightIsometric            from '../images/cartridges/red-light-isometric.svg';

import YellowDarkInsertedSide       from '../images/cartridges/yellow-dark-inserted-side.svg';
import YellowDarkInsertedTop        from '../images/cartridges/yellow-dark-inserted-top.svg';
import YellowDarkFace               from '../images/cartridges/yellow-dark-face.svg';
import YellowDarkIsometric          from '../images/cartridges/yellow-dark-isometric.svg';

import YellowRegularInsertedSide    from '../images/cartridges/yellow-regular-inserted-side.svg';
import YellowRegularInsertedTop     from '../images/cartridges/yellow-regular-inserted-top.svg';
import YellowRegularFace            from '../images/cartridges/yellow-regular-face.svg';
import YellowRegularIsometric       from '../images/cartridges/yellow-regular-isometric.svg';

import YellowLightInsertedSide      from '../images/cartridges/yellow-light-inserted-side.svg';
import YellowLightInsertedTop       from '../images/cartridges/yellow-light-inserted-top.svg';
import YellowLightFace              from '../images/cartridges/yellow-light-face.svg';
import YellowLightIsometric         from '../images/cartridges/yellow-light-isometric.svg';

import { theme }                    from '../themes/theme-context';

const CARTRIDGE_TYPE: ICartridgeType = {
    [CARTRIDGE.blueDarker]: {
        [CARTRIDGE_STATE.insertedSide]: BlueDarkerInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: BlueDarkerInsertedTop,
        [CARTRIDGE_STATE.face]: BlueDarkerFace,
        [CARTRIDGE_STATE.isometric]: BlueDarkerIsometric,
        color: theme.verascopeColor.purple100,
    },
    [CARTRIDGE.blueDark]: {
        [CARTRIDGE_STATE.insertedSide]: BlueDarkInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: BlueDarkInsertedTop,
        [CARTRIDGE_STATE.face]: BlueDarkFace,
        [CARTRIDGE_STATE.isometric]: BlueDarkIsometric,
        color: theme.verascopeColor.purple200,
    },
    [CARTRIDGE.blueRegular]: {
        [CARTRIDGE_STATE.insertedSide]: BlueRegularInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: BlueRegularInsertedTop,
        [CARTRIDGE_STATE.face]: BlueRegularFace,
        [CARTRIDGE_STATE.isometric]: BlueRegularIsometric,
        color: theme.verascopeColor.purple300,
    },
    [CARTRIDGE.blueLight]: {
        [CARTRIDGE_STATE.insertedSide]: BlueLightInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: BlueLightInsertedTop,
        [CARTRIDGE_STATE.face]: BlueLightFace,
        [CARTRIDGE_STATE.isometric]: BlueLightIsometric,
        color: theme.verascopeColor.purple400,
    },
    [CARTRIDGE.grayOne]: {
        [CARTRIDGE_STATE.insertedSide]: GrayOneInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GrayOneInsertedTop,
        [CARTRIDGE_STATE.face]: GrayOneFace,
        [CARTRIDGE_STATE.isometric]: GrayOneIsometric,
        color: theme.verascopeColor.white,
    },
    [CARTRIDGE.grayTwo]: {
        [CARTRIDGE_STATE.insertedSide]: GrayTwoInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GrayTwoInsertedTop,
        [CARTRIDGE_STATE.face]: GrayTwoFace,
        [CARTRIDGE_STATE.isometric]: GrayTwoIsometric,
        color: theme.verascopeColor.gray600,
    },
    [CARTRIDGE.grayThree]: {
        [CARTRIDGE_STATE.insertedSide]: GrayThreeInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GrayThreeInsertedTop,
        [CARTRIDGE_STATE.face]: GrayThreeFace,
        [CARTRIDGE_STATE.isometric]: GrayThreeIsometric,
        color: theme.verascopeColor.gray500,
    },
    [CARTRIDGE.grayFour]: {
        [CARTRIDGE_STATE.insertedSide]: GrayFourInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GrayFourInsertedTop,
        [CARTRIDGE_STATE.face]: GrayFourFace,
        [CARTRIDGE_STATE.isometric]: GrayFourIsometric,
        color: theme.verascopeColor.gray400,
    },
    [CARTRIDGE.grayFive]: {
        [CARTRIDGE_STATE.insertedSide]: GrayFiveInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GrayFiveInsertedTop,
        [CARTRIDGE_STATE.face]: GrayFiveFace,
        [CARTRIDGE_STATE.isometric]: GrayFiveIsometric,
        color: theme.verascopeColor.gray300,
    },
    [CARTRIDGE.graySix]: {
        [CARTRIDGE_STATE.insertedSide]: GraySixInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GraySixInsertedTop,
        [CARTRIDGE_STATE.face]: GraySixFace,
        [CARTRIDGE_STATE.isometric]: GraySixIsometric,
        color: theme.verascopeColor.gray200,
    },
    [CARTRIDGE.graySeven]: {
        [CARTRIDGE_STATE.insertedSide]: GraySevenInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GraySevenInsertedTop,
        [CARTRIDGE_STATE.face]: GraySevenFace,
        [CARTRIDGE_STATE.isometric]: GraySevenIsometric,
        color: theme.verascopeColor.gray100,
    },
    [CARTRIDGE.greenDark]: {
        [CARTRIDGE_STATE.insertedSide]: GreenDarkInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GreenDarkInsertedTop,
        [CARTRIDGE_STATE.face]: GreenDarkFace,
        [CARTRIDGE_STATE.isometric]: GreenDarkIsometric,
        color: theme.verascopeColor.green100,
    },
    [CARTRIDGE.greenRegular]: {
        [CARTRIDGE_STATE.insertedSide]: GreenRegularInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GreenRegularInsertedTop,
        [CARTRIDGE_STATE.face]: GreenRegularFace,
        [CARTRIDGE_STATE.isometric]: GreenRegularIsometric,
        color: theme.verascopeColor.green200,
    },
    [CARTRIDGE.greenLight]: {
        [CARTRIDGE_STATE.insertedSide]: GreenLightInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: GreenLightInsertedTop,
        [CARTRIDGE_STATE.face]: GreenLightFace,
        [CARTRIDGE_STATE.isometric]: GreenLightIsometric,
        color: theme.verascopeColor.green300,
    },
    [CARTRIDGE.orangeDark]: {
        [CARTRIDGE_STATE.insertedSide]: OrangeDarkInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: OrangeDarkInsertedTop,
        [CARTRIDGE_STATE.face]: OrangeDarkFace,
        [CARTRIDGE_STATE.isometric]: OrangeDarkIsometric,
        color: theme.verascopeColor.orange100,
    },
    [CARTRIDGE.orangeRegular]: {
        [CARTRIDGE_STATE.insertedSide]: OrangeRegularInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: OrangeRegularInsertedTop,
        [CARTRIDGE_STATE.face]: OrangeRegularFace,
        [CARTRIDGE_STATE.isometric]: OrangeRegularIsometric,
        color: theme.verascopeColor.orange200,
    },
    [CARTRIDGE.orangeLight]: {
        [CARTRIDGE_STATE.insertedSide]: OrangeLightInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: OrangeLightInsertedTop,
        [CARTRIDGE_STATE.face]: OrangeLightFace,
        [CARTRIDGE_STATE.isometric]: OrangeLightIsometric,
        color: theme.verascopeColor.orange300,
    },
    [CARTRIDGE.redDark]: {
        [CARTRIDGE_STATE.insertedSide]: RedDarkInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: RedDarkInsertedTop,
        [CARTRIDGE_STATE.face]: RedDarkFace,
        [CARTRIDGE_STATE.isometric]: RedDarkIsometric,
        color: theme.verascopeColor.red100,
    },
    [CARTRIDGE.redRegular]: {
        [CARTRIDGE_STATE.insertedSide]: RedRegularInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: RedRegularInsertedTop,
        [CARTRIDGE_STATE.face]: RedRegularFace,
        [CARTRIDGE_STATE.isometric]: RedRegularIsometric,
        color: theme.verascopeColor.red200,
    },
    [CARTRIDGE.redLight]: {
        [CARTRIDGE_STATE.insertedSide]: RedLightInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: RedLightInsertedTop,
        [CARTRIDGE_STATE.face]: RedLightFace,
        [CARTRIDGE_STATE.isometric]: RedLightIsometric,
        color: theme.verascopeColor.red300,
    },
    [CARTRIDGE.yellowDark]: {
        [CARTRIDGE_STATE.insertedSide]: YellowDarkInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: YellowDarkInsertedTop,
        [CARTRIDGE_STATE.face]: YellowDarkFace,
        [CARTRIDGE_STATE.isometric]: YellowDarkIsometric,
        color: theme.verascopeColor.yellow100,
    },
    [CARTRIDGE.yellowRegular]: {
        [CARTRIDGE_STATE.insertedSide]: YellowRegularInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: YellowRegularInsertedTop,
        [CARTRIDGE_STATE.face]: YellowRegularFace,
        [CARTRIDGE_STATE.isometric]: YellowRegularIsometric,
        color: theme.verascopeColor.yellow200,
    },
    [CARTRIDGE.yellowLight]: {
        [CARTRIDGE_STATE.insertedSide]: YellowLightInsertedSide,
        [CARTRIDGE_STATE.insertedTop]: YellowLightInsertedTop,
        [CARTRIDGE_STATE.face]: YellowLightFace,
        [CARTRIDGE_STATE.isometric]: YellowLightIsometric,
        color: theme.verascopeColor.yellow300,
    },
};

export default CARTRIDGE_TYPE;
