// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeCreateCustomer = async ({
    test,
    email,
    name,
    userId,
}: {
    test: boolean,
    email: string,
    name: string,
    userId: string,
}): Promise<Stripe.Customer> => {
    const functions = getFunctions();
    const createCustomer = httpsCallable<
        {
            test: boolean,
            email: string,
            name: string,
            userId: string,
        },
        Stripe.Customer
    >(
        functions,
        'stripeCreateCustomer',
    );

    return createCustomer({
        test,
        email,
        name,
        userId,
    }).then((result) => {
        const customer = result.data as Stripe.Customer;
        return customer;
    }).catch((err) => err);
};

export default stripeCreateCustomer;
