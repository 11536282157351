// ===== Packages =====

import {
    Node,
    Element,
}                           from 'slate';

// ===== Elements =====

import FigureElement        from './figureElement';

// ===== Interfaces =====

import {
    EditorNode,
    GalleryNode,
    FigureNode,
}                           from '../../interfaces';

// ===== Enums =====

import {
    FIGURE_ORIENTATION,
}                           from './enums';

const BLOCK_GALLERY_TYPE = 'gallery';

const isGalleryElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_GALLERY_TYPE;

const newGalleryElement = (
    existingFigure: FigureNode,
    id: string,
    newUrl?: string,
    newFilePath?: string,
): GalleryNode => ({
    id,
    type: BLOCK_GALLERY_TYPE,
    children: [
        { ...existingFigure, orientation: FIGURE_ORIENTATION.left },
        FigureElement.newFigureElement({
            id,
            url: newUrl,
            filePath: newFilePath,
            orientation: FIGURE_ORIENTATION.right,
        }),
    ],
});

export default {
    TYPE: BLOCK_GALLERY_TYPE,
    isGalleryElement,
    newGalleryElement,
};
