// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import {
    CURSOR_Z_INDEX,
}                                   from '../../constants/generalConstants';
import FONT_TYPE                    from '../../constants/fontType';

const CART_ICON_CONTAINER_PADDING = 5;

interface ContainerProps {
    lightBackground?: boolean,
}
export const Container = styled.div<ContainerProps>`
    position: fixed;
    top: 7.5px;
    right: 0;
    z-index: ${CURSOR_Z_INDEX - 2};
    padding: 2.5px;
    margin-right: 10px;
    background: ${({ lightBackground, theme }) => (lightBackground
        ? theme.color.white
        : 'transparent'
    )};
    border-radius: 20px;
`;

interface CartIconContainerProps {
    dark?: boolean,
    height: number,
    detectTouchDevice: boolean,
    lightBackground?: boolean,
}
export const CartIconContainer = styled.div<CartIconContainerProps>`
    width: ${({ height }) => `${height + 2 * CART_ICON_CONTAINER_PADDING}px`};
    height: ${({ height }) => `${height + 2 * CART_ICON_CONTAINER_PADDING}px`};
    border-radius: ${({ height }) => `${(height + 2 * CART_ICON_CONTAINER_PADDING) / 2}px`};
    padding: ${`${CART_ICON_CONTAINER_PADDING}px`};
    background: ${({ lightBackground, theme }) => (lightBackground
        ? theme.color.white
        : 'transparent'
    )};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    
    & svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    & path, & rect {
        fill: ${({ theme, dark }) => (dark
        ? theme.verascopeColor.purple200
        : theme.color.white
    )};
        transform: scale(1);
        transform-origin: 50% 50% 0;
    }

    &:hover path, &:hover rect {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            fill: ${theme.verascopeColor.yellow200};
            transform: scale(1.5);
        `)}
    }
`;

interface CartBadgeProps {
    count: number,
    transitionDuration: number,
}
export const CartBadge = styled.div<CartBadgeProps>`
    position: absolute;
    top: -1px;
    right: -3px;
    padding: 4px;
    padding-right: 2.5px;
    padding-bottom: 2.5px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: 0.7em;
    color: ${({ theme }) => theme.verascopeColor.white};
    background: ${({ theme }) => theme.verascopeColor.red200};
    --cartItemCounter: ${({ count }) => count};
    counter-reset: cartItemCounter var(--cartItemCounter);
    pointer-events: none;
    transition: ${({ transitionDuration }) => `--cartItemCounter ${transitionDuration}ms`};

    &::after {
        content: counter(cartItemCounter);
    }
`;
