// ===== Packages =====

import {
    Node,
    Element,
}                       from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    DividerNode,
}                       from '../interfaces';

const BLOCK_DIVIDER_TYPE = 'divider';

const isDividerElement = (node: EditorNode | Node): boolean => {
    if (!Element.isElement(node)) { return false; }

    const hasDividerType = 'type' in node && node.type === BLOCK_DIVIDER_TYPE;

    return hasDividerType;
};

const newDividerElement = (): DividerNode => ({
    type: BLOCK_DIVIDER_TYPE,
    children: [{ text: '' }],
});

export default {
    TYPE: BLOCK_DIVIDER_TYPE,
    isDividerElement,
    newDividerElement,
};
