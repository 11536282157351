const formatNumber = (num: number): string => {
    if (!num) {
        return num.toString();
    }

    const number = Number(num);
    if (number >= 1000 && number < 1000000) {
        const arr = number.toFixed().split('');

        return `${(number / 1000).toFixed((arr.length < 6 && arr[2] !== '0') ? 1 : 0)}k`;
    }

    if (number >= 1000000) {
        const arr = number.toFixed().split('');

        return `${(number / 1000000).toFixed((arr.length < 9 && arr[2] !== '0') ? 1 : 0)}m`;
    }

    return number.toString();
};

export default formatNumber;
