export const MAX_FADE_IN_STAGGER_TRANSITION_DURATION = 500;
export const PIVOT_TRANSITION_DURATION = 150;
export const BODY_TRANSITION_DURATION = 150;
export const LINK_CARD_TRANSITION_DURATION = 150;
export const GROUP_EXPAND_TRANSITION = 150;
export const TOOLTIP_TRANSITION_DURATION = 150;
export const TOOL_TRANSITION_DURATION = 50;
export const TOOL_MARGIN_LEFT = 5;
export const MAX_TOOLS_PER_ROW = 7;
export const LINK_CARD_MAX_TITLE_COUNT = 30;
export const LINK_CARD_BUTTON_HEIGHT = 25;
export const TOOL_GROUPS_COUNT = 4;
export const TOOL_GROUP_MARGIN = 5;
export const TOOL_GROUP_PADDING = 5;
export const TOOL_MARGIN = 2.5;
export const GROUP_TOOL_MARGIN_LEFT_ADDITION = 2.5;
export const LINK_CARD_IMAGE_HEIGHT = 90;
export const LINK_CARD_IMAGE_PADDING = 10;
export const LINK_CARD_WITH_METADATA_HEIGHT = LINK_CARD_IMAGE_HEIGHT + (2 * LINK_CARD_IMAGE_PADDING);
export const LINK_CARD_WITHOUT_METADATA_HEIGHT = 85;
export const LINK_CARD_IMAGE_WIDTH = 100;
export const LINK_CARD_INFO_WIDTH = 150;
export const LINK_CARD_WITH_METADATA_WIDTH = LINK_CARD_IMAGE_WIDTH + LINK_CARD_INFO_WIDTH + (2 * LINK_CARD_IMAGE_PADDING);
export const LINK_CARD_WITHOUT_METADATA_WIDTH = 280;
export const HOVER_CARD_TRANSITION_DURATION = 150;
