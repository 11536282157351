// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> prepareDailyDigest
export enum CHARACTER_ROLE_TYPE {
    founder = 'Founder',
    artist = 'Technical Concept Artist',
    mathematician = 'Applied Mathematician',
    mlEngineer = 'ML Engineer',
    gameDesigner = 'Game Designer',
    soundDesigner = 'Songwriter / Sound Designer',
    hciResearcher = 'HCI Researcher',
    storyteller = 'Storyteller / Worldbuilder',
    gameDeveloper = 'Game Developer',
}
