// ===== Packages =====

import {
    Node,
    Element,
}                           from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    LineNode,
}                           from '../interfaces';

import LineInterface        from '../interfaces/lineInterface';
import ListableInterface    from '../interfaces/listableInterface';

const BLOCK_LINE_TYPE = 'line';

const isLineElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_LINE_TYPE;

const newLineElement = (text = ''): LineNode => ({
    type: BLOCK_LINE_TYPE,
    isLine: true,
    isListable: true,
    children: [{ text }],
});

export default {
    TYPE: BLOCK_LINE_TYPE,
    isLineElement,
    ...LineInterface,
    ...ListableInterface,
    newLineElement,
};
