// ===== Constants =====

import EmbeddingDimensions  from '../constants/embeddingDimensions';

const getEmbeddingDimensions = (parentWidth: number): number => {
    let currentDimension = 240;
    const sizes: string[] = Object.keys(EmbeddingDimensions);
    sizes.forEach((size: string) => {
        if (
            EmbeddingDimensions[size].width > EmbeddingDimensions[currentDimension].width
            && EmbeddingDimensions[size].width <= parentWidth
        ) {
            currentDimension = parseInt(size, 10);
        }
    });
    return currentDimension;
};

export default getEmbeddingDimensions;
