// ===== Packages =====

import {
    Node,
    Element,
}                       from 'slate';

// ===== Enums =====

import {
    EditorNode,
    ListItemNode,
}                       from '../../interfaces';

const BLOCK_LIST_ITEM_TYPE = 'list-item';

const isListItemElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_LIST_ITEM_TYPE;

const newListItemElement = (): ListItemNode => ({
    type: BLOCK_LIST_ITEM_TYPE,
    children: [],
});

export const UNWRAP_ITEM = 'unwrap_list_item';
export const WRAP_ITEM = 'wrap_list_item';
export default {
    TYPE: BLOCK_LIST_ITEM_TYPE,
    isListItemElement,
    newListItemElement,
};
