// ===== Packages =====

import {
    Node,
    Element,
}                           from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    BlockQuoteNode,
}                           from '../interfaces';
import LineInterface        from '../interfaces/lineInterface';
import ListableInterface    from '../interfaces/listableInterface';

const BLOCK_BLOCK_QUOTE_TYPE = 'block-quote';

const isBlockQuoteElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_BLOCK_QUOTE_TYPE;

const newBlockQuoteElement = (text = ''): BlockQuoteNode => ({
    type: BLOCK_BLOCK_QUOTE_TYPE,
    isLine: true,
    isListable: true,
    children: [{ text }],
});

export default {
    TYPE: BLOCK_BLOCK_QUOTE_TYPE,
    isBlockQuoteElement,
    ...LineInterface,
    ...ListableInterface,
    newBlockQuoteElement,
};
