// ===== Packages =====

import { v4 as uuidv4 }                             from 'uuid';

// ===== Interfaces =====

import {
    ISocialEmergenceNode,
    ISocialEmergenceConnection,
    ISocialEmergenceTheoryAggregatedTrustProps,
    ISocialEmergenceTheoryAverageTrustProps,
    ISocialEmergenceTheoryMutualTrustProps,
    ISocialEmergenceTheoryNormalizeTrustProps,
    ISocialEmergenceTheorySocialCapitalProps,
    ISocialEmergenceTheorySocialCapitalRankProps,
    ISocialEmergenceTheorySocialCapitalRatioProps,
    ISocialEmergenceTheorySocialCohesionProps,
    ISocialEmergenceTheoryIndirectConnectionProps,
}                                                   from '../interfaces';

// ===== Services =====

import {
    yensAlgorithm,
}                                                   from '.';

// ===== Enums =====

import {
    SOCIAL_EMERGENCE_CONNECTION_TYPE,
}                                                   from '../enums';

const CRITICAL_INFLUENCE_DISTANCE = 3;
const SOCIAL_CAPITAL_CONVERGENCE_THRESHOLD = 0.001;
const MAX_SOCIAL_CAPITAL_ITERATIONS = 10;
const K_SHORTEST_PATH_COUNT = 3;

/**
 * Updates social capital and rank properties of input agents
 * @param agents array of agents
 * @returns array of agents with updated social capital and rank properties
 */
const computeSocialCapitalAndRank = ({
    agents,
}: ISocialEmergenceTheorySocialCapitalRankProps): ISocialEmergenceNode[] => {
    let updatedAgents: ISocialEmergenceNode[] = [];
    const predecessors: ISocialEmergenceNode[][] = agents.map(() => []);

    agents.forEach((agent: ISocialEmergenceNode) => {
        // 1. initialize each agent with equal social capital
        updatedAgents.push({
            ...agent,
            socialCapitalRatio: 1 / agents.length,
        });
    });

    updatedAgents.forEach((agent: ISocialEmergenceNode) => {
        Array.from(agent.connections.values()).forEach((connection: ISocialEmergenceConnection) => {
            // 2. Populate predecessors map
            predecessors[connection.targetIndex].push(agent);
        });
    });

    // 3. Update social capital values
    updatedAgents = calculateSocialCapitalRatio({
        prevAgents: updatedAgents,
        predecessors,
        iterationCount: 0,
    });

    // 4. Determine rank of agents
    const orderedAgents = [...updatedAgents];
    // sort agents in descending order
    orderedAgents.sort((a: ISocialEmergenceNode, b: ISocialEmergenceNode) => {
        if (
            a.socialCapitalRatio === null
            || b.socialCapitalRatio === null
        ) {
            throw Error('Encountered null social capital value while ranking updated social capital values.');
        }

        return b.socialCapitalRatio! - a.socialCapitalRatio!;
    });
    // assign agent ranks
    let currentRank = 0;
    orderedAgents.forEach((orderedAgent: ISocialEmergenceNode, index: number) => {
        if (
            index !== 0
            && orderedAgent.socialCapitalRatio === orderedAgents[index - 1].socialCapitalRatio
        ) {
            // agents with same ratio should have the same rank
            updatedAgents[orderedAgent.index].rank = updatedAgents[orderedAgents[index - 1].index].rank;
        } else {
            updatedAgents[orderedAgent.index].rank = currentRank;
            currentRank += 1;
        }
    });

    return updatedAgents;
};

/**
 * Helper function used to perform recursive calculation of social capital
 * values until the iteration convergence threshold is met
 * @param prevAgents array of agents with initialized social capital values
 * @param predecessors array with an array of predecessors associated with the agent at each index
 * @param iterationCount maximum number of iterations to peform if convergence threshold isn't met
 * @returns array of agents with updated social capital properties
 */
const calculateSocialCapitalRatio = ({
    prevAgents,
    predecessors,
    iterationCount,
}: ISocialEmergenceTheorySocialCapitalRatioProps): ISocialEmergenceNode[] => {
    // run iteration of social capital calculation
    const newAgents: ISocialEmergenceNode[] = [];
    prevAgents.forEach((agent: ISocialEmergenceNode, agentIndex: number) => {
        let socialCapitalRatio = 0;
        // calculate social capital ratio
        predecessors[agentIndex].forEach((predecessor: ISocialEmergenceNode) => {
            if (!predecessor.connections.get(agentIndex)) {
                throw Error('Unable to obtain target node reference while computing social capital.');
            }
            const normalizedWeight = normalizeTrust({
                agents: [predecessor],
                trust: predecessor.connections.get(agentIndex)!.weight,
            });
            const normalizedTrustSum = computeAggregatedTrust({
                agents: [predecessor],
                normalized: true,
            });

            if (prevAgents[predecessor.index].socialCapitalRatio === null) {
                throw Error('Encountered null prior social capital value while computing updated social capital.');
            }

            if (normalizedTrustSum !== 0) {
                socialCapitalRatio += (normalizedWeight / normalizedTrustSum) * prevAgents[predecessor.index].socialCapitalRatio!;
            }
        });

        // calculate and store social capital
        const ratio = socialCapitalRatio;
        newAgents.push({
            ...agent,
            socialCapitalRatio: ratio,
        });
    });

    // normalize in case zero social capital contributions
    // these tend to throw ratio out of whack
    // no longer adds up to 1
    let socialCapitalRatioSum = 0;
    newAgents.forEach((a: ISocialEmergenceNode) => {
        socialCapitalRatioSum += a.socialCapitalRatio!;
    });
    const normalizedNewAgents: ISocialEmergenceNode[] = [];
    newAgents.forEach((a: ISocialEmergenceNode) => {
        normalizedNewAgents.push({
            ...a,
            socialCapitalRatio: socialCapitalRatioSum !== 0
                ? a.socialCapitalRatio! / socialCapitalRatioSum
                : a.socialCapitalRatio!,
        });
    });

    // determine if another iteration is needed
    const aggregatedTrust = computeAggregatedTrust({
        agents: normalizedNewAgents,
        normalized: true,
    });
    let performAdditionalIteration = false;
    let numZeroSocialCapitalRatio = 0;
    for (let i = 0; i < normalizedNewAgents.length; i += 1) {
        const normalizedAgent = normalizedNewAgents[i];
        if (
            normalizedAgent.socialCapitalRatio === null
            || prevAgents[i].socialCapitalRatio === null
        ) {
            throw Error('Encountered null social capital values while calculating updated social capital values.');
        }
        const newSocialCapitalRatio = normalizedAgent.socialCapitalRatio as number;
        const prevSocialCapitalRatio = prevAgents[i].socialCapitalRatio as number;
        const newSocialCapital = aggregatedTrust * newSocialCapitalRatio;
        const prevSocialCapital = aggregatedTrust * prevSocialCapitalRatio;
        if (Math.abs(newSocialCapital - prevSocialCapital) > SOCIAL_CAPITAL_CONVERGENCE_THRESHOLD) {
            performAdditionalIteration = true;
        }
        if (newSocialCapitalRatio === 0) numZeroSocialCapitalRatio += 1;
    }

    if (
        performAdditionalIteration && iterationCount < MAX_SOCIAL_CAPITAL_ITERATIONS
        && numZeroSocialCapitalRatio === 0
    ) {
        return calculateSocialCapitalRatio({
            prevAgents: newAgents,
            predecessors,
            iterationCount: iterationCount + 1,
        });
    }

    // if (
    //     performAdditionalIteration
    //     && iterationCount >= MAX_SOCIAL_CAPITAL_ITERATIONS
    // ) {
    //     throw Error('Encountered max social capital iteration count. Expected convergence.');
    // }

    return normalizedNewAgents;
};

const computeSocialCapital = ({
    agents,
    ratio,
}: ISocialEmergenceTheorySocialCapitalProps): number => {
    const socialCapitalSum = computeAggregatedTrust({
        agents,
        normalized: true,
    });
    return socialCapitalSum * ratio;
};

/**
 * Calculates the mutual trust associated with two non-hierarchical agents
 * @param abTrust trust value from agent A to agent B
 * @param baTrust trust value from agent B to agent A
 * @returns mutual trust value of agents
 */
const computeNonHierarchicalMutualTrust = ({
    abTrust,
    baTrust,
}: ISocialEmergenceTheoryMutualTrustProps): number => {
    if (abTrust >= 0 && baTrust >= 0) {
        return abTrust * baTrust;
    }

    if (abTrust <= 0 && baTrust > 0) {
        return abTrust / baTrust;
    }

    if (abTrust > 0 && baTrust <= 0) {
        return baTrust / abTrust;
    }

    return -1 * abTrust * baTrust;
};

/**
 * Calculates
 * @param agents array of agents with trust values associated
 * with each agent pair (augmented with indirect and stranger connections)
 * @returns social cohesion value of agents
 */
const computeSocialCohesion = ({
    agents,
}: ISocialEmergenceTheorySocialCohesionProps): number => {
    let aggregatedTrust = 0;
    agents.forEach((agentA: ISocialEmergenceNode) => {
        const agentAIndex = agentA.index;
        agents.forEach((agentB: ISocialEmergenceNode) => {
            const agentBIndex = agentB.index;
            if (agentAIndex !== agentBIndex) {
                const abConnection = agentA.connections.get(agentBIndex);
                const baConnection = agentB.connections.get(agentAIndex);
                if (!abConnection || !baConnection) {
                    throw Error('Unable to obtain target connections reference while computing social cohesion.');
                }
                const abTrust = abConnection.weight;
                const baTrust = baConnection.weight;

                const mutualTrust = computeNonHierarchicalMutualTrust({
                    abTrust,
                    baTrust,
                });

                aggregatedTrust += mutualTrust;
            }
        });
    });

    return aggregatedTrust / agents.length;
};

/**
 * Computes the aggregated trust of a collection of agents
 * @param agents array of agents with trust values associated
 * with each agent pair (augmented with indirect and stranger connections)
 * @returns aggregated trust value of input agents
 */
const computeAggregatedTrust = ({
    agents,
    normalized,
}: ISocialEmergenceTheoryAggregatedTrustProps): number => {
    let aggregatedTrust = 0;
    if (normalized) {
        const trustValues: number[] = [];
        agents.forEach((agent: ISocialEmergenceNode) => {
            Array.from(agent.connections.values()).forEach((connection: ISocialEmergenceConnection) => {
                trustValues.push(connection.weight);
            });
        });
        const minTrustVal = Math.min(...trustValues);
        const maxTrustVal = Math.max(...trustValues);
        let normalizedTrustValues = [...trustValues];

        if (minTrustVal < 0) {
            normalizedTrustValues = trustValues.map((trustVal: number) => {
                if (maxTrustVal - minTrustVal !== 0) {
                    // avoid divide by zero
                    return (
                        (trustVal - minTrustVal)
                        * (Math.abs(minTrustVal) + Math.abs(maxTrustVal))
                    ) / (maxTrustVal - minTrustVal);
                }

                // if min and max are the same, then all values between are the same as well
                // we shift them all to zero
                return 0;
            });
        }

        aggregatedTrust = normalizedTrustValues.reduce((sum: number, val: number) => sum + val, 0);
    } else {
        agents.forEach((agent: ISocialEmergenceNode) => {
            Array.from(agent.connections.values()).forEach((connection: ISocialEmergenceConnection) => {
                aggregatedTrust += connection.weight;
            });
        });
    }

    return aggregatedTrust;
};

/**
 * Normalizes the trust value based on trust values in array of agents
 * @param agents array of agents with trust values associated
 * @param trust value to normalize
 * @returns normalized trust value
 */
const normalizeTrust = ({
    agents,
    trust,
}: ISocialEmergenceTheoryNormalizeTrustProps): number => {
    const trustValues: number[] = [];
    agents.forEach((agent: ISocialEmergenceNode) => {
        Array.from(agent.connections.values()).forEach((connection: ISocialEmergenceConnection) => {
            trustValues.push(connection.weight);
        });
    });
    const minTrustVal = Math.min(...trustValues);
    const maxTrustVal = Math.max(...trustValues);
    if (minTrustVal < 0) {
        if (maxTrustVal - minTrustVal !== 0) {
            // avoid divide by zero
            return (
                (trust - minTrustVal)
                * (Math.abs(minTrustVal) + Math.abs(maxTrustVal))
            ) / (maxTrustVal - minTrustVal);
        }

        // if min and max are the same, then all values between are the same as well
        // we shift them all to zero
        return 0;
    }

    return trust;
};

/**
 * Computes the average trust of a collection of agents
 * @param agents array of agents with trust values associated
 * with each agent pair (augmented with indirect and stranger connections)
 * @returns average trust value of input agents
 */
const computeAverageTrust = ({
    agents,
    normalized,
}: ISocialEmergenceTheoryAverageTrustProps): number => {
    const aggregatedTrust = computeAggregatedTrust({
        agents,
        normalized,
    });
    const numConnections = agents.reduce((sum: number, agent: ISocialEmergenceNode) => {
        let connections = 0;
        Array.from(agent.connections.values()).forEach(() => {
            connections += 1;
        });
        return sum + connections;
    }, 0);
    return aggregatedTrust / numConnections;
};

/**
 * Searches for the shortest length trusted path between a source node and target node
 * @param agents array of agents
 * @param graph n by n weight matrix of agents, reflecting personal and parasocial connections
 * @param sourceIndex index of source node in graph
 * @param targetIndex index of target node in graph
 * @returns indirect connection
 */
const getIndirectConnection = ({
    agents,
    graph,
    sourceIndex,
    targetIndex,
}: ISocialEmergenceTheoryIndirectConnectionProps): ISocialEmergenceConnection | undefined => {
    const SOURCE_NODE_PATH_LENGTH_CONTRIBUTION = 1;
    const TARGET_NODE_PATH_LENGTH_CONTRIBUTON = 1;
    const n = graph.length;
    const graphCopy = [...graph.map((arr: (number | null)[]) => [...arr])];

    // determine if trust through social proof available
    let result = yensAlgorithm({
        graph,
        sourceIndex,
        targetIndex,
        k: K_SHORTEST_PATH_COUNT,
    });

    if (result.cycle) {
        // cycle detected
        // remove a culprit edge from graph until we no longer detect a cycle
        let iterations = 0;
        // shouldn't have to do this more than n * n times, because
        // there would be no more edges in the graph
        while (result.cycle && iterations < n * n) {
            // remove largest weight edge if shortest path
            let startIndex = 0;
            let endIndex = 1;
            let bestWeight = graphCopy[result.cycle.path[startIndex]][result.cycle.path[endIndex]] as number;
            for (let i = 1; i < result.cycle.path.length - 1; i += 1) {
                const nextWeight = graphCopy[result.cycle.path[i]][result.cycle.path[i + 1]];
                if (
                    nextWeight !== null
                    && nextWeight < bestWeight
                ) {
                    startIndex = i;
                    endIndex = i + 1;
                    bestWeight = nextWeight;
                }
            }
            graphCopy[result.cycle.path[startIndex]][result.cycle.path[endIndex]] = null;
            result = yensAlgorithm({
                graph: graphCopy,
                sourceIndex,
                targetIndex,
                k: K_SHORTEST_PATH_COUNT,
            });
            iterations += 1;
        }

        if (result.cycle) {
            throw Error(
                `Unable to determine indirect connections because persistent cycle encountered: Cycle = ${
                    JSON.stringify(result.cycle, null, 4)
                }`,
            );
        }
    }

    // get all paths within indirect connection region
    const validPaths: number[][] = [];
    result.paths.forEach((path: number[]) => {
        if (path.length <= CRITICAL_INFLUENCE_DISTANCE
            + (SOURCE_NODE_PATH_LENGTH_CONTRIBUTION + TARGET_NODE_PATH_LENGTH_CONTRIBUTON)) {
            validPaths.push(path);
        }
    });
    if (validPaths.length === 0) return undefined;

    // determine if shortest path only includes positive weight edges before target index
    let trustedPathIndex: number | null = null;
    for (let i = 0; i < validPaths.length; i += 1) {
        const currentShortestPath = validPaths[i];
        for (let j = 0; j < currentShortestPath.length - 2; j += 1) { // -1 so we don't check final edge
            const startIndex = currentShortestPath[j];
            const endIndex = currentShortestPath[j + 1];
            const connection = agents[startIndex].connections.get(endIndex);
            const antiParallelConnection = agents[endIndex].connections.get(startIndex);
            if (!connection) {
                throw Error(`Expected a connection between nodes ${
                    startIndex
                } and ${
                    endIndex
                } while searching for trusted path to form an indirect connection between ${
                    sourceIndex
                } and ${
                    targetIndex
                }.`);
            } else if (
                (
                    connection.type === SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                    && (
                        !antiParallelConnection
                        || antiParallelConnection.weight < 0
                    )
                )
                || connection.weight < 0
            ) {
                break;
            } else if (j === currentShortestPath.length - 3) { // -3 because -2 is never shortestPath.length - 1
                trustedPathIndex = i;
                break;
            }
        }

        if (trustedPathIndex !== null) break;
    }

    if (trustedPathIndex === null) return undefined;

    const shortestPath = validPaths[trustedPathIndex];
    const influencerIndex = shortestPath[shortestPath.length - 2];
    const indirectConnection = agents[influencerIndex].connections.get(targetIndex);
    if (!indirectConnection) {
        throw Error(`Encountered an influencer without connection to agent identified indirect connection: Influencer Index = ${influencerIndex}, Target Index = ${targetIndex}`);
    }
    const outsourcedTrustWeight = (
        (
            (CRITICAL_INFLUENCE_DISTANCE + TARGET_NODE_PATH_LENGTH_CONTRIBUTON)
            - (shortestPath.length - (SOURCE_NODE_PATH_LENGTH_CONTRIBUTION + TARGET_NODE_PATH_LENGTH_CONTRIBUTON))
        ) / (CRITICAL_INFLUENCE_DISTANCE + TARGET_NODE_PATH_LENGTH_CONTRIBUTON)
    ) * indirectConnection.weight;

    return {
        id: uuidv4(),
        sourceIndex,
        targetIndex,
        weight: outsourcedTrustWeight,
        type: SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect,
    };
};

export default {
    computeSocialCapitalAndRank,
    computeSocialCapital,
    computeNonHierarchicalMutualTrust,
    computeSocialCohesion,
    computeAggregatedTrust,
    computeAverageTrust,
    getIndirectConnection,
    CRITICAL_INFLUENCE_DISTANCE,
};
