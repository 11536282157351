// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> prepareDailyDigest
export enum USER_ACTION_TYPE {
    // General
    clickHomeButton = 'click-home-button', // payload: route
    clickCartButton = 'click-cart-button', // payload: route
    clickBreadcrumbButton = 'click-breadcrumb-button', // payload: route
    turnOffSound = 'turn-off-sound',
    turnOnSound = 'turn-on-sound',
    viewProfile = 'view-profile',
    hideProfile = 'hide-profile',
    signUpProfile = 'sign-up-profile', // payload: authenticationType
    signInProfile = 'sign-in-profile',
    signOutProfile = 'sign-out-profile',
    viewTwitter = 'view-twitter',
    composeEmail = 'compose-email',
    error = 'error', // payload: error
    errorResetPage = 'error-reset-page',
    // Pages
    viewLandingPage = 'view-landing-page',
    viewAboutPage = 'view-about-page',
    viewCharactersPage = 'view-characters-page',
    viewTreasureChestPage = 'view-treasure-chest-page',
    viewReadPage = 'view-read-page', // payload: id
    viewPhysicalBookPage = 'view-physical-book-page',
    viewUnsubscribePage = 'view-unsubscribe-page',
    viewUnsubscribeResultPage = 'view-unsubscribe-result-page',
    viewSocialEmergenceTheoryPage = 'view-social-emergence-page',
    viewCartPage = 'view-cart-page',
    viewCheckoutPage = 'view-checkout-page',
    // Notification Request
    submitNotificationRequest = 'submit-notification-request', // payload: request
    // Landing Page
    grabJellyLogo = 'grab-jelly-logo',
    revealLandingPageContent = 'reveal-landing-page-content',
    hideLandingPageContent = 'hide-landing-page-content',
    grabLandingPageInteractableObject = 'grab-landing-page-interactable-object', // payload: objectType
    clickCuneiformTablet = 'click-cuneiform-tablet',
    // Characters Page
    selectCharacter = 'select-character', // payload: character
    viewCharacterInfo = 'view-character-info', // payload: character
    clickCharacterInfoProfileButton = 'click-character-info-profile-button', // payload: character, buttonType
    applyCharacterRole = 'apply-character-role', // payload: character
    // Treasure Chest Page
    insertCartridge = 'insert-cartridge', // payload: cartridge
    ejectCartridge = 'ejectCartridge', // payload: cartridge
    filterCartridges = 'filter-cartridges', // payload: filters
    openCartridgeLink = 'open-cartridge-link', // payload: cartridge, href
    clickCartridgeVimeo = 'click-cartridge-vimeo', // payload: cartridge, videoType, url
    playCartridgeYouTube = 'play-cartridge-youtube', // payload: cartridge, videoType, url
    pauseCartridgeYouTube = 'pause-cartridge-youtube', // payload: cartridge, videoType, url
    endCartridgeYouTube = 'end-cartridge-youtube', // payload: cartridge, videoType, url
    adjustTreasureChestSplitView = 'adjust-treasure-chest-split-view',
    // Read Page
    viewPost = 'view-post', // payload: postId
    viewPostChapter = 'view-post-chapter', // payload: postId, chapterId
    viewPostChapterSection = 'view-post-chapter-section', // payload: postId, chapterId, sectionId
    createAnnotation = 'create-annotation', // payload: annotationId
    editInitiateAnnotation = 'edit-initiate-annotation', // payload: annotationId
    editSaveAnnotation = 'edit-save-annotation', // payload: annotationId
    editCancelAnnotation = 'edit-cancel-annotation', // payload: annotationId
    deleteAnnotation = 'delete-annotation', // payload: annotationId
    openAnnotation = 'open-annotation', // payload: annotationId
    closeAnnotation = 'close-annotation', // payload: annotationId
    getLinkAnnotation = 'get-link-annotation', // payload: annotationId
    toggleAnnotationStack = 'toggle-annotation-stack', // payload: expanded
    adjustPostSplitView = 'adjust-post-split-view',
    clickInlineLink = 'click-inline-link', // payload: id, href, postId
    playAudioNote = 'play-audio-note', // payload: id, editorType, postId
    pauseAudioNote = 'pause-audio-note', // payload: id, editorType, postId
    skipBackwardAudioNote = 'skip-backward-audio-note', // payload: id, editorType, postId
    skipForwardAudioNote = 'skip-forward-audio-note', // payload: id, editorType, postId
    adjustAudioNotePlaybackSpeed = 'adjust-audio-note-playback-speed', // payload: id, speed, editorType, postId
    toggleAudioNoteTranscript = 'toggle-audio-note-transcript', // payload: visible, id, editorType, postId
    scrubAudioNoteProgressBar = 'scrub-audio-note-progress-bar', // payload: id, editorType, postId
    scrubAudioNoteTranscriptWord = 'scrub-audio-note-transcript-word', // payload: id, word, editorType, postId
    playYouTubeVideo = 'play-youtube-video', // payload: id, editorType, postId
    pauseYouTubeVideo = 'pause-youtube-video', // payload: id, editorType, postId
    endYoutubeVideo = 'end-youtube-video', // payload: id, editorType, postId
    clickTweet = 'click-tweet', // payload: id, editorType, postId
    clickVimeoVideo = 'click-vimeo-video', // payload: id, editorType, postId
    startAnnotationDictation = 'startAnnotationDictation', // payload: id
    stopAnnotationDictation = 'stopAnnotationDictation', // payload: id
    finishAnnotationDictation = 'finishAnnotationDictation', // payload: id
    reactToAnnotation = 'react-to-annotation', // payload: id, reactionType, editorType
    sharePost = 'share-post', // payload: postId, platform
    adjustPostResolution = 'adjust-post-resolution', // payload: postId, currentResolution
    toggleResolutionLevelContent = 'toggle-resolution-level-content', // payload: expanded, resolution, currentResolution
    toggleCollapsedPostChapter = 'expand-collapsed-post-section', // payload: postId, chapterId, expanded
    grabOutlineChapter = 'grab-outline-chapter',
    grabOutlineSection = 'grab-outline-section',
    addPostChapter = 'add-post-chapter', // payload: postId, chapterId,
    deletePostChapter = 'delete-post-chapter', // payload: postId, chapterId,
    movePostChapter = 'move-post-chapter', // payload: postId, chapterId, direction, units
    addPostChapterSection = 'add-post-chapter-section', // payload: postId, chapterId, sectionId
    deletePostChapterSection = 'delete-chapter-post-section', // payload: postId, chapterId, sectionId
    movePostChapterSection = 'move-post-chapter-section', // payload: postId, chapterId, sectionId, direction, units
    toggleContentsTable = 'toggle-contents-table', // payload: expanded
    copyTextHighlight = 'copy-text-highlight', // payload: postId, text
    presentedAnnotationNudgeMessage = 'presented-annotation-nudge-message', // payload: postId
    presentedPurchaseBookNudgeMessage = 'presented-purchase-book-nudge-message', // payload: postId
    presentedCreateAccountWebNudgeMessage = 'presented-create-account-web-nudge-message',
    presentedCreateAccountDigitalNudgeMessage = 'presented-create-account-digital-nudge-message',
    presentedCreateAccountPhysicalNudgeMessage = 'presented-create-account-physical-nudge-message',
    presentedCreateAccountNavigateNudgeMessage = 'presented-create-account-navigate-nudge-message',
    presentedCreateAccountAnnotationNudgeMessage = 'presented-create-account-annotation-nudge-message',
    selectAdventureCharacter = 'select-adventure-character', // payload: characterId
    readTenMinutes = 'read-ten-minutes', // payload: postId
    readThirtyMinutes = 'read-thirty-minutes', // payload: postId
    readOneHour = 'read-one-hour', // payload: postId
    // Unsubscribe Result Page
    clickUnsubscribeButton = 'click-unsubscribe-button',
    // Social Emergence Theory Page
    addAgentToSocialNetwork = 'add-agent-to-social-network', // payload: agentCount
    removeAgentFromSocialNetwork = 'remove-agent-from-social-network', // payload: agentCount
    modifyAgentName = 'modify-agent-name', // payload: agentIndex, previousName, newName
    modifyTrustValue = 'modify-trust-value', // payload: sourceIndex, targetIndex, previousWeight, newWeight
    switchGraphMode = 'switch-graph-mode', // payload: mode
    activateAssembleGroupMode = 'active-assemble-group-mode',
    deactivateAssembleGroupMode = 'deactivate-assemble-group-mode',
    addAgentToGroup = 'add-agent-to-group', // payload: agentIndex
    removeAgentFromGroup = 'remove-agent-from-group', // payload: agentIndex
    focusAgent = 'focus-agent', // payload: agentIndex
    resetNetwork = 'reset-network',
    // Cart Page
    addItemToCart = 'add-item-to-cart', // payload: type
    removeItemFromCart = 'remove-item-from-cart', // payload: type
    incrementItemQuantity = 'increment-cart-item-quantity', // payload: type
    decrementItemQuantity = 'decrement-cart-item-quantity', // payload: type
    emptyCart = 'empty-cart',
    // Checkout Page
    togglePromotionCode = 'toggle-promotion-code', // payload: expanded
    applyPromotionCode = 'apply-promotion-code', // payload: code
    removePromotionCodeBadge = 'remove-promotion-code-badge', // payload: promotionId
    toggleOrderSummary = 'toggle-order-summary', // payload: expanded
    selectSavedMailingAddress = 'select-saved-mailing-address',
    selectSavedBillingAddress = 'select-saved-billing-address',
    selectSavedStripePaymentMethod = 'select-saved-stripe-payment-method',
    clickEditCheckoutDetailsButton = 'click-edit-checkout-details-button', // to
    clickCheckoutBackButton = 'click-checkout-back-button', // to
    clickCheckoutActionButton = 'click-checkout-action-button', // to
    clickCheckoutBuyButton = 'click-checkout-buy-button',
    completeCheckoutPaymentDetails = 'complete-checkout-payment-details',
    selectCheckoutPaymentMethod = 'select-checkout-payment-method', // type
    viewCheckoutReceipt = 'view-checkout-receipt',
    viewTrackingWebsite = 'view-tracking-website',
}
