// ===== Interfaces =====

import { IKeyCode } from '../interfaces';

const KEYCODE: IKeyCode = {
    backspace: 'Backspace',
    tab: 'Tab',
    enter: 'Enter',
    shift: 'Shift',
    control: 'Control',
    alt: 'Alt',
    pause: 'Pause',
    capslock: 'CapsLock',
    escape: 'Escape',
    spacebar: ' ',
    pageup: 'PageUp',
    pagedown: 'PageDown',
    end: 'End',
    home: 'Home',
    arrowleft: 'ArrowLeft',
    arrowup: 'ArrowUp',
    arrowright: 'ArrowRight',
    arrowdown: 'ArrowDown',
    insert: 'Insert',
    delete: 'Delete',
    command: 'Meta',
    numlock: 'NumLock',
    scrolllock: 'ScrollLock',
};

export default KEYCODE;
