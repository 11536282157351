// ===== Packages =====

import styled       from 'styled-components';

// ===== General Constants =====

import {
    CURSOR_Z_INDEX,
}                   from '../../constants/generalConstants';

interface ModalContainerProps {
    color?: string,
    width?: number,
    isVisible: boolean,
}
export const ModalContainer = styled.div<ModalContainerProps>`
    width: ${({ width }) => (width
        ? `${width}px`
        : '600px'
    )};
    height: auto;
    max-height: 80%;
    background-color: ${({ color, theme }) => color || theme.verascopeColor.purple300};
    position: fixed;
    left: 50%;
    top: 0;
    will-change: transform;
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: ${CURSOR_Z_INDEX - 1};
    padding: 40px;
    box-sizing: border-box;
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    cursor: none;

    ${({ theme }) => theme.mediaQuery.small} {
        margin-left: 0;
        width: 600px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-left: 0;
        width: 350px;
    }
`;

interface ModallessContainerProps {
    isVisible: boolean,
}
export const ModallessContainer = styled.div<ModallessContainerProps>`
    position: fixed;
    left: 50%;
    top: 0;
    z-index: ${CURSOR_Z_INDEX - 1};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
`;
