// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                       from 'react';
import { ReactSVG }                     from 'react-svg';

// ===== Components =====

import {
    OutsideClickHandler,
}                                       from '../Editor/helpers';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
}                                       from '../../enums';

// ===== Services =====

import {
    playAudio,
    isLetter,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IDropdownItem,
}                                       from '../../interfaces';

// ===== Images =====

import PixelAsteriskIcon                from '../../images/8-bit_asterisk.svg';
import PixelCrossIcon                   from '../../images/8-bit_cross.svg';

// ===== Sounds =====

import ButtonClick                      from '../../sounds/button_click.mp3';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    DEFAULT_AUDIO_VOLUME,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import KEYCODE                          from '../../constants/keycodes';

// ===== Styles =====

import {
    Container,
    DropdownHeading,
    SelectOption,
    SelectButton,
    SelectedValue,
    SelectArrow,
    SelectOptionInput,
    SelectOptionLabel,
    SelectDropdown,
    ArrowContainer,
    RequiredIndicatorContainer,
    UnselectButton,
    DropdownContainer,
}                                       from './styles';

PixelDropdown.defaultProps = {
    isRequired: false,
    isValid: true,
    noValuesMessage: 'No values',
};
interface Props {
    isRequired?: boolean,
    isValid?: boolean,
    hasSound: boolean,
    currentValue: IDropdownItem | undefined,
    values: IDropdownItem[],
    placeholderText: string,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    onChange: (value: IDropdownItem | undefined) => void,
    noValuesMessage?: string,
}
function PixelDropdown({
    isRequired,
    isValid,
    hasSound,
    currentValue,
    values,
    placeholderText,
    noValuesMessage,
    onCursorEnter,
    onCursorLeave,
    onChange,
}: Props): JSX.Element {
    // ===== Refs =====

    // ----- Sound Clips

    const buttonClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [dropdownIsVisible, setDropdownIsVisible] = useState<boolean>(false);
    const [dropdownIsFocused, setDropdownIsFocused] = useState<boolean>(false);

    // ===== Methods =====

    const onDropdownEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.dropdown,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onDropdownLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onDropdownUnselectButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.dropdownUnselectButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onDropdownUnselectButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onDropdownChange = (value: IDropdownItem | undefined, e: React.MouseEvent | React.TouchEvent): void => {
        e.stopPropagation();
        onChange(value);
        setDropdownIsVisible(false);
        onCursorLeave();
    };

    const onDropdownFocus = (): void => {
        if (!dropdownIsFocused) setDropdownIsFocused(true);
        setDropdownIsVisible(true);

        // Play Sound
        if (hasSound && buttonClickClip.current) {
            buttonClickClip.current.pause();
            buttonClickClip.current.currentTime = 0;
            playAudio(buttonClickClip.current);
        }
    };

    const onDropdownBlur = (): void => {
        if (dropdownIsFocused) setDropdownIsFocused(false);
        if (dropdownIsVisible) setDropdownIsVisible(false);
    };

    const onDropdownKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === KEYCODE.arrowdown) {
            // Cancel the default action, if needed
            e.preventDefault();

            if (!currentValue) {
                onChange(values[0]);
            } else {
                const currentValueIndex = values.findIndex((item) => item.value === currentValue.value);
                const nextValueIndex = Math.min(currentValueIndex + 1, values.length - 1);
                const nextValue = values[nextValueIndex];
                onChange(nextValue);
                const nextValueElement = document.getElementById(`pixel-dropdown=${nextValue.value}`);
                if (nextValueElement) {
                    nextValueElement.scrollIntoView({
                        behavior: 'auto',
                        inline: 'nearest',
                        block: 'nearest',
                    });
                }
            }
        } else if (e.key === KEYCODE.arrowup) {
            // Cancel the default action, if needed
            e.preventDefault();

            if (!currentValue) {
                onChange(values[0]);
            } else {
                const currentValueIndex = values.findIndex((item) => item.value === currentValue.value);
                const nextValueIndex = Math.max(currentValueIndex - 1, 0);
                const nextValue = values[nextValueIndex];
                onChange(nextValue);
                const nextValueElement = document.getElementById(`pixel-dropdown=${nextValue.value}`);
                if (nextValueElement) {
                    nextValueElement.scrollIntoView({
                        behavior: 'auto',
                        inline: 'nearest',
                        block: 'nearest',
                    });
                }
            }
        } else if (e.key === KEYCODE.enter) {
            setDropdownIsVisible(!dropdownIsVisible);
        } else if (isLetter(e.key)) {
            const nextValueIndex = values.findIndex((item) => item.name[0].toLowerCase() === e.key.toLowerCase());
            if (nextValueIndex === -1) {
                return;
            }
            const nextValue = values[nextValueIndex];
            onChange(nextValue);
            const nextValueElement = document.getElementById(`pixel-dropdown=${nextValue.value}`);
            if (nextValueElement) {
                nextValueElement.scrollIntoView({
                    behavior: 'auto',
                    inline: 'nearest',
                    block: 'nearest',
                });
            }
        }
    };

    const handleDropdownVisibility = (): void => {
        setDropdownIsVisible(!dropdownIsVisible);
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            buttonClickClip.current
        ) {
            // Button Click
            buttonClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            buttonClickClip.current.src = ButtonClick;
        }

        return function cleanup() {
            if (buttonClickClip.current) buttonClickClip.current.remove();
        };
    }, []);

    return (
        <OutsideClickHandler
            onClick={() => {
                if (dropdownIsVisible) {
                    setDropdownIsVisible(false);
                }
            }}
        >
            <Container>
                <DropdownContainer
                    isInvalid={!isValid}
                    dropdownIsVisible={dropdownIsVisible}
                    dropdownIsFocused={dropdownIsFocused}
                    valueSelected={!!currentValue}
                    {...(values.length > 0
                        ? {
                            className: HOVER_TARGET_CLASSNAME,
                        } : {})}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: (e) => onDropdownEnter(e),
                    } : {
                        onMouseEnter: (e) => onDropdownEnter(e),
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchEnd: (e) => onDropdownLeave(e),
                    } : {
                        onMouseLeave: (e) => onDropdownLeave(e),
                    })}
                    {...(values.length > 0
                        ? {
                            onMouseDown: handleDropdownVisibility,
                        } : {})}
                >
                    {currentValue && (
                        <DropdownHeading
                            isInvalid={!isValid}
                        >
                            {placeholderText}
                        </DropdownHeading>
                    )}
                    <SelectButton
                        role="combobox"
                        aria-labelledby="select button"
                        aria-haspopup="listbox"
                        aria-expanded={dropdownIsVisible}
                        aria-controls="select-dropdown"
                        noValue={!currentValue}
                        isInvalid={!isValid}
                        dropdownIsVisible={dropdownIsVisible}
                        dropdownIsFocused={dropdownIsFocused}
                        onFocus={() => onDropdownFocus()}
                        onBlur={() => onDropdownBlur()}
                        onKeyDown={(e) => onDropdownKeyDown(e)}
                    >
                        {values.length > 0 && (
                            <SelectedValue>
                                {!currentValue
                                    ? placeholderText
                                    : currentValue.name}
                            </SelectedValue>
                        )}
                        {values.length === 0 && (<SelectedValue>{noValuesMessage}</SelectedValue>)}
                        <ArrowContainer>
                            {isRequired && (
                                <RequiredIndicatorContainer
                                    isInvalid={!isValid}
                                >
                                    <ReactSVG
                                        src={PixelAsteriskIcon}
                                    />
                                </RequiredIndicatorContainer>
                            )}
                            {values.length > 0 && (<SelectArrow />)}
                        </ArrowContainer>
                    </SelectButton>
                    <SelectDropdown
                        role="listbox"
                    >
                        {values.map((item: IDropdownItem) => (
                            <SelectOption
                                selected={!!currentValue && currentValue.value === item.value}
                                value={item.value}
                                key={item.value}
                                role="option"
                                className={HOVER_TARGET_CLASSNAME}
                                {...(detectTouchDevice(document) ? {
                                    onTouchStart: (e) => onDropdownEnter(e),
                                } : {
                                    onMouseEnter: (e) => onDropdownEnter(e),
                                })}
                                {...(detectTouchDevice(document) ? {
                                    onTouchEnd: (e) => onDropdownLeave(e),
                                } : {
                                    onMouseLeave: (e) => onDropdownLeave(e),
                                })}
                                onMouseDown={(e) => onDropdownChange(item, e)}
                            >
                                <SelectOptionInput
                                    type="radio"
                                    id={`pixel-dropdown=${item.value}`}
                                    name={item.value}
                                    checked={!!currentValue && currentValue.value === item.value}
                                />
                                <SelectOptionLabel htmlFor={item.value}>{item.name}</SelectOptionLabel>
                            </SelectOption>
                        ))}
                    </SelectDropdown>
                </DropdownContainer>
                {!!currentValue && (
                    <UnselectButton
                        isInvalid={!isValid}
                        detectTouchDevice={detectTouchDevice(document)}
                        className={HOVER_TARGET_CLASSNAME}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: (e) => onDropdownUnselectButtonEnter(e),
                        } : {
                            onMouseEnter: (e) => onDropdownUnselectButtonEnter(e),
                        })}
                        {...(detectTouchDevice(document) ? {
                            onTouchEnd: (e) => onDropdownUnselectButtonLeave(e),
                        } : {
                            onMouseLeave: (e) => onDropdownUnselectButtonLeave(e),
                        })}
                        onMouseDown={(e) => onDropdownChange(undefined, e)}
                    >
                        <ReactSVG
                            src={PixelCrossIcon}
                        />
                    </UnselectButton>
                )}
            </Container>
        </OutsideClickHandler>
    );
}

export default PixelDropdown;
