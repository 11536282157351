// Reference: https://www.better.dev/javascript-algorithms-array-chunking

const chunkArray = (array: any[], size: number): any[][] => {
    const result: any[][] = [];

    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        result.push(chunk);
    }

    return result;
};

export default chunkArray;
