const findParentNodeWithClass = (
    className: string,
    child: HTMLElement,
    searchAttempts: number,
): HTMLElement | null => {
    let found = false;
    let target: HTMLElement | null = child;
    for (let i = 0; i < searchAttempts; i += 1) {
        if (target && target.classList.contains(className)) {
            found = true;
            break;
        } else if (target) {
            target = target.parentElement;
        }
    }

    if (found) {
        return target;
    }
    return null;
};

export default findParentNodeWithClass;
