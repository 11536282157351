// References:
// - https://docs.stripe.com/js/payment_intents/confirm_payment
// - https://docs.stripe.com/api/errors#errors-type
export enum STRIPE_PAYMENT_INTENT_ERROR_TYPE {
    apiError = 'api_error',
    cardError = 'card_error',
    idempotencyError = 'idempotency_error',
    invalidRequestError = 'invalid_request_error',
    validationError = 'validation_error',
}
