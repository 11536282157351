// ===== Hooks =====
import { useEventListener }  from '.';

const useRemovePasteStyling = (element: HTMLElement): void => {
    useEventListener(
        'paste',
        (e: Event): void => {
            e.preventDefault();
            const text = (e as ClipboardEvent).clipboardData?.getData('text/plain');
            document.execCommand('insertHTML', false, text);
        },
        element,
    );
};

export default useRemovePasteStyling;
