// ===== Packages =====

import {
    Node,
    Element,
}                       from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    HeaderOptions,
    HeaderNode,
}                       from '../interfaces';
import LineInterface    from '../interfaces/lineInterface';

// ===== Enums =====

import { HEADER_SIZE }  from './enums';

const BLOCK_HEADER_TYPE = 'header';

const isValidHeaderSize = (headerSize: HEADER_SIZE): boolean => Object.values(HEADER_SIZE).includes(headerSize);

const isHeaderElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === BLOCK_HEADER_TYPE;

const isH1 = (node: EditorNode | Node): boolean => isHeaderElement(node)
    && 'headerSize' in node
    && node.headerSize === HEADER_SIZE.one;

const isH2 = (node: EditorNode | Node): boolean => isHeaderElement(node)
    && 'headerSize' in node
    && node.headerSize === HEADER_SIZE.two;

const newHeaderElement = (options: HeaderOptions = {}): HeaderNode => ({
    isLine: true,
    type: BLOCK_HEADER_TYPE,
    headerSize: options.headerSize || HEADER_SIZE.one,
    children: [{ text: options.text || '' }],
});

export default {
    HEADER_SIZE,
    TYPE: BLOCK_HEADER_TYPE,
    isHeaderElement,
    ...LineInterface,
    newHeaderElement,
    isValidHeaderSize,
    isH1,
    isH2,
};
