// ===== Enums =====

import {
    FILTER_TYPE,
}                       from '../enums';

// ===== Constants =====

import THEME_TO_ID      from '../constants/ThemeToID';
import TYPE_TO_ID       from '../constants/TypeToID';
import AUTHOR_TO_ID     from '../constants/AuthorToID';

const tagHumanIDToID = (type: FILTER_TYPE, humanId: string): string => {
    let id: string;
    if (type === FILTER_TYPE.theme) {
        id = THEME_TO_ID.get(humanId)!;
    } else if (type === FILTER_TYPE.type) {
        id = TYPE_TO_ID.get(humanId)!;
    } else {
        id = AUTHOR_TO_ID.get(humanId)!;
    }

    return id;
};

export default tagHumanIDToID;
