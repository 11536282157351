// Reference: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
const copyToClipboard = (
    text: string,
    onComplete?: () => void,
    onError?: (error: any) => void,
): void => {
    navigator.clipboard.writeText(text).then(() => {
        if (onComplete) onComplete();
    }, (err) => {
        if (onError) onError(err);
    });
};

export default copyToClipboard;
