// ===== Packages =====

import {
    Editor,
    Node,
    NodeEntry,
    Transforms,
    Range,
    Path,
}                           from 'slate';

// ===== Elements =====

import _AudioNoteElement    from '../elements/audioNoteElement';

// ===== Interfaces =====

import { AudioNoteNode }    from '../interfaces';

export const AudioNoteElement = _AudioNoteElement;

interface InsertAudioNoteOptions {
    id: string,
    filePath?: string,
    title?: string,
    description?: string,
    uploaded?: boolean,
}
const insertAudioNote = (editor: Editor, options: InsertAudioNoteOptions): void => {
    const {
        id,
        filePath,
        title,
        description,
        uploaded,
    } = options;

    const { selection } = editor;

    if (selection) {
        const endTextPath = Range.end(selection).path;
        const currentBlockPath = Path.parent(endTextPath);
        const nextBlockPath = Path.next(currentBlockPath);
        const audioNoteElement = AudioNoteElement.newAudioNoteElement({
            title,
            description,
            uploaded,
            filePath,
            id,
        });
        return Transforms.insertNodes(editor, audioNoteElement, { at: nextBlockPath });
    }
};

const updateAudioNote = (editor: Editor, options: InsertAudioNoteOptions): void => {
    const {
        id,
        filePath: newFilePath,
        description: newDescription,
        title: newTitle,
        uploaded: newUploaded,
    } = options;
    const { selection } = editor;

    if (selection && Range.isExpanded(selection)) { return; }

    const [audioNoteEntry] = Array.from(Editor.nodes(editor, { match: AudioNoteElement.isAudioNoteElement }));
    if (audioNoteEntry) {
        const [audioNote, audioNotePath] = audioNoteEntry;
        const {
            filePath: oldFilePath,
            description: oldDescription,
            title: oldTitle,
            uploaded: oldUploaded,
        } = (audioNote as AudioNoteNode);
        return Transforms.setNodes<AudioNoteNode>(editor, {
            id,
            filePath: !newFilePath ? oldFilePath : newFilePath,
            description: newDescription === undefined ? oldDescription : newDescription,
            title: newTitle === undefined ? oldTitle : newTitle,
            uploaded: newUploaded === undefined ? oldUploaded : newUploaded,
        }, { at: audioNotePath });
    }
};

const removeAudioNote = (editor: Editor): void => {
    const [audioNoteEntry] = Array.from(Editor.nodes(editor, { match: AudioNoteElement.isAudioNoteElement }));
    if (audioNoteEntry) {
        const [, audioNotePath] = audioNoteEntry;
        return Transforms.removeNodes(editor, { at: audioNotePath });
    }
};

const getSelectionAudioNotes = (editor: Editor): NodeEntry<Node>[] => Array.from(
    Array.from(Editor.nodes(editor, { match: AudioNoteElement.isAudioNoteElement })),
);

const hasSelectedAudioNote = (editor: Editor): boolean => {
    const [match] = getSelectionAudioNotes(editor);
    return !!match;
};

export const AudioNoteHelpers = {
    insertAudioNote,
    updateAudioNote,
    removeAudioNote,
    getSelectionAudioNotes,
    hasSelectedAudioNote,
};

export const withAudioNotes = (editor: Editor): Editor => {
    const { isVoid, isInline, normalizeNode } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path);

        if (!AudioNoteElement.isAudioNoteElement(node)) { return normalizeNode(entry); }

        const audioNote = node;

        const missingFilePathProp = !('filePath' in audioNote);
        if (missingFilePathProp) { return Transforms.setNodes<AudioNoteNode>(editor, { filePath: '' }, { at: path }); }

        const missingTitleProp = !('title' in audioNote);
        if (missingTitleProp) { return Transforms.setNodes<AudioNoteNode>(editor, { title: '' }, { at: path }); }

        const missingDescriptionProp = !('description' in audioNote);
        if (missingDescriptionProp) { return Transforms.setNodes<AudioNoteNode>(editor, { description: '' }, { at: path }); }

        // NOTE: we assume that the AudioNote was uploaded unless otherwise indicated
        const missingUploadedProp = !('uploaded' in audioNote);
        if (missingUploadedProp) { return Transforms.setNodes<AudioNoteNode>(editor, { uploaded: true }, { at: path }); }
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (AudioNoteElement.isAudioNoteElement(element) ? false : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (AudioNoteElement.isAudioNoteElement(node) ? true : isVoid(node));

    return editor;
};
