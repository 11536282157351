// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                           from 'firebase/functions';

// ===== Enums =====

import {
    STORAGE_ENTITY,
}                           from '../enums';

const generateTextSpeech = (
    annotationId: string,
    text: string,
    gender: string, // 'masculine' or 'feminine'
    onComplete?: (result: any) => void,
): boolean => {
    const functions = getFunctions();
    const textToSpeech = httpsCallable(functions, 'textToSpeech');
    const storageEntity = process.env.NODE_ENV === 'development'
        ? STORAGE_ENTITY.stagingAnnotations
        : STORAGE_ENTITY.annotations;
    textToSpeech({
        text,
        gender,
        // ** IMPORTANT **: If you change the prefix text, change it in cloud functions as well
        filename: `text-to-speech-annotation-${gender}-${annotationId}`,
        mediaId: annotationId,
        storageEntity,
    }).then((result) => {
        if (onComplete) onComplete(result);
    });

    return true;
};

export default generateTextSpeech;
