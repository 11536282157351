// ===== Packages =====

import React, {
    useCallback,
    useMemo,
    useState,
    useRef,
    useEffect,
}                                                       from 'react';
import styled                                           from 'styled-components';
import {
    Slate,
    Editable,
    withReact,
    ReactEditor,
}                                                       from 'slate-react';
import {
    createEditor,
    Node,
    Transforms,
    Editor,
    Descendant,
    Range,
    NodeEntry,
    Location,
    Point,
}                                                       from 'slate';
import { withHistory }                                  from 'slate-history';
import {
    StorageError,
    getDownloadURL,
    UploadTaskSnapshot,
    StorageReference,
    UploadTask,
}                                                       from 'firebase/storage';
import {
    useParams,
}                                                       from 'react-router-dom';
import ShortUniqueId                                    from 'short-unique-id';
import mime                                             from 'mime-types';
import { v4 as uuidv4 }                                 from 'uuid';

// ===== Components =====

import { SlateLeaf }                                    from '../../../styles';
import Modal                                            from '../../Modal';
import {
    GenericModal,
    PortableToolbar,
}                                                       from '../helpers';
import {
    BottomBufferElement,
    BottomBufferHelpers,
    withBottomBuffers,
}                                                       from '../plugins/bottomBuffersPlugin';
import {
    withDividers,
    DividerHelpers,
    DividerElement,
}                                                       from '../plugins/dividersPlugin';
import {
    EmphasizerHelpers,
}                                                       from '../plugins/emphasizersPlugin';
import {
    withHeaders,
    HeaderHelpers,
    HeaderElement,
}                                                       from '../plugins/headersPlugin';
import {
    withLines,
    LineHelpers,
}                                                       from '../plugins/linesPlugin';
import {
    withAudioNotes,
    AudioNoteHelpers,
    AudioNoteElement,
}                                                       from '../plugins/audioNotesPlugin';
import {
    withWebLinks,
    WebLinkHelpers,
    WebLinkElement,
}                                                       from '../plugins/webLinksPlugin';
import {
    withResolutionLevels,
    ResolutionLevelHelpers,
    ResolutionLevelElement,
}                                                       from '../plugins/resolutionLevelsPlugin';
import {
    withLists,
    ListHelpers,
    ListElement,
    ListItemElement,
}                                                       from '../plugins/listsPlugin';
import {
    withFigures,
    FigureHelpers,
    CaptionElement,
    FigureElement,
    GalleryElement,
    FigureContentElement,
    FigureContentTypes,
}                                                       from '../plugins/figuresPlugin';
import {
    withBlockQuotes,
    BlockQuoteHelpers,
    BlockQuoteElement,
}                                                       from '../plugins/blockQuotesPlugin';
import HandleOnKeyDown                                  from '../plugins/handleHotKeys';

import {
    BlockQuote,
    BottomBuffer,
    Caption,
    Divider,
    AudioNote,
    Figure,
    Gallery,
    HeaderOne,
    HeaderTwo,
    Image,
    InlineLink,
    List,
    ListItem,
    Paragraph,
    YouTubeEmbed,
    TwitterEmbed,
    SpotifyEmbed,
    VimeoEmbed,
    ResolutionLevel,
}                                                       from '../renderers';

// ===== Services =====

import {
    playAudio,
    uploadToCloudStorage,
    setMediaInDB,
    getMediaStorageBucket,
    getStorageErrorMessage,
    updateAnnotationInDB,
    updatePostInDB,
    normalizePostSelectionPath,
}                                                       from '../../../services';

// ===== Hooks =====

import {
    useEventListener,
    useTimeout,
}                                                       from '../../../hooks';

// ===== Enums =====

import {
    EDITOR_TOOLBAR_TYPE,
    EDITOR_TOOLBAR_TOOL_GROUP,
    EDITOR_CONTEXT_TYPE,
    STORAGE_ENTITY,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    STORAGE_ERROR_CODE,
    MEDIA_TYPE,
    FIRESTORE_COLLECTION,
}                                                       from '../../../enums';
import { EMPHASIZER_MARK }                              from '../plugins/enums';
import {
    RESOLUTION_LEVEL,
    RESOLUTION_LEVEL_TYPE,
}                                                       from '../elements/enums';

// ===== Interfaces =====

import {
    ICoord,
    IEmoji,
    IMediaItem,
    IModalMessage,
    IAnnotationQuote,
    IEditorDecoration,
    IAnnotationItem,
    IUserItem,
    ISnackbarItem,
    IPostChapterItem,
    IResolutionLevelItem,
}                                                       from '../../../interfaces';
import {
    WebLinkNode,
}                                                       from '../interfaces';

// ===== Sounds =====

import InputClick                                       from '../../../sounds/button_click.mp3';

// ===== Constants =====

import {
    POST_EDITOR_MAX_WIDTH,
    INITIAL_EDITOR_VALUE,
    HOVER_TARGET_CLASSNAME,
    DEFAULT_AUDIO_VOLUME,
    SLATE_EDITOR_CLASSNAME,
    ANNOTATION_DECORATION_PREFIX,
    REVEAL_QUOTE_DECORATION_PREFIX,
    SLATE_EDITOR_POST_EDITOR_EDITABLE_CLASSNAME,
    SLATE_EDITOR_SELECT_ALL_TEXT_TIMEOUT_DURATION,
}                                                       from '../../../constants/generalConstants';
import CURSOR_SIGN                                      from '../../../constants/cursorSigns';
import { SAVE_POST_DURING_AUDIO_RECORDING_ERROR }       from '../../../constants/notificationMessages';

// ===== Styles =====

import { theme as themeObj }                            from '../../../themes/theme-context';

const EDITOR_PLUGINS = [
    withFigures,
    withLists,
    withHeaders,
    withLines,
    withWebLinks,
    withResolutionLevels,
    withBlockQuotes,
    withBottomBuffers,
    withDividers,
    withReact,
    withHistory,
    withAudioNotes,
];

PostEditor.defaultProps = {
    setRef: undefined,
    hide: false,
    enableGallery: true,
    postValue: undefined,
    targetAnnotationID: null,
    chapters: undefined,
};
interface Props {
    id: string,
    user: IUserItem | null,
    hasSound: boolean,
    currentSessionId: string | null,
    postValue?: string,
    type: EDITOR_CONTEXT_TYPE,
    color: string,
    readOnly: boolean,
    placeholder: string,
    setRef?: (ref: Editor) => void,
    hide?: boolean,
    bufferHeight: number,
    parentRef: HTMLElement | null,
    isAuthor: boolean,
    enableGallery?: boolean, // Whether we all users to generate image galleries in the post.
    acceptedFiles: File[],
    uploadingMedia: Map<string, IMediaItem>,
    setUploadingMedia: (mediaItem: IMediaItem) => void,
    updateUploadingMedia: (mediaItem: IMediaItem) => void,
    removeUploadingMedia: (id: string) => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    postValueIsSaving: boolean,
    savePost: (
        value: string,
        chapterIndex?: number | undefined,
        sectionIndex?: number | undefined,
    ) => Promise<void>,
    setEditor: (editor: Editor) => void,
    postQuote: IAnnotationQuote | null,
    annotations: IAnnotationItem[],
    setAnnotations: React.Dispatch<React.SetStateAction<Map<string, IAnnotationItem>>>,
    chapters?: IPostChapterItem[],
    setAnnotationHighlightsRendered: React.Dispatch<React.SetStateAction<boolean>>,
    annotationHighlightsRendered: boolean,
    targetAnnotationID?: string | null,
    setRemeasurePostHeight: React.Dispatch<React.SetStateAction<boolean>>,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    currentResolutionLevel: IResolutionLevelItem | null,
    hasSuccess: boolean,
    hasError: boolean,
    selectedPostValuePath: number[],
    unusedResolutionLevelCount: number,
    resolutionLevelIcons: Map<RESOLUTION_LEVEL, string> | null,
}
function PostEditor({
    id,
    user,
    hasSound,
    currentSessionId,
    postValue,
    type,
    hide = false,
    color,
    setRef,
    isAuthor = false,
    readOnly = false,
    parentRef,
    placeholder = 'Type post...',
    bufferHeight = 0,
    enableGallery = true,
    acceptedFiles,
    uploadingMedia,
    setUploadingMedia,
    updateUploadingMedia,
    removeUploadingMedia,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    savePost,
    postValueIsSaving,
    setEditor,
    postQuote,
    annotations,
    setAnnotations,
    chapters,
    setAnnotationHighlightsRendered,
    annotationHighlightsRendered,
    targetAnnotationID,
    setRemeasurePostHeight,
    setSnackbarData,
    currentResolutionLevel,
    hasSuccess,
    hasError,
    selectedPostValuePath,
    unusedResolutionLevelCount,
    resolutionLevelIcons,
}: Props): JSX.Element {
    // ===== General Information =====
    //
    // 1. Although there is logic that modifies the selection paths of annotation quotes
    // when it is subsumed within a resolution level creation or deletion, there is no
    // logic to return the selection path to its original state if a user undoes their
    // command via the keyboard versus manipulating the toolbars.
    //
    // 2. If there are already annotations within a selection when you add or remove a
    // resolution level, the annotations will be removed. This is because the path of
    // the annotations becomes out of sync with the path of the selection after the
    // resolution level is added or removed.

    // ===== General Constants =====

    const PORTABLE_TOOLBAR_BUTTON_LENGTH = 30;
    const HIGHLIGHT_TRANSITION_DURATION = 150;

    // ===== Ref =====

    const containerRef = useRef<HTMLDivElement>(null);
    const selectionToolbarRef = useRef<HTMLDivElement | null>(null);

    // ----- Sound Clips
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== React Router =====

    const params = useParams();

    // ===== State =====

    const getSavedSlateValue = (): Descendant[] => {
        const postContent = postValue;
        if (!postContent || postContent.length === 0) {
            return INITIAL_EDITOR_VALUE;
        }

        let contentTree;
        try {
            contentTree = JSON.parse(postContent);
        } catch (error) {
            throw Error('There was a problem interpreting the post content.');
        }

        const tempEditor = EDITOR_PLUGINS
            .reduce((
                compositeEditor: Editor,
                withPlugin: (editor: Editor) => Editor,
            ) => withPlugin(compositeEditor), createEditor());
        tempEditor.children = contentTree;
        Editor.normalize(tempEditor, { force: true });
        return tempEditor.children;
    };
    const slateValue = useMemo(getSavedSlateValue, [postValue]);
    // Indicates whether we've set initial post value
    const [initializedValue, setInitializedValue] = useState<boolean>(false);
    // Stores the slate editor value
    const [value, setValue] = useState(slateValue);
    // Stores the position of the block toolbar
    const [blockToolbarPosition, setBlockToolbarPosition] = useState<ICoord>({
        y: 0,
        x: -52,
    });
    // Stores the position of the selection toolbar
    const [selectionToolbarPosition, setSelectionToolbarPosition] = useState<ICoord>({
        y: 24,
        x: -52,
    });
    // Stores whether we should update toolbar positions
    // Value updated everytime change occurs to editor
    const [updateToolbars, setUpdateToolbars] = useState<boolean>(false);
    // Stores whether selection toolbar is revealed (set in PortableToolbar)
    // Selection Toolbar is revealed when text is selected
    // Value is used to hide Block Toolbar when Selection Toolbar is active
    const [showSelectionToolbar, setShowSelectionToolbar] = useState<boolean>(false);
    // Stores whether we should rerender elements that have measuring logic
    // This occurs when we need to wait for rendering to complete, such as with images
    // in figures.
    const [rerenderMeasuringElements, setRerenderMeasuringElements] = useState<boolean>(false);
    // Stores whether audio is being recorded
    // Makes sure we record one audio note at a time
    const [isRecordingAudioNote, setIsRecordingAudioNote] = useState<boolean>(false);
    // Indicates whether we need to execute a save operation
    // Occurs when we try to save while already perfoming save operation
    const [queueSave, setQueueSave] = useState<boolean>(false);
    // Stores the state of the editor modal
    const [modal, setModal] = useState<{
        visible: boolean,
        message: IModalMessage,
        proceedCallback?:() => void,
        rejectCallback?: () => void,
            }>({
                visible: false,
                message: {},
            });
    // Determines the width of it's parent in order to modify width of editor
    const [parentWidth, setParentWidth] = useState<number | string | null>(parentRef
        ? parentRef.clientWidth
        : null);
    // Stores media item that is currently uploading
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    // Stores media item that has completed uploading
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);

    const editor = useMemo(() => EDITOR_PLUGINS
        .reduce((compositeEditor, withPlugin) => withPlugin(compositeEditor), createEditor()), []);

    // ===== Hotkeys =====

    const handleOnKeyDown = useMemo(() => HandleOnKeyDown({
        tabCallback: () => {
            ListHelpers.indentSelectionLists(editor);
            LineHelpers.indentLineElements(editor);
        },
        shiftTabCallback: () => {
            ListHelpers.unindentSelectionLists(editor);
            LineHelpers.unindentLineElements(editor);
        },
        modBCallback: () => EmphasizerHelpers.toggleBold(editor),
        modICallback: () => EmphasizerHelpers.toggleItalicize(editor),
        modUCallback: () => EmphasizerHelpers.toggleUnderline(editor),
        modACallback: () => timeoutSelectAllText(),
        modShiftSCallback: () => EmphasizerHelpers.toggleStrikethrough(editor),
    }), []);

    // ===== Decorations =====

    const decorate = useCallback(([, path]: NodeEntry) => {
        const decorations: IEditorDecoration[] = [];
        annotations.forEach((annotation) => {
            annotation.quoteHistory[0].selections.forEach((selection) => {
                const nodeRange = Editor.range(editor, path);
                const annotationSelection = normalizePostSelectionPath(
                    annotation.id,
                    selection!,
                    chapters,
                    currentResolutionLevel,
                );
                const annotationRange = Editor.range(editor, annotationSelection as Location);
                const intersection = Range.intersection(nodeRange, annotationRange);
                if (intersection) {
                    decorations.push({
                        anchor: intersection.anchor,
                        focus: intersection.focus,
                        [`${ANNOTATION_DECORATION_PREFIX}${annotation.id}`]: annotation.id,
                        annotationQuote: true,
                        // We show annotation quote when user hovering over annotation (targeAnnotationID)
                        // We show annotation quote on !readOnly so author knows where there are annotations
                        // We show annotation quote when an annotation is focused (params.annotationId)
                        [`${REVEAL_QUOTE_DECORATION_PREFIX}${annotation.id}`]:
                            (!!targetAnnotationID && targetAnnotationID === annotation.id)
                            || !readOnly
                            || (params.annotationId && params.annotationId === annotation.id),
                        highlightColor: themeObj.verascopeColor.purple400,
                    });
                }
            });
        });

        // We place after annotations highlight logic so that it is
        // prioritized when displayed
        // i.e. we show a text highlight on top of an annotation highlight
        if (postQuote) {
            postQuote.selections.forEach((selection) => {
                const nodeRange = Editor.range(editor, path);
                const intersection = Range.intersection(nodeRange, selection as Range);

                if (intersection) {
                    decorations.push({
                        anchor: intersection.anchor,
                        focus: intersection.focus,
                        postQuote: true,
                        highlightColor: themeObj.verascopeColor.orange200,
                    });
                }
            });
        }

        if (annotations.length > 0 && !annotationHighlightsRendered) {
            setAnnotationHighlightsRendered(true);
        }

        return decorations;
    }, [
        postQuote,
        annotations,
        currentResolutionLevel,
    ]);

    // ===== Side Effects =====

    /**
     * Set reference to editor to parent
     */
    useEffect(() => {
        setEditor(editor);
    }, []);

    /**
     * Set initial post value
     */
    useEffect(() => {
        if (!initializedValue && postValue && slateValue) {
            setValue(slateValue);
            setInitializedValue(true);
            setRemeasurePostHeight(true);
        }
    }, [
        postValue,
        slateValue,
        initializedValue,
    ]);

    /**
     * Set change post value when chapter or section change
     */
    useEffect(() => {
        if (initializedValue && postValue && slateValue) {
            // User has switched chapters or sections
            // Inject new post value into editor
            setValue(slateValue);
            editor.children = slateValue;
            if (!readOnly) moveCursorToEndOfDocument();
            setRemeasurePostHeight(true);
        } else if (initializedValue && !postValue && slateValue) {
            // User has created a new chapter or section
            // Reset initialization so new post normalized post value can be injected
            // via getSavedSlateValue and 'Set initial post value' effect
            setInitializedValue(false);
        }
    }, [
        selectedPostValuePath,
    ]);

    /**
     * Set Slate Editor Reference
     * and focus editor
     */
    useEffect(() => {
        if (!readOnly && initializedValue) {
            setSlateEditorRef(editor);
            moveCursorToEndOfDocument();
        }
    }, [
        initializedValue,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (inputClickClip.current) {
            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;
        }

        return function cleanup() {
            if (inputClickClip.current) inputClickClip.current.remove();
        };
    }, []);

    /**
     * Execute queued save
     */
    useEffect(() => {
        if (!postValueIsSaving && queueSave) {
            setQueueSave(false);
            handleAutoSave();
        }
    }, [postValueIsSaving]);

    /**
     * Determine Initial Editor Width
     */
    useEffect(() => {
        const currentParentWidth = parentRef
            ? parentRef.clientWidth
            : '100%';
        if (parentWidth !== currentParentWidth) {
            setParentWidth(currentParentWidth);
        }
    }, [parentRef]);

    /**
     * Determine initial position of block toolbar
     */
    useEffect(() => {
        if (
            !readOnly
            && containerRef.current
            && !blockToolbarPosition.y
        ) {
            updateBlockToolbarPosition();
        }
    }, [
        containerRef,
    ]);

    /**
     * Handle Autosave
     */
    useEffect(() => {
        if (!readOnly) {
            handleAutoSave();
        }
    }, [value]);

    /**
     * Update Position of Toolbars
     */
    useEffect(() => {
        const nativeSelection = window.getSelection();
        if (
            !readOnly
            && updateToolbars
            && nativeSelection
        ) {
            const range = nativeSelection.rangeCount > 0
                ? nativeSelection.getRangeAt(0)
                : null;
            const rect = nativeSelection.rangeCount > 0
                ? range?.getBoundingClientRect()
                : null;

            if (
                rect
                && containerRef.current
                && (rect.top - containerRef.current.getBoundingClientRect().top - 7) !== blockToolbarPosition.y
            ) {
                // Only update block toolbar if it's vertical position has changed
                // This is to avoid a rerender for the block toolbar
                updateBlockToolbarPosition();
            }
            setUpdateToolbars(false);
        }
    }, [updateToolbars]);

    /**
     * Confirmation of leave page
     */
    useEffect(() => {
        if (!readOnly) {
            // Add a dialog that prevents reloading page if changes unsaved
            window.addEventListener('beforeunload', handleUnsavedAudioNoteDialog);
        }

        return function cleanup() {
            // Remove a dialog that prevents reloading page if changes unsaved
            if (!readOnly) window.removeEventListener('beforeunload', handleUnsavedAudioNoteDialog);
        };
    }, []);

    /**
     * pdate Positions of Toolbars on Resize
     */
    useEventListener(
        'resize',
        () => {
            if (!readOnly) {
                setUpdateToolbars(true);
            }
        },
    );

    /**
     * Update Parent Width on Resize
     */
    useEventListener(
        'resize',
        () => {
            const currentParentWidth = parentRef
                ? parentRef.clientWidth
                : '100%';
            if (parentWidth !== currentParentWidth) {
                setParentWidth(currentParentWidth);
            }
        },
    );

    /**
     * Upload dropzone files to storage
     * and create figure or audio note
     * WARNING: Accepted Files don't clear so this could be called again
     */
    useEffect(() => {
        if (!readOnly) {
            const uploadTasks: UploadTask[] = [];
            const mediaItems: IMediaItem[] = [];
            for (let i = 0; i < acceptedFiles.length; i += 1) {
                const file = acceptedFiles[i];
                const mediaId = uuidv4();
                const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
                const mediaType = file.type.startsWith('audio/')
                    ? MEDIA_TYPE.audioNote
                    : MEDIA_TYPE.image;
                const mediaBucket = getMediaStorageBucket(mediaType);
                const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
                const fileExtension = mime.extension(file.type);
                const storageEntity = process.env.NODE_ENV === 'production'
                    ? STORAGE_ENTITY.posts
                    : STORAGE_ENTITY.stagingPosts;
                const filePath = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
                const mediaItem: IMediaItem = {
                    id: mediaId,
                    userId: user!.id,
                    file,
                    filePath,
                    type: MEDIA_TYPE.image,
                    uploadProgress: 0,
                };
                mediaItems.push(mediaItem);
                setUploadingMedia(mediaItem);

                // make db entry of media
                // execute before file upload so upload cloud function has a place to write to
                setMediaInDB({
                    mediaItem,
                    filePath,
                });

                // upload file to cloud storage
                // url will be set by cloud function
                const uploadTask = uploadToCloudStorage(
                    file,
                    filePath,
                );
                uploadTasks.push(uploadTask);
            }

            uploadTasks.forEach((task: UploadTask, index: number) => {
                task.on(
                    'state_changed',
                    (snapshot: UploadTaskSnapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadingStateChangeEvent({
                            mediaItem: mediaItems[index],
                            snapshot,
                            progress,
                        });
                    },
                    (error: StorageError) => {
                        throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                    },
                    () => {
                        setUploadingCompleteEvent({
                            mediaItem: mediaItems[index],
                            storageRef: task.snapshot.ref,
                        });
                    },
                );
            });
        }
    }, [acceptedFiles]);

    /**
     * Processes progress updates for uploading media
     */
    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
        }
    }, [uploadingStateChangeEvent]);

    /**
     * Processes completion of uploading media
     */
    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    /**
     * Manages logic to edit annotation quotes if associated text is altered
     * and deletes annotations if associated text is deleted (floating annotations)
     *
     * IMPORTANT: Designed to support single-character deletions and additions only
     * Multiple-character deletions and additions could be supported but would require
     * that determine the difference in length between the previous and current value
     * then wherever a test for a single-character change is made, it would need to be
     * updated to test for every factorial of the difference in length. e.g. If there
     * was a difference of 3, you would need to do a test for 1, 2, and 3 characters
     * wherever tests are made.
     *
     * IMPORTANT: Not designed to support quick-typed changes. Quick-typed changes
     * could be supported but would require that you delay updating the annotations locally
     * and the database until the user stops typing for a certain amount of time (e.g. with a
     * timeout). You would also have to support multi-character changes.
     */
    useEffect(() => {
        const handleDeleteAnnotation = (
            annotation: IAnnotationItem,
            updatedAnnotations: IAnnotationItem[],
        ): IAnnotationItem[] => {
            const annotationCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.annotations
                : FIRESTORE_COLLECTION.stagingAnnotations;
            // Delete in updated annotations array
            const annotationIndex = updatedAnnotations.findIndex((a) => a.id === annotation.id);
            if (annotationIndex !== -1) updatedAnnotations.splice(annotationIndex, 1);
            const remainingAnnotations = [...updatedAnnotations];

            // Remove from post, chapters, and sections
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            updatePostInDB({
                collection: postsCollection,
                id,
                annotations: [...updatedAnnotations.map((a) => a.id)],
            });
            if (chapters) {
                const indicesOfChaptersToRemove: number[] = [];
                chapters.forEach((chapter, chapterIndex) => {
                    if (chapter.annotations.includes(annotation.id)) {
                        indicesOfChaptersToRemove.push(chapterIndex);
                    }
                });
                const updatedChapters = [...chapters];
                indicesOfChaptersToRemove.forEach((chapterIndex) => {
                    const chapterAnnotations = [...updatedChapters[chapterIndex].annotations];
                    const annotationChapterIndex = chapterAnnotations.findIndex((chapterAnnotationId) => chapterAnnotationId === annotation.id);
                    if (annotationChapterIndex !== -1) chapterAnnotations.splice(annotationChapterIndex, 1);
                    updatedChapters[chapterIndex].annotations = [...chapterAnnotations];
                    updatedChapters[chapterIndex].updated = [
                        ...updatedChapters[chapterIndex].updated,
                        Date.now(),
                    ];

                    if (updatedChapters[chapterIndex].sections) {
                        const indicesOfSectionsToRemove: number[] = [];
                        updatedChapters[chapterIndex].sections!.forEach((section, sectionIndex) => {
                            if (section.annotations.includes(annotation.id)) {
                                indicesOfSectionsToRemove.push(sectionIndex);
                            }
                        });
                        indicesOfSectionsToRemove.forEach((sectionIndex) => {
                            const sectionAnnotations = [...updatedChapters[chapterIndex].sections![sectionIndex].annotations];
                            const annotationSectionIndex = sectionAnnotations.findIndex((sectionAnnotationId) => sectionAnnotationId === annotation.id);
                            if (annotationSectionIndex !== -1) sectionAnnotations.splice(annotationSectionIndex, 1);
                            updatedChapters[chapterIndex].sections![sectionIndex].annotations = [...sectionAnnotations];
                            updatedChapters[chapterIndex].sections![sectionIndex].updated = [
                                ...updatedChapters[chapterIndex].sections![sectionIndex].updated,
                                Date.now(),
                            ];
                        });
                    }
                });
                updatePostInDB({
                    collection: postsCollection,
                    id,
                    chapters: updatedChapters,
                });
            }

            // Delete annotation
            updateAnnotationInDB({
                collection: annotationCollection,
                id: annotation.id,
                deleted: {
                    deleted: true,
                    timestamp: Date.now(),
                },
            });

            return remainingAnnotations;
        };
        if (
            !readOnly
            && annotations.length > 0
            && editor.children.length > 0
        ) {
            let updatedAnnotations = [...annotations];
            // Indicates whether we need to update annotations locally
            let annotationsUpdated = false;
            annotations.forEach((annotation) => {
                // We cache selections and texts so that updates to updated annotations selections
                // dont interfere with selection indices (e.g. delete a selection and cause indexing issues)
                const { selections } = annotation.quoteHistory[0];
                const { texts } = annotation.quoteHistory[0];
                const updatedQuote: IAnnotationQuote = {
                    id: uuidv4(),
                    timestamp: Date.now(),
                    selections: [],
                    texts: [],
                };
                // Indicates whether we need to update annotations locally and in DB
                let annotationUpdated = false;
                // Indicates whether we need to delete annotation
                let deleteAnnotation = false;
                for (let i = 0; i < annotation.quoteHistory[0].selections.length; i += 1) {
                    let selectionUpdated = false;
                    const normalizedSelection = normalizePostSelectionPath(
                        annotation.id,
                        selections[i],
                        chapters,
                        currentResolutionLevel,
                    );
                    const quoteText = texts[i];
                    const selectionText = Editor.string(editor, normalizedSelection as Location);

                    if (quoteText !== selectionText) {
                        // Test to see if character before quote text was added or removed

                        const selectionShiftBack = {
                            anchor: {
                                path: selections[i]!.anchor.path,
                                offset: selections[i]!.anchor.offset - 1,
                            },
                            focus: {
                                path: selections[i]!.focus.path,
                                offset: selections[i]!.focus.offset - 1,
                            },
                        };
                        const normalizedSelectionShiftBack = {
                            anchor: {
                                path: normalizedSelection!.anchor.path,
                                offset: normalizedSelection!.anchor.offset - 1,
                            },
                            focus: {
                                path: normalizedSelection!.focus.path,
                                offset: normalizedSelection!.focus.offset - 1,
                            },
                        };
                        const selectionShiftForward = {
                            anchor: {
                                path: selections[i]!.anchor.path,
                                offset: selections[i]!.anchor.offset + 1,
                            },
                            focus: {
                                path: selections[i]!.focus.path,
                                offset: selections[i]!.focus.offset + 1,
                            },
                        };
                        const normalizedSelectionShiftForward = {
                            anchor: {
                                path: normalizedSelection!.anchor.path,
                                offset: normalizedSelection!.anchor.offset + 1,
                            },
                            focus: {
                                path: normalizedSelection!.focus.path,
                                offset: normalizedSelection!.focus.offset + 1,
                            },
                        };

                        const selectionShiftBackText = Editor.string(editor, normalizedSelectionShiftBack as Location);
                        const selectionShiftForwardText = Editor.string(editor, normalizedSelectionShiftForward as Location);

                        if (quoteText === selectionShiftBackText) {
                            // Update annotation quote
                            // Shift selection back by one character
                            updatedQuote.selections.push(selectionShiftBack);
                            updatedQuote.texts.push(quoteText);
                            selectionUpdated = true;
                            annotationUpdated = true;
                            annotationsUpdated = true;
                        } else if (quoteText === selectionShiftForwardText) {
                            // Update annotation quote
                            // Shift selection forward by one character
                            updatedQuote.selections.push(selectionShiftForward);
                            updatedQuote.texts.push(quoteText);
                            selectionUpdated = true;
                            annotationUpdated = true;
                            annotationsUpdated = true;
                        } else {
                            // Test to see if character in quote text was added or removed

                            // 1. Character was added
                            const lengthenedSelection = {
                                anchor: Point.isBefore(selections[i]!.anchor as Point, selections[i]!.focus)
                                    ? selections[i]!.anchor
                                    : {
                                        path: selections[i]!.anchor.path,
                                        offset: selections[i]!.anchor.offset + 1,
                                    },
                                focus: Point.isBefore(selections[i]!.anchor as Point, selections[i]!.focus)
                                    ? {
                                        path: selections[i]!.focus.path,
                                        offset: selections[i]!.focus.offset + 1,
                                    } : selections[i]!.focus,
                            };
                            const normalizedLengthenedSelection = {
                                anchor: Point.isBefore(normalizedSelection!.anchor as Point, normalizedSelection!.focus)
                                    ? normalizedSelection!.anchor
                                    : {
                                        path: normalizedSelection!.anchor.path,
                                        offset: normalizedSelection!.anchor.offset + 1,
                                    },
                                focus: Point.isBefore(normalizedSelection!.anchor as Point, normalizedSelection!.focus)
                                    ? {
                                        path: normalizedSelection!.focus.path,
                                        offset: normalizedSelection!.focus.offset + 1,
                                    } : normalizedSelection!.focus,
                            };
                            const lengthenedSelectionText = Editor.string(editor, normalizedLengthenedSelection);
                            for (let j = 0; j < lengthenedSelectionText.length; j += 1) {
                                const removeCharText = lengthenedSelectionText.slice(0, j) + lengthenedSelectionText.slice(j + 1);
                                if (quoteText === removeCharText) {
                                    // Update annotation quote
                                    const firstSelectionText = lengthenedSelectionText.slice(0, j);
                                    const firstSelection = {
                                        anchor: Point.isBefore(lengthenedSelection.anchor as Point, lengthenedSelection.focus)
                                            ? lengthenedSelection.anchor
                                            : {
                                                path: lengthenedSelection.focus.path,
                                                offset: lengthenedSelection.focus.offset + firstSelectionText.length,
                                            },
                                        focus: Point.isBefore(lengthenedSelection.anchor as Point, lengthenedSelection.focus)
                                            ? {
                                                path: lengthenedSelection.anchor.path,
                                                offset: lengthenedSelection.anchor.offset + firstSelectionText.length,
                                            } : lengthenedSelection.focus,
                                    };
                                    const secondSelectionText = lengthenedSelectionText.slice(j + 1);
                                    const secondSelection = {
                                        anchor: Point.isBefore(lengthenedSelection.anchor as Point, lengthenedSelection.focus)
                                            ? {
                                                path: lengthenedSelection.anchor.path,
                                                offset: lengthenedSelection.anchor.offset + firstSelectionText.length + 1,
                                            }
                                            : {
                                                path: lengthenedSelection.focus.path,
                                                offset: lengthenedSelection.focus.offset + firstSelectionText.length + secondSelectionText.length + 1,
                                            },
                                        focus: Point.isBefore(lengthenedSelection.anchor as Point, lengthenedSelection.focus)
                                            ? {
                                                path: lengthenedSelection.anchor.path,
                                                offset: lengthenedSelection.anchor.offset + firstSelectionText.length + secondSelectionText.length + 1,
                                            } : {
                                                path: lengthenedSelection.focus.path,
                                                offset: lengthenedSelection.focus.offset + firstSelectionText.length + 1,
                                            },
                                    };
                                    updatedQuote.selections.push(firstSelection);
                                    updatedQuote.texts.push(firstSelectionText);
                                    updatedQuote.selections.push(secondSelection);
                                    updatedQuote.texts.push(secondSelectionText);
                                    selectionUpdated = true;
                                    annotationUpdated = true;
                                    annotationsUpdated = true;

                                    break; // Get out of for loop that tests for added character
                                }
                            }

                            if (!selectionUpdated) {
                                // 1. Character was removed
                                for (let j = 0; j < quoteText.length; j += 1) {
                                    const removeCharText = quoteText.slice(0, j) + quoteText.slice(j + 1);
                                    const shortenedSelection = {
                                        anchor: Point.isBefore(selections[i]!.anchor as Point, selections[i]!.focus)
                                            ? selections[i]!.anchor
                                            : {
                                                path: selections[i]!.anchor.path,
                                                offset: selections[i]!.anchor.offset - 1,
                                            },
                                        focus: Point.isBefore(selections[i]!.anchor as Point, selections[i]!.focus)
                                            ? {
                                                path: selections[i]!.focus.path,
                                                offset: selections[i]!.focus.offset - 1,
                                            } : selections[i]!.focus,
                                    };
                                    const normalizedShortenedSelection = {
                                        anchor: Point.isBefore(normalizedSelection!.anchor as Point, normalizedSelection!.focus)
                                            ? normalizedSelection!.anchor
                                            : {
                                                path: normalizedSelection!.anchor.path,
                                                offset: normalizedSelection!.anchor.offset - 1,
                                            },
                                        focus: Point.isBefore(normalizedSelection!.anchor as Point, normalizedSelection!.focus)
                                            ? {
                                                path: normalizedSelection!.focus.path,
                                                offset: normalizedSelection!.focus.offset - 1,
                                            } : normalizedSelection!.focus,
                                    };
                                    const shortenedSelectionText = Editor.string(editor, normalizedShortenedSelection);
                                    if (removeCharText === shortenedSelectionText) {
                                        if (
                                            removeCharText === ''
                                            && annotation.quoteHistory[0].selections.length === 1
                                        ) {
                                            // Delete annotation
                                            deleteAnnotation = true;
                                            selectionUpdated = true;
                                            annotationUpdated = true;
                                            annotationsUpdated = true;
                                        } else if (
                                            removeCharText === ''
                                            && annotation.quoteHistory[0].selections.length > 1
                                        ) {
                                            // Remove selection from annotation
                                            // Omitting selection from updated quote will remove it from annotation
                                            selectionUpdated = true;
                                            annotationUpdated = true;
                                            annotationsUpdated = true;
                                        } else {
                                            // Update annotation quote
                                            // Modify text and selection to consider removed character
                                            updatedQuote.selections.push(shortenedSelection);
                                            updatedQuote.texts.push(removeCharText);
                                            selectionUpdated = true;
                                            annotationUpdated = true;
                                            annotationsUpdated = true;
                                        }

                                        break; // Get out of for loop that tests for removed character
                                    }
                                }

                                if (!selectionUpdated) {
                                    // Selection was not found, remove from annotation
                                    // Delete annotation if only has one selection
                                    if (annotation.quoteHistory[0].selections.length === 1) {
                                        // Delete annotation
                                        deleteAnnotation = true;
                                        selectionUpdated = true;
                                        annotationUpdated = true;
                                        annotationsUpdated = true;
                                    } else {
                                        // Remove selection from annotation
                                        // Omitting selection from updated quote will remove it from annotation
                                        selectionUpdated = true;
                                        annotationUpdated = true;
                                        annotationsUpdated = true;
                                    }
                                }
                            }
                        }
                    } else {
                        updatedQuote.selections.push(selections[i]);
                        updatedQuote.texts.push(quoteText);
                    }
                }

                if (annotationUpdated) {
                    const annotationCollection = process.env.NODE_ENV === 'production'
                        ? FIRESTORE_COLLECTION.annotations
                        : FIRESTORE_COLLECTION.stagingAnnotations;
                    if (deleteAnnotation) {
                        // Delete annotation
                        const remainingAnnotations = handleDeleteAnnotation(annotation, updatedAnnotations);
                        updatedAnnotations = [...remainingAnnotations];
                    } else {
                        // Determine if any selections can be merged
                        const mergedQuoteSelections = [];
                        const mergedQuoteTexts = [];
                        for (let i = 0; i < updatedQuote.selections.length; i += 1) {
                            const selection = updatedQuote.selections[i];
                            const selectionText = updatedQuote.texts[i];

                            if (i > 0) {
                                const previousSelection: Range = mergedQuoteSelections[mergedQuoteSelections.length - 1]!;
                                const previousSelectionText: string = mergedQuoteTexts[mergedQuoteSelections.length - 1];
                                const previousSelectionEnd = Point.isBefore(previousSelection!.anchor as Point, previousSelection!.focus)
                                    ? previousSelection!.focus.offset
                                    : previousSelection!.anchor.offset;
                                const selectionStart = Point.isBefore(selection!.anchor as Point, selection!.focus)
                                    ? selection!.anchor.offset
                                    : selection!.focus.offset;
                                if (previousSelectionEnd === selectionStart) {
                                    // Merge selections
                                    const mergedSelection = {
                                        anchor: Point.isBefore(previousSelection!.anchor as Point, previousSelection!.focus as Point)
                                            ? previousSelection!.anchor
                                            : previousSelection!.focus,
                                        focus: Point.isBefore(selection!.anchor as Point, selection!.focus as Point)
                                            ? selection!.focus
                                            : selection!.anchor,
                                    };
                                    mergedQuoteSelections[mergedQuoteSelections.length - 1] = mergedSelection;
                                    mergedQuoteTexts[mergedQuoteSelections.length - 1] = `${previousSelectionText}${selectionText}`;
                                } else {
                                    mergedQuoteSelections.push(selection);
                                    mergedQuoteTexts.push(selectionText);
                                }
                            } else {
                                // First elements in merge arrays
                                mergedQuoteSelections.push(selection);
                                mergedQuoteTexts.push(selectionText);
                            }
                        }

                        if (mergedQuoteSelections.length === 0) {
                            // Annotation had multiple selections, but they were all deleted
                            // Delete annotation
                            const remainingAnnotations = handleDeleteAnnotation(annotation, updatedAnnotations);
                            updatedAnnotations = [...remainingAnnotations];
                        }

                        const updatedQuoteHistory: IAnnotationQuote[] = [
                            {
                                ...updatedQuote,
                                selections: mergedQuoteSelections,
                                texts: mergedQuoteTexts,
                            },
                            ...annotation.quoteHistory,
                        ];
                        updatedAnnotations.splice(
                            updatedAnnotations.findIndex((a) => a.id === annotation.id),
                            1,
                            {
                                ...annotation,
                                quoteHistory: updatedQuoteHistory,
                            },
                        );
                        updateAnnotationInDB({
                            collection: annotationCollection,
                            id: annotation.id,
                            quoteHistory: updatedQuoteHistory,
                        });
                    }
                }
            });

            if (annotationsUpdated) {
                const updatedAnnotationsMap = new Map(updatedAnnotations.map((a) => [a.id, a]));
                setAnnotations(updatedAnnotationsMap);
            }
        }
    }, [
        value,
    ]);

    /**
     * Delay editor select all text so it takes
     * place after default selection behavior
     */
    const {
        start: timeoutSelectAllText,
    } = useTimeout(() => {
        // Select entire contents of the editor
        Transforms.select(editor, {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
        });
    }, SLATE_EDITOR_SELECT_ALL_TEXT_TIMEOUT_DURATION);

    // ===== Methods =====

    /**
     * Manages logic to save the state of the editor
     */
    const handleAutoSave = (): void => {
        if (!readOnly && postValue) {
            let parsedBeforeJSON;
            try {
                parsedBeforeJSON = JSON.parse(postValue);
            } catch (error) {
                throw Error('There was a problem interpreting the post content.');
            }

            const before = JSON.stringify(parsedBeforeJSON);
            const after = JSON.stringify(value);

            if (
                before !== after
                && !postValueIsSaving
            ) {
                let chapterIndex: number | undefined;
                let sectionIndex: number | undefined;
                if (
                    chapters
                    && currentResolutionLevel
                    && currentResolutionLevel.level < RESOLUTION_LEVEL.four
                ) {
                    // Determine which post value changed
                    let priorNodes = 0;
                    for (let i = 0; i < chapters.length; i += 1) {
                        chapterIndex = i;
                        if (chapters[i].sections) {
                            for (let j = 0; j < chapters[i].sections!.length; j += 1) {
                                sectionIndex = j;
                                const sectionValueString = chapters[i].sections![j].value;
                                const sectionValue = JSON.parse(sectionValueString);
                                const correspondingValueInPost = JSON.parse(after)
                                    .slice(priorNodes, priorNodes + sectionValue.length);
                                const correspondingValueInPostString = JSON.stringify(correspondingValueInPost);
                                if (sectionValueString !== correspondingValueInPostString) {
                                    break;
                                }
                                priorNodes += sectionValue.length;
                            }
                        } else {
                            // Nullify sectionIndex residue from previous iteration
                            sectionIndex = undefined;
                            const chapterValueString = chapters[i].value!;
                            const chapterValue = JSON.parse(chapterValueString);
                            const correspondingValueInPost = JSON.parse(after)
                                .slice(priorNodes, priorNodes + chapterValue.length);
                            const correspondingValueInPostString = JSON.stringify(correspondingValueInPost);
                            if (chapterValueString !== correspondingValueInPostString) {
                                break;
                            }
                            priorNodes += chapterValue.length;
                        }
                    }
                    let specificValue = JSON.parse(after).slice(priorNodes);
                    const bufferIndex = specificValue.findIndex((node: Node) => BottomBufferElement.isBottomBufferElement(node));
                    if (bufferIndex !== -1) {
                        specificValue = specificValue.slice(0, bufferIndex + 1);
                        const specificValueString = JSON.stringify(specificValue);
                        savePost(specificValueString, chapterIndex, sectionIndex);
                    } else {
                        throw Error('There was a problem saving the post. Unable to locate post value bottom buffer.');
                    }
                } else if (
                    chapters
                    && currentResolutionLevel
                    && currentResolutionLevel.level === RESOLUTION_LEVEL.four
                    && selectedPostValuePath.length === 1
                ) {
                    // Determine which post value changed
                    let priorNodes = 0;
                    const chapter = chapters[selectedPostValuePath[0]];
                    [chapterIndex] = selectedPostValuePath;
                    if (chapter.sections) {
                        for (let i = 0; i < chapter.sections.length; i += 1) {
                            sectionIndex = i;
                            const sectionValueString = chapter.sections![i].value;
                            const sectionValue = JSON.parse(sectionValueString);
                            const correspondingValueInPost = JSON.parse(after)
                                .slice(priorNodes, priorNodes + sectionValue.length);
                            const correspondingValueInPostString = JSON.stringify(correspondingValueInPost);
                            if (sectionValueString !== correspondingValueInPostString) {
                                break;
                            }
                            priorNodes += sectionValue.length;
                        }
                    }
                    let specificValue = (JSON.parse(after)).slice(priorNodes);
                    const bufferIndex = specificValue.findIndex((node: Node) => BottomBufferElement.isBottomBufferElement(node));
                    if (bufferIndex !== -1) {
                        specificValue = specificValue.slice(0, bufferIndex + 1);
                        const specificValueString = JSON.stringify(specificValue);
                        savePost(specificValueString, chapterIndex, sectionIndex);
                    } else {
                        throw Error('There was a problem saving the post. Unable to locate post value bottom buffer.');
                    }
                } else if (selectedPostValuePath.length === 2) {
                    // Resolution Level 5
                    // Proceed as normal
                    savePost(
                        after,
                        selectedPostValuePath[0],
                        selectedPostValuePath[1],
                    );
                } else if (selectedPostValuePath.length === 1) {
                    // Resolution Level 5
                    // Proceed as normal
                    savePost(
                        after,
                        selectedPostValuePath[0],
                    );
                } else {
                    // Resolution Level 5
                    // Proceed as normal
                    savePost(after);
                }
            } else if (postValueIsSaving && !queueSave) {
                setQueueSave(true);
            }
        } else {
            const val = JSON.stringify(value, null, 2);
            if (!postValueIsSaving) {
                let chapterIndex: number | undefined;
                let sectionIndex: number | undefined;
                if (
                    chapters
                    && currentResolutionLevel
                    && currentResolutionLevel.level < RESOLUTION_LEVEL.four
                ) {
                    // Determine which post value changed
                    chapterIndex = 0;
                    if (chapters[0].sections) {
                        // Change the first section
                        sectionIndex = 0;
                    } else {
                        // Nullify sectionIndex residue from previous iteration
                        sectionIndex = undefined;
                    }
                    savePost(val, chapterIndex, sectionIndex);
                } else if (
                    chapters
                    && currentResolutionLevel
                    && currentResolutionLevel.level === RESOLUTION_LEVEL.four
                    && selectedPostValuePath.length === 1
                ) {
                    // Determine which post value annotation is associated with
                    const chapter = chapters[selectedPostValuePath[0]];
                    [chapterIndex] = selectedPostValuePath;
                    if (chapter.sections) {
                        // Change the first section
                        sectionIndex = 0;
                    }
                    savePost(val, chapterIndex, sectionIndex);
                } else if (selectedPostValuePath.length === 2) {
                    // Resolution Level 5
                    // Proceed as normal
                    savePost(
                        val,
                        selectedPostValuePath[0],
                        selectedPostValuePath[1],
                    );
                } else if (selectedPostValuePath.length === 1) {
                    // Resolution Level 5
                    // Proceed as normal
                    savePost(
                        val,
                        selectedPostValuePath[0],
                    );
                } else {
                    // Resolution Level 5
                    // Proceed as normal
                    savePost(val);
                }
            } else if (postValueIsSaving && !queueSave) {
                setQueueSave(true);
            }
        }
    };

    /**
     * Manages the logic to correctly position the block toolbar
     */
    const updateBlockToolbarPosition = (): void => {
        const nativeSelection = window.getSelection();
        let y = 24;
        let x = -52;
        if (
            nativeSelection
            && nativeSelection.rangeCount > 0
            && containerRef.current
        ) {
            const range = nativeSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            y = rect.top - containerRef.current.getBoundingClientRect().top - 5;
            x = -52;
        }

        setBlockToolbarPosition({
            x,
            y,
        });
    };

    /**
     * Manages the logic to correctly position the selection toolbar
     */
    const updateSelectionToolbarPosition = (): void => {
        const nativeSelection = window.getSelection();
        let y = 24;
        let x = -52;
        if (
            nativeSelection
            && nativeSelection.rangeCount > 0
            && containerRef.current
            && selectionToolbarRef.current
        ) {
            const range = nativeSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const toolbarWidth = selectionToolbarRef.current.children[0]
                ? selectionToolbarRef.current.children[0].clientWidth
                : 0;
            y = (rect.top - containerRef.current.getBoundingClientRect().top
                - (selectionToolbarRef.current.offsetHeight + 7)
            );
            x = Math.max(
                ((rect.left - containerRef.current.getBoundingClientRect().left + rect.width / 2)
                    - (toolbarWidth / 2)
                ),
                0,
            );
        }
        setSelectionToolbarPosition({
            x,
            y,
        });
    };

    /**
     * Is reactive to changes to the slate value (making links)
     * and changes to the selection (placing cursor on links)
     */
    const getSelectedLinkURL = useMemo(() => {
        if (editor.selection && !readOnly) {
            const links = WebLinkHelpers.getSelectionWebLinks(editor);
            if (links.length === 1) {
                const [link] = links;
                return (link[0]as WebLinkNode).href;
            }
        }
        return null;
    }, [
        value,
        editor.selection,
    ]);

    /**
     * Is reactive to changes to the slate value (making links)
     * and changes to the selection (placing cursor on links)
     */
    const getSelectedLinkID = useMemo(() => {
        if (editor.selection && !readOnly) {
            const links = WebLinkHelpers.getSelectionWebLinks(editor);
            if (links.length === 1) {
                const [link] = links;
                return (link[0]as WebLinkNode).id;
            }
        }
        return null;
    }, [
        value,
        editor.selection,
    ]);

    /**
     * Manages setting the slate editor reference
     * @param ref ref to the slate editor
     */
    const setSlateEditorRef = (ref: Editor): void => {
        if (ref && setRef) {
            setRef(ref);
        }
    };

    /**
     * Manages the logic to move the cursor to the end of the document
     */
    const moveCursorToEndOfDocument = (): void => {
        const [lastTextNode, lastTextNodePath] = Editor.leaf(editor, [], { edge: 'end' });
        const lastDocumentPoint = {
            path: lastTextNodePath,
            offset: lastTextNode.text.length,
        };
        Transforms.select(editor, lastDocumentPoint);
        ReactEditor.focus(editor);
    };

    /**
     * Places a specified emoji in the editor
     * @param emojiObj emoji object
     */
    const handleEmojiSelect = (emojiObj: IEmoji): void => {
        ReactEditor.focus(editor);
        Transforms.insertText(editor, emojiObj.char);
    };

    /**
     * Manages response to editor hovering over
     * @param e mouse event
     */
    const onEditableEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editor,
                [readOnly ? CURSOR_SIGN.highlight : CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    /**
     * Manages response to editor no longer hovering over
     * @param e mouse event
     */
    const onEditableLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    /**
     * Manages response to editor being focused
     */
    const onEditableFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    /**
     * Manages response to editor being blurred
     */
    const onEditableBlur = (): void => {
        setInputFocused(false);
    };

    /**
     * Manages completion update of an uploading media
     * @param mediaItem media item that was uploaded
     * @param fileURL storage url of the uploaded file
     */
    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        fileURL: string,
    ): void => {
        // Select end of document if no selection
        if (!editor.selection) {
            moveCursorToEndOfDocument();
        }

        // Update uploading media item
        updateUploadingMedia({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...uploadingMedia.get(mediaItem.id)!,
            url: fileURL,
        });

        const fileType = mediaItem.file.type.split('/')[0];
        switch (fileType) {
        case 'audio': {
            // Create Audio Note
            AudioNoteHelpers.insertAudioNote(editor, {
                id: mediaItem.id,
                filePath: mediaItem.filePath,
                title: mediaItem.file.name,
                uploaded: true,
            });
            break;
        }
        case 'image': {
            // Create Figure
            FigureHelpers.insertFigure(
                editor,
                mediaItem.id,
                undefined,
                mediaItem.filePath,
                mediaItem.file.name,
            );
            break;
        }
        default:
            break;
        }
    };

    /**
     * Manages progress update of an uploading media
     * @param mediaItem media item that is uploading
     * @param _snapshot upload task snapshot
     * @param progress perentage of upload completed
     */
    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            setUploadingMedia(mediaItem);
        }
    };

    /**
     * Manages presenting a dialog to the user if they are about to abandon
     * an unsaved audio note, inquiring if they would like to save it
     * @param _e unused event
     */
    const handleUnsavedAudioNoteDialog = (_e: Event): void => {
        if (isRecordingAudioNote) {
            // Inform user that they are attempting to save while audio
            // recording in progress
            setModal({
                visible: true,
                message: SAVE_POST_DURING_AUDIO_RECORDING_ERROR,
                proceedCallback: () => {
                    handleAutoSave();
                    setModal({
                        visible: false,
                        message: SAVE_POST_DURING_AUDIO_RECORDING_ERROR,
                    });
                },
                rejectCallback: () => {
                    setModal({
                        visible: false,
                        message: SAVE_POST_DURING_AUDIO_RECORDING_ERROR,
                    });
                },
            });
        }
    };

    /**
     * Manages response to an image fully loading
     */
    const onImageLoad = (): void => {
        setRerenderMeasuringElements(true);
        setRemeasurePostHeight(true);
    };

    /**
     * Manages response to an embed fully loading
     */
    const onEmbedLoad = (): void => {
        setRerenderMeasuringElements(true);
        setRemeasurePostHeight(true);
    };

    /**
     * Signals to remeasure the height of the post
     */
    const onAudioNoteAdjustHeight = (): void => {
        setRerenderMeasuringElements(true);
        setRemeasurePostHeight(true);
    };

    // ===== Rendering =====

    const renderModal = (): React.ReactElement => (
        <Modal
            hasContainer
            hasSound={hasSound}
            isOpen={modal.visible}
            closeModal={modal.rejectCallback}
        >
            <GenericModal
                color={themeObj.verascopeColor.purple200}
                message={modal.message}
                callback={modal.proceedCallback}
                hideCancel={!modal.rejectCallback}
            />
        </Modal>
    );

    const renderElement = useCallback(({
        element,
        attributes,
        children,
    } : {
        element: any,
        attributes: any,
        children: any,
    }) => {
        if (BlockQuoteElement.isBlockQuoteElement(element)) {
            return (
                <BlockQuote
                    attributes={attributes}
                >
                    {children}
                </BlockQuote>
            );
        }

        if (BottomBufferElement.isBottomBufferElement(element)) {
            return (
                <BottomBuffer
                    adjustSelectionAroundBottomBuffer={() => BottomBufferHelpers.adjustSelectionAroundBottomBuffer(editor)}
                    height={bufferHeight}
                    readOnly={readOnly}
                    editorType={type}
                    currentResolutionLevel={currentResolutionLevel}
                    attributes={attributes}
                >
                    {children}
                </BottomBuffer>
            );
        }

        if (CaptionElement.isCaptionElement(element)) {
            return (
                <Caption
                    id={element.id}
                    text={Node.string(element)}
                    contentType={element.figureContentType}
                    readOnly={readOnly}
                    attributes={attributes}
                    placeholder="Write a caption..."
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                >
                    {children}
                </Caption>
            );
        }

        if (DividerElement.isDividerElement(element)) {
            return (
                <Divider
                    color={color}
                    readOnly={readOnly}
                    attributes={attributes}
                >
                    {children}
                </Divider>
            );
        }

        if (FigureElement.isFigureElement(element)) {
            const { url, filePath, id: figureId } = element.children[0];
            switch (element.figureContentType) {
            case FigureContentTypes.youtube:
                return (
                    <YouTubeEmbed
                        id={figureId}
                        url={url}
                        color={color}
                        readOnly={readOnly}
                        user={user}
                        currentSessionId={currentSessionId}
                        postId={id}
                        editorType={type}
                        attributes={attributes}
                        onLoad={onEmbedLoad}
                    >
                        {children}
                    </YouTubeEmbed>
                );
            case FigureContentTypes.twitter:
                return (
                    <TwitterEmbed
                        id={figureId}
                        url={url}
                        color={color}
                        readOnly={readOnly}
                        user={user}
                        currentSessionId={currentSessionId}
                        postId={id}
                        editorType={type}
                        attributes={attributes}
                        onLoad={onEmbedLoad}
                    >
                        {children}
                    </TwitterEmbed>
                );
            case FigureContentTypes.spotify:
                return (
                    <SpotifyEmbed
                        url={url}
                        color={color}
                        attributes={attributes}
                        onLoad={onEmbedLoad}
                    >
                        {children}
                    </SpotifyEmbed>
                );
            case FigureContentTypes.vimeo:
                return (
                    <VimeoEmbed
                        id={figureId}
                        url={url}
                        color={color}
                        readOnly={readOnly}
                        user={user}
                        currentSessionId={currentSessionId}
                        postId={id}
                        editorType={type}
                        attributes={attributes}
                        onLoad={onEmbedLoad}
                    >
                        {children}
                    </VimeoEmbed>
                );
            default:
                return (
                    <Figure
                        url={url}
                        user={user}
                        filePath={filePath}
                        editorType={type}
                        parentRef={parentRef}
                        editorRef={containerRef.current}
                        attributes={attributes}
                        enableGallery={enableGallery}
                        layoutType={element.orientation}
                        isGalleryChild={element.isGalleryChild}
                        rerender={rerenderMeasuringElements}
                        setRerender={setRerenderMeasuringElements}
                        createGallery={(imgId, imgURL, path) => FigureHelpers.bundleFigureWithNewFigureIntoGallery(editor, imgId, imgURL, path)}
                        uploadingMedia={uploadingMedia}
                        setUploadingMedia={setUploadingMedia}
                        updateUploadingMedia={updateUploadingMedia}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                    >
                        {children}
                    </Figure>
                );
            }
        }

        if (AudioNoteElement.isAudioNoteElement(element)) {
            return (
                <AudioNote
                    id={element.id}
                    user={user}
                    hasSound={hasSound}
                    editorType={type}
                    filePath={element.filePath}
                    title={element.title}
                    description={element.description}
                    color={color}
                    readOnly={readOnly}
                    attributes={attributes}
                    currentSessionId={currentSessionId}
                    postId={id}
                    parentRef={containerRef.current || undefined}
                    isRecordingAudioNote={isRecordingAudioNote}
                    setIsRecordingAudioNote={setIsRecordingAudioNote}
                    broadcastHeightAdjusted={onAudioNoteAdjustHeight}
                    uploadingMedia={uploadingMedia}
                    setUploadingMedia={setUploadingMedia}
                    updateUploadingMedia={updateUploadingMedia}
                    removeUploadingMedia={removeUploadingMedia}
                    updateAudioNote={({
                        id: audioNoteId,
                        filePath,
                        title,
                        uploaded,
                        description,
                    }) => AudioNoteHelpers.updateAudioNote(editor, {
                        id: audioNoteId,
                        filePath,
                        title,
                        uploaded,
                        description,
                    })}
                    {...(readOnly
                        ? {
                            isAuthor,
                        } : {}
                    )}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    setSnackbarData={setSnackbarData}
                >
                    {children}
                </AudioNote>
            );
        }

        if (GalleryElement.isGalleryElement(element)) {
            return (
                <Gallery
                    attributes={attributes}
                    rerender={rerenderMeasuringElements}
                    setRerender={setRerenderMeasuringElements}
                >
                    {children}
                </Gallery>
            );
        }

        if (HeaderElement.isH1(element)) {
            return (
                <HeaderOne
                    isFirstElement={element.isFirstElement}
                    attributes={attributes}
                >
                    {children}
                </HeaderOne>
            );
        }

        if (HeaderElement.isH2(element)) {
            return (
                <HeaderTwo
                    isFirstElement={element.isFirstElement}
                    attributes={attributes}
                >
                    {children}
                </HeaderTwo>
            );
        }

        if (
            FigureContentElement.isFigureContentElement(element)
            && element.figureContentType === FigureContentTypes.image
        ) {
            return (
                <Image
                    id={element.id}
                    user={user}
                    color={color}
                    readOnly={readOnly}
                    editorType={type}
                    imagePath={element.filePath}
                    attributes={attributes}
                    layoutType={element.orientation}
                    isGalleryChild={element.isGalleryChild}
                    onLoad={onImageLoad}
                    setImagePath={(filePath) => FigureHelpers.setFigureUrlFilePath(editor, undefined, filePath)}
                    setLayout={(orientation) => FigureHelpers.setFigureOrientation(editor, orientation)}
                    removeFigure={() => FigureHelpers.removeFigure(editor)}
                    swapFigureOrder={() => FigureHelpers.shuffleGalleryOrder(editor)}
                    uploadingMedia={uploadingMedia}
                    setUploadingMedia={setUploadingMedia}
                    updateUploadingMedia={updateUploadingMedia}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setSnackbarData={setSnackbarData}
                >
                    {children}
                </Image>
            );
        }

        if (WebLinkElement.isWebLinkElement(element)) {
            return (
                <InlineLink
                    id={element.id}
                    color={color}
                    href={element.href}
                    readOnly={readOnly}
                    user={user}
                    currentSessionId={currentSessionId}
                    postId={id}
                    editorType={type}
                    hasSuccess={hasSuccess}
                    hasError={hasError}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    parentRef={containerRef.current}
                    attributes={attributes}
                >
                    {children}
                </InlineLink>
            );
        }

        if (ResolutionLevelElement.isResolutionLevelElement(element)) {
            return (
                <ResolutionLevel
                    color={color}
                    level={{
                        level: element.level,
                        icon: resolutionLevelIcons?.get(element.level) || null,
                    }}
                    currentResolutionLevel={currentResolutionLevel}
                    isInline={element.levelType === RESOLUTION_LEVEL_TYPE.inline}
                    readOnly={readOnly}
                    user={user}
                    currentSessionId={currentSessionId}
                    hasSuccess={hasSuccess}
                    hasError={hasError}
                    unusedResolutionLevelCount={unusedResolutionLevelCount}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    parentRef={containerRef.current}
                    attributes={attributes}
                >
                    {children}
                </ResolutionLevel>
            );
        }

        if (ListElement.isListElement(element)) {
            const listType = ListElement.isOL(element)
                ? ListElement.LIST_TYPE.ordered : ListElement.LIST_TYPE.unordered;
            return (
                <List
                    type={listType}
                    attributes={attributes}
                >
                    {children}
                </List>
            );
        }

        if (ListItemElement.isListItemElement(element)) {
            return (
                <ListItem
                    attributes={attributes}
                >
                    {children}
                </ListItem>
            );
        }

        return (
            <Paragraph
                hasSuccess={hasSuccess}
                hasError={hasError}
                attributes={attributes}
            >
                {children}
            </Paragraph>
        );
    }, [
        id,
        color,
        user,
        readOnly,
        hasSound,
        type,
        currentSessionId,
        type,
        uploadingMedia,
        currentResolutionLevel,
        setUploadingMedia,
        updateUploadingMedia,
        rerenderMeasuringElements,
        containerRef.current,
        parentRef,
        isRecordingAudioNote,
        onAudioNoteAdjustHeight,
        onCursorEnter,
        onCursorLeave,
        setInputFocused,
        onImageLoad,
        onEmbedLoad,
        setSnackbarData,
        hasSuccess,
        hasError,
        resolutionLevelIcons,
    ]);

    const renderLeaf = useCallback(({
        attributes,
        children,
        leaf,
    }: {
        attributes: any,
        children: any,
        leaf: any,
    }) => {
        const annotationDecorations = Object.keys(leaf).filter((key) => key.includes(ANNOTATION_DECORATION_PREFIX));
        const revealQuote = Object.keys(leaf)
            .filter((key) => key.includes(REVEAL_QUOTE_DECORATION_PREFIX))
            .reduce((acc, key) => acc || leaf[key], false);
        return (
            <SlateLeaf
                {...attributes}
                {...(leaf.postQuote && { 'data-quote': 'post-quote' })} // When highlighting
                {...(leaf.annotationQuote && { 'data-quote': 'annotation-quote' })} // When revealing annotation context
                {...(annotationDecorations.reduce((acc, key) => {
                    acc[`data-${key}`] = leaf[key];
                    return acc;
                }, {} as Record<string, string>))}
                bold={leaf[EMPHASIZER_MARK.bold]}
                italics={leaf[EMPHASIZER_MARK.italicize]}
                underline={leaf[EMPHASIZER_MARK.underline]}
                strikethrough={leaf[EMPHASIZER_MARK.strikethrough]}
                highlightColor={leaf.postQuote || (leaf.annotationQuote && revealQuote)
                    ? leaf.highlightColor
                    : null}
                highlightDuration={HIGHLIGHT_TRANSITION_DURATION}
            >
                {children}
            </SlateLeaf>
        );
    }, []);

    return (
        <Container
            ref={containerRef}
            visible={!hide}
            parentWidth={parentWidth}
            bufferHeight={bufferHeight}
            withBottomBuffer={!!currentResolutionLevel
            && currentResolutionLevel.level !== RESOLUTION_LEVEL.five}
        >
            {renderModal()}
            {initializedValue && (
                <Slate
                    editor={editor}
                    value={value}
                    onChange={(val) => {
                        setValue(val);
                        const nativeSelection = window.getSelection();

                        if (
                            !readOnly
                            && !updateToolbars
                            && nativeSelection
                            && nativeSelection.isCollapsed
                        ) {
                            // Update only if not selecting text
                            setUpdateToolbars(true);
                        }
                    }}
                >
                    <Editable
                        {...(readOnly
                            ? {
                                readOnly: true,
                            } : {}
                        )}
                        data-id={id}
                        className={`${HOVER_TARGET_CLASSNAME} ${SLATE_EDITOR_CLASSNAME} ${SLATE_EDITOR_POST_EDITOR_EDITABLE_CLASSNAME}`}
                        spellCheck
                        decorate={decorate}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={!readOnly ? placeholder : undefined}
                        onKeyDown={handleOnKeyDown}
                        onMouseEnter={onEditableEnter}
                        onMouseLeave={onEditableLeave}
                        onFocus={onEditableFocus}
                        onBlur={onEditableBlur}
                    />
                    {!readOnly
                    && (
                        <PortableToolbar
                            // Post Editor Only
                            handleEmojiSelect={handleEmojiSelect}
                            onSelectionToolbarVisibilityChange={setShowSelectionToolbar}
                            // Common to PostEditor and AnnotationEditor
                            user={user}
                            type={EDITOR_TOOLBAR_TYPE.selection}
                            hasSound={hasSound}
                            buttonLength={PORTABLE_TOOLBAR_BUTTON_LENGTH}
                            editorType={type}
                            editorId={id}
                            setRef={(ref) => { selectionToolbarRef.current = ref; }}
                            color={color}
                            value={value}
                            selectedLinkURL={getSelectedLinkURL}
                            selectedLinkID={getSelectedLinkID}
                            top={selectionToolbarPosition.y}
                            left={selectionToolbarPosition.x}
                            defaultOpenGroups={new Map([
                                [EDITOR_TOOLBAR_TOOL_GROUP.layout, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.media, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.text, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.embeddings, true],
                            ])}
                            selection={editor.selection}
                            setSelection={(selection) => Transforms.select(editor, selection)}
                            updatePosition={updateSelectionToolbarPosition}
                            toggleBold={() => EmphasizerHelpers.toggleBold(editor)}
                            containsBold={EmphasizerHelpers.selectionContainsBold(editor)}
                            toggleItalics={() => EmphasizerHelpers.toggleItalicize(editor)}
                            containsItalics={EmphasizerHelpers.selectionContainsItalicize(editor)}
                            toggleStrikethrough={() => EmphasizerHelpers.toggleStrikethrough(editor)}
                            containsStrikethrough={EmphasizerHelpers.selectionContainsStrikethrough(editor)}
                            toggleUnderline={() => EmphasizerHelpers.toggleUnderline(editor)}
                            containsUnderline={EmphasizerHelpers.selectionContainsUnderline(editor)}
                            toggleHeaderOne={() => HeaderHelpers.toggleH1(editor)}
                            containsHeaderOne={HeaderHelpers.hasSelectedH1(editor)}
                            toggleHeaderTwo={() => HeaderHelpers.toggleH2(editor)}
                            containsHeaderTwo={HeaderHelpers.hasSelectedH2(editor)}
                            toggleNumberedList={() => ListHelpers.toggleOL(editor)}
                            containsNumberedList={ListHelpers.hasSelectedOL(editor)}
                            toggleBulletedList={() => ListHelpers.toggleUL(editor)}
                            containsBulletedList={ListHelpers.hasSelectedUL(editor)}
                            toggleQuote={() => BlockQuoteHelpers.toggleBlockQuote(editor)}
                            containsQuote={BlockQuoteHelpers.hasSelectedBlockQuote(editor)}
                            toggleDivider={() => DividerHelpers.toggleDivider(editor)}
                            containsDivider={DividerHelpers.hasSelectedDivider(editor)}
                            toggleResolution={() => {
                                if (currentResolutionLevel) {
                                    // Sets resolution level to the current resolution level
                                    // If user is on resolution level 5, set it to resolution level 5
                                    // To push to resolution 4, user will have to be in resolution level 4
                                    ResolutionLevelHelpers.toggleResolution(editor, currentResolutionLevel.level);
                                }
                            }}
                            containsResolution={!!currentResolutionLevel
                                && ResolutionLevelHelpers.hasSelectionResolutionLevels(editor)
                                && !ResolutionLevelHelpers.selectionHasHigherResolutionLevels(editor, currentResolutionLevel.level)}
                            createFigure={(figureId, filePath, caption) => FigureHelpers.insertFigure(editor, figureId, undefined, filePath, caption)}
                            containsFigure={FigureHelpers.hasSelectedImageFigure(editor)}
                            createLink={(linkId, href, selection) => {
                                if (selection) {
                                    Transforms.select(editor, selection);
                                }
                                WebLinkHelpers.wrapSelectionInLinkCmd(editor, linkId, href);
                            }}
                            updateLink={(linkId, href, selection) => {
                                if (selection) {
                                    Transforms.select(editor, selection);
                                }
                                WebLinkHelpers.setWebLinkHref(editor, linkId, href);
                            }}
                            removeLink={() => WebLinkHelpers.removeWebLink(editor)}
                            containsLink={WebLinkHelpers.hasSelectedLink(editor)}
                            selectEndDocument={moveCursorToEndOfDocument}
                            createYouTubeEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsYouTube={FigureHelpers.hasSelectedYouTubeFigure(editor)}
                            createTwitterEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsTwitter={FigureHelpers.hasSelectedTwitterFigure(editor)}
                            createSpotifyEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsSpotify={FigureHelpers.hasSelectedSpotifyFigure(editor)}
                            createVimeoEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsVimeo={FigureHelpers.hasSelectedVimeoFigure(editor)}
                            insertAudioNote={(audioNoteId) => AudioNoteHelpers.insertAudioNote(editor, { id: audioNoteId })}
                            containsAudioNote={AudioNoteHelpers.hasSelectedAudioNote(editor)}
                            uploadingMedia={uploadingMedia}
                            setUploadingMedia={setUploadingMedia}
                            updateUploadingMedia={updateUploadingMedia}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            setInputFocused={setInputFocused}
                        />
                    )}
                    {!readOnly
                    && (
                        <PortableToolbar
                            // Post Editor Only
                            type={EDITOR_TOOLBAR_TYPE.block}
                            editorType={type}
                            editorId={id}
                            value={value}
                            top={blockToolbarPosition.y}
                            left={blockToolbarPosition.x}
                            hide={showSelectionToolbar}
                            handleEmojiSelect={handleEmojiSelect}
                            // Common to PostEditor and AnnotationEditor
                            user={user}
                            hasSound={hasSound}
                            color={color}
                            buttonLength={PORTABLE_TOOLBAR_BUTTON_LENGTH}
                            defaultOpenGroups={new Map([
                                [EDITOR_TOOLBAR_TOOL_GROUP.text, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.layout, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.media, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.embeddings, true],
                            ])}
                            selection={editor.selection}
                            setSelection={(selection) => Transforms.select(editor, selection)}
                            toggleBold={() => EmphasizerHelpers.toggleBold(editor)}
                            containsBold={EmphasizerHelpers.selectionContainsBold(editor)}
                            toggleItalics={() => EmphasizerHelpers.toggleItalicize(editor)}
                            containsItalics={EmphasizerHelpers.selectionContainsItalicize(editor)}
                            toggleStrikethrough={() => EmphasizerHelpers.toggleStrikethrough(editor)}
                            containsStrikethrough={EmphasizerHelpers.selectionContainsStrikethrough(editor)}
                            toggleUnderline={() => EmphasizerHelpers.toggleUnderline(editor)}
                            containsUnderline={EmphasizerHelpers.selectionContainsUnderline(editor)}
                            toggleHeaderOne={() => HeaderHelpers.toggleH1(editor)}
                            containsHeaderOne={HeaderHelpers.hasSelectedH1(editor)}
                            toggleHeaderTwo={() => HeaderHelpers.toggleH2(editor)}
                            containsHeaderTwo={HeaderHelpers.hasSelectedH2(editor)}
                            toggleNumberedList={() => ListHelpers.toggleOL(editor)}
                            containsNumberedList={ListHelpers.hasSelectedOL(editor)}
                            toggleBulletedList={() => ListHelpers.toggleUL(editor)}
                            containsBulletedList={ListHelpers.hasSelectedUL(editor)}
                            toggleQuote={() => BlockQuoteHelpers.toggleBlockQuote(editor)}
                            containsQuote={BlockQuoteHelpers.hasSelectedBlockQuote(editor)}
                            toggleDivider={() => DividerHelpers.toggleDivider(editor)}
                            containsDivider={DividerHelpers.hasSelectedDivider(editor)}
                            toggleResolution={() => {
                                if (currentResolutionLevel) {
                                    // Sets resolution level to the current resolution level
                                    // If user is on resolution level 5, set it to resolution level 5
                                    // To push to resolution 4, user will have to be in resolution level 4
                                    ResolutionLevelHelpers.toggleResolution(editor, currentResolutionLevel.level);
                                }
                            }}
                            containsResolution={!!currentResolutionLevel
                                && ResolutionLevelHelpers.hasSelectionResolutionLevels(editor)
                                && !ResolutionLevelHelpers.selectionHasHigherResolutionLevels(editor, currentResolutionLevel.level)}
                            createFigure={(figureId, filePath, caption) => FigureHelpers.insertFigure(editor, figureId, undefined, filePath, caption)}
                            containsFigure={FigureHelpers.hasSelectedImageFigure(editor)}
                            createLink={(linkId, href, selection) => {
                                if (selection) {
                                    Transforms.select(editor, selection);
                                }
                                WebLinkHelpers.wrapSelectionInLinkCmd(editor, linkId, href);
                            }}
                            updateLink={(linkId, href, selection) => {
                                if (selection) {
                                    Transforms.select(editor, selection);
                                }
                                WebLinkHelpers.setWebLinkHref(editor, linkId, href);
                            }}
                            removeLink={() => WebLinkHelpers.removeWebLink(editor)}
                            // We leave out containsLink because Block Toolbar should not show it as applied
                            selectEndDocument={moveCursorToEndOfDocument}
                            createYouTubeEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsYouTube={FigureHelpers.hasSelectedYouTubeFigure(editor)}
                            createTwitterEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsTwitter={FigureHelpers.hasSelectedTwitterFigure(editor)}
                            createSpotifyEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsSpotify={FigureHelpers.hasSelectedSpotifyFigure(editor)}
                            createVimeoEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsVimeo={FigureHelpers.hasSelectedVimeoFigure(editor)}
                            insertAudioNote={(audioNoteId) => AudioNoteHelpers.insertAudioNote(editor, { id: audioNoteId })}
                            containsAudioNote={AudioNoteHelpers.hasSelectedAudioNote(editor)}
                            uploadingMedia={uploadingMedia}
                            setUploadingMedia={setUploadingMedia}
                            updateUploadingMedia={updateUploadingMedia}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            setInputFocused={setInputFocused}
                        />
                    )}
                </Slate>
            )}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    parentWidth: number | string | null,
    visible: boolean,
    withBottomBuffer: boolean,
    bufferHeight: number,
}
const Container = styled.div<ContainerProps>`
    position: relative;
    width: 100%;
    height: fit-content;
    max-width: ${({ parentWidth }) => (typeof parentWidth === 'string'
        ? parentWidth
        : `${POST_EDITOR_MAX_WIDTH}px`
    )};
    margin: 0 auto;
    padding-bottom: ${({ withBottomBuffer, bufferHeight }) => `${withBottomBuffer
        ? bufferHeight
        : 0}px`};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    background: inherit;
    transition: ${({ theme }) => `opacity 300ms ${theme.motion.delayEasing}`};

    // Add background to Editable div so that Slate components can
    // inherit the background color
    & > .${SLATE_EDITOR_POST_EDITOR_EDITABLE_CLASSNAME} {
        background: inherit;
    }
`;

export default PostEditor;
