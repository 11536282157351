/* eslint-disable no-bitwise */
const toUTF16 = (codePoint: number): string => {
    const TEN_BITS = 0b1111111111; // parseInt('1111111111', 2)

    const u = (codeUnit: number): string => `\\u${codeUnit.toString(16).toUpperCase()}`;

    if (codePoint <= 0xFFFF) {
        return u(codePoint);
    }

    const updatedCodePoint = codePoint - 0x10000;

    // Shift right to get to most significant 10 bits
    const leadSurrogate = 0xD800 + (updatedCodePoint >> 10);

    // Mask to get least significant 10 bits
    const tailSurrogate = 0xDC00 + (updatedCodePoint & TEN_BITS);

    return u(leadSurrogate) + u(tailSurrogate);
};

export default toUTF16;
