// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeGetTaxTransaction = async ({
    test,
    taxTransactionId,
}: {
    test: boolean,
    taxTransactionId: string,
}): Promise<Stripe.Tax.Transaction> => {
    const functions = getFunctions();
    const getTaxTransaction = httpsCallable<
        {
            test: boolean,
            taxTransactionId: string,
        },
        Stripe.Tax.Transaction | Error
    >(
        functions,
        'stripeGetTaxTransaction',
    );

    return getTaxTransaction({
        test,
        taxTransactionId,
    }).then((result) => {
        const taxTransaction = result.data as Stripe.Tax.Transaction;
        return taxTransaction;
    }).catch((err) => err);
};

export default stripeGetTaxTransaction;
