// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';

// ===== Interfaces =====

import {
    IShippoAddressObject,
}                               from '../interfaces';

const shippoAddressValidation = async (
    {
        test,
        address,
    }: {
        test: boolean,
        address: IShippoAddressObject,
    },
): Promise<Shippo.Address | undefined> => {
    const functions = getFunctions();
    const validator = httpsCallable<
        {
            test: boolean,
            address: IShippoAddressObject,
        },
        Shippo.Address | undefined
    >(
        functions,
        'shippoAddressValidation',
    );

    return validator({
        test,
        address,
    }).then((result) => {
        const validatedAddress = result.data as Shippo.Address | undefined;
        return validatedAddress;
    }).catch((err) => err);
};

export default shippoAddressValidation;
