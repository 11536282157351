// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useEffect,
}                                       from 'react';
import { ReactSVG }                     from 'react-svg';
import {
    useDrag,
    useDrop,
}                                       from 'react-dnd';
import { v4 as uuidv4 }                 from 'uuid';

// ===== Components =====

import ReaderOutlineSection             from '../ReaderOutlineSection';
import Tooltip                          from '../Tooltip';

// ===== Hooks =====

import {
    useTimeout,
}                                       from '../../hooks';

// ===== Enums =====

import {
    DRAGGABLE_COMPONENT_TYPE,
    CURSOR_TARGET,
    TOOLTIP_TYPE,
    USER_ACTION_TYPE,
    INTERACTABLE_OBJECT,
    DRAG_DIRECTION,
}                                       from '../../enums';
import {
    RESOLUTION_LEVEL,
}                                       from '../Editor/elements/enums';

// ===== Services =====

import {
    playAudio,
    recordUserAction,
    detectTouchDevice,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IPostChapterItem,
    ISnackbarItem,
    IPostPathEvent,
    IResolutionLevelItem,
}                                       from '../../interfaces';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    EDITOR_CHAPTER_DESCRIPTION_INPUT_ID_PREFIX,
    EDITOR_CHAPTER_TITLE_INPUT_ID_PREFIX,
    HOVER_TARGET_CLASSNAME,
    EDITOR_CHAPTER_TITLE_INPUT_CLASSNAME,
    EDITOR_CHAPTER_DESCRIPTION_INPUT_CLASSNAME,
    OUTLINE_DRAG_INDICATOR_TRANSITION_DURATION,
    OUTLINE_DRAG_INDICATOR_X_OFFSET,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    GRAB_DRAG_INDICATOR_DELAY_DURATION,
    SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
}                                       from '../../constants/generalConstants';
import KEYCODE                          from '../../constants/keycodes';
import CURSOR_SIGN                      from '../../constants/cursorSigns';

// ===== Sounds =====

import InputClick                       from '../../sounds/button_click.mp3';
import ObjectGrasp                      from '../../sounds/object_grasp.mp3';
import ObjectThump                      from '../../sounds/object_thump.mp3';

// ===== Images =====

import MoveSign                         from '../../images/move.svg';
import GrabSign                         from '../../images/grab.svg';
import PlusIcon                         from '../../images/editor/plus.svg';
import CautionIcon                      from '../../images/caution.svg';
import PencilIcon                       from '../../images/editor/pencil.svg';
import HighlightIcon                    from '../../images/editor/highlight.svg';
import DragIndicatorIcon                from '../../images/editor/drag-indicator.svg';

// ===== Styles =====

import {
    Container,
    OutlineChapterHead,
    OutlineChapterTitleInputContainer,
    OutlineChapterDescriptionInput,
    OutlineChapterTitleInput,
    OutlineChapterDragIndicator,
    OutlineNumberText,
    OutlineChapterBody,
    AddSectionButton,
    AddSectionButtonIcon,
    ChapterNonEmptyIndicator,
    ChapterAnnotationsIndicator,
}                                       from './styles';

interface Props {
    hasSound: boolean,
    user: IUserItem | null,
    postId: string,
    index: number,
    chapter: IPostChapterItem,
    currentSessionId: string | null,
    currentResolutionLevel: IResolutionLevelItem | null,
    chapters: IPostChapterItem[],
    postValueIsSaving: boolean,
    updateChapters: (chapters: IPostChapterItem[], value?: string) => Promise<void>,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    selectedPostValuePath: number[],
    onChangeSelectedPostValuePath: (event: IPostPathEvent) => void,
}
function ReaderOutlineChapter({
    hasSound,
    user,
    index,
    postId,
    chapter,
    currentSessionId,
    currentResolutionLevel,
    chapters,
    postValueIsSaving,
    updateChapters,
    setCursorSigns,
    onCursorEnter,
    onCursorLeave,
    setSnackbarData,
    selectedPostValuePath,
    onChangeSelectedPostValuePath,
}: Props): JSX.Element {
    // ===== General Constants =====

    const SNACKBAR_MESSAGE_DELETE_CHAPTER_WITH_VALUE_ERROR = 'Chapter text must be cleared before it can be deleted.';
    const SNACKBAR_MESSAGE_DELETE_CHAPTER_WITH_SECTIONS_VALUE_ERROR = 'Text in chapter sections must be cleared before it can be deleted.';
    const SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR = 'Chapter is saving. Please try again in a few seconds.';

    // ===== Refs =====

    const descriptionInputRef = useRef<HTMLDivElement>(null);

    // ----- Sound Clips
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());
    const dragIndicatorGraspClip = useRef<HTMLAudioElement>(new Audio());
    const dragIndicatorThumpClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // Set up drag source for chapter
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: DRAGGABLE_COMPONENT_TYPE.editorOutlineTitle,
            item: {
                id: chapter.id,
                index,
            },
            canDrag: () => !postValueIsSaving,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { index: chapterIndex } = item;
                const didDrop = monitor.didDrop();

                // If the chapter was dropped outside the list, move it back to its original position
                if (!didDrop) {
                    moveChapter(index, chapterIndex);
                }
            },
        }),
        [
            index,
            chapters,
        ],
    );

    // Set up drop target for chapter
    const [, drop] = useDrop(
        () => ({
            accept: DRAGGABLE_COMPONENT_TYPE.editorOutlineTitle,
            hover({ id, index: draggedIndex }: { id: string, index: number }) {
                // If the chapter is being dragged over itself, do nothing
                if (id !== chapter.id) {
                    moveChapter(draggedIndex, index);
                }
            },
            drop({ id, index: oldIndex }: { id: string, index: number }) {
                // If the chapter is being dropped on itself, do nothing
                if (id === chapter.id) {
                    // Update selected post value path
                    const updatedPath = [index];
                    if (
                        chapter.sections
                        && selectedPostValuePath.length > 1
                        && selectedPostValuePath[0] === oldIndex
                    ) {
                        // Add section if previous selected post value path was a section
                        // from the same chapter
                        updatedPath.push(selectedPostValuePath[1]);
                    }
                    onChangeSelectedPostValuePath({
                        path: updatedPath,
                    });
                }
            },
        }),
        [chapters],
    );

    // ===== Animation Constants =====

    // Duration (in milliseconds) for outline chapter head to respond to hover over
    const OUTLINE_CHAPTER_HEAD_TRANSITION_DURATION = 200;
    // Duration (in milliseconds) for outline chapter section to respond to hover over
    const OUTLINE_CHAPTER_SECTION_TRANSITION_DURATION = 200;
    // Duration (in milliseconds) for add section button to respond to hover over
    const ADD_SECTION_BUTTON_TRANSITION_DURATION = 150;
    // Duration (in milliseconds) for non-empty chapter indicator to appear
    const CHAPTER_INDICATOR_TRANSITION_DURATION = GRAB_DRAG_INDICATOR_DELAY_DURATION;

    // ===== Methods =====

    /**
     * Manages response to post title or subtitle input hovering over
     * @param e mouse or touch event
     */
    const onInputMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.input,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to post title or subtitle input no longer hovering over
     * @param e mouse or touch event
     */
    const onInputMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to title or subtitle input focusing
     */
    const onInputFocus = (): void => {
        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    /**
     * Manages determining whether keystroke is enter or escape
     * If enter: moves focus to input
     * If escape: blurs focused input
     * If backspace/delete: removes focused chapter
     * @param e keyboard event
     */
    const checkForEnter = (e: React.KeyboardEvent): void => {
        if (e.key === KEYCODE.enter) {
            // Move from outline chapter title field to outline chapter description field
            if (document.activeElement?.classList.contains(EDITOR_CHAPTER_TITLE_INPUT_CLASSNAME)) {
                // Prevents newline character from being placed in next chapter
                e.preventDefault();

                const chapterDescription = document.activeElement?.parentElement?.nextSibling as HTMLDivElement;
                if (chapterDescription) {
                    chapterDescription.focus();
                }
            }

            // We don't want to move focus to the next chapter if the user is in the description field
            // because the description field is not a text input, it is a div that can contain multiple
            // lines of text
        } else if (e.key === KEYCODE.escape) {
            // Blur Focused Input
            (document.activeElement as HTMLInputElement).blur();
        } else if (
            e.key === KEYCODE.backspace
            || e.key === KEYCODE.delete
        ) {
            // Delete Outline Chapter
            // Backspace on empty title
            if (
                document.activeElement?.classList.contains(EDITOR_CHAPTER_TITLE_INPUT_CLASSNAME)
                && (document.activeElement as HTMLInputElement).value.length === 0 // Title
                && document.activeElement.parentElement?.nextElementSibling?.innerHTML?.length === 0 // Description
            ) {
                const chapterIndex = parseInt(document.activeElement.id.split('-')[1], 10);
                deleteChapter(chapterIndex);
            }

            // Delete Outline Chapter
            // Backspaced on empty description
            if (
                document.activeElement?.classList.contains(EDITOR_CHAPTER_DESCRIPTION_INPUT_CLASSNAME)
                && document.activeElement.innerHTML?.length === 0 // Description
                && (document.activeElement.previousSibling?.firstChild as HTMLInputElement)?.value.length === 0 // Title
            ) {
                const chapterIndex = parseInt(document.activeElement.id.split('-')[1], 10);
                deleteChapter(chapterIndex);
            }
        }
    };

    /**
     * Adds a new section to a chapter
     * @param chapterIndex index of chapter to add section to
     */
    const onAddSectionButtonMouseDown = (): void => {
        if (postValueIsSaving) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }
        let value = SERIALIZED_TRUE_INITIAL_EDITOR_VALUE;
        if (chapters[index].value) {
            // copy value
            value = chapters[index].value!;
            // delete value
            // eslint-disable-next-line no-param-reassign
            delete chapters[index].value;
        }

        const chapterId = chapters[index].id;
        const sectionId = uuidv4();
        const newSectionIndex = chapters[index].sections ? chapters[index].sections!.length : 0;
        if (chapters[index].sections) {
            const updatedChapters = [
                ...chapters,
            ];

            updatedChapters[index] = {
                ...updatedChapters[index],
                sections: [
                    ...chapters[index].sections!,
                    {
                        id: sectionId,
                        title: '',
                        value,
                        published: Date.now(),
                        updated: [],
                        views: [],
                        // Not first section, so no annotations to copy from parent chapter
                        annotations: [],
                    },
                ],
                updated: [
                    ...updatedChapters[index].updated,
                    Date.now(),
                ],
            };
            updateChapters(updatedChapters);
        } else {
            // We've begun to write in the chapter, but haven't added a section yet
            // Convert the chapter to a section
            const updatedChapters = [
                ...chapters,
            ];

            updatedChapters[index] = {
                // Leave out chapter value property
                id: updatedChapters[index].id,
                title: updatedChapters[index].title,
                description: updatedChapters[index].description,
                published: updatedChapters[index].published,
                updated: [
                    ...updatedChapters[index].updated,
                    Date.now(),
                ],
                views: updatedChapters[index].views,
                // No need to move Annotations to section if its the first section, because they
                // should be accounted for at the chapter level too
                annotations: updatedChapters[index].annotations,
                sections: [
                    {
                        id: sectionId,
                        title: '',
                        published: Date.now(),
                        updated: [
                            Date.now(),
                        ],
                        views: [],
                        annotations: updatedChapters[index].annotations.length > 0
                            && updatedChapters[index].value
                            ? [...updatedChapters[index].annotations]
                            : [],
                        // Move existing chapter value to section if it exists
                        value: updatedChapters[index].value
                            ? updatedChapters[index].value!
                            : SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
                    },
                ],
            };
            updateChapters(updatedChapters);
        }

        onChangeSelectedPostValuePath({
            path: [index, newSectionIndex],
        });

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.addPostChapterSection,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId,
                    sectionId,
                },
            });
        }
    };

    /**
     * Moves a chapter due to drag and drop
     * @param draggedIndex index of the chapter to move
     * @param droppedIndex index to move the chapter to
     */
    const moveChapter = (draggedIndex: number, droppedIndex: number): void => {
        const updatedChapters = [...chapters];
        const [removed] = updatedChapters.splice(draggedIndex, 1);
        updatedChapters.splice(droppedIndex, 0, removed);
        updateChapters(updatedChapters);

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.movePostChapter,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId: removed.id,
                    direction: droppedIndex > draggedIndex ? DRAG_DIRECTION.down : DRAG_DIRECTION.up,
                    units: Math.abs(droppedIndex - draggedIndex),
                },
            });
        }
    };

    /**
     * Removes a chapter from the contents table
     * @param index index of the chapter to remove
     */
    const deleteChapter = (chapterIndex: number): void => {
        if (postValueIsSaving) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }
        // prevent deleting chapter if it has a non-empty value or sections with non-empty values
        const chapterSectionsHaveValue = chapters[chapterIndex].sections?.reduce((acc, section) => {
            if (section.value && section.value.length > 0) {
                return true;
            }
            return acc;
        }, false);

        if (
            chapters.length === 1
            && chapters[chapterIndex].value
            && chapters[chapterIndex].value!.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length
            && !chapterSectionsHaveValue
        ) {
            // There is a single chapter with a non-empty value
            // Move it to the post value
            const updatedChapters = [...chapters];
            const [removed] = updatedChapters.splice(chapterIndex, 1);
            // Pass value to post
            // No need to pass annotations as well, since they are already in the post
            updateChapters(updatedChapters, removed.value!);

            // Modify selected post value path
            // Eliminate path
            onChangeSelectedPostValuePath({
                path: [],
            });

            // Record User Action
            if (
                user
                && currentSessionId
            ) {
                recordUserAction({
                    type: USER_ACTION_TYPE.deletePostChapter,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId,
                        chapterId: removed.id,
                    },
                });
            }
            return;
        }

        if (
            (
                chapters[chapterIndex].value
                && chapters[chapterIndex].value!.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length
            ) || chapterSectionsHaveValue
        ) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: chapters[chapterIndex].value!.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length
                    ? SNACKBAR_MESSAGE_DELETE_CHAPTER_WITH_VALUE_ERROR
                    : SNACKBAR_MESSAGE_DELETE_CHAPTER_WITH_SECTIONS_VALUE_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });

            return;
        }

        const updatedChapters = [...chapters];
        const [removed] = updatedChapters.splice(chapterIndex, 1);
        updateChapters(updatedChapters);

        // Modify selected post value path
        // Chapter index is greater than 0 (otherwise would be caught by first conditional)
        // so we can switch to the previous chapter
        const updatedPath = [chapterIndex - 1];
        if (chapters[updatedPath[0]].sections) {
            updatedPath.push(chapters[updatedPath[0]].sections!.length - 1);
        }
        onChangeSelectedPostValuePath({
            path: updatedPath,
        });

        // Record User Action
        if (
            user
            && currentSessionId
        ) {
            recordUserAction({
                type: USER_ACTION_TYPE.deletePostChapter,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId: removed.id,
                },
            });
        }
    };

    /**
     * Manages response to section button hovering over
     * @param e mouse event
     */
    const onAddSectionButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.addSectionButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to section button no longer hovering over
     * @param e mouse event
     */
    const onAddSectionButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to title input changing
     * * @param e input event
     */
    const onOutlineTitleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const updatedChapters = [...chapters];
        updatedChapters[index] = {
            ...updatedChapters[index],
            title: e.target.value,
            updated: [
                ...updatedChapters[index].updated,
                Date.now(),
            ],
        };
        updateChapters(updatedChapters);
    };

    /**
     * Manages response to editable div changing
     * @param e form event
     */
    const onOutlineDescriptionInputChange = (e: React.FormEvent<HTMLDivElement>): void => {
        const updatedChapters = [...chapters];
        updatedChapters[index] = {
            ...updatedChapters[index],
            description: (e.target as HTMLDivElement).textContent!,
            updated: [
                ...updatedChapters[index].updated,
                Date.now(),
            ],
        };
        updateChapters(updatedChapters);
    };

    /**
     * Manages response to drag indicator hovering over
     * @param e mouse or touch event
     */
    const onDragIndicatorMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.dragIndicator,
            [CURSOR_SIGN.grab],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to drag indicator no longer hovering over
     * @param e mouse or touch event
     */
    const onDragIndicatorMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to drag indicator being selected
     * @param e mouse or touch event
     */
    const onDragIndicatorMouseDown = (e: React.MouseEvent | React.TouchEvent): void => {
        // Changes Cursor
        clearTimeoutRevealMoveAction();
        timeoutRevealMoveAction();

        // Play Sound
        if (hasSound && dragIndicatorGraspClip.current) {
            dragIndicatorGraspClip.current.pause();
            dragIndicatorGraspClip.current.currentTime = 0;
            playAudio(dragIndicatorGraspClip.current);
        }
    };

    /**
     * Manages response to change in chapter/section position
     * @param type chapter or section object
     */
    const onDragIndicatorMouseMove = (
        type: USER_ACTION_TYPE,
    ): void => {
        if (
            user
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    /**
     * Manages response to drag indicator being released
     */
    const onDragIndicatorMouseUp = (): void => {
        clearTimeoutRevealMoveAction();
        // We still want to show cursor action,
        // but because object no longer held
        // we switch to click action
        setCursorSigns([GrabSign]);

        if (hasSound && dragIndicatorThumpClip.current) {
            dragIndicatorThumpClip.current.pause();
            dragIndicatorThumpClip.current.currentTime = 0;
            playAudio(dragIndicatorThumpClip.current);
        }
    };

    /**
     * Manages modifying selected post value path to this chapter
     */
    const selectChapter = (): void => {
        if (postValueIsSaving) {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_POST_VALUE_SAVING_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
            return;
        }
        if (
            selectedPostValuePath[0] !== index
            && (
                !chapter.sections
                || (
                    currentResolutionLevel
                    && currentResolutionLevel.level < RESOLUTION_LEVEL.five
                )
            )
        ) {
            onChangeSelectedPostValuePath({
                path: [index],
            });
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            inputClickClip.current
            && dragIndicatorGraspClip.current
            && dragIndicatorThumpClip.current
        ) {
            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;

            // Chapter/Section Grasp
            dragIndicatorGraspClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dragIndicatorGraspClip.current.src = ObjectGrasp;

            // Chapter/Section Thump
            dragIndicatorThumpClip.current.volume = DEFAULT_AUDIO_VOLUME;
            dragIndicatorThumpClip.current.src = ObjectThump;
        }

        return function cleanup() {
            if (inputClickClip.current) inputClickClip.current.remove();
            if (dragIndicatorGraspClip.current) dragIndicatorGraspClip.current.remove();
            if (dragIndicatorThumpClip.current) dragIndicatorThumpClip.current.remove();
        };
    }, []);

    /**
     * Injects chapter description into editable div
     */
    useEffect(() => {
        if (
            descriptionInputRef.current
            && chapter.description.length > 0
            && descriptionInputRef.current.textContent === ''
        ) {
            descriptionInputRef.current.textContent = chapter.description;
        }
    }, []);

    /**
     * Manages delay of showing move cursor action
     * when drag indicator selected
     */
    const {
        start: timeoutRevealMoveAction,
        clear: clearTimeoutRevealMoveAction,
    } = useTimeout(() => {
        setCursorSigns([MoveSign]);
    }, GRAB_DRAG_INDICATOR_DELAY_DURATION);

    // ===== Memoizations =====

    const nonEmptyIndicatorVisible = useMemo(() => (
        !!chapter.value && chapter.value.length > SERIALIZED_TRUE_INITIAL_EDITOR_VALUE.length
    ), [
        chapter.value,
    ]);

    // ===== Render =====

    return (
        <Container
            // Ensures that drag preview and drop target is entire chapter
            ref={(node) => drop(preview(node))}
            isDragging={isDragging}
        >
            <OutlineChapterHead
                selected={selectedPostValuePath.length === 1 && selectedPostValuePath[0] === index}
                multipleChapters={chapters.length > 1}
                transitionDuration={OUTLINE_CHAPTER_HEAD_TRANSITION_DURATION}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: selectChapter,
                } : {
                    onMouseDown: selectChapter,
                })}
            >
                <OutlineNumberText>
                    {index + 1}
                </OutlineNumberText>
                <OutlineChapterTitleInputContainer>
                    <OutlineChapterTitleInput
                        type="text"
                        id={`${EDITOR_CHAPTER_TITLE_INPUT_ID_PREFIX}-${index}`}
                        className={`${HOVER_TARGET_CLASSNAME} ${EDITOR_CHAPTER_TITLE_INPUT_CLASSNAME}`}
                        defaultValue={chapter.title}
                        placeholder="Chapter Title"
                        onKeyDown={checkForEnter}
                        onMouseEnter={onInputMouseEnter}
                        onMouseLeave={onInputMouseLeave}
                        onFocus={onInputFocus}
                        onChange={(e) => onOutlineTitleInputChange(e)}
                    />
                </OutlineChapterTitleInputContainer>
                <OutlineChapterDescriptionInput
                    contentEditable
                    ref={descriptionInputRef}
                    id={`${EDITOR_CHAPTER_DESCRIPTION_INPUT_ID_PREFIX}-${index}`}
                    className={`${HOVER_TARGET_CLASSNAME} ${EDITOR_CHAPTER_DESCRIPTION_INPUT_CLASSNAME}`}
                    defaultValue={chapter.description}
                    data-placeholder="Description"
                    onKeyDown={checkForEnter}
                    onMouseEnter={onInputMouseEnter}
                    onMouseLeave={onInputMouseLeave}
                    onFocus={onInputFocus}
                    onInput={(e) => onOutlineDescriptionInputChange(e)}
                />
                {chapters.length > 1 && (
                    <OutlineChapterDragIndicator
                        ref={drag}
                        className={HOVER_TARGET_CLASSNAME}
                        horizontalOffset={OUTLINE_DRAG_INDICATOR_X_OFFSET}
                        transitionDuration={OUTLINE_DRAG_INDICATOR_TRANSITION_DURATION}
                        onMouseEnter={onDragIndicatorMouseEnter}
                        onMouseLeave={onDragIndicatorMouseLeave}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: onDragIndicatorMouseDown,
                        } : {
                            onMouseDown: onDragIndicatorMouseDown,
                        })}
                        {...(detectTouchDevice(document) ? {
                            onTouchMove: () => onDragIndicatorMouseMove(USER_ACTION_TYPE.grabOutlineChapter),
                        } : {
                            onMouseMove: () => onDragIndicatorMouseMove(USER_ACTION_TYPE.grabOutlineChapter),
                        })}
                        {...(detectTouchDevice(document) ? {
                            onTouchEnd: onDragIndicatorMouseUp,
                        } : {
                            onMouseUp: onDragIndicatorMouseUp,
                        })}
                    >
                        <ReactSVG
                            src={DragIndicatorIcon}
                        />
                    </OutlineChapterDragIndicator>
                )}
                <ChapterNonEmptyIndicator
                    isVisible={nonEmptyIndicatorVisible}
                    transitionDuration={CHAPTER_INDICATOR_TRANSITION_DURATION}
                >
                    <Tooltip
                        text="Chapter has content"
                        side={TOOLTIP_TYPE.left}
                    />
                    <ReactSVG
                        src={PencilIcon}
                    />
                </ChapterNonEmptyIndicator>
                <ChapterAnnotationsIndicator
                    isVisible={chapter.annotations.length > 0}
                    nonEmptyIndicatorVisible={nonEmptyIndicatorVisible}
                    transitionDuration={CHAPTER_INDICATOR_TRANSITION_DURATION}
                >
                    <Tooltip
                        text="Chapter has annotations"
                        side={TOOLTIP_TYPE.left}
                    />
                    <ReactSVG
                        src={HighlightIcon}
                    />
                </ChapterAnnotationsIndicator>
            </OutlineChapterHead>
            {currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
            && (
                <OutlineChapterBody>
                    {chapter.sections && (
                        chapter.sections.map((section, sectionIndex) => (
                            <ReaderOutlineSection
                                key={section.id}
                                user={user}
                                postId={postId}
                                currentSessionId={currentSessionId}
                                chapterIndex={index}
                                index={sectionIndex}
                                numSections={chapter.sections!.length}
                                section={section}
                                transitionDuration={OUTLINE_CHAPTER_SECTION_TRANSITION_DURATION}
                                chapters={chapters}
                                sections={chapter.sections!}
                                postValueIsSaving={postValueIsSaving}
                                updateChapters={updateChapters}
                                onDragIndicatorMouseEnter={onDragIndicatorMouseEnter}
                                onDragIndicatorMouseLeave={onDragIndicatorMouseLeave}
                                onDragIndicatorMouseDown={onDragIndicatorMouseDown}
                                onDragIndicatorMouseUp={onDragIndicatorMouseUp}
                                onDragIndicatorMouseMove={onDragIndicatorMouseMove}
                                onInputMouseEnter={onInputMouseEnter}
                                onInputMouseLeave={onInputMouseLeave}
                                onInputFocus={onInputFocus}
                                setSnackbarData={setSnackbarData}
                                selectedPostValuePath={selectedPostValuePath}
                                onChangeSelectedPostValuePath={onChangeSelectedPostValuePath}
                            />
                        )))}
                    <AddSectionButton
                        className={HOVER_TARGET_CLASSNAME}
                        chapterSelected={selectedPostValuePath.length === 1 && selectedPostValuePath[0] === index}
                        bottomSectionSelected={
                            selectedPostValuePath.length === 1
                            || (
                                selectedPostValuePath.length === 2
                                && !!chapter.sections
                                && chapter.sections!.length - 1 === selectedPostValuePath[1]
                            )
                        }
                        transitionDuration={ADD_SECTION_BUTTON_TRANSITION_DURATION}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: onAddSectionButtonMouseDown,
                        } : {
                            onMouseDown: onAddSectionButtonMouseDown,
                        })}
                        onMouseEnter={onAddSectionButtonEnter}
                        onMouseLeave={onAddSectionButtonLeave}
                    >
                        <Tooltip
                            text="Add Section"
                            side={TOOLTIP_TYPE.right}
                        />
                        <AddSectionButtonIcon>
                            <ReactSVG
                                src={PlusIcon}
                            />
                        </AddSectionButtonIcon>
                    </AddSectionButton>
                </OutlineChapterBody>
            )}
        </Container>
    );
}

export default ReaderOutlineChapter;
