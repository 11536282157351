// Repurposed from https://usehooks.com/useKeyPress/
import { useEffect, useState } from 'react';

const useKeyPress = (key: string, element = window): boolean => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // If pressed key is our target key then set to true
    function downHandler(e: KeyboardEvent): void {
        if (e.key === key) {
            setKeyPressed(true);
        }
    }

    // If released key is our target key then set to false
    const upHandler = (e: KeyboardEvent): void => {
        if (e.key === key) {
            setKeyPressed(false);
        }
    };

    // Add event listeners
    useEffect(() => {
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener;
        if (!isSupported) {
            return;
        }

        element.addEventListener('keydown', downHandler);
        element.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            element.removeEventListener('keydown', downHandler);
            element.removeEventListener('keyup', upHandler);
        };
    }, [element]); // Empty array ensures that effect is only run on mount and unmount

    return keyPressed;
};

export default useKeyPress;
