// ===== Packages =====

import Stripe               from 'stripe';

// ===== Interfaces =====

import {
    IStoreItem,
}                           from '../interfaces';

// ===== Services =====

import {
    roundToNDecimals,
}                           from '.';

const applyPromotionToCheckoutItem = (
    item: IStoreItem,
    promotion: Stripe.Coupon,
): number => {
    // Type: percent off
    if ((
        !promotion.applies_to
        || (
            promotion.applies_to
            && promotion.applies_to.products.includes(item.productId)
        ))
        && !!promotion.percent_off
        && !!item.amount
    ) {
        return roundToNDecimals(
            (item.amount * ((100 - promotion.percent_off) / 100)),
            0,
        );
    }

    // Type: amount off
    if ((
        !promotion.applies_to
        || (
            promotion.applies_to
            && promotion.applies_to.products.includes(item.productId)
        ))
        && !!promotion.amount_off
        && !!item.amount
    ) {
        return roundToNDecimals(
            (item.amount - promotion.amount_off),
            0,
        );
    }

    return item.amount!;
};

export default applyPromotionToCheckoutItem;
