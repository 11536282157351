// ===== Packages =====

import React, {
    useState,
    useEffect,
    useCallback,
}                                               from 'react';
import styled                                   from 'styled-components';
import { ReactSVG }                             from 'react-svg';
import { useDropzone }                          from 'react-dropzone';
import { useSelected }                          from 'slate-react';
import {
    ref,
    getStorage,
    getDownloadURL,
    StorageError,
    UploadTaskSnapshot,
    StorageReference,
}                                               from 'firebase/storage';
import ShortUniqueId                            from 'short-unique-id';
import { v4 as uuidv4 }                         from 'uuid';
import mime                                     from 'mime-types';
import { LazyLoadImage }                        from 'react-lazy-load-image-component';

// ===== Components =====

import Tooltip                                  from '../../Tooltip';
import {
    DropzoneInput,
    PlaceholderBox,
}                                               from '../../../styles';

// ===== Services =====

import {
    hexToRgb,
    setColorLightness,
    uploadToCloudStorage,
    setMediaInDB,
    getMediaStorageBucket,
    getStorageErrorMessage,
    updateAnnotationMediaInDB,
}                                               from '../../../services';

// ===== Interfaces =====

import {
    IMediaItem,
    ISnackbarItem,
    IUserItem,
}                                               from '../../../interfaces';

// ===== Enums =====

import {
    TOOLTIP_TYPE,
    STORAGE_ENTITY,
    MEDIA_TYPE,
    STORAGE_ERROR_CODE,
    FIRESTORE_COLLECTION,
    INTERACTABLE_OBJECT,
    CURSOR_TARGET,
    EDITOR_CONTEXT_TYPE,
}                                               from '../../../enums';
import { FIGURE_ORIENTATION }                   from '../elements/figureElement/enums';

// ===== Assets =====

import ImageLayoutLeftIcon                      from '../../../images/editor/image-left.svg';
import ImageLayoutRightIcon                     from '../../../images/editor/image-right.svg';
import ImageLayoutCenterIcon                    from '../../../images/editor/image-center.svg';
import ImageLayoutFullscreenIcon                from '../../../images/editor/image-fullscreen.svg';
import TrashIcon                                from '../../../images/editor/trash.svg';
import ImageSwapIcon                            from '../../../images/editor/image-swap.svg';
import DownloadIcon                             from '../../../images/editor/download-arrow.svg';
import SwapIcon                                 from '../../../images/editor/swap.svg';
import CautionIcon                              from '../../../images/caution.svg';

// ===== Constants =====

import {
    BUTTON_TEXT_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
    BUTTON_CONTAINER_LIGHTNESS_VALUE,
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_FIGURE_IMAGE_CONTAINER_CLASSNAME,
    EDITOR_CAPTION_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
}                                               from '../../../constants/generalConstants';
import CURSOR_SIGN                              from '../../../constants/cursorSigns';

Image.defaultProps = {
    readOnly: false,
    onLoad: undefined,
};
interface Props {
    id: string,
    user: IUserItem | null,
    color: string,
    readOnly?: boolean,
    editorType: EDITOR_CONTEXT_TYPE,
    imagePath: string,
    setImagePath: (filePath: string) => void,
    onLoad?: () => void,
    layoutType: FIGURE_ORIENTATION,
    removeFigure: () => void,
    swapFigureOrder: () => void,
    isGalleryChild: boolean,
    setLayout: (layout: FIGURE_ORIENTATION) => void,
    children: React.ReactElement,
    attributes: any,
    uploadingMedia: Map<string, IMediaItem>,
    setUploadingMedia: (mediaItem: IMediaItem) => void,
    updateUploadingMedia: (mediaItem: IMediaItem) => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
}
function Image({
    id,
    user,
    children,
    attributes,
    color,
    readOnly,
    editorType,
    setImagePath,
    imagePath,
    onLoad,
    layoutType,
    isGalleryChild,
    setLayout,
    removeFigure,
    swapFigureOrder,
    uploadingMedia,
    setUploadingMedia,
    updateUploadingMedia,
    onCursorEnter,
    onCursorLeave,
    setSnackbarData,
}: Props): JSX.Element {
    // ===== General Constants =====

    const SNACKBAR_MESSAGE_FETCH_IMAGE_ERROR = 'There was a problem fetching image.';
    const SNACKBAR_MESSAGE_DOWNLOAD_IMAGE_ERROR = 'There was a problem initiating image download.';
    const IMAGE_PLACEHOLDER_WIDTH = 450;
    const IMAGE_PLACEHOLDER_HEIGHT = 300;
    const LAZY_IMAGE_THRESHOLD = 1000;

    // ===== State =====
    const [url, setUrl] = useState<string | null>(null);
    const [path, setPath] = useState<string | null>(null);
    const selected = useSelected();
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    // Swap Image
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            handleImageReplace(acceptedFiles[0]);
        }
    }, [user]);
    const {
        open: openSwap,
        getRootProps: getRootPropsSwap,
        getInputProps: getInputPropsSwap,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: 'image/*',
        onDropAccepted,
    });

    // ===== Side Effects =====

    useEffect(() => {
        if (imagePath !== path) {
            if (url) {
                // Make sure to revoke the data uris to avoid memory leaks
                // Set when we use temporary URL from blob
                URL.revokeObjectURL(url);
            }
            setPath(imagePath);
            const storage = getStorage();
            const pathParts = imagePath.split('.');
            const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
            getDownloadURL(ref(storage, mediumPath)).then((imageURL) => {
                setUrl(imageURL);
            }).catch((error) => {
                // We assume cloud function has not yet generated medium image yet
                if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                    getDownloadURL(ref(storage, imagePath)).then((imageURL) => {
                        setUrl(imageURL);
                    }).catch(() => {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_FETCH_IMAGE_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                    });
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_FETCH_IMAGE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                }
            });
        }
    }, [imagePath]);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
            // setUploadingStateChangeEvent(null);
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    // ====== Methods =====

    const handleLayoutChange = (layout: FIGURE_ORIENTATION): void => {
        setLayout(layout);
    };

    const handleImageReplace = (file: File): void => {
        if (!user) throw Error('There was a problem replacing image. User data not found.');

        // Swap URL
        const tempFileURL = URL.createObjectURL(file);
        setUrl(tempFileURL);

        const mediaId = uuidv4();
        const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
        const mediaBucket = getMediaStorageBucket(MEDIA_TYPE.image);
        const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
        const fileExtension = mime.extension(file.type);
        let storageEntity: STORAGE_ENTITY;
        if (editorType === EDITOR_CONTEXT_TYPE.post) {
            storageEntity = process.env.NODE_ENV === 'production'
                ? STORAGE_ENTITY.posts
                : STORAGE_ENTITY.stagingPosts;
        } else {
            storageEntity = process.env.NODE_ENV === 'development'
                ? STORAGE_ENTITY.stagingAnnotations
                : STORAGE_ENTITY.annotations;
        }
        const filePath = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
        const mediaItem: IMediaItem = {
            id: mediaId,
            userId: user.id,
            file,
            filePath,
            type: MEDIA_TYPE.image,
            uploadProgress: 0,
            caption: '',
        };
        setUploadingMedia(mediaItem);

        // make db entry of media
        // execute before file upload so upload cloud function has a place to write to
        setMediaInDB({
            mediaItem,
            filePath,
        });

        // upload file to cloud storage
        // url will be set by cloud function
        const uploadTask = uploadToCloudStorage(
            file,
            filePath,
        );

        uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadingStateChangeEvent({
                    mediaItem,
                    snapshot,
                    progress,
                });
            },
            (error: StorageError) => {
                throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
            },
            () => {
                setUploadingCompleteEvent({
                    mediaItem,
                    storageRef: uploadTask.snapshot.ref,
                });
            },
        );
    };

    const handleImageSwap = (e: React.MouseEvent): void => {
        e.stopPropagation();
        swapFigureOrder();
    };

    const handleDelete = (e: React.MouseEvent): void => {
        e.stopPropagation();
        removeFigure();
    };

    const handleDownload = (): void => {
        const executeDownload = (downloadURL: string): void => {
            const link = document.createElement('a');
            link.download = 'true';
            link.href = downloadURL;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        const storage = getStorage();
        const pathParts = imagePath.split('.');
        const largePath = `${pathParts[0]}_large.${pathParts[1]}`;
        getDownloadURL(ref(storage, largePath)).then((imageURL) => {
            executeDownload(imageURL);
        }).catch((error) => {
            // We assume cloud function has not yet generated large image yet
            if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                getDownloadURL(ref(storage, imagePath)).then((imageURL) => {
                    executeDownload(imageURL);
                }).catch(() => {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_DOWNLOAD_IMAGE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                });
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_DOWNLOAD_IMAGE_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            }
        });
    };

    const onMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter && !readOnly) {
            onCursorEnter(
                CURSOR_TARGET.editorElement,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave && !readOnly) onCursorLeave(e);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorElementButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        imgURL: string,
    ): void => {
        // Update Uploading Media
        updateUploadingMedia({
            ...uploadingMedia.get(mediaItem.id)!,
            url: imgURL,
        });

        // Update DB
        const collection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.media
            : FIRESTORE_COLLECTION.stagingMedia;
        updateAnnotationMediaInDB({
            collection,
            id,
            annotationMediaType: mediaItem.type,
            filePath: mediaItem.filePath,
        });

        setImagePath(mediaItem.filePath);
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            setUploadingMedia(mediaItem);
        }
    };

    return (
        <Container
            {...attributes}
            color={color}
            selected={selected}
            className={`${EDITOR_FIGURE_IMAGE_CONTAINER_CLASSNAME} ${HOVER_TARGET_CLASSNAME}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            // Don't add 'contentEditable={false}' because it prevents backspace to delete
        >
            {!readOnly
            && (
                <Overlay
                    selected={selected}
                >
                    <LayoutContainer>
                        {isGalleryChild
                            ? (
                                <ActionButton
                                    className={HOVER_TARGET_CLASSNAME}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    onClick={handleImageSwap}
                                >
                                    <Tooltip
                                        text="Swap Images"
                                        side={TOOLTIP_TYPE.top}
                                    />
                                    <ReactSVG
                                        src={SwapIcon}
                                    />
                                </ActionButton>
                            )
                            : (
                                <>
                                    <LayoutButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        color={color}
                                        active={layoutType === FIGURE_ORIENTATION.left}
                                        onMouseEnter={onButtonMouseEnter}
                                        onMouseLeave={onButtonMouseLeave}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleLayoutChange(FIGURE_ORIENTATION.left);
                                        }}
                                    >
                                        <Tooltip
                                            text="Left"
                                            side={TOOLTIP_TYPE.top}
                                        />
                                        <ReactSVG
                                            src={ImageLayoutLeftIcon}
                                        />
                                    </LayoutButton>
                                    <LayoutButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        color={color}
                                        active={layoutType === FIGURE_ORIENTATION.center}
                                        onMouseEnter={onButtonMouseEnter}
                                        onMouseLeave={onButtonMouseLeave}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleLayoutChange(FIGURE_ORIENTATION.center);
                                        }}
                                    >
                                        <Tooltip
                                            text="Center"
                                            side={TOOLTIP_TYPE.top}
                                        />
                                        <ReactSVG
                                            src={ImageLayoutCenterIcon}
                                        />
                                    </LayoutButton>
                                    <LayoutButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        color={color}
                                        active={layoutType === FIGURE_ORIENTATION.fullscreen}
                                        onMouseEnter={onButtonMouseEnter}
                                        onMouseLeave={onButtonMouseLeave}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleLayoutChange(FIGURE_ORIENTATION.fullscreen);
                                        }}
                                    >
                                        <Tooltip
                                            text="Fullscreen"
                                            side={TOOLTIP_TYPE.top}
                                        />
                                        <ReactSVG
                                            src={ImageLayoutFullscreenIcon}
                                        />
                                    </LayoutButton>
                                    <LayoutButton
                                        className={HOVER_TARGET_CLASSNAME}
                                        color={color}
                                        active={layoutType === FIGURE_ORIENTATION.right}
                                        onMouseEnter={onButtonMouseEnter}
                                        onMouseLeave={onButtonMouseLeave}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleLayoutChange(FIGURE_ORIENTATION.right);
                                        }}
                                    >
                                        <Tooltip
                                            text="Right"
                                            side={TOOLTIP_TYPE.top}
                                        />
                                        <ReactSVG
                                            src={ImageLayoutRightIcon}
                                        />
                                    </LayoutButton>
                                </>
                            )}
                    </LayoutContainer>
                    {/* <DragIndicatorContainer>
                        <ReactSVG
                            src={DragIndicatorIcon}
                        />
                    </DragIndicatorContainer> */}
                    <CardActionContainer>
                        <ActionButton
                            {...getRootPropsSwap({
                                className: HOVER_TARGET_CLASSNAME,
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    openSwap();
                                },
                            })}
                        >
                            <Tooltip
                                text="Replace"
                                side={TOOLTIP_TYPE.top}
                            />
                            <DropzoneInput {...getInputPropsSwap()} />
                            <ReactSVG
                                src={ImageSwapIcon}
                            />
                        </ActionButton>
                        <ActionButton
                            {...(url
                                ? {
                                    className: HOVER_TARGET_CLASSNAME,
                                    onMouseEnter: onButtonMouseEnter,
                                    onMouseLeave: onButtonMouseLeave,
                                    onMouseDown: handleDownload,
                                }
                                : {}
                            )}
                        >
                            <Tooltip
                                text="Download"
                                side={TOOLTIP_TYPE.top}
                            />
                            <ReactSVG
                                src={DownloadIcon}
                            />
                        </ActionButton>
                        <ActionButton
                            className={HOVER_TARGET_CLASSNAME}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onMouseDown={handleDelete}
                        >
                            <Tooltip
                                text="Remove"
                                side={TOOLTIP_TYPE.top}
                            />
                            <ReactSVG
                                src={TrashIcon}
                            />
                        </ActionButton>
                    </CardActionContainer>
                </Overlay>
            )}
            {url
            && !readOnly
            && (
                <Graphic
                    src={url}
                    layoutType={layoutType}
                    onLoad={onLoad}
                />
            )}
            {url
            && readOnly
            && (
                <LazyLoadImage
                    src={url}
                    width="100%"
                    afterLoad={onLoad}
                    threshold={LAZY_IMAGE_THRESHOLD}
                    placeholder={(
                        <PlaceholderBox
                            width={IMAGE_PLACEHOLDER_WIDTH}
                            height={IMAGE_PLACEHOLDER_HEIGHT}
                            margin="0px auto"
                        />
                    )}
                    style={{
                        borderRadius: `${layoutType === FIGURE_ORIENTATION.fullscreen
                            ? 0
                            : 5
                        }px`,
                    }}
                />
            )}
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    selected: boolean,
    layoutType: FIGURE_ORIENTATION,
}
const Container = styled.div<ContainerProps>`
    position: relative;
    top: ${({ selected }) => `${selected
        ? -4
        : 0
    }px`};
    left: ${({ selected }) => `${selected
        ? -4
        : 0
    }px`};
    width: 100%;
    box-sizing: content-box;
    border: ${({ selected, color, theme }) => (selected
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        }`
        : 'none'
    )};
    border-radius: ${({ layoutType }) => `${layoutType === FIGURE_ORIENTATION.fullscreen
        ? 0
        : 5
    }px`};
    box-shadow: ${({ selected, theme }) => (selected
        ? theme.color.boxShadow300
        : 'none'
    )};
    caret-color: transparent;
    cursor: none;
    transition: ${({ theme }) => `box-shadow ${EDITOR_CAPTION_TRANSITION_DURATION}ms ${theme.motion.eagerEasing}`};
`;

interface OverlayProps {
    selected: boolean,
}
const Overlay = styled.div<OverlayProps>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ selected, theme }) => {
        const rgb = hexToRgb(theme.color.neutral900);
        if (selected && rgb) {
            return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`;
        }
        return 'rgba(0, 0, 0, 0)';
    }};
    opacity: ${({ selected }) => (selected
        ? 1
        : 0
    )};
    width: 100%;
    height: 100%;
    transition: ${({ theme }) => `
        opacity ${EDITOR_CAPTION_TRANSITION_DURATION}ms ${theme.motion.eagerEasing},
        background-color ${EDITOR_CAPTION_TRANSITION_DURATION}ms ${theme.motion.eagerEasing}
    `};
`;

interface GraphicProps {
    layoutType: FIGURE_ORIENTATION,
}
const Graphic = styled.img<GraphicProps>`
    width: 100%;
    border-radius: ${({ layoutType }) => `${layoutType === FIGURE_ORIENTATION.fullscreen
        ? 0
        : 5
    }px`};
`;

// const DragIndicatorContainer = styled.button`
//     position: absolute;
//     top: 50%;
//     right: 5px;
//     transform: translateY(-50%);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     outline: none;
//     height: 30px;
//     width: 30px;
//     padding: 5px;
//     border-radius: 15px;
//     border: none;
//     text-decoration: none;
//     background-color: transparent;
//     cursor: grab;
//     transition: background-color 0.3s;
//
//     & div {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 100%;
//         height: 100%;
//     }
//
//     & svg {
//         width: 100%;
//         height: 100%;
//         fill: ${({ theme }) => theme.color.neutral600};
//     }
//
//     &:hover {
//         background-color: ${({ theme }) => theme.color.neutral700};
//
//         & svg {
//             fill: ${({ theme }) => theme.color.neutral400};
//         }
//     }
// `;

const CardActionContainer = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        bottom: 5px;
        top: auto;
    }
`;

const ActionButton = styled.div`
    position: relative;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 15px;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s;

    ${CardActionContainer} & {
        margin-left: 5px;
    }

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral600};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral700};

        & svg {
            fill: ${({ theme }) => theme.color.neutral400};
        }
    }
`;

const LayoutContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
`;

interface LayoutButtonProps {
    active: boolean,
}
const LayoutButton = styled(ActionButton)<LayoutButtonProps>`
    padding: 2.5px;

    ${LayoutContainer} & {
        margin-right: 5px;
    }

    ${({ active, color }) => active && color && `
        & svg {
            width: 100%;
            height: 100%;
            fill: ${color};
        }

        &:hover {
            background-color: ${setColorLightness(
        color,
        BUTTON_CONTAINER_LIGHTNESS_VALUE,
    )};

            & svg {
                fill: ${setColorLightness(
        color,
        BUTTON_TEXT_LIGHTNESS_VALUE,
    )};
            }
        }
    `};
`;

export default Image;
