// ===== Interfaces =====

import { IRgb, IHsl } from '../interfaces';

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */

const hslToRgb = (hsl: IHsl): IRgb => {
    let newH = hsl.h;
    let newS = hsl.s;
    let newL = hsl.l;
    let r;
    let g;
    let b;

    if (!Number.isFinite(newH)) newH = 0;
    if (!Number.isFinite(newS)) newS = 0;
    if (!Number.isFinite(newL)) newL = 0;

    newH /= 60;
    if (newH < 0) newH = 6 - (-newH % 6);
    newH %= 6;

    newS = Math.max(0, Math.min(1, newS / 100));
    newL = Math.max(0, Math.min(1, newL / 100));

    const c = (1 - Math.abs((2 * newL) - 1)) * newS;
    const x = c * (1 - Math.abs((newH % 2) - 1));

    if (newH < 1) {
        r = c;
        g = x;
        b = 0;
    } else if (newH < 2) {
        r = x;
        g = c;
        b = 0;
    } else if (newH < 3) {
        r = 0;
        g = c;
        b = x;
    } else if (newH < 4) {
        r = 0;
        g = x;
        b = c;
    } else if (newH < 5) {
        r = x;
        g = 0;
        b = c;
    } else {
        r = c;
        g = 0;
        b = x;
    }

    const m = newL - (c / 2);
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return {
        r,
        g,
        b,
    };
};

export default hslToRgb;
