// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useEffect,
    useState,
}                                   from 'react';
import {
    useNavigate,
    useLocation,
}                                   from 'react-router-dom';
import { Transition }               from 'react-transition-group';
import { ReactSVG }                 from 'react-svg';

// ===== Components =====

import Modal                        from '../Modal';
import { Button }                   from '../Editor/helpers';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    CHARACTER_INFO_PROFILE_BUTTON_TYPE,
    USER_ACTION_TYPE,
    BUTTON_TYPE,
    CHARACTER_ROLE_TYPE,
}                                   from '../../enums';

// ===== Hooks =====

import {
    useTimeout,
}                                   from '../../hooks';

// ===== Services =====

import {
    playAudio,
    recordUserAction,
    updatePageTitle,
    detectTouchDevice,
}                                   from '../../services';

// ===== Interfaces =====

import {
    IDimension,
    ICharacter,
    IUserItem,
}                                   from '../../interfaces';

// ===== Images =====

import VerascopeLogo                from '../../images/verascope-logo-silhouette.svg';
import FaceSilhouetteOne            from '../../images/silhouettes/face_silhouette_1.svg';
import FaceSilhouetteTwo            from '../../images/silhouettes/face_silhouette_2.svg';
import FaceSilhouetteThree          from '../../images/silhouettes/face_silhouette_3.svg';
import FaceSilhouetteFour           from '../../images/silhouettes/face_silhouette_4.svg';
import FaceSilhouetteFive           from '../../images/silhouettes/face_silhouette_5.svg';
import FaceSilhouetteSix            from '../../images/silhouettes/face_silhouette_6.svg';
import FaceSilhouetteSeven          from '../../images/silhouettes/face_silhouette_7.svg';
import FaceSilhouetteEight          from '../../images/silhouettes/face_silhouette_8.svg';
import BodySilhouetteOne            from '../../images/silhouettes/body_silhouette_1.svg';
import BodySilhouetteTwo            from '../../images/silhouettes/body_silhouette_2.svg';
import BodySilhouetteThree          from '../../images/silhouettes/body_silhouette_3.svg';
import BodySilhouetteFour           from '../../images/silhouettes/body_silhouette_4.svg';
import BodySilhouetteFive           from '../../images/silhouettes/body_silhouette_5.svg';
import BodySilhouetteSix            from '../../images/silhouettes/body_silhouette_6.svg';
import BodySilhouetteSeven          from '../../images/silhouettes/body_silhouette_7.svg';
import BodySilhouetteEight          from '../../images/silhouettes/body_silhouette_8.svg';
import AfikaFaceSide                from '../../images/afika_side.png';
import AfikaFullIsometric           from '../../images/afika_full.png';
import InfoIcon                     from '../../images/info.svg';
import SkillsIcon                   from '../../images/tools.svg';
import OriginIcon                   from '../../images/house.svg';
import LocationIcon                 from '../../images/location.svg';
import ProfileIcon                  from '../../images/user.svg';
import TwitterIcon                  from '../../images/twitter.svg';
import PortfolioIcon                from '../../images/link.svg';
import MailIcon                     from '../../images/envelope.svg';
import ArrowIcon                    from '../../images/arrow.svg';
import HeadIcon                     from '../../images/head.svg';
import CrossIcon                    from '../../images/cross.svg';
import UnlockIcon                   from '../../images/unlock.svg';
import LockIcon                     from '../../images/lock.svg';

// ===== Sounds =====

import CharacterEnter               from '../../sounds/swoosh_in.mp3';
import CharacterExit                from '../../sounds/swoosh_out.mp3';

// ===== Constants =====

import {
    PAGE_TITLE_TRANSITION_DURATION,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    DEFAULT_AUDIO_VOLUME,
    HOVER_TARGET_CLASSNAME,
}                                   from '../../constants/generalConstants';
import CURSOR_SIGN                  from '../../constants/cursorSigns';
import MEDIA_QUERY_SIZE             from '../../constants/mediaQuerySizes';

// ===== Styles =====

import {
    Container,
    CharacterTileContainer,
    CharacterTileGrid,
    CharacterTile,
    CharacterDetailContainer,
    CharacterTileImage,
    CharacterShadow,
    CharacterDetail,
    CharacterNameContainer,
    CharacterNameParallelogram,
    CharacterNameText,
    CharacterRoleText,
    CharacterFullLengthImage,
    CharacterNameTextContainer,
    CharacterInfo,
    CharacterInfoRow,
    CharacterInfoRowIcon,
    CharacterInfoRowText,
    CharacterInfoProfileRowButton,
    CharacterInfoProfileRowButtonIcon,
    CharacterInfoRowButtonContainer,
    CharacterInfoBorder,
    CarouselButton,
    CarouselButtonIcon,
    CharacterInfoButton,
    CharacterInfoButtonIcon,
    ModalContentsContainer,
    ModalCloseButton,
    ApplyButtonContainer,
}                                   from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
}                                   from '../../styles';
import { theme }                    from '../../themes/theme-context';

interface Props {
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function CharactersView({
    hasSound,
    user,
    currentSessionId,
    viewportDimensions,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====
    const CHARACTER_COUNT = 9;
    const CHARACTER_TILE_LENGTH = 100;
    const DEFAULT_CHARACTER_FULL_LENGTH_IMAGE_OFFSET = 200;
    const MODAL_WIDTH = 330;

    // ===== Refs =====

    const characterNameRef = useRef<HTMLHeadingElement>(null);
    const characterRoleRef = useRef<HTMLHeadingElement>(null);
    const characterDetailContainerRef = useRef<HTMLHeadingElement>(null);

    // ----- Sound Clips

    const characterEnterClip = useRef<HTMLAudioElement>(new Audio());
    const characterExitClip = useRef<HTMLAudioElement>(new Audio());

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== State =====

    // stores whether we've rendered component before
    // used to gracefully load character full image initially
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    // indicates whether character tiles should be shown
    const [characterTilesVisible, setCharacterTilesVisible] = useState<boolean>(false);
    // stores index of selected character
    const [selectedCharacterIndex, setSelectedCharacterIndex] = useState<number>(0);
    // stores a cache of the next character index that is
    // used as an argument for the next character index
    // we need this cache to pass data into the setTimeout
    // we have the timeout to wait for the character text to exit before
    // clearing character text metadata
    const [cachedCharacterIndex, setCachedCharacterIndex] = useState<number>(0);
    // stores the index of the next character to present
    // we need to have this to wait for character image to exit
    // before computing new character text metadata
    const [nextCharacterIndex, setNextCharacterIndex] = useState<number>(0);
    // stores width of selected character text width
    const [characterTextMetadata, setCharacterTextMetadata] = useState<{
        name: number,
        nameCharCount: number,
        nameLongestWordCharCount: number,
        role: number,
        roleCharCount: number,
        roleLongestWordCharCount: number,
    } | null>(null);
    // stores width of character detail container
    const [characterDetailContainerWidth, setCharacterDetailContainerWidth] = useState<number | undefined>(undefined);
    // indicates whether character name parallelogram should be zero width
    // we need this to contract the character name text parallelogram
    // while we're exiting one character and adding another.
    // without it, the parallelogram never fully collapses
    const [zeroWidthParallelogram, setZeroWidthParallelogram] = useState<boolean>(true);
    // indicates whether character text layout should be frozen
    // we need this so that the character text layout position and size remains
    // constant while it's exiting from the viewport
    const [freezeCharacterTextLayout, setFreezeCharacterTextLayout] = useState<boolean>(false);
    // stores a cached value of the last character name longest word character count
    // we need this as a helper value for freezing the character text layout
    const [cachedCharacterNameLongestWordChar, setCachedCharacterNameLongestWordChar] = useState<number | null>(null);
    // stores the index offset of the character carousel in small viewport
    const [carouselOffsetIndex, setCarouselOffsetIndex] = useState<number>(0);
    // indicates whether the modal present in small viewports is visible
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);

    // ===== Animation Constants =====
    const SHOW_CHARACTER_TILES_DELAY_DURATION = 50;
    const CHARACTER_TILE_TRANSITION_DURATION = 200;
    const CHARACTER_TILE_STAGGER_OFFSET_DURATION = 100;
    const CHARACTER_TILE_REVEAL_DELAY_DURATION = PAGE_TITLE_TRANSITION_DURATION / 4;
    const CAROUSEL_BUTTON_TRANSITION_DURATION = 200;
    const characterOneEnterTransition = useMemo(() => (
        (0 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterOneExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(0, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterTwoEnterTransition = useMemo(() => (
        (1 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterTwoExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(1, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterThreeEnterTransition = useMemo(() => (
        (2 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterThreeExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(2, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterFourEnterTransition = useMemo(() => (
        (3 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterFourExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(3, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterFiveEnterTransition = useMemo(() => (
        (4 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterFiveExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(4, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterSixEnterTransition = useMemo(() => (
        (5 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterSixExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(5, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterSevenEnterTransition = useMemo(() => (
        (6 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterSevenExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(6, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterEightEnterTransition = useMemo(() => (
        (7 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterEightExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(7, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characterNineEnterTransition = useMemo(() => (
        (8 * CHARACTER_TILE_STAGGER_OFFSET_DURATION)
        + CHARACTER_TILE_TRANSITION_DURATION
        + CHARACTER_TILE_REVEAL_DELAY_DURATION
    ), []);

    const characterNineExitTransition = useMemo(() => (
        (CHARACTER_COUNT - Math.max(8, 0)) * CHARACTER_TILE_STAGGER_OFFSET_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
    ), []);

    const characters: ICharacter[] = useMemo(() => [
        {
            index: 0,
            faceImage: AfikaFaceSide,
            fullLengthImage: AfikaFullIsometric,
            name: 'Afika Nyati',
            role: CHARACTER_ROLE_TYPE.founder,
            locked: false,
            unlockable: false,
            color: theme.verascopeColor.yellow100,
            enterTransition: characterOneEnterTransition,
            exitTransition: characterOneExitTransition,
            info: 'A designer disguised as an engineer with a passion for communication'
            + ' technology and the ways it can be used to improve human ability and understanding.',
            skills: ['Designer', 'Developer', 'Artist'],
            origin: 'Johannesburg, South Africa',
            location: 'Brooklyn, New York',
            twitterHandle: 'afikanyati',
            portfolioUrl: 'https://afikanyati.com',
            email: 'afika@verasco.pe',
        },
        {
            index: 1,
            faceImage: FaceSilhouetteOne,
            fullLengthImage: BodySilhouetteOne,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.artist,
            locked: true,
            unlockable: false,
            color: theme.verascopeColor.orange200,
            enterTransition: characterTwoEnterTransition,
            exitTransition: characterTwoExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=Technical%20Concept%20Artist',
            info: 'Effortlessly brings to life detailed artifacts from their imagination.',
            skills: ['Artist', 'Imagineer'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 2,
            faceImage: FaceSilhouetteTwo,
            fullLengthImage: BodySilhouetteTwo,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.mathematician,
            locked: true,
            unlockable: true,
            color: theme.verascopeColor.green200,
            enterTransition: characterThreeEnterTransition,
            exitTransition: characterThreeExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=Applied%20Mathematician',
            info: 'Acts as a medium between mathematical and physical space, and instantiates mathematical objects'
            + ', structures, and behaviors into our world.',
            skills: ['Conceptual Terrain Explorer', 'Thinker'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 3,
            faceImage: FaceSilhouetteThree,
            fullLengthImage: BodySilhouetteThree,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.mlEngineer,
            locked: true,
            unlockable: true,
            color: theme.verascopeColor.red200,
            enterTransition: characterFourEnterTransition,
            exitTransition: characterFourExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=ML%20Engineer',
            info: 'Harnesses the electric speed of computational systems to build'
            + ' artifacts that can learn how to perform tasks that scale infinitely.',
            skills: ['Architect', 'Magician', 'Developer'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 4,
            faceImage: FaceSilhouetteFour,
            fullLengthImage: BodySilhouetteFour,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.gameDesigner,
            locked: true,
            unlockable: true,
            color: theme.verascopeColor.blue200,
            enterTransition: characterFiveEnterTransition,
            exitTransition: characterFiveExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=Game%20Designer',
            info: 'Conceptualizes complex environments with emergent activity that invites play.',
            skills: ['Architect', 'Designer'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 5,
            faceImage: FaceSilhouetteFive,
            fullLengthImage: BodySilhouetteFive,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.soundDesigner,
            locked: true,
            unlockable: false,
            color: theme.verascopeColor.yellow200,
            enterTransition: characterSixEnterTransition,
            exitTransition: characterSixExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=Songwriter%20/%20Sound%20Designer',
            info: 'Utilizes the richness of sound and the auditory sense in service of moving audiences.',
            skills: ['Vibe Generator', 'Artist', 'Designer'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 6,
            faceImage: FaceSilhouetteSix,
            fullLengthImage: BodySilhouetteSix,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.hciResearcher,
            locked: true,
            unlockable: false,
            color: theme.verascopeColor.red300,
            enterTransition: characterSevenEnterTransition,
            exitTransition: characterSevenExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=HCI%20Researcher',
            info: 'Conceptualizes systems that effectively facilitate machine-to-person'
            + ' or person-to-person communication tasks for pragmatic purposes.',
            skills: ['Designer', 'Information Theorist', 'Thinker'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 7,
            faceImage: FaceSilhouetteSeven,
            fullLengthImage: BodySilhouetteSeven,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.storyteller,
            locked: true,
            unlockable: false,
            color: theme.verascopeColor.purple500,
            enterTransition: characterEightEnterTransition,
            exitTransition: characterEightExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=Storyteller%20/%20Worldbuilder',
            info: 'Gives life to rich, elaborate, character-driven worlds with resonant lore.',
            skills: ['Imagineer', 'Magician', 'Artist'],
            origin: 'Locked',
            location: 'Locked',
        },
        {
            index: 8,
            faceImage: FaceSilhouetteEight,
            fullLengthImage: BodySilhouetteEight,
            name: 'Locked Character',
            role: CHARACTER_ROLE_TYPE.gameDeveloper,
            locked: true,
            unlockable: true,
            color: theme.verascopeColor.orange100,
            enterTransition: characterNineEnterTransition,
            exitTransition: characterNineExitTransition,
            applyLink: 'https://verascope.typeform.com/to/nhI3k4l4#character=Game%20Developer',
            info: 'Elegantly brings together aesthetics, story, technology, and game mechanics to'
            + ' construct satisfying interactive virtual experiences.',
            skills: ['Architect', 'Magician', 'Developer'],
            origin: 'Locked',
            location: 'Locked',
        },
    ], []);

    const CHARACTER_INFO_BORDER_TRANSITION_DURATION = 150;
    const CHARACTER_INFO_ROW_ICON_TRANSITION_DURATION = 150;
    const CHARACTER_INFO_ROW_TEXT_TRANSITION_DURATION = 150;
    const CHARACTER_INFO_PROFILE_ROW_BUTTON_TRANSITION_DURATION = 150;
    const CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION = 75;
    const CHARACTER_INFO_TEXT_STAGGER_OFFSET_DURATION = 75;
    const CHARACTER_INFO_PROFILE_BUTTON_STAGGER_DELAY_TRANSITION = 75;
    const MODAL_CLOSE_BUTTON_TRANSITION_DURATION = 300;
    const CHARACTER_INFO_BUTTON_TRANSITION_DURATION = 300;

    const characterInfoInfoIconEnterDelayDuration = useMemo(() => (
        CHARACTER_INFO_BORDER_TRANSITION_DURATION
    ), [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoInfoTextEnterDelayDuration = useMemo(() => (
        CHARACTER_INFO_BORDER_TRANSITION_DURATION
        + CHARACTER_INFO_TEXT_STAGGER_OFFSET_DURATION
    ), [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoSkillsIconEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoSkillsTextEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION
            + CHARACTER_INFO_TEXT_STAGGER_OFFSET_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoOriginIconEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].skills) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoOriginTextEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION
            + CHARACTER_INFO_TEXT_STAGGER_OFFSET_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].skills) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoLocationIconEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].skills) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].origin) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoLocationTextEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION
            + CHARACTER_INFO_TEXT_STAGGER_OFFSET_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].skills) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].origin) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoProfileIconEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].skills) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].origin) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].location) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoProfileButtonsEnterDelayDuration = useMemo(() => {
        let duration = CHARACTER_INFO_BORDER_TRANSITION_DURATION
            + CHARACTER_INFO_TEXT_STAGGER_OFFSET_DURATION;
        if (characters[selectedCharacterIndex].info) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].skills) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].origin) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        if (characters[selectedCharacterIndex].location) {
            duration += CHARACTER_INFO_ROW_STAGGER_OFFSET_DURATION;
        }

        return duration;
    }, [
        characters,
        selectedCharacterIndex,
    ]);

    const CHARACTER_NAME_PARALLELOGRAM_TRANSITION_DURATION = 250;
    const CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION = CHARACTER_NAME_PARALLELOGRAM_TRANSITION_DURATION;
    const CHARACTER_UNLOCK_BUTTON_TRANSITION_DURATION = CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION + 250;
    const CHARACTER_NAME_TEXT_TRANSITION_DURATION = 200;
    const CHARACTER_TILE_GRID_TRANSITION_DURATION = SHOW_CHARACTER_TILES_DELAY_DURATION
        + CHARACTER_TILE_TRANSITION_DURATION
        + characterNineEnterTransition
        + CHARACTER_TILE_STAGGER_OFFSET_DURATION;
    const CHARACTER_NAME_ROLE_BUFFER_DURATION = 50;

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.characters,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const onCharacterTileEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.characterTile,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCharacterTileLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onCharacterTileClick = async (index: number): Promise<void> => {
        setFreezeCharacterTextLayout(true);
        setZeroWidthParallelogram(true);
        setCachedCharacterIndex(index);
        clearTimeoutSetNextCharacterIndex();
        timeoutSetNextCharacterIndex();

        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) {
            const carouselIndex = index;
            setCarouselOffsetIndex(carouselIndex);
        }

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.selectCharacter,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    character: characters[index],
                },
            });
        }

        // Play Sound
        if (hasSound) {
            characterExitClip.current.pause();
            characterExitClip.current.currentTime = 0;
            playAudio(characterExitClip.current);
        }
    };

    const onCharacterInfoProfileButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.characterInfoProfileButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCharacterInfoProfileButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onCharacterInfoProfileButtonClick = async (
        type: CHARACTER_INFO_PROFILE_BUTTON_TYPE,
        text: string,
    ): Promise<void> => {
        switch (type) {
        case CHARACTER_INFO_PROFILE_BUTTON_TYPE.twitter:
            window.open(`https://twitter.com/${text}`, '_blank');
            break;
        case CHARACTER_INFO_PROFILE_BUTTON_TYPE.portfolio:
            window.open(text, '_blank');
            break;
        case CHARACTER_INFO_PROFILE_BUTTON_TYPE.mail:
            window.location.href = `mailto:${text}`;
            break;
        default:
        }

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickCharacterInfoProfileButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    character: characters[selectedCharacterIndex],
                    buttonType: type,
                },
            });
        }
    };

    const onCarouselButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.characterCarouselButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCarouselButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onCarouselButtonClick = (left: boolean): void => {
        let index = selectedCharacterIndex;
        if (left) {
            index += 1;
            if (index === CHARACTER_COUNT) index = 0;
        } else {
            index -= 1;
            if (index < 0) index = CHARACTER_COUNT - 1;
        }

        onCharacterTileClick(index);
    };

    const onCharacterInfoButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.characterInfoButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCharacterInfoButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onCharacterInfoButtonClick = async (): Promise<void> => {
        setModalIsOpen(true);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewCharacterInfo,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    character: characters[selectedCharacterIndex],
                },
            });
        }
    };

    const onModalCloseButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.modalCloseButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onModalCloseButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onModalCloseButtonClick = (): void => {
        setModalIsOpen(false);
    };

    const onUnlockCharacterButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.characterUnlockButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onUnlockCharacterButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const computeCharacterDetailContainerWidth = (): void => {
        if (characterDetailContainerRef.current) {
            const characterDetailContainerRect: DOMRect = characterDetailContainerRef.current.getBoundingClientRect();
            setCharacterDetailContainerWidth(characterDetailContainerRect.width);
        }
    };

    const computeCharacterTextMetadata = (): void => {
        if (characterNameRef.current && characterRoleRef.current) {
            const characterNameRect: DOMRect = characterNameRef.current.getBoundingClientRect();
            const characterRoleRect: DOMRect = characterRoleRef.current.getBoundingClientRect();
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const nameText = characterNameRef.current.textContent!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const roleText = characterRoleRef.current.textContent!;
            const nameCharCount: number = nameText.length;
            const roleCharCount: number = roleText.length;
            const nameLongestWordCharCount: number = Math.max(...(nameText.split(' ').map((word: string) => word.length)));
            const roleLongestWordCharCount: number = Math.max(...(roleText.split(' ').map((word: string) => word.length)));

            setCharacterTextMetadata({
                name: characterNameRect.width,
                nameCharCount,
                nameLongestWordCharCount,
                role: characterRoleRect.width,
                roleCharCount,
                roleLongestWordCharCount,
            });
        }
    };

    const onUnlockButtonMouseDown = (): void => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.applyCharacterRole,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    character: characters[selectedCharacterIndex],
                },
            });
        }
    };

    /**
     * Records user action of viewing characters page
     */
    const recordCharactersPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewCharactersPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'Characters (Team)',
        );
    }, []);

    /**
     * Records View Characters Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordCharactersPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        // Character Enter
        characterEnterClip.current.volume = DEFAULT_AUDIO_VOLUME;
        characterEnterClip.current.src = CharacterEnter;

        // Character Exit
        characterExitClip.current.volume = DEFAULT_AUDIO_VOLUME;
        characterExitClip.current.src = CharacterExit;

        return function cleanup() {
            characterEnterClip.current.remove();
            characterExitClip.current.remove();
        };
    }, []);

    useEffect(() => {
        clearTimeoutShowCharacterTiles();
        timeoutShowCharacterTiles();
    }, []);

    // Update Character Detail Container Width
    useEffect(() => {
        if (!characterDetailContainerWidth) {
            // On initialization, wait for the tiles to show before
            // computing character detail container width and showing
            // character detail
            clearTimeoutComputeCharacterDetailContainerWidth();
            timeoutComputeCharacterDetailContainerWidth();
        } else {
            // Compute the width of character detail container
            computeCharacterDetailContainerWidth();
        }
    }, [viewportDimensions]);

    useEffect(() => {
        if (characterDetailContainerWidth) {
            setZeroWidthParallelogram(true);
            clearTimeoutShowCharacterNameParallelogram();
            timeoutShowCharacterNameParallelogram();
            clearTimeoutSetIsInitialRender();
            timeoutSetIsInitialRender();
        }
    }, [characterDetailContainerWidth]);

    useEffect(() => {
        if (characterTextMetadata) {
            // Cache Character Name Longest Word Character Count
            setCachedCharacterNameLongestWordChar(characterTextMetadata.nameLongestWordCharCount);
            // Clear currrent character text metadata
            setCharacterTextMetadata(null);
        }
    }, [cachedCharacterIndex]);

    useEffect(() => {
        if (characterDetailContainerWidth) {
            clearTimeoutShowCharacterNameParallelogram();
            timeoutShowCharacterNameParallelogram();
        }
    }, [nextCharacterIndex]);

    useEffect(() => {
        // Compute the width of character text metadata
        clearTimeoutComputeCharacterTextMetadata();
        timeoutComputeCharacterTextMetadata();
    }, [nextCharacterIndex]);

    const {
        start: timeoutShowCharacterTiles,
        clear: clearTimeoutShowCharacterTiles,
    } = useTimeout(() => {
        setCharacterTilesVisible(true);
    }, SHOW_CHARACTER_TILES_DELAY_DURATION);

    const {
        start: timeoutSetIsInitialRender,
        clear: clearTimeoutSetIsInitialRender,
    } = useTimeout(() => {
        setIsInitialRender(false);
    }, characterInfoProfileButtonsEnterDelayDuration);

    const {
        start: timeoutComputeCharacterDetailContainerWidth,
        clear: clearTimeoutComputeCharacterDetailContainerWidth,
    } = useTimeout(() => {
        computeCharacterDetailContainerWidth();
    }, CHARACTER_TILE_GRID_TRANSITION_DURATION);

    const {
        start: timeoutComputeCharacterTextMetadata,
        clear: clearTimeoutComputeCharacterTextMetadata,
    } = useTimeout(() => {
        computeCharacterTextMetadata();
    }, CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION);

    // Not sure why we need to have double the full length image here
    const {
        start: timeoutShowCharacterNameParallelogram,
        clear: clearTimeoutShowCharacterNameParallelogram,
    } = useTimeout(() => {
        setZeroWidthParallelogram(false);
    }, 2 * CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION + CHARACTER_NAME_PARALLELOGRAM_TRANSITION_DURATION);

    const {
        start: timeoutSetSelectedCharacterIndex,
        clear: clearTimeoutSetSelectedCharacterIndex,
    } = useTimeout(() => {
        setSelectedCharacterIndex(nextCharacterIndex);

        // Play Sound
        if (hasSound) {
            characterEnterClip.current.pause();
            characterEnterClip.current.currentTime = 0;
            playAudio(characterEnterClip.current);
        }
    }, CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION);

    const {
        start: timeoutSetNextCharacterIndex,
        clear: clearTimeoutSetNextCharacterIndex,
    } = useTimeout(() => {
        setNextCharacterIndex(cachedCharacterIndex);
        // Unfreeze character text layout
        setFreezeCharacterTextLayout(false);
        // Clear Cached Character Name Longest Word Character Count
        setCachedCharacterNameLongestWordChar(null);
        clearTimeoutSetSelectedCharacterIndex();
        timeoutSetSelectedCharacterIndex();
    }, CHARACTER_NAME_TEXT_TRANSITION_DURATION);

    // ===== Memoization =====

    const characterInfoBorderIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && (
            !!characters[selectedCharacterIndex].info
            || !!characters[selectedCharacterIndex].skills
            || !!characters[selectedCharacterIndex].origin
            || !!characters[selectedCharacterIndex].location
            || !!characters[selectedCharacterIndex].twitterHandle
            || !!characters[selectedCharacterIndex].portfolioUrl
            || !!characters[selectedCharacterIndex].email
        )
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoInfoIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].info
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoSkillsIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].skills
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoOriginIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].origin
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoLocationIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].location
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoProfileIconIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && (
            !!characters[selectedCharacterIndex].twitterHandle
            || !!characters[selectedCharacterIndex].portfolioUrl
            || !!characters[selectedCharacterIndex].email
        )
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoProfileTwitterIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].twitterHandle
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoProfilePortfolioIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].portfolioUrl
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const characterInfoProfileMailIsVisible = useMemo(() => (
        !zeroWidthParallelogram
        && !!characters[selectedCharacterIndex].email
    ), [
        zeroWidthParallelogram,
        characters,
        selectedCharacterIndex,
    ]);

    const CharacterInfoContents = useMemo(() => (
        <>
            <Transition
                in={!!characters[selectedCharacterIndex].info}
                timeout={{
                    enter: 0,
                    exit: characterInfoProfileButtonsEnterDelayDuration,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CharacterInfoRow
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                                duration: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                                easing: theme.motion.overshoot,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                            })[state],
                        }}
                    >
                        <CharacterInfoRowIcon
                            src={InfoIcon}
                            visible={characterInfoInfoIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_ICON_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoInfoIsVisible
                                ? characterInfoInfoIconEnterDelayDuration
                                : characterInfoProfileButtonsEnterDelayDuration}
                        />
                        <CharacterInfoRowText
                            visible={characterInfoInfoIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_TEXT_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoInfoIsVisible
                                ? characterInfoInfoTextEnterDelayDuration
                                : characterInfoProfileButtonsEnterDelayDuration}
                        >
                            {characters[selectedCharacterIndex].info}
                        </CharacterInfoRowText>
                    </CharacterInfoRow>
                )}
            </Transition>
            <Transition
                in={!!characters[selectedCharacterIndex].skills}
                timeout={{
                    enter: 0,
                    exit: characterInfoProfileIconEnterDelayDuration,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CharacterInfoRow
                        margin
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                                duration: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                                easing: theme.motion.overshoot,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                            })[state],
                        }}
                    >
                        <CharacterInfoRowIcon
                            src={SkillsIcon}
                            visible={characterInfoSkillsIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_ICON_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoSkillsIsVisible
                                ? characterInfoSkillsIconEnterDelayDuration
                                : characterInfoProfileIconEnterDelayDuration}
                        />
                        <CharacterInfoRowText
                            visible={characterInfoSkillsIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_TEXT_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoSkillsIsVisible
                                ? characterInfoSkillsTextEnterDelayDuration
                                : characterInfoLocationTextEnterDelayDuration}
                        >
                            {characters[selectedCharacterIndex].skills?.reduce((skills, skill, index) => {
                                if (index === 0) {
                                    return skill;
                                }

                                return `${skills}, ${skill}`;
                            }, '')}
                        </CharacterInfoRowText>
                    </CharacterInfoRow>
                )}
            </Transition>
            <Transition
                in={!!characters[selectedCharacterIndex].origin}
                timeout={{
                    enter: 0,
                    exit: characterInfoLocationIconEnterDelayDuration,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CharacterInfoRow
                        margin
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                                duration: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                                easing: theme.motion.overshoot,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                            })[state],
                        }}
                    >
                        <CharacterInfoRowIcon
                            src={OriginIcon}
                            visible={characterInfoOriginIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_ICON_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoOriginIsVisible
                                ? characterInfoOriginIconEnterDelayDuration
                                : characterInfoLocationIconEnterDelayDuration}
                        />
                        <CharacterInfoRowText
                            visible={characterInfoOriginIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_TEXT_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoOriginIsVisible
                                ? characterInfoOriginTextEnterDelayDuration
                                : characterInfoOriginTextEnterDelayDuration}
                        >
                            {characters[selectedCharacterIndex].origin}
                        </CharacterInfoRowText>
                    </CharacterInfoRow>
                )}
            </Transition>
            <Transition
                in={!!characters[selectedCharacterIndex].location}
                timeout={{
                    enter: 0,
                    exit: characterInfoOriginIconEnterDelayDuration,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CharacterInfoRow
                        margin
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                                duration: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                                easing: theme.motion.overshoot,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                            })[state],
                        }}
                    >
                        <CharacterInfoRowIcon
                            src={LocationIcon}
                            visible={characterInfoLocationIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_ICON_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoLocationIsVisible
                                ? characterInfoLocationIconEnterDelayDuration
                                : characterInfoOriginIconEnterDelayDuration}
                        />
                        <CharacterInfoRowText
                            visible={characterInfoLocationIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_TEXT_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoLocationIsVisible
                                ? characterInfoLocationTextEnterDelayDuration
                                : characterInfoSkillsTextEnterDelayDuration}
                        >
                            {characters[selectedCharacterIndex].location}
                        </CharacterInfoRowText>
                    </CharacterInfoRow>
                )}
            </Transition>
            <Transition
                in={
                    !!characters[selectedCharacterIndex].twitterHandle
                    || !!characters[selectedCharacterIndex].portfolioUrl
                    || !!characters[selectedCharacterIndex].email
                }
                timeout={{
                    enter: 0,
                    exit: characterInfoSkillsIconEnterDelayDuration,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CharacterInfoRow
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                                duration: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                                easing: theme.motion.overshoot,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                offset: characterDetailContainerWidth!,
                            })[state],
                        }}
                    >
                        <CharacterInfoRowIcon
                            src={ProfileIcon}
                            visible={characterInfoProfileIconIsVisible}
                            transitionDuration={CHARACTER_INFO_ROW_ICON_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoProfileIconIsVisible
                                ? characterInfoProfileIconEnterDelayDuration
                                : characterInfoSkillsIconEnterDelayDuration}
                        />
                        <CharacterInfoRowButtonContainer>
                            <CharacterInfoProfileRowButton
                                className={HOVER_TARGET_CLASSNAME}
                                visible={characterInfoProfileTwitterIsVisible}
                                transitionDuration={CHARACTER_INFO_PROFILE_ROW_BUTTON_TRANSITION_DURATION}
                                transitionDelayDuration={characterInfoProfileTwitterIsVisible
                                    ? characterInfoProfileButtonsEnterDelayDuration
                                    : characterInfoInfoTextEnterDelayDuration}
                                onMouseEnter={onCharacterInfoProfileButtonEnter}
                                onMouseLeave={onCharacterInfoProfileButtonLeave}
                                onClick={() => onCharacterInfoProfileButtonClick(
                                    CHARACTER_INFO_PROFILE_BUTTON_TYPE.twitter,
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    characters[selectedCharacterIndex].twitterHandle!,
                                )}
                            >
                                <CharacterInfoProfileRowButtonIcon
                                    src={TwitterIcon}
                                />
                            </CharacterInfoProfileRowButton>
                            <CharacterInfoProfileRowButton
                                className={HOVER_TARGET_CLASSNAME}
                                visible={characterInfoProfilePortfolioIsVisible}
                                transitionDuration={CHARACTER_INFO_PROFILE_ROW_BUTTON_TRANSITION_DURATION}
                                transitionDelayDuration={characterInfoProfilePortfolioIsVisible
                                    ? characterInfoProfileButtonsEnterDelayDuration
                                    + CHARACTER_INFO_PROFILE_BUTTON_STAGGER_DELAY_TRANSITION
                                    : characterInfoInfoIconEnterDelayDuration}
                                onMouseEnter={onCharacterInfoProfileButtonEnter}
                                onMouseLeave={onCharacterInfoProfileButtonLeave}
                                onClick={() => onCharacterInfoProfileButtonClick(
                                    CHARACTER_INFO_PROFILE_BUTTON_TYPE.portfolio,
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    characters[selectedCharacterIndex].portfolioUrl!,
                                )}
                            >
                                <CharacterInfoProfileRowButtonIcon
                                    src={PortfolioIcon}
                                />
                            </CharacterInfoProfileRowButton>
                            <CharacterInfoProfileRowButton
                                className={HOVER_TARGET_CLASSNAME}
                                visible={characterInfoProfileMailIsVisible}
                                transitionDuration={CHARACTER_INFO_PROFILE_ROW_BUTTON_TRANSITION_DURATION}
                                transitionDelayDuration={characterInfoProfileMailIsVisible
                                    ? characterInfoProfileButtonsEnterDelayDuration
                                    + 2 * CHARACTER_INFO_PROFILE_BUTTON_STAGGER_DELAY_TRANSITION
                                    : 0}
                                onMouseEnter={onCharacterInfoProfileButtonEnter}
                                onMouseLeave={onCharacterInfoProfileButtonLeave}
                                onClick={() => onCharacterInfoProfileButtonClick(
                                    CHARACTER_INFO_PROFILE_BUTTON_TYPE.mail,
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    characters[selectedCharacterIndex].email!,
                                )}
                            >
                                <CharacterInfoProfileRowButtonIcon
                                    src={MailIcon}
                                />
                            </CharacterInfoProfileRowButton>
                        </CharacterInfoRowButtonContainer>
                    </CharacterInfoRow>
                )}
            </Transition>
        </>
    ), [
        characters,
        selectedCharacterIndex,
        characterInfoProfileButtonsEnterDelayDuration,
        characterDetailContainerWidth,
        characterInfoInfoIsVisible,
        characterInfoInfoIconEnterDelayDuration,
        characterInfoInfoTextEnterDelayDuration,
        characterInfoSkillsIsVisible,
        characterInfoSkillsIconEnterDelayDuration,
        characterInfoSkillsTextEnterDelayDuration,
        characterInfoOriginIsVisible,
        characterInfoOriginIconEnterDelayDuration,
        characterInfoOriginTextEnterDelayDuration,
        characterInfoLocationIsVisible,
        characterInfoLocationIconEnterDelayDuration,
        characterInfoLocationTextEnterDelayDuration,
        characterInfoProfileIconIsVisible,
        characterInfoProfileButtonsEnterDelayDuration,
        characterInfoProfileIconEnterDelayDuration,
        characterInfoProfileTwitterIsVisible,
        characterInfoProfilePortfolioIsVisible,
        characterInfoProfileMailIsVisible,
    ]);

    return (
        <Container>
            <PageLogo
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            <PageTitle
                withEnterTransition
            >
                CHARACTERS
            </PageTitle>
            <PageSubtitle
                withEnterTransition
                color={theme.verascopeColor.purple500}
            >
                We&rsquo;re assembling a team
            </PageSubtitle>
            {viewportDimensions.width < MEDIA_QUERY_SIZE.large.min
            && (
                <Modal
                    hasContainer
                    width={MODAL_WIDTH}
                    isOpen={modalIsOpen}
                    hasSound={hasSound}
                    closeModal={() => setModalIsOpen(false)}
                >
                    <ModalContentsContainer>
                        <ModalCloseButton
                            className={HOVER_TARGET_CLASSNAME}
                            transitionDuration={MODAL_CLOSE_BUTTON_TRANSITION_DURATION}
                            onMouseEnter={onModalCloseButtonEnter}
                            onMouseLeave={onModalCloseButtonLeave}
                            onClick={onModalCloseButtonClick}
                        >
                            <ReactSVG
                                src={CrossIcon}
                            />
                        </ModalCloseButton>
                        {CharacterInfoContents}
                    </ModalContentsContainer>
                </Modal>
            )}
            <CharacterTileContainer>
                <CharacterTileGrid>
                    {characters.map((tile, index) => (
                        <CharacterTile
                            index={index}
                            className={HOVER_TARGET_CLASSNAME}
                            rotateOffsetIndex={carouselOffsetIndex}
                            visible={characterTilesVisible}
                            isInitialRender={isInitialRender}
                            numTiles={CHARACTER_COUNT}
                            length={CHARACTER_TILE_LENGTH}
                            background={tile.color}
                            selected={tile.index === cachedCharacterIndex}
                            {...(tile.index !== selectedCharacterIndex
                                ? {
                                    onMouseEnter: (e) => onCharacterTileEnter(e),
                                    onMouseLeave: (e) => onCharacterTileLeave(e),
                                    onClick: () => onCharacterTileClick(tile.index),
                                } : {}
                            )}
                            transitionDuration={CHARACTER_TILE_TRANSITION_DURATION}
                            enterDelayTransitionDuration={tile.enterTransition}
                            exitDelayTransitionDuration={tile.enterTransition}
                        >
                            <CharacterTileImage
                                src={tile.faceImage}
                            />
                        </CharacterTile>
                    ))}
                </CharacterTileGrid>
                {viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                && (
                    <CarouselButton
                        leftSided
                        className={HOVER_TARGET_CLASSNAME}
                        visible={characterTilesVisible}
                        transitionDuration={CAROUSEL_BUTTON_TRANSITION_DURATION}
                        transitionDelayDuration={CHARACTER_TILE_GRID_TRANSITION_DURATION}
                        onMouseEnter={onCarouselButtonEnter}
                        onMouseLeave={onCarouselButtonLeave}
                        onClick={() => onCarouselButtonClick(true)}
                    >
                        <CarouselButtonIcon
                            src={ArrowIcon}
                        />
                    </CarouselButton>
                )}
                {viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                && (
                    <CarouselButton
                        className={HOVER_TARGET_CLASSNAME}
                        visible={characterTilesVisible}
                        transitionDuration={CAROUSEL_BUTTON_TRANSITION_DURATION}
                        transitionDelayDuration={CHARACTER_TILE_GRID_TRANSITION_DURATION}
                        onMouseEnter={onCarouselButtonEnter}
                        onMouseLeave={onCarouselButtonLeave}
                        onClick={() => onCarouselButtonClick(false)}
                    >
                        <CarouselButtonIcon
                            src={ArrowIcon}
                        />
                    </CarouselButton>
                )}
            </CharacterTileContainer>
            <CharacterDetailContainer
                ref={characterDetailContainerRef}
            >
                <Transition
                    in={
                        viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min
                        && !!characterTextMetadata
                        && !!characterDetailContainerWidth
                        && (
                            !!characters[selectedCharacterIndex].info
                            || !!characters[selectedCharacterIndex].skills
                            || !!characters[selectedCharacterIndex].origin
                            || !!characters[selectedCharacterIndex].location
                            || !!characters[selectedCharacterIndex].twitterHandle
                            || !!characters[selectedCharacterIndex].portfolioUrl
                            || !!characters[selectedCharacterIndex].email
                        )
                    }
                    timeout={{
                        enter: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                        exit: CHARACTER_NAME_TEXT_TRANSITION_DURATION,
                    }}
                >
                    {(state) => (
                        <CharacterInfoButton
                            className={HOVER_TARGET_CLASSNAME}
                            transitionDuration={CHARACTER_INFO_BUTTON_TRANSITION_DURATION}
                            style={{
                                ...FADE_IN_DEFAULT_STYLE({
                                    duration: CHARACTER_INFO_BUTTON_TRANSITION_DURATION,
                                    easing: theme.motion.overshoot,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({})[state],
                            }}
                            onMouseEnter={onCharacterInfoButtonEnter}
                            onMouseLeave={onCharacterInfoButtonLeave}
                            onClick={onCharacterInfoButtonClick}
                        >
                            <CharacterInfoButtonIcon
                                src={HeadIcon}
                            />
                        </CharacterInfoButton>
                    )}
                </Transition>
                <CharacterDetail>
                    <Transition
                        in={
                            viewportDimensions.width < MEDIA_QUERY_SIZE.large.min
                            && viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                            && !!characterTextMetadata
                            && !!characterDetailContainerWidth
                            && (
                                !!characters[selectedCharacterIndex].info
                                || !!characters[selectedCharacterIndex].skills
                                || !!characters[selectedCharacterIndex].origin
                                || !!characters[selectedCharacterIndex].location
                                || !!characters[selectedCharacterIndex].twitterHandle
                                || !!characters[selectedCharacterIndex].portfolioUrl
                                || !!characters[selectedCharacterIndex].email
                            )
                        }
                        timeout={{
                            enter: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                            exit: CHARACTER_NAME_TEXT_TRANSITION_DURATION,
                        }}
                    >
                        {(state) => (
                            <CharacterInfoButton
                                className={HOVER_TARGET_CLASSNAME}
                                transitionDuration={CHARACTER_INFO_BUTTON_TRANSITION_DURATION}
                                style={{
                                    ...FADE_IN_DEFAULT_STYLE({
                                        duration: CHARACTER_INFO_BUTTON_TRANSITION_DURATION,
                                        easing: theme.motion.overshoot,
                                    }),
                                    ...FADE_IN_TRANSITION_STYLES({})[state],
                                }}
                                onMouseEnter={onCharacterInfoButtonEnter}
                                onMouseLeave={onCharacterInfoButtonLeave}
                                onClick={onCharacterInfoButtonClick}
                            >
                                <CharacterInfoButtonIcon
                                    src={HeadIcon}
                                />
                            </CharacterInfoButton>
                        )}
                    </Transition>
                    <Transition
                        in={!!characterTextMetadata && !!characterDetailContainerWidth}
                        timeout={{
                            enter: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                            exit: CHARACTER_NAME_TEXT_TRANSITION_DURATION,
                        }}
                    >
                        {(state) => (
                            <CharacterFullLengthImage
                                src={characters[selectedCharacterIndex].fullLengthImage}
                                style={{
                                    ...FADE_IN_DEFAULT_STYLE({
                                        direction: viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                                            ? 'left'
                                            : 'down',
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        offset: isInitialRender
                                            ? DEFAULT_CHARACTER_FULL_LENGTH_IMAGE_OFFSET
                                            : characterDetailContainerWidth,
                                        duration: CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                                        easing: theme.motion.overshoot,
                                    }),
                                    ...FADE_IN_TRANSITION_STYLES({
                                        direction: viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                                            ? 'left'
                                            : 'down',
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        offset: isInitialRender
                                            ? DEFAULT_CHARACTER_FULL_LENGTH_IMAGE_OFFSET
                                            : characterDetailContainerWidth,
                                    })[state],
                                }}
                            />
                        )}
                    </Transition>
                    <Transition
                        in={
                            !!characterTextMetadata
                            && !!characterDetailContainerWidth
                            && characters[selectedCharacterIndex].locked
                            && !!user
                        }
                        timeout={{
                            enter: CHARACTER_UNLOCK_BUTTON_TRANSITION_DURATION,
                            exit: characters[selectedCharacterIndex].index === 0
                                ? 0
                                : CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION,
                        }}
                    >
                        {(state) => (
                            <ApplyButtonContainer>
                                <Button
                                    center
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={BUTTON_TYPE.solid}
                                    background={characters[selectedCharacterIndex].color}
                                    {...(characters[selectedCharacterIndex].unlockable
                                        ? {
                                            icon: UnlockIcon,
                                            text: 'Unlock Character',
                                            onMouseEnter: onUnlockCharacterButtonEnter,
                                            onMouseLeave: onUnlockCharacterButtonLeave,
                                            onMouseDown: onUnlockButtonMouseDown,
                                            link: {
                                                href: `${characters[selectedCharacterIndex].applyLink!}&uid=${user?.id || ''}`,
                                                newTab: true,
                                            },
                                        } : {
                                            icon: LockIcon,
                                            text: 'Locked',
                                            disabled: true,
                                        })
                                    }
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 20,
                                            duration: CHARACTER_UNLOCK_BUTTON_TRANSITION_DURATION,
                                            easing: theme.motion.overshoot,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 20,
                                        })[state],
                                    }}
                                />
                            </ApplyButtonContainer>
                        )}
                    </Transition>
                    <CharacterShadow />
                    <CharacterNameContainer>
                        <CharacterNameParallelogram
                            visible={!!characterTextMetadata && !!characterDetailContainerWidth}
                            zeroWidth={zeroWidthParallelogram}
                            width={characterTextMetadata?.name}
                            longestWordChar={characterTextMetadata?.nameLongestWordCharCount}
                            color={characters[selectedCharacterIndex].color}
                            transitionDuration={CHARACTER_NAME_PARALLELOGRAM_TRANSITION_DURATION}
                            transitionDelayDuration={CHARACTER_NAME_TEXT_TRANSITION_DURATION}
                        />
                        <CharacterNameTextContainer>
                            <CharacterNameText
                                ref={characterNameRef}
                                freezeLayout={freezeCharacterTextLayout}
                                width={characterTextMetadata?.name}
                                longestWordChar={characterTextMetadata?.nameLongestWordCharCount}
                                cachedLongestWordChar={cachedCharacterNameLongestWordChar}
                                color={characters[selectedCharacterIndex].color}
                                visible={!!characterTextMetadata && !!characterDetailContainerWidth}
                                transitionDuration={CHARACTER_NAME_TEXT_TRANSITION_DURATION}
                                transitionDelayDuration={
                                    2 * CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION
                                    + CHARACTER_NAME_PARALLELOGRAM_TRANSITION_DURATION
                                }
                            >
                                {characters[nextCharacterIndex].name}
                            </CharacterNameText>
                            <CharacterRoleText
                                ref={characterRoleRef}
                                freezeLayout={freezeCharacterTextLayout}
                                width={characterTextMetadata?.role}
                                longestWordChar={characterTextMetadata?.roleLongestWordCharCount}
                                cachedLongestWordChar={cachedCharacterNameLongestWordChar}
                                visible={!!characterTextMetadata && !!characterDetailContainerWidth}
                                transitionDuration={CHARACTER_NAME_TEXT_TRANSITION_DURATION}
                                transitionDelayDuration={
                                    2 * CHARACTER_FULL_LENGTH_IMAGE_TRANSITION_DURATION
                                    + CHARACTER_NAME_PARALLELOGRAM_TRANSITION_DURATION
                                    + 2 * CHARACTER_NAME_ROLE_BUFFER_DURATION
                                }
                            >
                                {characters[nextCharacterIndex].role}
                            </CharacterRoleText>
                        </CharacterNameTextContainer>
                    </CharacterNameContainer>
                    <CharacterInfo
                        hidden={false}
                    >
                        <CharacterInfoBorder
                            visible={characterInfoBorderIsVisible}
                            transitionDuration={CHARACTER_INFO_BORDER_TRANSITION_DURATION}
                            transitionDelayDuration={characterInfoBorderIsVisible
                                ? 0
                                : characterInfoProfileButtonsEnterDelayDuration}
                        />
                        {CharacterInfoContents}
                    </CharacterInfo>
                </CharacterDetail>
            </CharacterDetailContainer>
        </Container>
    );
}

export default CharactersView;
