// ===== Packages =====

import styled                       from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.color.white};
`;
