// ===== Packages =====

import linkifyIt        from 'linkify-it';
import tlds             from 'tlds';

const validateURL = (input: string): boolean => {
    // Inititalize Linkify
    const linkify = linkifyIt().tlds(tlds);

    let url = '';

    if (input.length === 0) return true;

    if (input.slice(0, 7) === 'http://') {
        // prefix = input.slice(0, 7);
        [, , url] = input.split('/');
    } else if (input.slice(0, 8) === 'https://') {
        // prefix = input.slice(0, 8);
        [, , url] = input.split('/');
    } else {
        url = input;
    }

    return linkify.test(url);
};

export default validateURL;
