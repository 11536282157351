// ===== Packages =====

import styled                               from 'styled-components';

// ===== Services =====

import {
    setColorLightness,
}                                           from '../../services';

// ===== Constants =====

import {
    EDITOR_OUTLINE_SECTION_LIGHTNESS_VALUE,
}                                           from '../../constants/generalConstants';
import FONT_TYPE                            from '../../constants/fontType';

const OUTLINE_CHAPTER_MARGIN_BOTTOM = 10;
const DRAG_INDICATOR_LENGTH = 20;
const GENERAL_INDICATOR_LENGTH = 15;
const CHAPTER_NON_EMPTY_INDICATOR_RIGHT = 5;
const CHAPTER_ANNOTATIONS_INDICATOR_RIGHT = GENERAL_INDICATOR_LENGTH + CHAPTER_NON_EMPTY_INDICATOR_RIGHT + 5;

interface ContainerProps {
    isDragging: boolean,
}
export const Container = styled.div<ContainerProps>`
    background: inherit;
    margin-bottom: ${`${OUTLINE_CHAPTER_MARGIN_BOTTOM}px`};
    opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
`;

interface OutlineChapterHeadProps {
    selected?: boolean,
    multipleChapters: boolean,
    transitionDuration: number,
}
export const OutlineChapterHead = styled.div<OutlineChapterHeadProps>`
    position: relative;
    padding: 10px 30px;
    padding-right: ${({ multipleChapters }) => (multipleChapters ? '30px' : '10px')};
    border-radius: 4px;
    border: ${({ selected, theme }) => (selected
        ? `4px solid ${setColorLightness(
            theme.verascopeColor.purple200,
            EDITOR_OUTLINE_SECTION_LIGHTNESS_VALUE,
        )}`
        : 'none'
    )};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: ${({ theme, transitionDuration }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};

        & + div {
            background: transparent;
        }
    }
`;

export const OutlineChapterTitleInputContainer = styled.div`
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: ${({ theme }) => `2px solid ${theme.color.neutral200}`};
`;

export const OutlineChapterTitleInput = styled.input`
    position: relative;
    width: 100%;
    background: transparent;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral1000};
    text-transform: none;
    border: none;
    text-decoration: none;
    cursor: none;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral400};
    }
`;

export const OutlineChapterDescriptionInput = styled.div`
    position: relative;
    background: transparent;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral1000};
    padding: 0;
    white-space: pre-wrap;
    cursor: none;

    &:empty:not(:focus):before {
        content: attr(data-placeholder);
        line-height: 1em;
        color: ${({ theme }) => theme.color.neutral400};
        pointer-events: none;
    }

    &:focus, &:hover {
        outline: none;
    }
`;

interface OutlineChapterDragIndicatorProps {
    horizontalOffset: number,
    transitionDuration: number,
}
export const OutlineChapterDragIndicator = styled.div<OutlineChapterDragIndicatorProps>`
    position: absolute;
    top: 50%;
    right: 0;
    transform: ${({ horizontalOffset }) => `translate(-${horizontalOffset}px, -50%)`};
    background: ${({ theme }) => theme.color.white};
    height: ${`${DRAG_INDICATOR_LENGTH}px`};
    width: ${`${DRAG_INDICATOR_LENGTH}px`};
    padding-right: 2px;
    opacity: 0;
    transition: ${({ theme, transitionDuration }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral500};
    }

    ${OutlineChapterHead}:hover & {
        opacity: 1;
        transform: translate(0px, -50%);
    };
`;

export const OutlineChapterBody = styled.div`
    position: relative;
    padding-left: 30px;
    background: inherit;
`;

export const OutlineNumberText = styled.span`
    position: absolute;
    top: 9px;
    left: 10px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 400;
    color: ${({ theme }) => theme.verascopeColor.gray300};
`;

interface AddSectionButtonProps {
    bottomSectionSelected: boolean,
    chapterSelected: boolean,
    transitionDuration: number,
}
export const AddSectionButton = styled.div<AddSectionButtonProps>`
    position: absolute;
    left: ${({ chapterSelected }) => `${chapterSelected ? 8 : 4}px`};
    bottom: ${({ bottomSectionSelected }) => `${bottomSectionSelected ? 9 : 5}px`};
    width: 22px;
    height: 22px;
    margin-top: 5px;
    border-radius: 11px;
    background-color: ${({ theme }) => theme.color.neutral400};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    transition: ${({ theme, transitionDuration }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.standardEasing}
    `};
`;

export const AddSectionButtonIcon = styled.div`
    display: inline-block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    transition: ${({ theme }) => `transform 300ms ${theme.motion.standardEasing}`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        fill: ${({ theme }) => theme.color.white};
        width: 100%;
        height: 100%;
    }

    ${AddSectionButton}:hover & {
        transform: translate(-50%, -50%) rotate(180deg);
    }
`;

interface ChapterNonEmptyIndicatorProps {
    isVisible: boolean,
    transitionDuration: number,
}
export const ChapterNonEmptyIndicator = styled.div<ChapterNonEmptyIndicatorProps>`
    position: absolute;
    bottom: 5px;
    right: ${`${CHAPTER_NON_EMPTY_INDICATOR_RIGHT}px`};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    z-index: 2; // Place above non-empty indicator in z-space
    transition: ${({ theme, transitionDuration }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div > svg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${`${GENERAL_INDICATOR_LENGTH}px`};
        width: ${`${GENERAL_INDICATOR_LENGTH}px`};
    }

    & svg {
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;

interface ChapterAnnotationsIndicatorProps {
    isVisible: boolean,
    nonEmptyIndicatorVisible: boolean,
    transitionDuration: number,
}
export const ChapterAnnotationsIndicator = styled.div<ChapterAnnotationsIndicatorProps>`
    position: absolute;
    bottom: 5px;
    right: ${({ nonEmptyIndicatorVisible }) => `${nonEmptyIndicatorVisible
        ? CHAPTER_ANNOTATIONS_INDICATOR_RIGHT
        : CHAPTER_NON_EMPTY_INDICATOR_RIGHT
    }px`};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    z-index: 1;
    transition: ${({ theme, transitionDuration }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div > svg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${`${GENERAL_INDICATOR_LENGTH}px`};
        width: ${`${GENERAL_INDICATOR_LENGTH}px`};
    }

    & svg {
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;
