// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

// ===== Interfaces =====

import {
    IAddress,
    ICartItem,
    IShippoRateObject,
}                               from '../interfaces';

const stripeUpdatePaymentIntent = async ({
    test,
    paymentIntentId,
    cartItems,
    customerId,
    paymentMethodId,
    userEmail,
    mailingAddress,
    billingAddress,
    shippingRate,
    promotionIds,
    addToMailingList,
    savePaymentMethod,
    trackingNumber,
}: {
    test: boolean,
    paymentIntentId: string,
    cartItems?: ICartItem[],
    customerId?: string,
    paymentMethodId?: string | null,
    userEmail?: string,
    mailingAddress?: IAddress,
    billingAddress?: IAddress,
    shippingRate?: IShippoRateObject,
    promotionIds?: string[],
    addToMailingList?: boolean,
    savePaymentMethod?: boolean,
    trackingNumber?: string,
}): Promise<Stripe.PaymentIntent> => {
    const functions = getFunctions();
    const updatePaymentIntent = httpsCallable<
        Record<
            string,
            boolean | string | IAddress | IShippoRateObject | string[] | ICartItem[]
        >,
        Stripe.PaymentIntent
    >(
        functions,
        'stripeUpdatePaymentIntent',
    );

    const args: Record<string, boolean | string | IAddress | IShippoRateObject | string[] | ICartItem[]> = {
        test,
        paymentIntentId,
    };
    if (cartItems) args.cartItems = [...cartItems];
    if (customerId) args.customerId = customerId;
    if (paymentMethodId) args.paymentMethodId = paymentMethodId;
    if (userEmail) args.userEmail = userEmail;
    if (mailingAddress) args.mailingAddress = mailingAddress;
    if (billingAddress) args.billingAddress = billingAddress;
    if (shippingRate) args.shippingRate = shippingRate;
    if (promotionIds) args.promotionIds = [...promotionIds];
    if (addToMailingList) args.addToMailingList = addToMailingList;
    if (savePaymentMethod) args.savePaymentMethod = savePaymentMethod;
    if (trackingNumber) args.trackingNumber = trackingNumber;

    return updatePaymentIntent(args).then((result) => {
        const paymentIntent = result.data as Stripe.PaymentIntent;
        return paymentIntent;
    }).catch((err) => err);
};

export default stripeUpdatePaymentIntent;
