// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';

// ===== Constants =====

import { EDITOR_FIGURE_MARGIN }                 from '../../../constants/generalConstants';

interface Props {
    rerender: boolean,
    setRerender: React.Dispatch<React.SetStateAction<boolean>>,
    children: React.ReactElement,
    attributes: any,
}
function Gallery({
    children,
    rerender = false,
    attributes,
    setRerender,
}: Props): JSX.Element {
    // ===== State =====

    const [height, setHeight] = useState<number | undefined>();

    // ===== Refs =====

    // We use this ref to access Gallery ref because
    // there is an issue assigning ref directly using figure
    //
    // This likely has to do with Slate attributes
    const childRef = useRef<HTMLSpanElement>(null);

    // ===== Side Effects =====

    // Calculate Gallery Height
    useEffect(() => {
        if (
            childRef.current
        ) {
            // Determine Gallery Height
            const childHeights: number[] = [];
            const { childNodes } = childRef.current.parentElement!;
            childNodes.forEach((child: ChildNode) => {
                childHeights.push((child as HTMLElement).clientHeight);
            });

            setHeight(Math.max(...childHeights) + 2 * EDITOR_FIGURE_MARGIN);
        }

        if (rerender && setRerender) {
            setRerender(false);
        }
    }, [
        childRef.current,
        rerender,
    ]);

    return (
        <Container
            {...attributes}
            height={height}
        >
            {children}
            <RefNode
                ref={childRef}
            />
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    height: number,
}
const Container = styled.div<ContainerProps>`
    height: ${({ height }) => `${height}px`};
`;

const RefNode = styled.span``;

export default Gallery;
