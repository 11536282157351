// ===== Packages =====

import {
    Node,
    Editor,
    Range,
    Transforms,
    NodeEntry,
}                           from 'slate';

// ===== Elements =====

import { ListItemElement }  from '../elements/listElement';
import _LineElement         from '../elements/lineElement';

// ===== Interfaces =====

import {
    LineNode,
    EditorNode,
}                           from '../interfaces';

const TAB_WHITESPACE = '    ';

export const LineElement = _LineElement;

const getSelectionLines = (editor: Editor): NodeEntry<Node>[] => {
    const start = Range.start(editor.selection as Range).path;
    const end = Range.end(editor.selection as Range).path;
    return Array
        .from(Node.nodes(editor, { from: start, to: end }))
        .filter(([node]) => LineElement.isLineElement(node));
};

const setAsLineElement = (editor: Editor): void => {
    Array.from(Editor.nodes(editor, { match: LineElement.implementsLineInterface })).forEach(([, path]) => {
        Transforms.setNodes<LineNode>(editor, { type: LineElement.TYPE }, { at: path });
    });
};

const indentLineElements = (editor: Editor): void => {
    Editor.withoutNormalizing(editor, () => {
        Array.from(Editor.nodes(editor, { match: LineElement.isLineElement })).forEach(([, linePath]) => {
            const lineIsInList = Array.from(Node.ancestors(editor, linePath))
                .some(([ancestor]) => ListItemElement.isListItemElement(ancestor));
            if (lineIsInList) { return; }

            const [, firstTextNodeOfLinePath] = Editor.leaf(editor, linePath, { edge: 'start' });
            const startOfLinePoint = {
                path: firstTextNodeOfLinePath,
                offset: 0,
            };
            Transforms.insertText(editor, TAB_WHITESPACE, { at: startOfLinePoint });
        });
    });
};

const unindentLineElements = (editor: Editor): void => {
    const TAB_SIZE = TAB_WHITESPACE.length;

    Editor.withoutNormalizing(editor, () => {
        Array.from(Editor.nodes(editor, { match: LineElement.isLineElement })).forEach(([, linePath]) => {
            const lineIsInList = Array.from(Node.ancestors(editor, linePath))
                .some(([ancestor]) => ListItemElement.isListItemElement(ancestor));
            if (lineIsInList) { return; }

            const [firstTextNodeOfLine, firstTextNodeOfLinePath] = Editor.leaf(editor, linePath, { edge: 'start' });
            const firstNonWhitespaceCharIndex = firstTextNodeOfLine.text.search(/\S|$/);
            const hasLeadingTab = firstNonWhitespaceCharIndex >= TAB_SIZE;
            if (hasLeadingTab) {
                const lineStartPoin = {
                    path: firstTextNodeOfLinePath,
                    offset: 0,
                };
                Transforms.delete(editor, { at: lineStartPoin, distance: TAB_SIZE });
            }
        });
    });
};

export const LineHelpers = {
    getSelectionLines,
    setAsLineElement,
    indentLineElements,
    unindentLineElements,
};

export const withLines = (editor: Editor): Editor => {
    const {
        normalizeNode,
        isInline,
        isVoid,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [_, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as EditorNode;

        if (!LineElement.isLineElement(node)) { return normalizeNode(entry); }

        const lineContainsBlocks = Editor.hasBlocks(editor, node);
        if (lineContainsBlocks) { return Transforms.unwrapNodes(editor, { at: path }); }

        const lineDeclaresIsLineProperty = node.isLine === true;
        if (!lineDeclaresIsLineProperty) { return Transforms.setNodes<LineNode>(editor, { isLine: true }, { at: path }); }

        const lineDeclaresIsListableProperty = node.isListable === true;
        if (!lineDeclaresIsListableProperty) { return Transforms.setNodes<LineNode>(editor, { isListable: true }, { at: path }); }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => (LineElement.isLineElement(element) ? false : isInline(element));

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => (LineElement.isLineElement(node) ? false : isVoid(node));

    return editor;
};
