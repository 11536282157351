// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> prepareDailyDigest
export enum AUTHENTICATION_TYPE {
    google = 'google',
    facebook = 'facebook',
    github = 'github',
    twitter = 'twitter',
    email = 'email',
    anonymous = 'anonymous',
    // microsoft = 'microsoft', // Requires registering the web app with Azure Active Directory v2.0 endpoint: https://firebase.google.com/docs/auth/web/microsoft-oauth
    // apple = 'apple', // Requires Apple Dev subscription with accompanying web app: https://firebase.google.com/docs/auth/web/apple
}
