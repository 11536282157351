// ===== Enums =====

import {
    FILTER_TYPE,
} from '../enums';

// ===== Services =====

import getFilterTypePathKey from './getFilterTypePathKey';

const getFilterPath = (
    searchParams: URLSearchParams,
    type: FILTER_TYPE,
    filterText: string,
): URLSearchParams => {
    let path = searchParams;
    const pathKey = getFilterTypePathKey(type);
    const isApplied = path.getAll(pathKey).includes(filterText);

    if (!isApplied) {
        path = new URLSearchParams([
            ...Array.from(path),
            [pathKey, filterText],
        ]);
    } else {
        path = new URLSearchParams(
            Array.from(path).filter(
                ([key, value]) => key !== pathKey || value !== filterText,
            ),
        );
    }

    return path;
};

export default getFilterPath;
