// ===== Packages =====

import emoji                from 'emoji.json';

// ===== Interfaces =====

import {
    IIconObject,
    IEmoji,
}                           from '../interfaces';

const findEmoji = (char: string): IEmoji => {
    const index = emoji.findIndex((e: IEmoji) => e.char === char);
    return emoji[index];
};

const getIconObject = (char: string): IIconObject => {
    const emji = findEmoji(char);
    return {
        char,
        name: emji.name,
        category: emji.category,
    };
};

export default getIconObject;
