// ===== Packages =====

import React, {
    useCallback,
    useMemo,
    useState,
    useEffect,
    useRef,
}                                                       from 'react';
import styled                                           from 'styled-components';
import { ReactSVG }                                     from 'react-svg';
import {
    Slate,
    Editable,
    withReact,
    ReactEditor,
}                                                       from 'slate-react';
import {
    createEditor,
    Node,
    Transforms,
    Editor,
    Descendant,
    Location,
    NodeEntry,
    Text,
    Selection,
}                                                       from 'slate';
import { withHistory }                                  from 'slate-history';
import { Transition }                                   from 'react-transition-group';
import { useDropzone }                                  from 'react-dropzone';
import { v4 as uuidv4 }                                 from 'uuid';
import {
    UploadTask,
    StorageError,
    getDownloadURL,
    UploadTaskSnapshot,
    StorageReference,
}                                                       from 'firebase/storage';
import ShortUniqueId                                    from 'short-unique-id';
import mime                                             from 'mime-types';

// ===== Components =====

import {
    SlateLeaf,
    SlatePlaceholder,
    DropzoneInput,
}                                                       from '../../../styles';
import Modal                                            from '../../Modal';
import Tooltip                                          from '../../Tooltip';
import {
    Button,
    UploadingMediaItem,
    GenericModal,
    EmojiSelector,
    PortableToolbar,
    StationaryToolbar,
}                                                       from '../helpers';
import {
    BottomBufferElement,
    BottomBufferHelpers,
    withBottomBuffers,
}                                                       from '../plugins/bottomBuffersPlugin';
import { EMPHASIZER_MARK }                              from '../plugins/enums';
import {
    withDividers,
    DividerHelpers,
    DividerElement,
}                                                       from '../plugins/dividersPlugin';
import {
    EmphasizerHelpers,
}                                                       from '../plugins/emphasizersPlugin';
import {
    withHeaders,
    HeaderHelpers,
    HeaderElement,
}                                                       from '../plugins/headersPlugin';
import {
    LineHelpers,
    withLines,
}                                                       from '../plugins/linesPlugin';
import {
    withAudioNotes,
    AudioNoteHelpers,
    AudioNoteElement,
}                                                       from '../plugins/audioNotesPlugin';
import {
    withWebLinks,
    WebLinkHelpers,
    WebLinkElement,
}                                                       from '../plugins/webLinksPlugin';
import {
    withLists,
    ListHelpers,
    ListElement,
    ListItemElement,
}                                                       from '../plugins/listsPlugin';
import {
    withFigures,
    FigureHelpers,
    CaptionElement,
    FigureElement,
    FigureContentElement,
    FigureContentTypes,
}                                                       from '../plugins/figuresPlugin';
import {
    withBlockQuotes,
    BlockQuoteHelpers,
    BlockQuoteElement,
}                                                       from '../plugins/blockQuotesPlugin';
import HandleOnKeyDown                                  from '../plugins/handleHotKeys';
import {
    BlockQuote,
    BottomBuffer,
    Caption,
    Divider,
    Figure,
    HeaderOne,
    HeaderTwo,
    Image,
    AudioNote,
    InlineLink,
    List,
    ListItem,
    Paragraph,
    YouTubeEmbed,
    TwitterEmbed,
    SpotifyEmbed,
    VimeoEmbed,
}                                                       from '../renderers';

// ===== Services =====

import {
    getMediaStorageBucket,
    setMediaInDB,
    setColorLightness,
    uploadToCloudStorage,
    playAudio,
    getStorageErrorMessage,
    binarySearchIndex,
    copyToClipboard,
    recordUserAction,
}                                                       from '../../../services';

// ===== Hooks =====

import {
    useTimeout,
    useEventListener,
}                                                       from '../../../hooks';

// ===== Enums =====

import {
    EDITOR_TOOLBAR_TYPE,
    EDITOR_TOOLBAR_TOOL_GROUP,
    TOOLTIP_TYPE,
    EDITOR_CONTEXT_TYPE,
    STORAGE_ENTITY,
    MEDIA_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    STORAGE_ERROR_CODE,
    BUTTON_TYPE,
    USER_ACTION_TYPE,
}                                                       from '../../../enums';

// ===== Interfaces =====

import {
    AudioNoteNode,
    FigureNode,
    WebLinkNode,
}                                                       from '../interfaces';
import {
    ICoord,
    IMediaItem,
    IModalMessage,
    IAnnotationQuote,
    IDimension,
    IUserItem,
    ISnackbarItem,
    IEditorDecoration,
    ITranscriptionWord,
    IAnnotationValue,
}                                                       from '../../../interfaces';

// ===== Sounds =====

import InputClick                                       from '../../../sounds/button_click.mp3';

// ===== Images =====

import ArrowIcon                                        from '../../../images/editor/arrow.svg';
import ToolsIcon                                        from '../../../images/editor/tools.svg';
import SmileyIcon                                       from '../../../images/editor/smiley.svg';
import UploadIcon                                       from '../../../images/editor/upload.svg';
import CrossIcon                                        from '../../../images/editor/cross.svg';
import MicrophoneIcon                                   from '../../../images/editor/mic.svg';
import CheckmarkIcon                                    from '../../../images/editor/checkmark.svg';
import ClipboardIcon                                    from '../../../images/editor/clipboard.svg';
import CautionIcon                                      from '../../../images/caution.svg';

// ===== Constants =====

import {
    INITIAL_EDITOR_VALUE,
    SERIALIZED_TRUE_INITIAL_EDITOR_VALUE,
    SERIALIZED_INITIAL_EDITOR_VALUE,
    BUTTON_CONTAINER_LIGHTNESS_VALUE_HOVER,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    FADE_IN_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    EDITOR_SEND_DELAY,
    EDITOR_COLLECT_UNSAVED_AUDIO_NOTES_DELAY,
    DEFAULT_AUDIO_VOLUME,
    HOVER_TARGET_CLASSNAME,
    ANNOTATION_EDITOR_Z_INDEX,
    DEFAULT_ANNOTATION_EDITOR_HEIGHT,
    SLATE_EDITOR_CLASSNAME,
    NANOSECONDS_IN_A_SECOND,
    MILLISECONDS_IN_A_SECOND,
    REMOVE_LEADING_AND_TRAILING_PUNCTUATION_REGEX,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    SLATE_EDITOR_SELECT_ALL_TEXT_TIMEOUT_DURATION,
}                                                       from '../../../constants/generalConstants';
import { SAVE_POST_DURING_AUDIO_RECORDING_ERROR }       from '../../../constants/notificationMessages';
import CURSOR_SIGN                                      from '../../../constants/cursorSigns';
import KEYCODE                                          from '../../../constants/keycodes';
import FONT_TYPE                                        from '../../../constants/fontType';
import MEDIA_QUERY_SIZE                                 from '../../../constants/mediaQuerySizes';
import {
    TOOL_MARGIN,
}                                                       from '../helpers/constants';

// ===== Styles =====

import { theme as themeObj }                            from '../../../themes/theme-context';

const EDITOR_PLUGINS = [
    withFigures,
    withLists,
    withHeaders,
    withLines,
    withWebLinks,
    withBlockQuotes,
    withBottomBuffers,
    withDividers,
    withReact,
    withHistory,
    withAudioNotes,
];

AnnotationEditor.defaultProps = {
    isEditing: false,
    message: undefined,
    extractValue: false,
    handleExtractValue: undefined,
    updatePosition: undefined,
    setRef: null,
    placeholder: null,
    shouldFocus: false,
    submitAnnotation: undefined,
    editAnnotation: undefined,
    cancelEditAnnotation: undefined,
    top: null,
    left: null,
    viewportDimensions: null,
    postEditor: null,
    postQuote: null,
    setPostQuote: undefined,
    width: null,
    postId: undefined,
    setEditor: undefined,
    boxShadow: false,
    remeasureParentHeight: undefined,
    dictationPlaying: false,
    transcript: undefined,
    timeElapsed: null,
};
interface Props {
    id: string,
    user: IUserItem | null,
    hasSound: boolean,
    readOnly: boolean,
    isEditing?: boolean,
    currentSessionId: string | null,
    boxShadow?: boolean,
    type: EDITOR_CONTEXT_TYPE,
    color: string,
    fontMultiplier: number,
    width?: number,
    message?: IAnnotationValue,
    top?: number,
    left?: number,
    submitAnnotation?: (
        value: string,
        text: string,
        quote: IAnnotationQuote,
        media: string[],
    ) => void,
    editAnnotation?: (
        value: string,
        text: string,
        media: string[],
    ) => void,
    cancelEditAnnotation?: () => void,
    shouldFocus?: boolean,
    isAuthor: boolean,
    placeholder?: string,
    extractValue?: boolean,
    handleExtractValue?: (value: string) => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    updatePosition?: () => void,
    setRef?: (ref: HTMLDivElement) => void,
    postEditor?: Editor | null,
    postQuote?: IAnnotationQuote | null,
    setPostQuote?: React.Dispatch<React.SetStateAction<IAnnotationQuote | null>>,
    viewportDimensions?: IDimension,
    postId?: string,
    setEditor?: (editor: Editor) => void,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    remeasureParentHeight?: () => void,
    dictationPlaying?: boolean,
    transcript?: any,
    timeElapsed?: number,
}
function AnnotationEditor({
    id,
    user,
    top = 0,
    left = 0,
    hasSound,
    readOnly,
    isEditing,
    currentSessionId,
    boxShadow,
    type,
    color,
    fontMultiplier,
    width,
    message,
    submitAnnotation,
    editAnnotation,
    shouldFocus = false,
    isAuthor,
    placeholder,
    extractValue = false,
    handleExtractValue,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    updatePosition,
    setRef,
    postEditor,
    postQuote,
    setPostQuote,
    viewportDimensions,
    postId,
    setEditor,
    setSnackbarData,
    remeasureParentHeight,
    dictationPlaying,
    transcript,
    timeElapsed,
    cancelEditAnnotation,
}: Props): JSX.Element {
    // ===== General Constants =====

    const EDITOR_BUTTON_CONTAINER_HEIGHT = 40;
    const COPY_TEXT_BUTTON_CONTAINER_HEIGHT = 30;
    const EDITOR_BUTTON_CONTAINER_PADDING = 5;
    const EDITOR_BUTTON_LENGTH = EDITOR_BUTTON_CONTAINER_HEIGHT - 2 * (EDITOR_BUTTON_CONTAINER_PADDING);
    const EDITOR_BUTTON_PADDING = 5;
    const EDITOR_BUTTON_MARGIN = 5;
    const NUM_EDITOR_BUTTONS = 4;
    const STATIONARY_TOOL_BAR_TRANSITION_DURATION = 200;
    const EMOJI_SELECTOR_TRANSITION_DURATION = 150;
    const NUM_ACTIVE_STATIONARY_TOOLBAR_GROUPS = 3;
    const EMOJI_SELECTOR_HEIGHT = 200;
    const SLATE_CONTAINER_DRAG_ACTIVE_MARGIN_RIGHT = 30;
    const UPLOAD_TRANSITION_DELAY = 200;
    const STATIONARY_EDITOR_MIN_HEIGHT = EDITOR_BUTTON_CONTAINER_HEIGHT;
    const AUDIO_NOTE_AUTO_RECORD_IDENTIFIER = 'https://verasco.pe/auto-record-audio-note';
    const TOOLBAR_BUTTON_LENGTH = EDITOR_BUTTON_LENGTH - 2 * TOOL_MARGIN;
    const EDITOR_TRANSITION_DURATION = 150;
    const DICTATION_WORD_SEARCH_EXTENT = 3;
    const SNACKBAR_MESSAGE_COPY_HIGHLIGHT_TEXT_SUCCESS = 'Copied highlighted text to clipboard!';
    const SNACKBAR_MESSAGE_COPY_HIGHLIGHT_TEXT_ERROR = 'There was a problem copying highlighted text to your clipboard.';
    const SNACKBAR_MESSAGE_HIGHLIGHT_HEADER_TEXT_ERROR = 'Headers cannot be annotated.';
    const SLATE_PLACEHOLDER_FONT_MULTIPLIER = 0.9;

    // ===== Ref =====

    const containerRef = useRef<HTMLDivElement>(null);
    const selectionToolbarRef = useRef<HTMLDivElement | null>(null);

    // ----- Sound Clips
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const getSavedSlateValue = (): Descendant[] => {
        const postContent = message;
        if (
            !postContent
            || (
                postContent
                && postContent.value.length === 0
            )
        ) {
            return INITIAL_EDITOR_VALUE;
        }

        let contentTree;
        try {
            contentTree = JSON.parse(postContent!.value);
        } catch (error) {
            throw Error('There was a problem interpreting the annotation message.');
        }

        const tempEditor = EDITOR_PLUGINS
            .reduce((
                compositeEditor: Editor,
                withPlugin: (editor: Editor) => Editor,
            ) => withPlugin(compositeEditor), createEditor());
        tempEditor.children = contentTree;
        Editor.normalize(tempEditor, { force: true });
        return tempEditor.children;
    };
    const slateValue = useMemo(getSavedSlateValue, []);
    // Stores the slate editor value
    const [value, setValue] = useState(slateValue);
    const [xPos, setXPos] = useState<number>(left);
    const [yPos, setYPos] = useState<number>(top);
    // Allow Submit Message
    const [canSubmit, setCanSubmit] = useState(false);
    // Stores the position of the selection toolbar
    const [selectionToolbarPosition, setSelectionToolbarPosition] = useState<ICoord>({
        y: 24,
        x: -10,
    });
    // Stores whether we should rerender elements that have measuring logic
    // This occurs when we need to wait for rendering to complete, such as with images
    // in figures.
    const [rerenderMeasuringElements, setRerenderMeasuringElements] = useState<boolean>(false);
    // Stores whether audio is being recorded
    // Makes sure we record one audio note at a time
    const [isRecordingAudioNote, setIsRecordingAudioNote] = useState<boolean>(false);
    // Stores the number of unsaved audio notes
    // Used to save audio notes if user forgets
    const [numberUnsavedAudioNotes, setNumberUnsavedAudioNotes] = useState<number>(0);
    // Flag that is see to inform unsaved audio notes to save
    const [saveOutstandingAudioNotes, setSaveOutstandingAudioNotes] = useState<boolean>(false);
    // Flag that is used to inform audio notes to communicate if they are unsaved
    const [notifyIfUnsavedAudioNote, setNotifyIfUnsavedAudioNote] = useState<boolean>(false);
    // Stores the state of the editor modal
    const [modal, setModal] = useState<{
        visible: boolean,
        message: IModalMessage,
        proceedCallback?:() => void,
        rejectCallback?: () => void,
            }>({
                visible: false,
                message: {},
            });
    // Stores whether the stationary toolbar is visible
    const [stationaryToolbarIsActive, setStationaryToolbarIsActive] = useState<boolean>(false);
    // Schedules modification of stationaryToolbarIsActive
    const [showStationaryToolbar, setShowStationaryToolbar] = useState<boolean>(false);
    // Indicates whether annotation editor is visible
    const [showAnnotationEditor, setShowAnnotationEditor] = useState<boolean>(readOnly);
    // Stores whether emoji selector is exposed
    const [showEmojiSelector, setShowEmojiSelector] = useState<boolean>(false);
    // Stores the height of the stationary toolbar
    const [stationaryToolbarHeight, setStationaryToolbarHeight] = useState<number>(0);
    // Flag to enable rapid creation of an audio note
    const [autoRecord, setAutoRecord] = useState<boolean>(false);
    // Upload dropzone files to storage
    // and create figure or audio note
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        if (!readOnly) {
            const uploadTasks: UploadTask[] = [];
            const mediaItems: IMediaItem[] = [];
            for (let i = 0; i < acceptedFiles.length; i += 1) {
                const file = acceptedFiles[i];
                let mediaType: MEDIA_TYPE;
                const mediaClass = file.type.split('/')[0];

                switch (mediaClass) {
                case 'audio':
                    mediaType = MEDIA_TYPE.audioNote;
                    break;
                default:
                    // All images will be mapped to image annotation because we
                    // don't expect sketches to be uploaded via this flow
                    mediaType = MEDIA_TYPE.image;
                    break;
                }

                const mediaId = uuidv4();
                const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
                const mediaBucket = getMediaStorageBucket(mediaType);
                const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
                const fileExtension = mime.extension(file.type);
                const storageEntity = process.env.NODE_ENV === 'development'
                    ? STORAGE_ENTITY.stagingAnnotations
                    : STORAGE_ENTITY.annotations;
                const filePath = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
                const mediaItem: IMediaItem = {
                    id: mediaId,
                    userId: user!.id,
                    file,
                    filePath,
                    type: mediaType,
                    uploadProgress: 0,
                };
                mediaItems.push(mediaItem);
                addUploadingMedia(mediaItem);

                // make db entry of media
                // execute before file upload so upload cloud function has a place to write to
                setMediaInDB({
                    mediaItem,
                    filePath,
                });

                // upload file to cloud storage
                // url will be set by cloud function
                const uploadTask = uploadToCloudStorage(
                    file,
                    filePath,
                );
                uploadTasks.push(uploadTask);
            }

            uploadTasks.forEach((task: UploadTask, index: number) => {
                task.on(
                    'state_changed',
                    (snapshot: UploadTaskSnapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadingStateChangeEvent({
                            mediaItem: mediaItems[index],
                            snapshot,
                            progress,
                        });
                    },
                    (error: StorageError) => {
                        throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                    },
                    () => {
                        setUploadingCompleteEvent({
                            mediaItem: mediaItems[index],
                            storageRef: task.snapshot.ref,
                        });
                    },
                );
            });
        }
    }, [
        readOnly,
        user,
    ]);
    // Set up dropzone for images and audio
    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject,
    } = useDropzone({
        multiple: true,
        noClick: true,
        noKeyboard: true,
        accept: ['image/*', 'audio/*'],
        onDropAccepted,
    });
    const [uploadingMedia, setUploadingMedia] = useState<Map<string, IMediaItem>>(new Map());
    // Various media items are competing to write over this, so we don't independently
    // We track the progress within each media item not here
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    // Various media items are competing to write over this, so we don't independently
    // We track the completion within each media item not here
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    const [dictationWords, setDictationWords] = useState<ITranscriptionWord[]>([]);
    const [currentDictationWordIndex, setCurrentDictationWordIndex] = useState<number | null>(null);

    const editor = useMemo(() => EDITOR_PLUGINS
        .reduce((compositeEditor, withPlugin) => withPlugin(compositeEditor), createEditor()), []);

    // ===== Hotkeys =====

    const handleOnKeyDown = useMemo(() => HandleOnKeyDown({
        tabCallback: () => {
            ListHelpers.indentSelectionLists(editor);
            LineHelpers.indentLineElements(editor);
        },
        shiftTabCallback: () => {
            ListHelpers.unindentSelectionLists(editor);
            LineHelpers.unindentLineElements(editor);
        },
        modBCallback: () => EmphasizerHelpers.toggleBold(editor),
        modICallback: () => EmphasizerHelpers.toggleItalicize(editor),
        modUCallback: () => EmphasizerHelpers.toggleUnderline(editor),
        modACallback: () => timeoutSelectAllText(),
        modShiftSCallback: () => EmphasizerHelpers.toggleStrikethrough(editor),
    }), []);

    // ===== Decorations =====

    const decorate = useCallback(([node, path]: NodeEntry) => {
        const decorations: IEditorDecoration[] = [];

        if (
            transcript
            && dictationWords.length > 0
            && typeof currentDictationWordIndex === 'number'
            && (
                dictationPlaying
                || (timeElapsed && timeElapsed > 0)
            ) && Text.isText(node)
        ) {
            const { text } = node;
            const currentDictationWord = dictationWords[currentDictationWordIndex];
            const parts = text.split(' ');
            const matchIndices = parts.reduce((indices: number[], word: string, index: number) => {
                const newIndices = [...indices];
                if (
                    word.replace(
                        REMOVE_LEADING_AND_TRAILING_PUNCTUATION_REGEX,
                        '',
                    ) === currentDictationWord.word.replace(
                        REMOVE_LEADING_AND_TRAILING_PUNCTUATION_REGEX,
                        '',
                    )
                ) {
                    newIndices.push(index);
                }
                return newIndices;
            }, []);
            const candidateIndices = matchIndices.filter((index: number) => (
                currentDictationWordIndex < index + DICTATION_WORD_SEARCH_EXTENT
                &&  currentDictationWordIndex > index - DICTATION_WORD_SEARCH_EXTENT));

            if (candidateIndices.length > 0) {
                const anchorOffset = parts
                    .slice(0, candidateIndices[0])
                    .reduce((str, word, index) => {
                        if (index === 0) {
                            return `${word} `;
                        }

                        return `${str}${word} `;
                    }, '').length;
                const decoration = {
                    anchor: { path, offset: anchorOffset },
                    focus: { path, offset: anchorOffset + parts[candidateIndices[0]].length },
                    dictationHighlight: true,
                    highlightColor: themeObj.verascopeColor.purple400,
                };
                decorations.push(decoration);
            }
        }
        return decorations;
    }, [
        dictationWords,
        timeElapsed,
        currentDictationWordIndex,
        transcript,
        dictationPlaying,
    ]);

    // ===== Side Effects =====

    // Set reference to editor to parent
    useEffect(() => {
        if (setEditor) setEditor(editor);
    }, []);

    useEffect(() => {
        if (containerRef.current && setRef) {
            setRef(containerRef.current);
        }
    }, [containerRef.current]);

    // update position of editor
    useEffect(() => {
        if (top !== yPos) {
            setYPos(top);
        }

        if (left !== xPos) {
            setXPos(left);
        }
    }, [
        top,
        left,
    ]);

    // Update to post
    // This case will not be affected by autoSave updates
    useEffect(() => {
        if (message) {
            const updatedValue = getSavedSlateValue();
            setValue(updatedValue);
        }
    }, [
        message,
    ]);

    // Set Slate Editor Reference
    // and focus editor
    useEffect(() => {
        // Set Selection so cursor can be focused
        if (shouldFocus) {
            Transforms.select(editor, { path: [0, 0], offset: 0 });
        }
    }, []);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (inputClickClip.current) {
            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;
        }

        return function cleanup() {
            if (inputClickClip.current) inputClickClip.current.remove();
        };
    }, []);

    // Redetermine if we can submit message
    // Called when there is a change to Slate value
    useEffect(() => {
        // Update Can Submit Status
        updateCanSubmit(value);
    }, [value]);

    // Update Positions of Toolbars on Resize
    useEventListener(
        'resize',
        () => {
            // Recompute height of stationary toolbar
            // Recompute height of stationary toolbar
            clearDelayComputeStationaryToolbarHeight();
            delayComputeStationaryToolbarHeight();
        },
    );

    useEffect(() => {
        if (transcript) {
            const transcriptWords = transcript.data.reduce((words: any[], phrase: any) => (
                [
                    ...words,
                    ...phrase.alternatives[0].words,
                ]
            ), []);
            const words: ITranscriptionWord[] = [];
            for (let i = 0; i < transcriptWords.length; i += 1) {
                const word = transcriptWords[i] as ITranscriptionWord;
                words.push(word);
            }

            setDictationWords([
                ...words,
            ]);
        } else {
            setDictationWords([]);
        }
    }, [
        transcript,
    ]);

    // Find and store current dictation word index
    useEffect(() => {
        if (dictationWords.length > 0) {
            const currentWordIndex = binarySearchIndex(
                dictationWords,
                (word: ITranscriptionWord): boolean => typeof timeElapsed === 'number'
                    && (MILLISECONDS_IN_A_SECOND * parseInt(word.endTime.seconds, 10))
                    + (MILLISECONDS_IN_A_SECOND * word.endTime.nanos) / NANOSECONDS_IN_A_SECOND < timeElapsed,
                (word: ITranscriptionWord): boolean => typeof timeElapsed === 'number'
                    && (MILLISECONDS_IN_A_SECOND * parseInt(word.startTime.seconds, 10))
                    + (MILLISECONDS_IN_A_SECOND * word.startTime.nanos) / NANOSECONDS_IN_A_SECOND > timeElapsed,
            );

            if (typeof currentWordIndex === 'number') {
                setCurrentDictationWordIndex(currentWordIndex);
            }
        }
    }, [
        timeElapsed,
        dictationWords,
    ]);

    // Delay show stationaryToolbar open
    // Waits for stationary toolbar container to animate in
    const {
        start: delayShowStationaryToolbar,
        clear: clearDelayShowStationaryToolbar,
    } = useTimeout(() => {
        setShowStationaryToolbar(true);
    }, STATIONARY_TOOL_BAR_TRANSITION_DURATION);

    useEffect(() => {
        if (stationaryToolbarIsActive) {
            clearDelayShowStationaryToolbar();
            delayShowStationaryToolbar();
        }
    }, [stationaryToolbarIsActive]);

    // Delay stationaryToolbarIsActive close
    // Waits for closing animations to complete
    const {
        start: delayStationaryToolbarIsActive,
        clear: clearDelayStationaryToolbarIsActive,
    } = useTimeout(() => {
        setStationaryToolbarIsActive(false);
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((NUM_ACTIVE_STATIONARY_TOOLBAR_GROUPS - 1) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ));

    /**
     * Delay editor select all text so it takes
     * place after default selection behavior
     */
    const {
        start: timeoutSelectAllText,
    } = useTimeout(() => {
        // Select entire contents of the editor
        Transforms.select(editor, {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
        });
    }, SLATE_EDITOR_SELECT_ALL_TEXT_TIMEOUT_DURATION);

    useEffect(() => {
        if (!showStationaryToolbar) {
            clearDelayStationaryToolbarIsActive();
            delayStationaryToolbarIsActive();
        }
    }, [showStationaryToolbar]);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    // Update Stationary Toolbar Height Value
    const {
        start: delayComputeStationaryToolbarHeight,
        clear: clearDelayComputeStationaryToolbarHeight,
    } = useTimeout(() => {
        if (containerRef.current) {
            const height = containerRef.current.clientHeight;
            setStationaryToolbarHeight(height);
        }
    }, Math.min(
        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
        ((NUM_ACTIVE_STATIONARY_TOOLBAR_GROUPS - 1) * FADE_IN_STAGGER_OFFSET_DURATION)
        + FADE_IN_STAGGER_TRANSITION_DURATION,
    ));
    useEffect(() => {
        if (containerRef.current) {
            // Recompute height of stationary toolbar
            clearDelayComputeStationaryToolbarHeight();
            delayComputeStationaryToolbarHeight();
        }
    }, [
        value,
        stationaryToolbarIsActive,
        containerRef.current,
    ]);

    // Handle send once all audio notes are saved
    useEffect(() => {
        if (
            saveOutstandingAudioNotes
            && numberUnsavedAudioNotes === 0
        ) {
            // All audio notes have been saved
            // Now can send
            setSaveOutstandingAudioNotes(false);
            delayHandleSave();
        }
    }, [numberUnsavedAudioNotes]);

    // Move Selection to Start of document if edit or cancel edit annotation
    useEffect(() => {
        if (!isEditing) {
            Transforms.select(editor, { path: [0, 0], offset: 0 });
        }
    }, [isEditing]);

    const {
        start: delayHandleSave,
    } = useTimeout(() => {
        handleSubmit();
    }, EDITOR_SEND_DELAY);

    const {
        start: delayDetermineIfCanSend,
        clear: clearDelayDetermineIfCanSend,
    } = useTimeout(() => {
        if (numberUnsavedAudioNotes > 0) {
            // Turn of signal to request number of unsaved audio notes
            setNotifyIfUnsavedAudioNote(false);
            // Send signal for audio notes to save
            setSaveOutstandingAudioNotes(true);
        } else {
            handleSubmit();
        }
    }, EDITOR_COLLECT_UNSAVED_AUDIO_NOTES_DELAY);

    useEffect(() => {
        if (extractValue && handleExtractValue) {
            const extractedValue = JSON.stringify(value, null, 2);
            handleExtractValue(extractedValue);
        }
    }, [extractValue, value]);

    // Inspect Mouseup events to confirm if user has
    // used mouse to select text.
    // If so, show Annotation Editor.
    useEventListener(
        'mouseup',
        (e: Event) => {
            if (!postId) return;
            const targetEditor = e.composedPath().find((ele: EventTarget) => {
                const target = (ele as HTMLElement);
                if (target.dataset) {
                    return target.dataset.id === postId;
                }

                return false;
            });

            if (
                !readOnly
                && !!targetEditor
            ) {
                const nativeSelection = window.getSelection();
                const showEditor = !nativeSelection?.isCollapsed;

                // If text selection and Annotation Editor not visible already
                // Show Annotation Editor
                if (
                    !nativeSelection?.isCollapsed
                    && showEditor !== showAnnotationEditor
                    && updatePosition
                    && postEditor
                    && nativeSelection?.toString()
                    && nativeSelection.toString().trim().length > 0
                    && setPostQuote
                ) {
                    let { selection } = postEditor;
                    if (!selection || Editor.string(postEditor, selection as Location).length === 0) {
                        selection = ReactEditor.toSlateRange(
                            postEditor,
                            window.getSelection()!,
                            { exactMatch: true, suppressThrow: true },
                        ) as Selection;
                    }

                    const selectedHeaderElements = Array.from(Editor.nodes(postEditor, {
                        at: selection as Location,
                        match: HeaderElement.isHeaderElement,
                    })).length > 0;

                    // We don't allow users to annotate headers because it interferes with the logic
                    // we've set up to navigate to post sections via the Contents Table
                    // The target element's text cannot be distrbuted between subelements
                    if (selectedHeaderElements) {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_HIGHLIGHT_HEADER_TEXT_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                        return;
                    }

                    setShowAnnotationEditor(true);

                    if (
                        viewportDimensions
                        && viewportDimensions.width < MEDIA_QUERY_SIZE.extraSmall.max
                    ) setStationaryToolbarIsActive(true);

                    updatePosition();
                    setPostQuote({
                        id: uuidv4(),
                        timestamp: Date.now(),
                        selections: [selection],
                        texts: [Editor.string(postEditor, selection as Location)],
                    });
                    moveCursorToEndOfDocument();
                } else if (
                    showAnnotationEditor
                    && containerRef.current
                    && e.composedPath
                    && !e.composedPath().includes(containerRef.current)
                ) {
                    setShowAnnotationEditor(false);
                    if (postQuote && setPostQuote) {
                        setPostQuote(null);
                    }
                }
            }
        },
    );

    // Inspect Keyup events to confirm if user has
    // used keys to select text.
    // If so, show selection toolbar.
    useEventListener(
        'keyup',
        (e: Event) => {
            if (!postId) return;
            const targetEditor = e.composedPath().find((ele: EventTarget) => {
                const target = (ele as HTMLElement);
                if (target.dataset) {
                    return target.dataset.id === postId;
                }

                return false;
            });

            if (
                !readOnly
                && !!targetEditor
            ) {
                const nativeSelection = window.getSelection();
                const showEditor = !nativeSelection?.isCollapsed;

                // If Text Selection and Selection Toolbar not Visible Already
                // Show Selection Toolbar
                if (
                    !nativeSelection?.isCollapsed
                    && showEditor
                    && showEditor !== showAnnotationEditor
                    && updatePosition
                    && postEditor
                    && setPostQuote
                ) {
                    let { selection } = postEditor;
                    if (!selection) {
                        selection = ReactEditor.toSlateRange(
                            postEditor,
                            window.getSelection()!,
                            { exactMatch: true, suppressThrow: true },
                        ) as Selection;
                    }

                    const selectedHeaderElements = Array.from(Editor.nodes(postEditor, {
                        at: selection as Location,
                        match: HeaderElement.isHeaderElement,
                    })).length > 0;

                    // We don't allow users to annotate headers because it interferes with the logic
                    // we've set up to navigate to post sections via the Contents Table
                    // The target element's text cannot be distrbuted between subelements
                    if (selectedHeaderElements) {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_HIGHLIGHT_HEADER_TEXT_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                        return;
                    }

                    setShowAnnotationEditor(true);

                    if (
                        viewportDimensions
                        && viewportDimensions.width < MEDIA_QUERY_SIZE.extraSmall.max
                    ) setStationaryToolbarIsActive(true);

                    updatePosition();
                    setPostQuote({
                        id: uuidv4(),
                        timestamp: Date.now(),
                        selections: [selection],
                        texts: [Editor.string(postEditor, selection as Location)],
                    });
                    moveCursorToEndOfDocument();
                }

                if (
                    nativeSelection?.isCollapsed
                    && document.activeElement !== containerRef.current
                    && !showEditor
                    && !postQuote
                    && showEditor !== showAnnotationEditor
                ) {
                    setShowAnnotationEditor(false);
                    if (postQuote && setPostQuote) {
                        setPostQuote(null);
                    }
                }
            }
        },
    );

    // Detect copy
    useEventListener(
        'keydown',
        (e: Event) => {
            const keyboardEvent = e as KeyboardEvent;
            if (
                showAnnotationEditor
                && !readOnly
                && !!postQuote
                && keyboardEvent.metaKey
                && keyboardEvent.key === 'c'
            ) {
                copyHighlightText();
            }
        },
    );

    const {
        start: timeoutResetEditorValue,
        clear: clearTimeoutResetEditorValue,
    } = useTimeout(() => {
        setValue(INITIAL_EDITOR_VALUE);
        editor.children = INITIAL_EDITOR_VALUE;
    }, EDITOR_TRANSITION_DURATION);

    // ===== Methods =====

    const updateSelectionToolbarPosition = (): void => {
        const nativeSelection = window.getSelection();
        let y = 24;
        let x = -52;
        if (
            nativeSelection
            && nativeSelection.rangeCount > 0
            && containerRef.current
            && selectionToolbarRef.current
        ) {
            const range = nativeSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const toolbarWidth = selectionToolbarRef.current.children[0]
                ? selectionToolbarRef.current.children[0].clientWidth
                : 0;
            y = (rect.top - containerRef.current.getBoundingClientRect().top
                - (selectionToolbarRef.current.offsetHeight + 7)
            );
            x = Math.max(
                ((rect.left - containerRef.current.getBoundingClientRect().left + rect.width / 2)
                    - (toolbarWidth / 2)
                ),
                0,
            );
        }
        setSelectionToolbarPosition({
            x,
            y,
        });
    };

    // Function is reactive to changes to the slate value (making links)
    // and changes to the selection (placing cursor on links)
    const getSelectedLinkURL = useMemo(() => {
        if (editor.selection) {
            const links = WebLinkHelpers.getSelectionWebLinks(editor);
            if (links.length === 1) {
                const [link] = links;
                return (link[0] as WebLinkNode).href;
            }
        }
        return null;
    }, [
        value,
        editor.selection,
    ]);

    // Function is reactive to changes to the slate value (making links)
    // and changes to the selection (placing cursor on links)
    const getSelectedLinkID = useMemo(() => {
        if (editor.selection) {
            const links = WebLinkHelpers.getSelectionWebLinks(editor);
            if (links.length === 1) {
                const [link] = links;
                return (link[0]as WebLinkNode).id;
            }
        }
        return null;
    }, [
        value,
        editor.selection,
    ]);

    const moveCursorToEndOfDocument = (): void => {
        const [lastTextNode, lastTextNodePath] = Editor.leaf(editor, [], { edge: 'end' });
        const lastDocumentPoint = {
            path: lastTextNodePath,
            offset: lastTextNode.text.length,
        };
        Transforms.select(editor, lastDocumentPoint);
        ReactEditor.focus(editor);
    };

    const updateCanSubmit = (val: Descendant[]): void => {
        const newValue = JSON.stringify(val, null, 2);
        if (
            newValue !== SERIALIZED_TRUE_INITIAL_EDITOR_VALUE
            && newValue !== SERIALIZED_INITIAL_EDITOR_VALUE
        ) {
            setCanSubmit(true);
        } else if (
            canSubmit
            && (
                newValue === SERIALIZED_TRUE_INITIAL_EDITOR_VALUE
                || newValue === SERIALIZED_INITIAL_EDITOR_VALUE
            )
        ) {
            setCanSubmit(false);
        }
    };

    const determineIfOutstandingAudioNotes = (e: React.MouseEvent): void => {
        e.preventDefault();
        setNotifyIfUnsavedAudioNote(true);
        clearDelayDetermineIfCanSend();
        delayDetermineIfCanSend();
    };

    const handleSubmit = (): void => {
        const executeSend = (): void => {
            if (postQuote && !isEditing && submitAnnotation) {
                // TODO if slow, do equality check by looking at intial elements of Slate Editor
                // i.e. One Line and Bottom Buffer
                const newValue = JSON.stringify(value, null, 2);
                const newText = Editor.string(editor, []);

                // Get annotation media
                // TODO: Add sketch too
                const figureNodes = Node.nodes(editor);
                const linkNodes = Node.nodes(editor);
                const audioNoteNodes = Node.nodes(editor);
                const annotationFigures = Array.from(figureNodes)
                    .filter(([node]) => FigureElement.isFigureElement(node))
                    .map(([node]) => (node as FigureNode).id);
                const annotationLinks = Array.from(linkNodes)
                    .filter(([node]) => WebLinkElement.isWebLinkElement(node))
                    .map(([node]) => (node as WebLinkNode).id);
                const annotationAudioNotes = Array.from(audioNoteNodes)
                    .filter(([node]) => AudioNoteElement.isAudioNoteElement(node))
                    .map(([node]) => (node as AudioNoteNode).id);
                const annotationMedia = [
                    ...annotationFigures,
                    ...annotationLinks,
                    ...annotationAudioNotes,
                ];

                // Submit Annotation
                submitAnnotation(newValue, newText, postQuote, annotationMedia);

                // Move Selection to Start of document
                Transforms.select(editor, { path: [0, 0], offset: 0 });

                // Reset Message Value
                const emptyValue = getSavedSlateValue();
                setValue(emptyValue);

                // Close Emoji Selector if Open
                if (showEmojiSelector) {
                    setShowEmojiSelector(false);
                }

                if (shouldFocus) {
                    ReactEditor.focus(editor);
                }

                // Hide annotation Editor
                setShowAnnotationEditor(false);
                if (postQuote && setPostQuote) {
                    setPostQuote(null);
                }

                // Clear Annotation Editor
                clearTimeoutResetEditorValue();
                timeoutResetEditorValue();
            } else if (isEditing && editAnnotation) {
                // TODO if slow, do equality check by looking at intial elements of Slate Editor
                // i.e. One Line and Bottom Buffer
                const newValue = JSON.stringify(value, null, 2);
                const newText = Editor.string(editor, []);

                // Get annotation media
                // TODO: Add sketch too
                const figureNodes = Node.nodes(editor);
                const linkNodes = Node.nodes(editor);
                const audioNoteNodes = Node.nodes(editor);
                const annotationFigures = Array.from(figureNodes)
                    .filter(([node]) => FigureElement.isFigureElement(node))
                    .map(([node]) => (node as FigureNode).id);
                const annotationLinks = Array.from(linkNodes)
                    .filter(([node]) => WebLinkElement.isWebLinkElement(node))
                    .map(([node]) => (node as WebLinkNode).id);
                const annotationAudioNotes = Array.from(audioNoteNodes)
                    .filter(([node]) => AudioNoteElement.isAudioNoteElement(node))
                    .map(([node]) => (node as AudioNoteNode).id);
                const annotationMedia = [
                    ...annotationFigures,
                    ...annotationLinks,
                    ...annotationAudioNotes,
                ];

                // Edit Annotation
                editAnnotation(newValue, newText, annotationMedia);

                // Close Emoji Selector if Open
                if (showEmojiSelector) {
                    setShowEmojiSelector(false);
                }
            } else {
                throw Error('There was a problem creating annotation.');
            }
        };

        if (isRecordingAudioNote) {
            // Inform user that they are attempting to save while audio
            // recording in progress
            setModal({
                visible: true,
                message: SAVE_POST_DURING_AUDIO_RECORDING_ERROR,
                proceedCallback: () => {
                    if (!readOnly) executeSend();
                    setModal({
                        visible: false,
                        message: SAVE_POST_DURING_AUDIO_RECORDING_ERROR,
                    });
                },
                rejectCallback: () => {
                    setModal({
                        visible: false,
                        message: SAVE_POST_DURING_AUDIO_RECORDING_ERROR,
                    });
                },
            });
        } else if (!readOnly) {
            executeSend();
        }
    };

    const handleEmojiButton = (e: React.MouseEvent): void => {
        e.preventDefault();
        setShowEmojiSelector(!showEmojiSelector);
    };

    const handleStationaryToolbarButton = (e: React.MouseEvent): void => {
        e.preventDefault();
        if (showStationaryToolbar) {
            setShowStationaryToolbar(false);
            // We set stationaryToolbarIsActive to false after a timeout
        } else {
            setStationaryToolbarIsActive(true);
        }
    };

    const handleRecordAudio = (e: React.MouseEvent): void => {
        e.preventDefault();

        // Select end of document if no selection
        if (!editor.selection) {
            moveCursorToEndOfDocument();
        }

        // Initiate Auto Record
        setAutoRecord(true);

        // Create Audio Note
        AudioNoteHelpers.insertAudioNote(editor, {
            id: uuidv4(),
            filePath: AUDIO_NOTE_AUTO_RECORD_IDENTIFIER,
        });

        const figureNodes = Node.nodes(editor);
        const linkNodes = Node.nodes(editor);
        const annotationFigures = Array.from(figureNodes)
            .filter(([node]) => FigureElement.isFigureElement(node))
            .map(([node]) => (node as FigureNode).id);
        const annotationLinks = Array.from(linkNodes)
            .filter(([node]) => WebLinkElement.isWebLinkElement(node))
            .map(([node]) => (node as WebLinkNode).id);
        const noText = Array.from(Node.nodes(editor))
            .reduce((empty, [node]) => empty && Node.string(node).length === 0, true);
        if (
            annotationFigures.length === 0
            && annotationLinks.length === 0
            && noText
        ) {
            // Move cursor to end of document only if audio note is first thing
            // user places in annotation
            moveCursorToEndOfDocument();
        }
    };

    const addUploadingMedia = (mediaItem: IMediaItem): void => {
        setUploadingMedia(new Map([
            ...Array.from(uploadingMedia.entries()),
            [mediaItem.id, mediaItem],
        ]));
    };

    const updateUploadingMedia = (mediaItem: IMediaItem): void => {
        setUploadingMedia(new Map([
            ...Array.from(uploadingMedia.entries()),
            [mediaItem.id, mediaItem],
        ]));
    };

    const removeUploadingMedia = (mediaId: string): void => {
        if (uploadingMedia.has(mediaId)) {
            uploadingMedia.delete(mediaId);
            setUploadingMedia(new Map([
                ...Array.from(uploadingMedia.entries()),
            ]));
        }
    };

    const onEditableMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter && !readOnly) {
            onCursorEnter(
                CURSOR_TARGET.annotator,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onEditableMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave && !readOnly) onCursorLeave(e);
    };

    const onEditableFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onEditableBlur = (): void => {
        setInputFocused(false);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        fileURL: string,
    ): void => {
        // Select end of document if no selection
        if (!editor.selection) {
            moveCursorToEndOfDocument();
        }

        // Update uploading media item
        updateUploadingMedia({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...uploadingMedia.get(mediaItem.id)!,
            url: fileURL,
        });

        const fileType = mediaItem.file.type.split('/')[0];
        switch (fileType) {
        case 'audio': {
            // Create Audio Note
            AudioNoteHelpers.insertAudioNote(editor, {
                id: mediaItem.id,
                filePath: mediaItem.filePath,
                title: mediaItem.file.name,
                uploaded: true,
            });
            break;
        }
        case 'image': {
            // Create Figure
            FigureHelpers.insertFigure(
                editor,
                mediaItem.id,
                undefined,
                mediaItem.filePath,
                mediaItem.file.name,
            );
            break;
        }
        default:
            break;
        }
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            addUploadingMedia(mediaItem);
        }
    };

    const checkForEscape = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === KEYCODE.escape) {
            const annotationEditor = document.querySelector(`[data-id='${id}']`);
            if (annotationEditor) {
                // Blur Input
                (annotationEditor as HTMLElement).blur();
                // Hide Editor
                setShowAnnotationEditor(false);
                if (postQuote && setPostQuote) {
                    setPostQuote(null);
                }
            }
        }
    };

    const onImageLoad = (): void => {
        setRerenderMeasuringElements(true);
        if (remeasureParentHeight) remeasureParentHeight();
    };

    const onEmbedLoad = (): void => {
        setRerenderMeasuringElements(true);
        if (remeasureParentHeight) remeasureParentHeight();
    };

    const onAudioNoteAdjustHeight = (): void => {
        setRerenderMeasuringElements(true);
        if (remeasureParentHeight) remeasureParentHeight();
    };

    const copyHighlightText = (): void => {
        if (!postQuote || readOnly) return;
        if (postQuote.texts.length > 1) throw new Error('Encountered unexpected non-contiguous selection in Annotatio Editor.');

        const highlightText = postQuote.texts[0]; // Will always be a contiguous selection
        copyToClipboard(
            highlightText,
            () => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_COPY_HIGHLIGHT_TEXT_SUCCESS,
                    icon: ClipboardIcon,
                });
            },
            () => {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_COPY_HIGHLIGHT_TEXT_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            },
        );

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.copyTextHighlight,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    text: highlightText,
                },
            });
        }
    };

    // ===== Rendering =====

    const renderModal = (): React.ReactElement => (
        <Modal
            hasContainer
            hasSound={hasSound}
            isOpen={modal.visible}
            closeModal={modal.rejectCallback}
        >
            <GenericModal
                color={color}
                message={modal.message}
                callback={modal.proceedCallback}
                hideCancel={!modal.rejectCallback}
            />
        </Modal>
    );

    const renderElement = useCallback(({
        element,
        attributes,
        children,
    }: {
        element: any,
        attributes: any,
        children: any,
    }) => {
        if (BlockQuoteElement.isBlockQuoteElement(element)) {
            return (
                <BlockQuote
                    attributes={attributes}
                >
                    {children}
                </BlockQuote>
            );
        }

        if (BottomBufferElement.isBottomBufferElement(element)) {
            return (
                <BottomBuffer
                    adjustSelectionAroundBottomBuffer={() => BottomBufferHelpers.adjustSelectionAroundBottomBuffer(editor)}
                    height={0}
                    readOnly={readOnly}
                    editorType={type}
                    attributes={attributes}
                >
                    {children}
                </BottomBuffer>
            );
        }

        if (CaptionElement.isCaptionElement(element)) {
            return (
                <Caption
                    id={element.id}
                    text={Node.string(element)}
                    contentType={element.figureContentType}
                    attributes={attributes}
                    placeholder="Write a caption..."
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                >
                    {children}
                </Caption>
            );
        }

        if (DividerElement.isDividerElement(element)) {
            return (
                <Divider
                    color={color}
                    attributes={attributes}
                >
                    {children}
                </Divider>
            );
        }

        if (FigureElement.isFigureElement(element)) {
            if (FigureElement.isFigureElement(element)) {
                const { url, filePath, id: figureId } = element.children[0];
                switch (element.figureContentType) {
                case FigureContentTypes.youtube:
                    return (
                        <YouTubeEmbed
                            id={figureId}
                            url={url}
                            color={color}
                            readOnly={readOnly}
                            user={user}
                            currentSessionId={currentSessionId}
                            postId={postId}
                            editorType={type}
                            attributes={attributes}
                            onLoad={onEmbedLoad}
                        >
                            {children}
                        </YouTubeEmbed>
                    );
                case FigureContentTypes.twitter:
                    return (
                        <TwitterEmbed
                            id={figureId}
                            url={url}
                            color={color}
                            readOnly={readOnly}
                            user={user}
                            currentSessionId={currentSessionId}
                            postId={postId}
                            editorType={type}
                            attributes={attributes}
                            onLoad={onEmbedLoad}
                        >
                            {children}
                        </TwitterEmbed>
                    );
                case FigureContentTypes.spotify:
                    return (
                        <SpotifyEmbed
                            url={url}
                            color={color}
                            attributes={attributes}
                            onLoad={onEmbedLoad}
                        >
                            {children}
                        </SpotifyEmbed>
                    );
                case FigureContentTypes.vimeo:
                    return (
                        <VimeoEmbed
                            id={figureId}
                            url={url}
                            color={color}
                            readOnly={readOnly}
                            user={user}
                            currentSessionId={currentSessionId}
                            postId={postId}
                            editorType={type}
                            attributes={attributes}
                            onLoad={onEmbedLoad}
                        >
                            {children}
                        </VimeoEmbed>
                    );
                default:
                    // Note: We leave out parentRef because Figure in Messaging
                    // Editor can't be fullscreen
                    return (
                        <Figure
                            url={url}
                            user={user}
                            readOnly={readOnly}
                            filePath={filePath}
                            editorType={type}
                            attributes={attributes}
                            layoutType={element.orientation}
                            isGalleryChild={element.isGalleryChild}
                            rerender={rerenderMeasuringElements}
                            setRerender={setRerenderMeasuringElements}
                            createGallery={(imgId, imgURL, path) => FigureHelpers.bundleFigureWithNewFigureIntoGallery(editor, imgId, imgURL, path)}
                            uploadingMedia={uploadingMedia}
                            setUploadingMedia={addUploadingMedia}
                            updateUploadingMedia={updateUploadingMedia}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                        >
                            {children}
                        </Figure>
                    );
                }
            }
        }

        if (AudioNoteElement.isAudioNoteElement(element)) {
            // containerRef.current.childNodes[0] makes parentRef the Slate Context
            return (
                <AudioNote
                    id={element.id}
                    user={user}
                    small={!stationaryToolbarIsActive}
                    hasSound={hasSound}
                    editorType={type}
                    buttonWidth={EDITOR_BUTTON_LENGTH}
                    filePath={element.filePath}
                    title={element.title}
                    description={element.description}
                    color={color}
                    attributes={attributes}
                    readOnly={readOnly}
                    currentSessionId={currentSessionId}
                    postId={postId}
                    parentRef={containerRef.current?.children[0] as HTMLElement}
                    autoRecord={autoRecord}
                    handleAutoRecord={setAutoRecord}
                    autoRecordIdentifier={AUDIO_NOTE_AUTO_RECORD_IDENTIFIER}
                    isRecordingAudioNote={isRecordingAudioNote}
                    setIsRecordingAudioNote={setIsRecordingAudioNote}
                    revealOptionsAbove={false}
                    saveOutstandingAudioNotes={saveOutstandingAudioNotes}
                    notifyIfUnsavedAudioNote={notifyIfUnsavedAudioNote}
                    incrementUnsavedAudioNotes={() => setNumberUnsavedAudioNotes((num) => num + 1)}
                    decrementUnsavedAudioNotes={() => setNumberUnsavedAudioNotes((num) => num - 1)}
                    broadcastHeightAdjusted={onAudioNoteAdjustHeight}
                    uploadingMedia={uploadingMedia}
                    setUploadingMedia={addUploadingMedia}
                    updateUploadingMedia={updateUploadingMedia}
                    removeUploadingMedia={removeUploadingMedia}
                    updateAudioNote={({
                        id: audioNoteId,
                        filePath,
                        title,
                        uploaded,
                        description,
                    }) => AudioNoteHelpers.updateAudioNote(editor, {
                        id: audioNoteId,
                        filePath,
                        title,
                        uploaded,
                        description,
                    })}
                    {...(readOnly
                        ? {
                            isAuthor,
                        } : {}
                    )}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    setSnackbarData={setSnackbarData}
                >
                    {children}
                </AudioNote>
            );
        }

        if (HeaderElement.isH1(element)) {
            return (
                <HeaderOne
                    isFirstElement={element.isFirstElement}
                    attributes={attributes}
                >
                    {children}
                </HeaderOne>
            );
        }

        if (HeaderElement.isH2(element)) {
            return (
                <HeaderTwo
                    isFirstElement={element.isFirstElement}
                    attributes={attributes}
                >
                    {children}
                </HeaderTwo>
            );
        }

        if (
            FigureContentElement.isFigureContentElement(element)
            && element.figureContentType === FigureContentTypes.image
        ) {
            return (
                <Image
                    id={element.id}
                    color={color}
                    user={user}
                    readOnly={readOnly}
                    editorType={type}
                    imagePath={element.filePath}
                    attributes={attributes}
                    layoutType={element.orientation}
                    isGalleryChild={element.isGalleryChild}
                    onLoad={onImageLoad}
                    setImagePath={(filePath) => FigureHelpers.setFigureUrlFilePath(editor, undefined, filePath)}
                    setLayout={(orientation) => FigureHelpers.setFigureOrientation(editor, orientation)}
                    removeFigure={() => FigureHelpers.removeFigure(editor)}
                    swapFigureOrder={() => FigureHelpers.shuffleGalleryOrder(editor)}
                    uploadingMedia={uploadingMedia}
                    setUploadingMedia={addUploadingMedia}
                    updateUploadingMedia={updateUploadingMedia}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setSnackbarData={setSnackbarData}
                >
                    {children}
                </Image>
            );
        }

        if (WebLinkElement.isWebLinkElement(element)) {
            return (
                <InlineLink
                    id={element.id}
                    color={color}
                    href={element.href}
                    readOnly={readOnly}
                    user={user}
                    currentSessionId={currentSessionId}
                    postId={postId}
                    editorType={type}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    parentRef={containerRef.current}
                    attributes={attributes}
                >
                    {children}
                </InlineLink>
            );
        }

        if (ListElement.isListElement(element)) {
            const listType = ListElement.isOL(element)
                ? ListElement.LIST_TYPE.ordered : ListElement.LIST_TYPE.unordered;
            return (
                <List
                    type={listType}
                    attributes={attributes}
                >
                    {children}
                </List>
            );
        }

        if (ListItemElement.isListItemElement(element)) {
            return (
                <ListItem
                    attributes={attributes}
                >
                    {children}
                </ListItem>
            );
        }

        return (
            <Paragraph
                attributes={attributes}
                fontFamily={FONT_TYPE.PLUS_JAKARTA_SANS}
            >
                {children}
            </Paragraph>
        );
    }, [
        postId,
        color,
        user,
        hasSound,
        autoRecord,
        type,
        readOnly,
        currentSessionId,
        uploadingMedia,
        setUploadingMedia,
        updateUploadingMedia,
        containerRef.current,
        isRecordingAudioNote,
        rerenderMeasuringElements,
        saveOutstandingAudioNotes,
        notifyIfUnsavedAudioNote,
        stationaryToolbarIsActive,
        onAudioNoteAdjustHeight,
        onCursorEnter,
        onCursorLeave,
        setInputFocused,
        setSnackbarData,
        onImageLoad,
        onEmbedLoad,
    ]);

    const renderLeaf = useCallback(({
        attributes,
        children,
        leaf,
    }: {
        attributes: any,
        children: any,
        leaf: any,
    }) => (
        <SlateLeaf
            {...attributes}
            {...(leaf.dictationHighlight && {
                'data-dictation': 'annotation-dictation',
            })}
            bold={leaf[EMPHASIZER_MARK.bold]}
            italics={leaf[EMPHASIZER_MARK.italicize]}
            underline={leaf[EMPHASIZER_MARK.underline]}
            strikethrough={leaf[EMPHASIZER_MARK.strikethrough]}
            highlightColor={leaf.dictationHighlight
                ? leaf.highlightColor
                : null}
        >
            {children}
        </SlateLeaf>
    ), []);

    const renderPlaceholder = useCallback(({
        attributes,
        children,
    }: {
        attributes: any,
        children: any,
    }) => (
        <SlatePlaceholder
            fontMultiplier={SLATE_PLACEHOLDER_FONT_MULTIPLIER}
            {...attributes}
        >
            {children}
        </SlatePlaceholder>
    ), []);

    const annotationIsEmpty = useMemo(() => {
        const figureNodes = Node.nodes(editor);
        const linkNodes = Node.nodes(editor);
        const audioNoteNodes = Node.nodes(editor);
        const annotationFigures = Array.from(figureNodes)
            .filter(([node]) => FigureElement.isFigureElement(node))
            .map(([node]) => (node as FigureNode).id);
        const annotationLinks = Array.from(linkNodes)
            .filter(([node]) => WebLinkElement.isWebLinkElement(node))
            .map(([node]) => (node as WebLinkNode).id);
        const annotationAudioNotes = Array.from(audioNoteNodes)
            .filter(([node]) => AudioNoteElement.isAudioNoteElement(node))
            .map(([node]) => (node as AudioNoteNode).id);
        const noText = Array.from(Node.nodes(editor))
            .reduce((empty, [node]) => empty && Node.string(node).length === 0, true);
        return noText
            && annotationFigures.length === 0
            && annotationLinks.length === 0
            && annotationAudioNotes.length === 0;
    }, [editor.children]);

    return (
        <Container
            ref={containerRef}
            {...(!readOnly
                && viewportDimensions
                && typeof xPos === 'number'
                && typeof yPos === 'number'
                ? {
                    bottom: viewportDimensions.height - yPos - DEFAULT_ANNOTATION_EDITOR_HEIGHT,
                    left: xPos,
                } : {}
            )}
            width={width}
            readOnly={readOnly}
            boxShadow={!!boxShadow}
            hide={!showAnnotationEditor}
            transitionDuration={EDITOR_TRANSITION_DURATION}
            buttonContainerHeight={EDITOR_BUTTON_CONTAINER_HEIGHT}
            withPadding={!annotationIsEmpty && !stationaryToolbarIsActive && !readOnly}
        >
            {renderModal()}
            <SlateContainer
                {...(!readOnly
                    ? {
                        ...getRootProps(),
                    }
                    : {}
                )}
                readOnly={readOnly}
                isDragAccept={isDragAccept}
                isDragReject={isDragReject}
                isDragActive={isDragActive}
                buttonCount={NUM_EDITOR_BUTTONS}
                buttonLength={EDITOR_BUTTON_LENGTH}
                canSubmit={canSubmit}
                annotationIsEmpty={annotationIsEmpty}
                buttonContainerHeight={EDITOR_BUTTON_CONTAINER_HEIGHT}
                buttonContainerPadding={EDITOR_BUTTON_CONTAINER_PADDING}
                stationaryToolbarIsActive={stationaryToolbarIsActive}
                dragContainerMargin={SLATE_CONTAINER_DRAG_ACTIVE_MARGIN_RIGHT}
                fontMultiplier={fontMultiplier}
            >
                <Slate
                    editor={editor}
                    value={value}
                    onChange={(val) => setValue(val)}
                >
                    <Editable
                        {...(readOnly
                            ? {
                                readOnly: true,
                            } : {}
                        )}
                        id={id}
                        data-id={id}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        renderPlaceholder={renderPlaceholder}
                        placeholder={placeholder}
                        className={`${HOVER_TARGET_CLASSNAME} ${SLATE_EDITOR_CLASSNAME}`}
                        {...(!readOnly
                            ? { spellCheck: true }
                            : {}
                        )}
                        decorate={decorate}
                        onKeyDown={handleOnKeyDown}
                        onKeyUp={checkForEscape}
                        onMouseEnter={onEditableMouseEnter}
                        onMouseLeave={onEditableMouseLeave}
                        onFocus={onEditableFocus}
                        onBlur={onEditableBlur}
                    />
                    <PortableToolbar
                        // PostEditor Only
                        editorType={type}
                        editorId={id}
                        // Common to PostEditor and AnnotationEditor
                        type={EDITOR_TOOLBAR_TYPE.selection}
                        user={user}
                        hasSound={hasSound}
                        readOnly={readOnly}
                        buttonLength={TOOLBAR_BUTTON_LENGTH}
                        setRef={(ref) => { selectionToolbarRef.current = ref; }}
                        color={color}
                        value={value}
                        selectedLinkURL={getSelectedLinkURL}
                        selectedLinkID={getSelectedLinkID}
                        top={selectionToolbarPosition.y}
                        left={selectionToolbarPosition.x}
                        defaultOpenGroups={new Map([
                            [EDITOR_TOOLBAR_TOOL_GROUP.layout, true],
                            [EDITOR_TOOLBAR_TOOL_GROUP.media, true],
                            [EDITOR_TOOLBAR_TOOL_GROUP.text, true],
                            [EDITOR_TOOLBAR_TOOL_GROUP.embeddings, true],
                        ])}
                        selection={editor.selection}
                        setSelection={(selection) => Transforms.select(editor, selection)}
                        updatePosition={updateSelectionToolbarPosition}
                        toggleBold={() => EmphasizerHelpers.toggleBold(editor)}
                        containsBold={EmphasizerHelpers.selectionContainsBold(editor)}
                        toggleItalics={() => EmphasizerHelpers.toggleItalicize(editor)}
                        containsItalics={EmphasizerHelpers.selectionContainsItalicize(editor)}
                        toggleStrikethrough={() => EmphasizerHelpers.toggleStrikethrough(editor)}
                        containsStrikethrough={EmphasizerHelpers.selectionContainsStrikethrough(editor)}
                        toggleUnderline={() => EmphasizerHelpers.toggleUnderline(editor)}
                        containsUnderline={EmphasizerHelpers.selectionContainsUnderline(editor)}
                        toggleHeaderOne={() => HeaderHelpers.toggleH1(editor)}
                        containsHeaderOne={HeaderHelpers.hasSelectedH1(editor)}
                        toggleHeaderTwo={() => HeaderHelpers.toggleH2(editor)}
                        containsHeaderTwo={HeaderHelpers.hasSelectedH2(editor)}
                        toggleNumberedList={() => ListHelpers.toggleOL(editor)}
                        containsNumberedList={ListHelpers.hasSelectedOL(editor)}
                        toggleBulletedList={() => ListHelpers.toggleUL(editor)}
                        containsBulletedList={ListHelpers.hasSelectedUL(editor)}
                        toggleQuote={() => BlockQuoteHelpers.toggleBlockQuote(editor)}
                        containsQuote={BlockQuoteHelpers.hasSelectedBlockQuote(editor)}
                        toggleDivider={() => DividerHelpers.toggleDivider(editor)}
                        containsDivider={DividerHelpers.hasSelectedDivider(editor)}
                        createFigure={(figureId, filePath, caption) => FigureHelpers.insertFigure(editor, figureId, undefined, filePath, caption)}
                        containsFigure={FigureHelpers.hasSelectedImageFigure(editor)}
                        createLink={(linkId, href, selection) => {
                            if (selection) {
                                Transforms.select(editor, selection);
                            }
                            WebLinkHelpers.wrapSelectionInLinkCmd(editor, linkId, href);
                        }}
                        updateLink={(linkId, href) => WebLinkHelpers.setWebLinkHref(editor, linkId, href)}
                        removeLink={() => WebLinkHelpers.removeWebLink(editor)}
                        containsLink={WebLinkHelpers.hasSelectedLink(editor)}
                        selectEndDocument={moveCursorToEndOfDocument}
                        createYouTubeEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                        containsYouTube={FigureHelpers.hasSelectedYouTubeFigure(editor)}
                        createTwitterEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                        containsTwitter={FigureHelpers.hasSelectedTwitterFigure(editor)}
                        createSpotifyEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                        containsSpotify={FigureHelpers.hasSelectedSpotifyFigure(editor)}
                        createVimeoEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                        containsVimeo={FigureHelpers.hasSelectedVimeoFigure(editor)}
                        insertAudioNote={(audioNoteId) => AudioNoteHelpers.insertAudioNote(editor, { id: audioNoteId })}
                        containsAudioNote={AudioNoteHelpers.hasSelectedAudioNote(editor)}
                        uploadingMedia={uploadingMedia}
                        setUploadingMedia={addUploadingMedia}
                        updateUploadingMedia={updateUploadingMedia}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                    />
                    <Transition
                        in={showEmojiSelector && !!width}
                        timeout={{
                            enter: EMOJI_SELECTOR_TRANSITION_DURATION,
                            exit: EMOJI_SELECTOR_TRANSITION_DURATION,
                        }}
                        mountOnEnter
                    >
                        {
                            (state) => (
                                <EmojiSelectorContainer
                                    height={EMOJI_SELECTOR_HEIGHT}
                                    width={width!}
                                    stationaryToolbarHeight={stationaryToolbarHeight}
                                    stationaryEditorMinHeight={STATIONARY_EDITOR_MIN_HEIGHT}
                                    isActive={showEmojiSelector}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 20,
                                            duration: EMOJI_SELECTOR_TRANSITION_DURATION,
                                            easing: themeObj.motion.eagerEasing,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 20,
                                        })[state],
                                    }}
                                >
                                    <EmojiSelector
                                        compact
                                        hasSound={hasSound}
                                        color={color}
                                        width={width!}
                                        responsiveLayout={false}
                                        handleEmojiSelect={(emojiObj) => {
                                            ReactEditor.focus(editor);
                                            if (emojiObj) Transforms.insertText(editor, emojiObj.char);
                                        }}
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        setInputFocused={setInputFocused}
                                    />
                                </EmojiSelectorContainer>
                            )
                        }
                    </Transition>
                </Slate>
            </SlateContainer>
            {!readOnly && (
                <>
                    <DropzoneInput {...getInputProps()} />
                    <StationaryEditorContainer
                        isActive={stationaryToolbarIsActive}
                        buttonLength={EDITOR_BUTTON_LENGTH}
                        buttonMargin={EDITOR_BUTTON_MARGIN}
                        canSubmit={canSubmit}
                        buttonContainerPadding={EDITOR_BUTTON_CONTAINER_PADDING}
                        buttonCount={NUM_EDITOR_BUTTONS}
                        transitionDuration={STATIONARY_TOOL_BAR_TRANSITION_DURATION}
                        stationaryEditorMinHeight={STATIONARY_EDITOR_MIN_HEIGHT}
                    >
                        <StationaryToolbar
                            // Annotation Editor Only
                            show={showAnnotationEditor && showStationaryToolbar}
                            // Common to PostEditor and AnnotationEditor
                            user={user}
                            hasSound={hasSound}
                            color={color}
                            buttonLength={TOOLBAR_BUTTON_LENGTH}
                            defaultOpenGroups={new Map([
                                [EDITOR_TOOLBAR_TOOL_GROUP.text, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.layout, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.media, true],
                                [EDITOR_TOOLBAR_TOOL_GROUP.embeddings, true],
                            ])}
                            selection={editor.selection}
                            setSelection={(selection) => Transforms.select(editor, selection)}
                            toggleBold={() => EmphasizerHelpers.toggleBold(editor)}
                            containsBold={EmphasizerHelpers.selectionContainsBold(editor)}
                            toggleItalics={() => EmphasizerHelpers.toggleItalicize(editor)}
                            containsItalics={EmphasizerHelpers.selectionContainsItalicize(editor)}
                            toggleStrikethrough={() => EmphasizerHelpers.toggleStrikethrough(editor)}
                            containsStrikethrough={EmphasizerHelpers.selectionContainsStrikethrough(editor)}
                            toggleUnderline={() => EmphasizerHelpers.toggleUnderline(editor)}
                            containsUnderline={EmphasizerHelpers.selectionContainsUnderline(editor)}
                            toggleHeaderOne={() => HeaderHelpers.toggleH1(editor)}
                            containsHeaderOne={HeaderHelpers.hasSelectedH1(editor)}
                            toggleHeaderTwo={() => HeaderHelpers.toggleH2(editor)}
                            containsHeaderTwo={HeaderHelpers.hasSelectedH2(editor)}
                            toggleNumberedList={() => ListHelpers.toggleOL(editor)}
                            containsNumberedList={ListHelpers.hasSelectedOL(editor)}
                            toggleBulletedList={() => ListHelpers.toggleUL(editor)}
                            containsBulletedList={ListHelpers.hasSelectedUL(editor)}
                            toggleQuote={() => BlockQuoteHelpers.toggleBlockQuote(editor)}
                            containsQuote={BlockQuoteHelpers.hasSelectedBlockQuote(editor)}
                            toggleDivider={() => DividerHelpers.toggleDivider(editor)}
                            containsDivider={DividerHelpers.hasSelectedDivider(editor)}
                            createFigure={(figureId, filePath, caption) => FigureHelpers.insertFigure(editor, figureId, undefined, filePath, caption)}
                            containsFigure={FigureHelpers.hasSelectedImageFigure(editor)}
                            createLink={(linkId, href, selection) => {
                                if (selection) {
                                    Transforms.select(editor, selection);
                                }
                                WebLinkHelpers.wrapSelectionInLinkCmd(editor, linkId, href);
                                WebLinkHelpers.wrapSelectionInLinkCmd(editor, linkId, href);
                            }}
                            // 1.We leave out containsLink because Block Toolbar should not show it as applied
                            // 2. We leave out updateLink, removeLink because Stationary Toolbar cannot present
                            // link card with these actions.
                            selectEndDocument={moveCursorToEndOfDocument}
                            createYouTubeEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsYouTube={FigureHelpers.hasSelectedYouTubeFigure(editor)}
                            createTwitterEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsTwitter={FigureHelpers.hasSelectedTwitterFigure(editor)}
                            createSpotifyEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsSpotify={FigureHelpers.hasSelectedSpotifyFigure(editor)}
                            createVimeoEmbedding={(embeddingId, src) => FigureHelpers.insertFigure(editor, embeddingId, src)}
                            containsVimeo={FigureHelpers.hasSelectedVimeoFigure(editor)}
                            insertAudioNote={(audioNoteId) => AudioNoteHelpers.insertAudioNote(editor, { id: audioNoteId })}
                            containsAudioNote={AudioNoteHelpers.hasSelectedAudioNote(editor)}
                            uploadingMedia={uploadingMedia}
                            setUploadingMedia={addUploadingMedia}
                            updateUploadingMedia={updateUploadingMedia}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            setInputFocused={setInputFocused}
                        />
                    </StationaryEditorContainer>
                    <ButtonContainer
                        isEditing={!!isEditing}
                        height={EDITOR_BUTTON_CONTAINER_HEIGHT}
                        padding={EDITOR_BUTTON_CONTAINER_PADDING}
                    >
                        <EditorButton
                            className={HOVER_TARGET_CLASSNAME}
                            length={EDITOR_BUTTON_LENGTH}
                            padding={EDITOR_BUTTON_PADDING}
                            margin={EDITOR_BUTTON_MARGIN}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onMouseDown={handleRecordAudio}
                        >
                            <Tooltip
                                text="Record"
                                side={TOOLTIP_TYPE.bottom}
                            />
                            <EditorButtonIcon
                                isActive
                                color={color}
                                length={EDITOR_BUTTON_LENGTH - 2 * (EDITOR_BUTTON_PADDING)}
                            >
                                <ReactSVG
                                    src={MicrophoneIcon}
                                />
                            </EditorButtonIcon>
                        </EditorButton>
                        <EditorButton
                            className={HOVER_TARGET_CLASSNAME}
                            color={color}
                            length={EDITOR_BUTTON_LENGTH}
                            padding={EDITOR_BUTTON_PADDING}
                            margin={EDITOR_BUTTON_MARGIN}
                            isActive={stationaryToolbarIsActive}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onMouseDown={handleStationaryToolbarButton}
                        >
                            <Tooltip
                                text="Toolkit"
                                side={TOOLTIP_TYPE.bottom}
                            />
                            <EditorButtonIcon
                                color={color}
                                isActive={stationaryToolbarIsActive}
                                length={EDITOR_BUTTON_LENGTH - 2 * (EDITOR_BUTTON_PADDING)}
                            >
                                <ReactSVG
                                    src={ToolsIcon}
                                />
                            </EditorButtonIcon>
                        </EditorButton>
                        <EditorButton
                            className={HOVER_TARGET_CLASSNAME}
                            color={color}
                            length={EDITOR_BUTTON_LENGTH}
                            padding={EDITOR_BUTTON_PADDING}
                            margin={EDITOR_BUTTON_MARGIN}
                            isActive={showEmojiSelector}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onMouseDown={handleEmojiButton}
                        >
                            <Tooltip
                                text="Emojis"
                                side={TOOLTIP_TYPE.bottom}
                            />
                            <EditorButtonIcon
                                color={color}
                                isActive={showEmojiSelector}
                                length={EDITOR_BUTTON_LENGTH - 2 * (EDITOR_BUTTON_PADDING)}
                            >
                                <ReactSVG
                                    src={SmileyIcon}
                                />
                            </EditorButtonIcon>
                        </EditorButton>
                        <Transition
                            in={isEditing}
                            timeout={{
                                enter: FADE_IN_TRANSITION_DURATION,
                                exit: FADE_IN_TRANSITION_DURATION,
                            }}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <SendButton
                                    className={HOVER_TARGET_CLASSNAME}
                                    color={themeObj.verascopeColor.red300}
                                    length={EDITOR_BUTTON_LENGTH}
                                    canSubmit
                                    stationaryToolbarIsActive={stationaryToolbarIsActive}
                                    isEditing={isEditing}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    onMouseDown={cancelEditAnnotation!}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'right',
                                            offset: 10,
                                            easing: themeObj.motion.overshoot,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'right',
                                            offset: 10,
                                        })[state],
                                    }}
                                >
                                    <ReactSVG
                                        src={CrossIcon}
                                    />
                                </SendButton>
                            )}
                        </Transition>
                        <Transition
                            in={canSubmit}
                            timeout={{
                                enter: FADE_IN_TRANSITION_DURATION,
                                exit: FADE_IN_TRANSITION_DURATION,
                            }}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <SendButton
                                    className={HOVER_TARGET_CLASSNAME}
                                    color={isEditing ? themeObj.verascopeColor.green300 : color}
                                    length={EDITOR_BUTTON_LENGTH}
                                    canSubmit={canSubmit && uploadingMedia.size === 0}
                                    stationaryToolbarIsActive={stationaryToolbarIsActive}
                                    isEditing={isEditing}
                                    marginLeft={isEditing}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    onMouseDown={determineIfOutstandingAudioNotes}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'right',
                                            offset: 10,
                                            easing: themeObj.motion.overshoot,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'right',
                                            offset: 10,
                                        })[state],
                                    }}
                                >
                                    <ReactSVG
                                        src={isEditing ? CheckmarkIcon : ArrowIcon}
                                    />
                                </SendButton>
                            )}
                        </Transition>
                    </ButtonContainer>
                    <Transition
                        in={isDragActive}
                        timeout={{
                            enter: FADE_IN_TRANSITION_DURATION,
                            exit: FADE_IN_TRANSITION_DURATION,
                        }}
                    >
                        {(transitionState) => (
                            <DropzoneCompactMessageContainer
                                isDragActive={isDragActive}
                                isDragReject={isDragReject}
                                stationaryToolbarIsActive={stationaryToolbarIsActive}
                                style={{
                                    ...FADE_IN_DEFAULT_STYLE({
                                        direction: stationaryToolbarIsActive ? 'left' : 'right',
                                        offset: 10,
                                    }),
                                    ...FADE_IN_TRANSITION_STYLES({
                                        direction: stationaryToolbarIsActive ? 'left' : 'right',
                                        offset: 10,
                                    })[transitionState],
                                }}
                            >
                                <DropzoneCompactMessageIcon
                                    length={EDITOR_BUTTON_LENGTH - 2 * (EDITOR_BUTTON_PADDING)}
                                >
                                    <ReactSVG
                                        src={isDragReject
                                            ? CrossIcon
                                            : UploadIcon}
                                    />
                                </DropzoneCompactMessageIcon>
                                <DropzoneCompactMessageText>
                                    {isDragReject
                                        ? 'Invalid'
                                        : 'Valid'}
                                </DropzoneCompactMessageText>
                            </DropzoneCompactMessageContainer>
                        )}
                    </Transition>
                    {width
                    && (
                        <UploadProgressContainer
                            showEmojiSelector={showEmojiSelector}
                            emojiSelectorWidth={width}
                            stationaryToolbarHeight={stationaryToolbarHeight}
                            stationaryToolbarIsActive={stationaryToolbarIsActive}
                        >
                            {Array.from(uploadingMedia.values()).map((item, index) => (
                                <Transition
                                    key={item.id}
                                    in={uploadingMedia.size > 0}
                                    timeout={{
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION
                                            + UPLOAD_TRANSITION_DELAY,
                                        ),
                                        exit: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            ((uploadingMedia.size - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ),
                                    }}
                                    appear
                                    mountOnEnter
                                >
                                    {(transitionState) => (
                                        <UploadingMediaItem
                                            compact
                                            progress={item.uploadProgress}
                                            mediaItem={item}
                                            onItemCompletion={removeUploadingMedia}
                                            color={color}
                                            style={{
                                                ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                    direction: 'up',
                                                    offset: 10,
                                                }),
                                                ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                    direction: 'up',
                                                    offset: 10,
                                                    numItems: uploadingMedia.size,
                                                    index,
                                                    enterDelay: UPLOAD_TRANSITION_DELAY,
                                                })[transitionState],
                                            }}
                                        />
                                    )}
                                </Transition>
                            ))}
                        </UploadProgressContainer>
                    )}
                </>
            )}
            <Transition
                in={!readOnly && showAnnotationEditor && !isEditing}
                timeout={{
                    enter: EDITOR_TRANSITION_DURATION,
                    exit: 0,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CopyTextContainer
                        buttonLength={COPY_TEXT_BUTTON_CONTAINER_HEIGHT}
                        editorHeight={EDITOR_BUTTON_CONTAINER_HEIGHT}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'right',
                                offset: 10,
                                easing: themeObj.motion.overshoot,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'right',
                                offset: 10,
                            })[state],
                        }}
                    >
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.solid}
                            background={themeObj.color.neutral800}
                            height={COPY_TEXT_BUTTON_CONTAINER_HEIGHT}
                            width={COPY_TEXT_BUTTON_CONTAINER_HEIGHT}
                            icon={ClipboardIcon}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onMouseDown={copyHighlightText}
                        />
                    </CopyTextContainer>
                )}
            </Transition>
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    bottom?: number,
    left?: number,
    width?: number,
    hide: boolean,
    readOnly: boolean,
    boxShadow: boolean,
    withPadding: boolean,
    transitionDuration: number,
    buttonContainerHeight: number,
}
const Container = styled.div<ContainerProps>`
    position: ${({ bottom, left }) => (typeof bottom === 'number' && typeof left === 'number'
        ? 'absolute'
        : 'relative'
    )};
    bottom: ${({ bottom }) => (typeof bottom === 'number' ? `${bottom}px` : 'auto')};
    left: ${({ left }) => (typeof left === 'number' ? `${left}px` : 'auto')};
    display: flex;
    flex-direction: column;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    max-width: 100%;
    max-height: 300px;
    padding-bottom: ${({ withPadding }) => `${withPadding ? 40 : 0}px`};
    background: ${({ theme }) => theme.color.white};
    border-radius: ${({ readOnly, buttonContainerHeight }) => (!readOnly
        ? `${buttonContainerHeight / 2}px`
        : '5px'
    )};
    box-shadow: ${({ boxShadow, theme }) => (boxShadow
        ? theme.color.boxShadow100
        : 'none'
    )};
    z-index: ${ANNOTATION_EDITOR_Z_INDEX};
    ${({ hide }) => hide && `
        opacity: 0;
        pointer-events: none;
    `};
    transition: ${({ transitionDuration, theme }) => `
        transform ${transitionDuration}ms ${theme.motion.overshoot},
        opacity ${transitionDuration}ms ${theme.motion.overshoot}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-bottom: 30px;
    }
`;

interface SlateContainerProps {
    canSubmit: boolean,
    readOnly: boolean,
    annotationIsEmpty: boolean,
    buttonCount: number,
    buttonLength: number,
    buttonMargin: number,
    buttonContainerPadding: number,
    dragContainerMargin: number,
    buttonContainerHeight: number,
    stationaryToolbarIsActive: boolean,
    isDragActive: boolean,
    isDragAccept: boolean,
    isDragReject: boolean,
    fontMultiplier: number,
}
const SlateContainer = styled.div<SlateContainerProps>`
    width: ${({
        isDragActive,
        stationaryToolbarIsActive,
        dragContainerMargin,
        buttonContainerPadding,
        buttonLength,
        buttonCount,
        canSubmit,
        buttonMargin,
        annotationIsEmpty,
    }) => {
        if (stationaryToolbarIsActive || !annotationIsEmpty) {
            return '100%';
        }

        if (isDragActive && !stationaryToolbarIsActive) {
            return `calc(100% - ${
                buttonLength * (canSubmit ? buttonCount : buttonCount - 1)
            }px - ${buttonMargin * (canSubmit ? buttonCount - 1 : buttonCount - 2)}px - ${dragContainerMargin}px - ${2 * buttonContainerPadding}px)`;
        }

        return `calc(100% - ${
            buttonLength * (canSubmit ? buttonCount : buttonCount - 1)
        }px - ${buttonContainerPadding * (canSubmit ? buttonCount - 1 : buttonCount - 2)}px)`;
    }};
    height: ${({ annotationIsEmpty, buttonContainerHeight }) => (annotationIsEmpty
        ? `${buttonContainerHeight}px`
        : 'auto'
    )};
    padding: 5px 10px;
    padding-left: ${({ readOnly }) => `${readOnly ? 10 : 20}px`};
    padding-bottom: ${({ readOnly }) => `${readOnly ? 0 : 5}px`};
    max-height: ${({ buttonContainerHeight }) => `calc(100% - ${buttonContainerHeight}px)`};
    font-size: ${({ fontMultiplier }) => `${fontMultiplier}em`};
    overflow-y: scroll;
    background-color: ${({
        isDragAccept,
        isDragReject,
        theme,
    }) => {
        if (isDragReject) {
            return setColorLightness(
                theme.color.error,
                95,
            );
        }

        if (isDragAccept) {
            return setColorLightness(
                theme.verascopeColor.blue200,
                95,
            );
        }

        return theme.color.white;
    }};
    ${({ isDragActive, stationaryToolbarIsActive, dragContainerMargin }) => isDragActive
        && !stationaryToolbarIsActive && `
        margin-right: ${dragContainerMargin}px;
    `};
    border-radius: ${({ buttonContainerHeight }) => `${buttonContainerHeight / 2}px`};
    ${({ stationaryToolbarIsActive }) => stationaryToolbarIsActive && `
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    `};
    transition: ${({ theme }) => `
        background-color 0.3s ${theme.motion.eagerEasing},
        margin-right 0.3s ${theme.motion.eagerEasing}
    `};

    &::-webkit-scrollbar {
        display: none;
    }
`;

interface ButtonContainerProps {
    height: number,
    padding: number,
    isEditing: boolean,
}
const ButtonContainer = styled.div<ButtonContainerProps>`
    position: absolute;
    bottom: 0;
    right: ${({ isEditing }) => (isEditing ? 'auto' : '0')};
    left: ${({ isEditing }) => (isEditing ? '0' : 'auto')};
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: ${({ height }) => `${height}px`};
    border-radius: ${({ height }) => `${height / 2}px`};
    padding: ${({ padding }) => `${padding}px`};
    background: inherit;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        right: 20px;
        padding-bottom: 30px;
    }
`;

interface EditorButtonProps {
    isActive?: boolean,
    color?: string,
    length: number,
    padding: number,
    margin: number,
}
const EditorButton = styled.div<EditorButtonProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ length }) => `${length}px`};
    width: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background-color: ${({ isActive, color }) => (isActive && color
        ? setColorLightness(color, BUTTON_CONTAINER_LIGHTNESS_VALUE_HOVER)
        : 'transparent'
    )};
    padding: ${({ padding }) => `${padding}px`};
    margin-right: ${({ margin }) => `${margin}px`};
    transition: background-color 0.3s;
    cursor: none;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: ${({
        isActive,
        color,
        theme,
    }) => {
        if (isActive && color) {
            return setColorLightness(color, BUTTON_CONTAINER_LIGHTNESS_VALUE_HOVER);
        }

        if (isActive) {
            return theme.color.neutral400;
        }

        return theme.color.neutral300;
    }};
    }
`;

interface StationaryEditorContainerProps {
    isActive: boolean,
    stationaryEditorMinHeight: number
    transitionDuration: number,
    buttonLength: number,
    canSubmit: boolean,
    buttonContainerPadding: number,
    buttonCount: number,
    buttonMargin: number,
}
const StationaryEditorContainer = styled.div<StationaryEditorContainerProps>`
    position: relative;
    left: ${({ isActive }) => `${isActive
        ? 0
        : 30
    }px`};
    background-color: ${({ theme }) => theme.color.neutral100};
    flex-shrink: 0;
    width: ${({ isActive, buttonLength }) => (isActive
        ? '100%'
        : `calc(100% - ${buttonLength}px)`
    )};
    height: ${({ isActive }) => (isActive
        ? 'auto'
        : '0px'
    )};
    min-height: ${({ isActive, stationaryEditorMinHeight }) => `${isActive
        ? stationaryEditorMinHeight
        : 0
    }px`};
    padding-left: ${({ buttonMargin }) => `${buttonMargin / 2}px`};
    padding-bottom: ${({ isActive, buttonMargin }) => (isActive ? `${buttonMargin / 2}px` : '0px')};
    padding-right: ${({
        isActive,
        buttonLength,
        canSubmit,
        buttonContainerPadding,
        buttonCount,
        buttonMargin,
    }) => `${isActive
        ? `calc(${
            buttonLength * (canSubmit ? buttonCount : buttonCount - 1)
        }px + ${buttonMargin * (canSubmit ? buttonCount - 1 : buttonCount - 2)}px + ${
            2 * buttonContainerPadding
        }px)`
        : '0px'
    }`};
    border-bottom-left-radius: ${({ buttonLength }) => `${buttonLength}px`};
    border-bottom-right-radius: ${({ buttonLength }) => `${buttonLength}px`};
    background: inherit;
    transition: ${({ transitionDuration, theme }) => `
        min-height ${transitionDuration}ms ${theme.motion.delayEasing},
        width ${transitionDuration}ms ${theme.motion.delayEasing},
        left ${transitionDuration}ms ${theme.motion.delayEasing}
    `};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-left: 20px;
        padding-right: ${({
        isActive,
        buttonLength,
        canSubmit,
        buttonContainerPadding,
        buttonCount,
        buttonMargin,
    }) => `${isActive
        ? `calc(${
            buttonLength * (canSubmit ? buttonCount : buttonCount - 1)
        }px + ${buttonMargin * (canSubmit ? buttonCount - 1 : buttonCount - 2)}px + ${
            2 * buttonContainerPadding
        }px + 20px)`
        : '20px'
    }`};
    }
`;

interface EditorButtonIconProps {
    isActive: boolean,
    length: number,
}
const EditorButtonIcon = styled.div<EditorButtonIconProps>`
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({
        color,
        isActive,
        theme,
    }) => (isActive
        ? color
        : theme.color.neutral600
    )};
    }

    & path {
        transition: fill 0.3s;
    }
`;

interface SendButtonProps {
    stationaryToolbarIsActive: boolean,
    canSubmit: boolean,
    length: number,
    isEditing: boolean | undefined,
    marginLeft?: boolean,
}
const SendButton = styled.div<SendButtonProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ length }) => `${length}px`};
    width: ${({ length }) => `${length}px`};
    border-radius: ${({ length }) => `${length / 2}px`};
    background-color: ${({
        stationaryToolbarIsActive,
        canSubmit,
        theme,
        color,
    }) => {
        if (canSubmit && color) {
            return color;
        }

        if (canSubmit) {
            return theme.verascopeColor.orange200;
        }

        if (stationaryToolbarIsActive) {
            return theme.color.neutral200;
        }

        return theme.color.neutral100;
    }};
    box-shadow: ${({ canSubmit, theme }) => (canSubmit
        ? theme.color.boxShadow300
        : 'none'
    )};
    margin-left: ${({ marginLeft }) => `${marginLeft ? 5 : 0}px`};
    padding: 5px;
    transition: background-color 0.3s, box-shadow 0.3s;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        fill: ${({ theme, canSubmit, stationaryToolbarIsActive }) => {
        if (canSubmit) {
            return theme.color.white;
        }

        if (stationaryToolbarIsActive) {
            return theme.color.neutral300;
        }

        return theme.color.neutral200;
    }};
        transform: ${({ isEditing }) => (isEditing ? 'none' : 'rotate(-90deg)')};
    }

    &:hover {
        box-shadow: ${({ canSubmit, theme }) => (canSubmit
        ? theme.color.boxShadow300
        : 'none'
    )};
    }
`;

interface EmojiSelectorContainerProps {
    height: number,
    width: number,
    isActive: boolean,
    stationaryToolbarHeight: number,
    stationaryEditorMinHeight: number,
}
const EmojiSelectorContainer = styled.div<EmojiSelectorContainerProps>`
    position: absolute;
    bottom: ${({ stationaryToolbarHeight, stationaryEditorMinHeight }) => `${stationaryToolbarHeight > stationaryEditorMinHeight
        ? stationaryToolbarHeight + 5
        : stationaryEditorMinHeight + 5
    }px`};
    right: 0px;
    display: flex;
    flex-direction: row;
    width: ${({ width }) => `${width}px`};
    max-width: 240px;
    height: ${({ height }) => `${height}px`};
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    background-color: ${({ theme }) => theme.color.white};
    overflow: hidden;
    transition: ${({ theme }) => `
        bottom: 300ms ${theme.motion.eagerEasing}
    `};

    ${({ isActive }) => !isActive && `
        pointer-events: none;
    `};
`;

interface DropzoneCompactMessageContainerProps {
    stationaryToolbarIsActive: boolean,
    isDragActive: boolean,
    isDragReject: boolean,
}
const DropzoneCompactMessageContainer = styled.div<DropzoneCompactMessageContainerProps>`
    position: absolute;
    top: 10px;
    left: ${({ stationaryToolbarIsActive }) => (stationaryToolbarIsActive
        ? 'auto'
        : '10px'
    )};
    right: ${({ stationaryToolbarIsActive }) => (stationaryToolbarIsActive
        ? '10px'
        : 'auto'
    )};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    padding-left: 10px;
    border-radius: 20px;
    background-color: ${({
        theme,
        isDragReject,
    }) => {
        if (isDragReject) {
            return theme.color.error;
        }

        return theme.verascopeColor.blue200;
    }};
    pointer-events: ${({ isDragActive }) => (isDragActive
        ? 'auto'
        : 'none'
    )};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
`;

interface DropzoneCompactMessageIconProps {
    length: number,
}
export const DropzoneCompactMessageIcon = styled.div<DropzoneCompactMessageIconProps>`
    width: ${({ length }) => `${length}px`};
    height: ${({ length }) => `${length}px`};
    padding: 2.5px;
    margin-right: 10px;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.white};
    }
`;

export const DropzoneCompactMessageText = styled.div`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.color.white};
`;

interface UploadProgressContainerProps {
    showEmojiSelector: boolean,
    emojiSelectorWidth: number,
    stationaryToolbarHeight: number,
    stationaryToolbarIsActive: boolean,
}
const UploadProgressContainer = styled.div<UploadProgressContainerProps>`
    position: absolute;
    top: ${({ stationaryToolbarIsActive }) => {
        if (!stationaryToolbarIsActive) {
            return '-10px';
        }

        return 'auto';
    }};
    bottom: ${({ stationaryToolbarHeight, stationaryToolbarIsActive }) => {
        if (!stationaryToolbarIsActive) {
            return 'auto';
        }

        return `${stationaryToolbarHeight - 30}px`;
    }};
    left: ${({ stationaryToolbarIsActive }) => (stationaryToolbarIsActive
        ? 'auto'
        : '15px'
    )};
    right: ${({ stationaryToolbarIsActive, showEmojiSelector, emojiSelectorWidth }) => {
        if (
            stationaryToolbarIsActive
            && showEmojiSelector
        ) {
            return `${emojiSelectorWidth + 20}px`;
        }

        if (stationaryToolbarIsActive) {
            return '15px';
        }

        return 'auto';
    }};
    transform: translateY(-100%);
    pointer-events: none;
    width: 150px;
    transition: ${({ theme }) => `
        bottom: 300ms ${theme.motion.eagerEasing}
    `};
`;

interface CopyTextContainerProps {
    buttonLength: number,
    editorHeight: number,
}
const CopyTextContainer = styled.div<CopyTextContainerProps>`
    position: absolute;
    bottom: ${({ buttonLength, editorHeight }) => `${(editorHeight - buttonLength) / 2}px`};
    right: ${({ buttonLength }) => `-${buttonLength + 5}px`};
    width: ${({ buttonLength }) => `${buttonLength}px`};
    height: ${({ buttonLength }) => `${buttonLength}px`};
    border-radius: ${({ buttonLength }) => `${buttonLength / 2}px`};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow300};
`;

export default AnnotationEditor;
