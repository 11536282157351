// ===== Enums =====

import { MEDIA_TYPE } from '../enums';

const getMediaStorageBucket = (type?: MEDIA_TYPE): string => {
    if (type) {
        switch (type) {
        case MEDIA_TYPE.audioNote:
            return 'audio';
        case MEDIA_TYPE.sketch:
            return 'sketches';
        default:
            return 'images';
        }
    }

    return 'images';
};

export default getMediaStorageBucket;
