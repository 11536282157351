// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> prepareDailyDigest
export enum INTERACTABLE_OBJECT {
    abacus = 'abacus',
    molecule = 'molecule',
    gameController = 'game-controller',
    magnet = 'magnet',
    lightbulb = 'lightbulb',
    puzzleRed = 'puzzle-red',
    puzzleOrange = 'puzzle-orange',
    puzzleGreen = 'puzzle-green',
    puzzleBlue = 'puzzle-blue',
    cuneiformTablet = 'cuneiform-tablet',
}
