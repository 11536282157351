// ===== Packages =====

import {
    FirebaseError,
}                                   from 'firebase/app';
import {
    User,
    getAuth,
    createUserWithEmailAndPassword,
}                                   from 'firebase/auth';

interface SignUpProps {
    credentials: {
        email: string,
        password: string,
    },
    onComplete: (user: User) => void,
    onError: (error: FirebaseError) => void,
}
const signUpWithEmail = ({
    credentials,
    onComplete,
    onError,
}: SignUpProps): void => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
        .then((userCredential) => {
            // Signed in
            const { user } = userCredential;
            onComplete(user);
        })
        .catch((error) => {
            onError(error);
        });
};

export default signUpWithEmail;
