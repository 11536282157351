// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                               from 'react';
import YouTube, { Options }                     from 'react-youtube';

// ===== Services =====

import {
    getYouTubeParams,
    getEmbeddingDimensions,
    recordUserAction,
}                                               from '../../services';

// ===== Hooks =====

import { useEventListener }                     from '../../hooks';

// ===== Interfaces =====

import {
    ICartridgeItem,
    IUserItem,
}                                               from '../../interfaces';

// ===== Enums =====

import {
    USER_ACTION_TYPE,
}                                               from '../../enums';

// ===== Constants =====

import EmbeddingDimensions                      from '../../constants/embeddingDimensions';
import {
    EMBEDDING_MAX_WIDTH,
}                                               from '../../constants/generalConstants';

// ===== Styles =====

import {
    Container,
}                                               from './styles';

// <YouTube
//   videoId={string}                  // defaults -> null
//   id={string}                       // defaults -> null
//   className={string}                // defaults -> null
//   containerClassName={string}       // defaults -> ''
//   opts={obj}                        // defaults -> {}
//   onReady={func}                    // defaults -> noop
//   onPlay={func}                     // defaults -> noop
//   onPause={func}                    // defaults -> noop
//   onEnd={func}                      // defaults -> noop
//   onError={func}                    // defaults -> noop
//   onStateChange={func}              // defaults -> noop
//   onPlaybackRateChange={func}       // defaults -> noop
//   onPlaybackQualityChange={func}    // defaults -> noop
// />

// Example: https://www.youtube.com/watch?v=nM9f0W2KD5s

interface Props {
    url: string,
    cartridge: ICartridgeItem,
    parentRef: HTMLElement,
    user: IUserItem | null,
    currentSessionId: string | null,
    triggerEmbeddingResize: boolean,
    setTriggerEmbeddingResize: React.Dispatch<React.SetStateAction<boolean>>,
}
function YouTubeEmbed({
    url,
    cartridge,
    parentRef,
    user,
    currentSessionId,
    triggerEmbeddingResize,
    setTriggerEmbeddingResize,
}: Props): JSX.Element {
    // ===== State =====

    const [youtubeId, setYoutubeId] = useState<string | null>('');
    const [youTubeStart, setYouTubeStart] = useState<number | null>(null);
    const [youTubeEnd, setYouTubeEnd] = useState<number | null>(null);
    const [options, setOptions] = useState<Options>({
        width: EmbeddingDimensions[480].width.toString(),
        height: EmbeddingDimensions[480].height.toString(),
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0, // 1 = autoplay 0 = no autoplay
        },
    });

    // ===== Side Effects =====

    // Get ID, Start Time, and End Time from URL
    useEffect(() => {
        const ytParams = getYouTubeParams(url);

        if (ytParams) {
            if (ytParams.id) setYoutubeId(ytParams.id);
            if (ytParams.start) setYouTubeStart(ytParams.start);
            if (ytParams.end) setYouTubeEnd(ytParams.end);
            determineOptions();
        }
    }, [url]);

    // On Mount, determine dimensions
    // On trigger, determine dimensions
    useEffect(() => {
        determineOptions();
        if (triggerEmbeddingResize) {
            determineOptions();
            setTriggerEmbeddingResize(false);
        }
    }, [
        triggerEmbeddingResize,
        youTubeStart,
        youTubeEnd,
    ]);

    // On resize, determine dimensions
    useEventListener(
        'resize',
        () => {
            determineOptions();
        },
    );

    // ===== Methods =====

    const determineOptions = (): void  => {
        if (parentRef) {
            const parentRect = parentRef.getBoundingClientRect();
            const parentPadding = 2 * parseFloat(
                window
                    .getComputedStyle(parentRef, null)
                    .getPropertyValue('padding')
                    .replace('px', ''),
            );

            const currentDimension = getEmbeddingDimensions(parentRect.width - parentPadding);
            let width;
            let height;
            if (parentRect.width - parentPadding > EMBEDDING_MAX_WIDTH) {
                width = EMBEDDING_MAX_WIDTH;
                height = [(9 / 16) * EMBEDDING_MAX_WIDTH].toString();
            } else {
                width = EmbeddingDimensions[currentDimension].width;
                height = EmbeddingDimensions[currentDimension].height.toString();
            }

            const updatedOptions: Options = {
                width: width.toString(),
                height: height.toString(),
                playerVars: {
                    autoplay: 0,
                },
            };
            if (youTubeStart) updatedOptions.playerVars!.start = youTubeStart;
            if (youTubeEnd) updatedOptions.playerVars!.end = youTubeEnd;
            setOptions(updatedOptions);
        }
    };

    const onPlay = async (): Promise<void> => {
        if (
            user
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.playCartridgeYouTube,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    cartridge,
                    videoType: 'youtube',
                    url,
                },
            });
        }
    };

    const onPause = async (): Promise<void> => {
        if (
            user
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.pauseCartridgeYouTube,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    cartridge,
                    videoType: 'youtube',
                    url,
                },
            });
        }
    };

    const onEnd = async (): Promise<void> => {
        if (
            user
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.endCartridgeYouTube,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    cartridge,
                    videoType: 'youtube',
                    url,
                },
            });
        }
    };

    return (
        <Container>
            {youtubeId && youtubeId.length > 0
            && (
                <YouTube
                    videoId={youtubeId}
                    opts={options}
                    onPlay={onPlay}
                    onPause={onPause}
                    onEnd={onEnd}
                />
            )}
        </Container>
    );
}

export default YouTubeEmbed;
