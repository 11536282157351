// ===== Interfaces =====

import { IMediaQuerySize } from '../interfaces';

const MEDIA_QUERY_SIZE: IMediaQuerySize = {
    large: {
        min: 1200,
        max: null,
    },
    medium: {
        min: 992,
        max: 1199,
    },
    small: {
        min: 768,
        max: 991,
    },
    extraSmall: {
        min: 0,
        max: 767,
    },
};

export default MEDIA_QUERY_SIZE;
