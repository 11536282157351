// ===== Packages =====

import React, {
    useEffect,
    useRef,
    useState,
    useMemo,
}                               from 'react';
import { Transition }           from 'react-transition-group';
import { ReactSVG }             from 'react-svg';

// ===== Components =====

import {
    Button,
}                               from '../Editor/helpers';

// ===== Services =====

import {
    playAudio,
    detectTouchDevice,
}                               from '../../services';

// ===== Hooks =====

import {
    useTimeout,
}                               from '../../hooks';

// ===== Interfaces =====

import {
    ISnackbarItem,
}                               from '../../interfaces';

// ===== Enums =====

import {
    BUTTON_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
}                               from '../../enums';

// ===== Images =====

import CrossIcon                from '../../images/cross.svg';

// ===== Sounds =====

import SnackbarEnter            from '../../sounds/swoosh_in.mp3';
import SnackbarExit             from '../../sounds/swoosh_out.mp3';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    HOVER_TARGET_CLASSNAME,
}                               from '../../constants/generalConstants';
import CURSOR_SIGN              from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    SnackbarIcon,
    SnackbarText,
    DismissButtonContainer,
}                               from './styles';
import { theme }                from '../../themes/theme-context';

interface Props {
    data: ISnackbarItem,
    hasSound: boolean,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function Snackbar({
    data,
    hasSound,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== General Constants =====

    const CENTER_TEXT_LENGTH_THRESHOLD = 40;
    const DISMISS_BUTTON_LENGTH = 30;

    // ===== Refs =====

    // ----- Sound Clips
    const enterClip = useRef<HTMLAudioElement>(new Audio());
    const exitClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    const [visible, setVisible] = useState<boolean>(data.visible);
    const [text, setText] = useState<string | null>(null);
    const [icon, setIcon] = useState<string | null>(null);
    const [hasSuccess, setHasSuccess] = useState<boolean>(false);
    const [hasFailure, setHasFailure] = useState<boolean>(false);
    const [resetTimeoutIsActive, setResetTimeoutIsActive] = useState<boolean>(false);
    const [snackbarQueue, setSnackbarQueue] = useState<ISnackbarItem[]>([]);

    // ===== Animation Constants =====

    const SNACKBAR_ENTER_DURATION = 200;
    const SNACKBAR_EXIT_DURATION = SNACKBAR_ENTER_DURATION;
    const SNACKBAR_RESET_TIMEOUT_DURATION = SNACKBAR_EXIT_DURATION + 100;

    // ===== Methods =====

    /**
     * Manages response to button hovering over
     * @param e mouse event
     */
    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.editorButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to button no longer hovering over
     * @param e mouse event
     */
    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const executeSnackbar = (item: ISnackbarItem): void => {
        clearTimeoutResetSnackbar();
        setVisible(true);
        setText(item.text);
        if (item.icon) setIcon(item.icon);
        if (item.hasSuccess) setHasSuccess(true);
        if (item.hasFailure) setHasFailure(true);

        if (item.duration && !item.hasFailure) {
            // failure snackbars are always made to be dismissable
            clearTimeoutDismissTimedSnackbar();
            timeoutDismissTimedSnackbar();
        }

        // Play Sound
        if (
            hasSound
            && enterClip.current
        ) {
            enterClip.current.pause();
            enterClip.current.currentTime = 0;
            playAudio(enterClip.current);
        }
    };

    /**
     * Manages dismissing the snackbar
     */
    const dismissSnackbar = (e?: React.MouseEvent | React.TouchEvent): void => {
        if (e) onCursorLeave(e);
        setVisible(false);
        setResetTimeoutIsActive(true);
        clearTimeoutResetSnackbar();
        timeoutResetSnackbar();

        // Play Sound
        if (
            hasSound
            && exitClip.current
        ) {
            exitClip.current.pause();
            exitClip.current.currentTime = 0;
            playAudio(exitClip.current);
        }
    };

    // ===== Side Effects ======

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            enterClip.current
            && exitClip.current
        ) {
            // Snackbar Enter
            enterClip.current.volume = DEFAULT_AUDIO_VOLUME;
            enterClip.current.src = SnackbarEnter;

            // Snackbar Exit
            exitClip.current.volume = DEFAULT_AUDIO_VOLUME;
            exitClip.current.src = SnackbarExit;
        }

        return function cleanup() {
            if (enterClip.current) enterClip.current.remove();
            if (exitClip.current) exitClip.current.remove();
        };
    }, []);

    useEffect(() => {
        if (
            (
                data.visible
                && !visible
            ) || (
                visible
                && data.visible
                && data.text !== text
            )
            || (
                visible
                && data.visible
                && data.icon !== icon
            )
            || (
                visible
                && data.visible
                && data.hasSuccess !== hasSuccess
            )
            || (
                visible
                && data.visible
                && data.hasFailure !== hasFailure
            )
        ) {
            if (resetTimeoutIsActive) {
                const queueItem: ISnackbarItem = {
                    ...data,
                };
                const updatedQueue = [...snackbarQueue, queueItem];
                setSnackbarQueue(updatedQueue);
            } else if (
                visible
                && (
                    data.dismissable
                    || data.hasFailure
                )
            ) {
                const queueItem: ISnackbarItem = {
                    ...data,
                };
                const updatedQueue = [...snackbarQueue, queueItem];
                setSnackbarQueue(updatedQueue);
                dismissSnackbar();
            } else {
                executeSnackbar(data);
            }
        } else if (!data.visible && visible) {
            dismissSnackbar();
        }
    }, [data]);

    useEffect(() => {
        if (
            !resetTimeoutIsActive
            && snackbarQueue.length > 0
        ) {
            const queue = [...snackbarQueue];
            const nextItem = queue.shift()!;
            setSnackbarQueue(queue);
            executeSnackbar(nextItem);
        }
    }, [resetTimeoutIsActive]);

    const {
        start: timeoutDismissTimedSnackbar,
        clear: clearTimeoutDismissTimedSnackbar,
    } = useTimeout(() => {
        dismissSnackbar();
    }, data.duration || 0);

    const {
        start: timeoutResetSnackbar,
        clear: clearTimeoutResetSnackbar,
    } = useTimeout(() => {
        setText(null);
        if (icon) setIcon(null);
        if (hasSuccess) setHasFailure(false);
        if (hasFailure) setHasFailure(false);
        if (resetTimeoutIsActive) setResetTimeoutIsActive(false);
    }, SNACKBAR_RESET_TIMEOUT_DURATION);

    // ===== Memoizations =====

    const dismissButtonBackground = useMemo(() => {
        if (hasSuccess) return theme.verascopeColor.blue200;
        if (hasFailure) return theme.verascopeColor.red200;
        return theme.verascopeColor.purple200;
    }, [hasSuccess, hasFailure]);

    return (
        <Transition
            in={visible}
            timeout={{
                enter: SNACKBAR_ENTER_DURATION,
                exit: SNACKBAR_EXIT_DURATION,
            }}
        >
            {(state) => (
                <Container
                    hasSuccess={hasSuccess}
                    hasFailure={hasFailure}
                    dismissable={!!data.dismissable || !!data.hasFailure}
                    hasIcon={!!icon}
                    style={{
                        ...FADE_IN_DEFAULT_STYLE({
                            direction: 'up',
                            offset: 10,
                            duration: SNACKBAR_ENTER_DURATION,
                            easing: theme.motion.eagerEasing,
                            horizontalCenter: true,
                        }),
                        ...FADE_IN_TRANSITION_STYLES({
                            direction: 'up',
                            offset: 10,
                            horizontalCenter: true,
                        })[state],
                    }}
                >
                    {icon
                    && (
                        <SnackbarIcon>
                            <ReactSVG
                                src={icon}
                            />
                        </SnackbarIcon>
                    )}
                    <SnackbarText
                        center={!icon && data.text.length < CENTER_TEXT_LENGTH_THRESHOLD}
                        hasIcon={!!icon}
                        dismissable={!!data.dismissable || !!data.hasFailure}
                        buttonLength={DISMISS_BUTTON_LENGTH}
                    >
                        {text}
                    </SnackbarText>
                    {(data.dismissable || data.hasFailure) && (
                        <DismissButtonContainer>
                            <Button
                                className={HOVER_TARGET_CLASSNAME}
                                type={BUTTON_TYPE.solid}
                                center
                                height={DISMISS_BUTTON_LENGTH}
                                width={DISMISS_BUTTON_LENGTH}
                                icon={CrossIcon}
                                background={dismissButtonBackground}
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                {...(detectTouchDevice(document) ? {
                                    onTouchStart: (e) => dismissSnackbar(e),
                                } : {
                                    onMouseDown: (e) => dismissSnackbar(e),
                                })}
                            />
                        </DismissButtonContainer>
                    )}
                </Container>
            )}
        </Transition>
    );
}

export default Snackbar;
