/* eslint-disable max-len */
// ===== Packages =====

import React        from 'react';

// ===== Styles =====

import {
    Container,
}                   from './styles';

Spinner.defaultProps = {
    large: false,
    lightBackground: false,
};
interface Props {
    large?: boolean,
    lightBackground?: boolean,
}
function Spinner({
    large,
    lightBackground,
}: Props): JSX.Element {
    return (
        <Container
            large={large}
            lightBackground={lightBackground}
            viewBox="0 0 20 20"
        >
            <path d="M2.9,2.9C3.4,2.6,4,2.7,4.3,3.2c0.2,0.3,0.3,0.8,0,1.1v0.1C2.9,5.8,2,7.8,2,10c0,4.4,3.6,8,8,8s8-3.6,8-8s-3.6-8-8-8C9.4,1.9,9.1,1.4,9.2,0.8C9.3,0.4,9.6,0.1,10,0c5.5,0,10,4.5,10,10s-4.5,10-10,10S0,15.5,0,10C0,7.3,1.1,4.8,2.9,2.9z" />
        </Container>
    );
}

export default Spinner;
