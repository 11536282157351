// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
}                                       from 'react';
import { createPortal }                 from 'react-dom';
import styled                           from 'styled-components';
import { ReactSVG }                     from 'react-svg';
import { useDropzone }                  from 'react-dropzone';
import { v4 as uuidv4 }                 from 'uuid';
import mime                             from 'mime-types';
import {
    useFocused,
    useSelected,
}                                       from 'slate-react';
import {
    ref,
    getStorage,
    getDownloadURL,
    StorageError,
    UploadTaskSnapshot,
    StorageReference,
}                                       from 'firebase/storage';
import {
    doc,
    getDoc,
    getFirestore,
    onSnapshot,
    Unsubscribe,
}                                       from 'firebase/firestore';
import ShortUniqueId                    from 'short-unique-id';

// ===== Components =====

import {
    Button,
    OptionsMenu,
    RecordingLoader,
}                                       from '../helpers';
import Tooltip                          from '../../Tooltip';

// ===== Constants =====

import {
    AUDIO_NOTE_GENERAL_ERROR,
    AUDIO_NOTE_BROWSER_NOT_SUPPORTED,
    AUDIO_NOTE_PERMISSION_DENIED_ERROR,
    AUDIO_NOTE_RECORDING_DEVICE_BUSY,
    AUDIO_NOTE_RECORDING_DEVICE_NOT_FOUND,
    AUDIO_NOTE_UNSUPPORTED_FILE_TYPE,
    AUDIO_NOTE_FETCH_FILE_ERROR,
}                                       from '../../../constants/notificationMessages';
import {
    MILLISECONDS_IN_A_SECOND,
    MILLISECONDS_IN_A_DAY,
    MILLISECONDS_IN_AN_HOUR,
    MILLISECONDS_IN_A_MINUTE,
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    BUTTON_CONTAINER_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
    HOVER_TARGET_CLASSNAME,
    CURSOR_Z_INDEX,
    DEFAULT_AUDIO_VOLUME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    NANOSECONDS_IN_A_SECOND,
}                                       from '../../../constants/generalConstants';
import KEYCODE                          from '../../../constants/keycodes';
import CURSOR_SIGN                      from '../../../constants/cursorSigns';
import FONT_TYPE                        from '../../../constants/fontType';

// ===== Enums =====

import {
    AUDIO_NOTE_RECORDING_STATE,
    TOOLTIP_TYPE,
    BUTTON_TYPE,
    AUDIO_NOTE_SKIP_DIRECTION,
    MEDIA_TYPE,
    STORAGE_ENTITY,
    STORAGE_ERROR_CODE,
    FIRESTORE_COLLECTION,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    EDITOR_CONTEXT_TYPE,
    USER_ACTION_TYPE,
}                                       from '../../../enums';

// ===== Interfaces =====

import {
    ICoord,
    IAudioNoteData,
    IMediaItem,
    IUserItem,
    IEditorAudioNoteItem,
    ISnackbarItem,
    ITranscriptionWord,
}                                       from '../../../interfaces';

// ===== Services =====

import {
    moveCursorToEnd,
    setColorLightness,
    uploadToCloudStorage,
    getStorageErrorMessage,
    setMediaInDB,
    getMediaStorageBucket,
    updateAnnotationMediaInDB,
    playAudio,
    recordUserAction,
    fontColorDiscriminator,
    detectTouchDevice,
}                                       from '../../../services';

// ===== Hooks =====

import {
    usePortal,
    useKeyPress,
    useInterval,
    useTimeout,
    useEventListener,
    useRemovePasteStyling,
}                                       from '../../../hooks';

// ===== Sounds =====

import InputClick                       from '../../../sounds/button_click.mp3';

// ===== Icons =====

import CautionIcon                      from '../../../images/caution.svg';
import SaveIcon                         from '../../../images/editor/floppy-disk.svg';
import TrashIcon                        from '../../../images/editor/trash.svg';
import PlayIcon                         from '../../../images/editor/play-fill.svg';
import PauseIcon                        from '../../../images/editor/pause-fill.svg';
import StopIcon                         from '../../../images/editor/stop-fill.svg';
import MicrophoneIcon                   from '../../../images/editor/mic.svg';
import RedoIcon                         from '../../../images/editor/redo.svg';
import PencilIcon                       from '../../../images/editor/pencil.svg';
import ImportIcon                       from '../../../images/editor/upload.svg';
import DownloadIcon                     from '../../../images/editor/download-arrow.svg';
import SkipForwardIcon                  from '../../../images/editor/skip-forward-15.svg';
import SkipBackwardIcon                 from '../../../images/editor/skip-backward-15.svg';
import ClockIcon                        from '../../../images/editor/clock.svg';
import TextIcon                         from '../../../images/editor/text.svg';

// ===== Styles =====

import { theme as themeObj }            from '../../../themes/theme-context';
import {
    DropzoneInput,
    OptionsMenuContainer,
    basicEditorFontStyles,
}                                       from '../../../styles';

// ===== Functions =====

export const getDefaultAudioTitle = (): string => {
    const now = new Date();
    const defaultTitle = `New Audio Note [${
        now.getFullYear()
    }-${
        now.getMonth() > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`
    }-${
        now.getDate()
    } ${
        now.getHours() > 9 ? now.getHours() : `0${now.getHours()}`
    }:${
        now.getMinutes() > 9 ? now.getMinutes() : `0${now.getMinutes()}`
    }:${
        now.getSeconds() > 9 ? now.getSeconds() : `0${now.getSeconds()}`
    }]`;
    return defaultTitle;
};

// ===== Resources =====

// 1) Accurate JavaScript Timer: https://medium.com/@olinations/stopwatch-script-that-keeps-accurate-time-a9b78f750b33
// 2) List of Mime Types: http://help.dottoro.com/lapuadlp.php
// 3) Useful Link: https://github.com/GersonRosales/Record-Audios-and-Videos-with-getUserMedia/blob/master/example_1.html
// 4) Errors while playing audio: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
// 5) Safari Considerations:
// https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html
// 5.1) NOTE: Safari on Desktop (and presumebly iOS) cannot play webm audio
// - See: https://discussions.apple.com/thread/8309003
// - See: https://www.reddit.com/r/apple/comments/40b3y3/this_is_how_you_can_play_webm_in_safari/
// 5.2) NOTE: Safari on Desktop does not support navigator.getUserMedia and thus cant record. Not yet tested in iOS
// - More on Safari support: https://stackoverflow.com/questions/42221646/voice-recording-on-iphone-by-using-safari-and-html5
// - Determining if we can record: https://stackoverflow.com/questions/30047056/is-it-possible-to-check-if-the-user-has-a-camera-and-microphone-and-if-the-permi
// Useful Resource on Audio: https://kaliatech.github.io/web-audio-recording-tests/dist/#/
// - There is a way to do visualizations and different encodings
// 6) Detecting playable audio formats: https://davidwalsh.name/detect-supported-audio-formats-javascript

AudioNote.defaultProps = {
    buttonWidth: undefined,
    readOnly: false,
    fixedWidth: false,
    isAuthor: false,
    uploaded: false,
    autoRecord: false,
    handleAutoRecord: null,
    revealOptionsAbove: false,
    autoRecordIdentifier: null,
    small: false,
    notifyIfUnsavedAudioNote: false,
    saveOutstandingAudioNotes: false,
    incrementUnsavedAudioNotes: undefined,
    decrementUnsavedAudioNotes: undefined,
};
interface Props {
    id: string,
    user: IUserItem | null,
    small?: boolean,
    buttonWidth?: number,
    hasSound: boolean,
    filePath: string | null,
    editorType: EDITOR_CONTEXT_TYPE,
    title: string,
    color: string,
    isAuthor?: boolean,
    uploaded?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement,
    readOnly?: boolean,
    parentRef: HTMLElement | undefined,
    attributes: any,
    currentSessionId: string | null,
    postId: string | undefined,
    autoRecord?: boolean,
    fixedWidth?: boolean,
    description: string,
    updateAudioNote: (data: IAudioNoteData) => void,
    handleAutoRecord?: ((handle: boolean) => void) | null,
    revealOptionsAbove?: boolean,
    autoRecordIdentifier?: string | null,
    isRecordingAudioNote: boolean,
    setIsRecordingAudioNote: React.Dispatch<React.SetStateAction<boolean>>,
    notifyIfUnsavedAudioNote?: boolean,
    saveOutstandingAudioNotes?: boolean,
    incrementUnsavedAudioNotes?: () => void,
    decrementUnsavedAudioNotes?: () => void,
    broadcastHeightAdjusted: () => void,
    uploadingMedia: Map<string, IMediaItem>,
    setUploadingMedia: (mediaItem: IMediaItem) => void,
    updateUploadingMedia: (mediaItem: IMediaItem) => void,
    removeUploadingMedia: (id: string) => void,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
}
function AudioNote({
    id,
    user,
    small,
    buttonWidth,
    hasSound,
    filePath = null,
    editorType,
    title = '',
    color,
    isAuthor = false,
    uploaded = false,
    children,
    readOnly = false,
    parentRef = undefined,
    attributes,
    currentSessionId,
    postId,
    autoRecord = false,
    fixedWidth = false,
    description = '',
    updateAudioNote,
    handleAutoRecord = null,
    revealOptionsAbove = true,
    autoRecordIdentifier = null,
    isRecordingAudioNote,
    setIsRecordingAudioNote,
    notifyIfUnsavedAudioNote,
    saveOutstandingAudioNotes,
    incrementUnsavedAudioNotes,
    decrementUnsavedAudioNotes,
    broadcastHeightAdjusted,
    uploadingMedia,
    setUploadingMedia,
    updateUploadingMedia,
    removeUploadingMedia,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    setSnackbarData,
}: Props): JSX.Element {
    // ===== General Constants =====

    const ACTION_CONTAINER_PADDING_LEFT = 20;
    const ACTION_CONTAINER_PADDING_RIGHT = 10;
    const DEFAULT_AUDIO_NOTE_BUTTON_LENGTH = 40;
    const MAX_TIMESTAMP_WIDTH = 65;
    const PROGRESS_SCRUBBER_ID = (elementId: string | null): string => (elementId ? `progress-scrubber id=${elementId}` : '');
    const PROGRESS_INDICATOR_ID = (elementId: string | null): string => (elementId ? `progress-indicator id=${elementId}` : '');
    const POTENTIAL_SCRUBBER_ID = (elementId: string | null): string => (elementId ? `potential-scrubber id=${elementId}` : '');
    const LEFT_TIME_FIGURE_ID = (elementId: string | null): string => (elementId ? `left-time-figure id=${elementId}` : '');
    const RIGHT_TIME_FIGURE_ID = (elementId: string | null): string => (elementId ? `right-time-figure id=${elementId}` : '');
    const TITLE_INPUT_ID = (elementId: string | null): string => (elementId ? `title-input id=${elementId}` : '');
    const DESCRIPTION_INPUT_ID = (elementId: string | null): string => (elementId ? `description-input id=${elementId}` : '');
    const PROGRESS_INDICATOR_DELAY_DURATION = 300;
    const PLAYBACK_DROPDOWN_ITEM_WIDTH = 60;
    const PLAYBACK_DROPDOWN_ITEM_HEIGHT = 30;
    const PLAYBACK_CONTAINER_PADDING_LEFT = 10;
    const STATUS_ICON_MARGIN_LEFT = 10;
    const TITLE_FONT_SIZE = 1;
    const DESCRIPTION_FONT_SIZE = 1;
    const CONTAINER_PADDING_AUDIO_NOTE = 10;
    const SNACKBAR_MESSAGE_FETCH_AUDIO_ERROR = 'There was a problem fetching audio note audio.';

    // TODO don't use refs on things that will leave dom

    // ===== Refs =====

    const audioNodeRef = useRef<HTMLAudioElement>(null);
    const titleNodeRef = useRef<HTMLInputElement>(null);
    const descriptionNodeRef = useRef<HTMLDivElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const titleInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    // ----- Sound Clips

    const inputClickClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // ----- Editing Audio Notes -----

    // Stores whether we have loaded an existing audio note
    const [isNewAudioNote, setIsNewAudioNote] = useState<boolean>((!filePath || filePath === autoRecordIdentifier));
    // Stores whether audio was uploaded or not
    const [audioUploaded, setAudioUploaded] = useState<boolean>(uploaded);
    // Stores recording state dictacting available functionality
    const [status, setStatus] = useState<AUDIO_NOTE_RECORDING_STATE>(!filePath || filePath === autoRecordIdentifier
        ? AUDIO_NOTE_RECORDING_STATE.idle
        : AUDIO_NOTE_RECORDING_STATE.pausedComplete);
    // Stores audio chunks from recording session
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    // Stores audio length in milliseconds (whereas Audio API stores seconds)
    const [audioLength, setAudioLength] = useState<number>(0);
    // Stores audio blob from recording session or audio import
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    // Stores link with audio data
    const [audioURL, setAudioURL] = useState<string | null>(filePath !== autoRecordIdentifier ? filePath : null);
    // Stores timestamp at which audio note started playing
    const [startTime, setStartTime] = useState<number | null>(null);
    // Stores intermission of audio note when it is paused
    const [savedTime, setSavedTime] = useState<number | null>(null);
    // Stores the current time lapsed in the audiio
    const [timeElapsed, setTimeElapsed] = useState<number>(0);
    // Stores time being pointed to when user is scrubbing the audio track
    const [potentialElapsed, setPotentialElapsed] = useState<number | null>(null);
    // Stores whether the time elapsed is being updated
    const [isUpdatingTime, setIsUpdatingTime] = useState<boolean>(false);
    // Stores whether audio note is being edited (this is different from readOnly).
    // It is equivalent to being in the post editor and not in the recording mode as opposed to the post reader
    const [isEditing, setIsEditing] = useState<boolean>((!filePath || filePath === autoRecordIdentifier) && !readOnly);
    // Position of title node relative to the viewport
    // Used by portal TitleInput to position it above
    // hidden node.
    const [tempId] = useState<string>(uuidv4());
    const [titlePos, setTitlePos] = useState<ICoord>({
        x: 0,
        y: 0,
    });
    // Width of stand-in title used by the
    // TitleInput
    const [titleWidth, setTitleWidth] = useState(0);
    // Position of description node relative to the viewport
    // Used by portal DescriptionInput to position it above
    // hidden node.
    const [descriptionPos, setDescriptionPos] = useState({
        left: 0,
        top: 0,
    });
    // Width of stand-in description used by the
    // DescriptionInput
    const [descriptionWidth, setDescriptionWidth] = useState(0);
    //  Width of Audio Note Button
    const [buttonLength] = useState(buttonWidth || DEFAULT_AUDIO_NOTE_BUTTON_LENGTH);
    // Stores the flag for spacebar presses
    const spacebarPress = useKeyPress(KEYCODE.enter);
    // Stores the current selection
    const [selection, setSelection] = useState< number>(0);
    // Stores Error Message
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Store whether we have recording permissions
    const [hasRecordingPermissions, setHasRecordingPermissions] = useState<boolean>(false);
    // Stores last value of isRecordingAudioNote
    const [prevIsRecordingAudioNote, setPrevIsRecordingAudioNote] = useState<boolean | null>(null);
    // Indicates whether description input is focused to reveal caret
    const [descriptionInputFocused, setDescriptionInputFocused] = useState<boolean>(false);
    // Stores audio note uploading progress
    const [uploadingStateChangeEvent, setUploadingStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    // Stores audio note completion event object
    const [uploadingCompleteEvent, setUploadingCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    // Indicates whether an attempt at fetching audio URL has been made
    const [attemptFetchURL, setAttemptFetchURL] = useState<boolean>(false);

    // ----- Saved Audio Note -----

    // Stores optional title of audio note
    const [audioTitle, setAudioTitle] = useState<string>(title);
    // Stores optional description of audio note
    const [audioDescription, setAudioDescription] = useState<string>(description);
    // Stores playback speed
    const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
    // Stores whether we should make playback speed visible
    // Used when the playback dropdown is made visible
    // We hide the playback for aesthetic reasons
    const [hidePlaybackSpeed, setHidePlaybackSpeed] = useState<boolean>(false);
    // Stores mime type of audio note
    const [mimeType, setMimeType] = useState<string>('');
    // Stores audio note media item
    const [audioNoteMediaItem, setAudioNoteMediaItem] = useState<IEditorAudioNoteItem | null>(null);
    // Indicates whether transcript should be visible
    const [showTranscript, setShowTranscript] = useState<boolean>(false);

    // ----- Both Saved and Editing -----

    // Stores whether Slate element is focused
    const focused = useFocused();
    // Stores whether Slate element is selected
    const selected = useSelected();

    // Store file locally
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            // Get File local URL
            const file = acceptedFiles[0];
            const blob = new Blob([file], {
                type: file.type,
            });
            const noteURL = URL.createObjectURL(blob);

            // Set Title
            setAudioTitle(file.name);
            // Save Blob, URL, and set to Pause Complete
            setAudioBlob(blob);
            setAudioURL(noteURL);
            setStatus(AUDIO_NOTE_RECORDING_STATE.pausedComplete);
            setTimeElapsed(0);
            // Flag as uploaded audio note
            setAudioUploaded(true);
        }
    }, []);
    const {
        open: openFileSelector,
        getRootProps,
        getInputProps,
    } = useDropzone({
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        accept: [
            'audio/x-m4a',
            'audio/mpeg',
            'audio/x-aiff',
            'audio/webm',
            'application/ogg',
            'audio/aiff',
            'audio/aifc',
            'audio/m4a',
            'audio/aac',
            'audio/mp3',
            'audio/mpeg3',
            'audio/mpg',
            'audio/x-mp3',
            'audio/x-mpeg',
            'audio/x-mpeg3',
            'audio/x-mpg',
            'application/x-ogg',
            'audio/x-ogg',
            'audio/wav',
            'audio/s-wav',
            'audio/wave',
            'audio/x-wav',
            'audio/flac',
        ],
        onDropAccepted,
    });

    // ===== Animation Constants =====

    const TRANSCRIPT_TRANSITION_DURATION = 400;

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (inputClickClip.current) {
            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;
        }

        return function cleanup() {
            if (inputClickClip.current) inputClickClip.current.remove();
        };
    }, []);

    // On Mount, Load Audio if Exists
    // IMPORTANT: Consider in the future only loading when user presses play.
    useEffect(() => {
        if (filePath && audioNodeRef.current) {
            audioNodeRef.current.load();
        }
    }, [audioNodeRef.current, filePath]);

    // Update Audio Details
    useEffect(() => {
        if (title !== audioTitle) {
            setAudioTitle(title);
        }

        if (description !== audioDescription) {
            setAudioDescription(description);
        }

        if (uploaded !== audioUploaded) {
            setAudioUploaded(uploaded);
        }
    }, [
        title,
        uploaded,
        description,
    ]);

    // Listen for saved audio note
    useEffect(() => {
        let unsubscribe: Unsubscribe;
        if (!isNewAudioNote) {
            const db = getFirestore();
            const collection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.media
                : FIRESTORE_COLLECTION.stagingMedia;
            unsubscribe = onSnapshot(doc(db, collection, id), (itemSnap) => {
                if (itemSnap.exists()) {
                    const audioNoteItem = itemSnap.data() as IEditorAudioNoteItem;
                    const initializingAudioNote = !audioNoteMediaItem;
                    if (audioNoteItem) setAudioNoteMediaItem(audioNoteItem);
                    if (initializingAudioNote && !showTranscript) {
                        broadcastHeightAdjusted();
                        // show transcript now that we have the data
                        setShowTranscript(true);
                    }
                }
            });
        }

        return function cleanup() {
            if (unsubscribe) unsubscribe();
        };
    }, [
        audioNoteMediaItem,
        isNewAudioNote,
        showTranscript,
    ]);

    // When modify audioURL, load new Audio
    // Get mime type
    useEffect(() => {
        // Fetch Error: https://stackoverflow.com/questions/49343024/getting-typeerror-failed-to-fetch-when-the-request-hasnt-actually-failed
        // Will fail on localhost because of CORS issues
        const fetchURL = (url: string): Promise<void | Error> => (fetch(url)
            .then((res) => res.blob()) // Gets the response and returns it as a blob
            .then((blob) => {
                setAudioBlob(blob);
                setMimeType(blob.type);
                if (readOnly) {
                    const tempURL = URL.createObjectURL(blob);
                    setAudioURL(tempURL);
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    (audioNodeRef.current!.childNodes[0] as HTMLSourceElement).src = tempURL;
                } else if (url !== audioURL) {
                    setAudioURL(url);
                }
                setAttemptFetchURL(true);
            })
            .catch(() => {
                setAudioURL(null);
                setAttemptFetchURL(true);
                setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
                setErrorMessage(AUDIO_NOTE_FETCH_FILE_ERROR);

                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_FETCH_AUDIO_ERROR,
                    icon: CautionIcon,
                    hasFailure: true,
                });
            })
        );

        if (
            audioNodeRef.current
            && filePath
            && filePath !== autoRecordIdentifier
            && !attemptFetchURL
        ) {
            audioNodeRef.current.load();
            const storage = getStorage();
            const pathParts = filePath.split('.');
            const flacPath = `${pathParts[0]}.flac`;
            getDownloadURL(ref(storage, flacPath))
                .then((url) => fetchURL(url))
                .catch((error) => {
                    if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                        getDownloadURL(ref(storage, filePath))
                            .then((url) => fetchURL(url))
                            .catch(() => {
                                setAudioURL(null);
                                setAttemptFetchURL(true);
                                setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
                                setErrorMessage(AUDIO_NOTE_FETCH_FILE_ERROR);

                                setSnackbarData({
                                    visible: true,
                                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                                    text: SNACKBAR_MESSAGE_FETCH_AUDIO_ERROR,
                                    icon: CautionIcon,
                                    hasFailure: true,
                                });
                            });
                    } else {
                        setAudioURL(null);
                        setAttemptFetchURL(true);
                        setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
                        setErrorMessage(AUDIO_NOTE_FETCH_FILE_ERROR);

                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_FETCH_AUDIO_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                    }
                });
        }
    }, [filePath]);

    useEffect(() => {
        if (audioNodeRef.current) {
            audioNodeRef.current.load();
        }
    }, [audioURL]);

    // Determine Recording Permissions
    useEffect(() => {
        if (!readOnly) {
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then((stream: MediaStream): void => {
                        const mediaStreamTrack = stream.getAudioTracks()[0];
                        if (typeof mediaStreamTrack !== 'undefined') {
                            setHasRecordingPermissions(true);
                        }
                    })
                    .catch((e) => {
                        switch (e.name) {
                        case 'NotFoundError':
                        case 'DevicesNotFoundError':
                            setErrorMessage(AUDIO_NOTE_RECORDING_DEVICE_NOT_FOUND);
                            if (hasRecordingPermissions) {
                                setHasRecordingPermissions(false);
                            }
                            break;
                        case 'SourceUnavailableError':
                            setErrorMessage(AUDIO_NOTE_RECORDING_DEVICE_BUSY);
                            if (hasRecordingPermissions) {
                                setHasRecordingPermissions(false);
                            }
                            break;
                        case 'PermissionDeniedError':
                        case 'SecurityError':
                            setErrorMessage(AUDIO_NOTE_PERMISSION_DENIED_ERROR);
                            if (hasRecordingPermissions) {
                                setHasRecordingPermissions(false);
                            }
                            break;
                        default:
                            setErrorMessage(AUDIO_NOTE_GENERAL_ERROR);
                            if (hasRecordingPermissions) {
                                setHasRecordingPermissions(false);
                            }
                        }
                    });
            } else {
                setErrorMessage(AUDIO_NOTE_BROWSER_NOT_SUPPORTED);
                if (hasRecordingPermissions) {
                    setHasRecordingPermissions(false);
                }
            }
        } else if (readOnly && mimeType) {
            // We are in read only
            // determine if audio type playable
            const audioIsSupported = supportsAudioType(mimeType);
            if (!audioIsSupported) {
                setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
                setErrorMessage(AUDIO_NOTE_UNSUPPORTED_FILE_TYPE(mime.extension(mimeType)));
            }
        }
    }, [readOnly, mimeType]);

    // Detect Auto Record
    useEffect(() => {
        if (
            autoRecord
            && !!handleAutoRecord
            && hasRecordingPermissions
            && autoRecordIdentifier
            && filePath === autoRecordIdentifier
        ) {
            // Notify parent editor to stop other recordings
            setIsRecordingAudioNote(false);
            setIsNewAudioNote(true);
            setIsEditing(true);
            startRecord();
            handleAutoRecord(false);
        }
    }, [hasRecordingPermissions]);

    // Listens to Editor parent on whether to stop recording
    // Triggered when new other audio note created
    useEffect(() => {
        if (
            status === AUDIO_NOTE_RECORDING_STATE.recording
            && !isRecordingAudioNote
            && prevIsRecordingAudioNote
        ) {
            stopRecord();
        }
        setPrevIsRecordingAudioNote(isRecordingAudioNote);
    }, [isRecordingAudioNote]);

    // Let parent element know if unsaved if signal sent
    useEffect(() => {
        if (
            notifyIfUnsavedAudioNote
            && status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
            && isEditing
            && incrementUnsavedAudioNotes
        ) {
            incrementUnsavedAudioNotes();
        }
    }, [notifyIfUnsavedAudioNote]);

    // Listens to Editor parent on whether to save audio note
    useEffect(() => {
        if (
            saveOutstandingAudioNotes
            && isNewAudioNote
            && status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
            && isEditing
        ) {
            saveAudioNote();
        }
    }, [saveOutstandingAudioNotes]);

    // Handle Play/Pause using Spacebar
    useEffect(() => {
        if (
            spacebarPress
            && selected
            && focused
            && (
                !isEditing
                || (
                    document.activeElement !== titleInputRef.current
                    && document.activeElement !== descriptionInputRef.current
                )
            ) && status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
        ) {
            // Play if paused, and not recording mode
            playAudioNote(true);
        } else if (
            spacebarPress
            && selected
            && focused
            && status === AUDIO_NOTE_RECORDING_STATE.playing
        ) {
            // Pause if playing
            playAudioNote(false);
        } else if (
            spacebarPress
            && selected
            && focused
            && status === AUDIO_NOTE_RECORDING_STATE.recording
        ) {
            // Pause recording
            pauseRecord();
        } else if (
            spacebarPress
            && selected
            && focused
            && status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
        ) {
            // Resume Recording
            resumeRecord();
        }
    }, [spacebarPress]);

    // Handle Move Cursor using Enter
    // when in title
    useEventListener(
        'keydown',
        (e: Event) => {
            if ((e as KeyboardEvent).key === KEYCODE.enter) {
                e.preventDefault();
                // Pressing enter from title to description
                descriptionInputRef.current?.focus();
                if (descriptionInputRef.current) {
                    moveCursorToEnd(descriptionInputRef.current);
                }
            }
        },
        titleInputRef.current as HTMLElement,
    );

    // Set Description Field when editing
    useEffect(() => {
        if (
            isEditing
            && descriptionInputRef.current
            && descriptionNodeRef.current
            && audioDescription.length > 0
        ) {
            descriptionInputRef.current.innerText = audioDescription;
            descriptionNodeRef.current.innerText = audioDescription;
        }
    }, [isEditing, descriptionInputRef.current]);

    // Update the audio length in realtime when recording audio
    useEventListener(
        'timeupdate',
        useCallback(() => {
            if (
                audioNodeRef.current
                && !Number.isNaN(audioNodeRef.current.duration)
            ) {
                setAudioLength(audioNodeRef.current.duration * MILLISECONDS_IN_A_SECOND);
            }
        }, [audioNodeRef.current]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        audioNodeRef.current!,
    );

    // Create audio note blob when done recording
    useEventListener(
        'dataavailable',
        useCallback((event) => {
            const newAudioChunks = [...audioChunks, (event as BlobEvent).data];
            setAudioChunks(newAudioChunks);
            if (!audioURL) {
                handleCreateAudioNote(newAudioChunks);
            }
        }, [audioChunks, audioURL]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mediaRecorderRef.current!,
    );

    // Update audio lenth when metadata updated
    useEventListener(
        'loadedmetadata',
        useCallback((): void => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { duration } = audioNodeRef.current!;
            // Chromium bug condition
            if (audioNodeRef.current && duration === Infinity) {
                // ...set audioNodeRef.current.currentTime to a very large number
                // ...listen to "timeupdate" event
                // ...on "timeupdate", retrieve now-updated duration
                audioNodeRef.current.currentTime = 10000000 * Math.random();
            } else if (!Number.isNaN(duration)) {
                setAudioLength(duration * MILLISECONDS_IN_A_SECOND);
            }
        }, [audioNodeRef.current]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        audioNodeRef.current!,
    );

    // Create audionote when recording stopped
    useEventListener(
        'stop',
        useCallback(() => {
            if (audioChunks.length > 0 && !audioURL) {
                const defaultTitle = title || getDefaultAudioTitle();
                setAudioTitle(defaultTitle);
                handleCreateAudioNote([...audioChunks]);
                setAudioChunks([]);
                // Flag as not an upload
                setAudioUploaded(false);
            }
        }, [audioChunks, audioURL]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mediaRecorderRef.current!,
    );

    // Update elapsed time
    // Stop audio when reached the end
    useInterval(() => {
        const newElapsed = computeTimeElapsed();
        setTimeElapsed(newElapsed);
        // Stop Playback when reach end of recording
        if (
            audioNodeRef.current
            && status === AUDIO_NOTE_RECORDING_STATE.playing
            && audioLength > 0
            && !Number.isNaN(audioLength)
            && timeElapsed >= audioLength
        ) {
            // Update Recording timestamp
            setIsUpdatingTime(false);
            audioNodeRef.current.pause();
            audioNodeRef.current.currentTime = 0;
            setTimeElapsed(0);
            setStartTime(null);
            setSavedTime(null);
            setStatus(AUDIO_NOTE_RECORDING_STATE.pausedComplete);
        }
        // The alternative occurs when the metadata is not fetched.
        // This results in immediately ending the song.
        // This is an edge case that happens a handful of times.
    }, isUpdatingTime ? MILLISECONDS_IN_A_SECOND / 10 : null);

    // Listen for changes to the selection
    // Used to update the position of the title
    // and description inputs while editing
    useEventListener(
        'keyup',
        () => {
            if (
                (
                    titleNodeRef.current
                    || descriptionNodeRef.current
                ) && isEditing
            ) {
                setSelection((int) => int + 1);
            }
        },
    );

    // Remove Paste Styling for Title
    useRemovePasteStyling(titleInputRef.current!);
    // Remove Paste Styling for Description
    useRemovePasteStyling(descriptionInputRef.current!);
    // Handle changes to description input
    useEventListener(
        'input',
        () => {
            setAudioDescription(descriptionInputRef.current!.innerText);
            descriptionNodeRef.current!.innerText = descriptionInputRef.current!.innerText;
        },
        descriptionInputRef.current!,
    );

    // Update position of audio title node
    useEffect(() => {
        // Modify width of Title Input
        handleModifyTitleInputWidth();
    }, [
        status,
        timeElapsed,
        audioLength,
        audioTitle,
        isEditing,
        selection,
        titleNodeRef.current,
    ]);

    // Update position of audio description node
    useEffect(() => {
        // Modify width of Description Input
        handleModifyDescriptionInputWidth();
    }, [
        status,
        timeElapsed,
        audioLength,
        audioDescription,
        isEditing,
        selection,
        descriptionNodeRef.current,
    ]);

    useEffect(() => {
        if (uploadingStateChangeEvent) {
            handleUploadingMediaStateChange(
                uploadingStateChangeEvent.mediaItem,
                uploadingStateChangeEvent.snapshot,
                uploadingStateChangeEvent.progress,
            );
            // setUploadingStateChangeEvent(null);
        }
    }, [uploadingStateChangeEvent]);

    useEffect(() => {
        if (uploadingCompleteEvent) {
            // Handle successful uploads on complete
            getDownloadURL(uploadingCompleteEvent.storageRef).then((downloadURL: string) => {
                handleUploadingMediaComplete(uploadingCompleteEvent.mediaItem, downloadURL);
            });
        }
    }, [uploadingCompleteEvent]);

    // Handle Resize
    useEventListener(
        'resize',
        () => {
            handleViewportResize();
        },
    );

    useEventListener(
        'scroll',
        () => {
            // Modify width of Title Input
            handleModifyTitleInputWidth();
            // Modify width of Description Input
            handleModifyDescriptionInputWidth();
        },
        parentRef,
    );

    const titleTarget = usePortal(
        `audio-note-title-input-${tempId}`,
        parentRef,
        true,
    );

    const descriptionTarget = usePortal(
        `audio-note-description-input-${tempId}`,
        parentRef,
        true,
    );

    // ========== Methods ===========

    const startRecord = (): void => {
        if (hasRecordingPermissions) {
            // Starting AudioNote
            navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: false,
                    autoGainControl: false,
                },
            }).then((stream) => {
                // Modify recording state
                setStatus(AUDIO_NOTE_RECORDING_STATE.recording);
                // Notify parent editor that recording is taking place
                setIsRecordingAudioNote(true);
                mediaRecorderRef.current = new MediaRecorder(stream, {
                    audioBitsPerSecond: 128000,
                });
                // Start recording
                mediaRecorderRef.current.start();
                // Cache timestamp started recording
                setStartTime(Date.now());
                // Update Recording timestamp
                setIsUpdatingTime(true);
            }).catch((e) => {
                setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
                switch (e.name) {
                case 'NotAllowedError':
                    setErrorMessage(AUDIO_NOTE_PERMISSION_DENIED_ERROR);
                    if (hasRecordingPermissions) {
                        setHasRecordingPermissions(false);
                    }
                    break;
                case 'NotFoundError':
                case 'DevicesNotFoundError':
                    setErrorMessage(AUDIO_NOTE_RECORDING_DEVICE_NOT_FOUND);
                    if (hasRecordingPermissions) {
                        setHasRecordingPermissions(false);
                    }
                    break;
                case 'SourceUnavailableError':
                    setErrorMessage(AUDIO_NOTE_RECORDING_DEVICE_BUSY);
                    if (hasRecordingPermissions) {
                        setHasRecordingPermissions(false);
                    }
                    break;
                case 'PermissionDeniedError':
                case 'SecurityError':
                    setErrorMessage(AUDIO_NOTE_PERMISSION_DENIED_ERROR);
                    if (hasRecordingPermissions) {
                        setHasRecordingPermissions(false);
                    }
                    break;
                default:
                    setErrorMessage(AUDIO_NOTE_GENERAL_ERROR);
                    if (hasRecordingPermissions) {
                        setHasRecordingPermissions(false);
                    }
                }
            });
        }
    };

    const stopRecord = (): void => {
        // Modidy recording state
        setStatus(AUDIO_NOTE_RECORDING_STATE.pausedComplete);
        // Notify parent editor that recording is not taking place
        setIsRecordingAudioNote(false);
        setTimeElapsed(0);
        // Stop Recording
        mediaRecorderRef.current?.stop();
        // Stop Updating Recording Timestamp
        setIsUpdatingTime(false);
        // Clear initial elapsed start time timestamp
        setStartTime(null);
        setSavedTime(null);
    };

    const pauseRecord = (): void => {
        setStatus(AUDIO_NOTE_RECORDING_STATE.pausedRecording);
        // Pause Recording
        mediaRecorderRef.current?.pause();
        // Stop Updating Recording Timestamp
        setIsUpdatingTime(false);
        // Collect and save elapsed time
        const difference = computeTimeElapsed();
        setSavedTime(difference);
    };

    const resumeRecord = (): void => {
        setStatus(AUDIO_NOTE_RECORDING_STATE.recording);
        // Resume Recording
        mediaRecorderRef.current?.resume();

        // Update Recording timestamp
        setIsUpdatingTime(true);
        // Cache timestamp started recording
        setStartTime(Date.now());
    };

    const playAudioNote = async (play: boolean): Promise<void> => {
        // Updates timestamps on a regular interval
        if (play && audioNodeRef.current) {
            if (timeElapsed === 0) {
                audioNodeRef.current.currentTime = 0;
            }
            audioNodeRef.current.play()
                .then(async () => {
                    setStatus(AUDIO_NOTE_RECORDING_STATE.playing);
                    // Update Recording timestamp
                    setIsUpdatingTime(true);
                    // Cache timestamp started recording
                    setStartTime(Date.now());

                    if (
                        readOnly
                        && user
                        && postId
                        && currentSessionId
                    ) {
                        // Record user action
                        const actionId = await recordUserAction({
                            type: USER_ACTION_TYPE.playAudioNote,
                            userId: user.id,
                            sessionId: currentSessionId,
                            payload: {
                                id,
                                editorType,
                                postId,
                            },
                        });
                        // Get media item
                        const collection = process.env.NODE_ENV === 'production'
                            ? FIRESTORE_COLLECTION.media
                            : FIRESTORE_COLLECTION.stagingMedia;
                        const db = getFirestore();
                        const mediaItemSnap = await getDoc(doc(db, collection, id));
                        if (mediaItemSnap.exists()) {
                            const mediaItem = mediaItemSnap.data() as IEditorAudioNoteItem;
                            // Increment plays
                            updateAnnotationMediaInDB({
                                collection,
                                id,
                                annotationMediaType: MEDIA_TYPE.audioNote,
                                plays: [
                                    ...mediaItem.plays,
                                    actionId,
                                ],
                            });
                        }
                    }
                })
                .catch(() => {
                    setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
                    setErrorMessage(AUDIO_NOTE_GENERAL_ERROR);
                });
        } else if (!play && audioNodeRef.current) {
            setStatus(AUDIO_NOTE_RECORDING_STATE.pausedComplete);
            audioNodeRef.current.pause();
            // Stop Updating Recording Timestamp
            setIsUpdatingTime(false);
            // Collect and save elapsed time
            if (
                readOnly
                && user
                && postId
                && currentSessionId
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.pauseAudioNote,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        id,
                        editorType,
                        postId,
                    },
                });
            }
        }

        broadcastHeightAdjusted();
    };

    const handleCreateAudioNote = (chunks: Blob[]): void => {
        const blob = new Blob(chunks, mediaRecorderRef.current ? {
            type: mediaRecorderRef.current.mimeType,
        } : undefined);
        // Create audio url
        const noteURL = URL.createObjectURL(blob);

        // Save blob and url
        setAudioBlob(blob);
        setMimeType(blob.type);
        setAudioURL(noteURL);
    };

    const saveAudioNote = (e?: React.MouseEvent | React.TouchEvent): void => {
        if (e) e.nativeEvent.stopPropagation();
        if (!user) throw Error('There was a problem saving audio note. User data not found.');

        // Focus Audio Note
        audioNodeRef.current?.parentElement?.click();
        if (isNewAudioNote && audioBlob) {
            // Prepare File
            const file = new File(
                [audioBlob],
                audioTitle === getDefaultAudioTitle()
                    ? getDefaultAudioTitle()
                    : audioTitle,
                {
                    type: audioBlob.type,
                    lastModified: Date.now(),
                },
            );

            const mediaType = MEDIA_TYPE.audioNote;
            const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
            const mediaBucket = getMediaStorageBucket(mediaType);
            const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
            const fileExtension = mime.extension(file.type);

            let storageEntity: STORAGE_ENTITY;
            if (editorType === EDITOR_CONTEXT_TYPE.post) {
                storageEntity = process.env.NODE_ENV === 'production'
                    ? STORAGE_ENTITY.posts
                    : STORAGE_ENTITY.stagingPosts;
            } else {
                storageEntity = process.env.NODE_ENV === 'development'
                    ? STORAGE_ENTITY.stagingAnnotations
                    : STORAGE_ENTITY.annotations;
            }
            const path = `${storageEntity}/${mediaBucket}/${id}/${fileName}-${uniqueId}.${fileExtension}`;
            const mediaItem: IMediaItem = {
                id,
                userId: user.id,
                file,
                title: audioTitle,
                description: audioDescription,
                filePath: path,
                type: mediaType,
                uploadProgress: 0,
            };
            setUploadingMedia(mediaItem);

            // make db entry of media
            // execute before file upload so upload cloud function has a place to write to
            setMediaInDB({
                mediaItem,
                filePath: path,
            });

            // upload file to cloud storage
            // url will be set by cloud function
            const uploadTask = uploadToCloudStorage(
                file,
                path,
            );

            uploadTask.on(
                'state_changed',
                (snapshot: UploadTaskSnapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadingStateChangeEvent({
                        mediaItem,
                        snapshot,
                        progress,
                    });
                },
                (error: StorageError) => {
                    throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
                },
                () => {
                    setUploadingCompleteEvent({
                        mediaItem,
                        storageRef: uploadTask.snapshot.ref,
                    });
                },
            );

            // Turn of isEditing and new audio note
            setIsEditing(false);

            // Set no not new audio note
            setIsNewAudioNote(false);
        } else if (filePath) {
            // Update DB
            const collection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.media
                : FIRESTORE_COLLECTION.stagingMedia;
            updateAnnotationMediaInDB({
                collection,
                id,
                annotationMediaType: MEDIA_TYPE.audioNote,
                description,
                title,
            });

            // Update Existing Audio Note
            updateAudioNote({
                id,
                title: audioTitle,
                uploaded: audioUploaded,
                description: audioDescription,
                filePath,
            });

            // Turn of isEditing
            setIsEditing(false);

            broadcastHeightAdjusted();
        }
    };

    const discardAudioNote = (): void => {
        if (
            audioNodeRef.current
            && audioNodeRef.current.duration > 0
            && !audioNodeRef.current.paused
        ) {
            // Pause music if playing when discard
            audioNodeRef.current.pause();
        }
        // Make sure to revoke the data uris to avoid memory leaks
        // Set when we use temporary URL from blob
        if (audioURL) {
            URL.revokeObjectURL(audioURL);
        }
        setIsNewAudioNote(true);
        setStatus(AUDIO_NOTE_RECORDING_STATE.idle);
        setAudioChunks([]);
        setAudioLength(0);
        setAudioBlob(null);
        setAudioURL(null);
        setTimeElapsed(0);
        setSavedTime(null);
        setStartTime(null);
        setPotentialElapsed(null);
        setIsUpdatingTime(false);
        setIsEditing(true);
        setAudioTitle('');
        setAudioDescription('');
        setAudioUploaded(false);
        setMimeType('');
        setPrevIsRecordingAudioNote(null);

        broadcastHeightAdjusted();
    };

    const importAudioNote = (): void => {
        openFileSelector();
    };

    const computeTimeElapsed = (): number => {
        // Collect and save elapsed time
        // We are likely recording audio, so need to time it the elapsed time
        let currentTime = Date.now();
        if (
            status === AUDIO_NOTE_RECORDING_STATE.playing
            && audioLength === 0
            && startTime
        ) {
            // We havent loaded track data yet
            currentTime = startTime;
        } else if (
            (
                status === AUDIO_NOTE_RECORDING_STATE.playing
                || status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
            )
            && audioLength > 0
            && audioNodeRef.current
            && startTime
        ) {
            // We have track data, so compute normally
            currentTime = (audioNodeRef.current.currentTime * MILLISECONDS_IN_A_SECOND) + startTime;
        }

        let difference;
        if (
            status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
            && startTime
        ) {
            difference = currentTime - startTime;
        } else if (savedTime && startTime) {
            // If we've paused, factor in previous time
            difference = (currentTime - startTime) + savedTime;
        } else {
            // Ternary makes sure that we don't begin with a large
            // timestamp
            difference = startTime ? currentTime - startTime : 0;
        }
        return difference;
    };

    const formatTimestamp = (timestamp: number): string => {
        if (
            Number.isNaN(timestamp)
            || timestamp === Infinity
        ) {
            // If invalid value, return no time
            return '00:00';
        }

        const timeInMilliseconds = timestamp / playbackSpeed;
        // const days = Math.floor(timeInMilliseconds / MILLISECONDS_IN_A_DAY);
        const hours = timestamp < 0
            ? Math.round((timeInMilliseconds % MILLISECONDS_IN_A_DAY) / MILLISECONDS_IN_AN_HOUR)
            : Math.floor((timeInMilliseconds % MILLISECONDS_IN_A_DAY) / MILLISECONDS_IN_AN_HOUR);
        const minutes = timestamp < 0
            ? Math.round((timeInMilliseconds % MILLISECONDS_IN_AN_HOUR) / MILLISECONDS_IN_A_MINUTE)
            : Math.floor((timeInMilliseconds % MILLISECONDS_IN_AN_HOUR) / MILLISECONDS_IN_A_MINUTE);
        const seconds = timestamp < 0
            ? Math.round((timeInMilliseconds % MILLISECONDS_IN_A_MINUTE) / MILLISECONDS_IN_A_SECOND)
            : Math.floor((timeInMilliseconds % MILLISECONDS_IN_A_MINUTE) / MILLISECONDS_IN_A_SECOND);

        let hoursText;
        if (Math.abs(hours) < 10) {
            const isNegative = timestamp < 0;
            hoursText = `${isNegative ? '-' : ''}0${Math.abs(hours)}`;
        } else {
            const isNegative = timestamp < 0;
            hoursText = `${isNegative ? '-' : ''}${Math.abs(hours)}`;
        }

        let minutesText;
        if (Math.abs(minutes) < 10) {
            const isNegative = timestamp < 0 && hours === 0;
            minutesText = `${isNegative ? '-' : ''}0${Math.abs(minutes)}`;
        } else {
            const isNegative = timestamp < 0 && hours === 0;
            minutesText = `${isNegative ? '-' : ''}${Math.abs(minutes)}`;
        }

        let secondsText = `${Math.abs(seconds)}`;
        if (Math.abs(seconds) < 10) {
            secondsText = `0${Math.abs(seconds)}`;
        }

        return `${Math.abs(hours) > 0 ? `${hoursText}:` : ''}${minutesText}:${secondsText}`;
    };

    const moveScrubber = async (e: React.MouseEvent): Promise<void> => {
        const target = e.target as HTMLElement;
        const { id: scrubberId } = target;
        let targetRect = target.getBoundingClientRect();
        const clickPosX = e.clientX;

        // If we clicked the Scrubber, and not Media Progress element
        if (
            audioURL
            && (
                scrubberId === PROGRESS_SCRUBBER_ID(audioURL)
                || scrubberId === LEFT_TIME_FIGURE_ID(audioURL)
                || scrubberId === RIGHT_TIME_FIGURE_ID(audioURL)
            )
        ) {
            // We need to get .right of parent element
            const parentTarget = target.parentElement;
            if (parentTarget) targetRect = parentTarget.getBoundingClientRect();
        }

        const lengthLeftSideTarget = clickPosX - targetRect.left;
        const totalLengthTarget = targetRect.right - targetRect.left;
        const percent = lengthLeftSideTarget / totalLengthTarget;
        const elapsed = percent * audioLength;

        if (audioNodeRef.current) {
            audioNodeRef.current.currentTime = +(percent * audioNodeRef.current.duration).toFixed(6);
        }

        if (status === AUDIO_NOTE_RECORDING_STATE.playing) {
            // if was playing, continue playing
            playAudioNote(true);
        } else {
            setTimeElapsed(elapsed);
        }

        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.scrubAudioNoteProgressBar,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    editorType,
                    postId,
                },
            });
        }
    };

    const getIdleMessage = (): string => {
        if (errorMessage) {
            return errorMessage;
        }

        if (!readOnly) {
            return 'Record/Upload Audio';
        }

        return 'No audio available.';
    };

    const handleProgressMouseEnter = (e: React.MouseEvent): void => {
        const target = e.target as HTMLElement;
        const { id: scrubberId } = target;
        let targetRect = target.getBoundingClientRect();
        const targetPosX = e.clientX;

        // If we clicked the Scrubber, and not Media Progress element
        if (
            audioURL
            && (
                scrubberId === PROGRESS_SCRUBBER_ID(audioURL)
                || scrubberId === POTENTIAL_SCRUBBER_ID(audioURL)
                || scrubberId === LEFT_TIME_FIGURE_ID(audioURL)
                || scrubberId === RIGHT_TIME_FIGURE_ID(audioURL)
            )
        ) {
            // We need to get .right of parent element
            const parentTarget = target.parentElement;
            if (parentTarget) targetRect = parentTarget.getBoundingClientRect();
        }
        const lengthLeftSideTarget = targetPosX - targetRect.left;
        const totalLengthTarget = targetRect.right - targetRect.left;
        const percent = lengthLeftSideTarget / totalLengthTarget;
        const potential = percent * audioLength;

        setPotentialElapsed(potential);
    };

    const handleProgressMouseLeave = (): void => {
        clearDelayContractProgressIndicator();
        delayContractProgressIndicator();
    };

    const {
        start: delayContractProgressIndicator,
        clear: clearDelayContractProgressIndicator,
    } = useTimeout(() => {
        setPotentialElapsed(null);
    }, PROGRESS_INDICATOR_DELAY_DURATION);

    const handleTitleChange = (): void => {
        if (titleInputRef.current) setAudioTitle(titleInputRef.current.value);
    };

    const onTitleInputMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onTitleInputMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onTitleInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onTitleInputBlur = (): void => {
        setInputFocused(false);
    };

    const onDescriptionTextareaMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onDescriptionTextareaMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onDescriptionTextareaFocus = (): void => {
        setInputFocused(true);
        setDescriptionInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onDescriptionTextareaBlur = (): void => {
        setInputFocused(false);
        setDescriptionInputFocused(false);
    };

    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.editorElementButton,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onContainerMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter && !AUDIO_NOTE_EMPTY) {
            onCursorEnter(
                CURSOR_TARGET.editorElement,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onContainerMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onTranscriptWordMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.audioNoteTranscriptWord,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onTranscriptWordMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onProgressBarMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.editorElement,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onProgressBarMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const handleDownload = (e: React.MouseEvent | React.TouchEvent): void => {
        e.nativeEvent.stopPropagation();
        if (audioBlob) {
            const link = document.createElement('a');
            link.download = `${audioTitle}.${mime.extension(mimeType)}`;
            const tempURL = URL.createObjectURL(audioBlob);
            link.href = tempURL;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Set when we use temporary URL from blob
            URL.revokeObjectURL(tempURL);
        }
    };

    const handleSkip = async (direction: AUDIO_NOTE_SKIP_DIRECTION): Promise<void> => {
        const fifteenSeconds = 15 * MILLISECONDS_IN_A_SECOND;
        let elapsed = timeElapsed;
        if (
            audioLength
            && audioNodeRef.current
            && direction === AUDIO_NOTE_SKIP_DIRECTION.forward
        ) {
            elapsed = elapsed + fifteenSeconds > audioLength
                ? audioLength
                : elapsed + fifteenSeconds;
            audioNodeRef.current.currentTime = audioNodeRef.current.currentTime + 15 > audioNodeRef.current.duration
                ? audioNodeRef.current.duration
                : audioNodeRef.current.currentTime + 15;
        } else if (
            audioNodeRef.current
            && direction === AUDIO_NOTE_SKIP_DIRECTION.backward
        ) {
            elapsed = elapsed - fifteenSeconds < 0
                ? 0
                : elapsed - fifteenSeconds;
            audioNodeRef.current.currentTime = audioNodeRef.current.currentTime - 15 < 0
                ? 0
                : audioNodeRef.current.currentTime - 15;
        }

        if (
            audioNodeRef.current
            && status === AUDIO_NOTE_RECORDING_STATE.playing
        ) {
            // if was playing, continue playing
            audioNodeRef.current.play();
        } else {
            // Modify timestamp
            setTimeElapsed(elapsed);
        }

        if (
            readOnly
            && user
            && postId
            && currentSessionId
            && direction === AUDIO_NOTE_SKIP_DIRECTION.forward
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.skipForwardAudioNote,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    editorType,
                    postId,
                },
            });
        } else if (
            readOnly
            && user
            && postId
            && currentSessionId
            && direction === AUDIO_NOTE_SKIP_DIRECTION.backward
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.skipBackwardAudioNote,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    editorType,
                    postId,
                },
            });
        }
    };

    const handlePlaybackSpeed = async (
        speed: number,
        e: React.MouseEvent | React.TouchEvent,
    ): Promise<void> => {
        e.nativeEvent.stopPropagation();
        if (
            audioNodeRef.current
            && speed !== playbackSpeed
        ) {
            setPlaybackSpeed(speed);
            audioNodeRef.current.playbackRate = speed;

            if (
                readOnly
                && user
                && postId
                && currentSessionId
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.adjustAudioNotePlaybackSpeed,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        id,
                        speed,
                        editorType,
                        postId,
                    },
                });
            }
        }
    };

    const supportsAudioType = (type: string): boolean => {
        // "probably" : The media type appears to be playable
        // "maybe": Cannot tell if the media type is playable without playing it
        // "": The media type is not playable
        let audio;

        if (!audio) {
            audio = document.createElement('audio');
        }

        return audio.canPlayType(type) === 'probably' || audio.canPlayType(type) === 'maybe';
    };

    const handleModifyDescriptionInputWidth = (): void => {
        if (descriptionNodeRef.current && isEditing) {
            const rect = descriptionNodeRef.current.getBoundingClientRect();
            if (parentRef) {
                setDescriptionPos({
                    top: rect.top - parentRef.getBoundingClientRect().top,
                    left: rect.left - parentRef.getBoundingClientRect().left,
                });
            }
            setDescriptionWidth(descriptionNodeRef.current.clientWidth);
        }
    };

    const handleModifyTitleInputWidth = (): void => {
        if (titleNodeRef.current && isEditing) {
            const rect = titleNodeRef.current.getBoundingClientRect();
            if (parentRef) {
                setTitlePos({
                    x: rect.left - parentRef.getBoundingClientRect().left,
                    y: rect.top - parentRef.getBoundingClientRect().top,
                });
            }
            setTitleWidth(titleNodeRef.current.clientWidth);
        }
    };

    const handleViewportResize = (): void => {
        // Modify width of Title Input
        handleModifyTitleInputWidth();
        // Modify width of Description Input
        handleModifyDescriptionInputWidth();
    };

    const handleUploadingMediaComplete = (
        mediaItem: IMediaItem,
        _fileURL: string,
    ): void => {
        removeUploadingMedia(mediaItem.id);

        if (audioURL) {
            // Revoke local url
            // Set when we use temporary URL from blob
            URL.revokeObjectURL(audioURL);
        }

        if (audioNodeRef.current?.parentElement) {
            // Focus Audio Note so that it applies
            // update to it
            audioNodeRef.current.parentElement.click();
        }

        if (saveOutstandingAudioNotes && decrementUnsavedAudioNotes) {
            // If audio note was unsaved but we received a signal from parent
            // editor to save
            decrementUnsavedAudioNotes();
        }

        // Update Audio Note
        updateAudioNote({
            id,
            title: audioTitle,
            uploaded: audioUploaded,
            description: audioDescription,
            filePath: mediaItem.filePath,
        });
    };

    const handleUploadingMediaStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingMedia.has(mediaItem.id)) {
            updateUploadingMedia({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...uploadingMedia.get(mediaItem.id)!,
                uploadProgress: progress,
            });
        } else {
            setUploadingMedia(mediaItem);
        }
    };

    const toggleTranscript = async (e: React.MouseEvent | React.TouchEvent): Promise<void> => {
        e.nativeEvent.stopPropagation();
        setShowTranscript(!showTranscript);

        // Removes Cursor Lock caused by Small Cursor on Buttons
        onCursorLeave();

        if (readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Show Transcript User Action
            recordUserAction({
                type: USER_ACTION_TYPE.toggleAudioNoteTranscript,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    visible: !showTranscript,
                    editorType,
                    postId,
                },
            });
        }

        broadcastHeightAdjusted();
    };

    const skipTranscriptWord = (word: ITranscriptionWord): void => {
        let timestamp = (MILLISECONDS_IN_A_SECOND * parseInt(word.startTime.seconds, 10))
            + (MILLISECONDS_IN_A_SECOND * word.startTime.nanos) / NANOSECONDS_IN_A_SECOND;

        if (audioLength && timestamp > audioLength) timestamp = audioLength;
        if (timestamp < 0) timestamp = 0;

        if (audioNodeRef.current) {
            audioNodeRef.current.currentTime = timestamp / MILLISECONDS_IN_A_SECOND; // must be in seconds
        }

        if (
            audioNodeRef.current
            && status === AUDIO_NOTE_RECORDING_STATE.playing
        ) {
            // if was playing, continue playing
            audioNodeRef.current.play();
        } else {
            // Modify timestamp
            setTimeElapsed(timestamp);
        }

        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.scrubAudioNoteTranscriptWord,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    word,
                    editorType,
                    postId,
                },
            });
        }
    };

    // ===== Memoization =====

    /**
     * Indicates whether the audio note has an audio file
     * Important: audioURL is also a proxy for fileURL which an empty
     * value also implies no audio file
     * We use audioURL instead of fileURL to be able to handle case
     * where we have the fileURL but are unable to retreive the audioURL
     * In such a case we set audioURL to null
     */
    const AUDIO_NOTE_EMPTY = useMemo(() => readOnly && !audioURL, [
        readOnly,
        audioURL,
    ]);

    // ===== Rendering =====

    // We subtract 10px because we have more than one button
    // Therefore they are stacked vertically
    const SMALL_ACTION_BUTTON_LENGTH = useMemo(() => buttonLength - 10, [buttonLength]);
    const propagateBackground = useMemo(() => !isEditing && status !== AUDIO_NOTE_RECORDING_STATE.idle, [
        isEditing,
        status,
    ]);
    const actionsVertical = useMemo(() => isEditing
    && !readOnly
    && (
        status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
        || status === AUDIO_NOTE_RECORDING_STATE.playing
    ), [
        isEditing,
        readOnly,
        status,
    ]);

    let Icon;
    let primaryColor = 'white';
    let handleClick;
    switch (status) {
    case AUDIO_NOTE_RECORDING_STATE.pausedComplete:
        handleClick = (e: React.MouseEvent | React.TouchEvent) => {
            e.nativeEvent.stopPropagation();
            playAudioNote(true);
        };
        Icon = PlayIcon;
        break;
    case AUDIO_NOTE_RECORDING_STATE.pausedRecording:
        handleClick = (e: React.MouseEvent | React.TouchEvent) => {
            e.nativeEvent.stopPropagation();
            resumeRecord();
        };
        break;
    case AUDIO_NOTE_RECORDING_STATE.playing:
        handleClick = (e: React.MouseEvent | React.TouchEvent) => {
            e.nativeEvent.stopPropagation();
            playAudioNote(false);
        };
        Icon = PauseIcon;
        break;
    case AUDIO_NOTE_RECORDING_STATE.recording:
        handleClick = (e: React.MouseEvent | React.TouchEvent) => {
            e.nativeEvent.stopPropagation();
            pauseRecord();
        };
        Icon = PauseIcon;
        primaryColor = 'red';
        break;
    default:
        handleClick = (e: React.MouseEvent | React.TouchEvent) => {
            e.nativeEvent.stopPropagation();
            startRecord();
        };
        Icon = MicrophoneIcon;
        break;
    }

    // Recording
    let numActions = 1;
    let captionMinWidth = `calc(100% - ${isAuthor
        ? buttonLength + ACTION_CONTAINER_PADDING_LEFT // Download Button
        : 0
    }px)`;

    if (status === AUDIO_NOTE_RECORDING_STATE.idle && readOnly && !filePath) {
        captionMinWidth = '100%';
    } else if (status === AUDIO_NOTE_RECORDING_STATE.idle) {
        captionMinWidth = `calc(100% - ${buttonLength + ACTION_CONTAINER_PADDING_LEFT}px)`; // Import Button
    } else if (
        !readOnly
        && (
            status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
            || status === AUDIO_NOTE_RECORDING_STATE.playing
        )
    ) {
        numActions = isEditing ? 3 : 3;
        if (audioNoteMediaItem && audioNoteMediaItem?.transcript) numActions += 1;
        captionMinWidth = `calc(100% - ${isEditing
            ? SMALL_ACTION_BUTTON_LENGTH
            : (numActions * SMALL_ACTION_BUTTON_LENGTH) + ACTION_CONTAINER_PADDING_LEFT + ACTION_CONTAINER_PADDING_RIGHT
        }px)`;
    } else if (
        readOnly
        && (
            status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
            || status === AUDIO_NOTE_RECORDING_STATE.playing
        )
    ) {
        numActions = isAuthor ? 2 : 1; // If author, we have a download audio button
        if (audioNoteMediaItem && audioNoteMediaItem?.transcript) numActions += 1;
        captionMinWidth = `calc(100% - ${(numActions * SMALL_ACTION_BUTTON_LENGTH) + ACTION_CONTAINER_PADDING_LEFT + ACTION_CONTAINER_PADDING_RIGHT}px)`;
    }

    let caption = (
        <ContentText
            small={small && status !== AUDIO_NOTE_RECORDING_STATE.recording}
            fontSize={DESCRIPTION_FONT_SIZE}
            buttonLength={buttonLength}
            statusIconMarginLeft={STATUS_ICON_MARGIN_LEFT}
            numButtons={status === AUDIO_NOTE_RECORDING_STATE.recording
                || status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                ? 2
                : 1}
            playbackContainerPaddingLeft={PLAYBACK_CONTAINER_PADDING_LEFT}
            {...(status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                ? {
                    color: themeObj.verascopeColor.red200,
                }
                : {}
            )}
        >
            <RecordingLoader small={small} />
        </ContentText>
    );

    if (status === AUDIO_NOTE_RECORDING_STATE.idle) {
        caption = (
            <ContentText
                small={small}
                fontSize={DESCRIPTION_FONT_SIZE}
                buttonLength={buttonLength}
                numButtons={AUDIO_NOTE_EMPTY ? 0 : 1} // not recording or pausedRecording
                statusIconMarginLeft={STATUS_ICON_MARGIN_LEFT}
                playbackContainerPaddingLeft={PLAYBACK_CONTAINER_PADDING_LEFT}
            >
                {getIdleMessage()}
            </ContentText>
        );
    } else if (
        status === AUDIO_NOTE_RECORDING_STATE.playing
        || status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
    ) {
        caption = (
            <DetailsContainer
                maxTimestampWidth={MAX_TIMESTAMP_WIDTH}
                rightTimeFigureVisible={
                    !readOnly
                    && !(
                        status === AUDIO_NOTE_RECORDING_STATE.playing
                        || (
                            status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                            && timeElapsed > 0
                        )
                    )
                }
            >
                {isEditing ? (
                    <>
                        {createPortal(
                            <TitleInput
                                type="text"
                                id={TITLE_INPUT_ID(audioURL)}
                                className={HOVER_TARGET_CLASSNAME}
                                ref={titleInputRef}
                                defaultValue={audioTitle}
                                placeholder="Audio Note Title"
                                top={titlePos.y}
                                left={titlePos.x}
                                width={titleWidth}
                                fontSize={TITLE_FONT_SIZE}
                                onChange={handleTitleChange}
                                onMouseEnter={onTitleInputMouseEnter}
                                onMouseLeave={onTitleInputMouseLeave}
                                onFocus={onTitleInputFocus}
                                onBlur={onTitleInputBlur}
                            />,
                            titleTarget,
                        )}
                        <TitleInputNode
                            type="text"
                            ref={titleNodeRef}
                            value={audioTitle}
                            placeholder="Audio Note Title"
                            fontSize={TITLE_FONT_SIZE}
                        />
                    </>
                ) : (
                    <Title
                        fontSize={TITLE_FONT_SIZE}
                    >
                        {audioTitle.length > 0
                            ? audioTitle
                            : 'Untitled'}
                    </Title>
                )}
                {isEditing ? (
                    <>
                        {createPortal(
                            <DescriptionInput
                                id={DESCRIPTION_INPUT_ID(audioURL)}
                                className={HOVER_TARGET_CLASSNAME}
                                ref={descriptionInputRef}
                                defaultValue={audioDescription}
                                data-placeholder="Description..."
                                contentEditable="true"
                                top={descriptionPos.top}
                                left={descriptionPos.left}
                                width={descriptionWidth}
                                fontSize={DESCRIPTION_FONT_SIZE}
                                onMouseEnter={onDescriptionTextareaMouseEnter}
                                onMouseLeave={onDescriptionTextareaMouseLeave}
                                onFocus={onDescriptionTextareaFocus}
                                onBlur={onDescriptionTextareaBlur}
                            />,
                            descriptionTarget,
                        )}
                        <DescriptionInputNode
                            ref={descriptionNodeRef}
                            contentEditable="true"
                            data-placeholder="Description..."
                            fontSize={DESCRIPTION_FONT_SIZE}
                        />
                    </>
                ) : (
                    audioDescription.length > 0 && (
                        <Description
                            fontSize={DESCRIPTION_FONT_SIZE}
                        >
                            {audioDescription}
                        </Description>
                    )
                )}
                {(
                    status === AUDIO_NOTE_RECORDING_STATE.playing
                    || (
                        status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                        && timeElapsed > 0
                    )
                ) && (
                    <ProgressBarContainer
                        onMouseEnter={handleProgressMouseEnter}
                        onMouseMove={handleProgressMouseEnter}
                        onMouseLeave={handleProgressMouseLeave}
                        onClick={moveScrubber}
                    >
                        <ProgressBar
                            id={PROGRESS_INDICATOR_ID(audioURL)}
                            className={HOVER_TARGET_CLASSNAME}
                            onMouseEnter={onProgressBarMouseEnter}
                            onMouseLeave={onProgressBarMouseLeave}
                        >
                            <ProgressScrubber
                                id={PROGRESS_SCRUBBER_ID(audioURL)}
                                color={color}
                                width={audioNodeRef.current
                                    ? 100 * (timeElapsed / audioLength)
                                    : 0}
                            />
                            <PotentialScrubber
                                id={POTENTIAL_SCRUBBER_ID(audioURL)}
                                width={audioNodeRef.current && potentialElapsed
                                    ? 100 * (potentialElapsed / audioLength)
                                    : 0}
                            />
                        </ProgressBar>
                        <ProgressTimeContainer>
                            <TimerFigure
                                id={LEFT_TIME_FIGURE_ID(audioURL)}
                                color={color}
                                small={!!small}
                                potentialElapsed={!!potentialElapsed}
                                width={MAX_TIMESTAMP_WIDTH}
                            >
                                {formatTimestamp(potentialElapsed || timeElapsed)}
                            </TimerFigure>
                            <TimerFigure
                                right
                                small={!!small}
                                id={RIGHT_TIME_FIGURE_ID(audioURL)}
                                width={MAX_TIMESTAMP_WIDTH}
                            >
                                {formatTimestamp(potentialElapsed
                                    ? potentialElapsed - audioLength
                                    : timeElapsed - audioLength)}
                            </TimerFigure>
                        </ProgressTimeContainer>
                    </ProgressBarContainer>
                )}
            </DetailsContainer>
        );
    } else if (status === AUDIO_NOTE_RECORDING_STATE.pausedRecording) {
        caption = (
            <ContentText
                small={small}
                fontSize={DESCRIPTION_FONT_SIZE}
                buttonLength={buttonLength}
                numButtons={2} // Is either recording or pausedRecording
                statusIconMarginLeft={STATUS_ICON_MARGIN_LEFT}
                playbackContainerPaddingLeft={PLAYBACK_CONTAINER_PADDING_LEFT}
                {...(status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                    ? {
                        color: themeObj.verascopeColor.red200,
                    }
                    : {}
                )}
            >
                Paused
            </ContentText>
        );
    }

    const currentTimeStamp = AUDIO_NOTE_RECORDING_STATE.pausedComplete && timeElapsed > 0
        ? timeElapsed
        : audioLength;

    const transcriptWords = audioNoteMediaItem?.transcript?.data.reduce((words: any[], phrase: any) => (
        [
            ...words,
            ...phrase.alternatives[0].words,
        ]
    ), []);

    const transcriptButtonColor = (!!audioNoteMediaItem?.transcript && showTranscript && color) || undefined;

    const actionsContainerMaxHeight = (
        (
            (
                status === AUDIO_NOTE_RECORDING_STATE.playing
                || (
                    status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                    && timeElapsed > 0
                )
            ) && (130 + 2 * CONTAINER_PADDING_AUDIO_NOTE)
        ) || (buttonLength + 2 * CONTAINER_PADDING_AUDIO_NOTE)
    );

    return (
        <Container
            {...attributes}
            className={HOVER_TARGET_CLASSNAME}
            color={color}
            small={small}
            focused={focused}
            selected={selected}
            fixedWidth={fixedWidth}
            descriptionInputFocused={descriptionInputFocused}
            padding={CONTAINER_PADDING_AUDIO_NOTE}
            onMouseEnter={onContainerMouseEnter}
            onMouseLeave={onContainerMouseLeave}
            // Don't add 'contentEditable={false}' while editing because it prevents backspace to delete
            // Necessary to avoid: Cannot resolve a Slate point from DOM point
            // Reference: https://github.com/ianstormtaylor/slate/issues/3930
            contentEditable={!readOnly}
        >
            <ContentContainer
                leftAlign={
                    status === AUDIO_NOTE_RECORDING_STATE.idle
                    || status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                    || status === AUDIO_NOTE_RECORDING_STATE.recording
                }
                width={captionMinWidth}
            >
                <PlaybackContainer
                    paddingLeft={PLAYBACK_CONTAINER_PADDING_LEFT}
                    hasProgressBar={
                        timeElapsed > 0
                        && (
                            status !== AUDIO_NOTE_RECORDING_STATE.recording
                            && status !== AUDIO_NOTE_RECORDING_STATE.pausedRecording
                        )
                    }
                    audioNoteEmpty={AUDIO_NOTE_EMPTY}
                >
                    <SkipButton
                        top
                        className={HOVER_TARGET_CLASSNAME}
                        isPlaying={status === AUDIO_NOTE_RECORDING_STATE.playing}
                        show={(status === AUDIO_NOTE_RECORDING_STATE.playing
                        || (
                            status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                            && timeElapsed > 0
                        )) && timeElapsed + (15 * MILLISECONDS_IN_A_SECOND) < audioLength}
                        width={buttonLength}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        onClick={() => handleSkip(AUDIO_NOTE_SKIP_DIRECTION.forward)}
                    >
                        <Tooltip
                            text="Skip Forward"
                            side={TOOLTIP_TYPE.left}
                        />
                        <ReactSVG
                            src={SkipForwardIcon}
                        />
                    </SkipButton>
                    <PlaybackButtons
                        status={status}
                        buttonLength={buttonLength}
                        noPermissions={!hasRecordingPermissions}
                    >
                        {(!readOnly || filePath)
                        && (
                            <StatusIconContainer
                                className={HOVER_TARGET_CLASSNAME}
                                width={buttonLength}
                                color={themeObj.color[primaryColor]}
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                onMouseDown={handleClick}
                            >
                                {status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                                    ? <RecordingIcon />
                                    : (
                                        <ReactSVG
                                            src={Icon}
                                        />
                                    )}
                            </StatusIconContainer>
                        )}
                        {(
                            status === AUDIO_NOTE_RECORDING_STATE.recording
                            || status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                        ) && (
                            <StatusIconContainer
                                marginLeft={STATUS_ICON_MARGIN_LEFT}
                                className={HOVER_TARGET_CLASSNAME}
                                width={buttonLength}
                                color={themeObj.color[primaryColor]}
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                onClick={stopRecord}
                            >
                                <ReactSVG
                                    src={StopIcon}
                                />
                            </StatusIconContainer>
                        )}
                    </PlaybackButtons>
                    <SkipButton
                        bottom
                        className={HOVER_TARGET_CLASSNAME}
                        isPlaying={status === AUDIO_NOTE_RECORDING_STATE.playing}
                        show={(status === AUDIO_NOTE_RECORDING_STATE.playing
                        || (
                            status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                            && timeElapsed > 0
                        ))}
                        width={buttonLength}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        onClick={() => handleSkip(AUDIO_NOTE_SKIP_DIRECTION.backward)}
                    >
                        <Tooltip
                            text="Skip Backward"
                            side={TOOLTIP_TYPE.left}
                        />
                        <ReactSVG
                            src={SkipBackwardIcon}
                        />
                    </SkipButton>
                </PlaybackContainer>
                {/* This is hide the inputs to add audio title and description */}
                {caption}
                {(
                    (
                        status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                        && timeElapsed === 0
                        && audioLength > 0
                    ) || status === AUDIO_NOTE_RECORDING_STATE.recording
                    || status === AUDIO_NOTE_RECORDING_STATE.pausedRecording
                ) && (
                    <TimerFigure
                        center
                        small={!!small}
                        recording={
                            status === AUDIO_NOTE_RECORDING_STATE.recording
                        }
                        width={MAX_TIMESTAMP_WIDTH}
                    >
                        {status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                            ? formatTimestamp(potentialElapsed || currentTimeStamp)
                            : formatTimestamp(potentialElapsed || timeElapsed)}
                    </TimerFigure>
                )}
            </ContentContainer>
            {(
                status === AUDIO_NOTE_RECORDING_STATE.idle
                || status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                || status === AUDIO_NOTE_RECORDING_STATE.playing
            ) && (
                <ActionsContainer
                    {...(status === AUDIO_NOTE_RECORDING_STATE.idle
                        ? {
                            onClick: getRootProps({
                                onClick: (e) => {
                                    e.nativeEvent.stopPropagation();
                                    openFileSelector();
                                },
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            }).onClick,
                            onMouseEnter: getRootProps({
                                onClick: (e) => {
                                    e.nativeEvent.stopPropagation();
                                    openFileSelector();
                                },
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            }).onMouseEnter,
                            onMouseLeave: getRootProps({
                                onClick: (e) => {
                                    e.nativeEvent.stopPropagation();
                                    openFileSelector();
                                },
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            }).onMouseLeave,
                        }
                        : {}
                    )}
                    numActions={numActions}
                    vertical={actionsVertical}
                    propagateBackground={propagateBackground}
                    buttonLength={SMALL_ACTION_BUTTON_LENGTH}
                    isIdle={status === AUDIO_NOTE_RECORDING_STATE.idle}
                    paddingLeft={ACTION_CONTAINER_PADDING_LEFT}
                    paddingRight={ACTION_CONTAINER_PADDING_RIGHT}
                    maxHeight={showTranscript
                        ? `${actionsContainerMaxHeight}px`
                        : 'none'}
                >
                    {status !== AUDIO_NOTE_RECORDING_STATE.idle
                    && !isEditing
                    && (
                        <OptionsMenuContainer>
                            <OptionsMenu
                                center
                                className={HOVER_TARGET_CLASSNAME}
                                revealAbove={revealOptionsAbove}
                                buttonIcon={ClockIcon}
                                buttonHeight={SMALL_ACTION_BUTTON_LENGTH}
                                childItemHeight={PLAYBACK_DROPDOWN_ITEM_HEIGHT}
                                childItemWidth={PLAYBACK_DROPDOWN_ITEM_WIDTH}
                                parentRef={audioNodeRef.current
                                    ? audioNodeRef.current.parentElement
                                    : null}
                                tooltipText="Playback Speed"
                                tooltipSideType={TOOLTIP_TYPE.bottom}
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                onToggle={(visible: boolean) => setHidePlaybackSpeed(visible)}
                            >
                                <Button
                                    center
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={playbackSpeed === 2
                                        ? BUTTON_TYPE.solid
                                        : BUTTON_TYPE.secret}
                                    height={PLAYBACK_DROPDOWN_ITEM_HEIGHT}
                                    width={PLAYBACK_DROPDOWN_ITEM_WIDTH}
                                    text="2"
                                    {...(playbackSpeed === 2 && color
                                        ? {
                                            background: setColorLightness(
                                                color,
                                                BUTTON_CONTAINER_LIGHTNESS_VALUE,
                                            ),
                                        } : {})}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => handlePlaybackSpeed(2, e),
                                    } : {
                                        onMouseDown: (e) => handlePlaybackSpeed(2, e),
                                    })}
                                />
                                <Button
                                    center
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={playbackSpeed === 1.5
                                        ? BUTTON_TYPE.solid
                                        : BUTTON_TYPE.secret}
                                    height={PLAYBACK_DROPDOWN_ITEM_HEIGHT}
                                    width={PLAYBACK_DROPDOWN_ITEM_WIDTH}
                                    text="1.5"
                                    {...(playbackSpeed === 1.5 && color
                                        ? {
                                            background: setColorLightness(
                                                color,
                                                BUTTON_CONTAINER_LIGHTNESS_VALUE,
                                            ),
                                        } : {})}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => handlePlaybackSpeed(1.5, e),
                                    } : {
                                        onMouseDown: (e) => handlePlaybackSpeed(1.5, e),
                                    })}
                                />
                                <Button
                                    center
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={playbackSpeed === 1
                                        ? BUTTON_TYPE.solid
                                        : BUTTON_TYPE.secret}
                                    height={PLAYBACK_DROPDOWN_ITEM_HEIGHT}
                                    width={PLAYBACK_DROPDOWN_ITEM_WIDTH}
                                    text="1"
                                    {...(playbackSpeed === 1 && color
                                        ? {
                                            background: setColorLightness(
                                                color,
                                                BUTTON_CONTAINER_LIGHTNESS_VALUE,
                                            ),
                                        } : {})}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => handlePlaybackSpeed(1, e),
                                    } : {
                                        onMouseDown: (e) => handlePlaybackSpeed(1, e),
                                    })}
                                />
                                <Button
                                    center
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={playbackSpeed === 0.5
                                        ? BUTTON_TYPE.solid
                                        : BUTTON_TYPE.secret}
                                    height={PLAYBACK_DROPDOWN_ITEM_HEIGHT}
                                    width={PLAYBACK_DROPDOWN_ITEM_WIDTH}
                                    text="0.5"
                                    {...(playbackSpeed === 0.5 && color
                                        ? {
                                            background: setColorLightness(
                                                color,
                                                BUTTON_CONTAINER_LIGHTNESS_VALUE,
                                            ),
                                        } : {})}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => handlePlaybackSpeed(0.5, e),
                                    } : {
                                        onMouseDown: (e) => handlePlaybackSpeed(0.5, e),
                                    })}
                                />
                            </OptionsMenu>
                            <CurrentPlaybackSpeedText
                                visible={!hidePlaybackSpeed}
                            >
                                {`${playbackSpeed}x`}
                            </CurrentPlaybackSpeedText>
                        </OptionsMenuContainer>
                    )}
                    {!isNewAudioNote
                    && audioNoteMediaItem?.transcript
                    && (
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={showTranscript
                            && audioNoteMediaItem?.transcript
                                ? BUTTON_TYPE.solid
                                : BUTTON_TYPE.secret}
                            height={SMALL_ACTION_BUTTON_LENGTH}
                            background={transcriptButtonColor}
                            icon={TextIcon}
                            tooltip={{
                                active: true,
                                text: 'Transcript',
                                side: TOOLTIP_TYPE.bottom,
                            }}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            {...(detectTouchDevice(document) ? {
                                onTouchStart: toggleTranscript,
                            } : {
                                onMouseDown: toggleTranscript,
                            })}
                        />
                    )}
                    {readOnly
                    && isAuthor
                    && audioURL
                    && (
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.secret}
                            height={SMALL_ACTION_BUTTON_LENGTH}
                            icon={DownloadIcon}
                            tooltip={{
                                active: true,
                                text: 'Download',
                                side: TOOLTIP_TYPE.bottom,
                            }}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            {...(detectTouchDevice(document) ? {
                                onTouchStart: handleDownload,
                            } : {
                                onMouseDown: handleDownload,
                            })}
                        />
                    )}
                    {!readOnly
                    && status !== AUDIO_NOTE_RECORDING_STATE.idle
                    && (
                        isEditing
                            ? (
                                <Button
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={BUTTON_TYPE.secret}
                                    height={SMALL_ACTION_BUTTON_LENGTH}
                                    icon={SaveIcon}
                                    tooltip={{
                                        active: true,
                                        text: 'Save',
                                        side: TOOLTIP_TYPE.bottom,
                                    }}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: saveAudioNote,
                                    } : {
                                        onMouseDown: saveAudioNote,
                                    })}
                                />
                            )
                            : (
                                <Button
                                    className={HOVER_TARGET_CLASSNAME}
                                    type={BUTTON_TYPE.secret}
                                    height={SMALL_ACTION_BUTTON_LENGTH}
                                    icon={PencilIcon}
                                    tooltip={{
                                        active: true,
                                        text: 'Edit',
                                        side: TOOLTIP_TYPE.bottom,
                                    }}
                                    onMouseEnter={onButtonMouseEnter}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: (e) => {
                                            e.nativeEvent.stopPropagation();
                                            setIsEditing(true);
                                        },
                                    } : {
                                        onMouseDown: (e) => {
                                            e.nativeEvent.stopPropagation();
                                            setIsEditing(true);
                                        },
                                    })}
                                />
                            )
                    )}
                    {!readOnly
                    && status !== AUDIO_NOTE_RECORDING_STATE.idle
                    && isEditing
                    && (
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.secret}
                            height={SMALL_ACTION_BUTTON_LENGTH}
                            icon={RedoIcon}
                            tooltip={{
                                active: true,
                                text: 'Redo',
                                side: TOOLTIP_TYPE.bottom,
                            }}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onClick={() => {
                                discardAudioNote();
                                startRecord();
                            }}
                        />
                    )}
                    {!readOnly
                    && status !== AUDIO_NOTE_RECORDING_STATE.idle
                    && (
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.secret}
                            height={SMALL_ACTION_BUTTON_LENGTH}
                            icon={TrashIcon}
                            tooltip={{
                                active: true,
                                text: 'Discard',
                                side: TOOLTIP_TYPE.bottom,
                            }}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onClick={discardAudioNote}
                        />
                    )}
                    {!readOnly
                    && status === AUDIO_NOTE_RECORDING_STATE.idle
                    && (
                        <Button
                            className={HOVER_TARGET_CLASSNAME}
                            type={BUTTON_TYPE.secret}
                            height={buttonLength}
                            icon={ImportIcon}
                            tooltip={{
                                active: true,
                                text: 'Import Audio',
                                side: TOOLTIP_TYPE.bottom,
                            }}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            onClick={importAudioNote}
                        />
                    )}
                </ActionsContainer>
            )}
            <TranscriptContainer
                visible={showTranscript && audioNoteMediaItem?.transcript}
                transitionDuration={TRANSCRIPT_TRANSITION_DURATION}
            >
                {showTranscript
                && audioNoteMediaItem?.transcript
                && (
                    transcriptWords.map((word: ITranscriptionWord) => {
                        const upperBoundary = (MILLISECONDS_IN_A_SECOND * parseInt(word.endTime.seconds, 10))
                            + (MILLISECONDS_IN_A_SECOND * word.endTime.nanos) / NANOSECONDS_IN_A_SECOND > timeElapsed;
                        const lowerBoundary = (MILLISECONDS_IN_A_SECOND * parseInt(word.startTime.seconds, 10))
                            + (MILLISECONDS_IN_A_SECOND * word.startTime.nanos) / NANOSECONDS_IN_A_SECOND <= timeElapsed;

                        return (
                            <TranscriptWord
                                className={HOVER_TARGET_CLASSNAME}
                                color={color}
                                highlighted={
                                    (
                                        status === AUDIO_NOTE_RECORDING_STATE.pausedComplete
                                        || status === AUDIO_NOTE_RECORDING_STATE.playing
                                    )
                                    && lowerBoundary
                                    && upperBoundary
                                }
                                onMouseEnter={onTranscriptWordMouseEnter}
                                onMouseLeave={onTranscriptWordMouseLeave}
                                onClick={() => skipTranscriptWord(word)}
                            >
                                {`${word.word} `}
                            </TranscriptWord>
                        );
                    })
                )}
            </TranscriptContainer>
            <AudioElement
                ref={audioNodeRef}
                preload="none"
            >
                {audioURL
                && (
                    <AudioSource
                        src={audioURL}
                    />
                )}
                <AudioTrack kind="captions" />
                Your browser does not support audio.
            </AudioElement>
            {children}
            <DropzoneInput {...getInputProps()} />
        </Container>
    );
}

// ============= Styled Components ==============

interface ContainerProps {
    fixedWidth: boolean,
    selected: boolean,
    focused: boolean,
    color: string,
    small: boolean,
    descriptionInputFocused: boolean,
    padding: number,
}
const Container = styled.div<ContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${({ fixedWidth }) => (fixedWidth
        ? '350px'
        : '100%'
    )};
    margin: 20px auto;
    max-width: 400px;
    min-height: 70px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.color.white};
    padding: ${({ padding }) => `${padding}px`};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    caret-color: ${({ descriptionInputFocused }) => (descriptionInputFocused
        ? 'auto'
        : 'transparent'
    )};
    border: ${({
        selected,
        focused,
        color,
        theme,
    }) => (selected && focused
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.blue200
        }`
        : 'none'
    )};
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        width: 100%;
    }
`;

interface ContentContainerProps {
    leftAlign: boolean,
    width: string,
}
const ContentContainer = styled.div<ContentContainerProps>`
    display: inline-flex;
    justify-content: ${({ leftAlign }) => (leftAlign ? 'flex-start' : 'center')};
    align-items: center;
    position: relative;
    height: 100%;
    width: ${({ width }) => width};
    // Rather than be none, we want a transparent the background color
    // so that the background transitions in response to hover events
    background: transparent;
    margin: 0;
    padding: 0;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

interface PlaybackContainerProps {
    paddingLeft: number,
    hasProgressBar: boolean,
    audioNoteEmpty: boolean,
}
const PlaybackContainer = styled.div<PlaybackContainerProps>`
    position: relative;
    display: ${({ audioNoteEmpty }) => (audioNoteEmpty ? 'none' : 'inline-flex')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${({ hasProgressBar }) => (hasProgressBar
        ? '130px'
        : 'auto'
    )};
    background: inherit;
    padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        height: auto;
        width: 130px;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 0px;
    }
`;

interface PlaybackButtonsProps {
    noPermissions: boolean,
    status: AUDIO_NOTE_RECORDING_STATE,
    buttonLength: number,
}
const PlaybackButtons = styled.div<PlaybackButtonsProps>`
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    pointer-events: ${({ noPermissions, status }) => (noPermissions
        && status === AUDIO_NOTE_RECORDING_STATE.idle
        ? 'none'
        : 'auto'
    )};
    background: inherit;
    border-radius: ${({ buttonLength }) => `${buttonLength / 2}px`};
    opacity: ${({ noPermissions, status }) => (noPermissions
        && status === AUDIO_NOTE_RECORDING_STATE.idle
        ? 0
        : 1
    )};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        margin-right: 5px;
    }
`;

interface StatusIconContainerProps {
    width: number,
    marginLeft?: number,
}
const StatusIconContainer = styled.div<StatusIconContainerProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => `${width}px`};
    height: ${({ width }) => `${width}px`};
    border-radius: ${({ width }) => `${width / 2}px`};
    margin-left: ${({ marginLeft }) => `${marginLeft || 0}px`};
    padding: 8px;
    background-color: ${({ theme }) => theme.color.neutral900};
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.4), 0 2px 10px 0 rgba(0,0,0,0.12);
    vertical-align: middle;
    cursor: none;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ color, theme }) => {
        if (color) {
            return color;
        }

        return theme.color.neutral600;
    }};
    }

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

interface TimerFigureProps {
    center?: boolean,
    right?: boolean,
    small: boolean,
    recording?: boolean,
    potentialElapsed?: boolean,
    width: number,
}
const TimerFigure = styled.h3<TimerFigureProps>`
    display: inline-flex;
    align-items: center;
    justify-content: ${({ center, right }) => {
        if (center) {
            return 'center';
        }

        if (right) {
            return 'flex-end';
        }

        return 'flex-start';
    }};
    margin: 0;
    padding: 0;
    height: 100%;
    width: ${({ width }) => `${width}px`};
    max-height: 91px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ small }) => `${small ? 0.9 : 1}em`};
    font-weight: 400;
    vertical-align: middle;
    color: ${({
        recording,
        potentialElapsed,
        color,
        theme,
    }) => {
        if (recording) {
            return theme.verascopeColor.red200;
        }

        if (potentialElapsed && color) {
            return color;
        }

        return theme.color.neutral700;
    }};
    transition: color 300ms;

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-left: 5px;
        justify-content: ${({ right }) => (() => {
        if (right) {
            return 'flex-end';
        }

        return 'flex-start';
    })()};
    }
`;

interface ContentTextProps {
    small: boolean | undefined,
    buttonLength: number,
    numButtons: number,
    statusIconMarginLeft: number,
    playbackContainerPaddingLeft: number,
    fontSize: number,
}
const ContentText = styled.h3<ContentTextProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize }) => `${fontSize}em`};
    padding-left: ${({ small }) => `${small ? 15 : 0}px`};
    font-weight: 500;
    color: ${({ color, theme }) => color || theme.color.neutral700};
    width: ${({
        numButtons,
        buttonLength,
        statusIconMarginLeft,
        playbackContainerPaddingLeft,
    }) => `calc(100% - ${numButtons > 1
        ? numButtons * buttonLength + playbackContainerPaddingLeft + statusIconMarginLeft
        : numButtons * buttonLength + playbackContainerPaddingLeft
    }px)`};
    text-align: center;
    margin: 0;
`;

interface ActionsContainerProps {
    isIdle: boolean,
    buttonLength: number,
    paddingLeft: number,
    paddingRight: number,
    propagateBackground: boolean,
    numActions: number,
    vertical: boolean,
    maxHeight: string,
}
const ActionsContainer = styled.div<ActionsContainerProps>`
    position: absolute;
    top: ${({ vertical }) => (vertical ? '50%' : '0px')};
    transform: ${({ vertical }) => (vertical
        ? 'translateY(-50%)'
        : 'none'
    )};
    right: 0px;
    display: inline-flex;
    flex-direction: ${({ vertical }) => (vertical
        ? 'column'
        : 'row'
    )};
    justify-content: space-around;
    align-items: center;
    background: inherit;
    border-radius: ${({ buttonLength }) => `${buttonLength / 2}px`}; // makes sure background does bleed
    width: ${({
        isIdle,
        buttonLength,
        numActions,
        paddingLeft,
        paddingRight,
        vertical,
    }) => {
        if (isIdle) {
            return 'auto';
        }

        if (vertical) {
            return `${buttonLength}px`;
        }

        return `${(numActions * buttonLength) + paddingLeft + paddingRight}px`;
    }};
    height: 100%;
    max-height: ${({ maxHeight }) => maxHeight};
    padding-left: ${({ paddingLeft, vertical }) => `${vertical ? 0 : paddingLeft}px`};
    padding-right: ${({ paddingRight, vertical }) => `${vertical ? 0 : paddingRight}px`};
    vertical-align: middle;

    & > div {
        background: ${({ propagateBackground }) => (propagateBackground
        ? 'inherit'
        : 'none'
    )};
    }
`;

const ProgressBarContainer = styled.div``;

const ProgressBar = styled.div`
    position: relative;
    height: 6px;
    width: 100%;
    background: ${({ theme }) => theme.color.neutral400};
    border-radius: 3px;
    margin: 10px 0;
    cursor: none;
    transition: ${({ theme }) => `
        height 300ms ${theme.motion.delayEasing},
        border-radius 300ms ${theme.motion.delayEasing}
    `};

    ${ProgressBarContainer}:hover & {
        height: 18px;
        border-radius: 9px;
    }
`;

interface ProgressScrubberProps {
    width: number,
}
const ProgressScrubber = styled.div<ProgressScrubberProps>`
    height: 100%;
    width: ${({ width }) => `${width}%`};
    border-radius: 3px;
    background-color: ${({ color, theme }) => color || theme.color.white};
`;

const PotentialScrubber = styled(ProgressScrubber)`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
`;

const ProgressTimeContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const RecordingIcon = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.white};
`;

interface DetailsContainerProps {
    rightTimeFigureVisible: boolean,
    maxTimestampWidth: number,
}
const DetailsContainer = styled.div<DetailsContainerProps>`
    width: ${({ rightTimeFigureVisible, maxTimestampWidth }) => (rightTimeFigureVisible
        ? `calc(100% - ${maxTimestampWidth}px)`
        : '100%'
    )};
    padding-left: 20px;
    ${({ theme }) => theme.mediaQuery.small} {
        padding-right: ${({ rightTimeFigureVisible }) => (rightTimeFigureVisible
        ? '20px'
        : '0px'
    )}
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding-left: 5px;
    }
`;

interface TitleProps {
    fontSize: number,
}
const Title = styled.h2<TitleProps>`
    ${basicEditorFontStyles}
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize }) => `${fontSize}em`};
    font-weight: 700;
    color: ${({ theme }) => theme.color.neutral900};
    width: 100%;
    margin: 0;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: 1em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1em;
    }
`;

interface DescriptionProps {
    fontSize: number,
}
const Description = styled.p<DescriptionProps>`
    ${basicEditorFontStyles}
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize }) => `${fontSize}em`};
    color: ${({ theme }) => theme.color.neutral600};
    width: 100%;
    margin: 5px 0px;
    white-space: pre-wrap;
`;

interface TitleInputProps {
    top?: number,
    left?: number,
    width?: number,
    fontSize: number,
}
const TitleInput = styled.input<TitleInputProps>`
    ${basicEditorFontStyles}
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize }) => `${fontSize}em`};
    font-weight: 700;
    width: ${({ width }) => `${width}px`};
    margin: 0;
    margin-top: 5px;
    padding: 2.5px 15px;
    text-transform: none;
    border: none;
    text-decoration: none;
    background-color: ${({ theme }) => theme.color.neutral100};
    border-radius: 20px;
    z-index: ${CURSOR_Z_INDEX - 2};

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral600};
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: 1em;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: 1em;
    }
`;

interface DescriptionInputProps {
    top?: number,
    left?: number,
    width?: number,
    fontSize: number,
}
const DescriptionInput = styled.div<DescriptionInputProps>`
    ${basicEditorFontStyles}
    position: absolute;
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: ${({ fontSize }) => `${fontSize}em`};
    color: ${({ theme }) => theme.color.neutral700};
    height: auto;
    width: ${({ width }) => `${width}px`};
    margin: 5px 0px;
    padding: 5px 10px;
    text-transform: none;
    border: none;
    text-decoration: none;
    resize: none;
    border-radius: 20px;
    z-index: ${CURSOR_Z_INDEX - 2};

    &:empty:not(:focus):before {
        content: attr(data-placeholder);
        line-height: 1em;
        color: ${({ theme }) => theme.color.neutral500};
    }

    &:focus {
        outline: none;
    }
`;

const AudioElement = styled.audio``;
const AudioSource = styled.source``;
const AudioTrack = styled.track``;
const TitleInputNode = styled(TitleInput)`
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    opacity: 0;
    pointer-events: none;
`;

const DescriptionInputNode = styled(DescriptionInput)`
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    opacity: 0;
    pointer-events: none;
`;

interface SkipButtonProps {
    top?: boolean,
    bottom?: boolean,
    width: number,
    show: boolean,
    isPlaying: boolean,
}
const SkipButton = styled.div<SkipButtonProps>`
    position: absolute;
    top: ${({ top }) => (top
        ? '0px'
        : 'auto'
    )};
    bottom: ${({ bottom }) => (bottom
        ? '0px'
        : 'auto'
    )};
    width: ${({ width }) => `${width}px`};
    height: ${({ width }) => `${width}px`};
    border-radius: ${({ width }) => `${width / 2}px`};
    padding: 8px;
    background-color: transparent;
    opacity: ${({ show }) => (show
        ? 1
        : 0
    )};
    pointer-events: ${({ show }) => (show
        ? 'auto'
        : 'none'
    )};
    cursor: none;
    transition: background-color 0.3s, opacity 0.3s;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral600};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.neutral100};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        position: relative;
        bottom: auto;
        padding: 4px;
        margin-right: 5px;
        display: ${({ isPlaying, show }) => (isPlaying && show ? 'inline-block' : 'none')};
    }
`;

interface CurrentPlaybackSpeedTextProps {
    visible: boolean,
}
const CurrentPlaybackSpeedText = styled.div<CurrentPlaybackSpeedTextProps>`
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.75em;
    line-height: 0.9em;
    color: ${({ theme }) => theme.color.neutral700};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: none;
    transition: ${({ theme }) => `
        opacity 300ms ${theme.motion.eagerEasing},
        bottom 300ms ${theme.motion.eagerEasing}
    `};

    ${OptionsMenuContainer}:hover & {
        bottom: -11px;
    }
`;

interface TranscriptContainerProps {
    visible: boolean,
    transitionDuration: number,
}
const TranscriptContainer = styled.div<TranscriptContainerProps>`
    width: 100%;
    height: ${({ visible }) => `${visible ? 100 : 0}%`};
    margin: ${({ visible }) => `${visible ? 5 : 0}px`};
    margin-bottom: 0;
    border-left: ${({ theme }) => `4px solid ${theme.color.neutral500}`};
    padding: ${({ visible }) => `${visible ? '5px 10' : 0}px`};
    padding-bottom: 0;
    background: ${({ theme }) => theme.color.white};
    transition: ${({ theme, transitionDuration }) => `
        height ${transitionDuration}ms ${theme.motion.overshoot},
        padding ${transitionDuration}ms ${theme.motion.overshoot},
        margin ${transitionDuration}ms ${theme.motion.overshoot}
    `};
`;

interface TranscriptWordProps {
    color: string,
    highlighted: boolean,
}
const TranscriptWord = styled.span<TranscriptWordProps>`
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
    color: ${({ highlighted, color, theme }) => (highlighted
        ? fontColorDiscriminator(color)
        : theme.color.neutral800
    )};
    background: ${({ highlighted, color, theme }) => (highlighted
        ? color
        : theme.color.white
    )};
`;

export default AudioNote;
