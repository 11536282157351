import {
    IYouTubeParams,
}                   from '../interfaces';

const YOUTUBE_URL_REGEX = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)(\S{11})((&|\?)(start|end)=(\d+))?((&|\?)(start|end)=(\d+))?/;
const getYouTubeParams = (url: string): IYouTubeParams | null => {
    const match = url ? url.match(YOUTUBE_URL_REGEX) : null;
    if (match && match[2]) {
        const params: IYouTubeParams = {
            id: match[2],
        };
        if (match[6]) params.start = parseInt(match[6], 10);
        if (match[10]) params.end = parseInt(match[10], 10);
        return params;
    }
    return null;
};

export default getYouTubeParams;
