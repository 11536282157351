// ===== Interfaces =====

import { IFontType } from '../interfaces';

const FONT_TYPE: IFontType = {
    IOWAN_OLD_STYLE: '\'Iowan Old Style\', \'Baskerville\', \'Baskerville Old Face\', \'Hoefler Text\', \'Times New Roman\', serif',
    PLUS_JAKARTA_SANS: '\'Plus Jakarta Sans\', Helvetica, Arial, sans-serif',
    PRESS_START: 'PressStart2P, sans-serif',
};

export default FONT_TYPE;
