// ===== Packages =====

import {
    Node,
    Editor,
    Path,
    Transforms,
    Point,
    Range,
    NodeEntry,
} from 'slate';

// ===== Elements =====

import {
    ListItemElement as _ListItemElement,
    ListElement as _ListElement,
    UNWRAP_LIST_ITEM,
    WRAP_LIST_ITEM,
}                                               from '../elements/listElement';
import LineElement                              from '../elements/lineElement';
import LineInterface                            from '../interfaces/lineInterface';

// ===== Interfaces =====

import {
    ListNode,
    ListItemNode,
    EditorNode,
}                                               from '../interfaces';

// ===== Enums =====

import { LIST_TYPE }                            from '../elements/listElement/enums';

export const ListElement = _ListElement;
export const ListItemElement = _ListItemElement;

const getSelectionLists = (editor: Editor, listType = ListElement.LIST_TYPE.unordered): NodeEntry<Node>[] => {
    const matchListType = (node: EditorNode | Node): boolean => ListElement.isListElement(node)
        && 'listType' in node
        && node.listType === listType;
    return Array.from(Editor.nodes(editor, { match: matchListType }));
};

const hasSelectedUL = (editor: Editor): boolean => {
    const [match] = getSelectionLists(editor, ListElement.LIST_TYPE.unordered);
    return !!match;
};

const hasSelectedOL = (editor: Editor): boolean => {
    const [match] = getSelectionLists(editor, ListElement.LIST_TYPE.ordered);
    return !!match;
};

const mergeAllSimilarTypeLists = (editor: Editor): void => {
    const listsAreImmediateSiblingsOfSameType = ([listA, pathA]: [ListNode, Path], [listB, pathB]: [ListNode, Path]): boolean => {
        const sameType = listA.listType === listB.listType;
        const areImmediateSiblings = Path.isSibling(pathA, pathB)
            && Math.abs(pathA[pathA.length - 1] - pathB[pathB.length - 1]) === 1;
        return sameType && areImmediateSiblings;
    };

    let hasConverged = false;
    while (!hasConverged) {
        // assume merging has converged
        hasConverged = true;

        const descendants = Array
            .from(Node.descendants(editor))
            .filter(([node]) => ListElement.isListElement(node));
        for (let currentIndex = descendants.length - 1; currentIndex > 0; currentIndex -= 1) {
            const currentEntry = descendants[currentIndex] as [ListNode, Path];
            const previousIndex = currentIndex - 1;
            const previousEntry = descendants[previousIndex] as [ListNode, Path];

            if (listsAreImmediateSiblingsOfSameType(currentEntry, previousEntry)) {
                // indicate that merging has not converged
                hasConverged = false;

                // merge the Lists together
                const [, currentPath] = currentEntry;
                Transforms.mergeNodes(editor, { at: currentPath });
            }
        }
    }
};

const wrapSelectionInList = (editor: Editor, listType: LIST_TYPE): void => {
    const nodeIsListableAndNotDirectChildOfListItem = (node: Node, path: Path): boolean => {
        if (Editor.isEditor(node)) { return false; }

        const nodeIsListable = ListElement.implementsListableInterface(node);
        const parent = Node.parent(editor, path);
        const nodeParentIsListItem = ListItemElement.isListItemElement(parent);
        return nodeIsListable && !nodeParentIsListItem;
    };

    Editor.withoutNormalizing(editor, () => {
        const selectionNodes = Array.from(Editor.nodes(editor));

        // set the listType of all existing lists in the selection to listType
        selectionNodes
            .filter(([node]) => ListElement.isListElement(node))
            .forEach(([, path]) => Transforms.setNodes<ListNode>(editor, { listType }, { at: path }));

        // wrap all Listable elements that are not already wrapped in ListItems in ListItems
        //  and then further within Lists of type ListType
        selectionNodes
            .filter(([node, path]) => nodeIsListableAndNotDirectChildOfListItem(node, path))
            .forEach(([, path]) => {
                Transforms.wrapNodes(editor, ListItemElement.newListItemElement(), { at: path });
                Transforms.wrapNodes(editor, ListElement.newListElement(listType), { at: path });
            });

        // Clean up! go through and merge all lists of the same type in the editor
        mergeAllSimilarTypeLists(editor);
    });
};

const toggleList = (editor: Editor, listType: LIST_TYPE): void => {
    const { unordered: u, ordered: o } = ListElement.LIST_TYPE;
    const containsHeterogeneousList = hasSelectedOL(editor) && hasSelectedUL(editor);
    const containsHomogeneousNonMatch = (listType === u && hasSelectedOL(editor) && !hasSelectedUL(editor))
        || (listType === o && hasSelectedUL(editor) && !hasSelectedOL(editor));
    const containsHomogeneousMatch = (listType === u && hasSelectedUL(editor) && !hasSelectedOL(editor))
        || (listType === o && hasSelectedOL(editor) && !hasSelectedUL(editor));
    const containsNoLists = !hasSelectedUL(editor) && !hasSelectedOL(editor);

    if (containsHeterogeneousList) {
        return wrapSelectionInList(editor, listType);
    }
    if (containsHomogeneousNonMatch) {
        return wrapSelectionInList(editor, listType);
    }
    if (containsHomogeneousMatch) {
        return unwrapSelectionFromList(editor);
    }
    if (containsNoLists) {
        return wrapSelectionInList(editor, listType);
    }
};

const toggleOL = (editor: Editor): void => toggleList(editor, ListElement.LIST_TYPE.ordered);

const toggleUL = (editor: Editor): void => toggleList(editor, ListElement.LIST_TYPE.unordered);

const getListDepth = (editor: Editor, path: Path): number => {
    // record the number of Lists that the Listable was nested under for
    //  post-processing purposes
    let nestedListCount = 0;

    // WARNING: in the Slate documentation Node.ancestors claims to provide the ancestors
    //  bottom-up by default and that passing reverse = true will provide the ancestors
    //  top-down. The documentation appears wrong for now, be weary that in a future
    //  version reverse = true may need to be removed
    Array.from(Node.ancestors(editor, path, { reverse: true })).forEach(([ancestor]) => {
        if (ListElement.isListElement(ancestor)) {
            nestedListCount += 1;
        } else if (ListItemElement.isListItemElement(ancestor)) {
            // continue to next ancestor...
        }
    });

    return nestedListCount;
};

const unwrapSelectionFromList = (editor: Editor): void => {
    const getListItemsThatMustBeUnwrapped = (): NodeEntry<Node>[] => Array
        .from(Node.descendants(editor))
        .filter(([node, _]) => ListItemElement.isListItemElement(node) && (UNWRAP_LIST_ITEM in node && node[UNWRAP_LIST_ITEM]));

    Editor.withoutNormalizing(editor, () => {
        Array.from(Editor.nodes(editor)).forEach(([node, listItemPath]) => {
            if (!ListItemElement.isListItemElement(node)) { return; }

            // NOTE: a ListItem always has exactly 1 child and that child is a Listable
            //  so we access it via extending the ListItem's path with a '0'
            const listablePath = [...listItemPath, 0];
            const indentCount = getListDepth(editor, listItemPath);
            Transforms.setNodes<ListItemNode>(editor, { indentCount }, { at: listablePath });

            // mark each ListItem in the selection as needing to be unwrapped
            Transforms.setNodes<ListItemNode>(editor, { [UNWRAP_LIST_ITEM]: true }, { at: listItemPath });
        });

        // lift up and unwrap every ListItem that was marked to be unwrapped
        let listItems = getListItemsThatMustBeUnwrapped();
        while (listItems.length > 0) {
            const listItemPath = listItems[0][1];

            const parent = Editor.parent(editor, listItemPath)[0];
            if (ListElement.isListElement(parent)) {
                // if a ListItem that needs to be unwrapped still has a parent
                //  that is a List, continue to lift the ListItem up
                Transforms.liftNodes(editor, { at: listItemPath });
            } else {
                // if a ListItem that needs to be unwrapped does not have a
                //  List for a parent then it has been maximally lifted and
                //  just needs to be unwrapped
                Transforms.unwrapNodes(editor, { at: listItemPath });
            }

            listItems = getListItemsThatMustBeUnwrapped();
        }
    });
};

const indentSelectionLists = (editor: Editor): void => {
    const getListItemsToBeWrapped = (): NodeEntry<Node>[] => Array
        .from(Node.descendants(editor))
        .filter(([node, _]) => ListItemElement.isListItemElement(node) && (WRAP_LIST_ITEM in node && node[WRAP_LIST_ITEM]));

    Editor.withoutNormalizing(editor, () => {
        // mark each ListItem that should be wrapped in new List
        Array
            .from(Editor.nodes(editor, { match: ListItemElement.isListItemElement }))
            .forEach(([, listPath]) => Transforms.setNodes<ListItemNode>(editor, {
                [WRAP_LIST_ITEM]: true,
            }, { at: listPath }));

        // wrap each marked ListItem inside a List of its same type
        let listItems = getListItemsToBeWrapped();
        while (listItems.length > 0) {
            const [, listItemPath] = listItems[0];

            // remove the temporary marker
            Transforms.unsetNodes(editor, WRAP_LIST_ITEM, { at: listItemPath });

            const parentList = Editor.parent(editor, listItemPath)[0];
            const { listType } = parentList as ListNode;
            Transforms.wrapNodes(editor, ListElement.newListElement(listType), { at: listItemPath });
            listItems = getListItemsToBeWrapped();
        }

        // Clean up! go through and merge all lists of the same type in the editor
        mergeAllSimilarTypeLists(editor);
    });
};

const unindentSelectionLists = (editor: Editor, options = { force: false }): void => {
    const { force } = options;

    const getListItemsThatMustBeUnwrapped = (): NodeEntry<Node>[] => Array
        .from(Node.descendants(editor))
        .filter(([node, _]) => ListItemElement.isListItemElement(node) && (UNWRAP_LIST_ITEM in node && node[UNWRAP_LIST_ITEM]));

    Editor.withoutNormalizing(editor, () => {
        Array.from(Editor.nodes(editor)).forEach(([node, listItemPath]) => {
            if (!ListItemElement.isListItemElement(node)) { return; }

            // mark each ListItem in the selection as needing to be unwrapped
            Transforms.setNodes<ListItemNode>(editor, { [UNWRAP_LIST_ITEM]: true }, { at: listItemPath });
        });

        let listItems = getListItemsThatMustBeUnwrapped();
        while (listItems.length > 0) {
            const [, listItemPath] = listItems[0];

            // always unset the node even if it doesn't need to be unindented
            Transforms.unsetNodes(editor, UNWRAP_LIST_ITEM, { at: listItemPath });

            // we can only unindent nodes that have depth >= 2 OR if
            //  it is strictly our intention to unwrap a root-list
            if (getListDepth(editor, listItemPath) >= 2 || force) {
                Transforms.liftNodes(editor, { at: listItemPath });
            }
            listItems = getListItemsThatMustBeUnwrapped();
        }
    });
};

export const ListHelpers = {
    getSelectionLists,
    wrapSelectionInList,
    unwrapSelectionFromList,
    indentSelectionLists,
    unindentSelectionLists,
    hasSelectedUL,
    hasSelectedOL,
    toggleList,
    toggleUL,
    toggleOL,
};

const elementCanBeChildOfList = (element: EditorNode): boolean => ListElement.isListElement(element)
    || ListItemElement.isListItemElement(element)
    || ListElement.implementsListableInterface(element);

const normalizeListItem = (editor: Editor, node: EditorNode, path: Path): void => {
    const { children } = node;

    if (!Editor.hasBlocks(editor, node)) {
        Transforms.setNodes(editor, LineElement.newLineElement(), { at: path });
        return Transforms.wrapNodes(editor, ListItemElement.newListItemElement(), { at: path });
    }

    const hasSingleChildThatIsAListable = children.length === 1 && ListElement.implementsListableInterface(children[0]);
    if (!hasSingleChildThatIsAListable) { return Transforms.unwrapNodes(editor, { at: path }); }

    const [parent] = Editor.parent(editor, path);
    if (!ListElement.isListElement(parent)) { return Transforms.unwrapNodes(editor, { at: path }); }
};

const normalizeList = (editor: Editor, list: ListNode, path: Path): void => {
    const { children } = list;

    if (children.length === 0) { return Transforms.removeNodes(editor, { at: path }); }

    if (!ListElement.listTypeIsValid(list.listType)) {
        const [parent] = Editor.parent(editor, path);
        const newListType = (ListElement.isListElement(parent) && ListElement.listTypeIsValid((parent as ListNode).listType))
            ? (parent as ListNode).listType : ListElement.LIST_TYPE.unordered;
        return Transforms.setNodes<ListNode>(editor, { listType: newListType }, { at: path });
    }

    // NOTE: for the following fixes we only need to list the invalid element
    //  up one level because normalizeNode will continue to be called until
    //  they ascend to the the editor's immediate child level
    Array.from(Node.children(editor, path)).forEach(([child, childPath]) => {
        // get rid of any children that should not be in a List
        if (!elementCanBeChildOfList(child as EditorNode)) {
            return Transforms.liftNodes(editor, { at: childPath });
        }

        // wrap any unwrapped Listables in a ListItem
        if (ListElement.implementsListableInterface(child)) {
            return Transforms.wrapNodes(editor, ListItemElement.newListItemElement(), { at: childPath });
        }
    });

    const previousSiblingIsListOfSameType = (): boolean => {
        const hasPreviousSibling = path[path.length - 1] > 0;
        if (!hasPreviousSibling) { return false; }

        const previous = Node.get(editor, Path.previous(path));
        if (!ListElement.isListElement(previous)) { return false; }

        const prevAndCurrHaveSameListTypes = (previous as ListNode).listType === list.listType;
        if (!prevAndCurrHaveSameListTypes) { return false; }

        return true;
    };

    if (previousSiblingIsListOfSameType()) {
        return Transforms.mergeNodes(editor, { at: path });
    }
};

const shouldUnwrapCurrentListItem = (editor: Editor): boolean => {
    const [[, cursorTextNodePath]] = Array.from(Editor.nodes(editor)).slice(-1);
    // WARNING: in the Slate documentation Node.ancestors claims to provide the ancestors
    //  bottom-up by default and that passing reverse = true will provide the ancestors
    //  top-down. The documentation appears wrong for now, be weary that in a future
    //  version reverse = true may need to be removed
    const [firstBlockAncestorEntry] = Array
        .from(Node.ancestors(editor, cursorTextNodePath, { reverse: true }))
        .filter(([node]) => Editor.isBlock(editor, node));

    if (!firstBlockAncestorEntry) { return false; }
    const [firstBlockAncestor, firstBlockAncestorPath] = firstBlockAncestorEntry;

    const parentIsLineAndListInterfaceElement = LineInterface.implementsLineInterface(firstBlockAncestor)
        && ListElement.implementsListableInterface(firstBlockAncestor);
    if (parentIsLineAndListInterfaceElement) {
        const [secondAncestor, secondAncestorPath] = Editor.parent(editor, firstBlockAncestorPath);
        if (ListItemElement.isListItemElement(secondAncestor)) {
            const cursorPoint = Range.start(editor.selection as Range);
            const [, startListItemTextNodePath] = Editor.leaf(editor, secondAncestorPath, { edge: 'start' });
            const startOfListItemPoint = {
                path: startListItemTextNodePath,
                offset: 0,
            };
            return Point.equals(startOfListItemPoint, cursorPoint);
        }
    }

    return false;
};

export const withLists = (editor: Editor): Editor => {
    const {
        normalizeNode, isInline, isVoid, deleteBackward,
    } = editor;

    // eslint-disable-next-line no-param-reassign
    editor.normalizeNode = (entry) => {
        const [, path] = entry;
        // NOTE: manually grab the node because entry will be changed
        //  to path in the near future per https://github.com/ianstormtaylor/slate/issues/3275
        const node = Node.get(editor, path) as ListNode;

        if (ListItemElement.isListItemElement(node)) {
            return normalizeListItem(editor, node, path);
        }

        if (ListElement.isListElement(node)) {
            return normalizeList(editor, node, path);
        }

        return normalizeNode(entry);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isInline = (element) => {
        if (ListItemElement.isListItemElement(element)
            || ListElement.isListElement(element)) {
            return false;
        }
        return isInline(element);
    };

    // eslint-disable-next-line no-param-reassign
    editor.isVoid = (node) => {
        if (ListItemElement.isListItemElement(node)
            || ListElement.isListElement(node)) {
            return false;
        }
        return isVoid(node);
    };

    // eslint-disable-next-line no-param-reassign
    editor.deleteBackward = (unit) => {
        if (shouldUnwrapCurrentListItem(editor)) {
            return unindentSelectionLists(editor, { force: true });
        }

        return deleteBackward(unit);
    };

    return editor;
};
