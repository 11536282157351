// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';

// ===== Interfaces =====

import {
    IShippoAddressObject,
}                               from '../interfaces';

const shippoCreateShipment = async ({
    test,
    paymentIntentId,
    addressTo,
    physicalItemsInCart,
    internationalShipment,
}: {
    test: boolean,
    paymentIntentId: string,
    addressTo: IShippoAddressObject,
    physicalItemsInCart: string[],
    internationalShipment: boolean,
}): Promise<Shippo.Shipment> => {
    const functions = getFunctions();
    const createShipment = httpsCallable<
        Record<
            string,
            boolean | IShippoAddressObject | string | string[]
        >,
        Shippo.Shipment
    >(
        functions,
        'shippoCreateShipment',
    );
    return createShipment({
        test,
        paymentIntentId,
        addressTo,
        physicalItemsInCart,
        internationalShipment,
    }).then((result) => {
        const shipment = result.data as Shippo.Shipment;
        return shipment;
    }).catch((err) => err);
};

export default shippoCreateShipment;
