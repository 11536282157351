// ===== Interfaces =====

import { IFileType } from '../interfaces';

const FILE_TYPE: IFileType = {
    wordDoc: 'doc',
    wordDocX: 'docx',
    excel: 'xlsx',
    pdf: 'pdf',
    mp3: 'mp3',
    m4a: 'm4a',
    png: 'png',
    jpg: 'jpg',
    jpeg: 'jpeg',
    gif: 'gif',
    svg: 'svg',
};

export default FILE_TYPE;
