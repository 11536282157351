// ===== Packages =====

import {
    getFunctions,
    httpsCallable,
}                               from 'firebase/functions';
import Stripe                   from 'stripe';

const stripeGetTaxCalculationLineItems = async ({
    test,
    taxCalculationId,
}: {
    test: boolean,
    taxCalculationId: string,
}): Promise<Stripe.Tax.CalculationLineItem[]> => {
    const functions = getFunctions();
    const getTaxCalculation = httpsCallable<
        {
            test: boolean,
            taxCalculationId: string,
        },
        Stripe.Tax.CalculationLineItem[]
    >(
        functions,
        'stripeGetTaxCalculationLineItems',
    );

    return getTaxCalculation({
        test,
        taxCalculationId,
    }).then((result) => {
        const taxCalculation = result.data as Stripe.Tax.CalculationLineItem[];
        return taxCalculation;
    }).catch((err) => err);
};

export default stripeGetTaxCalculationLineItems;
