// ===== Packages =====

import {
    doc,
    where,
    query,
    getDoc,
    getDocs,
    collection,
    getFirestore,
    DocumentData,
    DocumentSnapshot,
}                                           from 'firebase/firestore';

// ===== Interfaces =====

import {
    IUserItem,
    ISaleItem,
    ICheckoutSessionItem,
}                                           from '../interfaces';

// ===== Enums =====

import {
    FIRESTORE_COLLECTION,
}                                           from '../enums';

// ===== Constants =====

import {
    PHYSICAL_BOOK_STRIPE_PRICE_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    WEB_BOOK_STRIPE_PRICE_API_ID,
    WEB_BOOK_STRIPE_PRICE_TEST_API_ID,
}                                           from '../constants/generalConstants';

const checkBookPurchases = async (user: IUserItem): Promise<{
    web: string[],
    digital: string[],
    physical: string[],
} | undefined> => {
    const PHYSICAL_BOOK_PRICE_ID = process.env.NODE_ENV === 'development'
        ? PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID
        : PHYSICAL_BOOK_STRIPE_PRICE_API_ID;
    const DIGITAL_BOOK_PRICE_ID = process.env.NODE_ENV === 'development'
        ? DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID
        : DIGITAL_BOOK_STRIPE_PRICE_API_ID;
    const WEB_BOOK_PRICE_ID = process.env.NODE_ENV === 'development'
        ? WEB_BOOK_STRIPE_PRICE_TEST_API_ID
        : WEB_BOOK_STRIPE_PRICE_API_ID;

    // Get all user's purchases
    if (user) {
        const purchasedWebSessionIds: string[] = [];
        const purchasedDigitalSessionIds: string[] = [];
        const purchasedPhysicalSessionIds: string[] = [];
        const db = getFirestore();
        const salesCollection = process.env.NODE_ENV === 'development'
            ? FIRESTORE_COLLECTION.stagingSales
            : FIRESTORE_COLLECTION.sales;
        const purchaseQuery = query(
            collection(db, salesCollection),
            where('userId', '==', user.id),
        );
        const purchaseSnap = await getDocs(purchaseQuery);
        const checkoutSessionIds: string[] = [];
        purchaseSnap.forEach((document) => {
            if (document.exists()) {
                const saleItem = document.data() as ISaleItem;
                checkoutSessionIds.push(saleItem.checkoutSessionId);
            }
        });
        const checkoutSessionCollection = process.env.NODE_ENV === 'development'
            ? FIRESTORE_COLLECTION.stagingCheckoutSessions
            : FIRESTORE_COLLECTION.checkoutSessions;
        const checkoutSessionPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
        checkoutSessionIds.forEach((id: string) => {
            checkoutSessionPromises.push(getDoc(doc(db, checkoutSessionCollection, id)));
        });
        const checkoutSessions: ICheckoutSessionItem[] = [];
        await Promise.all(checkoutSessionPromises)
            .then((sessions) => {
                sessions.forEach((sessionSnap) => {
                    if (sessionSnap.exists()) {
                        const sessionItem = sessionSnap.data() as ICheckoutSessionItem;
                        checkoutSessions.push(sessionItem);
                    }
                });
            });
        for (let i = 0; i < checkoutSessions.length; i += 1) {
            const session = checkoutSessions[i];
            const cart = session.carts[session.carts.length - 1];
            for (let k = 0; k < cart.items.length; k += 1) {
                const item = cart.items[k];
                if (item.priceId === PHYSICAL_BOOK_PRICE_ID) {
                    purchasedPhysicalSessionIds.push(session.paymentIntentId);
                } else if (item.priceId === DIGITAL_BOOK_PRICE_ID) {
                    purchasedDigitalSessionIds.push(session.paymentIntentId);
                    break;
                } else if (item.priceId === WEB_BOOK_PRICE_ID) {
                    purchasedWebSessionIds.push(session.paymentIntentId);
                    break;
                }
            }
        }

        return {
            web: purchasedWebSessionIds,
            digital: purchasedDigitalSessionIds,
            physical: purchasedPhysicalSessionIds,
        };
    }

    return undefined;
};

export default checkBookPurchases;
