// ===== Enums =====

import {
    FILTER_TYPE,
}                       from '../enums';

// ===== Constants =====

import ID_TO_THEME      from '../constants/IDToTheme';
import ID_TO_TYPE       from '../constants/IDToType';
import ID_TO_AUTHOR     from '../constants/IDToAuthor';

const tagIDToHumanID = (type: FILTER_TYPE, id: string): string => {
    let humanId: string;
    if (type === FILTER_TYPE.theme) {
        humanId = ID_TO_THEME.get(id)!;
    } else if (type === FILTER_TYPE.type) {
        humanId = ID_TO_TYPE.get(id)!;
    } else {
        humanId = ID_TO_AUTHOR.get(id)!;
    }

    return humanId;
};

export default tagIDToHumanID;
