// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
}                                       from 'react';
import {
    useNavigate,
    useLocation,
}                                       from 'react-router-dom';
import { ReactSVG }                     from 'react-svg';
import { v4 as uuidv4 }                 from 'uuid';
import {
    Graph,
    GraphNode,
    GraphLink,
}                                       from 'react-d3-graph';
import {
    css,
    FlattenSimpleInterpolation,
    Keyframes,
}                                       from 'styled-components';

// ===== Components =====

import Modal                            from '../Modal';
import Tooltip                          from '../Tooltip';
import {
    Button,
}                                       from '../Editor/helpers';

// ===== Hooks =====

import {
    useTimeout,
}                                       from '../../hooks';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    USER_ACTION_TYPE,
    BUTTON_TYPE,
    SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE,
    SOCIAL_EMERGENCE_CONNECTION_TYPE,
    TOOLTIP_TYPE,
    KEYFRAME_ANCHOR_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    updatePageTitle,
    playAudio,
    detectTouchDevice,
    isMobile,
    getMoveIndexLeftKeyframe,
    getMoveIndexRightKeyframe,
    SocialEmergenceTheory,
    roundToNDecimals,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IUserItem,
    IDimension,
    ISnackbarItem,
    ISocialEmergenceNode,
    ISocialEmergenceConnection,
}                                       from '../../interfaces';

// ===== Sounds =====

import InputClick                       from '../../sounds/button_click.mp3';
import SettingsExpand                   from '../../sounds/swoosh_in.mp3';
import SettingsContract                 from '../../sounds/swoosh_out.mp3';
import AddAgent                         from '../../sounds/create.mp3';
import RemoveAgent                      from '../../sounds/delete.mp3';
import ToggleAssembleGroupMode          from '../../sounds/logomark_thump.mp3';
import SelectAgent                      from '../../sounds/select_tag.mp3';
import SwitchGraphMode                  from '../../sounds/snap.mp3';

// ===== Images =====

import VerascopeLogo                    from '../../images/verascope-logo-silhouette.svg';
import CrossIcon                        from '../../images/cross.svg';
import CautionIcon                      from '../../images/caution.svg';
import PlusIcon                         from '../../images/plus.svg';
import MinusIcon                        from '../../images/minus.svg';
import GearIcon                         from '../../images/gear.svg';
import ReloadIcon                       from '../../images/reload.svg';
import GroupIcon                        from '../../images/group.svg';
import BadgeIcon                        from '../../images/badge.svg';
import HashIcon                         from '../../images/hash.svg';
import PuzzleIcon                       from '../../images/puzzle.svg';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    HOVER_TARGET_CLASSNAME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    UNASSIGNED_ERROR_MESSAGE,
    MILLISECONDS_IN_A_SECOND,
    MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
    MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
    CURSOR_POSITION_REGEX,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import KEYCODE                          from '../../constants/keycodes';

// ===== Styles =====

import {
    Container,
    BodyContainer,
    AgentCountInput,
    SettingsContainer,
    SettingsButtonContainer,
    SettingsExpander,
    GraphContainer,
    AdjacencyMatrixContainer,
    RowNamedAdjacencyMatrix,
    AdjacencyMatrix,
    AgentCountContainer,
    AgentCountInputContainer,
    HeaderContainer,
    AgentCountText,
    AdjacencyMatrixRow,
    AdjacencyMatrixCell,
    AdjacencyMatrixCellInput,
    AdjacencyMatrixPlaceholderText,
    AgentNameInput,
    AgentNamesContainer,
    AdjacencyMatrixColumnLabelContainer,
    AdjacencyMatrixColumnLabel,
    ResetNetworkButtonContainer,
    AssembleGroupButtonContainer,
    SettingsTooltip,
    GraphModeContainer,
    GraphModeButton,
    GraphModeButtonText,
    AssembledGroupContainer,
    AssembledGroupItem,
    AssembledGroupHeader,
    AssembledGroup,
    AssembleGroupTooltip,
    DialogContentsContainer,
    DialogCloseButton,
    DialogHeader,
    DialogBody,
    DialogAgentBodyItem,
    DialogAgentBodyItemProperty,
    DialogAgentBodyItemValue,
    AgentPropertyIconContainer,
    AgentPropertyIcon,
    AgentPropertyValueContainer,
    AgentPropertyValue,
    AgentPropertyContainer,
    AgentPropertiesContainer,
    AssembledGroupDetailContainer,
    AssembledGroupDetail,
    AssembledGroupDetailIcon,
    AssembledGroupDetailValue,
    AgentSelectedIndicator,
    AgentSelectedIndicatorContainer,
    ConnectionTypeKeyContainer,
    ConnectionTypeKeyItem,
    ConnectionTypeKeyItemText,
    ConnectionTypeKeyItemIndicator,
    GraphDisablePlaceholder,
}                                       from './styles';
import {
    PageLogo,
    PageSubtitle,
    PageTitle,
    MovingButtonBackground,
}                                       from '../../styles';
import { theme }                        from '../../themes/theme-context';

interface Props {
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    cursorRef: React.RefObject<HTMLDivElement>,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setCursorIsHidden: React.Dispatch<React.SetStateAction<boolean>>,
}
function SocialEmergenceView({
    hasSound,
    user,
    currentSessionId,
    viewportDimensions,
    cursorRef,
    setSnackbarData,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    setCursorIsHidden,
}: Props): JSX.Element {
    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== Refs =====

    const INPUT_REF_NAME = 'agentInputRef';
    const agentInputRef = useRef<HTMLInputElement>(null);
    const settingsButtonRef = useRef<HTMLButtonElement | null>(null);

    // ===== General Constants =====
    const DEFAULT_AGENTS = [
        {
            index: 0,
            name: `${1}`,
            socialCapitalRatio: null,
            rank: null,
            connections: new Map(),
        },
        {
            index: 1,
            name: `${2}`,
            socialCapitalRatio: null,
            rank: null,
            connections: new Map([
                [0, {
                    id: uuidv4(),
                    sourceIndex: 1,
                    targetIndex: 0,
                    weight: -1,
                    type: SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial,
                }],
                [2, {
                    id: uuidv4(),
                    sourceIndex: 1,
                    targetIndex: 2,
                    weight: 1,
                    type: SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial,
                }],
            ]),
        },
        {
            index: 2,
            name: `${3}`,
            socialCapitalRatio: null,
            rank: null,
            connections: new Map(),
        },
    ];
    const AGENT_MODAL_WIDTH = 330;
    const AGENT_INPUT_BUTTON_LENGTH = 25;
    const SETTINGS_BUTTON_LENGTH = 30;
    const GRAPH_MODE_CONTAINER_Z_INDEX = 1;
    const ASSEMBLE_GROUP_BUTTON_Z_INDEX = GRAPH_MODE_CONTAINER_Z_INDEX + 1;
    const RESET_NETWORK_BUTTON_Z_INDEX = GRAPH_MODE_CONTAINER_Z_INDEX + 1;
    const SETTINGS_BUTTON_Z_INDEX = GRAPH_MODE_CONTAINER_Z_INDEX + 2;
    const RESET_NETWORK_BUTTON_LENGTH = 35;
    const RESET_NETWORK_BUTTON_CONTAINER_PADDING = 2.5;
    const RESET_NETWORK_BUTTON_CONTAINER_LENGTH = RESET_NETWORK_BUTTON_LENGTH + 2 * RESET_NETWORK_BUTTON_CONTAINER_PADDING;
    const ASSEMBLE_GROUP_BUTTON_LENGTH = RESET_NETWORK_BUTTON_LENGTH;
    const ASSEMBLE_GROUP_BUTTON_CONTAINER_PADDING = RESET_NETWORK_BUTTON_CONTAINER_PADDING;
    const ASSEMBLE_GROUP_BUTTON_CONTAINER_LENGTH = RESET_NETWORK_BUTTON_CONTAINER_LENGTH;
    const SETTINGS_CONTAINER_CONTRACTED_LENGTH = RESET_NETWORK_BUTTON_CONTAINER_LENGTH;
    const MIN_AGENT_COUNT = 0;
    const MAX_AGENT_COUNT = 50;
    const AGENT_PROPERTY_ICON_LENGTH = 30;
    const AGENT_PROPERTY_TOOLTIP_WIDTH = 100;
    const AGENT_PROPERTY_TOOLTIP_HEIGHT = 50;
    const AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH = 60;
    const AGENT_PROPERTY_RANK_LENGTH = AGENT_PROPERTY_ICON_LENGTH;
    const ADJACENCY_MATRIX_CELL_LENGTH_LARGE = 50;
    const ADJACENCY_MATRIX_CELL_LENGTH_SMALL = 40;
    const ADJACENCY_MATRIX_CELL_THRESHOLD_SMALL = 6;
    const ADJACENCY_MATRIX_CELL_THRESHOLD_SCROLL = 12;
    const ADJACENCY_MATRIX_CONTAINER_FADE_IN_OFFSET = 15;
    const AGENT_COUNT_CONTAINER_FADE_IN_OFFSET = 5;
    const SNACKBAR_MESSAGE_MIN_AGENT_ERROR = `Minimum allowable agents is '${MIN_AGENT_COUNT}'`;
    const SNACKBAR_MESSAGE_MAX_AGENT_ERROR = `Maximum allowable agents is '${MAX_AGENT_COUNT}'`;
    const SNACKBAR_MESSAGE_AGENT_COUNT_NO_VALUE_ERROR = 'Please specify a valid count.';
    const SNACKBAR_MESSAGE_AGENT_NO_NAME_ERROR = 'Every agent must be named.';
    const SNACKBAR_MESSAGE_AGENT_NAME_TAKEN_ERROR = 'Agent name must be unique.';
    const AGENT_NAME_INPUT_ID_PREFIX = 'agent-name-input';
    const AGENT_NAME_LABEL_ID_PREFIX = 'agent-name-label';
    const AGENT_CELL_ID_PREFIX = 'agent-cell';
    const ID_SEPARATOR = '-';
    const GRAPH_STRING_DELIMITER_TOKEN = '|';
    const GRAPH_STRING_CONNECTIONS_SEPARATOR = ',';
    const GRAPH_STRING_CONNECTIONS_NODE_SEPARATOR = '->';
    const GRAPH_ID = 'social-network-id';
    const GRAPH_MAX_ZOOM = 8;
    const GRAPH_MIN_ZOOM = 0.1;
    const GRAPH_NODE_SIZE = 400;
    const GRAPH_SIZE_DISABLE_LIMIT = 15;
    const GRAPH_NODE_STROKE_WIDTH_NORMAL = 4;
    const GRAPH_NODE_STROKE_WIDTH_HIGHLIGHT = 6;
    const GRAPH_LINK_STROKE_WIDTH = GRAPH_NODE_STROKE_WIDTH_NORMAL;
    const GRAPH_MODE_COUNT = 2;
    const GRAPH_MODE_BUTTON_WIDTH = 100;
    const GRAPH_MODE_BUTTON_MARGIN = 5;
    const GRAPH_MODE_BUTTON_HEIGHT = RESET_NETWORK_BUTTON_LENGTH - GRAPH_MODE_BUTTON_MARGIN;
    const GRAPH_MODE_BUTTON_WIDTH_SMALL_VIEWPORT = 75;
    const GRAPH_MODE_BUTTON_HEIGHT_SMALL_VIEWPORT = 30;
    const GRAPH_CONNECTION_PERSONAL_COLOR = theme.verascopeColor.purple400;
    const GRAPH_CONNECTION_INDIRECT_COLOR = theme.verascopeColor.green200;
    const GRAPH_CONNECTION_PARASOCIAL_COLOR = theme.verascopeColor.yellow200;
    const GRAPH_CONNECTION_STRANGER_COLOR = theme.verascopeColor.red200;
    const MOVING_BUTTON_INITIAL_INDEX = 1;
    const ASSEMBLED_GROUP_ITEM_HEIGHT = 30;
    const ASSEMBLED_GROUP_CONTAINER_MAX_HEIGHT = 250;
    const ASSEMBLED_GROUP_TOOLTIP_WIDTH = 120;
    const ASSEMBLED_GROUP_TOOLTIP_HEIGHT = 60;
    const ASSEMBLED_GROUP_DETAIL_ICON_LENGTH = 25;
    const RENDERING_ROUND_TO_N_DECIMALS = 2;
    enum INPUT_TYPE {
        agentCount = 'agent-count',
        agentName = 'agent-name',
        connectionWeight = 'connection-weight',
    }
    enum GRAPH_MODE {
        trust = 'trust',
        mutualTrust = 'mutual-trust',
    }
    interface AGENT_POSITION {
        top: number,
        left: number,
    }

    // ----- Sound Clips
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());
    const settingsExpandClip = useRef<HTMLAudioElement>(new Audio());
    const settingsContractClip = useRef<HTMLAudioElement>(new Audio());
    const addAgentClip = useRef<HTMLAudioElement>(new Audio());
    const removeAgentClip = useRef<HTMLAudioElement>(new Audio());
    const toggleAssembleGroupModeClip = useRef<HTMLAudioElement>(new Audio());
    const selectAgentClip = useRef<HTMLAudioElement>(new Audio());
    const switchGraphModeClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);
    // Indicates whether the agent dialog is open caused by selecting an agent node in network
    const [agentDialogIsOpen, setAgentDialogIsOpen] = useState<boolean>(false);
    // Indicates whether settings are open
    const [settingsExpanded, setSettingsExpanded] = useState<boolean>(false);
    // indicates whether filter settings is expanding
    // used for css animations
    const [settingsExpanding, setSettingsExpanding] = useState<boolean>(false);
    // indicates whether filter settings is contracting
    // used for css animations
    const [settingsContracting, setSettingsContracting] = useState<boolean>(false);
    // stores agents in social network
    const [agents, setAgents] = useState<ISocialEmergenceNode[]>(DEFAULT_AGENTS);
    // stores a cache of agent count
    // used when finding out tallest agent name label
    const [agentCountCache, setAgentCountCache] = useState<number>(agents.length);
    // indicates whether agent count is being edited
    const [editingAgentCount, setEditingAgentCount] = useState<boolean>(false);
    // stores a cache of name of agent currently being edited
    const [agentNameCache, setAgentNameCache] = useState<string | null>(null);
    // stores a sentinel that flags when changes are made to agents
    const [agentsMetadataUpdated, setAgentsMetadataUpdated] = useState<boolean>(false);
    // stores a sentinel that flags when agents must be refreshed
    const [refreshAgents, setRefreshAgents] = useState<boolean>(false);
    const [focusedAgentNameInputIndex, setFocusedAgentNameInputIndex] = useState<number | null>(null);
    // stores tallest agent name label index
    const [tallestAgentNameLabelIndex, setTallestAgentNameLabelIndex] = useState<number | null>(null);
    // stores tallest agent name label height
    const [tallestAgentNameLabelHeight, setTallestAgentNameLabelHeight] = useState<number | null>(null);
    // indicates whether agent name has just been updated
    const [agentNameUpdated, setAgentNameUpdated] = useState<boolean>(false);
    // stores id of focused node in network
    const [focusedNodeId, setFocusedNodeId] = useState<string | undefined>(undefined);
    // stores data of focused node in network
    const [focusedNode, setFocusedNode] = useState<ISocialEmergenceNode | null>(null);
    // indicates whether social network should be reset
    const [resettingNetwork, setResettingNetwork] = useState<boolean>(false);
    // determines whether graph is directed or undirected based on trust vs mutual trust
    const [graphMode, setGraphMode] = useState<GRAPH_MODE>(GRAPH_MODE.trust);
    // stores desired state of pivot button background
    const [movingButtonBackground, setMovingButtonBackground] = useState<{ active: boolean, desiredIndex: number}>({
        active: false,
        desiredIndex: 0,
    });
    // stores the current index of the pivot button background
    const [movingButtonCurrentIndex, setMovingButtonCurrentIndex] = useState<number>(MOVING_BUTTON_INITIAL_INDEX);
    // stores the next index of the movingButtonCurrentIndex to be set following a timeout
    const [movingButtonNextIndex, setMovingButtonNextIndex] = useState<number>(MOVING_BUTTON_INITIAL_INDEX + 1);
    // indicates whether assemble group mode has activated, which enables user to pick agents to add or remove
    const [assembleGroupModeIsActive, setAssembleGroupModeIsActive] = useState<boolean>(false);
    // stores assembled group of agents
    const [assembledGroup, setAssembledGroup] = useState<Map<number, ISocialEmergenceNode>>(new Map());
    // stores selected agents' positions
    const [selectedAgentPositions, setSelectedAgentPositions] = useState<Map<number, AGENT_POSITION>>(new Map());
    // stores current graph zoom value
    const [graphZoom, setGraphZoom] = useState(1);

    // ===== Animation Constants =====

    const MODAL_CLOSE_BUTTON_TRANSITION_DURATION = 300;
    const AGENT_COUNT_TRANSITION_DURATION = 200;
    const SETTINGS_CONTAINER_TRANSITION_DURATION = 200;
    const SETTINGS_EXPAND_CONTRACT_DURATION = 300;
    const SETTINGS_TOOLTIP_TRANSITION_DURATION = 300;
    const ASSEMBLE_GROUP_TOOLTIP_TRANSITION_DURATION = SETTINGS_TOOLTIP_TRANSITION_DURATION;
    const ADJACENCY_MATRIX_CELL_TRANSITION_DURATION = AGENT_COUNT_TRANSITION_DURATION;
    const SETTINGS_CONTRACT_DELAY_DURATION = SETTINGS_EXPAND_CONTRACT_DURATION;
    const AGENT_COUNT_EXPAND_DELAY_DURATION = (2 / 3) * SETTINGS_EXPAND_CONTRACT_DURATION;
    const ADJACENCY_MATRIX_EXPAND_DELAY_DURATION = SETTINGS_EXPAND_CONTRACT_DURATION
        + (1 / 3) * AGENT_COUNT_EXPAND_DELAY_DURATION;
    const OPEN_SETTINGS_TIMEOUT_DURATION = 1000;
    const NODE_FOCUS_ANIMATION_DURATION = 1000;
    const RESETTING_NETWORK_TIMEOUT_DURATION = 200;
    const GRAPH_MODE_BUTTON_HOVER_TRANSITION_DURATION = 400;
    const GRAPH_CONTAINER_TRANSITION_DURATION = 400;

    // ===== Methods =====

    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.socialEmergence,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const onDialogCloseButtonEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.modalCloseButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onDialogCloseButtonLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    const onDialogCloseButtonClick = (): void => {
        setAgentDialogIsOpen(false);
        setFocusedNode(null);
    };

    /**
     * Manages response to button hovering over
     * @param e mouse event
     */
    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.editorButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to button no longer hovering over
     * @param e mouse event
     */
    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to graph hovering over
     * @param e mouse event
     */
    const onGraphMouseEnter = (e: React.MouseEvent): void => {
        if (!assembleGroupModeIsActive) {
            onCursorEnter(
                CURSOR_TARGET.socialEmergenceGraph,
                [CURSOR_SIGN.move],
                e.target as HTMLElement,
            );
        }
    };

    /**
     * Manages response to graph no longer hovering over
     * @param e mouse event
     */
    const onGraphMouseLeave = (e: React.MouseEvent): void => {
        if (!assembleGroupModeIsActive) {
            onCursorLeave(e);
        }
    };

    const onGraphZoomChange = (_: number, nextZoom: number): void => {
        setGraphZoom(nextZoom);
    };

    /**
     * Records user action of viewing social emergence page
     */
    const recordSocialEmergencePageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewSocialEmergenceTheoryPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    const toggleSettings = (): void => {
        clearTimeoutSettingsExpandingContracting();
        timeoutSettingsExpandingContracting();
        if (settingsExpanded) {
            setSettingsContracting(true);
        } else {
            setSettingsExpanding(true);
        }
        setSettingsExpanded(!settingsExpanded);

        // Play Sound
        if (
            !settingsExpanded
            && hasSound
            && settingsExpandClip.current
        ) {
            settingsExpandClip.current.pause();
            settingsExpandClip.current.currentTime = 0;
            playAudio(settingsExpandClip.current);
        } else if (
            settingsExpanded
            && hasSound
            && settingsContractClip.current
        ) {
            settingsContractClip.current.pause();
            settingsContractClip.current.currentTime = 0;
            playAudio(settingsContractClip.current);
        }
    };

    const onAgentCountInputFocus = (): void => {
        setInputFocused(true);

        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    const onAgentInputMouseEnter = (e: React.MouseEvent): void => {
        if (onCursorEnter) {
            onCursorEnter(
                CURSOR_TARGET.input,
                [CURSOR_SIGN.click],
                e.target as HTMLElement,
            );
        }
    };

    const onClickAgentCount = (): void => {
        setEditingAgentCount(true);
    };

    const modifyAgentCount = (count: number): void => {
        if (count === agents.length) throw Error('Attempt made to modify agent count to equivalent cardinality.');

        if (count > agents.length) {
            updateAgents([
                ...agents,
                ...Array
                    .from(Array(count - agents.length).keys())
                    .map((i) => ({
                        index: agents.length + i,
                        name: `${agents.length + i + 1}`,
                        socialCapitalRatio: null,
                        rank: null,
                        connections: new Map(),
                    })),
            ]);

            // Play Sound
            if (hasSound && addAgentClip.current) {
                addAgentClip.current.pause();
                addAgentClip.current.currentTime = 0;
                playAudio(addAgentClip.current);
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.addAgentToSocialNetwork,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        agentCount: count,
                    },
                });
            }
        } else {
            const reducedAgents = [...agents.slice(0, count)];
            const updatedAgents: ISocialEmergenceNode[] = [];
            // remove any connections associated with removed agents
            reducedAgents.forEach((agent: ISocialEmergenceNode) => {
                const updatedConnections: Map<number, ISocialEmergenceConnection> = new Map();
                Array.from(agent.connections.values()).forEach((connection: ISocialEmergenceConnection) => {
                    if (connection.targetIndex < reducedAgents.length) {
                        updatedConnections.set(connection.targetIndex, connection);
                    }
                });

                updatedAgents.push({
                    ...agent,
                    connections: updatedConnections,
                });
            });

            updateAgents(updatedAgents);

            // Play Sound
            if (hasSound && removeAgentClip.current) {
                removeAgentClip.current.pause();
                removeAgentClip.current.currentTime = 0;
                playAudio(removeAgentClip.current);
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.removeAgentFromSocialNetwork,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        agentCount: count,
                    },
                });
            }
        }
    };

    /**
     * Manages tracking enter key presses during changes to agent count input
     * @param e keyboard event
     */
    const checkForEnter = (
        inputType: INPUT_TYPE,
        e: React.KeyboardEvent,
        id?: string,
    ): void => {
        if (e.key === KEYCODE.enter) {
            let nextAgentNameInput: HTMLInputElement;
            let sourceIndex: number;
            let targetIndex: number;
            switch (inputType) {
            case INPUT_TYPE.agentName:
                if (!id) throw Error('Input id absent: unable to get element from DOM');
                nextAgentNameInput = document.getElementById(id)?.nextSibling as HTMLInputElement;
                if (nextAgentNameInput) {
                    nextAgentNameInput.focus();
                    nextAgentNameInput.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'nearest',
                        block: 'center',
                    });
                }
                break;
            case INPUT_TYPE.connectionWeight:
                if (!id) throw Error('Input id absent: unable to get element from DOM');
                sourceIndex = parseInt(id.split(ID_SEPARATOR).slice(-2)[0], 10);
                targetIndex = parseInt(id.split(ID_SEPARATOR).slice(-1)[0], 10);
                if (
                    targetIndex + 1 < agents.length
                    && sourceIndex !== targetIndex + 1
                ) {
                    const nextAgentCellInput = document
                        .getElementById(`${
                            AGENT_CELL_ID_PREFIX
                        }${
                            ID_SEPARATOR
                        }${
                            sourceIndex
                        }${
                            ID_SEPARATOR
                        }${
                            targetIndex + 1
                        }`) as HTMLInputElement;
                    if (nextAgentCellInput) {
                        nextAgentCellInput.focus();
                        nextAgentCellInput.scrollIntoView({
                            behavior: 'smooth',
                            inline: 'nearest',
                            block: 'center',
                        });
                    }
                } else if (
                    targetIndex + 1 < agents.length
                    && sourceIndex === targetIndex + 1
                    && targetIndex + 2 < agents.length
                ) {
                    const nextAgentCellInput = document
                        .getElementById(`${
                            AGENT_CELL_ID_PREFIX
                        }${
                            ID_SEPARATOR
                        }${
                            sourceIndex
                        }${
                            ID_SEPARATOR
                        }${
                            targetIndex + 2
                        }`) as HTMLInputElement;
                    if (nextAgentCellInput) {
                        nextAgentCellInput.focus();
                        nextAgentCellInput.scrollIntoView({
                            behavior: 'smooth',
                            inline: 'nearest',
                            block: 'center',
                        });
                    }
                } else if (
                    sourceIndex + 1 < agents.length
                    && sourceIndex + 1 !== 0
                ) {
                    const nextAgentCellInput = document
                        .getElementById(`${
                            AGENT_CELL_ID_PREFIX
                        }${
                            ID_SEPARATOR
                        }${
                            sourceIndex + 1
                        }${
                            ID_SEPARATOR
                        }${
                            0
                        }`) as HTMLInputElement;
                    if (nextAgentCellInput) {
                        nextAgentCellInput.focus();
                        nextAgentCellInput.scrollIntoView({
                            behavior: 'smooth',
                            inline: 'nearest',
                            block: 'center',
                        });
                    }
                }
                break;
            default:
                // Note that ref.current may be null. This is expected, because you may
                // conditionally render the ref-ed element, or you may forgot to assign it
                if (!agentInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(INPUT_REF_NAME));
                // Cancel the default action, if needed
                e.preventDefault();

                if (agentInputRef.current.value.length === 0) {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_AGENT_COUNT_NO_VALUE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                    agentInputRef.current.value = `${agents.length}`;
                } else if (parseInt(agentInputRef.current.value, 10) !== agents.length) {
                    const newAgentCount = parseInt(agentInputRef.current.value, 10);
                    modifyAgentCount(newAgentCount);
                }
                setEditingAgentCount(false);
                break;
            }
        } else if (e.key === KEYCODE.escape) {
            if (inputType === INPUT_TYPE.agentCount) {
                // Note that ref.current may be null. This is expected, because you may
                // conditionally render the ref-ed element, or you may forgot to assign it
                if (!agentInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(INPUT_REF_NAME));
                // Cancel the default action, if needed
                e.preventDefault();

                // Blur Input
                agentInputRef.current.blur();
            }
        }
    };

    const onAgentInputMouseLeave = (e: React.MouseEvent): void => {
        if (onCursorLeave) onCursorLeave(e);
    };

    const onAgentCountInputBlur = (): void => {
        setInputFocused(false);
        setEditingAgentCount(false);

        // Note that ref.current may be null. This is expected, because you may
        // conditionally render the ref-ed element, or you may forgot to assign it
        if (!agentInputRef.current) throw Error(UNASSIGNED_ERROR_MESSAGE(INPUT_REF_NAME));

        if (agentInputRef.current.value.length === 0) {
            agentInputRef.current.value = `${agents.length}`;
        }
    };

    const onIncrementAgentCount = (): void => {
        if (agents.length + 1 <= MAX_AGENT_COUNT) {
            modifyAgentCount(agents.length + 1);
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_MAX_AGENT_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
        }
    };

    const onDecrementAgentCount = (): void => {
        if (agents.length - 1 >= MIN_AGENT_COUNT) {
            modifyAgentCount(agents.length - 1);
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_MIN_AGENT_ERROR,
                icon: CautionIcon,
                hasFailure: true,
            });
        }
    };

    const updateAgent = (
        index: number,
        property: SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE,
        value: string | number | Map<number, ISocialEmergenceConnection>,
    ): void => {
        const updatedAgents = [...agents];
        updatedAgents[index] = {
            ...agents[index],
            [property]: value,
        };
        setAgents(updatedAgents);
        setRefreshAgents(!refreshAgents);
    };

    const updateAgents = (newAgents: ISocialEmergenceNode[]): void => {
        const updatedAgents = [...newAgents];
        setAgents(updatedAgents);
        setRefreshAgents(!refreshAgents);
    };

    /**
     * Augment connections with indirect connections, parasocial connections
     * and stranger connections
     */
    const refreshAgentProperties = (): void => {
        // create 2D array with only personal connections and parasocial connections
        const weightMatrix: (number | null)[][] = Array(agents.length).fill([]);
        const updatedAgents: ISocialEmergenceNode[] = [];
        agents.forEach((a: ISocialEmergenceNode, i: number) => {
            const updatedAgent = {
                ...a,
                connections: new Map(),
            };

            // populate weight matrix to use to determine indirect connections with agent connections
            weightMatrix[i] = Array(agents.length).fill(null);
            Array.from(a.connections.values()).forEach((connection: ISocialEmergenceConnection) => {
                if (
                    connection.type === SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                    || connection.type === SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial
                ) {
                    // determine if connection is parasocial or personal connection
                    const updatedConnection = {
                        ...connection,
                        type: agents[connection.targetIndex].connections.get(connection.sourceIndex)
                            && agents[connection.targetIndex].connections.get(connection.sourceIndex)!.type
                                !== SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect
                            && agents[connection.targetIndex].connections.get(connection.sourceIndex)!.type
                                !== SOCIAL_EMERGENCE_CONNECTION_TYPE.stereotype
                            && agents[connection.targetIndex].connections.get(connection.sourceIndex)!.type
                                !== SOCIAL_EMERGENCE_CONNECTION_TYPE.stranger
                            ? SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                            : SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial,
                    };

                    // only add personal and parasocial connections to updated agent
                    updatedAgent.connections.set(updatedConnection.targetIndex, updatedConnection);
                    // only add personal and parasocial connection weights to weight matrix
                    if (updatedConnection.weight > 0) {
                        // invert so that shortest path algorithm can find most trustworthy paths to destinations
                        weightMatrix[updatedConnection.sourceIndex][updatedConnection.targetIndex] = 1 / updatedConnection.weight;
                    } else if (updatedConnection.weight === 0) {
                        weightMatrix[updatedConnection.sourceIndex][updatedConnection.targetIndex] = 0;
                    } else {
                        // negate so that negative numbers become positive and higher weight than the positive numbers
                        // we minus 1 in case any numbers are -1 < x < 0, so would map into the region of the positive numbers
                        weightMatrix[updatedConnection.sourceIndex][updatedConnection.targetIndex] = -1 * (updatedConnection.weight - 1);
                    }
                }
            });

            // add agent to new array of agents
            updatedAgents.push(updatedAgent);
        });

        // Augment with indirect connections
        updatedAgents.forEach((_: ISocialEmergenceNode, agentIndex: number) => {
            for (let i = 0; i < updatedAgents.length; i += 1) {
                const sourceIndex = agentIndex;
                const targetIndex = i;
                if (agentIndex !== i && !updatedAgents[agentIndex].connections.get(i)) {
                    // connection to other agent not present
                    // determine if trust through social proof available
                    const indirectConnection = SocialEmergenceTheory.getIndirectConnection({
                        agents: updatedAgents,
                        graph: weightMatrix,
                        sourceIndex,
                        targetIndex,
                    });

                    if (indirectConnection) {
                        updatedAgents[agentIndex].connections.set(i, indirectConnection);
                    }
                }
            }
        });

        // Augment with stranger connections
        const averageTrust = SocialEmergenceTheory.computeAverageTrust({
            agents: updatedAgents,
        });
        updatedAgents.forEach((_: ISocialEmergenceNode, agentIndex: number) => {
            const { connections } = updatedAgents[agentIndex];
            for (let i = 0; i < updatedAgents.length; i += 1) {
                const startIndex = agentIndex;
                const endIndex = i;
                if (agentIndex !== i && !connections.get(i)) {
                    // connection to other agent not present
                    // create stranger connection
                    updatedAgents[agentIndex].connections.set(i, {
                        id: uuidv4(),
                        sourceIndex: startIndex,
                        targetIndex: endIndex,
                        weight: averageTrust,
                        type: SOCIAL_EMERGENCE_CONNECTION_TYPE.stranger,
                    });
                }
            }
        });

        // Determine social capital and rank of agents
        const updatedSocialCapitalAgents = SocialEmergenceTheory.computeSocialCapitalAndRank({
            agents: updatedAgents,
        });

        // update state
        setAgents(updatedSocialCapitalAgents);
        setAgentsMetadataUpdated(!agentsMetadataUpdated);
    };

    /**
     * Creates or updates the weight of a connection between two agents
     * @param sourceIndex index of source agent
     * @param targetIndex index of target agent
     * @param e keyboard event
     */
    const onAdjacencyMatrixCellChange = (
        sourceIndex: number,
        targetIndex: number,
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        if (agents.length <= sourceIndex) {
            throw Error('Unable to update agent whose index exceeds the number of existing agents.');
        }

        const agent = agents[sourceIndex];
        const newWeightValue = e.target.value;
        if (agent.connections.has(targetIndex) && newWeightValue.length > 0) {
            // update weight
            const newWeight = parseInt(e.target.value, 10);
            const oldConnection = agent.connections.get(targetIndex)!;
            const updatedConnections: Map<number, ISocialEmergenceConnection> = new Map([
                ...Array.from(agent.connections.entries()),
                [targetIndex, {
                    ...oldConnection,
                    // We assume parasocial connection and convert it when refreshing agent
                    // properties
                    type: SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial,
                    weight: newWeight,
                }],
            ]);
            updateAgent(
                sourceIndex,
                SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE.connections,
                updatedConnections,
            );

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.modifyTrustValue,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        sourceIndex,
                        targetIndex,
                        previousWeight: oldConnection.weight,
                        newWeight,
                    },
                });
            }
        } else if (agent.connections.has(targetIndex) && newWeightValue.length === 0) {
            // remove connection
            const updatedConnections: Map<number, ISocialEmergenceConnection> = new Map([
                ...Array.from(agent.connections.entries()),
            ]);
            updatedConnections.delete(targetIndex);
            updateAgent(
                sourceIndex,
                SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE.connections,
                updatedConnections,
            );

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.modifyTrustValue,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        sourceIndex,
                        targetIndex,
                        previousWeight: agent.connections.get(targetIndex)!.weight,
                        newWeight: '',
                    },
                });
            }
        } else {
            const weight = parseInt(e.target.value, 10);
            const updatedConnections: Map<number, ISocialEmergenceConnection> = new Map([
                ...Array.from(agent.connections.entries()),
                [targetIndex, {
                    id: uuidv4(),
                    sourceIndex,
                    targetIndex,
                    weight,
                    // We assume parasocial connection and convert it when refreshing agent
                    // properties
                    type: SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial,
                }],
            ]);
            updateAgent(
                sourceIndex,
                SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE.connections,
                updatedConnections,
            );

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.modifyTrustValue,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        sourceIndex,
                        targetIndex,
                        previousWeight: null,
                        newWeight: weight,
                    },
                });
            }
        }
    };

    const onAgentNameInputFocus = (sourceIndex: number): void => {
        // cache agent name
        setAgentNameCache(agents[sourceIndex].name);
        // store index of agent
        setFocusedAgentNameInputIndex(sourceIndex);
    };

    const onAgentNameInputBlur = (sourceIndex: number): void => {
        const otherAgentNames: Map<string, boolean> = new Map();
        for (let i = 0; i < agents.length; i += 1) {
            if (i !== sourceIndex) {
                otherAgentNames.set(agents[i].name, true);
            }
        }

        if (agents[sourceIndex].name.length === 0 || otherAgentNames.has(agents[sourceIndex].name)) {
            // Revert name in UI
            const agentElement: HTMLInputElement = document.getElementById(`${AGENT_NAME_INPUT_ID_PREFIX}-${sourceIndex}`) as HTMLInputElement;
            if (agentElement && agentNameCache) {
                agentElement.value = agentNameCache;
                if (agents[sourceIndex].name.length === 0) {
                    // Agent name must be at least one character
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_AGENT_NO_NAME_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                } else if (otherAgentNames.has(agents[sourceIndex].name)) {
                    // Agent name already used
                    // Must be unique
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_AGENT_NAME_TAKEN_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                }
            } else if (!agentElement) {
                throw Error('Unable to find input element associated with current input change.');
            } else {
                throw Error('Unable to find cached name of last edited agent.');
            }
            // Revert name in state
            if (agentNameCache) {
                updateAgent(
                    sourceIndex,
                    SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE.name,
                    agentNameCache,
                );
                setAgentNameUpdated(true);
            } else {
                throw Error('Unable to find cached name of last edited agent.');
            }
        } else if (user && currentSessionId) {
            // Name is valid
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.modifyAgentName,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    agentIndex: sourceIndex,
                    previousName: agentNameCache,
                    newName: agents[sourceIndex].name,
                },
            });
        }
        setFocusedAgentNameInputIndex(null);
    };

    const onAgentNameChange = (
        sourceIndex: number,
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const newName = e.target.value;
        updateAgent(
            sourceIndex,
            SOCIAL_EMERGENCE_NODE_PROPERTY_TYPE.name,
            newName,
        );
        setAgentNameUpdated(true);
    };

    const onResetNetwork = (): void => {
        if (assembleGroupModeIsActive) {
            // clear assembled group state
            setAssembledGroup(new Map());
            setSelectedAgentPositions(new Map());
            setAssembleGroupModeIsActive(false);

            // Play Sound
            if (hasSound && toggleAssembleGroupModeClip.current) {
                toggleAssembleGroupModeClip.current.pause();
                toggleAssembleGroupModeClip.current.currentTime = 0;
                playAudio(toggleAssembleGroupModeClip.current);
            }
        }

        // reset agents
        updateAgents(DEFAULT_AGENTS);

        setResettingNetwork(true);
        clearTimeoutUntoggleResettingNetwork();
        timeoutUntoggleResettingNetwork();

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.resetNetwork,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    const handleAssembleGroup = (): void => {
        const previousState = assembleGroupModeIsActive;
        const nextState = !previousState;
        if (assembleGroupModeIsActive) {
            // clear assembled group state
            setAssembledGroup(new Map());
            setSelectedAgentPositions(new Map());
        }
        setAssembleGroupModeIsActive(nextState);

        // Play Sound
        if (hasSound && toggleAssembleGroupModeClip.current) {
            toggleAssembleGroupModeClip.current.pause();
            toggleAssembleGroupModeClip.current.currentTime = 0;
            playAudio(toggleAssembleGroupModeClip.current);
        }

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: nextState
                    ? USER_ACTION_TYPE.activateAssembleGroupMode
                    : USER_ACTION_TYPE.deactivateAssembleGroupMode,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    const handleGraphMode = (
        mode: GRAPH_MODE,
        e: React.MouseEvent | React.TouchEvent,
    ): void => {
        e.stopPropagation();
        if (graphMode !== mode) {
            setGraphMode(mode);
            let index: number;
            switch (mode) {
            case GRAPH_MODE.mutualTrust:
                index = 2;
                // disband group
                if (assembledGroup.size > 0) {
                    setAssembledGroup(new Map());
                    setSelectedAgentPositions(new Map());
                }
                break;
            default:
                // GRAPH_MODE.mutualTrust
                index = 1;
                // disband group
                if (assembledGroup.size > 0) {
                    setAssembledGroup(new Map());
                    setSelectedAgentPositions(new Map());
                }
            }
            // set next moving button index used
            // in the timeout
            setMovingButtonNextIndex(index);
            // clear any revious delay
            clearDelayChangeMovingButtonIndex();
            // start new delay
            delayChangeMovingButtonIndex();
            // start moving the button by setting desired index
            setMovingButtonBackground({
                active: true,
                desiredIndex: index,
            });

            // Play Sound
            if (hasSound && switchGraphModeClip.current) {
                switchGraphModeClip.current.pause();
                switchGraphModeClip.current.currentTime = 0;
                playAudio(switchGraphModeClip.current);
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.switchGraphMode,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        mode,
                    },
                });
            }
        }
    };

    const onClickNode = (id: string): void => {
        const agentIndex = parseInt(id.split(GRAPH_STRING_DELIMITER_TOKEN)[0], 10);
        const agent = agents[agentIndex];
        if (
            assembleGroupModeIsActive
            && cursorRef.current
            && !assembledGroup.has(agent.index)
        ) {
            // add to assembled group
            const newGroup = new Map([
                ...Array.from(assembledGroup.entries()),
                [agentIndex, agent],
            ]);
            setAssembledGroup(newGroup);
            // save selected agents' position
            const cursorTranslateStyle = cursorRef.current.style.transform;
            const cursorPosition = cursorTranslateStyle.match(CURSOR_POSITION_REGEX);
            if (cursorPosition && cursorPosition.length === 2) {
                const cursorLeft = parseInt(cursorPosition[0].replace('px', ''), 10);
                const cursorTop = parseInt(cursorPosition[1].replace('px', ''), 10);
                const newPositions = new Map([
                    ...Array.from(selectedAgentPositions.entries()),
                    [agentIndex, {
                        top: cursorTop,
                        left: cursorLeft,
                    }],
                ]);
                setSelectedAgentPositions(newPositions);
            }

            // Play Sound
            if (hasSound && selectAgentClip.current) {
                selectAgentClip.current.pause();
                selectAgentClip.current.currentTime = 0;
                playAudio(selectAgentClip.current);
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.addAgentToGroup,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        agentIndex,
                    },
                });
            }
        } else if (
            assembleGroupModeIsActive
            && assembledGroup.has(agent.index)
        ) {
            // remove from social cohesion group
            let newGroup: Map<number, ISocialEmergenceNode>;
            if (assembledGroup.size > 1) {
                newGroup = new Map([
                    ...Array.from(assembledGroup.entries()),
                ]);
                newGroup.delete(agentIndex);
            } else {
                newGroup = new Map();
            }
            setAssembledGroup(newGroup);
            // remove agent from selected agents' positions
            let newPositions: Map<number, AGENT_POSITION>;
            if (selectedAgentPositions.size > 1) {
                newPositions = new Map([
                    ...Array.from(selectedAgentPositions.entries()),
                ]);
                newPositions.delete(agentIndex);
            } else {
                newPositions = new Map();
            }
            setSelectedAgentPositions(newPositions);

            // Play Sound
            if (hasSound && selectAgentClip.current) {
                selectAgentClip.current.pause();
                selectAgentClip.current.currentTime = 0;
                playAudio(selectAgentClip.current);
            }

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.removeAgentFromGroup,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        agentIndex,
                    },
                });
            }
        } else {
            setFocusedNodeId(id);
            setFocusedNode(agent);
            clearTimeoutRevealAgentDetailsDialog();
            timeoutRevealAgentDetailsDialog();

            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.focusAgent,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        agentIndex,
                    },
                });
            }
        }
    };

    const onMouseOverNode = (): void => {
        setCursorIsHidden(true);
    };

    const onMouseOutNode = (): void => {
        setCursorIsHidden(false);
    };

    const getConnectionTypeColor = (
        abType: SOCIAL_EMERGENCE_CONNECTION_TYPE,
        baType?: SOCIAL_EMERGENCE_CONNECTION_TYPE,
    ): string => {
        let color;
        if (baType) {
            if (
                abType === SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                && baType === SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
            ) {
                // personal connection
                color = GRAPH_CONNECTION_PERSONAL_COLOR;
            } else if (
                abType === SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect
                || baType === SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect
            ) {
                // indirect connection
                color = GRAPH_CONNECTION_INDIRECT_COLOR;
            } else if (
                abType === SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial
                || baType === SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial
            ) {
                // parasocial connection
                color = GRAPH_CONNECTION_PARASOCIAL_COLOR;
            } else {
                // stranger connection
                color = GRAPH_CONNECTION_STRANGER_COLOR;
            }
        } else {
            switch (abType) {
            case SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect:
                // indirect connection
                color = GRAPH_CONNECTION_INDIRECT_COLOR;
                break;
            case SOCIAL_EMERGENCE_CONNECTION_TYPE.stranger:
                // stranger connection
                color = GRAPH_CONNECTION_STRANGER_COLOR;
                break;
            case SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial:
                // parasocial connection
                color = GRAPH_CONNECTION_PARASOCIAL_COLOR;
                break;
            default:
                // personal connection
                color = GRAPH_CONNECTION_PERSONAL_COLOR;
            }
        }

        return color;
    };

    // ===== Side Effects =====

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            'Social Emergence Theory',
        );
    }, []);

    /**
     * Records View Social Emergence Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordSocialEmergencePageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            inputClickClip.current
            && settingsExpandClip.current
            && settingsContractClip.current
            && addAgentClip.current
            && removeAgentClip.current
            && toggleAssembleGroupModeClip.current
            && selectAgentClip.current
            && switchGraphModeClip.current
        ) {
            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;

            // Settings Expand
            settingsExpandClip.current.volume = DEFAULT_AUDIO_VOLUME;
            settingsExpandClip.current.src = SettingsExpand;

            // Settings Contract
            settingsContractClip.current.volume = DEFAULT_AUDIO_VOLUME;
            settingsContractClip.current.src = SettingsContract;

            // Add Agent
            addAgentClip.current.volume = DEFAULT_AUDIO_VOLUME;
            addAgentClip.current.src = AddAgent;

            // Remove Agent
            removeAgentClip.current.volume = DEFAULT_AUDIO_VOLUME;
            removeAgentClip.current.src = RemoveAgent;

            // Toggle Graph Mode
            toggleAssembleGroupModeClip.current.volume = DEFAULT_AUDIO_VOLUME;
            toggleAssembleGroupModeClip.current.src = ToggleAssembleGroupMode;

            // Select Agent
            selectAgentClip.current.volume = DEFAULT_AUDIO_VOLUME;
            selectAgentClip.current.src = SelectAgent;

            // Switch Graph Mode
            switchGraphModeClip.current.volume = DEFAULT_AUDIO_VOLUME;
            switchGraphModeClip.current.src = SwitchGraphMode;
        }

        return function cleanup() {
            if (inputClickClip.current) inputClickClip.current.remove();
            if (settingsExpandClip.current) settingsExpandClip.current.remove();
            if (settingsContractClip.current) settingsContractClip.current.remove();
            if (addAgentClip.current) addAgentClip.current.remove();
            if (removeAgentClip.current) removeAgentClip.current.remove();
            if (toggleAssembleGroupModeClip.current) toggleAssembleGroupModeClip.current.remove();
            if (selectAgentClip.current) selectAgentClip.current.remove();
            if (switchGraphModeClip.current) switchGraphModeClip.current.remove();
        };
    }, []);

    /**
     * Opens settings when page loaded
     */
    useEffect(() => {
        if (settingsButtonRef.current) {
            clearTimeoutOpenSettings();
            timeoutOpenSettings();
        }
    }, []);

    /**
     * Refresh agent properties upon initialization
     * and when agents updated
     */
    useEffect(() => {
        refreshAgentProperties();
    }, [refreshAgents]);

    /**
     * Determine the height of the tallest agent name
     * to adjust height of settings container
     */
    useEffect(() => {
        if (
            agentNameUpdated
            && focusedAgentNameInputIndex !== null
        ) {
            const focusedElement = document
                .getElementById(`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${focusedAgentNameInputIndex}`);
            const focusedElementRect = focusedElement?.getBoundingClientRect();
            if (
                focusedElementRect
                && tallestAgentNameLabelIndex !== null
                && tallestAgentNameLabelHeight !== null
                && focusedAgentNameInputIndex === tallestAgentNameLabelIndex
                && focusedElementRect.height > tallestAgentNameLabelHeight
            ) {
                // the tallest agent name label got taller
                // update height
                setTallestAgentNameLabelHeight(focusedElementRect.height);
            } else if (
                focusedElementRect
                && tallestAgentNameLabelIndex !== null
                && tallestAgentNameLabelHeight !== null
                && focusedAgentNameInputIndex === tallestAgentNameLabelIndex
                && focusedElementRect.height < tallestAgentNameLabelHeight
            ) {
                // the tallest agent name label got shorter
                // check to see if still the tallest
                let tallestAgentIndex = focusedAgentNameInputIndex;
                let tallestAgentHeight = focusedElementRect.height;
                for (let i = 0; i < agents.length; i += 1) {
                    if (i !== focusedAgentNameInputIndex) {
                        const agentElement = document
                            .getElementById(`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${i}`);
                        const agentElementRect = agentElement?.getBoundingClientRect();
                        if (
                            agentElementRect
                            && agentElementRect.height > tallestAgentHeight
                        ) {
                            tallestAgentIndex = i;
                            tallestAgentHeight = agentElementRect.height;
                        }
                    }
                }
                setTallestAgentNameLabelHeight(tallestAgentHeight);
                if (tallestAgentIndex !== tallestAgentNameLabelIndex) {
                    setTallestAgentNameLabelIndex(tallestAgentIndex);
                }
            } else if (
                focusedElementRect
                && tallestAgentNameLabelIndex !== null
                && tallestAgentNameLabelHeight !== null
                && focusedAgentNameInputIndex !== tallestAgentNameLabelIndex
                && focusedElementRect.height > tallestAgentNameLabelHeight
            ) {
                // new tallest agent name label
                // set as new tallest agent name label
                setTallestAgentNameLabelHeight(focusedElementRect.height);
                setTallestAgentNameLabelIndex(focusedAgentNameInputIndex);
            }
            setAgentNameUpdated(false);
        } else if (
            agentNameUpdated
            && focusedAgentNameInputIndex === null
        ) {
            // occurs when agent name is reverted following blur of empty agent name input
            // find tallest agent
            let tallestAgentIndex: number | null = null;
            let tallestAgentHeight: number | null = null;
            for (let i = 0; i < agents.length; i += 1) {
                const agentElement = document
                    .getElementById(`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${i}`);
                const agentElementRect = agentElement?.getBoundingClientRect();
                if (
                    agentElementRect
                    && tallestAgentHeight !== null
                    && agentElementRect.height > tallestAgentHeight
                ) {
                    tallestAgentIndex = i;
                    tallestAgentHeight = agentElementRect.height;
                } else if (
                    agentElementRect
                    && !tallestAgentHeight
                ) {
                    tallestAgentIndex = i;
                    tallestAgentHeight = agentElementRect.height;
                }
            }
            if (
                tallestAgentIndex !== null
                && tallestAgentHeight !== null
            ) {
                setTallestAgentNameLabelIndex(tallestAgentIndex);
                setTallestAgentNameLabelHeight(tallestAgentHeight);
            }
            setAgentNameUpdated(false);
        } else if (
            tallestAgentNameLabelIndex === null
            && tallestAgentNameLabelHeight === null
        ) {
            // initial render
            // find tallest agent
            let tallestAgentIndex: number | null = null;
            let tallestAgentHeight: number | null = null;
            for (let i = 0; i < agents.length; i += 1) {
                const agentElement = document
                    .getElementById(`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${i}`);
                const agentElementRect = agentElement?.getBoundingClientRect();
                if (
                    agentElementRect
                    && tallestAgentHeight !== null
                    && agentElementRect.height > tallestAgentHeight
                ) {
                    tallestAgentIndex = i;
                    tallestAgentHeight = agentElementRect.height;
                } else if (
                    agentElementRect
                    && !tallestAgentHeight
                ) {
                    tallestAgentIndex = i;
                    tallestAgentHeight = agentElementRect.height;
                }
            }
            if (
                tallestAgentIndex !== null
                && tallestAgentHeight !== null
            ) {
                setTallestAgentNameLabelIndex(tallestAgentIndex);
                setTallestAgentNameLabelHeight(tallestAgentHeight);
            }
        } else if (
            tallestAgentNameLabelIndex !== null
            && tallestAgentNameLabelHeight !== null
            && agents.length <= tallestAgentNameLabelIndex
        ) {
            // tallest agent was removed
            // find new tallest agent
            let tallestAgentIndex: number | null = null;
            let tallestAgentHeight: number | null = null;
            for (let i = 0; i < agents.length; i += 1) {
                const agentElement = document
                    .getElementById(`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${i}`);
                const agentElementRect = agentElement?.getBoundingClientRect();
                if (
                    agentElementRect
                    && tallestAgentHeight !== null
                    && agentElementRect.height > tallestAgentHeight
                ) {
                    tallestAgentIndex = i;
                    tallestAgentHeight = agentElementRect.height;
                } else if (
                    agentElementRect
                    && !tallestAgentHeight
                ) {
                    tallestAgentIndex = i;
                    tallestAgentHeight = agentElementRect.height;
                }
            }

            if (
                tallestAgentIndex !== null
                && tallestAgentHeight !== null
            ) {
                setTallestAgentNameLabelIndex(tallestAgentIndex);
                setTallestAgentNameLabelHeight(tallestAgentHeight);
            }
            // update agent count cache
            setAgentCountCache(agents.length);
        } else if (
            tallestAgentNameLabelIndex !== null
            && tallestAgentNameLabelHeight !== null
            && agents.length > agentCountCache
        ) {
            // agent was added
            // see if it is taller than currnt tallest agent name label
            const newAgentElement = document
                .getElementById(`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${agents.length - 1}`);
            const newAgentElementRect = newAgentElement?.getBoundingClientRect();
            if (
                newAgentElementRect
                && newAgentElementRect.height > tallestAgentNameLabelHeight
            ) {
                setTallestAgentNameLabelIndex(agents.length - 1);
                setTallestAgentNameLabelHeight(newAgentElementRect.height);
            }
            // update agent count cache
            setAgentCountCache(agents.length);
        }
    }, [agentNameUpdated, agents.length]);

    const {
        start: timeoutOpenSettings,
        clear: clearTimeoutOpenSettings,
    } = useTimeout(() => {
        if (settingsButtonRef.current && !isMobile()) {
            const mouseDownEvent = new Event('mousedown', {
                bubbles: true,
                cancelable: false,
            });
            settingsButtonRef.current.dispatchEvent(mouseDownEvent);
        } else if (settingsButtonRef.current && isMobile()) {
            const touch = new Touch({
                identifier: Date.now(),
                target: settingsButtonRef.current,
                clientX: 0,
                clientY: 0,
                radiusX: 2.5,
                radiusY: 2.5,
                rotationAngle: 0,
                force: 1,
            });
            const touchStartEvent = new TouchEvent('touchstart', {
                cancelable: true,
                bubbles: true,
                touches: [touch],
                targetTouches: [],
                changedTouches: [touch],
            });
            const touchEndEvent = new TouchEvent('touchend', {
                cancelable: true,
                bubbles: true,
                touches: [touch],
                targetTouches: [],
                changedTouches: [touch],
            });
            settingsButtonRef.current.dispatchEvent(touchStartEvent);
            settingsButtonRef.current.dispatchEvent(touchEndEvent);
        }
    }, OPEN_SETTINGS_TIMEOUT_DURATION);

    const {
        start: timeoutSettingsExpandingContracting,
        clear: clearTimeoutSettingsExpandingContracting,
    } = useTimeout(() => {
        if (settingsExpanding) {
            setSettingsExpanding(false);
        }
        if (settingsContracting) {
            setSettingsContracting(false);
        }
    }, SETTINGS_EXPAND_CONTRACT_DURATION);

    const {
        start: timeoutUntoggleResettingNetwork,
        clear: clearTimeoutUntoggleResettingNetwork,
    } = useTimeout(() => {
        setResettingNetwork(false);

        // reset cell inputs
        DEFAULT_AGENTS.forEach((sourceAgent: ISocialEmergenceNode) => {
            DEFAULT_AGENTS.forEach((targetAgent: ISocialEmergenceNode) => {
                const inputCellId = `${
                    AGENT_CELL_ID_PREFIX
                }${
                    ID_SEPARATOR
                }${
                    sourceAgent.index
                }${
                    ID_SEPARATOR
                }${
                    targetAgent.index
                }`;
                const cellInputElement = document.getElementById(inputCellId) as HTMLInputElement;
                const connection = agents[sourceAgent.index].connections.get(targetAgent.index);
                if (
                    connection
                    && cellInputElement
                    && (
                        connection.type === SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                        || connection.type === SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial
                    )
                ) {
                    cellInputElement.value = connection.weight.toString();
                } else if (
                    cellInputElement
                    && sourceAgent.index !== targetAgent.index
                ) {
                    cellInputElement.value = '';
                }
            });
        });
    }, RESETTING_NETWORK_TIMEOUT_DURATION);

    const {
        start: delayChangeMovingButtonIndex,
        clear: clearDelayChangeMovingButtonIndex,
    } = useTimeout(
        useCallback(() => {
            setMovingButtonBackground({
                active: true,
                desiredIndex: movingButtonNextIndex,
            });
            // Set the new index
            setMovingButtonCurrentIndex(movingButtonNextIndex);
        }, [
            movingButtonNextIndex,
        ]),
        Math.min(
            Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION,
            MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION,
        ),
    );

    const {
        start: timeoutRevealAgentDetailsDialog,
        clear: clearTimeoutRevealAgentDetailsDialog,
    } = useTimeout(() => {
        setAgentDialogIsOpen(true);
    }, NODE_FOCUS_ANIMATION_DURATION);

    // ===== Rendering =====

    const buttonAnimations = useMemo(() => {
        let animations: Map<string, Keyframes> = new Map();
        for (let i = 1; i < GRAPH_MODE_COUNT; i += 1) {
            const moveIndexLeft = getMoveIndexLeftKeyframe(
                GRAPH_MODE_BUTTON_WIDTH,
                i,
                GRAPH_MODE_BUTTON_MARGIN,
                KEYFRAME_ANCHOR_TYPE.left,
            );
            const moveIndexRight = getMoveIndexRightKeyframe(
                GRAPH_MODE_BUTTON_WIDTH,
                i,
                GRAPH_MODE_BUTTON_MARGIN,
                KEYFRAME_ANCHOR_TYPE.left,
            );
            animations = animations.set(
                `move${i}Right`,
                moveIndexRight,
            );
            animations = animations.set(
                `move${i}Left`,
                moveIndexLeft,
            );
        }

        return animations;
    }, [
        movingButtonCurrentIndex,
    ]);

    let animation: FlattenSimpleInterpolation | null = null;
    if (movingButtonBackground.active) {
        const keyframe = buttonAnimations.get(
            `move${
                Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex)
            }${
                movingButtonBackground.desiredIndex > movingButtonCurrentIndex
                    ? 'Right'
                    : 'Left'
            }`,
        );

        // Error: https://stackoverflow.com/questions/67601508/why-do-my-styled-component-keyframes-error-with-ts-styled-plugin9999-in-react
        // eslint-disable-next-line max-len
        animation = css`${keyframe} ${Math.min(Math.abs(movingButtonBackground.desiredIndex - movingButtonCurrentIndex) * MOVING_BUTTON_BACKGROUND_TRANSITION_DURATION, MOVING_BUTTON_BACKGROUND_TRANSITION_MAX_DURATION)}ms${` ${theme.motion.delayEasing}`}`;
    }

    // ===== Memoization =====

    const cellLength = useMemo(() => {
        if (agents.length < ADJACENCY_MATRIX_CELL_THRESHOLD_SMALL) {
            return ADJACENCY_MATRIX_CELL_LENGTH_LARGE;
        }

        return ADJACENCY_MATRIX_CELL_LENGTH_SMALL;
    }, [agents]);

    const movingButtonHeight = useMemo(() => (
        GRAPH_MODE_BUTTON_HEIGHT
    ), []);

    const movingButtonWidth = useMemo(() => (
        GRAPH_MODE_BUTTON_WIDTH
    ), []);

    const movingButtonIndex = useMemo(() => movingButtonCurrentIndex - 1, [movingButtonCurrentIndex]);

    const assembledSocialCapital = useMemo(() => (
        Array.from(assembledGroup.values())
            .reduce((sum: number, agent: ISocialEmergenceNode) => {
                if (agent.socialCapitalRatio) {
                    return sum + SocialEmergenceTheory.computeSocialCapital({
                        agents,
                        ratio: agent.socialCapitalRatio,
                    });
                }

                return sum;
            }, 0)
    ), [
        agentsMetadataUpdated,
        assembledGroup,
    ]);

    const graphIsDisabled = useMemo(() => agents.length > GRAPH_SIZE_DISABLE_LIMIT, [agents]);

    const network = useMemo(() => {
        const nodes: GraphNode[] = [];
        const links: GraphLink[] = [];

        if (!resettingNetwork) {
            for (let i = 0; i < agents.length; i += 1) {
                const agent = agents[i];
                let agentId = `${agent.index}${GRAPH_STRING_DELIMITER_TOKEN}`;
                Array.from(agent.connections.values()).forEach((connection, connectionIndex) => {
                    agentId += `${
                        connection.sourceIndex
                    }${
                        GRAPH_STRING_CONNECTIONS_NODE_SEPARATOR
                    }${
                        connection.targetIndex
                    }${
                        GRAPH_STRING_CONNECTIONS_NODE_SEPARATOR
                    }${
                        graphMode === GRAPH_MODE.trust
                            ? roundToNDecimals(connection.weight, RENDERING_ROUND_TO_N_DECIMALS)
                            : roundToNDecimals(
                                SocialEmergenceTheory.computeNonHierarchicalMutualTrust({
                                    abTrust: connection.weight,
                                    baTrust: agents[connection.targetIndex].connections.get(connection.sourceIndex)!.weight,
                                }),
                                RENDERING_ROUND_TO_N_DECIMALS,
                            )
                    }`;
                    if (connectionIndex + 1 !== agent.connections.size) {
                        agentId += GRAPH_STRING_CONNECTIONS_SEPARATOR;
                    }
                });
                nodes.push({
                    id: agentId,
                });
            }
            for (let i = 0; i < agents.length; i += 1) {
                const agent = agents[i];
                Array.from(agent.connections.values()).forEach((connection) => {
                    const color = graphMode === GRAPH_MODE.trust
                        ? getConnectionTypeColor(connection.type)
                        : getConnectionTypeColor(
                            connection.type,
                            agents[connection.targetIndex].connections.get(connection.sourceIndex)!.type,
                        );
                    if (
                        graphMode !== GRAPH_MODE.mutualTrust
                        || connection.sourceIndex > connection.targetIndex
                    ) {
                        links.push({
                            source: nodes[connection.sourceIndex].id,
                            target: nodes[connection.targetIndex].id,
                            color,
                        });
                    }
                });
            }
        }

        return {
            nodes,
            links,
            focusedNodeId,
        };
    }, [
        resettingNetwork,
        agents.length,
        agentsMetadataUpdated,
        focusedNodeId,
        graphMode,
    ]);

    const networkConfig = {
        automaticRearrangeAfterDropNode: false,
        collapsible: false,
        directed: graphMode === GRAPH_MODE.trust,
        focusZoom: Math.min(graphZoom * 2, GRAPH_MAX_ZOOM),
        focusAnimationDuration: NODE_FOCUS_ANIMATION_DURATION / MILLISECONDS_IN_A_SECOND,
        height: viewportDimensions.height,
        nodeHighlightBehavior: true,
        linkHighlightBehavior: true,
        highlightDegree: 1,
        highlightOpacity: 2,
        maxZoom: GRAPH_MAX_ZOOM,
        minZoom: GRAPH_MIN_ZOOM,
        freezeAllDragEvents: assembleGroupModeIsActive,
        staticGraph: assembleGroupModeIsActive,
        width: viewportDimensions.width,
        d3: {
            gravity: -500,
            linkLength: 300,
            linkStrength: 2,
        },
        node: {
            color: assembleGroupModeIsActive
                ? theme.verascopeColor.blue300
                : theme.verascopeColor.purple100,
            fontColor: theme.color.white,
            fontSize: 15,
            fontWeight: 'bold',
            highlightColor: theme.verascopeColor.purple100,
            highlightFontSize: 20,
            highlightFontWeight: 'bold',
            highlightStrokeColor: theme.color.white,
            highlightStrokeWidth: GRAPH_NODE_STROKE_WIDTH_HIGHLIGHT,
            labelProperty: (node: GraphNode) => {
                const agentIndex = parseInt(node.id.split(GRAPH_STRING_DELIMITER_TOKEN)[0], 10);
                return agents[agentIndex].name;
            },
            mouseCursor: 'none',
            opacity: 1,
            size: GRAPH_NODE_SIZE,
            strokeColor: theme.verascopeColor.orange200,
            strokeWidth: GRAPH_NODE_STROKE_WIDTH_NORMAL,
        },
        link: {
            color: theme.verascopeColor.orange100,
            fontColor: theme.verascopeColor.purple500,
            fontSize: 15,
            fontWeight: 'bold',
            highlightColor: theme.color.white,
            highlightFontSize: 20,
            highlightFontWeight: 'bold',
            labelProperty: (link: GraphLink) => {
                const sourceIndex = link.source.split(GRAPH_STRING_DELIMITER_TOKEN)[0];
                const targetIndex = link.target.split(GRAPH_STRING_DELIMITER_TOKEN)[0];
                const sourceConnections: string[] = link.source
                    .split(GRAPH_STRING_DELIMITER_TOKEN)[1]
                    .split(GRAPH_STRING_CONNECTIONS_SEPARATOR);

                let connectionWeight: string | null = null;
                for (let i = 0; i < sourceConnections.length; i += 1) {
                    if (sourceConnections[i].includes(`${sourceIndex}${GRAPH_STRING_CONNECTIONS_NODE_SEPARATOR}${targetIndex}`)) {
                        [, , connectionWeight] = sourceConnections[i].split(GRAPH_STRING_CONNECTIONS_NODE_SEPARATOR);
                        break;
                    }
                }

                if (connectionWeight) {
                    return connectionWeight;
                }

                return '?';
            },
            mouseCursor: 'none',
            renderLabel: true,
            strokeWidth: GRAPH_LINK_STROKE_WIDTH,
            markerHeight: 3,
            markerWidth: 3,
            type: graphMode === GRAPH_MODE.trust ? 'CURVE_SMOOTH' : 'STRAIGHT',
        },
    };

    return (
        <Container>
            <HeaderContainer>
                <PageLogo
                    withEnterTransition
                    className={HOVER_TARGET_CLASSNAME}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: (e) => onLogoEnter(e),
                    } : {
                        onMouseEnter: (e) => onLogoEnter(e),
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchEnd: (e) => onLogoLeave(e),
                    } : {
                        onMouseLeave: (e) => onLogoLeave(e),
                    })}
                    onMouseDown={onLogoClick}
                >
                    <ReactSVG
                        src={VerascopeLogo}
                    />
                </PageLogo>
                <PageTitle
                    withEnterTransition
                    color={theme.color.white}
                >
                    Social Emergence Theory
                </PageTitle>
                <PageSubtitle
                    withEnterTransition
                    color={theme.verascopeColor.purple400}
                >
                    Take it for a test ride
                </PageSubtitle>
            </HeaderContainer>
            <BodyContainer>
                <Modal
                    hasContainer
                    width={AGENT_MODAL_WIDTH}
                    isOpen={agentDialogIsOpen && !!focusedNode}
                    hasSound={hasSound}
                    closeModal={onDialogCloseButtonClick}
                >
                    <DialogContentsContainer>
                        <DialogCloseButton
                            className={HOVER_TARGET_CLASSNAME}
                            transitionDuration={MODAL_CLOSE_BUTTON_TRANSITION_DURATION}
                            onMouseEnter={onDialogCloseButtonEnter}
                            onMouseLeave={onDialogCloseButtonLeave}
                            onClick={onDialogCloseButtonClick}
                        >
                            <ReactSVG
                                src={CrossIcon}
                            />
                        </DialogCloseButton>
                        <DialogHeader>
                            Agent Details
                        </DialogHeader>
                        <DialogBody>
                            <DialogAgentBodyItem>
                                <DialogAgentBodyItemProperty>
                                    Name
                                </DialogAgentBodyItemProperty>
                                <DialogAgentBodyItemValue>
                                    {focusedNode && focusedNode.name}
                                </DialogAgentBodyItemValue>
                            </DialogAgentBodyItem>
                            <DialogAgentBodyItem>
                                <DialogAgentBodyItemProperty>
                                    Social Capital
                                </DialogAgentBodyItemProperty>
                                <DialogAgentBodyItemValue>
                                    {focusedNode && focusedNode.socialCapitalRatio}
                                </DialogAgentBodyItemValue>
                            </DialogAgentBodyItem>
                        </DialogBody>
                    </DialogContentsContainer>
                </Modal>
                <SettingsContainer
                    expanded={settingsExpanded}
                    settingsExpanding={settingsExpanding}
                    settingsContracting={settingsContracting}
                    contractedLength={SETTINGS_CONTAINER_CONTRACTED_LENGTH}
                    expandDuration={SETTINGS_EXPAND_CONTRACT_DURATION}
                    exitDelayDuration={SETTINGS_CONTRACT_DELAY_DURATION}
                    cellLength={cellLength}
                    agentPropertyRankLength={AGENT_PROPERTY_RANK_LENGTH}
                    agentPropertySocialCapitalLength={AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH}
                    agentCount={agents.length}
                    maxCellScrollThreshold={ADJACENCY_MATRIX_CELL_THRESHOLD_SCROLL}
                    zIndex={SETTINGS_BUTTON_Z_INDEX}
                    tallestAgentNameLabelHeight={tallestAgentNameLabelHeight || 0}
                    transitionDuration={SETTINGS_CONTAINER_TRANSITION_DURATION}
                >
                    <SettingsTooltip
                        settingsExpanded={settingsExpanded}
                        settingsExpanding={settingsExpanding}
                        settingsContracting={settingsContracting}
                        transitionDuration={SETTINGS_TOOLTIP_TRANSITION_DURATION}
                    >
                        Configure Network
                    </SettingsTooltip>
                    <AdjacencyMatrixContainer
                        visible={settingsExpanded}
                        fadeInOffset={ADJACENCY_MATRIX_CONTAINER_FADE_IN_OFFSET}
                        scrollable={agents.length >= ADJACENCY_MATRIX_CELL_THRESHOLD_SCROLL}
                        cellLength={cellLength}
                        agentPropertyRankLength={AGENT_PROPERTY_RANK_LENGTH}
                        agentPropertySocialCapitalLength={AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH}
                        maxCellScrollThreshold={ADJACENCY_MATRIX_CELL_THRESHOLD_SCROLL}
                        expandDuration={SETTINGS_EXPAND_CONTRACT_DURATION}
                        enterDelayDuration={ADJACENCY_MATRIX_EXPAND_DELAY_DURATION}
                    >
                        <AdjacencyMatrixColumnLabelContainer
                            cellLength={cellLength}
                            agentCount={agents.length}
                            agentPropertyRankLength={AGENT_PROPERTY_RANK_LENGTH}
                            agentPropertySocialCapitalLength={AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH}
                        >
                            {agents.map((agent: ISocialEmergenceNode) => (
                                <AdjacencyMatrixColumnLabel
                                    key={agent.index}
                                    id={`${AGENT_NAME_LABEL_ID_PREFIX}${ID_SEPARATOR}${agent.index}`}
                                    cellLength={cellLength}
                                    unnamed={!agent.name}
                                >
                                    {agent.name || 'Unnamed'}
                                </AdjacencyMatrixColumnLabel>
                            ))}
                            <AgentPropertyIconContainer>
                                <AgentPropertyIcon
                                    paddingLeft
                                    iconLength={AGENT_PROPERTY_ICON_LENGTH}
                                    length={AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH}
                                >
                                    <Tooltip
                                        text="Social Capital (prestige)"
                                        side={TOOLTIP_TYPE.top}
                                        tooltipStyle={{
                                            width: AGENT_PROPERTY_TOOLTIP_WIDTH,
                                            height: AGENT_PROPERTY_TOOLTIP_HEIGHT,
                                        }}
                                    />
                                    <ReactSVG
                                        src={BadgeIcon}
                                    />
                                </AgentPropertyIcon>
                                <AgentPropertyIcon
                                    iconLength={AGENT_PROPERTY_ICON_LENGTH}
                                    length={AGENT_PROPERTY_RANK_LENGTH}
                                >
                                    <Tooltip
                                        text="Social Rank"
                                        side={TOOLTIP_TYPE.top}
                                        tooltipStyle={{
                                            width: AGENT_PROPERTY_TOOLTIP_WIDTH,
                                            height: AGENT_PROPERTY_TOOLTIP_HEIGHT,
                                        }}
                                    />
                                    <ReactSVG
                                        src={HashIcon}
                                    />
                                </AgentPropertyIcon>
                            </AgentPropertyIconContainer>
                        </AdjacencyMatrixColumnLabelContainer>
                        <RowNamedAdjacencyMatrix>
                            <AgentNamesContainer>
                                {agents.map((sourceAgent: ISocialEmergenceNode) => {
                                    const id = `${AGENT_NAME_INPUT_ID_PREFIX}${ID_SEPARATOR}${sourceAgent.index}`;
                                    return (
                                        <AgentNameInput
                                            key={sourceAgent.index}
                                            type="text"
                                            id={id}
                                            className={HOVER_TARGET_CLASSNAME}
                                            transitionDuration={ADJACENCY_MATRIX_CELL_TRANSITION_DURATION}
                                            defaultValue={`${sourceAgent.name || (sourceAgent.index + 1)}`}
                                            placeholder="Agent Name..."
                                            onChange={(e) => onAgentNameChange(sourceAgent.index, e)}
                                            onMouseEnter={onAgentInputMouseEnter}
                                            onMouseLeave={onAgentInputMouseLeave}
                                            onFocus={() => onAgentNameInputFocus(sourceAgent.index)}
                                            onBlur={() => onAgentNameInputBlur(sourceAgent.index)}
                                            onKeyUp={(e) => checkForEnter(
                                                INPUT_TYPE.agentName,
                                                e,
                                                id,
                                            )}
                                            cellLength={cellLength}
                                        />
                                    );
                                })}
                            </AgentNamesContainer>
                            <AdjacencyMatrix
                                agentCount={agents.length}
                            >
                                {agents.map((sourceAgent: ISocialEmergenceNode) => (
                                    <AdjacencyMatrixRow
                                        key={sourceAgent.index}
                                    >
                                        {agents.map((targetAgent: ISocialEmergenceNode) => {
                                            const id = `${
                                                AGENT_CELL_ID_PREFIX
                                            }${
                                                ID_SEPARATOR
                                            }${
                                                sourceAgent.index
                                            }${
                                                ID_SEPARATOR
                                            }${
                                                targetAgent.index
                                            }`;

                                            let background = theme.verascopeColor.purple400;
                                            if (
                                                graphMode === GRAPH_MODE.trust
                                                && !assembleGroupModeIsActive
                                                && agents[sourceAgent.index].connections.get(targetAgent.index)
                                            ) {
                                                background = getConnectionTypeColor(
                                                    agents[sourceAgent.index].connections.get(targetAgent.index)!.type,
                                                );
                                            }

                                            if ((
                                                graphMode === GRAPH_MODE.trust
                                                && sourceAgent.index === targetAgent.index
                                            ) || (
                                                graphMode === GRAPH_MODE.mutualTrust
                                                && sourceAgent.index >= targetAgent.index
                                            )) {
                                                return (
                                                    <AdjacencyMatrixCell
                                                        key={`${sourceAgent.index}${targetAgent.index}`}
                                                        disable
                                                        rowIndex={sourceAgent.index}
                                                        colIndex={targetAgent.index}
                                                        cellLength={cellLength}
                                                        agentCount={agents.length}
                                                        compact={agents.length >= ADJACENCY_MATRIX_CELL_THRESHOLD_SMALL}
                                                        background={background}
                                                    >
                                                        {sourceAgent.index === targetAgent.index ? '0' : '-'}
                                                    </AdjacencyMatrixCell>
                                                );
                                            }

                                            if (graphMode === GRAPH_MODE.mutualTrust) {
                                                let mutualTrust = '-';
                                                if (
                                                    agents[sourceAgent.index].connections.get(targetAgent.index)
                                                    && agents[targetAgent.index].connections.get(sourceAgent.index)
                                                ) {
                                                    mutualTrust = roundToNDecimals(
                                                        SocialEmergenceTheory.computeNonHierarchicalMutualTrust({
                                                            abTrust: agents[sourceAgent.index].connections.get(targetAgent.index)!.weight,
                                                            baTrust: agents[targetAgent.index].connections.get(sourceAgent.index)!.weight,
                                                        }),
                                                        RENDERING_ROUND_TO_N_DECIMALS,
                                                    ).toString();
                                                }
                                                return (
                                                    <AdjacencyMatrixCell
                                                        key={`${sourceAgent.index}${targetAgent.index}`}
                                                        rowIndex={sourceAgent.index}
                                                        colIndex={targetAgent.index}
                                                        cellLength={cellLength}
                                                        agentCount={agents.length}
                                                        compact={agents.length >= ADJACENCY_MATRIX_CELL_THRESHOLD_SMALL}
                                                        background={background}
                                                    >
                                                        {mutualTrust}
                                                    </AdjacencyMatrixCell>
                                                );
                                            }

                                            if (assembleGroupModeIsActive) {
                                                return (
                                                    <AdjacencyMatrixCell
                                                        key={`${sourceAgent.index}${targetAgent.index}`}
                                                        rowIndex={sourceAgent.index}
                                                        colIndex={targetAgent.index}
                                                        cellLength={cellLength}
                                                        agentCount={agents.length}
                                                        compact={agents.length >= ADJACENCY_MATRIX_CELL_THRESHOLD_SMALL}
                                                        background={background}
                                                    >
                                                        {agents[sourceAgent.index]
                                                            .connections.get(targetAgent.index)!.weight.toString()}
                                                    </AdjacencyMatrixCell>
                                                );
                                            }

                                            return (
                                                <AdjacencyMatrixCell
                                                    key={`${sourceAgent.index}${targetAgent.index}`}
                                                    rowIndex={sourceAgent.index}
                                                    colIndex={targetAgent.index}
                                                    cellLength={cellLength}
                                                    agentCount={agents.length}
                                                    compact={agents.length >= ADJACENCY_MATRIX_CELL_THRESHOLD_SMALL}
                                                    background={background}
                                                >
                                                    <AdjacencyMatrixCellInput
                                                        type="number"
                                                        id={id}
                                                        className={HOVER_TARGET_CLASSNAME}
                                                        transitionDuration={ADJACENCY_MATRIX_CELL_TRANSITION_DURATION}
                                                        {...(agents[sourceAgent.index].connections.has(targetAgent.index)
                                                            && (
                                                                agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                                === SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                                                                || agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                                === SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial
                                                            ) ? {
                                                                defaultValue: agents[sourceAgent.index]
                                                                    .connections.get(targetAgent.index)!.weight.toString(),
                                                            } : {})}
                                                        {...(agents[sourceAgent.index].connections.has(targetAgent.index)
                                                            && agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                            !== SOCIAL_EMERGENCE_CONNECTION_TYPE.personal
                                                            && agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                            !== SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial
                                                            && (
                                                                agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                                === SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect
                                                                || agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                                === SOCIAL_EMERGENCE_CONNECTION_TYPE.stereotype
                                                                || agents[sourceAgent.index].connections.get(targetAgent.index)!.type
                                                                === SOCIAL_EMERGENCE_CONNECTION_TYPE.stranger
                                                            ) ? {
                                                                placeholder: roundToNDecimals(
                                                                    agents[sourceAgent.index]
                                                                        .connections.get(targetAgent.index)!.weight,
                                                                    RENDERING_ROUND_TO_N_DECIMALS,
                                                                ).toString(),
                                                            } : {
                                                                placeholder: 'w',
                                                            })}
                                                        onChange={(e) => onAdjacencyMatrixCellChange(sourceAgent.index, targetAgent.index, e)}
                                                        onMouseEnter={onAgentInputMouseEnter}
                                                        onMouseLeave={onAgentInputMouseLeave}
                                                        onKeyUp={(e) => checkForEnter(
                                                            INPUT_TYPE.connectionWeight,
                                                            e,
                                                            id,
                                                        )}
                                                    />
                                                </AdjacencyMatrixCell>
                                            );
                                        })}
                                    </AdjacencyMatrixRow>
                                ))}
                                {agents.length === 0 && (
                                    <AdjacencyMatrixPlaceholderText>
                                        Adjacency Matrix is empty
                                    </AdjacencyMatrixPlaceholderText>
                                )}
                            </AdjacencyMatrix>
                            <AgentPropertiesContainer>
                                {agents.map((sourceAgent: ISocialEmergenceNode) => {
                                    let socialCapital = '-';

                                    if (sourceAgent.socialCapitalRatio !== null) {
                                        socialCapital = roundToNDecimals(
                                            SocialEmergenceTheory.computeSocialCapital({
                                                agents,
                                                ratio: sourceAgent.socialCapitalRatio,
                                            }),
                                            RENDERING_ROUND_TO_N_DECIMALS,
                                        ).toString();
                                    }

                                    return (
                                        <AgentPropertyContainer
                                            key={sourceAgent.index}
                                            cellLength={cellLength}
                                            width={AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH + AGENT_PROPERTY_RANK_LENGTH}
                                        >
                                            <AgentPropertyValueContainer
                                                width={AGENT_PROPERTY_SOCIAL_CAPITAL_LENGTH}
                                            >
                                                <AgentPropertyValue
                                                    length={AGENT_PROPERTY_ICON_LENGTH}
                                                >
                                                    {socialCapital}
                                                </AgentPropertyValue>
                                            </AgentPropertyValueContainer>
                                            <AgentPropertyValueContainer
                                                width={AGENT_PROPERTY_RANK_LENGTH}
                                            >
                                                <AgentPropertyValue
                                                    length={AGENT_PROPERTY_ICON_LENGTH}
                                                >
                                                    {sourceAgent.rank !== null ? sourceAgent.rank + 1 : '-'}
                                                </AgentPropertyValue>
                                            </AgentPropertyValueContainer>
                                        </AgentPropertyContainer>
                                    );
                                })}
                            </AgentPropertiesContainer>
                        </RowNamedAdjacencyMatrix>
                    </AdjacencyMatrixContainer>
                    <SettingsButtonContainer
                        length={SETTINGS_BUTTON_LENGTH}
                        settingsExpanded={settingsExpanded}
                        transitionDuration={SETTINGS_CONTAINER_TRANSITION_DURATION}
                    >
                        <SettingsExpander
                            className={HOVER_TARGET_CLASSNAME}
                            ref={settingsButtonRef}
                            length={SETTINGS_BUTTON_LENGTH}
                            expanded={settingsExpanded}
                            expandDuration={SETTINGS_EXPAND_CONTRACT_DURATION}
                            transitionDuration={SETTINGS_EXPAND_CONTRACT_DURATION}
                            onMouseEnter={onButtonMouseEnter}
                            onMouseLeave={onButtonMouseLeave}
                            {...(detectTouchDevice(document) ? {
                                onTouchStart: toggleSettings,
                            } : {
                                onMouseDown: toggleSettings,
                            })}
                        >
                            <ReactSVG
                                src={GearIcon}
                            />
                        </SettingsExpander>
                    </SettingsButtonContainer>
                    <AgentCountContainer
                        visible={settingsExpanded}
                        leftOffset={SETTINGS_BUTTON_LENGTH}
                        fadeInOffset={AGENT_COUNT_CONTAINER_FADE_IN_OFFSET}
                        expandDuration={SETTINGS_EXPAND_CONTRACT_DURATION}
                        enterDelayDuration={AGENT_COUNT_EXPAND_DELAY_DURATION}
                        transitionDuration={SETTINGS_CONTAINER_TRANSITION_DURATION}
                    >
                        <AgentCountInputContainer
                            length={SETTINGS_BUTTON_LENGTH}
                            transitionDuration={AGENT_COUNT_TRANSITION_DURATION}
                        >
                            <Button
                                className={HOVER_TARGET_CLASSNAME}
                                type={BUTTON_TYPE.secret}
                                background={theme.color.neutral500}
                                height={AGENT_INPUT_BUTTON_LENGTH}
                                width={AGENT_INPUT_BUTTON_LENGTH}
                                icon={MinusIcon}
                                disabled={agents.length - 1 < MIN_AGENT_COUNT}
                                {...(agents.length - 1 >= MIN_AGENT_COUNT
                                    ? {
                                        onMouseEnter: onButtonMouseEnter,
                                        onMouseLeave: onButtonMouseLeave,
                                    } : {})}
                                {...(detectTouchDevice(document) ? {
                                    onTouchStart: onDecrementAgentCount,
                                } : {
                                    onMouseDown: onDecrementAgentCount,
                                })}
                            />
                            {editingAgentCount ? (
                                <AgentCountInput
                                    type="number"
                                    min={MIN_AGENT_COUNT}
                                    max={MAX_AGENT_COUNT}
                                    ref={agentInputRef}
                                    className={HOVER_TARGET_CLASSNAME}
                                    defaultValue={agents.length}
                                    placeholder="#"
                                    onKeyUp={(e) => checkForEnter(
                                        INPUT_TYPE.agentCount,
                                        e,
                                    )}
                                    onFocus={onAgentCountInputFocus}
                                    onBlur={onAgentCountInputBlur}
                                />
                            ) : (
                                <AgentCountText
                                    className={HOVER_TARGET_CLASSNAME}
                                    transitionDuration={AGENT_COUNT_TRANSITION_DURATION}
                                    onMouseEnter={onAgentInputMouseEnter}
                                    onMouseLeave={onAgentInputMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: onClickAgentCount,
                                    } : {
                                        onMouseDown: onClickAgentCount,
                                    })}
                                >
                                    {agents.length}
                                </AgentCountText>
                            )}
                            <Button
                                className={HOVER_TARGET_CLASSNAME}
                                type={BUTTON_TYPE.secret}
                                background={theme.color.neutral500}
                                height={AGENT_INPUT_BUTTON_LENGTH}
                                width={AGENT_INPUT_BUTTON_LENGTH}
                                icon={PlusIcon}
                                disabled={agents.length + 1 > MAX_AGENT_COUNT}
                                {...(agents.length + 1 <= MAX_AGENT_COUNT
                                    ? {
                                        onMouseEnter: onButtonMouseEnter,
                                        onMouseLeave: onButtonMouseLeave,
                                    } : {})}
                                {...(detectTouchDevice(document) ? {
                                    onTouchStart: onIncrementAgentCount,
                                } : {
                                    onMouseDown: onIncrementAgentCount,
                                })}
                            />
                        </AgentCountInputContainer>
                    </AgentCountContainer>
                </SettingsContainer>
                <ResetNetworkButtonContainer
                    length={RESET_NETWORK_BUTTON_CONTAINER_LENGTH}
                    padding={RESET_NETWORK_BUTTON_CONTAINER_PADDING}
                    zIndex={RESET_NETWORK_BUTTON_Z_INDEX}
                >
                    <Button
                        className={HOVER_TARGET_CLASSNAME}
                        type={BUTTON_TYPE.secret}
                        background={theme.color.neutral500}
                        height={RESET_NETWORK_BUTTON_LENGTH}
                        width={RESET_NETWORK_BUTTON_LENGTH}
                        icon={ReloadIcon}
                        tooltip={{
                            active: true,
                            text: 'Reset',
                            side: TOOLTIP_TYPE.left,
                        }}
                        disabled={false}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: onResetNetwork,
                        } : {
                            onMouseDown: onResetNetwork,
                        })}
                    />
                </ResetNetworkButtonContainer>
                <AssembleGroupButtonContainer
                    assembleGroupModeIsActive={assembleGroupModeIsActive}
                    length={ASSEMBLE_GROUP_BUTTON_CONTAINER_LENGTH}
                    padding={ASSEMBLE_GROUP_BUTTON_CONTAINER_PADDING}
                    resetButtonLength={RESET_NETWORK_BUTTON_CONTAINER_LENGTH}
                    zIndex={ASSEMBLE_GROUP_BUTTON_Z_INDEX}
                >
                    <AssembleGroupTooltip
                        visible={assembleGroupModeIsActive}
                        transitionDuration={ASSEMBLE_GROUP_TOOLTIP_TRANSITION_DURATION}
                    >
                        Select agents from network to add to group
                    </AssembleGroupTooltip>
                    <Button
                        className={HOVER_TARGET_CLASSNAME}
                        type={BUTTON_TYPE.secret}
                        background={assembleGroupModeIsActive
                            ? theme.color.white
                            : theme.color.neutral500}
                        height={ASSEMBLE_GROUP_BUTTON_LENGTH}
                        width={ASSEMBLE_GROUP_BUTTON_LENGTH}
                        icon={GroupIcon}
                        tooltip={{
                            active: true,
                            text: assembleGroupModeIsActive
                                ? 'Disband Group'
                                : 'Assemble Group',
                            side: TOOLTIP_TYPE.left,
                        }}
                        disabled={graphIsDisabled}
                        {...(!graphIsDisabled
                            ? {
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            } : {})}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: handleAssembleGroup,
                        } : {
                            onMouseDown: handleAssembleGroup,
                        })}
                    />
                </AssembleGroupButtonContainer>
                <GraphContainer
                    className={HOVER_TARGET_CLASSNAME}
                    assembleGroupModeIsActive={assembleGroupModeIsActive}
                    transitionDuration={GRAPH_CONTAINER_TRANSITION_DURATION}
                    onMouseEnter={onGraphMouseEnter}
                    onMouseLeave={onGraphMouseLeave}
                >
                    {!graphIsDisabled
                        ? (
                            <Graph
                                id={GRAPH_ID}
                                data={network}
                                config={networkConfig}
                                onClickNode={onClickNode}
                                onMouseOverNode={onMouseOverNode}
                                onMouseOutNode={onMouseOutNode}
                                onZoomChange={onGraphZoomChange}
                            />
                        ) : (
                            <GraphDisablePlaceholder>
                                Network too large to display.
                            </GraphDisablePlaceholder>
                        )}
                    <AgentSelectedIndicatorContainer>
                        {Array.from(selectedAgentPositions.values()).map((position: AGENT_POSITION) => (
                            <AgentSelectedIndicator
                                top={position.top}
                                left={position.left}
                                graphZoom={graphZoom}
                                nodeLength={GRAPH_NODE_SIZE}
                            />
                        ))}
                    </AgentSelectedIndicatorContainer>
                </GraphContainer>
                <GraphModeContainer
                    buttonCount={GRAPH_MODE_COUNT}
                    resetButtonLength={RESET_NETWORK_BUTTON_CONTAINER_LENGTH}
                    buttonMargin={GRAPH_MODE_BUTTON_MARGIN}
                    buttonWidth={GRAPH_MODE_BUTTON_WIDTH}
                    buttonHeight={GRAPH_MODE_BUTTON_HEIGHT}
                    zIndex={GRAPH_MODE_CONTAINER_Z_INDEX}
                >
                    <MovingButtonBackground
                        solid
                        visible
                        disabled={assembleGroupModeIsActive}
                        color={theme.verascopeColor.purple400}
                        top={GRAPH_MODE_BUTTON_MARGIN}
                        buttonHeight={movingButtonHeight}
                        buttonWidth={movingButtonWidth}
                        animation={animation}
                        spacing={GRAPH_MODE_BUTTON_MARGIN}
                        xOffset={GRAPH_MODE_BUTTON_MARGIN / 2}
                        index={movingButtonIndex}
                        transitionDuration={GRAPH_MODE_BUTTON_HOVER_TRANSITION_DURATION}
                    />
                    <GraphModeButton
                        className={HOVER_TARGET_CLASSNAME}
                        disabled={assembleGroupModeIsActive}
                        selected={graphMode === GRAPH_MODE.trust}
                        hoverDuration={GRAPH_MODE_BUTTON_HOVER_TRANSITION_DURATION}
                        index={0}
                        buttonMargin={GRAPH_MODE_BUTTON_MARGIN}
                        buttonWidth={GRAPH_MODE_BUTTON_WIDTH}
                        buttonHeight={GRAPH_MODE_BUTTON_HEIGHT}
                        smallButtonWidth={GRAPH_MODE_BUTTON_WIDTH_SMALL_VIEWPORT}
                        smallButtonHeight={GRAPH_MODE_BUTTON_HEIGHT_SMALL_VIEWPORT}
                        {...(assembleGroupModeIsActive
                            ? {
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            } : {})}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: (e) => handleGraphMode(GRAPH_MODE.trust, e),
                        } : {
                            onMouseDown: (e) => handleGraphMode(GRAPH_MODE.trust, e),
                        })}
                    >
                        <GraphModeButtonText
                            transitionDuration={GRAPH_MODE_BUTTON_HOVER_TRANSITION_DURATION}
                        >
                            Trust
                        </GraphModeButtonText>
                    </GraphModeButton>
                    <GraphModeButton
                        className={HOVER_TARGET_CLASSNAME}
                        disabled={assembleGroupModeIsActive}
                        selected={graphMode === GRAPH_MODE.mutualTrust}
                        hoverDuration={GRAPH_MODE_BUTTON_HOVER_TRANSITION_DURATION}
                        index={1}
                        buttonMargin={GRAPH_MODE_BUTTON_MARGIN}
                        buttonWidth={GRAPH_MODE_BUTTON_WIDTH}
                        buttonHeight={GRAPH_MODE_BUTTON_HEIGHT}
                        smallButtonWidth={GRAPH_MODE_BUTTON_WIDTH_SMALL_VIEWPORT}
                        smallButtonHeight={GRAPH_MODE_BUTTON_HEIGHT_SMALL_VIEWPORT}
                        {...(!assembleGroupModeIsActive
                            ? {
                                onMouseEnter: onButtonMouseEnter,
                                onMouseLeave: onButtonMouseLeave,
                            } : {})}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: (e) => handleGraphMode(GRAPH_MODE.mutualTrust, e),
                        } : {
                            onMouseDown: (e) => handleGraphMode(GRAPH_MODE.mutualTrust, e),
                        })}
                    >
                        <GraphModeButtonText
                            transitionDuration={GRAPH_MODE_BUTTON_HOVER_TRANSITION_DURATION}
                        >
                            Mutual Trust
                        </GraphModeButtonText>
                    </GraphModeButton>
                </GraphModeContainer>
                {assembledGroup.size > 0 && (
                    <AssembledGroupContainer>
                        <AssembledGroupHeader>
                            Group
                        </AssembledGroupHeader>
                        <AssembledGroup
                            itemCount={assembledGroup.size}
                            itemHeight={ASSEMBLED_GROUP_ITEM_HEIGHT}
                            maxHeight={ASSEMBLED_GROUP_CONTAINER_MAX_HEIGHT}
                        >
                            {Array.from(assembledGroup.values()).map((agent: ISocialEmergenceNode) => (
                                <AssembledGroupItem
                                    key={agent.index}
                                >
                                    {agent.name}
                                </AssembledGroupItem>
                            ))}
                        </AssembledGroup>
                        <AssembledGroupDetailContainer>
                            <AssembledGroupDetail>
                                <Tooltip
                                    text="Social Cohesion (coherence)"
                                    side={TOOLTIP_TYPE.left}
                                    tooltipStyle={{
                                        width: ASSEMBLED_GROUP_TOOLTIP_WIDTH,
                                        height: ASSEMBLED_GROUP_TOOLTIP_HEIGHT,
                                    }}
                                />
                                <AssembledGroupDetailIcon
                                    length={ASSEMBLED_GROUP_DETAIL_ICON_LENGTH}
                                >
                                    <ReactSVG
                                        src={PuzzleIcon}
                                    />
                                </AssembledGroupDetailIcon>
                                <AssembledGroupDetailValue>
                                    {roundToNDecimals(
                                        SocialEmergenceTheory
                                            .computeSocialCohesion({
                                                agents: Array.from(assembledGroup.values()),
                                            }),
                                        RENDERING_ROUND_TO_N_DECIMALS,
                                    )}
                                </AssembledGroupDetailValue>
                            </AssembledGroupDetail>
                            <AssembledGroupDetail>
                                <Tooltip
                                    text="Social Capital (prestige)"
                                    side={TOOLTIP_TYPE.left}
                                    tooltipStyle={{
                                        width: ASSEMBLED_GROUP_TOOLTIP_WIDTH,
                                        height: ASSEMBLED_GROUP_TOOLTIP_HEIGHT,
                                    }}
                                />
                                <AssembledGroupDetailIcon
                                    length={ASSEMBLED_GROUP_DETAIL_ICON_LENGTH}
                                >
                                    <ReactSVG
                                        src={BadgeIcon}
                                    />
                                </AssembledGroupDetailIcon>
                                <AssembledGroupDetailValue>
                                    {roundToNDecimals(
                                        assembledSocialCapital,
                                        RENDERING_ROUND_TO_N_DECIMALS,
                                    )}
                                </AssembledGroupDetailValue>
                            </AssembledGroupDetail>
                        </AssembledGroupDetailContainer>
                    </AssembledGroupContainer>
                )}
                <ConnectionTypeKeyContainer>
                    <ConnectionTypeKeyItem>
                        <Tooltip
                            text="Two-sided working relationship."
                            side={TOOLTIP_TYPE.right}
                            tooltipStyle={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 180,
                                height: 50,
                            }}
                        />
                        <ConnectionTypeKeyItemIndicator
                            type={SOCIAL_EMERGENCE_CONNECTION_TYPE.personal}
                        />
                        <ConnectionTypeKeyItemText>
                            Personal
                        </ConnectionTypeKeyItemText>
                    </ConnectionTypeKeyItem>
                    <ConnectionTypeKeyItem>
                        <Tooltip
                            text="One-sided relationship induced by information from trusted personal connection."
                            side={TOOLTIP_TYPE.right}
                            tooltipStyle={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 200,
                                height: 70,
                            }}
                        />
                        <ConnectionTypeKeyItemIndicator
                            type={SOCIAL_EMERGENCE_CONNECTION_TYPE.indirect}
                        />
                        <ConnectionTypeKeyItemText>
                            Indirect
                        </ConnectionTypeKeyItemText>
                    </ConnectionTypeKeyItem>
                    <ConnectionTypeKeyItem>
                        <Tooltip
                            text="One-sided relationship with noteworthy individual."
                            side={TOOLTIP_TYPE.right}
                            tooltipStyle={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 200,
                                height: 50,
                            }}
                        />
                        <ConnectionTypeKeyItemIndicator
                            type={SOCIAL_EMERGENCE_CONNECTION_TYPE.parasocial}
                        />
                        <ConnectionTypeKeyItemText>
                            Parasocial
                        </ConnectionTypeKeyItemText>
                    </ConnectionTypeKeyItem>
                    <ConnectionTypeKeyItem>
                        <Tooltip
                            text="No relationship. Trustworthiness is function of avg. trust of network."
                            side={TOOLTIP_TYPE.right}
                            tooltipStyle={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 200,
                                height: 70,
                            }}
                        />
                        <ConnectionTypeKeyItemIndicator
                            type={SOCIAL_EMERGENCE_CONNECTION_TYPE.stranger}
                        />
                        <ConnectionTypeKeyItemText>
                            Stranger
                        </ConnectionTypeKeyItemText>
                    </ConnectionTypeKeyItem>
                </ConnectionTypeKeyContainer>
            </BodyContainer>
        </Container>
    );
}

export default SocialEmergenceView;
