// ===== Packages =====

import {
    Editor,
    Text,
    Transforms,
    Range,
}                       from 'slate';

// ===== Interfaces =====

import { CustomText }   from '../interfaces';

// ===== Enums =====

import {
    EMPHASIZER_MARK,
}                       from './enums';

const isValidEmphasizerMark = (type: EMPHASIZER_MARK): boolean => Object.values(EMPHASIZER_MARK).includes(type);

const addMarkToSelection = (editor: Editor, type: EMPHASIZER_MARK): void => {
    if (!isValidEmphasizerMark(type)) { return; }

    if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
        const [currentTextNode] = Editor.leaf(editor, editor.selection.anchor, { edge: 'start' });
        const { text, ...remainingProps } = currentTextNode;
        const newTextWithMark = { ...remainingProps, [type]: true, text: '\uFEFF' }; // Reference: https://www.fileformat.info/info/unicode/char/feff/index.htm
        return Transforms.insertNodes(editor, newTextWithMark);
    }

    return Transforms.setNodes(editor, { [type]: true }, { match: Text.isText, split: true });
};

const removeMarkFromSelection = (editor: Editor, type: EMPHASIZER_MARK): void => {
    if (!isValidEmphasizerMark(type)) { return; }

    if (editor.selection && Range.isCollapsed(editor.selection as Range)) {
        const [currentTextNode] = Editor.leaf(editor, editor.selection.anchor, { edge: 'start' });
        const { text, [type]: _, ...remainingProps } = currentTextNode as CustomText;
        const newTextWithoutMark = { ...remainingProps, text: '\uFEFF' };
        return Transforms.insertNodes(editor, newTextWithoutMark);
    }

    return Transforms.unsetNodes(editor, type, { match: Text.isText, split: true });
};

const selectionContainsMark = (editor: Editor, type: EMPHASIZER_MARK): boolean | undefined => {
    if (!isValidEmphasizerMark(type)) { return; }
    const [match] = Array.from(Editor.nodes(editor, { match: (node) => (Text.isText(node) && (node as CustomText)[type]) || false }));
    return !!match;
};

const toggleMark = (editor: Editor, type: EMPHASIZER_MARK): void => {
    if (selectionContainsMark(editor, type)) {
        removeMarkFromSelection(editor, type);
    } else {
        addMarkToSelection(editor, type);
    }
};

export const EmphasizerHelpers = {
    // apply mark
    addBoldToSelection: (editor: Editor) => addMarkToSelection(editor, EMPHASIZER_MARK.bold),
    addItalicizeToSelection: (editor: Editor) => addMarkToSelection(editor, EMPHASIZER_MARK.italicize),
    addStrikethroughToSelection: (editor: Editor) => addMarkToSelection(editor, EMPHASIZER_MARK.strikethrough),
    addUnderlineToSelection: (editor: Editor) => addMarkToSelection(editor, EMPHASIZER_MARK.underline),

    // remove mark
    removeBoldFromSelection: (editor: Editor) => removeMarkFromSelection(editor, EMPHASIZER_MARK.bold),
    removeItalicizeFromSelection: (editor: Editor) => removeMarkFromSelection(editor, EMPHASIZER_MARK.italicize),
    removeStrikethroughFromSelection: (editor: Editor) => removeMarkFromSelection(editor, EMPHASIZER_MARK.strikethrough),
    removeUnderlineFromSelection: (editor: Editor) => removeMarkFromSelection(editor, EMPHASIZER_MARK.underline),

    // query for mark
    selectionContainsBold: (editor: Editor) => selectionContainsMark(editor, EMPHASIZER_MARK.bold),
    selectionContainsItalicize: (editor: Editor) => selectionContainsMark(editor, EMPHASIZER_MARK.italicize),
    selectionContainsStrikethrough: (editor: Editor) => selectionContainsMark(editor, EMPHASIZER_MARK.strikethrough),
    selectionContainsUnderline: (editor: Editor) => selectionContainsMark(editor, EMPHASIZER_MARK.underline),

    // toggle mark
    toggleBold: (editor: Editor) => toggleMark(editor, EMPHASIZER_MARK.bold),
    toggleItalicize: (editor: Editor) => toggleMark(editor, EMPHASIZER_MARK.italicize),
    toggleStrikethrough: (editor: Editor) => toggleMark(editor, EMPHASIZER_MARK.strikethrough),
    toggleUnderline: (editor: Editor) => toggleMark(editor, EMPHASIZER_MARK.underline),
};
