// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
}                                   from '../../constants/generalConstants';

const ORDER_SUMMARY_CONTAINER_WIDTH = 350;
const PROMOTION_CODE_ADD_BUTTON_WIDTH = 65;
const PROMOTION_CODE_ADD_BUTTON_MARGIN_LEFT = 10;

export const Container = styled.div`
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: ${({ theme }) => theme.color.white};
`;

export const ContentContainer = styled.div`
    position: absolute;
    top: 90px;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: calc(100vh - 90px);

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        flex-direction: column;
        overflow-y: scroll;
    }
`;

export const CheckpointContainer = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 20px;
    padding-bottom: 0px;
    height: 100%;
    max-width: ${`calc(100vw - ${ORDER_SUMMARY_CONTAINER_WIDTH}px)`};

    ${({ theme }) => theme.mediaQuery.small} {
        padding: 10px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: 10px;
        flex: 0 0 auto;
        max-width: none;
    }
`;

export const OrderSummaryContainer = styled.div`
    position: relative;
    flex: ${`0 0 ${ORDER_SUMMARY_CONTAINER_WIDTH}px`};
    padding: 20px;
    height: 100%;
    overflow-y: scroll;

    ${({ theme }) => theme.mediaQuery.small} {
        padding: 10px;
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: 10px;
        overflow-y: hidden;
        flex: 0 0 auto;
        height: auto;
        padding-bottom: 10px;
    }
`;

export const Header = styled.div`
    position: relative;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    background: ${({ theme }) => theme.color.white};
    border-bottom: ${({ theme }) => `3px solid ${theme.verascopeColor.purple100}`};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: 10px;
        border: ${({ theme }) => `3px solid ${theme.verascopeColor.purple100}`};
        background: ${({ theme }) => theme.color.neutral300};
    }
`;

export const OrderSummaryToggleButton = styled.div`
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${({ theme }) => `5px solid ${theme.verascopeColor.purple100}`};
    text-transform: uppercase;
    background: ${({ theme }) => theme.color.white};
`;

interface OrderSummaryToggleButtonContainerProps {
    orderSummaryIsVisible: boolean,
    detectTouchDevice: boolean,
}
export const OrderSummaryToggleButtonContainer = styled.div<OrderSummaryToggleButtonContainerProps>`
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
    background: ${({ theme }) => theme.color.white};
    transform: ${({ orderSummaryIsVisible }) => `translateY(-50%) rotate(${orderSummaryIsVisible ? 180 : 0}deg)`};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            background: ${theme.verascopeColor.yellow200};
        `)}
    }

    &:hover ${OrderSummaryToggleButton} {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            border-top: 5px solid ${theme.verascopeColor.purple100};
            background: ${theme.verascopeColor.yellow200};
        `)}
    }
`;

interface OrderSummaryProps {
    isVisible: boolean,
}
export const OrderSummary = styled.div<OrderSummaryProps>`
    height: ${({ isVisible }) => (isVisible
        ? 'auto'
        : '0px'
    )};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        padding: 0px 10px;
    }
    
`;

export const BreadcrumbContainer = styled.div`
    padding-bottom: calc(10px + 0.1em); // Add 0.1 em so that breadcrumb is flush with order summary title
    border-bottom: ${({ theme }) => `3px solid ${theme.verascopeColor.purple100}`};
`;

export const CartItemsContainer = styled.div`
    margin-bottom: 20px;
`;

export const CartItemFinalQuantityContainer = styled.span`
`;

export const CartItemFinalQuantityValue = styled.span`
    color: ${({ theme }) => theme.verascopeColor.purple400};
    margin-left: 15px;
`;

interface PromotionCodeContainerProps {
    noBorder?: boolean,
}
export const PromotionCodeContainer = styled.div<PromotionCodeContainerProps>`
    width: 100%;
    margin-bottom: 20px;
    border-bottom: ${({ noBorder, theme }) => (noBorder
        ? 'none'
        : `3px solid ${theme.verascopeColor.purple100}`
    )};
`;

export const PromotionCodeHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${({ theme }) => theme.color.white};
`;

export const PromotionCodeHeaderText = styled.h3`
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    color: ${({ theme }) => theme.verascopeColor.purple100};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

export const PromotionCodeToggleButton = styled.div`
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${({ theme }) => `5px solid ${theme.verascopeColor.purple100}`};
    text-transform: uppercase;
    background: ${({ theme }) => theme.color.white};
`;

interface PromotionCodeToggleButtonContainerProps {
    detectTouchDevice: boolean,
    promotionCodeInputIsVisible: boolean,
}
export const PromotionCodeToggleButtonContainer = styled.div<PromotionCodeToggleButtonContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    padding: 10px;
    margin-left: 10px;
    background: ${({ theme }) => theme.color.white};
    transform: ${({ promotionCodeInputIsVisible }) => `rotate(${promotionCodeInputIsVisible ? 180 : 0}deg)`};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            background: ${theme.verascopeColor.yellow200};
        `)}
    }

    &:hover ${PromotionCodeToggleButton} {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            border-top: 5px solid ${theme.verascopeColor.purple100};
            background: ${theme.verascopeColor.yellow200};
        `)}
    }
`;

interface PromotionCodeBodyProps {
    isVisible: boolean,
}
export const PromotionCodeBody = styled.div<PromotionCodeBodyProps>`
    display: ${({ isVisible }) => (isVisible
        ? 'block'
        : 'none'
    )};
    padding-bottom: 10px;
`;

interface PromotionCodeInputProps {
    isInvalid: boolean,
    addButtonVisible: boolean,
}
export const PromotionCodeInput = styled.input<PromotionCodeInputProps>`
    display: inline-block;
    vertical-align: middle;
    border: ${({ isInvalid, theme }) => `3px solid ${isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.purple100
    }`};
    height: 40px;
    width: ${({ addButtonVisible }) => (addButtonVisible
        ? `calc(100% - ${PROMOTION_CODE_ADD_BUTTON_WIDTH + PROMOTION_CODE_ADD_BUTTON_MARGIN_LEFT}px)`
        : '100%')};
    outline: none;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.color.neutral900
    )};
    padding: 0px 10px;
    cursor: none;
    background-color: transparent;

    &:-ms-input-placeholder {
        color: ${({ theme }) => theme.color.neutral500};
    }

    &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.color.neutral500};
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral500};
    }

    &::-ms-input-placeholder {
        color: ${({ theme }) => theme.color.neutral500};
    }

    &:focus {
        color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
        border: ${({ theme, isInvalid }) => `3px solid ${isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    }`};

        &:-ms-input-placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.orange200}`};
        }

        &::-webkit-input-placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.orange200}`};
        }

        &::placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.orange200}`};
        }

        &::-ms-input-placeholder {
                color: ${({ theme }) => `${theme.verascopeColor.orange200}`};
        }
    }

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

interface PromotionCodeAddButtonProps {
    detectTouchDevice: boolean,
    isInvalid: boolean,
    isValidatingCode: boolean,
}
export const PromotionCodeAddButton = styled.button<PromotionCodeAddButtonProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    flex: 0 0 65px;
    width: ${`${PROMOTION_CODE_ADD_BUTTON_WIDTH}px`};
    height: 40px;
    padding: 15px;
    margin-left: ${`${PROMOTION_CODE_ADD_BUTTON_MARGIN_LEFT}px`};
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.color.neutral500
        : theme.color.white
    )};
    text-transform: uppercase;
    background: ${({ isValidatingCode, isInvalid, theme }) => {
        if (isInvalid) {
            return 'transparent';
        }

        if (isValidatingCode) {
            return theme.color.neutral300;
        }

        return theme.verascopeColor.purple100;
    }};

    ${({ isInvalid, isValidatingCode, theme }) => !isInvalid && !isValidatingCode && `
        &:hover {
        background: ${theme.verascopeColor.orange200};
    }`}

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
    
    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

export const PromotionCodeBadgeContainer = styled.div``;

export const PromotionCodeBadge = styled.div`
    position: relative;
    display: inline-block;
    width: fit-content;
    padding: 7.5px;
    padding-right: 5px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    color: ${({ theme }) => theme.verascopeColor.orange100};
    text-transform: uppercase;
    border: ${({ theme }) => `3px solid ${theme.verascopeColor.orange200}`};
    background: ${({ theme }) => theme.color.white};

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

interface PromotionCodeBadgeRemoveButtonProps {
    detectTouchDevice: boolean,
}
export const PromotionCodeBadgeRemoveButton = styled.button<PromotionCodeBadgeRemoveButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    padding: 5px;
    margin-left: 7.5px;
    background: ${({ theme }) => theme.color.white};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 100%;
        height: 100%;
        overflow: visible;
    }

    & path, & rect {
        fill: ${({ theme }) => theme.verascopeColor.orange100};
        transform: scale(1);
        transform-origin: 50% 50% 0;
    }

    &:hover {
        background: ${({ theme }) => theme.verascopeColor.orange200};
        & path, & rect {
        fill: ${({ theme }) => theme.color.white};
    }
    }
`;

export const PlaceholderCheckpointContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        animation: rotate 2s linear infinite;
    }

    & svg {
        width: 100%;
        height: 100%;
    }

    & path, & rect {
        fill: ${({ theme }) => theme.verascopeColor.purple100};
    }
`;

export const PlaceholderCheckpointText = styled.h3`
    width: 20ch;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-weight: 500;
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    line-height: 2em;
    color: ${({ theme }) => theme.verascopeColor.purple300};
    text-transform: uppercase;
    text-align: center;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;
