// IMPORTANT: Make sure this is in sync with functions/src/index.ts -> uploadTreasureChestItem
export enum CARTRIDGE {
    blueDarker = 'blue-darker',
    blueDark = 'blue-dark',
    blueRegular = 'blue-regular',
    blueLight = 'blue-light',
    grayOne = 'gray-one',
    grayTwo = 'gray-two',
    grayThree = 'gray-three',
    grayFour = 'gray-four',
    grayFive = 'gray-five',
    graySix = 'gray-six',
    graySeven = 'gray-seven',
    greenDark = 'green-dark',
    greenRegular = 'green-regular',
    greenLight = 'green-light',
    orangeDark = 'orange-dark',
    orangeRegular = 'orange-regular',
    orangeLight = 'orange-light',
    redDark = 'red-dark',
    redRegular = 'red-regular',
    redLight = 'red-light',
    yellowDark = 'yellow-dark',
    yellowRegular = 'yellow-regular',
    yellowLight = 'yellow-light',
}
