// ===== Interfaces =====

import { IEmojiCategoryIcon }   from '../interfaces';

// ===== Images =====

import AirplaneIcon             from '../images/editor/airplane.svg';
import CutleryIcon              from '../images/editor/cutlery.svg';
import DogIcon                  from '../images/editor/dog.svg';
import FlagIcon                 from '../images/editor/flag.svg';
import HeadIcon                 from '../images/editor/head.svg';
import LightbulbIcon            from '../images/editor/lightbulb.svg';
import UsersIcon                from '../images/editor/users.svg';
import HashtagIcon              from '../images/editor/hashtag.svg';
import SmileyIcon               from '../images/editor/smiley.svg';
import SoccerIcon               from '../images/editor/soccer.svg';

const EMOJI_CATEGORY_ICONS: IEmojiCategoryIcon = {
    'Smileys & Emotion': SmileyIcon,
    'People & Body': UsersIcon,
    Component: HeadIcon,
    'Animals & Nature': DogIcon,
    'Food & Drink': CutleryIcon,
    'Travel & Places': AirplaneIcon,
    Activities: SoccerIcon,
    Objects: LightbulbIcon,
    Symbols: HashtagIcon,
    Flags: FlagIcon,
};

export default EMOJI_CATEGORY_ICONS;
