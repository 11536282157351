// ===== Interfaces =====

import {
    IMediaItem,
}                       from '../interfaces';

// ===== Assets =====

import DOC              from '../images/editor/doc.svg';
import PDF              from '../images/editor/pdf.svg';
import XLSX             from '../images/editor/xlsx.svg';
import Music            from '../images/editor/music-note.svg';
import Image            from '../images/editor/image.svg';
import File             from '../images/editor/file.svg';

// ===== Constants =====

import FILE_TYPE        from '../constants/fileType';

const getFileTypeIcon = (mediaItem: IMediaItem): string => {
    const fileType = mediaItem.file.name.toLowerCase().split('.')[-1];

    switch (fileType) {
    case FILE_TYPE.wordDoc:
    case FILE_TYPE.wordDocX:
        return DOC;
    case FILE_TYPE.excel:
        return XLSX;
    case FILE_TYPE.pdf:
        return PDF;
    case FILE_TYPE.mp3:
    case FILE_TYPE.m4a:
        return Music;
    case FILE_TYPE.png:
    case FILE_TYPE.jpg:
    case FILE_TYPE.jpeg:
    case FILE_TYPE.gif:
        return Image;
    default:
        return File;
    }
};

export default getFileTypeIcon;
