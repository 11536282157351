// ===== Packages =====

import React                                    from 'react';
import styled                                   from 'styled-components';

// ===== Enums =====

import { LIST_TYPE }                            from '../elements/listElement/enums';

interface Props {
    type: LIST_TYPE,
    children: React.ReactElement,
    attributes: any,
}
function List({
    type,
    attributes,
    children,
}: Props): JSX.Element {
    if (type === LIST_TYPE.ordered) {
        return (
            <NumberedList {...attributes}>
                {children}
            </NumberedList>
        );
    }

    return (
        <BulletedList>
            {children}
        </BulletedList>
    );
}

// ===== Styled Components =====

const NumberedList = styled.ol``;

const BulletedList = styled.ul``;

export default List;
