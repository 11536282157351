// ===== Packages =====

import React, {
    useState,
    useEffect,
}                                                       from 'react';
import styled                                           from 'styled-components';
import { ReactSVG }                                     from 'react-svg';
import {
    useFocused,
    useSelected,
}                                                       from 'slate-react';
import {
    HttpsCallableResult,
}                                                       from 'firebase/functions';

// ===== Services =====

import {
    fetchURLMetadata,
    setColorLightness,
}                                                       from '../../../services';

// ===== Hooks =====

import { useInterval }                                  from '../../../hooks';

// ===== Interfaces =====

import {
    ILinkMetadata,
}                                                       from '../../../interfaces';

// ===== Components =====

import {
    PlaceholderContainer,
    PlaceholderHeading,
    PlaceholderImageContainer,
}                                                       from '../../../styles';

// ===== Assets =====

import LinkIcon                                         from '../../../images/editor/link.svg';

// ===== Constants =====

import {
    EDITOR_SELECTION_LIGHTNESS_VALUE,
    EDITOR_SELECTION_BORDER_THICKNESS,
    PLACEHOLDER_ELLIPSIS_INTERVAL_DURATION,
}                                                       from '../../../constants/generalConstants';
import FONT_TYPE                                        from '../../../constants/fontType';

interface Props {
    href: string,
    color: string,
    children: React.ReactElement,
    attributes: any,
}
function BlockLink({
    href,
    color,
    children,
    attributes,
}: Props): JSX.Element {
    // ===== State =====

    const [placeholderEllipsis, setPlaceholderEllipsis] = useState<string>('...');
    const [isRunningInterval, setIsRunningInterval] = useState<boolean>(true);
    // stores OG Metadata of cartridge with website
    const [linkMetadata, setLinkMetadata] = useState<ILinkMetadata | null>(null);
    const focused = useFocused();
    const selected = useSelected();

    // ===== Side Effects =====

    // Manage Placeholder Ellipsis Changes
    useInterval(() => {
        handlePlaceholderEllipsisText();
    }, isRunningInterval ? PLACEHOLDER_ELLIPSIS_INTERVAL_DURATION : null);

    // Get Link Data
    useEffect(() => {
        // Get Link Metadata
        // TODO: Make sure you only download metadata on demand to avoid high cost in function calls
        fetchURLMetadata(
            href,
            (result: HttpsCallableResult<unknown>) => {
                const metadata: ILinkMetadata = result.data as ILinkMetadata;
                if (isRunningInterval) {
                    setIsRunningInterval(false);
                }
                setLinkMetadata(metadata);
            },
        );
    }, []);

    // ===== Methods =====

    const handlePlaceholderEllipsisText = (): void => {
        // Handle Ellipsis Modification
        const currentEllipsis = placeholderEllipsis;
        const currentNumDots = currentEllipsis.split('.').length - 1;
        let updatedNumDots;
        if (currentNumDots === 3) {
            updatedNumDots = 0;
        } else {
            updatedNumDots = currentNumDots + 1;
        }

        setPlaceholderEllipsis(`${'.'.repeat(updatedNumDots)}`);
    };

    return (
        <Container
            {...attributes}
            color={color}
            focused={focused}
            selected={selected}
            contentEditable={false}
        >
            {isRunningInterval
                ? (
                    <PlaceholderContainer>
                        <PlaceholderImageContainer>
                            <ReactSVG
                                src={LinkIcon}
                            />
                        </PlaceholderImageContainer>
                        <PlaceholderHeading>
                            {!linkMetadata
                                ? `Loading Link${placeholderEllipsis}`
                                : 'Link Not Found'}
                        </PlaceholderHeading>
                    </PlaceholderContainer>
                ) : (
                    <LinkCardContainer
                        href={href}
                        target="_blank"
                    >
                        <LinkCardImageContainer>
                            <LinkCardImage
                                {...(linkMetadata
                                    ? {
                                        url: linkMetadata.image,
                                    } : {}
                                )}
                            />
                        </LinkCardImageContainer>
                        <LinkCardInfoContainer>
                            <LinkCardTitle
                                color={color}
                            >
                                {linkMetadata?.title || href}
                            </LinkCardTitle>
                            {linkMetadata?.url
                            && (
                                <LinkCardAddress>
                                    {linkMetadata.url}
                                </LinkCardAddress>
                            )}
                            {linkMetadata?.description
                            && (
                                <LinkCardDescription>
                                    {linkMetadata.description}
                                </LinkCardDescription>
                            )}
                        </LinkCardInfoContainer>
                    </LinkCardContainer>
                )}
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    selected: boolean,
    focused: boolean,
}
const Container = styled.div<ContainerProps>`
    position: relative;
    margin: 20px auto;
    width: 100%;
    max-width: 550px;
    min-height: 170px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    border: ${({
        selected,
        focused,
        color,
        theme,
    }) => (selected && focused
        ? `${EDITOR_SELECTION_BORDER_THICKNESS}px solid ${color
            ? setColorLightness(
                color,
                EDITOR_SELECTION_LIGHTNESS_VALUE,
            )
            : theme.verascopeColor.purple200
        }`
        : 'none'
    )};
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

const LinkCardContainer = styled.a`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
`;

const LinkCardImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
`;

interface LinkCardImageProps {
    url?: string | null,
}
const LinkCardImage = styled.div<LinkCardImageProps>`
    width: 200px;
    height: 150px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.neutral200};
    background-image: ${({ url }) => `url(${url})`};
    background-repeat: no-repeat;
    background-size: cover;
`;

const LinkCardInfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 5px;
    padding-right: 15px;
    height: 100%;
`;

const LinkCardTitle = styled.h2`
    margin: 0;
    padding-top: 5px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 700;
    font-size: 1.2em;
    color: ${({ theme }) => theme.color.neutral900};
    transition: color 0.3s;

    &:hover {
        color: ${({ color, theme }) => color || theme.color.neutral700};
    }
`;

const LinkCardAddress = styled.p`
    margin: 0;
    padding-top: 5px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 500;
    font-size: 0.75em;
    color: ${({ theme }) => theme.color.neutral700};
`;

const LinkCardDescription = styled.p`
    margin: 0;
    padding-top: 15px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-weight: 300;
    font-size: 0.75em;
    color: ${({ theme }) => theme.color.neutral600};
    max-width: 50ch;
`;

export default BlockLink;
