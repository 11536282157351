// Reference: https://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects
// ===== Packages =====

import { isEqual } from 'lodash';

function deepEqual<T>(obj: T, other: T): boolean {
    return isEqual(obj, other);
}

export default deepEqual;
