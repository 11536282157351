// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                               from 'react';
import styled                                   from 'styled-components';
import { Transition }                           from 'react-transition-group';
import { ReactSVG }                             from 'react-svg';
import {
    HttpsCallableResult,
}                                               from 'firebase/functions';
import {
    doc,
    getDoc,
    getFirestore,
}                                               from 'firebase/firestore';

// ===== Services =====

import {
    setColorLightness,
    applyCharacterLimit,
    fetchURLMetadata,
    recordUserAction,
    updateAnnotationMediaInDB,
    isMobile,
    detectTouchDevice,
}                                               from '../../../services';

// ===== Hooks =====

import { useTimeout }                           from '../../../hooks';

// ===== Components =====

import {
    LinkCardImage,
    LinkCardTitle,
    LinkCardAddress,
    TemporaryLinkText,
    LinkCardContainer,
    LinkCardTopContent,
    LinkCardInfoContainer,
    LinkCardImageContainer,
}                                               from '../helpers/PortableToolbar';

// ===== Interfaces =====

import {
    IUserItem,
    ILinkMetadata,
    IEditorLinkItem,
}                                               from '../../../interfaces';

// ===== Enums =====

import {
    MEDIA_TYPE,
    CURSOR_TARGET,
    EDITOR_TOOLBAR_TYPE,
    FIRESTORE_COLLECTION,
    INTERACTABLE_OBJECT,
    USER_ACTION_TYPE,
    EDITOR_CONTEXT_TYPE,
}                                               from '../../../enums';

// ===== Images =====

import LinkIcon                                 from '../../../images/editor/link.svg';

// ===== Constants =====

import CURSOR_SIGN                              from '../../../constants/cursorSigns';
import {
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    HOVER_TARGET_CLASSNAME,
    SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
    ERROR_BACKGROUND_LIGHTNESS_VALUE,
}                                               from '../../../constants/generalConstants';
import {
    LINK_CARD_IMAGE_WIDTH,
    LINK_CARD_IMAGE_HEIGHT,
    LINK_CARD_TRANSITION_DURATION,
    LINK_CARD_MAX_TITLE_COUNT,
    LINK_CARD_WITH_METADATA_HEIGHT,
    LINK_CARD_WITHOUT_METADATA_HEIGHT,
    LINK_CARD_WITH_METADATA_WIDTH,
    LINK_CARD_WITHOUT_METADATA_WIDTH,
}                                               from '../helpers/constants';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';
import { theme as themeObj }                    from '../../../themes/theme-context';

const TRANSITION_LINK_CARD_DELAY = 200;

InlineLink.defaultProps = {
    readOnly: false,
    hasSuccess: false,
    hasError: false,
};
interface Props {
    id: string,
    href: string,
    color: string,
    readOnly?: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    postId: string | undefined,
    editorType: EDITOR_CONTEXT_TYPE,
    parentRef: HTMLElement | null,
    hasSuccess?: boolean,
    hasError?: boolean,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    children: React.ReactElement,
    attributes: any,
}
function InlineLink({
    id,
    href,
    color,
    children,
    readOnly = false,
    user,
    currentSessionId,
    postId,
    editorType,
    hasSuccess,
    hasError,
    onCursorEnter,
    onCursorLeave,
    parentRef,
    attributes,
}: Props): JSX.Element {
    // ===== General Constants =====

    const LINK_CARD_FONT_MULTIPLIER = 0.6;

    // ===== Refs =====

    // We use this ref to access InlineLink ref because
    // there is an issue assigning ref directly using figure
    //
    // This likely has to do with Slate attributes
    const childRef = useRef<HTMLSpanElement | null>(null);

    // ===== State =====

    const [showCard, setShowCard] = useState<boolean>(false);
    const [cardAbove, setCardAbove] = useState<boolean>(true);
    const [centerCard, setCenterCard] = useState<boolean>(true);
    // stores OG Metadata of cartridge with website
    const [linkMetadata, setLinkMetadata] = useState<ILinkMetadata | null>(null);

    // ===== Methods =====

    const onClick = async (e: React.MouseEvent): Promise<void> => {
        e.stopPropagation();
        window.open(href, '_blank');

        if (
            readOnly
            && user
            && postId
            && currentSessionId
        ) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.clickInlineLink,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    id,
                    href,
                    postId,
                    editorType,
                },
            });
            // Get media item
            const collection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.media
                : FIRESTORE_COLLECTION.stagingMedia;
            const db = getFirestore();
            const linkItemSnap = await getDoc(doc(db, collection, id));
            if (linkItemSnap.exists()) {
                const linkItem = linkItemSnap.data() as IEditorLinkItem;
                // Increment plays
                updateAnnotationMediaInDB({
                    collection,
                    id,
                    annotationMediaType: MEDIA_TYPE.link,
                    clicks: [
                        ...linkItem.clicks,
                        actionId,
                    ],
                });
            }
        }
    };

    // ===== Side Effects =====

    // Determine position of link card
    useEffect(() => {
        if (
            readOnly
            && childRef.current
            && parentRef
            && !isMobile()
        ) {
            const linkRect = childRef.current.parentElement!.getBoundingClientRect();
            const parentRect = parentRef.getBoundingClientRect();
            const linkCardHeight = linkMetadata
                ? LINK_CARD_WITH_METADATA_HEIGHT
                : LINK_CARD_WITHOUT_METADATA_HEIGHT;
            const linkCardWidth = linkMetadata
                ? LINK_CARD_WITH_METADATA_WIDTH
                : LINK_CARD_WITHOUT_METADATA_WIDTH;

            // Determine vertical position
            // if link card should go above or below link text
            if (
                (linkRect.top - parentRect.top) > linkCardHeight
                && !cardAbove
            ) {
                setCardAbove(true);
            } else if (
                (linkRect.top - parentRect.top) < linkCardHeight
                && cardAbove
            ) {
                setCardAbove(false);
            }

            // Determine horizontal position
            // if link card is center or left-aligned
            if (
                (linkRect.left - parentRect.left) > linkCardWidth
                && !centerCard
            ) {
                setCenterCard(true);
            } else if (
                (linkRect.left - parentRect.left) < linkCardWidth
                && centerCard
            ) {
                setCenterCard(false);
            }
        }
    }, [
        readOnly,
        childRef.current,
        linkMetadata,
        parentRef,
    ]);

    // if link url modified
    // update in toolbar
    useEffect(() => {
        // Get Link Metadata
        if (showCard && !linkMetadata) {
            fetchURLMetadata(
                href,
                (result: HttpsCallableResult<unknown>) => {
                    const metadata: ILinkMetadata = result.data as ILinkMetadata;
                    setLinkMetadata(metadata);
                },
            );
        }
    }, [showCard]);

    const {
        start: delayHideLinkCard,
        clear: clearDelayHideLinkCard,
    } = useTimeout(() => {
        setShowCard(false);
    }, TRANSITION_LINK_CARD_DELAY);

    // ===== Methods =====

    const onMouseEnter = (e: React.MouseEvent): void => {
        if (!isMobile()) {
            clearDelayHideLinkCard();
            setShowCard(true);
        }

        onCursorEnter(
            CURSOR_TARGET.editorButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onMouseLeave = (e: React.MouseEvent): void => {
        if (!isMobile()) {
            clearDelayHideLinkCard();
            delayHideLinkCard();
        }

        onCursorLeave(e);
    };

    return (
        <Container
            color={color}
            readOnly={readOnly}
            hasSuccess={hasSuccess}
            hasError={hasError}
            {...(readOnly
                ? {
                    className: HOVER_TARGET_CLASSNAME,
                    onMouseEnter,
                    onMouseLeave,
                    onClick,
                } : {}
            )}
            {...attributes}
            // Necessary to avoid: Cannot resolve a Slate point from DOM point
            // Reference: https://github.com/ianstormtaylor/slate/issues/3930
            contentEditable={!readOnly}
        >
            {children}
            <RefNode
                ref={childRef}
            />
            {readOnly
            && (
                <Transition
                    in={showCard}
                    timeout={{
                        enter: LINK_CARD_TRANSITION_DURATION,
                        exit: LINK_CARD_TRANSITION_DURATION,
                    }}
                >
                    {(state) => (
                        <LinkCard
                            visible={showCard}
                            isAbove={cardAbove}
                            centerCard={centerCard}
                            hasLinkMetadata={!!linkMetadata}
                            style={{
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: cardAbove ? 'up' : 'down',
                                    offset: 20,
                                    duration: LINK_CARD_TRANSITION_DURATION,
                                    easing: themeObj.motion.standardEasing,
                                    horizontalCenter: centerCard,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: cardAbove ? 'up' : 'down',
                                    offset: 20,
                                    horizontalCenter: centerCard,
                                })[state],
                            }}
                        >
                            <LinkCardContainer
                                readOnly
                                hasLinkMetadata={!!linkMetadata}
                                toolbarType={EDITOR_TOOLBAR_TYPE.selection}
                            >
                                <LinkCardTopContent
                                    hasLinkMetadata={!!linkMetadata}
                                >
                                    {linkMetadata
                                        ? (
                                            <>
                                                <LinkCardImageContainer>
                                                    <LinkCardImage
                                                        width={LINK_CARD_IMAGE_WIDTH}
                                                        height={LINK_CARD_IMAGE_HEIGHT}
                                                        {...(linkMetadata?.image
                                                            ? {
                                                                url: linkMetadata.image,
                                                            } : {}
                                                        )}
                                                    >
                                                        {!linkMetadata.image
                                                        && (
                                                            <ReactSVG
                                                                src={LinkIcon}
                                                            />
                                                        )}
                                                    </LinkCardImage>
                                                </LinkCardImageContainer>
                                                <LinkCardInfoContainer>
                                                    <LinkCardTitle
                                                        className={HOVER_TARGET_CLASSNAME}
                                                        href={href}
                                                        target="_blank"
                                                        color={color}
                                                        fontMultiplier={LINK_CARD_FONT_MULTIPLIER}
                                                        onMouseEnter={onMouseEnter}
                                                        onMouseLeave={onMouseLeave}
                                                        {...(detectTouchDevice(document) ? {
                                                            onTouchStart: (e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            },
                                                        } : {
                                                            onMouseDown: (e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            },
                                                        })}
                                                    >
                                                        {linkMetadata.title
                                                            ? applyCharacterLimit(
                                                                linkMetadata.title,
                                                                LINK_CARD_MAX_TITLE_COUNT,
                                                            )
                                                            : 'Untitled Page'}
                                                    </LinkCardTitle>
                                                    {linkMetadata.url
                                                    && (
                                                        <LinkCardAddress>
                                                            {linkMetadata.url}
                                                        </LinkCardAddress>
                                                    )}
                                                </LinkCardInfoContainer>
                                            </>
                                        ) : (
                                            <TemporaryLinkText
                                                href={href}
                                                target="_blank"
                                                color={color}
                                                fontMultiplier={LINK_CARD_FONT_MULTIPLIER}
                                            >
                                                {href
                                                && applyCharacterLimit(
                                                    href,
                                                    28,
                                                )}
                                            </TemporaryLinkText>
                                        )}
                                </LinkCardTopContent>
                            </LinkCardContainer>
                        </LinkCard>
                    )}
                </Transition>
            )}
        </Container>
    );
}

// ===== Styled Components =====

interface ContainerProps {
    readOnly: boolean,
    hasSuccess: boolean,
    hasError: boolean,
}
const Container = styled.span<ContainerProps>`
    ${basicEditorFontStyles}
    position: relative;
    font-size: 1em;
    font-weight: 500;
    color: ${({ color, theme }) => (color
        ? setColorLightness(
            color,
            45,
        )
        : theme.color.neutral800
    )};
    cursor: none;
    text-decoration: underline;
    transition: color 0.3s;
    background: ${({
        hasSuccess,
        hasError,
        theme,
    }) => {
        if (hasError) {
            return setColorLightness(
                theme.color.error,
                ERROR_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (hasSuccess) {
            return setColorLightness(
                theme.verascopeColor.blue200,
                SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        // Rather than be none, we want a transparent the background color
        // so that the background transitions in response to hover events
        return 'transparent';
    }};

    ${({ readOnly, color }) => readOnly && color && `
        &:hover {
            color: ${setColorLightness(color, 30)};
        }
    `};
`;

interface LinkCardProps {
    isAbove: boolean,
    visible: boolean,
    centerCard: boolean,
    hasLinkMetadata: boolean,
}
const LinkCard = styled.div<LinkCardProps>`
    position: absolute;
    top: ${({ isAbove, hasLinkMetadata }) => {
        if (!isAbove) {
            return 'auto';
        }

        if (isAbove && !hasLinkMetadata) {
            return `calc(-${LINK_CARD_WITHOUT_METADATA_HEIGHT}px - 5px)`;
        }

        return `calc(-${LINK_CARD_WITH_METADATA_HEIGHT}px - 5px)`;
    }};
    bottom: ${({ isAbove, hasLinkMetadata }) => {
        if (isAbove) {
            return 'auto';
        }

        if (isAbove && !hasLinkMetadata) {
            return `calc(-${LINK_CARD_WITHOUT_METADATA_HEIGHT}px - 5px)`;
        }

        return `calc(-${LINK_CARD_WITH_METADATA_HEIGHT}px - 5px)`;
    }};
    left: 0;
    width: ${({ hasLinkMetadata }) => `${hasLinkMetadata
        ? LINK_CARD_WITH_METADATA_WIDTH
        : LINK_CARD_WITHOUT_METADATA_WIDTH
    }px`};
    height: ${({ hasLinkMetadata }) => `${hasLinkMetadata
        ? LINK_CARD_WITH_METADATA_HEIGHT
        : LINK_CARD_WITHOUT_METADATA_HEIGHT
    }px`};
    pointer-events: ${({ visible }) => (visible
        ? 'auto'
        : 'none'
    )};
    z-index: 1;

    ${({ centerCard }) => centerCard && `
        & > * {
            left: 50%;
            transform: translateX(-50%);
        }
    `};
`;

const RefNode = styled.span``;

export default InlineLink;
