// ===== Packages =====

import linkifyIt    from 'linkify-it';
import tlds         from 'tlds';

const validateEmail = (input: string): boolean => {
    // Inititalize Linkify
    const linkify = linkifyIt().tlds(tlds);
    const valid = linkify.match(input);

    if (valid) {
        return valid.length === 1
            && valid[0].index === 0
            && valid[0].text.split('@').length === 2
            && input.split(' ').length === 1;
    }

    return false;
};

export default validateEmail;
