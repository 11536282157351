// ===== Interfaces =====

import {
    ISingleSourcePathItem,
    ISingleSourcePathAlgorithmProps,
    IPathCycle,
}                                   from '../interfaces';

const bellmanFordAlgorithm = ({
    graph,
    sourceIndex,
    longest,
}: ISingleSourcePathAlgorithmProps): ISingleSourcePathItem => {
    const n = graph.length;
    const distance: number[] = new Array(n).fill(longest ? -1 * Infinity : Infinity);
    const predecessor: number[] = new Array(n).fill(null);

    distance[sourceIndex] = 0;

    // Relax edges repeatedly
    for (let i = 0; i < n - 1; i += 1) {
        for (let u = 0; u < n; u += 1) {
            for (let v = 0; v < n; v += 1) {
                if (graph[u][v] !== null) {
                    const uv = graph[u][v] as number;
                    const alt = distance[u] + uv;
                    if (longest && alt > distance[v]) {
                        distance[v] = alt;
                        predecessor[v] = u;
                    } else if (!longest && alt < distance[v]) {
                        distance[v] = alt;
                        predecessor[v] = u;
                    }
                }
            }
        }
    }

    // Function to find and return the negative cycle (or positive cycle if longest path)
    const findCycle = (startIndex: number): IPathCycle | undefined => {
        const cycle = [startIndex];
        let current = startIndex;

        for (let i = 0; i < n; i += 1) {
            current = predecessor[current];
            if (current === null) {
            // The cycle has reached a vertex with null predecessor, indicating it's not a complete cycle
                return undefined;
            }

            if (current === startIndex) {
                // Found a cycle, reconstruct and return it
                cycle.unshift(startIndex);
                return { path: cycle };
            }

            cycle.unshift(current);
        }

        // If the loop completes, it means the cycle is longer than n, indicating a negative cycle
        return { path: cycle };
    };

    // Check for negative cycles (or positive cycle if longest path)
    for (let u = 0; u < n; u += 1) {
        for (let v = 0; v < n; v += 1) {
            if (graph[u][v] !== null) {
                const uv = graph[u][v] as number;
                if (longest && distance[u] + uv > distance[v]) {
                    // Negative cycle detected, reconstruct and return the cycle
                    const cycle = findCycle(u);
                    return {
                        distance,
                        predecessor,
                        cycle,
                    };
                }

                if (!longest && distance[u] + uv < distance[v]) {
                    // Negative cycle detected, reconstruct and return the cycle
                    const cycle = findCycle(u);
                    return {
                        distance,
                        predecessor,
                        cycle,
                    };
                }
            }
        }
    }

    // No negative cycle found
    return {
        distance,
        predecessor,
    };
};

export default bellmanFordAlgorithm;
