// ===== Packages =====

import React                                    from 'react';
import styled                                   from 'styled-components';
import { v4 as uuidv4 }                         from 'uuid';

// ===== Services =====

import {
    setColorLightness,
}                                               from '../../../services';

// ===== Constants =====

import {
    SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
    ERROR_BACKGROUND_LIGHTNESS_VALUE,
    SLATE_PARAGRAPH_CLASSNAME,
}                                               from '../../../constants/generalConstants';
import FONT_TYPE                                from '../../../constants/fontType';

// ===== Styles =====

import { basicEditorFontStyles }                from '../../../styles';

Paragraph.defaultProps = {
    fontFamily: null,
    hasSuccess: false,
    hasError: false,
};
interface Props {
    hasSuccess?: boolean,
    hasError?: boolean,
    fontFamily?: string,
    children: React.ReactElement,
    attributes: any,
}
function Paragraph({
    fontFamily,
    hasSuccess,
    hasError,
    children,
    attributes,
}: Props): JSX.Element {
    // Used to bucket annotations by paragraphs
    const paragraphId = uuidv4();
    return (
        <Container
            {...attributes}
            data-paragraph-id={paragraphId}
            className={SLATE_PARAGRAPH_CLASSNAME}
            hasSuccess={hasSuccess}
            hasError={hasError}
            fontFamily={fontFamily}
        >
            {children}
        </Container>
    );
}

// ===== Styled Components =====

interface ParagraphProps {
    hasSuccess: boolean,
    hasError: boolean,
    fontFamily?: string,
}
const Container = styled.p<ParagraphProps>`
    ${basicEditorFontStyles}
    font-family: ${({ fontFamily }) => fontFamily || FONT_TYPE.IOWAN_OLD_STYLE};
    margin: 0;
    margin-bottom: 5px;
    background: ${({
        hasSuccess,
        hasError,
        theme,
    }) => {
        if (hasError) {
            return setColorLightness(
                theme.color.error,
                ERROR_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        if (hasSuccess) {
            return setColorLightness(
                theme.verascopeColor.blue200,
                SUCCESS_BACKGROUND_LIGHTNESS_VALUE,
            );
        }

        // Rather than be none, we want a transparent the background color
        // so that the background transitions in response to hover events
        return 'transparent';
    }};
`;

export default Paragraph;
