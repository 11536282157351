// ===== Packages =====

import React                from 'react';
import onClickOutside       from 'react-onclickoutside';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e: MouseEvent) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement,
}
class OutsideClickHandler extends React.Component<Props> {
    // eslint-disable-next-line react/no-unused-class-component-methods
    handleClickOutside = (event: MouseEvent): void => {
        const { onClick } = this.props;
        onClick(event);
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    render() {
        const { children } = this.props;
        return children;
    }
}
export default onClickOutside(OutsideClickHandler);
