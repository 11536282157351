// Reference: https://docs.stripe.com/payments/paymentintents/lifecycle
export enum STRIPE_PAYMENT_INTENT_STATUS {
    // The payment attempt failed.
    // Returns this status so that the payment can be retried.
    requiresPaymentMethod = 'requires_payment_method',
    // Funds associated with payment intent have not been captured.
    requiresCapture = 'requires_capture',
    // After the customer provides their payment information, the PaymentIntent is ready to be confirmed.
    // In most integrations, this state is skipped because payment method information is submitted at the same time that the payment is confirmed.
    requiresConfirmation = 'requires_confirmation',
    // If the payment requires additional actions, such as authenticating with 3D Secure, the PaymentIntent has a status of requires_action.
    requiresAction = 'requires_action',
    // After required actions are handled, the PaymentIntent moves to processing for asynchronous payment methods, such as bank debits.
    // These types of payment methods can take up to a few days to process.
    // Other payment methods, such as cards, are processed more quickly and don’t go into the processing status.
    processing = 'processing',
    // The payment flow it is driving is complete.
    succeeded = 'succeeded',
    // Payment intent canceled before it's in processing or succeeded.
    // Canceling it invalidates the PaymentIntent for future payment attempts, and can’t be undone.
    // If any funds have been held, cancellation releases them.
    canceled = 'canceled',
}
