// ===== Packages =====

import React, {
    useMemo,
    useState,
    useEffect,
}                                               from 'react';
import {
    useNavigate,
    useLocation,
}                                               from 'react-router-dom';
import { ReactSVG }                             from 'react-svg';
import { Transition }                           from 'react-transition-group';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    PAGE_ROUTE,
    USER_ACTION_TYPE,
}                                               from '../../enums';

// ===== Services =====

import {
    recordUserAction,
    detectTouchDevice,
    checkBookPurchases,
}                                               from '../../services';

// ===== Interfaces =====

import {
    ICartItem,
    IUserItem,
}                                               from '../../interfaces';

// ===== Images =====

import CartIcon                                 from '../../images/8-bit_cart.svg';

// ===== Constants =====

import {
    HOVER_TARGET_CLASSNAME,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    DIGITAL_BOOK_STRIPE_PRICE_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    NAVIGATION_CART_BUTTON_ID,
    UPLOADING_MEDIA_DESTINATION_ID,
}                                               from '../../constants/generalConstants';
import CURSOR_SIGN                              from '../../constants/cursorSigns';

// ===== Styles =====

import {
    Container,
    CartIconContainer,
    CartBadge,
}                                               from './styles';
import { theme }                                from '../../themes/theme-context';

interface Props {
    style: any,
    dark: boolean,
    height: number,
    user: IUserItem | null,
    currentSessionId: string | null,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
}
function CartButton({
    style,
    dark,
    height,
    user,
    currentSessionId,
    onCursorEnter,
    onCursorLeave,
}: Props): JSX.Element {
    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== State =====

    // Indicates whether user has bought a physical book
    const [purchasedPhysicalBookSessionIds, setPurchasedPhysicalBookSessionIds] = useState<string[]>([]);
    // Indicates whether user has bought a digital book
    const [purchasedDigitalBookSessionIds, setPurchasedDigitalBookSessionIds] = useState<string[]>([]);
    // Indicates whether checked for book purchases
    const [checkedBookPurchases, setCheckedBookPurchases] = useState<boolean>(false);

    // ===== Animation Constants =====

    const CART_BADGE_TRANSITION_DURATION = 150;
    const CART_ITEM_COUNT_TRANSITION_DURATION = 500;

    // ===== Methods =====

    const determineBookPurchases = async (): Promise<void> => {
        if (!checkedBookPurchases) setCheckedBookPurchases(true);
        if (user) {
            const purchases = await checkBookPurchases(user);
            if (purchases) {
                setPurchasedPhysicalBookSessionIds(purchases.physical);
                setPurchasedDigitalBookSessionIds(purchases.digital);
            }
        }
    };

    const onCartEnter = (e: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.cart,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onCartLeave = (e: React.MouseEvent | React.TouchEvent | React.SyntheticEvent): void => {
        onCursorLeave(e);
    };

    const onCartClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickCartButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: location.pathname,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.cart}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    // ===== Side Effects =====

    /**
     * Manages checking whether user has bought a book in the past
     */
    useEffect(() => {
        if (user) {
            determineBookPurchases();
        }
    }, [
        user?.cart,
    ]);

    // ===== Memoization =====

    const PHYSICAL_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID
            : PHYSICAL_BOOK_STRIPE_PRICE_API_ID
    ), []);
    const DIGITAL_BOOK_PRICE_ID = useMemo(() => (
        process.env.NODE_ENV === 'development'
            ? DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID
            : DIGITAL_BOOK_STRIPE_PRICE_API_ID
    ), []);

    const numCartItems = useMemo(() => {
        let count = 0;
        if (user?.cart) {
            user.cart.forEach((item: ICartItem) => {
                count += item.quantity;
            });
            if (
                user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                && purchasedPhysicalBookSessionIds.length === 0
                && purchasedDigitalBookSessionIds.length === 0
            ) {
                // Add one for complimentary digital book
                count += 1;
            }
            if (
                user.cart.find((item: ICartItem) => (
                    item.priceId === PHYSICAL_BOOK_PRICE_ID
                    || item.priceId === DIGITAL_BOOK_PRICE_ID
                ))
                && (
                    (
                        !user.bookAccess
                        || !user.bookAccess.granted
                    )
                )
            ) {
                // Add one for complimentary web book
                count += 1;
            }
        }
        return count;
    }, [
        user?.cart,
        purchasedPhysicalBookSessionIds,
        purchasedDigitalBookSessionIds,
    ]);

    return (
        <Container
            lightBackground={dark}
            style={style}
            id={UPLOADING_MEDIA_DESTINATION_ID(NAVIGATION_CART_BUTTON_ID)}
        >
            <CartIconContainer
                dark={dark}
                height={height}
                detectTouchDevice={detectTouchDevice(document)}
                lightBackground={dark}
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onCartEnter(e),
                } : {
                    onMouseEnter: (e) => onCartEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onCartLeave(e),
                } : {
                    onMouseLeave: (e) => onCartLeave(e),
                })}
                onMouseDown={onCartClick}
            >
                <ReactSVG
                    src={CartIcon}
                />
            </CartIconContainer>
            <Transition
                in={numCartItems > 0}
                timeout={{
                    enter: CART_BADGE_TRANSITION_DURATION,
                    exit: CART_BADGE_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CartBadge
                        count={numCartItems}
                        transitionDuration={CART_ITEM_COUNT_TRANSITION_DURATION}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'left',
                                offset: 10,
                                duration: CART_BADGE_TRANSITION_DURATION,
                                easing: theme.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'left',
                                offset: 10,
                            })[state],
                        }}
                    />
                )}
            </Transition>
        </Container>
    );
}

export default CartButton;
