// ===== Packages =====

import {
    Node,
    Element,
}                       from 'slate';

// ===== Interfaces =====

import {
    EditorNode,
    AudioNoteOptions,
    AudioNoteNode,
}                       from '../interfaces';

const AUDIO_NOTE_TYPE = 'audio-note';

const isAudioNoteElement = (node: EditorNode | Node): boolean => Element.isElement(node)
    && 'type' in node
    && node.type === AUDIO_NOTE_TYPE;

const newAudioNoteElement = (options: AudioNoteOptions): AudioNoteNode => {
    const {
        id,
        filePath,
        description,
        uploaded,
        title,
    } = options;

    return {
        id,
        type: AUDIO_NOTE_TYPE,
        filePath: filePath || '',
        title: title || '',
        description: description || '',
        uploaded: uploaded !== false,
        children: [{ text: '' }],
    };
};

export default {
    TYPE: AUDIO_NOTE_TYPE,
    isAudioNoteElement,
    newAudioNoteElement,
};
