// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
}                                       from 'react';
import {
    useLocation,
    useNavigate,
}                                       from 'react-router-dom';
import {
    doc,
    getFirestore,
    runTransaction,
}                                       from 'firebase/firestore';
import {
    getAuth,
}                                       from 'firebase/auth';
import Stripe                           from 'stripe';
import {
    useStripe,
    useElements,
    PaymentElement,
}                                       from '@stripe/react-stripe-js';
import {
    StripePaymentElementOptions,
    StripePaymentElementChangeEvent,
    ConfirmPaymentData,
    StripeError,
}                                       from '@stripe/stripe-js';
import { ReactSVG }                     from 'react-svg';
import { phone }                        from 'phone';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment                           from 'moment-timezone';

// ===== Components =====

import PixelDropdown                    from '../PixelDropdown';
import PixelInput                       from '../PixelInput';
import PixelRadioInput                  from '../PixelRadioInput';
import PixelCheckbox                    from '../PixelCheckbox';

// ===== Hooks =====

import {
    useInterval,
}                                       from '../../hooks';

// ===== Enums =====

import {
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    CHECKOUT_CHECKPOINT,
    BILLING_ADDRESS_TYPE,
    SHIPPO_RATE_ATTRIBUTE_TYPE,
    PAGE_ROUTE,
    CHECKOUT_SESSION_STATUS_TYPE,
    STRIPE_PAYMENT_INTENT_ERROR_TYPE,
    FIRESTORE_COLLECTION,
    USER_ACTION_TYPE,
}                                       from '../../enums';

// ===== Services =====

import {
    playAudio,
    validateName,
    detectTouchDevice,
    capitalizeFirstLetter,
    shippoAddressValidation,
    shippoCreateShipment,
    stripeUpdatePaymentIntent,
    stripeGetTaxCalculationLineItems,
    stripeCreateCustomer,
    recordUserAction,
}                                       from '../../services';

// ===== Interfaces =====

import {
    IRadioItem,
    IDropdownItem,
    IShippoAddressObject,
    IShippoRateObject,
    ICountry,
    ICountryState,
    ISnackbarItem,
    IAddress,
    IUserItem,
    IOrderCost,
    ISaleItem,
}                                       from '../../interfaces';

// ===== Images =====

import PixelCartIcon                    from '../../images/8-bit_cart.svg';
import PixelChevronIcon                 from '../../images/8-bit_chevron.svg';
import Spinner                          from '../../images/editor/spinner.svg';

// ===== Sounds =====

import Success                          from '../../sounds/message_success.mp3';
import SwooshIn                         from '../../sounds/swoosh_in.mp3';
import SwooshOut                        from '../../sounds/swoosh_out.mp3';

// ===== Constants =====

import {
    DEFAULT_AUDIO_VOLUME,
    SUPPORT_EMAIL_ADDRESS,
    HOVER_TARGET_CLASSNAME,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    CENTS_IN_A_DOLLAR,
}                                       from '../../constants/generalConstants';
import CURSOR_SIGN                      from '../../constants/cursorSigns';
import countriesJSON                    from '../../constants/countries.json';

// ===== Styles =====

import {
    CheckpointFormContainer,
    InputGroupContainer,
    InputContainer,
    CheckpointTitle,
    ButtonsContainer,
    BackButton,
    BackButtonIconContainer,
    BackButtonTextContainer,
    ActionButton,
    CheckpointSubtitle,
    PaymentWidgetContainer,
    BillingAddressContainer,
    ReviewShippingDetailContainer,
    ReviewShippingDetail,
    ReviewShippingDetailTitle,
    ReviewShippingDetailBodyLine,
    ReviewShippingDetailBody,
    ReviewTransactionDetailContainer,
    ReviewTransactionDetail,
    ReviewTransactionDetailTitle,
    ReviewTransactionDetailBody,
    ReviewShippingDetailBodyLineHighlight,
    ReviewTransactionTotal,
    ReviewTransactionTotalTitle,
    ReviewTransactionTotalBody,
    ReviewShippingContainer,
    ReviewShippingDetailEditButtonContainer,
    ReviewShippingDetailEditButton,
    BillingContainer,
    ReviewContainer,
    ReviewShippingDetailBodyLink,
    MailingListContainer,
    ActionButtonLoadingContainer,
    ActionButtonLoadingMessageText,
    SavePaymentMethodContainer,
    PaymentElementContainer,
}                                       from './styles';
import {
    SpinnerContainer,
}                                       from '../CartView/styles';

interface Props {
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    checkpoint: string | undefined,
    fetchedStoreItems: boolean,
    physicalItemsInCart: string[],
    stripePaymentIntentId: string | undefined,
    stripeClientSecret: string | undefined,
    stripeTaxCalculationId: string | undefined,
    savedStripePaymentMethods: Stripe.PaymentMethod[],
    customerStreetOne: string,
    addressIsValid: boolean,
    customerBillingStreetOne: string,
    billingAddressIsValid: boolean,
    shippingRates: Map<string, IShippoRateObject>,
    selectedShippingRateID: string | undefined,
    selectedBillingAddressType: IRadioItem | undefined,
    orderCost?: {
        subtotal: number,
        shipping?: number,
        tax?: number,
        total?: number,
    },
    stripePaymentDetailsCompleted: boolean,
    stripePaymentSucceeded: boolean,
    snackbarData: ISnackbarItem,
    stripeSucceededPaymentIntent: Stripe.PaymentIntent | undefined,
    stripePaymentMethod: Stripe.PaymentMethod | undefined,
    orderSaleItem: ISaleItem | undefined,
    changeCheckoutSessionStatus: (status: CHECKOUT_SESSION_STATUS_TYPE) => Promise<void>,
    setOrderCost: React.Dispatch<React.SetStateAction<IOrderCost | undefined>>,
    setStripeTaxCalculationId: React.Dispatch<React.SetStateAction<string | undefined>>,
    setCustomerStreetOne: React.Dispatch<React.SetStateAction<string>>,
    setAddressIsValid: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomerBillingStreetOne: React.Dispatch<React.SetStateAction<string>>,
    setBillingAddressIsValid: React.Dispatch<React.SetStateAction<boolean>>,
    setShippingRates: React.Dispatch<React.SetStateAction<Map<string, IShippoRateObject>>>,
    setSelectedShippingRateID: React.Dispatch<React.SetStateAction<string | undefined>>,
    setSelectedBillingAddressType: React.Dispatch<React.SetStateAction<IRadioItem | undefined>>,
    setStripePaymentDetailsCompleted: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomerDetailsCompleted: React.Dispatch<React.SetStateAction<boolean>>,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setCursorIsHidden: React.Dispatch<React.SetStateAction<boolean>>,
}
function CheckoutCheckpoint({
    hasSound,
    user,
    currentSessionId,
    checkpoint,
    fetchedStoreItems,
    physicalItemsInCart,
    savedStripePaymentMethods,
    stripePaymentIntentId,
    stripeClientSecret,
    stripeTaxCalculationId,
    customerStreetOne,
    addressIsValid,
    customerBillingStreetOne,
    billingAddressIsValid,
    shippingRates,
    selectedShippingRateID,
    selectedBillingAddressType,
    orderCost,
    stripePaymentDetailsCompleted,
    stripePaymentSucceeded,
    snackbarData,
    stripeSucceededPaymentIntent,
    stripePaymentMethod,
    orderSaleItem,
    changeCheckoutSessionStatus,
    setOrderCost,
    setStripeTaxCalculationId,
    setCustomerStreetOne,
    setAddressIsValid,
    setCustomerBillingStreetOne,
    setBillingAddressIsValid,
    setShippingRates,
    setSelectedShippingRateID,
    setSelectedBillingAddressType,
    setStripePaymentDetailsCompleted,
    setCustomerDetailsCompleted,
    setSnackbarData,
    setInputFocused,
    onCursorEnter,
    onCursorLeave,
    setCursorIsHidden,
}: Props): JSX.Element {
    // ===== General Constants =====

    // Snackbar Message when user hasn't entered a first name
    const SNACKBAR_MESSAGE_NO_FIRST_NAME_ERROR = 'Please provide a first name.';
    // Snackbar Message when user hasn't entered a last name
    const SNACKBAR_MESSAGE_NO_LAST_NAME_ERROR = 'Please provide a last name.';
    // Snackbar Message when user hasn't selected a country
    const SNACKBAR_MESSAGE_NO_COUNTRY_ERROR = 'Please select a country.';
    // Snackbar Message when user hasn't selected a state
    const SNACKBAR_MESSAGE_NO_STATE_ERROR = 'Please select a state/province.';
    // Snackbar Message when user hasn't entered a city
    const SNACKBAR_MESSAGE_NO_CITY_ERROR = 'Please provide a city.';
    // Snackbar Message when user hasn't entered a zip code
    const SNACKBAR_MESSAGE_NO_ZIP_CODE_ERROR = 'Please provide a zip code.';
    // Snackbar Message when user hasn't entered a street address
    const SNACKBAR_MESSAGE_NO_STREET_ONE_ERROR = 'Please provide a street address.';
    // Snackbar Message when user hasn't entered a phone number
    const SNACKBAR_MESSAGE_NO_PHONE_NUMBER_ERROR = 'Please provide a phone number.';
    // Snackbar Message when user hasn't entered valid mailing address: specific error
    const SNACKBAR_MESSAGE_MAILING_ADDRESS_ERROR_MESSAGE = (error: string): string => `Encountered an error validating your mailing address: ${error}`;
    // Snackbar Message when user hasn't entered valid mailing address: generic error
    const SNACKBAR_MESSAGE_MAILING_ADDRESS_ERROR = 'Encountered an error validating your mailing address. Please provide a valid mailing address.';
    // Snackbar Message when encounter error fetching shipping rates
    const SNACKBAR_MESSAGE_SHIPPING_RATE_ERROR_MESSAGE = (error: string): string => `Encountered an error fetching shipping rates: ${error}.`;
    // Snackbar Message when rates information i
    const SNACKBAR_MESSAGE_SHIPPING_NO_RATE_ERROR = `Unable to proceed. Shipping rates information cannot be accessed. Please contact the verascope team for support at: ${
        SUPPORT_EMAIL_ADDRESS
    }.`;
    // Snackbar Message when user hasn't selected shipping method
    const SNACKBAR_MESSAGE_SELECT_VALID_SHIPPING_METHOD_ERROR = 'Please select a valid shipping method.';
    //
    const SNACKBAR_MESSAGE_BILLING_NO_ADDRESS_ERROR = 'Please select a billing adress option.';
    // Snackbar Message when user hasn't entered a first name
    const SNACKBAR_MESSAGE_NO_BILLING_FIRST_NAME_ERROR = 'Please provide a first name.';
    // Snackbar Message when user hasn't entered a last name
    const SNACKBAR_MESSAGE_NO_BILLING_LAST_NAME_ERROR = 'Please provide a last name.';
    // Snackbar Message when user hasn't selected a billing country
    const SNACKBAR_MESSAGE_NO_BILLING_COUNTRY_ERROR = 'Please select a country.';
    // Snackbar Message when user hasn't selected a billing state
    const SNACKBAR_MESSAGE_NO_BILLING_STATE_ERROR = 'Please select a state/province.';
    // Snackbar Message when user hasn't entered a billing city
    const SNACKBAR_MESSAGE_NO_BILLING_CITY_ERROR = 'Please provide a city.';
    // Snackbar Message when user hasn't entered a billing zip code
    const SNACKBAR_MESSAGE_NO_BILLING_ZIP_CODE_ERROR = 'Please provide a zip code.';
    // Snackbar Message when user hasn't entered a billing street address
    const SNACKBAR_MESSAGE_NO_BILLING_STREET_ONE_ERROR = 'Please provide a street address.';
    // Snackbar Message when user hasn't entered a billing phone number
    const SNACKBAR_MESSAGE_NO_BILLING_PHONE_NUMBER_ERROR = 'Please provide a billing phone number.';
    // Snackbar Message when user hasn't entered valid billing address: specific error
    const SNACKBAR_MESSAGE_BILLING_ADDRESS_ERROR_MESSAGE = (error: string): string => `Encountered an error validating your billing address: ${error}`;
    // Snackbar Message when Stripe Payment Element validation failed
    const SNACKBAR_MESSAGE_PAYMENT_DETAILS_ERROR_MESSAGE = (error: string): string => `Payment details validation error: ${error}`;
    // Snackbar Message when Stripe Payment Element processing failed: specific error
    const SNACKBAR_MESSAGE_PAYMENT_ERROR_MESSAGE = (error: string): string => `Payment failed. ${error}`;
    // Snackbar Message when Stripe Payment Element processing failed: generic error
    const SNACKBAR_MESSAGE_GENERIC_PAYMENT_ERROR = 'Payment failed. Please try again.';
    // Snackbar Message when user hasn't entered valid billing address: generic error
    const SNACKBAR_MESSAGE_BILLING_ADDRESS_ERROR = 'Encountered an error validating your billing address. Please provide a valid billing address.';
    // Snackbar Message when user hasn't entered valid billing address: generic error
    const SNACKBAR_MESSAGE_REVIEW_ERROR = 'Unable to proceed. Lost connection with payment processing service. Please refresh the page and try again.';
    // Snackbar Message when completing purchase after review
    const SNACKBAR_MESSAGE_STRIPE_REVIEW_ERROR = 'Unable to complete purchase because a connection with payment processor cannot be reached.';
    const ACTION_BUTTON_MESSAGE_ELLIPSIS_INTERVAL_DURATION = 1000;

    // ===== React Router =====

    const navigate = useNavigate();
    const location = useLocation();

    // ===== Stripe =====

    const stripe = useStripe();
    const elements = useElements();

    // ===== Refs =====

    const checkpointFormContainerRef = useRef<HTMLDivElement>(null);

    // ----- Sound Clips
    const successClip = useRef<HTMLAudioElement>(new Audio());
    const swooshInClip = useRef<HTMLAudioElement>(new Audio());
    const swooshOutClip = useRef<HTMLAudioElement>(new Audio());

    // ===== State =====

    // ----- General
    // Indicates whether action button is loading
    const [actionButtonIsLoading, setActionButtonIsLoading] = useState<boolean>(false);
    // Stores a message to present on action button when loading
    const [actionButtonLoadingMessage, setActionButtonLoadingMessage] = useState<string>('');
    // Animates action button loading text when loading
    const [actionButtonMessageEllipsis, setActionButtonMessageEllipsis] = useState<string>('...');

    // ----- Info
    // Stores a list of saved addresses
    const [savedAddresses, setSavedAddresses] = useState<IAddress[]>([]);
    // Stores selected saved address
    const [selectedSavedAddress, setSelectedSavedAddress] = useState<IDropdownItem | undefined>(undefined);
    // Stores a list of countries
    const [countries, setCountries] = useState<IDropdownItem[]>([]);
    // Stores selected country
    const [customerCountry, setCustomerCountry] = useState<IDropdownItem | undefined>(undefined);
    // Stores first name of customer
    const [customerFirstName, setCustomerFirstName] = useState<string>('');
    // Stores last name of customer
    const [customerLastName, setCustomerLastName] = useState<string>('');
    // Stores company of customer
    const [customerCompany, setCustomerCompany] = useState<string>('');
    // Stores street address line two of customer
    const [customerStreetTwo, setCustomerStreetTwo] = useState<string>('');
    // Stores city of customer
    const [customerCity, setCustomerCity] = useState<string>('');
    // Stores a list of states
    const [states, setStates] = useState<IDropdownItem[]>([]);
    // Stores state of customer
    const [customerState, setCustomerState] = useState<IDropdownItem | undefined>(undefined);
    // Stores zip code of customer
    const [customerZipCode, setCustomerZipCode] = useState<string>('');
    // Stores phone of customer
    const [customerPhone, setCustomerPhone] = useState<string>('');
    // Indicates whether first name is not provided
    const [firstNameNotProvided, setFirstNameNotProvided] = useState<boolean>(false);
    // Indicates whether last name is not provided
    const [lastNameNotProvided, setLastNameNotProvided] = useState<boolean>(false);
    // Indicates whether country is not selected
    const [countryNotSelected, setCountryNotSelected] = useState<boolean>(false);
    // Indicates whether state is not selected
    const [stateNotSelected, setStateNotSelected] = useState<boolean>(false);
    // Indicates whether city is not provided
    const [cityNotProvided, setCityNotProvided] = useState<boolean>(false);
    // Indicates whether zip code is not provided
    const [zipCodeNotProvided, setZipCodeNotProvided] = useState<boolean>(false);
    // Indicates whether street one is not provided
    const [streetOneNotProvided, setStreetOneNotProvided] = useState<boolean>(false);
    // Indicates whether phone number is not provided
    const [phoneNumberNotProvided, setPhoneNumberNotProvided] = useState<boolean>(false);

    // ----- Shipping Method
    // Stores a list of shipping methods
    const [shippingMethods, setShippingMethods] = useState<Map<string, IRadioItem>>(new Map());

    // ----- Billing
    // Stores selected saved stripe payment method
    const [selectedSavedStripePaymentMethod, setSelectedSavedStripePaymentMethod] = useState<IDropdownItem | undefined>(undefined);
    // Stores stripe payment method
    const [stripePaymentMethodType, setStripePaymentMethodType] = useState<string | undefined>(undefined);
    // Indicates whether stripe is handling payment
    const [stripePaymentIsLoading, setStripePaymentIsLoading] = useState<boolean>(false);
    // Stores selected saved billing address
    const [selectedSavedBillingAddress, setSelectedSavedBillingAddress] = useState<IDropdownItem | undefined>(undefined);
    // Stores selected billing country
    const [customerBillingCountry, setCustomerBillingCountry] = useState<IDropdownItem | undefined>(undefined);
    // Stores first name of billing customer
    const [customerBillingFirstName, setCustomerBillingFirstName] = useState<string>('');
    // Stores last name of billing customer
    const [customerBillingLastName, setCustomerBillingLastName] = useState<string>('');
    // Stores company of billing customer
    const [customerBillingCompany, setCustomerBillingCompany] = useState<string>('');
    // Stores street address line two of billing customer
    const [customerBillingStreetTwo, setCustomerBillingStreetTwo] = useState<string>('');
    // Stores city of billing customer
    const [customerBillingCity, setCustomerBillingCity] = useState<string>('');
    // Stores a list of billing states
    const [billingStates, setBillingStates] = useState<IDropdownItem[]>([]);
    // Stores state of billing customer
    const [customerBillingState, setCustomerBillingState] = useState<IDropdownItem | undefined>(undefined);
    // Stores zip code of billing customer
    const [customerBillingZipCode, setCustomerBillingZipCode] = useState<string>('');
    // Stores phone of billing customer
    const [customerBillingPhone, setCustomerBillingPhone] = useState<string>('');
    // Indicates whether billing first name is not provided
    const [billingFirstNameNotProvided, setBillingFirstNameNotProvided] = useState<boolean>(false);
    // Indicates whether billing last name is not provided
    const [billingLastNameNotProvided, setBillingLastNameNotProvided] = useState<boolean>(false);
    // Indicates whether billing country is not selected
    const [billingCountryNotSelected, setBillingCountryNotSelected] = useState<boolean>(false);
    // Indicates whether billing state is not selected
    const [billingStateNotSelected, setBillingStateNotSelected] = useState<boolean>(false);
    // Indicates whether billing city is not provided
    const [billingCityNotProvided, setBillingCityNotProvided] = useState<boolean>(false);
    // Indicates whether billing zip code is not provided
    const [billingZipCodeNotProvided, setBillingZipCodeNotProvided] = useState<boolean>(false);
    // Indicates whether billing street one is not provided
    const [billingStreetOneNotProvided, setBillingStreetOneNotProvided] = useState<boolean>(false);
    // Indicates whether billing phone number is not provided
    const [billingPhoneNumberNotProvided, setBillingPhoneNumberNotProvided] = useState<boolean>(false);
    // Indicates whether customer wants to subscribe to mailing list
    const [subscribeToMailingList, setSubscribeToMailingList] = useState<boolean>(false);
    // Indicates whether customer wants to save payment method
    const [savePaymentMethod, setSavePaymentMethod] = useState<boolean>(false);

    // ===== Methods =====

    const onActionButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.checkoutActionButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onActionButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onBackButtonEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.checkoutBackButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onBackButtonLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onLinkEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.linkButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    const onLinkLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    const onSelectSavedAddress = (address: IDropdownItem | undefined): void => {
        setSelectedSavedAddress(address);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.selectSavedMailingAddress,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }

        if (address) {
            // Auto-populate address form
            const selectedAddress = savedAddresses[parseInt(address.value, 10)];
            setCustomerFirstName(selectedAddress.firstName);
            setCustomerLastName(selectedAddress.lastName);
            if (selectedAddress.company) {
                setCustomerCompany(selectedAddress.company);
            } else if (customerCompany.length > 0) {
                setCustomerCompany('');
            }
            setCustomerStreetOne(selectedAddress.street1);
            if (selectedAddress.street2) {
                setCustomerStreetTwo(selectedAddress.street2);
            } else if (customerStreetTwo.length > 0) {
                setCustomerStreetTwo('');
            }
            setCustomerCity(selectedAddress.city);
            // Find country
            const countryItem = countriesJSON
                .find((item: any) => item.code2 === selectedAddress.country) as ICountry;
            setCustomerCountry({
                value: countryItem.code2,
                name: countryItem.name,
            });
            // Find state
            if (selectedAddress.state) {
                const stateMap: Map<string, IDropdownItem> = new Map(
                    (countryItem.states as ICountryState[]).map((state: ICountryState) => ([
                        state.code,
                        {
                            value: state.code,
                            name: state.name,
                        },
                    ])),
                );
                const stateItem = stateMap.get(selectedAddress.state);
                if (stateItem) {
                    const stateArr: IDropdownItem[] = (countryItem.states as ICountryState[]).map((state: ICountryState) => ({
                        value: state.code,
                        name: state.name,
                    }));
                    setStates(stateArr);
                    setCustomerState(stateItem);
                }
            }
            setCustomerZipCode(selectedAddress.zip);
            if (selectedAddress.phone) setCustomerPhone(selectedAddress.phone);
        } else {
            setCustomerFirstName('');
            setCustomerLastName('');
            setCustomerCompany('');
            setCustomerStreetOne('');
            setCustomerStreetTwo('');
            setCustomerCity('');
            setCustomerState(undefined);
            setCustomerCountry(undefined);
            setCustomerZipCode('');
            setCustomerPhone('');
        }
    };

    const onSelectSavedStripePaymentMethod = (paymentMethod: IDropdownItem | undefined): void => {
        setSelectedSavedStripePaymentMethod(paymentMethod);
        setStripePaymentDetailsCompleted(!!paymentMethod);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.selectSavedStripePaymentMethod,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }

        // Update Payment Intent
        if (stripePaymentIntentId) {
            stripeUpdatePaymentIntent({
                test: process.env.NODE_ENV === 'development',
                paymentIntentId: stripePaymentIntentId,
                paymentMethodId: paymentMethod?.value || null,
            });
        }
    };

    const onSelectCountry = (country: IDropdownItem | undefined): void => {
        setCustomerCountry(country);

        if (country) {
            // Find country
            const countryItem = countriesJSON
                .find((item: any) => item.code2 === country.value) as ICountry;

            // Change country states
            const stateArr: IDropdownItem[] = (countryItem.states as ICountryState[]).map((state: ICountryState) => ({
                value: state.code,
                name: state.name,
            }));
            setStates(stateArr);
            setCustomerState(undefined);
        }
    };

    const onSelectState = (state: IDropdownItem | undefined): void => {
        setCustomerState(state);
    };

    const onSelectSavedBillingAddress = (address: IDropdownItem | undefined): void => {
        setSelectedSavedBillingAddress(address);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.selectSavedBillingAddress,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }

        if (address) {
            // Auto-populate address form
            const selectedAddress = savedAddresses[parseInt(address.value, 10)];
            setCustomerBillingFirstName(selectedAddress.firstName);
            setCustomerBillingLastName(selectedAddress.lastName);
            if (selectedAddress.company) {
                setCustomerBillingCompany(selectedAddress.company);
            } else if (customerBillingCompany.length > 0) {
                setCustomerBillingCompany('');
            }
            setCustomerBillingStreetOne(selectedAddress.street1);
            if (selectedAddress.street2) {
                setCustomerBillingStreetTwo(selectedAddress.street2);
            } else if (customerBillingStreetTwo.length > 0) {
                setCustomerBillingStreetTwo('');
            }
            setCustomerBillingCity(selectedAddress.city);
            // Find country
            const countryItem = countriesJSON
                .find((item: any) => item.code2 === selectedAddress.country) as ICountry;
            setCustomerBillingCountry({
                value: countryItem.code2,
                name: countryItem.name,
            });
            // Find state
            if (selectedAddress.state) {
                const stateMap: Map<string, IDropdownItem> = new Map(
                    (countryItem.states as ICountryState[]).map((state: ICountryState) => ([
                        state.code,
                        {
                            value: state.code,
                            name: state.name,
                        },
                    ])),
                );
                const stateItem = stateMap.get(selectedAddress.state);
                if (stateItem) {
                    const stateArr: IDropdownItem[] = (countryItem.states as ICountryState[]).map((state: ICountryState) => ({
                        value: state.code,
                        name: state.name,
                    }));
                    setStates(stateArr);
                    setCustomerBillingState(stateItem);
                }
            }
            setCustomerBillingZipCode(selectedAddress.zip);
            if (selectedAddress.phone) setCustomerBillingPhone(selectedAddress.phone);
        } else {
            setCustomerBillingFirstName('');
            setCustomerBillingLastName('');
            setCustomerBillingCompany('');
            setCustomerBillingStreetOne('');
            setCustomerBillingStreetTwo('');
            setCustomerBillingCity('');
            setCustomerBillingState(undefined);
            setCustomerBillingCountry(undefined);
            setCustomerBillingZipCode('');
            setCustomerBillingPhone('');
        }
    };

    const onSelectBillingCountry = (country: IDropdownItem | undefined): void => {
        setCustomerBillingCountry(country);

        if (country) {
            // Find country
            const countryItem = countriesJSON
                .find((item: any) => item.code2 === country.value) as ICountry;

            // Change country states
            const stateArr: IDropdownItem[] = (countryItem.states as ICountryState[]).map((state: ICountryState) => ({
                value: state.code,
                name: state.name,
            }));
            setBillingStates(stateArr);
            setCustomerBillingState(undefined);
        }
    };

    const onSelectBillingState = (state: IDropdownItem | undefined): void => {
        setCustomerBillingState(state);
    };

    const onReviewEditButtonClick = (newCheckpoint: CHECKOUT_CHECKPOINT, e: React.MouseEvent): void => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickEditCheckoutDetailsButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    to: newCheckpoint,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.checkout}/${newCheckpoint}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    const handleActionButtonMessageEllipsisText = (): void => {
        const currentEllipsis = actionButtonMessageEllipsis;
        const currentNumDots = currentEllipsis.split('.').length - 1;
        let updatedNumDots;
        if (currentNumDots === 3) {
            updatedNumDots = 0;
        } else {
            updatedNumDots = currentNumDots + 1;
        }

        setActionButtonMessageEllipsis(`${'.'.repeat(updatedNumDots)}`);
    };

    const onBackButtonClick = (): void => {
        // Play Sound
        if (hasSound && swooshOutClip.current) {
            swooshOutClip.current.pause();
            swooshOutClip.current.currentTime = 0;
            playAudio(swooshOutClip.current);
        }

        let url: string | undefined;
        let toCheckpoint: CHECKOUT_CHECKPOINT | undefined;
        switch (checkpoint) {
        case CHECKOUT_CHECKPOINT.info:
            url = `/${PAGE_ROUTE.cart}`;
            break;
        case CHECKOUT_CHECKPOINT.shipping:
            url =  `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.info}`;
            toCheckpoint = CHECKOUT_CHECKPOINT.info;
            break;
        case CHECKOUT_CHECKPOINT.billing:
            if (physicalItemsInCart.length > 0) {
                url = `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.shipping}`;
                toCheckpoint = CHECKOUT_CHECKPOINT.shipping;
            } else {
                url = `/${PAGE_ROUTE.cart}`;
            }
            break;
        case CHECKOUT_CHECKPOINT.review:
            url = `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`;
            toCheckpoint = CHECKOUT_CHECKPOINT.billing;
            break;
        default:
            // CHECKOUT_CHECKPOINT.confirmation
            // Do nothing
            // Should not allow to go back
        }
        if (
            user
            && currentSessionId
            && toCheckpoint
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickCheckoutBackButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    to: toCheckpoint,
                },
            });
        }
        if (url) {
            navigate(
                url,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        }
    };

    const onActionButtonClick = async (): Promise<void> => {
        const auth = getAuth();
        let shippoMailingAddressValidation: Shippo.Address | undefined;
        let shippoBillingAddressValidation: Shippo.Address | undefined;
        // let stripePaymentError: { error: Stripe.StripeError };
        switch (checkpoint) {
        case CHECKOUT_CHECKPOINT.info:
            if (physicalItemsInCart.length === 0) {
                throw Error(
                    `There are no items in your cart. Mailing address view should not be presented. Please contact the verascope team for support at: ${
                        SUPPORT_EMAIL_ADDRESS
                    }.`,
                );
            }

            // Validate Country
            if (!customerCountry) {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_COUNTRY_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!countryNotSelected) setCountryNotSelected(true);
                break;
            } else if (countryNotSelected) {
                setCountryNotSelected(false);
            }

            // Validate First Name
            if (customerFirstName.length > 0) {
                if (firstNameNotProvided) setFirstNameNotProvided(false);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_FIRST_NAME_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!firstNameNotProvided) setFirstNameNotProvided(true);
                break;
            }

            // Validate Last Name
            if (customerLastName.length > 0) {
                if (lastNameNotProvided) setLastNameNotProvided(false);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_LAST_NAME_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!lastNameNotProvided) setLastNameNotProvided(true);
                break;
            }

            // Validate State
            // eslint-disable-next-line no-case-declarations
            const countryItem = countriesJSON
                .find((item: any) => item.code2 === customerCountry.value) as ICountry;

            if (customerCountry
                && !customerState
                && countryItem.states.length > 0
            ) {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_STATE_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!stateNotSelected) setStateNotSelected(true);
                break;
            } else if (stateNotSelected) {
                setStateNotSelected(false);
            }

            // Validate City
            if (customerCity.length > 0) {
                if (cityNotProvided) setCityNotProvided(false);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_CITY_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!cityNotProvided) setCityNotProvided(true);
                break;
            }

            // Validate Zip Code
            if (customerZipCode.length > 0) {
                if (zipCodeNotProvided) setZipCodeNotProvided(false);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_ZIP_CODE_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!zipCodeNotProvided) setZipCodeNotProvided(true);
                break;
            }

            // Validate Street One
            if (customerStreetOne.length > 0) {
                if (streetOneNotProvided) setStreetOneNotProvided(false);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_STREET_ONE_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!streetOneNotProvided) setStreetOneNotProvided(true);
                break;
            }

            // Validate Phone Number
            if (customerPhone.length > 0) {
                if (phoneNumberNotProvided) setPhoneNumberNotProvided(false);
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_NO_PHONE_NUMBER_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (!phoneNumberNotProvided) setPhoneNumberNotProvided(true);
                break;
            }

            // Validate mailing address
            try {
                // IMPORTANT: Validation doesn't work for international addresses in testing
                // In live, international address validation is $0.06/address
                const addressObj: IShippoAddressObject = {
                    name: `${customerFirstName} ${customerLastName}`,
                    street1: customerStreetOne,
                    city: customerCity,
                    zip: customerZipCode,
                    country: customerCountry.value,
                    validate: true,
                };
                if (customerCompany.length > 0) addressObj.company = customerCompany;
                if (customerStreetTwo.length > 0) addressObj.street2 = customerStreetTwo;
                if (customerState) addressObj.state = customerState.value;
                const validatePhoneNumber = customerPhone.length > 2
                    && customerPhone.substring(0, 2) === '00'
                    ? phone(`+${customerPhone.substring(2)}`)
                    : phone(customerPhone);
                if (validatePhoneNumber.isValid) {
                    addressObj.phone = validatePhoneNumber.phoneNumber;
                }
                if (auth.currentUser?.email) addressObj.email = auth.currentUser.email;
                setActionButtonIsLoading(true);
                setActionButtonLoadingMessage('Validating address');
                shippoMailingAddressValidation = await shippoAddressValidation({
                    test: process.env.NODE_ENV === 'development',
                    address: addressObj,
                });
            } catch (e) {
                setActionButtonIsLoading(false);
                setActionButtonLoadingMessage('');
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_MAILING_ADDRESS_ERROR_MESSAGE((e as any).toString()),
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (user && currentSessionId) {
                    recordUserAction({
                        type: USER_ACTION_TYPE.error,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            error: SNACKBAR_MESSAGE_MAILING_ADDRESS_ERROR_MESSAGE((e as any).toString()),
                        },
                    });
                }
                break;
            }

            if (
                stripePaymentIntentId
                && shippoMailingAddressValidation
                && shippoMailingAddressValidation.validation_results?.is_valid
            ) {
                try {
                    if (!addressIsValid) setAddressIsValid(true);
                    // Update mailing address
                    const mailingAddress: IAddress = {
                        firstName: customerFirstName,
                        lastName: customerLastName,
                        street1: customerStreetOne,
                        city: customerCity,
                        zip: customerZipCode,
                        country: customerCountry.value,
                    };
                    if (customerCompany.length > 0) mailingAddress.company = customerCompany;
                    if (customerStreetTwo.length > 0) mailingAddress.street2 = customerStreetTwo;
                    if (customerState) mailingAddress.state = customerState.value;
                    const validatePhoneNumber = customerPhone.length > 2
                        && customerPhone.substring(0, 2) === '00'
                        ? phone(`+${customerPhone.substring(2)}`)
                        : phone(customerPhone);
                    if (validatePhoneNumber.isValid) {
                        mailingAddress.phone = validatePhoneNumber.phoneNumber;
                    }
                    // Update address based on Shippo
                    if (customerStreetOne !== shippoMailingAddressValidation.street1) {
                        setCustomerStreetOne(shippoMailingAddressValidation.street1);
                        mailingAddress.street1 = shippoMailingAddressValidation.street1;
                    }
                    if (
                        customerStreetTwo.length > 0
                        && customerStreetTwo !== shippoMailingAddressValidation.street2
                    ) {
                        setCustomerStreetTwo(shippoMailingAddressValidation.street2!);
                        mailingAddress.street2 = shippoMailingAddressValidation.street2;
                    }
                    if (customerCity !== shippoMailingAddressValidation.city) {
                        setCustomerCity(shippoMailingAddressValidation.city);
                        mailingAddress.city = shippoMailingAddressValidation.city;
                    }
                    if (
                        shippoMailingAddressValidation.zip
                        && customerZipCode !== shippoMailingAddressValidation.zip
                    ) {
                        setCustomerZipCode(shippoMailingAddressValidation.zip);
                        mailingAddress.zip = shippoMailingAddressValidation.zip;
                    }
                    if (
                        shippoMailingAddressValidation.phone
                        && shippoMailingAddressValidation.phone.length > 0
                        && customerPhone !== shippoMailingAddressValidation.phone
                    ) {
                        setCustomerPhone(shippoMailingAddressValidation.phone);
                        mailingAddress.phone = shippoMailingAddressValidation.phone;
                    } else if (
                        shippoMailingAddressValidation.phone
                        && shippoMailingAddressValidation.phone.length > 0
                        && mailingAddress.phone !== shippoMailingAddressValidation.phone
                    ) {
                        mailingAddress.phone = shippoMailingAddressValidation.phone;
                    }

                    if (stripePaymentIntentId) {
                        // Update Payment Intent
                        stripeUpdatePaymentIntent({
                            test: process.env.NODE_ENV === 'development',
                            paymentIntentId: stripePaymentIntentId,
                            mailingAddress,
                        });

                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.mailingInfoCollected);
                    }

                    // Get shipping rates
                    const addressTo: IShippoAddressObject = {
                        name: shippoMailingAddressValidation.name,
                        street1: shippoMailingAddressValidation.street1,
                        city: shippoMailingAddressValidation.city,
                        state: shippoMailingAddressValidation.state,
                        zip: customerZipCode,
                        country: customerCountry.value,
                    };
                    if (
                        shippoMailingAddressValidation.company
                        && shippoMailingAddressValidation.company.length > 0
                    ) {
                        addressTo.company = shippoMailingAddressValidation.company;
                    }
                    if (
                        shippoMailingAddressValidation.street2
                        && shippoMailingAddressValidation.street2.length > 0
                    ) {
                        addressTo.street2 = shippoMailingAddressValidation.street2;
                    }
                    if (
                        shippoMailingAddressValidation.street3
                        && shippoMailingAddressValidation.street3.length > 0
                    ) {
                        addressTo.street3 = shippoMailingAddressValidation.street3;
                    }
                    if (
                        shippoMailingAddressValidation.zip
                        && shippoMailingAddressValidation.zip.length > 0
                    ) {
                        addressTo.zip = shippoMailingAddressValidation.zip;
                    }
                    if (
                        shippoMailingAddressValidation.country
                        && shippoMailingAddressValidation.country.length > 0
                    ) {
                        addressTo.country = shippoMailingAddressValidation.country;
                    }
                    if (
                        shippoMailingAddressValidation.phone
                        && shippoMailingAddressValidation.phone.length > 0
                    ) {
                        addressTo.phone = shippoMailingAddressValidation.phone;
                    }
                    if (
                        shippoMailingAddressValidation.email
                        && shippoMailingAddressValidation.email.length > 0
                    ) {
                        addressTo.email = shippoMailingAddressValidation.email;
                    }
                    setActionButtonLoadingMessage('Fetching shipment rates');
                    const shipment = await shippoCreateShipment({
                        test: process.env.NODE_ENV === 'development',
                        paymentIntentId: stripePaymentIntentId,
                        addressTo,
                        physicalItemsInCart,
                        internationalShipment: !!user?.cart
                            && fetchedStoreItems
                            && customerCountry.value !== 'US',
                    });
                    setActionButtonIsLoading(false);
                    setActionButtonLoadingMessage('');

                    // Store shipping rates
                    const ratesMap: Map<string, IShippoRateObject> = new Map();
                    const { rates } = shipment;
                    rates.forEach((rate: Shippo.Rate) => {
                        const shippoRate: IShippoRateObject = {
                            id: rate.object_id,
                            timestamp: rate.object_created,
                            attributes: rate.attributes,
                            amount: rate.amount,
                            currency: rate.currency,
                            provider: rate.provider,
                            serviceName: rate.servicelevel.name,
                            estimatedDays: parseInt(rate.estimated_days, 10),
                            durationTerms: rate.duration_terms,
                        };
                        ratesMap.set(rate.object_id, shippoRate);
                    });
                    const shippoRates = Array.from(ratesMap.values());
                    setShippingRates(ratesMap);
                    const rateList: IRadioItem[] = shippoRates.map((rate: IShippoRateObject) => {
                        const radioItem: IRadioItem = {
                            value: rate.id,
                            title: `${
                                rate.provider
                            } ${
                                rate.serviceName
                            } (${
                                rate.estimatedDays
                            } day${
                                rate.estimatedDays === 1 ? '' : 's'
                            })`,
                            subtitle: rate.durationTerms,
                            detail: `$${rate.amount}`, // in dollars
                        };
                        if (rate.attributes.length > 0) {
                            const [rateAttribute] = rate.attributes;
                            switch (rateAttribute) {
                            case SHIPPO_RATE_ATTRIBUTE_TYPE.fastest:
                                radioItem.annotation = 'Fastest';
                                break;
                            case SHIPPO_RATE_ATTRIBUTE_TYPE.bestValue:
                                radioItem.annotation = 'Best Value';
                                break;
                            default:
                                // SHIPPO_RATE_ATTRIBUTE_TYPE.cheapest
                                radioItem.annotation = 'Cheapest';
                                break;
                            }
                        }
                        return radioItem;
                    });
                    const shippingMethodMap: Map<string, IRadioItem> = new Map();
                    rateList.forEach((rate: IRadioItem) => {
                        shippingMethodMap.set(rate.value, rate);
                    });
                    setShippingMethods(shippingMethodMap);

                    // Play Sound
                    if (hasSound && swooshInClip.current) {
                        swooshInClip.current.pause();
                        swooshInClip.current.currentTime = 0;
                        playAudio(swooshInClip.current);
                    }

                    if (user && currentSessionId) {
                        // Record user action
                        recordUserAction({
                            type: USER_ACTION_TYPE.clickCheckoutActionButton,
                            userId: user.id,
                            sessionId: currentSessionId,
                            payload: {
                                to: CHECKOUT_CHECKPOINT.shipping,
                            },
                        });
                    }

                    // Update to have shippo address details
                    navigate(
                        `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.shipping}`,
                        {
                            state: {
                                prevPath: location.pathname,
                            },
                        },
                    );

                    if (checkpointFormContainerRef.current) {
                        checkpointFormContainerRef.current.scrollTop = 0;
                    }

                    if (snackbarData.visible) {
                        setSnackbarData({
                            visible: false,
                            text: '',
                        });
                    }
                } catch (e) {
                    setActionButtonIsLoading(false);
                    setActionButtonLoadingMessage('');
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_SHIPPING_RATE_ERROR_MESSAGE((e as any).toString()),
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (user && currentSessionId) {
                        recordUserAction({
                            type: USER_ACTION_TYPE.error,
                            userId: user.id,
                            sessionId: currentSessionId,
                            payload: {
                                error: SNACKBAR_MESSAGE_SHIPPING_RATE_ERROR_MESSAGE((e as any).toString()),
                            },
                        });
                    }
                    break;
                }
            } else {
                setActionButtonIsLoading(false);
                setActionButtonLoadingMessage('');
                if (addressIsValid) setAddressIsValid(false);
                if (
                    shippoMailingAddressValidation
                    && shippoMailingAddressValidation.validation_results?.messages
                ) {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_MAILING_ADDRESS_ERROR_MESSAGE(
                            shippoMailingAddressValidation.validation_results.messages[0].text,
                        ),
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_MAILING_ADDRESS_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                }
            }
            break;
        case CHECKOUT_CHECKPOINT.shipping:
            if (selectedShippingRateID) {
                // Update Payment Intent
                const shippingRate = shippingRates.get(selectedShippingRateID) as IShippoRateObject;
                if (stripePaymentIntentId && shippingRate) {
                    setActionButtonIsLoading(true);
                    setActionButtonLoadingMessage('Selecting rate');

                    // Update stripe
                    const updatedPaymentIntent = await stripeUpdatePaymentIntent({
                        test: process.env.NODE_ENV === 'development',
                        paymentIntentId: stripePaymentIntentId,
                        shippingRate,
                    });

                    // Change checkout status
                    changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.shippingMethodSelected);

                    // Update order cost
                    if (
                        updatedPaymentIntent.metadata
                        && updatedPaymentIntent.metadata.taxCalculationId
                        && updatedPaymentIntent.metadata.taxCalculationId !== stripeTaxCalculationId
                    ) {
                        setActionButtonLoadingMessage('Calculating taxes');
                        stripeGetTaxCalculationLineItems({
                            test: process.env.NODE_ENV === 'development',
                            taxCalculationId: updatedPaymentIntent.metadata.taxCalculationId,
                        }).then((taxCalculationLineItems) => {
                            const subtotal = taxCalculationLineItems.reduce((
                                sum: number,
                                lineItem: Stripe.Tax.CalculationLineItem,
                            ) => (
                                sum + (lineItem.quantity * lineItem.amount)
                            ), 0);
                            const shipping = parseFloat(shippingRate.amount) * CENTS_IN_A_DOLLAR;
                            let tax = taxCalculationLineItems.reduce((
                                sum: number,
                                lineItem: Stripe.Tax.CalculationLineItem,
                            ) => (
                                sum + (lineItem.quantity * lineItem.amount_tax)
                            ), 0);
                            if (updatedPaymentIntent.metadata.shippingTax) {
                                tax += parseInt(updatedPaymentIntent.metadata.shippingTax, 10);
                            }
                            const total = !!subtotal
                                && (
                                    shipping !== undefined
                                    || physicalItemsInCart.length === 0
                                )
                                && tax !== undefined
                                ? subtotal + (shipping || 0) + tax
                                : undefined;
                            setOrderCost({
                                subtotal,
                                shipping,
                                tax,
                                total,
                            });
                            setStripeTaxCalculationId(updatedPaymentIntent.metadata.taxCalculationId);
                        });
                    }
                } else {
                    setActionButtonIsLoading(false);
                    setActionButtonLoadingMessage('');
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_SHIPPING_NO_RATE_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    break;
                }

                setActionButtonIsLoading(false);
                setActionButtonLoadingMessage('');

                // Play Sound
                if (hasSound && swooshInClip.current) {
                    swooshInClip.current.pause();
                    swooshInClip.current.currentTime = 0;
                    playAudio(swooshInClip.current);
                }

                if (user && currentSessionId) {
                    // Record user action
                    recordUserAction({
                        type: USER_ACTION_TYPE.clickCheckoutActionButton,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            to: CHECKOUT_CHECKPOINT.billing,
                        },
                    });
                }

                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );

                if (checkpointFormContainerRef.current) {
                    checkpointFormContainerRef.current.scrollTop = 0;
                }

                if (snackbarData.visible) {
                    setSnackbarData({
                        visible: false,
                        text: '',
                    });
                }
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_SELECT_VALID_SHIPPING_METHOD_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
            }
            break;
        case CHECKOUT_CHECKPOINT.billing:
            if (!selectedBillingAddressType) {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_BILLING_NO_ADDRESS_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                break;
            } else if (
                physicalItemsInCart.length === 0
                || (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                )
            ) {
                // Validate Billing Country
                if (!customerBillingCountry) {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_COUNTRY_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingCountryNotSelected) setBillingCountryNotSelected(true);
                    break;
                } else if (billingCountryNotSelected) {
                    setBillingCountryNotSelected(false);
                }

                // Validate Billing First Name
                if (customerBillingFirstName.length > 0) {
                    if (billingFirstNameNotProvided) setBillingFirstNameNotProvided(false);
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_FIRST_NAME_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingFirstNameNotProvided) setBillingFirstNameNotProvided(true);
                    break;
                }

                // Validate Billing Last Name
                if (customerBillingLastName.length > 0) {
                    if (billingLastNameNotProvided) setBillingLastNameNotProvided(false);
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_LAST_NAME_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingLastNameNotProvided) setBillingLastNameNotProvided(true);
                    break;
                }

                // Validate Billing State
                // eslint-disable-next-line no-case-declarations
                const billingCountryItem = countriesJSON
                    .find((item: any) => item.code2 === customerBillingCountry.value) as ICountry;

                if (customerBillingCountry
                    && !customerBillingState
                    && billingCountryItem.states.length > 0
                ) {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_STATE_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingStateNotSelected) setBillingStateNotSelected(true);
                    break;
                } else if (billingStateNotSelected) {
                    setBillingStateNotSelected(false);
                }

                // Validate Billing City
                if (customerBillingCity.length > 0) {
                    if (billingCityNotProvided) setBillingCityNotProvided(false);
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_CITY_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingCityNotProvided) setBillingCityNotProvided(true);
                    break;
                }

                // Validate Billing Zip Code
                if (customerBillingZipCode.length > 0) {
                    if (billingZipCodeNotProvided) setBillingZipCodeNotProvided(false);
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_ZIP_CODE_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingZipCodeNotProvided) setBillingZipCodeNotProvided(true);
                    break;
                }

                // Validate Billing Street One
                if (customerBillingStreetOne.length > 0) {
                    if (billingStreetOneNotProvided) setBillingStreetOneNotProvided(false);
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_STREET_ONE_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingStreetOneNotProvided) setBillingStreetOneNotProvided(true);
                    break;
                }

                // Validate Billing Phone Number
                if (customerBillingPhone.length > 0) {
                    if (phoneNumberNotProvided) setBillingPhoneNumberNotProvided(false);
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_NO_BILLING_PHONE_NUMBER_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    if (!billingPhoneNumberNotProvided) setBillingPhoneNumberNotProvided(true);
                    break;
                }
            }

            // Validate billing address
            try {
                // IMPORTANT: Validation doesn't work for international addresses in testing
                // In live, international address validation is $0.06/address
                const addressObj: IShippoAddressObject = {
                    name: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? `${customerBillingFirstName} ${customerBillingLastName}`
                        : `${customerFirstName} ${customerLastName}`,
                    street1: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingStreetOne
                        : customerStreetOne,
                    city: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingCity
                        : customerCity,
                    zip: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingZipCode
                        : customerZipCode,
                    country: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingCountry!.value
                        : customerCountry!.value,
                    validate: true,
                };
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerCompany.length > 0
                ) {
                    addressObj.company = customerCompany;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingCompany.length > 0
                ) {
                    addressObj.company = customerBillingCompany;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerStreetTwo.length > 0
                ) {
                    addressObj.street2 = customerStreetTwo;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingStreetTwo.length > 0
                ) {
                    addressObj.street2 = customerBillingStreetTwo;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerState
                ) {
                    addressObj.state = customerState.value;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingState
                ) {
                    addressObj.state = customerBillingState.value;
                }
                const validatePhoneNumber = customerPhone.length > 2
                    && customerPhone.substring(0, 2) === '00'
                    ? phone(`+${customerPhone.substring(2)}`)
                    : phone(customerPhone);
                const validateBillingPhoneNumber = customerBillingPhone.length > 2
                    && customerBillingPhone.substring(0, 2) === '00'
                    ? phone(`+${customerBillingPhone.substring(2)}`)
                    : phone(customerBillingPhone);
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && validatePhoneNumber.isValid
                ) {
                    addressObj.phone = validatePhoneNumber.phoneNumber;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && validateBillingPhoneNumber.isValid
                ) {
                    addressObj.phone = validateBillingPhoneNumber.phoneNumber;
                }
                if (auth.currentUser?.email) addressObj.email = auth.currentUser.email;
                setActionButtonIsLoading(true);
                setActionButtonLoadingMessage('Validating address');
                shippoBillingAddressValidation = await shippoAddressValidation({
                    test: process.env.NODE_ENV === 'development',
                    address: addressObj,
                });
            } catch (e) {
                setActionButtonIsLoading(false);
                setActionButtonLoadingMessage('');
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_BILLING_ADDRESS_ERROR_MESSAGE((e as any).toString()),
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
                if (user && currentSessionId) {
                    recordUserAction({
                        type: USER_ACTION_TYPE.error,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            error: SNACKBAR_MESSAGE_BILLING_ADDRESS_ERROR_MESSAGE((e as any).toString()),
                        },
                    });
                }
                break;
            }

            if (
                shippoBillingAddressValidation
                && shippoBillingAddressValidation.validation_results?.is_valid
            ) {
                if (!billingAddressIsValid) setBillingAddressIsValid(true);

                // Update billing address
                const billingAddress: IAddress = {
                    firstName: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingFirstName
                        : customerFirstName,
                    lastName: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingLastName
                        : customerLastName,
                    street1: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingStreetOne
                        : customerStreetOne,
                    city: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingCity
                        : customerCity,
                    zip: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingZipCode
                        : customerZipCode,
                    country: selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                        ? customerBillingCountry!.value
                        : customerCountry!.value,
                };
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerCompany.length > 0
                ) {
                    billingAddress.company = customerCompany;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingCompany.length > 0
                ) {
                    billingAddress.company = customerBillingCompany;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerStreetTwo.length > 0
                ) {
                    billingAddress.street2 = customerStreetTwo;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingStreetTwo.length > 0
                ) {
                    billingAddress.street2 = customerBillingStreetTwo;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerState
                ) {
                    billingAddress.state = customerState.value;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingState
                ) {
                    billingAddress.state = customerBillingState.value;
                }
                const validatePhoneNumber = customerPhone.length > 2
                    && customerPhone.substring(0, 2) === '00'
                    ? phone(`+${customerPhone.substring(2)}`)
                    : phone(customerPhone);
                const validateBillingPhoneNumber = customerBillingPhone.length > 2
                    && customerBillingPhone.substring(0, 2) === '00'
                    ? phone(`+${customerBillingPhone.substring(2)}`)
                    : phone(customerBillingPhone);
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && validatePhoneNumber.isValid
                ) {
                    billingAddress.phone = validatePhoneNumber.phoneNumber;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && validateBillingPhoneNumber.isValid
                ) {
                    billingAddress.phone = validateBillingPhoneNumber.phoneNumber;
                }
                // Update address based on Shippo
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingStreetOne !== shippoBillingAddressValidation.street1
                ) {
                    setCustomerBillingStreetOne(shippoBillingAddressValidation.street1);
                    billingAddress.street1 = shippoBillingAddressValidation.street1;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && customerStreetTwo.length > 0
                    && customerStreetTwo !== shippoBillingAddressValidation.street2
                ) {
                    billingAddress.street2 = shippoBillingAddressValidation.street2;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingStreetTwo.length > 0
                    && customerBillingStreetTwo !== shippoBillingAddressValidation.street2
                ) {
                    setCustomerBillingStreetTwo(shippoBillingAddressValidation.street2!);
                    billingAddress.street2 = shippoBillingAddressValidation.street2;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && customerBillingCity !== shippoBillingAddressValidation.city
                ) {
                    setCustomerBillingCity(shippoBillingAddressValidation.city);
                    billingAddress.city = shippoBillingAddressValidation.city;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && shippoBillingAddressValidation.zip
                    && customerBillingZipCode !== shippoBillingAddressValidation.zip
                ) {
                    setCustomerBillingZipCode(shippoBillingAddressValidation.zip);
                    billingAddress.zip = shippoBillingAddressValidation.zip;
                }
                if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && shippoBillingAddressValidation.phone
                    && shippoBillingAddressValidation.phone.length > 0
                    && customerBillingPhone !== shippoBillingAddressValidation.phone
                ) {
                    setCustomerBillingPhone(shippoBillingAddressValidation.phone);
                    billingAddress.phone = shippoBillingAddressValidation.phone;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.different
                    && shippoBillingAddressValidation.phone
                    && shippoBillingAddressValidation.phone.length > 0
                    && billingAddress.phone !== shippoBillingAddressValidation.phone
                ) {
                    billingAddress.phone = shippoBillingAddressValidation.phone;
                } else if (
                    selectedBillingAddressType
                    && selectedBillingAddressType.value ===  BILLING_ADDRESS_TYPE.same
                    && shippoBillingAddressValidation.phone
                    && shippoBillingAddressValidation.phone.length > 0
                    && billingAddress.phone !== shippoBillingAddressValidation.phone
                ) {
                    billingAddress.phone = shippoBillingAddressValidation.phone;
                }

                if (stripePaymentIntentId) {
                    // Update Payment Intent
                    const updatedPaymentIntent = await stripeUpdatePaymentIntent({
                        test: process.env.NODE_ENV === 'development',
                        paymentIntentId: stripePaymentIntentId,
                        billingAddress,
                        addToMailingList: subscribeToMailingList,
                        savePaymentMethod,
                    });

                    // Change checkout status
                    changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.paymentInfoCollected);

                    // Validate payment details / check for payment method errors
                    if (!stripe || !elements) {
                        // Stripe.js hasn't yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        setActionButtonIsLoading(false);
                        setActionButtonLoadingMessage('');
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_STRIPE_REVIEW_ERROR,
                            icon: PixelCartIcon,
                            hasFailure: true,
                        });
                        return;
                    }
                    setActionButtonLoadingMessage('Validating payment method');
                    if (!selectedSavedStripePaymentMethod) {
                        const paymentDetailsValidation = await elements.submit();
                        if (paymentDetailsValidation.error?.message) {
                            setActionButtonIsLoading(false);
                            setActionButtonLoadingMessage('');
                            setSnackbarData({
                                visible: true,
                                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                                text: SNACKBAR_MESSAGE_PAYMENT_DETAILS_ERROR_MESSAGE(paymentDetailsValidation.error.message),
                                icon: PixelCartIcon,
                                hasFailure: true,
                            });
                            break;
                        }
                    }

                    // Update order cost
                    if (
                        updatedPaymentIntent.metadata
                        && updatedPaymentIntent.metadata.taxCalculationId
                        && updatedPaymentIntent.metadata.taxCalculationId !== stripeTaxCalculationId
                    ) {
                        setActionButtonLoadingMessage('Calculating taxes');
                        const taxCalculationLineItems = await stripeGetTaxCalculationLineItems({
                            test: process.env.NODE_ENV === 'development',
                            taxCalculationId: updatedPaymentIntent.metadata.taxCalculationId,
                        });

                        const subtotal = taxCalculationLineItems.reduce((
                            sum: number,
                            lineItem: Stripe.Tax.CalculationLineItem,
                        ) => (
                            sum + (lineItem.quantity * lineItem.amount)
                        ), 0);
                        let shipping: number | undefined;
                        if (updatedPaymentIntent.metadata.shippingRate) {
                            const rate = JSON.parse(updatedPaymentIntent.metadata.shippingRate) as IShippoRateObject;
                            shipping = parseFloat(rate.amount) * CENTS_IN_A_DOLLAR;
                        }
                        let tax = taxCalculationLineItems.reduce((
                            sum: number,
                            lineItem: Stripe.Tax.CalculationLineItem,
                        ) => (
                            sum + (lineItem.quantity * lineItem.amount_tax)
                        ), 0);
                        if (updatedPaymentIntent.metadata.shippingTax) {
                            tax += parseInt(updatedPaymentIntent.metadata.shippingTax, 10);
                        }
                        const total = !!subtotal
                            && (
                                shipping !== undefined
                                || physicalItemsInCart.length === 0
                            )
                            && tax !== undefined
                            ? subtotal + (shipping || 0) + tax
                            : undefined;
                        setOrderCost({
                            subtotal,
                            shipping,
                            tax,
                            total,
                        });
                        setStripeTaxCalculationId(updatedPaymentIntent.metadata.taxCalculationId);
                    }
                    setActionButtonIsLoading(false);
                    setActionButtonLoadingMessage('');
                }

                // Play Sound
                if (hasSound && swooshInClip.current) {
                    swooshInClip.current.pause();
                    swooshInClip.current.currentTime = 0;
                    playAudio(swooshInClip.current);
                }

                if (user && currentSessionId) {
                    // Record user action
                    recordUserAction({
                        type: USER_ACTION_TYPE.clickCheckoutActionButton,
                        userId: user.id,
                        sessionId: currentSessionId,
                        payload: {
                            to: CHECKOUT_CHECKPOINT.review,
                        },
                    });
                }

                setCustomerDetailsCompleted(true);

                navigate(
                    `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.review}`,
                    {
                        state: {
                            prevPath: location.pathname,
                        },
                    },
                );

                if (checkpointFormContainerRef.current) {
                    checkpointFormContainerRef.current.scrollTop = 0;
                }

                if (snackbarData.visible) {
                    setSnackbarData({
                        visible: false,
                        text: '',
                    });
                }
            } else {
                setActionButtonIsLoading(false);
                setActionButtonLoadingMessage('');
                if (billingAddressIsValid) setBillingAddressIsValid(false);
                if (
                    shippoBillingAddressValidation
                    && shippoBillingAddressValidation.validation_results?.messages
                ) {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_BILLING_ADDRESS_ERROR_MESSAGE(
                            shippoBillingAddressValidation.validation_results.messages[0].text,
                        ),
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_BILLING_ADDRESS_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                }
            }
            break;
        case CHECKOUT_CHECKPOINT.review:
            if (
                stripePaymentIntentId
                && checkpoint === CHECKOUT_CHECKPOINT.review
            ) {
                // Change checkout status
                changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.reviewed);

                if (!stripe || !elements) {
                    // Stripe.js hasn't yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_STRIPE_REVIEW_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    return;
                }

                if (user && currentSessionId) {
                    // Record user action
                    recordUserAction({
                        type: USER_ACTION_TYPE.clickCheckoutBuyButton,
                        userId: user.id,
                        sessionId: currentSessionId,
                    });
                }

                setStripePaymentIsLoading(true);

                // Create customer
                const userEmail = user?.email || auth.currentUser?.email || undefined;
                if (
                    user
                    && !user.stripeCustomerId
                    && userEmail
                ) {
                    setActionButtonLoadingMessage('Generating customer profile');
                    const customer = await stripeCreateCustomer({
                        test: process.env.NODE_ENV === 'development',
                        email: userEmail,
                        name: `${user.firstName} ${user.lastName}`,
                        userId: user.id,
                    });
                    await stripeUpdatePaymentIntent({
                        test: process.env.NODE_ENV === 'development',
                        paymentIntentId: stripePaymentIntentId,
                        customerId: customer.id,
                    });
                    // Change in firestore
                    const usersCollection = process.env.NODE_ENV === 'production'
                        ? FIRESTORE_COLLECTION.users
                        : FIRESTORE_COLLECTION.stagingUsers;
                    const db = getFirestore();
                    runTransaction(db, async (transaction) => {
                        const sessionRef = doc(
                            db,
                            usersCollection,
                            user.id,
                        );
                        transaction.update(sessionRef, {
                            updated: Date.now(),
                            stripeCustomerId: customer.id,
                        });
                    });
                }

                // Confirm Payment
                setActionButtonLoadingMessage('Confirming payment');
                const confirmParams: ConfirmPaymentData = {
                    return_url: `${window.location.origin}/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.confirmation}`,
                    save_payment_method: savePaymentMethod && !selectedSavedStripePaymentMethod,
                };
                let stripePaymentError: StripeError | undefined;
                if (
                    selectedSavedStripePaymentMethod
                    && stripeClientSecret
                ) {
                    confirmParams.payment_method = selectedSavedStripePaymentMethod.value;
                    const { error } = await stripe.confirmPayment({
                        clientSecret: stripeClientSecret,
                        confirmParams,
                    });
                    stripePaymentError = error;
                } else if (
                    !selectedSavedStripePaymentMethod
                ) {
                    const { error } = await stripe.confirmPayment({
                        elements,
                        confirmParams,
                    });
                    stripePaymentError = error;
                } else {
                    setStripePaymentIsLoading(false);
                    setActionButtonLoadingMessage('');
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_GENERIC_PAYMENT_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    break;
                }

                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, customer will be redirected to
                // `return_url`.
                if (stripePaymentError) {
                    setStripePaymentIsLoading(false);
                    setActionButtonLoadingMessage('');
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: stripePaymentError.message
                            ? SNACKBAR_MESSAGE_PAYMENT_ERROR_MESSAGE(stripePaymentError.message)
                            : SNACKBAR_MESSAGE_GENERIC_PAYMENT_ERROR,
                        icon: PixelCartIcon,
                        hasFailure: true,
                    });
                    switch (stripePaymentError.type) {
                    case STRIPE_PAYMENT_INTENT_ERROR_TYPE.cardError:
                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.cardError);
                        break;
                    case STRIPE_PAYMENT_INTENT_ERROR_TYPE.apiError:
                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.apiError);
                        break;
                    case STRIPE_PAYMENT_INTENT_ERROR_TYPE.idempotencyError:
                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.idempotencyError);
                        break;
                    case STRIPE_PAYMENT_INTENT_ERROR_TYPE.invalidRequestError:
                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.invalidRequestError);
                        break;
                    case STRIPE_PAYMENT_INTENT_ERROR_TYPE.validationError:
                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.validationError);
                        break;
                    default:
                        // Change checkout status
                        changeCheckoutSessionStatus(CHECKOUT_SESSION_STATUS_TYPE.unknownPaymentError);
                    }
                }
            } else {
                setSnackbarData({
                    visible: true,
                    duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                    text: SNACKBAR_MESSAGE_REVIEW_ERROR,
                    icon: PixelCartIcon,
                    hasFailure: true,
                });
            }
            break;
        default:
            // CHECKOUT_CHECKPOINT.confirmation
            // Do nothing
            // Should not allow to go forward
        }
    };

    const onStripePaymentElementEnter = (): void => {
        setCursorIsHidden(true);
    };

    const onStripePaymentElementLeave = (): void => {
        setCursorIsHidden(false);
    };

    const onStripePaymentElementChange = (e: StripePaymentElementChangeEvent): void => {
        const paymentMethodType = e.value;
        if (paymentMethodType) {
            setStripePaymentMethodType(paymentMethodType.type);
            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.selectCheckoutPaymentMethod,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        type: paymentMethodType,
                    },
                });
            }
        }
        if (
            e.complete
            && !stripePaymentDetailsCompleted
        ) {
            setStripePaymentDetailsCompleted(true);
            if (user && currentSessionId) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.completeCheckoutPaymentDetails,
                    userId: user.id,
                    sessionId: currentSessionId,
                });
            }
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            successClip.current
            && swooshInClip.current
            && swooshOutClip.current
        ) {
            // Success
            successClip.current.volume = DEFAULT_AUDIO_VOLUME;
            successClip.current.src = Success;

            // Swoosh In
            swooshInClip.current.volume = DEFAULT_AUDIO_VOLUME;
            swooshInClip.current.src = SwooshIn;

            // Swoosh Out
            swooshOutClip.current.volume = DEFAULT_AUDIO_VOLUME;
            swooshOutClip.current.src = SwooshOut;
        }

        return function cleanup() {
            if (successClip.current) successClip.current.remove();
            if (swooshInClip.current) swooshInClip.current.remove();
            if (swooshOutClip.current) swooshOutClip.current.remove();
        };
    }, []);

    /**
     * Fetches and saves list of countries
     */
    useEffect(() => {
        const countryArr: IDropdownItem[] = (countriesJSON as ICountry[]).map((country: ICountry) => ({
            value: country.code2,
            name: country.name,
        }));
        setCountries(countryArr);
    }, []);

    /**
     * Fetches and saves list of a user's saved addresses
     */
    useEffect(() => {
        if (
            user
            && user.addresses
            && user.addresses.length > 0
            && savedAddresses.length !== user.addresses.length
        ) {
            setSavedAddresses(user.addresses);
        }
    }, [user]);

    /**
     * Manages Action Button Loading Message Ellipsis Changes
     */
    useInterval(() => {
        handleActionButtonMessageEllipsisText();
    }, actionButtonIsLoading || stripePaymentIsLoading ? ACTION_BUTTON_MESSAGE_ELLIPSIS_INTERVAL_DURATION : null);

    /**
     * Redirects to different checkpoint based on value of payment success
     */
    useEffect(() => {
        if (
            checkpoint === CHECKOUT_CHECKPOINT.review
            && selectedBillingAddressType
            && billingAddressIsValid
            && (
                physicalItemsInCart.length === 0
                || (
                    addressIsValid
                    && selectedShippingRateID
                )
            ) && stripePaymentSucceeded
        ) {
            navigate(
                `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.confirmation}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        } else if (
            checkpoint === CHECKOUT_CHECKPOINT.review
            && selectedBillingAddressType
            && billingAddressIsValid
            && (
                physicalItemsInCart.length === 0
                || (
                    addressIsValid
                    && selectedShippingRateID
                )
            ) && !stripePaymentDetailsCompleted
            && !stripePaymentSucceeded
        ) {
            navigate(
                `/${PAGE_ROUTE.checkout}/${CHECKOUT_CHECKPOINT.billing}`,
                {
                    state: {
                        prevPath: location.pathname,
                    },
                },
            );
        }
    }, [
        checkpoint,
        stripePaymentSucceeded,
    ]);

    // ===== Memoization =====

    const checkpointTitle = useMemo(() => {
        switch (checkpoint) {
        case CHECKOUT_CHECKPOINT.info:
            return 'Shipping Address';
        case CHECKOUT_CHECKPOINT.shipping:
            return 'Shipping Method';
        case CHECKOUT_CHECKPOINT.billing:
            return 'Billing';
        case CHECKOUT_CHECKPOINT.review:
            return 'Confirm Shipping and Billing Details';
        default:
            // CHECKOUT_CHECKPOINT.confirmation
            return 'Order complete! Check your email for further details.';
        }
    }, [checkpoint]);

    const actionButtonInteractionProperties = useMemo(() => {
        const properties: Record<string, string | (
            (e: React.TouchEvent) => void) | ((e: React.MouseEvent) => void
        )> = !(actionButtonIsLoading || stripePaymentIsLoading)
            ? {
                className: HOVER_TARGET_CLASSNAME,
            } : {};

        if (
            !(
                actionButtonIsLoading
                || stripePaymentIsLoading
            ) && detectTouchDevice(document)
        ) {
            properties.onTouchStart = (e: React.TouchEvent) => onActionButtonEnter(e);
            properties.onTouchEnd = (e: React.TouchEvent) => onActionButtonLeave(e);
        } else if (
            !(
                actionButtonIsLoading
                || stripePaymentIsLoading
            ) && !detectTouchDevice(document)
        ) {
            properties.onMouseEnter = (e: React.TouchEvent) => onActionButtonEnter(e);
            properties.onMouseLeave = (e: React.TouchEvent) => onActionButtonLeave(e);
        }

        return properties;
    }, [
        actionButtonIsLoading,
        stripePaymentIsLoading,
    ]);

    const dropdownSavedAddresses = useMemo(() => (
        savedAddresses.map((address: IAddress, index: number) => ({
            value: `${index}`,
            name: `${
                `${address.firstName} ${address.lastName}`
            }${address.street_no
                ? `, ${address.street_no}`
                : ''
            }, ${
                address.street1
            }${address.street2
                ? `, ${address.street2}`
                : ''
            }${address.street3
                ? `, ${address.street3}`
                : ''
            }, ${
                address.city
            }${address.state
                ? ` ${address.state}`
                : ''
            } ${
                address.zip
            }, ${
                address.country
            }`,
        }))
    ), [savedAddresses]);

    const dropdownSavedStripePaymentMethods = useMemo(() => (
        savedStripePaymentMethods.map((paymentMethod: Stripe.PaymentMethod) => {
            let name: string | undefined;
            if (
                paymentMethod.type === 'card'
                && paymentMethod.card
            ) {
                name = `${
                    capitalizeFirstLetter(paymentMethod.card.brand.replace('_', ' ').toLowerCase())
                } ending with ${
                    paymentMethod.card.last4
                }`;
            } else {
                name = capitalizeFirstLetter(paymentMethod.type.replace('_', ' ').toLowerCase());
            }

            return {
                value: paymentMethod.id,
                name,
            };
        })
    ), [savedStripePaymentMethods]);

    const stripePaymentElementOptions: StripePaymentElementOptions = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false,
        },
    };

    // ===== Renderer =====

    const InfoCheckpoint = useMemo(() => (
        <CheckpointFormContainer
            extraPadding
            ref={checkpointFormContainerRef}
        >
            <CheckpointTitle
                noMarginTop={stripePaymentSucceeded && !!stripeSucceededPaymentIntent}
            >
                {checkpointTitle}
            </CheckpointTitle>
            {savedAddresses.length > 0 && (
                <InputContainer>
                    <PixelDropdown
                        hasSound={hasSound}
                        currentValue={selectedSavedAddress}
                        values={dropdownSavedAddresses}
                        placeholderText="Saved Address"
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        onChange={onSelectSavedAddress}
                    />
                </InputContainer>
            )}
            <InputContainer>
                <PixelDropdown
                    isRequired
                    hasSound={hasSound}
                    isValid={!countryNotSelected && addressIsValid}
                    currentValue={customerCountry}
                    values={countries}
                    placeholderText="Country"
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    onChange={onSelectCountry}
                />
            </InputContainer>
            <InputGroupContainer>
                <InputContainer
                    paddingRight
                >
                    <PixelInput
                        isRequired
                        isValid={!firstNameNotProvided && (
                            customerFirstName.length === 0
                            || (
                                validateName(customerFirstName)
                                && addressIsValid
                            )
                        )}
                        placeholderText="First Name"
                        value={customerFirstName}
                        hasSound={hasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        onChange={(value: string) => setCustomerFirstName(capitalizeFirstLetter(value))}
                    />
                </InputContainer>
                <InputContainer
                    paddingLeft
                >
                    <PixelInput
                        isRequired
                        isValid={!lastNameNotProvided && (
                            customerLastName.length === 0
                            || (
                                validateName(customerLastName)
                                && addressIsValid
                            )
                        )}
                        placeholderText="Last Name"
                        value={customerLastName}
                        hasSound={hasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        onChange={(value: string) => setCustomerLastName(capitalizeFirstLetter(value))}
                    />
                </InputContainer>
            </InputGroupContainer>
            <InputContainer>
                <PixelInput
                    isValid={customerCompany.length === 0 || (
                        validateName(customerCompany)
                        && addressIsValid
                    )}
                    placeholderText="Company"
                    value={customerCompany}
                    hasSound={hasSound}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    onChange={(value: string) => setCustomerCompany(capitalizeFirstLetter(value))}
                />
            </InputContainer>
            <InputContainer>
                <PixelInput
                    isRequired
                    isValid={!streetOneNotProvided && (
                        customerStreetOne.length === 0
                        || addressIsValid
                    )}
                    placeholderText="Address"
                    value={customerStreetOne}
                    hasSound={hasSound}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    onChange={(value: string) => setCustomerStreetOne(value)}
                />
            </InputContainer>
            <InputContainer>
                <PixelInput
                    isValid={customerStreetTwo.length === 0 || addressIsValid}
                    placeholderText="Apartment, Suite, Street Line 2, Etc"
                    value={customerStreetTwo}
                    hasSound={hasSound}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    onChange={(value: string) => setCustomerStreetTwo(value)}
                />
            </InputContainer>
            <InputGroupContainer>
                <InputContainer>
                    <PixelInput
                        isRequired
                        isValid={!cityNotProvided && (
                            customerCity.length === 0
                            || addressIsValid
                        )}
                        placeholderText="City"
                        value={customerCity}
                        hasSound={hasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        onChange={(value: string) => setCustomerCity(value)}
                    />
                </InputContainer>
                {states.length > 0 && (
                    <InputContainer
                        paddingLeft
                    >
                        <PixelDropdown
                            isRequired
                            hasSound={hasSound}
                            isValid={!stateNotSelected && addressIsValid}
                            currentValue={customerState}
                            values={states}
                            placeholderText="State"
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            onChange={onSelectState}
                        />
                    </InputContainer>
                )}
                <InputContainer
                    paddingLeft
                >
                    <PixelInput
                        isRequired
                        isValid={!zipCodeNotProvided && (
                            customerZipCode.length === 0
                            || addressIsValid
                        )}
                        placeholderText="Zip Code"
                        value={customerZipCode}
                        hasSound={hasSound}
                        onCursorEnter={onCursorEnter}
                        onCursorLeave={onCursorLeave}
                        setInputFocused={setInputFocused}
                        onChange={(value: string) => setCustomerZipCode(value)}
                    />
                </InputContainer>
            </InputGroupContainer>
            <InputContainer>
                <PixelInput
                    isRequired
                    isValid={!phoneNumberNotProvided && (
                        customerPhone.length === 0
                        || phone(customerPhone).isValid
                        || (
                            customerPhone.length > 2
                            && customerPhone.substring(0, 2) === '00'
                            && phone(`+${customerPhone.substring(2)}`).isValid
                        )
                    )}
                    placeholderText="Phone"
                    value={customerPhone}
                    hasSound={hasSound}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    onChange={(value: string) => setCustomerPhone(value)}
                />
            </InputContainer>
            <ButtonsContainer>
                <BackButton
                    className={HOVER_TARGET_CLASSNAME}
                    detectTouchDevice={detectTouchDevice(document)}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: onBackButtonEnter,
                    } : {
                        onMouseEnter: onBackButtonEnter,
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchEnd: onBackButtonLeave,
                    } : {
                        onMouseLeave: onBackButtonLeave,
                    })}
                    onMouseDown={onBackButtonClick}
                >
                    <BackButtonIconContainer>
                        <ReactSVG
                            src={PixelChevronIcon}
                        />
                    </BackButtonIconContainer>
                    <BackButtonTextContainer>
                        Cart
                    </BackButtonTextContainer>
                </BackButton>
                <ActionButton
                    noHover={actionButtonIsLoading || stripePaymentIsLoading}
                    detectTouchDevice={detectTouchDevice(document)}
                    {...(actionButtonInteractionProperties)}
                    {...(!(actionButtonIsLoading || stripePaymentIsLoading || !stripe || !elements)
                        ? {
                            onMouseDown: onActionButtonClick,
                        } : {})}
                >
                    {actionButtonIsLoading || stripePaymentIsLoading || !stripe || !elements
                        ? (
                            <ActionButtonLoadingContainer>
                                <SpinnerContainer
                                    src={Spinner}
                                    alt="spinner"
                                />
                                {actionButtonLoadingMessage.length > 0 && (
                                    <ActionButtonLoadingMessageText>
                                        {`${actionButtonLoadingMessage}${actionButtonMessageEllipsis}`}
                                    </ActionButtonLoadingMessageText>
                                )}
                            </ActionButtonLoadingContainer>
                        )
                        : 'Shipping'}
                </ActionButton>
            </ButtonsContainer>
        </CheckpointFormContainer>
    ), [
        checkpointFormContainerRef.current,
        hasSound,
        checkpointTitle,
        savedAddresses,
        snackbarData,
        selectedSavedAddress,
        dropdownSavedAddresses,
        countryNotSelected,
        customerCountry,
        countries,
        addressIsValid,
        firstNameNotProvided,
        customerFirstName,
        lastNameNotProvided,
        customerLastName,
        customerCompany,
        streetOneNotProvided,
        customerStreetOne,
        customerStreetTwo,
        cityNotProvided,
        customerCity,
        stateNotSelected,
        customerState,
        states,
        zipCodeNotProvided,
        customerZipCode,
        phoneNumberNotProvided,
        customerPhone,
        actionButtonIsLoading,
        actionButtonLoadingMessage,
        actionButtonMessageEllipsis,
        stripePaymentIsLoading,
        actionButtonInteractionProperties,
        stripePaymentSucceeded,
        stripeSucceededPaymentIntent,
        stripe,
        elements,
    ]);

    const ShippingCheckpoint = useMemo(() => (
        <CheckpointFormContainer
            extraPadding
            ref={checkpointFormContainerRef}
        >
            <CheckpointTitle
                noMarginTop={stripePaymentSucceeded && !!stripeSucceededPaymentIntent}
            >
                {checkpointTitle}
            </CheckpointTitle>
            <InputContainer>
                <PixelRadioInput
                    items={Array.from(shippingMethods.values())}
                    selected={selectedShippingRateID
                        ? shippingMethods.get(selectedShippingRateID)
                        : undefined}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    onChange={(value: IRadioItem) => setSelectedShippingRateID(value.value)}
                />
            </InputContainer>
            <ButtonsContainer>
                <BackButton
                    className={HOVER_TARGET_CLASSNAME}
                    detectTouchDevice={detectTouchDevice(document)}
                    {...(detectTouchDevice(document) ? {
                        onTouchStart: onBackButtonEnter,
                    } : {
                        onMouseEnter: onBackButtonEnter,
                    })}
                    {...(detectTouchDevice(document) ? {
                        onTouchEnd: onBackButtonLeave,
                    } : {
                        onMouseLeave: onBackButtonLeave,
                    })}
                    onMouseDown={onBackButtonClick}
                >
                    <BackButtonIconContainer>
                        <ReactSVG
                            src={PixelChevronIcon}
                        />
                    </BackButtonIconContainer>
                    <BackButtonTextContainer>
                        Info
                    </BackButtonTextContainer>
                </BackButton>
                <ActionButton
                    noHover={actionButtonIsLoading || stripePaymentIsLoading}
                    detectTouchDevice={detectTouchDevice(document)}
                    {...(actionButtonInteractionProperties)}
                    {...(!(actionButtonIsLoading || stripePaymentIsLoading || !stripe || !elements)
                        ? {
                            onMouseDown: onActionButtonClick,
                        } : {})}
                >
                    {actionButtonIsLoading || stripePaymentIsLoading || !stripe || !elements
                        ? (
                            <ActionButtonLoadingContainer>
                                <SpinnerContainer
                                    src={Spinner}
                                    alt="spinner"
                                />
                                {actionButtonLoadingMessage.length > 0 && (
                                    <ActionButtonLoadingMessageText>
                                        {`${actionButtonLoadingMessage}${actionButtonMessageEllipsis}`}
                                    </ActionButtonLoadingMessageText>
                                )}
                            </ActionButtonLoadingContainer>
                        )
                        : 'Billing'}
                </ActionButton>
            </ButtonsContainer>
        </CheckpointFormContainer>
    ), [
        checkpointFormContainerRef.current,
        checkpointTitle,
        shippingMethods,
        selectedShippingRateID,
        actionButtonIsLoading,
        actionButtonLoadingMessage,
        actionButtonMessageEllipsis,
        stripePaymentIsLoading,
        actionButtonInteractionProperties,
        stripePaymentSucceeded,
        stripeSucceededPaymentIntent,
        stripe,
        elements,
    ]);

    const BillingAndReviewCheckpoint = useMemo(() => {
        const auth = getAuth();
        return (
            <CheckpointFormContainer
                extraPadding
                ref={checkpointFormContainerRef}
            >
                <CheckpointTitle
                    noMarginTop={stripePaymentSucceeded && !!stripeSucceededPaymentIntent}
                >
                    {checkpointTitle}
                </CheckpointTitle>
                <BillingContainer
                    visible={checkpoint === CHECKOUT_CHECKPOINT.billing}
                >
                    <CheckpointSubtitle>
                        All transactions are secure and encrypted
                    </CheckpointSubtitle>
                    {savedStripePaymentMethods.length > 0 && (
                        <InputContainer>
                            <PixelDropdown
                                hasSound={hasSound}
                                currentValue={selectedSavedStripePaymentMethod}
                                values={dropdownSavedStripePaymentMethods}
                                placeholderText="Saved Payment Methods"
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                onChange={onSelectSavedStripePaymentMethod}
                            />
                        </InputContainer>
                    )}
                    <PaymentElementContainer
                        visible={!selectedSavedStripePaymentMethod}
                    >
                        <PaymentWidgetContainer
                            className={HOVER_TARGET_CLASSNAME}
                            {...(detectTouchDevice(document) ? {
                                onTouchStart: onStripePaymentElementEnter,
                            } : {
                                onMouseEnter: onStripePaymentElementEnter,
                            })}
                            {...(detectTouchDevice(document) ? {
                                onTouchEnd: onStripePaymentElementLeave,
                            } : {
                                onMouseLeave: onStripePaymentElementLeave,
                            })}
                        >
                            <PaymentElement
                                options={stripePaymentElementOptions}
                                onChange={(e) => onStripePaymentElementChange(e)}
                            />
                        </PaymentWidgetContainer>
                        <SavePaymentMethodContainer>
                            <PixelCheckbox
                                checked={savePaymentMethod}
                                text="Save payment method for future purchases"
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                onChange={(checked) => setSavePaymentMethod(checked)}
                            />
                        </SavePaymentMethodContainer>
                    </PaymentElementContainer>
                    <CheckpointTitle>
                        Billing Address
                    </CheckpointTitle>
                    <CheckpointSubtitle>
                        {physicalItemsInCart.length === 0
                            ? 'Provide an address that matches your card or billing method'
                            : 'Select the address that matches your card or billing method'}
                    </CheckpointSubtitle>
                    {physicalItemsInCart.length > 0
                    && (
                        <InputContainer
                            noMargin
                        >
                            <PixelRadioInput
                                items={[
                                    {
                                        title: 'Same as billing address',
                                        value: BILLING_ADDRESS_TYPE.same,
                                    },
                                    {
                                        title: 'Use a different billing address',
                                        value: BILLING_ADDRESS_TYPE.different,
                                    },
                                ]}
                                selected={selectedBillingAddressType}
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                onChange={(value: IRadioItem) => {
                                    setSelectedBillingAddressType(value);
                                }}
                            />
                        </InputContainer>
                    )}
                    {(
                        physicalItemsInCart.length === 0
                        || (
                            selectedBillingAddressType
                            && selectedBillingAddressType.value === BILLING_ADDRESS_TYPE.different
                        )
                    ) && (
                        <BillingAddressContainer
                            physicalItemsInCart={physicalItemsInCart.length > 0}
                        >
                            {savedAddresses.length > 0 && (
                                <InputContainer>
                                    <PixelDropdown
                                        hasSound={hasSound}
                                        currentValue={selectedSavedBillingAddress}
                                        values={dropdownSavedAddresses}
                                        placeholderText="Saved Address"
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        onChange={onSelectSavedBillingAddress}
                                    />
                                </InputContainer>
                            )}
                            <InputContainer>
                                <PixelDropdown
                                    isRequired
                                    hasSound={hasSound}
                                    isValid={!billingCountryNotSelected && billingAddressIsValid}
                                    currentValue={customerBillingCountry}
                                    values={countries}
                                    placeholderText="Country"
                                    onCursorEnter={onCursorEnter}
                                    onCursorLeave={onCursorLeave}
                                    onChange={onSelectBillingCountry}
                                />
                            </InputContainer>
                            <InputGroupContainer>
                                <InputContainer
                                    paddingRight
                                >
                                    <PixelInput
                                        isRequired
                                        isValid={!billingFirstNameNotProvided && (
                                            customerBillingFirstName.length === 0
                                            || (
                                                validateName(customerBillingFirstName)
                                                && billingAddressIsValid
                                            )
                                        )}
                                        placeholderText="First Name"
                                        value={customerBillingFirstName}
                                        hasSound={hasSound}
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        setInputFocused={setInputFocused}
                                        onChange={(value: string) => setCustomerBillingFirstName(capitalizeFirstLetter(value))}
                                    />
                                </InputContainer>
                                <InputContainer
                                    paddingLeft
                                >
                                    <PixelInput
                                        isRequired
                                        isValid={!billingLastNameNotProvided && (
                                            customerBillingLastName.length === 0
                                            || (
                                                validateName(customerBillingLastName)
                                                && billingAddressIsValid
                                            )
                                        )}
                                        placeholderText="Last Name"
                                        value={customerBillingLastName}
                                        hasSound={hasSound}
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        setInputFocused={setInputFocused}
                                        onChange={(value: string) => setCustomerBillingLastName(capitalizeFirstLetter(value))}
                                    />
                                </InputContainer>
                            </InputGroupContainer>
                            <InputContainer>
                                <PixelInput
                                    isValid={customerBillingCompany.length === 0 || (
                                        validateName(customerBillingCompany)
                                        && billingAddressIsValid
                                    )}
                                    placeholderText="Company"
                                    value={customerBillingCompany}
                                    hasSound={hasSound}
                                    onCursorEnter={onCursorEnter}
                                    onCursorLeave={onCursorLeave}
                                    setInputFocused={setInputFocused}
                                    onChange={(value: string) => setCustomerBillingCompany(capitalizeFirstLetter(value))}
                                />
                            </InputContainer>
                            <InputContainer>
                                <PixelInput
                                    isRequired
                                    isValid={!billingStreetOneNotProvided && (
                                        customerBillingStreetOne.length === 0
                                        || billingAddressIsValid
                                    )}
                                    placeholderText="Address"
                                    value={customerBillingStreetOne}
                                    hasSound={hasSound}
                                    onCursorEnter={onCursorEnter}
                                    onCursorLeave={onCursorLeave}
                                    setInputFocused={setInputFocused}
                                    onChange={(value: string) => setCustomerBillingStreetOne(value)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <PixelInput
                                    isValid={customerBillingStreetTwo.length === 0 || billingAddressIsValid}
                                    placeholderText="Apartment, Suite, Street Line 2, Etc"
                                    value={customerBillingStreetTwo}
                                    hasSound={hasSound}
                                    onCursorEnter={onCursorEnter}
                                    onCursorLeave={onCursorLeave}
                                    setInputFocused={setInputFocused}
                                    onChange={(value: string) => setCustomerBillingStreetTwo(value)}
                                />
                            </InputContainer>
                            <InputGroupContainer>
                                <InputContainer>
                                    <PixelInput
                                        isRequired
                                        isValid={!billingCityNotProvided && (
                                            customerBillingCity.length === 0
                                            || billingAddressIsValid
                                        )}
                                        placeholderText="City"
                                        value={customerBillingCity}
                                        hasSound={hasSound}
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        setInputFocused={setInputFocused}
                                        onChange={(value: string) => setCustomerBillingCity(value)}
                                    />
                                </InputContainer>
                                {billingStates.length > 0 && (
                                    <InputContainer
                                        paddingLeft
                                    >
                                        <PixelDropdown
                                            isRequired
                                            hasSound={hasSound}
                                            isValid={!billingStateNotSelected && billingAddressIsValid}
                                            currentValue={customerBillingState}
                                            values={billingStates}
                                            placeholderText="State"
                                            onCursorEnter={onCursorEnter}
                                            onCursorLeave={onCursorLeave}
                                            onChange={onSelectBillingState}
                                        />
                                    </InputContainer>
                                )}
                                <InputContainer
                                    paddingLeft
                                >
                                    <PixelInput
                                        isRequired
                                        isValid={!billingZipCodeNotProvided && (
                                            customerBillingZipCode.length === 0
                                            || billingAddressIsValid
                                        )}
                                        placeholderText="Zip Code"
                                        value={customerBillingZipCode}
                                        hasSound={hasSound}
                                        onCursorEnter={onCursorEnter}
                                        onCursorLeave={onCursorLeave}
                                        setInputFocused={setInputFocused}
                                        onChange={(value: string) => setCustomerBillingZipCode(value)}
                                    />
                                </InputContainer>
                            </InputGroupContainer>
                            <InputContainer>
                                <PixelInput
                                    isRequired
                                    isValid={!billingPhoneNumberNotProvided && (
                                        customerBillingPhone.length === 0
                                        || phone(customerBillingPhone).isValid
                                        || (
                                            customerBillingPhone.length > 2
                                            && customerBillingPhone.substring(0, 2) === '00'
                                            && phone(`+${customerBillingPhone.substring(2)}`).isValid
                                        )
                                    )}
                                    placeholderText="Phone"
                                    value={customerBillingPhone}
                                    hasSound={hasSound}
                                    onCursorEnter={onCursorEnter}
                                    onCursorLeave={onCursorLeave}
                                    setInputFocused={setInputFocused}
                                    onChange={(value: string) => setCustomerBillingPhone(value)}
                                />
                            </InputContainer>
                        </BillingAddressContainer>
                    )}
                    {user
                    && user.mailingListSubscription?.history
                    && !user.mailingListSubscription.history[user.mailingListSubscription.history.length - 1].subscribed
                    && (
                        <MailingListContainer
                            largeMarginTop={physicalItemsInCart.length > 0}
                        >
                            <PixelCheckbox
                                checked={subscribeToMailingList}
                                text="Subscribe to mailing list for news and offers"
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                onChange={(checked) => setSubscribeToMailingList(checked)}
                            />
                        </MailingListContainer>
                    )}
                </BillingContainer>
                <ReviewContainer
                    visible={checkpoint === CHECKOUT_CHECKPOINT.review}
                >
                    <ReviewShippingContainer>
                        <ReviewShippingDetailContainer>
                            <ReviewShippingDetail>
                                <ReviewShippingDetailTitle>
                                    Email
                                </ReviewShippingDetailTitle>
                                <ReviewShippingDetailBody>
                                    <ReviewShippingDetailBodyLine>
                                        {auth.currentUser?.email || 'No Email'}
                                    </ReviewShippingDetailBodyLine>
                                </ReviewShippingDetailBody>
                            </ReviewShippingDetail>
                            {addressIsValid
                            && physicalItemsInCart.length > 0 && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Ship To
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailEditButtonContainer>
                                            <ReviewShippingDetailEditButton
                                                className={HOVER_TARGET_CLASSNAME}
                                                detectTouchDevice={detectTouchDevice(document)}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchStart: onActionButtonEnter,
                                                } : {
                                                    onMouseEnter: onActionButtonEnter,
                                                })}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchEnd: onActionButtonLeave,
                                                } : {
                                                    onMouseLeave: onActionButtonLeave,
                                                })}
                                                onMouseDown={(e) => onReviewEditButtonClick(CHECKOUT_CHECKPOINT.info, e)}
                                            >
                                                Edit
                                            </ReviewShippingDetailEditButton>
                                        </ReviewShippingDetailEditButtonContainer>
                                        <ReviewShippingDetailBodyLine>
                                            {`${customerFirstName} ${customerLastName}`}
                                        </ReviewShippingDetailBodyLine>
                                        {customerCompany.length > 0 && (
                                            <ReviewShippingDetailBodyLine>
                                                <ReviewShippingDetailBodyLineHighlight>
                                                    {customerCompany}
                                                </ReviewShippingDetailBodyLineHighlight>
                                            </ReviewShippingDetailBodyLine>
                                        )}
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {customerStreetOne}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                        {customerStreetTwo.length > 0 && (
                                            <ReviewShippingDetailBodyLine>
                                                <ReviewShippingDetailBodyLineHighlight>
                                                    {customerStreetTwo}
                                                </ReviewShippingDetailBodyLineHighlight>
                                            </ReviewShippingDetailBodyLine>
                                        )}
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {`${
                                                    customerCity
                                                }${customerState
                                                    ? ` ${customerState.name}`
                                                    : ''
                                                } ${
                                                    customerZipCode
                                                }, ${
                                                    customerCountry?.name
                                                }`}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {customerPhone.substring(0, 2) === '00'
                                                    ? `+${customerPhone.substring(2)}`
                                                    : customerPhone}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {selectedShippingRateID && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Shipping Method
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailEditButtonContainer>
                                            <ReviewShippingDetailEditButton
                                                className={HOVER_TARGET_CLASSNAME}
                                                detectTouchDevice={detectTouchDevice(document)}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchStart: onActionButtonEnter,
                                                } : {
                                                    onMouseEnter: onActionButtonEnter,
                                                })}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchEnd: onActionButtonLeave,
                                                } : {
                                                    onMouseLeave: onActionButtonLeave,
                                                })}
                                                onMouseDown={(e) => onReviewEditButtonClick(CHECKOUT_CHECKPOINT.shipping, e)}
                                            >
                                                Edit
                                            </ReviewShippingDetailEditButton>
                                        </ReviewShippingDetailEditButtonContainer>
                                        <ReviewShippingDetailBodyLine>
                                            {selectedShippingRateID &&  shippingMethods.get(selectedShippingRateID)?.title}
                                        </ReviewShippingDetailBodyLine>
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {` ${selectedShippingRateID && shippingMethods.get(selectedShippingRateID)?.detail}`}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {selectedBillingAddressType
                            && (
                                <ReviewShippingDetail
                                    noSeparator
                                >
                                    <ReviewShippingDetailTitle>
                                        Billing Method
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailEditButtonContainer>
                                            <ReviewShippingDetailEditButton
                                                className={HOVER_TARGET_CLASSNAME}
                                                detectTouchDevice={detectTouchDevice(document)}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchStart: onActionButtonEnter,
                                                } : {
                                                    onMouseEnter: onActionButtonEnter,
                                                })}
                                                {...(detectTouchDevice(document) ? {
                                                    onTouchEnd: onActionButtonLeave,
                                                } : {
                                                    onMouseLeave: onActionButtonLeave,
                                                })}
                                                onMouseDown={(e) => onReviewEditButtonClick(CHECKOUT_CHECKPOINT.billing, e)}
                                            >
                                                Edit
                                            </ReviewShippingDetailEditButton>
                                        </ReviewShippingDetailEditButtonContainer>
                                        {stripePaymentMethodType && (
                                            <ReviewShippingDetailBodyLine>
                                                {capitalizeFirstLetter(stripePaymentMethodType.replace('_', ' ').toLowerCase())}
                                            </ReviewShippingDetailBodyLine>
                                        )}
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {selectedBillingAddressType.value === BILLING_ADDRESS_TYPE.same
                                                    // eslint-disable-next-line max-len
                                                    ? `${
                                                        customerStreetOne
                                                    }${
                                                        customerStreetTwo
                                                            ? `, ${customerStreetTwo}`
                                                            : ''
                                                    }, ${
                                                        customerCity
                                                    }${
                                                        customerState
                                                            ? ` ${customerState.name}`
                                                            : ''
                                                    } ${
                                                        customerZipCode
                                                    }, ${
                                                        customerCountry?.name
                                                    }, ${
                                                        customerPhone.substring(0, 2) === '00'
                                                            ? `+${customerPhone.substring(2)}`
                                                            : customerPhone}`
                                                    // eslint-disable-next-line max-len
                                                    : `${
                                                        customerBillingStreetOne
                                                    }${
                                                        customerBillingStreetTwo
                                                            ? `, ${customerBillingStreetTwo}`
                                                            : ''
                                                    }, ${
                                                        customerBillingCity
                                                    }${
                                                        customerBillingState
                                                            ? ` ${customerBillingState.name}`
                                                            : ''
                                                    } ${
                                                        customerBillingZipCode
                                                    }, ${
                                                        customerBillingCountry?.name
                                                    }, ${
                                                        customerBillingPhone.substring(0, 2) === '00'
                                                            ? `+${customerBillingPhone.substring(2)}`
                                                            : customerBillingPhone}`}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                        </ReviewShippingDetailContainer>
                        {orderCost && (
                            <ReviewTransactionDetailContainer>
                                <ReviewTransactionDetail>
                                    <ReviewTransactionDetailTitle>
                                        Subtotal
                                    </ReviewTransactionDetailTitle>
                                    <ReviewTransactionDetailBody>
                                        {`$${orderCost.subtotal / CENTS_IN_A_DOLLAR}`}
                                    </ReviewTransactionDetailBody>
                                </ReviewTransactionDetail>
                                {physicalItemsInCart.length > 0
                                && orderCost.shipping !== undefined && (
                                    <ReviewTransactionDetail>
                                        <ReviewTransactionDetailTitle>
                                            Shipping
                                        </ReviewTransactionDetailTitle>
                                        <ReviewTransactionDetailBody>
                                            {`$${orderCost.shipping / CENTS_IN_A_DOLLAR}`}
                                        </ReviewTransactionDetailBody>
                                    </ReviewTransactionDetail>
                                )}
                                {orderCost.tax !== undefined && (
                                    <ReviewTransactionDetail>
                                        <ReviewTransactionDetailTitle>
                                            Tax
                                        </ReviewTransactionDetailTitle>
                                        <ReviewTransactionDetailBody>
                                            {`$${orderCost.tax / CENTS_IN_A_DOLLAR}`}
                                        </ReviewTransactionDetailBody>
                                    </ReviewTransactionDetail>
                                )}
                                {orderCost.total !== undefined && (
                                    <ReviewTransactionTotal>
                                        <ReviewTransactionTotalTitle>
                                            Total
                                        </ReviewTransactionTotalTitle>
                                        <ReviewTransactionTotalBody>
                                            {`$${orderCost.total / CENTS_IN_A_DOLLAR}`}
                                        </ReviewTransactionTotalBody>
                                    </ReviewTransactionTotal>
                                )}
                            </ReviewTransactionDetailContainer>
                        )}
                    </ReviewShippingContainer>
                </ReviewContainer>
                <ButtonsContainer>
                    <BackButton
                        className={HOVER_TARGET_CLASSNAME}
                        detectTouchDevice={detectTouchDevice(document)}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: onBackButtonEnter,
                        } : {
                            onMouseEnter: onBackButtonEnter,
                        })}
                        {...(detectTouchDevice(document) ? {
                            onTouchEnd: onBackButtonLeave,
                        } : {
                            onMouseLeave: onBackButtonLeave,
                        })}
                        onMouseDown={onBackButtonClick}
                    >
                        <BackButtonIconContainer>
                            <ReactSVG
                                src={PixelChevronIcon}
                            />
                        </BackButtonIconContainer>
                        <BackButtonTextContainer>
                            {checkpoint === CHECKOUT_CHECKPOINT.billing
                            && physicalItemsInCart.length > 0 && (
                                'Shipping'
                            )}
                            {checkpoint === CHECKOUT_CHECKPOINT.billing
                            && physicalItemsInCart.length === 0 && (
                                'Cart'
                            )}
                            {checkpoint === CHECKOUT_CHECKPOINT.review
                            && (
                                'Billing'
                            )}
                        </BackButtonTextContainer>
                    </BackButton>
                    <ActionButton
                        noHover={actionButtonIsLoading || stripePaymentIsLoading}
                        detectTouchDevice={detectTouchDevice(document)}
                        {...(actionButtonInteractionProperties)}
                        {...(!(actionButtonIsLoading || stripePaymentIsLoading || !stripe || !elements)
                            ? {
                                onMouseDown: onActionButtonClick,
                            } : {})}
                    >
                        {actionButtonIsLoading || stripePaymentIsLoading || !stripe || !elements
                            ? (
                                <ActionButtonLoadingContainer>
                                    <SpinnerContainer
                                        src={Spinner}
                                        alt="spinner"
                                    />
                                    {actionButtonLoadingMessage.length > 0 && (
                                        <ActionButtonLoadingMessageText>
                                            {`${actionButtonLoadingMessage}${actionButtonMessageEllipsis}`}
                                        </ActionButtonLoadingMessageText>
                                    )}
                                </ActionButtonLoadingContainer>
                            )
                            : `${checkpoint === CHECKOUT_CHECKPOINT.billing ? 'Review' : 'Buy'}`}
                    </ActionButton>
                </ButtonsContainer>
            </CheckpointFormContainer>
        );
    }, [
        checkpointFormContainerRef.current,
        checkpointTitle,
        hasSound,
        snackbarData,
        stripePaymentElementOptions,
        physicalItemsInCart,
        selectedBillingAddressType,
        savedAddresses,
        selectedSavedBillingAddress,
        dropdownSavedAddresses,
        savedStripePaymentMethods,
        dropdownSavedStripePaymentMethods,
        selectedSavedStripePaymentMethod,
        billingCountryNotSelected,
        customerBillingCountry,
        countries,
        billingFirstNameNotProvided,
        customerBillingFirstName,
        billingLastNameNotProvided,
        customerBillingLastName,
        customerBillingCompany,
        billingStreetOneNotProvided,
        customerBillingStreetOne,
        billingAddressIsValid,
        customerBillingStreetTwo,
        billingCityNotProvided,
        customerBillingCity,
        billingStates,
        billingStateNotSelected,
        customerBillingState,
        billingZipCodeNotProvided,
        customerBillingZipCode,
        billingPhoneNumberNotProvided,
        customerBillingPhone,
        addressIsValid,
        customerFirstName,
        customerLastName,
        customerCompany,
        customerStreetOne,
        customerStreetTwo,
        customerCity,
        customerState,
        customerZipCode,
        customerCountry,
        customerPhone,
        selectedShippingRateID,
        shippingMethods,
        subscribeToMailingList,
        savePaymentMethod,
        stripePaymentSucceeded,
        orderCost,
        stripePaymentMethodType,
        actionButtonIsLoading,
        actionButtonLoadingMessage,
        actionButtonMessageEllipsis,
        stripePaymentIsLoading,
        actionButtonInteractionProperties,
        stripePaymentSucceeded,
        stripeSucceededPaymentIntent,
        stripe,
        elements,
    ]);

    const ConfirmCheckpoint = useMemo(() => {
        let stripePaymentIntentPhone: string | undefined;
        let billingAddress: IAddress | undefined;
        if (stripeSucceededPaymentIntent?.shipping?.phone) {
            stripePaymentIntentPhone = stripeSucceededPaymentIntent.shipping.phone.substring(0, 2) === '00'
                ? `+${stripeSucceededPaymentIntent.shipping.phone.substring(2)}`
                : stripeSucceededPaymentIntent.shipping.phone;
        }
        if (
            stripeSucceededPaymentIntent
            && stripeSucceededPaymentIntent.metadata.billingAddress
        ) {
            billingAddress = JSON.parse(stripeSucceededPaymentIntent.metadata.billingAddress) as IAddress;
            if (
                billingAddress.phone
                && !stripePaymentIntentPhone
            ) {
                stripePaymentIntentPhone = billingAddress.phone.substring(0, 2) === '00'
                    ? `+${billingAddress.phone.substring(2)}`
                    : billingAddress.phone;
            }
        }
        return (
            <CheckpointFormContainer
                extraPadding
                ref={checkpointFormContainerRef}
            >
                <CheckpointTitle
                    noMarginTop={stripePaymentSucceeded && !!stripeSucceededPaymentIntent}
                >
                    {checkpointTitle}
                </CheckpointTitle>
                <ReviewContainer
                    visible
                >
                    <ReviewShippingContainer>
                        <ReviewShippingDetailContainer>
                            {orderSaleItem
                            && orderSaleItem.receiptNumber
                            && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Order Number
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {`#${orderSaleItem.receiptNumber}`}
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {orderSaleItem
                            && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Date
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {moment.utc(orderSaleItem.timestamp)
                                                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                                .format('dddd D MMMM YYYY')}
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {stripeSucceededPaymentIntent
                            && stripeSucceededPaymentIntent.receipt_email
                            && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Email
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {stripeSucceededPaymentIntent.receipt_email}
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {stripeSucceededPaymentIntent
                            && stripePaymentIntentPhone
                            && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Phone
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {stripePaymentIntentPhone}
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {orderSaleItem
                            && orderSaleItem.receiptURL
                            && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Receipt Link
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLink
                                            className={HOVER_TARGET_CLASSNAME}
                                            href={orderSaleItem.receiptURL}
                                            target="_blank"
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: onLinkEnter,
                                            } : {
                                                onMouseEnter: onLinkEnter,
                                            })}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchEnd: onLinkLeave,
                                            } : {
                                                onMouseLeave: onLinkLeave,
                                            })}
                                            onMouseDown={() => {
                                                if (user && currentSessionId) {
                                                    // Record user action
                                                    recordUserAction({
                                                        type: USER_ACTION_TYPE.viewCheckoutReceipt,
                                                        userId: user.id,
                                                        sessionId: currentSessionId,
                                                    });
                                                }
                                            }}
                                        >
                                            View Receipt
                                        </ReviewShippingDetailBodyLink>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {stripeSucceededPaymentIntent?.shipping?.address && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Ship To
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {stripeSucceededPaymentIntent.shipping.name}
                                        </ReviewShippingDetailBodyLine>
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {stripeSucceededPaymentIntent.shipping.address.line1}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                        {stripeSucceededPaymentIntent.shipping.address.line2
                                        && stripeSucceededPaymentIntent.shipping.address.line2.length > 0 && (
                                            <ReviewShippingDetailBodyLine>
                                                <ReviewShippingDetailBodyLineHighlight>
                                                    {stripeSucceededPaymentIntent.shipping.address.line2}
                                                </ReviewShippingDetailBodyLineHighlight>
                                            </ReviewShippingDetailBodyLine>
                                        )}
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {`${
                                                    stripeSucceededPaymentIntent.shipping.address.city
                                                } ${
                                                    stripeSucceededPaymentIntent.shipping.address.state
                                                } ${
                                                    stripeSucceededPaymentIntent.shipping.address.postal_code
                                                }, ${
                                                    stripeSucceededPaymentIntent.shipping.address.country
                                                }`}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {stripeSucceededPaymentIntent
                            && stripeSucceededPaymentIntent.shipping && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Shipping Carrier
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {stripeSucceededPaymentIntent.shipping.carrier}
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {orderSaleItem?.shipping?.trackingNumber && (
                                <ReviewShippingDetail>
                                    <ReviewShippingDetailTitle>
                                        Tracking Number
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {orderSaleItem.shipping.trackingNumber}
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {orderSaleItem?.shipping?.trackingURLProvider && (
                                <ReviewShippingDetail
                                    noSeparator={!(billingAddress && stripePaymentMethod)}
                                >
                                    <ReviewShippingDetailTitle>
                                        Tracking Link
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLink
                                            className={HOVER_TARGET_CLASSNAME}
                                            href={orderSaleItem.shipping.trackingURLProvider}
                                            target="_blank"
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: onLinkEnter,
                                            } : {
                                                onMouseEnter: onLinkEnter,
                                            })}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchEnd: onLinkLeave,
                                            } : {
                                                onMouseLeave: onLinkLeave,
                                            })}
                                            onMouseDown={() => {
                                                if (user && currentSessionId) {
                                                    // Record user action
                                                    recordUserAction({
                                                        type: USER_ACTION_TYPE.viewTrackingWebsite,
                                                        userId: user.id,
                                                        sessionId: currentSessionId,
                                                    });
                                                }
                                            }}
                                        >
                                            Track your order
                                        </ReviewShippingDetailBodyLink>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                            {billingAddress
                            && stripePaymentMethod
                            && (
                                <ReviewShippingDetail
                                    noSeparator
                                >
                                    <ReviewShippingDetailTitle>
                                        Billing Method
                                    </ReviewShippingDetailTitle>
                                    <ReviewShippingDetailBody>
                                        <ReviewShippingDetailBodyLine>
                                            {stripePaymentMethod.type === 'card' && stripePaymentMethod.card
                                                ? `${
                                                    capitalizeFirstLetter(stripePaymentMethod.card.brand.replace('_', ' ').toLowerCase())
                                                } ending with ${
                                                    stripePaymentMethod.card.last4
                                                }`
                                                : capitalizeFirstLetter(stripePaymentMethod.type.replace('_', ' ').toLowerCase())}
                                        </ReviewShippingDetailBodyLine>
                                        <ReviewShippingDetailBodyLine>
                                            <ReviewShippingDetailBodyLineHighlight>
                                                {`${
                                                    billingAddress.street1
                                                }${
                                                    billingAddress.street2
                                                        ? `, ${billingAddress.street2}`
                                                        : ''
                                                }, ${
                                                    billingAddress.city
                                                }${
                                                    billingAddress.state
                                                        ? ` ${billingAddress.state}`
                                                        : ''
                                                } ${
                                                    billingAddress.zip
                                                }, ${
                                                    billingAddress.country
                                                }${billingAddress.phone
                                                    ? `, +${
                                                        billingAddress.phone.substring(0, 2) === '00'
                                                            ? billingAddress.phone.substring(2)
                                                            : billingAddress.phone
                                                    }`
                                                    : ''
                                                }`}
                                            </ReviewShippingDetailBodyLineHighlight>
                                        </ReviewShippingDetailBodyLine>
                                    </ReviewShippingDetailBody>
                                </ReviewShippingDetail>
                            )}
                        </ReviewShippingDetailContainer>
                        {orderCost && (
                            <ReviewTransactionDetailContainer>
                                <ReviewTransactionDetail>
                                    <ReviewTransactionDetailTitle>
                                        Subtotal
                                    </ReviewTransactionDetailTitle>
                                    <ReviewTransactionDetailBody>
                                        {`$${orderCost.subtotal / CENTS_IN_A_DOLLAR}`}
                                    </ReviewTransactionDetailBody>
                                </ReviewTransactionDetail>
                                {orderCost.shipping !== undefined && (
                                    <ReviewTransactionDetail>
                                        <ReviewTransactionDetailTitle>
                                            Shipping
                                        </ReviewTransactionDetailTitle>
                                        <ReviewTransactionDetailBody>
                                            {`$${orderCost.shipping / CENTS_IN_A_DOLLAR}`}
                                        </ReviewTransactionDetailBody>
                                    </ReviewTransactionDetail>
                                )}
                                {orderCost.tax !== undefined && (
                                    <ReviewTransactionDetail>
                                        <ReviewTransactionDetailTitle>
                                            Tax
                                        </ReviewTransactionDetailTitle>
                                        <ReviewTransactionDetailBody>
                                            {`$${orderCost.tax / CENTS_IN_A_DOLLAR}`}
                                        </ReviewTransactionDetailBody>
                                    </ReviewTransactionDetail>
                                )}
                                {orderCost.total !== undefined && (
                                    <ReviewTransactionTotal>
                                        <ReviewTransactionTotalTitle>
                                            Total
                                        </ReviewTransactionTotalTitle>
                                        <ReviewTransactionTotalBody>
                                            {`$${orderCost.total / CENTS_IN_A_DOLLAR}`}
                                        </ReviewTransactionTotalBody>
                                    </ReviewTransactionTotal>
                                )}
                            </ReviewTransactionDetailContainer>
                        )}
                    </ReviewShippingContainer>
                </ReviewContainer>
            </CheckpointFormContainer>
        );
    }, [
        checkpointFormContainerRef,
        checkpointTitle,
        stripeSucceededPaymentIntent,
        stripePaymentMethod,
        orderSaleItem,
        stripePaymentSucceeded,
        orderCost,
    ]);

    const ActiveCheckpoint = (): JSX.Element => {
        switch (checkpoint) {
        case CHECKOUT_CHECKPOINT.info:
            return InfoCheckpoint;
        case CHECKOUT_CHECKPOINT.shipping:
            return ShippingCheckpoint;
        case CHECKOUT_CHECKPOINT.billing:
        case CHECKOUT_CHECKPOINT.review:
            return BillingAndReviewCheckpoint;
        default:
            // CHECKOUT_CHECKPOINT.confirmation
            return ConfirmCheckpoint;
        }
    };

    return ActiveCheckpoint();
}

export default CheckoutCheckpoint;
