/**
 * Used by the App component as a list of permissible enum
 * values that represent the permissible audio note recording states
 */

export enum AUDIO_NOTE_RECORDING_STATE {
    idle = 'idle',
    recording = 'recording',
    pausedComplete = 'paused complete',
    pausedRecording = 'paused recording',
    playing = 'playing',
}
