// ===== Packages =====

import React, {
    useRef,
    useState,
    useEffect,
}                                       from 'react';
import styled                           from 'styled-components';
import { Transition }                   from 'react-transition-group';

// ===== Components =====

import {
    Button,
    OutsideClickHandler,
}                                       from '.';

// ===== Services =====

import {
    detectTouchDevice,
}                                       from '../../../services';

// ===== Images =====

import MoreIcon                         from '../../../images/editor/more.svg';

// ===== Enums =====

import {
    TOOLTIP_TYPE,
    BUTTON_TYPE,
}                                       from '../../../enums';

// ===== Constants =====

import {
    CURSOR_Z_INDEX,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
}                                       from '../../../constants/generalConstants';

// ===== Styles =====

import { theme as themeObj }            from '../../../themes/theme-context';

const CHILD_PADDING = 5;
const TRANSITION_DURATION = 150;

OptionsMenu.defaultProps = {
    className: undefined,
    buttonText: undefined,
    buttonIcon: MoreIcon,
    buttonType: BUTTON_TYPE.secret,
    buttonColor: undefined,
    buttonWidth: undefined,
    buttonStyle: undefined,
    buttonCenter: false,
    closeOnSelect: true,
    yOffset: 0,
    setRef: null,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    onToggle: undefined,
};
interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    className?: string,
    children: React.ReactElement | React.ReactElement[] | null,
    tooltipText: string,
    tooltipSideType: TOOLTIP_TYPE,
    parentRef: HTMLElement | null,
    buttonIcon?: string | null,
    buttonText?: string,
    buttonType?: BUTTON_TYPE,
    buttonColor?: string,
    buttonHeight: number,
    buttonWidth?: number,
    buttonStyle?: any,
    buttonCenter?: boolean,
    childItemWidth: number,
    childItemHeight: number,
    onToggle?: ((open: boolean) => void) | undefined,
    center: boolean,
    closeOnSelect?: boolean,
    yOffset?: number,
    revealAbove: boolean,
    setRef?: (ref: HTMLElement) => void,
    onMouseEnter?: (e: React.MouseEvent) => void,
    onMouseLeave?: (e: React.MouseEvent) => void,
}
function OptionsMenu({
    className,
    center = false,
    buttonIcon = MoreIcon,
    buttonText = undefined,
    buttonType = BUTTON_TYPE.secret,
    buttonColor = undefined,
    buttonHeight = 34,
    buttonWidth = undefined,
    buttonStyle,
    buttonCenter = false,
    children,
    parentRef = null,
    tooltipText = 'More',
    childItemWidth = 150,
    childItemHeight = 40,
    tooltipSideType = TOOLTIP_TYPE.top,
    onToggle = undefined,
    closeOnSelect = true,
    yOffset = 0,
    revealAbove = false,
    setRef,
    onMouseEnter,
    onMouseLeave,
}: Props): JSX.Element {
    // ===== State =====

    const [visible, setVisible] = useState(false);

    // ===== Refs =====

    const elementRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLElement | null>(null);

    // ===== Methods =====

    const toggleMenu = (e: React.MouseEvent | React.TouchEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        setVisible(!visible);
        if (onToggle) {
            onToggle(!visible);
        }
    };

    const findPosition = (): boolean => {
        if (elementRef.current) {
            const { left: optionsMenuLeftPos } = elementRef.current.getBoundingClientRect();
            const windowWidth = parentRef
                ? parentRef.getBoundingClientRect().right
                : window.innerWidth;
            return windowWidth - optionsMenuLeftPos > childItemWidth + (2 * CHILD_PADDING);
        }

        return true;
    };

    // ===== Side Effects =====

    useEffect(() => {
        if (buttonRef.current && setRef) {
            setRef(buttonRef.current);
        }
    }, [buttonRef.current]);

    const numChildren = React.Children.toArray(children).filter((o) => o).length;
    const menuHeight = numChildren * (childItemHeight + CHILD_PADDING) + CHILD_PADDING;
    const left = findPosition();

    return (
        <OutsideClickHandler
            onClick={() => {
                if (visible) {
                    setVisible(false);
                    if (onToggle) {
                        onToggle(false);
                    }
                }
            }}
        >
            <OptionsButton
                visible={visible}
                ref={elementRef}
            >
                <Button
                    setRef={(ref) => { buttonRef.current = ref; }}
                    type={buttonType}
                    height={buttonHeight}
                    {...({ className } || {})}
                    {...(buttonCenter
                        ? { center: true }
                        : {}
                    )}
                    {...(typeof buttonWidth === 'number'
                        ? { width: buttonWidth }
                        : {}
                    )}
                    icon={buttonIcon}
                    {...(buttonText
                        ? { text: buttonText }
                        : {}
                    )}
                    {...(buttonColor
                        ? { background: buttonColor }
                        : {}
                    )}
                    tooltip={{
                        active: !!tooltipText,
                        text: tooltipText,
                        side: tooltipSideType,
                    }}
                    style={buttonStyle}
                    {...(onMouseEnter
                        ? { onMouseEnter }
                        : {}
                    )}
                    {...(onMouseLeave
                        ? { onMouseLeave }
                        : {}
                    )}
                    onClick={toggleMenu}
                />
                <Transition
                    in={visible}
                    timeout={{
                        enter: TRANSITION_DURATION,
                        exit: TRANSITION_DURATION,
                    }}
                >
                    {
                        (state) => (
                            <Menu
                                center={center}
                                visible={visible}
                                revealAbove={revealAbove}
                                buttonHeight={buttonHeight}
                                height={menuHeight}
                                width={childItemWidth}
                                yOffset={yOffset}
                                {...(left ? {} : { right: true })}
                                {...(closeOnSelect && detectTouchDevice(document)
                                    ? {
                                        onTouchStart: toggleMenu,
                                    } : null)}
                                {...(closeOnSelect && !detectTouchDevice(document)
                                    ? {
                                        onMouseDown: toggleMenu,
                                    } : null)}
                                style={{
                                    ...FADE_IN_DEFAULT_STYLE({
                                        direction: 'up',
                                        offset: 10,
                                        duration: TRANSITION_DURATION,
                                        easing: themeObj.motion.eagerEasing,
                                        horizontalCenter: left && center,
                                    }),
                                    ...FADE_IN_TRANSITION_STYLES({
                                        direction: 'up',
                                        offset: 10,
                                        horizontalCenter: left && center,
                                    })[state],
                                }}
                            >
                                {/* Children should have onMouseDown or onTouchStart to beat the OutsideClickHandler */}
                                {children}
                            </Menu>
                        )
                    }
                </Transition>
            </OptionsButton>
        </OutsideClickHandler>
    );
}

// ===== Styled Components =====

interface OptionsButtonProps {
    visible: boolean,
}
const OptionsButton = styled.div<OptionsButtonProps>`
    position: relative;
    display: inline-block;
    z-index: ${({ visible }) => (visible
        ? CURSOR_Z_INDEX - 1
        : 0
    )};
    background: inherit;
    border-radius: 50%;
    transition: ${({ theme }) => `
        z-index ${TRANSITION_DURATION}ms ${theme.motion.eagerEasing}
    `};
`;

interface MenuProps {
    visible: boolean,
    buttonHeight: number,
    yOffset: number,
    width: number,
    height: number,
    center: boolean,
    revealAbove: boolean,
    right?: boolean,
}
const Menu = styled.div<MenuProps>`
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: ${({
        buttonHeight,
        yOffset,
        revealAbove,
        height,
    }) => {
        if (revealAbove) {
            return `-${height + 5 + yOffset}px`;
        }

        return `${buttonHeight + 5 + yOffset}px`;
    }};
    right: ${({ right }) => {
        if (right) {
            return '0px';
        }

        return 'auto';
    }};
    left: ${({ center, right }) => {
        if (center && !right) {
            return '50%';
        }

        if (right) {
            return 'auto';
        }

        return '0px';
    }};
    width: ${({ width }) => `${width + (2 * CHILD_PADDING)}px`};
    height: ${({ height }) => `${height}px`};
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    padding: ${`${CHILD_PADDING}px`};
    pointer-events: ${({ visible }) => (visible
        ? 'auto'
        : 'none'
    )};
`;

export default OptionsMenu;
