import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    box-shadow: ${({ theme }) => theme.color.boxShadow500};
`;

export const Frame = styled.iframe`
    border-radius: 5px;
`;
