export enum BUTTON_TYPE {
    // General
    line = 'line',
    solid = 'solid',

    // Client Only
    floating = 'floating',
    secret = 'secret',
    nested = 'nested',
}
