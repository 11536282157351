const ID_TO_ITEM = new Map<string, string>([
    ['0009c253-67ce-43c7-acdc-e1dcb9aaa788', 'power-of-simplicity'],
    ['019df6c8-de16-4ff8-a923-0d2fadbd8754', 'theories-of-everything-carlo-rovelli'],
    ['0224c662-58f8-421f-923f-8f163c0bc2c7', 'jonathan-blow-no-frauds-club-2'],
    ['02e06fab-41d1-4bd4-b8c2-393ffabfe6e0', 'communications-design-group-research-agenda'],
    ['0374ff56-2b83-4ff0-a5af-900960b7b071', 'designing-for-behavior-change'],
    ['0401b9d3-0eca-4749-a11b-5e98505c9cb3', 'john-von-neumann-doc'],
    ['0403a72a-999d-44f1-b0b8-90ae4b2a601c', 'how-the-periodic-table-went-from-a-sketch-to-an-enduring-masterpiece'],
    ['04d73199-c192-4a6b-bb71-98f9de8318d6', 'hyperbolica'],
    ['055600c6-970f-4869-8845-500380f1ba65', 'behavior-purpose-teleology'],
    ['05c431a8-516a-4662-9f59-ae9fb55244c9', 'make-cognitive-maps-not-levels'],
    ['06f20409-de3a-468b-b299-582ef446b569', 'what-happened-to-new-math'],
    ['07156bcc-1a4e-40b4-bdfd-6062657eeb06', 'conversation-with-james-blake'],
    ['071c2c76-a013-4e5d-85e5-0bb1027bde80', 'controls-as-language'],
    ['082d99f7-e4b0-4ac4-a39c-fda41b23acab', 'gris'],
    ['09d25014-276f-4dc6-9071-3076a4c356f1', 'daniels-on-everything-everywhere-all-at-once'],
    ['0d2ceb08-d1c9-4f79-93a0-c19601ad0a2d', 'the-creativity-process-behind-gris'],
    ['0de5f717-2fa7-4e96-a201-c136769e0249', 'minecraft-the-story-of-mojang'],
    ['0e60bbc7-fc0d-400d-8217-9c92442db7cb', 'owlboy'],
    ['0ec65957-c55d-4b20-b150-e21027865467', 'a-mathematicians-lament'],
    ['0f3fc4b1-554a-4a1e-936c-f1f0eb6ccd7f', 'on-teaching'],
    ['140381dd-3e57-42a0-8768-9cc200053939', 'karl-friston-mlst'],
    ['15b7a926-2e4d-4894-98cf-33af97d5aa31', 'her'],
    ['177222ea-3825-49a0-91d5-cef66ae51710', 'ori-and-the-blind-forest'],
    ['17f335d7-cee0-451d-9f5f-c0ed4b207ee4', 'a-pattern-language'],
    ['17f43eae-7609-4840-9141-1171040c1262', 'moss'],
    ['18c2ad79-4104-40f0-ac16-6ce789a3190b', 'edward-tufte-mindscape'],
    ['1a569ced-b79a-46a9-abde-c4af5eab564f', 'should-loved-biology'],
    ['1b365213-c147-41a2-9803-22debd18405d', 'ladder-of-abstraction'],
    ['1c0300d1-a3ce-4c59-bb1b-a619cc451311', 'insert-complicated-title-here'],
    ['1f5be80e-eba1-42fb-aa30-182f3991f57a', 'algebra'],
    ['1fcf77ef-89c0-4cc0-a7c1-9ff1e706d848', 'the-mother-of-all-demos'],
    ['202773d9-0c78-4ce7-a8a3-d27c60f83bb7', 'hades'],
    ['20cdc116-2478-4571-aae7-482a309b7b89', 'the-art-of-game-design'],
    ['20fd9552-b71d-407b-9621-8da75a3308c2', 'jeff-bhasker-vlad-tv'],
    ['2149f4dd-23bd-4a86-8c01-d2b9335c2f51', 'math-is-play'],
    ['22205244-c2c7-44a8-9515-eb1cef8a8d30', 'on-the-education-system'],
    ['2280ffd5-ac3a-46c3-ad04-d46abd5b410d', 'science-and-complexity'],
    ['22f6c75c-cd52-4132-990e-024710f25ca8', 'the-new-media-reader'],
    ['23446028-37a2-41b0-8089-948cd67f2a56', 'game-feel'],
    ['23a62b22-9a04-485e-9d34-edbfab6a6407', 'infinity-category-theory-offers-a-birds-eye-view-of-mathematics'],
    ['2919dcfa-7fd8-4035-b244-20c4bf901d2e', 'video-games-and-the-future-of-education'],
    ['2946fe21-e6af-455c-90da-d7c3d4b6fbdc', 'music-composition-fundamentals'],
    ['2a6c1134-5d05-446a-aaee-53c9de18d039', 'kanye-west-online-shopping'],
    ['2bb322d7-9187-4217-a176-96e9e65cadd8', 'powers-of-ten'],
    ['2fd215cb-e596-4e72-847d-0b866335e3b9', 'quantum-computation-and-quantum-information'],
    ['30b5d84a-4eba-446a-8e47-a58738d33597', 'ancestry-la-lucha-libra-ai'],
    ['3420f232-1f0f-4c95-bdb2-fa2bd562b869', 'inside-apple-park-first-look-at-the-design-team-shaping-the-future-of-tech'],
    ['35abaa2b-423b-4e9e-b9cd-eca1004ccec4', 'how-feynman-diagrams-almost-saved-space'],
    ['35c7d0cb-e3d3-4d92-a80f-874644f328ed', 'games-and-rules'],
    ['39d37924-1710-4dea-abe4-09b1942bf3e3', 'conversation-with-kendrick-lamar'],
    ['3b269036-a9cc-4f92-99b0-8d491f248541', 'ong-on-the-differences-between-orality-and-literacy'],
    ['3b74a2f9-89d8-4e62-aad7-8433a6f65c8d', 'karl-friston-toe'],
    ['3bfc1955-d60c-47fc-ab75-bb48f5ea92ac', 'conversation-with-tame-impala'],
    ['3ccb107d-80ea-4c36-8aa7-abd89023e361', 'the-everything-music-composition-book'],
    ['3d334d86-a7db-4ad6-9ed0-fee20d6fd945', 'tony-fadell-lex-fridman'],
    ['3dc516bd-e493-44dd-a513-49b7a6702832', 'universal-principles-of-design'],
    ['3f92dafd-f8b3-4e83-b868-5a52891d63c8', 'in-our-time-paul-dirac'],
    ['41712104-ce6f-4537-bec6-fa0bf84a61ec', 'tame'],
    ['427cec7b-93e2-4679-a0dc-5d78ce063510', 'windosill'],
    ['42c67813-9e5e-4a49-b875-2c366de35408', 'square-circle-triangle'],
    ['42e6fd68-2b90-4e04-b343-59495ee7a000', 'celeste'],
    ['43e77290-d6ef-41ef-bd7a-78d92ed2d06c', 'jacob-collier-broken-record-2'],
    ['456c4ef4-f9b1-4086-93ad-dc2dba879104', 'zelda-breath-of-the-wild'],
    ['45e83f59-81de-4c91-9808-4349bf1e7e04', 'the-philosophies-of-software-languages-from-smalltalk-to-perl-2'],
    ['46e6e8c7-e239-492a-a099-bd7e783d1711', 'on-top-of-a-mountain-with-tyler-the-creator'],
    ['49af3b09-5cd2-4107-a735-e7da3976f435', 'how-prince-of-persia-defeated-apple-iis-memory-limitations'],
    ['4a6e7d22-38a6-4982-ab85-f2275f92e454', 'coca-cola-masterpiece'],
    ['4ab275d8-989e-4a46-b64c-5db71c6f6003', 'why-does-celeste-feel-so-good-to-play'],
    ['4dc99119-c6de-40c0-8af8-ab1cf79c5515', 'abstract-the-art-of-design-christoph-niemann'],
    ['4e0c6536-1994-4f5e-bc4d-6cb9ef4f0eeb', 'stewart-brand-on-starting-things-and-staying-curious'],
    ['4f387066-5ec4-4f0f-9c41-ac6f6204fe7d', 'a-master-course-in-fashion-design-how-to-make-the-best-clothes'],
    ['50d7f280-3633-465c-b469-83ab77d6027c', 'understanding-media'],
    ['5126a188-d9a8-4064-93c2-e5ce0890e37d', 'envisioning-information'],
    ['51312e38-a409-4bfa-9aa0-e79a03a820f8', 'monument-valley'],
    ['51cf9879-4d82-4a97-8a30-77b5c2d4ed36', 'outer-wilds'],
    ['52a896e5-1b25-4774-a877-4ccc91308f95', 'the-stanley-parable'],
    ['52ddbb51-be51-4f1b-ac81-65166d61f97e', 'the-founders-dilemma'],
    ['5406943f-97ec-4d4c-9158-5767f4547233', 'tobi-lutke-kaz-nejatian-network-state'],
    ['54d151ab-9a30-46dd-8d40-4a08012d1c9c', 'todd-howard-lex-fridman'],
    ['551ee4d9-bad1-4e7b-af59-d3c3e596486d', 'limbo'],
    ['55379ff4-8d82-4013-8607-6e1f957bd957', 'the-art-of-monument-valley'],
    ['57631f5f-6c56-4790-a69f-3c6e4abe97b6', 'helvetica'],
    ['59105d16-9ef4-4899-9de1-33ba2292f111', 'visualizing-musical-structure'],
    ['592cdae5-84b3-4a49-84d0-a42c8ef919f6', 'the-making-of-prince-of-persia'],
    ['59436b5c-18fa-4cdc-ba89-1907ee2898ed', 'magic-ink'],
    ['5ab8a0aa-7781-4a59-8d5a-935508b4db77', 'steven-smith-the-guru-helping-kanye-west-design-his-yeezy-empire'],
    ['5b2a86aa-a8c1-4376-b051-7069ad335a50', 'seeing-with-fresh-eyes'],
    ['5b9b3bcd-df2a-4d7b-bb03-118569259296', 'narrative-mechanics'],
    ['5f42da93-9485-4ad5-a926-9cb98513b7f7', 'abstract-the-art-of-design-es-devlin'],
    ['5f7ff802-6915-4b58-9a4d-d1096723cac9', 'from-geometry-to-topology'],
    ['60480f3a-0db9-4b34-8297-14cf53bb0fa5', 'will-computers-redefine-the-roots-of-math'],
    ['6134d905-cf76-4630-9eb2-932545670b00', '3d-user-interfaces-theory-and-practice'],
    ['63dfe2e4-7119-4122-8fb1-b07548f0c8ac', 'visual-group-theory'],
    ['64486c5f-d68a-44c8-a478-45a93384b81b', 'thinking-in-systems'],
    ['6559a544-e235-400f-bc9d-f34222145a51', 'peter-dodds-on-quantifying-the-shape-of-stories'],
    ['6694337d-b912-4898-a547-6490d6759d88', 'thinking-and-creating-with-the-body'],
    ['6777974a-830a-4977-b9f1-0706eb90bef1', 'bit-player'],
    ['68028838-ec8c-463e-b557-697985834960', 'thomas-tull-from-batman-dark-knight-trilogy-to-ai-and-the-rolling-stones'],
    ['6804e4ec-9b6a-4557-8964-9f2b0e807956', 'new-textbooks-for-the-new-mathematics'],
    ['6a41517b-83cd-4e1b-ab0e-5c40f1eff6d2', 'bmws-new-electric-ix-car-will-feature-a-completely-new-symphony-composed-by-hans-zimmer'],
    ['6aee56a6-9c0b-483b-affb-b9d18b0825af', 'keyboards-codes-and-the-search-for-optimality'],
    ['6af18261-83a4-4acb-b0fd-a8ca72291bc2', 'karl-friston-wired'],
    ['6b3e8dd9-a686-487f-af3b-616262b83bdf', 'karl-friston-mindscape'],
    ['6b66e16b-b121-409a-9489-be9a2885fe9d', 'secrets-of-game-feel-and-juice'],
    ['6ce22e29-31a0-44e8-a3da-48b7d73c8150', 'an-introduction-to-systems-biology'],
    ['6f2813be-577d-4fcb-891f-6794b8cc29f8', 'sean-carrolls-mindscape-tai-danae-bradley-on-algebra-topology-language-and-entropy'],
    ['6fb18bdf-3ed3-494c-b131-39e6236d8732', 'interfaces-as-a-scarce-resource'],
    ['70d1ed6a-49d9-45b8-b0b9-a76bb33af0df', 'jacob-collier-masterclass'],
    ['720558cf-2d69-492c-baaf-987f609378fd', 'education-as-a-game'],
    ['725fdbaa-8e75-489a-b1ac-40cf3a209cbc', 'urbanized'],
    ['73049147-5164-45a9-b490-7ebc03aaf947', 'why-minecraft-technical-feat'],
    ['7394b0f2-0800-468a-851b-b639b91ba0cd', 'devonte-hynes-and-philip-glass-compare-notes'],
    ['73c4c579-69ce-444b-b8d1-9d81f8a7b121', 'the-visual-display-of-quantitative-information'],
    ['73edb1ae-4b53-436c-b4e0-f690511a059e', 'absent-minded-father-of-cybernetics'],
    ['741cbce4-c363-4db6-89a5-b10a3e224887', 'how-hans-zimmer-conjured-the-otherworldly-sounds-of-dune'],
    ['74536e8b-cba4-4d2e-910c-3f198af40cda', 'interview-with-alan-kay'],
    ['7460c5c0-8042-4c3b-b66b-439b97de8933', 'portal'],
    ['74fce640-78c7-4a07-bb9d-5a0836e4d0e3', 'sean-carrolls-mindscape-c-thi-nguyen-on-games-art-values-and-agency'],
    ['75af373f-9e0a-4186-b48c-ba1b26674a91', 'braid'],
    ['7620bdd8-c8a6-4d55-94e8-a9184bbc2c9c', 'univalent-foundations'],
    ['7671b315-9fbd-4152-be49-894d3ee86266', 'japanese-designer-oki-sato-on-his-playful-approach-to-design'],
    ['777b0fd3-d492-4ffc-9201-1701024e6257', 'objectified'],
    ['7937954e-71da-4d81-94fd-e693a2fa3a6c', 'maps-of-complex-systems-by-archi-archambault'],
    ['793f2bb6-4bfc-4716-871f-8a2562e90920', 'pace-layers-thinking'],
    ['79a17679-b102-4836-a9c2-a1e7aa63018f', 'what-makes-a-good-puzzle'],
    ['7ac176c0-e5ff-4856-8636-8dbd98431b2a', 'the-art-of-journey-talk'],
    ['7d26e737-08ad-4354-bbbb-d3fa15f7e4df', '9th-wonder-and-sampling-for-duckworth'],
    ['7ef486b6-085a-41ff-bf4b-474fc2cdc35f', 'sean-carolls-mindscape-emily-riehl-on-topology-categories-and-the-future-of-mathematics'],
    ['7f0cdaae-d1a8-41d5-b616-99ab8cb995fe', 'stewart-brand-floating-upstream'],
    ['84ea506e-0cab-4295-ac8d-d8980f7d4f1d', 'endless-forms-most-beautiful-2'],
    ['86bcd130-ed2f-4baf-a733-bfab1cfd4de5', 'ori-and-the-will-of-the-wisps'],
    ['89f29976-b95c-46fd-a44b-6c41622bf068', 'rick-beato-mindscape'],
    ['8a9107e1-1e19-4299-81ac-7dfaa45646dc', 'how-claude-shannon-invented-the-future'],
    ['8c11b0ce-0664-4d8a-92bc-f2cf3377d482', 'path-towards-autonomous-ai'],
    ['8c764938-163e-4554-8ca9-d43c12b80d91', 'visual-explanations'],
    ['8ccd1852-3a5f-496b-af0b-8af289f66eac', 'what-are-the-new-discoveries-in-human-biology'],
    ['8f27451c-dfb5-484e-bfc3-d8e1e79bd8ee', 'surfaces'],
    ['913c74ad-f53f-4ace-b1a9-3246f3b80784', 'a-man-named-scott'],
    ['91588add-813b-4026-9625-faefd893ec72', 'screen-graphics-of-her-interview-with-geoff-mcfetridge'],
    ['917656f5-77e8-4a4f-8bcc-e33d53719171', 'jacob-collier-broken-record-1'],
    ['924ddf04-77a0-4864-880c-4529f7e3ac76', 'the-philosophies-of-software-languages-from-java-to-javascript-3'],
    ['924fbc6b-ad3c-41b0-89ef-f8f2b3cfa64d', 'cassettes-with-william-july-michael-levin'],
    ['9287da2d-3d34-4f62-8983-47f271c041f3', 'tai-danae-bradley-a-new-perspective-of-entropy'],
    ['94004e48-d831-4479-8744-c6a2eb6d94ee', 'tai-danae-bradley-where-math-meets-language'],
    ['9649943d-a167-4e65-91b1-e5c111522327', 'the-new-math'],
    ['96bbe9ab-6fef-4be8-8fbb-9a7f391ad04f', 'computational-boundary-of-a-self'],
    ['985a93a0-88c3-4f11-8b90-0f0dd0cdc680', 'how-nintendo-made-marios-most-iconic-level'],
    ['9a495793-6ce9-49ff-a4f0-c1185b4c7419', 'primitive-futurism'],
    ['9be3affc-13ba-43d5-bfb4-be497460acb0', 'synesthesia-and-creating-your-own-score'],
    ['9c16fb4d-e483-465b-a9dc-3d4b0c010509', 'eric-smith-origins-of-life'],
    ['9c9a73f2-2ae1-474d-a161-09382800bbe1', '3d-game-engine-design'],
    ['9cafbdff-c946-4f0f-8ebf-c8369ea48151', 'paul-dirac-and-religion-of-mathematical-beauty'],
    ['9d66517b-9bc6-47e3-ad54-04646f7e27ee', 'conversation-with-andre-3000'],
    ['9e968ad9-e7d8-48ba-956b-05a130ad2555', 'linguistics-style-and-writing-in-the-21st-century'],
    ['9f1eb903-a6b5-445e-a37e-c6ec4c5c4842', 'why-childish-gambinos-because-the-internet-is-a-stroke-of-genius'],
    ['9fd43855-9634-4a76-8bae-b7ce04f2bd42', 'color-in-ui-design'],
    ['a024435f-b5c2-4961-a2be-d3a3eb3b37e3', 'the-making-of-outer-wilds'],
    ['a3d91085-1f04-4990-8cbf-443afcc09952', 'why-is-dunes-score-like-that'],
    ['a3e6d1bb-edaf-4c54-8cf7-84f3e83f91e8', 'relation-between-math-and-physics'],
    ['a3f02ea0-adc6-4d5b-9879-1ff49802c937', 'conversation-with-kevin-abstract'],
    ['a53f03ce-61c4-4501-92c5-bdd6aed77bdb', 'elon-musk-code-conference-2016'],
    ['a57f5154-c91d-4718-8d78-6cf439b2a6ee', 'cmu-jason-yuan'],
    ['a807cf49-adb6-4e49-b4f3-dccb8c261b1d', 'the-art-of-journey-book'],
    ['a915b5b7-e76d-4c62-b341-9a5d5795a3ff', 'the-world-from-another-point-of-view'],
    ['a963bde6-fb40-487b-bbf2-55486b49b5a0', 'jonathan-blow-no-frauds-club-1'],
    ['a96ecad5-15e1-48e6-8773-725b87a9ab37', 'yann-lecun-dark-matter-of-intelligence-and-self-supervised-learning'],
    ['aa9920ba-b665-43f4-b18d-f49a7e60fee1', 'visual-basic'],
    ['ad026088-4827-408f-aded-8f554841ab11', 'symbolic-analysis-of-relay-and-switching-circuits'],
    ['adf3a43f-c884-4178-91bc-214f6eb0562c', 'levin-bongard-polycomputing'],
    ['aeb88128-f7a7-4280-b0a2-2190b7361776', 'information-is-beautiful'],
    ['aee832f9-790e-4374-bfd8-7b9fb245ff3c', 'luckys-tale-the-unexpected-delight-of-third-person-virtual-reality'],
    ['aff7a0a4-6d18-44ce-9d3e-2b173b26a345', 'topology'],
    ['b1d0f018-41c6-467c-bf47-1544b7136d4a', 'are-optical-computers-the-future-of-computing'],
    ['b60fd99d-76cb-4340-a915-aba9a0251506', 'human-information-retrieval'],
    ['b6e300bf-35a8-4e72-94eb-c8da08d0559f', 'tyler-the-creator-opens-up-and-gets-raw-real-and-uncut'],
    ['b7357b97-9baa-4e30-a440-fe7701edbe79', 'mindstorms'],
    ['b7dcfdee-20e5-438f-be07-a05e0704e63f', 'understanding-context'],
    ['b864a18b-d4ff-44fc-82bc-7d7267720674', 'a-topological-picturebook'],
    ['b894a07c-d951-4e90-99e9-df00920f0070', 'arpa-xerox-parc-culture'],
    ['b8a34e33-7a56-410b-b282-5c3d7dd38f05', 'designing-virtual-worlds'],
    ['b9060ffd-8187-4049-8d31-abf99654366d', 'the-philosophy-of-computer-languages'],
    ['b9a92f7f-99c5-45e3-bbdc-ff7a93959c9a', 'exploring-spatial-software'],
    ['b9f9ee69-3fda-4d68-8ffd-2640823473a8', 'baba-is-you'],
    ['bc33daef-18cc-434a-ac4e-f99d0811bb32', 'the-witness'],
    ['bde9dcd3-3ed2-4ae7-829c-0a434ab2f26f', 'justin-roiland-breaks-down-his-most-iconic-characters'],
    ['be031177-a005-4f64-a49b-fe53a35ebfa4', 'creating-usability-with-motion'],
    ['bef8be67-5447-4c74-9794-17673c0c14d4', 'math-thought-ancient-to-modern'],
    ['bfa86192-7706-4365-9898-75d9b19da3af', 'walter-isaacson-albert-einstein'],
    ['bfce90c9-94bc-4bd7-a431-5d8e42c10ad5', 'christopher-alexander-patterns-in-architecture'],
    ['c2b7d418-c022-4dfa-ad4c-6604930f1dd2', 'the-philosophies-of-software-languages-from-plankalkul-to-c-1'],
    ['c464bb3c-084b-4090-8ce1-2e2b04d38c4a', 'seeing-spaces'],
    ['c511ce8c-0008-400c-bb44-d26123986e74', 'shigeru-miyamoto-on-the-origins-of-nintendos-famous-character'],
    ['c53479fd-3171-4c10-929c-3124620d13b9', 'rams'],
    ['c598734f-1988-47e4-af0f-9773ac48b53e', 'cell-biology-by-the-numbers'],
    ['c805a1a7-35b9-4cb2-9806-a38a20547a20', 'the-father-of-mobile-computing-is-not-impressed'],
    ['c85e7c32-69d8-4ca1-9d94-2ccc25b1b4ac', 'conversation-with-pharrell'],
    ['cc5e22f3-bf4f-4723-96bb-b323c1f2dcf1', 'david-wolpert-free-will-limits-of-science-no-free-lunch-mathematical-universe'],
    ['ccf8c62e-f8c7-4cd3-b68f-3381e9911926', 'the-evolution-of-nintendo'],
    ['cf98c299-c1d0-455a-86f3-09f6392affe2', 'the-reset'],
    ['d0fc9c89-0a57-4a17-99de-e0c623597d92', 'hollow-knight'],
    ['d115d8c0-3938-43b2-b713-7fb41e0f47ce', 'abstract-the-art-of-design-bjarke-ingels'],
    ['d12f0b61-5e7a-4097-a186-b3f786540799', 'mit-regressions'],
    ['d1538866-4d8e-43e2-88de-290ed9a70773', 'luckys-tale'],
    ['d389a950-0fbe-4ea8-9783-444779bc8348', 'designing-to-reveal-the-nature-of-the-universe'],
    ['d4259b4e-4d0c-4623-b931-375a388b9c82', 'competency-navigating-arbitrary-spaces'],
    ['d6850422-f297-446e-9e57-3689b7943476', 'archery-in-the-pathless-timing-not-aiming'],
    ['d8b36359-0b39-4460-a518-6f7c35463333', 'education-as-games'],
    ['db0c2655-f786-4df6-8d8b-1cdb830e79eb', 'producing-unique-pop-with-lido'],
    ['dc1b2419-b115-45af-8b48-824fd317fb18', 'demis-hassabis-lex-fridman'],
    ['dc23f1b4-213e-4eb7-8e0c-8240a3bb7341', 'scale-up-the-circular-economy-alan-kay'],
    ['dcbb48bc-0083-4085-a358-ab2dbbb1c1cf', 'you-and-your-research'],
    ['ddeb7b5c-0bab-4dcf-8227-f3c02eb025d6', 'beautiful-evidence'],
    ['df1dd2e0-2adb-4fef-b0c5-b74c406867b8', 'breaking-conventions-with-the-legend-of-zelda-breath-of-the-wild'],
    ['df273eef-0ffb-4f2f-9e82-a2865ff56683', 'oral-history-of-douglas-engelbart'],
    ['e13a6bfb-7c20-4f15-a70a-9829fede7567', 'kill-math'],
    ['e161112b-7dc4-4a87-bab8-6a6cace0be79', 'conversation-with-tyler-the-creator'],
    ['e16cf568-3f31-421d-a10f-3feec86a559f', 'understanding-virgil-ablohs-best-runway-collection'],
    ['e54b448d-4a7e-45d6-b82d-b33a99368a06', 'apple-reinvent-cursor'],
    ['e6479b06-44c4-47af-a5e2-c8ce4acfb673', 'seymour-papert-logo-teaching'],
    ['e6530820-cdb8-4463-bb64-0475162446f6', 'inside-kanye-wests-vision-for-the-future'],
    ['e7df24b2-9ef2-40d7-ae1a-ae5154aa54df', 'inside'],
    ['e9c21660-f292-4e1c-933d-c1d761a2c289', 'procedural-spatial-computing'],
    ['e9c4dd7d-84ea-4613-b307-c4171cfa2444', 'lupe-fiasco-mit'],
    ['ed11ad09-5144-403f-82c1-80776f4631e6', 'facade'],
    ['ed6849db-e3ef-4e48-bd93-c67cd5f55b87', 'designing-sound'],
    ['ee0820aa-e71e-4ebb-9fa1-9191e56a5337', 'the-mathematical-theory-of-communication'],
    ['ee2dcd2b-7dde-4fcd-85f2-069a3983f610', 'campbell-biology'],
    ['ee3c24f4-4fad-454f-840b-383e317fc1a9', 'accelerating-change-douglas-engelbart'],
    ['ef53335d-6d45-45b1-923a-d2d7bf2e94be', 'the-humane-representation-of-thought'],
    ['f070ed9b-a6e7-4a4d-8e93-c146926289a1', 'the-consequences-of-literacy-on-an-oral-culture'],
    ['f207d59e-fd2a-4efc-9563-00e591976975', 'balenciaga-demna-gvasalia-and-a-world-of-clones'],
    ['f296532d-a352-4b59-94c6-73068770280b', 'doing-with-images-makes-symbols'],
    ['f31535ba-38a7-4f1b-80c0-d8f24e75464d', 'speaking-with-cells-the-electrical-future-of-regenerative-medicine-with-dr-michael-levin'],
    ['f3589e1f-622b-43df-a73a-38eaed2df3c0', 'dune-director-denis-villeneuve-breaks-down-the-gom-jabbar-scene'],
    ['f3ed6b8d-1635-402a-b10b-bcb8d0c7989f', 'journey'],
    ['f444d7dd-646c-4bd7-9559-e45201219ecb', 'world-building-in-blender'],
    ['f4bf08b1-013a-444e-9a8d-60b63c18028a', 'pace-layering,-how-complex-systems-learn-and-keep-learning'],
    ['f5ebca04-7354-45c8-a2a7-6f265730f53e', 'harmonic-gestalt'],
    ['fbc0a1ef-a820-4713-b3f9-09fde8b49d32', 'portal-2'],
    ['fbcf50dc-89b6-40f0-98ea-df64e9dce867', 'the-philosophies-of-software-languages-from-go-to-elixir-4'],
    ['fc23d862-9ecf-44b8-9cf7-1e3774a705d6', 'react-doc'],
    ['fe101652-97a7-481c-9742-01b5b0978fbf', 'games-agency-as-art'],
    ['ff1a827a-9038-4cac-b387-eb5b759b2e83', 'm-c-escher-the-graphic-work'],
]);

export default ID_TO_ITEM;
