// ===== Interfaces =====

import { ISpotifyData } from '../interfaces';

// ===== Constants =====

const SPOTIFY_URL_REGEX = /https:\/\/open.spotify.com\/(embed\/)?(track|album|episode|show)\/([^#&?]*)/;

const getSpotifyId = (url = ''): ISpotifyData | boolean => {
    const match = url ? url.match(SPOTIFY_URL_REGEX) : null;

    let id: string | null = null;
    let type: string | null = null;
    if (match && match[3] && match[3].length === 22) [, , , id] = match;
    if (match && match[2]) [, , type] = match;

    if (id && type) {
        return {
            id,
            type,
        };
    }

    return false;
};

export default getSpotifyId;
