// ===== Packages =====

import styled                       from 'styled-components';

// ===== Constants =====

import FONT_TYPE                    from '../../constants/fontType';
import {
    CART_AND_CHECKOUT_FONT_SIZE_LARGE,
    CART_AND_CHECKOUT_FONT_SIZE_SMALL,
}                                   from '../../constants/generalConstants';

const DROPDOWN_HEIGHT = 36;
const UNSELECT_BUTTON_LENGTH = DROPDOWN_HEIGHT;
const UNSELECT_BUTTON_MARGIN_LEFT = 5;
const DROPDOWN_BORDER_THICKNESS = 3;

export const Container = styled.div`
    position: relative;
    width: 100%;
    background: ${({ theme }) => theme.color.white};
`;

interface SelectButtonProps {
    noValue: boolean,
    isInvalid: boolean,
    dropdownIsVisible: boolean,
    dropdownIsFocused: boolean,
}
export const SelectButton = styled.button<SelectButtonProps>`
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${({ noValue }) => (noValue ? `${DROPDOWN_HEIGHT}px` : 'auto')};
    min-height: 20px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE + 0.1}em`};
    font-weight: 500;
    color: ${({
        isInvalid,
        noValue,
        dropdownIsVisible,
        dropdownIsFocused,
        theme,
    }) => {
        if (isInvalid) {
            return theme.verascopeColor.red200;
        }

        if (noValue && !dropdownIsVisible) {
            return theme.color.neutral500;
        }

        if (noValue && dropdownIsFocused) {
            return theme.verascopeColor.orange100;
        }

        return theme.verascopeColor.purple100;
    }};
    text-transform: uppercase;
    background: ${({ theme }) => theme.color.white};
    padding: 0 12px;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL + 0.1}em`};
    }
`;

export const SelectedValue = styled.span`
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const SelectArrow = styled.span`
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
    transition: ${({ theme }) => `transform 250ms ${theme.motion.standardEasing}`};
`;

export const SelectDropdown = styled.ul`
    position: absolute;
    left: -3px;
    list-style: none;
    width: calc(100% + 6px);
    box-shadow: ${({ theme }) => theme.color.boxShadow400};
    background-color: #fff;
    border: ${({ theme }) => `${DROPDOWN_BORDER_THICKNESS}px solid ${theme.verascopeColor.purple100}`};
    padding: 5px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
    overscroll-behavior: contain;
    z-index: 1;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 25px;
    }
`;

interface DropdownContainerProps {
    isInvalid: boolean,
    valueSelected: boolean,
    dropdownIsVisible: boolean,
    dropdownIsFocused: boolean,
}
export const DropdownContainer = styled.div<DropdownContainerProps>`
    display: inline-block;
    position: relative;
    width: ${({ valueSelected }) => (valueSelected
        ?  `calc(100% - ${UNSELECT_BUTTON_LENGTH + UNSELECT_BUTTON_MARGIN_LEFT + 2 * DROPDOWN_BORDER_THICKNESS}px)`
        : '100%'
    )};
    min-height: 40px;
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE}em`};
    font-weight: 500;
    color: ${({ theme }) => theme.verascopeColor.purple100};
    border: ${({
        dropdownIsFocused,
        isInvalid,
        theme,
    }) => {
        if (isInvalid) {
            return `3px solid ${theme.verascopeColor.red200}`;
        }

        if (dropdownIsFocused) {
            return `3px solid ${theme.verascopeColor.orange200}`;
        }

        return `3px solid ${theme.verascopeColor.purple100}`;
    }};

    ${({ dropdownIsVisible }) => dropdownIsVisible && `
        & ${SelectArrow} {
            transform: rotate(180deg);
        }

        & ${SelectDropdown} {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1);
        }
    `}

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL}em`};
    }
`;

interface DropdownHeadingProps {
    isInvalid: boolean,
}
export const DropdownHeading = styled.h3<DropdownHeadingProps>`
    margin: 0;
    padding: 0.8em 12px;
    padding-bottom: 0em;
    font-family: ${FONT_TYPE.PRESS_START};
    font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_LARGE - 0.1}em`};
    font-weight: 500;
    color: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.purple300)};
    text-transform: uppercase;

    ${({ theme }) => theme.mediaQuery.small} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }

    ${({ theme }) => theme.mediaQuery.extraSmall} {
        font-size: ${`${CART_AND_CHECKOUT_FONT_SIZE_SMALL - 0.1}em`};
    }
`;

export const SelectOptionInput = styled.input`
    position: absolute;
    left: 0;
    opacity: 0;

    &:checked ~ label {
        background: ${({ theme }) => theme.verascopeColor.orange200};
        color: ${({ theme }) => theme.color.white};
    }
`;

export const SelectOptionLabel = styled.label`
    width: 100%;
    padding: 8px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: none;
`;

interface SelectOptionProps {
    selected: boolean,
}
export const SelectOption = styled.li<SelectOptionProps>`
    position: relative;
    cursor: none;
    display: flex;
    gap: 1rem;
    align-items: center;
    text-align: center;
    line-height: 1.8em;

    ${({ selected, theme }) => !selected && `
        &:hover {
            background: ${theme.verascopeColor.purple400};
            color: ${theme.color.white};
        }
    `}
`;

export const ArrowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface RequiredIndicatorContainerProps {
    isInvalid: boolean,
}
export const RequiredIndicatorContainer = styled.div<RequiredIndicatorContainerProps>`
    width: 20px;
    height: 20px;
    padding-right: 5px;

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 60%;
        height: 60%;
    }

    & path, & rect {
        fill: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.orange200
    )};
    }
`;

interface UnselectButtonProps {
    isInvalid: boolean,
    detectTouchDevice: boolean,
}
export const UnselectButton = styled.button<UnselectButtonProps>`
    display: inline-block;
    outline: none;
    border: none;
    background: none;
    cursor: none;
    padding: 0px;
    vertical-align: top;
    width: ${`${UNSELECT_BUTTON_LENGTH + 2 * DROPDOWN_BORDER_THICKNESS}px`};
    height: ${`${UNSELECT_BUTTON_LENGTH + 2 * DROPDOWN_BORDER_THICKNESS}px`};
    margin-left: ${`${UNSELECT_BUTTON_MARGIN_LEFT}px`};
    background: ${({ isInvalid, theme }) => (isInvalid
        ? theme.verascopeColor.red200
        : theme.verascopeColor.purple100)};

    &:hover {
        ${({ detectTouchDevice, theme }) => (!detectTouchDevice && `
            background: ${theme.verascopeColor.purple200};
        `)}
    }

    & div {
        position: relative;
        top: 0.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    & svg {
        width: 50%;
        height: 50%;
    }

    & path, & rect {
        fill: ${({ theme }) => theme.color.white};
    }
`;
