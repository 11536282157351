// ===== Packages =====

import styled                               from 'styled-components';

// ===== Services =====

import {
    setColorLightness,
}                                           from '../../services';

// ===== Constants =====

import {
    EDITOR_OUTLINE_SECTION_LIGHTNESS_VALUE,
}                                           from '../../constants/generalConstants';
import FONT_TYPE                            from '../../constants/fontType';

const DRAG_INDICATOR_LENGTH = 16;
const GENERAL_INDICATOR_LENGTH = 15;
const SECTION_NON_EMPTY_INDICATOR_RIGHT = 5;
const SECTION_ANNOTATIONS_INDICATOR_RIGHT = GENERAL_INDICATOR_LENGTH + SECTION_NON_EMPTY_INDICATOR_RIGHT + 5;

interface ContainerProps {
    selected?: boolean,
    isDragging: boolean,
    transitionDuration: number,
}
export const Container = styled.div<ContainerProps>`
    position: relative;
    padding: 5px 5px;
    padding-left: 40px;
    margin-top: 7.5px;
    border-radius: 4px;
    border: ${({ selected, theme }) => (selected
        ? `4px solid ${setColorLightness(
            theme.verascopeColor.purple200,
            EDITOR_OUTLINE_SECTION_LIGHTNESS_VALUE,
        )}`
        : 'none'
    )};
    background: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => theme.color.boxShadow100};
    opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
    transition: ${({ theme, transitionDuration }) => `
        box-shadow ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    &:hover {
        box-shadow: ${({ theme }) => theme.color.boxShadow300};
    }
`;

export const OutlineChapterSectionInput = styled.input`
    position: relative;
    width: 100%;
    background: transparent;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.5em;
    color: ${({ theme }) => theme.color.neutral1000};
    text-transform: none;
    border: none;
    text-decoration: none;
    cursor: none;

    &:focus{
        outline: none;
    }

    &::placeholder {
        color: ${({ theme }) => theme.color.neutral400};
    }
`;

interface SectionNonEmptyIndicatorProps {
    isVisible: boolean,
    transitionDuration: number,
}
export const SectionNonEmptyIndicator = styled.div<SectionNonEmptyIndicatorProps>`
    position: absolute;
    top: 50%;
    right: ${`${SECTION_NON_EMPTY_INDICATOR_RIGHT}px`};
    transform: translate(0px, -50%);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({ theme, transitionDuration }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div > svg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${`${GENERAL_INDICATOR_LENGTH}px`};
        width: ${`${GENERAL_INDICATOR_LENGTH}px`};
    }

    & svg {
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;

interface SectionAnnotationsIndicatorProps {
    isVisible: boolean,
    transitionDuration: number,
}
export const SectionAnnotationsIndicator = styled.div<SectionAnnotationsIndicatorProps>`
    position: absolute;
    top: 50%;
    right: ${`${SECTION_ANNOTATIONS_INDICATOR_RIGHT}px`};
    transform: translate(0px, -50%);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${({ theme, transitionDuration }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing},
        right ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div > svg {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${`${GENERAL_INDICATOR_LENGTH}px`};
        width: ${`${GENERAL_INDICATOR_LENGTH}px`};
    }

    & svg {
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral500};
    }
`;

interface OutlineChapterDragIndicatorProps {
    horizontalOffset: number,
    transitionDuration: number,
}
export const OutlineSectionDragIndicator = styled.div<OutlineChapterDragIndicatorProps>`
    position: absolute;
    top: 50%;
    right: 0;
    transform: ${({ horizontalOffset }) => `translate(-${horizontalOffset}px, -50%)`};
    background: ${({ theme }) => theme.color.white};
    height: ${`${DRAG_INDICATOR_LENGTH}px`};
    width: ${`${DRAG_INDICATOR_LENGTH}px`};
    padding-right: 2px;
    opacity: 0;
    z-index: 1;
    transition: ${({ theme, transitionDuration }) => `
        opacity ${transitionDuration}ms ${theme.motion.eagerEasing},
        transform ${transitionDuration}ms ${theme.motion.eagerEasing}
    `};

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    & svg {
        height: 100%;
        fill: ${({ theme }) => theme.color.neutral500};
    }

    ${Container}:hover & {
        opacity: 1;
        transform: translate(0px, -50%);
    };

    ${Container}:hover > & ~ ${SectionNonEmptyIndicator} {
        /* Moves SectionNonEmptyIndicator to right when visible */
        right: ${`${DRAG_INDICATOR_LENGTH + SECTION_NON_EMPTY_INDICATOR_RIGHT}px`};
    };

    ${Container}:hover > & ~ ${SectionAnnotationsIndicator} {
        /* Moves SectionAnnotationsIndicator to right when visible */
        right: ${`${DRAG_INDICATOR_LENGTH + SECTION_ANNOTATIONS_INDICATOR_RIGHT}px`};
    };
`;

export const OutlineNumberText = styled.span`
    position: absolute;
    top: 9px;
    left: 10px;
    font-family: ${FONT_TYPE.PLUS_JAKARTA_SANS};
    font-size: 0.8em;
    font-weight: 400;
    color: ${({ theme }) => theme.verascopeColor.gray300};
`;
