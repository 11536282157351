const ID_TO_THEME = new Map<string, string>([
    ['045f9841-8174-41d4-bae9-569a6e4df187', 'computer-science'],
    ['0ee268bd-a7fe-4957-b591-ec43e6264e80', 'intelligence'],
    ['123f2e54-fb1f-4c29-ba78-2eb9a675b826', 'trade'],
    ['278d9cfb-f202-45f2-aeab-855cc641d2a2', 'material-science'],
    ['2e268ded-cfbf-4268-a3e8-cfde0d17c77d', 'physics'],
    ['3bdcb340-1e74-4d5f-9b02-fed581914234', 'transportation'],
    ['3ce3a27b-084d-46b8-9ca0-1f16a1394dd2', 'economics'],
    ['46fc1469-4af5-4b92-b8fa-79eeb756b581', 'character-design'],
    ['47e42ea9-c1a1-49f5-8388-05db1bdf6dc0', 'systems'],
    ['4b5c0d4e-9754-432d-9666-fa077015b9d9', 'user-interface-design'],
    ['59f124cf-9052-48f4-8ab7-4512d7d73aac', 'typography'],
    ['5c3ce21e-f42d-449b-8f87-bbc426da3b73', 'chemistry'],
    ['5d38d151-8df9-4fdd-8aa9-ca8af790e24b', 'science'],
    ['60b233c8-4f73-454b-95d0-bd6e9ccb4de8', 'philosophy'],
    ['6adba072-9160-46f3-83c4-032a00ab5e98', 'simulation'],
    ['6c070665-a5ec-4cbc-870d-359a67ec6cba', 'music-theory'],
    ['6c9bea11-8072-437c-88fa-43ed65c4d9a1', 'world-building'],
    ['6ee80279-408e-417b-aeb2-1482450cda6b', 'modeling'],
    ['71da75cd-3bd8-40b9-aed4-90edff2b5213', 'design'],
    ['72fcde35-a8c8-454e-a053-0a6a604716b1', 'process'],
    ['82388366-1f95-4775-851c-0f4fb6113a4a', 'history'],
    ['857a7865-2a85-4a40-ab39-ebcdc377a2d8', 'perception'],
    ['85862192-8646-4cda-b2da-705c34e12990', 'biology'],
    ['868f3741-a83b-4253-974c-1575a9df7980', 'spatial-computing'],
    ['89c30262-2e09-464a-8b0f-4a3fda66e02d', 'cryptography'],
    ['91c31692-378a-4e1f-9ac9-eacf16e030fc', 'electrification'],
    ['926bc3d4-b6b7-4e6f-a2c4-81ba21565b03', 'electronics'],
    ['93d39090-36d2-4b42-9a41-4e88248c0cd0', 'communication'],
    ['9579143f-c633-4c08-b88d-8323615c3e8d', 'culture'],
    ['962b7735-7402-450b-85e1-d4883b7f4685', 'company-building'],
    ['97798a0c-11aa-44e3-a05a-2455224f4f97', 'measurement'],
    ['9780951d-ae06-44f6-84fe-e556b1724bdd', 'extended-cognition'],
    ['9fc85637-04ac-45c3-87f0-cfd9abc42029', 'game-development'],
    ['a28a5afe-beb1-4b32-ba9a-9d5393b8223f', 'game-design'],
    ['a89239b2-f5ee-4bc4-a931-c1ada032f0f8', 'space'],
    ['ae2d8b9f-13b4-400a-a553-65f9bc489628', 'politics'],
    ['b483e70f-c822-4b1e-967c-0c44ee2c53a5', 'sound-design'],
    ['b5fd4a88-8d33-453f-8315-da7e8819fb49', 'visual-arts'],
    ['b64c96ad-3a33-4fc5-91a8-1bc8f1687765', 'machine-learning'],
    ['bcdfee95-ecf0-468f-9f1e-9e657d19ee27', 'fashion-design'],
    ['c060e730-08fb-4d5e-8221-9006d3fb7977', 'music-composition'],
    ['c3774b1f-a301-43cd-b0d9-0b4c590f8d82', 'storytelling'],
    ['c4afc80d-03f5-44ba-a05b-2fb1fe86cc25', 'creativity'],
    ['d8eea7ef-29b2-4cf3-ac4e-6e162b02ae7e', 'architecture'],
    ['ddf39279-cf0e-46f6-8235-a591764c7129', 'learning'],
    ['e53abe3f-9ec8-44bc-b56b-5448134d2bc2', 'motion-design'],
    ['e7de99f4-26a5-494c-b1f9-a27d68ba0c88', 'film'],
    ['ee4f805e-6bcf-41b2-8a2a-ba0836900db4', 'web-development'],
    ['f35307ce-5a86-46a0-8716-f21630f19475', 'data-visualization'],
    ['fd6691c6-6afe-45e3-b98c-bfcc3f769d03', 'mathematics'],
    ['ff72b7df-7d33-4f9b-b20b-43d4408f9dd5', 'visual-design'],
]);

export default ID_TO_THEME;
