const THEME_TO_ID = new Map<string, string>([
    ['computer-science', '045f9841-8174-41d4-bae9-569a6e4df187'],
    ['intelligence', '0ee268bd-a7fe-4957-b591-ec43e6264e80'],
    ['trade', '123f2e54-fb1f-4c29-ba78-2eb9a675b826'],
    ['material-science', '278d9cfb-f202-45f2-aeab-855cc641d2a2'],
    ['physics', '2e268ded-cfbf-4268-a3e8-cfde0d17c77d'],
    ['transportation', '3bdcb340-1e74-4d5f-9b02-fed581914234'],
    ['economics', '3ce3a27b-084d-46b8-9ca0-1f16a1394dd2'],
    ['character-design', '46fc1469-4af5-4b92-b8fa-79eeb756b581'],
    ['systems', '47e42ea9-c1a1-49f5-8388-05db1bdf6dc0'],
    ['user-interface-design', '4b5c0d4e-9754-432d-9666-fa077015b9d9'],
    ['typography', '59f124cf-9052-48f4-8ab7-4512d7d73aac'],
    ['chemistry', '5c3ce21e-f42d-449b-8f87-bbc426da3b73'],
    ['science', '5d38d151-8df9-4fdd-8aa9-ca8af790e24b'],
    ['philosophy', '60b233c8-4f73-454b-95d0-bd6e9ccb4de8'],
    ['simulation', '6adba072-9160-46f3-83c4-032a00ab5e98'],
    ['music-theory', '6c070665-a5ec-4cbc-870d-359a67ec6cba'],
    ['world-building', '6c9bea11-8072-437c-88fa-43ed65c4d9a1'],
    ['modeling', '6ee80279-408e-417b-aeb2-1482450cda6b'],
    ['design', '71da75cd-3bd8-40b9-aed4-90edff2b5213'],
    ['process', '72fcde35-a8c8-454e-a053-0a6a604716b1'],
    ['history', '82388366-1f95-4775-851c-0f4fb6113a4a'],
    ['perception', '857a7865-2a85-4a40-ab39-ebcdc377a2d8'],
    ['biology', '85862192-8646-4cda-b2da-705c34e12990'],
    ['spatial-computing', '868f3741-a83b-4253-974c-1575a9df7980'],
    ['cryptography', '89c30262-2e09-464a-8b0f-4a3fda66e02d'],
    ['electrification', '91c31692-378a-4e1f-9ac9-eacf16e030fc'],
    ['electronics', '926bc3d4-b6b7-4e6f-a2c4-81ba21565b03'],
    ['communication', '93d39090-36d2-4b42-9a41-4e88248c0cd0'],
    ['culture', '9579143f-c633-4c08-b88d-8323615c3e8d'],
    ['company-building', '962b7735-7402-450b-85e1-d4883b7f4685'],
    ['measurement', '97798a0c-11aa-44e3-a05a-2455224f4f97'],
    ['extended-cognition', '9780951d-ae06-44f6-84fe-e556b1724bdd'],
    ['game-development', '9fc85637-04ac-45c3-87f0-cfd9abc42029'],
    ['game-design', 'a28a5afe-beb1-4b32-ba9a-9d5393b8223f'],
    ['space', 'a89239b2-f5ee-4bc4-a931-c1ada032f0f8'],
    ['politics', 'ae2d8b9f-13b4-400a-a553-65f9bc489628'],
    ['sound-design', 'b483e70f-c822-4b1e-967c-0c44ee2c53a5'],
    ['visual-arts', 'b5fd4a88-8d33-453f-8315-da7e8819fb49'],
    ['machine-learning', 'b64c96ad-3a33-4fc5-91a8-1bc8f1687765'],
    ['fashion-design', 'bcdfee95-ecf0-468f-9f1e-9e657d19ee27'],
    ['music-composition', 'c060e730-08fb-4d5e-8221-9006d3fb7977'],
    ['storytelling', 'c3774b1f-a301-43cd-b0d9-0b4c590f8d82'],
    ['creativity', 'c4afc80d-03f5-44ba-a05b-2fb1fe86cc25'],
    ['architecture', 'd8eea7ef-29b2-4cf3-ac4e-6e162b02ae7e'],
    ['learning', 'ddf39279-cf0e-46f6-8235-a591764c7129'],
    ['motion-design', 'e53abe3f-9ec8-44bc-b56b-5448134d2bc2'],
    ['film', 'e7de99f4-26a5-494c-b1f9-a27d68ba0c88'],
    ['web-development', 'ee4f805e-6bcf-41b2-8a2a-ba0836900db4'],
    ['data-visualization', 'f35307ce-5a86-46a0-8716-f21630f19475'],
    ['mathematics', 'fd6691c6-6afe-45e3-b98c-bfcc3f769d03'],
    ['visual-design', 'ff72b7df-7d33-4f9b-b20b-43d4408f9dd5'],
]);

export default THEME_TO_ID;
