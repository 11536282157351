// Reference: https://stackoverflow.com/questions/34201483/deep-clone-in-typescript-preserving-types
// ===== Packages =====

import { cloneDeep } from 'lodash';

function deepCopy<T>(obj: T): T {
    return cloneDeep(obj) as T;
}

export default deepCopy;
