// ===== Assets =====

import PostEditIcon         from '../images/editor/post-edit.svg';
import MicrophoneIcon       from '../images/editor/mic.svg';

// ======== RULES ========
//
// 1. Always begin message name with the object that has just had an action applied to it.
// e.g. TAG_HEADER, TAG, POST..
// - This might be a concatenation of two objects when specificity is required.
// e.g. USER_PASSWORD, GROUP_SETTINGS, USER_FILE,
//
// 2. Make sure to stick to CRUD conventions. Always include CRUD operation
// in your message name: CREATE, READ, UPDATE, DELETE
// - There are times where you can't stick to this convention. No problem. It's
// just a rule of thumb.
//
// 3. Make sure to always conclude message name with success status:
// SUCCESS or ERROR
//
// 4. For the actual message strings, follow the same convention as all the other
// strings. The current convention is so:
// - 4.1. Start with the object that has been acted on. e.g. Post, Password, Settings...
// - 4.2. If success, follow with 'successfully'
// - 4.3. Next, add the type of CRUD operation
// - 4.4. If success, conclude with an exclamation mark
// - 4.5. If error, merely just explain the error
//
// 5. Place your message under the correct Category. If it doesn't fit under any
// existing category, create new one.
// - Some messages and their actions aren't easily categorizable. Put them under
// the 'Other' category

// Post
export const POST_DRAFT_DELETE_SUCCESS = 'Draft successfully deleted!';
export const POST_DRAFT_POST_CREATE_SUCCESS = 'Post successfully published!';
export const PROMPT_RESPONSE_CREATE_SUCCESS = 'Response successfully published!';
export const POST_UPDATE_SUCCESS = 'Post successfully updated!';
export const POST_DELETE_SUCCESS = 'Post successfully deleted!';
export const POST_PIN_UPDATE_SUCCESS = (pinned: boolean): string => `Post successfully ${pinned ? 'pinned' : 'unpinned'}!`;
export const POST_BOOKMARK_UPDATE_SUCCESS = (alreadyBookmarked: boolean): string => `Post successfully ${alreadyBookmarked ? 'unbookmarked' : 'bookmarked'}!`;
export const POST_TITLE_ERROR_MESSAGE = 'Please include a post title.';
export const POST_BODY_ERROR_MESSAGE = 'Please include a post body.';
export const POST_TAGS_ERROR_MESSAGE = 'Please include at least one post tag.';
export const PROMPT_RESPONSE_TAGS_ERROR_MESSAGE = 'Please include at least one unique tag in your prompt response.';
export const POST_LEAVE_UNSAVED_MESSAGE = {
    icon: PostEditIcon,
    title: 'Discard Changes',
    message: 'Are you sure you want to leave this page? Any edits made to this post will be lost.',
};

// Editor
export const AUDIO_NOTE_PERMISSION_DENIED_ERROR = 'Microphone access was denied.';
export const AUDIO_NOTE_GENERAL_ERROR = 'Unknown error encountered.';
export const AUDIO_NOTE_BROWSER_NOT_SUPPORTED = 'Recording not supported in browser.';
export const AUDIO_NOTE_RECORDING_DEVICE_NOT_FOUND = 'Recording device not found.';
export const AUDIO_NOTE_RECORDING_DEVICE_BUSY = 'Recording device is busy.';
export const AUDIO_NOTE_UNSUPPORTED_FILE_TYPE = (type: string | boolean): string => `.${type} is not supported in browser.`;
export const AUDIO_NOTE_FETCH_FILE_ERROR = 'Unable to fetch audio note file.';
export const SAVE_POST_DURING_AUDIO_RECORDING_ERROR = {
    icon: MicrophoneIcon,
    title: 'Abandon Audio Note',
    message: 'Are you sure you want to leave page? Ongoing audio recording will be lost.',
};
