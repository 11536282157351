// ===== Packages =====

import React, {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
    useLayoutEffect,
}                                           from 'react';
import styled                               from 'styled-components';
import { useDropzone }                      from 'react-dropzone';
import { Transition }                       from 'react-transition-group';
import { ReactSVG }                         from 'react-svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment                               from 'moment-timezone';
import { v4 as uuidv4 }                     from 'uuid';
import {
    doc,
    where,
    query,
    getDoc,
    setDoc,
    addDoc,
    getDocs,
    onSnapshot,
    collection,
    getFirestore,
    DocumentData,
    DocumentSnapshot,
}                                           from 'firebase/firestore';
import {
    getDownloadURL,
    getStorage,
    ref,
    StorageError,
    StorageReference,
    UploadTaskSnapshot,
}                                           from 'firebase/storage';
import {
    getAuth,
    Unsubscribe,
}                                           from 'firebase/auth';
import {
    Editor,
}                                           from 'slate';
import {
    Resizable,
    ResizeCallbackData,
}                                           from 'react-resizable';
// We've imported Framer Motion v4.1.17 because anything higher has an unresolved error
// Reference: https://stackoverflow.com/questions/69769360/error-importing-framer-motion-v5-in-react-with-create-react-app
import Sheet                                from 'react-modal-sheet';
import {
    useMatch,
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
}                                           from 'react-router-dom';
import ShortUniqueId                        from 'short-unique-id';
import mime                                 from 'mime-types';

// ===== Components =====

import {
    PostEditor,
    AnnotationEditor,
}                                           from '../Editor/editors';
import {
    Annotation,
    AnnotationBucket,
    UploadingMediaItem,
    Button,
    OptionsMenu,
}                                           from '../Editor/helpers';
import ReaderContentsTableOutliner          from '../ReaderContentsTableOutliner';
import ReaderLocalizingNavigator            from '../ReaderLocalizingNavigator';
import Tooltip                              from '../Tooltip';
import Modal                                from '../Modal';

// ===== Hooks =====

import {
    useTimeout,
}                                           from '../../hooks';

// ===== Enums =====

import {
    PAGE_ROUTE,
    EDITOR_CONTEXT_TYPE,
    CURSOR_TARGET,
    INTERACTABLE_OBJECT,
    MEDIA_TYPE,
    FIRESTORE_COLLECTION,
    USER_ACTION_TYPE,
    BUTTON_TYPE,
    STORAGE_ERROR_CODE,
    TOOLTIP_TYPE,
    STORAGE_ENTITY,
    READER_PARAMS_TYPE,
    ADVENTURE_CHARACTER,
    READ_DURATION_TYPE,
    BOOK_TYPE,
    STAGED_CART_ITEM_CART_OPERATION,
    STORE_ITEM_TYPE,
}                                           from '../../enums';
import { RESOLUTION_LEVEL }                 from '../Editor/elements/enums';

// ===== Services =====

import {
    hexToRgb,
    playAudio,
    updatePageTitle,
    setAnnotationInDB,
    updateAnnotationInDB,
    generateTextSpeech,
    findParentNodeWithClass,
    updatePostInDB,
    getElementCoords,
    recordUserAction,
    updateUserInDB,
    formatNumber,
    setColorLightness,
    detectTouchDevice,
    getMediaStorageBucket,
    uploadToCloudStorage,
    getStorageErrorMessage,
    setMediaInDB,
    stripeGetStoreItems,
    checkBookPurchases,
}                                           from '../../services';

// ===== Interfaces =====

import {
    ICoord,
    IEmail,
    IPostItem,
    IDimension,
    IMediaItem,
    IAnnotationItem,
    IAnnotationQuote,
    IUserItem,
    ISnackbarItem,
    IAnnotationValue,
    IContentsChapterItem,
    IPostPathEvent,
    IPostPathExpandChapterItem,
    IResolutionLevelItem,
    ICartItem,
    IStagedCartItem,
    IStoreItem,
}                                           from '../../interfaces';

// ===== Constants =====

import {
    FADE_IN_TRANSITION_DURATION,
    FADE_IN_DEFAULT_STYLE,
    FADE_IN_TRANSITION_STYLES,
    MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_OFFSET_DURATION,
    FADE_IN_STAGGER_TRANSITION_DURATION,
    FADE_IN_STAGGER_DEFAULT_STYLE,
    FADE_IN_STAGGER_TRANSITION_STYLES,
    DEFAULT_AUDIO_VOLUME,
    DETAIL_VIEW_Z_INDEX,
    READER_VIEW_OVERLAY_Z_INDEX,
    HANDLEBAR_TRANSITION_DURATION,
    HOVER_TARGET_CLASSNAME,
    DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH,
    DETAIL_VIEW_HANDLEBAR_WIDTH,
    DETAIL_VIEW_HANDLEBAR_HEIGHT,
    DETAIL_VIEW_TRANSITION_DURATION,
    DETAIL_SHEET_ENTER_TRANSITION_DURATION,
    DETAIL_SHEET_EXIT_TRANSITION_DURATION,
    DETAIL_SHEET_CLASSNAME,
    DETAIL_SHEET_HEIGHT_REDUCTION,
    DEFAULT_ANNOTATION_EDITOR_HEIGHT,
    SLATE_PARAGRAPH_CLASSNAME,
    ANNOTATION_DECORATION_PREFIX,
    BOOK_POST_ID,
    BODY_FONT_SIZE,
    DEFAULT_SNACKBAR_VISIBLE_DURATION,
    EXTENDED_SNACKBAR_VISIBLE_DURATION,
    USER_PROFILE_AVATAR_LENGTH,
    DEFAULT_POST_TITLE,
    POST_TITLE_MARGIN_TOP,
    POST_TITLE_MARGIN_BOTTOM,
    POST_SUBTITLE_MARGIN,
    POST_AUTHOR_MARGIN_LEFT,
    POST_DETAIL_TEXT_MARGIN_LEFT,
    POST_THUMBNAIL_MAX_WIDTH_MEDIUM,
    POST_THUMBNAIL_MAX_WIDTH_SMALL,
    CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_Y_OFFSET_SMALL_VIEWPORT,
    BUTTON_CONTAINER_LIGHTNESS_VALUE,
    POST_BANNER_LEFT_CONTAINER_WIDTH,
    POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT,
    POST_TITLE_ENTER_DURATION,
    POST_SUBTITLE_ENTER_DURATION,
    POST_THUMBNAIL_ENTER_DURATION,
    POST_METADATA_ENTER_DURATION,
    CONTENTS_TABLE_MARGIN,
    POST_BANNER_HEIGHT,
    CONTENTS_TABLE_TOP_EXPANDED,
    WEB_BOOK_STRIPE_PRICE_API_ID,
    WEB_BOOK_STRIPE_PRICE_TEST_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_API_ID,
    DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_API_ID,
    PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID,
    EMAIL_SENDER_ADDRESS,
    TEAM_EMAIL_ADDRESS,
    EMAIL_REPLY_ADDRESS,
    EMAIL_TEMPLATE_ANNOTATION_NOTICE,
    MILLISECONDS_IN_A_MINUTE,
    MILLISECONDS_IN_AN_HOUR,
    MILLISECONDS_IN_A_SECOND,
    ANNOTATION_EDITOR_FONT_MULTIPLIER_LARGE,
    NAVIGATION_CART_BUTTON_ID,
}                                           from '../../constants/generalConstants';
import MEDIA_QUERY_SIZE                     from '../../constants/mediaQuerySizes';
import CURSOR_SIGN                          from '../../constants/cursorSigns';
import KEYCODE                              from '../../constants/keycodes';

// ===== Sounds =====

import ObjectGrasp                          from '../../sounds/object_grasp.mp3';
import ObjectThump                          from '../../sounds/object_thump.mp3';
import InputClick                           from '../../sounds/button_click.mp3';
import SwooshIn                             from '../../sounds/swoosh_in.mp3';
import SwooshOut                            from '../../sounds/swoosh_out.mp3';
import TooltipEnter                         from '../../sounds/create.mp3';
import ButtonHover                          from '../../sounds/button_hover.mp3';
import Success                              from '../../sounds/message_success.mp3';

// ===== Images =====

import CrossIcon                            from '../../images/editor/cross.svg';
import UploadIcon                           from '../../images/editor/upload.svg';
import VerascopeLogo                        from '../../images/verascope-logo-silhouette.svg';
import CautionIcon                          from '../../images/caution.svg';
import SmileyIcon                           from '../../images/editor/smiley.svg';
import ClockIcon                            from '../../images/editor/clock.svg';
import EyeIcon                              from '../../images/editor/eye.svg';
import HighlightIcon                        from '../../images/editor/highlight.svg';
import MagnifyingGlassIcon                  from '../../images/editor/magnifying-glass.svg';
import AddImageIcon                         from '../../images/editor/image-add.svg';
import GeekPortrait                         from '../../images/geek.svg';
import PhilosopherPortrait                  from '../../images/philosopher.svg';
import JournalistPortrait                   from '../../images/journalist.svg';
import InvestorPortrait                     from '../../images/investor.svg';
import MinorPortrait                        from '../../images/minor.svg';
import CartIcon                             from '../../images/cart.svg';

// ===== Styles =====

import {
    Container,
    EditorUpdateText,
    UpdateTextContainer,
    UploadProgressContainer,
    AnnotationContainer,
    PostInput,
    PostSubtitle,
    PostTitle,
    ModalSheetCloseButtonContainer,
    PostBanner,
    PostBannerLeftContainer,
    PostBannerRightContainer,
    PostThumbnail,
    PostContentContainer,
    PostContent,
    PostBannerCenterContainer,
    PostContentCenterContainer,
    PostMetadataContainer,
    PostContentLeftContainer,
    PostTitlesContainer,
    CollapsedPostTitle,
    PostAuthorContainer,
    PostAuthorName,
    PostMetadataContentContainer,
    PostDetailsContainer,
    PostDetail,
    PostDetailIcon,
    PostDetailText,
    ResolutionLevelSelectorContainer,
    CurrentResolutionLevelText,
    CurrentResolutionLevelIcon,
    AddPostThumbnailIcon,
    AddPostThumbnailContainer,
    AddPostThumbnailInput,
    PostThumbnailUploadProgressBarContainer,
    PostThumbnailUploadProgressBar,
    PostPlaceholderContainer,
    Handlebar,
    HandlebarContainer,
    PurchaseBookButtonContainer,
    ModalContentsContainer,
    ModalHeader,
    ModalTitle,
    ModalSubtitle,
    ModalBody,
    AdventureCharacterContainer,
    AdventureCharacterAvatarContainer,
    AdventureCharacterAvatar,
    AdventureCharacterName,
    AdventureCharacterEngagementLevel,
    CartAnimationItemsContainer,
    ButtonContainer,
}                                           from './styles';
import {
    PageLogo,
    DetailView,
    DropzoneInput,
    PlaceholderBox,
    AvatarsContainer,
    AvatarContainer,
    UserAvatar,
    Overlay,
}                                           from '../../styles';
import { theme as themeObj }                from '../../themes/theme-context';

interface Props {
    write: boolean,
    hasSound: boolean,
    user: IUserItem | null,
    currentSessionId: string | null,
    viewportDimensions: IDimension,
    notifyUserToSignUpToNavigateBook: boolean,
    snackbarIsVisible: boolean,
    cachedHasSound: boolean | null,
    stickyPostBannerActive: boolean,
    setStickyPostBannerActive: React.Dispatch<React.SetStateAction<boolean>>,
    setCursorSigns: React.Dispatch<React.SetStateAction<string[]>>,
    setNudgeUserToSignUpAfterAnnotation: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpForWebBook: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpForDigitalBook: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpForPhysicalBook: React.Dispatch<React.SetStateAction<boolean>>,
    setNotifyUserToSignUpToNavigateBook: React.Dispatch<React.SetStateAction<boolean>>,
    setHasSound: React.Dispatch<React.SetStateAction<boolean>>,
    setCachedHasSound: React.Dispatch<React.SetStateAction<boolean | null>>,
    onCursorEnter: (
        targetType: CURSOR_TARGET | INTERACTABLE_OBJECT | string,
        actions: string[],
        candidateTarget?: HTMLElement,
    ) => void,
    onCursorLeave: (e?: React.MouseEvent | React.TouchEvent | React.SyntheticEvent) => void,
    setInputFocused: React.Dispatch<React.SetStateAction<boolean>>,
    setSnackbarData: React.Dispatch<React.SetStateAction<ISnackbarItem>>,
}
function ReaderView({
    write,
    hasSound,
    user,
    currentSessionId,
    viewportDimensions,
    notifyUserToSignUpToNavigateBook,
    snackbarIsVisible,
    cachedHasSound,
    stickyPostBannerActive,
    setStickyPostBannerActive,
    setCursorSigns,
    onCursorEnter,
    onCursorLeave,
    setInputFocused,
    setNudgeUserToSignUpAfterAnnotation,
    setNotifyUserToSignUpForWebBook,
    setNotifyUserToSignUpForDigitalBook,
    setNotifyUserToSignUpForPhysicalBook,
    setNotifyUserToSignUpToNavigateBook,
    setSnackbarData,
    setHasSound,
    setCachedHasSound,
}: Props): JSX.Element {
    // ===== General Constants =====

    const DEFAULT_EDITOR_COLOR = themeObj.verascopeColor.purple200;
    const UPLOAD_TRANSITION_DELAY = 200;
    const ANNOTATION_EDITOR_WIDTH = 255;
    const MIN_ANNOTATION_SECTION_WIDTH = 300;
    const MIN_POST_SECTION_WIDTH = 650;
    const DETAIL_VIEW_BACKGROUND = 'inherit';
    const TITLE_INPUT_FONT_WEIGHT = 700;
    const SUBTITLE_INPUT_FONT_WEIGHT = 500;
    const TITLE_INPUT_MARGIN_TOP = 20;
    const SUBTITLE_INPUT_MARGIN_TOP = 20;
    const SNACKBAR_MESSAGE_FILE_ACCEPT = (multipleFiles: boolean): string => `Drop file${multipleFiles ? 's' : ''} in post.`;
    const SNACKBAR_MESSAGE_FILE_REJECT = (multipleFiles: boolean): string => `File type ${multipleFiles ? 's' : ''} not supported.`;
    const POST_TITLE_FONT_MULTIPLIER = 1.8;
    const POST_SUBTITLE_FONT_MULTIPLIER = 1.1;
    const POST_METADATA_FONT_MULTIPLIER = 0.8;
    const POST_TITLE_PLACEHOLDER_WIDTH_LARGE = 300;
    const POST_TITLE_PLACEHOLDER_WIDTH_MEDIUM = 250;
    const POST_TITLE_PLACEHOLDER_WIDTH_SMALL = 200;
    const POST_TITLE_PLACEHOLDER_WIDTH_EXTRA_SMALL = 100;
    const POST_SUBTITLE_PLACEHOLDER_WIDTH_LARGE = 600;
    const POST_SUBTITLE_PLACEHOLDER_WIDTH_MEDIUM = 500;
    const POST_SUBTITLE_PLACEHOLDER_WIDTH_SMALL = 300;
    const POST_SUBTITLE_PLACEHOLDER_WIDTH_EXTRA_SMALL = 200;
    const POST_AUTHOR_NAME_PLACEHOLDER_WIDTH = 100;
    const POST_PUBLISHED_PLACEHOLDER_WIDTH = 50;
    const POST_VIEWS_PLACEHOLDER_WIDTH = 30;
    const POST_ANNOTATIONS_PLACEHOLDER_WIDTH = 30;
    const MODAL_SHEET_BUTTON_WIDTH = 30;
    const MODAL_SHEET_BUTTON_HEIGHT = 30;
    const STICKY_POST_BANNER_HEIGHT = 50;
    const DEFAULT_STICKY_BANNER_THRESHOLD = POST_BANNER_HEIGHT - STICKY_POST_BANNER_HEIGHT;
    const SNACKBAR_MESSAGE_FETCH_IMAGE_ERROR = 'There was a problem fetching post image.';
    const POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED = 10;
    const POST_BANNER_LEFT_CONTAINER_PADDING_CONTRACTED = 5;
    const POST_THUMBNAIL_HEIGHT_EXPANDED = POST_BANNER_HEIGHT - 2 * POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED;
    const POST_THUMBNAIL_WIDTH_EXPANDED = 200;
    const POST_THUMBNAIL_HEIGHT_CONTRACTED = 40;
    const POST_THUMBNAIL_WIDTH_CONTRACTED = (POST_THUMBNAIL_HEIGHT_CONTRACTED / POST_THUMBNAIL_HEIGHT_EXPANDED) * POST_THUMBNAIL_WIDTH_EXPANDED;
    const POST_THUMBNAIL_BOTTOM_EXPANDED = 0;
    const POST_THUMBNAIL_BOTTOM_CONTRACTED = 0;
    const POST_THUMBNAIL_BORDER_RADIUS_EXPANDED = 5;
    const POST_THUMBNAIL_BORDER_RADIUS_CONTRACTED = 2.5;
    const POST_BANNER_LEFT_CONTAINER_HEIGHT_EXPANDED = POST_THUMBNAIL_HEIGHT_EXPANDED + 2 * POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED;
    const POST_BANNER_LEFT_CONTAINER_HEIGHT_CONTRACTED = POST_THUMBNAIL_HEIGHT_CONTRACTED + 2 * POST_BANNER_LEFT_CONTAINER_PADDING_CONTRACTED;
    const AVATAR_LENGTH = 28;
    const CONTENTS_TABLE_TOP_CONTRACTED = STICKY_POST_BANNER_HEIGHT;
    const POST_PUBLISHED_THRESHOLD = 830;
    const POST_VIEWS_THRESHOLD = 710;
    const POST_ANNOTATIONS_THRESHOLD = 710;
    const POST_AUTHOR_THRESHOLD = 470;
    const PURCHASE_BOOK_BUTTON_THRESHOLD = 850;
    const ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH = 65;
    const RESOLUTION_LEVEL_BUTTON_LENGTH = 70;
    const RESOLUTION_LEVEL_ITEM_WIDTH = 250;
    const RESOLUTION_LEVEL_ITEM_HEIGHT = 35;
    // Value of margin top for PostContent when composing a post
    const POST_CONTENT_MARGIN_TOP_WRITE = 50;
    // Snackbar message when user tries to upload a new post thumbnail while another upload is in progress
    const SNACKBAR_MESSAGE_EXISTING_AVATAR_UPLOAD = 'Unable to upload new image while uploading another.';
    // Height of post bottom buffer
    const POST_EDITOR_BUFFER_HEIGHT = 300;
    // Total number of resolution levels
    const TOTAL_RESOLUTION_LEVELS = 5;
    // Width of Web Book Download Button within Post Metadata Container
    const WEB_DOWNLOAD_BUTTON_WIDTH_SMALL = 75;
    // Width of Digital Book Download Button within Post Metadata Container
    const DIGITAL_DOWNLOAD_BUTTON_WIDTH_SMALL = 85;
    // Width of Physical Download Button within Post Metadata Container
    const PHYSICAL_DOWNLOAD_BUTTON_WIDTH_SMALL = 95;
    // Height of Book Download Button within Post Metadata Container
    const DOWNLOAD_BUTTON_HEIGHT_SMALL = 30;
    // Width of Web Book Download Button at corner of Post Banner when Sticky Banner is Active
    const WEB_DOWNLOAD_BUTTON_WIDTH_REGULAR = 84;
    // Width of Digital Book Download Button at corner of Post Banner when Sticky Banner is Active
    const DIGITAL_DOWNLOAD_BUTTON_WIDTH_REGULAR = 96;
    // Width of Physical Book Download Button at corner of Post Banner when Sticky Banner is Active
    const PHYSICAL_DOWNLOAD_BUTTON_WIDTH_REGULAR = 108;
    // Height of Book Download Button at corner of Post Banner when Sticky Banner is Active
    const DOWNLOAD_BUTTON_HEIGHT_REGULAR = 34;
    // Snackbar message when annotation nudge threshold is exceeded
    const SNACKBAR_MESSAGE_ANNOTATION_NUDGE = 'You have been reading for a while! Consider sharing your thoughts with other readers by contributing an annotation. To create an annotation, highlight text and add content using the revealed editor.';
    // Message when purchase book nudge threshold is exceeded
    const PURCHASE_BOOK_NUDGE_TOOLTIP_MESSAGE = 'You\'re on a roll! Consider purchasing a digital or physical copy of this book to read it offline.';
    // Snackbar message when attempt to add multiple copy of web book
    const SNACKBAR_MESSAGE_ADD_MULTIPLE_WEB_BOOK_TO_CART_ERROR = 'Only one copy of a digital item may be added to your cart.';
    // Snackbar message when attempt to add multiple copy of digital book
    const SNACKBAR_MESSAGE_ADD_MULTIPLE_DIGITAL_BOOK_TO_CART_ERROR = 'Only one copy of a digital item may be added to your cart.';
    // Snackbar message when attempt to add web book to cart when physical book already in cart
    const SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_PHYSICAL_BOOK_ALREADY_IN_CART_ERROR = 'Physical book already in cart. Item includes access to the web book.';
    // Snackbar message when attempt to add web book to cart when physical book already in cart
    const SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_DIGITAL_BOOK_ALREADY_IN_CART_ERROR = 'Digital book already in cart. Item includes access to the web book.';
    // Snackbar message when attempt to add digital book to cart when physical book already in cart
    const SNACKBAR_MESSAGE_ADD_DIGITAL_BOOK_TO_CART_WHEN_PHYSICAL_BOOK_ALREADY_IN_CART_ERROR = 'Physical book already in cart. Item includes a complimentary digital book copy.';
    // Snackbar message when attempt to add digital book to cart when physical book already purchased
    const SNACKBAR_MESSAGE_ADD_DIGITAL_BOOK_TO_CART_WHEN_PURCHASE_PHYSICAL_BOOK_ERROR = 'Already purchased a physical book which includes a complimentary digital book copy.';
    // Snackbar message when attempt to add digital book to cart when already purchased it
    const SNACKBAR_MESSAGE_REPURCHASE_DIGITAL_BOOK_ERROR = 'Already purchased a copy of the digital book.';
    // Snackbar message when attempt to add web book to cart when physical book already purchased
    const SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_PURCHASE_PHYSICAL_BOOK_ERROR = 'Already purchased a physical book which includes access to the web book.';
    // Snackbar message when attempt to add web book to cart when digital book already purchased
    const SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_PURCHASE_DIGITAL_BOOK_ERROR = 'Already purchased a digital book copy which includes access to the web book.';
    // Snackbar message when attempt to add web book to cart when already purchased it
    const SNACKBAR_MESSAGE_REPURCHASE_WEB_BOOK_ERROR = 'Already purchased access to the web book.';
    // Snackbar message when attempt to add digital book to cart when physical book already in it
    const SNACKBAR_MESSAGE_REPLACE_DIGITAL_BOOK_WITH_PHYSICAL_BOOK = 'Removed digital book from cart because it is included with a purchase of the physical book!';
    // Snackbar message when attempt to add digital book to cart when physical book already in it
    const SNACKBAR_MESSAGE_REPLACE_WEB_BOOK_WITH_PHYSICAL_BOOK = 'Removed web book from cart because it is included with a purchase of the physical book!';
    // Snackbar message when attempt to add web book to cart when digital book already in it
    const SNACKBAR_MESSAGE_REPLACE_WEB_BOOK_WITH_DIGITAL_BOOK = 'Removed web book from cart because it is included with a purchase of the digital book!';
    // Snackbar message when encounter cart item staging error
    const SNACKBAR_MESSAGE_ADD_CART_ITEM_TO_CART_ERROR = 'Encountered a problem adding item to cart. Please try again.';
    // Width of Choose Your Own Adventure Modal
    const CHARACTER_MODAL_WIDTH = 800;
    // Length of post placeholder box horizontal margin
    const POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN = 20;
    // Length of post placeholder box vertical margin
    const POST_PLACEHOLDER_BOX_VERTICAL_MARGIN = 5;
    // Length of post placeholder block (five boxes) margin
    const POST_PLACEHOLDER_BLOCK_VERTICAL_MARGIN = 30;

    // ===== Refs =====

    const containerRef = useRef<HTMLInputElement>(null);
    const annotationEditorRef = useRef<HTMLDivElement | null>(null);
    const postEditorContainerRef = useRef<HTMLDivElement | null>(null);
    const titleInputRef = useRef<HTMLInputElement | null>(null);
    const subtitleInputRef = useRef<HTMLInputElement | null>(null);
    const postBannerRef = useRef<HTMLDivElement | null>(null);
    const postContentContainerRef = useRef<HTMLDivElement | null>(null);
    const contentsTableRef = useRef<HTMLDivElement | null>(null);
    const contentsTableProgressBarRef = useRef<HTMLDivElement | null>(null);
    const contentsTableProgressBarContainerRef = useRef<HTMLDivElement | null>(null);
    const postBannerLeftContainerRef = useRef<HTMLDivElement | null>(null);
    const postThumbnailRef = useRef<HTMLDivElement | null>(null);
    const contentsTableToggleButtonContainerRef = useRef<HTMLDivElement | null>(null);
    const postScrollTop = useRef<number>(0);
    const resolutionLevelButtonRef = useRef<HTMLElement | null>(null);
    const readerLocalizingNavigatorRef = useRef<HTMLDivElement | null>(null);

    // ----- Sound Clips
    const handlebarGraspClip = useRef<HTMLAudioElement>(new Audio());
    const resizeConstraintThumpClip = useRef<HTMLAudioElement>(new Audio());
    const inputClickClip = useRef<HTMLAudioElement>(new Audio());
    const swooshInClip = useRef<HTMLAudioElement>(new Audio());
    const swooshOutClip = useRef<HTMLAudioElement>(new Audio());
    const tooltipEnterClip = useRef<HTMLAudioElement>(new Audio());
    const buttonHoverClip = useRef<HTMLAudioElement>(new Audio());
    const successClip = useRef<HTMLAudioElement>(new Audio());

    // ----- Views

    const detailViewRef = useRef<HTMLDivElement>(null);

    // ===== React Router =====

    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const annotationIsFocused = useMatch(`${PAGE_ROUTE.book}/:annotationId`);
    const [searchParams] = useSearchParams();

    // ===== State =====

    // Stores the current post id
    const [postId, setPostId] = useState<string | null>(BOOK_POST_ID);
    // Stores the current post title
    const [title, setTitle] = useState<string>('');
    // Stores the current post subtitle
    const [subtitle, setSubtitle] = useState<string>('');
    // Stores the current post data
    const [post, setPost] = useState<IPostItem | undefined>(undefined);
    // Stores the current post annotation data
    const [annotations, setAnnotations] = useState<Map<string, IAnnotationItem>>(new Map());
    // Stores current selected annotation data
    const [annotationDetailData, setAnnotationDetailData] = useState<IAnnotationItem | null>(null);
    // Stores reference to editor component
    const [postEditor, setPostEditor] = useState<Editor | null>(null);
    // Stores reference to annotation editor component
    const [annotationEditor, setAnnotationEditor] = useState<Editor | null>(null);
    // Stores the data of a post quote when text is highlighted by the user
    const [postQuote, setPostQuote] = useState<IAnnotationQuote | null>(null);
    // Triggers listener for post changes
    const [listenForPostChanges, setListenForPostChanges] = useState<boolean>(false);
    // Indicates whether the post is being saved
    const [postValueIsSaving, setPostValueIsSaving] = useState<boolean>(false);
    // Indicates whether page must be rerendered due to initialization of root components
    const [initializationRerender, setInitializationRerender] = useState<boolean>(false);
    // Stores data of media being uploaded
    const [uploadingMedia, setUploadingMedia] = useState<Map<string, IMediaItem>>(new Map());
    // Stores the position of the block toolbar
    const [annotationEditorPosition, setAnnotationEditorPosition] = useState<ICoord>({
        y: 0,
        x: 0,
    });
    // the width of the screen (%) occupied by resizable annotation section
    const [annotationViewWidth, setAnnotationViewWidth] = useState<number>(MIN_ANNOTATION_SECTION_WIDTH);
    // indicates whether detail view is being resized
    const [isSteering, setIsSteering] = useState<boolean>(false);
    // indicates whether the cursor is over the split view handlebar
    const [handlebarHovered, setHandlebarHovered] = useState<boolean>(false);
    // stores the minimum width of the detail view permissible
    const [hitDetailMinimum, setHitDetailMinimum] = useState<boolean>(false);
    // stores the minimum width of the cartridge view permissible
    const [hitPostMinimum, setHitPostMinimum] = useState<boolean>(false);
    // used to update annotation positioning
    const [annotationHighlightsRendered, setAnnotationHighlightsRendered] = useState<boolean>(false);
    // indicates which annotation is currently being hovered over
    const [targetAnnotationID, setTargetAnnotationID] = useState<string | null>(null);
    // indicates whether we should remeasure post height
    const [remeasurePostHeight, setRemeasurePostHeight] = useState<boolean>(false);
    // indicates whether we've fetched post
    const [fetchedPost, setFetchedPost] = useState<boolean>(false);
    // stores url of post image
    const [postImageURL, setPostImageURL] = useState<string | undefined>(undefined);
    // stores storage path of post image
    const [postImagePath, setPostImagePath] = useState<string | undefined>(undefined);
    // stores value at which post banner becomes sticky
    const [stickyBannerScrollThreshold, setStickyBannerScrollThreshold] = useState<number>(DEFAULT_STICKY_BANNER_THRESHOLD);
    // Stores URLs of all post authors
    const [postAuthorAvatarURLs, setPostAuthorAvatarURLs] = useState<string[]>([]);
    // Stores post author data
    const [postAuthors, setPostAuthors] = useState<IUserItem[]>([]);
    // Stores contents table data of post when in reading mode
    const [contentsTable, setContentsTable] = useState<IContentsChapterItem[] | null>(null);
    // Indicates the path of the post value currently being edited or viewed
    const [selectedPostValuePath, setSelectedPostValuePath] = useState<number[]>([]);
    // Stores current resolution level
    const [currentResolutionLevel, setCurrentResolutionLevel] = useState<IResolutionLevelItem | null>(null);
    // Indicates whether contents table path should be changed upon contents table refresh
    const [queueSelectedPostValuePathChange, setQueueSelectedPostValuePathChange] = useState<number[] | null>(null);
    // Stores data of post thumbnail being uploaded
    const [uploadingPostThumbnail, setUploadingPostThumbnail] = useState<IMediaItem | null>(null);
    // We track the progress of post thumbnail upload here
    const [uploadingThumbnailStateChangeEvent, setUploadingThumbnailStateChangeEvent] = useState<{
        mediaItem: IMediaItem,
        snapshot: UploadTaskSnapshot,
        progress: number,
    } | null>(null);
    // We track the completion of post thumbnail here
    const [uploadingThumbnailCompleteEvent, setUploadingThumbnailCompleteEvent] = useState<{
        mediaItem: IMediaItem,
        storageRef: StorageReference,
    } | null>(null);
    // Stores the number of resolution levels applied to the post
    const [appliedResolutionLevelCount, setAppliedResolutionLevelCount] = useState<number | null>(null);
    // Indicates whether to present purchase book nudge message
    // occurs after PURCHASE_NUDGE_THRESHOLD_DURATION of reading
    const [nudgeUserToPurchaseBook, setNudgeUserToPurchaseBook] = useState<boolean>(false);
    // Indicates whether we've attempted to initiate the sequence that manages annotation nudge message
    const [initiatedAnnotationNudgeRoutine, setInitiatedAnnotationNudgeRoutine] = useState<boolean>(false);
    // Indicates whether we've attempted to initiate the sequence that manages purchase book nudge message
    const [initiatedPurchaseBookNudgeRoutine, setInitiatedPurchaseBookNudgeRoutine] = useState<boolean>(false);
    // Indicates whether character modal is visible
    // Visible when anonymous user navigates to ReaderView
    const [characterModalIsVisible, setCharacterModalIsVisible] = useState<boolean>(false);
    // Indicates whether we've shown character selection modal to new users already
    const [checkedForCharacterModal, setCheckedForCharacterModal] = useState<boolean>(false);
    // Indicates whether we should show resolution tooltip to new users
    const [showResolutionTooltip, setShowResolutionTooltip] = useState<boolean>(false);
    // Indicates whether we've shown resolution tooltip to new users already
    const [presentedResolutionTooltip, setPresentedResolutionTooltip] = useState<boolean>(false);
    // Stores timestamp of when user entered page
    const [startOrResumeBrowsingTimestamp, setStartOrResumeBrowsingTimestamp] = useState<number>(Date.now());
    // Indicates if browser tab is focused
    const [browserTabFocused, setBrowserTabFocused] = useState<boolean>(true);
    // Stores duration reader has been reading post before tab is unfocused
    const [cachedPostFocusedDuration, setCachedPostFocusedDuration] = useState<number>(0);
    // Indicates whether we've begun reading timers
    const [initiatedReadingTimers, setInitiatedReadingTimers] = useState<boolean>(false);
    // Indicates whether entry user action has been recorded
    const [recordedViewPageUserAction, setRecordedViewPageUserAction] = useState<boolean>(false);
    // Indicates that rerenders upon page load have completed
    const [pageLoadRendersCompleted, setPageLoadRendersCompleted] = useState<boolean>(false);
    // Stores a list of web book items to animate to cart button in navigation bar
    const [animateWebBookToCartButtonItems, setAnimateWebBookToCartButtonItems] = useState<Map<string, IMediaItem>>(new Map());
    // Stores a list of web book items to animate to cart button in navigation bar
    const [animateDigitalBookToCartButtonItems, setAnimateDigitalBookToCartButtonItems] = useState<Map<string, IMediaItem>>(new Map());
    // Stores a list of web book items to animate to cart button in navigation bar
    const [animatePhysicalBookToCartButtonItems, setAnimatePhysicalBookToCartButtonItems] = useState<Map<string, IMediaItem>>(new Map());
    // Stores a list of cart items staged to be added to cart following animation completion
    const [stagedCartItems, setStagedCartItems] = useState<Map<string, IStagedCartItem>>(new Map());
    // Indicates whether web book cart item is currently being animated
    const [animatingWebBookCartItem, setAnimatingWebBookCartItem] = useState<boolean>(false);
    // Indicates whether digital book cart item is currently being animated
    const [animatingDigitalBookCartItem, setAnimatingDigitalBookCartItem] = useState<boolean>(false);
    // Indicates whether physical book cart item is currently being animated
    const [animatingPhysicalBookCartItem, setAnimatingPhysicalBookCartItem] = useState<boolean>(false);
    // Stores a map of store items
    const [storeItems, setStoreItems] = useState<Map<string, IStoreItem>>(new Map());
    // Indicates whether store items ahve been fetched
    const [fetchedStoreItems, setFetchedStoreItems] = useState<boolean>(false);
    // Indicates whether user has bought a web book
    const [purchasedWebBook, setPurchasedWebBook] = useState<boolean>(false);
    // Indicates whether user has bought a digital book
    const [purchasedDigitalBook, setPurchasedDigitalBook] = useState<boolean>(false);
    // Indicates whether user has bought a physical book
    const [purchasedPhysicalBook, setPurchasedPhysicalBook] = useState<boolean>(false);
    // Indicates whether checked for book purchases
    const [checkedBookPurchases, setCheckedBookPurchases] = useState<boolean>(false);

    // Set up dropzone for images and audio
    const {
        getRootProps,
        getInputProps,
        rootRef,
        acceptedFiles,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        multiple: true,
        noClick: true,
        noKeyboard: true,
        accept: ['image/*', 'audio/*'],
    });

    /**
     * Handles valid image for thumbnail
     */
    const onDropAccepted = useCallback((acceptedThumbnailFiles: File[]) => {
        if (write && acceptedThumbnailFiles.length > 0) {
            handlePostThumbnailReplace(acceptedThumbnailFiles[0]);
        }
    }, [
        write,
        user,
    ]);
    // Set up dropzone for thumbnail image
    const {
        getRootProps: getPostThumbnailRootProps,
        getInputProps: getPostThumbnailInputProps,
        isDragAccept: isPostThumbnailDragAccept,
        isDragReject: isPostThumbnailDragReject,
        open: openPostThumbnailDropzone,
    } = useDropzone({
        multiple: false,
        maxFiles: 1,
        noClick: true,
        noDrag: true,
        noKeyboard: true,
        noDragEventsBubbling: true, // Because a parent element has a drag event listener
        accept: ['image/*'],
        onDropAccepted,
    });

    // Choose Your Own Adventure Characters
    const adventureCharacters = new Map([
        [RESOLUTION_LEVEL.five, {
            id: ADVENTURE_CHARACTER.meticulousGeek,
            level: RESOLUTION_LEVEL.five,
            name: 'Meticulous Geek',
            icon: GeekPortrait,
            duration: 10 * MILLISECONDS_IN_AN_HOUR,
            background: themeObj.verascopeColor.orange200,
            nameColor: themeObj.verascopeColor.orange100,
            engagementColor: themeObj.verascopeColor.orange300,
            engagementHoverColor: themeObj.verascopeColor.orange300,
        }],
        [RESOLUTION_LEVEL.four, {
            id: ADVENTURE_CHARACTER.analyticalPhilosopher,
            level: RESOLUTION_LEVEL.four,
            name: 'Analytical Philosopher',
            icon: PhilosopherPortrait,
            duration: 2 * MILLISECONDS_IN_AN_HOUR,
            background: themeObj.verascopeColor.yellow100,
            nameColor: themeObj.verascopeColor.yellow200,
            engagementColor: themeObj.color.white,
            engagementHoverColor: themeObj.verascopeColor.yellow100,
        }],
        [RESOLUTION_LEVEL.three, {
            id: ADVENTURE_CHARACTER.committedJournalist,
            level: RESOLUTION_LEVEL.three,
            name: 'Committed Journalist',
            icon: JournalistPortrait,
            duration: 30 * MILLISECONDS_IN_A_MINUTE,
            background: themeObj.verascopeColor.red200,
            nameColor: themeObj.verascopeColor.red100,
            engagementColor: themeObj.verascopeColor.red300,
            engagementHoverColor: themeObj.verascopeColor.red300,
        }],
        [RESOLUTION_LEVEL.two, {
            id: ADVENTURE_CHARACTER.busyInvestor,
            level: RESOLUTION_LEVEL.two,
            name: 'Busy Investor',
            icon: InvestorPortrait,
            duration: 5 * MILLISECONDS_IN_A_MINUTE,
            background: themeObj.verascopeColor.green200,
            nameColor: themeObj.verascopeColor.green100,
            engagementColor: themeObj.verascopeColor.green300,
            engagementHoverColor: themeObj.verascopeColor.green300,
        }],
        [RESOLUTION_LEVEL.one, {
            id: ADVENTURE_CHARACTER.restlessMinor,
            level: RESOLUTION_LEVEL.one,
            name: 'Restless Minor',
            icon: MinorPortrait,
            duration: 30 * MILLISECONDS_IN_A_SECOND,
            background: themeObj.verascopeColor.blue200,
            nameColor: themeObj.verascopeColor.blue100,
            engagementColor: themeObj.verascopeColor.blue300,
            engagementHoverColor: themeObj.verascopeColor.blue300,
        }],
    ]);

    // ===== Animation Constants =====

    const POST_BANNER_TRANSITION_DURATION = 300;
    const COLLAPSED_POST_TITLE_TRANSITION_DURATION = 200;
    const COLLAPSED_PURCHASE_BUTTONS_TRANSITION_DURATION = COLLAPSED_POST_TITLE_TRANSITION_DURATION;
    const POST_METADATA_TRANSITION_DURATION = 200;
    const POST_SUBTITLE_TRANSITION_DURATION = 200;
    const POST_THUMBNAIL_TRANSITION_DURATION = 200;
    // Wait until user has oriented themselves with the UI
    const SHOW_RESOLUTION_TOOLTIP_DURATION = 15 * MILLISECONDS_IN_A_SECOND;
    const HIDE_RESOLUTION_TOOLTIP_DURATION = 8000;
    const OVERLAY_TRANSITION_DURATION = 200;
    // Duration after which annotation nudge should be presented
    const PURCHASE_BOOK_NUDGE_THRESHOLD_DURATION = 20 * MILLISECONDS_IN_A_MINUTE;
    // Duration after which annotation nudge should be presented
    const ANNOTATION_NUDGE_THRESHOLD_DURATION = 10 * MILLISECONDS_IN_A_MINUTE;
    // Duration after which annotation nudge should be hidden
    const HIDE_PURCHASE_BOOK_NUDGE_TIMEOUT_DURATION = EXTENDED_SNACKBAR_VISIBLE_DURATION;
    // Duration of transition of Choose Your Own Adventure characters
    const ADVENTURE_CHARACTER_TRANSITION_DURATION = 200;
    // Duration representing ten minutes
    const TEN_MINUTES_DURATION = 10 * MILLISECONDS_IN_A_MINUTE;
    // Duration representing thirty minutes
    const THIRTY_MINUTES_DURATION = 30 * MILLISECONDS_IN_A_MINUTE;
    // Duration representing an hour
    const HOUR_DURATION = MILLISECONDS_IN_AN_HOUR;
    // Duration after when we show post upon page load renders quiet down
    const PAGE_LOAD__RENDERS_COMPLETED_TIMEOUT_DURATION = 5 * MILLISECONDS_IN_A_SECOND;

    // ===== Methods =====

    /**
     * Manages response to page logo hovering over
     * @param e mouse or touch event
     */
    const onLogoEnter = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.logomark,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to page logo no longer hovering over
     * @param e mouse or touch event
     */
    const onLogoLeave = (e: React.MouseEvent | React.TouchEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to page logo selection
     * @param e mouse or touch event
     */
    const onLogoClick = async (e: React.MouseEvent): Promise<void> => {
        onCursorLeave(e);
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.clickHomeButton,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    route: PAGE_ROUTE.book,
                },
            });
        }
        navigate(
            `/${PAGE_ROUTE.landing}`,
            {
                state: {
                    prevPath: location.pathname,
                },
            },
        );
    };

    /**
     * Fetch stripe product prices
     */
    const fetchStoreItems = async (): Promise<void> => {
        const items = await stripeGetStoreItems({
            test: process.env.NODE_ENV === 'development',
        });
        setStoreItems(items);
        setFetchedStoreItems(true);
    };

    const determineBookPurchases = async (): Promise<void> => {
        if (user) {
            const purchases = await checkBookPurchases(user);
            if (purchases) {
                setPurchasedWebBook(purchases.web.length > 0);
                setPurchasedDigitalBook(purchases.digital.length > 0);
                setPurchasedPhysicalBook(purchases.physical.length > 0);
            }
        }

        setCheckedBookPurchases(true);
    };

    /**
     * Retrieves post data from Firestore if it exists, otherwise creates a new post
     */
    const fetchPost = async (): Promise<void> => {
        const db = getFirestore();
        const postsCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.posts
            : FIRESTORE_COLLECTION.stagingPosts;
        let id = postId;
        if (!id) {
            id = uuidv4();
            setPostId(id);
        }
        const postRef = doc(db, postsCollection, id);
        const postSnap = await getDoc(postRef);

        const postViews: string[] = []; // Harvest in order to update views below
        if (postSnap.exists()) {
            // Set Post Item
            const postData = postSnap.data() as IPostItem;
            setPost(postData);
            setListenForPostChanges(true);
            postViews.push(...postData.views);
        } else {
            // Create post document
            const emptyPost: IPostItem = {
                id,
                title,
                subtitle,
                authors: user?.id ? [user.id] : [],
                imagePath: '',
                value: '',
                published: Date.now(),
                updated: [],
                views: [],
                annotations: [],
            };
            await setDoc(doc(db, postsCollection, id), emptyPost);
            setListenForPostChanges(true);
        }

        if (
            user
            && currentSessionId
            && !fetchedPost
            && !write
        ) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.viewPost,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId: id,
                },
            });
            updatePostInDB({
                collection: postsCollection,
                id,
                views: [
                    ...postViews,
                    actionId,
                ],
            });
        }
    };

    /**
     * Modifies the title of the post in Firestore
     * @param newTitle updated title
     */
    const handleTitleChange = async (newTitle: string): Promise<void> => {
        if (post && postId) {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            updatePostInDB({
                collection: postsCollection,
                id: postId,
                title: newTitle,
            });
        }
    };

    /**
     * Modifies the subtitle of the post in Firestore
     * @param newSubtitle updated subtitle
     */
    const handleSubtitleChange = async (newSubtitle: string): Promise<void> => {
        if (post && postId) {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            updatePostInDB({
                collection: postsCollection,
                id: postId,
                subtitle: newSubtitle,
            });
        }
    };

    /**
     * Modifies the post content in Firestore
     * @param value updated post content
     */
    const handlePostChange = async (
        value: string,
        chapterIndex?: number | undefined,
        sectionIndex?: number | undefined,
    ): Promise<void> => {
        if (!!post && !!postId && write) {
            setPostValueIsSaving(true);
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;

            if ('value' in post) {
                // post has no chapters or sections
                await updatePostInDB({
                    collection: postsCollection,
                    id: postId,
                    value,
                    authors: user?.id && !post.authors.includes(user.id)
                        ? [
                            ...post.authors,
                            user.id,
                        ] : post.authors,
                });
            } else if ('chapters' in post) {
                if (
                    chapterIndex !== undefined
                    && sectionIndex !== undefined
                    && post.chapters![chapterIndex]
                    && post.chapters![chapterIndex]!.sections
                    && post.chapters![chapterIndex]!.sections![sectionIndex]
                    && 'value' in post.chapters![chapterIndex]!.sections![sectionIndex]
                ) {
                    const updatedChapters = [...post.chapters!];
                    updatedChapters[chapterIndex]!.sections![sectionIndex].value = value;
                    updatedChapters[chapterIndex]!.sections![sectionIndex].updated = [
                        ...updatedChapters[chapterIndex]!.sections![sectionIndex].updated,
                        Date.now(),
                    ];
                    await updatePostInDB({
                        collection: postsCollection,
                        id: postId,
                        chapters: updatedChapters,
                        authors: user?.id && !post.authors.includes(user.id)
                            ? [
                                ...post.authors,
                                user.id,
                            ] : post.authors,
                    });
                } else if (
                    chapterIndex !== undefined
                    && post.chapters![chapterIndex]
                    && 'value' in post.chapters![chapterIndex]
                ) {
                    const updatedChapters = [...post.chapters!];
                    updatedChapters[chapterIndex].value = value;
                    updatedChapters[chapterIndex].updated = [
                        ...updatedChapters[chapterIndex].updated,
                        Date.now(),
                    ];
                    await updatePostInDB({
                        collection: postsCollection,
                        id: postId,
                        chapters: updatedChapters,
                        authors: user?.id && !post.authors.includes(user.id)
                            ? [
                                ...post.authors,
                                user.id,
                            ] : post.authors,
                    });
                }
            }
            setPostValueIsSaving(false);
        }
    };

    /**
     * Adds a new media item to the uploading queue
     * @param mediaItem media item to upload
     */
    const addUploadingMedia = (mediaItem: IMediaItem): void => {
        setUploadingMedia(new Map([
            ...Array.from(uploadingMedia.entries()),
            [mediaItem.id, mediaItem],
        ]));
    };

    /**
     * Updates an existing media item in the uploading queue
     * @param mediaItem media item to update
     */
    const updateUploadingMedia = (mediaItem: IMediaItem): void => {
        setUploadingMedia(new Map([
            ...Array.from(uploadingMedia.entries()),
            [mediaItem.id, mediaItem],
        ]));
    };

    /**
     * Removes a media item from the uploading queue
     * @param mediaId media item to remove from the uploading queue
     */
    const removeUploadingMedia = (mediaId: string): void => {
        if (uploadingMedia.has(mediaId)) {
            uploadingMedia.delete(mediaId);
            setUploadingMedia(new Map([
                ...Array.from(uploadingMedia.entries()),
            ]));
        }
    };

    const addAnimatingCartItem = (
        item: IMediaItem,
        type: BOOK_TYPE,
    ): void => {
        switch (type) {
        case BOOK_TYPE.physical:
            setAnimatePhysicalBookToCartButtonItems(new Map([
                ...Array.from(animatePhysicalBookToCartButtonItems.entries()),
                [item.id, item],
            ]));
            break;
        case BOOK_TYPE.digital:
            setAnimateDigitalBookToCartButtonItems(new Map([
                ...Array.from(animateDigitalBookToCartButtonItems.entries()),
                [item.id, item],
            ]));
            break;
        default:
            // BOOK_TYPE.web
            setAnimateWebBookToCartButtonItems(new Map([
                ...Array.from(animateWebBookToCartButtonItems.entries()),
                [item.id, item],
            ]));
        }
    };

    const onCartItemAnimationComplete = (id: string): void => {
        if (animateWebBookToCartButtonItems.has(id)) {
            animateWebBookToCartButtonItems.delete(id);
            setAnimateWebBookToCartButtonItems(new Map([
                ...Array.from(animateWebBookToCartButtonItems.entries()),
            ]));
            if (animatingWebBookCartItem) setAnimatingWebBookCartItem(false);
        } else if (animateDigitalBookToCartButtonItems.has(id)) {
            animateDigitalBookToCartButtonItems.delete(id);
            setAnimateDigitalBookToCartButtonItems(new Map([
                ...Array.from(animateDigitalBookToCartButtonItems.entries()),
            ]));
            if (animatingDigitalBookCartItem) setAnimatingDigitalBookCartItem(false);
        } else if (animatePhysicalBookToCartButtonItems.has(id)) {
            animatePhysicalBookToCartButtonItems.delete(id);
            setAnimatePhysicalBookToCartButtonItems(new Map([
                ...Array.from(animatePhysicalBookToCartButtonItems.entries()),
            ]));
            if (animatingPhysicalBookCartItem) setAnimatingPhysicalBookCartItem(false);
        }

        const stagedCartItem = stagedCartItems.get(id);
        let updatedCart;
        if (
            user
            && !!stagedCartItem
            && user.cart
        ) {
            switch (stagedCartItem.cartOperation) {
            case STAGED_CART_ITEM_CART_OPERATION.filterOut:
                updatedCart = [
                    ...(user.cart.filter((item: ICartItem) => item.priceId !== stagedCartItem.operationArgument)),
                    stagedCartItem.cartItem,
                ];
                break;
            default:
                // STAGED_CART_ITEM_CART_OPERATION.none
                updatedCart = [
                    ...user.cart,
                    stagedCartItem.cartItem,
                ];
            }
            updateUserInDB({
                userId: user.id,
                cart: updatedCart,
            });

            // Play Sound
            if (hasSound && successClip.current) {
                successClip.current.pause();
                successClip.current.currentTime = 0;
                playAudio(successClip.current);
            }
        } else if (
            user
            && !!stagedCartItem
            && !user.cart
        ) {
            updateUserInDB({
                userId: user.id,
                cart: [stagedCartItem.cartItem],
            });

            // Play Sound
            if (hasSound && successClip.current) {
                successClip.current.pause();
                successClip.current.currentTime = 0;
                playAudio(successClip.current);
            }
        } else {
            setSnackbarData({
                visible: true,
                duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_ADD_CART_ITEM_TO_CART_ERROR,
                icon: CartIcon,
                hasFailure: true,
            });
        }
    };

    /**
     * Submits an annotation to Firestore
     * @param value annotation text
     * @param quote highlighed quote associated with annotation
     * @param media optional media associated with annotation
     */
    const handleSubmitAnnotation = async (
        value: string,
        text: string,
        quote: IAnnotationQuote,
        media: string[],
    ): Promise<void> => {
        if (!annotationEditor) throw Error('There was a problem submitting annotation. Editor reference not found.');
        if (!user) throw Error('There was a problem submitting annotation. User data not found.');
        if (!postId) throw Error('There was a problem submitting annotation. Post ID not found.');

        // Clear annotation nudge timeout if active
        if (user.annotations.length === 0) clearTimeoutAnnotationNudge();

        const id = uuidv4();
        const timestamp = Date.now();
        const annotationValue: IAnnotationValue = {
            id: uuidv4(),
            timestamp,
            value,
            text,
        };
        let annotationQuote = { ...quote };
        let chapterIndex = 0;
        let sectionIndex = 0;
        if (
            !!post
            && !!post.chapters
            && currentResolutionLevel
            && currentResolutionLevel.level < RESOLUTION_LEVEL.four
        ) {
            // Determine which post value annotation is associated with
            const pathFirstIndex = annotationQuote.selections[0]!.anchor.path[0];
            let priorNodes = 0;
            for (let i = 0; i < post.chapters.length; i += 1) {
                if (post.chapters[i].sections) {
                    // Reset index
                    sectionIndex = 0;
                    for (let j = 0; j < post.chapters[i].sections!.length; j += 1) {
                        const sectionValue = JSON.parse(post.chapters[i].sections![j].value);
                        if (pathFirstIndex < priorNodes + sectionValue.length) {
                            break;
                        }
                        priorNodes += sectionValue.length;
                        sectionIndex += 1;
                    }
                } else {
                    const chapterValue = JSON.parse(post.chapters[i].value!);
                    if (pathFirstIndex < priorNodes + chapterValue.length) {
                        break;
                    }
                    priorNodes += chapterValue.length;
                    chapterIndex += 1;
                }
            }
            // Update annotation quote to reflect new path
            annotationQuote = {
                ...annotationQuote,
                selections: [{
                    anchor: {
                        path: [
                            annotationQuote.selections[0]!.anchor.path[0] - priorNodes,
                            ...annotationQuote.selections[0]!.anchor.path.slice(1),
                        ],
                        offset: annotationQuote.selections[0]!.anchor.offset,
                    },
                    focus: {
                        path: [
                            annotationQuote.selections[0]!.focus.path[0] - priorNodes,
                            ...annotationQuote.selections[0]!.focus.path.slice(1),
                        ],
                        offset: annotationQuote.selections[0]!.focus.offset,
                    },
                }],
            };
        } else if (
            !!post
            && !!post.chapters
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.four
            && selectedPostValuePath.length === 1
        ) {
            // Determine which post value annotation is associated with
            const pathFirstIndex = annotationQuote.selections[0]!.anchor.path[0];
            let priorNodes = 0;
            const chapter = post.chapters[selectedPostValuePath[0]];
            [chapterIndex] = selectedPostValuePath;
            if (chapter.sections) {
                for (let j = 0; j < chapter.sections.length; j += 1) {
                    const sectionValue = JSON.parse(chapter.sections![j].value);
                    if (pathFirstIndex < priorNodes + sectionValue.length) {
                        break;
                    }
                    priorNodes += sectionValue.length;
                    sectionIndex += 1;
                }
            }

            // Update annotation quote to reflect new path
            annotationQuote = {
                ...annotationQuote,
                selections: [{
                    anchor: {
                        path: [
                            annotationQuote.selections[0]!.anchor.path[0] - priorNodes,
                            ...annotationQuote.selections[0]!.anchor.path.slice(1),
                        ],
                        offset: annotationQuote.selections[0]!.anchor.offset,
                    },
                    focus: {
                        path: [
                            annotationQuote.selections[0]!.focus.path[0] - priorNodes,
                            ...annotationQuote.selections[0]!.focus.path.slice(1),
                        ],
                        offset: annotationQuote.selections[0]!.focus.offset,
                    },
                }],
            };
        }
        const annotation: IAnnotationItem = {
            id,
            postId,
            history: [annotationValue],
            userId: user.id,
            media,
            quoteHistory: [annotationQuote],
            views: [],
            plays: [],
            published: timestamp,
            updated: [],
            deleted: {
                deleted: false,
                timestamp: null,
            },
        };
        // We capture text sychronously because it is cleared by the annotation editor
        // after this call.
        // Placing it in the setAnnotationInDB callback will result in an empty string.
        setAnnotationInDB({ annotation }).then(async () => {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;

            // Update post annotation references
            if (post && postId) {
                updatePostInDB({
                    collection: postsCollection,
                    id: postId,
                    annotations: [
                        ...post.annotations,
                        id,
                    ],
                });
            }

            // Update post chapter and sections annotation references
            if (
                postId
                && post
                && 'chapters' in post
                && (
                    post.chapters![selectedPostValuePath[0]]
                    || (
                        currentResolutionLevel
                        && currentResolutionLevel.level < RESOLUTION_LEVEL.five
                    )
                )
            ) {
                const updatedChapters = [...post.chapters!];
                if (post.chapters![selectedPostValuePath[0]]) {
                    // Resolution 4+
                    updatedChapters[selectedPostValuePath[0]].annotations = [
                        ...updatedChapters[selectedPostValuePath[0]].annotations,
                        id,
                    ];
                } else {
                    // Resolution 1-3
                    updatedChapters[chapterIndex].annotations = [
                        ...updatedChapters[chapterIndex].annotations,
                        id,
                    ];
                }

                if (
                    selectedPostValuePath.length === 2
                    && post.chapters![selectedPostValuePath[0]]!.sections
                    && post.chapters![selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]]
                ) {
                    updatedChapters[selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].annotations = [
                        ...updatedChapters[selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].annotations,
                        id,
                    ];
                } else if (
                    currentResolutionLevel
                    && currentResolutionLevel.level < RESOLUTION_LEVEL.five
                    && 'sections' in post.chapters![chapterIndex]
                ) {
                    updatedChapters[chapterIndex]!.sections![sectionIndex].annotations = [
                        ...updatedChapters[chapterIndex]!.sections![sectionIndex].annotations,
                        id,
                    ];
                }

                await updatePostInDB({
                    collection: postsCollection,
                    id: postId,
                    chapters: updatedChapters,
                });
            }

            // Update author annotation references
            if (user) {
                updateUserInDB({
                    userId: user.id,
                    annotations: [
                        ...user.annotations,
                        id,
                    ],
                });
            }

            // Determine whether to present sign up nudge message
            const auth = getAuth();
            if (
                auth.currentUser
                && auth.currentUser.isAnonymous
            ) {
                setNudgeUserToSignUpAfterAnnotation(true);

                // Play Sound
                if (tooltipEnterClip.current) {
                    tooltipEnterClip.current.pause();
                    tooltipEnterClip.current.currentTime = 0;
                    playAudio(tooltipEnterClip.current);
                }
            }

            // Generate Text Dictation
            // This should in turn generate a transcript with timestamps
            // by triggering the speech-to-text cloud function
            // We want to create both male and female dictation files for the annotation
            // But we use the female one by default
            generateTextSpeech(
                id,
                text,
                'masculine',
            );
            generateTextSpeech(
                id,
                text,
                'feminine',
                (result) => {
                    // We use feminine voice by default
                    const { filePath } = result.data;
                    const annotationCollection = process.env.NODE_ENV === 'production'
                        ? FIRESTORE_COLLECTION.annotations
                        : FIRESTORE_COLLECTION.stagingAnnotations;
                    updateAnnotationInDB({
                        collection: annotationCollection,
                        id,
                        dictationFilePaths: [filePath],
                    });
                },
            );
        });

        // Send annotation notice email
        // Send confirmation email
        const storage = getStorage();
        const db = getFirestore();
        const emailRequestCollection = FIRESTORE_COLLECTION.mail;
        const generateUserName = (userItem: IUserItem): string => {
            if (userItem.firstName && userItem.lastName) {
                return `${userItem.firstName} ${userItem.lastName}`;
            }

            if (userItem.firstName) {
                return userItem.firstName;
            }

            return 'A person';
        };

        // eslint-disable-next-line max-len
        let userAvatarURL = 'https://firebasestorage.googleapis.com/v0/b/verascope-website.appspot.com/o/mail-images%2Fuser-avatar-placeholder.png?alt=media&token=ee00bee1-136c-4b3c-bade-ee2c37e2aa1d';
        if (user.avatarFilePath) {
            const annotationAuthorPathParts = user.avatarFilePath.split('.');
            const mediumPath = `${annotationAuthorPathParts[0]}_medium.${annotationAuthorPathParts[1]}`;
            userAvatarURL = await getDownloadURL(ref(storage, mediumPath));
        }
        // eslint-disable-next-line max-len
        let postAuthorAvatarURL = 'https://firebasestorage.googleapis.com/v0/b/verascope-website.appspot.com/o/mail-images%2Fuser-avatar-placeholder.png?alt=media&token=ee00bee1-136c-4b3c-bade-ee2c37e2aa1d';
        if (postAuthors[0].avatarFilePath) {
            const postAuthorPathParts = postAuthors[0].avatarFilePath.split('.');
            const mediumPath = `${postAuthorPathParts[0]}_medium.${postAuthorPathParts[1]}`;
            postAuthorAvatarURL = await getDownloadURL(ref(storage, mediumPath));
        }
        const annotationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        const annotationCollectionRef = collection(db, annotationCollection);
        const annotationsSnapshot = await getDocs(annotationCollectionRef);
        let dayAnnotations = 0;
        let totalAnnotations = 0;
        if (!annotationsSnapshot.empty) {
            annotationsSnapshot.forEach((annotationDoc) => {
                if (annotationDoc.exists()) {
                    const item = annotationDoc.data() as IAnnotationItem;
                    if (!item.deleted.deleted) totalAnnotations += 1;
                    if (
                        !item.deleted.deleted
                        && item.published >= new Date().setHours(0, 0, 0, 0)
                        && item.published <= new Date().setHours(23, 59, 59, 999)
                    ) {
                        dayAnnotations += 1;
                    }
                }
            });
        }

        const emailRequest: IEmail = {
            from: EMAIL_SENDER_ADDRESS,
            to: TEAM_EMAIL_ADDRESS,
            replyTo: EMAIL_REPLY_ADDRESS,
            message: EMAIL_TEMPLATE_ANNOTATION_NOTICE({
                userName: generateUserName(user),
                dayAnnotations,
                totalAnnotations,
                annotationLink: `${window.location.origin}/${PAGE_ROUTE.book}/${annotation.id}`,
                quoteText: annotationQuote.texts[0],
                postAuthor: postAuthors.length > 1
                    ? postAuthors.reduce((acc, author) => {
                        if (acc.length > 0) {
                            return `${acc}, ${generateUserName(author)}`;
                        }
                        return generateUserName(author);
                    }, '') : generateUserName(postAuthors[0]),
                postTitle: post?.title || DEFAULT_POST_TITLE,
                postAuthorAvatarURL,
                annotationText: text,
                userAvatarURL,
                mailingListEmail: TEAM_EMAIL_ADDRESS,
                annotationDate: moment.utc(annotation.published).format('ll'),
            }),
        };
        await addDoc(collection(db, emailRequestCollection), emailRequest);

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.createAnnotation,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    annotationId: annotation.id,
                },
            });
        }
    };

    /**
     * Updates the position of the annotation editor based on the current selection
     */
    const updateAnnotationEditorPosition = (): void => {
        const nativeSelection = window.getSelection();
        let y = 0;
        let x = 0;
        if (
            nativeSelection
            && nativeSelection.rangeCount > 0
            && containerRef.current
            && annotationEditorRef.current
        ) {
            // For read
            // We don't use Dropzone Ref
            const range = nativeSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const toolbarWidth = annotationEditorRef.current.children[0]
                ? annotationEditorRef.current.children[0].clientWidth
                : 0;
            y = Math.max(
                (rect.top - containerRef.current.getBoundingClientRect().top
                    - (DEFAULT_ANNOTATION_EDITOR_HEIGHT + 7)
                ),
                0,
            );
            x = Math.max(
                ((rect.left - containerRef.current.getBoundingClientRect().left + rect.width / 2)
                    - (toolbarWidth / 2)
                ),
                0,
            );
        } else if (
            nativeSelection
            && nativeSelection.rangeCount > 0
            && rootRef.current
            && annotationEditorRef.current
        ) {
            // For write
            // We use Dropzone Ref
            const range = nativeSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const toolbarWidth = annotationEditorRef.current.children[0]
                ? annotationEditorRef.current.children[0].clientWidth
                : 0;
            y = (rect.top - rootRef.current.getBoundingClientRect().top
                - (DEFAULT_ANNOTATION_EDITOR_HEIGHT + 7)
            );
            x = Math.max(
                ((rect.left - rootRef.current.getBoundingClientRect().left + rect.width / 2)
                    - (toolbarWidth / 2)
                ),
                0,
            );
        }
        setAnnotationEditorPosition({
            x,
            y,
        });

        if (viewportDimensions.width < MEDIA_QUERY_SIZE.small.min) {
            // Make sure highlighted annotation is at top of screen
            const highlight = document.querySelector('[data-quote=\'post-quote\']');
            if (highlight) {
                // corresponds with align to top with smooth scroll
                highlight.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'nearest',
                    block: 'center',
                });
            }
        }
    };

    /**
     * Resizes the relative viewport partitioning between the annotation view and the post view\
     * Also enforces minimum and maximum constraints for the annotation view
     * @param e resize event
     * @param data resize data
     */
    const onResize = (
        e: React.SyntheticEvent<Element, Event>,
        data: ResizeCallbackData,
    ): void => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) return;

        setAnnotationViewWidth(data.size.width);
        if (data.size.width <= MIN_ANNOTATION_SECTION_WIDTH && !hitDetailMinimum) {
            setHitDetailMinimum(true);

            // Play Sound
            if (hasSound && resizeConstraintThumpClip.current) {
                resizeConstraintThumpClip.current.pause();
                resizeConstraintThumpClip.current.currentTime = 0;
                playAudio(resizeConstraintThumpClip.current);
            }

            // Change Cursor
            setCursorSigns([CURSOR_SIGN.caution]);
        } else if (hitDetailMinimum && data.size.width > MIN_ANNOTATION_SECTION_WIDTH) {
            setHitDetailMinimum(false);

            // Change Cursor
            if (isSteering) {
                setCursorSigns([CURSOR_SIGN.horizontalSteer]);
            } else if (handlebarHovered) {
                setCursorSigns([CURSOR_SIGN.grab]);
            }
        } else if ((viewportDimensions.width - data.size.width) <= MIN_POST_SECTION_WIDTH && !hitPostMinimum) {
            setHitPostMinimum(true);

            // Play Sound
            if (hasSound && resizeConstraintThumpClip.current) {
                resizeConstraintThumpClip.current.pause();
                resizeConstraintThumpClip.current.currentTime = 0;
                playAudio(resizeConstraintThumpClip.current);
            }

            // Change Cursor
            setCursorSigns([CURSOR_SIGN.caution]);
        } else if (hitPostMinimum && (viewportDimensions.width - data.size.width) > MIN_POST_SECTION_WIDTH) {
            setHitPostMinimum(false);

            // Change Cursor
            if (isSteering) {
                setCursorSigns([CURSOR_SIGN.horizontalSteer]);
            } else if (handlebarHovered) {
                setCursorSigns([CURSOR_SIGN.grab]);
            }
        }
    };

    /**
     * Manages response to annotation view resize handlebar hovering over
     * @param e mouse or touch event
     */
    const onHandlebarMouseEnter = (e: React.MouseEvent): void => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) return;

        setHandlebarHovered(true);
        if (!isSteering) {
            onCursorEnter(
                CURSOR_TARGET.handlebar,
                [CURSOR_SIGN.grab],
                e.target as HTMLElement,
            );
        }
    };

    /**
     * Manages response to annotation view resize handlebar no longer hovering over
     * @param e mouse or touch event
     */
    const onHandlebarMouseLeave = (e: React.MouseEvent): void => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) return;

        setHandlebarHovered(false);
        if (!isSteering) {
            onCursorLeave(e);
        }
    };

    /**
     * Manages response to starting resize annotation view
     * @param e resize event
     */
    const onResizeStart = (e: React.SyntheticEvent): void => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) return;

        setIsSteering(true);
        onCursorEnter(
            CURSOR_TARGET.handlebar,
            [CURSOR_SIGN.horizontalSteer],
            e.target as HTMLElement,
        );

        // Play Sound
        if (hasSound && handlebarGraspClip.current) {
            handlebarGraspClip.current.pause();
            handlebarGraspClip.current.currentTime = 0;
            playAudio(handlebarGraspClip.current);
        }
    };

    /**
     * Manages response to ending resize annotation view
     * @param e resize event
     */
    const onResizeStop = async (e: React.SyntheticEvent): Promise<void> => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) return;

        setIsSteering(false);
        if (hitDetailMinimum) {
            setHitDetailMinimum(false);
        }
        if (hitPostMinimum) {
            setHitPostMinimum(false);
        }
        if (!handlebarHovered) {
            onCursorLeave(e);
        } else {
            // Change Cursor
            setCursorSigns([CURSOR_SIGN.grab]);
        }

        updateAnnotationEditorPosition();

        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.adjustPostSplitView,
                userId: user.id,
                sessionId: currentSessionId,
            });
        }
    };

    /**
     * Manages presenting an annotation to user
     * @param annotation annotation to present
     */
    const onShowAnnotationDetail = (annotation: IAnnotationItem): void => {
        setAnnotationDetailData(annotation);
    };

    /**
     * Manages hiding an annotation from user
     */
    const onHideAnnotationDetail = (): void => {
        setAnnotationDetailData(null);

        if (annotationIsFocused) {
            navigate(
                `/${PAGE_ROUTE.book}?${searchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        }
    };

    /**
     * Manages determining whether keystroke is enter or escape
     * If enter: moves focus to subtitle input
     * If escape: blurs focused input
     * @param e keyboard event
     */
    const checkForEnter = (e: React.KeyboardEvent): void => {
        if (e.key === KEYCODE.enter) {
            // Move from title field to subtitle field
            if (
                titleInputRef.current
                && titleInputRef.current === document.activeElement
            ) {
                subtitleInputRef.current?.focus();
            }
        } else if (e.key === KEYCODE.escape) {
            // Blur Focused Input
            (document.activeElement as HTMLInputElement).blur();
        }
    };

    /**
     * Manages response to post title or subtitle input hovering over
     * @param e mouse event
     */
    const onInputMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.input,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to post title or subtitle input no longer hovering over
     * @param e mouse event
     */
    const onInputMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to button hovering over
     * @param e mouse event
     */
    const onButtonMouseEnter = (e: React.MouseEvent): void => {
        onCursorEnter(
            CURSOR_TARGET.editorButton,
            [CURSOR_SIGN.click],
            e.target as HTMLElement,
        );
    };

    /**
     * Manages response to button no longer hovering over
     * @param e mouse event
     */
    const onButtonMouseLeave = (e: React.MouseEvent): void => {
        onCursorLeave(e);
    };

    /**
     * Manages response to title or subtitle input focusing
     */
    const onInputFocus = (): void => {
        // Play Sound
        if (hasSound && inputClickClip.current) {
            inputClickClip.current.pause();
            inputClickClip.current.currentTime = 0;
            playAudio(inputClickClip.current);
        }
    };

    /**
     * Manages response to title input changing
     */
    const onTitleInputChange = (): void => {
        if (titleInputRef.current) {
            const newTitle = titleInputRef.current.value;
            if (newTitle !== title) handleTitleChange(newTitle);

            updatePageTitle(
                write ? 'Write' : 'Read',
                newTitle.length > 0 ? newTitle : DEFAULT_POST_TITLE,
            );
        }
    };

    /**
     * Manages response to subtitle input changing
     */
    const onSubtitleInputChange = (): void => {
        if (subtitleInputRef.current) {
            const newSubtitle = subtitleInputRef.current.value;
            if (newSubtitle !== subtitle) handleSubtitleChange(newSubtitle);
        }
    };

    /**
     * Manages response to changing active contents table item
     */
    const onChangeSelectedPostValuePath = async ({
        path,
        e,
        expandItems,
    }: IPostPathEvent): Promise<void> => {
        // Clear annotations
        // This is done to prevent PostEditor from deleting annotations
        // because value changes before new annotations are loaded
        setAnnotations(new Map());

        // Update path
        setSelectedPostValuePath(path);

        // Update Scroll Position
        trackPostScrollTop();

        // Reveal sections if chapter has any
        if (
            !!contentsTable
            && expandItems
        ) {
            onSetExpandContentsTableChapter(expandItems);
        }

        if (e) {
            // Scroll to table of contents section
            const target = e.target as HTMLElement;
            target.scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest',
                block: 'center',
            });
        }

        const postsCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.posts
            : FIRESTORE_COLLECTION.stagingPosts;

        // Increment post view count
        // Method can only be triggered by selecting a chapter or a section
        // Explains why there are only two conditional paths
        if (
            !write
            && user
            && postId
            && post
            && currentSessionId
            && path.length === 1
            && 'chapters' in post
            && post.chapters![path[0]]
            && 'value' in post.chapters![path[0]]
        ) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.viewPostChapter,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId: post.chapters![path[0]].id,
                },
            });
            const updatedChapters = [...post.chapters!];
            updatedChapters[path[0]].views = [
                ...updatedChapters[path[0]].views,
                actionId,
            ];
            updatePostInDB({
                collection: postsCollection,
                id: postId,
                chapters: updatedChapters,
                views: [
                    ...post.views,
                ],
            });
        } else if (
            !write
            && user
            && postId
            && post
            && currentSessionId
            && path.length === 2
            && 'chapters' in post
            && post.chapters![path[0]]
            && post.chapters![path[0]]!.sections
            && post.chapters![path[0]]!.sections![path[1]]
            && 'value' in post.chapters![path[0]]!.sections![path[1]]
        ) {
            // Record user action
            const actionId = await recordUserAction({
                type: USER_ACTION_TYPE.viewPostChapterSection,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId,
                    chapterId: post.chapters![path[0]].id,
                    sectionId: post.chapters![path[0]].sections![path[1]].id,
                },
            });
            const updatedChapters = [...post.chapters!];
            updatedChapters[path[0]].sections![path[1]].views = [
                ...updatedChapters[path[0]].sections![path[1]].views,
                actionId,
            ];
            updatePostInDB({
                collection: postsCollection,
                id: postId,
                chapters: updatedChapters,
                views: [
                    ...post.views,
                ],
            });
        }

        // Set URL Params for chapter and section
        if (path.length === 0 && currentResolutionLevel) {
            if (searchParams.has(READER_PARAMS_TYPE.resolution.toString())) {
                let newSearchParams = searchParams;
                newSearchParams = new URLSearchParams([
                    // can be any resolution level
                    [READER_PARAMS_TYPE.resolution.toString(), currentResolutionLevel.level.toString()],
                ]);
                navigate(
                    `/${PAGE_ROUTE.book}?${newSearchParams.toString()}`,
                    {
                        state: location.state,
                    },
                );
            } else {
                navigate(
                    `/${PAGE_ROUTE.book}`,
                    {
                        state: location.state,
                    },
                );
            }
        } else if (path.length === 1 && currentResolutionLevel) {
            let newSearchParams = searchParams;
            newSearchParams = new URLSearchParams([
                // must always be resolution level four or five
                [
                    READER_PARAMS_TYPE.resolution.toString(),
                    currentResolutionLevel.level < RESOLUTION_LEVEL.four
                        ? RESOLUTION_LEVEL.four.toString()
                        : currentResolutionLevel.level.toString(),
                ],
                [READER_PARAMS_TYPE.chapter.toString(), (path[0] + 1).toString()],
            ]);

            navigate(
                `/${PAGE_ROUTE.book}?${newSearchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        } else if (path.length === 2 && currentResolutionLevel) {
            let newSearchParams = searchParams;
            newSearchParams = new URLSearchParams([
                // must always be resolution level five
                [READER_PARAMS_TYPE.resolution.toString(), RESOLUTION_LEVEL.five.toString()],
                [READER_PARAMS_TYPE.chapter.toString(), (path[0] + 1).toString()],
                [READER_PARAMS_TYPE.section.toString(), (path[1] + 1).toString()],
            ]);

            navigate(
                `/${PAGE_ROUTE.book}?${newSearchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        }
    };

    /**
     * Expands or collapses chapter body in contents table
     * @param index toggled chapter index
     * @param e mouse or touch event
     */
    const onSetExpandContentsTableChapter = (
        expandItems: IPostPathExpandChapterItem[],
        e?: React.MouseEvent | React.TouchEvent,
    ): void => {
        e?.stopPropagation();
        if (!contentsTable) return;

        const updatedContentsTable = [...contentsTable];
        expandItems.forEach(({ chapterIndex, expand }) => {
            const updatedChapter = {
                ...contentsTable[chapterIndex],
                expanded: expand,
            };
            updatedContentsTable[chapterIndex] = updatedChapter;

            if (
                user
                && currentSessionId
                && e // Only record user action if event is triggered by user
            ) {
                // Record User Action
                recordUserAction({
                    type: USER_ACTION_TYPE.toggleCollapsedPostChapter,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        chapterId: contentsTable[chapterIndex].id,
                        expanded: expand,
                    },
                });
            }
        });
        setContentsTable(updatedContentsTable);
    };

    /**
     * Adjusts Post Banner presentation based on scroll position of post
     * @param e scroll event
     */
    const trackPostScrollTop = (e?: Event): void => {
        const target: HTMLElement | null = e?.target as HTMLElement;

        const scrollTop = target?.scrollTop || 0;

        // Necessary for AnnotationBucket
        postScrollTop.current = scrollTop;

        const fixedToViewport = scrollTop >= stickyBannerScrollThreshold;

        // Post Banner Left Container
        if (postBannerLeftContainerRef.current) {
            postBannerLeftContainerRef.current.style.height = fixedToViewport
                ? `${POST_BANNER_LEFT_CONTAINER_HEIGHT_CONTRACTED}px`
                : `${((POST_BANNER_LEFT_CONTAINER_HEIGHT_CONTRACTED - POST_BANNER_LEFT_CONTAINER_HEIGHT_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + POST_BANNER_LEFT_CONTAINER_HEIGHT_EXPANDED}px`;
            postBannerLeftContainerRef.current.style.padding = fixedToViewport
                ? `${POST_BANNER_LEFT_CONTAINER_PADDING_CONTRACTED}px`
                : `${((POST_BANNER_LEFT_CONTAINER_PADDING_CONTRACTED - POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED}px`;
        }

        // Post Thumbnail
        if (postThumbnailRef.current) {
            postThumbnailRef.current.style.bottom = fixedToViewport
                ? `${POST_THUMBNAIL_BOTTOM_CONTRACTED}px`
                : `${((POST_THUMBNAIL_BOTTOM_CONTRACTED - POST_THUMBNAIL_BOTTOM_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_BOTTOM_EXPANDED}px`;
            postThumbnailRef.current.style.width = fixedToViewport
                ? `${POST_THUMBNAIL_WIDTH_CONTRACTED}px`
                : `${((POST_THUMBNAIL_WIDTH_CONTRACTED - POST_THUMBNAIL_WIDTH_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_WIDTH_EXPANDED}px`;
            postThumbnailRef.current.style.borderRadius = fixedToViewport
                ? `${POST_THUMBNAIL_BORDER_RADIUS_CONTRACTED}px`
                : `${((POST_THUMBNAIL_BORDER_RADIUS_CONTRACTED - POST_THUMBNAIL_BORDER_RADIUS_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_BORDER_RADIUS_EXPANDED}px`;
            if (viewportDimensions.width >= MEDIA_QUERY_SIZE.large.min) {
                postThumbnailRef.current.style.height = fixedToViewport
                    ? `${POST_THUMBNAIL_HEIGHT_CONTRACTED}px`
                    : `${((POST_THUMBNAIL_HEIGHT_CONTRACTED - POST_THUMBNAIL_HEIGHT_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_HEIGHT_EXPANDED}px`;
            } else if (viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min) {
                postThumbnailRef.current.style.height = fixedToViewport
                    ? `${POST_THUMBNAIL_HEIGHT_CONTRACTED}px`
                    : `${((POST_THUMBNAIL_HEIGHT_CONTRACTED - POST_THUMBNAIL_MAX_WIDTH_MEDIUM) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_MAX_WIDTH_MEDIUM}px`;
            } else if (viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min) {
                postThumbnailRef.current.style.height = fixedToViewport
                    ? `${POST_THUMBNAIL_HEIGHT_CONTRACTED}px`
                    : `${((POST_THUMBNAIL_HEIGHT_CONTRACTED - POST_THUMBNAIL_MAX_WIDTH_SMALL) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_MAX_WIDTH_SMALL}px`;
            } else {
                // viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                postThumbnailRef.current.style.height = fixedToViewport
                    ? `${POST_THUMBNAIL_HEIGHT_CONTRACTED}px`
                    : `${((POST_THUMBNAIL_HEIGHT_CONTRACTED - POST_THUMBNAIL_MAX_WIDTH_SMALL) / stickyBannerScrollThreshold) * scrollTop + POST_THUMBNAIL_MAX_WIDTH_SMALL}px`;
            }
        }

        // Contents Table
        if (contentsTableRef.current) {
            const contentsTableHeightExpanded = viewportDimensions.height - POST_BANNER_HEIGHT;
            const contentsTableHeightContracted = viewportDimensions.height - STICKY_POST_BANNER_HEIGHT;
            contentsTableRef.current.style.top = fixedToViewport
                ? `${CONTENTS_TABLE_TOP_CONTRACTED}px`
                : `${((CONTENTS_TABLE_TOP_CONTRACTED - CONTENTS_TABLE_TOP_EXPANDED) / stickyBannerScrollThreshold) * scrollTop + CONTENTS_TABLE_TOP_EXPANDED}px`;
            contentsTableRef.current.style.height = fixedToViewport
                ? `${contentsTableHeightContracted - 2 * CONTENTS_TABLE_MARGIN}px`
                : `${((contentsTableHeightContracted - contentsTableHeightExpanded) / stickyBannerScrollThreshold) * scrollTop + (contentsTableHeightExpanded - 2 * CONTENTS_TABLE_MARGIN)}px`;
        }

        // Contents Table Toggle Button Container
        if (contentsTableToggleButtonContainerRef.current) {
            const contentsTableToggleButtonContainerTopExpanded = POST_BANNER_HEIGHT + CONTENTS_TABLE_MARGIN;
            const contentsTableToggleButtonContainerTopContracted = STICKY_POST_BANNER_HEIGHT + CONTENTS_TABLE_MARGIN;
            if (viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min) {
                contentsTableToggleButtonContainerRef.current.style.top = fixedToViewport
                    ? `${contentsTableToggleButtonContainerTopContracted}px`
                    : `${((contentsTableToggleButtonContainerTopContracted - contentsTableToggleButtonContainerTopExpanded) / stickyBannerScrollThreshold) * scrollTop + contentsTableToggleButtonContainerTopExpanded}px`;
            } else {
                contentsTableToggleButtonContainerRef.current.style.top = fixedToViewport
                    ? `${contentsTableToggleButtonContainerTopContracted + CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_Y_OFFSET_SMALL_VIEWPORT}px`
                    : `${
                        ((contentsTableToggleButtonContainerTopContracted - contentsTableToggleButtonContainerTopExpanded) / stickyBannerScrollThreshold) * scrollTop
                        + contentsTableToggleButtonContainerTopExpanded
                        + CONTENTS_TABLE_TOGGLE_BUTTON_CONTAINER_Y_OFFSET_SMALL_VIEWPORT}px`;
            }
        }

        if (
            scrollTop >= stickyBannerScrollThreshold
            && !stickyPostBannerActive
        ) {
            setStickyPostBannerActive(true);
        } else if (
            scrollTop < stickyBannerScrollThreshold
            && stickyPostBannerActive
        ) {
            setStickyPostBannerActive(false);
        }

        // Post Banner
        // Must be placed after stickyPostBannerActive changes
        // If it is before, it's changes will cause scrollHeight to change
        // and the scroll event will be triggered again, reverting sticky banner
        if (postBannerRef.current) {
            postBannerRef.current.style.position = fixedToViewport ? 'fixed' : 'relative';
            postBannerRef.current.style.top = fixedToViewport ? '0px' : 'auto';
            postBannerRef.current.style.transform = fixedToViewport
                ? `translateY(calc(-100% + ${STICKY_POST_BANNER_HEIGHT}px))`
                : 'none';
        }

        // Contents Table Progress Bar
        if (
            contentsTableProgressBarRef.current
            && postContentContainerRef.current
        ) {
            contentsTableProgressBarRef.current.style.height = `${
                100 * (scrollTop / (postContentContainerRef.current.scrollHeight - postContentContainerRef.current.clientHeight))
            }%`;
        }

        // Reader Localizing Navigator
        if (readerLocalizingNavigatorRef.current) {
            readerLocalizingNavigatorRef.current.style.width = `${getLocalizerProgress(scrollTop)}%`;
        }

        // move the annotation editor if it is visible
        // we can infer visibility based on presence of highlight decoration
        const highlight = document.querySelector('[data-quote=\'post-quote\']');
        if (highlight) {
            let y = 0;
            let x = 0;
            if (
                containerRef.current
                && annotationEditorRef.current
            ) {
                // For read
                // We don't use Dropzone Ref
                const rect = highlight.getBoundingClientRect();
                const toolbarWidth = annotationEditorRef.current.children[0]
                    ? annotationEditorRef.current.children[0].clientWidth
                    : 0;
                y = Math.max(
                    (rect.top - containerRef.current.getBoundingClientRect().top
                        - (DEFAULT_ANNOTATION_EDITOR_HEIGHT + 7)
                    ),
                    0,
                );
                x = Math.max(
                    ((rect.left - containerRef.current.getBoundingClientRect().left + rect.width / 2)
                        - (toolbarWidth / 2)
                    ),
                    0,
                );
            } else if (
                rootRef.current
                && annotationEditorRef.current
            ) {
                // For write
                // We use Dropzone Ref
                const rect = highlight.getBoundingClientRect();
                const toolbarWidth = annotationEditorRef.current.children[0]
                    ? annotationEditorRef.current.children[0].clientWidth
                    : 0;
                y = (rect.top - rootRef.current.getBoundingClientRect().top
                    - (DEFAULT_ANNOTATION_EDITOR_HEIGHT + 7)
                );
                x = Math.max(
                    ((rect.left - rootRef.current.getBoundingClientRect().left + rect.width / 2)
                        - (toolbarWidth / 2)
                    ),
                    0,
                );
            }
            setAnnotationEditorPosition({
                x,
                y,
            });
        }
    };

    /**
     * Adjusts the resolution level of the post
     * @param level desired resolution level
     */
    const handleResolutionLevel = async (level: RESOLUTION_LEVEL): Promise<void> => {
        const auth = getAuth();
        if (!write && auth.currentUser?.isAnonymous) {
            setNotifyUserToSignUpToNavigateBook(true);

            // Play Sound
            if (hasSound && tooltipEnterClip.current) {
                tooltipEnterClip.current.pause();
                tooltipEnterClip.current.currentTime = 0;
                playAudio(tooltipEnterClip.current);
            }
            return;
        }

        setCurrentResolutionLevel({
            level,
            icon: adventureCharacters.get(level)!.icon,
        });

        // Set URL Params for resolution layer
        let resolutionLevel: number;
        switch (level) {
        case RESOLUTION_LEVEL.one:
            resolutionLevel = 1;
            break;
        case RESOLUTION_LEVEL.two:
            resolutionLevel = 2;
            break;
        case RESOLUTION_LEVEL.three:
            resolutionLevel = 3;
            break;
        case RESOLUTION_LEVEL.four:
            resolutionLevel = 4;
            break;
        case RESOLUTION_LEVEL.five:
        default:
            resolutionLevel = 5;
            break;
        }

        if (
            user
            && post
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.adjustPostResolution,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    currentResolution: resolutionLevel,
                    postId: post.id,
                },
            });

            // Update user resolution level preference
            if (user) {
                updateUserInDB({
                    userId: user.id,
                    resolution: level,
                });
            }
        }
    };

    /**
     * Manages response to toggling the resolution level button
     * @param visible reveal or hide the button
     */
    const handleToggleResolutionLevelButton = (visible: boolean): void => {
        // Play Sound
        if (
            visible
            && hasSound
            && swooshInClip.current
        ) {
            swooshInClip.current.pause();
            swooshInClip.current.currentTime = 0;
            playAudio(swooshInClip.current);
        } else if (
            !visible
            && hasSound
            && swooshOutClip.current
        ) {
            swooshOutClip.current.pause();
            swooshOutClip.current.currentTime = 0;
            playAudio(swooshOutClip.current);
        }
    };

    /**
     * Generates the contents table for the post based on the post's chapters and sections
     */
    const refreshContentsTable = (): void => {
        if (!post) return;

        let generatedContentsTable: IContentsChapterItem[] = [];
        if (post.chapters) {
            generatedContentsTable = post.chapters.map((chapter, chapterIndex) => {
                const expanded = (!!contentsTable && contentsTable[chapterIndex].expanded)
                    || (selectedPostValuePath.length === 2 && selectedPostValuePath[0] === chapterIndex);
                const chapterItem: IContentsChapterItem = {
                    id: chapter.id,
                    title: chapter.title,
                    description: chapter.description,
                    expanded,
                    annotationCount: chapter.annotations.length,
                };
                if (chapter.sections) {
                    chapterItem.sections = chapter.sections.map((section) => ({
                        id: section.id,
                        title: section.title,
                        annotationCount: section.annotations.length,
                    }));
                }
                return chapterItem;
            });
        }

        setContentsTable(generatedContentsTable);
    };

    const selectPostThumbnail = (e: React.MouseEvent): void => {
        if (!uploadingPostThumbnail) {
            e.stopPropagation();
            openPostThumbnailDropzone();
        } else {
            setSnackbarData({
                visible: true,
                duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_EXISTING_AVATAR_UPLOAD,
                icon: CautionIcon,
                hasFailure: true,
            });
        }
    };

    const handlePostThumbnailReplace = async (file: File): Promise<void> => {
        const mediaId = uuidv4();
        const uniqueId = new ShortUniqueId({ length: 6 })(); // avoid file name collisions
        const mediaBucket = getMediaStorageBucket(MEDIA_TYPE.image);
        const fileName = file.name.toLowerCase().split('.')[0].replace(' ', '_');
        const fileExtension = mime.extension(file.type);
        const storageEntity = process.env.NODE_ENV === 'production'
            ? STORAGE_ENTITY.posts
            : STORAGE_ENTITY.stagingPosts;
        const filePath = `${storageEntity}/${mediaBucket}/${mediaId}/${fileName}-${uniqueId}.${fileExtension}`;
        const mediaItem: IMediaItem = {
            id: mediaId,
            type: MEDIA_TYPE.image,
            userId: user!.id,
            file,
            filePath,
            uploadProgress: 0,
        };
        setUploadingPostThumbnail(mediaItem);

        // make db entry of media
        // execute before file upload so upload cloud function has a place to write to
        setMediaInDB({
            mediaItem,
            filePath,
        });

        // upload file to cloud storage
        // url will be set by cloud function
        const uploadTask = uploadToCloudStorage(
            file,
            filePath,
        );

        uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadingThumbnailStateChangeEvent({
                    mediaItem,
                    snapshot,
                    progress,
                });
            },
            (error: StorageError) => {
                throw Error(getStorageErrorMessage(error.code as STORAGE_ERROR_CODE));
            },
            () => {
                setUploadingThumbnailCompleteEvent({
                    mediaItem,
                    storageRef: uploadTask.snapshot.ref,
                });
            },
        );
    };

    /**
     * Manages progress update of an uploading post thumbnail
     * @param mediaItem thumbnail being uploaded
     * @param _snapshot upload task snapshot
     * @param progress perentage of upload completed
     */
    const handleUploadingPostThumbnailStateChange = (
        mediaItem: IMediaItem,
        _snapshot: UploadTaskSnapshot,
        progress: number,
    ): void => {
        if (uploadingPostThumbnail) {
            setUploadingPostThumbnail({
                ...uploadingPostThumbnail,
                uploadProgress: progress,
            });
        } else {
            setUploadingPostThumbnail(mediaItem);
        }
    };

    /**
     * Manages completion update of an uploading post thumbnail
     */
    const handleUploadingPostThumbnailComplete = (): void => {
        // Add post thumbnail file path to user item
        if (postId && uploadingPostThumbnail) {
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            updatePostInDB({
                collection: postsCollection,
                id: postId,
                imagePath: uploadingPostThumbnail.filePath,
            });
        }
        // Remove from uploading queue
        setUploadingPostThumbnail(null);
    };

    /**
     * Manages determining the number of resolution levels applied to the post
     */
    const determineNumberAppliedResolutionLevels = async (): Promise<void> => {
        if (
            !write
            && postId
            && !appliedResolutionLevelCount
        ) {
            const db = getFirestore();
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            const postRef = doc(db, postsCollection, postId);
            const postSnap = await getDoc(postRef);
            if (postSnap.exists()) {
                // Set Post Item
                const postData = postSnap.data() as IPostItem;
                if ('chapters' in postData) {
                    // Merge values of all sections in all chapters
                    const mergedPostValue = `[${postData.chapters!.reduce((value, chapter) => {
                        if (!chapter.sections) {
                            return `${value.length > 0 ? `${value},` : value}${chapter.value!.slice(1, -1)}`;
                        }
                        return `${value.length > 0 ? `${value},` : value}${chapter.sections!.map((section) => section.value?.slice(1, -1)).join(',')}`;
                    }, '')}]`;

                    // Find all resolution level elements
                    const searchToken = '"level":';
                    const startingIndices = [];
                    let indexOccurence = mergedPostValue.indexOf(searchToken, 0);
                    while (indexOccurence >= 0) {
                        startingIndices.push(indexOccurence);
                        indexOccurence = mergedPostValue.indexOf(searchToken, indexOccurence + 1);
                    }

                    // Determine number of unique resolution levels
                    const resolutionLevels = startingIndices.map((index) => {
                        const level = mergedPostValue.slice(index + searchToken.length, index + searchToken.length + 1);
                        return Number(level);
                    });
                    const numAppliedResolutionLevels = new Set(resolutionLevels).size;
                    setAppliedResolutionLevelCount(numAppliedResolutionLevels);
                } else {
                    const searchToken = '"level":';
                    const startingIndices = [];
                    let indexOccurence = postData.value!.indexOf(searchToken, 0);
                    while (indexOccurence >= 0) {
                        startingIndices.push(indexOccurence);
                        indexOccurence = postData.value!.indexOf(searchToken, indexOccurence + 1);
                    }

                    // Determine number of unique resolution levels
                    const resolutionLevels = startingIndices.map((index) => {
                        const level = postData.value!.slice(index + searchToken.length, index + searchToken.length + 1);
                        return Number(level);
                    });
                    const numAppliedResolutionLevels = new Set(resolutionLevels).size;
                    setAppliedResolutionLevelCount(numAppliedResolutionLevels);
                }
            }
        }
    };

    /**
     * Manages navigating to the previous appropriate page if present
     */
    const handleNavigatePreviousPage = (): void => {
        const auth = getAuth();
        if (!write && auth.currentUser?.isAnonymous) {
            setNotifyUserToSignUpToNavigateBook(true);

            // Play Sound
            if (hasSound && tooltipEnterClip.current) {
                tooltipEnterClip.current.pause();
                tooltipEnterClip.current.currentTime = 0;
                playAudio(tooltipEnterClip.current);
            }
            return;
        }

        if (
            post
            && post.chapters
            && post.chapters[selectedPostValuePath[0]]
            && post.chapters[selectedPostValuePath[0]].sections
            && selectedPostValuePath[1] - 1 >= 0
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
        ) {
            // Navigate to previous section from current section
            onChangeSelectedPostValuePath({
                path: [
                    selectedPostValuePath[0],
                    selectedPostValuePath[1] - 1,
                ],
                expandItems: [{
                    chapterIndex: selectedPostValuePath[0],
                    expand: true,
                }],
            });

            // Removes Cursor Lock caused by Small Cursor on Buttons
            onCursorLeave();
        } else if (
            post
            && post.chapters
            && selectedPostValuePath[0] - 1 >= 0
            && currentResolutionLevel
            && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
        ) {
            // Navigate to previous chapter from first section in current chapter or sectionless chapter
            const updatedSelectedPostValuePath = [selectedPostValuePath[0] - 1];
            if (
                post.chapters[updatedSelectedPostValuePath[0]].sections
                && post.chapters[updatedSelectedPostValuePath[0]].sections!.length > 0
            ) {
                updatedSelectedPostValuePath.push(post.chapters[updatedSelectedPostValuePath[0]].sections!.length - 1);
            }
            onChangeSelectedPostValuePath({
                path: updatedSelectedPostValuePath,
                expandItems: [
                    {
                        chapterIndex: updatedSelectedPostValuePath[0],
                        expand: true,
                    },
                    {
                        chapterIndex: selectedPostValuePath[0],
                        expand: false, // Contract current chapter
                    },
                ],
            });

            // Removes Cursor Lock caused by Small Cursor on Buttons
            onCursorLeave();
        }
    };

    /**
     * Manages navigating to the next appropriate page if present
     */
    const handleNavigateNextPage = (): void => {
        const auth = getAuth();
        if (!write && auth.currentUser?.isAnonymous) {
            setNotifyUserToSignUpToNavigateBook(true);

            // Play Sound
            if (hasSound && tooltipEnterClip.current) {
                tooltipEnterClip.current.pause();
                tooltipEnterClip.current.currentTime = 0;
                playAudio(tooltipEnterClip.current);
            }
            return;
        }

        if (
            post
            && post.chapters
            && post.chapters[selectedPostValuePath[0]]
            && post.chapters[selectedPostValuePath[0]].sections
            && post.chapters[selectedPostValuePath[0]].sections!.length > selectedPostValuePath[1] + 1
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
        ) {
            // Navigate to next section from current section
            onChangeSelectedPostValuePath({
                path: [
                    selectedPostValuePath[0],
                    selectedPostValuePath[1] + 1,
                ],
                expandItems: [{
                    chapterIndex: selectedPostValuePath[0],
                    expand: true,
                }],
            });

            // Removes Cursor Lock caused by Small Cursor on Buttons
            onCursorLeave();
        } else if (
            post
            && post.chapters
            && post.chapters.length > selectedPostValuePath[0] + 1
        ) {
            // Navigate to next chapter from last section in current chapter or sectionless chapter
            const updatedSelectedPostValuePath = [selectedPostValuePath[0] + 1];
            if (
                post.chapters[updatedSelectedPostValuePath[0]].sections
                && post.chapters[updatedSelectedPostValuePath[0]].sections!.length > 0
                && currentResolutionLevel
                && currentResolutionLevel.level === RESOLUTION_LEVEL.five
            ) {
                updatedSelectedPostValuePath.push(0);
            }
            onChangeSelectedPostValuePath({
                path: updatedSelectedPostValuePath,
                expandItems: [
                    {
                        chapterIndex: updatedSelectedPostValuePath[0],
                        expand: true,
                    },
                    {
                        chapterIndex: selectedPostValuePath[0],
                        expand: false, // Contract current chapter
                    },
                ],
            });

            // Removes Cursor Lock caused by Small Cursor on Buttons
            onCursorLeave();
        }
    };

    /**
     * Determines percentage of post completed
     * @returns percentage of post completed
     */
    const getLocalizerProgress = (scrollTop: number): number => {
        let totalPages = 1;
        let completedPages = 0;
        let currentPageProgress = 0; // A value between 0 and 1
        if (
            post
            && post.chapters
            && currentResolutionLevel
            && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
        ) {
            totalPages = post.chapters.reduce((total, chapter) => {
                if (
                    chapter.sections
                    && currentResolutionLevel
                    && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                ) {
                    return total + chapter.sections!.length;
                }
                return total + 1;
            }, 0);

            completedPages = post.chapters.reduce((total, chapter, chapterIndex) => {
                if (
                    chapter.sections
                    && currentResolutionLevel
                    && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                ) {
                    return total + chapter.sections!.reduce((sectionTotal, _section, sectionIndex) => {
                        if (
                            (
                                selectedPostValuePath.length === 1
                                && chapterIndex <= selectedPostValuePath[0]
                            ) || (
                                selectedPostValuePath.length === 2
                                && chapterIndex <= selectedPostValuePath[0]
                                && sectionIndex < selectedPostValuePath[1]
                            )
                        ) {
                            return sectionTotal + 1;
                        }
                        return sectionTotal;
                    }, 0);
                }

                if (
                    selectedPostValuePath.length > 0
                    && chapterIndex < selectedPostValuePath[0]
                ) {
                    return total + 1;
                }

                return total;
            }, 0);

            // Determine current page progress
            if (postContentContainerRef.current) {
                const scrollableHeight = postContentContainerRef.current.scrollHeight - postContentContainerRef.current.clientHeight;
                if (scrollableHeight === 0) {
                    currentPageProgress = 1;
                } else {
                    currentPageProgress = scrollTop / scrollableHeight;
                }
            }

            return Math.round(((completedPages + currentPageProgress) / totalPages) * 100);
        }

        // No chapters or sections
        // Return percentage of current page completed
        if (postContentContainerRef.current) {
            return 100 * (scrollTop / (postContentContainerRef.current.scrollHeight - postContentContainerRef.current.clientHeight));
        }

        return 0;
    };

    /**
     * Manages adding book to cart
     */
    const addBookToCart = async ({ type }: { type: BOOK_TYPE }): Promise<void> => {
        if (!user?.admin) {
            setSnackbarData({
                visible: true,
                duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                text: 'Item cannot be added to your cart. It has not been formally put on sale.',
                icon: CartIcon,
                hasFailure: true,
            });
            return;
        }
        // Determine whether to present sign up nudge message
        const auth = getAuth();
        if (
            auth.currentUser
            && auth.currentUser.isAnonymous
        ) {
            switch (type) {
            case BOOK_TYPE.physical:
                setNotifyUserToSignUpForPhysicalBook(true);
                break;
            case BOOK_TYPE.digital:
                setNotifyUserToSignUpForDigitalBook(true);
                break;
            default:
                // BOOK_TYPE.web
                setNotifyUserToSignUpForWebBook(true);
            }

            // Play Sound
            if (tooltipEnterClip.current) {
                tooltipEnterClip.current.pause();
                tooltipEnterClip.current.currentTime = 0;
                playAudio(tooltipEnterClip.current);
            }
            return;
        }

        // Add book to cart
        if (user) {
            const PHYSICAL_BOOK_PRICE_ID = process.env.NODE_ENV === 'development'
                ? PHYSICAL_BOOK_STRIPE_PRICE_TEST_API_ID
                : PHYSICAL_BOOK_STRIPE_PRICE_API_ID;
            const DIGITAL_BOOK_PRICE_ID = process.env.NODE_ENV === 'development'
                ? DIGITAL_BOOK_STRIPE_PRICE_TEST_API_ID
                : DIGITAL_BOOK_STRIPE_PRICE_API_ID;
            const WEB_BOOK_PRICE_ID = process.env.NODE_ENV === 'development'
                ? WEB_BOOK_STRIPE_PRICE_TEST_API_ID
                : WEB_BOOK_STRIPE_PRICE_API_ID;
            const stagedItemId = uuidv4();
            const cartMediaItem: IMediaItem = {
                id: stagedItemId,
                userId: user.id,
                file: new File([], type),
                // We set this to the ID of the cart navigation button
                // This is how it is located
                filePath: NAVIGATION_CART_BUTTON_ID,
                type: MEDIA_TYPE.image,
                uploadProgress: 100,
            };
            switch (type) {
            case BOOK_TYPE.physical:
                if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Includes physical book
                    // Increment quantity and remove existing cart item
                    let cartItem = user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)!;
                    cartItem = {
                        ...cartItem,
                        quantity: cartItem.quantity + 1,
                        updated: Date.now(),
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.filterOut,
                        operationArgument: PHYSICAL_BOOK_PRICE_ID,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingPhysicalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(PHYSICAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.physical);
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === DIGITAL_BOOK_PRICE_ID)
                    && !user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Includes digital book
                    // Remove and replace with physical book
                    const cartItem = {
                        priceId: PHYSICAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.filterOut,
                        operationArgument: DIGITAL_BOOK_PRICE_ID,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingPhysicalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(PHYSICAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.physical);
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_REPLACE_DIGITAL_BOOK_WITH_PHYSICAL_BOOK,
                        icon: CartIcon,
                    });
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === WEB_BOOK_PRICE_ID)
                    && !user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Includes digital book
                    // Remove and replace with physical book
                    const cartItem = {
                        priceId: PHYSICAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.filterOut,
                        operationArgument: WEB_BOOK_PRICE_ID,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingPhysicalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(PHYSICAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.physical);
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_REPLACE_WEB_BOOK_WITH_PHYSICAL_BOOK,
                        icon: CartIcon,
                    });
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Doesn't include physical book
                    const cartItem = {
                        priceId: PHYSICAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.none,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingPhysicalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(PHYSICAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.physical);
                } else {
                    // Cart is empty
                    const cartItem = {
                        priceId: PHYSICAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.none,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingPhysicalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(PHYSICAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.physical);
                }
                break;
            case BOOK_TYPE.digital:
                if (purchasedPhysicalBook) {
                    // Purchased physical book which includes a complimentary digital book.
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_DIGITAL_BOOK_TO_CART_WHEN_PURCHASE_PHYSICAL_BOOK_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (purchasedDigitalBook) {
                    // Already purchased digital book
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_REPURCHASE_DIGITAL_BOOK_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                ) {
                    // Physical book purchase includes a complimentary digital book.
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_DIGITAL_BOOK_TO_CART_WHEN_PHYSICAL_BOOK_ALREADY_IN_CART_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === DIGITAL_BOOK_PRICE_ID)
                ) {
                    // Cart isn't empty
                    // Includes digital book
                    // Digital item can only be purchased once
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_MULTIPLE_DIGITAL_BOOK_TO_CART_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === WEB_BOOK_PRICE_ID)
                    && !user.cart.find((item: ICartItem) => item.priceId === DIGITAL_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Includes web book
                    // Remove and replace with digital book
                    const cartItem = {
                        priceId: DIGITAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.filterOut,
                        operationArgument: WEB_BOOK_PRICE_ID,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingDigitalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(DIGITAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.digital);
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_REPLACE_WEB_BOOK_WITH_DIGITAL_BOOK,
                        icon: CartIcon,
                    });
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !user.cart.find((item: ICartItem) => item.priceId === DIGITAL_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Doesn't include digital book
                    const cartItem = {
                        priceId: DIGITAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.none,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingDigitalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(DIGITAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.digital);
                } else if (fetchedStoreItems) {
                    // Cart is empty
                    const cartItem = {
                        priceId: DIGITAL_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.none,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingDigitalBookCartItem(true);
                    const associatedStoreItem = storeItems.get(DIGITAL_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.digital);
                }
                break;
            default:
                // BOOK_TYPE.web
                if (purchasedPhysicalBook) {
                    // Purchased physical book which includes a complimentary web book.
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_PURCHASE_PHYSICAL_BOOK_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (purchasedDigitalBook) {
                    // Purchased digital book which includes a complimentary web book.
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_PURCHASE_DIGITAL_BOOK_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (purchasedWebBook) {
                    // Already purchased digital book
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_REPURCHASE_WEB_BOOK_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === PHYSICAL_BOOK_PRICE_ID)
                ) {
                    // Physical book purchase includes a complimentary web book.
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_PHYSICAL_BOOK_ALREADY_IN_CART_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === DIGITAL_BOOK_PRICE_ID)
                ) {
                    // Digital book purchase includes a complimentary web book.
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_WEB_BOOK_TO_CART_WHEN_DIGITAL_BOOK_ALREADY_IN_CART_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !!user.cart.find((item: ICartItem) => item.priceId === WEB_BOOK_PRICE_ID)
                ) {
                    // Cart isn't empty
                    // Includes web book
                    // Digital item can only be purchased once
                    setSnackbarData({
                        visible: true,
                        duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_ADD_MULTIPLE_WEB_BOOK_TO_CART_ERROR,
                        icon: CartIcon,
                        hasFailure: true,
                    });
                    break;
                } else if (
                    user.cart
                    && user.cart.length > 0
                    && !user.cart.find((item: ICartItem) => item.priceId === WEB_BOOK_PRICE_ID)
                    && fetchedStoreItems
                ) {
                    // Cart isn't empty
                    // Doesn't include web book
                    const cartItem = {
                        priceId: WEB_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.none,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingWebBookCartItem(true);
                    const associatedStoreItem = storeItems.get(WEB_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.web);
                } else if (fetchedStoreItems) {
                    // Cart is empty
                    const cartItem = {
                        priceId: WEB_BOOK_PRICE_ID,
                        timestamp: Date.now(),
                        updated: Date.now(),
                        quantity: 1,
                    };
                    const stagedCartItem = {
                        id: stagedItemId,
                        cartItem,
                        cartOperation: STAGED_CART_ITEM_CART_OPERATION.none,
                    };
                    setStagedCartItems(new Map([
                        ...Array.from(stagedCartItems.entries()),
                        [cartMediaItem.id, stagedCartItem],
                    ]));
                    setAnimatingWebBookCartItem(true);
                    const associatedStoreItem = storeItems.get(WEB_BOOK_PRICE_ID);
                    if (associatedStoreItem) {
                        [cartMediaItem.url] = associatedStoreItem.imageURLs;
                    }
                    addAnimatingCartItem(cartMediaItem, BOOK_TYPE.web);
                }
            }
        }

        if (
            user
            && post
            && currentSessionId
        ) {
            // Record user action
            switch (type) {
            case BOOK_TYPE.physical:
                recordUserAction({
                    type: USER_ACTION_TYPE.addItemToCart,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                        type: STORE_ITEM_TYPE.physicalBook,
                    },
                });
                break;
            case BOOK_TYPE.digital:
                recordUserAction({
                    type: USER_ACTION_TYPE.addItemToCart,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                        type: STORE_ITEM_TYPE.digitalBook,
                    },
                });
                break;
            default:
                // BOOK_TYPE.web
                recordUserAction({
                    type: USER_ACTION_TYPE.addItemToCart,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                        type: STORE_ITEM_TYPE.webBook,
                    },
                });
            }
        }
    };

    /**
     * Manages presenting annotation nudge message
     */
    const performAnnotationNudge = (): void => {
        if (user && user.annotations.length === 0) {
            setSnackbarData({
                visible: true,
                duration: EXTENDED_SNACKBAR_VISIBLE_DURATION,
                text: SNACKBAR_MESSAGE_ANNOTATION_NUDGE,
                icon: HighlightIcon,
                dismissable: true,
            });

            if (
                user
                && post
                && currentSessionId
            ) {
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.presentedAnnotationNudgeMessage,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                    },
                });
            }
        }
    };

    /**
     * Manages presenting purchase book nudge message
     */
    const performPurchaseBookNudge = (): void => {
        setNudgeUserToPurchaseBook(true);
        clearTimeoutHidePurchaseBookNudgeMessage();
        timeoutHidePurchaseBookNudgeMessage();

        // Play Sound
        if (tooltipEnterClip.current) {
            tooltipEnterClip.current.pause();
            tooltipEnterClip.current.currentTime = 0;
            playAudio(tooltipEnterClip.current);
        }

        if (
            user
            && post
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.presentedPurchaseBookNudgeMessage,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    postId: post.id,
                },
            });
        }
    };

    /**
     * Manages setting resolution level of post based on selection of character
     * @param character type of character selected
     */
    const handleSelectAdventureCharacter = (characterId: ADVENTURE_CHARACTER, icon: string): void => {
        let level: RESOLUTION_LEVEL;
        switch (characterId) {
        case ADVENTURE_CHARACTER.restlessMinor:
            level = RESOLUTION_LEVEL.one;
            break;
        case ADVENTURE_CHARACTER.busyInvestor:
            level = RESOLUTION_LEVEL.two;
            break;
        case ADVENTURE_CHARACTER.committedJournalist:
            level = RESOLUTION_LEVEL.three;
            break;
        case ADVENTURE_CHARACTER.analyticalPhilosopher:
            level = RESOLUTION_LEVEL.four;
            break;
        case ADVENTURE_CHARACTER.meticulousGeek:
        default:
            level = RESOLUTION_LEVEL.five;
        }

        setCurrentResolutionLevel({
            level,
            icon,
        });

        // Update user resolution level preference
        if (user) {
            updateUserInDB({
                userId: user.id,
                resolution: level,
            });
        }

        setCharacterModalIsVisible(false);
        setCheckedForCharacterModal(true);

        // Present tooltip to inform of capability to adjust resolution level
        clearTimeoutShowResolutionTooltip();
        timeoutShowResolutionTooltip();

        // Play Sound
        if (hasSound && successClip.current) {
            successClip.current.pause();
            successClip.current.currentTime = 0;
            playAudio(successClip.current);
        }

        if (
            user
            && currentSessionId
        ) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.selectAdventureCharacter,
                userId: user.id,
                sessionId: currentSessionId,
                payload: {
                    characterId,
                },
            });
        }
    };

    /**
     * Manages pausing and resuming logo animation
     * based on whether tab is focused
     */
    const handleVisibilityChange = (): void => {
        if (document.visibilityState === 'visible') {
            setStartOrResumeBrowsingTimestamp(Date.now());
            setBrowserTabFocused(true);
        } else {
            setCachedPostFocusedDuration(cachedPostFocusedDuration + (Date.now() - startOrResumeBrowsingTimestamp));
            setBrowserTabFocused(false);
        }
    };

    /**
     * Manages pausing and resuming logo animation
     * toggling sound on and off
     * based on whether tab or window is focused
     * @param focused whether tab or window is focused or blurred
     */
    const handleTabOrWindowChange = (focused: boolean): void => {
        if (focused) {
            setStartOrResumeBrowsingTimestamp(Date.now());
            setBrowserTabFocused(true);
            // Toggle Sound
            if (cachedHasSound !== null) {
                setHasSound(cachedHasSound);
                setCachedHasSound(null);
            }
        } else {
            setCachedPostFocusedDuration(cachedPostFocusedDuration + (Date.now() - startOrResumeBrowsingTimestamp));
            setBrowserTabFocused(false);
            // Toggle Sound
            setCachedHasSound(hasSound);
            setHasSound(false);
        }
    };

    /**
     * Manages recording user has read for some significant duration of time
     */
    const recordReadDuration = (duration: READ_DURATION_TYPE): void => {
        if (
            user
            && post
            && currentSessionId
        ) {
            switch (duration) {
            case READ_DURATION_TYPE.hour:
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.readOneHour,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                    },
                });
                break;
            case READ_DURATION_TYPE.thirty:
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.readThirtyMinutes,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                    },
                });
                break;
            case READ_DURATION_TYPE.ten:
                // Record user action
                recordUserAction({
                    type: USER_ACTION_TYPE.readTenMinutes,
                    userId: user.id,
                    sessionId: currentSessionId,
                    payload: {
                        postId: post.id,
                    },
                });
                break;
            default:
            }
        }
    };

    /**
     * Records user action of viewing read page
     */
    const recordReadPageUserAction = async (): Promise<void> => {
        if (user && currentSessionId) {
            // Record user action
            recordUserAction({
                type: USER_ACTION_TYPE.viewReadPage,
                userId: user.id,
                sessionId: currentSessionId,
            });
            setRecordedViewPageUserAction(true);
        }
    };

    // ===== Side Effects =====

    /**
     * Loads all page sound files into audio elements
     */
    useEffect(() => {
        if (
            resizeConstraintThumpClip.current
            && handlebarGraspClip.current
            && inputClickClip.current
            && swooshInClip.current
            && swooshOutClip.current
            && tooltipEnterClip.current
            && buttonHoverClip.current
            && successClip.current
        ) {
            // Handlebar Grasp
            handlebarGraspClip.current.volume = DEFAULT_AUDIO_VOLUME;
            handlebarGraspClip.current.src = ObjectGrasp;

            // Resize Constraint Thump
            resizeConstraintThumpClip.current.volume = DEFAULT_AUDIO_VOLUME;
            resizeConstraintThumpClip.current.src = ObjectThump;

            // Input Click
            inputClickClip.current.volume = DEFAULT_AUDIO_VOLUME;
            inputClickClip.current.src = InputClick;

            // Swoosh In
            swooshInClip.current.volume = DEFAULT_AUDIO_VOLUME;
            swooshInClip.current.src = SwooshIn;

            // Swoosh Out
            swooshOutClip.current.volume = DEFAULT_AUDIO_VOLUME;
            swooshOutClip.current.src = SwooshOut;

            // Tooltip Enter
            tooltipEnterClip.current.volume = DEFAULT_AUDIO_VOLUME;
            tooltipEnterClip.current.src = TooltipEnter;

            // Button Hover
            buttonHoverClip.current.volume = DEFAULT_AUDIO_VOLUME;
            buttonHoverClip.current.src = ButtonHover;

            // Select Adventure Character
            successClip.current.volume = DEFAULT_AUDIO_VOLUME;
            successClip.current.src = Success;
        }

        return function cleanup() {
            if (handlebarGraspClip.current) handlebarGraspClip.current.remove();
            if (resizeConstraintThumpClip.current) resizeConstraintThumpClip.current.remove();
            if (inputClickClip.current) inputClickClip.current.remove();
            if (swooshInClip.current) swooshInClip.current.remove();
            if (swooshOutClip.current) swooshOutClip.current.remove();
            if (tooltipEnterClip.current) tooltipEnterClip.current.remove();
            if (buttonHoverClip.current) buttonHoverClip.current.remove();
            if (successClip.current) successClip.current.remove();
        };
    }, []);

    /**
     * Fetches and saves store items
     */
    useEffect(() => {
        fetchStoreItems();
    }, []);

    /**
     * Triggers rerender upon initialization of root components
     */
    useEffect(() => {
        if (!initializationRerender) {
            setInitializationRerender(true);
        }
    }, [containerRef.current, rootRef.current]);

    /**
     * Restarts timer everytime page rerenders when page first loads
     * Keeps content from being presented
     */
    useEffect(() => {
        if (!pageLoadRendersCompleted) {
            clearTimeoutPageLoadRendersCompleted();
            timeoutPageLoadRendersCompleted();
        }
    }, [
        // Props
        write,
        hasSound,
        user,
        currentSessionId,
        viewportDimensions,
        notifyUserToSignUpToNavigateBook,
        snackbarIsVisible,
        // State
        postId,
        title,
        subtitle,
        post,
        annotations,
        annotationDetailData,
        postEditor,
        annotationEditor,
        postQuote,
        listenForPostChanges,
        postValueIsSaving,
        initializationRerender,
        uploadingMedia,
        annotationEditorPosition,
        annotationViewWidth,
        isSteering,
        handlebarHovered,
        hitDetailMinimum,
        hitPostMinimum,
        annotationHighlightsRendered,
        stickyPostBannerActive,
        targetAnnotationID,
        remeasurePostHeight,
        fetchedPost,
        postImageURL,
        postImagePath,
        stickyBannerScrollThreshold,
        postAuthorAvatarURLs,
        postAuthors,
        contentsTable,
        selectedPostValuePath,
        currentResolutionLevel,
        queueSelectedPostValuePathChange,
        uploadingPostThumbnail,
        uploadingThumbnailStateChangeEvent,
        uploadingThumbnailCompleteEvent,
        appliedResolutionLevelCount,
        nudgeUserToPurchaseBook,
        initiatedAnnotationNudgeRoutine,
        initiatedPurchaseBookNudgeRoutine,
        characterModalIsVisible,
        checkedForCharacterModal,
        showResolutionTooltip,
        presentedResolutionTooltip,
        browserTabFocused,
        cachedPostFocusedDuration,
        initiatedReadingTimers,
        recordedViewPageUserAction,
    ]);

    /**
     * Manages page title changes
     */
    useEffect(() => {
        updatePageTitle(
            write ? 'Write' : 'Read',
            title.length > 0 ? title : DEFAULT_POST_TITLE,
        );
    }, [title]);

    /**
     * Records View Read Page User Action
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !recordedViewPageUserAction
        ) {
            recordReadPageUserAction();
        }
    }, [
        user,
        currentSessionId,
    ]);

    /**
     * Manages navigating to specified chapter and/or section
     * and particular resolution level if present
     */
    useEffect(() => {
        // Parse search params and apply settings

        // Apply resolution level
        let resolution: number | null = null;
        if (searchParams.has(READER_PARAMS_TYPE.resolution)) {
            resolution = Number(searchParams.get(READER_PARAMS_TYPE.resolution));
            switch (resolution) {
            case 1:
                handleResolutionLevel(RESOLUTION_LEVEL.one);
                break;
            case 2:
                handleResolutionLevel(RESOLUTION_LEVEL.two);
                break;
            case 3:
                handleResolutionLevel(RESOLUTION_LEVEL.three);
                break;
            case 4:
                handleResolutionLevel(RESOLUTION_LEVEL.four);
                break;
            case 5:
                handleResolutionLevel(RESOLUTION_LEVEL.five);
                break;
            default:
                break;
            }
        }

        // Apply chapter and section search parameters to URL
        if (
            searchParams.has(READER_PARAMS_TYPE.chapter)
            && (
                resolution === null
                || resolution >= 4
            )
        ) {
            const chapter = Number(searchParams.get(READER_PARAMS_TYPE.chapter));
            if (chapter) {
                if (
                    searchParams.has(READER_PARAMS_TYPE.section)
                    && (
                        resolution === null
                        || resolution === 5
                    )
                ) {
                    const section = Number(searchParams.get(READER_PARAMS_TYPE.section));
                    if (section) {
                        onChangeSelectedPostValuePath({
                            path: [chapter - 1, section - 1],
                            expandItems: [{
                                chapterIndex: chapter - 1,
                                expand: true,
                            }],
                        });
                    }
                } else {
                    onChangeSelectedPostValuePath({ path: [chapter - 1] });
                }
            }
        }
    }, []);

    /**
     * Manages triggering timeout to present annotation nudge
     */
    useEffect(() => {
        if (
            !!user
            && post
            && user.annotations.length === 0
            && !write
            && !initiatedAnnotationNudgeRoutine
        ) {
            timeoutAnnotationNudge();
        }

        if (
            !!user
            && post
            && !initiatedAnnotationNudgeRoutine
        ) {
            setInitiatedAnnotationNudgeRoutine(true);
        }
    }, [
        user,
        post,
    ]);

    /**
     * Manages triggering timeout to present purchase book nudge
     */
    useEffect(() => {
        if (
            !!user
            && post
            && checkedBookPurchases
            && !purchasedWebBook
            && !purchasedDigitalBook
            && !purchasedPhysicalBook
            && !write
            && !initiatedPurchaseBookNudgeRoutine
        ) {
            timeoutPurchaseBookNudge();
        }

        if (
            !!user
            && post
            && !initiatedPurchaseBookNudgeRoutine
        ) {
            setInitiatedPurchaseBookNudgeRoutine(true);
        }
    }, [
        user,
        post,
        checkedBookPurchases,
    ]);

    /**
     * Manages timers for recording significant reading durations
     */
    useEffect(() => {
        if (
            !!user
            && post
            && !initiatedReadingTimers
            && !write
        ) {
            timeoutReadTenMinutes();
            timeoutReadThirtyMinutes();
            timeoutReadOneHour();
            setInitiatedReadingTimers(true);
        }
    }, [
        user,
        post,
        initiatedReadingTimers,
    ]);

    /**
     * Fetches post once user and current session data received
     */
    useEffect(() => {
        if (
            user
            && currentSessionId
            && !fetchedPost
            && checkedForCharacterModal
        ) {
            setFetchedPost(true);
            fetchPost();
        }
    }, [
        user,
        currentSessionId,
        checkedForCharacterModal,
    ]);

    /**
     * Sets post title and subtitle
     */
    useEffect(() => {
        if (post) {
            if (post.title !== title) {
                setTitle(post.title);
            }

            if (post.subtitle !== subtitle) {
                setSubtitle(post.subtitle);
            }
        }
    }, [post]);

    /**
     * When in reading mode, generates contents table of post
     */
    useEffect(() => {
        if (!write && post && !contentsTable) {
            refreshContentsTable();
        }
    }, [post]);

    /**
     * Determines the number of resolution levels in post
     */
    useEffect(() => {
        determineNumberAppliedResolutionLevels();
    }, []);

    /**
     * Sets post value path to first chapter and/or section
     * if post has chapters and/or sections and annotation hasn't been focused
     */
    useEffect(() => {
        if (
            post
            && !params.annotationId
            && post.chapters
            && post.chapters.length > 0
            && !post.chapters[0].sections
            && selectedPostValuePath.length === 0
            && currentResolutionLevel
            && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
        ) {
            onChangeSelectedPostValuePath({ path: [0] });
        } else if (
            post
            && !params.annotationId
            && post.chapters
            && post.chapters.length > 0
            && post.chapters[0].sections
            && post.chapters[0].sections.length > 0
            && selectedPostValuePath.length === 0
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
        ) {
            // Refresh contents table
            // So that we can expand current chapter
            if (!write) refreshContentsTable();

            // Set flag to change selected post value path once contents table is refreshed
            setQueueSelectedPostValuePathChange([0, 0]);
        }
    }, [
        post,
        params,
        // We leave out currentResolutionLevel because
        // it is present before post generally and we don't
        // want side effect to run whenever resolution level
        // changes
    ]);

    /**
     * Sets post value path when resolution level is changed
     */
    useEffect(() => {
        if (!currentResolutionLevel) return;
        let newSearchParams = searchParams;
        if (
            currentResolutionLevel.level === RESOLUTION_LEVEL.five
            && selectedPostValuePath.length === 1
            && post
            && post.chapters
            && post.chapters[selectedPostValuePath[0]]
            && post.chapters[selectedPostValuePath[0]].sections
        ) {
            // We have switched from a lower resolution level to resolution level 5
            // which is a chapter with sections.
            // We need to set the selectedPostValuePath to the first section of the chapter.
            onChangeSelectedPostValuePath({
                path: [selectedPostValuePath[0], 0],
            });
        } else if (
            currentResolutionLevel.level === RESOLUTION_LEVEL.five
            && selectedPostValuePath.length > 0
        ) {
            newSearchParams = new URLSearchParams([
                ...(Array.from(newSearchParams).filter(([param]) => param !== READER_PARAMS_TYPE.resolution.toString())),
                [READER_PARAMS_TYPE.resolution.toString(), currentResolutionLevel.level.toString()],
            ]);
            navigate(
                `/${PAGE_ROUTE.book}${params.annotationId ? `/${params.annotationId}` : ''}?${newSearchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        } else if (
            currentResolutionLevel.level === RESOLUTION_LEVEL.four
            && selectedPostValuePath.length > 1
        ) {
            // We have switched from a resolution level 5 to resolution level 4
            // which is a chapter without sections.
            // We need to set the selectedPostValuePath to the chapter.
            onChangeSelectedPostValuePath({
                path: [selectedPostValuePath[0]],
            });
        } else if (
            currentResolutionLevel.level === RESOLUTION_LEVEL.four
            && selectedPostValuePath.length <= 1
        ) {
            newSearchParams = new URLSearchParams([
                ...(Array.from(newSearchParams).filter(([param]) => param !== READER_PARAMS_TYPE.resolution.toString()
                    && param !== READER_PARAMS_TYPE.section.toString())
                ),
                [READER_PARAMS_TYPE.resolution.toString(), currentResolutionLevel.level.toString()],
            ]);
            navigate(
                `/${PAGE_ROUTE.book}${params.annotationId ? `/${params.annotationId}` : ''}?${newSearchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        } else if (selectedPostValuePath.length > 0) {
            // We have switched from a resolution level greater than three to resolution level 3 or below
            // which is a post without chapters.
            // We need to set the selectedPostValuePath to the post.
            onChangeSelectedPostValuePath({
                path: [],
            });
        } else {
            newSearchParams = new URLSearchParams([
                ...(Array.from(newSearchParams).filter(([param]) => param !== READER_PARAMS_TYPE.resolution.toString())),
                [READER_PARAMS_TYPE.resolution.toString(), currentResolutionLevel.level.toString()],
            ]);
            navigate(
                `/${PAGE_ROUTE.book}${params.annotationId ? `/${params.annotationId}` : ''}?${newSearchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        }
    }, [
        // Set after currentResolutionLevel sometimes
        // but necessary to test for first conditional
        post,
        currentResolutionLevel,
    ]);

    /**
     * Sets post value path to the chapter and section of a focused annotation
     */
    useEffect(() => {
        if (
            params.annotationId
            && annotations.size > 0
            && post?.chapters
            && !searchParams.has(READER_PARAMS_TYPE.chapter)
            && !searchParams.has(READER_PARAMS_TYPE.section)
        ) {
            const annotation = annotations.get(params.annotationId);
            if (annotation) {
                // Find which chapter and section the annotation belongs to
                for (let i = 0; i < post.chapters.length; i += 1) {
                    const chapter = post.chapters[i];
                    if (
                        chapter.annotations.includes(annotation.id)
                        && currentResolutionLevel
                        && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
                    ) {
                        const chapterIndex = i;
                        if (chapter.sections) {
                            for (let j = 0; chapter.sections.length; j += 1) {
                                const section = chapter.sections[j];
                                if (
                                    section.annotations.includes(annotation.id)
                                    && currentResolutionLevel.level === RESOLUTION_LEVEL.five
                                ) {
                                    const sectionIndex = j;
                                    onChangeSelectedPostValuePath({
                                        path: [chapterIndex, sectionIndex],
                                        expandItems: [{
                                            chapterIndex,
                                            expand: true,
                                        }],
                                    });
                                    break;
                                }
                            }
                        } else {
                            onChangeSelectedPostValuePath({ path: [chapterIndex] });
                            break;
                        }
                    }
                }
            }
        }
    }, [
        params,
        annotations,
        currentResolutionLevel,
    ]);

    /**
     * Sets post value path to a queued chapter and section
     * upon initial contents table generation or write mode
     */
    useEffect(() => {
        if (
            (
                contentsTable
                || write
            ) && queueSelectedPostValuePathChange
        ) {
            onChangeSelectedPostValuePath({
                path: queueSelectedPostValuePathChange,
                expandItems: [{
                    chapterIndex: queueSelectedPostValuePathChange[0],
                    expand: true,
                }],
            });
            setQueueSelectedPostValuePathChange(null);
        }
    }, [
        contentsTable,
        queueSelectedPostValuePathChange,
    ]);

    /**
     * Sets post resolution level to user's preferred level if annotation not focused
     * Otherwise, set resolution level to level 5.
     */
    useEffect(() => {
        if (
            user
            && !params.annotationId
            && !currentResolutionLevel
            && !write
        ) {
            // We only want to automatically set user resolution level when in read mode
            setCurrentResolutionLevel({
                level: user.resolution || RESOLUTION_LEVEL.five,
                icon: adventureCharacters.get(user.resolution || RESOLUTION_LEVEL.five)!.icon,
            });

            // Update URL with resolution level
            let newSearchParams = searchParams;
            newSearchParams = new URLSearchParams([
                ...(Array.from(newSearchParams).filter(([key]) => key !== READER_PARAMS_TYPE.resolution.toString())),
                [READER_PARAMS_TYPE.resolution.toString(), (user.resolution || RESOLUTION_LEVEL.five).toString()],
            ]);
            navigate(
                `/${PAGE_ROUTE.book}${params.annotationId ? `/${params.annotationId}` : ''}?${newSearchParams.toString()}`,
                {
                    state: location.state,
                },
            );
        } else if (
            (
                params.annotationId
                || write
            ) && !currentResolutionLevel
        ) {
            setCurrentResolutionLevel({
                level: RESOLUTION_LEVEL.five,
                icon: adventureCharacters.get(RESOLUTION_LEVEL.five)!.icon,
            });
        }
    }, [
        user,
        params,
    ]);

    /**
     * Manages presenting Character Selector if anonymous user
     */
    useEffect(() => {
        const auth = getAuth();
        if (
            user
            && auth.currentUser
            && (auth.currentUser.isAnonymous || user.admin)
            && !characterModalIsVisible
            && !checkedForCharacterModal
            && !write
            && appliedResolutionLevelCount !== null
            && appliedResolutionLevelCount > 0
            && location.state
            && (location.state as { prevPath: string}).prevPath === `/${PAGE_ROUTE.landing}`
        ) {
            setCharacterModalIsVisible(true);
        } else if (
            user
            && !checkedForCharacterModal
            && (
                write
                || !location.state
                || (location.state as { prevPath: string}).prevPath !== `/${PAGE_ROUTE.landing}`
                || (auth.currentUser && !auth.currentUser.isAnonymous)
                || (appliedResolutionLevelCount && appliedResolutionLevelCount === 0)
            )
        ) {
            setCheckedForCharacterModal(true);
        }
    }, [
        user,
        appliedResolutionLevelCount,
    ]);

    /**
     * Fetches user items of all post authors
     */
    useEffect(() => {
        if (
            post
            && postAuthors.length === 0
        ) {
            const db = getFirestore();
            const usersCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.users
                : FIRESTORE_COLLECTION.stagingUsers;
            const authorPromises: Promise<DocumentSnapshot<DocumentData>>[] = [];
            for (let i = 0; i < post.authors.length; i += 1) {
                const userId = post.authors[i];
                authorPromises.push(getDoc(doc(db, usersCollection, userId)));
            }
            Promise.all(authorPromises).then((authors) => {
                setPostAuthors([
                    ...(authors
                        .map((authorSnap) => authorSnap.data() as IUserItem)
                        .filter((author) => !!author)
                    ),
                ]);
            });
        }
    }, [post]);

    /**
     * Fetches avater URLs of all post authors
     */
    useEffect(() => {
        if (postAuthors.length > 0) {
            const authorAvatarURLs: string[] = [];
            const postAuthorsPromises = [];
            for (let i = 0; i < postAuthors.length; i += 1) {
                const postAuthor = postAuthors[i];
                if (postAuthor.avatarFilePath) {
                    const storage = getStorage();
                    const pathParts = postAuthor.avatarFilePath!.split('.');
                    const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
                    postAuthorsPromises.push(getDownloadURL(ref(storage, mediumPath)));
                }
            }

            Promise.all(postAuthorsPromises).then((imageURLs) => {
                authorAvatarURLs.push(...imageURLs);
                setPostAuthorAvatarURLs(authorAvatarURLs);
            });
        }
    }, [postAuthors]);

    /**
     * Listens for changes to post data in Firestore
     */
    useEffect(() => {
        let unsubscribe: Unsubscribe;
        if (postId && listenForPostChanges) {
            const db = getFirestore();
            const postsCollection = process.env.NODE_ENV === 'production'
                ? FIRESTORE_COLLECTION.posts
                : FIRESTORE_COLLECTION.stagingPosts;
            unsubscribe = onSnapshot(doc(db, postsCollection, postId), (postDoc) => {
                if (postDoc.exists()) {
                    const postData: IPostItem | undefined = postDoc.data() as IPostItem;
                    if (postData) {
                        // We update editor value state but we treat the local version as the source of truth
                        // Therefore, we do not expect changes made in Firestore to be integrated, at least
                        // while someone is editing the post locally.
                        //
                        // We only update this because it holds various metadata changes we need
                        // to read, such as the "last updated" value.
                        setPost(postData as IPostItem);
                    }
                }
            });
        }

        return function cleanup() {
            if (unsubscribe) unsubscribe();
        };
    }, [listenForPostChanges]);

    /**
     * Listens for changes to annotations in Firestore
     */
    useEffect(() => {
        if (!postId) return;
        if (
            !!post?.chapters
            && selectedPostValuePath.length === 0
            && currentResolutionLevel
            && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
        ) return;

        // Initialize DB
        const db = getFirestore();

        const annotationCollection = process.env.NODE_ENV === 'production'
            ? FIRESTORE_COLLECTION.annotations
            : FIRESTORE_COLLECTION.stagingAnnotations;
        const annotationQuery = query(
            collection(db, annotationCollection),
            where('postId', '==', postId),
            where('deleted.deleted', '==', false),
        );
        const unsubscribe = onSnapshot(annotationQuery, (querySnapshot) => {
            const map: Map<string, IAnnotationItem> = new Map();
            const pageAnnotationsMap: Map<string, boolean> = new Map();

            if (
                post
                && selectedPostValuePath.length === 1
                && 'chapters' in post
                && post.chapters![selectedPostValuePath[0]]
                && currentResolutionLevel
                && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
            ) {
                // Generate map of annotations in post chapter
                // This is used to test for membership in the chapter
                post.chapters![selectedPostValuePath[0]]
                    .annotations.forEach((annotationId) => {
                        pageAnnotationsMap.set(annotationId, true);
                    });
            } else if (
                post
                && selectedPostValuePath.length === 2
                && 'chapters' in post
                && post.chapters![selectedPostValuePath[0]]
                && post.chapters![selectedPostValuePath[0]].sections
                && post.chapters![selectedPostValuePath[0]].sections![selectedPostValuePath[1]]
                && currentResolutionLevel
                && currentResolutionLevel.level === RESOLUTION_LEVEL.five
            ) {
                // Generate map of annotations in post section
                // This is used to test for membership in the section
                post.chapters![selectedPostValuePath[0]]
                    .sections![selectedPostValuePath[1]]
                    .annotations.forEach((annotationId) => {
                        pageAnnotationsMap.set(annotationId, true);
                    });
            }

            querySnapshot.forEach((document) => {
                if (document.exists()) {
                    const annotation = document.data() as IAnnotationItem;
                    // Add annotation to annotation map
                    if (
                        selectedPostValuePath.length === 0
                        || (
                            currentResolutionLevel
                            && currentResolutionLevel.level < RESOLUTION_LEVEL.four
                        )
                    ) {
                        map.set(document.id, annotation);
                    } else if (pageAnnotationsMap.has(document.id)) {
                        // Get annotations in post chapter or section
                        map.set(document.id, annotation);
                    }
                }
            });

            setAnnotations(map);

            // Refresh contents table
            if (!write) refreshContentsTable();
        });

        return function cleanup() {
            unsubscribe();
        };
    }, [
        post?.chapters,
        // Necessary to rerender when anonymous user is deleted
        // and annotations replaced with new authenticated user id
        user,
        selectedPostValuePath,
        currentResolutionLevel,
    ]);

    /**
     * Manages fetching URL for Post Image
     */
    useEffect(() => {
        if (
            post?.imagePath
            && post.imagePath !== postImagePath
        ) {
            if (postImageURL) {
                // Make sure to revoke the data uris to avoid memory leaks
                // Set when we use temporary URL from blob
                URL.revokeObjectURL(postImageURL);
            }
            setPostImagePath(post.imagePath);
            const storage = getStorage();
            const pathParts = post.imagePath.split('.');
            const mediumPath = `${pathParts[0]}_medium.${pathParts[1]}`;
            getDownloadURL(ref(storage, mediumPath)).then((imageURL) => {
                setPostImageURL(imageURL);
            }).catch((error) => {
                // We assume cloud function has not yet generated medium image yet
                if (error.code === STORAGE_ERROR_CODE.objectNotFound) {
                    getDownloadURL(ref(storage, post.imagePath)).then((imageURL) => {
                        setPostImageURL(imageURL);
                    }).catch(() => {
                        setSnackbarData({
                            visible: true,
                            duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                            text: SNACKBAR_MESSAGE_FETCH_IMAGE_ERROR,
                            icon: CautionIcon,
                            hasFailure: true,
                        });
                    });
                } else {
                    setSnackbarData({
                        visible: true,
                        duration: DEFAULT_SNACKBAR_VISIBLE_DURATION,
                        text: SNACKBAR_MESSAGE_FETCH_IMAGE_ERROR,
                        icon: CautionIcon,
                        hasFailure: true,
                    });
                }
            });
        }
    }, [post?.imagePath]);

    /**
     * Manages checking whether user has bought a book in the past
     */
    useEffect(() => {
        if (
            user
            && !checkedBookPurchases
        ) {
            determineBookPurchases();
        }
    }, [
        user,
        checkedBookPurchases,
    ]);

    /**
     * Set post banner scroll threshold once post banner height is known
     */
    useEffect(() => {
        if (postBannerRef.current) {
            setStickyBannerScrollThreshold(postBannerRef.current.clientHeight - STICKY_POST_BANNER_HEIGHT);
        }
    }, [
        postBannerRef.current,
        viewportDimensions.width,
    ]);

    /**
     * Manages updating scroll top upon scrolling
     */
    useLayoutEffect(() => {
        if (postContentContainerRef.current) {
            postContentContainerRef.current.addEventListener('scroll', trackPostScrollTop);
        }

        return () => {
            if (postContentContainerRef.current) {
                postContentContainerRef.current.removeEventListener('scroll', trackPostScrollTop);
            }
        };
    }, [
        postContentContainerRef.current,
        stickyBannerScrollThreshold,
        containerRef.current,
        annotationEditorRef.current,
        rootRef.current,
        contentsTableProgressBarRef.current,
        readerLocalizingNavigatorRef.current,
        postBannerRef.current,
        postBannerLeftContainerRef.current,
        postThumbnailRef.current,
        contentsTableRef.current,
        contentsTableProgressBarContainerRef.current,
        contentsTableToggleButtonContainerRef.current,
        // must be present to update state value in method when sticky banner is active
        stickyPostBannerActive,
        selectedPostValuePath,
    ]);

    /**
     * Remeasures post height so annotation container matches height
     */
    useEffect(() => {
        if (remeasurePostHeight) {
            setRemeasurePostHeight(false);
        }
    }, [remeasurePostHeight]);

    /**
     * Present annotation detail if small screen and annotation selected
     */
    useEffect(() => {
        if (
            viewportDimensions.width < MEDIA_QUERY_SIZE.small.min
            && params.annotationId
            && annotations.size > 0
            && !annotationDetailData
        ) {
            const annotation = annotations.get(params.annotationId);
            if (annotation) onShowAnnotationDetail(annotation);
        }
    }, [
        params,
        annotations,
        viewportDimensions.width,
        annotationHighlightsRendered,
    ]);

    /**
     * Scroll to highlight and annotation if annotation selected
     */
    useEffect(() => {
        if (
            annotationHighlightsRendered
            && params.annotationId
        ) {
            const highlightElement: HTMLElement | null = document
                .querySelector(`[data-${ANNOTATION_DECORATION_PREFIX}${params.annotationId}='${params.annotationId}']`);
            if (highlightElement) {
                highlightElement.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'nearest',
                    block: 'center',
                });
            }
        }
    }, [
        params,
        annotationHighlightsRendered,
    ]);

    /**
     * Manages triggering snackbar messages upon Dropzone events
     */
    useEffect(() => {
        if (
            write
            && isDragAccept
        ) {
            // Show file accept
            setSnackbarData({
                visible: true,
                text: SNACKBAR_MESSAGE_FILE_ACCEPT(acceptedFiles.length > 1),
                icon: UploadIcon,
                hasSuccess: true,
            });
        } else if (
            write
            && isDragReject
        ) {
            // Show file reject
            setSnackbarData({
                visible: true,
                text: SNACKBAR_MESSAGE_FILE_REJECT(acceptedFiles.length > 1),
                icon: CrossIcon,
                hasFailure: true,
            });
        } else {
            // Hide snackbar
            setSnackbarData({
                visible: false,
                text: '',
            });
        }
    }, [
        write,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    ]);

    /**
     * Processes progress updates for uploading post thumbnail
     */
    useEffect(() => {
        if (uploadingThumbnailStateChangeEvent) {
            handleUploadingPostThumbnailStateChange(
                uploadingThumbnailStateChangeEvent.mediaItem,
                uploadingThumbnailStateChangeEvent.snapshot,
                uploadingThumbnailStateChangeEvent.progress,
            );
        }
    }, [uploadingThumbnailStateChangeEvent]);

    /**
     * Processes completion of uploading post thumbnail
     */
    useEffect(() => {
        if (uploadingThumbnailCompleteEvent) {
            handleUploadingPostThumbnailComplete();
        }
    }, [uploadingThumbnailCompleteEvent]);

    /**
     * Manages event listener that tracks on focus and on blur events of window
     * Executes if change *tab* or *window* focus
     */
    useEffect(() => {
        window.onfocus = () => handleTabOrWindowChange(true);
        window.onblur = () => handleTabOrWindowChange(false);
    }, [
        cachedHasSound,
        cachedPostFocusedDuration,
        startOrResumeBrowsingTimestamp,
    ]);

    /**
     * Manages event listener that tracks on focus and on blur events of window
     * Prevent changes page if in another *tab*
     */
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange, false);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, [
        cachedPostFocusedDuration,
        startOrResumeBrowsingTimestamp,
    ]);

    /**
     * Manages setting flag indicating page load renders have completed
     */
    const {
        start: timeoutPageLoadRendersCompleted,
        clear: clearTimeoutPageLoadRendersCompleted,
    } = useTimeout(() => {
        if (!pageLoadRendersCompleted) setPageLoadRendersCompleted(true);
    }, PAGE_LOAD__RENDERS_COMPLETED_TIMEOUT_DURATION);

    /**
     * Manages showing resolution tooltip for first-time users
     */
    const {
        start: timeoutShowResolutionTooltip,
        clear: clearTimeoutShowResolutionTooltip,
    } = useTimeout(() => {
        setShowResolutionTooltip(true);
        // Reveal resolutions
        if (resolutionLevelButtonRef.current) {
            resolutionLevelButtonRef.current.click();
        }
        clearTimeoutHideResolutionTooltip();
        timeoutHideResolutionTooltip();
        // Play Sound
        if (tooltipEnterClip.current) {
            tooltipEnterClip.current.pause();
            tooltipEnterClip.current.currentTime = 0;
            playAudio(tooltipEnterClip.current);
        }
    }, SHOW_RESOLUTION_TOOLTIP_DURATION);

    /**
     * Manages hiding resolution tooltip for first-time users
     */
    const {
        start: timeoutHideResolutionTooltip,
        clear: clearTimeoutHideResolutionTooltip,
    } = useTimeout(() => {
        setShowResolutionTooltip(false);
        setPresentedResolutionTooltip(true);
    }, HIDE_RESOLUTION_TOOLTIP_DURATION);

    /**
     * Manages determining if annotation nudge should be presented
     */
    const {
        start: timeoutAnnotationNudge,
        clear: clearTimeoutAnnotationNudge,
    } = useTimeout(() => {
        performAnnotationNudge();
    }, browserTabFocused ? ANNOTATION_NUDGE_THRESHOLD_DURATION - cachedPostFocusedDuration : null);

    /**
     * Manages determining if get purchase book nudge should be presented
     */
    const {
        start: timeoutPurchaseBookNudge,
    } = useTimeout(() => {
        performPurchaseBookNudge();
    }, browserTabFocused ? PURCHASE_BOOK_NUDGE_THRESHOLD_DURATION - cachedPostFocusedDuration : null);

    /**
     * Manages hiding purchase book nudge message
     */
    const {
        start: timeoutHidePurchaseBookNudgeMessage,
        clear: clearTimeoutHidePurchaseBookNudgeMessage,
    } = useTimeout(() => {
        setNudgeUserToPurchaseBook(false);
    }, HIDE_PURCHASE_BOOK_NUDGE_TIMEOUT_DURATION);

    /**
     * Manages recording user has read for ten minutes
     */
    const {
        start: timeoutReadTenMinutes,
    } = useTimeout(() => {
        recordReadDuration(READ_DURATION_TYPE.ten);
    }, browserTabFocused ? TEN_MINUTES_DURATION - cachedPostFocusedDuration : null);

    /**
     * Manages recording user has read for ten minutes
     */
    const {
        start: timeoutReadThirtyMinutes,
    } = useTimeout(() => {
        recordReadDuration(READ_DURATION_TYPE.thirty);
    }, browserTabFocused ? THIRTY_MINUTES_DURATION - cachedPostFocusedDuration : null);

    /**
     * Manages recording user has read for ten minutes
     */
    const {
        start: timeoutReadOneHour,
    } = useTimeout(() => {
        recordReadDuration(READ_DURATION_TYPE.hour);
    }, browserTabFocused ? HOUR_DURATION - cachedPostFocusedDuration : null);

    // ===== Memoization =====

    const resizeableMaxConstraintsWidth = useMemo(() => (
        viewportDimensions.width - MIN_POST_SECTION_WIDTH
    ), [
        viewportDimensions,
    ]);

    const customSheetSnapPoint = useMemo(() => (
        viewportDimensions.height - DETAIL_SHEET_HEIGHT_REDUCTION
    ), [
        viewportDimensions,
    ]);

    const showDetailBanner = useMemo(() => (
        !!annotationIsFocused && !!annotationDetailData
    ), [
        annotationIsFocused,
        annotationDetailData,
    ]);

    const titlePlaceholderWidth = useMemo(() => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.small.min) {
            return POST_TITLE_PLACEHOLDER_WIDTH_EXTRA_SMALL;
        }

        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) {
            return POST_TITLE_PLACEHOLDER_WIDTH_SMALL;
        }

        if (viewportDimensions.width < MEDIA_QUERY_SIZE.large.min) {
            return POST_TITLE_PLACEHOLDER_WIDTH_MEDIUM;
        }

        return POST_TITLE_PLACEHOLDER_WIDTH_LARGE;
    }, [viewportDimensions.width]);

    const subtitlePlaceholderWidth = useMemo(() => {
        if (viewportDimensions.width < MEDIA_QUERY_SIZE.small.min) {
            return POST_SUBTITLE_PLACEHOLDER_WIDTH_EXTRA_SMALL;
        }

        if (viewportDimensions.width < MEDIA_QUERY_SIZE.medium.min) {
            return POST_SUBTITLE_PLACEHOLDER_WIDTH_SMALL;
        }

        if (viewportDimensions.width < MEDIA_QUERY_SIZE.large.min) {
            return POST_SUBTITLE_PLACEHOLDER_WIDTH_MEDIUM;
        }

        return POST_SUBTITLE_PLACEHOLDER_WIDTH_LARGE;
    }, [viewportDimensions.width]);

    const informResolutionAdjustCapability = useMemo(() => (
        showResolutionTooltip
        && !presentedResolutionTooltip
    ), [
        showResolutionTooltip,
        presentedResolutionTooltip,
    ]);

    const postContentAdjustMarginTop = useMemo(() => {
        if (stickyPostBannerActive) {
            return POST_CONTENT_MARGIN_TOP_WRITE + stickyBannerScrollThreshold + STICKY_POST_BANNER_HEIGHT;
        }

        return POST_CONTENT_MARGIN_TOP_WRITE;
    }, [
        write,
        stickyPostBannerActive,
        stickyBannerScrollThreshold,
    ]);

    const postContentLeftContainerWidth = useMemo(() => (
        POST_BANNER_LEFT_CONTAINER_WIDTH - POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT
    ), []);

    /**
     * Navigation tip text for previous page
     */
    const previousPageText = useMemo(() => {
        if (
            post
            && post.chapters
            && post.chapters[selectedPostValuePath[0]]
            && post.chapters[selectedPostValuePath[0]].sections
            && selectedPostValuePath[1] - 1 >= 0
            && post.chapters[selectedPostValuePath[0]].sections![selectedPostValuePath[1] - 1]
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
        ) {
            // Return title of section before current section
            return `${selectedPostValuePath[0] + 1}.${selectedPostValuePath[1]} ${post.chapters[selectedPostValuePath[0]].sections![selectedPostValuePath[1] - 1].title}`;
        }

        if (
            post
            && post.chapters
            && selectedPostValuePath[0] - 1 >= 0
            && currentResolutionLevel
            && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
        ) {
            if (
                post.chapters[selectedPostValuePath[0] - 1]
                && post.chapters[selectedPostValuePath[0] - 1].sections
                && post.chapters[selectedPostValuePath[0] - 1].sections!.length > 0
                && currentResolutionLevel.level === RESOLUTION_LEVEL.five
            ) {
                // Return title of section in chapter before current chapter
                return `${selectedPostValuePath[0]}.${post.chapters[selectedPostValuePath[0] - 1].sections!.length} ${post.chapters[selectedPostValuePath[0] - 1].sections![post.chapters[selectedPostValuePath[0] - 1].sections!.length - 1].title}`;
            }

            // Return title of chapter before current chapter section
            return `${selectedPostValuePath[0]}. ${post.chapters[selectedPostValuePath[0] - 1].title}`;
        }

        return undefined;
    }, [
        post,
        selectedPostValuePath,
    ]);

    /**
     * Navigation tip text for next page
     */
    const nextPageText = useMemo(() => {
        if (
            post
            && post.chapters
            && post.chapters[selectedPostValuePath[0]]
            && post.chapters[selectedPostValuePath[0]].sections
            && post.chapters[selectedPostValuePath[0]].sections!.length > selectedPostValuePath[1] + 1
            && post.chapters[selectedPostValuePath[0]].sections![selectedPostValuePath[1] + 1]
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
        ) {
            // Return title of section after current section
            return `${selectedPostValuePath[0] + 1}.${selectedPostValuePath[1] + 2} ${post.chapters[selectedPostValuePath[0]].sections![selectedPostValuePath[1] + 1].title}`;
        }

        if (
            post
            && post.chapters
            && post.chapters.length > selectedPostValuePath[0] + 1
            && currentResolutionLevel
            && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
        ) {
            if (
                post.chapters[selectedPostValuePath[0] + 1]
                && post.chapters[selectedPostValuePath[0] + 1].sections
                && post.chapters[selectedPostValuePath[0] + 1].sections!.length > 0
                && currentResolutionLevel.level === RESOLUTION_LEVEL.five
            ) {
                // Return title of section in chapter after current chapter
                return `${selectedPostValuePath[0] + 2}.${1} ${post.chapters[selectedPostValuePath[0] + 1].sections![0].title}`;
            }

            // Return title of section in chapter after current chapter section
            return `${selectedPostValuePath[0] + 2}. ${post.chapters[selectedPostValuePath[0] + 1].title}`;
        }

        return undefined;
    }, [
        post,
        selectedPostValuePath,
    ]);

    /**
     * Determines the current post value based on the selected post value path
     * @returns the current post value based on the selected post value path
     */
    const currentPostValue = useMemo(() => {
        // We return undefined because returning a string
        // will cause initializedValue flag to be set to true in PostEditor
        // and prevent updating when actual value is received from database
        if (!post) { return undefined; }

        if (
            currentResolutionLevel
            && currentResolutionLevel.level < RESOLUTION_LEVEL.four
            && 'chapters' in post
        ) {
            // Merge values of all sections in all chapters
            return `[${post.chapters!.reduce((value, chapter) => {
                if (!chapter.sections) {
                    return `${value.length > 0 ? `${value},` : value}${chapter.value!.slice(1, -1)}`;
                }

                return `${value.length > 0 ? `${value},` : value}${chapter.sections!.map((section) => section.value?.slice(1, -1)).join(',')}`;
            }, '')}]`;
        }

        // switch selected post value
        if (
            selectedPostValuePath.length === 0
            && 'value' in post
        ) {
            // post has no chapters or sections
            return post!.value;
        }

        if (
            selectedPostValuePath.length === 1
            && 'chapters' in post
            && post.chapters![selectedPostValuePath[0]]
            && 'value' in post.chapters![selectedPostValuePath[0]]
        ) {
            return post.chapters![selectedPostValuePath[0]].value!;
        }

        if (
            selectedPostValuePath.length === 1
            && 'chapters' in post
            && post.chapters![selectedPostValuePath[0]]
            && post.chapters![selectedPostValuePath[0]]!.sections
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.four
        ) {
            // Merge values of all sections in chapter
            return `[${post.chapters![selectedPostValuePath[0]].sections!.reduce((value, section, index) => `${value.length > 0 ? `${value},` : value}${section.value!.slice(1, -1)}`, '')}]`;
        }

        if (
            selectedPostValuePath.length === 2
            && 'chapters' in post
            && post.chapters![selectedPostValuePath[0]]
            && post.chapters![selectedPostValuePath[0]]!.sections
            && post.chapters![selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]]
            && 'value' in post.chapters![selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]]
            && currentResolutionLevel
            && currentResolutionLevel.level === RESOLUTION_LEVEL.five
        ) {
            return post.chapters![selectedPostValuePath[0]]!.sections![selectedPostValuePath[1]].value!;
        }

        // May hit this when we haven't yet set the selectedPostValuePath
        return undefined;
    }, [
        post,
        selectedPostValuePath,
    ]);

    /**
     * Determines how many resolution levels are unused in post
     */
    const unusedResolutionLevelCount = useMemo(
        () => {
            if (!write && appliedResolutionLevelCount) {
                return TOTAL_RESOLUTION_LEVELS - appliedResolutionLevelCount - 1;
            }
            return 0;
        },
        [
            write,
            appliedResolutionLevelCount,
        ],
    );

    // ===== Renderers =====

    /**
     * Renderer for File Upload Progress
     */
    const FileUploads = useMemo((): React.ReactElement => {
        const uploadingFiles = Array.from(uploadingMedia.values()).filter((uploadingItem) => {
            if (
                uploadingItem.type === MEDIA_TYPE.image
                || uploadingItem.type === MEDIA_TYPE.sketch
                || uploadingItem.type === MEDIA_TYPE.audioNote
            ) {
                return true;
            }
            return false;
        });

        return (
            <Transition
                in={uploadingFiles.length > 0}
                timeout={{
                    enter: FADE_IN_TRANSITION_DURATION,
                    exit: FADE_IN_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
            >
                {
                    (state) => (
                        <UploadProgressContainer
                            style={{
                                ...FADE_IN_DEFAULT_STYLE({
                                    direction: 'up',
                                    offset: 10,
                                }),
                                ...FADE_IN_TRANSITION_STYLES({
                                    direction: 'up',
                                    offset: 10,
                                })[state],
                            }}
                        >
                            {uploadingFiles.map((item, index) => (
                                <Transition
                                    key={item.id}
                                    in={uploadingFiles.length > 0}
                                    timeout={{
                                        enter: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION
                                            + UPLOAD_TRANSITION_DELAY,
                                        ),
                                        exit: Math.min(
                                            MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                            ((uploadingFiles.length - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                            + FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ),
                                    }}
                                    appear
                                    mountOnEnter
                                >
                                    {(transitionState) => (
                                        <UploadingMediaItem
                                            compact
                                            progress={item.uploadProgress}
                                            mediaItem={item}
                                            onItemCompletion={removeUploadingMedia}
                                            color={themeObj.verascopeColor.blue200}
                                            style={{
                                                ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                    direction: 'up',
                                                    offset: 10,
                                                }),
                                                ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                    direction: 'up',
                                                    offset: 10,
                                                    numItems: uploadingFiles.length,
                                                    index,
                                                    enterDelay: UPLOAD_TRANSITION_DELAY,
                                                })[transitionState],
                                            }}
                                        />
                                    )}
                                </Transition>
                            ))}
                        </UploadProgressContainer>
                    )
                }
            </Transition>
        );
    }, [
        uploadingMedia,
    ]);

    /**
     * Renderer for Animate To Cart Items
     */
    const WebBookAnimationItems = useMemo((): React.ReactElement => (
        <Transition
            in={animateWebBookToCartButtonItems.size > 0}
            timeout={{
                enter: FADE_IN_TRANSITION_DURATION,
                exit: FADE_IN_TRANSITION_DURATION,
            }}
            appear
            mountOnEnter
        >
            {
                (state) => (
                    <CartAnimationItemsContainer
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 10,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 10,
                            })[state],
                        }}
                    >
                        {Array.from(animateWebBookToCartButtonItems.values()).map((item, index) => (
                            <Transition
                                key={item.id}
                                in={animateWebBookToCartButtonItems.size > 0}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + UPLOAD_TRANSITION_DELAY,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((animateWebBookToCartButtonItems.size - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                            >
                                {(transitionState) => (
                                    <UploadingMediaItem
                                        compact
                                        progress={item.uploadProgress}
                                        mediaItem={item}
                                        iconImageUrl={item.url}
                                        onItemCompletion={onCartItemAnimationComplete}
                                        color={themeObj.verascopeColor.blue200}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'up',
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'up',
                                                offset: 10,
                                                numItems: animateWebBookToCartButtonItems.size,
                                                index,
                                                enterDelay: UPLOAD_TRANSITION_DELAY,
                                            })[transitionState],
                                        }}
                                    />
                                )}
                            </Transition>
                        ))}
                    </CartAnimationItemsContainer>
                )
            }
        </Transition>
    ), [
        animateWebBookToCartButtonItems,
    ]);

    const DigitalBookAnimationItems = useMemo((): React.ReactElement => (
        <Transition
            in={animateDigitalBookToCartButtonItems.size > 0}
            timeout={{
                enter: FADE_IN_TRANSITION_DURATION,
                exit: FADE_IN_TRANSITION_DURATION,
            }}
            appear
            mountOnEnter
        >
            {
                (state) => (
                    <CartAnimationItemsContainer
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 10,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 10,
                            })[state],
                        }}
                    >
                        {Array.from(animateDigitalBookToCartButtonItems.values()).map((item, index) => (
                            <Transition
                                key={item.id}
                                in={animateDigitalBookToCartButtonItems.size > 0}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + UPLOAD_TRANSITION_DELAY,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((animateDigitalBookToCartButtonItems.size - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                            >
                                {(transitionState) => (
                                    <UploadingMediaItem
                                        compact
                                        progress={item.uploadProgress}
                                        mediaItem={item}
                                        iconImageUrl={item.url}
                                        onItemCompletion={onCartItemAnimationComplete}
                                        color={themeObj.verascopeColor.blue200}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'up',
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'up',
                                                offset: 10,
                                                numItems: animateDigitalBookToCartButtonItems.size,
                                                index,
                                                enterDelay: UPLOAD_TRANSITION_DELAY,
                                            })[transitionState],
                                        }}
                                    />
                                )}
                            </Transition>
                        ))}
                    </CartAnimationItemsContainer>
                )
            }
        </Transition>
    ), [
        animateDigitalBookToCartButtonItems,
    ]);

    const PhysicalBookAnimationItems = useMemo((): React.ReactElement => (
        <Transition
            in={animatePhysicalBookToCartButtonItems.size > 0}
            timeout={{
                enter: FADE_IN_TRANSITION_DURATION,
                exit: FADE_IN_TRANSITION_DURATION,
            }}
            appear
            mountOnEnter
        >
            {
                (state) => (
                    <CartAnimationItemsContainer
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 10,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 10,
                            })[state],
                        }}
                    >
                        {Array.from(animatePhysicalBookToCartButtonItems.values()).map((item, index) => (
                            <Transition
                                key={item.id}
                                in={animatePhysicalBookToCartButtonItems.size > 0}
                                timeout={{
                                    enter: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        (index * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION
                                        + UPLOAD_TRANSITION_DELAY,
                                    ),
                                    exit: Math.min(
                                        MAX_FADE_IN_STAGGER_TRANSITION_DURATION,
                                        ((animatePhysicalBookToCartButtonItems.size - Math.max(index - 1, 0)) * FADE_IN_STAGGER_OFFSET_DURATION)
                                        + FADE_IN_STAGGER_TRANSITION_DURATION,
                                    ),
                                }}
                                appear
                                mountOnEnter
                            >
                                {(transitionState) => (
                                    <UploadingMediaItem
                                        compact
                                        progress={item.uploadProgress}
                                        mediaItem={item}
                                        iconImageUrl={item.url}
                                        onItemCompletion={onCartItemAnimationComplete}
                                        color={themeObj.verascopeColor.blue200}
                                        style={{
                                            ...FADE_IN_STAGGER_DEFAULT_STYLE({
                                                direction: 'up',
                                                offset: 10,
                                            }),
                                            ...FADE_IN_STAGGER_TRANSITION_STYLES({
                                                direction: 'up',
                                                offset: 10,
                                                numItems: animatePhysicalBookToCartButtonItems.size,
                                                index,
                                                enterDelay: UPLOAD_TRANSITION_DELAY,
                                            })[transitionState],
                                        }}
                                    />
                                )}
                            </Transition>
                        ))}
                    </CartAnimationItemsContainer>
                )
            }
        </Transition>
    ), [
        animatePhysicalBookToCartButtonItems,
    ]);

    /**
     * Renderer for post update text
     */
    const UpdateText = useMemo(() => {
        let notifText = '';
        if (postValueIsSaving) {
            notifText = 'Saving...';
        } else if (post?.updated && post.updated.length > 0) {
            notifText = `Draft saved ${moment.utc(post.updated[post.updated.length - 1]).fromNow()}`;
        }

        return (
            <Transition
                in={postValueIsSaving || !!post?.updated}
                timeout={{
                    enter: FADE_IN_TRANSITION_DURATION,
                    exit: FADE_IN_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
            >
                {(state) => (
                    <UpdateTextContainer
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 10,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 10,
                            })[state],
                        }}
                    >
                        <EditorUpdateText>
                            {notifText}
                        </EditorUpdateText>
                    </UpdateTextContainer>
                )}
            </Transition>
        );
    }, [
        post,
        postValueIsSaving,
    ]);

    /**
     * Renderer for annotation view handlebar
     */
    const handle = (
        <HandlebarContainer
            width={DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH}
            height="100%"
            isSteering={isSteering}
            transitionDuration={HANDLEBAR_TRANSITION_DURATION}
        >
            <Handlebar
                className={HOVER_TARGET_CLASSNAME}
                width={DETAIL_VIEW_HANDLEBAR_WIDTH}
                height={DETAIL_VIEW_HANDLEBAR_HEIGHT}
                isSteering={isSteering}
                transitionDuration={HANDLEBAR_TRANSITION_DURATION}
                onMouseEnter={onHandlebarMouseEnter}
                onMouseLeave={onHandlebarMouseLeave}
            />
        </HandlebarContainer>
    );

    /**
     * Renderer for annotations
     */
    const CustomSheet = styled(Sheet)`
        .react-modal-sheet-backdrop {
            background-color: rgba(
                ${hexToRgb(themeObj.verascopeColor.purple100)!.r},
                ${hexToRgb(themeObj.verascopeColor.purple100)!.g},
                ${hexToRgb(themeObj.verascopeColor.purple100)!.b},
                0.6
            ) !important;
        }
        .react-modal-sheet-container {
            background-color: ${DETAIL_VIEW_BACKGROUND} !important;
            box-shadow: ${themeObj.color.boxShadow300};
        }
        .react-modal-sheet-header {
            background: ${DETAIL_VIEW_BACKGROUND};
        }
        .react-modal-sheet-content {
            background-color: ${DETAIL_VIEW_BACKGROUND};
        }
    `;

    /**
     * Determines the height of the post editor
     */
    const postHeight = useMemo(() => {
        if (postEditorContainerRef.current) {
            const postEditorElement = postEditorContainerRef.current.children[0];
            const postEditorHeight = postEditorElement.getBoundingClientRect().height + POST_CONTENT_MARGIN_TOP_WRITE;
            return postEditorHeight;
        }
    }, [
        postValueIsSaving,
        title,
        subtitle,
        postEditorContainerRef.current,
        remeasurePostHeight,
        annotationHighlightsRendered,
        post, // Necessary to rerender when change postValue via chapter or section change
    ]);

    /**
     * Generates a list of annotations to render
     */
    const annotationItems = useMemo(() => (
        Array.from(annotations.values())
    ), [
        postHeight,
        annotations,
        currentResolutionLevel,
        annotationHighlightsRendered,
    ]);

    /**
     * Find annotation paragraphs
     * UNRESOLVED BUG: It may not be competent in locating deeply nested annotations
     */
    const annotationParagraphs = useMemo(() => {
        const highlightElements: HTMLElement[] = [];
        annotationItems.forEach((annotation) => {
            const highlightElement: HTMLElement | null = document
                .querySelector(`[data-${ANNOTATION_DECORATION_PREFIX}${annotation.id}='${annotation.id}']`);
            if (highlightElement) {
                highlightElements.push(highlightElement);
            }
        });

        return highlightElements.map((highlightElement: HTMLElement) => findParentNodeWithClass(
            SLATE_PARAGRAPH_CLASSNAME,
            highlightElement,
            10,
        ));
    }, [
        annotationItems,
    ]);

    /**
     * Map of unique paragraphs associated with annotations
     */
    const paragraphMap = useMemo(() => {
        // Create map of unique paragraphs
        const map: Map<string, {
            index: number,
            element: HTMLElement,
        }> = new Map();

        annotationParagraphs.forEach((paragraph, index) => {
            const paragraphId = paragraph?.dataset.paragraphId;
            if (paragraphId && !map.has(paragraphId)) {
                map.set(paragraphId, {
                    index,
                    element: paragraph,
                });
            }
        });

        return map;
    }, [
        annotationParagraphs,
    ]);

    /**
     * Determines the top position of each annotation paragraph
     */
    const paragraphPositionTopMap = useMemo(() => {
        // Create map of unique paragraphs
        const map: Map<string, number> = new Map();
        Array.from(paragraphMap).forEach(([id, data]) => {
            const coords = getElementCoords(
                data.element,
                postEditorContainerRef.current || undefined,
            );
            if (!map.has(id)) {
                map.set(id, coords.y);
            }
        });

        return map;
    }, [
        paragraphMap,
        postEditorContainerRef.current,
    ]);

    /**
     * Generates annotation paragraph groups
     */
    const paragraphBuckets = useMemo(() => {
        // Bucket highlights into paragraphs
        const buckets: Map<string, IAnnotationItem[]> = new Map();
        annotationItems.forEach((annotation: IAnnotationItem, index: number) => {
            const paragraph = annotationParagraphs[index];
            const paragraphId = paragraph?.dataset.paragraphId;
            if (paragraphId && buckets.has(paragraphId)) {
                buckets.get(paragraphId)!.push(annotation);
            } else if (paragraphId && !buckets.has(paragraphId)) {
                buckets.set(paragraphId, [annotation]);
            }
        });

        return buckets;
    }, [
        annotationItems,
        annotationParagraphs,
    ]);

    /**
     * Renderer for annotations
     * UNRESOLVED BUG: Some orderings of annotations (by when they are created) don't
     * cause annotation buckets to activate
     */
    const Annotations = useMemo(() => {
        const buckets = Array.from(paragraphMap).sort((a, b) => {
            if (a[1].index > b[1].index) return 1;
            if (a[1].index < b[1].index) return -1;
            return 0;
        });

        return (
            <>
                {buckets.map(([id], index) => {
                    const nextBucketId = index + 1 < buckets.length ? buckets[index + 1][0] : null;
                    const postBannerHeightExpanded = POST_THUMBNAIL_HEIGHT_EXPANDED + 2 * POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED;
                    const postBannerHeightContracted = POST_THUMBNAIL_HEIGHT_CONTRACTED + 2 * POST_BANNER_LEFT_CONTAINER_PADDING_CONTRACTED;
                    return (
                        <AnnotationBucket
                            key={id}
                            user={user}
                            post={post}
                            hasSound={hasSound}
                            currentSessionId={currentSessionId}
                            top={paragraphPositionTopMap.get(id)!}
                            width={viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                                ? annotationViewWidth - DETAIL_VIEW_HANDLEBAR_CONTAINER_WIDTH
                                : ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH}
                            postBannerHeight={postScrollTop.current > stickyBannerScrollThreshold
                                ? postBannerHeightContracted
                                : ((postBannerHeightContracted - postBannerHeightExpanded) / stickyBannerScrollThreshold) * postScrollTop.current + postBannerHeightExpanded}
                            bucketIndex={index}
                            bucketCount={buckets.length}
                            nextBucketTop={typeof nextBucketId === 'string'
                                ? paragraphPositionTopMap.get(nextBucketId)!
                                : null}
                            annotations={paragraphBuckets.get(id)!}
                            viewportDimensions={viewportDimensions}
                            onCursorEnter={onCursorEnter}
                            onCursorLeave={onCursorLeave}
                            setInputFocused={setInputFocused}
                            setTargetAnnotationID={setTargetAnnotationID}
                            setCursorSigns={setCursorSigns}
                            showAnnotationDetail={onShowAnnotationDetail}
                            hideAnnotationDetail={onHideAnnotationDetail}
                            postScrollTop={postScrollTop.current}
                            setSnackbarData={setSnackbarData}
                            showWarning={hitDetailMinimum || hitPostMinimum}
                        />
                    );
                })}
            </>
        );
    }, [
        post?.annotations, // Necessary to rerender with new annotations. Causes multiple rerenders
        post?.chapters, // Necessary on lower resolution levels. Causes multiple rerenders
        paragraphMap, // Necessary to rerender with new annotations. Causes multiple rerenders
        paragraphBuckets, // Necessary to rerender with new annotations. Causes multiple rerenders
        paragraphPositionTopMap, // Necessary to rerender with new annotations. Causes multiple rerenders
        postScrollTop.current, // Necessary to collapse expanded annotatin buckets when scrolling begins
        user, // Necessary to record user actions in annotation
        currentSessionId, // Necessary to record user actions in annotation
        annotationViewWidth, // Necessary to adjust width of annotation buckets when resizing detail view
        viewportDimensions, // Necessary to adjust width of annotation buckets when resizing detail view
        hitDetailMinimum, // Necessary to change color of background when minimum is hit
        hitPostMinimum, // Necessary to change color of background when maximum is hit
    ]);

    /**
     * Renderer for the annotation detail view
     */
    const SheetContent = useMemo(() => {
        if (annotationDetailData) {
            return (
                <Annotation
                    key={annotationDetailData.id}
                    annotation={annotationDetailData}
                    hasSound={hasSound}
                    user={user}
                    post={post}
                    bucketWidth={viewportDimensions.width}
                    currentSessionId={currentSessionId}
                    annotationStackActive={false}
                    index={0}
                    horizontalMargin={0}
                    zIndex={0}
                    annotationCount={annotations.size}
                    hide={false}
                    annotationStackExpanded={false}
                    bucketHeight={null}
                    viewportDimensions={viewportDimensions}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    setTargetAnnotationID={setTargetAnnotationID}
                    setCursorSigns={setCursorSigns}
                    showAnnotationDetail={onShowAnnotationDetail}
                    hideAnnotationDetail={onHideAnnotationDetail}
                    setSnackbarData={setSnackbarData}
                />
            );
        }

        return <div />;
    }, [
        post,
        user,
        hasSound,
        annotations,
        currentSessionId,
        viewportDimensions,
        annotationDetailData,
    ]);

    /**
     * Renderer for the post editor
     */
    const PostEditorComponent = useMemo(() => {
        if (postId) {
            return (
                <PostEditor
                    readOnly={!write}
                    user={user}
                    isAuthor={!!user && !!post?.authors.includes(user.id)}
                    hasSound={hasSound}
                    currentSessionId={currentSessionId}
                    color={DEFAULT_EDITOR_COLOR}
                    id={postId}
                    type={EDITOR_CONTEXT_TYPE.post} // Used to know which storage bucket to save media
                    postValue={currentPostValue}
                    placeholder="Express your mind..."
                    bufferHeight={POST_EDITOR_BUFFER_HEIGHT}
                    parentRef={postEditorContainerRef.current}
                    acceptedFiles={acceptedFiles}
                    uploadingMedia={uploadingMedia}
                    setUploadingMedia={addUploadingMedia}
                    updateUploadingMedia={updateUploadingMedia}
                    removeUploadingMedia={removeUploadingMedia}
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    savePost={handlePostChange}
                    postValueIsSaving={postValueIsSaving}
                    setEditor={setPostEditor}
                    postQuote={postQuote}
                    annotations={Array.from(annotations.values())}
                    setAnnotations={setAnnotations}
                    chapters={post?.chapters}
                    setAnnotationHighlightsRendered={setAnnotationHighlightsRendered}
                    annotationHighlightsRendered={annotationHighlightsRendered}
                    targetAnnotationID={targetAnnotationID}
                    setRemeasurePostHeight={setRemeasurePostHeight}
                    setSnackbarData={setSnackbarData}
                    currentResolutionLevel={currentResolutionLevel}
                    hasSuccess={isDragAccept}
                    hasError={isDragReject || hitPostMinimum || hitDetailMinimum}
                    selectedPostValuePath={selectedPostValuePath}
                    unusedResolutionLevelCount={unusedResolutionLevelCount}
                    resolutionLevelIcons={new Map(Array.from(adventureCharacters.values()).map((character) => [character.level, character.icon]))}
                />
            );
        }

        return null;
    }, [
        write,
        user,
        post,
        hasSound,
        currentSessionId,
        postId,
        postEditorContainerRef.current,
        acceptedFiles,
        uploadingMedia,
        postValueIsSaving,
        annotations,
        annotationHighlightsRendered,
        targetAnnotationID,
        currentResolutionLevel,
        isDragAccept,
        isDragReject,
        hitPostMinimum,
        hitDetailMinimum,
        selectedPostValuePath,
        postQuote,
        unusedResolutionLevelCount,
    ]);

    /**
     * Renderer for the resolution level buttons
     */
    const ResolutionLevelButtons = useMemo(() => {
        const buttons: {
            level: RESOLUTION_LEVEL,
            icon: string,
            text: string,
        }[] = [];
        adventureCharacters.forEach((character) => {
            if (
                write
                || character.level - unusedResolutionLevelCount > 0
            ) {
                let duration = '';
                if (character.duration / MILLISECONDS_IN_AN_HOUR > 1) {
                    duration += `${Math.floor(character.duration / MILLISECONDS_IN_AN_HOUR)}hr`;
                } else if (character.duration / MILLISECONDS_IN_A_MINUTE > 1) {
                    duration += `${Math.floor(character.duration / MILLISECONDS_IN_A_MINUTE)}m`;
                } else {
                    duration += `${Math.floor(character.duration / MILLISECONDS_IN_A_SECOND)}s`;
                }
                buttons.push({
                    level: character.level,
                    icon: character.icon,
                    text: `${character.level - unusedResolutionLevelCount}.  ${character.name}  (${duration})`,
                });
            }
        });

        if (buttons.length === 0) {
            return <div />;
        }

        return buttons.map((resolutionLevel) => (
            <Button
                key={resolutionLevel.level}
                className={HOVER_TARGET_CLASSNAME}
                type={currentResolutionLevel
                && currentResolutionLevel.level === resolutionLevel.level
                    ? BUTTON_TYPE.solid
                    : BUTTON_TYPE.secret}
                height={RESOLUTION_LEVEL_ITEM_HEIGHT}
                width={RESOLUTION_LEVEL_ITEM_WIDTH}
                icon={resolutionLevel.icon}
                text={resolutionLevel.text}
                {...(currentResolutionLevel
                    && currentResolutionLevel.level === resolutionLevel.level
                    ? {
                        background: setColorLightness(
                            DEFAULT_EDITOR_COLOR,
                            BUTTON_CONTAINER_LIGHTNESS_VALUE,
                        ),
                    } : {})}
                onMouseEnter={onButtonMouseEnter}
                onMouseLeave={onButtonMouseLeave}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: () => handleResolutionLevel(resolutionLevel.level),
                } : {
                    onMouseDown: () => handleResolutionLevel(resolutionLevel.level),
                })}
            />
        ));
    }, [
        write,
        currentResolutionLevel,
        unusedResolutionLevelCount,
    ]);

    const CharacterModal = useMemo(() => (
        <Modal
            hasContainer
            overrideClickOverlay
            hasSound={hasSound}
            width={CHARACTER_MODAL_WIDTH}
            isOpen={characterModalIsVisible}
            color={themeObj.verascopeColor.purple200}
            closeModal={() => setCharacterModalIsVisible(false)}
        >
            <ModalContentsContainer>
                <ModalHeader>
                    <ModalTitle>
                        Choose Your Own Adventure
                    </ModalTitle>
                    <ModalSubtitle>
                        Customize your reading experience by selecting a character whose
                        desired level of engagement matches your own.
                    </ModalSubtitle>
                </ModalHeader>
                <ModalBody>
                    {Array.from(adventureCharacters.values())
                        .filter((character) => character.level - unusedResolutionLevelCount > 0)
                        .map((character) => {
                            let duration = 'est. ';
                            if (character.duration / MILLISECONDS_IN_AN_HOUR > 1) {
                                duration += `${Math.floor(character.duration / MILLISECONDS_IN_AN_HOUR)} hours`;
                            } else if (character.duration / MILLISECONDS_IN_A_MINUTE > 1) {
                                duration += `${Math.floor(character.duration / MILLISECONDS_IN_A_MINUTE)} minutes`;
                            } else {
                                duration += `${Math.floor(character.duration / MILLISECONDS_IN_A_SECOND)} seconds`;
                            }
                            return (
                                <AdventureCharacterContainer
                                    className={HOVER_TARGET_CLASSNAME}
                                    background={character.background}
                                    engagementHoverColor={character.engagementHoverColor}
                                    transitionDuration={ADVENTURE_CHARACTER_TRANSITION_DURATION}
                                    onMouseEnter={(e) => {
                                        onButtonMouseEnter(e);

                                        // Play Sound
                                        if (hasSound && buttonHoverClip.current) {
                                            buttonHoverClip.current.pause();
                                            buttonHoverClip.current.currentTime = 0;
                                            playAudio(buttonHoverClip.current);
                                        }
                                    }}
                                    onMouseLeave={onButtonMouseLeave}
                                    {...(detectTouchDevice(document) ? {
                                        onTouchStart: () => handleSelectAdventureCharacter(
                                            character.id,
                                            character.icon,
                                        ),
                                    } : {
                                        onMouseDown: () => handleSelectAdventureCharacter(
                                            character.id,
                                            character.icon,
                                        ),
                                    })}
                                >
                                    <AdventureCharacterAvatarContainer
                                        background={character.background}
                                        transitionDuration={ADVENTURE_CHARACTER_TRANSITION_DURATION}
                                    >
                                        <AdventureCharacterAvatar
                                            src={character.icon}
                                        />
                                    </AdventureCharacterAvatarContainer>
                                    <AdventureCharacterName
                                        color={character.nameColor}
                                    >
                                        {character.name}
                                    </AdventureCharacterName>
                                    <AdventureCharacterEngagementLevel
                                        color={character.engagementColor}
                                        transitionDuration={ADVENTURE_CHARACTER_TRANSITION_DURATION}
                                    >
                                        {duration}
                                    </AdventureCharacterEngagementLevel>
                                </AdventureCharacterContainer>
                            );
                        })}
                </ModalBody>
            </ModalContentsContainer>
        </Modal>
    ), [
        hasSound,
        characterModalIsVisible,
        unusedResolutionLevelCount,
    ]);

    // ===== Render Behavior ======
    /*
     * 1) remeasurePostHeight from PostEditor will cause a lot of rerenders as the post's media items
     * are loading into the post.
     *
     * 2) Everytime App.tsx rerenders, ReaderView will also rerender.
    */

    return (
        <Container
            {...(write
                ? {
                    ...getRootProps({
                        role: 'container',
                    }),
                }
                : { ref: containerRef }
            )}
            hasSuccess={isDragAccept}
            hasError={isDragReject || hitPostMinimum || hitDetailMinimum}
        >
            { write && (<DropzoneInput {...getInputProps()} />)}
            {
            /* We don't want to render PostEditor until we receive
               post data so that it is not written over by empty value
            */
            }
            <PostContentContainer
                ref={postContentContainerRef}
                isSteering={isSteering}
                transitionDuration={DETAIL_VIEW_TRANSITION_DURATION}
            >
                <PostBanner
                    ref={postBannerRef}
                    height={POST_BANNER_HEIGHT}
                    stickyPostBannerActive={stickyPostBannerActive}
                    transitionDuration={POST_BANNER_TRANSITION_DURATION}
                >
                    <PostBannerLeftContainer
                        ref={postBannerLeftContainerRef}
                        width={POST_BANNER_LEFT_CONTAINER_WIDTH - POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT}
                        marginRight={POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT}
                        heightExpanded={POST_BANNER_LEFT_CONTAINER_HEIGHT_EXPANDED}
                        paddingExpanded={POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED}
                    >
                        <Transition
                            in={write || !!postImageURL}
                            timeout={{
                                enter: POST_TITLE_ENTER_DURATION + POST_SUBTITLE_ENTER_DURATION,
                                exit: POST_METADATA_ENTER_DURATION,
                            }}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <PostThumbnail
                                    ref={postThumbnailRef}
                                    url={postImageURL}
                                    bottomExpanded={POST_THUMBNAIL_BOTTOM_EXPANDED}
                                    heightExpandedLarge={POST_THUMBNAIL_HEIGHT_EXPANDED}
                                    heightExpandedMedium={POST_THUMBNAIL_MAX_WIDTH_MEDIUM}
                                    heightExpandedSmall={POST_THUMBNAIL_MAX_WIDTH_SMALL}
                                    heightExpandedExtraSmall={POST_THUMBNAIL_MAX_WIDTH_SMALL}
                                    widthExpanded={POST_THUMBNAIL_WIDTH_EXPANDED}
                                    borderRadiusExpanded={POST_THUMBNAIL_BORDER_RADIUS_EXPANDED}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'left',
                                            offset: 10,
                                            duration: POST_THUMBNAIL_ENTER_DURATION,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'left',
                                            offset: 10,
                                        })[state],
                                    }}
                                >
                                    {!postImageURL
                                    && post
                                    && write
                                    && pageLoadRendersCompleted && (
                                        <AddPostThumbnailContainer
                                            {...getPostThumbnailRootProps({
                                                isDragAccept: isPostThumbnailDragAccept,
                                                isDragReject: isPostThumbnailDragReject,
                                                className: HOVER_TARGET_CLASSNAME,
                                                onMouseEnter: onButtonMouseEnter,
                                                onMouseLeave: onButtonMouseLeave,
                                                onClick: selectPostThumbnail,
                                            })}
                                        >
                                            <AddPostThumbnailInput {...getPostThumbnailInputProps()} />
                                            {uploadingPostThumbnail
                                                ? (
                                                    <PostThumbnailUploadProgressBarContainer>
                                                        <PostThumbnailUploadProgressBar
                                                            progress={uploadingPostThumbnail.uploadProgress}
                                                        />
                                                    </PostThumbnailUploadProgressBarContainer>
                                                ) : (
                                                    <AddPostThumbnailIcon
                                                        stickyPostBannerActive={stickyPostBannerActive}
                                                        transitionDuration={POST_THUMBNAIL_TRANSITION_DURATION}
                                                    >
                                                        <ReactSVG
                                                            src={AddImageIcon}
                                                        />
                                                    </AddPostThumbnailIcon>
                                                )}
                                        </AddPostThumbnailContainer>
                                    )}
                                </PostThumbnail>
                            )}
                        </Transition>
                    </PostBannerLeftContainer>
                    <PostBannerCenterContainer
                        height={POST_BANNER_HEIGHT}
                        postBannerLeftContainerWidth={POST_BANNER_LEFT_CONTAINER_WIDTH - POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT}
                        postBannerRightContainerWidth={viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                            ? annotationViewWidth
                            : ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH}
                    >
                        <PostTitlesContainer>
                            {/* Post loaded and title either present or not */}
                            <Transition
                                in={!!post && !write}
                                timeout={{
                                    enter: 0,
                                    exit: POST_METADATA_ENTER_DURATION
                                        + POST_THUMBNAIL_ENTER_DURATION
                                        + POST_SUBTITLE_ENTER_DURATION,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <PostTitle
                                        fontMultiplier={POST_TITLE_FONT_MULTIPLIER}
                                        style={{
                                            ...FADE_IN_DEFAULT_STYLE({
                                                direction: 'right',
                                                offset: 10,
                                                duration: POST_TITLE_ENTER_DURATION,
                                            }),
                                            ...FADE_IN_TRANSITION_STYLES({
                                                direction: 'right',
                                                offset: 10,
                                            })[state],
                                        }}
                                    >
                                        {post?.title || DEFAULT_POST_TITLE}
                                    </PostTitle>
                                )}
                            </Transition>
                            {/* Post not yet loaded */}
                            {!post
                            && !write
                            && (
                                <PlaceholderBox
                                    width={titlePlaceholderWidth}
                                    height={POST_TITLE_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                    margin={`${POST_TITLE_MARGIN_TOP}px 0px ${
                                        viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                                            ? POST_TITLE_MARGIN_BOTTOM
                                            : 0
                                    }px`}
                                />
                            )}
                            {/* Editing post title */}
                            {post
                            && write
                            && (
                                <PostInput
                                    type="text"
                                    ref={titleInputRef}
                                    className={HOVER_TARGET_CLASSNAME}
                                    defaultValue={post.title}
                                    placeholder="Title"
                                    onKeyUp={checkForEnter}
                                    color={themeObj.verascopeColor.purple100}
                                    fontSize={POST_TITLE_FONT_MULTIPLIER}
                                    fontWeight={TITLE_INPUT_FONT_WEIGHT}
                                    marginTop={TITLE_INPUT_MARGIN_TOP}
                                    onMouseEnter={onInputMouseEnter}
                                    onMouseLeave={onInputMouseLeave}
                                    onFocus={onInputFocus}
                                    onChange={onTitleInputChange}
                                />
                            )}
                            {/* Post loaded and subtitle present */}
                            <Transition
                                in={!!post
                                    && !!post.subtitle
                                    && !write}
                                timeout={{
                                    enter: POST_TITLE_ENTER_DURATION,
                                    exit: POST_METADATA_ENTER_DURATION
                                        + POST_THUMBNAIL_ENTER_DURATION,
                                }}
                                appear
                                mountOnEnter
                                unmountOnExit
                            >
                                {(state) => (
                                    <PostSubtitle
                                        fontMultiplier={POST_SUBTITLE_FONT_MULTIPLIER}
                                        fixedToViewport={stickyPostBannerActive}
                                        transitionDuration={POST_SUBTITLE_TRANSITION_DURATION}
                                        style={{
                                            ...FADE_IN_DEFAULT_STYLE({
                                                direction: 'down',
                                                offset: 10,
                                                duration: POST_SUBTITLE_ENTER_DURATION,
                                            }),
                                            ...FADE_IN_TRANSITION_STYLES({
                                                direction: 'down',
                                                offset: 10,
                                            })[state],
                                        }}
                                    >
                                        {post!.subtitle}
                                    </PostSubtitle>
                                )}
                            </Transition>
                            {/* Post not yet loaded */}
                            {!post
                            && !write
                            && (
                                <PlaceholderBox
                                    width={subtitlePlaceholderWidth}
                                    height={POST_SUBTITLE_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                    margin={POST_SUBTITLE_MARGIN}
                                />
                            )}
                            {/* Editing post subtitle */}
                            {post
                            && write
                            && (
                                <PostInput
                                    type="text"
                                    ref={subtitleInputRef}
                                    className={HOVER_TARGET_CLASSNAME}
                                    defaultValue={post.subtitle}
                                    placeholder="Subtitle"
                                    onKeyUp={checkForEnter}
                                    color={themeObj.verascopeColor.purple200}
                                    fontSize={POST_SUBTITLE_FONT_MULTIPLIER}
                                    fontWeight={SUBTITLE_INPUT_FONT_WEIGHT}
                                    marginTop={SUBTITLE_INPUT_MARGIN_TOP}
                                    onMouseEnter={onInputMouseEnter}
                                    onMouseLeave={onInputMouseLeave}
                                    onFocus={onInputFocus}
                                    onChange={onSubtitleInputChange}
                                />
                            )}
                        </PostTitlesContainer>
                        <Transition
                            in
                            timeout={{
                                enter: POST_TITLE_ENTER_DURATION
                                    + POST_SUBTITLE_ENTER_DURATION
                                    + POST_THUMBNAIL_ENTER_DURATION,
                                exit: 0,
                            }}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <PostMetadataContainer
                                    padding={POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 10,
                                            duration: POST_METADATA_ENTER_DURATION,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 10,
                                        })[state],
                                    }}
                                >
                                    <Transition
                                        in={
                                            !post
                                            || !post.title
                                            || !stickyPostBannerActive
                                        }
                                        timeout={{
                                            enter: 0,
                                            exit: 0,
                                        }}
                                        appear
                                        mountOnEnter
                                    >
                                        {(transitionState) => (
                                            <PostMetadataContentContainer
                                                padding={POST_BANNER_LEFT_CONTAINER_PADDING_EXPANDED}
                                                style={{
                                                    ...FADE_IN_DEFAULT_STYLE({
                                                        direction: 'up',
                                                        offset: 20,
                                                        duration: POST_METADATA_TRANSITION_DURATION,
                                                        easing: themeObj.motion.eagerEasing,
                                                    }),
                                                    ...FADE_IN_TRANSITION_STYLES({
                                                        direction: 'up',
                                                        offset: 20,
                                                    })[transitionState],
                                                }}
                                            >
                                                {viewportDimensions.width > POST_AUTHOR_THRESHOLD
                                                && (
                                                    <PostAuthorContainer>
                                                        <Tooltip
                                                            text={`Author${postAuthors.length > 1 ? 's' : ''}`}
                                                            side={TOOLTIP_TYPE.top}
                                                        />
                                                        <AvatarsContainer>
                                                            {postAuthors.map((author, index) => {
                                                                const hasAuthorAvatarImage = !!postAuthorAvatarURLs[index]
                                                                    || (
                                                                        postAuthors.length > 0
                                                                        && !!postAuthors[index] && !!postAuthors[index].photoURL
                                                                    );
                                                                return (
                                                                    <AvatarContainer
                                                                        hasAvatarImage={hasAuthorAvatarImage}
                                                                        length={AVATAR_LENGTH}
                                                                    >
                                                                        {hasAuthorAvatarImage
                                                                            ? (
                                                                                <UserAvatar
                                                                                    url={
                                                                                        postAuthorAvatarURLs[index]
                                                                                        || postAuthors[index].photoURL!
                                                                                    } // Has to be present if avatarURL is false
                                                                                    length={USER_PROFILE_AVATAR_LENGTH}
                                                                                />
                                                                            ) : (
                                                                                <ReactSVG
                                                                                    src={SmileyIcon}
                                                                                />
                                                                            )}
                                                                    </AvatarContainer>
                                                                );
                                                            })}
                                                        </AvatarsContainer>
                                                        {postAuthors.length > 0
                                                            ? (
                                                                <PostAuthorName
                                                                    fontMultiplier={POST_METADATA_FONT_MULTIPLIER}
                                                                >
                                                                    {postAuthors.reduce((acc, author, authorIndex) => {
                                                                        if (authorIndex === 0 && postAuthors.length === 1) {
                                                                            return `${author.firstName} ${author.lastName}`;
                                                                        }

                                                                        if (authorIndex === 0 && postAuthors.length > 1) {
                                                                            return `${author.firstName}`;
                                                                        }

                                                                        if (authorIndex !== 0 && postAuthors.length > 1) {
                                                                            return `${acc}, ${author.firstName}`;
                                                                        }

                                                                        return `${acc}, ${author.firstName} ${author.lastName}`;
                                                                    }, '')}
                                                                </PostAuthorName>
                                                            ) : (
                                                                <PlaceholderBox
                                                                    width={POST_AUTHOR_NAME_PLACEHOLDER_WIDTH}
                                                                    height={POST_METADATA_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                                                    margin={`0px 0px 0px ${POST_AUTHOR_MARGIN_LEFT}px`}
                                                                />
                                                            )}
                                                    </PostAuthorContainer>
                                                )}
                                                <PostDetailsContainer>
                                                    {viewportDimensions.width > POST_PUBLISHED_THRESHOLD
                                                    && (
                                                        <PostDetail>
                                                            <Tooltip
                                                                text="Published"
                                                                side={TOOLTIP_TYPE.top}
                                                            />
                                                            <PostDetailIcon>
                                                                <ReactSVG
                                                                    src={ClockIcon}
                                                                />
                                                            </PostDetailIcon>
                                                            {post?.published
                                                                ? (
                                                                    <PostDetailText
                                                                        fontMultiplier={POST_METADATA_FONT_MULTIPLIER}
                                                                    >
                                                                        {moment.utc(post!.published).format('D MMM YYYY')}
                                                                    </PostDetailText>
                                                                ) : (
                                                                    <PlaceholderBox
                                                                        width={POST_PUBLISHED_PLACEHOLDER_WIDTH}
                                                                        height={POST_METADATA_FONT_MULTIPLIER * BODY_FONT_SIZE}

                                                                    />
                                                                )}
                                                        </PostDetail>
                                                    )}
                                                    {viewportDimensions.width > POST_VIEWS_THRESHOLD
                                                    && (
                                                        <PostDetail>
                                                            <Tooltip
                                                                text="Views"
                                                                side={TOOLTIP_TYPE.top}
                                                            />
                                                            <PostDetailIcon>
                                                                <ReactSVG
                                                                    src={EyeIcon}
                                                                />
                                                            </PostDetailIcon>
                                                            {post?.views
                                                                ? (
                                                                    <PostDetailText
                                                                        fontMultiplier={POST_METADATA_FONT_MULTIPLIER}
                                                                    >
                                                                        {formatNumber(post!.views.length)}
                                                                    </PostDetailText>
                                                                ) : (
                                                                    <PlaceholderBox
                                                                        width={POST_VIEWS_PLACEHOLDER_WIDTH}
                                                                        height={POST_METADATA_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                                                        margin={`0px 0px 0px ${POST_DETAIL_TEXT_MARGIN_LEFT}px`}
                                                                    />
                                                                )}
                                                        </PostDetail>
                                                    )}
                                                    {viewportDimensions.width > POST_ANNOTATIONS_THRESHOLD
                                                    && (
                                                        <PostDetail>
                                                            <Tooltip
                                                                text="Annotations"
                                                                side={TOOLTIP_TYPE.top}
                                                            />
                                                            <PostDetailIcon>
                                                                <ReactSVG
                                                                    src={HighlightIcon}
                                                                />
                                                            </PostDetailIcon>
                                                            {post?.annotations
                                                                ? (
                                                                    <PostDetailText
                                                                        fontMultiplier={POST_METADATA_FONT_MULTIPLIER}
                                                                    >
                                                                        {formatNumber(post!.annotations.length)}
                                                                    </PostDetailText>
                                                                ) : (
                                                                    <PlaceholderBox
                                                                        width={POST_ANNOTATIONS_PLACEHOLDER_WIDTH}
                                                                        height={POST_METADATA_FONT_MULTIPLIER * BODY_FONT_SIZE}
                                                                        margin={`0px 0px 0px ${POST_DETAIL_TEXT_MARGIN_LEFT}px`}
                                                                    />
                                                                )}
                                                        </PostDetail>
                                                    )}
                                                    {!characterModalIsVisible
                                                    && checkedForCharacterModal
                                                    && pageLoadRendersCompleted && (
                                                        <PostDetail>
                                                            <ButtonContainer>
                                                                <Button
                                                                    className={HOVER_TARGET_CLASSNAME}
                                                                    type={BUTTON_TYPE.floating}
                                                                    center
                                                                    height={DOWNLOAD_BUTTON_HEIGHT_SMALL}
                                                                    width={WEB_DOWNLOAD_BUTTON_WIDTH_SMALL}
                                                                    icon={CartIcon}
                                                                    text="Web"
                                                                    background={themeObj.verascopeColor.orange200}
                                                                    loading={animatingWebBookCartItem}
                                                                    disabled={animatingDigitalBookCartItem || animatingPhysicalBookCartItem}
                                                                    onMouseEnter={onButtonMouseEnter}
                                                                    onMouseLeave={onButtonMouseLeave}
                                                                    {...(detectTouchDevice(document) ? {
                                                                        onTouchStart: () => addBookToCart({
                                                                            type: BOOK_TYPE.web,
                                                                        }),
                                                                    } : {
                                                                        onMouseDown: () => addBookToCart({
                                                                            type: BOOK_TYPE.web,
                                                                        }),
                                                                    })}
                                                                    style={{
                                                                        marginRight: '10px',
                                                                    }}
                                                                />
                                                                {WebBookAnimationItems}
                                                            </ButtonContainer>
                                                            <ButtonContainer>
                                                                <Button
                                                                    className={HOVER_TARGET_CLASSNAME}
                                                                    type={BUTTON_TYPE.floating}
                                                                    center
                                                                    height={DOWNLOAD_BUTTON_HEIGHT_SMALL}
                                                                    width={DIGITAL_DOWNLOAD_BUTTON_WIDTH_SMALL}
                                                                    icon={CartIcon}
                                                                    text="Digital"
                                                                    background={themeObj.verascopeColor.orange200}
                                                                    loading={animatingDigitalBookCartItem}
                                                                    disabled={animatingWebBookCartItem || animatingPhysicalBookCartItem}
                                                                    onMouseEnter={onButtonMouseEnter}
                                                                    onMouseLeave={onButtonMouseLeave}
                                                                    {...(detectTouchDevice(document) ? {
                                                                        onTouchStart: () => addBookToCart({
                                                                            type: BOOK_TYPE.digital,
                                                                        }),
                                                                    } : {
                                                                        onMouseDown: () => addBookToCart({
                                                                            type: BOOK_TYPE.digital,
                                                                        }),
                                                                    })}
                                                                    style={{
                                                                        marginRight: '10px',
                                                                    }}
                                                                />
                                                                {DigitalBookAnimationItems}
                                                            </ButtonContainer>
                                                            <ButtonContainer>
                                                                <Button
                                                                    className={HOVER_TARGET_CLASSNAME}
                                                                    type={BUTTON_TYPE.floating}
                                                                    center
                                                                    height={DOWNLOAD_BUTTON_HEIGHT_SMALL}
                                                                    width={PHYSICAL_DOWNLOAD_BUTTON_WIDTH_SMALL}
                                                                    icon={CartIcon}
                                                                    text="Physical"
                                                                    background={themeObj.verascopeColor.orange200}
                                                                    loading={animatingPhysicalBookCartItem}
                                                                    disabled={animatingWebBookCartItem || animatingDigitalBookCartItem}
                                                                    onMouseEnter={onButtonMouseEnter}
                                                                    onMouseLeave={onButtonMouseLeave}
                                                                    {...(detectTouchDevice(document) ? {
                                                                        onTouchStart: () => addBookToCart({
                                                                            type: BOOK_TYPE.physical,
                                                                        }),
                                                                    } : {
                                                                        onMouseDown: () => addBookToCart({
                                                                            type: BOOK_TYPE.physical,
                                                                        }),
                                                                    })}
                                                                />
                                                                {PhysicalBookAnimationItems}
                                                            </ButtonContainer>
                                                            {nudgeUserToPurchaseBook && (
                                                                <Tooltip
                                                                    permanent
                                                                    text={PURCHASE_BOOK_NUDGE_TOOLTIP_MESSAGE}
                                                                    side={TOOLTIP_TYPE.bottom}
                                                                />
                                                            )}
                                                        </PostDetail>
                                                    )}
                                                </PostDetailsContainer>
                                            </PostMetadataContentContainer>
                                        )}
                                    </Transition>
                                </PostMetadataContainer>
                            )}
                        </Transition>
                        <Transition
                            in={
                                !!post
                                && !!post.title
                                && stickyPostBannerActive
                                && viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                            }
                            timeout={{
                                enter: POST_METADATA_TRANSITION_DURATION,
                                exit: 0,
                            }}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <CollapsedPostTitle
                                    height={STICKY_POST_BANNER_HEIGHT}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 20,
                                            duration: COLLAPSED_POST_TITLE_TRANSITION_DURATION,
                                            easing: themeObj.motion.eagerEasing,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 20,
                                        })[state],
                                    }}
                                >
                                    {post!.title}
                                </CollapsedPostTitle>
                            )}
                        </Transition>
                    </PostBannerCenterContainer>
                    <PostBannerRightContainer
                        annotationViewWidth={viewportDimensions.width >= MEDIA_QUERY_SIZE.medium.min
                            ? annotationViewWidth
                            : ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH}
                    >
                        <Transition
                            in={
                                !!post
                                && !!post.title
                                && stickyPostBannerActive
                                && !characterModalIsVisible
                                && checkedForCharacterModal
                                && pageLoadRendersCompleted
                                && viewportDimensions.width >= PURCHASE_BOOK_BUTTON_THRESHOLD
                            }
                            timeout={{
                                enter: POST_METADATA_TRANSITION_DURATION,
                                exit: 0,
                            }}
                            appear
                            mountOnEnter
                            unmountOnExit
                        >
                            {(state) => (
                                <PurchaseBookButtonContainer
                                    buttonHeight={DOWNLOAD_BUTTON_HEIGHT_REGULAR}
                                    stickyPostBannerHeight={STICKY_POST_BANNER_HEIGHT}
                                    style={{
                                        ...FADE_IN_DEFAULT_STYLE({
                                            direction: 'up',
                                            offset: 20,
                                            duration: COLLAPSED_PURCHASE_BUTTONS_TRANSITION_DURATION,
                                            easing: themeObj.motion.eagerEasing,
                                        }),
                                        ...FADE_IN_TRANSITION_STYLES({
                                            direction: 'up',
                                            offset: 20,
                                        })[state],
                                    }}
                                >
                                    <ButtonContainer>
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.floating}
                                            center
                                            height={DOWNLOAD_BUTTON_HEIGHT_REGULAR}
                                            width={WEB_DOWNLOAD_BUTTON_WIDTH_REGULAR}
                                            icon={CartIcon}
                                            text="Web"
                                            background={themeObj.verascopeColor.orange200}
                                            loading={animatingWebBookCartItem}
                                            disabled={animatingDigitalBookCartItem || animatingPhysicalBookCartItem}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: () => addBookToCart({
                                                    type: BOOK_TYPE.web,
                                                }),
                                            } : {
                                                onMouseDown: () => addBookToCart({
                                                    type: BOOK_TYPE.web,
                                                }),
                                            })}
                                            style={{
                                                marginRight: '10px',
                                            }}
                                        />
                                        {WebBookAnimationItems}
                                    </ButtonContainer>
                                    <ButtonContainer>
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.floating}
                                            center
                                            height={DOWNLOAD_BUTTON_HEIGHT_REGULAR}
                                            width={DIGITAL_DOWNLOAD_BUTTON_WIDTH_REGULAR}
                                            icon={CartIcon}
                                            text="Digital"
                                            background={themeObj.verascopeColor.orange200}
                                            loading={animatingDigitalBookCartItem}
                                            disabled={animatingWebBookCartItem || animatingPhysicalBookCartItem}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: () => addBookToCart({
                                                    type: BOOK_TYPE.digital,
                                                }),
                                            } : {
                                                onMouseDown: () => addBookToCart({
                                                    type: BOOK_TYPE.digital,
                                                }),
                                            })}
                                            style={{
                                                marginRight: '10px',
                                            }}
                                        />
                                        {DigitalBookAnimationItems}
                                    </ButtonContainer>
                                    <ButtonContainer>
                                        <Button
                                            className={HOVER_TARGET_CLASSNAME}
                                            type={BUTTON_TYPE.floating}
                                            center
                                            height={DOWNLOAD_BUTTON_HEIGHT_REGULAR}
                                            width={PHYSICAL_DOWNLOAD_BUTTON_WIDTH_REGULAR}
                                            icon={CartIcon}
                                            text="Physical"
                                            background={themeObj.verascopeColor.orange200}
                                            loading={animatingPhysicalBookCartItem}
                                            disabled={animatingWebBookCartItem || animatingDigitalBookCartItem}
                                            onMouseEnter={onButtonMouseEnter}
                                            onMouseLeave={onButtonMouseLeave}
                                            {...(detectTouchDevice(document) ? {
                                                onTouchStart: () => addBookToCart({
                                                    type: BOOK_TYPE.physical,
                                                }),
                                            } : {
                                                onMouseDown: () => addBookToCart({
                                                    type: BOOK_TYPE.physical,
                                                }),
                                            })}
                                        />
                                        {PhysicalBookAnimationItems}
                                    </ButtonContainer>
                                    {nudgeUserToPurchaseBook && (
                                        <Tooltip
                                            permanent
                                            text={PURCHASE_BOOK_NUDGE_TOOLTIP_MESSAGE}
                                            side={TOOLTIP_TYPE.bottom}
                                        />
                                    )}
                                </PurchaseBookButtonContainer>
                            )}
                        </Transition>
                    </PostBannerRightContainer>
                </PostBanner>
                <PostContent
                    adjustMarginTop={postContentAdjustMarginTop}
                >
                    <PostContentLeftContainer
                        width={postContentLeftContainerWidth}
                        marginRight={POST_BANNER_LEFT_CONTAINER_MARGIN_RIGHT}
                    >
                        {post
                        && pageLoadRendersCompleted && (
                            <ReaderContentsTableOutliner
                                hide={characterModalIsVisible}
                                hasSound={hasSound}
                                user={user}
                                currentSessionId={currentSessionId}
                                viewportDimensions={viewportDimensions}
                                write={write}
                                post={post}
                                currentResolutionLevel={currentResolutionLevel}
                                contentsTable={contentsTable}
                                selectedPostValuePath={selectedPostValuePath}
                                hasError={isDragReject || hitPostMinimum || hitDetailMinimum}
                                notifyUserToSignUpToNavigateBook={notifyUserToSignUpToNavigateBook}
                                postValueIsSaving={postValueIsSaving}
                                onChangeSelectedPostValuePath={onChangeSelectedPostValuePath}
                                onSetExpandContentsTableChapter={onSetExpandContentsTableChapter}
                                setCursorSigns={setCursorSigns}
                                onCursorEnter={onCursorEnter}
                                onCursorLeave={onCursorLeave}
                                setSnackbarData={setSnackbarData}
                                contentsTableRef={contentsTableRef}
                                contentsTableProgressBarContainerRef={contentsTableProgressBarContainerRef}
                                contentsTableProgressBarRef={contentsTableProgressBarRef}
                                contentsTableToggleButtonContainerRef={contentsTableToggleButtonContainerRef}
                                setNotifyUserToSignUpToNavigateBook={setNotifyUserToSignUpToNavigateBook}
                            />
                        )}
                    </PostContentLeftContainer>
                    <PostContentCenterContainer
                        ref={postEditorContainerRef}
                        readOnly={!write}
                        postBannerLeftContainerWidth={POST_BANNER_LEFT_CONTAINER_WIDTH}
                        postBannerRightContainerWidth={viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                            ? annotationViewWidth
                            : ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH}
                    >
                        {post
                        && postId
                        && pageLoadRendersCompleted
                            ? PostEditorComponent
                            : (
                                <PostPlaceholderContainer
                                    postBannerHeight={POST_BANNER_HEIGHT}
                                    postContentMarginTop={postContentAdjustMarginTop}
                                >
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`0px ${POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BLOCK_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BLOCK_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                    <PlaceholderBox
                                        width={`calc(100% - ${2 * POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN}px)`}
                                        height={BODY_FONT_SIZE}
                                        margin={`${
                                            POST_PLACEHOLDER_BOX_VERTICAL_MARGIN
                                        }px ${
                                            POST_PLACEHOLDER_BOX_HORIZTONAL_MARGIN
                                        }px`}
                                    />
                                </PostPlaceholderContainer>
                            )}
                        {post
                        && post.chapters
                        && post.chapters.length > 0
                        && !write
                        && pageLoadRendersCompleted
                        && currentResolutionLevel
                        && currentResolutionLevel.level >= RESOLUTION_LEVEL.four
                        && !snackbarIsVisible
                        && (
                            <ReaderLocalizingNavigator
                                previousPageText={previousPageText}
                                nextPageText={nextPageText}
                                post={post}
                                chapterIndex={'chapters' in post && selectedPostValuePath.length > 0
                                    ? selectedPostValuePath[0]
                                    : undefined}
                                sectionIndex={'chapters' in post
                                    && post.chapters[selectedPostValuePath[0]]
                                    && post.chapters[selectedPostValuePath[0]].sections
                                    && selectedPostValuePath.length > 1
                                    ? selectedPostValuePath[1]
                                    : undefined}
                                readerLocalizingNavigatorRef={readerLocalizingNavigatorRef}
                                initialProgress={getLocalizerProgress(0)}
                                onMouseEnter={onButtonMouseEnter}
                                onMouseLeave={onButtonMouseLeave}
                                onNavigatePreviousPage={handleNavigatePreviousPage}
                                onNavigateNextPage={handleNavigateNextPage}
                            />
                        )}
                    </PostContentCenterContainer>
                    {annotations.size > 0
                    && paragraphMap.size > 0
                    && !write
                    && post
                    && pageLoadRendersCompleted
                    && (
                        <DetailView
                            ref={detailViewRef}
                            isVisible
                            zIndex={DETAIL_VIEW_Z_INDEX - 1} // We want to ensure UpdateText and UploadProgress are above this
                            width={viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                                ? annotationViewWidth
                                : ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH}
                            height={postHeight}
                            isSteering={isSteering}
                            showWarning={false} // Will inherit from parent
                            background={DETAIL_VIEW_BACKGROUND}
                            transitionDuration={DETAIL_VIEW_TRANSITION_DURATION}
                        >
                            <Resizable
                                width={viewportDimensions.width >= MEDIA_QUERY_SIZE.small.min
                                    ? annotationViewWidth
                                    : ANNOTATION_VIEW_SMALL_VIEWPORT_WIDTH}
                                height={viewportDimensions.height}
                                handle={handle}
                                axis="x"
                                onResizeStart={onResizeStart}
                                onResizeStop={onResizeStop}
                                onResize={onResize}
                                minConstraints={[MIN_ANNOTATION_SECTION_WIDTH, viewportDimensions.height]}
                                maxConstraints={[resizeableMaxConstraintsWidth, viewportDimensions.height]}
                                resizeHandles={['w']}
                            >
                                <AnnotationContainer>
                                    {Annotations}
                                </AnnotationContainer>
                            </Resizable>
                        </DetailView>
                    )}
                </PostContent>
            </PostContentContainer>
            {write && FileUploads}
            {write && UpdateText}
            {!write
            && postId
            && post
            && pageLoadRendersCompleted
            && (
                <AnnotationEditor
                    id="annotation-editor"
                    user={user}
                    shouldFocus
                    isAuthor
                    boxShadow
                    currentSessionId={currentSessionId}
                    readOnly={false}
                    hasSound={hasSound}
                    postId={postId}
                    top={annotationEditorPosition.y}
                    left={annotationEditorPosition.x}
                    setRef={(editorRef) => { annotationEditorRef.current = editorRef; }}
                    postEditor={postEditor}
                    width={ANNOTATION_EDITOR_WIDTH}
                    type={EDITOR_CONTEXT_TYPE.annotation} // Used to know which storage bucket to save media
                    color={DEFAULT_EDITOR_COLOR}
                    fontMultiplier={ANNOTATION_EDITOR_FONT_MULTIPLIER_LARGE}
                    submitAnnotation={handleSubmitAnnotation}
                    placeholder="Type..."
                    onCursorEnter={onCursorEnter}
                    onCursorLeave={onCursorLeave}
                    setInputFocused={setInputFocused}
                    updatePosition={updateAnnotationEditorPosition}
                    postQuote={postQuote}
                    setPostQuote={setPostQuote}
                    viewportDimensions={viewportDimensions}
                    setEditor={setAnnotationEditor}
                    setSnackbarData={setSnackbarData}
                />
            )}
            <Transition
                in={
                    viewportDimensions.width < MEDIA_QUERY_SIZE.small.min
                    && annotations.size > 0
                    && !write
                    && showDetailBanner
                    && !!annotationIsFocused
                }
                timeout={{
                    enter: DETAIL_SHEET_ENTER_TRANSITION_DURATION,
                    exit: DETAIL_SHEET_EXIT_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <CustomSheet
                        className={DETAIL_SHEET_CLASSNAME}
                        isOpen
                        onClose={onHideAnnotationDetail}
                        snapPoints={[customSheetSnapPoint]}
                        initialSnap={0}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                direction: 'up',
                                offset: 20,
                                duration: DETAIL_SHEET_ENTER_TRANSITION_DURATION,
                                easing: themeObj.motion.eagerEasing,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({
                                direction: 'up',
                                offset: 20,
                            })[state],
                        }}
                    >
                        <CustomSheet.Container
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onViewportBoxUpdate={() => {}}
                        >
                            <CustomSheet.Header />
                            <CustomSheet.Content
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onViewportBoxUpdate={() => {}}
                            >
                                <ModalSheetCloseButtonContainer>
                                    <Button
                                        className={HOVER_TARGET_CLASSNAME}
                                        type={BUTTON_TYPE.solid}
                                        background={themeObj.color.neutral200}
                                        height={MODAL_SHEET_BUTTON_HEIGHT}
                                        width={MODAL_SHEET_BUTTON_WIDTH}
                                        icon={CrossIcon}
                                        {...(detectTouchDevice(document) ? {
                                            onTouchStart: onHideAnnotationDetail,
                                        } : {
                                            onMouseDown: onHideAnnotationDetail,
                                        })}
                                    />
                                </ModalSheetCloseButtonContainer>
                                {SheetContent}
                            </CustomSheet.Content>
                        </CustomSheet.Container>
                        <Sheet.Backdrop />
                    </CustomSheet>
                )}
            </Transition>
            <PageLogo
                dark={stickyPostBannerActive}
                withEnterTransition
                className={HOVER_TARGET_CLASSNAME}
                {...(detectTouchDevice(document) ? {
                    onTouchStart: (e) => onLogoEnter(e),
                } : {
                    onMouseEnter: (e) => onLogoEnter(e),
                })}
                {...(detectTouchDevice(document) ? {
                    onTouchEnd: (e) => onLogoLeave(e),
                } : {
                    onMouseLeave: (e) => onLogoLeave(e),
                })}
                onMouseDown={onLogoClick}
            >
                <ReactSVG
                    src={VerascopeLogo}
                />
            </PageLogo>
            {!!user
            && post
            && pageLoadRendersCompleted
            && currentResolutionLevel
            && (
                write
                || (
                    appliedResolutionLevelCount !== null
                    && appliedResolutionLevelCount > 0
                )
            )
            && (
                <ResolutionLevelSelectorContainer
                    readOnly={!write}
                    selectorWidth={RESOLUTION_LEVEL_BUTTON_LENGTH}
                >
                    {informResolutionAdjustCapability
                    && !write
                    && (
                        <Tooltip
                            permanent={showResolutionTooltip}
                            text="Too little or too much detail? Adjust the engagement level."
                            side={TOOLTIP_TYPE.left}
                        />
                    )}
                    <OptionsMenu
                        center={!write}
                        revealAbove
                        setRef={(resRef) => { resolutionLevelButtonRef.current = resRef; }}
                        className={HOVER_TARGET_CLASSNAME}
                        buttonIcon={MagnifyingGlassIcon}
                        buttonColor={themeObj.verascopeColor.purple400}
                        buttonHeight={RESOLUTION_LEVEL_BUTTON_LENGTH}
                        childItemHeight={RESOLUTION_LEVEL_ITEM_HEIGHT}
                        childItemWidth={RESOLUTION_LEVEL_ITEM_WIDTH}
                        parentRef={rootRef.current || containerRef.current}
                        tooltipText="Engagement Level"
                        tooltipSideType={!write ? TOOLTIP_TYPE.left : TOOLTIP_TYPE.right}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        onToggle={(visible) => handleToggleResolutionLevelButton(visible)}
                    >
                        {ResolutionLevelButtons}
                    </OptionsMenu>
                    {currentResolutionLevel.icon && (
                        <CurrentResolutionLevelIcon
                            src={currentResolutionLevel.icon}
                        />
                    )}
                    {!currentResolutionLevel.icon && (
                        <CurrentResolutionLevelText
                            currentResolutionDigit={currentResolutionLevel.level - unusedResolutionLevelCount}
                        >
                            {/* In write mode, unusedResolutionCount in zero */}
                            {currentResolutionLevel.level - unusedResolutionLevelCount}
                        </CurrentResolutionLevelText>
                    )}
                </ResolutionLevelSelectorContainer>
            )}
            <Transition
                in={!write && notifyUserToSignUpToNavigateBook}
                timeout={{
                    enter: OVERLAY_TRANSITION_DURATION,
                    exit: OVERLAY_TRANSITION_DURATION,
                }}
                appear
                mountOnEnter
                unmountOnExit
            >
                {(state) => (
                    <Overlay
                        className={HOVER_TARGET_CLASSNAME}
                        zIndex={READER_VIEW_OVERLAY_Z_INDEX}
                        onMouseEnter={onButtonMouseEnter}
                        onMouseLeave={onButtonMouseLeave}
                        {...(detectTouchDevice(document) ? {
                            onTouchStart: () => setNotifyUserToSignUpToNavigateBook(false),
                        } : {
                            onMouseDown: () => setNotifyUserToSignUpToNavigateBook(false),
                        })}
                        style={{
                            ...FADE_IN_DEFAULT_STYLE({
                                duration: OVERLAY_TRANSITION_DURATION,
                            }),
                            ...FADE_IN_TRANSITION_STYLES({})[state],
                        }}
                    />
                )}
            </Transition>
            {CharacterModal}
        </Container>
    );
}

export default ReaderView;
