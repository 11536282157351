// ===== Packages =====

import {
    doc,
    getFirestore,
    runTransaction,
}                           from 'firebase/firestore';

// ===== Enums =====

import {
    MEDIA_TYPE,
}                           from '../enums';

interface Args {
    collection: string,
    id: string,
    annotationMediaType: MEDIA_TYPE,
    description?: string,
    caption?: string,
    title?: string,
    url?: string,
    userId?: string,
    filePath?: string,
    transcript?: any,
    plays?: string[],
    clicks?: string[],
}
const updateAnnotationMediaInDB = async ({
    collection,
    id,
    annotationMediaType,
    description,
    caption,
    title,
    url,
    userId,
    filePath,
    transcript,
    plays,
    clicks,
}: Args): Promise<void> => {
    const db = getFirestore();
    const ref = doc(db, collection, id);
    try {
        return await runTransaction(db, async (transaction) => {
            if (annotationMediaType) {
                // Handling annotation media
                switch (annotationMediaType) {
                case MEDIA_TYPE.image: {
                    if (userId) transaction.update(ref, { userId });
                    if (filePath) transaction.update(ref, { filePath });
                    if (caption) transaction.update(ref, { caption });
                    break;
                }
                case MEDIA_TYPE.sketch: {
                    if (userId) transaction.update(ref, { userId });
                    if (filePath) transaction.update(ref, { filePath });
                    if (caption) transaction.update(ref, { caption });
                    break;
                }
                case MEDIA_TYPE.audioNote: {
                    if (userId) transaction.update(ref, { userId });
                    if (filePath) transaction.update(ref, { filePath });
                    if (title) transaction.update(ref, { title });
                    if (description) transaction.update(ref, { description });
                    if (transcript) transaction.update(ref, { transcript });
                    if (plays) transaction.update(ref, { plays });
                    break;
                }
                case MEDIA_TYPE.link: {
                    if (userId) transaction.update(ref, { userId });
                    if (url) transaction.update(ref, { href: url });
                    if (clicks) transaction.update(ref, { clicks });
                    break;
                }
                case MEDIA_TYPE.embedding: {
                    if (userId) transaction.update(ref, { userId });
                    if (url) transaction.update(ref, { url });
                    if (caption) transaction.update(ref, { caption });
                    break;
                }
                default:
                    throw Error('Encountered unrecognized annotation media type during annotation media data update to Firestore.');
                }
            } else {
                throw Error('Encountered unrecognized annotation media type.');
            }
        });
    } catch (e) {
        throw Error('Encountered an error updating annotation.');
    }
};

export default updateAnnotationMediaInDB;
